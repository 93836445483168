import { useEffect, useRef, useState } from 'react';

import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import LoadingPage from '../../pages/loading/LoadingPage';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';

import { Image } from '../../designComponents/imageboxs/Image';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import { MText } from '../../designComponents/text/ContentText';

// 회원 사진 기록
const PhotoHistoryModal = ({ userId, type, setToggle }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const currentTarget = useRef();

    useEffect(() => {
        setIsLoading(true);
        RefreshCheckApi.get(`/adminUser/photoHistory?user_id=${userId}&type=${type}`)
            .then((response) => {
                setData(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                alert('[ERROR]관리자에게 문의하세요');
            });
    }, [userId, type]);

    // 클릭 감지
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    // 이미지 모달 외부 클릭 시 Modal창 닫힘
    const handleClickOutside = (event) => {
        if (!currentTarget.current) {
            return;
        } else {
            if (currentTarget && !currentTarget.current.contains(event.target)) {
                setToggle((prev) => !prev);
            }
        }
    };

    return (
        <DefaultModal
            bodyWidth={39}
            title={type === 1 ? '프로필 사진 변경 이력' : '카메라 인증 이력'}
            boxStyle={{ width: '70vh', height: '50vh' }}
            xIcon={true}
            closeOn={true}
            cancelText="닫기"
            onClickBottomClose={() => setToggle({})}
            onClickClose={() => setToggle({})}
        >
            {isLoading ? (
                <LoadingPage text={'이미지 기록 정보를 불러오는 중입니다.'} style={{ height: '35vh' }} />
            ) : (
                <FlexMainWrapper style={{ alignItems: 'center', flexWrap: 'wrap', padding: '20px' }}>
                    {data?.length ? (
                        data[0]?.photo_lists?.map((item, index) => {
                            return <Image key={index} width={120} height={120} alt="회원 이미지" url={item} style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }} />;
                        })
                    ) : (
                        <FlexMainWrapper style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '35vh' }}>
                            <MText text={'이력이 존재하지 않습니다.'} color="#797979" />
                        </FlexMainWrapper>
                    )}
                </FlexMainWrapper>
            )}
        </DefaultModal>
    );
};

export default PhotoHistoryModal;
