import React, { useCallback, useContext, useRef, useState } from 'react';

// axios
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import { UseSyrupListsTableFilter } from '../../components/SyrupComponents/UseSyrupListsTableFilter';
import DetailUseSyrupListModal from '../../components/SyrupComponents/DetailUseSyrupListModal';
import UserModal from '../../components/UserComponents/UserModal';

// design-components
import MainTable from '../../designComponents/tables/MainTable';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';

// data_system
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import { moneyUpAndDown, sexFilter } from '../../utils/textFilter';
import TableFilter from "../../designComponents/filters/TableFilter";

// 시럽 사용내역
const SyrupUsingLists = () => {
    // regacy-context api
    const { user_info_modal_state } = useContext(UserInfoModalContext);
    // state
    const [params, setParams] = useState({
        orderByType: 1,
        orderType: 1
    });
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [detailToggle, setDetailToggle] = useState([false, 0]);

    const ref = useRef(null);

    const callApi = async (page, param) => {
        try {
            let input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.post('/adminSyrup/list', input);
            if (response.status === 200) {
                return response.data.data;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const pageCountCallApi = async (page, param) => {
        try {
            let input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.post('/adminSyrup/list', input);
            return response.data.all_cnt;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            {detailToggle[0] && <DetailUseSyrupListModal detailToggle={detailToggle} setDetailToggle={setDetailToggle} />}
            {user_info_modal_state.modalVisible && <UserModal />}

            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '사용내용', value: 4 },
                    { id: 5, title: '회원ID', value: 5 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />

            <TableFilter
                param={params}
                setParam={setParams}
                topFilterLists={[

                    {
                        filterText: '정렬 기준',
                        orderLists: [
                            { name: '사용 일시', width: 50, type: 1 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...params };
                            if (e.target.checked)
                                obj.orderByType = type; // setOrderbyType(type);
                            else delete obj.orderByType;
                            setParams(obj);
                        },
                        orderbyType: params.orderByType,
                    },
                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림 차순', width: 50, type: 1 },
                            { name: '오름 차순', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...params };
                            if (e.target.checked)
                                obj.orderType = type; // setOrderbyType(type);
                            else delete obj.orderType;
                            setParams(obj);
                        },
                        orderbyType: params.orderType,
                    },

                ]}
                filterLists={[
                    {
                        key: 'point_reason_detail',
                        value: '사용 내용[단짝]',
                        filter: [
                            {
                                key: 0,
                                value: '사진 인증 완료된 단짝'
                            },
                            {
                                key: 1,
                                value: '프로필 작성률 높은 단짝'
                            },
                            {
                                key: 2,
                                value: '최근 가입한 단짝'
                            },
                            {
                                key: 3,
                                value: '편지 많이 받은 단짝'
                            },
                            {
                                key: 4,
                                value: '평점 높은 단짝'
                            },
                            {
                                key: 5,
                                value: '사진 인증한 단짝'
                            },
                            {
                                key: 6,
                                value: '오늘의 랜덤 단짝'
                            },
                            {
                                key: 7,
                                value: '종교가 같은 단짝'
                            },

                        ]

                    },
                    {
                        key: 'point_reason_detail',
                        value: '사용 내용[상품]',
                        filter:[
                            {
                                key: 10,
                                value: '편지 사용'
                            },
                            {
                                key: 11,
                                value: '편지 반환'
                            },
                            {
                                key: 12,
                                value: '지나간 인연 보기'
                            },
                            {
                                key: 13,
                                value: '편지 사용(최초 사용)'
                            },
                            {
                                key: 14,
                                value: '편지 사용(하루 첫 사용)'
                            },
                            {
                                key: 15,
                                value: '탈퇴 재고 시럽'
                            },
                        ]
                    },
                    {
                        key: 'point_reason_detail',
                        value: '사용 내용[결제]',
                        filter:[
                            {
                                key: 20,
                                value: '충전'
                            },
                            {
                                key: 21,
                                value: '환불'
                            },
                        ]
                    },
                    {
                        key: 'point_reason_detail',
                        value: '사용 내용[이벤트]',
                        filter:[
                            {
                                key: 30,
                                value: '빼빼로 데이'
                            },
                            {
                                key: 31,
                                value: '크리스마스'
                            },
                            {
                                key: 35,
                                value: '새해'
                            },
                            {
                                key: 32,
                                value: '화이트 데이'
                            },
                            {
                                key: 33,
                                value: '어린이 날'
                            },
                            {
                                key: 34,
                                value: '7월 7석'
                            },
                        ]
                    },{
                        key: 'point_reason_detail',
                        value: '사용 내용[관리자]',
                        filter:[
                            {
                                key: 40,
                                value: '관리자 추가'
                            },
                            {
                                key: 41,
                                value: '관리자 회수'
                            },

                        ]
                    },
                    {
                        key: 'point_reason_detail',
                        value: '사용 내용[기타]',
                        filter:[
                            {
                                key: -1,
                                value: '기타'
                            },

                        ]
                    }

                ]}
                research={ref}
            />
            
            

            <MainTable
                ref={ref}
                tableStyle={{ width: '95%' }}
                loadingText={'시럽사용내역 데이터를 불러오고 있습니다.'}
                headerData={UseSyrupListsTableFilter}
                checkID={'id'}
                param={params}
                toggleBlur={true}
                setParam={setParams}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    setDetailToggle([true, item.id]);
                }}
                tbodyRenderItem={(key, value) => {
                    if (key === 'sinor_love') return moneyUpAndDown(value);
                    if (key === 'user_sex') return sexFilter(value);
                    return value;
                }}
            />
        </div>
    );
};

export default SyrupUsingLists;
