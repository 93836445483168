import {getPoints, getPointsSearch, postPoints,} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import {GET_POINTS, POST_POINTS} from "./points_table_type";

export const getPointsAsync = createGetRequestThunk(GET_POINTS, getPoints);

export const getPointsSearchAsync = createGetRequestThunk(
  GET_POINTS,
  getPointsSearch
);

export const postPointsAsync = createPostRequestThunk(POST_POINTS, postPoints);
