import React from "react";
import styled from "styled-components";
import {rgb_121_121_121} from "../../colors";
import {w_px11rate} from "../../size";

const ListContent = ({ children }) => {
  return <Content>{children}</Content>;
};

export default React.memo(ListContent);

const Content = styled.div`
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_regular;
  color: ${rgb_121_121_121};
`;
