export const userAccountStatusFilter = [
    {
        key: 'created_at',
        value: '결제일시',
    },
    {
        key: 'status',
        value: '출금상태',
    },
    {
        key: 'history_type',
        value: '상품 구분',
    },
    {
        key: 'gathering_name',
        value: '모임명',
    },
    {
        key: 'owner_name',
        value: '모임장명',
    },
    {
        key: 'start_date',
        value: '일정일시',
    },
    {
        key: 'content_title',
        value: '일정명',
    },
    {
        key: 'member_name',
        value: '모임원명',
    },
    {
        key: 'member_nick_name',
        value: '모임원 닉네임',
    },
    {
        key: 'member_phone_no',
        value: '휴대폰 번호',
    },
    {
        key: 'cost',
        value: '금액',
    },
    {
        key: 'charge',
        value: '수수료',
    },
    {
        key: 'cost_pay_out',
        value: '출금 정산액',
    },
    {
        key: 'total',
        value: '출금 후 잔액',
    },
    {
        key: 'pay_out_status',
        value: '이체처리여부',
    },
];
