export const GET_SUBSCRIBE_PAYMENTS_DETAIL =
  "admin/GET_SUBSCRIBE_PAYMENTS_DETAIL";
export const GET_SUBSCRIBE_PAYMENTS_DETAIL_SUCCESS =
  "admin/GET_SUBSCRIBE_PAYMENTS_DETAIL_SUCCESS";
export const GET_SUBSCRIBE_PAYMENTS_DETAIL_FAILURE =
  "admin/GET_SUBSCRIBE_PAYMENTS_DETAIL_FAILURE";
export const PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD =
  "admin/PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD";
export const PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_SUCCESS =
  "admin/PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_SUCCESS";
export const PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_FAILURE =
  "admin/PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_FAILURE";
export const PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO =
  "admin/PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO";
export const PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS =
  "admin/PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS";
export const PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE =
  "admin/PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE";
export const GET_SUBSCRIBE_PAYMENTS_DETAIL_REFUND =
  "admin/POST_SUBSCRIBE_PAYMENTS_DETAIL_REFUND";
export const GET_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_SUCCESS =
  "admin/POST_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_SUCCESS";
export const POST_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_FAILURE =
  "admin/POST_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_FAILURE";
