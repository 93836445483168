import React, { useEffect } from 'react';
import moment from 'moment';

// styled-components
import { TableWrapper } from '../Common/Styles/CommonTableStyled';
import { ExcelWrapper } from '../styles/UserActivityTableStyled';

// excel
import { onClickExcelFormat } from '../../data_system/xlsx_util/xlsx_util';

// components
import ExcelBtn from '../StatisticsComponents/Excel';

// 리텐션 테이블
function RetentionTable({ header, data, type }) {
    /******************************** function ********************************/

    // 엑셀 생성 및 값 수정
    const onPressPrintExcel = () => {
        // 상단 제목
        let header = ['날짜', '당일 접속자 수'];

        Array(30) // 30일 까지 날짜 기입
            .fill()
            .map((_, index) => {
                return header.push(`D+${index + 1}`);
            });

        // 데이터
        let copyDataArr = data.slice();
        let copyData = [];

        copyDataArr.forEach((item, index) => {
            let perArr = [];

            item.retention_value.forEach((per, index) => {
                perArr.push(per);
            });

            copyData.push([item.retention_date, item.user_cnt, ...perArr]);
        });

        onClickExcelFormat(header, copyData, `리텐션(${moment().format('YYYY-MM-DD')}).xlsx`);
    };

    /********************************** html **********************************/
    return (
        <TableWrapper id="table-wrapper" style={{ marginBottom: '5%' }} filterToggle={true} data={data} text={true}>
            <ExcelWrapper width={100}>
                <ExcelBtn onPress={onPressPrintExcel} />
            </ExcelWrapper>

            <table>
                <thead className="title">
                    <tr>
                        <th style={{ padding: '10px 2px', textAlign: 'center' }}>날짜</th>
                        <th style={{ padding: '10px 2px', textAlign: 'center' }}>인앱 활동자 수</th>
                        {Array(31)
                            .fill()
                            .map((_, index) => {
                                return (
                                    <th key={index} style={{ padding: '10px 2px', textAlign: 'center' }}>
                                        D+{index + 1}
                                    </th>
                                );
                            })}
                    </tr>
                </thead>

                <tbody className="body">
                    {data.length ? (
                        data?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{ width: '56px', fontWeight: '600', textAlign: 'center' }}>{item.retention_date}</td>
                                    <td style={{ padding: 0, textAlign: 'center' }}>{item.user_cnt}</td>
                                    {item?.retention_value.map((per, index) => {
                                        return (
                                            <>
                                                <td key={index} style={{ padding: 0, textAlign: 'center' }}>
                                                    {per || '-'}
                                                </td>
                                            </>
                                        );
                                    })}
                                    {Array(item?.retention_value.length < 31 ? 31 - item?.retention_value.length : 0)
                                        .fill()
                                        .map((_, index) => {
                                            return (
                                                <td key={index} style={{ padding: 0, textAlign: 'center' }}>
                                                    {'-'}
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={32} style={{ textAlign: 'center', height: '20vh', color: 'gray' }}>
                                데이터가 존재하지 않습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </TableWrapper>
    );
}

export default RetentionTable;
