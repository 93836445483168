import BootStrapTabs from '../../common/BootStrap/BootStrapTabs';

const BannerTab = ({ setExposureLocation, exposureLocation }) => {
    const tabList = [
        {
            title: '메인 페이지',
            key: 1,
        },
        {
            title: '모임 페이지',
            key: 2,
        },
        {
            title: '채팅 페이지',
            key: 3,
        },
        {
            title: '내 정보',
            key: 5,
        },
    ];

    return <BootStrapTabs tabList={tabList} setValue={setExposureLocation} defaultKey={exposureLocation}></BootStrapTabs>;
};

export default BannerTab;
