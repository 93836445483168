import React from "react";
import styled from "styled-components";
import {rgb_0_0_0, rgb_255_255_255, rgb_66_139_247, rgba_66_139_247_0d2,} from "../../colors";
import {h_px28rate, w_px10rate, w_px11rate, w_px3rate, w_px82rate,} from "../../size";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ko} from "date-fns/esm/locale";

const ShowScheduleSetting = ({
  exposureStartDatetime,
  setExposureStartDatetime,
  exposureEndDatetime,
  setExposureEndDatetime,
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <DateBtn
        locale={ko}
        selected={exposureStartDatetime}
        dateFormat="yyyy-MM-dd"
        onChange={(date) => setExposureStartDatetime(date)}
        placeholderText="클릭해주세요."
      ></DateBtn>
      <MiddlePart>~</MiddlePart>
      <DateBtn
        locale={ko}
        selected={exposureEndDatetime}
        dateFormat="yyyy-MM-dd"
        onChange={(date) => setExposureEndDatetime(date)}
        placeholderText="클릭해주세요."
      ></DateBtn>
    </div>
  );
};

export default React.memo(ShowScheduleSetting);

const MiddlePart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${h_px28rate}px;
  box-sizing: border-box;
  font-size: ${w_px10rate}px;
  font-family: noto_sans_kr_medium;
  padding: 0px;
  margin: 0px;
  background-color: ${rgba_66_139_247_0d2};
  color: ${rgb_66_139_247};
`;

const DateBtn = styled(DatePicker)`
  padding-left: ${w_px3rate}px;
  padding-right: ${w_px3rate}px;
  margin: 0px;
  width: ${w_px82rate}px;
  height: ${h_px28rate}px;
  box-sizing: border-box;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_regular;
  color: ${rgb_0_0_0};
  border: 1px solid ${rgba_66_139_247_0d2};
  background-color: ${rgb_255_255_255};
  display: flex;
  align-items: center;
`;
