/*******************************************************
 * call_type - 0: 전체
 * call_status - 0: 통화 요청 1: 부재중 2: 통화 중 3: 통화 종료
 *******************************************************/
export const callHistoryFilter = [
    {
        key: 'id',
        value: '내역ID',
    },
    {
        key: 'call_type',
        value: '통화 유형',
        filter: [
            { key: 0, value: '전체' },
            // { key: 1, value: '일반통화' },
            // { key: 2, value: '영상통화' },
        ],
    },
    {
        key: 'call_status',
        value: '통화 상태',
        filter: [
            // { key: 0, value: '통화 요청' },
            // { key: 1, value: '부재중' },
            // { key: 2, value: '통화중' },
            // { key: 3, value: '통화 종료' },
        ],
    },
    {
        key: 'start_time',
        value: '통화 시작 시간',
        filter: [
            // { key: 0, value: '00:00~03:00' },
            // { key: 1, value: '03:00~06:00' },
            // { key: 2, value: '06:00~12:00' },
        ],
    },
    {
        key: 'end_time',
        value: '통화 종료 시간',
    },
    {
        key: 'send_user_name',
        value: '발신자',
        filter: null,
    },
    {
        key: 'send_nick_name',
        value: '발신자 닉네임',
        filter: null,
    },
    {
        key: null,
        value: null,
        filter: null,
    },
    {
        key: 'receive_user_name',
        value: '수신자',
        filter: null,
    },
    {
        key: 'receive_user_nick_name',
        value: '수신자 닉네임',
        filter: null,
    },
    {
        key: 'call_time',
        value: '통화시간',
        filter: [
            // { key: 0, value: '1분 이상' },
            // { key: 1, value: '3분 이상' },
            // { key: 2, value: '5분 이상' },
            // { key: 3, value: '10분 이상' },
        ],
    },
];
