import produce from "immer";
import {handleActions} from "redux-actions";
import {GET_SMS_FAILURE, GET_SMS_SUCCESS} from "./sms_type";

const initialState = {
    sms_user: null,
};

const smsReducer = handleActions(
    {
        [GET_SMS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.sms_user = action.payload;
            }),
        [GET_SMS_FAILURE]: (state,action) =>
            produce(state,(draft)=>{

            })
    },
    initialState
);

export default smsReducer;
