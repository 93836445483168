import React, { useEffect } from 'react';
import moment from 'moment';

// styled-components
import { ModalWrapper, ModalBox, Header, Text } from '../../Common/Styles/CommonModalStyled';
import { CommentBox, UserImage, Name, Comment, CommentDate, DeleteComment } from '../../Common/Styles/CommentStyled';

// API URL
import { main_server_image_ip } from '../../../communication_system/communication_ips';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsLists } from '../../../data_system/redux/modules/reports.reducer/reports.reducer';
import { deleteGatheringContentsComment } from '../../../data_system/redux/modules/gathering.reducer/gatheringContents.reducer';
import Comments from '../Comments';

// 댓글보기 모달
function ShowCommentsModal({ showCommentToggle, setShowCommentToggle }) {
    const dispatch = useDispatch();

    // useSelector
    const { comments } = useSelector((state) => state.reportsReducer);

    /******************************************* useEffect *******************************************/

    // 데이터 조회
    useEffect(() => {
        dispatch(getCommentsLists(showCommentToggle[1]));
    }, [dispatch, showCommentToggle]);

    /******************************************* function *******************************************/

    // 댓글 삭제하기
    const onClickDeleteComment = (commnet_id) => () => {
        if (window.confirm('댓글을 삭제하시겠습니까?')) {
            dispatch(deleteGatheringContentsComment(commnet_id));
        }
    };

    /********************************************* html *********************************************/
    return (
        <ModalWrapper>
            <ModalBox width={60}>
                <Header>
                    <Text>상세정보</Text>
                    <Text style={{ cursor: 'pointer' }} onClick={() => setShowCommentToggle([false, 0])}>
                        닫기
                    </Text>
                </Header>

                <div style={{ height: '100%', overflow: 'auto' }}>
                    {comments.length >= 1
                        ? comments?.map((data, index) => {
                              return (
                                  <>
                                      <CommentBox key={index} style={{ margin: '0 10px' }}>
                                          <UserImage key={index} sizes={45} src={main_server_image_ip + data.url} alt="회원이미지" />
                                          <div style={{ width: '100%' }}>
                                              <Name>
                                                  {data.name}({data.nick_name})<CommentDate>{moment(data.created_at).format('YYYY년 MM월 DD일')}</CommentDate>
                                              </Name>
                                              <Comment>{data.comment}</Comment>
                                              <DeleteComment>
                                                  <span className="delte-btn" onClick={onClickDeleteComment(data.id)}>
                                                      삭제
                                                  </span>
                                              </DeleteComment>
                                          </div>
                                      </CommentBox>
                                      <Comments contentsId={data.id} style={{ marginLeft: '10%' }} />
                                  </>
                              );
                          })
                        : null}
                </div>
            </ModalBox>
        </ModalWrapper>
    );
}

export default ShowCommentsModal;
