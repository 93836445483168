import styled from 'styled-components';
import React from 'react';
import { w_px11rate } from '../../size';
import { rgb_255_255_255, rgb_66_139_247 } from '../../colors';

const BlueBtn = ({ children, onClick, width, height, style }) => {
    return (
        <Btn onClick={onClick} width={width} height={height} style={{ ...style }}>
            {children}
        </Btn>
    );
};

export default React.memo(BlueBtn);

const Btn = styled.button`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    border: 1px solid ${rgb_66_139_247};
    background-color: ${rgb_66_139_247};
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: noto_sans_kr_medium;
    color: ${rgb_255_255_255};
`;
