import FlexMainWrapper from "../../designComponents/wrappers/FlexMainWrapper";
import UserChart from "./UserChart";
import { SText } from "../../designComponents/text/ContentText";
import React, { useEffect, useState } from "react";
import RefreshCheckApi from "../../communication_system/axios_apis/refresh_check/refresh_check_api";
import moment from "moment/moment";
import LoadingPage from "../../pages/loading/LoadingPage";
import { Box } from "../styles/UserActivityTableStyled";

const UserDashBordPieChart = ({platform}) => {

	const beforeSevenDays = moment().subtract(7, 'days').format('YYYY-MM-DD'); // 7일 전


	const [loading, setLoading] = useState(true);

	const [userGraghData, setUserGraghData] = useState({ complete: [], sex: [] });
	const [compareDate, setCompareDate] = useState(beforeSevenDays); // 비교 날짜 상태


	// 회원현황 데이터 요청
	useEffect(() => {
		getPieData().then()
	}, [compareDate, platform]);


	const getPieData = async () => {
		try {

			setLoading(true);

			const adminStatusRes = await RefreshCheckApi.get(`/adminStatus/current/graph`, { date: compareDate, platform: 0 });

			setUserGraghData(adminStatusRes.data.data);
			setLoading(false);

		} catch(err){
			let errMessage = ''
			if(err.response.status === 500){
				errMessage = '회원 현황 조회 ERROR 관리자에게 문의해주세요'
			} else {
				errMessage = err.response?.data?.message
			}
		}
	}

	if(loading){
		return (<LoadingPage text={'회원현황 정보를 불러오는 중입니다'} sizes={true} />)
	}


	const etest = (
		<Box>
			<div>
				<div className="wrapper01">
					<h3>신규활성도 점수 기준</h3>
					<h4>[일자기준 / 누적 회원 수 활성도]</h4>
				</div>
			</div>

			<div className="wrapper02">
				<div>
					<div className="point-wrapper">
						<p>휴대폰인증까지</p>
						<span>[0~9]</span>
					</div>
					<div className="point-wrapper">
						<p>기본정보까지</p>
						<span>[10~20]</span>
					</div>
					<div className="point-wrapper">
						<p>사진등록까지</p>
						<span>[21~40]</span>
					</div>
					<div className="point-wrapper">
						<p>추가정보까지</p>
						<span>[41~100]</span>
					</div>
				</div>
			</div>
		</Box>


	)

	const labelList = {
		'0-9': '휴대폰인증까지 [0-9]',
		'10-29': '기본정보까지 [10-29]',
		'30-49': '사진등록까지[30-49]',
		'50-100': '추가정보까지[50-100]',
	}




	return (
		<FlexMainWrapper style={{ alignItems: 'center' }}>
			<FlexMainWrapper style={{ alignItems: 'end'}}>
				{userGraghData.complete.length && <UserChart style={{width: '400px'}} data={userGraghData.complete} title="활성도" y={'value'} />}
				<FlexMainWrapper style={{ flexDirection: 'column' }}>
					{userGraghData.complete.map((item, index) => {
						return (
							<FlexMainWrapper key={index} style={{ alignItems: 'center', }}>
								<SText style={{ width: '100px', fontSize:'10px' }} text={labelList[item.name]} />
								<SText style={{ width: '50px' }} color={`#818181`} text={`${item.y}명`} />
							</FlexMainWrapper>
						);
					})}
				</FlexMainWrapper>
			</FlexMainWrapper>
			<FlexMainWrapper style={{ alignItems: 'end' }}>
				{userGraghData.sex.length && <UserChart data={userGraghData.sex} title="성별" y={'value'} />}
				<FlexMainWrapper style={{ flexDirection: 'column' }}>
					{userGraghData.sex.map((item, index) => {
						return (
							<FlexMainWrapper key={index} style={{ alignItems: 'center' }}>


								<SText style={{ width: '30px' }} text={item.name} />
								<SText style={{ width: '50px' }} color={`#818181`} text={`${item.y}명`} />
							</FlexMainWrapper>
						);
					})}
				</FlexMainWrapper>
			</FlexMainWrapper>
		</FlexMainWrapper>
	)
}


export default UserDashBordPieChart