import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

// custom hooks
import useInput from '../../hook/useInput';

// react-query && axios
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 시럽 결제정보 생성
function SyrupInfoComponent({ setParams, payType }) {
    // react-query
    const { isLoading, error, data } = useQuery(['syrupPackage'], async () => {
        return await RefreshCheckApi.get('/adminSyrup/package').then((res) => {
            return res.data.data;
        });
    });

    // state
    const [syrupPackageID, setSyrupPackageID] = useState(1);
    const [payAmount, setPayAmount] = useState();

    // hook
    const [point, onChangePoint] = useInput(0);

    // 초기 가격값 적용
    useEffect(() => {
        if (data?.length) {
            setPayAmount(data[0]?.sale_price);
        }
    }, [data]);

    // 입력값 확인
    useEffect(() => {
        if (data && data?.length > 0) {
            let [test] = data?.filter((value, index) => value.id === syrupPackageID);
            let syrup = data?.length ? test : null;

            let newParams = {
                pay_type: payType,
                pay_contents: 7,
                pay_amount: payAmount,
                used_point: parseInt(point),
                package_id: syrupPackageID,
                syrup: syrup?.sinor_love + syrup?.bonus_love,
                package: data,
                point: point,
            };

            setParams(newParams);
        }
    }, [data, payType, setParams, payAmount, point, syrupPackageID]);

    // 에러 감지
    useEffect(() => {
        if (error) {
            alert(error);
        }
    }, [error]);

    // 실 결제금액
    const payAmountFilter = (packageID) => {
        data?.forEach((data) => {
            if (parseInt(data.id) === packageID) {
                setPayAmount(parseInt(data.sale_price));
            }
        });
    };

    // package_id 선택
    const onChangePackage = (e) => {
        let packageID = parseInt(e.target.value);

        payAmountFilter(packageID);
        setSyrupPackageID(packageID);
    };

    return (
        <div>
            <ColWrapper>
                <Subtitle>시럽 패키지</Subtitle>
                <SubInfo>
                    {isLoading ? (
                        <LoadingWrapper>
                            <LoadingOutlined />
                            <div>시럽 패키지를 불러오는 중입니다.</div>
                        </LoadingWrapper>
                    ) : (
                        <Select onChange={onChangePackage}>
                            {data?.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>
                                        {item.origin_price || 0}원 {item.sale_rate || 0}% 할인 {item.sale_price}원 {item.sinor_love + item.bonus_love}개
                                    </option>
                                );
                            })}
                        </Select>
                    )}
                </SubInfo>
            </ColWrapper>

            <ColWrapper>
                <Subtitle>실 결제금액</Subtitle>
                <SubInfo>{payAmount?.toLocaleString('ko-KR') || '5,000'}원</SubInfo>
            </ColWrapper>

            <ColWrapper>
                <Subtitle>포인트</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="포인트 입력" onChange={onChangePoint} />
                </SubInfo>
            </ColWrapper>
        </div>
    );
}

export default SyrupInfoComponent;

/********************************* styled *********************************/

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 85px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 60px;
`;

const Select = styled.select`
    cursor: pointer;
    outline: none;

    border: 1px solid #cccccc;

    /* width: 90px; */
    height: 25px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 13px;
    width: 205px;
`;
