import {getHosts, getHostsSearch, postHosts, deleteAdminHost,} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import CreateDeleteRequestThunk from "../../redux_functions/create_delete_request_thunk";
import {GET_HOSTS, POST_HOSTS, DELETE_HOSTS} from "./hosts_table_type";

export const getHostsAsync = createGetRequestThunk(GET_HOSTS, getHosts);

export const getHostsSearchAsync = createGetRequestThunk(
  GET_HOSTS,
  getHostsSearch
);

export const postHostsAsync = createPostRequestThunk(POST_HOSTS, postHosts);

export const deleteHostAsync = CreateDeleteRequestThunk(DELETE_HOSTS, deleteAdminHost)
