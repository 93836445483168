import { useEffect, useState } from 'react';
import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import { LText, SText } from '../../designComponents/text/ContentText';
import UserDashBord from "./UserDashBorad";
import UserDashBordPieChart from "./UserDashBordPieChart";
import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";
import PlatformTab from "../../GroupComponent/Tab/PlatformTab";

// 통계 > 최 상단 현황판 및 파이차트
const UserPieChart = () => {

    const [platform, setPlatform] = useState(0)

    return (
            <>
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', gap: '15px', margin: '0 0 3vh 0' }}>
                <PlatformTab setPlatform={setPlatform}></PlatformTab>

            </FlexMainWrapper>

            <SText text={'오늘날짜 기준 값 / 시작날짜 기준으로  금일 증가값'} color="#797979" />

            <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                <UserDashBord platform={platform}></UserDashBord>
                <UserDashBordPieChart platform={platform}></UserDashBordPieChart>

            </FlexMainWrapper>
        </>
    );
};

export default UserPieChart;




