import React, { useCallback, useEffect, useRef, useState } from 'react';

// communication_system
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// designComponents
import AddGatheringModal from '../../designComponents/modals/AddGatheringModal';
import AddGatheringChooseInterestModal from '../../designComponents/modals/AddGatheringChooseInterestModal';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { ImageModal } from '../../designComponents/modals/ImageModal';

/** 모임등록 페이지 */
const AddGathering = () => {
    const [createBoxToggle, setCreateBoxToggle] = useState(false);
    const [interestToggle, setInterestToggle] = useState(false);
    const [imageToggle, setImageToggle] = useState(false);

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [src, setSrc] = useState('');
    const [kategoryLists, setKategoryLists] = useState([]); // 선택된 모임 카테고리
    const [usersData, setUsersData] = useState([]); // 모임생성 - 선택된 유저 DATA
    const [param, setParam] = useState({});

    // useRef
    const currentTarget = useRef();
    const tableRef = useRef(null);

    // 클릭 감지
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    // 이미지 모달 외부 클릭 시 Modal창 닫힘
    const handleClickOutside = (event) => {
        if (currentTarget.current) {
            if (currentTarget && !currentTarget.current.contains(event.target)) {
                setImageToggle((prev) => !prev);
            }
        }
    };

    const callApi = useCallback(
        async (page, param) => {
            const input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.get(`/adminUser`, { params: input });
            return response.data.data;
        },
        [searchText, searchType],
    );

    const pageCountCallApi = useCallback(
        async (page, param) => {
            const input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.get(`/adminUser`, { params: input });
            return response.all_count;
        },
        [searchText, searchType],
    );

    return (
        <Wrapper02>
            <MainPageTitle text={createBoxToggle ? '모임등록(2)' : '모임장 선택(1)'} />
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    callApi();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />

            <FlexMainWrapper>
                <MainTable
                    tableStyle={{ width: '98%' }}
                    tableRef={tableRef}
                    loadingText={'모임장 리스트를 불러오고 있습니다.'}
                    toggleBlur={true}
                    headerData={[
                        { key: 'id', value: 'ID' },
                        { key: 'name', value: '이름' },
                        { key: 'nickname', value: '닉네임' },
                        { key: 'birthday', value: '생년월일' },
                        { key: 'phone_no', value: '휴대폰번호' },
                    ]}
                    checkID={'id'}
                    param={param}
                    setParam={setParam}
                    callApi={callApi}
                    pageCountCallApi={pageCountCallApi}
                    onClickRowItem={(item) => () => {
                        setCreateBoxToggle(Boolean(item));
                        setUsersData(item);
                    }}
                    tbodyRenderItem={(key, value) => {
                        return value;
                    }}
                />

                {createBoxToggle && (
                    <AddGatheringModal
                        setCreateBoxToggle={setCreateBoxToggle}
                        interestToggle={interestToggle}
                        setInterestToggle={setInterestToggle}
                        kategoryLists={kategoryLists}
                        setKategoryLists={setKategoryLists}
                        usersData={usersData}
                        setImageToggle={setImageToggle}
                        setSrc={setSrc}
                    />
                )}
                {interestToggle && (
                    <AddGatheringChooseInterestModal
                        setInterestToggle={setInterestToggle}
                        kategoryLists={kategoryLists}
                        setKategoryLists={setKategoryLists}
                    />
                )}
                {imageToggle && <ImageModal src={src} ref={currentTarget} onClickClose={() => setImageToggle((prev) => !prev)} />}
            </FlexMainWrapper>
        </Wrapper02>
    );
};

export default AddGathering;
