import React, { useCallback, useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// custom hooks
import useInput from '../../hook/useInput';

// components
import SearchModal from './SearchModal';

/** 모임결제정보 입력 */
function GatheringInfoComponent({ setParams }) {
    // state
    const [gatheringID, onChangeGatheringID, setGatheringID] = useInput(0);
    const [gatheringName, onChangeGatheringName, setGatheringName] = useInput('');
    const [contentsID, onChangeContentsID, setContentsID] = useInput(0);
    const [contentsName, onChangeContentsName, setContentsName] = useInput('');
    const [amount, setAmount] = useState(0);
    const [point, setPoint] = useState(0);

    // toggle-state
    const [searchGatheringToggle, setSearchGatheringToggle] = useState(false);
    const [type, setType] = useState('모임');

    // 입력값 확인
    useEffect(() => {
        let newParams = {
            title: String(contentsName),
            gathering_id: parseInt(gatheringID),
            contents_id: parseInt(contentsID),
            pay_amount: amount,
            pay_contents: 6,
            point: point,
        };
        setParams(newParams);
    }, [setParams, gatheringID, contentsID, amount, point, contentsName]);

    // 상춤가격 입력
    const onChangePayments = useCallback(
        (e) => {
            setAmount(parseInt(e.target.value));
        },
        [setAmount],
    );

    // 포인트 입력
    const onChangePoint = useCallback(
        (e) => {
            setPoint(parseInt(e.target.value));
        },
        [setPoint],
    );

    /** 모임검색 */
    const onSearchGatheringToggle = useCallback(
        (type) => () => {
            let _type = String(type);
            setSearchGatheringToggle(true);
            setType(_type);
        },
        [setSearchGatheringToggle, setType],
    );

    /** setState 객체 */
    const funcParam = {
        gatheringID: gatheringID,
        setGatheringID: setGatheringID,
        contentsID: contentsID,
        setContentsID: setContentsID,
        setAmount: setAmount,
        setContentsName: setContentsName,
        setGatheringName: setGatheringName,
    };

    return (
        <div>
            {searchGatheringToggle && <SearchModal type={type} setToggle={setSearchGatheringToggle} funcParam={funcParam} />}

            <ColWrapper className="pay-contents">
                <Subtitle>결제가격</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="결제가격 입력" onChange={onChangePayments} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>포인트</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="포인트 입력" onChange={onChangePoint} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle style={{ width: '116px' }}>모임ID</Subtitle>
                <SubInfo02>
                    <Input type="number" placeholder="모임ID 입력" onChange={onChangeGatheringID} value={gatheringID} />
                    <Button onClick={onSearchGatheringToggle('모임')}>검색</Button>
                </SubInfo02>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle style={{ width: '116px' }}>모임명</Subtitle>
                <SubInfo02>
                    <Input
                        type="text"
                        placeholder="모임명 입력"
                        onChange={onChangeGatheringName}
                        value={gatheringName}
                        readOnly
                    />
                </SubInfo02>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle style={{ width: '116px' }}>일정ID</Subtitle>
                <SubInfo02>
                    <Input type="number" placeholder="일정ID 입력" onChange={onChangeContentsID} value={contentsID} />
                    <Button onClick={onSearchGatheringToggle('일정')}>검색</Button>
                </SubInfo02>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle style={{ width: '116px' }}>일정명</Subtitle>
                <SubInfo02>
                    <Input type="text" placeholder="일정명 입력" onChange={onChangeContentsName} value={contentsName} readOnly />
                </SubInfo02>
            </ColWrapper>
        </div>
    );
}

export default GatheringInfoComponent;

/********************************* styled *********************************/

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 85px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 60px;
`;

const SubInfo02 = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;

    width: 100%;

    font-size: 13.5px;
    font-weight: 500;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const Button = styled.button`
    cursor: pointer;

    border: 1px solid #428bf7;
    background-color: #428bf7;
    color: #fff;

    padding: 3px 13px;

    :hover {
        background-color: #1067e7;
    }
`;
