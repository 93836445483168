import { BANK_CODE } from '../../components/SubscribePayment/payBankCodeService';
import { dateFilter, interestTextFilter } from '../../utils/textFilter';

/* eslint-disable array-callback-return */
const xlsx = require('xlsx');

// DAU, WAU, MAU excel
export const arrayToExcelMarketting = (header, body, fileName) => {
    const array = [header];

    body.map((obj, _) => {
        array.push([obj.login_date, obj['기존 접속회원'], obj['신규 접속회원']]);
    });

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

export const onClickExcelFormat = (header, body, fileName) => {
    const array = [header, ...body];

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

export const arrayToExcel = (header, body, fileName) => {
    const array = [];
    const keys = header.map((e) => e.key);

    array.push(header.map((e) => e.value));

    body.map((e) => {
        const tmp = [];

        keys.map((key) => {
            if (key === 'date_info' || key === 'login_date') {
                return tmp.push(e[key].slice(0, 10));
            }
            return tmp.push(Number(e[key] ?? 0));
        });
        array.push(tmp);
    });

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

// 배열을 재나열 하는 함수
function refreshArray(data) {
    let array = [];

    data.map((value) => {
        let arr = Object.values(value);
        let acc = Array(arr.length + 1);

        arr.map((cur, idx) => {
            if (typeof cur === 'string') {
                acc.unshift(cur);
                acc.pop();
            } else {
                acc[idx + 1] = cur;
            }
        });

        acc[1] = acc[2] + acc[3];
        array.push(acc);
    });

    return array;
}

// 고객데이터 객체 형식으로 넘겨 받는 함수
export const arrayToExcelForObj = (header, total, body, fileName) => {
    const array = [];

    const newHeader = Object.values(header);
    let topHeader = Array(6);
    let middleHeader = Array(6);

    newHeader.map((value) => {
        // top header
        if (value === '결혼상태') topHeader[0] = value;
        if (value === '성별') topHeader[2] = value;
        if (value === '비율') topHeader[4] = value;

        // middle header
        if (value === 'TOTAL') middleHeader[1] = value;
        if (value === '남') {
            middleHeader[2] = value;
            middleHeader[4] = value;
        }
        if (value === '여') {
            middleHeader[3] = value;
            middleHeader[5] = value;
        }
    });

    array.push(topHeader);
    array.push(middleHeader);
    array.push(...refreshArray(total));
    array.push(...refreshArray(body));

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

// 모임 계좌정보 필터
const gatheringBankInfoFilter = (bank_code) => {
    let bank = '';
    BANK_CODE.forEach((data) => {
        if (bank_code === data.CODE1) {
            bank = data.KOR;
        }
    });
    return bank;
};

/** 계좌사용내역 엑셀 출력 */
export const gatheringAccountUseListsExcel = (header, body, fileName) => {
    const array = [];
    const keys = header.map((e) => e.key);

    array.push(header.map((e) => e.value));

    body.map((e) => {
        let newArr = [];
        keys.map(async (key) => {
            if (key === 'account_bank') {
                e[key] = gatheringBankInfoFilter(e[key]);
            }
            return newArr.push(e[key] || '-');
        });
        array.push(newArr);
    });

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

export const gatheringListsExcel = (header, body, fileName) => {
    const array = [];
    const keys = header.map((e) => e.key);

    array.push(header.map((e) => e.value));

    body.map((e) => {
        let newArr = [];
        keys.map(async (key) => {
            if (key === 'interest_code') {
                e[key] = interestTextFilter(e[key]).join();
            } else if (key === 'created_at') {
                e[key] = dateFilter(e[key]);
            } else if (key === 'deleted_at') {
                e[key] = e[key] ? dateFilter(e[key]) : '-';
            }
            return newArr.push(e[key] || '-');
        });
        array.push(newArr);
    });
    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};
