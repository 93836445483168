export const gatheringListsFilter = [
    {
        key: 'id',
        value: '모임ID',
    },
    {
        key: 'purpose',
        value: '모임 구분',
        filter: [
            {
                key: 0,
                value: '친목(재밌게)',
            },
            {
                key: 1,
                value: '배움(알차게)',
            },
        ],
    },
    {
        key: 'exposure_yn',
        value: '검색 노출',
        filter: [
            {
                key: 1,
                value: 'ON',
            },
            {
                key: 0,
                value: 'OFF',
            },
        ],
    },
    {
        key: 'gathering_name',
        value: '모임명',
    },
    {
        key: 'interest_code',
        value: '관심주제',
        filter: [
            {
                key: true,
                value: '관심주제',
            },
        ],
    },
    {
        key: 'created_at',
        value: '개설일',
        filter: [
            {
                key: 1,
                value: '내림차순',
            },
            {
                key: 2,
                value: '오름차순',
            },
        ],
    },
    {
        key: 'deleted_at',
        value: '삭제 여부',
        filter: [
            {
                key: 1,
                value: 'ON',
            },
            {
                key: 2,
                value: 'OFF',
            },
        ],
    },
    {
        key: 'owner_name',
        value: '모임장명',
        filter: [
            {
                key: 0,
                value: '탈퇴',
            },
        ],
    },
    {
        key: 'owner_nick_name',
        value: '모임장 닉네임',
    },
    {
        key: 'owner_phone_no',
        value: '모임장 연락처',
    },
    {
        key: 'owner_photo',
        value: '모임장 사진',
    },
    {
        key: 'gathering_image',
        value: '모임사진',
    },
    {
        key: 'address',
        value: '활동지역',
    },
    {
        key: 'date_cnt',
        value: '일정수',
    },
    {
        key: 'crew_cnt',
        value: '가입 회원수',
    },
    {
        key: 'wish_cnt',
        value: '찜한 회원수',
    },
];
