import styled from 'styled-components';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { MText } from '../text/ContentText';
import LoadingPage from '../../pages/loading/LoadingPage';
import Table from 'react-bootstrap/Table';
const DesignTable = ({
    marginTop = 1,
    header = [],
    addData = [],
    data = [],
    onClick = () => {},
    addBody = [],
    body = [],
    onToggle = false,
    modalComponent,
    allCount,
    page,
    setPage,
    bodyRederItem = null,
    noneHieght = 40,
    wrapper,
    tableWrapper,
    rowTextStyle,
}) => {
    return (
        <TextWrapper style={{ marginTop: `${marginTop}%`, ...wrapper, overflow: 'auto' }}>
            {onToggle && modalComponent}
            <Table style={{ height: '100%', whiteSpace: 'nowrap' }} hover={true}>
                <thead style={{ backgroundColor: 'rgb(218 218 218)', ...tableWrapper }}>
                    <tr>
                        {header.map((item, index) => (
                            <th key={index} style={{ flex: item.width, borderLeft: index === 0 ? 0 : '', ...rowTextStyle }}>
                                {item.title}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={'body'}>
                    {data.map((item, index) => {
                        const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;
                        return (
                            <tr key={index} onClick={() => onClick(item, header)} style={{ ...tableWrapper }}>
                                {body.map((value, index) => (
                                    <td key={index} style={{ flex: value.width, borderLeft: index === 0 ? 0 : '', ...rowTextStyle }}>
                                        {bodyRederItem ? bodyRederItem(value.key, item[value.key], item) : item[value.key] || nullTag}
                                        {value.key02 ? '/' + item[value.key02] : null}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {parseInt(allCount) > 9 ? (
                <Pagination totalPages={allCount ? Math.round(allCount / 10) + 1 : 10} limit={10} page={page} setPage={setPage} wrapperStyle={{ margin: '10px 0 0 0' }} />
            ) : (
                <div style={{ marginBottom: '7%' }} />
            )}
        </TextWrapper>
    );
};

export default DesignTable;

const TextWrapper = styled.div`
    align-items: center;
    font-size: 13px;
    padding: 0 2px;
`;
