import styled from 'styled-components';

export const ReverseExcelButtonSt = styled.button`
    cursor: pointer;
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};

    border: 1px solid #167848;
    background-color: #fff;
    border-radius: 5px;

    :hover {
        background-color: #f5f4f4;
    }
`;

export const ReverseExcelFont = styled.span`
    color: ${(props) => `${props.color}`};
    font-size: 13px;
`;

export const ExcelButtonSt = styled.button`
    cursor: pointer;
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};

    border: 0;
    background-color: #167848;
    border-radius: 5px;

    :hover {
        background-color: #13623b;
    }
`;

export const ButtonSt = styled.button`
    cursor: pointer;
    padding-block: 4px;

    border: 0;
    background-color: rgb(66, 139, 247);
    border-radius: 5px;

    :hover {
        background-color: rgb(5 101 242);
    }
`;

export const Font = styled.span`
    color: ${(props) => `${props.color}`};
    font-size: 13px;
`;

export const ButtonStReverse = styled.button`
    cursor: pointer;
    padding-block: 4px;

    border: 1px solid rgb(66, 139, 247);
    background-color: #fff;
    border-radius: 5px;

    :hover {
        background-color: #f5f4f4;
    }
`;

export const FontReverse = styled.span`
    color: ${(props) => `${props.color}`};
    font-size: 13px;
`;

export const ButtonSt02 = styled.button`
    cursor: pointer;
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};

    border: 0;
    background-color: rgb(66, 139, 247);
    border-radius: 5px;

    :hover {
        background-color: rgb(5 101 242);
    }
`;

export const Font02 = styled.span`
    color: ${(props) => `${props.color}`};
    font-weight: 600;
    font-size: 14px;
`;

export const ButtonStReverse02 = styled.button`
    cursor: pointer;
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};

    border: 1px solid rgb(66, 139, 247);
    background-color: #fff;
    border-radius: 5px;

    :hover {
        background-color: #f5f4f4;
    }
`;

export const FontReverse02 = styled.span`
    color: ${(props) => `${props.color}`};
    font-weight: 600;
    font-size: 14px;
`;

export const Button = styled.div`
    cursor: pointer;
    position: relative;
`;

export const RedDot = styled.div`
    position: absolute;
    bottom: 3px;
    right: 2px;
    background-color: red;
    border-radius: 50%;
    width: 10px;
    height: 10px;
`;
