import axios from 'axios';
import { marketting_main_server_ip } from '../../communication_ips';
import { refresh_check, refresh_error_handle } from './refresh_check';

const MainRefreshCheckApi = axios.create({
    baseURL: marketting_main_server_ip,
    timeout: 20000,
    params: {},
});

MainRefreshCheckApi.interceptors.request.use(refresh_check, refresh_error_handle);

export default MainRefreshCheckApi;
