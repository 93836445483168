export const orderTableFilter = [
    {
        key: 'payment_created_at',
        value: '결제일시',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'pay_status_number',
        value: '주문상태',
        filter: [
            {
                key: 0,
                value: '결제 대기중',
            },
            {
                key: 1,
                value: '입금 대기중',
            },
            {
                key: 2,
                value: '결제 완료',
            },
            {
                key: 3,
                value: '환불 신청',
            },
            {
                key: 4,
                value: '환불 완료',
            },
            {
                key: 5,
                value: '환불 거절',
            },
            {
                key: 6,
                value: '입금 기한 만료',
            },
        ],
    },
    {
        key: 'order_status_number',
        value: '배송상태',
        filter: [
            {
                key: 0,
                value: '상품 준비중',
            },
            {
                key: 1,
                value: '배송 중',
            },
            {
                key: 2,
                value: '배송 완료',
            },
            {
                key: 3,
                value: '구매 확정',
            },
            {
                key: 4,
                value: '반품 신청',
            },
            {
                key: 5,
                value: '반품 완료',
            },
        ],
    },
    {
        key: 'order_number',
        value: '주문번호',
    },
    {
        key: 'user_name',
        value: '회원명',
    },
    {
        key: 'user_phone_no',
        value: '휴대폰 번호',
    },
    {
        key: 'product_id',
        value: '상품ID',
    },
    {
        key: 'product_name',
        value: '상품명',
    },
    {
        key: 'order_count',
        value: '수량',
    },
    {
        key: 'host_name',
        value: '판매처',
    },
    {
        key: 'address',
        value: '배송주소',
    },
    {
        key: 'discount_price',
        value: '구매금액',
    },
    {
        key: 'pay_amount',
        value: '실 결제금액',
    },
    {
        key: 'pay_type_number',
        value: '결제수단',
        filter: [
            {
                key: 0,
                value: '신용카드',
            },
            {
                key: 1,
                value: '가상계좌',
            },
        ],
    },
];
