import React, { useState, useEffect } from 'react';

// styled-components
import { PhotoBox, ImgeSetupBtn, ImgCloseOutlined, DemOuter, DragImage } from '../../design_system/components/modal/NewUserModalStyled';
import { LoadingOutlined } from '@ant-design/icons';

// Drag and Drop
import { Motion, spring } from 'react-motion';
import range from 'lodash.range';

// server ip
import { main_server_image_ip } from '../../communication_system/communication_ips';

const ModalDraggableBallHook = ({ data, profileList, setProfileList, onChangeRow }) => {
    // state
    const pic = [...profileList];

    const [mouseXY, setMouseXY] = useState([0, 0]);
    const [mouseCircleDelta, setMouseCircleDelta] = useState([0, 0]);
    const [lastPress, setLastPress] = useState(null);
    const [isPressed, setIsPressed] = useState(false);
    const [order, setOrder] = useState(range(Number(data?.length)));

    // global funtion from this line
    const springSetting1 = { stiffness: 180, damping: 10 };
    const springSetting2 = { stiffness: 120, damping: 17 };

    // 움직이는 범위의 width와 heigh를 여기서 정한다.
    // const [count, width, height] = [data?.length, 153, 153];
    const [count, width, height] = [data?.length, 113, 113];

    // 여기서 사진의 위치를 바꾼다.
    function reinsert(arr, from, to) {
        const _arr = arr.slice(0);
        const val = _arr[from];

        _arr.splice(from, 1);
        _arr.splice(to, 0, val);

        return _arr;
    }

    function reinsert2(pic, from, to) {
        const _pic = pic.slice(0);
        const val = _pic[from];

        _pic.splice(from, 1); // 가져다 놓은 위치로 바꿈
        _pic.splice(_pic.indexOf(to), 0, val); // 가져다 놓은 위치로 바꿈

        return _pic;
    }

    function clamp(n, min, max) {
        return Math.max(Math.min(n, max), min);
    }

    // max-width가 912px일 때 3 -> 2칸으로 줄인다.
    const layout = range(count).map((n) => {
        const row = Math.floor(n / 3);
        const col = n % 3;

        return [width * col, height * row];
    });

    /******************************
     * Funtion start from this line
     ******************************/
    const handleMouseDown = (key, [pressX, pressY], { pageX, pageY }) => {
        setLastPress(key);
        setIsPressed(true);
        setMouseCircleDelta([pageX - pressX, pageY - pressY]);
        setMouseXY([pressX, pressY]);
    };

    const handleTouchStart = (key, pressLocation, e) => {
        handleMouseDown(key, pressLocation, e.touches[0]);
    };

    /**********************************
     * useEffect start from this line
     **********************************/
    useEffect(() => {
        // componentDidUpdate
        const handleMouseMove = ({ pageX, pageY }) => {
            if (isPressed) {
                const mouseXY = [pageX - mouseCircleDelta[0], pageY - mouseCircleDelta[1]];
                const col = clamp(Math.floor(mouseXY[0] / width), 0, 2);
                const row = clamp(Math.floor(mouseXY[1] / height), 0, Math.floor(count / 3));
                const index = row * 3 + col;

                let newOrder = reinsert(order, order.indexOf(lastPress), index);
                let newOrder2 = reinsert2(pic, pic.indexOf(pic[lastPress]), pic[index]);

                onChangeRow(newOrder2);

                setMouseXY(mouseXY);
                // setOrder(newOrder);
                setOrder(newOrder);
            }
        };

        const handleTouchMove = (e) => {
            handleMouseMove(e.touches[0]);
        };

        const handleMouseUp = () => {
            setIsPressed(false);
            setMouseCircleDelta([0, 0]);
        };

        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleMouseUp);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        // componentWillUnmount
        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleMouseUp);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [height, width, lastPress, mouseCircleDelta, order, count, isPressed, mouseXY]);

    // 기본 이미지로 변경
    const onSetDefaultClick = (key) => () => {
        const tmp = [...data];
        const idx = tmp.findIndex((e) => e === data[key]);

        tmp[idx] = 'userPhoto/default.jpg';
        setProfileList(tmp);

        alert('기본이미지로 변경 완료. 저장을 누르시면 저장됩니다.');
    };

    // 삭제할 이미지 1장일 경우 기본이미지로 초기화
    const setDefaultClick = (key) => {
        const tmp = [...data];
        const idx = tmp.findIndex((e) => e === data[key]);

        tmp[idx] = 'userPhoto/default.jpg';
        setProfileList(tmp);

        alert('삭제 완료 후 기본이미지로 변경 완료. 저장을 누르시면 저장됩니다.');
    };

    // 프로필 사진 삭제
    const removeProfile = (item, key) => () => {
        if (profileList?.length === 1) {
            setDefaultClick(key);
        } else {
            setProfileList(profileList.filter((e) => e !== item));
            alert('사진 삭제 완료. 저장을 누르시면 저장됩니다.');
        }
    };

    // 이미지 onError
    const handleImgError = (e) => {
        e.target.src = `/images/noneImage.png`;
    };

    return (
        <DemOuter className="demo2-outer" height={Number(data?.length)}>
            <div className="demo2">
                {order.map((_, key) => {
                    // 매개변수 _(언더바)의 뜻은 해당 매개변수가 중요하지 않다. 라는 뜻 [있어도 그만 없어도 그만]
                    let style;
                    let x;
                    let y;
                    const visualPosition = order.indexOf(key);

                    if (key === lastPress && isPressed) {
                        [x, y] = mouseXY;
                        style = {
                            translateX: x,
                            translateY: y,
                            scale: spring(1.2, springSetting1),
                        };
                    } else {
                        [x, y] = layout[visualPosition];
                        style = {
                            translateX: spring(x, springSetting2),
                            translateY: spring(y, springSetting2),
                            scale: spring(1, springSetting1),
                        };
                    }

                    return (
                        <Motion key={key} style={style}>
                            {({ translateX, translateY, scale, boxShadow }) => {
                                if (data) {
                                    return (
                                        <>
                                            <PhotoBox
                                                className="profile-photo-box"
                                                onMouseDown={handleMouseDown.bind(null, key, [x, y])}
                                                onTouchStart={handleTouchStart.bind(null, key, [x, y])}
                                                style={{
                                                    display: 'block',
                                                    WebkitTransform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
                                                    transform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
                                                }}
                                            >
                                                <div>
                                                    <ImgeSetupBtn>
                                                        <label id="label">기본이미지 변경</label>
                                                        <div className="setup" onClick={onSetDefaultClick(key)}>
                                                            Set Default
                                                        </div>

                                                        <label id="label">이미지 삭제</label>
                                                        <ImgCloseOutlined className="delete" onClick={removeProfile(data[key], key)} />
                                                    </ImgeSetupBtn>
                                                </div>
                                                {data?.length > 0 ? (
                                                    <DragImage
                                                        style={{
                                                            zIndex: key === lastPress ? 99 : visualPosition,
                                                            boxShadow: `${boxShadow}px 5px 5px rgba(0,0,0,0.5)`,
                                                        }}
                                                        className="demo2-ball"
                                                        src={data[key]?.includes('https://k.kakaocdn.net') ? data[key] : main_server_image_ip + data[key]}
                                                        alt="이미지"
                                                        onError={handleImgError}
                                                    />
                                                ) : (
                                                    <LoadingOutlined />
                                                )}
                                            </PhotoBox>
                                        </>
                                    );
                                } else {
                                }
                            }}
                        </Motion>
                    );
                })}
            </div>
        </DemOuter>
    );
};

export default ModalDraggableBallHook;
