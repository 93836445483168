import React, { useState, useEffect } from 'react';

// styled-components
import { PlusOutlined } from '@ant-design/icons';
import {
    RowLine,
    DetailModalBox,
    CloseBtnWrapper,
    DetailInfoWrapper,
    CustomLeftOutlined,
    TitleInput,
    Title,
    SubTitle,
    ScheduleInfoWrapper,
    AddButtonWrapper,
    AddButton,
    ButtonWeapper,
} from '../styles/ActivityDetailModalStyled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updateActivity } from '../../data_system/redux/modules/activities_reducer/activity.reducer';

// components
import ActivityDetailModalInfoEntry from './ActivityDetailModalInfoEntry';
import ActivityDetailModalActivityInfoEntry from './ActivityDetailModalActivityInfoEntry';
import ActivityDetailModalScheduleEntry from './ActivityDetailModalScheduleEntry';

// 놀이 상세 조회 modal component
function ActivityDetailModal({ headerHeight, height, setDetailModalToggle }) {
    const dispatch = useDispatch();
    // useSelector
    const { activityDetail, loadActivityUpdateLoading } = useSelector((state) => state.activityReducer);
    // state
    const [toggleAddSchedule, setToggleAddSchedule] = useState(false);
    // 기본정보
    const [title, setTitle] = useState('');
    const [infoParam, setInfoParam] = useState({});
    const [infoDetailParam, setInfoDetailParam] = useState({});

    const [params, setParams] = useState({});

    /******************** useEffect ********************/

    // 데이터 상태 적용 (title)
    useEffect(() => {
        setTitle(activityDetail?.title);
    }, [activityDetail]);

    // 데이터 상태 적용
    useEffect(() => {
        setParams({
            activity_id: activityDetail?.activity_id, // 놀이 id
            title: title, // 놀이 제목
            exposure_yn: infoParam.exposure_yn, // 노출여부
            crawling_yn: infoParam.crawling_yn, // 크롤링 여부
            detail_image_list: infoParam.detail_image_list, // 썸네일 리스트
            category: activityDetail?.category, // 카테고리(고정값)
            host_name: activityDetail?.host_name, // 호스트(고정값)

            routin_yn: infoDetailParam?.routin_yn, // 구분(정기, 단기)
            date_count: infoDetailParam?.date_count, // 총 횟수
            price: infoDetailParam?.price, // 가격
            charge: infoDetailParam?.charge, // 할인율
            visit_yn: infoDetailParam?.visit_yn, // 방문 서비스
            address: infoDetailParam?.address, // 주소
            explanation: infoDetailParam?.explanation, // 상세설명
            notice: infoDetailParam?.notice, // 유의사항
            material: infoDetailParam?.material, // 준비물
            refund: infoDetailParam?.refund, // 환불정책

            caution: activityDetail?.caution, // 주의
            date_list: activityDetail?.date_list, // 일수
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setParams, title, infoParam, infoDetailParam]);

    useEffect(() => {
        if (loadActivityUpdateLoading) {
            closeModal();
        }
    });

    /******************** function ********************/

    // 일정 추가
    const onClickAddScheduleBtn = () => {
        setToggleAddSchedule((prev) => !prev);
    };

    // 놀이명 변경
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    // Modal 창 닫기
    const closeModal = () => {
        setDetailModalToggle(false);
    };

    // update 요청 함수
    const updateActivityData = () => {
        const param = {
            activity_id: activityDetail?.activity_id, // 놀이 id
            host: activityDetail?.host_id, // 놀이 id

            title: title, // 놀이 제목
            exposure_yn: infoParam.exposure_yn, // 노출여부
            crawling_yn: infoParam.crawling_yn, // 크롤링 여부
            detail_image_list: infoParam.detail_image_list, // 썸네일 리스트
            category: activityDetail?.category, // 카테고리(고정값)
            host_name: activityDetail?.host_name, // 호스트(고정값)

            routin_yn: infoDetailParam?.routin_yn, // 구분(정기, 단기)
            routin_count: Number(infoDetailParam?.date_count), // 총 횟수
            price: infoDetailParam?.price, // 가격
            discount_rating: Number(infoDetailParam?.charge), // 할인율
            visit_yn: infoDetailParam?.visit_yn, // 방문 서비스
            address: infoDetailParam?.address, // 주소
            explanation: infoDetailParam?.explanation, // 상세설명
            notice: infoDetailParam?.notice, // 유의사항
            material: [infoDetailParam?.material], // 준비물
            refund: infoDetailParam?.refund, // 환불정책

            caution: activityDetail?.caution, // 주의
            date_list: activityDetail?.date_list, // 일수
        };
        dispatch(updateActivity(param));
    };

    /******************** html ********************/
    return (
        <>
            {toggleAddSchedule ? <ActivityDetailModalScheduleEntry setToggleAddSchedule={setToggleAddSchedule} /> : null}

            <DetailModalBox>
                <CloseBtnWrapper headerHeight={headerHeight}>
                    <CustomLeftOutlined onClick={closeModal} />
                    <div>상세조회</div>
                </CloseBtnWrapper>

                <DetailInfoWrapper height={height}>
                    <TitleInput type="text" value={title} onChange={onChangeTitle} />

                    <div id="information">
                        <Title>기본정보</Title>
                        <ActivityDetailModalInfoEntry activityDetail={activityDetail} setInfoParam={setInfoParam} />
                    </div>

                    <RowLine />

                    <div id="activity-information">
                        <Title>놀이정보</Title>
                        <ActivityDetailModalActivityInfoEntry activityDetail={activityDetail} setInfoDetailParam={setInfoDetailParam} />
                    </div>

                    <RowLine />

                    <div id="schedule">
                        <ScheduleInfoWrapper>
                            <SubTitle>일정</SubTitle>

                            <AddButtonWrapper onClick={onClickAddScheduleBtn}>
                                <PlusOutlined />
                                <AddButton>추가</AddButton>
                            </AddButtonWrapper>
                        </ScheduleInfoWrapper>
                    </div>

                    <ButtonWeapper>
                        <button className="cencle" onClick={closeModal}>
                            취소
                        </button>
                        <button className="save" onClick={updateActivityData}>
                            저장
                        </button>
                    </ButtonWeapper>
                </DetailInfoWrapper>
            </DetailModalBox>
        </>
    );
}

export default ActivityDetailModal;
