export const GET_EVENTS_DETAIL = "admin/GET_EVENTS_DETAIL";
export const GET_EVENTS_DETAIL_SUCCESS = "admin/GET_EVENTS_DETAIL_SUCCESS";
export const GET_EVENTS_DETAIL_FAILURE = "admin/GET_EVENTS_DETAIL_FAILURE";
export const POST_EVENTS_DETAIL = "admin/POST_EVENTS_DETAIL";
export const POST_EVENTS_DETAIL_SUCCESS = "admin/POST_EVENTS_DETAIL_SUCCESS";
export const POST_EVENTS_DETAIL_FAILURE = "admin/POST_EVENTS_DETAIL_FAILURE";
export const PUT_EVENTS_ORDER = "admin/PUT_EVENTS_ORDER";
export const PUT_EVENTS_DETAIL = "admin/PUT_EVENTS_DETAIL";
export const PUT_EVENTS_DETAIL_SUCCESS = "admin/PUT_EVENTS_DETAIL_SUCCESS";
export const PUT_EVENTS_DETAIL_FAILURE="admin/PUT_EVENTS_DETAIL_FAILURE";

