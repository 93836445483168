import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ArrowUpOutlined, CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { MainModalBtutton } from '../buttons/Buttons';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { InputLine } from '../inputs/InputLine';

/** 사이드 일반 모달 */
export const ModalComponent = ({
    windowStyle,
    boxStyle,
    headerStyle,
    bodyStyle,
    icon,
    toggle = false,
    header,
    body,
    bottomBtnOn = true,
    cancelText = '취소',
    saveText = '저장',
    closeModal = () => {},
    onClickSave = () => {},
}) => {
    return (
        toggle && (
            <ModalWindow style={{ ...windowStyle }}>
                <ModalBox style={{ ...boxStyle }}>
                    <Header style={{ ...headerStyle }}>
                        {icon || <LeftOutlined onClick={closeModal} style={{ color: '#FFF', fontWeight: 600 }} />}
                        <HeaderText>{header}</HeaderText>
                    </Header>
                    <Body style={{ ...bodyStyle }}>{body}</Body>
                    {bottomBtnOn && (
                        <BottomButtonLine>
                            <MainModalBtutton text={cancelText} styleReverse={true} onClick={closeModal} />
                            <MainModalBtutton text={saveText} onClick={onClickSave} />
                        </BottomButtonLine>
                    )}
                </ModalBox>
            </ModalWindow>
        )
    );
};

/** 기본 모달창 */
export const DefaultModal = ({
    children,
    title = '윈도우 모달창',
    windowStyle,
    boxStyle,
    onClickClose,
    xIcon,
    visible = true,
    bottomBtnOn = true,
    cancelText = '취소',
    middleText = '중간',
    saveText = '저장',
    onClickBottomClose = () => {},
    onClickMiddle = () => {},
    onClickSave = () => {},
    closeOn = false,
    middleOn = false,
    saveOn = false,
    bodyWidth = 53,
    modalRef,
}) => {
    return (
        <DefaultModalModalWindow style={{ ...windowStyle, display: visible ? '' : 'none' }}>
            {xIcon && <CustomCloseOutlined onClick={onClickClose} />}
            <ModalBox02 style={{ ...boxStyle }} ref={modalRef}>
                <BoxHeader id="top-line">
                    <Text>{title}</Text>
                    <Text style={{ cursor: 'pointer' }} onClick={onClickClose}>
                        닫기
                    </Text>
                </BoxHeader>
                <div style={{ height: `${bodyWidth}vh`, overflow: 'auto' }}>{children}</div>
                {bottomBtnOn && (
                    <BottomButtonLine>
                        {closeOn && <MainModalBtutton text={cancelText} styleReverse={true} onClick={onClickBottomClose} />}
                        {middleOn && <MainModalBtutton text={middleText} style={{}} onClick={onClickMiddle} />}
                        {saveOn && <MainModalBtutton text={saveText} onClick={onClickSave} />}
                    </BottomButtonLine>
                )}
            </ModalBox02>
        </DefaultModalModalWindow>
    );
};

export const ImageModal = ({ children, windowStyle, onClickClose, xIcon, visible = true }) => {
    return (
        <DefaultModalModalWindow style={{ ...windowStyle, display: visible ? '' : 'none' }}>
            {xIcon && <CustomCloseOutlined onClick={onClickClose} />}
            {children}
        </DefaultModalModalWindow>
    );
};

/** 모달창 바디 */
export const ModalBody = ({ modalComponents, wrapperStyle, leftStyle, leftComponents, rightStyle, rightComponents }) => {
    const defaultStyle = { width: '100%', justifyContent: 'start', padding: '2%', flexDirection: 'column' };
    return (
        <FlexMainWrapper style={{ width: '100%', alignItems: 'start', justifyContent: 'center', gap: 0, ...wrapperStyle }}>
            {modalComponents}
            <FlexMainWrapper style={{ ...defaultStyle, ...leftStyle }}>{leftComponents}</FlexMainWrapper>
            <FlexMainWrapper style={{ ...defaultStyle, ...rightStyle }}>{rightComponents}</FlexMainWrapper>
        </FlexMainWrapper>
    );
};

/** 채팅창 모달 */
export const ChatModalComponent = ({
    windowStyle,
    boxStyle,
    headerStyle,
    bodyStyle,
    icon,
    toggle = false,
    header,
    body,
    inputValue,
    closeModal = () => {},
    onChange = () => {},
    onSubmit = () => {},
}) => {
    return (
        toggle && (
            <ModalWindow style={{ ...windowStyle }}>
                <ModalBox style={{ borderRadius: '8px', backgroundColor: '#c4d5e2', justifyContent: 'start', ...boxStyle }}>
                    <Header style={{ backgroundColor: 'rgb(211 220 255)', justifyContent: 'space-between', ...headerStyle }}>
                        <HeaderText>{header}</HeaderText>
                        {icon || <CloseOutlined onClick={closeModal} style={{ fontSize: '22px', color: '#FFF', fontWeight: 600 }} />}
                    </Header>
                    <ChatBody style={{ ...bodyStyle }}>{body}</ChatBody>
                    <ChatInputBox>
                        <ChatInputWrapper type="submit" onSubmit={onSubmit}>
                            <InputLine
                                wrapperStyle={{ width: '100%' }}
                                inputStyle={{ borderWidth: 0, height: '25px', fontSize: '15px', fontWeight: 500 }}
                                placeholder={'관리자 메세지를 입력해주세요.'}
                                value={inputValue}
                                onChange={(e) => onChange(e)}
                            />
                            <SendButton type="submit">
                                <ArrowUpOutlined style={{ cursor: 'pointer', color: '#FFF' }} />
                            </SendButton>
                        </ChatInputWrapper>
                    </ChatInputBox>
                </ModalBox>
            </ModalWindow>
        )
    );
};

const ChatBody = styled.div`
    height: 57vh;
    padding: 12px 17px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-width: 0;
    overflow: auto;
    padding-bottom: 20%;
    @media (max-width: 912px) {
        height: 75%;
    }
`;

const ChatInputBox = styled.div`
    background-color: #c4d5e2;
    width: 100%;
    height: 7vh;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

const ChatInputWrapper = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;

    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;

    padding: 0px 10px;
    margin: 10px auto;
    background-color: #fff;
    border-radius: 10px;
    width: 85%;
`;

const SendButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0000007d;
    border-radius: 50%;
    border: 0px;
    width: 35px;
    height: 35px;
`;

const BoxHeader = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: rgb(66, 139, 247);
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    height: 5vh;
    align-items: center;
    @media (max-width: 1120px) {
        width: 100%;
    }
`;

const Text = styled.div`
    padding: 0 15px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 45px;
`;

const DefaultModalModalWindow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    background-color: #000000c9;
    text-align: center;

    width: 100%;
    height: 100vh;
`;

const CustomCloseOutlined = styled(CloseOutlined)`
    position: fixed;
    top: 0;
    right: 0;
    padding: 30px;
    color: #fff;
    font-size: 35px;
    font-weight: bold;

    @media (max-width: 912px) {
        display: none;
    }
`;

const ModalWindow = styled.div`
    width: 60vh;
    @media (max-width: 912px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
`;

const ModalBox = styled.div`
    position: relative;
    background-color: #fff;

    @media (max-width: 912px) {
        height: 100vh;
    }
`;

const ModalBox02 = styled.div`
    position: relative;
    background-color: #fff;
    width: 70%;
    height: 65vh;
    border-radius: 10px;
    @media (max-width: 912px) {
        width: 98%;
        height: 85vh;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding: 12px 10px;
    background-color: rgb(66, 139, 247);
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
`;

const HeaderText = styled.div`
    color: #fff;
    font-weight: 600;
`;

const Body = styled.div`
    height: 57vh;
    padding: 12px 17px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-width: 0;
    overflow: auto;
    padding-bottom: 20%;
    @media (max-width: 912px) {
        height: 80%;
        width: 94%;
    }
`;

const BottomButtonLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;

    padding: 7px 0;
    background-color: #fff;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-width: 0;
`;
