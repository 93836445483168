import React from 'react';
import styled from 'styled-components';

const NewRadioBtn = ({ type, onClick, checked }) => {
    return <Input type={type} onClick={onClick} checked={checked} readOnly />;
};

export default React.memo(NewRadioBtn);

const Input = styled.input`
    cursor: pointer;
    margin: 0 3px 0 0;
    width: 15px;
    height: 15px;
`;
