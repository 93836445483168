// 테이블 필터리스트
export const ProductListsFilter = [
    {
        key: 'id',
        value: 'ID',
    },
    {
        key: 'product_thumbnail',
        value: '상품이미지',
    },
    {
        key: 'name',
        value: '상품명',
    },
    {
        key: 'promotional_title',
        value: '상품제목',
    },
    {
        key: 'list_price',
        value: '정가',
        filter: [
            {
                key: 1,
                value: '10,000~20,000',
            },
            {
                key: 2,
                value: '20,000~30,000',
            },
            {
                key: 3,
                value: '30,000~40,000',
            },
            {
                key: 4,
                value: '40,000~50,000',
            },
            {
                key: 5,
                value: '50,000만원 이상',
            },
            {
                key: 6,
                value: '100,000만원 이상',
            },
        ],
    },
    {
        key: 'discount_price',
        value: '할인가',
        filter: [
            {
                key: 1,
                value: '10,000~20,000',
            },
            {
                key: 2,
                value: '20,000~30,000',
            },
            {
                key: 3,
                value: '30,000~40,000',
            },
            {
                key: 4,
                value: '40,000~50,000',
            },
            {
                key: 5,
                value: '50,000만원 이상',
            },
            {
                key: 6,
                value: '100,000만원 이상',
            },
        ],
    },
    {
        key: 'host_name',
        value: '판매처',
    },
    {
        key: 'sales_yn',
        value: '판매상태',
        filter: [
            {
                key: 1,
                value: '판매중',
            },
            {
                key: 2,
                value: '매진',
            },
            {
                key: 3,
                value: '품절',
            },
            {
                key: 4,
                value: '판매종료',
            },
            {
                key: 5,
                value: '판매금지',
            },
            {
                key: 6,
                value: '판매예정',
            },
        ],
    },
    {
        key: 'avail_yn',
        value: '노출여부',
        filter: [
            {
                key: 1,
                value: '노출함',
            },
            {
                key: 2,
                value: '노출안함',
            },
        ],
    },
    {
        key: 'option_yn',
        value: '옵션여부',
        filter: [
            {
                key: 1,
                value: 'Y',
            },
            {
                key: 0,
                value: 'N',
            },
        ],
    },
    {
        key: 'inventory_cnt',
        value: '재고',
        filter: [
            {
                key: 1,
                value: '있음',
            },
            {
                key: 0,
                value: '없음',
            },
        ],
    },
    {
        key: 'created_at',
        value: '등록일',
        filter: [
            {
                key: '1',
                value: '최신순',
            },
            {
                key: '2',
                value: '오래된 순',
            },
        ],
    },
    {
        key: 'updated_at',
        value: '수정일',
        filter: [
            {
                key: '1',
                value: '최신순',
            },
            {
                key: '2',
                value: '오래된 순',
            },
        ],
    },
];

// 상품리스트 목데이터
export const ProductListsMockData = [
    {
        id: 3347, // id
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg', // 상품썸네일
        product_name: '여름티셔츠', // 상품명칭
        product_title: '체리블라썸 신상', // 상품제목
        product_price: 57000, // 정가
        product_sale: 51300, // 할인가
        host: '(주)신명나는옷자락쓰', // 호스트
        seling_status: '판매중', // 판매여부
        option_yn: 1, // 옵션여부
        stock: '34', // 재고
        created_at: '2023-05-07 12:53:46', // 등록일
        updated_at: '2023-06-07 17:12:03', // 수정일
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
    {
        id: 3347,
        product_thumbnail: 'https://thumbnail6.coupangcdn.com/thumbnails/remote/492x492ex/image/vendor_inventory/20e3/8a62a820d2c42b06ec8a579bac87d7826a8003ebc235f3ab163ece6acaf5.jpg',
        product_name: '여름티셔츠',
        product_title: '체리블라썸 신상',
        product_price: 57000,
        product_sale: 51300,
        host: '(주)신명나는옷자락쓰',
        seling_status: '판매중',
        option_yn: 1,
        stock: '34',
        created_at: '2023-05-07 12:53:46',
        updated_at: '2023-06-07 17:12:03',
    },
];
