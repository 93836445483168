export const SmsPageUserListsFilter = [
    {
        key: 'phone_no',
        value: '핸드폰 번호',
        filter: [
        ],
    },
    {
        key: 'user_created_at',
        value: '가입 일자',
    },
    {
        key: 'name',
        value: '이름',
    },
    {
        key: 'nick_name',
        value: '시럽닉네임/시놀닉네임',
    },
    {
        key: 'sex',
        value: '성별',
        filter: [
        ],
    },
    {
        key: 'age',
        value: '연령',
    },
    {
        key: 'marketing_info_receive_agree_yn',
        value: '마케팅 동의 여부',
        filter: []
    },
];
