import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import MainRefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/main_refresh_check_api';

/************************ action을 정의 ************************/

// 회원 데이터 비교 현황
const GET_COMPARE_USER_DATA_REQUEST = 'GET_COMPARE_USER_DATA_REQUEST';
const GET_COMPARE_USER_DATA_SUCCESS = 'GET_COMPARE_USER_DATA_SUCCESS';
const GET_COMPARE_USER_DATA_FAILURE = 'GET_COMPARE_USER_DATA_FAILURE';
// 회원현황 그래프 데이터
const GET_USER_GRAGH_DATA_REQUEST = 'GET_USER_GRAGH_DATA_REQUEST';
const GET_USER_GRAGH_DATA_SUCCESS = 'GET_USER_GRAGH_DATA_SUCCESS';
const GET_USER_GRAGH_DATA_FAILURE = 'GET_USER_GRAGH_DATA_FAILURE';
// 회원 라인 차트 데이터
const GET_USER_LINECHART_DATA_REQUEST = 'GET_USER_LINECHART_DATA_REQUEST';
const GET_USER_LINECHART_DATA_SUCCESS = 'GET_USER_LINECHART_DATA_SUCCESS';
const GET_USER_LINECHART_DATA_FAILURE = 'GET_USER_LINECHART_DATA_FAILURE';
// 회원활동
const GET_USERS_ACTIVITY_DATA_REQUEST = 'GET_USERS_ACTIVITY_DATA_REQUEST';
const GET_USERS_ACTIVITY_DATA_SUCCESS = 'GET_USERS_ACTIVITY_DATA_SUCCESS';
const GET_USERS_ACTIVITY_DATA_FAILURE = 'GET_USERS_ACTIVITY_DATA_FAILURE';
// 회원활동 Excel
const GET_USERS_ACTIVITY_EXCEL_DATA_REQUEST = 'GET_USERS_ACTIVITY_EXCEL_DATA_REQUEST';
const GET_USERS_ACTIVITY_EXCEL_DATA_SUCCESS = 'GET_USERS_ACTIVITY_EXCEL_DATA_SUCCESS';
const GET_USERS_ACTIVITY_EXCEL_DATA_FAILURE = 'GET_USERS_ACTIVITY_EXCEL_DATA_FAILURE';
// 매출
const GET_SALES_STATISTICS_DATA_REQUEST = 'GET_SALES_STATISTICS_DATA_REQUEST';
const GET_SALES_STATISTICS_DATA_SUCCESS = 'GET_SALES_STATISTICS_DATA_SUCCESS';
const GET_SALES_STATISTICS_DATA_FAILURE = 'GET_SALES_STATISTICS_DATA_FAILURE';
// 매출 Excel
const GET_SALES_STATISTICS_EXCEL_DATA_REQUEST = 'GET_SALES_STATISTICS_EXCEL_DATA_REQUEST';
const GET_SALES_STATISTICS_EXCEL_DATA_SUCCESS = 'GET_SALES_STATISTICS_EXCEL_DATA_SUCCESS';
const GET_SALES_STATISTICS_EXCEL_DATA_FAILURE = 'GET_SALES_STATISTICS_EXCEL_DATA_FAILURE';
// DB 최신화
const UPDATE_DATABASE_REQUEST = 'UPDATE_DATABASE_REQUEST';
const UPDATE_DATABASE_SUCCESS = 'UPDATE_DATABASE_SUCCESS';
const UPDATE_DATABASE_FAILURE = 'UPDATE_DATABASE_FAILURE';

/************************ 초기상태 ************************/

const initialState = {
    compareUserData: null, // 회원 데이터 비교 현황
    userGraghData: null, // 회원 그래프 데이터
    userLineChartData: [], // 회원 라인 차트 데이터
    userActivityData: null, // 회원활동 데이터
    salesStatisticsData: null, // 매출 데이터
    // Excel
    userActivityExcelData: null, // 회원활동 Excel 데이터
    salesStatisticsExcelData: null, // 매출 Excel 데이터
    // 회원 데이터 비교 현황
    loadCompareUserDataLoading: false,
    loadCompareUserDataDone: false,
    loadCompareUserDataError: null,
    // 회원 현황 그래프 데이터
    loadUserGraghDataLoading: false,
    loadUserGraghDataDone: false,
    loadUserGraghDataError: null,
    // 회원 라인 차트 데이터
    loadUserLineChartDataLoading: false,
    loadUserLineChartDataDone: false,
    loadUserLineChartDataError: null,
    // 회원활동
    loadUserActivityDataLoading: false,
    loadUserActivityDataDone: false,
    loadUserActivityDataError: null,
    // 회원활동 excel
    loadUserActivityExcelDataLoading: false,
    loadUserActivityExcelDataDone: false,
    loadUserActivityExcelDataError: null,
    // 매출
    loadSalesStatisticsDataLoading: false,
    loadSalesStatisticsDataDone: false,
    loadSalesStatisticsDataError: null,
    // 매출 excel
    loadSalesStatisticsExcelDataLoading: false,
    loadSalesStatisticsExcelDataDone: false,
    loadSalesStatisticsExcelDataError: null,
    // DB 최신화 요청
    updateDatabaseLoading: false,
    updateDatabaseDone: false,
    updateDatabaseError: null,
};

/************************ action 함수 ************************/

// 회원 데이터 비교 현황 요청
export const getCompareData = (compareDate) => async (dispatch) => {
    dispatch({
        type: GET_COMPARE_USER_DATA_REQUEST,
    });
    try {
        // const response = await MainRefreshCheckApi.get(`/adminStatus/current`, {
        const response = await RefreshCheckApi.get(`/adminStatus/current`, {
            params: {
                date: compareDate,
            },
        });

        dispatch({
            type: GET_COMPARE_USER_DATA_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_COMPARE_USER_DATA_FAILURE,
            payload: error,
        });
    }
};

// 회원데이터 그래프
export const requestUserGraghData = (param) => async (dispatch) => {
    dispatch({
        type: GET_USER_GRAGH_DATA_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/current/graph`, {
            params: {
                date: param,
            },
        });

        dispatch({
            type: GET_USER_GRAGH_DATA_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_GRAGH_DATA_FAILURE,
            payload: error,
        });
    }
};

// 회원통계 라인차트 데이터
export const requestUserLineChartData = (params) => async (dispatch) => {
    dispatch({
        type: GET_USER_LINECHART_DATA_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/userAccount/graph/${params.type}`, { params });

        dispatch({
            type: GET_USER_LINECHART_DATA_SUCCESS,
            payload: response.data.data.sort((a, b) => {
                if (a.date > b.date) {
                    return 1;
                }
                if (a.date < b.date) {
                    return -1;
                }
                return 0;
            }),
        });
    } catch (error) {
        dispatch({
            type: GET_USER_LINECHART_DATA_FAILURE,
            payload: error,
        });
    }
};

// 회원활동 데이터 요청
export const getUserActivityData = (params) => async (dispatch) => {
    dispatch({
        type: GET_USERS_ACTIVITY_DATA_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/userAccount/page`, { params });

        dispatch({
            type: GET_USERS_ACTIVITY_DATA_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_ACTIVITY_DATA_FAILURE,
            payload: error,
        });
    }
};

// 회원활동 Excel 데이터 요청
export const getUserActivityDataExcel = () => async (dispatch) => {
    dispatch({
        type: GET_USERS_ACTIVITY_EXCEL_DATA_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/userAccount/page/excel`);

        dispatch({
            type: GET_USERS_ACTIVITY_EXCEL_DATA_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_ACTIVITY_EXCEL_DATA_FAILURE,
            payload: error,
        });
    }
};

// 매출 데이터 요청
export const getSalesStatisticsData = (params) => async (dispatch) => {
    dispatch({
        type: GET_SALES_STATISTICS_DATA_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/pay`, { params });

        dispatch({
            type: GET_SALES_STATISTICS_DATA_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SALES_STATISTICS_DATA_FAILURE,
            payload: error,
        });
    }
};

// 매출 Excel 데이터 요청
export const getSalesStatisticsDataExcel = () => async (dispatch) => {
    dispatch({
        type: GET_SALES_STATISTICS_EXCEL_DATA_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/pay/excel`);

        dispatch({
            type: GET_SALES_STATISTICS_EXCEL_DATA_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SALES_STATISTICS_EXCEL_DATA_FAILURE,
            payload: error,
        });
    }
};

// DB 최신화 요청
export const requestUpdateDatabase = (params) => async (dispatch) => {
    dispatch({
        type: UPDATE_DATABASE_REQUEST,
    });
    try {
        const response = await MainRefreshCheckApi.get(`/adminStatus/update`);

        dispatch({
            type: UPDATE_DATABASE_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_DATABASE_FAILURE,
            payload: error,
        });
    }
};

/************************ reducer ************************/
const newStatisticsReducer = handleActions(
    {
        // 회원현황
        [GET_COMPARE_USER_DATA_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCompareUserDataLoading = true;
                draft.loadCompareUserDataDone = false;
                draft.loadCompareUserDataError = null;
            }),
        [GET_COMPARE_USER_DATA_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCompareUserDataLoading = action.payload ? false : true;
                draft.compareUserData = action.payload;
                draft.loadCompareUserDataDone = action.payload ? true : false;
            }),
        [GET_COMPARE_USER_DATA_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCompareUserDataLoading = false;
                draft.loadCompareUserDataError = action.error;
            }),

        // 회원 그래프 데이터
        [GET_USER_GRAGH_DATA_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserGraghDataLoading = true;
                draft.loadUserGraghDataDone = false;
                draft.loadUserGraghDataError = null;
            }),
        [GET_USER_GRAGH_DATA_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserGraghDataLoading = false;
                draft.userGraghData = action.payload;
                draft.loadUserGraghDataDone = true;
            }),
        [GET_USER_GRAGH_DATA_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserGraghDataLoading = false;
                draft.loadUserGraghDataError = action.error;
            }),

        // 회원 라인 차트 데이터
        [GET_USER_LINECHART_DATA_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserLineChartDataLoading = true;
                draft.loadUserLineChartDataDone = false;
                draft.loadUserLineChartDataError = null;
            }),
        [GET_USER_LINECHART_DATA_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserLineChartDataLoading = action.payload ? false : true;
                draft.userLineChartData = action.payload;
                draft.loadUserLineChartDataDone = action.payload ? false : true;
            }),
        [GET_USER_LINECHART_DATA_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserLineChartDataLoading = false;
                draft.loadUserLineChartDataError = action.error;
            }),

        // 회원활동 테이블
        [GET_USERS_ACTIVITY_DATA_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserActivityDataLoading = true;
                draft.loadUserActivityDataDone = false;
                draft.loadUserActivityDataError = null;
            }),
        [GET_USERS_ACTIVITY_DATA_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserActivityDataLoading = action.payload ? false : true;
                draft.userActivityData = action.payload;
                draft.loadUserActivityDataDone = action.payload ? true : false;
            }),
        [GET_USERS_ACTIVITY_DATA_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserActivityDataLoading = false;
                draft.loadUserActivityDataError = action.error;
            }),

        // 회원활동 테이블 EXCEL
        [GET_USERS_ACTIVITY_EXCEL_DATA_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserActivityExcelDataLoading = true;
                draft.loadUserActivityExcelDataDone = false;
                draft.loadUserActivityExcelDataError = null;
            }),
        [GET_USERS_ACTIVITY_EXCEL_DATA_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserActivityExcelDataLoading = false;
                draft.userActivityExcelData = action.payload;
                draft.loadUserActivityExcelDataDone = true;
            }),
        [GET_USERS_ACTIVITY_EXCEL_DATA_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUserActivityExcelDataLoading = false;
                draft.loadUserActivityExcelDataError = action.error;
            }),

        // 매출
        [GET_SALES_STATISTICS_DATA_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadSalesStatisticsDataLoading = true;
                draft.loadSalesStatisticsDataDone = false;
                draft.loadSalesStatisticsDataError = null;
            }),
        [GET_SALES_STATISTICS_DATA_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadSalesStatisticsDataLoading = false;
                draft.salesStatisticsData = action.payload;
                draft.loadSalesStatisticsDataDone = true;
            }),
        [GET_SALES_STATISTICS_DATA_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadSalesStatisticsDataLoading = false;
                draft.loadSalesStatisticsDataError = action.error;
            }),

        // 매출 테이블 EXCEL
        [GET_SALES_STATISTICS_EXCEL_DATA_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadSalesStatisticsExcelDataLoading = true;
                draft.loadSalesStatisticsExcelDataDone = false;
                draft.loadSalesStatisticsExcelDataError = null;
            }),
        [GET_SALES_STATISTICS_EXCEL_DATA_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadSalesStatisticsExcelDataLoading = false;
                draft.salesStatisticsExcelData = action.payload;
                draft.loadSalesStatisticsExcelDataDone = true;
            }),
        [GET_SALES_STATISTICS_EXCEL_DATA_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadSalesStatisticsExcelDataLoading = false;
                draft.loadSalesStatisticsExcelDataError = action.error;
            }),

        // DB 최신화 요청
        [UPDATE_DATABASE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateDatabaseLoading = true;
                draft.updateDatabaseDone = false;
                draft.updateDatabaseError = null;
            }),
        [UPDATE_DATABASE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateDatabaseLoading = false;
                draft.updateDatabaseDone = true;
            }),
        [UPDATE_DATABASE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateDatabaseLoading = false;
                draft.updateDatabaseError = action.error;
            }),
    },
    initialState,
);

export default newStatisticsReducer;
