import styled from 'styled-components';
import CheckboxInput from '../checkboxs/CheckboxInput';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { MainButton } from '../buttons/Buttons';

export const DateInput = ({ wrapperStyle, titleStyle, inputStyle, title, onChange, name = '날짜 입력창', value }) => {
    return (
        <InputLineWrapper style={{ ...wrapperStyle }}>
            {title && <Title style={{ ...titleStyle }}>{title}</Title>}
            <DateInputTag type="date" onChange={onChange} name={name} value={value} style={{ ...inputStyle }} />
        </InputLineWrapper>
    );
};

const DateInputTag = styled.input`
    outline: none;
    padding: 2px 5px;

    width: 95%;
    height: 24px;

    font-size: 12px;
    border: 1px solid #428bf742;

    ::-webkit-inner-spin-button {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
        user-select: none;
    }
`;

export const SelectEntry = ({ onChange = () => {}, value, option = [], title, wrapperStyle, selectStyle, titleStyle }) => {
    return (
        <InputLineWrapper style={{ ...wrapperStyle }}>
            {title && <Title style={{ ...titleStyle }}>{title}</Title>}
            <Select style={{ ...selectStyle }} onChange={onChange} value={value}>
                {option.map((data, index) => (
                    <option key={index} value={data.value}>
                        {data.text}
                    </option>
                ))}
            </Select>
        </InputLineWrapper>
    );
};

const Select = styled.select`
    cursor: pointer;
    outline: none;

    padding: 2px 1px;
    font-size: 12px;

    width: 98%;
    height: 30px;

    border: 1px solid #428bf742;
`;

export const InputLine = ({
    wrapperStyle,
    titleStyle,
    type = 'text',
    title,
    value,
    onChange = () => {},
    placeholder,
    disabled,
    inputStyle,
    buttonOn = false,
    btnText = '클릭',
    onClick = () => {},
}) => {
    return (
        <InputLineWrapper style={{ ...wrapperStyle }}>
            {title && <Title style={{ ...titleStyle }}>{title}</Title>}
            {buttonOn ? (
                <FlexMainWrapper style={{ alignItems: 'center', width: '100%', gap: 8 }}>
                    <Input style={{ ...inputStyle }} type={type} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />
                    <MainButton text={btnText} style={{ borderRadius: 0, height: '31px' }} onClick={onClick} />
                </FlexMainWrapper>
            ) : (
                <Input style={{ ...inputStyle }} type={type} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />
            )}
        </InputLineWrapper>
    );
};

export const InputWithTitle = ({
    wrapperStyle,
    titleStyle,
    type = 'text',
    title,
    value,
    onChange = () => {},
    placeholder,
    disabled,
    inputStyle,
    buttonOn = false,
    btnText = '클릭',
    onClick = () => {},
    buttonOnWidth = 90,
    buttonOffWidth = 95,
    btnWidth = 70,
}) => {
    return (
        <InputLineWrapper style={{ margin: '0 0 5px 0', ...wrapperStyle }}>
            {title && <Title style={{ textAlign: 'start', fontSize: '14px', width: buttonOn ? '110px' : '120px', ...titleStyle }}>{title}</Title>}
            {buttonOn ? (
                <FlexMainWrapper style={{ alignItems: 'center', width: '100%', gap: 0 }}>
                    <Input
                        style={{ fontSize: '14px', width: buttonOn ? `${buttonOnWidth}%` : `${buttonOffWidth}%`, ...inputStyle }}
                        type={type}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                    <MainButton text={btnText} style={{ borderRadius: 0, height: '31px', width: `${btnWidth}px` }} onClick={onClick} />
                </FlexMainWrapper>
            ) : (
                <Input style={{ fontSize: '14px', ...inputStyle }} type={type} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />
            )}
        </InputLineWrapper>
    );
};

export const InputTitle = ({ exposure = true, titleStyle, inputStyle, type = 'text', title, value, onChange = () => {}, placeholder, wrapperStyle, inputRef, disabled = false }) => {
    return (
        <InputLineWrapper style={{ width: '98%', ...wrapperStyle }}>
            {exposure && <Title style={{ ...titleStyle }}>{title}</Title>}
            <Input ref={inputRef} style={{ ...inputStyle }} type={type} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />
        </InputLineWrapper>
    );
};

export const TwoCheckBox = ({ title, items, titleStyle }) => {
    return (
        <InputLineWrapper style={{ margin: 0 }}>
            <Title style={{ width: '75px', ...titleStyle }}>{title}</Title>
            {items.map((item, index) => (
                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', gap: '3px' }}>
                    <SubTitle>{item.subTitle}</SubTitle>
                    <CheckboxInput key={index} checked={item.checked} onChange={item.onChange} />
                </FlexMainWrapper>
            ))}
        </InputLineWrapper>
    );
};

export const SingleMainInput = ({ inputStyle, type = 'text', title, value, onChange = () => {}, placeholder, wrapperStyle, inputRef }) => {
    <SingleInput ref={inputRef} style={{ ...inputStyle }} type={type} value={value} onChange={onChange} placeholder={placeholder} />;
};

const InputLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    margin: 10px 10px;
`;

const SubTitle = styled.div`
    font-size: 13px;
`;

const Title = styled.div`
    font-size: 13px;
    color: #797979;
    width: 100px;
`;

const Input = styled.input`
    outline: none;
    padding: 2px 5px;
    width: 95%;
    height: 25px;
    border: 1px solid #428bf742;
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const SingleInput = styled.input`
    outline: none;
    padding: 2px 5px;
    width: 95%;
    height: 25px;
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    ::placeholder,
    ::-webkit-input-placeholder {
        color: rgb(171 171 171);
    }

    :-ms-input-placeholder {
        color: rgb(171 171 171);
    }

    &:focus {
        outline: none;
    }
`;
