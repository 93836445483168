import React, { useContext, useRef, useState } from 'react';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { SText } from '../../designComponents/text/ContentText';
import { ArrowRightOutlined } from '@ant-design/icons';
import { sexFilter, userFilter } from '../../utils/textFilter';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import UserModal from '../UserComponents/UserModal';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import { Image } from '../../designComponents/imageboxs/Image';

const ChatModalHeader = ({ chatParams, usersInfo, usersProfile, type }) => {
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [src, setSrc] = useState('');
    const [imageToggle, setImageToggle] = useState(false);

    const imgRef = useRef(null);
    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;

    let nameA;
    let nickNameA;
    let sexA;

    let nameB;
    let nickNameB;
    let sexB;

    if (type === 2) {
        nameA = chatParams?.to?.name;
        nickNameA = chatParams?.to?.nick_name;
        sexA = sexFilter(chatParams?.to?.sex);

        nameB = chatParams?.from?.name;
        nickNameB = chatParams?.from?.nick_name;
        sexB = sexFilter(chatParams?.from?.sex);
    } else {
        nameA = userFilter(chatParams.data?.info?.from, usersInfo, usersProfile, type)?.name;
        nickNameA = userFilter(chatParams.data?.info?.from, usersInfo, usersProfile, type)?.nick_name;
        sexA = sexFilter(userFilter(chatParams.data?.info?.from, usersInfo, usersProfile, type)?.sex);

        nameB = userFilter(chatParams.data?.info?.to, usersInfo, usersProfile, type)?.name;
        nickNameB = userFilter(chatParams.data?.info?.to, usersInfo, usersProfile, type)?.nick_name;
        sexB = sexFilter(userFilter(chatParams.data?.info?.to, usersInfo, usersProfile, type)?.sex);
    }

    return (
        <FlexMainWrapper style={{ alignItems: 'center' }}>
            {user_info_modal_state.modalVisible && <UserModal setImageToggle={setImageToggle} serSrcOrigin={setSrc} />}
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image url={src} width={500} height={false} cover={'contain'} imgRef={imgRef} />
                </ImageModal>
            )}
            <FlexMainWrapper
                style={{ alignItems: 'center', gap: '3px', cursor: 'pointer' }}
                onClick={() => {
                    if (chatParams.data.info.from) {
                        user_info_modal_actions.setId(chatParams.data.info.from);
                        user_info_modal_actions.setModalVisible(true);
                    } else {
                        alert('회원이 존재하지 않습니다.');
                    }
                }}
            >
                <SText text={nameA || nullTag} />
                {nickNameA ? <SText text={`(${nickNameA})`} /> : <SText text={nullTag} />}
                <span style={{ color: '#000' }}>|</span>
                <SText text={sexA || nullTag} />
            </FlexMainWrapper>
            <ArrowRightOutlined style={{ color: '#000' }} />
            <FlexMainWrapper
                style={{ alignItems: 'center', gap: '3px', cursor: 'pointer' }}
                onClick={() => {
                    if (chatParams.data.info.to) {
                        user_info_modal_actions.setId(chatParams.data.info.to);
                        user_info_modal_actions.setModalVisible(true);
                    } else {
                        alert('회원이 존재하지 않습니다.');
                    }
                }}
            >
                <SText text={nameB || nullTag} />
                {nickNameB ? <SText text={`(${nickNameB})`} /> : <SText text={nullTag} />}
                <span style={{ color: '#000' }}>|</span>
                <SText text={sexB || nullTag} />
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export default ChatModalHeader;
