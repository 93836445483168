import React from 'react';
import CheckboxInput from '../checkboxs/CheckboxInput';

const TheadLists = ({ headerLists, onClickAllCheck, checked,checkAble = true }) => {
    return (
        <tr>
            {
                checkAble ? <th>
                    <CheckboxInput onChange={(e) => onClickAllCheck(e)} checked={checked}/>
                </th>:null
            }
            {headerLists.map((item, index) => (
                <th key={`${item.key}${index}`}>{item.value}</th>
            ))}
        </tr>
    );
};

export default TheadLists;
