import styled from 'styled-components';

// 다음 주소검색 API
export const postCodeStyle = {
  position: 'fixed',
  top: '15%',
  left: '40%',

  display: 'block',
  border: '1px solid #cccccc',

  zIndex: 100,
  backgroundColor: '#cccccc',
  padding: '2px',

  boxShadow: '0 1px 2px var(--shadow-2)',

  width: '450px',
  height: '600px',

  //* TODO: 이거 반응형 912px 기준으로 디자인
};

export const CloseWindowBox = styled.div`
  position: fixed;
  top: 11%;
  left: 40%;

  background-color: #cdcdcd;

  text-align: end;
  font-size: 25px;
  font-weight: bold;
  line-height: 34px;

  padding: 5px;

  width: 446px;
  height: 30px;

  svg {
    cursor: pointer;
  }
`;

export const LogoImagePosition = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  width: 200px;
  height: 200px;

  border: 1px solid #cccccc;
  border-radius: 50%;

  @media (max-width: 1150px) {
    position: initial;
    margin-bottom: 30px;
  }
`;

export const LogoImage = styled.img`
  object-fit: cover;

  width: 200px;
  height: 200px;
  border-radius: 50%;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 90%;

  margin: 25px auto;

  @media (max-width: 912px) {
    margin: 0;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 465px; // 33%;

  margin-bottom: 5px;

  @media (max-width: 912px) {
    width: 100%;
  }
`;

export const Table = styled.div`
  position: relative;

  border: 1px solid #cccccc;

  padding: 10px 20px;

  @media (max-width: 912px) {
    width: 95%;
  }
`;

export const MainTitle = styled.div`
  margin-bottom: 10px;

  font-size: 17px;
  font-weight: bold;
`;

export const Title = styled.div`
  width: 150px;

  font-size: 15px;
  font-weight: bold;
`;

export const Input = styled.input`
  border: 1px solid #cccccc;
  outline: none;

  padding: 0 5px;

  width: 98%; // 170px;
  height: 26px;
`;

export const Select = styled.select`
  border: 1px solid #cccccc;
  outline: none;

  width: 100%; // 170px;
  height: 28px;
`;

export const CencelButton = styled.button`
  cursor: pointer;
  width: 140px;
  height: 30px;

  border: 1px solid #cccccc;

  background-color: #fff;
  color: #000;

  :hover {
    background-color: #cccccc;
  }
`;

export const CreateButton = styled.button`
  cursor: pointer;
  width: 140px;
  height: 30px;

  border: 0;

  background-color: rgb(66, 139, 247);
  color: #fff;

  :hover {
    background-color: rgb(40 116 228);
  }
`;

export const FindFileButton = styled.button`
  cursor: pointer;
  width: 100px;
  height: 28px;

  border: 0;

  background-color: #999999;
  color: #fff;

  :hover {
    background: #7d7b7b;
  }
`;

export const DirectInputBtn = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: gray;

  margin: 5px 0; // width: 465px; // 33%;

  text-decoration: underline;
  text-align: end;

  :hover {
    color: black;
  }
`;

export const LogoImageWrapper = styled.div`
  display: flex; /* flex-direction: column; */
  align-items: center; /* gap: 11px; */

  margin-top: 10px;
  margin-bottom: 5px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  width: 429px; // 30.4%;
`;

export const ButtomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 3px;

  width: 100%; // 33%;
  margin-bottom: 8px;
`;

export const HostNameBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 465px;

  @media (max-width: 912px) {
    width: 100%;
  }
`;

export const HostNameBtnWrapperBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 345px;

  .check-result-text {
    font-size: 14px;
    margin-right: 8px;
  }
`;
