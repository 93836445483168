import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MainButton } from '../../designComponents/buttons/Buttons';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { Image, makeImageUrl } from '../../designComponents/imageboxs/Image';
import DraggableImageGrid from '../../GroupComponent/Grid/DraggableImageGrid';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
const AnnounceDetailModal = ({ item, visible, setVisible, onChange }) => {
    const [editMode, setEditMode] = useState(false);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState([]);
    const htmlValidator = /(https|http)[\w|.|\-|&|?|=|%|\/|:]+/g;
    const imageRef = useRef(null);
    const onSave = async () => {
        if (window.confirm('저장하시겠습니까?')) {
            const { id } = item ?? {};
            let result;
            if (id) {
                result = await RefreshCheckApi.patch('/v2/announce', {
                    id,
                    title,
                    images: image,
                });
            } else {
                result = await RefreshCheckApi.post('/v2/announce', {
                    title,
                    images: image,
                });
            }
            if (result.status === 200) {
                if (onChange) {
                    onChange();
                }
                alert('저장되었습니다.');
                setVisible(false);
            }
        }
    };
    const onChangeImages = async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });
        const response = await RefreshCheckApi.post(`/adminGathering/contents/images?path=announce`, imageFormData);
        setTimeout(() => {
            if (response.status === 200) {
                setImage([...image, ...response.data]);
            }
            e.target.value = '';
        }, 500);
    };

    useEffect(() => {
        setTitle(item?.title ?? '');

        if (item) {
            const data = item.content.match(htmlValidator);

            const result = [];
            data.map((e) => {
                if (e !== 'http' && e !== 'https') {
                    result.push(e);
                }
            });
            setImage(result);
        } else {
            setImage([]);
        }
    }, [item]);
    useEffect(() => {
        if (!visible) {
            setEditMode(false);
            setTitle('');
            setImage([]);
        } else {
            if (!item) {
                setEditMode(true);
            }
        }
    }, [visible]);
    return (
        <Modal
            show={visible}
            size={'lg'}
            centered
            onHide={() => {
                setVisible(false);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Form.Control placeholder={'공지 제목'} value={title} disabled={!editMode} onChange={(e) => setTitle(e.target.value)} />
                </Modal.Title>
                <FlexMainWrapper style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center', marginRight: 16 }}>
                    <MainButton text={editMode ? '저장' : '수정'} fontStyle={{ fontSize: 16 }} onClick={editMode ? onSave : () => setEditMode((pre) => !pre)} />
                </FlexMainWrapper>
            </Modal.Header>
            <Modal.Body>
                {editMode && (
                    <FlexMainWrapper column style={{ alignItems: 'stretch' }}>
                        <MainButton width={null} height={null} onClick={() => imageRef.current?.click()} text={'이미지 추가'} />
                        <input
                            type="file"
                            multiple
                            hidden
                            ref={imageRef}
                            onChange={(e) => {
                                onChangeImages(e);
                            }}
                        />
                        <DraggableImageGrid data={image} onImageChange={(image) => setImage(image)} />
                    </FlexMainWrapper>
                )}
                <div>
                    {image.map((e) => (
                        <img style={{ width: '100%' }} src={makeImageUrl(e)} />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AnnounceDetailModal;
