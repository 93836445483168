import React, { useCallback, useEffect, useRef, useState } from 'react';

import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { ProductListsFilter } from '../../dataSystems/PageFilters/CummersListsMockData';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { Image } from '../../designComponents/imageboxs/Image';
import { colorFilter, contentFilter, dateFilter, isEmptyObj, moneyFilter } from '../../utils/textFilter';
import { DefaultModal, ImageModal } from '../../designComponents/modals/ModalComponent';
import ProductDetailInfo from '../../components/ProductComponent/ProductDetailInfo';
import AddProduct from './addProduct';
import { MainButton } from '../../designComponents/buttons/Buttons';

/** 상품 페이지 */
const CummersLists = () => {
    const [detailProduct, setDetailProduct] = useState({});
    const [addProductParam, setAddProductParam] = useState({});
    const [imageToggle, setImageToggle] = useState({});

    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [param, setParam] = useState({});

    const ref = useRef(null);
    const tableRef = useRef(null);
    const imgRef = useRef(null);

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get('/adminProduct', { params: input });
        return response.data.product_lists;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            limit: true,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get('/adminProduct', { params: input });
        return response.data.all_count + 1;
    };

    return (
        <Wrapper02>
            {imageToggle.toggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image imgRef={imgRef} size="S150" url={imageToggle.url} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}
            {addProductParam.toggle && (
                <DefaultModal
                    title={'상품 등록'}
                    bodyWidth={79}
                    boxStyle={{ width: '100vh', height: '90vh' }}
                    onClickClose={() => setAddProductParam({})}
                    bottomBtnOn
                    saveOn
                    closeOn
                    saveText={'등록'}
                    onClickBottomClose={() => setAddProductParam({})}
                    onClickSave={async () => {
                        try {
                            const body = addProductParam.body;
                            if (!body.name) return alert('상품명을 입력하세요');
                            if (!body.promotional_title) return alert('상품 설명을 입력하세요');
                            if (!body.list_price) return alert('상품 가격을 입력하세요');
                            if (!body.discount_price) return alert('상품 할인가를 입력하세요');
                            if (!body.image_lists) return alert('상품 이미지를 추가해주세요.');
                            else if (body.image_lists[0] === 'userPhoto/default.jpg') return alert('상품 이미지를 추가해주세요.');
                            if (!isEmptyObj(body.host, true)) return alert('판매처를 선택해주세요.');
                            const response = await RefreshCheckApi.post('/adminProduct', body);
                            if (response.status === 200) {
                                ref?.current?.refresh();
                                alert('상품등록 완료');
                            }
                        } catch (error) {
                            console.error('error: ', error);
                        }
                    }}
                >
                    <AddProduct addProductParam={addProductParam} setAddProductParam={setAddProductParam} />
                </DefaultModal>
            )}
            {detailProduct.toggle && (
                <DefaultModal
                    title={'상품 상세 정보'}
                    bodyWidth={79}
                    boxStyle={{ width: '120vh', height: '90vh' }}
                    onClickClose={() => setDetailProduct({})}
                    bottomBtnOn={true}
                    saveOn={true}
                    middleOn={true}
                    closeOn={true}
                    cancelText={'닫기'}
                    middleText={'삭제'}
                    saveText={'저장'}
                    onClickBottomClose={() => setDetailProduct({})}
                    onClickMiddle={() => {
                        if (window.confirm('해당 상품을 삭제 하시겠습니까?')) {
                            // TODO : Delete Product
                        }
                    }}
                    onClickSave={async () => {
                        try {
                            if (window.confirm('수정된 내용을 저장 하시겠습니까?')) {
                                const response = await RefreshCheckApi.patch('/adminProduct/update', detailProduct.data);
                                if (response.status === 200) {
                                    alert(response.data.message);
                                    ref?.current?.refresh();
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                >
                    <ProductDetailInfo data={detailProduct.data} detailProduct={detailProduct} setDetailProduct={setDetailProduct} />
                </DefaultModal>
            )}
            <MainPageTitle text={'상품리스트'} />
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '판매처', value: 1 },
                    { id: 2, title: '상품명', value: 2 },
                    { id: 3, title: '상품제목', value: 3 },
                    { id: 4, title: '상품ID', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => setSearchText(e.target.value)}
            />
            <MainTable
                scrollWidth={175}
                buttonComponents={({ checkItems }) => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'end' }}>
                        <TopSideButton middleOn={false} text01={'등록'} text02={'삭제'} onClick={() => setAddProductParam({ body: {}, toggle: true })} onClickDelete={() => {}} />
                    </FlexMainWrapper>
                )}
                ref={ref}
                tableRef={tableRef}
                loadingText={'전체 상품내역을 불러오고 있습니다.'}
                headerData={ProductListsFilter}
                filterKey={'test'}
                filterItem={(item) => <MainButton text={item.value} onClick={() => {}} />}
                checkID={'id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item, key) => async () => {
                    try {
                        if (key !== 'product_thumbnail') {
                            const response = await RefreshCheckApi.get(`adminProduct/detail?id=${item.id}&host_id=${item.host_id}`);
                            return setDetailProduct({ toggle: true, data: { ...response.data } });
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }}
                tbodyRenderItem={(key, value, item) => {
                    if (key === 'list_price') return moneyFilter(value);
                    if (key === 'discount_price') return moneyFilter(value);
                    if (key === 'name') return value;
                    if (key === 'promotional_title') return contentFilter(value, 20);
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'updated_at') return dateFilter(value);
                    if (key === 'sales_yn') return colorFilter(value === 'Y' ? true : false, value);
                    if (key === 'avail_yn') return colorFilter(value === 'Y' ? true : false, value);
                    if (key === 'option_yn') return colorFilter(value === 'Y' ? true : false, value);
                    if (key === 'inventory_cnt') return <div>{value || 0}개</div>;
                    if (key === 'product_thumbnail')
                        return <Image imgRef={imgRef} url={value} width={80} height={80} cover={'cover'} alt={'이미지'} size={'S150'} onClick={() => setImageToggle({ toggle: true, url: value })} />;
                    else return value;
                }}
            />
        </Wrapper02>
    );
};

export default CummersLists;
