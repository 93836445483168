/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

// moment
import moment from 'moment';

// styled-components
import styled from 'styled-components';

// recharts
import { ResponsiveContainer } from 'recharts';

// components
import LineChartEntry from './LineChartEntry';

// redux
import LoadingPage from '../../pages/loading/LoadingPage';
import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import PlatformTab from '../../GroupComponent/Tab/PlatformTab';

const LineChartCompoenent = () => {
    // const dispatch = useDispatch();
    // useSelector
    // const { userLineChartData, loadUserLineChartDataLoading } = useSelector((state) => state.newStatisticsReducer);
    // state
    // const [accumulateDate, setAccumulateDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [lineDateType, setLineDateType] = useState(0);
    const [lineStartDate, setLineStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [lineEndDate, setLineEndDate] = useState(moment().subtract(0, 'days').format('YYYY-MM-DD'));
    const [lineWidth, setLineWidth] = useState(0);

    const [lineData, setLineDate] = useState([]);
    const [loding, setLoding] = useState(false);

    // useRef
    const lineChartAreaRef = useRef();

    /*********************** useEffect ***********************/

    const [platform, setPlatform] = useState(0);

    // 너비 구하기
    useLayoutEffect(() => {
        setLineWidth(lineChartAreaRef.current.offsetWidth);
    }, [lineChartAreaRef]);

    // 데이터요청
    useEffect(() => {
        onChangeLineData().then().catch();
    }, [lineStartDate, lineEndDate, lineDateType, platform]);

    /*********************** function ***********************/

    // const onChangeAccumulateDate = () => {
    //     dispatch(
    //         requestUserLineChartData({
    //             date: moment(accumulateDate).format('YYYY-MM-DD'),
    //         }),
    //     );
    // };

    // 그래프 데이터 요청
    const onChangeLineData = async () => {
        try {
            setLoding(true);
            const adminStatusRes = await RefreshCheckApi.post(`/adminStatus/new`, { s_date: lineStartDate, e_date: lineEndDate, graph_type: lineDateType, platform: platform });
            setLineDate(adminStatusRes.data.data);
            setLoding(false);
        } catch (err) {}
    };

    /************************* html *************************/
    return (
        <>
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', gap: '15px', margin: '0 0 3vh 0' }}>
                <PlatformTab setPlatform={setPlatform}></PlatformTab>
            </FlexMainWrapper>

            <ButtonWrapper>
                <TypeInputContainer>
                    <TypeInput type={'button'} value={'일별'} style={{ fontWeight: lineDateType === 0 ? 'bold' : 'normal' }} onClick={() => setLineDateType(0)} />
                    <div style={{ height: '70%', backgroundColor: 'rgb(229 229 229)', width: '1px' }} />
                    <TypeInput type={'button'} value={'주별'} style={{ fontWeight: lineDateType === 1 ? 'bold' : 'normal' }} onClick={() => setLineDateType(1)} />
                    <div style={{ height: '70%', backgroundColor: 'rgb(229 229 229)', width: '1px' }} />
                    <TypeInput type={'button'} value={'월별'} style={{ fontWeight: lineDateType === 2 ? 'bold' : 'normal' }} onClick={() => setLineDateType(2)} />
                </TypeInputContainer>

                <SetTypeWrapper>
                    <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={lineStartDate} onChange={(e) => setLineStartDate(e.target.value)} />
                    <span>~</span>
                    <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={lineEndDate} onChange={(e) => setLineEndDate(e.target.value)} />
                </SetTypeWrapper>
            </ButtonWrapper>

            <LineChartWrapper ref={lineChartAreaRef}>
                {loding ? (
                    <LoadingPage text={'회원그래프 데이터를 불러오는 중입니다.'} />
                ) : (
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChartEntry data={lineData} lineDateType={lineDateType} lineWidth={lineWidth} />
                    </ResponsiveContainer>
                )}
            </LineChartWrapper>
        </>
    );
};

export default LineChartCompoenent;

/*********************** styled-components ***********************/

const SetTypeWrapper = styled.div`
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TypeInputContainer = styled.div`
    border: 1px solid rgb(229 229 229);
    display: flex;
    align-items: center;
    border-radius: 5px;
`;

const TypeInput = styled.input`
    cursor: pointer;
    background-color: rgb(255 255 255);
    font-style: noto_sans_kr_medium;
    border: 0px;
    padding-inline: 12px;
    padding-block: 4px;
`;

const TextInputNoLine = styled.input`
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    font-family: noto_sans_kr_medium;
    box-sizing: border-box;
    border: 0px;

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;

    width: 92%;
    margin: 35px auto 20px auto;
`;

const LineChartWrapper = styled.div`
    width: 92%;
    margin: auto;
`;
