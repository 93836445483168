import React, { useState } from 'react';

import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import GatheringScheduleLists from '../../components/GatheringComponents/GatheringScheduleLists';
import GatheringSchedulePayUser from '../../components/GatheringComponents/GatheringSchedulePayUser';
import GatheringSchedulerTab from "../../GroupComponent/Tab/GatheringSchedulerTab";

/** 모임관리 > 일정, 결제회원 */
const GatheringSchedule = () => {

    const [tabType, setTabType] = useState(0)

    return (
        <Wrapper02>

            <GatheringSchedulerTab setValue={setTabType}></GatheringSchedulerTab>
            {tabType === 0 && <GatheringScheduleLists />}
            {tabType === 1 && <GatheringSchedulePayUser />}
        </Wrapper02>
    );
};

export default GatheringSchedule;
