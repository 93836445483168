import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// 맨 앞 페이지 주제수정 Modal
function SurveyUpdateModal({ setToggle, id, name }) {
    const [surveyName, setSurveyName] = useState(name || 0);
    /******************************** useEffect ********************************/

    // TODO

    /******************************** function ********************************/

    // 모달창 닫기
    const onClickCloseModal = () => {
        setToggle(false);
    };

    // 주제 이름 바꾸기
    const onChangeName = (e) => {
        setSurveyName(e.target.value);
    };

    // 정보 업데이트
    const onSubmitUpdate = (e) => {
        e.preventDefault();
    };

    /********************************** html **********************************/
    return (
        <ModalWindow>
            <ModalBox>
                <div className="title">
                    <h3 style={{ margin: '0', fontWeight: '400' }}>이름 바꾸기</h3>
                </div>

                <Explanation className="explanation">
                    <span>항목의 새 이름을 입력하세요.</span>
                </Explanation>

                <form onSubmit={onSubmitUpdate}>
                    <div className="input-wrapper">
                        <UpdateInput type="text" placeholder="새 이름을 입력하세요." value={surveyName} onChange={onChangeName} />
                    </div>

                    <ButtonWrapper className="btn-wrapper">
                        <CencleButton type="button" onClick={onClickCloseModal}>
                            취소
                        </CencleButton>
                        <CreateButton type="submit">확인</CreateButton>
                    </ButtonWrapper>
                </form>
            </ModalBox>
        </ModalWindow>
    );
}

export default SurveyUpdateModal;

/******************************** styled-components ********************************/

const ModalWindow = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #0000009c;

    width: 100%;
    height: 100vh;
`;

const ModalBox = styled.div`
    background-color: #fff;
    border-radius: 20px;

    padding: 30px 42px;

    width: 430px;
    height: 18vh;

    overflow: auto;
`;

const Explanation = styled.div`
    margin: 20px 0;

    color: #5f6368;
    font-size: 14px;
`;

const UpdateInput = styled.input`
    outline: none;

    width: 98%;
    padding: 5px 5px;

    border: 1px solid #cccccc;
    border-radius: 3px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;

    margin-top: 6%;
`;

const CencleButton = styled.button`
    cursor: pointer;
    width: 75px;
    height: 30px;

    font-size: 14px;
    text-align: center;
    color: #9246d4;

    border: 1px solid #cccccc;
    border-radius: 5px;

    :hover {
        background-color: #cccccc24;
    }
`;

const CreateButton = styled.button`
    cursor: pointer;
    width: 75px;
    height: 30px;

    font-size: 14px;
    text-align: center;
    color: #fff;

    background-color: #4d90fe;
    border: 1px solid #4d90fe;
    border-radius: 5px;

    :hover {
        background-color: #307fff;
    }
`;
