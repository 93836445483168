import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 채팅 리스트 불러오기
export const GET_QUESTION_LISTS_REQUEST = 'GET_CHATTING_LISTS_REQUEST';
export const GET_QUESTION_LISTS_SUCCESS = 'GET_CHATTING_LISTS_SUCCESS';
export const GET_QUESTION_LISTS_FAILURE = 'GET_CHATTING_LISTS_FAILURE';
// 채팅 리스트 총 길이 불러오기
export const GET_QUESTION_LISTS_ALL_COUNT_REQUEST = 'GET_QUESTION_LISTS_ALL_COUNT_REQUEST';
export const GET_QUESTION_LISTS_ALL_COUNT_SUCCESS = 'GET_QUESTION_LISTS_ALL_COUNT_SUCCESS';
export const GET_QUESTION_LISTS_ALL_COUNT_FAILURE = 'GET_QUESTION_LISTS_ALL_COUNT_FAILURE';
// 채팅 카테고리 가져오기
export const GET_QUESTION_CATEGORY_LISTS_REQUEST = 'GET_QUESTION_CATEGORY_LISTS_REQUEST';
export const GET_QUESTION_CATEGORY_LISTS_SUCCESS = 'GET_QUESTION_CATEGORY_LISTS_SUCCESS';
export const GET_QUESTION_CATEGORY_LISTS_FAILURE = 'GET_QUESTION_CATEGORY_LISTS_FAILURE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    questionLists: [], // 질문 리스트
    getQuestionListsAllCount: null, // 질문 리스트 총 길이
    questionCategoryLists: [],
    // 질문 리스트
    getQuestionListsLoading: false,
    getQuestionListsDone: false,
    getQuestionListsError: null,
    // 질문 리스트 총 길이
    getQuestionListsAllCountLoading: false,
    getQuestionListsAllCountDone: false,
    getQuestionListsAllCountError: null,
    // 채팅 카테고리 가져오기
    getQuestionCategoryListsLoading: false,
    getQuestionCategoryListsDone: false,
    getQuestionCategoryListsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 질문 리스트 불러오기
export const requestGetQuestionLists = (input) => async (dispatch) => {
    dispatch({
        type: GET_QUESTION_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminQuestion`, { params: input });

        dispatch({
            type: GET_QUESTION_LISTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_QUESTION_LISTS_FAILURE,

            error: error,
        });
    }
};

// 질문 리스트 DATA 총 길이 불러오기
export const requestGetQuestionListsAllCount = (input) => async (dispatch) => {
    dispatch({
        type: GET_QUESTION_LISTS_ALL_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminQuestion/allCount`, { params: input });

        dispatch({
            type: GET_QUESTION_LISTS_ALL_COUNT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_QUESTION_LISTS_ALL_COUNT_FAILURE,

            error: error,
        });
    }
};

// question category
export const requestGetQuestionCategory = () => async (dispatch) => {
    dispatch({
        type: GET_QUESTION_CATEGORY_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminQuestion/category`);

        dispatch({
            type: GET_QUESTION_CATEGORY_LISTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_QUESTION_CATEGORY_LISTS_FAILURE,

            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const questionReducer = handleActions(
    {
        // 채팅 리스트 불러오기
        [GET_QUESTION_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionListsLoading = true;
                draft.getQuestionListsDone = false;
                draft.getQuestionListsError = null;
            }),
        [GET_QUESTION_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionListsLoading = action.payload ? false : true;
                draft.questionLists = action.payload;
                draft.getQuestionListsDone = action.payload ? true : false;
            }),
        [GET_QUESTION_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionListsLoading = false;
                draft.getQuestionListsError = action.error;
            }),

        // 채팅 리스트 총 길이 불러오기
        [GET_QUESTION_LISTS_ALL_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionListsAllCountLoading = true;
                draft.getQuestionListsAllCountDone = false;
                draft.getQuestionListsAllCountError = null;
            }),
        [GET_QUESTION_LISTS_ALL_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionListsAllCountLoading = action.payload ? false : true;
                draft.getQuestionListsAllCount = action.payload;
                draft.getQuestionListsAllCountDone = action.payload ? true : false;
            }),
        [GET_QUESTION_LISTS_ALL_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionListsAllCountLoading = false;
                draft.getQuestionListsAllCountError = action.error;
            }),

        // 채팅 카테고리 리스트 불러오기
        [GET_QUESTION_CATEGORY_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionCategoryListsLoading = true;
                draft.getQuestionCategoryListsDone = false;
                draft.getQuestionCategoryListsError = null;
            }),
        [GET_QUESTION_CATEGORY_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionCategoryListsLoading = action.payload ? false : true;
                draft.questionCategoryLists = action.payload;
                draft.getQuestionCategoryListsDone = action.payload ? true : false;
            }),
        [GET_QUESTION_CATEGORY_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionCategoryListsLoading = false;
                draft.getQuestionCategoryListsError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionListsLoading = false;
                draft.getQuestionListsDone = false;
                draft.getQuestionListsError = null;
            }),
    },
    initialState,
);

export default questionReducer;
