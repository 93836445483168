import React from 'react';
import styled from 'styled-components';
import { Empty } from 'antd';
import LoadingPage from '../../pages/loading/LoadingPage';
import CheckboxInput from '../checkboxs/CheckboxInput';

const defaultText = '데이터를 불러오고 있습니다.';
const TbodyLists = ({
    data = [],
    checkAble = true,
    renderItem = null,
    tdItemLists = [],
    loading = false,
    colSpan,
    loadingText = defaultText,
    onClickRowItem = () => {},
    disabledKey,
    onClickSingleCheck,
    checked,
    noneDataText = '데이터가 존재하지 않습니다.',
    nullText = '-',
    adminCheck = () => {},
}) => {
    const newItemLists = [...tdItemLists.map((item, _) => item.key)];

    return loading ? (
        <tr>
            <td colSpan={colSpan}>
                <LoadingPage text={loadingText} />
            </td>
        </tr>
    ) : data.length ? (
        data.map((item, index) => (
            <tr key={index}>
                {checkAble &&
                    <td style={{...adminCheck(item)}}>
                        <CheckboxInput onChange={(e) => onClickSingleCheck(e, item)} checked={checked(item)}/>
                    </td>
                }
                {newItemLists.map((key, idx) => (
                    <td key={`${key}${idx}`} style={{...adminCheck(item, key)}}
                        onClick={disabledKey !== key ? onClickRowItem(item, key) : null}>
                        {(renderItem !== null ? renderItem(key, item[key], item) : item[key]) || <span style={{ color: '#797979' }}>{nullText}</span>}
                    </td>
                ))}
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan={colSpan}>
                <EmptyWrapper className="empty-box">
                    <Empty />
                    <div style={{ margin: '5% 0' }}>{noneDataText}</div>
                </EmptyWrapper>
            </td>
        </tr>
    );
};

export default TbodyLists;

export const EmptyWrapper = styled.div`
    margin: 10% auto;
    font-size: 13px;
    text-align: center;
`;

export const HoverSpan = styled.span`
    font-weight: 600;
    text-decoration: underline;
    &:hover {
        font-weight: 600;
        color: rgb(66, 139, 247);
    }
`;
