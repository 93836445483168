import React from 'react';
import styled from 'styled-components';
import { MainButton } from '../buttons/Buttons';
import { SearchWhite1x } from '../../design_system/icons/icons';

export const LookCountSearchbar = ({ text = '조회수', onSubmit = () => {}, onChangeStart = () => {}, onChangeEnd = () => {}, onClickCallAPI = () => {} }) => {
    return (
        <SearchCountWrapper style={{ margin: '0 0 45px 0' }}>
            <CountText>{text}</CountText>
            <form onSubmit={onSubmit}>
                <BtnWrapper>
                    <Input type="number" placeholder="~회 부터" onChange={onChangeStart} />~
                    <Input type="number" placeholder="~회 까지" onChange={onChangeEnd} />
                    <MainButton onClick={onClickCallAPI} text={<SearchWhite1x />} style={{ borderRadius: 0 }} width={35} height={35} />
                </BtnWrapper>
            </form>
        </SearchCountWrapper>
    );
};

const SearchCountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
`;

const CountText = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 13px;
    padding: 0 6px;

    border: 1px solid rgba(66, 139, 247, 0.2);

    width: 126px;
    height: 33px;
`;

const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Input = styled.input`
    border: 1px solid rgba(66, 139, 247, 0.2);
    padding: 0 5px;

    outline: none;

    width: 94px;
    height: 33px;
`;
