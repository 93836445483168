export const SyrupPaymentsFilter = [
    {
        key: 'id',
        value: '주문 ID',

    },
    {
        key: 'created_at',
        value: '사용일시',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        // 결제상태 0: 결제대기중, 1: 입금대기중, 2: 결제완료, 3: 환불신청, 4: 환불완료 5: 환불거절 6: 결제취소
        key: 'pay_status',
        value: '결제상태',
        filter: [
            {
                key: 0,
                value: '결제대기중',
            },
            {
                key: 1,
                value: '입금대기중',
            },
            {
                key: 2,
                value: '결제완료',
            },
            {
                key: 3,
                value: '환불신청',
            },
            {
                key: 4,
                value: '환불완료',
            },
            {
                key: 5,
                value: '환불거절',
            },
            {
                key: 6,
                value: '결제취소',
            },
        ],
    },
    {
        key: 'user_name',
        value: '회원명',
    },
    {
        key: 'user_nickname',
        value: '회원 닉네임',
    },
    {
        key: 'user_phone_no',
        value: '휴대폰번호',
    },
    {
        key: 'contents',
        value: '결제상품',
        filter: [
            {
                key: 7,
                value: '시럽',
            }
        ],
    },
    {
        // 0: 카드결제, 1: 계좌이체
        key: 'pay_type',
        value: '결제수단',
        filter: [
            {
                key: 0,
                value: '신용 카드',
            },
            {
                key: 1,
                value: '무통장 계좌',
            },
            // {
            //     key: 2,
            //     value: '카카오 페이',
            // },
            // {
            //     key: 3,
            //     value: '네이버 페이',
            // },
            {
                key: 4,
                value: '계좌 이체',
            },
        ],
    },
    {
        key: 'point',
        value: '할인에 사용한 포인트',
        filter: [
            {
                key: 1,
                value: '없음',
            },
            {
                key: 2,
                value: '0~100포인트',
            },
            {
                key: 3,
                value: '100이상~500포인트',
            },
            {
                key: 4,
                value: '500이상~1,000포인트',
            },
            {
                key: 5,
                value: '1,000이상~2,000포인트',
            },
            {
                key: 6,
                value: '2,000이상~3,000포인트',
            },
            {
                key: 7,
                value: '3,000이상~4,000포인트',
            },
            {
                key: 8,
                value: '4,000포인트 이상',
            },
        ],
    },
    {
        key: 'amount',
        value: '실 결제금액',
        filter: [
            {
                key: 1,
                value: '없음',
            },
            {
                key: 2,
                value: '0~1,000원',
            },
            {
                key: 3,
                value: '1,000~5,000원',
            },
            {
                key: 4,
                value: '5,000~5 만원',
            },
            {
                key: 5,
                value: '5 만원~10 만원',
            },
            {
                key: 6,
                value: '10 만원~30 만원',
            },
            {
                key: 7,
                value: '30 만원~100 만원',
            },
            {
                key: 8,
                value: '100 만원 이상',
            },
        ],
    },
    {
        key: 'accumulate_amount',
        value: '누적 결제금액',
        filter: [
            {
                key: 1,
                value: '없음',
            },
            {
                key: 2,
                value: '0~1,000원',
            },
            {
                key: 3,
                value: '1,000~5,000원',
            },
            {
                key: 4,
                value: '5,000~5 만원',
            },
            {
                key: 5,
                value: '5 만원~10 만원',
            },
            {
                key: 6,
                value: '10 만원~30 만원',
            },
            {
                key: 7,
                value: '30 만원~100 만원',
            },
            {
                key: 8,
                value: '100 만원 이상',
            },
        ],
    },
];

export const AllPaymentsFilter = [
    {
        key: 'pay_id',
        value: '주문 ID',
    },
    {
        key: 'pay_created_at',
        value: '결제일시',
    },
    {
        // 결제상태 0: 결제대기중, 1: 입금대기중, 2: 결제완료, 3: 환불신청, 4: 환불완료 5: 환불거절 6: 결제취소
        key: 'pay_status',
        value: '결제상태',
        filter: [
            {
                key: 0,
                value: '결제대기중',
            },
            {
                key: 1,
                value: '입금대기중',
            },
            {
                key: 2,
                value: '결제완료',
            },
            {
                key: 3,
                value: '환불신청',
            },
            {
                key: 4,
                value: '환불완료',
            },
            {
                key: 5,
                value: '환불거절',
            },
            {
                key: 6,
                value: '기간 만료',
            },
            {
                key: 7,
                value: '결제 취소',
            },
        ],
    },
    {
        key: 'name',
        value: '이름/닉네임',
    },
    {
        key: 'phone_no',
        value: '휴대폰번호',
    },
    {
        key: 'contents',
        value: '결제상품',
        filter: [
            {
                key: 7,
                value: '시럽',
            },
            {
                key: 6,
                value: '모임',
            },
            {
                key: 5,
                value: '상품',
            },
        ],
    },
    {
        key: 'pay_type', // 0: 카드결제, 1: 계좌이체
        value: '결제수단',
        filter: [
            {
                key: 0,
                value: '신용카드',
            },
            {
                key: 1,
                value: '무통장 계좌',
            },
            {
                key: 2,
                value: '카카오 페이',
            },
            {
                key: 3,
                value: '네이버 페이',
            },
            {
                key: 4,
                value: '계좌 이체',
            },
            {
                key: 5,
                value: '현장 결제',
            },
        ],
    },
    {
        key: 'point',
        value: '포인트',
    },
    {
        key: 'amount',
        value: '실 결제금액',
    },
    {
        key: 'accumulate_amount',
        value: '누적 결제금액',
    },
];
