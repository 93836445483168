import React, { useState } from 'react';

// components
import SyrupUsingLists from './SyrupUsingLists';
import SyrupPayments from './SyrupPayments';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

// 시럽관리 페이지
function SyrupManagement() {
    const [syrupUsingLists, setSyrupUsingLists] = useState(true);
    const [syrupPayments, setSyrupPayments] = useState(false);

    const onClickToggle = (type) => () => {
        if (type === 1) {
            setSyrupUsingLists(true);
            setSyrupPayments(false);
        } else if (type === 2) {
            setSyrupPayments(true);
            setSyrupUsingLists(false);
        }
    };

    const titleRenderItems = [
        {
            text: '하트 사용 내역',
            toggle: syrupUsingLists,
            onPress: onClickToggle(1),
        },
        {
            text: '하트 결제 내역',
            toggle: syrupPayments,
            onPress: onClickToggle(2),
        },
    ];

    return (
        <Wrapper02>
            <MainPageTitle sigle={false} rederItem={titleRenderItems} />
            {syrupUsingLists && <SyrupUsingLists />}
            {syrupPayments && <SyrupPayments />}
        </Wrapper02>
    );
}

export default SyrupManagement;
