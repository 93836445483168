import Modal from 'react-bootstrap/Modal';
import useWindowDimensions from '../../hook/useWindowDimesions';

export const MiddleModal = ({ visible, setVisible, title, scrollable = false, children, footer }) => {
    const size = useWindowDimensions();
    return (
        <Modal
            show={visible}
            onHide={() => {
                setVisible(false);
            }}
            style={{
                maxHeight: size * 0.8,
            }}
            centered
            size={'xl'}
            scrollable={scrollable}
        >
            <Modal.Header closeButton>{title ? <Modal.Title>{title}</Modal.Title> : null}</Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            {footer ? <Modal.Footer>{footer}</Modal.Footer> : null}
        </Modal>
    );
};
