import Modal from 'react-modal';
import styled from 'styled-components';
import { h_px159rate, h_px15rate, h_px18rate, h_px20rate, h_px25rate, h_px4rate, h_px52rate, h_px64rate, h_px8rate, w_px15rate, w_px20rate } from '../../size';
import { ArrowBackwardWhite1x } from '../../icons/icons';
import React, { useEffect } from 'react';
import { rgb_210_210_210, rgb_255_255_255, rgb_66_139_247 } from '../../colors';
import WhiteBtn from '../white_btn/WhiteBtn';
import PinkBtn from '../pink_btn/PinkBtn';
import { useSelector } from 'react-redux';
import SubTitle from '../sub_title/SubTitle';
import ListName from '../list_name/ListName';

import ContentText from '../content_text/ContentText';

import MatchDispatchCenter from '../../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';

import moment from 'moment';
import LoadingPage from '../../../pages/loading/LoadingPage';

const ActivityPaymentModal = (props) => {
    const { visible, setVisible } = props;
    const paymentDetail = useSelector((state) => state.activityPaymentsReducer.activityPaymentsDetail);
    const { onGetActivityPaymentsDetailRefundAsync } = MatchDispatchCenter();

    const closeModal = () => {
        setVisible(false);
    };

    const refundPayment = () => {
        //* bug: id 못받아옴 !!
        onGetActivityPaymentsDetailRefundAsync(paymentDetail.activity_id);
        setVisible(false);
    };
    return (
        <Modal
            isOpen={visible}
            onAfterOpen={() => {
                document.body.style.overflow = 'hidden';
            }}
            onRequestClose={() => closeModal()}
            style={{ content: { borderRadius: h_px20rate, left: 0, right: 0, padding: 0, margin: h_px4rate, zIndex: '1' } }}
        >
            <ModalBox>
                <HeaderTitle>
                    <HeaderArrowBackwardBtn
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        <ArrowBackwardWhite1x />
                    </HeaderArrowBackwardBtn>
                    <div style={{ marginBottom: h_px4rate }}>상세정보</div>
                </HeaderTitle>

                {paymentDetail ? (
                    <Body>
                        <div style={{ width: '100%' }}>
                            <SubTitle style={{ fontSize: h_px25rate }}>{paymentDetail.name} </SubTitle>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <div style={{ width: '50%', padding: h_px4rate }}>
                                    <SubTitle style={{ fontSize: h_px18rate, marginTop: h_px18rate }}>신청 놀이 정보</SubTitle>
                                    <div style={{ marginTop: h_px18rate, width: '100%' }}>
                                        <div>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName
                                                                    style={{
                                                                        display: 'flex',
                                                                        fontSize: h_px15rate,
                                                                        width: 'auto',
                                                                        justifyContent: 'flex-start',
                                                                    }}
                                                                >
                                                                    방문 서비스
                                                                </ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <div style={{ display: 'flex' }}>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {paymentDetail.activity_visit === 1 ? 'Y' : 'N'}
                                                                </ContentText>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        width: 'auto',
                                                                    }}
                                                                >
                                                                    주소
                                                                </ListName>
                                                            </TrWrapper>
                                                        </th>

                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {paymentDetail.address}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>시작 일시</ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {moment(paymentDetail.activity_start_date).year() === 3000 ? '날짜, 시간 협의' : paymentDetail.activity_start_date}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>종료 일시</ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {moment(paymentDetail.activity_end_date).year() === 3000 ? '날짜, 시간 협의' : paymentDetail.activity_end_date}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        width: 'auto',
                                                                    }}
                                                                >
                                                                    호스트
                                                                </ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {paymentDetail.host_name}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <SubTitle style={{ fontSize: h_px18rate, marginTop: h_px18rate }}>환불 정보</SubTitle>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName
                                                                    style={{
                                                                        display: 'flex',
                                                                        fontSize: h_px15rate,
                                                                        width: 'auto',
                                                                        justifyContent: 'flex-start',
                                                                    }}
                                                                >
                                                                    환불 요청일시
                                                                </ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <div style={{ display: 'flex' }}>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {paymentDetail.refund_date}
                                                                </ContentText>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        width: 'auto',
                                                                    }}
                                                                >
                                                                    주소
                                                                </ListName>
                                                            </TrWrapper>
                                                        </th>

                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {paymentDetail.address}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>시작 일시</ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {moment(paymentDetail.activity_start_date).year() === 3000 ? '날짜, 시간 협의' : paymentDetail.activity_start_date}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>종료 일시</ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {moment(paymentDetail.activity_end_date).year() === 3000 ? '날짜, 시간 협의' : paymentDetail.activity_end_date}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            <TrWrapper>
                                                                <ListName
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        width: 'auto',
                                                                    }}
                                                                >
                                                                    호스트
                                                                </ListName>
                                                            </TrWrapper>
                                                        </th>
                                                        <th>
                                                            <TrWrapper>
                                                                <ContentText
                                                                    style={{
                                                                        fontSize: h_px15rate,
                                                                        padding: 0,
                                                                        marginLeft: w_px15rate,
                                                                        marginRight: w_px15rate,
                                                                    }}
                                                                >
                                                                    {paymentDetail.host_name}
                                                                </ContentText>
                                                            </TrWrapper>
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '50%',
                                        borderLeft: `1px solid ${rgb_210_210_210}`,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 'auto',
                                        padding: h_px4rate,
                                        paddingLeft: w_px15rate,
                                    }}
                                >
                                    <SubTitle style={{ fontSize: h_px18rate, marginTop: h_px18rate }}>환불 정보</SubTitle>
                                    <div>
                                        <table style={{ width: '100%', paddingBottom: h_px15rate }}>
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <TrWrapper>
                                                            <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>환불 요청 일시</ListName>
                                                        </TrWrapper>
                                                    </th>

                                                    <th>
                                                        <TrWrapper>
                                                            <ContentText
                                                                style={{
                                                                    fontSize: h_px15rate,
                                                                    padding: 0,
                                                                    marginLeft: w_px15rate,
                                                                    marginRight: w_px15rate,
                                                                }}
                                                            >
                                                                {paymentDetail.refund_date}
                                                            </ContentText>
                                                        </TrWrapper>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <TrWrapper>
                                                            <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>환불 예정 금액</ListName>
                                                        </TrWrapper>
                                                    </th>

                                                    <th>
                                                        <TrWrapper>
                                                            <ContentText
                                                                style={{
                                                                    fontSize: h_px15rate,
                                                                    padding: 0,
                                                                    marginLeft: w_px15rate,
                                                                    marginRight: w_px15rate,
                                                                }}
                                                            >
                                                                {paymentDetail.refund_amount}
                                                            </ContentText>
                                                        </TrWrapper>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <TrWrapper>
                                                            <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>환불 은행</ListName>
                                                        </TrWrapper>
                                                    </th>

                                                    <th>
                                                        <TrWrapper>
                                                            <ContentText
                                                                style={{
                                                                    fontSize: h_px15rate,
                                                                    padding: 0,
                                                                    marginLeft: w_px15rate,
                                                                    marginRight: w_px15rate,
                                                                }}
                                                            >
                                                                {paymentDetail.refund_bank}
                                                            </ContentText>
                                                        </TrWrapper>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <TrWrapper>
                                                            <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>환불 계좌번호</ListName>
                                                        </TrWrapper>
                                                    </th>

                                                    <th>
                                                        <TrWrapper>
                                                            <ContentText
                                                                style={{
                                                                    fontSize: h_px15rate,
                                                                    padding: 0,
                                                                    marginLeft: w_px15rate,
                                                                    marginRight: w_px15rate,
                                                                }}
                                                            >
                                                                {paymentDetail.refund_account}
                                                            </ContentText>
                                                        </TrWrapper>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <TrWrapper>
                                                            <ListName style={{ fontSize: h_px15rate, width: 'auto' }}>환불 예금주</ListName>
                                                        </TrWrapper>
                                                    </th>
                                                    <th>
                                                        <TrWrapper>
                                                            <ContentText
                                                                style={{
                                                                    fontSize: h_px15rate,
                                                                    padding: 0,
                                                                    marginLeft: w_px15rate,
                                                                    marginRight: w_px15rate,
                                                                }}
                                                            >
                                                                {paymentDetail.refund_holder}
                                                            </ContentText>
                                                        </TrWrapper>
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Body>
                ) : (
                    <LoadingPage />
                )}

                <Footer>
                    <PinkBtn style={{ marginLeft: h_px8rate }} onClick={refundPayment}>
                        환불 처리
                    </PinkBtn>
                    <WhiteBtn onClick={() => closeModal()}>취소</WhiteBtn>
                </Footer>
            </ModalBox>
        </Modal>
    );
};

export default ActivityPaymentModal;

const ModalBox = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    justify-content: space-between;
`;

const Body = styled.div`
    display: flex;
    flex: auto;
    padding: ${h_px20rate}px;
`;
const TrWrapper = styled.div`
    margin-top: ${h_px18rate}px;
    display: flex;
    justify-content: flex-start;
`;
const Footer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    height: ${h_px52rate}px;
    align-items: center;
    padding: ${h_px20rate}px;
`;
const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    height: ${h_px64rate}px;
    font-size: ${w_px15rate}px;
    font-family: noto_sans_kr_bold;
    background-color: ${rgb_66_139_247};
    color: ${rgb_255_255_255};
`;

const HeaderArrowBackwardBtn = styled.button`
    border-width: 0px;
    background-color: ${rgb_66_139_247};
`;
const Image = styled.img`
    width: 130px;
    height: 130px;
    resize: auto;
    padding: ${h_px4rate}px;
`;
const MemoInput = styled.textarea`
    width: 100%;
    height: ${h_px159rate}px;

    border-radius: ${w_px15rate}px;
    padding: ${w_px20rate}px;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;
