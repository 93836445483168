export const headers = [
  {
    key: 'date',
    value: '일자',
  },
  {
    key: 'findBestFriendClicks',
    value: '단짝찾기메뉴_클릭수',
  },
  {
    key: 'findBestFriendClickPercentage',
    value: '단짝찾기메뉴_클릭률',
  },
  {
    key: 'activityClicks',
    value: '놀이터메뉴_클릭수',
  },
  {
    key: 'activityClickPercentage',
    value: '놀이터메뉴_클릭률',
  },
  {
    key: 'activityTrips',
    value: '놀이터_카테고리_여행',
  },
  {
    key: 'activityEducate',
    value: '놀이터_카테고리_교육',
  },
  {
    key: 'activityCultureBeauty',
    value: '놀이터_카테고리_문화/뷰티',
  },
  {
    key: 'activityLeisureHealth',
    value: '놀이터_카테고리_레저/건강',
  },
  {
    key: 'activityDetailPopularPlay',
    value: '놀이터상세버튼_인기놀이',
  },
  {
    key: 'activityDetailGoodsThumbnail',
    value: '놀이터상세버튼_상품섬네일',
  },
  {
    key: 'activityDetailAllGoodsThumbnail',
    value: '놀이터상세버튼_상품섬네일(전체)',
  },
  {
    key: 'activityDetailApply',
    value: '놀이터상세버튼_신청하기',
  },
];
