import React, { useCallback, useEffect, useState } from 'react';
import produce from 'immer';

// styled-components
import { DropdownFrame, MainTitleBtn, HeaderTitle, HeaderInput, DropdownListFrame, MenuItemBtn } from './Styles/DropdownStyled';

// icons
import { ArrowDownBlue1x, ArrowUpBlue1x } from '../../design_system/icons/icons';

const listType = { id: 1, title: '휴대폰번호', value: 1 };

// 드롭다운 리스트 바
const Dropdown = ({ list = [listType], index = 0, resetThenSet = () => {} }) => {
    const [isListOpen, setIsListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(index ? list[index]['title'] : list[0]['title']);

    /****************************************** useEffect ******************************************/

    // 선택한 list-item 변경 함수 호출
    useEffect(() => {
        selectItem(list[index]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    /****************************************** function ******************************************/

    // 리스트 열기 || 닫기
    const toggleList = useCallback(() => {
        setIsListOpen(produce((isListOpen) => !isListOpen));
    }, []);

    // 선택힌 value 변경
    const selectItem = useCallback(
        (item) => {
            const { title } = item;

            setHeaderTitle(title);
            setIsListOpen(false);
            resetThenSet(item);
        },
        [resetThenSet],
    );

    /******************************************** html ********************************************/
    return (
        <DropdownFrame>
            <MainTitleBtn type="button" onClick={toggleList} isListOpen={isListOpen}>
                <HeaderTitle>{headerTitle}</HeaderTitle>
                {isListOpen ? <ArrowUpBlue1x /> : <ArrowDownBlue1x />}
            </MainTitleBtn>

            {isListOpen ? (
                <DropdownListFrame role="list">
                    {list.map((item) => (
                        <MenuItemBtn type="button" key={item.id} onClick={() => selectItem(item)}>
                            {item.title}
                        </MenuItemBtn>
                    ))}
                </DropdownListFrame>
            ) : null}
        </DropdownFrame>
    );
};

// 검색창 컴포넌트
const SearchableDropdownComponent = ({ list = [listType], index = 0, resetThenSet = () => {} }) => {
    // const toggleList = useCallback(() => {setIsListOpen(produce((isListOpen) => !isListOpen))}, []);
    const [isListOpen, setIsListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(index ? list[index]['title'] : '선택없음');

    /****************************************** function ******************************************/

    // 검색어 적용 함수
    const selectItem = useCallback(
        (item) => {
            const { title } = item;
            setHeaderTitle(title);
            setIsListOpen(false);
            resetThenSet(item);
        },
        [resetThenSet],
    );

    // 리스트 토글 스타일
    let isListOpenStyled = { display: isListOpen ? 'block' : 'none' };

    // 상단 SELECT 제목 스타일
    let headerTitleStyled = (item) => {
        return { display: item.title.includes(headerTitle) ? 'block' : 'none' };
    };

    /******************************************** html ********************************************/
    return (
        <DropdownFrame>
            <MainTitleBtn className="main-btn" style={{ paddingLeft: 0 }}>
                <HeaderInput
                    value={headerTitle}
                    onChange={(e) => {
                        setHeaderTitle(e.target.value);
                    }}
                    onFocus={() => {
                        setIsListOpen(true);
                    }}
                    onBlur={(e) => {}}
                />
                {isListOpen ? <ArrowUpBlue1x /> : <ArrowDownBlue1x />}
            </MainTitleBtn>

            <DropdownListFrame role="list" style={isListOpenStyled}>
                {list.map((item, _) => (
                    <MenuItemBtn
                        type="button"
                        key={'searchable_' + item.id}
                        onClick={() => selectItem(item)}
                        style={headerTitleStyled(item)}
                    >
                        {item.title}
                    </MenuItemBtn>
                ))}
            </DropdownListFrame>
        </DropdownFrame>
    );
};

export const SearchableDropdown = React.memo(SearchableDropdownComponent);

export default React.memo(Dropdown);
