// gathering.schedule;
import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 결제상태관리
export const GET_PAY_USERS_REQUEST = 'GET_PAY_USERS_REQUEST';
export const GET_PAY_USERS_SUCCESS = 'GET_PAY_USERS_SUCCESS';
export const GET_PAY_USERS_FAILURE = 'GET_PAY_USERS_FAILURE';
// 리셋
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    payUsers: [], // 결제회원 데이터
    // 결제회원 리스트 조회
    getpayUsersLoading: false,
    getpayUsersDone: false,
    getpayUsersError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 결제회원 리스트 조회
export const getGatheringPayUsers = (params) => async (dispatch) => {
    dispatch({
        type: GET_PAY_USERS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/account/pay/users`, { params: params });
        dispatch({
            type: GET_PAY_USERS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_PAY_USERS_FAILURE,
            error: error,
        });
    }
};
/******************************************
 * reducer
 ******************************************/
const gatheringPayUsersReducer = handleActions(
    {
        // 결제회원 리스트 조회
        [GET_PAY_USERS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getpayUsersLoading = true;
                draft.getpayUsersDone = false;
                draft.getpayUsersError = null;
            }),
        [GET_PAY_USERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getpayUsersLoading = false;
                draft.payUsers = action.payload;
                draft.getpayUsersDone = true;
            }),
        [GET_PAY_USERS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getpayUsersLoading = false;
                draft.getpayUsersError = action.error;
            }),

        // 상태 리셋
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 결제회원 리스트 조회
                draft.getpayUsersLoading = false;
                draft.getpayUsersDone = false;
                draft.getpayUsersError = null;
            }),
    },
    initialState,
);

export default gatheringPayUsersReducer;
