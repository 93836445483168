import React from "react";
import styled from "styled-components";
import {rgb_0_0_0, rgba_66_139_247_0d2} from "../../colors";
import {h_px6rate, w_px8rate} from "../../size";

const DetailTextInput = ({
  width,
  height,
  font_size,
  placeholder,
  value,
  onChange,
  disabled,
    style,
    rows
}) => {
  return (
    <Input
      width={width}
      height={height}
      font_size={font_size}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      rows={rows??1}
      style={{...style}}
    ></Input>
  );
};

export default React.memo(DetailTextInput);

export const Input = styled.input`
  padding-top: ${h_px6rate}px;
  padding-bottom: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  padding-right: ${w_px8rate}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  font-size: ${(props) => props.font_size}px;
  color: ${rgb_0_0_0};
  font-family: noto_sans_kr_medium;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;
