import moment from 'moment/moment';
import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import LoadingPage from '../../pages/loading/LoadingPage';
import { useEffect, useState } from 'react';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import styled from 'styled-components';

const UserDashBord = ({ platform }) => {
    const beforeSevenDays = moment().subtract(7, 'days').format('YYYY-MM-DD'); // 7일 전
    const [loading, setLoading] = useState(false);
    const [compareDate, setCompareDate] = useState(beforeSevenDays); // 비교 날짜 상태
    const [compareUserData, setCompareUserData] = useState([]);

    useEffect(() => {
        getCurrentData().then();
    }, [platform, compareDate]);

    const getCurrentData = async () => {
        try {
            setLoading(true);
            const adminStatusRes = await RefreshCheckApi.post(`/adminStatus/current`, { date: compareDate, platform: platform });
            setCompareUserData(adminStatusRes.data.data);
            setLoading(false);
        } catch (err) {
            let errMessage = '';

            if (err.response.status === 500) {
                errMessage = '회원 현황 조회 ERROR 관리자에게 문의해주세요';
            } else {
                errMessage = err.response?.data?.message;
            }
        }
    };

    let dataComponent;

    if (loading) {
        dataComponent = <LoadingPage text={'회원현황 정보를 불러오는 중입니다'} sizes={true} style={{ width: '100%', height: '100%' }} />;
    } else {
        dataComponent = (
            <>
                <CountBox>
                    <div id="sub-title">누적 회원수</div>
                    <div id="count">{Number(compareUserData?.user_current_accumulate)?.toLocaleString('ko-KR')} 명</div>
                    <div id="up-down-point">
                        {Number(compareUserData?.diffData?.user_current_accumulate)?.toLocaleString('ko-KR')}

                        {Number(compareUserData?.diffData?.user_current_accumulate) === 0 ? null : Number(compareUserData?.diffData?.user_current_accumulate) > 0 ? <span>명↑</span> : <span>명↓</span>}
                    </div>
                </CountBox>

                <CountBox>
                    <div id="sub-title">현재 회원수</div>
                    <div id="count">{compareUserData?.user_current?.toLocaleString('ko-KR')} 명</div>
                    <div id="up-down-point">
                        {Number(compareUserData?.diffData?.user_current)?.toLocaleString('ko-KR')}
                        {Number(compareUserData?.diffData?.user_current) === 0 ? null : Number(compareUserData?.diffData?.user_current) > 0 ? <span>명↑</span> : <span>명↓</span>}
                    </div>
                </CountBox>

                <CountBox>
                    <div id="sub-title">탈퇴수</div>
                    <div id="count">{compareUserData?.user_withdraw?.toLocaleString('ko-KR')} 명</div>
                    <div id="up-down-point">
                        {Number(compareUserData?.diffData?.user_withdraw)?.toLocaleString('ko-KR')}
                        {Number(compareUserData?.diffData?.user_withdraw) === 0 ? null : Number(compareUserData?.diffData?.user_withdraw) > 0 ? <span>명↑</span> : <span>명↓</span>}
                    </div>
                </CountBox>

                <CountBox>
                    <div id="sub-title">누적 결제수</div>
                    <div id="count">{compareUserData?.pay_accumulate?.toLocaleString('ko-KR')} 명</div>
                    <div id="up-down-point">
                        {Number(compareUserData?.diffData?.pay_accumulate)?.toLocaleString('ko-KR')}

                        {Number(compareUserData?.diffData?.pay_accumulate) === 0 ? null : Number(compareUserData?.diffData?.pay_accumulate) > 0 ? <span>명↑</span> : <span>명↓</span>}
                    </div>
                </CountBox>
            </>
        );
    }

    return (
        <StatusBoardBoxWrapper>
            <DateBox>
                <input type="date" id="disabled" disabled value={moment().subtract('days').format('YYYY-MM-DD')} />
                <span>~</span>
                <input type="date" value={compareDate} onChange={(e) => setCompareDate(e.target.value)} />
            </DateBox>
            <Wrapper03 style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: '300px', minHeight: '150px' }}>{dataComponent}</Wrapper03>
        </StatusBoardBoxWrapper>
    );
};

export default UserDashBord;

const CountBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    #sub-title {
        font-size: 14px;
        font-family: noto_sans_kr_medium;
        font-weight: bold;

        width: 80px;

        margin-right: 5px;
    }

    #count {
        font-size: 12px;
        font-weight: 500;
        color: rgb(112, 112, 112);
        text-align: end;
        letter-spacing: 0.2px;

        width: 100px;
        margin-right: 12px;
        margin-bottom: 10px;
    }

    #up-down-point {
        background-color: #ffffff;
        padding: 0 4px;

        text-align: end;

        font-size: 13px;
        color: rgb(85, 210, 41);
        width: 100px;
    }
`;

const CountBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 30px 15px;
`;

const StatusBoardBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    width: 100%;

    @media (max-width: 912px) {
        width: 100%;
    }
`;

const DateBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
    padding: 7px 5px;
    width: 320px;
    border-radius: 5px;
    border: 1px solid rgb(112, 112, 112, 0.1);
    box-shadow:
        0px 6px 8px rgba(25, 50, 47, 0.08),
        0px 3px 4px rgba(18, 71, 52, 0.02),
        0px 1px 16px rgba(18, 71, 52, 0.03);
    #disabled {
        ::-webkit-calendar-picker-indicator {
            display: none; // 달력 이모지 커서 포인트
        }
    }

    input {
        outline: none;
        background-color: #fff;
        border: 0;
        font-size: 13px;
        font-weight: bold;
        font-family: noto_sans_kr_medium;
        ::-webkit-calendar-picker-indicator {
            cursor: pointer; // 달력 이모지 커서 포인트
        }
    }
    span {
        font-weight: bold;
        margin: 0 5px;
    }
`;

const StatusBoardBox = styled.div`
    position: relative;

    width: 242px;
    height: 280px;

    background-color: rgb(112, 112, 112, 0.1);
`;
