import {getReports, getReportsSearch,} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import {GET_REPORTS} from "./reports_table_type";

export const getReportsAsync = createGetRequestThunk(GET_REPORTS, getReports);

export const getReportsSearchAsync = createGetRequestThunk(
  GET_REPORTS,
  getReportsSearch
);

