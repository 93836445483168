import React, { useEffect, useRef, useState } from 'react';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import MainTable from '../../designComponents/tables/MainTable';
import { alarmTalkTemplateFilter } from '../../dataSystems/PageFilters/AlarmTalkFilter';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';
import { contentFilter, dateFilter, messageTypeFilter } from '../../utils/textFilter';
import { DefaultModal, ModalBody } from '../../designComponents/modals/ModalComponent';
import { FlexTwoText, FlexTwoTextLists } from '../../designComponents/text/TextMapping';
import { MText, SText, Textbox } from '../../designComponents/text/ContentText';
import TableFilter from '../../designComponents/filters/TableFilter';

const AlarmTalkTemplate = () => {
    const [param, setParam] = useState({});
    const [detail, setDetail] = useState({});
    const ref = useRef(null);

    const callApi = async (_, param) => {
        try {
            let input = { page: 1, size: 50 };
            const response = await MainRefreshCheckApi.get('/adminRequest/alarmTalk/template', { params: input });
            const result = param.templateStatus ? response.data.data.filter((v, i) => v.templateStatus === param.templateStatus) : response.data.data;
            return result;
        } catch (error) {
            console.error( error);
        }
    };

    const pageCountCallApi = async (_, param) => {
        try {
            let input = { page: 1, size: 50 };
            const response = await MainRefreshCheckApi.get('/adminRequest/alarmTalk/template', { params: input });
            const length = param.templateStatus ? response.data.data.filter((v, i) => v.templateStatus === param.templateStatus).length : response.data.data.length;
            return length;
        } catch (error) {
            console.error('error: ', error);
        }
    };

    return (
        <Wrapper02>
            {detail.toggle && (
                <DefaultModal
                    title={'알림톡 상세 정보'}
                    boxStyle={{ width: '100vh', height: '70vh' }}
                    bodyWidth={59}
                    bottomBtnOn={true}
                    closeOn={true}
                    saveOn={true}
                    onClickClose={() => setDetail({ toggle: false })}
                    onClickBottomClose={() => setDetail({ toggle: false })}
                    onClickSave={() => {}}
                >
                    <DetailModalBody data={detail.data} />
                </DefaultModal>
            )}
            <MainPageTitle text={'알람톡 템플릿'} />
            <div style={{ overflow: 'auto' }}>
                <TableFilter
                    wrapperStyle={{ width: '95%' }}
                    arrFilterSt={{ flexDirection: 'column', alignItems: 'start' }}
                    param={param}
                    setParam={setParam}
                    topFilterLists={[
                        {
                            filterText: '템플릿 상태',
                            orderLists: [
                                { name: '승인건', width: 50, type: 'READY' },
                                { name: '거절건', width: 50, type: 'ACTIVE' },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.templateStatus = type;
                                else delete obj.templateStatus;
                                setParam(obj);
                            },
                            orderbyType: param.templateStatus,
                        },
                    ]}
                    research={ref}
                />
            </div>
            <MainTable
                scrollWidth={150}
                ref={ref}
                tableStyle={{ width: '98%' }}
                loadingText={'알람톡 템플릿 내역을 불러오고 있습니다.'}
                toggleBlur
                headerData={alarmTalkTemplateFilter}
                checkID={'id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    const obj = { ...detail };
                    obj.toggle = true;
                    obj.data = item;
                    setDetail(obj);
                }}
                tbodyRenderItem={(key, value) => {
                    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;
                    if (key === 'created_at') return value ? dateFilter(value) : nullTag;
                    if (key === 'content') return value ? contentFilter(value, 30) : nullTag;
                    return value || nullTag;
                }}
            />
        </Wrapper02>
    );
};

export default AlarmTalkTemplate;

const DetailModalBody = ({ data }) => {
    return (
        <ModalBody
            leftComponents={
                <>
                    <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', margin: '15px 0' }}>
                        <MText text={'템플릿 정보'} color="#000" style={{ fontSize: '17px' }} />
                    </FlexMainWrapper>
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '채널명',
                                text02: data.channelId,
                            },
                            {
                                text01: '생성일',
                                text02: dateFilter(data.created_at),
                            },
                            {
                                text01: '템플릿명',
                                text02: data.templateName,
                            },
                            {
                                text01: '검수 결과',
                                text02: data.templateInspectionStatus,
                            },
                            {
                                text01: '상태',
                                text02: data.templateStatus,
                            },
                        ]}
                    />

                    <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', margin: '15px 0' }}>
                        <MText text={'버튼 지정'} color="#000" style={{ fontSize: '17px' }} />
                    </FlexMainWrapper>
                    <FlexTwoText text01={'메세지 유형'} text02={messageTypeFilter(data.messageType)} gap={15} wrapperStyle={{ marginBottom: '10px' }} />
                    {data.buttons.map((item) => {
                        return (
                            <FlexMainWrapper style={{ flexDirection: 'column', marginBottom: '20px' }}>
                                <FlexTwoText text01Style={{ width: '60px', textAlign: 'start' }} text01={'버튼명'} text02={item.name} gap={15} />
                                {item.schemeAndroid && <FlexTwoText text01={'Android'} text02={item.schemeAndroid} text02Style={{ color: 'rgb(94 94 94)' }} gap={15} />}
                                {item.schemeIos && <FlexTwoText text01={'IOS'} text02={item.schemeIos} text02Style={{ color: 'rgb(94 94 94)' }} gap={15} />}
                                {item.linkMobile && <FlexTwoText text01={'모바일 링크'} text02={item.linkMobile} text02Style={{ color: 'rgb(94 94 94)' }} gap={15} />}
                                {item.linkPc && <FlexTwoText text01={'PC 링크'} text02={item.linkPc} text02Style={{ color: 'rgb(94 94 94)' }} gap={15} />}
                            </FlexMainWrapper>
                        );
                    })}
                </>
            }
            rightComponents={
                <>
                    <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', margin: '15px 0' }}>
                        <MText text={'검수 결과'} color="#000" style={{ fontSize: '17px' }} />
                    </FlexMainWrapper>
                    {data.comments.length ? (
                        data.comments.map((item) => {
                            return (
                                <FlexMainWrapper style={{ flexDirection: 'column', gap: '15px' }}>
                                    <FlexTwoText text01={'답변 시각'} text02={item.createTime} text02Style={{ color: 'rgb(94 94 94)' }} gap={15} />
                                    <FlexTwoText
                                        text01={'응답 유형'}
                                        text02={item.status === 'REJ' ? `검수반려(${item.status})` : `승인(${item.status})`}
                                        text02Style={{ color: 'rgb(94 94 94)', fontSize: '13px' }}
                                        gap={15}
                                    />
                                    <Textbox
                                        item={
                                            <SText
                                                text={item.content.split('\n').map((word) => (
                                                    <div>{word}</div>
                                                ))}
                                                style={{ textAlign: 'start', lineHeight: 1.7 }}
                                            />
                                        }
                                    />
                                </FlexMainWrapper>
                            );
                        })
                    ) : (
                        <SText text={'검수 결과가 존재하지 않습니다.'} color="#797979" />
                    )}
                </>
            }
        />
    );
};
