import React, { useState } from 'react';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import SendAlarmTalkTypeTab from "../../GroupComponent/Tab/SendAlarmTalkTypeTab";
import AlarmTalkHistory from "../../EventComponents/AlarmTalk/AlarmTalkHistory";
import AlarmTalkReserveHistory from "../../EventComponents/AlarmTalk/AlarmTalkReserveHistory";

const AlarmTalkResult = () => {
    const [listType, setListType] = useState(0);


    let historyComponent = (<></>)

    if(Number(listType)=== 0){
        historyComponent = <AlarmTalkHistory></AlarmTalkHistory>
    } else if(Number(listType) === 1) {
        historyComponent = <AlarmTalkReserveHistory></AlarmTalkReserveHistory>
    }

    return (
        <Wrapper02>
            <SendAlarmTalkTypeTab setSendType={setListType}></SendAlarmTalkTypeTab>
            {historyComponent}
        </Wrapper02>
    );
};

export default AlarmTalkResult;
