import React from 'react';

import ActivityUserCountBar from '../../components/MarketingComponents/ActivityUserCountBar';
import RetentionComponent from '../../components/MarketingComponents/RetentionComponent';
import PowerUserCurve from '../../components/MarketingComponents/PowerUserCurve';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

const Marketing = () => {


    return (
        <div>
            <MainPageTitle text={'마케팅'} />

            <Wrapper02 style={{ overflowY: 'auto' }}>
                <ActivityUserCountBar />
            </Wrapper02>

            <Wrapper02 style={{ overflowY: 'auto' }}>
                <PowerUserCurve />
            </Wrapper02>

            <Wrapper02 style={{ overflowY: 'auto' }}>
                <RetentionComponent />
            </Wrapper02>
        </div>
    );
};

export default Marketing;

