import {createContext, useState} from "react";

const ChattingTitleContext = createContext({
  chatting_title_state: {
    title: "",
  },
  chatting_title_actions: {
    setTitle: () => {},
  },
});

const ChattingTitleProvider = ({ children }) => {
  const [title, setTitle] = useState(false);

  const value = {
    chatting_title_state: { title },
    chatting_title_actions: {
      setTitle,
    },
  };

  return (
    <ChattingTitleContext.Provider value={value}>
      {children}
    </ChattingTitleContext.Provider>
  );
};

const ChattingTitleConsumer = ChattingTitleContext.Consumer;

export { ChattingTitleConsumer, ChattingTitleProvider };

export default ChattingTitleContext;
