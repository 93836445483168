import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { main_server_image_ip } from '../../../../../../communication_system/communication_ips';
import { chatting_date_function } from '../../../../../../data_system/data_functions/chatting_date_function/chatting_date_function';
import { rgb_0_0_0, rgb_121_121_121, rgb_255_255_255, rgb_66_139_247, rgba_66_139_247_0d1 } from '../../../../../colors';
import { h_px14rate, h_px32rate, h_px8rate, w_px103rate, w_px10rate, w_px14rate, w_px17rate, w_px18rate, w_px20rate, w_px58rate, w_px7rate, w_px9rate } from '../../../../../size';

const ChattingDetailComponent = ({ chattingsDetail }) => {
    const userAImage = chattingsDetail['user_a_image'];
    const userBImage = chattingsDetail['user_b_image'];
    const adminImage = chattingsDetail['admin_image'];

    return (
        <div
            style={{
                paddingTop: h_px14rate,
                paddingLeft: w_px7rate,
                paddingRight: w_px7rate,
            }}
        >
            {chattingsDetail.chatting_list.map((item, index) => {
                return (
                    <div key={index}>
                        {item['date_yn'] === true ? (
                            <div
                                style={{
                                    marginBottom: h_px32rate,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DateYnText>{moment(item['created_at']).format('YYYY년 MM월 DD일')}</DateYnText>
                            </div>
                        ) : null}
                        {item['chatting_div'] === 0 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {item['photo_yn'] === true || parseInt(item['chatting_type']) === 1 ? (
                                    <div
                                        style={{
                                            marginRight: w_px18rate,
                                        }}
                                    >
                                        <img
                                            src={main_server_image_ip + adminImage}
                                            style={{
                                                width: w_px58rate,
                                                height: w_px58rate,
                                                borderRadius: w_px58rate / 2,
                                                resizeMode: 'contain',
                                            }}
                                            alt={''}
                                        ></img>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            marginRight: w_px18rate,
                                            width: w_px58rate,
                                            height: w_px58rate,
                                        }}
                                    ></div>
                                )}
                                {parseInt(item['chatting_type']) === 0 ? (
                                    <ChattingContentTypeA>
                                        <div
                                            style={{
                                                maxWidth: w_px103rate,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: w_px14rate,
                                                }}
                                            >
                                                {item['contents']}
                                            </div>
                                        </div>
                                    </ChattingContentTypeA>
                                ) : (
                                    <div
                                        style={{
                                            marginBottom: h_px32rate,
                                        }}
                                    >
                                        <img
                                            src={main_server_image_ip + item['contents']}
                                            style={{
                                                width: w_px103rate,
                                                height: w_px103rate,
                                                borderRadius: w_px20rate,
                                                backgroundColor: rgb_0_0_0,
                                            }}
                                            alt={''}
                                        ></img>
                                    </div>
                                )}
                                <div
                                    style={{
                                        marginLeft: w_px10rate,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginBottom: h_px32rate,
                                    }}
                                >
                                    <DateText>{chatting_date_function(item['created_at'])}</DateText>
                                </div>
                            </div>
                        ) : item['chatting_div'] === 1 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {item['photo_yn'] === true || parseInt(item['chatting_type']) === 1 ? (
                                    <div
                                        style={{
                                            marginRight: w_px18rate,
                                        }}
                                    >
                                        <img
                                            src={main_server_image_ip + userAImage}
                                            style={{
                                                width: w_px58rate,
                                                height: w_px58rate,
                                                borderRadius: w_px58rate / 2,
                                                resizeMode: 'contain',
                                            }}
                                            alt={''}
                                        ></img>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            marginRight: w_px18rate,
                                            width: w_px18rate,
                                            height: w_px18rate,
                                        }}
                                    ></div>
                                )}
                                {parseInt(item['chatting_type']) === 0 ? (
                                    <ChattingContentTypeA>
                                        <div
                                            style={{
                                                maxWidth: w_px103rate,
                                            }}
                                        >
                                            {item['contents']}
                                        </div>
                                    </ChattingContentTypeA>
                                ) : (
                                    <div
                                        style={{
                                            marginBottom: h_px32rate,
                                        }}
                                    >
                                        <img
                                            source={{
                                                uri: main_server_image_ip + item['contents'],
                                            }}
                                            style={{
                                                width: w_px103rate,
                                                height: w_px103rate,
                                                borderRadius: w_px20rate,
                                                backgroundColor: rgb_0_0_0,
                                            }}
                                            alt={''}
                                        ></img>
                                    </div>
                                )}
                                <div
                                    style={{
                                        marginLeft: w_px10rate,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginBottom: h_px32rate,
                                    }}
                                >
                                    <DateText>{chatting_date_function(item['created_at'])}</DateText>
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <div
                                    style={{
                                        marginRight: w_px10rate,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginBottom: h_px32rate,
                                    }}
                                >
                                    <DateText>{chatting_date_function(item['created_at'])}</DateText>
                                </div>
                                {parseInt(item['chatting_type']) === 0 ? (
                                    <ChattingContentTypeB>
                                        <div
                                            style={{
                                                maxWidth: w_px103rate,
                                            }}
                                        >
                                            {item['contents']}
                                        </div>
                                    </ChattingContentTypeB>
                                ) : (
                                    <div
                                        style={{
                                            marginBottom: h_px32rate,
                                        }}
                                    >
                                        <img
                                            source={{
                                                uri: main_server_image_ip + item['contents'],
                                            }}
                                            style={{
                                                width: w_px103rate,
                                                height: w_px103rate,
                                                borderRadius: w_px20rate,
                                                backgroundColor: rgb_0_0_0,
                                            }}
                                            alt={''}
                                        ></img>
                                    </div>
                                )}
                                {item['photo_yn'] === true || parseInt(item['chatting_type']) === 1 ? (
                                    <div
                                        style={{
                                            marginLeft: w_px18rate,
                                        }}
                                    >
                                        <img
                                            src={main_server_image_ip + userBImage}
                                            style={{
                                                width: w_px58rate,
                                                height: w_px58rate,
                                                borderRadius: w_px58rate / 2,
                                                resizeMode: 'contain',
                                            }}
                                            alt={''}
                                        ></img>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            marginLeft: w_px18rate,
                                            width: w_px18rate,
                                            height: w_px18rate,
                                        }}
                                    ></div>
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(ChattingDetailComponent);

const DateYnText = styled.div`
    font-size: ${w_px14rate}px;
    color: ${rgb_121_121_121};
    font-family: roboto_regular;
`;
const ChattingContentTypeA = styled.div`
    padding-top: ${h_px8rate}px;
    padding-bottom: ${h_px8rate}px;
    padding-left: ${w_px17rate}px;
    padding-right: ${w_px17rate}px;
    background-color: ${rgb_255_255_255};
    border-radius: ${w_px20rate}px;
    margin-bottom: ${h_px32rate}px;
    border: 1px solid ${rgb_121_121_121};
    font-family: noto_sans_kr_regular;
    color: ${rgb_121_121_121};
`;

const ChattingContentTypeB = styled.div`
    padding-top: ${h_px8rate}px;
    padding-bottom: ${h_px8rate}px;
    padding-left: ${w_px17rate}px;
    padding-right: ${w_px17rate}px;
    background-color: ${rgba_66_139_247_0d1};
    border-radius: ${w_px20rate}px;
    margin-bottom: ${h_px32rate}px;
    border: 1px solid ${rgb_66_139_247};
    font-family: noto_sans_kr_regular;
    color: ${rgb_66_139_247};
`;

const DateText = styled.div`
    font-size: ${w_px9rate}px;
    color: ${rgb_121_121_121};
    font-family: noto_sans_kr_regular;
`;

// const ReadYnText = styled.div`
//   font-size: ${w_px9rate}px;
//   color: ${rgb_255_16_125};
//   font-family: noto_sans_kr_medium;
// `;
