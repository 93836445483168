import styled from 'styled-components';

export const DragButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 17px;
`;

export const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 3px;

    width: 98%;

    .delete {
        background-color: rgb(255, 16, 125);
    }
    .delete:hover {
        background-color: rgb(187 0 85);
    }

    .save {
        background-color: #19c300;
        width: 80px;
    }
    .save:hover {
        background-color: #169902;
    }
`;

export const DragFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
`;

export const FilterText = styled.span`
    cursor: pointer;

    font-size: 19px;
    font-weight: ${(props) => (props.locationNum === props.index ? 'bold' : '')};
    text-decoration: ${(props) => (props.locationNum === props.index ? 'underline' : '')};

    margin-bottom: 1%;
`;

export const StaticsRoutingBtn = styled.span`
    cursor: pointer;

    font-size: 15px;
    font-weight: bold;

    margin-bottom: 1%;

    &:hover {
        color: gray;
    }
`;

export const DragCSSTitle = styled.div`
    font-size: 13px;

    .list-item {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 5px;

        padding: 10px 30px;
        font-size: 13px;
        font-weight: 500;

        border-top: 1px solid #888;
        border-bottom: 1px solid #888;
    }

    .item {
        width: 100px;
        padding-left: 2px;
		text-align: center;
    }
`;

export const DragCSS = styled.div`
    font-size: 13px;
	

    .list-item {
        cursor: grab;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        padding: 10px 30px;
        border-bottom: 1px solid #888;
    }

    .list-item:hover {
        background-color: #0000001f;
    }
    .list-item:active {
        cursor: grabbing;
        background-color: #00000073;
        color: #fff;
    }

    .item {
        cursor: pointer;
        width: 100px;
        text-align: center;
		
    }
`;

export const Status = styled.div`
    width: 40px;
    height: 22px;
    line-height: 23px;

    text-align: center;
    font-weight: bold;
    color: ${(props) => `${props.color}`};

    border: ${(props) => `1px solid ${props.color}`};
    background-color: ${(props) => `${props.backgroundColor}`};
`;

export const ModalBody = styled.div`
    overflow: auto;
    margin-top: 11%;
    height: 100vh; // ${(props) => `${props.height}px`};

    @media (max-width: 912px) {
        height: 86vh;
    }
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
`;

export const CheckboxItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    font-size: 14px;
`;

export const SearchCountWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
`;

export const CountText = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 13px;
    padding: 0 6px;

    border: 1px solid rgba(66, 139, 247, 0.2);

    width: 126px;
    height: 33px;
`;

export const Input = styled.input`
    border: 1px solid rgba(66, 139, 247, 0.2);
    padding: 0 5px;

    outline: none;

    width: 94px;
    height: 33px;
`;

export const SearchBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SearchBtn = styled.button`
    cursor: pointer;
    width: 35px;
    height: 35px;

    background-color: rgb(66 139 247);
    border-width: 0px;
    align-items: center;
    justify-content: center;

    img {
        width: 17px;
        height: 17px;
    }

    :hover {
        background-color: rgb(5 101 242);
    }
    :active {
        background-color: rgb(66 139 247);
    }
`;

export const AllSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;

    margin: 45px 0;
`;
