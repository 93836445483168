import React from "react";
import styled from "styled-components";
import {rgb_0_0_0, rgba_66_139_247_0d2} from "../../colors";
import {h_px6rate, w_px8rate} from "../../size";

const TitleTextInput = ({
  value,
  height,
  font_size,
  placeholder,
  onChange,
}) => {
  return (
    <Input
      value={value}
      height={height}
      font_size={font_size}
      placeholder={placeholder}
      onChange={onChange}
    ></Input>
  );
};

export default React.memo(TitleTextInput);

const Input = styled.input`
  padding-top: ${h_px6rate}px;
  padding-bottom: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  padding-right: ${w_px8rate}px;
  height: ${(props) => props.height}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  font-size: ${(props) => props.font_size}px;
  color: ${rgb_0_0_0};
  font-family: noto_sans_kr_bold;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;
