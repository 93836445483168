import React, { useEffect, useState } from 'react';

// styled-components
import { CloseOutlined } from '@ant-design/icons';
import { ModalWindow, ChooseIntersetBox, TitleLineWrapper, Title, KategoryWrapper, KategorySubTitle, Item, SaveButtonWrapper, SaveButton } from '../../components/styles/AddGatheringModalStyled';

// mock
import { interestMock } from '../../dataSystems/CategoryLists';

function AddGatheringChooseInterestModal({ setInterestToggle, kategoryLists, setKategoryLists, gatheringFilter, onClickInterests = () => {}, onClickCloseModal = () => {} }) {
    const [interestCodeLists, setInterestCodeLists] = useState([]);

    // 카데고리 불러오기
    useEffect(() => {
        getInterestCode();
    }, []);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                onClickClose();
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 카테고리 가져오기 함수
    const getInterestCode = async () => {
        // const response = await RefreshCheckApi.get('/adminGathering/interestCode');
        setInterestCodeLists(interestMock);
    };

    let array = [...kategoryLists]; // 여행 > 체크박스 단일 선택
    const handleSingleCheck = (checked, key) => () => {
        if (onClickInterests) {
            if (checked) {
                const filterArr = array.filter((item) => item !== key);
                array = filterArr;
                onClickInterests(checked, key, array);
            } else {
                if (array.length >= 4) {
                    alert('123관심주제는 최대 4개까지 설정 가능합니다.');
                } else {
                    array.push(key);
                    onClickInterests(checked, key, array);
                }
            }
        } else {
            let arr = [];
            if (kategoryLists) {
                arr = [...kategoryLists];
            }
            if (!checked) {
                if (gatheringFilter) {
                    arr.push(key);
                    setKategoryLists(arr);
                } else {
                    if (kategoryLists && kategoryLists.length >= 4) {
                        alert('관심주제는 최대 4개까지 설정 가능합니다.');
                    } else {
                        arr.push(key);
                        setKategoryLists(arr);
                    }
                }
            } else {
                // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
                setKategoryLists(kategoryLists.filter((el) => el !== key));
            }
        }
    };

    // 모달창 닫기
    const onClickClose = () => {
        if (onClickCloseModal) onClickCloseModal();
        else setInterestToggle(false);
    };

    // 선택 카테고리 저장
    const onClickSave = () => {
        onClickClose();
    };

    return (
        <ModalWindow>
            <ChooseIntersetBox>
                <TitleLineWrapper>
                    <Title>관심주제 선택</Title>
                    <CloseOutlined className="close-btn" onClick={onClickClose} />
                </TitleLineWrapper>

                <div>
                    <KategorySubTitle>여행</KategorySubTitle>
                    <KategoryWrapper>
                        {interestCodeLists.map((item, index) => {
                            return (
                                item.code_group === '여행' && (
                                    <Item
                                        key={index}
                                        onClick={handleSingleCheck(kategoryLists?.includes(item.id), item.id)}
                                        // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        checked={kategoryLists?.includes(item.id) ? true : false}
                                    >
                                        {item.code_name}
                                    </Item>
                                )
                            );
                        })}
                    </KategoryWrapper>

                    <KategorySubTitle>건강</KategorySubTitle>
                    <KategoryWrapper>
                        {interestCodeLists.map((item, index) => {
                            return (
                                item.code_group === '건강' && (
                                    <Item
                                        key={index}
                                        onClick={handleSingleCheck(kategoryLists?.includes(item.id), item.id)}
                                        // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        checked={kategoryLists?.includes(item.id) ? true : false}
                                    >
                                        {item.code_name}
                                    </Item>
                                )
                            );
                        })}
                    </KategoryWrapper>

                    <KategorySubTitle>문화</KategorySubTitle>
                    <KategoryWrapper>
                        {interestCodeLists.map((item, index) => {
                            return (
                                item.code_group === '문화' && (
                                    <Item
                                        key={index}
                                        onClick={handleSingleCheck(kategoryLists?.includes(item.id), item.id)}
                                        // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        checked={kategoryLists?.includes(item.id) ? true : false}
                                    >
                                        {item.code_name}
                                    </Item>
                                )
                            );
                        })}
                    </KategoryWrapper>

                    <KategorySubTitle>기타</KategorySubTitle>
                    <KategoryWrapper>
                        {interestCodeLists.map((item, index) => {
                            return (
                                item.code_group === '기타' && (
                                    <Item
                                        key={index}
                                        onClick={handleSingleCheck(kategoryLists?.includes(item.id), item.id)}
                                        // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        checked={kategoryLists?.includes(item.id) ? true : false}
                                    >
                                        {item.code_name}
                                    </Item>
                                )
                            );
                        })}
                    </KategoryWrapper>
                </div>

                <SaveButtonWrapper id="save-button">
                    <SaveButton onClick={onClickSave}>저장</SaveButton>
                </SaveButtonWrapper>
            </ChooseIntersetBox>
        </ModalWindow>
    );
}

export default AddGatheringChooseInterestModal;
