import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getAiCallHistory, getAiCallTotal } from '../../api/main/aiCAll/aiCallRequest';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import ContentDetailModal from '../../designComponents/modals/ContentDetailModal';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import TableFilter from '../../designComponents/filters/TableFilter';
import MainTable from '../../designComponents/tables/MainTable';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { contentsListsTableFilter } from '../MockData/ContentsListsTableFilter';
import { checkReadFilter, contentFilter, contentTypeFilter, dateFilter } from '../../utils/textFilter';
import { Image } from '../../designComponents/imageboxs/Image';
import { PeriodSelector } from '../BannerComponent/BannerInfoModal';
import { DateInput, FilterTitle, ResetButton } from '../ProductComponent/PageStyled';
const headerData = [
    {
        key: 'start_time',
        value: '통화 시작',
    },
    {
        key: 'end_time',
        value: '통화 종료',
    },
    {
        key: 'phone_no',
        value: '발신 번호',
    },
    {
        key: 'ai_name',
        value: 'ai 이름',
    },
    {
        key: 'call_second',
        value: '통화 시간(초)',
    },
    {
        key: 'cumulative_second',
        value: '누적사용시간',
    },
    {
        key: 'is_account',
        value: '회원 여부',
    },
];
const AiCall = () => {
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [param, setParam] = useState({});
    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);
    const [totalTime, setTotalTime] = useState(0);
    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            phone_no: searchText.replace(/\s/g, '') === '' ? null : searchText,
            start_date: startDate,
            end_date: endDate,
        };
        const response = await getAiCallHistory(input);
        setLoading(false);
        return response.data;
    };
    const allCount = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            phone_no: searchText.replace(/\s/g, '') === '' ? null : searchText,
            start_date: startDate,
            end_date: endDate,
        };
        const response = await getAiCallHistory(input);
        console.log(response);

        return response.all_count;
    };
    const getTotalSecond = async () => {
        const result = await getAiCallTotal({ start_date: startDate, end_date: endDate });
        setTotalTime(result.data.exclude_second);
    };
    useEffect(() => {
        if (startDate != null || endDate != null) {
            tableRef.current?.refresh();
        }
        getTotalSecond();
    }, [startDate, endDate]);
    return (
        <Wrapper02>
            <MainPageTitle text={'79전화 이용 내역'} />
            <SearchbarWithDropdown
                style={{ margin: '45px 0 5px 0' }}
                list={[{ id: 1, title: '발신 번호', value: 1 }]}
                resetThenSet={useCallback((item) => {}, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    tableRef?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />

            <DateInput type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate || false} />
            <span> ~ </span>
            <DateInput type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate || false} />

            <MainTable
                buttonComponents={() => {
                    return <div>총 통화 시간 (무료통화 제외) : {Math.floor(totalTime / 60)}분</div>;
                }}
                rendering={loading}
                filterKey={'interest_code'}
                filterItem={(item) => <MainButton text={item.value} onClick={() => {}} />}
                tableStyle={{ width: '95%' }}
                ref={tableRef}
                loadingText={'79전화 내역 리스트를 불러오고 있습니다.'}
                headerData={headerData}
                checkID={'id'}
                param={param}
                setParam={setParam}
                toggleBlur={true}
                callApi={callApi}
                noneDataText={'내역이 존재하지 않습니다.'}
                pageCountCallApi={allCount}
                tbodyRenderItem={(key, value, data) => {
                    if (key === 'start_time' || key === 'end_time') return dateFilter(value);
                    if (key === 'ai_name') return value === '+827086859372' ? '김시연' : '에이미';
                    return value;
                }}
            />
        </Wrapper02>
    );
};

export default AiCall;
