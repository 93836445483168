import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#ffd6b1', '#b1dbff', '#00C49F', '#FFBB28', '#FF8042', '#b7edb8', '#ffff82', '#fc8fc4'];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, innerRadius, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 5) * sin;
    const mx = cx + (outerRadius + 20) * cos;
    const my = cy + (outerRadius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 3;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // 선 길이 조정
    const line = (name) => {
        switch (true) {
            case name === '10~40세':
                return <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey + 10}`} stroke={fill} fill="none" />;
            case name === '41~45세':
                return <path d={`M${sx},${sy}L${mx},${my}L${ex - 28},${ey + 60}`} stroke={fill} fill="none" />;
            case name === '45~50세':
                return <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />;
            case name === '75~80세':
                return <path d={`M${sx},${sy}L${mx},${my}L${ex + 35},${ey - 7}`} stroke={fill} fill="none" />;
            case name === '80~85세':
                return <path d={`M${sx},${sy}L${mx},${my}L${ex + 33},${ey + 12}`} stroke={fill} fill="none" />;
            case name === '85~90세':
                return <path d={`M${sx},${sy}L${mx},${my}L${ex + 33},${ey + 45}`} stroke={fill} fill="none" />;
            default:
                return <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />;
        }
    };

    // 나이 표기 위치 조정
    const age = (name) => {
        switch (true) {
            case name === '10~40세':
                return (
                    <text x={ex + (cos >= 0 ? 1 : -1) * -15} y={ey + 15} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '41~45세':
                return (
                    <text x={ex + (cos >= 0 ? 1 : -1) * -45} y={ey + 60} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '46~50세':
                return (
                    <text x={x - 3} y={y + 6} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '51~55세':
                return (
                    <text x={x + 17} y={y + 5} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '56~60세':
                return (
                    <text x={x + 7} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '61~65세':
                return (
                    <text x={x - 12} y={y - 9} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '66~70세':
                return (
                    <text x={x} y={y - 5} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '71~75세':
                return (
                    <text x={ex + (cos >= 0 ? 1 : -1) * -13} y={ey - 13} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '76~80세':
                return (
                    <text x={ex + (cos >= 0 ? 1 : -1) * 28} y={ey - 12} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '81~85세':
                return (
                    <text x={ex + (cos >= 0 ? 1 : -1) * 30} y={ey + 17} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            case name === '86~90세':
                return (
                    <text x={ex + (cos >= 0 ? 1 : -1) * 22} y={ey + 49} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
            default:
                return (
                    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '9px', fontWeight: 'bold' }}>
                        {payload.name}
                    </text>
                );
        }
    };

    // n명(n%) 표기 위치 조정
    const countAndPercent = (name) => {
        switch (true) {
            case name === '10~40세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * -9} y={ey + 24} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * -13} y={ey + 16} dy={18} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            case name === '41~45세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * -37} y={ey + 70} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * -44} y={ey + 61} dy={18} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            case name === '46~50세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * -41} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * -45} y={ey - 10} dy={18} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            case name === '66~70세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 2} y={ey - 5} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * -2} y={ey - 12} dy={16} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            case name === '71~75세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * -7} y={ey - 4} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * -11} y={ey - 14} dy={18} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            case name === '76~80세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 35} y={ey - 3} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * 29} y={ey - 12} dy={18} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            case name === '81~85세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 35} y={ey + 25} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * 31} y={ey + 16} dy={18} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            case name === '86~90세':
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * 31} y={ey + 58} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text
                            x={ex + (cos >= 0 ? 1 : -1) * 23}
                            y={ey + 49}
                            dy={18}
                            textAnchor={textAnchor}
                            fill="#999" // "#999"
                            style={{ fontSize: '9px' }}
                        >
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
            default:
                return (
                    <>
                        <text x={ex + (cos >= 0 ? 1 : -1) * -7} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontSize: '9px' }}>{`${value}명`}</text>

                        <text x={ex + (cos >= 0 ? 1 : -1) * -11} y={ey - 7} dy={18} textAnchor={textAnchor} fill="#999" style={{ fontSize: '9px' }}>
                            {`(${(percent * 100).toFixed(2)}%)`}
                        </text>
                    </>
                );
        }
    };

    return (
        <g>
            {line(payload.name)}
            {age(payload.name)}
            {countAndPercent(payload.name)}
        </g>
    );
};

function PieChartComponents({ datas }) {
    // 300 300
    return (
        <ResponsiveContainer width={350} height={350}>
            <PieChart>
                <Pie
                    data={datas}
                    cx="50%" // "30%"
                    cy="30%" // "28%"
                    label={renderActiveShape} // label={renderCustomizedLabel}, label
                    labelLine={true} // labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {datas?.map((entry, index) => {
                        return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
                    })}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}

export default PieChartComponents;
