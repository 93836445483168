const locationData = [
    {
        name: '서울특별시',
        sub: [
            {
                name: '종로구',
                sub: [
                    { name: '세종로' },
                    { name: '종로1가' },
                    { name: '운니동' },
                    { name: '경운동' },
                    { name: '관철동' },
                    { name: '인사동' },
                    { name: '낙원동' },
                    { name: '종로2가' },
                    { name: '신교동' },
                    { name: '통인동' },
                    { name: '누하동' },
                    { name: '옥인동' },
                    { name: '필운동' },
                    { name: '교남동' },
                    { name: '평동' },
                    { name: '송월동' },
                    { name: '홍파동' },
                    { name: '교북동' },
                    { name: '무악동' },
                    { name: '종로5가' },
                    { name: '서린동' },
                    { name: '숭인동' },
                    { name: '당주동' },
                    { name: '신문로1가' },
                    { name: '신문로2가' },
                    { name: '평창동' },
                    { name: '홍지동' },
                    { name: '신영동' },
                    { name: '관수동' },
                    { name: '종로3가' },
                    { name: '창신동' },
                    { name: '와룡동' },
                    { name: '인의동' },
                    { name: '예지동' },
                    { name: '원남동' },
                    { name: '종로4가' },
                    { name: '혜화동' },
                    { name: '명륜2가' },
                    { name: '명륜4가' },
                    { name: '명륜3가' },
                    { name: '연건동' },
                    { name: '연지동' },
                    { name: '효제동' },
                    { name: '종로6가' },
                    { name: '충신동' },
                    { name: '이화동' },
                    { name: '동숭동' },
                    { name: '묘동' },
                    { name: '장사동' },
                    { name: '삼청동' },
                    { name: '화동' },
                    { name: '가회동' },
                    { name: '재동' },
                    { name: '계동' },
                    { name: '적선동' },
                    { name: '내자동' },
                    { name: '사직동' },
                    { name: '행촌동' },
                    { name: '청진동' },
                    { name: '수송동' },
                    { name: '공평동' },
                    { name: '견지동' },
                    { name: '팔판동' },
                    { name: '소격동' },
                    { name: '사간동' },
                    { name: '명륜1가' },
                    { name: '관훈동' },
                    { name: '중학동' },
                    { name: '권농동' },
                    { name: '안국동' },
                    { name: '원서동' },
                    { name: '부암동' },
                    { name: '청운동' },
                    { name: '궁정동' },
                    { name: '효자동' },
                    { name: '창성동' },
                    { name: '통의동' },
                    { name: '체부동' },
                    { name: '훈정동' },
                    { name: '구기동' },
                    { name: '내수동' },
                    { name: '봉익동' },
                    { name: '익선동' },
                    { name: '돈의동' },
                    { name: '도렴동' },
                    { name: '누상동' },
                    { name: '송현동' },
                ],
            },
            {
                name: '중구',
                sub: [
                    { name: '무교동' },
                    { name: '태평로1가' },
                    { name: '북창동' },
                    { name: '태평로2가' },
                    { name: '남대문로4가' },
                    { name: '남대문로5가' },
                    { name: '봉래동1가' },
                    { name: '서소문동' },
                    { name: '정동' },
                    { name: '봉래동2가' },
                    { name: '을지로2가' },
                    { name: '장교동' },
                    { name: '충무로2가' },
                    { name: '저동1가' },
                    { name: '예장동' },
                    { name: '순화동' },
                    { name: '의주로1가' },
                    { name: '충정로1가' },
                    { name: '장충동1가' },
                    { name: '장충동2가' },
                    { name: '쌍림동' },
                    { name: '을지로5가' },
                    { name: '방산동' },
                    { name: '오장동' },
                    { name: '신당동' },
                    { name: '을지로1가' },
                    { name: '수표동' },
                    { name: '을지로3가' },
                    { name: '충무로3가' },
                    { name: '저동2가' },
                    { name: '충무로4가' },
                    { name: '충무로5가' },
                    { name: '인현동2가' },
                    { name: '예관동' },
                    { name: '을지로4가' },
                    { name: '주교동' },
                    { name: '흥인동' },
                    { name: '황학동' },
                    { name: '중림동' },
                    { name: '다동' },
                    { name: '삼각동' },
                    { name: '을지로6가' },
                    { name: '입정동' },
                    { name: '산림동' },
                    { name: '남대문로1가' },
                    { name: '소공동' },
                    { name: '남창동' },
                    { name: '남대문로2가' },
                    { name: '남대문로3가' },
                    { name: '회현동1가' },
                    { name: '충무로1가' },
                    { name: '명동1가' },
                    { name: '명동2가' },
                    { name: '광희동1가' },
                    { name: '초동' },
                    { name: '인현동1가' },
                    { name: '묵정동' },
                    { name: '필동2가' },
                    { name: '필동3가' },
                    { name: '회현동2가' },
                    { name: '남산동2가' },
                    { name: '남산동3가' },
                    { name: '을지로7가' },
                    { name: '만리동1가' },
                    { name: '필동1가' },
                    { name: '의주로2가' },
                    { name: '회현동3가' },
                    { name: '남산동1가' },
                    { name: '남학동' },
                    { name: '주자동' },
                    { name: '광희동2가' },
                    { name: '무학동' },
                    { name: '만리동2가' },
                    { name: '수하동' },
                ],
            },
            {
                name: '용산구',
                sub: [
                    { name: '용산동2가' },
                    { name: '용산동4가' },
                    { name: '이태원동' },
                    { name: '동빙고동' },
                    { name: '서빙고동' },
                    { name: '갈월동' },
                    { name: '남영동' },
                    { name: '동자동' },
                    { name: '한강로1가' },
                    { name: '한강로2가' },
                    { name: '한강로3가' },
                    { name: '한남동' },
                    { name: '원효로1가' },
                    { name: '원효로2가' },
                    { name: '효창동' },
                    { name: '용문동' },
                    { name: '문배동' },
                    { name: '신계동' },
                    { name: '청암동' },
                    { name: '도원동' },
                    { name: '서계동' },
                    { name: '청파동1가' },
                    { name: '청파동2가' },
                    { name: '청파동3가' },
                    { name: '신창동' },
                    { name: '산천동' },
                    { name: '원효로4가' },
                    { name: '이촌동' },
                    { name: '후암동' },
                    { name: '원효로3가' },
                    { name: '용산동1가' },
                    { name: '보광동' },
                    { name: '용산동5가' },
                    { name: '주성동' },
                    { name: '용산동6가' },
                    { name: '용산동3가' },
                ],
            },
            {
                name: '성동구',
                sub: [
                    { name: '용답동' },
                    { name: '성수동2가' },
                    { name: '송정동' },
                    { name: '성수동1가' },
                    { name: '금호동3가' },
                    { name: '금호동4가' },
                    { name: '금호동2가' },
                    { name: '옥수동' },
                    { name: '상왕십리동' },
                    { name: '홍익동' },
                    { name: '마장동' },
                    { name: '행당동' },
                    { name: '하왕십리동' },
                    { name: '도선동' },
                    { name: '사근동' },
                    { name: '응봉동' },
                    { name: '금호동1가' },
                ],
            },
            {
                name: '광진구',
                sub: [{ name: '중곡동' }, { name: '능동' }, { name: '구의동' }, { name: '광장동' }, { name: '군자동' }, { name: '자양동' }, { name: '화양동' }],
            },
            {
                name: '동대문구',
                sub: [
                    { name: '신설동' },
                    { name: '용두동' },
                    { name: '답십리동' },
                    { name: '장안동' },
                    { name: '전농동' },
                    { name: '휘경동' },
                    { name: '제기동' },
                    { name: '이문동' },
                    { name: '회기동' },
                    { name: '청량리동' },
                ],
            },
            {
                name: '중랑구',
                sub: [{ name: '신내동' }, { name: '면목동' }, { name: '상봉동' }, { name: '중화동' }, { name: '묵동' }, { name: '망우동' }],
            },
            {
                name: '성북구',
                sub: [
                    { name: '동소문동5가' },
                    { name: '삼선동4가' },
                    { name: '삼선동5가' },
                    { name: '보문동4가' },
                    { name: '보문동5가' },
                    { name: '보문동7가' },
                    { name: '보문동1가' },
                    { name: '보문동2가' },
                    { name: '성북동1가' },
                    { name: '삼선동1가' },
                    { name: '안암동4가' },
                    { name: '안암동5가' },
                    { name: '종암동' },
                    { name: '하월곡동' },
                    { name: '장위동' },
                    { name: '석관동' },
                    { name: '길음동' },
                    { name: '정릉동' },
                    { name: '상월곡동' },
                    { name: '성북동' },
                    { name: '돈암동' },
                    { name: '동소문동1가' },
                    { name: '동소문동2가' },
                    { name: '동소문동3가' },
                    { name: '동소문동4가' },
                    { name: '동소문동6가' },
                    { name: '동선동1가' },
                    { name: '동선동4가' },
                    { name: '삼선동2가' },
                    { name: '삼선동3가' },
                    { name: '동소문동7가' },
                    { name: '동선동5가' },
                    { name: '안암동2가' },
                    { name: '안암동3가' },
                    { name: '보문동6가' },
                    { name: '보문동3가' },
                    { name: '동선동2가' },
                    { name: '동선동3가' },
                    { name: '안암동1가' },
                ],
            },
            { name: '강북구', sub: [{ name: '미아동' }, { name: '번동' }, { name: '수유동' }, { name: '우이동' }] },
            {
                name: '도봉구',
                sub: [{ name: '창동' }, { name: '쌍문동' }, { name: '방학동' }, { name: '도봉동' }],
            },
            {
                name: '노원구',
                sub: [{ name: '공릉동' }, { name: '상계동' }, { name: '중계동' }, { name: '하계동' }, { name: '월계동' }],
            },
            {
                name: '은평구',
                sub: [
                    { name: '진관동' },
                    { name: '녹번동' },
                    { name: '갈현동' },
                    { name: '구산동' },
                    { name: '대조동' },
                    { name: '응암동' },
                    { name: '역촌동' },
                    { name: '불광동' },
                    { name: '신사동' },
                    { name: '수색동' },
                    { name: '증산동' },
                ],
            },
            {
                name: '서대문구',
                sub: [
                    { name: '충정로2가' },
                    { name: '미근동' },
                    { name: '냉천동' },
                    { name: '천연동' },
                    { name: '옥천동' },
                    { name: '영천동' },
                    { name: '현저동' },
                    { name: '홍제동' },
                    { name: '홍은동' },
                    { name: '충정로3가' },
                    { name: '합동' },
                    { name: '연희동' },
                    { name: '북가좌동' },
                    { name: '남가좌동' },
                    { name: '북아현동' },
                    { name: '대현동' },
                    { name: '창천동' },
                    { name: '대신동' },
                    { name: '신촌동' },
                    { name: '봉원동' },
                ],
            },
            {
                name: '마포구',
                sub: [
                    { name: '상암동' },
                    { name: '아현동' },
                    { name: '공덕동' },
                    { name: '도화동' },
                    { name: '마포동' },
                    { name: '신공덕동' },
                    { name: '대흥동' },
                    { name: '염리동' },
                    { name: '노고산동' },
                    { name: '신수동' },
                    { name: '성산동' },
                    { name: '동교동' },
                    { name: '망원동' },
                    { name: '서교동' },
                    { name: '합정동' },
                    { name: '연남동' },
                    { name: '용강동' },
                    { name: '현석동' },
                    { name: '구수동' },
                    { name: '창전동' },
                    { name: '상수동' },
                    { name: '신정동' },
                    { name: '중동' },
                    { name: '당인동' },
                    { name: '하중동' },
                    { name: '토정동' },
                ],
            },
            { name: '양천구', sub: [{ name: '신정동' }, { name: '신월동' }, { name: '목동' }] },
            {
                name: '강서구',
                sub: [
                    { name: '내발산동' },
                    { name: '외발산동' },
                    { name: '공항동' },
                    { name: '염창동' },
                    { name: '등촌동' },
                    { name: '화곡동' },
                    { name: '가양동' },
                    { name: '마곡동' },
                    { name: '방화동' },
                    { name: '개화동' },
                    { name: '오쇠동' },
                    { name: '오곡동' },
                    { name: '과해동' },
                ],
            },
            {
                name: '구로구',
                sub: [
                    { name: '구로동' },
                    { name: '가리봉동' },
                    { name: '개봉동' },
                    { name: '고척동' },
                    { name: '천왕동' },
                    { name: '궁동' },
                    { name: '온수동' },
                    { name: '오류동' },
                    { name: '항동' },
                    { name: '신도림동' },
                ],
            },
            { name: '금천구', sub: [{ name: '가산동' }, { name: '독산동' }, { name: '시흥동' }] },
            {
                name: '영등포구',
                sub: [
                    { name: '영등포동7가' },
                    { name: '영등포동8가' },
                    { name: '여의도동' },
                    { name: '당산동3가' },
                    { name: '당산동4가' },
                    { name: '양평동3가' },
                    { name: '양평동' },
                    { name: '대림동' },
                    { name: '신길동' },
                    { name: '영등포동' },
                    { name: '영등포동1가' },
                    { name: '영등포동3가' },
                    { name: '영등포동4가' },
                    { name: '문래동1가' },
                    { name: '문래동2가' },
                    { name: '문래동3가' },
                    { name: '당산동' },
                    { name: '문래동4가' },
                    { name: '문래동5가' },
                    { name: '문래동6가' },
                    { name: '양평동1가' },
                    { name: '양평동4가' },
                    { name: '도림동' },
                    { name: '당산동1가' },
                    { name: '당산동2가' },
                    { name: '당산동5가' },
                    { name: '당산동6가' },
                    { name: '양평동2가' },
                    { name: '영등포동2가' },
                    { name: '영등포동5가' },
                    { name: '영등포동6가' },
                    { name: '양평동5가' },
                    { name: '양화동' },
                    { name: '양평동6가' },
                ],
            },
            {
                name: '동작구',
                sub: [
                    { name: '사당동' },
                    { name: '동작동' },
                    { name: '신대방동' },
                    { name: '대방동' },
                    { name: '상도동' },
                    { name: '상도1동' },
                    { name: '노량진동' },
                    { name: '본동' },
                    { name: '흑석동' },
                ],
            },
            { name: '관악구', sub: [{ name: '봉천동' }, { name: '신림동' }, { name: '남현동' }] },
            {
                name: '서초구',
                sub: [
                    { name: '방배동' },
                    { name: '양재동' },
                    { name: '서초동' },
                    { name: '염곡동' },
                    { name: '우면동' },
                    { name: '반포동' },
                    { name: '잠원동' },
                    { name: '원지동' },
                    { name: '신원동' },
                    { name: '내곡동' },
                ],
            },
            {
                name: '강남구',
                sub: [
                    { name: '대치동' },
                    { name: '일원동' },
                    { name: '도곡동' },
                    { name: '개포동' },
                    { name: '수서동' },
                    { name: '역삼동' },
                    { name: '신사동' },
                    { name: '논현동' },
                    { name: '압구정동' },
                    { name: '청담동' },
                    { name: '삼성동' },
                    { name: '세곡동' },
                    { name: '율현동' },
                    { name: '자곡동' },
                ],
            },
            {
                name: '송파구',
                sub: [
                    { name: '장지동' },
                    { name: '가락동' },
                    { name: '방이동' },
                    { name: '오금동' },
                    { name: '풍납동' },
                    { name: '거여동' },
                    { name: '잠실동' },
                    { name: '신천동' },
                    { name: '송파동' },
                    { name: '석촌동' },
                    { name: '문정동' },
                    { name: '마천동' },
                    { name: '삼전동' },
                ],
            },
            {
                name: '강동구',
                sub: [{ name: '명일동' }, { name: '고덕동' }, { name: '길동' }, { name: '둔촌동' }, { name: '성내동' }, { name: '천호동' }, { name: '상일동' }, { name: '암사동' }, { name: '강일동' }],
            },
        ],
    },
    {
        name: '부산광역시',
        sub: [
            {
                name: '중구',
                sub: [
                    { name: '대창동1가' },
                    { name: '대창동2가' },
                    { name: '중앙동2가' },
                    { name: '중앙동3가' },
                    { name: '중앙동4가' },
                    { name: '중앙동5가' },
                    { name: '중앙동6가' },
                    { name: '중앙동7가' },
                    { name: '동광동1가' },
                    { name: '보수동1가' },
                    { name: '보수동2가' },
                    { name: '보수동3가' },
                    { name: '부평동2가' },
                    { name: '부평동3가' },
                    { name: '부평동4가' },
                    { name: '남포동6가' },
                    { name: '영주동' },
                    { name: '남포동1가' },
                    { name: '광복동1가' },
                    { name: '남포동2가' },
                    { name: '남포동4가' },
                    { name: '남포동5가' },
                    { name: '부평동1가' },
                    { name: '창선동1가' },
                    { name: '창선동2가' },
                    { name: '광복동2가' },
                    { name: '대청동2가' },
                    { name: '신창동1가' },
                    { name: '광복동3가' },
                    { name: '동광동5가' },
                    { name: '남포동3가' },
                    { name: '대청동1가' },
                    { name: '대청동3가' },
                    { name: '대청동4가' },
                    { name: '신창동4가' },
                    { name: '중앙동1가' },
                    { name: '동광동3가' },
                    { name: '동광동4가' },
                    { name: '신창동3가' },
                    { name: '신창동2가' },
                    { name: '동광동2가' },
                ],
            },
            {
                name: '서구',
                sub: [
                    { name: '서대신동2가' },
                    { name: '동대신동1가' },
                    { name: '동대신동2가' },
                    { name: '동대신동3가' },
                    { name: '서대신동3가' },
                    { name: '부용동1가' },
                    { name: '부민동1가' },
                    { name: '토성동1가' },
                    { name: '토성동2가' },
                    { name: '충무동1가' },
                    { name: '토성동4가' },
                    { name: '남부민동' },
                    { name: '암남동' },
                    { name: '서대신동1가' },
                    { name: '부용동2가' },
                    { name: '부민동2가' },
                    { name: '토성동3가' },
                    { name: '아미동1가' },
                    { name: '토성동5가' },
                    { name: '초장동' },
                    { name: '충무동2가' },
                    { name: '아미동2가' },
                    { name: '부민동3가' },
                    { name: '충무동3가' },
                ],
            },
            { name: '동구', sub: [{ name: '초량동' }, { name: '수정동' }, { name: '좌천동' }, { name: '범일동' }] },
            {
                name: '영도구',
                sub: [
                    { name: '남항동3가' },
                    { name: '영선동1가' },
                    { name: '영선동2가' },
                    { name: '영선동3가' },
                    { name: '영선동4가' },
                    { name: '대교동1가' },
                    { name: '대교동2가' },
                    { name: '봉래동3가' },
                    { name: '봉래동4가' },
                    { name: '봉래동5가' },
                    { name: '청학동' },
                    { name: '동삼동' },
                    { name: '봉래동1가' },
                    { name: '봉래동2가' },
                    { name: '남항동1가' },
                    { name: '남항동2가' },
                    { name: '대평동1가' },
                    { name: '대평동2가' },
                    { name: '신선동3가' },
                    { name: '신선동2가' },
                    { name: '신선동1가' },
                ],
            },
            {
                name: '부산진구',
                sub: [
                    { name: '양정동' },
                    { name: '전포동' },
                    { name: '부전동' },
                    { name: '범천동' },
                    { name: '범전동' },
                    { name: '부암동' },
                    { name: '당감동' },
                    { name: '개금동' },
                    { name: '가야동' },
                    { name: '연지동' },
                    { name: '초읍동' },
                ],
            },
            {
                name: '동래구',
                sub: [
                    { name: '명륜동' },
                    { name: '온천동' },
                    { name: '사직동' },
                    { name: '안락동' },
                    { name: '칠산동' },
                    { name: '낙민동' },
                    { name: '수안동' },
                    { name: '복천동' },
                    { name: '명장동' },
                ],
            },
            {
                name: '남구',
                sub: [{ name: '문현동' }, { name: '대연동' }, { name: '감만동' }, { name: '우암동' }, { name: '용호동' }, { name: '용당동' }],
            },
            {
                name: '북구',
                sub: [{ name: '덕천동' }, { name: '구포동' }, { name: '만덕동' }, { name: '금곡동' }, { name: '화명동' }],
            },
            {
                name: '해운대구',
                sub: [{ name: '재송동' }, { name: '반여동' }, { name: '우동' }, { name: '중동' }, { name: '좌동' }, { name: '송정동' }, { name: '반송동' }, { name: '석대동' }],
            },
            {
                name: '사하구',
                sub: [{ name: '괴정동' }, { name: '당리동' }, { name: '하단동' }, { name: '신평동' }, { name: '장림동' }, { name: '구평동' }, { name: '감천동' }, { name: '다대동' }],
            },
            {
                name: '금정구',
                sub: [
                    { name: '두구동' },
                    { name: '노포동' },
                    { name: '청룡동' },
                    { name: '남산동' },
                    { name: '구서동' },
                    { name: '장전동' },
                    { name: '부곡동' },
                    { name: '금사동' },
                    { name: '금성동' },
                    { name: '선동' },
                    { name: '회동동' },
                    { name: '서동' },
                    { name: '오륜동' },
                ],
            },
            {
                name: '강서구',
                sub: [
                    { name: '강동동' },
                    { name: '대저2동' },
                    { name: '봉림동' },
                    { name: '동선동' },
                    { name: '천성동' },
                    { name: '명지동' },
                    { name: '죽림동' },
                    { name: '송정동' },
                    { name: '생곡동' },
                    { name: '구랑동' },
                    { name: '미음동' },
                    { name: '범방동' },
                    { name: '신호동' },
                    { name: '화전동' },
                    { name: '녹산동' },
                    { name: '지사동' },
                    { name: '식만동' },
                    { name: '죽동동' },
                    { name: '대저1동' },
                    { name: '성북동' },
                    { name: '대항동' },
                    { name: '눌차동' },
                ],
            },
            { name: '연제구', sub: [{ name: '거제동' }, { name: '연산동' }] },
            {
                name: '수영구',
                sub: [{ name: '남천동' }, { name: '망미동' }, { name: '수영동' }, { name: '광안동' }, { name: '민락동' }],
            },
            {
                name: '사상구',
                sub: [{ name: '삼락동' }, { name: '괘법동' }, { name: '감전동' }, { name: '엄궁동' }, { name: '모라동' }, { name: '덕포동' }, { name: '주례동' }, { name: '학장동' }],
            },
            { name: '기장군', sub: [{ name: '장안읍' }, { name: '정관읍' }, { name: '기장읍' }, { name: '일광면' }, { name: '철마면' }] },
        ],
    },
    {
        name: '대구광역시',
        sub: [
            {
                name: '중구',
                sub: [
                    { name: '덕산동' },
                    { name: '사일동' },
                    { name: '동일동' },
                    { name: '남일동' },
                    { name: '전동' },
                    { name: '동성로3가' },
                    { name: '동성로2가' },
                    { name: '포정동' },
                    { name: '동성로1가' },
                    { name: '태평로2가' },
                    { name: '북성로1가' },
                    { name: '화전동' },
                    { name: '향촌동' },
                    { name: '남산동' },
                    { name: '삼덕동1가' },
                    { name: '삼덕동2가' },
                    { name: '삼덕동3가' },
                    { name: '봉산동' },
                    { name: '남성로' },
                    { name: '계산동2가' },
                    { name: '동산동' },
                    { name: '대신동' },
                    { name: '대봉동' },
                    { name: '동인동2가' },
                    { name: '동인동4가' },
                    { name: '상서동' },
                    { name: '수동' },
                    { name: '종로1가' },
                    { name: '문화동' },
                    { name: '공평동' },
                    { name: '시장북로' },
                    { name: '하서동' },
                    { name: '계산동1가' },
                    { name: '서문로2가' },
                    { name: '서성로2가' },
                    { name: '서문로1가' },
                    { name: '동인동1가' },
                    { name: '동문동' },
                    { name: '교동' },
                    { name: '달성동' },
                    { name: '태평로3가' },
                    { name: '종로2가' },
                    { name: '수창동' },
                    { name: '인교동' },
                    { name: '태평로1가' },
                    { name: '용덕동' },
                    { name: '서야동' },
                    { name: '서성로1가' },
                    { name: '서내동' },
                    { name: '북성로2가' },
                    { name: '대안동' },
                    { name: '장관동' },
                    { name: '동인동3가' },
                    { name: '도원동' },
                    { name: '북내동' },
                    { name: '상덕동' },
                    { name: '완전동' },
                ],
            },
            {
                name: '동구',
                sub: [
                    { name: '신서동' },
                    { name: '괴전동' },
                    { name: '사복동' },
                    { name: '동내동' },
                    { name: '각산동' },
                    { name: '대림동' },
                    { name: '숙천동' },
                    { name: '율암동' },
                    { name: '내곡동' },
                    { name: '봉무동' },
                    { name: '불로동' },
                    { name: '지저동' },
                    { name: '능성동' },
                    { name: '진인동' },
                    { name: '백안동' },
                    { name: '미곡동' },
                    { name: '미대동' },
                    { name: '지묘동' },
                    { name: '도학동' },
                    { name: '용수동' },
                    { name: '신무동' },
                    { name: '신용동' },
                    { name: '중대동' },
                    { name: '송정동' },
                    { name: '덕곡동' },
                    { name: '서호동' },
                    { name: '동호동' },
                    { name: '신천동' },
                    { name: '입석동' },
                    { name: '신암동' },
                    { name: '효목동' },
                    { name: '율하동' },
                    { name: '금강동' },
                    { name: '용계동' },
                    { name: '내동' },
                    { name: '평광동' },
                    { name: '도동' },
                    { name: '검사동' },
                    { name: '방촌동' },
                    { name: '신평동' },
                    { name: '둔산동' },
                    { name: '부동' },
                    { name: '상매동' },
                    { name: '매여동' },
                    { name: '신기동' },
                ],
            },
            {
                name: '서구',
                sub: [
                    { name: '내당동' },
                    { name: '비산동' },
                    { name: '평리동' },
                    { name: '중리동' },
                    { name: '이현동' },
                    { name: '원대동1가' },
                    { name: '원대동3가' },
                    { name: '원대동2가' },
                    { name: '상리동' },
                ],
            },
            { name: '남구', sub: [{ name: '봉덕동' }, { name: '대명동' }, { name: '이천동' }] },
            {
                name: '북구',
                sub: [
                    { name: '검단동' },
                    { name: '읍내동' },
                    { name: '칠성동2가' },
                    { name: '침산동' },
                    { name: '관음동' },
                    { name: '태전동' },
                    { name: '매천동' },
                    { name: '팔달동' },
                    { name: '금호동' },
                    { name: '사수동' },
                    { name: '복현동' },
                    { name: '대현동' },
                    { name: '산격동' },
                    { name: '고성동2가' },
                    { name: '고성동3가' },
                    { name: '칠성동1가' },
                    { name: '서변동' },
                    { name: '연경동' },
                    { name: '노원동1가' },
                    { name: '노원동2가' },
                    { name: '노원동3가' },
                    { name: '동호동' },
                    { name: '학정동' },
                    { name: '국우동' },
                    { name: '도남동' },
                    { name: '동천동' },
                    { name: '구암동' },
                    { name: '노곡동' },
                    { name: '동변동' },
                    { name: '조야동' },
                    { name: '고성동1가' },
                ],
            },
            {
                name: '수성구',
                sub: [
                    { name: '가천동' },
                    { name: '범어동' },
                    { name: '만촌동' },
                    { name: '수성동1가' },
                    { name: '수성동2가' },
                    { name: '수성동3가' },
                    { name: '수성동4가' },
                    { name: '시지동' },
                    { name: '매호동' },
                    { name: '사월동' },
                    { name: '신매동' },
                    { name: '노변동' },
                    { name: '연호동' },
                    { name: '이천동' },
                    { name: '성동' },
                    { name: '황금동' },
                    { name: '두산동' },
                    { name: '지산동' },
                    { name: '범물동' },
                    { name: '삼덕동' },
                    { name: '중동' },
                    { name: '상동' },
                    { name: '파동' },
                    { name: '고모동' },
                    { name: '욱수동' },
                    { name: '대흥동' },
                ],
            },
            {
                name: '달서구',
                sub: [
                    { name: '월성동' },
                    { name: '두류동' },
                    { name: '파호동' },
                    { name: '신당동' },
                    { name: '이곡동' },
                    { name: '장기동' },
                    { name: '용산동' },
                    { name: '죽전동' },
                    { name: '감삼동' },
                    { name: '호산동' },
                    { name: '호림동' },
                    { name: '갈산동' },
                    { name: '유천동' },
                    { name: '대천동' },
                    { name: '월암동' },
                    { name: '성당동' },
                    { name: '상인동' },
                    { name: '송현동' },
                    { name: '진천동' },
                    { name: '대곡동' },
                    { name: '도원동' },
                    { name: '본리동' },
                    { name: '본동' },
                    { name: '장동' },
                ],
            },
            {
                name: '달성군',
                sub: [
                    { name: '논공읍' },
                    { name: '현풍읍' },
                    { name: '하빈면' },
                    { name: '다사읍' },
                    { name: '유가읍' },
                    { name: '구지면' },
                    { name: '화원읍' },
                    { name: '가창면' },
                    { name: '옥포읍' },
                ],
            },
        ],
    },
    {
        name: '인천광역시',
        sub: [
            {
                name: '중구',
                sub: [
                    { name: '운남동' },
                    { name: '항동1가' },
                    { name: '항동7가' },
                    { name: '신흥동1가' },
                    { name: '신흥동2가' },
                    { name: '신흥동3가' },
                    { name: '유동' },
                    { name: '북성동1가' },
                    { name: '운서동' },
                    { name: '중산동' },
                    { name: '운북동' },
                    { name: '중앙동4가' },
                    { name: '관동3가' },
                    { name: '신포동' },
                    { name: '내동' },
                    { name: '경동' },
                    { name: '용동' },
                    { name: '을왕동' },
                    { name: '남북동' },
                    { name: '덕교동' },
                    { name: '답동' },
                    { name: '율목동' },
                    { name: '무의동' },
                    { name: '도원동' },
                    { name: '선화동' },
                    { name: '송월동1가' },
                    { name: '항동6가' },
                    { name: '송학동2가' },
                    { name: '송학동3가' },
                    { name: '사동' },
                    { name: '신생동' },
                    { name: '인현동' },
                    { name: '선린동' },
                    { name: '관동1가' },
                    { name: '송학동1가' },
                    { name: '전동' },
                    { name: '송월동3가' },
                    { name: '북성동3가' },
                    { name: '북성동2가' },
                    { name: '송월동2가' },
                    { name: '중앙동3가' },
                    { name: '해안동4가' },
                    { name: '관동2가' },
                    { name: '항동4가' },
                    { name: '항동5가' },
                    { name: '해안동2가' },
                    { name: '해안동3가' },
                    { name: '항동2가' },
                    { name: '항동3가' },
                    { name: '중앙동1가' },
                    { name: '중앙동2가' },
                    { name: '해안동1가' },
                ],
            },
            {
                name: '동구',
                sub: [{ name: '송림동' }, { name: '창영동' }, { name: '금곡동' }, { name: '송현동' }, { name: '화수동' }, { name: '화평동' }, { name: '만석동' }],
            },
            {
                name: '미추홀구',
                sub: [{ name: '도화동' }, { name: '학익동' }, { name: '주안동' }, { name: '문학동' }, { name: '숭의동' }, { name: '용현동' }, { name: '관교동' }],
            },
            {
                name: '연수구',
                sub: [{ name: '옥련동' }, { name: '선학동' }, { name: '연수동' }, { name: '청학동' }, { name: '동춘동' }, { name: '송도동' }],
            },
            {
                name: '남동구',
                sub: [
                    { name: '고잔동' },
                    { name: '남촌동' },
                    { name: '도림동' },
                    { name: '구월동' },
                    { name: '만수동' },
                    { name: '장수동' },
                    { name: '서창동' },
                    { name: '논현동' },
                    { name: '간석동' },
                    { name: '운연동' },
                    { name: '수산동' },
                ],
            },
            {
                name: '부평구',
                sub: [
                    { name: '구산동' },
                    { name: '부평동' },
                    { name: '십정동' },
                    { name: '산곡동' },
                    { name: '청천동' },
                    { name: '갈산동' },
                    { name: '부개동' },
                    { name: '일신동' },
                    { name: '삼산동' },
                ],
            },
            {
                name: '계양구',
                sub: [
                    { name: '서운동' },
                    { name: '효성동' },
                    { name: '작전동' },
                    { name: '계산동' },
                    { name: '임학동' },
                    { name: '용종동' },
                    { name: '병방동' },
                    { name: '선주지동' },
                    { name: '이화동' },
                    { name: '갈현동' },
                    { name: '장기동' },
                    { name: '둑실동' },
                    { name: '상야동' },
                    { name: '방축동' },
                    { name: '목상동' },
                    { name: '다남동' },
                    { name: '박촌동' },
                    { name: '귤현동' },
                    { name: '오류동' },
                    { name: '동양동' },
                    { name: '하야동' },
                    { name: '평동' },
                ],
            },
            {
                name: '서구',
                sub: [
                    { name: '백석동' },
                    { name: '검암동' },
                    { name: '경서동' },
                    { name: '연희동' },
                    { name: '심곡동' },
                    { name: '신현동' },
                    { name: '석남동' },
                    { name: '원창동' },
                    { name: '가좌동' },
                    { name: '금곡동' },
                    { name: '오류동' },
                    { name: '왕길동' },
                    { name: '가정동' },
                    { name: '청라동' },
                    { name: '공촌동' },
                    { name: '마전동' },
                    { name: '당하동' },
                    { name: '원당동' },
                    { name: '불로동' },
                    { name: '대곡동' },
                    { name: '시천동' },
                ],
            },
            {
                name: '강화군',
                sub: [
                    { name: '강화읍' },
                    { name: '하점면' },
                    { name: '송해면' },
                    { name: '선원면' },
                    { name: '불은면' },
                    { name: '양도면' },
                    { name: '내가면' },
                    { name: '길상면' },
                    { name: '화도면' },
                    { name: '교동면' },
                    { name: '양사면' },
                    { name: '삼산면' },
                    { name: '서도면' },
                ],
            },
            {
                name: '옹진군',
                sub: [{ name: '덕적면' }, { name: '영흥면' }, { name: '자월면' }, { name: '대청면' }, { name: '백령면' }, { name: '북도면' }, { name: '연평면' }],
            },
        ],
    },
    {
        name: '광주광역시',
        sub: [
            {
                name: '동구',
                sub: [
                    { name: '학동' },
                    { name: '동명동' },
                    { name: '계림동' },
                    { name: '산수동' },
                    { name: '지산동' },
                    { name: '서석동' },
                    { name: '장동' },
                    { name: '선교동' },
                    { name: '대인동' },
                    { name: '금남로5가' },
                    { name: '충장로5가' },
                    { name: '수기동' },
                    { name: '남동' },
                    { name: '금동' },
                    { name: '대의동' },
                    { name: '궁동' },
                    { name: '용산동' },
                    { name: '소태동' },
                    { name: '충장로1가' },
                    { name: '충장로2가' },
                    { name: '충장로3가' },
                    { name: '충장로4가' },
                    { name: '금남로1가' },
                    { name: '금남로2가' },
                    { name: '금남로4가' },
                    { name: '금남로3가' },
                    { name: '호남동' },
                    { name: '불로동' },
                    { name: '월남동' },
                    { name: '내남동' },
                    { name: '광산동' },
                    { name: '황금동' },
                    { name: '용연동' },
                    { name: '운림동' },
                ],
            },
            {
                name: '서구',
                sub: [
                    { name: '농성동' },
                    { name: '양동' },
                    { name: '쌍촌동' },
                    { name: '화정동' },
                    { name: '치평동' },
                    { name: '내방동' },
                    { name: '벽진동' },
                    { name: '마륵동' },
                    { name: '광천동' },
                    { name: '동천동' },
                    { name: '유촌동' },
                    { name: '덕흥동' },
                    { name: '서창동' },
                    { name: '풍암동' },
                    { name: '금호동' },
                    { name: '매월동' },
                    { name: '용두동' },
                    { name: '세하동' },
                ],
            },
            {
                name: '남구',
                sub: [
                    { name: '월산동' },
                    { name: '백운동' },
                    { name: '주월동' },
                    { name: '방림동' },
                    { name: '진월동' },
                    { name: '행암동' },
                    { name: '임암동' },
                    { name: '송하동' },
                    { name: '양과동' },
                    { name: '이장동' },
                    { name: '지석동' },
                    { name: '압촌동' },
                    { name: '구동' },
                    { name: '서동' },
                    { name: '양림동' },
                    { name: '사동' },
                    { name: '칠석동' },
                    { name: '노대동' },
                    { name: '봉선동' },
                    { name: '양촌동' },
                    { name: '승촌동' },
                    { name: '대지동' },
                    { name: '원산동' },
                    { name: '월성동' },
                    { name: '도금동' },
                    { name: '화장동' },
                    { name: '구소동' },
                    { name: '석정동' },
                    { name: '덕남동' },
                    { name: '신장동' },
                ],
            },
            {
                name: '북구',
                sub: [
                    { name: '장등동' },
                    { name: '동림동' },
                    { name: '운암동' },
                    { name: '임동' },
                    { name: '신용동' },
                    { name: '용두동' },
                    { name: '오룡동' },
                    { name: '풍향동' },
                    { name: '지야동' },
                    { name: '연제동' },
                    { name: '월출동' },
                    { name: '중흥동' },
                    { name: '신안동' },
                    { name: '우산동' },
                    { name: '각화동' },
                    { name: '문흥동' },
                    { name: '두암동' },
                    { name: '망월동' },
                    { name: '용전동' },
                    { name: '대촌동' },
                    { name: '북동' },
                    { name: '유동' },
                    { name: '누문동' },
                    { name: '금곡동' },
                    { name: '청풍동' },
                    { name: '화암동' },
                    { name: '용봉동' },
                    { name: '오치동' },
                    { name: '매곡동' },
                    { name: '충효동' },
                    { name: '운정동' },
                    { name: '태령동' },
                    { name: '본촌동' },
                    { name: '양산동' },
                    { name: '삼각동' },
                    { name: '일곡동' },
                    { name: '수곡동' },
                    { name: '효령동' },
                    { name: '생용동' },
                    { name: '덕의동' },
                    { name: '용강동' },
                ],
            },
            {
                name: '광산구',
                sub: [
                    { name: '지죽동' },
                    { name: '하남동' },
                    { name: '비아동' },
                    { name: '수완동' },
                    { name: '신창동' },
                    { name: '도천동' },
                    { name: '송정동' },
                    { name: '도산동' },
                    { name: '신촌동' },
                    { name: '소촌동' },
                    { name: '우산동' },
                    { name: '복룡동' },
                    { name: '월계동' },
                    { name: '쌍암동' },
                    { name: '신가동' },
                    { name: '운남동' },
                    { name: '장덕동' },
                    { name: '흑석동' },
                    { name: '월곡동' },
                    { name: '산정동' },
                    { name: '운수동' },
                    { name: '선암동' },
                    { name: '서봉동' },
                    { name: '지평동' },
                    { name: '본덕동' },
                    { name: '용봉동' },
                    { name: '동산동' },
                    { name: '삼거동' },
                    { name: '대산동' },
                    { name: '송학동' },
                    { name: '신동' },
                    { name: '삼도동' },
                    { name: '명도동' },
                    { name: '동호동' },
                    { name: '덕림동' },
                    { name: '양산동' },
                    { name: '동림동' },
                    { name: '도덕동' },
                    { name: '오운동' },
                    { name: '양동' },
                    { name: '장수동' },
                    { name: '등임동' },
                    { name: '산막동' },
                    { name: '고룡동' },
                    { name: '임곡동' },
                    { name: '광산동' },
                    { name: '남산동' },
                    { name: '송치동' },
                    { name: '송산동' },
                    { name: '내산동' },
                    { name: '하산동' },
                    { name: '월전동' },
                    { name: '장록동' },
                    { name: '송촌동' },
                    { name: '송대동' },
                    { name: '요기동' },
                    { name: '유계동' },
                    { name: '박호동' },
                    { name: '왕동' },
                    { name: '산월동' },
                    { name: '안청동' },
                    { name: '진곡동' },
                    { name: '오선동' },
                    { name: '황룡동' },
                    { name: '산수동' },
                    { name: '선동' },
                    { name: '지산동' },
                    { name: '북산동' },
                    { name: '두정동' },
                    { name: '신룡동' },
                    { name: '옥동' },
                    { name: '용곡동' },
                    { name: '명화동' },
                    { name: '용동' },
                    { name: '연산동' },
                    { name: '오산동' },
                    { name: '지정동' },
                    { name: '도호동' },
                    { name: '사호동' },
                ],
            },
        ],
    },
    {
        name: '대전광역시',
        sub: [
            {
                name: '동구',
                sub: [
                    { name: '구도동' },
                    { name: '삼정동' },
                    { name: '가양동' },
                    { name: '용전동' },
                    { name: '성남동' },
                    { name: '홍도동' },
                    { name: '삼성동' },
                    { name: '낭월동' },
                    { name: '삼괴동' },
                    { name: '대성동' },
                    { name: '상소동' },
                    { name: '하소동' },
                    { name: '신상동' },
                    { name: '비룡동' },
                    { name: '신흥동' },
                    { name: '판암동' },
                    { name: '세천동' },
                    { name: '신하동' },
                    { name: '신촌동' },
                    { name: '사성동' },
                    { name: '오동' },
                    { name: '주촌동' },
                    { name: '내탑동' },
                    { name: '인동' },
                    { name: '효동' },
                    { name: '대동' },
                    { name: '자양동' },
                    { name: '신안동' },
                    { name: '소제동' },
                    { name: '원동' },
                    { name: '천동' },
                    { name: '가오동' },
                    { name: '정동' },
                    { name: '중동' },
                    { name: '추동' },
                    { name: '주산동' },
                    { name: '마산동' },
                    { name: '효평동' },
                    { name: '용운동' },
                    { name: '이사동' },
                    { name: '대별동' },
                    { name: '장척동' },
                    { name: '소호동' },
                    { name: '직동' },
                ],
            },
            {
                name: '중구',
                sub: [
                    { name: '안영동' },
                    { name: '선화동' },
                    { name: '목동' },
                    { name: '중촌동' },
                    { name: '용두동' },
                    { name: '오류동' },
                    { name: '태평동' },
                    { name: '유천동' },
                    { name: '산성동' },
                    { name: '사정동' },
                    { name: '문화동' },
                    { name: '은행동' },
                    { name: '대흥동' },
                    { name: '문창동' },
                    { name: '석교동' },
                    { name: '호동' },
                    { name: '옥계동' },
                    { name: '대사동' },
                    { name: '부사동' },
                    { name: '침산동' },
                    { name: '목달동' },
                    { name: '정생동' },
                    { name: '금동' },
                    { name: '어남동' },
                    { name: '무수동' },
                    { name: '구완동' },
                ],
            },
            {
                name: '서구',
                sub: [
                    { name: '관저동' },
                    { name: '월평동' },
                    { name: '도안동' },
                    { name: '괴정동' },
                    { name: '내동' },
                    { name: '갈마동' },
                    { name: '둔산동' },
                    { name: '만년동' },
                    { name: '변동' },
                    { name: '가장동' },
                    { name: '도마동' },
                    { name: '정림동' },
                    { name: '가수원동' },
                    { name: '흑석동' },
                    { name: '매노동' },
                    { name: '평촌동' },
                    { name: '오동' },
                    { name: '우명동' },
                    { name: '괴곡동' },
                    { name: '용문동' },
                    { name: '탄방동' },
                    { name: '복수동' },
                    { name: '산직동' },
                    { name: '장안동' },
                    { name: '봉곡동' },
                    { name: '원정동' },
                    { name: '용촌동' },
                ],
            },
            {
                name: '유성구',
                sub: [
                    { name: '장대동' },
                    { name: '화암동' },
                    { name: '구성동' },
                    { name: '가정동' },
                    { name: '도룡동' },
                    { name: '방현동' },
                    { name: '덕진동' },
                    { name: '봉산동' },
                    { name: '관평동' },
                    { name: '송강동' },
                    { name: '대정동' },
                    { name: '봉명동' },
                    { name: '상대동' },
                    { name: '계산동' },
                    { name: '덕명동' },
                    { name: '원신흥동' },
                    { name: '용계동' },
                    { name: '구암동' },
                    { name: '노은동' },
                    { name: '궁동' },
                    { name: '원내동' },
                    { name: '교촌동' },
                    { name: '복용동' },
                    { name: '죽동' },
                    { name: '신성동' },
                    { name: '장동' },
                    { name: '하기동' },
                    { name: '자운동' },
                    { name: '전민동' },
                    { name: '학하동' },
                    { name: '송정동' },
                    { name: '방동' },
                    { name: '둔곡동' },
                    { name: '구룡동' },
                    { name: '금고동' },
                    { name: '신동' },
                    { name: '금탄동' },
                    { name: '안산동' },
                    { name: '원촌동' },
                    { name: '갑동' },
                    { name: '탑립동' },
                    { name: '용산동' },
                    { name: '어은동' },
                    { name: '지족동' },
                    { name: '대동' },
                    { name: '문지동' },
                    { name: '반석동' },
                    { name: '외삼동' },
                    { name: '성북동' },
                    { name: '세동' },
                    { name: '추목동' },
                    { name: '신봉동' },
                    { name: '수남동' },
                ],
            },
            {
                name: '대덕구',
                sub: [
                    { name: '대화동' },
                    { name: '신대동' },
                    { name: '석봉동' },
                    { name: '상서동' },
                    { name: '문평동' },
                    { name: '목상동' },
                    { name: '신일동' },
                    { name: '신탄진동' },
                    { name: '송촌동' },
                    { name: '비래동' },
                    { name: '오정동' },
                    { name: '중리동' },
                    { name: '읍내동' },
                    { name: '법동' },
                    { name: '이현동' },
                    { name: '갈전동' },
                    { name: '삼정동' },
                    { name: '연축동' },
                    { name: '와동' },
                    { name: '덕암동' },
                    { name: '평촌동' },
                    { name: '용호동' },
                    { name: '미호동' },
                    { name: '장동' },
                ],
            },
        ],
    },
    {
        name: '울산광역시',
        sub: [
            {
                name: '중구',
                sub: [
                    { name: '다운동' },
                    { name: '유곡동' },
                    { name: '학성동' },
                    { name: '복산동' },
                    { name: '약사동' },
                    { name: '반구동' },
                    { name: '옥교동' },
                    { name: '성남동' },
                    { name: '우정동' },
                    { name: '학산동' },
                    { name: '남외동' },
                    { name: '태화동' },
                    { name: '교동' },
                    { name: '성안동' },
                    { name: '동동' },
                    { name: '서동' },
                    { name: '북정동' },
                    { name: '장현동' },
                ],
            },
            {
                name: '남구',
                sub: [
                    { name: '무거동' },
                    { name: '삼산동' },
                    { name: '여천동' },
                    { name: '야음동' },
                    { name: '선암동' },
                    { name: '상개동' },
                    { name: '달동' },
                    { name: '성암동' },
                    { name: '두왕동' },
                    { name: '매암동' },
                    { name: '신정동' },
                    { name: '옥동' },
                    { name: '부곡동' },
                    { name: '고사동' },
                    { name: '황성동' },
                    { name: '용연동' },
                    { name: '남화동' },
                    { name: '용잠동' },
                    { name: '장생포동' },
                ],
            },
            {
                name: '동구',
                sub: [{ name: '방어동' }, { name: '화정동' }, { name: '일산동' }, { name: '전하동' }, { name: '미포동' }, { name: '동부동' }, { name: '서부동' }, { name: '주전동' }],
            },
            {
                name: '북구',
                sub: [
                    { name: '염포동' },
                    { name: '구유동' },
                    { name: '정자동' },
                    { name: '신명동' },
                    { name: '당사동' },
                    { name: '산하동' },
                    { name: '어물동' },
                    { name: '매곡동' },
                    { name: '신천동' },
                    { name: '중산동' },
                    { name: '창평동' },
                    { name: '호계동' },
                    { name: '연암동' },
                    { name: '화봉동' },
                    { name: '송정동' },
                    { name: '효문동' },
                    { name: '진장동' },
                    { name: '명촌동' },
                    { name: '상안동' },
                    { name: '양정동' },
                    { name: '신현동' },
                    { name: '천곡동' },
                    { name: '가대동' },
                    { name: '달천동' },
                    { name: '시례동' },
                    { name: '무룡동' },
                    { name: '대안동' },
                ],
            },
            {
                name: '울주군',
                sub: [
                    { name: '언양읍' },
                    { name: '두동면' },
                    { name: '두서면' },
                    { name: '삼남면' },
                    { name: '서생면' },
                    { name: '범서읍' },
                    { name: '상북면' },
                    { name: '삼동면' },
                    { name: '웅촌면' },
                    { name: '온양읍' },
                    { name: '청량읍' },
                    { name: '온산읍' },
                ],
            },
        ],
    },
    {
        name: '세종특별자치시',
        sub: [],
    },
    {
        name: '경기도',
        sub: [
            {
                name: '수원시 장안구',
                sub: [
                    { name: '파장동' },
                    { name: '연무동' },
                    { name: '이목동' },
                    { name: '영화동' },
                    { name: '송죽동' },
                    { name: '조원동' },
                    { name: '정자동' },
                    { name: '율전동' },
                    { name: '천천동' },
                    { name: '상광교동' },
                    { name: '하광교동' },
                ],
            },
            {
                name: '수원시 권선구',
                sub: [
                    { name: '세류동' },
                    { name: '권선동' },
                    { name: '대황교동' },
                    { name: '서둔동' },
                    { name: '구운동' },
                    { name: '입북동' },
                    { name: '당수동' },
                    { name: '곡반정동' },
                    { name: '평동' },
                    { name: '고색동' },
                    { name: '오목천동' },
                    { name: '탑동' },
                    { name: '장지동' },
                    { name: '호매실동' },
                    { name: '금곡동' },
                    { name: '평리동' },
                ],
            },
            {
                name: '수원시 팔달구',
                sub: [
                    { name: '팔달로1가' },
                    { name: '남수동' },
                    { name: '매향동' },
                    { name: '북수동' },
                    { name: '우만동' },
                    { name: '지동' },
                    { name: '인계동' },
                    { name: '팔달로3가' },
                    { name: '영동' },
                    { name: '중동' },
                    { name: '구천동' },
                    { name: '매산로1가' },
                    { name: '고등동' },
                    { name: '화서동' },
                    { name: '팔달로2가' },
                    { name: '남창동' },
                    { name: '신풍동' },
                    { name: '장안동' },
                    { name: '매교동' },
                    { name: '매산로2가' },
                    { name: '매산로3가' },
                    { name: '교동' },
                ],
            },
            {
                name: '수원시 영통구',
                sub: [{ name: '이의동' }, { name: '매탄동' }, { name: '원천동' }, { name: '영통동' }, { name: '신동' }, { name: '망포동' }, { name: '하동' }],
            },
            {
                name: '성남시 수정구',
                sub: [
                    { name: '금토동' },
                    { name: '심곡동' },
                    { name: '태평동' },
                    { name: '수진동' },
                    { name: '복정동' },
                    { name: '창곡동' },
                    { name: '시흥동' },
                    { name: '사송동' },
                    { name: '신흥동' },
                    { name: '단대동' },
                    { name: '양지동' },
                    { name: '고등동' },
                    { name: '상적동' },
                    { name: '산성동' },
                    { name: '신촌동' },
                    { name: '오야동' },
                    { name: '둔전동' },
                ],
            },
            {
                name: '성남시 중원구',
                sub: [
                    { name: '성남동' },
                    { name: '여수동' },
                    { name: '하대원동' },
                    { name: '갈현동' },
                    { name: '상대원동' },
                    { name: '금광동' },
                    { name: '은행동' },
                    { name: '중앙동' },
                    { name: '도촌동' },
                ],
            },
            {
                name: '성남시 분당구',
                sub: [
                    { name: '삼평동' },
                    { name: '운중동' },
                    { name: '하산운동' },
                    { name: '대장동' },
                    { name: '수내동' },
                    { name: '정자동' },
                    { name: '서현동' },
                    { name: '이매동' },
                    { name: '야탑동' },
                    { name: '금곡동' },
                    { name: '구미동' },
                    { name: '백현동' },
                    { name: '판교동' },
                    { name: '동원동' },
                    { name: '석운동' },
                    { name: '궁내동' },
                    { name: '율동' },
                    { name: '분당동' },
                ],
            },
            {
                name: '의정부시',
                sub: [
                    { name: '산곡동' },
                    { name: '의정부동' },
                    { name: '장암동' },
                    { name: '신곡동' },
                    { name: '금오동' },
                    { name: '녹양동' },
                    { name: '자일동' },
                    { name: '가능동' },
                    { name: '호원동' },
                    { name: '용현동' },
                    { name: '낙양동' },
                    { name: '민락동' },
                    { name: '고산동' },
                ],
            },
            {
                name: '안양시 만안구',
                sub: [{ name: '안양동' }, { name: '석수동' }, { name: '박달동' }],
            },
            {
                name: '안양시 동안구',
                sub: [{ name: '관양동' }, { name: '평촌동' }, { name: '호계동' }, { name: '비산동' }],
            },
            {
                name: '부천시',
                sub: [
                    { name: '약대동' },
                    { name: '중동' },
                    { name: '상동' },
                    { name: '송내동' },
                    { name: '삼정동' },
                    { name: '오정동' },
                    { name: '원종동' },
                    { name: '고강동' },
                    { name: '내동' },
                    { name: '심곡동' },
                    { name: '소사동' },
                    { name: '역곡동' },
                    { name: '범박동' },
                    { name: '계수동' },
                    { name: '옥길동' },
                    { name: '괴안동' },
                    { name: '소사본동' },
                    { name: '심곡본동' },
                    { name: '춘의동' },
                    { name: '여월동' },
                    { name: '작동' },
                    { name: '원미동' },
                    { name: '도당동' },
                    { name: '대장동' },
                ],
            },
            {
                name: '광명시',
                sub: [{ name: '철산동' }, { name: '하안동' }, { name: '옥길동' }, { name: '광명동' }, { name: '소하동' }, { name: '일직동' }, { name: '노온사동' }, { name: '가학동' }],
            },
            {
                name: '평택시',
                sub: [
                    { name: '청북읍' },
                    { name: '진위면' },
                    { name: '칠괴동' },
                    { name: '칠원동' },
                    { name: '동삭동' },
                    { name: '청룡동' },
                    { name: '고덕면' },
                    { name: '지제동' },
                    { name: '합정동' },
                    { name: '소사동' },
                    { name: '용이동' },
                    { name: '안중읍' },
                    { name: '포승읍' },
                    { name: '오성면' },
                    { name: '현덕면' },
                    { name: '서정동' },
                    { name: '장당동' },
                    { name: '모곡동' },
                    { name: '이충동' },
                    { name: '지산동' },
                    { name: '독곡동' },
                    { name: '신장동' },
                    { name: '유천동' },
                    { name: '비전동' },
                    { name: '세교동' },
                    { name: '팽성읍' },
                    { name: '도일동' },
                    { name: '가재동' },
                    { name: '장안동' },
                    { name: '죽백동' },
                    { name: '서탄면' },
                    { name: '신대동' },
                    { name: '군문동' },
                    { name: '평택동' },
                    { name: '통복동' },
                    { name: '월곡동' },
                ],
            },
            {
                name: '동두천시',
                sub: [
                    { name: '생연동' },
                    { name: '광암동' },
                    { name: '탑동동' },
                    { name: '상패동' },
                    { name: '지행동' },
                    { name: '보산동' },
                    { name: '송내동' },
                    { name: '상봉암동' },
                    { name: '하봉암동' },
                    { name: '안흥동' },
                    { name: '동두천동' },
                    { name: '걸산동' },
                ],
            },
            {
                name: '안산시 상록구',
                sub: [
                    { name: '사동' },
                    { name: '이동' },
                    { name: '일동' },
                    { name: '부곡동' },
                    { name: '팔곡일동' },
                    { name: '건건동' },
                    { name: '사사동' },
                    { name: '수암동' },
                    { name: '장상동' },
                    { name: '본오동' },
                    { name: '장하동' },
                    { name: '양상동' },
                    { name: '성포동' },
                    { name: '팔곡이동' },
                    { name: '월피동' },
                ],
            },
            {
                name: '안산시 단원구',
                sub: [
                    { name: '신길동' },
                    { name: '고잔동' },
                    { name: '초지동' },
                    { name: '원곡동' },
                    { name: '선부동' },
                    { name: '대부북동' },
                    { name: '대부남동' },
                    { name: '원시동' },
                    { name: '화정동' },
                    { name: '와동' },
                    { name: '대부동동' },
                    { name: '성곡동' },
                    { name: '목내동' },
                    { name: '선감동' },
                    { name: '풍도동' },
                ],
            },
            {
                name: '고양시 덕양구',
                sub: [
                    { name: '덕은동' },
                    { name: '주교동' },
                    { name: '원흥동' },
                    { name: '성사동' },
                    { name: '동산동' },
                    { name: '용두동' },
                    { name: '삼송동' },
                    { name: '효자동' },
                    { name: '지축동' },
                    { name: '도내동' },
                    { name: '원당동' },
                    { name: '신원동' },
                    { name: '대자동' },
                    { name: '관산동' },
                    { name: '내유동' },
                    { name: '오금동' },
                    { name: '벽제동' },
                    { name: '고양동' },
                    { name: '토당동' },
                    { name: '대장동' },
                    { name: '화정동' },
                    { name: '행주내동' },
                    { name: '행주외동' },
                    { name: '향동동' },
                    { name: '강매동' },
                    { name: '현천동' },
                    { name: '내곡동' },
                    { name: '행신동' },
                    { name: '화전동' },
                    { name: '신평동' },
                    { name: '북한동' },
                    { name: '선유동' },
                ],
            },
            {
                name: '고양시 일산동구',
                sub: [
                    { name: '식사동' },
                    { name: '중산동' },
                    { name: '풍동' },
                    { name: '장항동' },
                    { name: '설문동' },
                    { name: '성석동' },
                    { name: '정발산동' },
                    { name: '마두동' },
                    { name: '백석동' },
                    { name: '산황동' },
                    { name: '사리현동' },
                    { name: '문봉동' },
                    { name: '지영동' },
                ],
            },
            {
                name: '고양시 일산서구',
                sub: [{ name: '일산동' }, { name: '대화동' }, { name: '덕이동' }, { name: '법곳동' }, { name: '가좌동' }, { name: '구산동' }, { name: '주엽동' }, { name: '탄현동' }],
            },
            {
                name: '과천시',
                sub: [
                    { name: '문원동' },
                    { name: '갈현동' },
                    { name: '막계동' },
                    { name: '과천동' },
                    { name: '주암동' },
                    { name: '중앙동' },
                    { name: '원문동' },
                    { name: '별양동' },
                    { name: '부림동' },
                    { name: '관문동' },
                ],
            },
            {
                name: '구리시',
                sub: [{ name: '사노동' }, { name: '토평동' }, { name: '갈매동' }, { name: '교문동' }, { name: '인창동' }, { name: '수택동' }, { name: '아천동' }],
            },
            {
                name: '남양주시',
                sub: [
                    { name: '와부읍' },
                    { name: '별내동' },
                    { name: '별내면' },
                    { name: '호평동' },
                    { name: '평내동' },
                    { name: '금곡동' },
                    { name: '일패동' },
                    { name: '지금동' },
                    { name: '도농동' },
                    { name: '화도읍' },
                    { name: '다산동' },
                    { name: '진접읍' },
                    { name: '진건읍' },
                    { name: '이패동' },
                    { name: '삼패동' },
                    { name: '조안면' },
                    { name: '퇴계원읍' },
                    { name: '수석동' },
                    { name: '오남읍' },
                    { name: '수동면' },
                ],
            },
            {
                name: '오산시',
                sub: [
                    { name: '양산동' },
                    { name: '지곶동' },
                    { name: '금암동' },
                    { name: '오산동' },
                    { name: '원동' },
                    { name: '벌음동' },
                    { name: '두곡동' },
                    { name: '고현동' },
                    { name: '청호동' },
                    { name: '갈곶동' },
                    { name: '부산동' },
                    { name: '궐동' },
                    { name: '수청동' },
                    { name: '은계동' },
                    { name: '내삼미동' },
                    { name: '외삼미동' },
                    { name: '세교동' },
                    { name: '청학동' },
                    { name: '가수동' },
                    { name: '서동' },
                    { name: '가장동' },
                    { name: '서랑동' },
                    { name: '탑동' },
                    { name: '누읍동' },
                ],
            },
            {
                name: '시흥시',
                sub: [
                    { name: '조남동' },
                    { name: '계수동' },
                    { name: '죽율동' },
                    { name: '정왕동' },
                    { name: '신천동' },
                    { name: '미산동' },
                    { name: '은행동' },
                    { name: '능곡동' },
                    { name: '하중동' },
                    { name: '광석동' },
                    { name: '군자동' },
                    { name: '금이동' },
                    { name: '과림동' },
                    { name: '무지내동' },
                    { name: '대야동' },
                    { name: '방산동' },
                    { name: '월곶동' },
                    { name: '안현동' },
                    { name: '매화동' },
                    { name: '도창동' },
                    { name: '논곡동' },
                    { name: '목감동' },
                    { name: '거모동' },
                    { name: '장곡동' },
                    { name: '하상동' },
                    { name: '물왕동' },
                    { name: '산현동' },
                    { name: '장현동' },
                    { name: '포동' },
                    { name: '화정동' },
                ],
            },
            {
                name: '군포시',
                sub: [
                    { name: '부곡동' },
                    { name: '대야미동' },
                    { name: '금정동' },
                    { name: '당정동' },
                    { name: '둔대동' },
                    { name: '속달동' },
                    { name: '당동' },
                    { name: '산본동' },
                    { name: '도마교동' },
                ],
            },
            {
                name: '의왕시',
                sub: [
                    { name: '고천동' },
                    { name: '왕곡동' },
                    { name: '오전동' },
                    { name: '이동' },
                    { name: '삼동' },
                    { name: '월암동' },
                    { name: '학의동' },
                    { name: '청계동' },
                    { name: '포일동' },
                    { name: '내손동' },
                    { name: '초평동' },
                ],
            },
            {
                name: '하남시',
                sub: [
                    { name: '천현동' },
                    { name: '하산곡동' },
                    { name: '춘궁동' },
                    { name: '광암동' },
                    { name: '덕풍동' },
                    { name: '풍산동' },
                    { name: '망월동' },
                    { name: '초이동' },
                    { name: '창우동' },
                    { name: '배알미동' },
                    { name: '신장동' },
                    { name: '미사동' },
                    { name: '학암동' },
                    { name: '감이동' },
                    { name: '상산곡동' },
                    { name: '선동' },
                    { name: '감북동' },
                    { name: '감일동' },
                    { name: '교산동' },
                    { name: '하사창동' },
                    { name: '상사창동' },
                    { name: '항동' },
                    { name: '초일동' },
                ],
            },
            {
                name: '용인시 처인구',
                sub: [
                    { name: '마평동' },
                    { name: '이동읍' },
                    { name: '김량장동' },
                    { name: '남동' },
                    { name: '유방동' },
                    { name: '호동' },
                    { name: '포곡읍' },
                    { name: '모현읍' },
                    { name: '원삼면' },
                    { name: '백암면' },
                    { name: '양지면' },
                    { name: '남사면' },
                    { name: '역북동' },
                    { name: '삼가동' },
                    { name: '고림동' },
                    { name: '운학동' },
                    { name: '해곡동' },
                ],
            },
            {
                name: '용인시 기흥구',
                sub: [
                    { name: '고매동' },
                    { name: '하갈동' },
                    { name: '신갈동' },
                    { name: '상갈동' },
                    { name: '보라동' },
                    { name: '공세동' },
                    { name: '마북동' },
                    { name: '보정동' },
                    { name: '구갈동' },
                    { name: '영덕동' },
                    { name: '상하동' },
                    { name: '동백동' },
                    { name: '중동' },
                    { name: '서천동' },
                    { name: '청덕동' },
                    { name: '언남동' },
                    { name: '농서동' },
                    { name: '지곡동' },
                ],
            },
            {
                name: '용인시 수지구',
                sub: [{ name: '죽전동' }, { name: '풍덕천동' }, { name: '성복동' }, { name: '상현동' }, { name: '동천동' }, { name: '고기동' }, { name: '신봉동' }],
            },
            {
                name: '파주시',
                sub: [
                    { name: '야당동' },
                    { name: '동패동' },
                    { name: '아동동' },
                    { name: '문산읍' },
                    { name: '파주읍' },
                    { name: '조리읍' },
                    { name: '월롱면' },
                    { name: '군내면' },
                    { name: '법원읍' },
                    { name: '광탄면' },
                    { name: '상지석동' },
                    { name: '적성면' },
                    { name: '금촌동' },
                    { name: '당하동' },
                    { name: '하지석동' },
                    { name: '와동동' },
                    { name: '목동동' },
                    { name: '탄현면' },
                    { name: '문발동' },
                    { name: '신촌동' },
                    { name: '금릉동' },
                    { name: '검산동' },
                    { name: '교하동' },
                    { name: '다율동' },
                    { name: '오도동' },
                    { name: '서패동' },
                    { name: '연다산동' },
                    { name: '산남동' },
                    { name: '맥금동' },
                    { name: '송촌동' },
                    { name: '야동동' },
                    { name: '파평면' },
                    { name: '장단면' },
                    { name: '진동면' },
                ],
            },
            {
                name: '이천시',
                sub: [
                    { name: '마장면' },
                    { name: '모가면' },
                    { name: '고담동' },
                    { name: '관고동' },
                    { name: '중리동' },
                    { name: '진리동' },
                    { name: '사음동' },
                    { name: '장호원읍' },
                    { name: '부발읍' },
                    { name: '신둔면' },
                    { name: '대월면' },
                    { name: '설성면' },
                    { name: '율면' },
                    { name: '장록동' },
                    { name: '호법면' },
                    { name: '대포동' },
                    { name: '백사면' },
                    { name: '증일동' },
                    { name: '안흥동' },
                    { name: '갈산동' },
                    { name: '단월동' },
                    { name: '창전동' },
                    { name: '증포동' },
                    { name: '송정동' },
                    { name: '율현동' },
                ],
            },
            {
                name: '안성시',
                sub: [
                    { name: '원곡면' },
                    { name: '일죽면' },
                    { name: '서운면' },
                    { name: '미양면' },
                    { name: '신흥동' },
                    { name: '인지동' },
                    { name: '금산동' },
                    { name: '연지동' },
                    { name: '대천동' },
                    { name: '서인동' },
                    { name: '금석동' },
                    { name: '계동' },
                    { name: '사곡동' },
                    { name: '도기동' },
                    { name: '당왕동' },
                    { name: '중리동' },
                    { name: '대덕면' },
                    { name: '양성면' },
                    { name: '고삼면' },
                    { name: '가사동' },
                    { name: '신건지동' },
                    { name: '신소현동' },
                    { name: '공도읍' },
                    { name: '보개면' },
                    { name: '죽산면' },
                    { name: '삼죽면' },
                    { name: '금광면' },
                    { name: '봉산동' },
                    { name: '옥천동' },
                    { name: '현수동' },
                    { name: '가현동' },
                    { name: '신모산동' },
                    { name: '봉남동' },
                    { name: '명륜동' },
                    { name: '숭인동' },
                    { name: '석정동' },
                    { name: '아양동' },
                    { name: '옥산동' },
                    { name: '낙원동' },
                    { name: '창전동' },
                    { name: '구포동' },
                    { name: '성남동' },
                    { name: '영동' },
                    { name: '동본동' },
                    { name: '발화동' },
                ],
            },
            {
                name: '김포시',
                sub: [
                    { name: '양촌읍' },
                    { name: '구래동' },
                    { name: '북변동' },
                    { name: '걸포동' },
                    { name: '운양동' },
                    { name: '장기동' },
                    { name: '감정동' },
                    { name: '사우동' },
                    { name: '풍무동' },
                    { name: '통진읍' },
                    { name: '고촌읍' },
                    { name: '월곶면' },
                    { name: '대곶면' },
                    { name: '하성면' },
                    { name: '마산동' },
                ],
            },
            {
                name: '화성시',
                sub: [
                    { name: '팔탄면' },
                    { name: '매송면' },
                    { name: '마도면' },
                    { name: '송산면' },
                    { name: '장안면' },
                    { name: '우정읍' },
                    { name: '오산동' },
                    { name: '영천동' },
                    { name: '송동' },
                    { name: '방교동' },
                    { name: '신동' },
                    { name: '진안동' },
                    { name: '병점동' },
                    { name: '산척동' },
                    { name: '장지동' },
                    { name: '청계동' },
                    { name: '목동' },
                    { name: '새솔동' },
                    { name: '향남읍' },
                    { name: '비봉면' },
                    { name: '양감면' },
                    { name: '능동' },
                    { name: '반월동' },
                    { name: '반송동' },
                    { name: '봉담읍' },
                    { name: '송산동' },
                    { name: '안녕동' },
                    { name: '배양동' },
                    { name: '기안동' },
                    { name: '반정동' },
                    { name: '기산동' },
                    { name: '정남면' },
                    { name: '중동' },
                    { name: '서신면' },
                    { name: '남양읍' },
                    { name: '석우동' },
                    { name: '황계동' },
                    { name: '금곡동' },
                ],
            },
            {
                name: '광주시',
                sub: [
                    { name: '쌍령동' },
                    { name: '삼동' },
                    { name: '중대동' },
                    { name: '장지동' },
                    { name: '역동' },
                    { name: '초월읍' },
                    { name: '곤지암읍' },
                    { name: '오포읍' },
                    { name: '송정동' },
                    { name: '탄벌동' },
                    { name: '태전동' },
                    { name: '회덕동' },
                    { name: '남한산성면' },
                    { name: '경안동' },
                    { name: '도척면' },
                    { name: '퇴촌면' },
                    { name: '남종면' },
                    { name: '직동' },
                    { name: '목동' },
                    { name: '목현동' },
                ],
            },
            {
                name: '양주시',
                sub: [
                    { name: '장흥면' },
                    { name: '마전동' },
                    { name: '은현면' },
                    { name: '남면' },
                    { name: '회암동' },
                    { name: '봉양동' },
                    { name: '백석읍' },
                    { name: '유양동' },
                    { name: '남방동' },
                    { name: '광사동' },
                    { name: '만송동' },
                    { name: '삼숭동' },
                    { name: '고읍동' },
                    { name: '광적면' },
                    { name: '옥정동' },
                    { name: '덕정동' },
                    { name: '율정동' },
                    { name: '고암동' },
                    { name: '산북동' },
                    { name: '덕계동' },
                    { name: '어둔동' },
                    { name: '회정동' },
                ],
            },
            {
                name: '포천시',
                sub: [
                    { name: '내촌면' },
                    { name: '일동면' },
                    { name: '이동면' },
                    { name: '화현면' },
                    { name: '영북면' },
                    { name: '관인면' },
                    { name: '창수면' },
                    { name: '신읍동' },
                    { name: '어룡동' },
                    { name: '자작동' },
                    { name: '선단동' },
                    { name: '설운동' },
                    { name: '동교동' },
                    { name: '소흘읍' },
                    { name: '군내면' },
                    { name: '신북면' },
                    { name: '영중면' },
                    { name: '가산면' },
                ],
            },
            {
                name: '여주시',
                sub: [
                    { name: '능서면' },
                    { name: '금사면' },
                    { name: '산북면' },
                    { name: '흥천면' },
                    { name: '대신면' },
                    { name: '가남읍' },
                    { name: '점동면' },
                    { name: '천송동' },
                    { name: '북내면' },
                    { name: '강천면' },
                    { name: '오학동' },
                    { name: '현암동' },
                    { name: '홍문동' },
                    { name: '교동' },
                    { name: '삼교동' },
                    { name: '점봉동' },
                    { name: '멱곡동' },
                    { name: '상거동' },
                    { name: '하거동' },
                    { name: '연라동' },
                    { name: '단현동' },
                    { name: '능현동' },
                    { name: '매룡동' },
                    { name: '월송동' },
                    { name: '창동' },
                    { name: '하동' },
                    { name: '가업동' },
                    { name: '상동' },
                    { name: '우만동' },
                    { name: '신진동' },
                    { name: '연양동' },
                    { name: '오금동' },
                ],
            },
            {
                name: '연천군',
                sub: [
                    { name: '전곡읍' },
                    { name: '청산면' },
                    { name: '군남면' },
                    { name: '백학면' },
                    { name: '미산면' },
                    { name: '장남면' },
                    { name: '왕징면' },
                    { name: '중면' },
                    { name: '신서면' },
                    { name: '연천읍' },
                ],
            },
            {
                name: '가평군',
                sub: [{ name: '가평읍' }, { name: '청평면' }, { name: '상면' }, { name: '북면' }, { name: '설악면' }, { name: '조종면' }],
            },
            {
                name: '양평군',
                sub: [
                    { name: '양동면' },
                    { name: '단월면' },
                    { name: '양평읍' },
                    { name: '양서면' },
                    { name: '옥천면' },
                    { name: '청운면' },
                    { name: '용문면' },
                    { name: '지평면' },
                    { name: '강하면' },
                    { name: '개군면' },
                    { name: '서종면' },
                    { name: '강상면' },
                ],
            },
        ],
    },
    {
        name: '강원도',
        sub: [
            {
                name: '춘천시',
                sub: [
                    { name: '신북읍' },
                    { name: '동면' },
                    { name: '신동면' },
                    { name: '동내면' },
                    { name: '온의동' },
                    { name: '칠전동' },
                    { name: '서면' },
                    { name: '남산면' },
                    { name: '사북면' },
                    { name: '소양로1가' },
                    { name: '근화동' },
                    { name: '우두동' },
                    { name: '사농동' },
                    { name: '퇴계동' },
                    { name: '석사동' },
                    { name: '신동' },
                    { name: '동산면' },
                    { name: '후평동' },
                    { name: '중앙로3가' },
                    { name: '약사동' },
                    { name: '효자동' },
                    { name: '요선동' },
                    { name: '낙원동' },
                    { name: '중앙로2가' },
                    { name: '조양동' },
                    { name: '운교동' },
                    { name: '소양로3가' },
                    { name: '남면' },
                    { name: '북산면' },
                    { name: '교동' },
                    { name: '옥천동' },
                    { name: '소양로2가' },
                    { name: '소양로4가' },
                    { name: '삼천동' },
                    { name: '봉의동' },
                    { name: '중앙로1가' },
                    { name: '송암동' },
                    { name: '죽림동' },
                    { name: '중도동' },
                ],
            },
            {
                name: '원주시',
                sub: [
                    { name: '개운동' },
                    { name: '명륜동' },
                    { name: '단구동' },
                    { name: '단계동' },
                    { name: '흥업면' },
                    { name: '귀래면' },
                    { name: '문막읍' },
                    { name: '부론면' },
                    { name: '신림면' },
                    { name: '지정면' },
                    { name: '호저면' },
                    { name: '태장동' },
                    { name: '가현동' },
                    { name: '소초면' },
                    { name: '평원동' },
                    { name: '학성동' },
                    { name: '관설동' },
                    { name: '반곡동' },
                    { name: '원동' },
                    { name: '일산동' },
                    { name: '판부면' },
                    { name: '봉산동' },
                    { name: '행구동' },
                    { name: '무실동' },
                    { name: '우산동' },
                    { name: '중앙동' },
                    { name: '인동' },
                ],
            },
            {
                name: '강릉시',
                sub: [
                    { name: '성산면' },
                    { name: '사천면' },
                    { name: '구정면' },
                    { name: '옥계면' },
                    { name: '죽헌동' },
                    { name: '대전동' },
                    { name: '난곡동' },
                    { name: '안현동' },
                    { name: '주문진읍' },
                    { name: '강동면' },
                    { name: '연곡면' },
                    { name: '운산동' },
                    { name: '유천동' },
                    { name: '홍제동' },
                    { name: '임당동' },
                    { name: '옥천동' },
                    { name: '교동' },
                    { name: '포남동' },
                    { name: '남문동' },
                    { name: '명주동' },
                    { name: '성내동' },
                    { name: '금학동' },
                    { name: '송정동' },
                    { name: '견소동' },
                    { name: '왕산면' },
                    { name: '지변동' },
                    { name: '저동' },
                    { name: '성남동' },
                    { name: '박월동' },
                    { name: '신석동' },
                    { name: '초당동' },
                    { name: '강문동' },
                    { name: '운정동' },
                    { name: '내곡동' },
                    { name: '노암동' },
                    { name: '용강동' },
                    { name: '장현동' },
                    { name: '담산동' },
                    { name: '유산동' },
                    { name: '입암동' },
                    { name: '두산동' },
                    { name: '학동' },
                    { name: '병산동' },
                    { name: '청량동' },
                    { name: '회산동' },
                    { name: '남항진동' },
                    { name: '월호평동' },
                ],
            },
            {
                name: '동해시',
                sub: [
                    { name: '천곡동' },
                    { name: '평릉동' },
                    { name: '지흥동' },
                    { name: '효가동' },
                    { name: '나안동' },
                    { name: '발한동' },
                    { name: '추암동' },
                    { name: '단봉동' },
                    { name: '이도동' },
                    { name: '대구동' },
                    { name: '망상동' },
                    { name: '초구동' },
                    { name: '부곡동' },
                    { name: '호현동' },
                    { name: '이로동' },
                    { name: '신흥동' },
                    { name: '달방동' },
                    { name: '구미동' },
                    { name: '구호동' },
                    { name: '송정동' },
                    { name: '용정동' },
                    { name: '북평동' },
                    { name: '어달동' },
                    { name: '대진동' },
                    { name: '묵호진동' },
                    { name: '삼화동' },
                    { name: '이기동' },
                    { name: '귀운동' },
                    { name: '동회동' },
                    { name: '쇄운동' },
                    { name: '괴란동' },
                    { name: '만우동' },
                    { name: '내동' },
                    { name: '지가동' },
                    { name: '심곡동' },
                    { name: '비천동' },
                ],
            },
            {
                name: '태백시',
                sub: [
                    { name: '황지동' },
                    { name: '장성동' },
                    { name: '문곡동' },
                    { name: '동점동' },
                    { name: '화전동' },
                    { name: '통동' },
                    { name: '상사미동' },
                    { name: '소도동' },
                    { name: '혈동' },
                    { name: '적각동' },
                    { name: '창죽동' },
                    { name: '하사미동' },
                    { name: '조탄동' },
                    { name: '철암동' },
                    { name: '백산동' },
                    { name: '원동' },
                    { name: '금천동' },
                ],
            },
            {
                name: '속초시',
                sub: [
                    { name: '교동' },
                    { name: '조양동' },
                    { name: '대포동' },
                    { name: '장사동' },
                    { name: '영랑동' },
                    { name: '동명동' },
                    { name: '설악동' },
                    { name: '노학동' },
                    { name: '도문동' },
                    { name: '중앙동' },
                    { name: '금호동' },
                    { name: '청학동' },
                    { name: '청호동' },
                ],
            },
            {
                name: '삼척시',
                sub: [
                    { name: '교동' },
                    { name: '갈천동' },
                    { name: '마달동' },
                    { name: '사직동' },
                    { name: '오분동' },
                    { name: '정상동' },
                    { name: '정하동' },
                    { name: '원덕읍' },
                    { name: '우지동' },
                    { name: '등봉동' },
                    { name: '도계읍' },
                    { name: '미로면' },
                    { name: '신기면' },
                    { name: '도경동' },
                    { name: '근덕면' },
                    { name: '노곡면' },
                    { name: '하장면' },
                    { name: '당저동' },
                    { name: '가곡면' },
                    { name: '성내동' },
                    { name: '읍중동' },
                    { name: '남양동' },
                    { name: '적노동' },
                    { name: '조비동' },
                    { name: '증산동' },
                    { name: '성북동' },
                    { name: '자원동' },
                    { name: '원당동' },
                    { name: '평전동' },
                    { name: '성남동' },
                    { name: '읍상동' },
                    { name: '건지동' },
                    { name: '근산동' },
                    { name: '마평동' },
                    { name: '오사동' },
                ],
            },
            {
                name: '홍천군',
                sub: [
                    { name: '내촌면' },
                    { name: '화촌면' },
                    { name: '남면' },
                    { name: '서면' },
                    { name: '홍천읍' },
                    { name: '두촌면' },
                    { name: '서석면' },
                    { name: '내면' },
                    { name: '북방면' },
                    { name: '동면' },
                ],
            },
            {
                name: '횡성군',
                sub: [
                    { name: '둔내면' },
                    { name: '우천면' },
                    { name: '안흥면' },
                    { name: '횡성읍' },
                    { name: '갑천면' },
                    { name: '공근면' },
                    { name: '서원면' },
                    { name: '강림면' },
                    { name: '청일면' },
                ],
            },
            {
                name: '영월군',
                sub: [
                    { name: '김삿갓면' },
                    { name: '남면' },
                    { name: '한반도면' },
                    { name: '주천면' },
                    { name: '상동읍' },
                    { name: '중동면' },
                    { name: '무릉도원면' },
                    { name: '북면' },
                    { name: '영월읍' },
                ],
            },
            {
                name: '평창군',
                sub: [{ name: '방림면' }, { name: '대화면' }, { name: '용평면' }, { name: '봉평면' }, { name: '진부면' }, { name: '대관령면' }, { name: '미탄면' }, { name: '평창읍' }],
            },
            {
                name: '정선군',
                sub: [{ name: '여량면' }, { name: '북평면' }, { name: '고한읍' }, { name: '신동읍' }, { name: '남면' }, { name: '임계면' }, { name: '화암면' }, { name: '정선읍' }, { name: '사북읍' }],
            },
            {
                name: '철원군',
                sub: [{ name: '동송읍' }, { name: '서면' }, { name: '근남면' }, { name: '갈말읍' }, { name: '철원읍' }, { name: '김화읍' }, { name: '근북면' }],
            },
            {
                name: '화천군',
                sub: [{ name: '사내면' }, { name: '하남면' }, { name: '화천읍' }, { name: '상서면' }, { name: '간동면' }],
            },
            {
                name: '양구군',
                sub: [{ name: '양구읍' }, { name: '남면' }, { name: '해안면' }, { name: '방산면' }, { name: '동면' }],
            },
            {
                name: '인제군',
                sub: [{ name: '상남면' }, { name: '북면' }, { name: '서화면' }, { name: '인제읍' }, { name: '남면' }, { name: '기린면' }],
            },
            {
                name: '고성군',
                sub: [{ name: '거진읍' }, { name: '현내면' }, { name: '죽왕면' }, { name: '토성면' }, { name: '간성읍' }, { name: '수동면' }],
            },
            {
                name: '양양군',
                sub: [{ name: '양양읍' }, { name: '손양면' }, { name: '현북면' }, { name: '현남면' }, { name: '강현면' }, { name: '서면' }],
            },
        ],
    },
    {
        name: '충청북도',
        sub: [
            {
                name: '청주시 상당구',
                sub: [
                    { name: '문의면' },
                    { name: '가덕면' },
                    { name: '남일면' },
                    { name: '지북동' },
                    { name: '운동동' },
                    { name: '월오동' },
                    { name: '낭성면' },
                    { name: '방서동' },
                    { name: '미원면' },
                    { name: '탑동' },
                    { name: '금천동' },
                    { name: '용담동' },
                    { name: '명암동' },
                    { name: '산성동' },
                    { name: '용정동' },
                    { name: '평촌동' },
                    { name: '용암동' },
                    { name: '북문로2가' },
                    { name: '수동' },
                    { name: '영동' },
                    { name: '남문로2가' },
                    { name: '서문동' },
                    { name: '남주동' },
                    { name: '대성동' },
                    { name: '문화동' },
                    { name: '서운동' },
                    { name: '석교동' },
                    { name: '영운동' },
                    { name: '북문로3가' },
                    { name: '북문로1가' },
                    { name: '남문로1가' },
                ],
            },
            {
                name: '청주시 서원구',
                sub: [
                    { name: '남이면' },
                    { name: '현도면' },
                    { name: '미평동' },
                    { name: '성화동' },
                    { name: '죽림동' },
                    { name: '장성동' },
                    { name: '장암동' },
                    { name: '개신동' },
                    { name: '사창동' },
                    { name: '산남동' },
                    { name: '분평동' },
                    { name: '사직동' },
                    { name: '모충동' },
                    { name: '수곡동' },
                ],
            },
            {
                name: '청주시 흥덕구',
                sub: [
                    { name: '석소동' },
                    { name: '옥산면' },
                    { name: '오송읍' },
                    { name: '복대동' },
                    { name: '강서동' },
                    { name: '휴암동' },
                    { name: '수의동' },
                    { name: '비하동' },
                    { name: '강내면' },
                    { name: '가경동' },
                    { name: '송정동' },
                    { name: '송절동' },
                    { name: '화계동' },
                    { name: '외북동' },
                    { name: '향정동' },
                    { name: '석곡동' },
                    { name: '신전동' },
                    { name: '현암동' },
                    { name: '운천동' },
                    { name: '신봉동' },
                    { name: '봉명동' },
                    { name: '문암동' },
                    { name: '지동동' },
                    { name: '서촌동' },
                    { name: '정봉동' },
                    { name: '내곡동' },
                    { name: '상신동' },
                    { name: '동막동' },
                    { name: '신촌동' },
                    { name: '원평동' },
                    { name: '신성동' },
                    { name: '평동' },
                    { name: '남촌동' },
                    { name: '신대동' },
                ],
            },
            {
                name: '청주시 청원구',
                sub: [
                    { name: '오창읍' },
                    { name: '외하동' },
                    { name: '내수읍' },
                    { name: '내덕동' },
                    { name: '율량동' },
                    { name: '주성동' },
                    { name: '주중동' },
                    { name: '북이면' },
                    { name: '사천동' },
                    { name: '우암동' },
                    { name: '정하동' },
                    { name: '정상동' },
                    { name: '오동동' },
                    { name: '정북동' },
                    { name: '외남동' },
                    { name: '외평동' },
                ],
            },
            {
                name: '충주시',
                sub: [
                    { name: '신니면' },
                    { name: '엄정면' },
                    { name: '산척면' },
                    { name: '중앙탑면' },
                    { name: '대소원면' },
                    { name: '노은면' },
                    { name: '주덕읍' },
                    { name: '교현동' },
                    { name: '칠금동' },
                    { name: '연수동' },
                    { name: '봉방동' },
                    { name: '목행동' },
                    { name: '문화동' },
                    { name: '금릉동' },
                    { name: '용산동' },
                    { name: '호암동' },
                    { name: '안림동' },
                    { name: '지현동' },
                    { name: '단월동' },
                    { name: '금가면' },
                    { name: '동량면' },
                    { name: '소태면' },
                    { name: '달천동' },
                    { name: '용관동' },
                    { name: '풍동' },
                    { name: '용두동' },
                    { name: '살미면' },
                    { name: '수안보면' },
                    { name: '앙성면' },
                    { name: '성내동' },
                    { name: '성남동' },
                    { name: '성서동' },
                    { name: '충인동' },
                    { name: '용탄동' },
                    { name: '종민동' },
                    { name: '목벌동' },
                    { name: '충의동' },
                    { name: '가주동' },
                    { name: '직동' },
                ],
            },
            {
                name: '제천시',
                sub: [
                    { name: '금성면' },
                    { name: '봉양읍' },
                    { name: '서부동' },
                    { name: '동현동' },
                    { name: '남천동' },
                    { name: '중앙로1가' },
                    { name: '명동' },
                    { name: '흑석동' },
                    { name: '두학동' },
                    { name: '신백동' },
                    { name: '자작동' },
                    { name: '하소동' },
                    { name: '신월동' },
                    { name: '왕암동' },
                    { name: '의림동' },
                    { name: '중앙로2가' },
                    { name: '화산동' },
                    { name: '영천동' },
                    { name: '청전동' },
                    { name: '모산동' },
                    { name: '고암동' },
                    { name: '송학면' },
                    { name: '장락동' },
                    { name: '백운면' },
                    { name: '한수면' },
                    { name: '청풍면' },
                    { name: '수산면' },
                    { name: '고명동' },
                    { name: '강제동' },
                    { name: '대랑동' },
                    { name: '명지동' },
                    { name: '신동' },
                    { name: '덕산면' },
                    { name: '교동' },
                    { name: '천남동' },
                    { name: '산곡동' },
                ],
            },
            {
                name: '보은군',
                sub: [
                    { name: '보은읍' },
                    { name: '장안면' },
                    { name: '마로면' },
                    { name: '탄부면' },
                    { name: '수한면' },
                    { name: '회인면' },
                    { name: '회남면' },
                    { name: '속리산면' },
                    { name: '산외면' },
                    { name: '삼승면' },
                    { name: '내북면' },
                ],
            },
            {
                name: '옥천군',
                sub: [
                    { name: '동이면' },
                    { name: '군서면' },
                    { name: '옥천읍' },
                    { name: '이원면' },
                    { name: '군북면' },
                    { name: '청산면' },
                    { name: '안내면' },
                    { name: '청성면' },
                    { name: '안남면' },
                ],
            },
            {
                name: '영동군',
                sub: [
                    { name: '매곡면' },
                    { name: '양강면' },
                    { name: '양산면' },
                    { name: '심천면' },
                    { name: '황간면' },
                    { name: '상촌면' },
                    { name: '용화면' },
                    { name: '학산면' },
                    { name: '영동읍' },
                    { name: '용산면' },
                    { name: '추풍령면' },
                ],
            },
            {
                name: '증평군',
                sub: [{ name: '증평읍' }, { name: '도안면' }],
            },
            {
                name: '진천군',
                sub: [{ name: '문백면' }, { name: '이월면' }, { name: '광혜원면' }, { name: '백곡면' }, { name: '진천읍' }, { name: '초평면' }, { name: '덕산읍' }],
            },
            {
                name: '괴산군',
                sub: [
                    { name: '연풍면' },
                    { name: '장연면' },
                    { name: '칠성면' },
                    { name: '청천면' },
                    { name: '괴산읍' },
                    { name: '문광면' },
                    { name: '청안면' },
                    { name: '사리면' },
                    { name: '감물면' },
                    { name: '불정면' },
                    { name: '소수면' },
                ],
            },
            {
                name: '음성군',
                sub: [
                    { name: '삼성면' },
                    { name: '대소면' },
                    { name: '금왕읍' },
                    { name: '음성읍' },
                    { name: '소이면' },
                    { name: '원남면' },
                    { name: '생극면' },
                    { name: '감곡면' },
                    { name: '맹동면' },
                ],
            },
            {
                name: '단양군',
                sub: [{ name: '어상천면' }, { name: '영춘면' }, { name: '가곡면' }, { name: '단양읍' }, { name: '매포읍' }, { name: '대강면' }, { name: '단성면' }, { name: '적성면' }],
            },
        ],
    },
    {
        name: '충청남도',
        sub: [
            {
                name: '천안시 동남구',
                sub: [
                    { name: '풍세면' },
                    { name: '청당동' },
                    { name: '신방동' },
                    { name: '목천읍' },
                    { name: '용곡동' },
                    { name: '봉명동' },
                    { name: '쌍용동' },
                    { name: '원성동' },
                    { name: '구성동' },
                    { name: '삼룡동' },
                    { name: '신부동' },
                    { name: '동면' },
                    { name: '광덕면' },
                    { name: '구룡동' },
                    { name: '수신면' },
                    { name: '성남면' },
                    { name: '북면' },
                    { name: '병천면' },
                    { name: '안서동' },
                    { name: '사직동' },
                    { name: '대흥동' },
                    { name: '성황동' },
                    { name: '영성동' },
                    { name: '문화동' },
                    { name: '유량동' },
                    { name: '오룡동' },
                    { name: '다가동' },
                    { name: '청수동' },
                ],
            },
            {
                name: '천안시 서북구',
                sub: [
                    { name: '성정동' },
                    { name: '두정동' },
                    { name: '쌍용동' },
                    { name: '신당동' },
                    { name: '부대동' },
                    { name: '성환읍' },
                    { name: '성거읍' },
                    { name: '직산읍' },
                    { name: '백석동' },
                    { name: '성성동' },
                    { name: '불당동' },
                    { name: '와촌동' },
                    { name: '차암동' },
                    { name: '업성동' },
                    { name: '입장면' },
                ],
            },
            {
                name: '공주시',
                sub: [
                    { name: '송선동' },
                    { name: '의당면' },
                    { name: '쌍신동' },
                    { name: '유구읍' },
                    { name: '사곡면' },
                    { name: '신풍면' },
                    { name: '우성면' },
                    { name: '정안면' },
                    { name: '태봉동' },
                    { name: '탄천면' },
                    { name: '이인면' },
                    { name: '반포면' },
                    { name: '웅진동' },
                    { name: '검상동' },
                    { name: '계룡면' },
                    { name: '주미동' },
                    { name: '오곡동' },
                    { name: '신관동' },
                    { name: '무릉동' },
                    { name: '월송동' },
                    { name: '석장리동' },
                    { name: '반죽동' },
                    { name: '중동' },
                    { name: '산성동' },
                    { name: '교동' },
                    { name: '옥룡동' },
                    { name: '금흥동' },
                    { name: '봉황동' },
                    { name: '금성동' },
                    { name: '금학동' },
                    { name: '중학동' },
                    { name: '동현동' },
                    { name: '소학동' },
                    { name: '상왕동' },
                    { name: '봉정동' },
                    { name: '월미동' },
                    { name: '신기동' },
                ],
            },
            {
                name: '보령시',
                sub: [
                    { name: '천북면' },
                    { name: '주교면' },
                    { name: '청소면' },
                    { name: '웅천읍' },
                    { name: '남포면' },
                    { name: '신흑동' },
                    { name: '대천동' },
                    { name: '궁촌동' },
                    { name: '청라면' },
                    { name: '죽정동' },
                    { name: '주산면' },
                    { name: '화산동' },
                    { name: '명천동' },
                    { name: '내항동' },
                    { name: '남곡동' },
                    { name: '요암동' },
                    { name: '오천면' },
                    { name: '동대동' },
                    { name: '미산면' },
                    { name: '성주면' },
                    { name: '주포면' },
                ],
            },
            {
                name: '아산시',
                sub: [
                    { name: '배방읍' },
                    { name: '둔포면' },
                    { name: '인주면' },
                    { name: '영인면' },
                    { name: '음봉면' },
                    { name: '탕정면' },
                    { name: '좌부동' },
                    { name: '도고면' },
                    { name: '송악면' },
                    { name: '온천동' },
                    { name: '방축동' },
                    { name: '풍기동' },
                    { name: '모종동' },
                    { name: '배미동' },
                    { name: '득산동' },
                    { name: '신동' },
                    { name: '남동' },
                    { name: '신창면' },
                    { name: '법곡동' },
                    { name: '장존동' },
                    { name: '읍내동' },
                    { name: '용화동' },
                    { name: '염치읍' },
                    { name: '권곡동' },
                    { name: '실옥동' },
                    { name: '선장면' },
                    { name: '초사동' },
                    { name: '신인동' },
                    { name: '기산동' },
                    { name: '점양동' },
                ],
            },
            {
                name: '서산시',
                sub: [
                    { name: '해미면' },
                    { name: '동문동' },
                    { name: '잠홍동' },
                    { name: '석림동' },
                    { name: '석남동' },
                    { name: '예천동' },
                    { name: '인지면' },
                    { name: '팔봉면' },
                    { name: '음암면' },
                    { name: '운산면' },
                    { name: '수석동' },
                    { name: '성연면' },
                    { name: '부석면' },
                    { name: '읍내동' },
                    { name: '갈산동' },
                    { name: '대산읍' },
                    { name: '지곡면' },
                    { name: '고북면' },
                    { name: '죽성동' },
                    { name: '양대동' },
                    { name: '오남동' },
                    { name: '장동' },
                    { name: '덕지천동' },
                    { name: '온석동' },
                ],
            },
            {
                name: '논산시',
                sub: [
                    { name: '벌곡면' },
                    { name: '연무읍' },
                    { name: '취암동' },
                    { name: '등화동' },
                    { name: '지산동' },
                    { name: '내동' },
                    { name: '강산동' },
                    { name: '관촉동' },
                    { name: '부창동' },
                    { name: '광석면' },
                    { name: '노성면' },
                    { name: '상월면' },
                    { name: '은진면' },
                    { name: '성동면' },
                    { name: '반월동' },
                    { name: '덕지동' },
                    { name: '강경읍' },
                    { name: '부적면' },
                    { name: '연산면' },
                    { name: '채운면' },
                    { name: '양촌면' },
                    { name: '가야곡면' },
                    { name: '화지동' },
                    { name: '대교동' },
                ],
            },
            {
                name: '계룡시',
                sub: [{ name: '두마면' }, { name: '금암동' }, { name: '엄사면' }, { name: '신도안면' }],
            },
            {
                name: '당진시',
                sub: [
                    { name: '신평면' },
                    { name: '송악읍' },
                    { name: '면천면' },
                    { name: '읍내동' },
                    { name: '채운동' },
                    { name: '우두동' },
                    { name: '원당동' },
                    { name: '시곡동' },
                    { name: '수청동' },
                    { name: '정미면' },
                    { name: '합덕읍' },
                    { name: '순성면' },
                    { name: '행정동' },
                    { name: '용연동' },
                    { name: '사기소동' },
                    { name: '구룡동' },
                    { name: '우강면' },
                    { name: '대호지면' },
                    { name: '고대면' },
                    { name: '송산면' },
                    { name: '석문면' },
                    { name: '대덕동' },
                ],
            },
            {
                name: '금산군',
                sub: [
                    { name: '금산읍' },
                    { name: '금성면' },
                    { name: '군북면' },
                    { name: '남일면' },
                    { name: '남이면' },
                    { name: '추부면' },
                    { name: '진산면' },
                    { name: '복수면' },
                    { name: '제원면' },
                    { name: '부리면' },
                ],
            },
            {
                name: '부여군',
                sub: [
                    { name: '내산면' },
                    { name: '홍산면' },
                    { name: '규암면' },
                    { name: '구룡면' },
                    { name: '부여읍' },
                    { name: '옥산면' },
                    { name: '석성면' },
                    { name: '초촌면' },
                    { name: '은산면' },
                    { name: '외산면' },
                    { name: '충화면' },
                    { name: '임천면' },
                    { name: '세도면' },
                    { name: '남면' },
                    { name: '장암면' },
                    { name: '양화면' },
                ],
            },
            {
                name: '서천군',
                sub: [
                    { name: '비인면' },
                    { name: '서천읍' },
                    { name: '화양면' },
                    { name: '마서면' },
                    { name: '장항읍' },
                    { name: '서면' },
                    { name: '판교면' },
                    { name: '종천면' },
                    { name: '한산면' },
                    { name: '마산면' },
                    { name: '기산면' },
                    { name: '시초면' },
                    { name: '문산면' },
                ],
            },
            {
                name: '청양군',
                sub: [
                    { name: '목면' },
                    { name: '정산면' },
                    { name: '장평면' },
                    { name: '대치면' },
                    { name: '청양읍' },
                    { name: '청남면' },
                    { name: '화성면' },
                    { name: '남양면' },
                    { name: '비봉면' },
                    { name: '운곡면' },
                ],
            },
            {
                name: '홍성군',
                sub: [
                    { name: '갈산면' },
                    { name: '은하면' },
                    { name: '홍북읍' },
                    { name: '광천읍' },
                    { name: '서부면' },
                    { name: '금마면' },
                    { name: '구항면' },
                    { name: '홍성읍' },
                    { name: '홍동면' },
                    { name: '장곡면' },
                    { name: '결성면' },
                ],
            },
            {
                name: '예산군',
                sub: [
                    { name: '오가면' },
                    { name: '고덕면' },
                    { name: '신양면' },
                    { name: '예산읍' },
                    { name: '대흥면' },
                    { name: '응봉면' },
                    { name: '덕산면' },
                    { name: '대술면' },
                    { name: '삽교읍' },
                    { name: '광시면' },
                    { name: '신암면' },
                    { name: '봉산면' },
                ],
            },
            {
                name: '태안군',
                sub: [{ name: '태안읍' }, { name: '안면읍' }, { name: '고남면' }, { name: '남면' }, { name: '근흥면' }, { name: '소원면' }, { name: '원북면' }, { name: '이원면' }],
            },
        ],
    },
    {
        name: '전라북도',
        sub: [
            {
                name: '전주시 완산구',
                sub: [
                    { name: '경원동3가' },
                    { name: '풍남동3가' },
                    { name: '교동' },
                    { name: '중노송동' },
                    { name: '남노송동' },
                    { name: '서노송동' },
                    { name: '중앙동2가' },
                    { name: '색장동' },
                    { name: '다가동1가' },
                    { name: '중화산동2가' },
                    { name: '서신동' },
                    { name: '평화동1가' },
                    { name: '삼천동1가' },
                    { name: '효자동1가' },
                    { name: '효자동3가' },
                    { name: '상림동' },
                    { name: '효자동2가' },
                    { name: '중앙동3가' },
                    { name: '서서학동' },
                    { name: '평화동2가' },
                    { name: '평화동3가' },
                    { name: '중인동' },
                    { name: '다가동4가' },
                    { name: '고사동' },
                    { name: '태평동' },
                    { name: '동완산동' },
                    { name: '중화산동1가' },
                    { name: '동서학동' },
                    { name: '삼천동2가' },
                    { name: '삼천동3가' },
                    { name: '용복동' },
                    { name: '서완산동1가' },
                    { name: '서완산동2가' },
                    { name: '풍남동1가' },
                    { name: '풍남동2가' },
                    { name: '중앙동4가' },
                    { name: '전동' },
                    { name: '다가동2가' },
                    { name: '전동3가' },
                    { name: '다가동3가' },
                    { name: '중앙동1가' },
                    { name: '경원동1가' },
                    { name: '경원동2가' },
                    { name: '석구동' },
                    { name: '대성동' },
                    { name: '원당동' },
                ],
            },
            {
                name: '전주시 덕진구',
                sub: [
                    { name: '진북동' },
                    { name: '덕진동1가' },
                    { name: '덕진동2가' },
                    { name: '금암동' },
                    { name: '팔복동1가' },
                    { name: '팔복동2가' },
                    { name: '반월동' },
                    { name: '동산동' },
                    { name: '여의동' },
                    { name: '장동' },
                    { name: '산정동' },
                    { name: '우아동1가' },
                    { name: '우아동2가' },
                    { name: '우아동3가' },
                    { name: '호성동1가' },
                    { name: '호성동2가' },
                    { name: '송천동2가' },
                    { name: '용정동' },
                    { name: '고랑동' },
                    { name: '팔복동4가' },
                    { name: '인후동2가' },
                    { name: '만성동' },
                    { name: '중동' },
                    { name: '성덕동' },
                    { name: '전미동2가' },
                    { name: '전미동1가' },
                    { name: '화전동' },
                    { name: '인후동1가' },
                    { name: '팔복동3가' },
                    { name: '송천동1가' },
                    { name: '강흥동' },
                    { name: '원동' },
                    { name: '호성동3가' },
                    { name: '금상동' },
                    { name: '남정동' },
                    { name: '도도동' },
                    { name: '도덕동' },
                ],
            },
            {
                name: '군산시',
                sub: [
                    { name: '성산면' },
                    { name: '나포면' },
                    { name: '대야면' },
                    { name: '내흥동' },
                    { name: '개정면' },
                    { name: '임피면' },
                    { name: '옥도면' },
                    { name: '비응도동' },
                    { name: '서수면' },
                    { name: '금암동' },
                    { name: '중동' },
                    { name: '미룡동' },
                    { name: '오식도동' },
                    { name: '내초동' },
                    { name: '옥구읍' },
                    { name: '개사동' },
                    { name: '옥산면' },
                    { name: '소룡동' },
                    { name: '조촌동' },
                    { name: '사정동' },
                    { name: '경장동' },
                    { name: '경암동' },
                    { name: '미장동' },
                    { name: '지곡동' },
                    { name: '수송동' },
                    { name: '나운동' },
                    { name: '산북동' },
                    { name: '옥서면' },
                    { name: '신영동' },
                    { name: '평화동' },
                    { name: '대명동' },
                    { name: '장재동' },
                    { name: '구암동' },
                    { name: '문화동' },
                    { name: '회현면' },
                    { name: '월명동' },
                    { name: '신창동' },
                    { name: '오룡동' },
                    { name: '금광동' },
                    { name: '신풍동' },
                    { name: '명산동' },
                    { name: '중앙로1가' },
                    { name: '영화동' },
                    { name: '신관동' },
                    { name: '삼학동' },
                    { name: '중앙로3가' },
                    { name: '미원동' },
                    { name: '개정동' },
                    { name: '선양동' },
                    { name: '둔율동' },
                    { name: '창성동' },
                    { name: '개복동' },
                    { name: '영동' },
                    { name: '송창동' },
                    { name: '해망동' },
                    { name: '신흥동' },
                    { name: '서흥남동' },
                    { name: '장미동' },
                    { name: '죽성동' },
                    { name: '금동' },
                    { name: '중앙로2가' },
                    { name: '송풍동' },
                    { name: '동흥남동' },
                ],
            },
            {
                name: '익산시',
                sub: [
                    { name: '여산면' },
                    { name: '창인동1가' },
                    { name: '창인동2가' },
                    { name: '중앙동1가' },
                    { name: '중앙동2가' },
                    { name: '평화동' },
                    { name: '남중동' },
                    { name: '모현동1가' },
                    { name: '모현동2가' },
                    { name: '신용동' },
                    { name: '신동' },
                    { name: '함열읍' },
                    { name: '황등면' },
                    { name: '용안면' },
                    { name: '망성면' },
                    { name: '용동면' },
                    { name: '송학동' },
                    { name: '오산면' },
                    { name: '웅포면' },
                    { name: '함라면' },
                    { name: '성당면' },
                    { name: '목천동' },
                    { name: '현영동' },
                    { name: '영등동' },
                    { name: '어양동' },
                    { name: '팔봉동' },
                    { name: '덕기동' },
                    { name: '석왕동' },
                    { name: '부송동' },
                    { name: '금마면' },
                    { name: '왕궁면' },
                    { name: '마동' },
                    { name: '춘포면' },
                    { name: '낭산면' },
                    { name: '동산동' },
                    { name: '금강동' },
                    { name: '인화동1가' },
                    { name: '인화동2가' },
                    { name: '삼기면' },
                    { name: '신흥동' },
                    { name: '주현동' },
                    { name: '용제동' },
                    { name: '석암동' },
                    { name: '만석동' },
                    { name: '갈산동' },
                    { name: '석탄동' },
                    { name: '임상동' },
                    { name: '월성동' },
                    { name: '중앙동3가' },
                    { name: '은기동' },
                    { name: '정족동' },
                ],
            },
            {
                name: '정읍시',
                sub: [
                    { name: '북면' },
                    { name: '정우면' },
                    { name: '송산동' },
                    { name: '입암면' },
                    { name: '금붕동' },
                    { name: '내장동' },
                    { name: '신태인읍' },
                    { name: '태인면' },
                    { name: '고부면' },
                    { name: '연지동' },
                    { name: '농소동' },
                    { name: '영파동' },
                    { name: '망제동' },
                    { name: '덕천면' },
                    { name: '이평면' },
                    { name: '칠보면' },
                    { name: '산외면' },
                    { name: '감곡면' },
                    { name: '소성면' },
                    { name: '장명동' },
                    { name: '상동' },
                    { name: '시기동' },
                    { name: '영원면' },
                    { name: '수성동' },
                    { name: '부전동' },
                    { name: '쌍암동' },
                    { name: '흑암동' },
                    { name: '용계동' },
                    { name: '옹동면' },
                    { name: '산내면' },
                    { name: '상평동' },
                    { name: '과교동' },
                    { name: '하북동' },
                    { name: '공평동' },
                    { name: '하모동' },
                    { name: '삼산동' },
                    { name: '진산동' },
                    { name: '구룡동' },
                    { name: '신월동' },
                    { name: '용산동' },
                    { name: '교암동' },
                    { name: '신정동' },
                ],
            },
            {
                name: '남원시',
                sub: [
                    { name: '송동면' },
                    { name: '대산면' },
                    { name: '수지면' },
                    { name: '사매면' },
                    { name: '주생면' },
                    { name: '아영면' },
                    { name: '산동면' },
                    { name: '대강면' },
                    { name: '화정동' },
                    { name: '주천면' },
                    { name: '고죽동' },
                    { name: '이백면' },
                    { name: '광치동' },
                    { name: '죽항동' },
                    { name: '쌍교동' },
                    { name: '천거동' },
                    { name: '조산동' },
                    { name: '월락동' },
                    { name: '식정동' },
                    { name: '금지면' },
                    { name: '산내면' },
                    { name: '인월면' },
                    { name: '덕과면' },
                    { name: '갈치동' },
                    { name: '보절면' },
                    { name: '향교동' },
                    { name: '용정동' },
                    { name: '도통동' },
                    { name: '운봉읍' },
                    { name: '동충동' },
                    { name: '하정동' },
                    { name: '금동' },
                    { name: '왕정동' },
                    { name: '신정동' },
                    { name: '노암동' },
                    { name: '어현동' },
                    { name: '신촌동' },
                    { name: '내척동' },
                    { name: '산곡동' },
                ],
            },
            {
                name: '김제시',
                sub: [
                    { name: '검산동' },
                    { name: '순동' },
                    { name: '백학동' },
                    { name: '상동동' },
                    { name: '용지면' },
                    { name: '금구면' },
                    { name: '황산면' },
                    { name: '서암동' },
                    { name: '신곡동' },
                    { name: '갈공동' },
                    { name: '만경읍' },
                    { name: '백산면' },
                    { name: '청하면' },
                    { name: '성덕면' },
                    { name: '진봉면' },
                    { name: '백구면' },
                    { name: '공덕면' },
                    { name: '부량면' },
                    { name: '흥사동' },
                    { name: '요촌동' },
                    { name: '하동' },
                    { name: '금산면' },
                    { name: '교동' },
                    { name: '옥산동' },
                    { name: '입석동' },
                    { name: '장화동' },
                    { name: '신덕동' },
                    { name: '광활면' },
                    { name: '신풍동' },
                    { name: '봉남면' },
                    { name: '죽산면' },
                    { name: '제월동' },
                    { name: '용동' },
                    { name: '복죽동' },
                    { name: '연정동' },
                    { name: '명덕동' },
                    { name: '황산동' },
                    { name: '도장동' },
                    { name: '월성동' },
                    { name: '난봉동' },
                    { name: '오정동' },
                    { name: '양전동' },
                    { name: '월봉동' },
                    { name: '신월동' },
                    { name: '서정동' },
                ],
            },
            {
                name: '완주군',
                sub: [
                    { name: '소양면' },
                    { name: '용진읍' },
                    { name: '이서면' },
                    { name: '봉동읍' },
                    { name: '상관면' },
                    { name: '운주면' },
                    { name: '화산면' },
                    { name: '경천면' },
                    { name: '삼례읍' },
                    { name: '구이면' },
                    { name: '고산면' },
                    { name: '비봉면' },
                    { name: '동상면' },
                ],
            },
            {
                name: '진안군',
                sub: [
                    { name: '진안읍' },
                    { name: '부귀면' },
                    { name: '동향면' },
                    { name: '성수면' },
                    { name: '마령면' },
                    { name: '주천면' },
                    { name: '백운면' },
                    { name: '안천면' },
                    { name: '정천면' },
                    { name: '용담면' },
                    { name: '상전면' },
                ],
            },
            {
                name: '무주군',
                sub: [{ name: '안성면' }, { name: '설천면' }, { name: '부남면' }, { name: '무주읍' }, { name: '적상면' }, { name: '무풍면' }],
            },
            {
                name: '장수군',
                sub: [{ name: '계북면' }, { name: '장계면' }, { name: '산서면' }, { name: '번암면' }, { name: '천천면' }, { name: '장수읍' }, { name: '계남면' }],
            },
            {
                name: '임실군',
                sub: [
                    { name: '관촌면' },
                    { name: '오수면' },
                    { name: '성수면' },
                    { name: '삼계면' },
                    { name: '강진면' },
                    { name: '덕치면' },
                    { name: '운암면' },
                    { name: '임실읍' },
                    { name: '지사면' },
                    { name: '신덕면' },
                    { name: '신평면' },
                    { name: '청웅면' },
                ],
            },
            {
                name: '순창군',
                sub: [
                    { name: '순창읍' },
                    { name: '복흥면' },
                    { name: '풍산면' },
                    { name: '인계면' },
                    { name: '금과면' },
                    { name: '팔덕면' },
                    { name: '적성면' },
                    { name: '유등면' },
                    { name: '동계면' },
                    { name: '쌍치면' },
                    { name: '구림면' },
                ],
            },
            {
                name: '고창군',
                sub: [
                    { name: '대산면' },
                    { name: '신림면' },
                    { name: '고창읍' },
                    { name: '흥덕면' },
                    { name: '고수면' },
                    { name: '아산면' },
                    { name: '공음면' },
                    { name: '상하면' },
                    { name: '해리면' },
                    { name: '심원면' },
                    { name: '성내면' },
                    { name: '부안면' },
                    { name: '성송면' },
                    { name: '무장면' },
                ],
            },
            {
                name: '부안군',
                sub: [
                    { name: '주산면' },
                    { name: '변산면' },
                    { name: '줄포면' },
                    { name: '백산면' },
                    { name: '동진면' },
                    { name: '행안면' },
                    { name: '계화면' },
                    { name: '상서면' },
                    { name: '부안읍' },
                    { name: '하서면' },
                    { name: '위도면' },
                    { name: '보안면' },
                    { name: '진서면' },
                ],
            },
        ],
    },
    {
        name: '전라남도',
        sub: [
            {
                name: '목포시',
                sub: [
                    { name: '산정동' },
                    { name: '연산동' },
                    { name: '죽교동' },
                    { name: '대양동' },
                    { name: '달동' },
                    { name: '용당동' },
                    { name: '상동' },
                    { name: '용해동' },
                    { name: '옥암동' },
                    { name: '대성동' },
                    { name: '석현동' },
                    { name: '서산동' },
                    { name: '온금동' },
                    { name: '동명동' },
                    { name: '북교동' },
                    { name: '죽동' },
                    { name: '양동' },
                    { name: '남교동' },
                    { name: '만호동' },
                    { name: '항동' },
                    { name: '금동1가' },
                    { name: '금동2가' },
                    { name: '상락동1가' },
                    { name: '복만동' },
                    { name: '광동3가' },
                    { name: '영해동2가' },
                    { name: '행복동2가' },
                    { name: '축복동3가' },
                    { name: '보광동3가' },
                    { name: '대의동1가' },
                    { name: '대의동2가' },
                    { name: '중앙동1가' },
                    { name: '중앙동2가' },
                    { name: '중앙동3가' },
                    { name: '수강동2가' },
                    { name: '금화동' },
                    { name: '호남동' },
                    { name: '창평동' },
                    { name: '상락동2가' },
                    { name: '해안동4가' },
                    { name: '수강동1가' },
                    { name: '영해동1가' },
                    { name: '대안동' },
                    { name: '명륜동' },
                    { name: '유달동' },
                    { name: '측후동' },
                    { name: '대의동3가' },
                    { name: '해안동3가' },
                    { name: '중동2가' },
                    { name: '유동' },
                    { name: '경동2가' },
                    { name: '광동1가' },
                    { name: '행복동1가' },
                    { name: '축복동1가' },
                    { name: '보광동1가' },
                    { name: '해안동1가' },
                    { name: '해안동2가' },
                    { name: '무안동' },
                    { name: '중동1가' },
                    { name: '경동1가' },
                    { name: '광동2가' },
                    { name: '축복동2가' },
                    { name: '보광동2가' },
                    { name: '율도동' },
                ],
            },
            {
                name: '여수시',
                sub: [
                    { name: '덕충동' },
                    { name: '소라면' },
                    { name: '돌산읍' },
                    { name: '종화동' },
                    { name: '묘도동' },
                    { name: '율촌면' },
                    { name: '국동' },
                    { name: '남면' },
                    { name: '서교동' },
                    { name: '남산동' },
                    { name: '봉산동' },
                    { name: '화장동' },
                    { name: '미평동' },
                    { name: '둔덕동' },
                    { name: '학동' },
                    { name: '안산동' },
                    { name: '선원동' },
                    { name: '공화동' },
                    { name: '관문동' },
                    { name: '고소동' },
                    { name: '중앙동' },
                    { name: '군자동' },
                    { name: '만흥동' },
                    { name: '신기동' },
                    { name: '오천동' },
                    { name: '상암동' },
                    { name: '신덕동' },
                    { name: '해산동' },
                    { name: '여서동' },
                    { name: '문수동' },
                    { name: '봉강동' },
                    { name: '화치동' },
                    { name: '평여동' },
                    { name: '중흥동' },
                    { name: '주삼동' },
                    { name: '월하동' },
                    { name: '봉계동' },
                    { name: '월내동' },
                    { name: '낙포동' },
                    { name: '호명동' },
                    { name: '화양면' },
                    { name: '소호동' },
                    { name: '여천동' },
                    { name: '신월동' },
                    { name: '웅천동' },
                    { name: '학용동' },
                    { name: '시전동' },
                    { name: '적량동' },
                    { name: '수정동' },
                    { name: '삼산면' },
                    { name: '교동' },
                    { name: '연등동' },
                    { name: '광무동' },
                    { name: '오림동' },
                    { name: '충무동' },
                    { name: '동산동' },
                    { name: '화정면' },
                    { name: '경호동' },
                ],
            },
            {
                name: '순천시',
                sub: [
                    { name: '별량면' },
                    { name: '인월동' },
                    { name: '서면' },
                    { name: '해룡면' },
                    { name: '승주읍' },
                    { name: '주암면' },
                    { name: '황전면' },
                    { name: '월등면' },
                    { name: '풍덕동' },
                    { name: '장천동' },
                    { name: '중앙동' },
                    { name: '동외동' },
                    { name: '매곡동' },
                    { name: '석현동' },
                    { name: '가곡동' },
                    { name: '교량동' },
                    { name: '오천동' },
                    { name: '덕월동' },
                    { name: '덕암동' },
                    { name: '연향동' },
                    { name: '조례동' },
                    { name: '외서면' },
                    { name: '왕지동' },
                    { name: '조곡동' },
                    { name: '용당동' },
                    { name: '옥천동' },
                    { name: '저전동' },
                    { name: '남내동' },
                    { name: '남정동' },
                    { name: '인제동' },
                    { name: '낙안면' },
                    { name: '생목동' },
                    { name: '영동' },
                    { name: '행동' },
                    { name: '대룡동' },
                    { name: '안풍동' },
                    { name: '송광면' },
                    { name: '금곡동' },
                    { name: '상사면' },
                    { name: '홍내동' },
                    { name: '대대동' },
                    { name: '와룡동' },
                    { name: '야흥동' },
                    { name: '삼거동' },
                ],
            },
            {
                name: '나주시',
                sub: [
                    { name: '남평읍' },
                    { name: '산포면' },
                    { name: '산정동' },
                    { name: '경현동' },
                    { name: '대호동' },
                    { name: '송촌동' },
                    { name: '석현동' },
                    { name: '청동' },
                    { name: '노안면' },
                    { name: '문평면' },
                    { name: '다시면' },
                    { name: '왕곡면' },
                    { name: '반남면' },
                    { name: '세지면' },
                    { name: '동강면' },
                    { name: '교동' },
                    { name: '보산동' },
                    { name: '과원동' },
                    { name: '성북동' },
                    { name: '삼도동' },
                    { name: '금천면' },
                    { name: '공산면' },
                    { name: '송월동' },
                    { name: '안창동' },
                    { name: '삼영동' },
                    { name: '봉황면' },
                    { name: '영산동' },
                    { name: '용산동' },
                    { name: '관정동' },
                    { name: '평산동' },
                    { name: '이창동' },
                    { name: '금계동' },
                    { name: '금성동' },
                    { name: '남내동' },
                    { name: '중앙동' },
                    { name: '남외동' },
                    { name: '운곡동' },
                    { name: '동수동' },
                    { name: '다도면' },
                    { name: '죽림동' },
                    { name: '부덕동' },
                    { name: '빛가람동' },
                    { name: '대기동' },
                    { name: '오량동' },
                    { name: '진포동' },
                    { name: '서내동' },
                    { name: '토계동' },
                ],
            },
            {
                name: '광양시',
                sub: [
                    { name: '광양읍' },
                    { name: '황길동' },
                    { name: '도이동' },
                    { name: '중동' },
                    { name: '황금동' },
                    { name: '태인동' },
                    { name: '진월면' },
                    { name: '다압면' },
                    { name: '옥룡면' },
                    { name: '봉강면' },
                    { name: '마동' },
                    { name: '광영동' },
                    { name: '옥곡면' },
                    { name: '금호동' },
                    { name: '진상면' },
                    { name: '성황동' },
                    { name: '중군동' },
                ],
            },
            {
                name: '담양군',
                sub: [
                    { name: '대전면' },
                    { name: '무정면' },
                    { name: '담양읍' },
                    { name: '봉산면' },
                    { name: '고서면' },
                    { name: '금성면' },
                    { name: '용면' },
                    { name: '월산면' },
                    { name: '수북면' },
                    { name: '가사문학면' },
                    { name: '대덕면' },
                    { name: '창평면' },
                ],
            },
            {
                name: '곡성군',
                sub: [
                    { name: '겸면' },
                    { name: '오산면' },
                    { name: '석곡면' },
                    { name: '삼기면' },
                    { name: '고달면' },
                    { name: '옥과면' },
                    { name: '목사동면' },
                    { name: '죽곡면' },
                    { name: '곡성읍' },
                    { name: '오곡면' },
                    { name: '입면' },
                ],
            },
            {
                name: '구례군',
                sub: [{ name: '용방면' }, { name: '구례읍' }, { name: '산동면' }, { name: '토지면' }, { name: '마산면' }, { name: '광의면' }, { name: '간전면' }, { name: '문척면' }],
            },
            {
                name: '고흥군',
                sub: [
                    { name: '동강면' },
                    { name: '금산면' },
                    { name: '고흥읍' },
                    { name: '도양읍' },
                    { name: '풍양면' },
                    { name: '도덕면' },
                    { name: '점암면' },
                    { name: '과역면' },
                    { name: '남양면' },
                    { name: '대서면' },
                    { name: '두원면' },
                    { name: '도화면' },
                    { name: '포두면' },
                    { name: '동일면' },
                    { name: '봉래면' },
                    { name: '영남면' },
                ],
            },
            {
                name: '보성군',
                sub: [
                    { name: '미력면' },
                    { name: '겸백면' },
                    { name: '벌교읍' },
                    { name: '보성읍' },
                    { name: '조성면' },
                    { name: '복내면' },
                    { name: '회천면' },
                    { name: '득량면' },
                    { name: '노동면' },
                    { name: '문덕면' },
                    { name: '율어면' },
                    { name: '웅치면' },
                ],
            },
            {
                name: '화순군',
                sub: [
                    { name: '동복면' },
                    { name: '동면' },
                    { name: '능주면' },
                    { name: '도곡면' },
                    { name: '이양면' },
                    { name: '청풍면' },
                    { name: '화순읍' },
                    { name: '도암면' },
                    { name: '북면' },
                    { name: '춘양면' },
                    { name: '이서면' },
                    { name: '남면' },
                    { name: '한천면' },
                ],
            },
            {
                name: '장흥군',
                sub: [
                    { name: '부산면' },
                    { name: '장동면' },
                    { name: '장흥읍' },
                    { name: '관산읍' },
                    { name: '대덕읍' },
                    { name: '용산면' },
                    { name: '유치면' },
                    { name: '안양면' },
                    { name: '장평면' },
                    { name: '회진면' },
                ],
            },
            {
                name: '강진군',
                sub: [
                    { name: '성전면' },
                    { name: '병영면' },
                    { name: '작천면' },
                    { name: '군동면' },
                    { name: '대구면' },
                    { name: '마량면' },
                    { name: '강진읍' },
                    { name: '칠량면' },
                    { name: '옴천면' },
                    { name: '도암면' },
                    { name: '신전면' },
                ],
            },
            {
                name: '해남군',
                sub: [
                    { name: '해남읍' },
                    { name: '현산면' },
                    { name: '옥천면' },
                    { name: '계곡면' },
                    { name: '마산면' },
                    { name: '황산면' },
                    { name: '산이면' },
                    { name: '문내면' },
                    { name: '화원면' },
                    { name: '북평면' },
                    { name: '북일면' },
                    { name: '화산면' },
                    { name: '송지면' },
                    { name: '삼산면' },
                ],
            },
            {
                name: '영암군',
                sub: [
                    { name: '서호면' },
                    { name: '삼호읍' },
                    { name: '시종면' },
                    { name: '신북면' },
                    { name: '미암면' },
                    { name: '군서면' },
                    { name: '학산면' },
                    { name: '영암읍' },
                    { name: '덕진면' },
                    { name: '도포면' },
                    { name: '금정면' },
                ],
            },
            {
                name: '무안군',
                sub: [
                    { name: '현경면' },
                    { name: '무안읍' },
                    { name: '일로읍' },
                    { name: '삼향읍' },
                    { name: '몽탄면' },
                    { name: '청계면' },
                    { name: '망운면' },
                    { name: '운남면' },
                    { name: '해제면' },
                ],
            },
            {
                name: '함평군',
                sub: [
                    { name: '엄다면' },
                    { name: '학교면' },
                    { name: '손불면' },
                    { name: '함평읍' },
                    { name: '해보면' },
                    { name: '월야면' },
                    { name: '나산면' },
                    { name: '대동면' },
                    { name: '신광면' },
                ],
            },
            {
                name: '영광군',
                sub: [
                    { name: '군서면' },
                    { name: '대마면' },
                    { name: '영광읍' },
                    { name: '묘량면' },
                    { name: '법성면' },
                    { name: '홍농읍' },
                    { name: '염산면' },
                    { name: '불갑면' },
                    { name: '군남면' },
                    { name: '백수읍' },
                    { name: '낙월면' },
                ],
            },
            {
                name: '장성군',
                sub: [
                    { name: '장성읍' },
                    { name: '북이면' },
                    { name: '남면' },
                    { name: '북일면' },
                    { name: '서삼면' },
                    { name: '진원면' },
                    { name: '동화면' },
                    { name: '삼서면' },
                    { name: '북하면' },
                    { name: '삼계면' },
                    { name: '황룡면' },
                ],
            },
            {
                name: '완도군',
                sub: [
                    { name: '완도읍' },
                    { name: '군외면' },
                    { name: '고금면' },
                    { name: '금당면' },
                    { name: '금일읍' },
                    { name: '노화읍' },
                    { name: '보길면' },
                    { name: '생일면' },
                    { name: '소안면' },
                    { name: '신지면' },
                    { name: '약산면' },
                    { name: '청산면' },
                ],
            },
            {
                name: '진도군',
                sub: [{ name: '진도읍' }, { name: '군내면' }, { name: '고군면' }, { name: '의신면' }, { name: '임회면' }, { name: '조도면' }, { name: '지산면' }],
            },
            {
                name: '신안군',
                sub: [
                    { name: '암태면' },
                    { name: '압해읍' },
                    { name: '비금면' },
                    { name: '도초면' },
                    { name: '신의면' },
                    { name: '임자면' },
                    { name: '자은면' },
                    { name: '안좌면' },
                    { name: '팔금면' },
                    { name: '흑산면' },
                    { name: '지도읍' },
                    { name: '증도면' },
                    { name: '하의면' },
                    { name: '장산면' },
                ],
            },
        ],
    },
    {
        name: '경상북도',
        sub: [
            {
                name: '포항시 남구',
                sub: [
                    { name: '연일읍' },
                    { name: '효자동' },
                    { name: '대잠동' },
                    { name: '상도동' },
                    { name: '대도동' },
                    { name: '해도동' },
                    { name: '지곡동' },
                    { name: '이동' },
                    { name: '송도동' },
                    { name: '오천읍' },
                    { name: '청림동' },
                    { name: '일월동' },
                    { name: '송내동' },
                    { name: '괴동동' },
                    { name: '동촌동' },
                    { name: '인덕동' },
                    { name: '구룡포읍' },
                    { name: '동해면' },
                    { name: '장기면' },
                    { name: '송정동' },
                    { name: '장흥동' },
                    { name: '호동' },
                    { name: '대송면' },
                    { name: '호미곶면' },
                ],
            },
            {
                name: '포항시 북구',
                sub: [
                    { name: '기계면' },
                    { name: '흥해읍' },
                    { name: '청하면' },
                    { name: '송라면' },
                    { name: '대흥동' },
                    { name: '득량동' },
                    { name: '죽도동' },
                    { name: '용흥동' },
                    { name: '우현동' },
                    { name: '창포동' },
                    { name: '두호동' },
                    { name: '장성동' },
                    { name: '양덕동' },
                    { name: '환호동' },
                    { name: '여남동' },
                    { name: '남빈동' },
                    { name: '죽장면' },
                    { name: '신광면' },
                    { name: '신흥동' },
                    { name: '상원동' },
                    { name: '여천동' },
                    { name: '덕산동' },
                    { name: '동빈2가' },
                    { name: '덕수동' },
                    { name: '대신동' },
                    { name: '동빈1가' },
                    { name: '항구동' },
                    { name: '기북면' },
                    { name: '중앙동' },
                    { name: '학산동' },
                    { name: '학잠동' },
                ],
            },
            {
                name: '경주시',
                sub: [
                    { name: '건천읍' },
                    { name: '내남면' },
                    { name: '배동' },
                    { name: '강동면' },
                    { name: '인왕동' },
                    { name: '탑동' },
                    { name: '배반동' },
                    { name: '율동' },
                    { name: '서악동' },
                    { name: '효현동' },
                    { name: '광명동' },
                    { name: '서면' },
                    { name: '감포읍' },
                    { name: '양북면' },
                    { name: '양남면' },
                    { name: '동방동' },
                    { name: '도지동' },
                    { name: '용강동' },
                    { name: '동천동' },
                    { name: '조양동' },
                    { name: '시래동' },
                    { name: '구정동' },
                    { name: '외동읍' },
                    { name: '천북면' },
                    { name: '산내면' },
                    { name: '안강읍' },
                    { name: '동부동' },
                    { name: '노동동' },
                    { name: '노서동' },
                    { name: '성건동' },
                    { name: '사정동' },
                    { name: '황성동' },
                    { name: '보문동' },
                    { name: '천군동' },
                    { name: '황용동' },
                    { name: '황오동' },
                    { name: '황남동' },
                    { name: '서부동' },
                    { name: '남산동' },
                    { name: '석장동' },
                    { name: '현곡면' },
                    { name: '암곡동' },
                    { name: '신평동' },
                    { name: '북군동' },
                    { name: '손곡동' },
                    { name: '마동' },
                    { name: '하동' },
                    { name: '북부동' },
                    { name: '성동동' },
                    { name: '구황동' },
                    { name: '진현동' },
                    { name: '시동' },
                    { name: '교동' },
                    { name: '충효동' },
                    { name: '평동' },
                    { name: '덕동' },
                ],
            },
            {
                name: '김천시',
                sub: [
                    { name: '봉산면' },
                    { name: '교동' },
                    { name: '농소면' },
                    { name: '남면' },
                    { name: '아포읍' },
                    { name: '덕곡동' },
                    { name: '양천동' },
                    { name: '구성면' },
                    { name: '지례면' },
                    { name: '대덕면' },
                    { name: '감호동' },
                    { name: '모암동' },
                    { name: '성내동' },
                    { name: '평화동' },
                    { name: '다수동' },
                    { name: '백옥동' },
                    { name: '부곡동' },
                    { name: '지좌동' },
                    { name: '대항면' },
                    { name: '개령면' },
                    { name: '감문면' },
                    { name: '황금동' },
                    { name: '신음동' },
                    { name: '대광동' },
                    { name: '응명동' },
                    { name: '어모면' },
                    { name: '감천면' },
                    { name: '남산동' },
                    { name: '삼락동' },
                    { name: '부항면' },
                    { name: '용두동' },
                    { name: '조마면' },
                    { name: '증산면' },
                    { name: '율곡동' },
                    { name: '문당동' },
                ],
            },
            {
                name: '안동시',
                sub: [
                    { name: '풍산읍' },
                    { name: '태화동' },
                    { name: '옥동' },
                    { name: '송현동' },
                    { name: '수상동' },
                    { name: '북후면' },
                    { name: '서후면' },
                    { name: '일직면' },
                    { name: '남후면' },
                    { name: '남선면' },
                    { name: '이천동' },
                    { name: '풍천면' },
                    { name: '신세동' },
                    { name: '법흥동' },
                    { name: '용상동' },
                    { name: '운흥동' },
                    { name: '천리동' },
                    { name: '남부동' },
                    { name: '남문동' },
                    { name: '안흥동' },
                    { name: '옥야동' },
                    { name: '당북동' },
                    { name: '평화동' },
                    { name: '송천동' },
                    { name: '임하면' },
                    { name: '임동면' },
                    { name: '길안면' },
                    { name: '예안면' },
                    { name: '도산면' },
                    { name: '삼산동' },
                    { name: '서부동' },
                    { name: '동문동' },
                    { name: '동부동' },
                    { name: '화성동' },
                    { name: '목성동' },
                    { name: '와룡면' },
                    { name: '녹전면' },
                    { name: '정상동' },
                    { name: '정하동' },
                    { name: '성곡동' },
                    { name: '명륜동' },
                    { name: '신안동' },
                    { name: '법상동' },
                    { name: '금곡동' },
                    { name: '안기동' },
                    { name: '운안동' },
                    { name: '대석동' },
                    { name: '광석동' },
                    { name: '상아동' },
                    { name: '안막동' },
                    { name: '북문동' },
                    { name: '석동동' },
                    { name: '수하동' },
                    { name: '노하동' },
                    { name: '옥정동' },
                    { name: '율세동' },
                ],
            },
            {
                name: '구미시',
                sub: [
                    { name: '오태동' },
                    { name: '광평동' },
                    { name: '옥성면' },
                    { name: '선산읍' },
                    { name: '상모동' },
                    { name: '임은동' },
                    { name: '신평동' },
                    { name: '공단동' },
                    { name: '사곡동' },
                    { name: '비산동' },
                    { name: '양호동' },
                    { name: '거의동' },
                    { name: '옥계동' },
                    { name: '금전동' },
                    { name: '산동면' },
                    { name: '장천면' },
                    { name: '지산동' },
                    { name: '고아읍' },
                    { name: '송정동' },
                    { name: '황상동' },
                    { name: '인의동' },
                    { name: '임수동' },
                    { name: '도개면' },
                    { name: '해평면' },
                    { name: '선기동' },
                    { name: '형곡동' },
                    { name: '무을면' },
                    { name: '도량동' },
                    { name: '부곡동' },
                    { name: '진평동' },
                    { name: '시미동' },
                    { name: '원평동' },
                    { name: '봉곡동' },
                    { name: '구포동' },
                    { name: '남통동' },
                    { name: '수점동' },
                    { name: '신동' },
                    { name: '구평동' },
                ],
            },
            {
                name: '영주시',
                sub: [
                    { name: '이산면' },
                    { name: '평은면' },
                    { name: '단산면' },
                    { name: '휴천동' },
                    { name: '부석면' },
                    { name: '하망동' },
                    { name: '풍기읍' },
                    { name: '봉현면' },
                    { name: '장수면' },
                    { name: '상망동' },
                    { name: '영주동' },
                    { name: '가흥동' },
                    { name: '문수면' },
                    { name: '상줄동' },
                    { name: '문정동' },
                    { name: '고현동' },
                    { name: '조암동' },
                    { name: '순흥면' },
                    { name: '안정면' },
                    { name: '적서동' },
                    { name: '조와동' },
                    { name: '창진동' },
                    { name: '아지동' },
                ],
            },
            {
                name: '영천시',
                sub: [
                    { name: '도동' },
                    { name: '금호읍' },
                    { name: '북안면' },
                    { name: '언하동' },
                    { name: '자양면' },
                    { name: '임고면' },
                    { name: '대창면' },
                    { name: '문내동' },
                    { name: '창구동' },
                    { name: '과전동' },
                    { name: '화룡동' },
                    { name: '금노동' },
                    { name: '완산동' },
                    { name: '대전동' },
                    { name: '도림동' },
                    { name: '오미동' },
                    { name: '화북면' },
                    { name: '화남면' },
                    { name: '조교동' },
                    { name: '망정동' },
                    { name: '야사동' },
                    { name: '문외동' },
                    { name: '고경면' },
                    { name: '신녕면' },
                    { name: '성내동' },
                    { name: '청통면' },
                    { name: '교촌동' },
                    { name: '서산동' },
                    { name: '화산면' },
                    { name: '오수동' },
                    { name: '작산동' },
                    { name: '도남동' },
                    { name: '본촌동' },
                    { name: '채신동' },
                    { name: '괴연동' },
                    { name: '봉동' },
                    { name: '매산동' },
                    { name: '녹전동' },
                    { name: '범어동' },
                    { name: '쌍계동' },
                    { name: '신기동' },
                ],
            },
            {
                name: '상주시',
                sub: [
                    { name: '낙상동' },
                    { name: '사벌면' },
                    { name: '서문동' },
                    { name: '무양동' },
                    { name: '낙양동' },
                    { name: '개운동' },
                    { name: '신봉동' },
                    { name: '가장동' },
                    { name: '양촌동' },
                    { name: '지천동' },
                    { name: '만산동' },
                    { name: '함창읍' },
                    { name: '청리면' },
                    { name: '공성면' },
                    { name: '외서면' },
                    { name: '공검면' },
                    { name: '낙동면' },
                    { name: '화산동' },
                    { name: '화남면' },
                    { name: '화북면' },
                    { name: '모서면' },
                    { name: '화동면' },
                    { name: '은척면' },
                    { name: '이안면' },
                    { name: '중동면' },
                    { name: '초산동' },
                    { name: '성하동' },
                    { name: '성동동' },
                    { name: '인봉동' },
                    { name: '복룡동' },
                    { name: '서성동' },
                    { name: '남성동' },
                    { name: '연원동' },
                    { name: '흥각동' },
                    { name: '거동동' },
                    { name: '인평동' },
                    { name: '서곡동' },
                    { name: '화개동' },
                    { name: '외답동' },
                    { name: '헌신동' },
                    { name: '병성동' },
                    { name: '부원동' },
                    { name: '남적동' },
                    { name: '냉림동' },
                    { name: '모동면' },
                    { name: '오대동' },
                    { name: '중덕동' },
                    { name: '내서면' },
                    { name: '계산동' },
                    { name: '죽전동' },
                    { name: '화서면' },
                    { name: '외남면' },
                    { name: '남장동' },
                    { name: '도남동' },
                ],
            },
            {
                name: '문경시',
                sub: [
                    { name: '유곡동' },
                    { name: '마성면' },
                    { name: '불정동' },
                    { name: '문경읍' },
                    { name: '호계면' },
                    { name: '가은읍' },
                    { name: '모전동' },
                    { name: '산양면' },
                    { name: '신기동' },
                    { name: '산북면' },
                    { name: '동로면' },
                    { name: '공평동' },
                    { name: '농암면' },
                    { name: '영순면' },
                    { name: '점촌동' },
                    { name: '흥덕동' },
                    { name: '윤직동' },
                    { name: '영신동' },
                    { name: '우지동' },
                    { name: '창동' },
                ],
            },
            {
                name: '경산시',
                sub: [
                    { name: '진량읍' },
                    { name: '하양읍' },
                    { name: '와촌면' },
                    { name: '삼남동' },
                    { name: '삼북동' },
                    { name: '신교동' },
                    { name: '상방동' },
                    { name: '백천동' },
                    { name: '대평동' },
                    { name: '대정동' },
                    { name: '중방동' },
                    { name: '중산동' },
                    { name: '정평동' },
                    { name: '옥곡동' },
                    { name: '사정동' },
                    { name: '옥산동' },
                    { name: '여천동' },
                    { name: '평산동' },
                    { name: '사동' },
                    { name: '남산면' },
                    { name: '점촌동' },
                    { name: '유곡동' },
                    { name: '자인면' },
                    { name: '용성면' },
                    { name: '남천면' },
                    { name: '갑제동' },
                    { name: '압량면' },
                    { name: '서상동' },
                    { name: '계양동' },
                    { name: '임당동' },
                    { name: '조영동' },
                    { name: '삼풍동' },
                    { name: '대동' },
                    { name: '남방동' },
                    { name: '신천동' },
                    { name: '내동' },
                ],
            },
            {
                name: '군위군',
                sub: [{ name: '군위읍' }, { name: '효령면' }, { name: '산성면' }, { name: '고로면' }, { name: '소보면' }, { name: '부계면' }, { name: '우보면' }, { name: '의흥면' }],
            },
            {
                name: '의성군',
                sub: [
                    { name: '의성읍' },
                    { name: '단촌면' },
                    { name: '봉양면' },
                    { name: '단밀면' },
                    { name: '점곡면' },
                    { name: '옥산면' },
                    { name: '금성면' },
                    { name: '구천면' },
                    { name: '안계면' },
                    { name: '춘산면' },
                    { name: '가음면' },
                    { name: '다인면' },
                    { name: '사곡면' },
                    { name: '신평면' },
                    { name: '안평면' },
                    { name: '단북면' },
                    { name: '안사면' },
                    { name: '비안면' },
                ],
            },
            {
                name: '청송군',
                sub: [{ name: '현동면' }, { name: '부남면' }, { name: '진보면' }, { name: '청송읍' }, { name: '파천면' }, { name: '현서면' }, { name: '안덕면' }, { name: '주왕산면' }],
            },
            {
                name: '영양군',
                sub: [{ name: '영양읍' }, { name: '입암면' }, { name: '청기면' }, { name: '일월면' }, { name: '수비면' }, { name: '석보면' }],
            },
            {
                name: '영덕군',
                sub: [
                    { name: '달산면' },
                    { name: '지품면' },
                    { name: '강구면' },
                    { name: '영덕읍' },
                    { name: '남정면' },
                    { name: '축산면' },
                    { name: '병곡면' },
                    { name: '영해면' },
                    { name: '창수면' },
                ],
            },
            {
                name: '청도군',
                sub: [
                    { name: '각남면' },
                    { name: '풍각면' },
                    { name: '각북면' },
                    { name: '청도읍' },
                    { name: '매전면' },
                    { name: '금천면' },
                    { name: '운문면' },
                    { name: '화양읍' },
                    { name: '이서면' },
                ],
            },
            {
                name: '고령군',
                sub: [{ name: '다산면' }, { name: '성산면' }, { name: '개진면' }, { name: '대가야읍' }, { name: '쌍림면' }, { name: '덕곡면' }, { name: '운수면' }, { name: '우곡면' }],
            },
            {
                name: '성주군',
                sub: [
                    { name: '초전면' },
                    { name: '선남면' },
                    { name: '대가면' },
                    { name: '용암면' },
                    { name: '수륜면' },
                    { name: '성주읍' },
                    { name: '월항면' },
                    { name: '금수면' },
                    { name: '벽진면' },
                    { name: '가천면' },
                ],
            },
            {
                name: '칠곡군',
                sub: [{ name: '왜관읍' }, { name: '지천면' }, { name: '동명면' }, { name: '석적읍' }, { name: '북삼읍' }, { name: '약목면' }, { name: '기산면' }, { name: '가산면' }],
            },
            {
                name: '예천군',
                sub: [
                    { name: '호명면' },
                    { name: '풍양면' },
                    { name: '예천읍' },
                    { name: '유천면' },
                    { name: '용궁면' },
                    { name: '개포면' },
                    { name: '감천면' },
                    { name: '보문면' },
                    { name: '지보면' },
                    { name: '효자면' },
                    { name: '용문면' },
                    { name: '은풍면' },
                ],
            },
            {
                name: '봉화군',
                sub: [
                    { name: '석포면' },
                    { name: '법전면' },
                    { name: '명호면' },
                    { name: '상운면' },
                    { name: '봉화읍' },
                    { name: '소천면' },
                    { name: '재산면' },
                    { name: '봉성면' },
                    { name: '춘양면' },
                    { name: '물야면' },
                ],
            },
            {
                name: '울진군',
                sub: [
                    { name: '울진읍' },
                    { name: '평해읍' },
                    { name: '기성면' },
                    { name: '죽변면' },
                    { name: '후포면' },
                    { name: '근남면' },
                    { name: '매화면' },
                    { name: '온정면' },
                    { name: '금강송면' },
                    { name: '북면' },
                ],
            },
            { name: '울릉군', sub: [{ name: '울릉읍' }, { name: '서면' }, { name: '북면' }] },
        ],
    },
    {
        name: '경상남도',
        sub: [
            {
                name: '창원시 의창구',
                sub: [
                    { name: '도계동' },
                    { name: '동정동' },
                    { name: '소계동' },
                    { name: '팔용동' },
                    { name: '동읍' },
                    { name: '소답동' },
                    { name: '대원동' },
                    { name: '삼동동' },
                    { name: '두대동' },
                    { name: '신월동' },
                    { name: '봉곡동' },
                    { name: '용호동' },
                    { name: '북면' },
                    { name: '사림동' },
                    { name: '명서동' },
                    { name: '내리동' },
                    { name: '서상동' },
                    { name: '반계동' },
                    { name: '퇴촌동' },
                    { name: '대산면' },
                    { name: '중동' },
                    { name: '봉림동' },
                    { name: '사화동' },
                    { name: '용동' },
                    { name: '용지동' },
                    { name: '반송동' },
                    { name: '명곡동' },
                    { name: '북동' },
                    { name: '차용동' },
                    { name: '덕정동' },
                    { name: '서곡동' },
                ],
            },
            {
                name: '창원시 성산구',
                sub: [
                    { name: '양곡동' },
                    { name: '가음정동' },
                    { name: '외동' },
                    { name: '불모산동' },
                    { name: '내동' },
                    { name: '중앙동' },
                    { name: '상남동' },
                    { name: '성주동' },
                    { name: '남산동' },
                    { name: '사파정동' },
                    { name: '반림동' },
                    { name: '가음동' },
                    { name: '대방동' },
                    { name: '사파동' },
                    { name: '남양동' },
                    { name: '귀산동' },
                    { name: '귀곡동' },
                    { name: '창곡동' },
                    { name: '천선동' },
                    { name: '신촌동' },
                    { name: '안민동' },
                    { name: '웅남동' },
                    { name: '성산동' },
                    { name: '귀현동' },
                    { name: '반지동' },
                    { name: '삼정자동' },
                    { name: '상복동' },
                    { name: '남지동' },
                    { name: '적현동' },
                    { name: '완암동' },
                    { name: '토월동' },
                    { name: '반송동' },
                ],
            },
            {
                name: '창원시 마산합포구',
                sub: [
                    { name: '두월동1가' },
                    { name: '두월동2가' },
                    { name: '부림동' },
                    { name: '상남동' },
                    { name: '서성동' },
                    { name: '신포동2가' },
                    { name: '월남동1가' },
                    { name: '월남동2가' },
                    { name: '월남동3가' },
                    { name: '월남동4가' },
                    { name: '월남동5가' },
                    { name: '월포동' },
                    { name: '자산동' },
                    { name: '장군동4가' },
                    { name: '중성동' },
                    { name: '중앙동1가' },
                    { name: '중앙동2가' },
                    { name: '중앙동3가' },
                    { name: '창동' },
                    { name: '창포동2가' },
                    { name: '해운동' },
                    { name: '홍문동' },
                    { name: '가포동' },
                    { name: '현동' },
                    { name: '진동면' },
                    { name: '진전면' },
                    { name: '우산동' },
                    { name: '월영동' },
                    { name: '진북면' },
                    { name: '남성동' },
                    { name: '동성동' },
                    { name: '산호동' },
                    { name: '신포동1가' },
                    { name: '오동동' },
                    { name: '구산면' },
                    { name: '덕동동' },
                    { name: '대성동1가' },
                    { name: '문화동' },
                    { name: '신월동' },
                    { name: '신창동' },
                    { name: '완월동' },
                    { name: '장군동5가' },
                    { name: '평화동' },
                    { name: '수성동' },
                    { name: '교방동' },
                    { name: '예곡동' },
                    { name: '대내동' },
                    { name: '대창동' },
                    { name: '유록동' },
                    { name: '화영동' },
                    { name: '장군동3가' },
                    { name: '신흥동' },
                    { name: '성호동' },
                    { name: '추산동' },
                    { name: '교원동' },
                    { name: '대외동' },
                    { name: '청계동' },
                    { name: '두월동3가' },
                    { name: '창포동3가' },
                    { name: '창포동1가' },
                    { name: '반월동' },
                    { name: '장군동1가' },
                    { name: '장군동2가' },
                    { name: '대성동2가' },
                ],
            },
            {
                name: '창원시 마산회원구',
                sub: [
                    { name: '구암동' },
                    { name: '석전동' },
                    { name: '양덕동' },
                    { name: '합성동' },
                    { name: '회원동' },
                    { name: '내서읍' },
                    { name: '두척동' },
                    { name: '봉암동' },
                    { name: '회성동' },
                ],
            },
            {
                name: '창원시 진해구',
                sub: [
                    { name: '여좌동' },
                    { name: '태백동' },
                    { name: '경화동' },
                    { name: '석동' },
                    { name: '자은동' },
                    { name: '풍호동' },
                    { name: '장천동' },
                    { name: '서중동' },
                    { name: '남문동' },
                    { name: '죽곡동' },
                    { name: '두동' },
                    { name: '용원동' },
                    { name: '제덕동' },
                    { name: '가주동' },
                    { name: '이동' },
                    { name: '남양동' },
                    { name: '소사동' },
                    { name: '대장동' },
                    { name: '덕산동' },
                    { name: '마천동' },
                    { name: '명동' },
                    { name: '원포동' },
                    { name: '태평동' },
                    { name: '제황산동' },
                    { name: '수송동' },
                    { name: '회현동' },
                    { name: '익선동' },
                    { name: '창선동' },
                    { name: '대천동' },
                    { name: '광화동' },
                    { name: '통신동' },
                    { name: '중앙동' },
                    { name: '부흥동' },
                    { name: '중평동' },
                    { name: '근화동' },
                    { name: '화천동' },
                    { name: '송학동' },
                    { name: '대흥동' },
                    { name: '평안동' },
                    { name: '충무동' },
                    { name: '속천동' },
                    { name: '대죽동' },
                    { name: '안곡동' },
                    { name: '수도동' },
                    { name: '북부동' },
                    { name: '안골동' },
                    { name: '청안동' },
                    { name: '동상동' },
                    { name: '도천동' },
                    { name: '현동' },
                    { name: '연도동' },
                    { name: '성내동' },
                    { name: '인사동' },
                    { name: '충의동' },
                    { name: '숭인동' },
                    { name: '대영동' },
                    { name: '남빈동' },
                    { name: '도만동' },
                    { name: '신흥동' },
                    { name: '송죽동' },
                    { name: '행암동' },
                    { name: '무송동' },
                    { name: '인의동' },
                ],
            },
            {
                name: '진주시',
                sub: [
                    { name: '지수면' },
                    { name: '진성면' },
                    { name: '문산읍' },
                    { name: '호탄동' },
                    { name: '내동면' },
                    { name: '가좌동' },
                    { name: '이반성면' },
                    { name: '사봉면' },
                    { name: '일반성면' },
                    { name: '주약동' },
                    { name: '강남동' },
                    { name: '칠암동' },
                    { name: '동성동' },
                    { name: '인사동' },
                    { name: '대안동' },
                    { name: '평안동' },
                    { name: '계동' },
                    { name: '봉곡동' },
                    { name: '이현동' },
                    { name: '유곡동' },
                    { name: '정촌면' },
                    { name: '명석면' },
                    { name: '상봉동' },
                    { name: '금곡면' },
                    { name: '대평면' },
                    { name: '수곡면' },
                    { name: '판문동' },
                    { name: '망경동' },
                    { name: '금산면' },
                    { name: '본성동' },
                    { name: '남성동' },
                    { name: '장대동' },
                    { name: '옥봉동' },
                    { name: '상대동' },
                    { name: '하대동' },
                    { name: '상평동' },
                    { name: '초전동' },
                    { name: '신안동' },
                    { name: '평거동' },
                    { name: '대곡면' },
                    { name: '집현면' },
                    { name: '중안동' },
                    { name: '미천면' },
                    { name: '충무공동' },
                    { name: '봉래동' },
                    { name: '수정동' },
                    { name: '장재동' },
                    { name: '하촌동' },
                    { name: '귀곡동' },
                ],
            },
            {
                name: '통영시',
                sub: [
                    { name: '무전동' },
                    { name: '용남면' },
                    { name: '도산면' },
                    { name: '광도면' },
                    { name: '당동' },
                    { name: '미수동' },
                    { name: '봉평동' },
                    { name: '도남동' },
                    { name: '태평동' },
                    { name: '정량동' },
                    { name: '동호동' },
                    { name: '한산면' },
                    { name: '북신동' },
                    { name: '산양읍' },
                    { name: '사량면' },
                    { name: '문화동' },
                    { name: '중앙동' },
                    { name: '도천동' },
                    { name: '서호동' },
                    { name: '명정동' },
                    { name: '욕지면' },
                    { name: '항남동' },
                    { name: '인평동' },
                    { name: '평림동' },
                ],
            },
            {
                name: '사천시',
                sub: [
                    { name: '곤명면' },
                    { name: '축동면' },
                    { name: '곤양면' },
                    { name: '서포면' },
                    { name: '대방동' },
                    { name: '실안동' },
                    { name: '노룡동' },
                    { name: '사천읍' },
                    { name: '사남면' },
                    { name: '용현면' },
                    { name: '송포동' },
                    { name: '정동면' },
                    { name: '동금동' },
                    { name: '사등동' },
                    { name: '향촌동' },
                    { name: '동동' },
                    { name: '서동' },
                    { name: '선구동' },
                    { name: '동림동' },
                    { name: '좌룡동' },
                    { name: '늑도동' },
                    { name: '죽림동' },
                    { name: '벌리동' },
                    { name: '봉남동' },
                    { name: '이금동' },
                    { name: '이홀동' },
                    { name: '궁지동' },
                    { name: '용강동' },
                    { name: '신벽동' },
                    { name: '서금동' },
                    { name: '대포동' },
                    { name: '신수동' },
                    { name: '마도동' },
                    { name: '백천동' },
                    { name: '와룡동' },
                ],
            },
            {
                name: '김해시',
                sub: [
                    { name: '삼정동' },
                    { name: '수가동' },
                    { name: '부원동' },
                    { name: '봉황동' },
                    { name: '구산동' },
                    { name: '삼계동' },
                    { name: '내동' },
                    { name: '전하동' },
                    { name: '어방동' },
                    { name: '안동' },
                    { name: '지내동' },
                    { name: '불암동' },
                    { name: '진영읍' },
                    { name: '한림면' },
                    { name: '외동' },
                    { name: '흥동' },
                    { name: '풍유동' },
                    { name: '명법동' },
                    { name: '주촌면' },
                    { name: '내덕동' },
                    { name: '무계동' },
                    { name: '삼문동' },
                    { name: '대청동' },
                    { name: '생림면' },
                    { name: '신문동' },
                    { name: '관동동' },
                    { name: '장유동' },
                    { name: '응달동' },
                    { name: '동상동' },
                    { name: '강동' },
                    { name: '이동' },
                    { name: '서상동' },
                    { name: '대성동' },
                    { name: '진례면' },
                    { name: '유하동' },
                    { name: '부곡동' },
                    { name: '대동면' },
                    { name: '상동면' },
                    { name: '삼방동' },
                    { name: '율하동' },
                    { name: '화목동' },
                ],
            },
            {
                name: '밀양시',
                sub: [
                    { name: '삼랑진읍' },
                    { name: '내이동' },
                    { name: '교동' },
                    { name: '삼문동' },
                    { name: '하남읍' },
                    { name: '부북면' },
                    { name: '산외면' },
                    { name: '산내면' },
                    { name: '상남면' },
                    { name: '상동면' },
                    { name: '남포동' },
                    { name: '단장면' },
                    { name: '초동면' },
                    { name: '가곡동' },
                    { name: '무안면' },
                    { name: '내일동' },
                    { name: '용평동' },
                    { name: '청도면' },
                    { name: '활성동' },
                ],
            },
            {
                name: '거제시',
                sub: [
                    { name: '장목면' },
                    { name: '장승포동' },
                    { name: '두모동' },
                    { name: '아양동' },
                    { name: '아주동' },
                    { name: '옥포동' },
                    { name: '장평동' },
                    { name: '고현동' },
                    { name: '양정동' },
                    { name: '수월동' },
                    { name: '일운면' },
                    { name: '동부면' },
                    { name: '남부면' },
                    { name: '사등면' },
                    { name: '연초면' },
                    { name: '거제면' },
                    { name: '둔덕면' },
                    { name: '하청면' },
                    { name: '상동동' },
                    { name: '문동동' },
                    { name: '삼거동' },
                    { name: '능포동' },
                    { name: '덕포동' },
                ],
            },
            {
                name: '양산시',
                sub: [
                    { name: '하북면' },
                    { name: '용당동' },
                    { name: '삼호동' },
                    { name: '명동' },
                    { name: '주진동' },
                    { name: '평산동' },
                    { name: '덕계동' },
                    { name: '동면' },
                    { name: '남부동' },
                    { name: '중부동' },
                    { name: '북부동' },
                    { name: '신기동' },
                    { name: '북정동' },
                    { name: '산막동' },
                    { name: '상북면' },
                    { name: '다방동' },
                    { name: '원동면' },
                    { name: '물금읍' },
                    { name: '매곡동' },
                    { name: '명곡동' },
                    { name: '소주동' },
                    { name: '호계동' },
                    { name: '주남동' },
                    { name: '어곡동' },
                    { name: '유산동' },
                    { name: '교동' },
                ],
            },
            {
                name: '의령군',
                sub: [
                    { name: '의령읍' },
                    { name: '가례면' },
                    { name: '칠곡면' },
                    { name: '대의면' },
                    { name: '용덕면' },
                    { name: '정곡면' },
                    { name: '부림면' },
                    { name: '유곡면' },
                    { name: '지정면' },
                    { name: '화정면' },
                    { name: '궁류면' },
                    { name: '봉수면' },
                    { name: '낙서면' },
                ],
            },
            {
                name: '함안군',
                sub: [
                    { name: '칠북면' },
                    { name: '칠원읍' },
                    { name: '가야읍' },
                    { name: '군북면' },
                    { name: '산인면' },
                    { name: '함안면' },
                    { name: '법수면' },
                    { name: '대산면' },
                    { name: '칠서면' },
                    { name: '여항면' },
                ],
            },
            {
                name: '창녕군',
                sub: [
                    { name: '창녕읍' },
                    { name: '성산면' },
                    { name: '대합면' },
                    { name: '대지면' },
                    { name: '도천면' },
                    { name: '고암면' },
                    { name: '계성면' },
                    { name: '이방면' },
                    { name: '유어면' },
                    { name: '부곡면' },
                    { name: '남지읍' },
                    { name: '장마면' },
                    { name: '영산면' },
                    { name: '길곡면' },
                ],
            },
            {
                name: '고성군',
                sub: [
                    { name: '대가면' },
                    { name: '고성읍' },
                    { name: '회화면' },
                    { name: '마암면' },
                    { name: '거류면' },
                    { name: '상리면' },
                    { name: '영오면' },
                    { name: '구만면' },
                    { name: '개천면' },
                    { name: '하이면' },
                    { name: '동해면' },
                    { name: '영현면' },
                    { name: '삼산면' },
                    { name: '하일면' },
                ],
            },
            {
                name: '남해군',
                sub: [
                    { name: '이동면' },
                    { name: '남면' },
                    { name: '서면' },
                    { name: '고현면' },
                    { name: '삼동면' },
                    { name: '미조면' },
                    { name: '창선면' },
                    { name: '남해읍' },
                    { name: '상주면' },
                    { name: '설천면' },
                ],
            },
            {
                name: '하동군',
                sub: [
                    { name: '금성면' },
                    { name: '하동읍' },
                    { name: '화개면' },
                    { name: '악양면' },
                    { name: '고전면' },
                    { name: '금남면' },
                    { name: '적량면' },
                    { name: '횡천면' },
                    { name: '양보면' },
                    { name: '북천면' },
                    { name: '진교면' },
                    { name: '청암면' },
                    { name: '옥종면' },
                ],
            },
            {
                name: '산청군',
                sub: [
                    { name: '단성면' },
                    { name: '금서면' },
                    { name: '생초면' },
                    { name: '시천면' },
                    { name: '신안면' },
                    { name: '생비량면' },
                    { name: '산청읍' },
                    { name: '오부면' },
                    { name: '신등면' },
                    { name: '차황면' },
                    { name: '삼장면' },
                ],
            },
            {
                name: '함양군',
                sub: [
                    { name: '함양읍' },
                    { name: '수동면' },
                    { name: '안의면' },
                    { name: '지곡면' },
                    { name: '백전면' },
                    { name: '마천면' },
                    { name: '휴천면' },
                    { name: '유림면' },
                    { name: '서하면' },
                    { name: '서상면' },
                    { name: '병곡면' },
                ],
            },
            {
                name: '거창군',
                sub: [
                    { name: '거창읍' },
                    { name: '남하면' },
                    { name: '남상면' },
                    { name: '웅양면' },
                    { name: '신원면' },
                    { name: '가조면' },
                    { name: '마리면' },
                    { name: '북상면' },
                    { name: '가북면' },
                    { name: '고제면' },
                    { name: '주상면' },
                    { name: '위천면' },
                ],
            },
            {
                name: '합천군',
                sub: [
                    { name: '청덕면' },
                    { name: '쌍책면' },
                    { name: '덕곡면' },
                    { name: '합천읍' },
                    { name: '율곡면' },
                    { name: '대양면' },
                    { name: '쌍백면' },
                    { name: '삼가면' },
                    { name: '야로면' },
                    { name: '봉산면' },
                    { name: '묘산면' },
                    { name: '가회면' },
                    { name: '가야면' },
                    { name: '초계면' },
                    { name: '대병면' },
                    { name: '용주면' },
                    { name: '적중면' },
                ],
            },
        ],
    },
    {
        name: '제주특별자치도',
        sub: [],
    },
];
export default locationData;
