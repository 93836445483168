import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// BANK_CODE
import { BANK_CODE } from '../../SubscribePayment/payBankCodeService';

// react-query
import { useMutation } from 'react-query';
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// custom-hook
import useInput from '../../../hook/useInput';

// components
import LoadingPage from '../../../pages/loading/LoadingPage';

// 계좌정보 생성하기 모달창
function CreateAccountModal({ setCreateAccount }) {
    // useInput(회원정보)
    const [userID, onChangeUserID] = useInput(0); // 회원ID
    const [licenseName, onChangeLicenseName] = useInput(''); // 회원명
    const [licenseNumber, onChangeLicenseNumber] = useInput(0); // 주민번호

    // useInput(계좌정보)
    const [bankCode, setBankCode] = useState(BANK_CODE[0].CODE1); // 은행코드
    const [accountNumber, onChangeAccountNumber] = useInput(0); // 계좌번호
    const [accountHolder, onChangeAccountHolder] = useInput(''); // 예금주
    const [email, onChangeEmail] = useInput(''); // 이메일

    // eslint-disable-next-line no-unused-vars
    const { mutate, isLoading, isError, error, isSuccess } = useMutation('create', async () => {
        let createData = await RefreshCheckApi.post(`/adminGathering/users/account`, {
            // 회원정보
            user_id: userID,
            license_name: licenseName,
            license_number: licenseNumber,
            // 계좌정보
            account_bank: bankCode,
            account_no: accountNumber,
            account_holder: accountHolder,
            email: email,
        }).then((res) => {
            return res.data.message;
        });

        return createData;
    });

    // 에러확인
    useEffect(() => {
        if (error) {
            alert(error.response.data.message);
        }
    }, [error]);

    // 생성 성공 시
    useEffect(() => {
        if (isSuccess) {
            alert(`[${isSuccess}]계좌정보를 생성했습니다.`);
            onClickCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    // 은행선택
    const onChangeBankCode = (e) => {
        setBankCode(e.target.value);
    };

    // 모달창 닫기
    const onClickCancel = () => {
        setCreateAccount(false);
    };

    // 계좌정보 생성하기
    const onClickCreate = () => {
        let body = {
            // 회원정보
            user_id: userID,
            license_name: licenseName,
            license_number: licenseNumber,
            // 계좌정보
            account_bank: bankCode,
            account_no: accountNumber,
            account_holder: accountHolder,
            email: email,
        };

        if (!userID) {
            alert('회원ID를 입력하세요');
        } else if (!licenseName) {
            alert('회원 이름을 입력하세요');
        } else if (!licenseNumber) {
            alert('주민번호를 입력하세요');
        } else if (!bankCode) {
            alert('은행을 선택하세요');
        } else if (!accountNumber) {
            alert('계좌번호를 입력하세요');
        } else if (!accountHolder) {
            alert('예금주명을 입력하세요');
        } else {
            mutate();
        }
    };

    return (
        <DetailModalWrapper id="modal-window">
            <DetailModalBox id="modal-box">
                <Header id="top-line">
                    <Text>계좌등록</Text>
                    <Text style={{ cursor: 'pointer' }} onClick={onClickCancel}>
                        닫기
                    </Text>
                </Header>

                <ModalBoxBody>
                    {false ? (
                        <LoadingPage text={'계좌생성을 위한 정보를 불러오는 중입니다.'} />
                    ) : (
                        <>
                            <label id="label">회원정보</label>
                            <InfoBox className="subscribe-info">
                                <InfoTitle>회원정보</InfoTitle>

                                <ColWrapper>
                                    <Subtitle>회원ID</Subtitle>
                                    <SubInfo>
                                        <Input type="number" placeholder="회원명을 입력하세요" onChange={onChangeUserID} />
                                    </SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>회원명</Subtitle>
                                    <SubInfo>
                                        <Input type="text" placeholder="회원명을 입력하세요" onChange={onChangeLicenseName} />
                                    </SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>주민번호</Subtitle>
                                    <SubInfo>
                                        <Input type="text" placeholder="회원명을 입력하세요" onChange={onChangeLicenseNumber} />
                                    </SubInfo>
                                </ColWrapper>
                            </InfoBox>

                            <label id="label">계좌정보</label>
                            <InfoBox className="subscribe-info">
                                <InfoTitle>계좌정보</InfoTitle>

                                <ColWrapper>
                                    <Subtitle>은행</Subtitle>
                                    <SubInfo>
                                        <Select onChange={onChangeBankCode}>
                                            {BANK_CODE.map((data, index) => {
                                                return (
                                                    <option key={index} value={data.CODE1}>
                                                        {data.KOR}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>계좌번호</Subtitle>
                                    <SubInfo>
                                        <Input type="number" placeholder="계좌번호를 '-'빼고 입력하세요" onChange={onChangeAccountNumber} />
                                    </SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>예금주</Subtitle>
                                    <SubInfo>
                                        <Input type="text" placeholder="예금주명을 입력하세요" onChange={onChangeAccountHolder} />
                                    </SubInfo>
                                </ColWrapper>
                            </InfoBox>
                        </>
                    )}
                </ModalBoxBody>

                <ButtonWeapper style={buttonBoxStyled}>
                    <button className="update" onClick={onClickCreate}>
                        생성
                    </button>
                    <button className="cencle" onClick={onClickCancel}>
                        취소
                    </button>
                </ButtonWeapper>
            </DetailModalBox>
        </DetailModalWrapper>
    );
}

export default CreateAccountModal;

/************************************** styled **************************************/

const DetailModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: #000000b0;
`;

const DetailModalBox = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 100vh;
    height: 70%;
    overflow: auto;

    border-radius: 10px;
    background-color: #fff;

    @media (max-width: 912px) {
        width: 98%;
        height: 90%;
    }

    .close-window {
        padding: 5px 25px;
        text-align: start;
        font-size: 20px;
        color: #428bf7;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        padding: 15px;
        color: gray;
    }
`;

const Header = styled.div`
    z-index: 999;
    display: flex;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    height: 5vh;

    align-items: center;

    @media (max-width: 1120px) {
        width: 100%;
    }
`;

const Text = styled.div`
    padding: 0 15px;

    color: #fff;
    font-size: 16px;
    font-weight: 600;

    line-height: 45px;
`;

const ModalBoxBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding: 0px 30px;

    #label {
        display: none;
    }

    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    width: 100%;
    height: 59vh;

    border-left: ${(props) => (props.leftLine ? '1px solid gray' : '')};
    padding-left: ${(props) => (props.leftLine ? '2%' : '')};

    @media (max-width: 912px) {
        height: 50%;
    }
`;

const InfoTitle = styled.h2`
    margin: 15px 0 30px 0;

    font-size: 17px;
    font-weight: 600;
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 3%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 80px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 0 5px;

    width: 100%;
    height: 27px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const Select = styled.select`
    width: 130px;
    height: 27px;

    outline: none;
    border-color: rgba(66, 139, 247, 0.2);
`;

const Image = styled.img`
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

const ButtonWeapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;

    margin-bottom: 10px;

    @media (max-width: 912px) {
        justify-content: center;
    }

    button {
        cursor: pointer;
        border: 0;
        background-color: #fff;

        width: 120px;
        height: 35px;
    }

    .cencle {
        border: 1px solid rgb(229, 229, 229);
        color: black;

        :hover {
            background-color: rgb(242 242 242);
        }
    }

    .update {
        border: 1px solid rgb(255, 16, 125);
        background-color: rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(198 3 92);
        }
    }

    .save {
        border: 1px solid #428bf7;
        background-color: #428bf7;
        color: #fff;

        :hover {
            background-color: #1d6ee6;
        }
    }
`;

const buttonBoxStyled = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    margin: '20px 20px',
};
