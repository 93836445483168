import React from 'react';
import styled from 'styled-components';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';

export const CategoryListsBox = ({
    categoryLists,
    interestMock,
    onClick = () => {},
    allBtnYN = false,
    text = '전체삭제',
    xiconYN = true,
    onClickAllDelete = () => {},
}) => {
    return (
        <TagBoxWrapper>
            {allBtnYN ? (
                <AllDelete onClick={onClickAllDelete}>
                    <span>{text}</span>
                    {xiconYN ? <CategoryCloseOutlined /> : null}
                </AllDelete>
            ) : null}
            {categoryLists.map((num) => {
                return interestMock.map((code, index) => {
                    if (code.id === num) {
                        return (
                            <div key={index}>
                                <TagBox className="interest-icon" onClick={onClick(num)}>
                                    {code.code_group}
                                    <RightOutlined />
                                    {code.code_name}
                                    <CategoryCloseOutlined />
                                </TagBox>
                            </div>
                        );
                    }
                    return null;
                });
            })}
        </TagBoxWrapper>
    );
};

const TagBoxWrapper = styled.div`
    display: flexbox;
    align-items: start;
    justify-content: start;

    width: 63%;
    margin-left: 5px;
`;

const TagBox = styled.div`
    display: inline-block;

    background-color: rgba(66, 139, 247, 0.08);

    border: 1px solid rgb(66, 139, 247);
    border-radius: 10px;

    width: auto;

    font-size: 12px;
    text-align: center;
    color: rgb(66, 139, 247);

    margin: 2px 2px;
    padding: 5px 7px;
`;

const CategoryCloseOutlined = styled(CloseOutlined)`
    cursor: pointer;

    font-size: 14px;
    font-weight: bold;
    color: black;

    margin-left: 7px;
`;

const AllDelete = styled.div`
    cursor: pointer;
    background-color: rgb(228 96 96 / 17%);

    border: 1px solid rgb(253 149 149);
    border-radius: 10px;

    width: auto;

    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ff3b3b;

    margin: 2px 2px;
    padding: 5px 7px;
`;
