export const GET_USERS_DETAIL = "admin/GET_USERS_DETAIL";
export const GET_USERS_DETAIL_SUCCESS = "admin/GET_USERS_DETAIL_SUCCESS";
export const GET_USERS_DETAIL_FAILURE = "admin/GET_USERS_DETAIL_FAILURE";
export const PUT_USERS_DETAIL = "admin/PUT_USERS_DETAIL";
export const PUT_USERS_DETAIL_SUCCESS = "admin/PUT_USERS_DETAIL_SUCCESS";
export const PUT_USERS_DETAIL_FAILURE = "admin/PUT_USERS_DETAIL_FAILURE";
export const PUT_USERS_DETAIL_ADMIN_MEMO = "admin/PUT_USERS_DETAIL_ADMIN_MEMO";
export const PUT_USERS_DETAIL_ADMIN_MEMO_SUCCESS =
  "admin/PUT_USERS_DETAIL_ADMIN_MEMO_SUCCESS";
export const PUT_USERS_DETAIL_ADMIN_MEMO_FAILURE =
  "admin/PUT_USERS_DETAIL_ADMIN_MEMO_FAILURE";
