import { combineReducers } from 'redux';

// 공통, 로딩
import commonCodeReducer from '../modules/commonCode_reducer/common_code_reducer';
import reduxLoadingReducer from '../modules/redux_loading_reducer/redux_loading_reducer';

// 이용자관리: 회원, 탈퇴회원, 관리자
import usersReducer from '../modules/users_reducer/users_reducer';
import newUsersReducer from '../modules/users_reducer/admin.users_reducer';
import deletedUsersReducer from '../modules/users_reducer/admin.deletedUser.reducer';
import administratorsReducer from '../modules/administrators_reducer/administrators_reducer';

// 단짝맺기관리: 구독결제, 신고, 채팅, 편지
import subscribePaymentsReducer from '../modules/subscribePayments_reducer/subscribePayments_reducer';
import newSubscribePaymentsReducer from '../modules/subscribePayments_reducer/subscribePayments.reducer';
import reportsReducer from '../modules/reports.reducer/reports.reducer';
import chatReducer from '../modules/chatting.reducer/ChattingsReducer';
import getLetterReducer from '../modules/Letter.reducer/LetterReducer';

// 취미/여가 관리: 취미/여가 결제, 호스트, 취미/여가
import activityPaymentsReducer from '../modules/activityPayments_reducer/activityPayments_reducer';
import hostsReducer from '../modules/hosts_reducer/hosts_reducer';
import activitiesReducer from '../modules/activities_reducer/activities_reducer';
import activityReducer from '../modules/activities_reducer/activity.reducer';
import addActivityReducer from '../modules/activities_reducer/activity.create.reducer';

// 대시보드: SMS, 통계, 마케팅, 클릭률(상세유입경로), 고객데이터
import smsReducer from '../modules/sms_reducer/sms_reducer';
import newSmsReducer from '../modules/sms.reducer/sms.reducer';
import statisticsReducer from '../modules/statistics_reducer/statistics_reducer';
import newStatisticsReducer from '../modules/statistics_reducer/statistics.reducer';
import marketingReducer from '../modules/marketing_reduter/marketing_reducer';
import powerUserCurveReducer from '../modules/marketing_reduter/marketing.reducer'; // 마케팅(리뉴얼)
import clickPercentageReducer from '../modules/clickPercentage/clickPercentage_reducer';
import customerDataReducer from '../modules/customer_data_reducer/customer_data_reducer';

// 상품관리 > 호스트 CRUD, 상품 CRUD, 주문내역 조회(주문관리)
import productHostReducer from '../modules/product_reducer/product.host_reducer';
import productReducer from '../modules/product_reducer/product.list_reducer';
import orderReducer from '../modules/product_reducer/product.order_reducer';

// 모임관리
import addGatheringReducer from '../modules/gathering.reducer/createGathering.reducer';
import getGatheringReducer from '../modules/gathering.reducer/gathering.reducer';
import scheduleReducer from '../modules/gathering.reducer/gathering.schedule.reducer';
import gatheringAccountReducer from '../modules/gathering.reducer/gathering.account.reducer';
import gatheringPayUsersReducer from '../modules/gathering.reducer/gathering.payUser.reducer';
import getGatheringContentsReducer from '../modules/gathering.reducer/gatheringContents.reducer';
import getGatheringChatReducer from '../modules/gathering.reducer/gatheringChat.reducer';

// 혜택관리: 포인트, 쿠폰, 이벤트, 공지
import pointsReducer from '../modules/points_reducer/points_reducer';
import pointHistoryReducer from '../modules/points_reducer/points.reducer';
import couponsReducer from '../modules/coupons_reducer/coupons.reducer';
import eventsReducer from '../modules/events_reducer/events_reducer';
import newEventsReducer from '../modules/events_reducer/events.reducer';
import announcementsReducer from '../modules/announcements_reducer/announcements_reducer';
import getSurveyReducer from '../modules/survey.reducer/survey.reducer';
import questionReducer from '../modules/question.reducer/QuestionReducer';

const rootReducer = combineReducers({
    commonCodeReducer: commonCodeReducer, // 공통
    reduxLoadingReducer: reduxLoadingReducer, // 로딩

    /********************* 이용자관리 *********************/
    usersReducer: usersReducer, // 회원
    newUsersReducer: newUsersReducer, // New 회원
    deletedUsersReducer: deletedUsersReducer, // New 회원
    administratorsReducer: administratorsReducer, // 관리자

    /********************* 대쉬보드 *********************/
    smsReducer: smsReducer, // SMS
    newSmsReducer: newSmsReducer, // SMS
    statisticsReducer: statisticsReducer, // 통계
    newStatisticsReducer: newStatisticsReducer, // 통계(리펙토링)
    marketingReducer: marketingReducer, // 마케팅
    powerUserCurveReducer: powerUserCurveReducer, // 마케팅
    clickPercentageReducer: clickPercentageReducer, // 클릭률(상세유입경로)
    customerDataReducer: customerDataReducer, // 고객데이터

    /********************* 단짝관리 *********************/
    subscribePaymentsReducer: subscribePaymentsReducer, // 구독결제
    newSubscribePaymentsReducer: newSubscribePaymentsReducer, // 구독결제
    reportsReducer: reportsReducer, // 신고
    chatReducer: chatReducer, // 채팅
    getLetterReducer: getLetterReducer, // 편지

    /********************* 모임관리 *********************/
    addGatheringReducer: addGatheringReducer, // 모임생성
    getGatheringReducer: getGatheringReducer, // 모임 리스트 조회
    scheduleReducer: scheduleReducer, // 모임 일정
    gatheringAccountReducer: gatheringAccountReducer, // 계좌관리
    gatheringPayUsersReducer: gatheringPayUsersReducer, // 결제회원
    getGatheringContentsReducer: getGatheringContentsReducer, // 결제회원
    getGatheringChatReducer: getGatheringChatReducer, // 모임채팅 조회

    /********************* 상품관리 *********************/
    productHostReducer: productHostReducer, // 상품리스트 > 호스트 생성
    productReducer: productReducer, // 상품리스트 > 상품추가, 조회, 수정, 삭제
    orderReducer: orderReducer, // 주문관리 > 주문내역조회

    /********************* 혜택관리 *********************/
    pointsReducer: pointsReducer, // 포인트
    pointHistoryReducer: pointHistoryReducer, // 포인트
    couponsReducer: couponsReducer, // 쿠폰
    eventsReducer: eventsReducer, // 이벤트
    newEventsReducer: newEventsReducer, // 이벤트
    announcementsReducer: announcementsReducer, // 공지
    getSurveyReducer: getSurveyReducer, // 설문관리
    questionReducer: questionReducer, // 설문관리

    /********************* 취미/여가 *********************/
    activityPaymentsReducer: activityPaymentsReducer, // 취미/여가 결제
    hostsReducer: hostsReducer, // 호스트
    activitiesReducer: activitiesReducer, // 놀이
    activityReducer: activityReducer, // 취미/여가
    addActivityReducer: addActivityReducer, // 취미/여가 생성
});

export default rootReducer;
