import { useCallback, useEffect, useRef, useState } from 'react';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { LText } from '../../designComponents/text/ContentText';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { MainButton } from '../../designComponents/buttons/Buttons';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_ip } from '../../communication_system/communication_ips';
import HorizontalImageLists from '../../designComponents/BeautifulDND/HorizontalImageLists';
import { SelectLine } from '../../designComponents/dropdowns/Selectdown';
import { isEmptyObj } from '../../utils/textFilter';
import CKEditorComponent from '../../components/ProductComponent/ckEditor';

/** 상품추가 페이지 */
const AddProduct = ({ addProductParam, setAddProductParam }) => {
    const [selfInputToggle, setSelfInputToggle] = useState(false);
    const [orignHosts, setOrignHosts] = useState([]);
    const [hostLists, setHostLists] = useState([]);
    const [hostSelect, setHostSelect] = useState({});
    const [html, setHtml] = useState('');
    const [htmlImage, setHtmlImage] = useState('');
    useEffect(() => {
        let obj = JSON.parse(JSON.stringify(addProductParam));
        let copyObj = { ...obj };
        copyObj.body.text_info = html;
        copyObj.body.text_info_image = htmlImage;
        setAddProductParam(copyObj);
    }, [htmlImage, html]);
    useEffect(() => {
        const res = RefreshCheckApi.get('/adminProduct/hostInformations');
        res.then((response) => {
            setOrignHosts(response.data.data);
            let listTile = []; // { id: 1, title: '휴대폰번호', value: 1 }
            response.data.data.forEach((item) => {
                listTile.push({ id: item.id, title: item.name, value: item.id });
            });
            setHostLists(listTile);
            setHostSelect(response.data.data[0]);
            let obj = JSON.parse(JSON.stringify(addProductParam));
            let copyObj = { ...obj };
            copyObj.body.host = response.data.data[0];
            setAddProductParam(copyObj);
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /** 상품가격 할인율 계산 함수 */
    const percentFilter = (list_price, discount_price) => {
        const _list_price = parseInt(list_price);
        const _discount_price = parseInt(discount_price);
        if (_list_price && _discount_price) {
            const result = 100 - (_discount_price / _list_price) * 100;
            return result.toFixed(2);
        } else {
            return 0;
        }
    };

    /** 타겟 이미지 클릭 */
    const imageInput = useRef();
    const onClickImageUpload = () => {
        imageInput.current.click();
    };

    /** 이미지를 서버로 보낸다 */
    const requestServer = useCallback(
        async (FormData) => {
            const result = await RefreshCheckApi.post(main_server_ip + '/adminProduct/productImage', FormData);
            setTimeout(() => {
                let obj = JSON.parse(JSON.stringify(addProductParam));
                let copyObj = { ...obj };
                if (copyObj.body.image_lists?.length > 0) {
                    copyObj.body.image_lists = [...copyObj.body.image_lists, ...result.data];
                } else {
                    copyObj.body.image_lists = result.data;
                }
                setAddProductParam(copyObj);
            }, 1000);
        },
        [addProductParam, setAddProductParam],
    );

    /** 이미지 업로드 */
    const onChangeImages = useCallback(
        async (e) => {
            const imageFormData = new FormData();
            [].forEach.call(e.target.files, (f) => {
                imageFormData.append('image', f);
            });
            requestServer(imageFormData);
        },
        [requestServer],
    );

    const getItems = (originData) => {
        let arr = []; // {id: 'item-0', content: 'item 0'}
        originData?.forEach((url, index) => {
            arr.push({ id: `item-${index}`, content: url });
        });
        return arr;
    };

    return (
        <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', alignItems: 'start' }}>
            <FlexMainWrapper style={{ alignItems: 'start', padding: '20px', gap: '20px', width: '95.5%' }} mobileOn>
                <FlexMainWrapper style={{ flexDirection: 'column', width: '50%', marginBottom: '20px' }}>
                    <LText text={'상품 등록'} />
                    <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', gap: '15px', marginTop: '15px' }}>
                        <InputLine
                            title={'상품명'}
                            placeholder={'상품명을 입력해주세요.'}
                            wrapperStyle={{ margin: 0 }}
                            titleStyle={{ textAlign: 'start', width: '60px' }}
                            inputStyle={{ width: '250px' }}
                            onChange={(e) => {
                                let obj = JSON.parse(JSON.stringify(addProductParam));
                                let copyObj = { ...obj };
                                copyObj.body.name = e.target.value;

                                setAddProductParam(copyObj);
                            }}
                        />
                        <InputLine
                            title={'상품 설명'}
                            placeholder={'상품 설명 내용을 입력해주세요.'}
                            wrapperStyle={{ margin: 0 }}
                            titleStyle={{ textAlign: 'start', width: '60px' }}
                            inputStyle={{ width: '250px' }}
                            onChange={(e) => {
                                let obj = JSON.parse(JSON.stringify(addProductParam));
                                let copyObj = { ...obj };
                                copyObj.body.promotional_title = e.target.value;
                                setAddProductParam(copyObj);
                            }}
                        />
                        <InputLine
                            title={'상품 가격'}
                            placeholder={'0원'}
                            wrapperStyle={{ margin: 0 }}
                            titleStyle={{ textAlign: 'start', width: '60px' }}
                            inputStyle={{ width: '250px' }}
                            onChange={(e) => {
                                let obj = JSON.parse(JSON.stringify(addProductParam));
                                let copyObj = { ...obj };
                                copyObj.body.list_price = e.target.value;
                                setAddProductParam(copyObj);
                            }}
                        />
                        <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'start', width: '100%' }}>
                            <InputLine
                                title={'할인가'}
                                placeholder={'0원'}
                                wrapperStyle={{ margin: 0 }}
                                titleStyle={{ textAlign: 'start', width: '60px' }}
                                inputStyle={{ width: '250px' }}
                                onChange={(e) => {
                                    let obj = JSON.parse(JSON.stringify(addProductParam));
                                    let copyObj = { ...obj };
                                    copyObj.body.discount_price = e.target.value;
                                    setAddProductParam(copyObj);
                                }}
                            />
                            <FlexMainWrapper style={{ justifyContent: 'end', width: '335px' }}>
                                <FlexTwoText
                                    text01={null}
                                    text02={`${percentFilter(addProductParam.body?.list_price, addProductParam.body?.discount_price)}% 할인된 가격 입니다.`}
                                    text02Style={{ color: '#797979', fontSize: '12px' }}
                                />
                            </FlexMainWrapper>
                        </FlexMainWrapper>
                        <InputLine
                            title={'판매처 링크'}
                            placeholder={'https://www.sinor.co.kr'}
                            wrapperStyle={{ margin: 0 }}
                            titleStyle={{ textAlign: 'start', width: '60px' }}
                            inputStyle={{ width: '250px' }}
                            onChange={(e) => {
                                let obj = JSON.parse(JSON.stringify(addProductParam));
                                let copyObj = { ...obj };
                                copyObj.body.refund_policy = e.target.value;
                                setAddProductParam(copyObj);
                            }}
                        />
                        <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                            <input type="file" name="host-logo-image" multiple hidden ref={imageInput} onChange={onChangeImages} />
                            <InputLine
                                title={'이미지 등록'}
                                placeholder={'이미지를 등록해 주세요.'}
                                wrapperStyle={{ margin: 0 }}
                                titleStyle={{ textAlign: 'start', width: '60px' }}
                                inputStyle={{ width: '190px' }}
                                disabled={true}
                            />
                            <MainButton style={{ borderRadius: 0, height: '31px' }} text={'추가'} onClick={onClickImageUpload} />
                        </FlexMainWrapper>
                        <HorizontalImageLists
                            images={getItems(addProductParam.body?.image_lists)}
                            originData={addProductParam}
                            onChange={(arr) => {
                                let obj = JSON.parse(JSON.stringify(addProductParam));
                                let copyObj = { ...obj };
                                copyObj.body.image_lists = arr.body.image_lists;
                                setAddProductParam(copyObj);
                            }}
                        />
                    </FlexMainWrapper>
                </FlexMainWrapper>

                <FlexMainWrapper style={{ flexDirection: 'column', width: '50%', marginBottom: '20px' }}>
                    <LText text={'판매처 등록'} />
                    <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', gap: '20px', marginTop: '15px' }}>
                        {selfInputToggle ? (
                            <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                                <InputLine
                                    title={'판매처명'}
                                    placeholder={'판매처명을 입력해주세요.'}
                                    wrapperStyle={{ margin: 0 }}
                                    titleStyle={{ textAlign: 'start', width: '85px', fontWeight: '500' }}
                                    inputStyle={{ width: '190px', height: '24px' }}
                                    onChange={(e) => {
                                        let obj = JSON.parse(JSON.stringify(addProductParam));
                                        let copyObj = { ...obj };
                                        copyObj.body.host = { name: e.target.value, self: true };
                                        setAddProductParam(copyObj);
                                    }}
                                />
                                <MainButton style={{ borderRadius: 0, height: '30px' }} text={'선택 입력'} width={70} onClick={() => setSelfInputToggle(false)} />
                            </FlexMainWrapper>
                        ) : (
                            <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                                <SelectLine
                                    title={'판매처명'}
                                    list={hostLists}
                                    wrapperStyle={{ alignItems: 'center' }}
                                    titleStyle={{ textAlign: 'start', width: '88px', fontWeight: '500', color: 'rgb(121, 121, 121)' }}
                                    selectStyle={{ width: '200px', height: '30px' }}
                                    resetThenSet={(target) => {
                                        const [selected] = orignHosts.filter((item) => item.id === parseInt(target.value));
                                        setHostSelect(selected);
                                        let obj = JSON.parse(JSON.stringify(addProductParam));
                                        let copyObj = { ...obj };
                                        copyObj.body.host = selected;
                                        setAddProductParam(copyObj);
                                    }}
                                />
                                <MainButton style={{ borderRadius: 0, height: '30px' }} text={'직접 입력'} width={70} onClick={() => setSelfInputToggle(true)} />
                            </FlexMainWrapper>
                        )}

                        <FlexTwoText
                            text01={'대표자 이름'}
                            text02={isEmptyObj(hostSelect) ? hostSelect.name : <span style={{ color: '#797979' }}>판매처를 선택해 주세요.</span>}
                            text01Style={{ textAlign: 'start', width: '96px', fontWeight: '500' }}
                        />
                        <FlexTwoText
                            text01={'대표 전화번호'}
                            text02={isEmptyObj(hostSelect) ? hostSelect.represent_number : <span style={{ color: '#797979' }}>판매처를 선택해 주세요.</span>}
                            text01Style={{ textAlign: 'start', width: '96px', fontWeight: '500' }}
                        />
                        <FlexTwoText
                            text01={'사업자 등록번호'}
                            text02={isEmptyObj(hostSelect) ? hostSelect.business_number : <span style={{ color: '#797979' }}>판매처를 선택해 주세요.</span>}
                            text01Style={{ textAlign: 'start', width: '96px', fontWeight: '500' }}
                        />
                        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', gap: '10px' }}>
                            <FlexTwoText
                                text01={'사업장 주소'}
                                text02={isEmptyObj(hostSelect) ? hostSelect.zone_code : <span style={{ color: '#797979' }}>00000</span>}
                                text01Style={{ textAlign: 'start', width: '96px', fontWeight: '500' }}
                            />
                            <FlexTwoText
                                text02={isEmptyObj(hostSelect) ? hostSelect.address : <span style={{ color: '#797979' }}>판매처를 선택해 주세요.</span>}
                                text01Style={{ textAlign: 'start', width: '96px', fontWeight: '500' }}
                            />
                            <FlexTwoText
                                text02={isEmptyObj(hostSelect) ? hostSelect.address_detail : <span style={{ color: '#797979' }}>판매처를 선택해 주세요.</span>}
                                text01Style={{ textAlign: 'start', width: '96px', fontWeight: '500' }}
                            />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </FlexMainWrapper>

            <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'start', padding: '20px', width: '95.4%' }}>
                <LText text={'상품 설명 이미지'} />
                <CKEditorComponent
                    addProductParam={addProductParam}
                    setAddProductParam={setAddProductParam}
                    onHtmlChange={(html) => {
                        setHtml(html.replaceAll('<figure', '<div').replaceAll('</figure>', '</div>'));
                    }}
                    onImageHtmlChange={(html) => {}}
                />
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export default AddProduct;
