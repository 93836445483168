import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';
import { QuestionBox } from './QuestionComponent';

// redux
import { useSelector } from 'react-redux';

//  components
import ResponseSummarize from './ResponseSummarizeComponent';
import ResponseQuestion from './ResponseQuestionComponent';
import ResponseShowDetail from './ResponseShowDetailComponent';
import LoadingPage from '../../pages/loading/LoadingPage';

// 설문관리 상세 > 응답
function ResponseComponent({ count }) {
    // useParams
    const { survey_id } = useParams();

    // useSelector
    const { questionLists, getQuestionLoading } = useSelector((state) => state.getSurveyReducer);

    // state
    const [selectQuestionID, setSelectQuestionID] = useState(questionLists[0].id);
    const [summarize, setSummarize] = useState(true);
    const [question, setQuestion] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    /******************************** useEffect ********************************/

    // 페이지 URL 변경
    useEffect(() => {
        if (summarize) {
            window.history.pushState('', '', `/survey/management/detail/${survey_id}/response`);
        } else if (question) {
            window.history.pushState('', '', `/survey/management/detail/${survey_id}/response/question`);
        } else if (showDetail) {
            window.history.pushState('', '', `/survey/management/detail/${survey_id}/response/detail`);
        }
    }, [survey_id, summarize, question, showDetail]);

    /******************************** function ********************************/

    // 컴포넌트 라우팅
    const onClickRoutes = (page) => () => {
        if (page === 1) {
            setSummarize(true);
            setQuestion(false);
            setShowDetail(false);
        } else if (page === 2) {
            setSummarize(false);
            setQuestion(true);
            setShowDetail(false);
        } else if (page === 3) {
            setSummarize(false);
            setQuestion(false);
            setShowDetail(true);
        }
    };

    /********************************** html **********************************/
    return (
        <div>
            {questionLists.length === 0 || getQuestionLoading ? (
                <LoadingPage text={'응답 결과에 대한 정보를 불러오는 중입니다.'} />
            ) : (
                <div>
                    <QuestionBox style={{ padding: '20px 20px 12px 20px' }}>
                        <Title>
                            <Title02>
                                <span>응답</span>
                                <span>{count}개</span>
                            </Title02>

                            <CustomMoreOutlined />
                        </Title>

                        <Title style={{ margin: '10% auto 0 auto', width: '70%' }}>
                            <ChoisePage status={summarize} onClick={onClickRoutes(1)}>
                                요약
                            </ChoisePage>
                            <ChoisePage status={question} onClick={onClickRoutes(2)}>
                                질문
                            </ChoisePage>
                            <ChoisePage status={showDetail} onClick={onClickRoutes(3)}>
                                개별 보기
                            </ChoisePage>
                        </Title>
                    </QuestionBox>

                    <div id="body">{summarize && <ResponseSummarize count={count} />}</div>
                    <div id="body">
                        {question && (
                            <ResponseQuestion
                                count={count}
                                setSummarize={setSummarize}
                                setQuestion={setQuestion}
                                setShowDetail={setShowDetail}
                                selectQuestionID={selectQuestionID}
                                setSelectQuestionID={setSelectQuestionID}
                            />
                        )}
                    </div>
                    <div id="body">{showDetail && <ResponseShowDetail count={count} selectQuestionID={selectQuestionID} />}</div>
                </div>
            )}
        </div>
    );
}

export default ResponseComponent;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title02 = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    font-size: 20px;
    font-weight: 500;
`;

const CustomMoreOutlined = styled(MoreOutlined)`
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;

    padding: 10px;

    :hover {
        background-color: #cccccc;
        border-radius: 50%;
    }
`;

const ChoisePage = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;

    text-decoration-thickness: ${(props) => (props.status ? '2px !important' : '')};
    text-underline-offset: ${(props) => (props.status ? '15px' : '')};

    text-decoration: ${(props) => (props.status ? 'underline' : '')};
    font-weight: ${(props) => (props.status ? 'bold' : '')};
    color: ${(props) => (props.status ? 'rgb(103, 58, 183)' : '')};
`;
