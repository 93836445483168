import produce from 'immer';
import { handleActions } from 'redux-actions';
// import axios from 'axios';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 가상계좌 생성
export const GET_VIRTUAL_ACCOUNNT_REQUEST = 'GET_VIRTUAL_ACCOUNNT_REQUEST';
export const GET_VIRTUAL_ACCOUNNT_SUCCESS = 'GET_VIRTUAL_ACCOUNNT_SUCCESS';
export const GET_VIRTUAL_ACCOUNNT_FAILURE = 'GET_VIRTUAL_ACCOUNNT_FAILURE';
// 가상계좌 조회(using user_id)
export const GET_USER_VIRTUAL_ACCOUNNT_REQUEST = 'GET_USER_VIRTUAL_ACCOUNNT_REQUEST';
export const GET_USER_VIRTUAL_ACCOUNNT_SUCCESS = 'GET_USER_VIRTUAL_ACCOUNNT_SUCCESS';
export const GET_USER_VIRTUAL_ACCOUNNT_FAILURE = 'GET_USER_VIRTUAL_ACCOUNNT_FAILURE';
// 환불신청
export const REQUEST_REFUND_REQUEST = 'REQUEST_REFUND_REQUEST';
export const REQUEST_REFUND_SUCCESS = 'REQUEST_REFUND_SUCCESS';
export const REQUEST_REFUND_FAILURE = 'REQUEST_REFUND_FAILURE';
// 리셋
export const RESET_STATE = 'RESET_STATE';

const initialState = {
    payInfo: null, // 결제정보 객체
    virtualAccountId: null, // 가상계좌 pay_id
    requestRefundResult: null, // 환불신청 결과
    // 결제정보
    payInfoLoading: false,
    payInfoDone: false,
    payInfoError: null,
    // 가상계좌 요청
    loadVirtualAccountLoading: false,
    loadVirtualAccountDone: false,
    loadVirtualAccountError: null,
    // 환불신청
    requestRefundLoading: false,
    requestRefundDone: false,
    requestRefundError: null,
};

// 가상계좌 발급 요청
export const requestPayInfo = (param) => async (dispatch) => {
    dispatch({
        type: GET_VIRTUAL_ACCOUNNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post('adminSubscribe/virtualAcount', param);

        dispatch({
            type: GET_VIRTUAL_ACCOUNNT_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_VIRTUAL_ACCOUNNT_FAILURE,
            payload: error,
        });
    }
};

// 가상계좌 조회
export const getUserVirtualAccount = (pay_id) => async (dispatch) => {
    dispatch({
        type: GET_USER_VIRTUAL_ACCOUNNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`adminSubscribe/userVirtualAccount/${pay_id}`);

        dispatch({
            type: GET_USER_VIRTUAL_ACCOUNNT_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_VIRTUAL_ACCOUNNT_FAILURE,
            payload: error,
        });
    }
};

// 환불신청
export const requestSubscribeRefund = (input) => async (dispatch) => {
    dispatch({
        type: REQUEST_REFUND_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSubscribe/${input}/cancel`);

        dispatch({
            type: REQUEST_REFUND_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        console.error(error);
        dispatch({
            type: REQUEST_REFUND_FAILURE,
            error: error.response.data.message,
        });
    }
};

const newSubscribePaymentsReducer = handleActions(
    {
        // 결제정보 요청
        [GET_VIRTUAL_ACCOUNNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.payInfoLoading = true;
                draft.payInfoDone = false;
                draft.payInfoError = null;
            }),
        [GET_VIRTUAL_ACCOUNNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.payInfoLoading = false;
                draft.virtualAccountId = action.payload;
                draft.payInfoDone = true;
            }),
        [GET_VIRTUAL_ACCOUNNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.payInfoLoading = true;
                draft.payInfoError = action.error;
            }),

        // 가상계좌 정보 요청
        [GET_USER_VIRTUAL_ACCOUNNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadVirtualAccountLoading = true;
                draft.loadVirtualAccountDone = false;
                draft.loadVirtualAccountError = null;
            }),
        [GET_USER_VIRTUAL_ACCOUNNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadVirtualAccountLoading = action.payload ? false : true;
                draft.payInfo = action.payload[0]; // 가상계좌 정보 담기
                draft.loadVirtualAccountDone = action.payload ? true : false;
            }),
        [GET_USER_VIRTUAL_ACCOUNNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadVirtualAccountLoading = true;
                draft.loadVirtualAccountError = action.error;
            }),

        // 환불신청
        [REQUEST_REFUND_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.requestRefundLoading = true;
                draft.requestRefundDone = false;
                draft.requestRefundError = null;
            }),
        [REQUEST_REFUND_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.requestRefundLoading = action.payload ? false : true;
                draft.requestRefundResult = action.payload;
                draft.requestRefundDone = action.payload ? true : false;
            }),
        [REQUEST_REFUND_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.requestRefundLoading = false;
                draft.requestRefundError = action.error;
            }),

        // 상태 리셋
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                draft.requestRefundLoading = false;
                draft.requestRefundDone = false;
                draft.requestRefundError = null;
                draft.requestRefundResult = null;
                // 가상계좌 발급요청
                draft.payInfoLoading = false;
                draft.payInfoDone = false;
                draft.payInfoError = null;
                draft.virtualAccountId = null;
            }),
    },
    initialState,
);

export default newSubscribePaymentsReducer;
