import React from 'react';
import styled from 'styled-components';

import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { BANK_CODE } from '../../components/SubscribePayment/payBankCodeService';

const listType = [{ id: 1, title: '휴대폰번호', value: 1 }];

export const MainSelect = ({ title = 'title', itemKey = 'value', style, list = listType, value, resetThenSet = () => {}, disabled, }) => {
    return (
        <Select value={value} onChange={(e) => resetThenSet(e.target)} style={{ ...style }} disabled={disabled}>
            {list?.map((item, index) => {
                return (
                    <option key={index} value={item[itemKey]}>
                        {item[title]}
                    </option>
                );
            })}
        </Select>
    );
};

export const SelectLine = ({ wrapperStyle, titleStyle, selectStyle, list = listType, title, itemTitle = 'title', value, resetThenSet = () => {}, itemKey = 'value', disabled }) => {
    return (
        <FlexMainWrapper style={{ ...wrapperStyle }}>
            <Title style={{ ...titleStyle }}>{title}</Title>
            <MainSelect value={value} style={selectStyle} list={list} resetThenSet={resetThenSet} itemKey={itemKey} disabled={disabled} title={itemTitle} />
        </FlexMainWrapper>
    );
};

export const BankSelect = ({ title = 'KOR', itemKey = 'CODE1', style, list = BANK_CODE, value, resetThenSet = () => {}, disabled }) => {
    return (
        <Select value={value} onChange={(e) => resetThenSet(e.target)} style={{ ...style }} disabled={disabled}>
            {list?.map((item, index) => {
                return (
                    <option key={index} value={item[itemKey]}>
                        {item[title]}
                    </option>
                );
            })}
        </Select>
    );
};

export const BankSelectLine = ({ wrapperStyle, titleStyle, selectStyle, list = listType, title, value, resetThenSet = () => {} }) => {
    return (
        <FlexMainWrapper style={{ ...wrapperStyle }}>
            <Title style={{ ...titleStyle }}>{title}</Title>
            <BankSelect value={value} style={selectStyle} list={list} resetThenSet={resetThenSet} />
        </FlexMainWrapper>
    );
};

const defaultCategoryLine = [
    {
        code: 0,
        code_group: 'DRINK_CODE',
        code_name: '애주가',
        created_at: '2022-09-06T05:14:02.000Z',
        deleted_at: null,
        id: 68,
        sub_code_group: null,
        updated_at: '2022-09-05T20:14:02.000Z',
    },
];
export const CategorySelect = ({ style, list = defaultCategoryLine, value, resetThenSet = () => {}, disabled }) => {
    return (
        <Select value={value}  onChange={(e) => resetThenSet(e.target)} style={{ ...style }} disabled={disabled}>
            { list.map((item, index) => {

                return (
                    <option key={index} value={item.id}>
                        {item.code_name}
                    </option>
                );
            }) }
        </Select>
    );
};

export const CategorySelectLine = ({ wrapperStyle, titleStyle, selectStyle, list, title, value, resetThenSet = () => {}, disabled }) => {
    return (
        <FlexMainWrapper style={{ ...wrapperStyle }}>
            <Title style={{ ...titleStyle }}>{title}</Title>
            <CategorySelect value={value} style={selectStyle} list={list} resetThenSet={resetThenSet} disabled={disabled} />
        </FlexMainWrapper>
    );
};

export const Select = styled.select`
    cursor: pointer;
    outline: none;
    width: 100%;
    padding: 3px 0;
    border: 1px solid rgba(66, 139, 247, 0.2);
`;

const Title = styled.div`
    font-size: 13px;
    color: #797979;
    width: 100px;
`;
