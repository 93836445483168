import createGetRequestThunk from '../redux_functions/create_get_request_thunk';
import {
  GET_STATISTICS_ACCOUNT_LINE,
  GET_STATISTICS_ACCOUNT_RATE,
  GET_STATISTICS_ACCOUNT_TABLE,
  GET_STATISTICS_ACCUMULATE_DATE,
  GET_STATISTICS_ACCUMULATE_TODAY,
  GET_STATISTICS_ADDITIONAL,
  GET_STATISTICS_PAYMENT_TABLE,
} from './statistics_type';
import {
  getAccountTableData,
  getStatisticsAccountRate,
  getStatisticsAccumulateData,
  getStatisticsAccumulateToday,
  getStatisticsAdditional,
  getStatisticsLine,
  getStatisticsPaymentTable,
} from '../../../../communication_system/axios_apis/axios_apis';

export const getStatisticsAccountTableData = createGetRequestThunk(GET_STATISTICS_ACCOUNT_TABLE, getAccountTableData);
export const getStatisticsAccountAccumulateDate = createGetRequestThunk(
  GET_STATISTICS_ACCUMULATE_DATE,
  getStatisticsAccumulateData,
);
// 회원데이터
export const getStatisticsAccountAccumulateToday = createGetRequestThunk(
  GET_STATISTICS_ACCUMULATE_TODAY,
  getStatisticsAccumulateToday,
);
export const getStatisticsLineData = createGetRequestThunk(GET_STATISTICS_ACCOUNT_LINE, getStatisticsLine);
export const getStatisticsAccountCurrentGraphData = createGetRequestThunk(
  GET_STATISTICS_ACCOUNT_RATE,
  getStatisticsAccountRate,
);
export const getStatisticsPayment = createGetRequestThunk(GET_STATISTICS_PAYMENT_TABLE, getStatisticsPaymentTable);
export const getStatisticsAdditionalRate = createGetRequestThunk(GET_STATISTICS_ADDITIONAL, getStatisticsAdditional);
