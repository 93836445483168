import styled from 'styled-components';

const FlexMainWrapper = ({ children, style, onClick = () => {}, mobileOn = false, width, mobileWidth, column = false }) => {
    return (
        <Wrapper style={{ flexDirection: column ? 'column' : 'row', ...style }} onClick={onClick} mobileOn={mobileOn} width={width} mobileWidth={mobileWidth}>
            {children}
        </Wrapper>
    );
};

export const FormWrapper = ({ children, style, onSubmit = (e) => e.preventDefault(), mobileOn = false, width, mobileWidth }) => {
    return (
        <WrapperForm style={{ ...style }} mobileOn={mobileOn} width={width} mobileWidth={mobileWidth} onSubmit={onSubmit}>
            {children}
        </WrapperForm>
    );
};

export default FlexMainWrapper;

export const WrapperForm = styled.form`
    position: relative;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 7px;
    width: ${(props) => `${props.width}%`};
    @media (max-width: 912px) {
        flex-direction: ${(props) => (props.mobileOn ? 'column' : '')};
        padding-bottom: ${(props) => (props.mobileOn ? '25px' : '')};
        width: ${(props) => `${props.mobileWidth}%`};
    }
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 7px;
    width: ${(props) => `${props.width}%`};
    @media (max-width: 912px) {
        flex-direction: ${(props) => (props.mobileOn ? 'column' : '')};
        padding-bottom: ${(props) => (props.mobileOn ? '25px' : '')};
        width: ${(props) => `${props.mobileWidth}%`};
    }
`;

export const Wrapper02 = styled.div`
    background-color: #fff;
    border-radius: 20px;
    padding: 2%;
    margin-bottom: 6%;
`;

export const Wrapper03 = styled.div`
    overflow: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow:
        0px 6px 8px rgba(25, 50, 47, 0.08),
        0px 3px 4px rgba(18, 71, 52, 0.02),
        0px 1px 16px rgba(18, 71, 52, 0.03);
    padding: 1%;
    margin-bottom: 6%;
    label {
        display: none;
    }
    @media (max-width: 912px) {
        width: 96%;
    }
`;

export const FilterBoxSt = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    width: ${(props) => `${props.width}px`};
    padding: 15px 25px;
    border-radius: 10px;
    background-color: rgb(240 240 240);
    @media (max-width: 912px) {
        width: ${(props) => `${props.mobileWidth}%`};
    }
`;

export const SearchbarWrapper = styled.div`
    display: flex;
    margin: 20px 0;
`;
