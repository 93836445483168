import React, { useCallback, useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// react-query && axios $ URL
import { main_server_image_ip } from '../../communication_system/communication_ips';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

/** 일정 결과 Entry */
function ContentsEntry({ item, index, funcParam, setToggle }) {
    const [clickToggle, setClickToggle] = useState(false);

    useEffect(() => {
        setClickToggle(item.content_id === funcParam.contentsID);
    }, [funcParam.contentsID, item.content_id]);

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /** 결과 선택 */
    const onClickResultID = useCallback(
        (item) => () => {
            funcParam.setContentsID(item.content_id);
            funcParam.setContents(item.content.slice(0, 7) + '...');

            if (window.confirm('해당 게시글을 등록하시겠습니까?')) {
                if (funcParam.pickLength >= 8) {
                    return alert('이미 8개의 PICK 게시글이 존재합니다.');
                } else {
                    const response = RefreshCheckApi.post('/adminGathering/pick/contents', item);
                    response.then((res) => {
                        if (res.data.ok) {
                            funcParam.searchData();
                            setToggle(false);
                        }
                    });
                }
            }
        },
        [funcParam, setToggle],
    );

    return (
        <ColumnWrapper key={index} onClick={onClickResultID(item)} clickToggle={clickToggle}>
            <div>
                <Images sizes={100} src={main_server_image_ip + item.contents_image_url} alt="모임 이미지" onError={handleImgError} />
            </div>
            <div>
                <ColWrapper className="pay-contents">
                    <Subtitle>게시글ID</Subtitle>
                    <SubInfo>
                        <span>{item.content_id}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>제목</Subtitle>
                    <SubInfo>
                        <span>{item.content_title}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>작성자</Subtitle>
                    <SubInfo>
                        <span>{item.user_name}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>
                        <span>내용</span>
                    </Subtitle>
                    <SubInfo>{item.content?.length >= 30 ? item.content?.slice(0, 20) + '...' : item.content}</SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>조회수</Subtitle>
                    <SubInfo>
                        <span>{item.look_cnt || 0}명</span>
                    </SubInfo>
                </ColWrapper>
            </div>
        </ColumnWrapper>
    );
}

export default ContentsEntry;

const Images = styled.img`
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

const ColumnWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 10px;

    padding-top: 5px;
    width: 100%;

    background-color: ${(props) => (props.clickToggle ? '#00000038' : '')};

    &:hover {
        background-color: #00000038;
    }
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
    width: 100%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: #fff;

    width: 135px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 100%;
    color: #fff;
`;
