import React from 'react';

export const NoticeColor = ({
    wrapperStyle,
    userBoxStyle,
    userFontStyle,
    adminBoxStyle,
    adminFontStyle,
    userText = '일반 회원',
    adminText = '관리자 계정',
}) => {
    const box = { width: '17px', height: '17px', borderRadius: '5px' };
    const wrapper = { display: 'flex', alignContent: 'center', gap: '5px' };
    return (
        <div style={{ ...wrapper, ...wrapperStyle }}>
            <div style={{ backgroundColor: 'black', ...box, ...userBoxStyle }} />
            <div style={{ fontSize: '14px', marginRight: '7px', ...userFontStyle }}>{userText}</div>
            <div style={{ backgroundColor: 'rgb(255,16,125)', ...box, ...adminBoxStyle }} />
            <div style={{ fontSize: '14px', ...adminFontStyle }}>{adminText}</div>
        </div>
    );
};
