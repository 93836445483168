const ComUtil = require('../utils/comUtil');
const productionMode = ComUtil.IsProduction();

let mainDomain = 'https://admin.senor.co.kr/admin';
let mainServerDomain = 'https://senor.co.kr/';

/** 개발 및 배포 환경 확인 */
if (!productionMode) {
    // mainDomain = 'https://admin.senor.co.kr/admin';
    mainDomain = 'http://localhost:8080/admin';
    // mainDomain = 'https://develop.senor.co.kr/admin';
    mainServerDomain = 'https://develop.senor.co.kr/';
}


export const cdn_image_ip = 'https://x9vbi5rfe2.execute-api.ap-northeast-2.amazonaws.com/prod/resize';

/** 메인 도메인 요청 URL */
export const main_server_ip = mainDomain;
// export const main_server_ip = 'http://localhost:8000/admin';

/** 마케팅 */
export const marketting_main_server_ip = mainServerDomain;
// export const marketting_main_server_ip = 'https://test.senor.co.kr/';
// export const marketting_main_server_ip = 'https://bab6-121-131-132-12.ngrok-free.app/';

/** S3 URL */
export const main_server_image_ip = 'https://sinor-production.s3.ap-northeast-2.amazonaws.com/';

/***********************************************************************
 * export const main_server_ip = 'https://admin.sinor.co.kr/admin';
 * export const main_server_ip = 'http://localhost:8080/admin';
 ***********************************************************************/
