import { useEffect, useRef, useState } from 'react';

// styled-components
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Wrapper } from '../Common/Styles/CommonTableSideModalStyled';
import { TableWrapper, Image, Button, Checkbox } from '../Common/Styles/CommonTableStyled';
import { DragButton, BtnWrapper, DragFilter, FilterText, DragCSSTitle, DragCSS, Status, StaticsRoutingBtn } from './EventLayoutStyled';

// API & URL
import { main_server_image_ip } from '../../communication_system/communication_ips';

// components
import LoadingPage from '../../pages/loading/LoadingPage';
import CreateBanner from './CreateBanner';
import DetailBanner from './DetailBanner';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { appSectionFilter } from '../../utils/textFilter';
import BannerTab from '../../GroupComponent/Tab/BannerTab';
import BannerInfoModal, { BannerLocationModal } from '../BannerComponent/BannerInfoModal';

/** 이벤트 페이지 컴포넌트 */
const Banner = () => {
    const [loading, setLoading] = useState(true);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [checkBannerIdList, setCheckBannerIdList] = useState([]); // 체크된 banner를 담을 배열
    const [createToggle, setCreateToggle] = useState(false);
    const [detailToggle, setDetailToggle] = useState([false, 0]);

    const tableRef = useRef();

    const [reloadToggle, setReloadToggle] = useState(false);

    // refactoring
    const [bannerList, setBannerList] = useState([]);
    const [exposureLocation, setExposureLocation] = useState(1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    // 정보 조회
    const getBannerList = async () => {
        try {
            setLoading(true);

            const bannerListResponse = await RefreshCheckApi.get('/adminEvent/list', {
                params: { exposure_location: Number(exposureLocation) },
            });
            setBannerList(bannerListResponse.data.data);
            setLoading(false);
        } catch (err) {}
    };

    // list 받아오기
    useEffect(() => {
        setCheckBannerIdList([]);
        getBannerList().then();
    }, [exposureLocation, reloadToggle]);

    // 클릭 시 전체 체크
    const onClickAllCheck = (e) => {
        const checked = e.target.checked;
        if (checked) {
            // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
            const idArray = [];
            const dataArray = [];
            bannerList.forEach((data) => {
                if (!data.deleted_at) {
                    idArray.push(data.event_id);
                }
            });
            bannerList.forEach((data) => dataArray.push(data));
            setCheckBannerIdList(idArray);
        } else {
            // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
            setCheckBannerIdList([]);
        }
    };

    // 클릭 시 단일 체크
    const onClickSingleCheck = (banner) => (e) => {
        e.stopPropagation();
        const checked = e.target.checked;

        if (checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            setCheckBannerIdList((prev) => [...prev, banner.event_id]);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setCheckBannerIdList(checkBannerIdList.filter((el) => el !== banner.event_id));
        }
    };

    // 상태필터
    const exposureFilter = (num) => {
        if (Number(num) === 0) {
            return (
                <Status color="rgb(255, 16, 125)" backgroundColor="rgb(255, 227, 240)">
                    비노출
                </Status>
            );
        } else if (Number(num) === 1) {
            return (
                <Status color="rgb(66, 139, 247)" backgroundColor="rgba(66, 139, 247, 0.08)">
                    노출
                </Status>
            );
        }
    };

    // 노출위치 TEXT 필터
    const exposureLocationFilter = (num) => {
        let _num = parseInt(num);

        switch (true) {
            case _num === 1:
                return '메인 페이지';
            case _num === 2:
                return '모임 페이지';
            case _num === 3:
                return '채팅 페이지';
            case _num === 4:
                return '내 정보';
            default:
                return null;
        }
    };

    // 이미지 에러 처리
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    // 베너 생성(등록)
    const onClickCreate = () => {
        setSelectedItem(null);
        setShowInfo(true);
    };

    // 이벤트 베너 상세정보
    const onClickDetailBanner = (item) => (e) => {
        e.stopPropagation();
        setSelectedItem(item);
        setShowInfo(true);
    };

    const onClickDeleteLists = async (e) => {
        try {
            await RefreshCheckApi.delete('/adminEvent', {
                data: {
                    event_id: checkBannerIdList,
                },
            });

            setReloadToggle((prev) => !prev);
        } catch (err) {}
    };
    const onCopy = async () => {
        console.log(checkBannerIdList);
        if (checkBannerIdList.length !== 1) {
            alert('복사를 원하는 이벤트 1개 선택해 주세요');
        } else {
            setShowCopyModal(true);
        }
    };

    if (loading) return <LoadingPage text={'이벤트 베너 정보를 불러오고 있습니다.'} />;

    return (
        <TableWrapper className="table-wrapper" filterToggle={true} text={false}>
            <BannerInfoModal
                data={selectedItem}
                visible={showInfo}
                setVisible={setShowInfo}
                onSave={() => {
                    getBannerList();
                }}
            />
            <BannerLocationModal visible={showCopyModal} setVisible={setShowCopyModal} bannerId={checkBannerIdList[0]} />
            <BannerTab exposureLocation={exposureLocation} setExposureLocation={setExposureLocation}></BannerTab>
            <BtnWrapper id="btn-wrapper">
                <Button className="create" onClick={onCopy}>
                    복사
                </Button>
                <Button className="create" onClick={onClickCreate}>
                    등록
                </Button>
                <Button className="delete" onClick={onClickDeleteLists}>
                    삭제
                </Button>
            </BtnWrapper>

            <Wrapper style={{ overflowX: 'auto' }}>
                <table ref={tableRef} style={{ width: detailToggle[0] || createToggle ? `calc(100% - 430px)` : '100%', marginBottom: '10%', tableLayout: 'fixed' }}>
                    <label id="label">테이블 제목</label>
                    <DragCSSTitle>
                        <div className="list-item">
                            <th className="item">
                                <Checkbox
                                    type="checkbox"
                                    onChange={onClickAllCheck}
                                    checked={checkBannerIdList?.length === bannerList?.length} // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                                />
                            </th>

                            <th className="item">상태</th>
                            <th className="item" style={{ width: '60px' }}>
                                노출 앱
                            </th>
                            <th className="item" style={{ width: '200px' }}>
                                이미지
                            </th>

                            <th className="item" style={{ width: '200px' }}>
                                베너제목
                            </th>
                            <th className="item">노출위치</th>
                            <th className="item">우선 순위</th>
                            <th className="item">총 클릭수</th>

                            <th className="item" style={{ width: '250px' }}>
                                베너 노출 기간
                            </th>
                        </div>
                    </DragCSSTitle>

                    <label id="label">이벤트 데이터 리스트</label>
                    {bannerList?.map((item, i) => (
                        <DragCSS>
                            <div
                                className="list-item"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '5px',
                                    justifyContent: 'space-evenly',
                                }}
                                onClick={onClickDetailBanner(item)}
                            >
                                <td className="item">
                                    <Checkbox
                                        type="checkbox"
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={onClickSingleCheck(item)}
                                        checked={checkBannerIdList.includes(item.event_id)} // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                    />
                                </td>

                                <td className="item" style={{ alignItems: 'center' }}>
                                    {exposureFilter(item.exposure_yn)}
                                </td>

                                <td className="item" style={{ width: '60px' }}>
                                    {appSectionFilter(item.platform)}
                                </td>

                                <td className="item" style={{ width: '200px', padding: 0, alignSelf: 'center' }}>
                                    <Image src={main_server_image_ip + item.image_url} alt="베너 이미지" onError={handleImgError} sizes={190} height={70} />
                                </td>

                                <td className="item" style={{ width: '200px' }}>
                                    {item.title}
                                </td>

                                <td className="item">{exposureLocationFilter(item.exposure_location)}</td>

                                <td className="item">{item.order}</td>

                                <td className="item">{`${item.click_cnt ? parseInt(item.click_cnt).toLocaleString('ko-KR') : 0}회`}</td>

                                <td className="item" style={{ width: '250px' }}>
                                    {item.period}
                                </td>
                            </div>
                        </DragCSS>
                    ))}
                </table>

                <div style={{}}>
                    {detailToggle[0] && <DetailBanner setToggle={setDetailToggle} tableRef={tableRef} detailToggle={detailToggle} setReloadToggle={setReloadToggle} />}
                    {createToggle && <CreateBanner data={{}} setToggle={setCreateToggle} tableRef={tableRef} setReloadToggle={setReloadToggle} />}
                </div>
            </Wrapper>
        </TableWrapper>
    );
};

export default Banner;
