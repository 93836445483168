import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// designComponents
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { NoticeColor } from '../../designComponents/tables/NoticeColor';

// components
import UserModal from '../../components/UserComponents/UserModal';
import CreatedPayInfoModal from '../../components/Common/Payments/CreatedPayInfoModal';
import { AllPaymentsFilter } from '../../components/SyrupComponents/SyrupPaymentsFilter';

// regacy-context
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import { moneyFilter, payContentsFilter, payStatusFilter, payTypeFilter } from '../../utils/textFilter';
import { adminUsers } from '../../dataSystems/AdminUserLists';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import TableFilter from '../../designComponents/filters/TableFilter';
import PayInfoModal from './PayInfoModal';
import { applyRefund } from '../../utils/refundFunction';

/** 전체결제 */
const AllPayment = () => {
    const { user_info_modal_state } = useContext(UserInfoModalContext); // regacy-context api

    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [param, setParam] = useState({
        orderByType: 1,
        orderType: 1,
    });

    const [originData, setOriginData] = useState({});
    const [createPaymentsModal, setCreatePaymentsModal] = useState(false);
    const [payInfoDetailToggle, setPayInfoDetailToggle] = useState({ toggle: false });
    const [moneyFilterToggle, setMoneyFilterToggle] = useState(false);

    const ref = useRef(null);
    const tableRef = useRef(null);
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (!modalRef.current) return;
        else if (modalRef && !modalRef.current.contains(event.target)) setMoneyFilterToggle(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    const callApi = async (page, param) => {
        let input = { page: page, size: 10, searchType: searchType, searchWord: searchText, ...param };
        const response = await RefreshCheckApi.post('/adminSubscribe/list', input);
        return response.data.data;
    };

    const pageCountCallApi = async (page, param) => {
        // let input = { page: page, size: 10, searchType: searchType, searchWord: searchText, ...param };
        // const response = await RefreshCheckApi.post('/adminSubscribe/list', input);

        return 10000;
    };

    const filterInput = (e, key) => {
        const value = e.target.value;
        let obj = { ...param };
        obj[key] = value;
        setParam(obj);
    };

    return (
        <Wrapper02 id="administrator">
            {user_info_modal_state.modalVisible && <UserModal />}
            {createPaymentsModal && <CreatedPayInfoModal setToggle={setCreatePaymentsModal} />}
            {payInfoDetailToggle.toggle && (
                <DefaultModal
                    title={`${payInfoDetailToggle.data.name} 회원님의 결제 상세 정보`}
                    boxStyle={{ width: '100vh', height: '70vh' }}
                    xIcon
                    onClickClose={() => setPayInfoDetailToggle({ toggle: false })}
                    closeOn
                    onClickBottomClose={() => setPayInfoDetailToggle({ toggle: false })}
                    saveOn={payInfoDetailToggle.pay_status >= 2 && payInfoDetailToggle.pay_status !== 4}
                    saveText={payInfoDetailToggle.pay_status === 2 ? '환불신청' : '환불처리'}
                    onClickSave={() => {
                        applyRefund(payInfoDetailToggle.data, ref);
                    }}
                >
                    <PayInfoModal
                        data={payInfoDetailToggle.data}
                        originData={originData}
                        onChange={(data, key) => {
                            const obj = { ...payInfoDetailToggle };
                            obj.data[key] = data;
                            setPayInfoDetailToggle(obj);
                        }}
                    />
                </DefaultModal>
            )}

            <DefaultModal
                modalRef={modalRef}
                visible={moneyFilterToggle}
                boxStyle={{ width: '40vh', height: '27vh' }}
                xIcon={true}
                bottomBtnOn={true}
                closeOn={true}
                saveOn={true}
                title={'금액 필터 설정'}
                cancelText="닫기"
                saveText="초기화"
                onClickClose={() => setMoneyFilterToggle(false)}
                onClickBottomClose={() => setMoneyFilterToggle(false)}
                onClickSave={() => {
                    let obj = { ...param };
                    delete obj.point_start;
                    delete obj.point_end;
                    delete obj.amount_start;
                    delete obj.amount_end;
                    delete obj.accumulate_amount_start;
                    delete obj.accumulate_amount_end;
                    setParam(obj);
                }}
            >
                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '10px', padding: '20px' }}>
                    <MoneyFilterInput
                        title="포인트"
                        startValue={param.point_start}
                        onChangeStart={(e) => {
                            filterInput(e, 'point_start');
                        }}
                        endValue={param.point_end}
                        onChangeEnd={(e) => {
                            filterInput(e, 'point_end');
                        }}
                    />
                    <MoneyFilterInput
                        title={'실 결제금액'}
                        startValue={param.amount_start}
                        onChangeStart={(e) => {
                            filterInput(e, 'amount_start');
                        }}
                        endValue={param.amount_end}
                        onChangeEnd={(e) => {
                            filterInput(e, 'amount_end');
                        }}
                    />
                    <MoneyFilterInput
                        title={'누적 결제금액'}
                        startValue={param.accumulate_amount_start}
                        onChangeStart={(e) => {
                            filterInput(e, 'accumulate_amount_start');
                        }}
                        endValue={param.accumulate_amount_end}
                        onChangeEnd={(e) => {
                            filterInput(e, 'accumulate_amount_end');
                        }}
                    />
                </FlexMainWrapper>
            </DefaultModal>

            <MainPageTitle text={'전체결제'} onClick={() => ref?.current?.refresh()} />
            <SearchbarWithDropdown
                list={[
                    // { id: 1, title: '전체검색', value: 1 },
                    { id: 2, title: '휴대폰번호', value: 2 },
                    { id: 3, title: '이름', value: 3 },
                    { id: 4, title: '닉네임', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />
            <div style={{ overflow: 'auto' }}>
                <TableFilter
                    param={param}
                    setParam={setParam}
                    wrapperStyle={{ width: '95%' }}
                    topFilterLists={[
                        {
                            filterText: '정렬 기준',
                            orderLists: [{ name: '결제 일시', width: 50, type: 1 }],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked)
                                    obj.orderByType = type; // setOrderbyType(type);
                                else delete obj.orderByType;
                                setParam(obj);
                            },
                            orderbyType: param.orderByType,
                        },
                        {
                            filterText: '정렬 방식',
                            orderLists: [
                                { name: '내림차순', width: 50, type: 1 },
                                { name: '오름차순', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked)
                                    obj.orderType = type; // setOrderbyType(type);
                                else delete obj.orderType;
                                setParam(obj);
                            },
                            orderbyType: param.orderType,
                        },
                    ]}
                    filterLists={[
                        {
                            key: 'pay_contents',
                            value: '결제 상품',
                            filter: [
                                {
                                    key: 7,
                                    value: '하트',
                                },
                                {
                                    key: 6,
                                    value: '모임 일정',
                                },
                                {
                                    key: 5,
                                    value: '상품',
                                },
                                {
                                    key: 9,
                                    value: '연회비',
                                },
                                {
                                    key: 12,
                                    value: '79전화',
                                },
                            ],
                        },
                        {
                            key: 'pay_status',
                            value: '결제 상태',
                            filter: [
                                {
                                    key: 0,
                                    value: '결제 대기 중',
                                },
                                {
                                    key: 1,
                                    value: '입금 대기 중',
                                },
                                {
                                    key: 2,
                                    value: '결제 완료',
                                },
                                {
                                    key: 3,
                                    value: '환불 신청',
                                },
                                {
                                    key: 4,
                                    value: '환불 완료',
                                },
                                {
                                    key: 5,
                                    value: '환불 거절',
                                },
                                {
                                    key: 6,
                                    value: '기간 만료',
                                },
                                {
                                    key: 7,
                                    value: '결제 취소',
                                },
                            ],
                        },
                        {
                            key: 'pay_type',
                            value: '결제 방식',
                            filter: [
                                {
                                    key: -1,
                                    value: '0원 결제 ',
                                },
                                {
                                    key: 1,
                                    value: '무통장 입금',
                                },
                                {
                                    key: 0,
                                    value: '국내 카드 결제',
                                },
                                {
                                    key: 4,
                                    value: '계좌 이체',
                                },
                                {
                                    key: 5,
                                    value: '현장 결제',
                                },
                            ],
                        },
                    ]}
                    research={ref}
                />
            </div>
            <MainTable
                toggleBlur={true}
                buttonComponents={() => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <NoticeColor />
                        <FlexMainWrapper>
                            <MainButton styleReverse text="금액 필터" width={80} onClick={() => setMoneyFilterToggle(true)} />
                            <TopSideButton middleOn={false} rightOn={false} leftWidth={90} text01={'결제내역생성'} onClick={() => setCreatePaymentsModal((prev) => !prev)} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                )}
                tableStyle={{ width: '95%' }}
                ref={ref}
                tableRef={tableRef}
                loadingText={'전체 결제내역을 불러오고 있습니다.'}
                headerData={AllPaymentsFilter}
                checkID={'pay_id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    const res = RefreshCheckApi.get(`/adminSyrup/payments/detail?pay_id=${item.pay_id}`);
                    res.then((response) => {
                        setOriginData(response.data.data[0]);
                        setPayInfoDetailToggle({
                            toggle: true,
                            pay_id: item.pay_id,
                            pay_status: item.pay_status_number,
                            data: { ...response.data.data[0], bank_code: '039' },
                        });
                    });
                }}
                adminCheck={(item) => adminUsers.includes(item.phone_no) && { color: 'rgb(255, 16, 125)' }}
                filterItem={(value) => {
                    return value;
                }}
                tbodyRenderItem={(key, value) => {
                    const pointFilterColor = { color: param.point_start && param.point_end && '#428bf7', fontWeight: param.point_start && param.point_end && 'bold' };
                    const amountFilterColor = { color: param.amount_start && param.amount_end && '#428bf7', fontWeight: param.amount_start && param.amount_end && 'bold' };
                    const accumulateAmountFilterColor = {
                        color: param.accumulate_amount_start && param.accumulate_amount_end && '#428bf7',
                        fontWeight: param.accumulate_amount_start && param.accumulate_amount_end && 'bold',
                    };
                    if (key === 'point') return <span style={pointFilterColor}>{moneyFilter(value, 'P')}</span>;
                    if (key === 'amount') return <span style={amountFilterColor}>{moneyFilter(value, '원')}</span>;
                    if (key === 'accumulate_amount') return <span style={accumulateAmountFilterColor}>{moneyFilter(value, '원')}</span>;
                    if (key === 'pay_status') return <span>{payStatusFilter(value)}</span>;
                    if (key === 'contents') return payContentsFilter(value);
                    if (key === 'pay_type') return payTypeFilter(value);
                    return value;
                }}
            />
        </Wrapper02>
    );
};

export default React.memo(AllPayment);

const MoneyFilterInput = ({
    placeholderStart = '시작 금액',
    placeholderEnd = '제한 금액',
    type = 'number',
    title = '금액',
    startValue,
    endValue,
    onChangeStart = () => {},
    onChangeEnd = () => {},
}) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'center', gap: '2px' }}>
            <InputLine
                placeholder={placeholderStart}
                type={type}
                title={title}
                inputStyle={{ width: '90px' }}
                titleStyle={{ width: '75px', textAlign: 'start' }}
                wrapperStyle={{ margin: 0 }}
                value={startValue || ''}
                onChange={onChangeStart}
            />
            <span>~</span>
            <InputLine placeholder={placeholderEnd} type="number" title={false} inputStyle={{ width: '90px' }} value={endValue || ''} wrapperStyle={{ margin: 0 }} onChange={onChangeEnd} />
        </FlexMainWrapper>
    );
};
