import React, { useState } from 'react';

import PointPage from '../../components/PointComponents/Point/PointPage';
import Coupon from '../coupon/Coupon';

import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

/** 포인트 페이지 */
const Point = () => {
    const [pointPageToggle, setPointPageToggle] = useState(true);
    const [couponPageToggle, setCouponPageToggle] = useState(false);

    /** 일정 페이지 전환 */
    const onClickShcedule = () => {
        setPointPageToggle(true);
        setCouponPageToggle(false);
    };

    /** 결제회원 페이지 전환 */
    const onClickPayUsers = () => {
        setCouponPageToggle(true);
        setPointPageToggle(false);
    };

    const rederItem = [
        { text: '포인트', toggle: pointPageToggle, onPress: onClickShcedule },
        { text: '포인트 쿠폰', toggle: couponPageToggle, onPress: onClickPayUsers },
    ];

    /****************************************** html ******************************************/
    return (
        <Wrapper02>
            <MainPageTitle sigle={false} rederItem={rederItem} />
            {pointPageToggle && <PointPage />}
            {couponPageToggle && <Coupon />}
        </Wrapper02>
    );
};

export default React.memo(Point);
