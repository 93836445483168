import React, { useEffect, useState } from 'react';

// styled-components
import { TableTitle, ExcelWrapper, Table, ExplainBox, Box } from '../styles/UserActivityTableStyled';

// excel
import { arrayToExcel } from '../../data_system/xlsx_util/xlsx_util';

// components
import ExcelBtn from './Excel';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';

// components
import LoadingPage from '../../pages/loading/LoadingPage';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import PlatformTab from '../../GroupComponent/Tab/PlatformTab';
import SinorUserActivityTable from '../../EventComponents/Statistics/SinorUserActivityTable';
import SinorLoveUserActivityTable from '../../EventComponents/Statistics/SinorLoveUserActivityTable';
import AllUserActivityTable from '../../EventComponents/Statistics/AllUserActivityTable';

// 회원활동 테이블 Component
function UserActivityTable() {
    // useSelector
    // state
    const [page, setPage] = useState(1);
    const [platform, setPlatform] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activityData, setActivityData] = useState([]);
    const [activityAllCnt, setActivityAllCnt] = useState(10);

    const [params, setParams] = useState({ page: 1, size: 10, platform });

    /*************** useEffect ***************/

    // 회원활동 통계 데이터 요청
    useEffect(() => {
        requestUserActivityData().then();
        // requestPrintExcel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, platform]);

    /*************** function ***************/

    // 요청함수
    const requestUserActivityData = async () => {
        try {
            setLoading(true);
            let param = {
                page: page,
                size: 10,
                platform: platform,
            };

            setParams(param);
            const adminActivity = await RefreshCheckApi.post(`/adminStatus/activate`, param);

            setActivityData(adminActivity.data.data);
            setActivityAllCnt(adminActivity.data.all_cnt || 0);
            setLoading(false);
            // dispatch(getUserActivityData(param));
        } catch (err) {}
    };

    const changePlatform = (platform) => (e) => {
        setPlatform(platform);
    };

    // 회원 활동 excel 데이터 요청
    // const requestPrintExcel = () => {
    //     dispatch(getUserActivityDataExcel());
    // };

    // Excel 출력 함수
    const onPressPrintExcel = async () => {
        // 헤터 객체
        const excelHeader = [
            {
                key: 'date_info',
                value: '일자',
            },
            {
                key: 'new_user_male',
                value: '신규 회원 남자',
            },
            {
                key: 'user_male',
                value: '현재 회원 남자',
            },
            {
                key: 'user_male_accumulate',
                value: '누적 회원 남자',
            },
            {
                key: 'new_user_female',
                value: '신규 회원 여자',
            },
            {
                key: 'user_female',
                value: '현재 회원 여자',
            },
            {
                key: 'user_female_accumulate',
                value: '누적 회원 여자',
            },
            {
                key: 'new_user_null',
                value: '신규 회원 성별미정',
            },
            {
                key: 'user_null',
                value: '현재 회원 성별미정',
            },
            {
                key: 'user_null_accumulate',
                value: '누적 회원 성별 미정',
            },
            {
                key: 'new_user',
                value: '신규 회원 전체',
            },
            {
                key: 'user_current',
                value: '현재 회원 전체',
            },
            {
                key: 'user_current_accumulate',
                value: '누적 회원 전체',
            },
            {
                key: 'com_0_10',
                value: '현재 회원 활성도 0_10',
            },
            {
                key: 'com_0_10_accumulate',
                value: '누적 회원 활성도 0_10',
            },
            {
                key: 'com_10_30',
                value: '현재 회원 활성도 10_30',
            },
            {
                key: 'com_10_30_accumulate',
                value: '누적 회원 활성도 10_30',
            },
            {
                key: 'com_30_50',
                value: '현재 회원 활성도 30_50',
            },
            {
                key: 'com_30_50_accumulate',
                value: '누적 회원 활성도 30_50',
            },
            {
                key: 'com_50_100',
                value: '현재 회원 활성도 50_100',
            },
            {
                key: 'com_50_100_accumulate',
                value: '누적 회원 활성도 50_100',
            },
            {
                key: 'letter',
                value: '편지 발송 수 ',
            },
            {
                key: 'letter_accumulate',
                value: '편지 누적 발송 수',
            },
            {
                key: 'chatting',
                value: '채팅수',
            },
            {
                key: 'chatting_accumulate',
                value: '채팅 누적 수',
            },
        ];

        arrayToExcel(excelHeader, activityData, '신규회원활성도.xlsx');
    };

    /*************** html ***************/

    let table;

    if (loading) {
        table = (
            <Table>
                <tbody id="tbody">
                    <tr>
                        <td colSpan={11}>
                            <LoadingPage text={'회원활동 데이터 정보를 불러오는 중입니다.'} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    } else if (Number(platform) === 0) {
        table = <AllUserActivityTable data={activityData}></AllUserActivityTable>;
    } else if (Number(platform) === 1) {
        table = <SinorLoveUserActivityTable data={activityData}></SinorLoveUserActivityTable>;
    } else if (Number(platform) === 2) {
        table = <SinorUserActivityTable data={activityData}></SinorUserActivityTable>;
    }

    return (
        <>
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', gap: '15px', margin: '0 0 3vh 0' }}>
                <PlatformTab setPlatform={setPlatform}></PlatformTab>
            </FlexMainWrapper>

            <ExcelWrapper>
                <ExcelBtn onPress={onPressPrintExcel} />
            </ExcelWrapper>

            {table}
            <ExplainBox>
                <Box>
                    <div>
                        <div className="wrapper01">
                            <div>
                                <h3>신규가입/현재 회원수/누적회원수</h3>
                            </div>

                            <div>
                                <h4>(일자 기준 현황)</h4>
                            </div>
                        </div>
                    </div>

                    <div className="wrapper02">
                        <div>
                            <div className="point-wrapper02">
                                <div>
                                    <div className="point-wrapper">
                                        <p>신규가입:</p>
                                        <span>일자기준 가입자 수</span>
                                    </div>
                                    <div className="point-wrapper">
                                        <p>현재 회원수:</p>
                                        <span>탈퇴자 미포함 누적회원</span>
                                    </div>
                                    <div className="point-wrapper">
                                        <p>누적 회원수:</p>
                                        <span>탈퇴자 포함 누적회원으로 표기</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>

                <Box>
                    <div>
                        <div className="wrapper01">
                            <h3>신규활성도 점수 기준</h3>
                            <h4>[일자기준 / 누적 회원 수 활성도]</h4>
                        </div>
                    </div>

                    <div className="wrapper02">
                        <div>
                            <div className="point-wrapper">
                                <p>휴대폰인증까지</p>
                                <span>[0~9]</span>
                            </div>
                            <div className="point-wrapper">
                                <p>기본정보까지</p>
                                <span>[10~20]</span>
                            </div>
                            <div className="point-wrapper">
                                <p>사진등록까지</p>
                                <span>[21~40]</span>
                            </div>
                            <div className="point-wrapper">
                                <p>추가정보까지</p>
                                <span>[41~100]</span>
                            </div>
                        </div>
                    </div>
                </Box>
            </ExplainBox>

            <Pagination totalPages={activityAllCnt ? Math.round(activityAllCnt / 10) : 10} limit={10} page={page} setPage={setPage} />
        </>
    );
}

export default UserActivityTable;
