import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { getUsersSearchForCoupon } from '../../../../../../communication_system/axios_apis/axios_apis';
import { only_number } from '../../../../../../data_system/validation_check/validation_check';
import { rgb_205_205_205, rgb_255_16_125, rgb_255_255_255 } from '../../../../../colors';
import {
    h_px12rate,
    h_px25rate,
    h_px28rate,
    h_px30rate,
    h_px671d5rate,
    h_px6rate,
    w_px100rate,
    w_px120rate,
    w_px12rate,
    w_px210rate,
    w_px25rate,
    w_px26rate,
    w_px4rate,
    w_px5rate,
    w_px8rate,
} from '../../../../../size';
import CheckBox from '../../../../check_box/CheckBox';
import ContentText from '../../../../content_text/ContentText';
import DetailTextInput, { Input } from '../../../../detail_text_input/DetailTextInput';
import DivisionWhiteBlueLine from '../../../../division_white_blue_line/DivisionWhiteBlueLine';
import Dropdown from '../../../../../../components/Common/Dropdown';
import ListName from '../../../../list_name/ListName';
import LongDropdown from '../../../../long_dropdown/LongDropdown';
import PinkBtn from '../../../../pink_btn/PinkBtn';
import UserSelectOrSearch from '../../../../user_select_or_search/UserSelectOrSearch';
import WhiteBtn from '../../../../white_btn/WhiteBtn';
import RadioBtn from '../../../../radio_btn/RadioBtn';
import moment from 'moment';
import MatchDispatchCenter from '../../../../../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';

const CouponDetailRegister = ({ close, refresh, setRefresh }) => {
    const [couponName, setCouponName] = useState('');
    const onChangeCouponName = useCallback((e) => {
        setCouponName(e.target.value);
    }, []);
    const [benefit, setBenefit] = useState('');
    const [useCount, setUseCount] = useState('');
    const [additional, setAdditional] = useState(false);
    const [publishAmount, setPublishAmount] = useState('');
    const [startDate, setStartDate] = useState(moment().toISOString());
    const [endDate, setEndDate] = useState(moment().toISOString());
    const { onPostCouponsDetailAsync } = MatchDispatchCenter();
    const coupon_status_dropdown_list = [
        {
            id: 0,
            title: '진행중',
            selected: false,
            key: 'open',
            value: 0,
        },
        {
            id: 1,
            title: '종료',
            selected: false,
            key: 'close',
            value: 1,
        },
    ];

    const [couponStatusDropdownItem, setCouponStatusDropdownItem] = useState(null);

    const couponStatusResetThenSet = useCallback((item) => {
        setCouponStatusDropdownItem(item);
    }, []);

    const coupon_target_dropdown_list = [
        {
            id: 0,
            title: '전체 회원',
            selected: false,
            key: 'total',
            value: 0,
        },
        {
            id: 1,
            title: '비구독 회원',
            selected: false,
            key: 'unsubscribe',
            value: 1,
        },
        {
            id: 2,
            title: '구독 회원',
            selected: false,
            key: 'subscribe',
            value: 2,
        },
        {
            id: 3,
            title: '놀이터 결제 회원',
            selected: false,
            key: 'playground_pay',
            value: 3,
        },
        {
            id: 4,
            title: '특정 회원 검색',
            selected: false,
            key: 'someone',
            value: 4,
        },
    ];

    const [couponTargetDropdownItem, setCouponTargetDropdownItem] = useState({
        id: 0,
        title: '전체 회원',
        selected: false,
        key: 'total',
        value: 0,
    });

    const couponTargetResetThenSet = useCallback((item) => {
        setCouponTargetDropdownItem(item);
    }, []);

    const [couponTargetText, setCouponTargetText] = useState('');

    const onChangeCouponTargetText = useCallback((e) => {
        setCouponTargetText(e.target.value);
    }, []);

    const [userList, setUserList] = useState([]);
    const [targetUserOb, setTargetUserOb] = useState(null);
    const onSearchUserList = useCallback(async () => {
        const response = await getUsersSearchForCoupon({
            keyword: couponTargetText,
        });

        setUserList(response.data.data);
    }, [couponTargetText]);

    const [couponCodeText, setCouponCodeText] = useState('');

    const onChangeCouponCodeText = useCallback((e) => {
        setCouponCodeText(e.target.value);
    }, []);

    const [totalCouponRelease, setTotalCouponRelease] = useState('');

    const onChangeTotalCouponRelease = useCallback((e) => {
        if (only_number.test(e.target.value)) {
            setPublishAmount(e.target.value);
        }
    }, []);

    const [infiniteCouponRelease, setInfiniteCouponRelease] = useState(false);

    const onClickInfiniteCouponRelease = useCallback(() => {
        setInfiniteCouponRelease((pre) => !pre);
    }, []);

    const coupon_alarm_dropdown_list = [
        {
            id: 0,
            title: '알림 안함',
            selected: false,
            key: 'alarm_no',
            value: 0,
        },
        {
            id: 1,
            title: '사용 시작일 알림',
            selected: false,
            key: 'alarm_yes',
            value: 1,
        },
    ];

    const [couponAlarmDropdownItem, setCouponAlarmDropdownItem] = useState(null);

    const couponAlarmResetThenSet = useCallback((item) => {
        setCouponAlarmDropdownItem(item);
    }, []);

    const coupon_discount_dropdown_list = [
        {
            id: 0,
            title: '비율 할인',
            selected: false,
            key: '0',
            value: 0,
        },
        {
            id: 1,
            title: '금액 할인',
            selected: false,
            key: '1',
            value: 1,
        },
    ];

    const [couponDiscountDropdownItem, setCouponDiscountDropdownItem] = useState({
        id: 0,
        title: '금액 할인',
        selected: false,
        key: '0',
        value: 0,
    });

    const couponDiscountResetThenSet = useCallback((item) => {
        setCouponDiscountDropdownItem(item);
    }, []);

    const [minPay, setMinPay] = useState('');

    const onChangeMinPay = useCallback((e) => {
        if (only_number.test(e.target.value)) {
            setMinPay(e.target.value);
        }
    }, []);

    const coupon_for_activity_dropdown_list = [
        {
            id: 0,
            title: '전체',
            selected: false,
            key: '0',
            value: 0,
        },
        {
            id: 1,
            title: '채팅 전체',
            selected: false,
            key: '1',
            value: 1,
        },
        {
            id: 2,
            title: '액티비티 전체',
            selected: false,
            key: '2',
            value: 2,
        },
        {
            id: 3,
            title: '쿠폰 적용 상품',
            selected: false,
            key: '3',
            value: 3,
        },
    ];

    const [couponForActivityDropdownItem, setCouponForActivityDropdownItem] = useState({
        id: 0,
        title: '전체',
        selected: false,
        key: '0',
        value: 0,
    });

    const couponForActivityResetThenSet = useCallback((item) => {
        setCouponForActivityDropdownItem(item);
    }, []);

    return (
        <div style={{ height: h_px671d5rate, overflow: 'auto' }}>
            <div
                style={{
                    paddingTop: h_px25rate,
                    paddingLeft: w_px25rate,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>쿠폰명</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px210rate}
                            height={h_px28rate}
                            placeholder={'쿠폰명 15자 이하로 입력 가능'}
                            value={couponName}
                            onChange={onChangeCouponName}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>상태</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <Dropdown
                            list={coupon_status_dropdown_list}
                            resetThenSet={couponStatusResetThenSet}
                            index={0}
                        ></Dropdown>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>발행대상</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <UserSelectOrSearch
                            list={coupon_target_dropdown_list}
                            resetThenSet={couponTargetResetThenSet}
                            index={0}
                            value={couponTargetText}
                            onChange={onChangeCouponTargetText}
                            couponTargetDropdownItem={couponTargetDropdownItem}
                            onSearch={onSearchUserList}
                            userList={userList}
                            targetUserOb={targetUserOb}
                            setTargetUserOb={setTargetUserOb}
                        ></UserSelectOrSearch>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>쿠폰 코드</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px210rate}
                            height={h_px28rate}
                            placeholder={'한글/영문 대소문자/숫자로 10자 이하로 구성'}
                            value={couponCodeText}
                            onChange={onChangeCouponCodeText}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <div
                        style={{
                            marginRight: w_px26rate,
                        }}
                    >
                        <ListName>중복 할인</ListName>
                    </div>

                    <div
                        style={{
                            marginBottom: h_px6rate,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <RadioBtn
                                    checked={additional}
                                    onClick={() => {
                                        setAdditional(true);
                                    }}
                                ></RadioBtn>
                                <div
                                    style={{
                                        marginLeft: w_px5rate,
                                    }}
                                >
                                    <ContentText>가능</ContentText>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginLeft: w_px5rate,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <RadioBtn checked={!additional} onClick={() => setAdditional(false)}></RadioBtn>
                                    <div
                                        style={{
                                            marginLeft: w_px5rate,
                                        }}
                                    >
                                        <ContentText>불가능</ContentText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>발행 수량</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <DetailTextInput
                            width={w_px100rate}
                            height={h_px28rate}
                            placeholder={'미입력시 1회'}
                            value={publishAmount}
                            onChange={onChangeTotalCouponRelease}
                            disabled={infiniteCouponRelease}
                        ></DetailTextInput>
                        <div
                            style={{
                                marginLeft: w_px8rate,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <CheckBox checked={infiniteCouponRelease} onClick={onClickInfiniteCouponRelease}></CheckBox>
                            <div
                                style={{
                                    marginLeft: w_px4rate,
                                }}
                            >
                                <ContentText>개수제한 없음</ContentText>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>사용 횟수</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <DetailTextInput
                            width={w_px100rate}
                            height={h_px28rate}
                            placeholder={'미입력시 1번'}
                            value={useCount}
                            onChange={(e) => setUseCount(e.target.value)}
                        ></DetailTextInput>
                        <div
                            style={{
                                marginLeft: w_px4rate,
                            }}
                        >
                            <ContentText>회</ContentText>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>발행 알림</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <LongDropdown
                            list={coupon_alarm_dropdown_list}
                            resetThenSet={couponAlarmResetThenSet}
                            index={
                                couponAlarmDropdownItem
                                    ? coupon_alarm_dropdown_list.findIndex(
                                          (e) => e.value === couponAlarmDropdownItem.value,
                                      )
                                    : 0
                            }
                        ></LongDropdown>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>시작일</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <Input
                            type={'datetime-local'}
                            value={startDate}
                            onChange={(e) => {
                                setStartDate(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>마감일</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <Input
                            type={'datetime-local'}
                            value={endDate}
                            onChange={(e) => {
                                setEndDate(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginBottom: h_px12rate,
                        alignItems: 'center',
                    }}
                >
                    <DivisionWhiteBlueLine></DivisionWhiteBlueLine>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>사용 혜택</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <Dropdown
                            list={coupon_discount_dropdown_list}
                            resetThenSet={couponDiscountResetThenSet}
                            index={couponDiscountDropdownItem ? couponDiscountDropdownItem.value : 0}
                        ></Dropdown>
                        <div
                            style={{
                                marginTop: h_px6rate,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <DetailTextInput
                                width={w_px100rate}
                                height={h_px28rate}
                                placeholder={'숫자 입력'}
                                value={benefit}
                                onChange={(e) => setBenefit(e.target.value)}
                            ></DetailTextInput>
                            <div
                                style={{
                                    marginLeft: w_px4rate,
                                }}
                            >
                                <ContentText>{couponDiscountDropdownItem.value === 1 ? '원' : '%'}</ContentText>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>최소 결제금액</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <DetailTextInput
                            width={w_px100rate}
                            height={h_px28rate}
                            placeholder={'미입력시 0원'}
                            value={minPay}
                            onChange={onChangeMinPay}
                        ></DetailTextInput>
                        <div
                            style={{
                                marginLeft: w_px4rate,
                            }}
                        >
                            <ContentText>원</ContentText>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>사용가능 주문</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <Dropdown
                            list={coupon_for_activity_dropdown_list}
                            resetThenSet={couponForActivityResetThenSet}
                            index={
                                couponForActivityDropdownItem
                                    ? coupon_for_activity_dropdown_list.findIndex(
                                          (e) => e.value === couponForActivityDropdownItem.value,
                                      )
                                    : 0
                            }
                        ></Dropdown>
                        {/*<div*/}
                        {/*    style={{*/}
                        {/*      marginTop: h_px6rate,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*<CouponForActivityBtn*/}
                        {/*    disabled={couponForActivityDropdownItem?.value !== 3}*/}
                        {/*>*/}
                        {/*  쿠폰 적용 상품 선택*/}
                        {/*</CouponForActivityBtn>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: h_px12rate,
                    }}
                >
                    <WhiteBtn>취소</WhiteBtn>
                    <div
                        style={{
                            marginLeft: w_px8rate,
                        }}
                    >
                        <PinkBtn
                            onClick={async () => {
                                const param = {
                                    name: couponName,
                                    status: couponStatusDropdownItem.value.toString(),
                                    target: couponTargetDropdownItem.value.toString(),
                                    target_remark: null,
                                    coupon_code: couponCodeText,
                                    benefit_type: couponDiscountDropdownItem.value.toString(),
                                    benefit: benefit,
                                    available_order_amount: minPay,
                                    publish_amount: infiniteCouponRelease
                                        ? -1
                                        : publishAmount.length > 0
                                        ? publishAmount
                                        : 1,
                                    apply_target: couponForActivityDropdownItem.value.toString(),
                                    apply_target_detail: null,
                                    alarm_type: couponAlarmDropdownItem.value.toString(),
                                    start_datetime: startDate,
                                    end_datetime: endDate,
                                    use_count: useCount.length > 0 ? useCount : 1,
                                    additional_use_yn: additional ? '1' : '0',
                                    explanation: null,
                                };
                                await onPostCouponsDetailAsync(param);
                                await close();
                                setRefresh(!refresh);
                            }}
                        >
                            저장
                        </PinkBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CouponDetailRegister);

const CouponForActivityBtn = styled.button`
    padding: 0px;
    margin: 0px;
    border-width: 0px;
    width: ${w_px120rate}px;
    height: ${h_px30rate}px;
    background-color: ${(props) => (props.disabled ? rgb_205_205_205 : rgb_255_16_125)};
    font-size: ${w_px12rate}px;
    font-family: noto_sans_kr_medium;
    color: ${rgb_255_255_255};
`;
