import React, { useEffect, useState } from 'react';

// custom axios & URL
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_image_ip } from '../../../communication_system/communication_ips';
import styled from 'styled-components';

/** 결제 생성 회원정보 모달 */
function UserInfoModal({ userId, setUserId, setAccessToken, setPayContents }) {
    const [userInfo, setUserInfo] = useState({});
    // 확인용
    useEffect(() => {
        const response = RefreshCheckApi.get(`/adminUser?searchType=4&searchWord=${userId}&page=1&size=10`);
        response.then((res) => {
            setUserInfo(res.data.data[0]);
        });
    }, [userId]);

    /** 이미지 필터 */
    const imageFilter = (src) => {
        if (src?.length) {
            return src.includes('https://k.kakaocdn.net') ? src[0] : main_server_image_ip + src[0];
        } else {
            return '/images/noneImage.png';
        }
    };

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /** 회원 다시 선택하기 */
    const onClickResetUserInfo = () => {
        setUserId(0);
        setAccessToken('');
        setPayContents(null);
    };

    const nullTag = <span style={{ color: 'gray' }}>정보없음</span>;
    return (
        <Wrapper>
            <ColWrapper>
                <Button onClick={onClickResetUserInfo}>회원 다시 선택</Button>
            </ColWrapper>

            <ColWrapper style={{ alignItems: 'start' }}>
                <Subtitle>회원사진</Subtitle>
                <SubInfo>
                    <Images sizes={100} src={imageFilter(userInfo?.profile_image_list)} alt="회원 이미지" onError={handleImgError} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper>
                <Subtitle>이름(ID)</Subtitle>
                <SubInfo>
                    <span>
                        {userInfo?.name || nullTag}({userInfo?.id})
                    </span>
                </SubInfo>
            </ColWrapper>

            <ColWrapper>
                <Subtitle>닉네임</Subtitle>
                <SubInfo>
                    <span>{userInfo?.nickname || nullTag}</span>
                </SubInfo>
            </ColWrapper>

            <ColWrapper>
                <Subtitle>성별</Subtitle>
                <SubInfo>
                    <span>{userInfo?.sex === 1 ? '남성' : userInfo?.sex === 2 ? '여성' : nullTag}</span>
                </SubInfo>
            </ColWrapper>

            <ColWrapper>
                <Subtitle>생년월일</Subtitle>
                <SubInfo>
                    <span>{userInfo?.birthday || nullTag}</span>
                </SubInfo>
            </ColWrapper>

            <ColWrapper>
                <Subtitle>전화번호</Subtitle>
                <SubInfo>
                    <span>{userInfo?.phone_no || nullTag}</span>
                </SubInfo>
            </ColWrapper>
        </Wrapper>
    );
}

export default UserInfoModal;

const Wrapper = styled.div`
    padding: 5%;

    width: 55%;
    height: 100%;
`;

const Images = styled.img`
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
    margin: 7px 0;
`;

const Subtitle = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: gray;

    width: 100%;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 100%;
`;

const Button = styled.button`
    cursor: pointer;

    border: 0;
    border-radius: 2px;

    background-color: #428bf7;
    color: #fff;

    &:hover {
        background-color: #1067e7;
    }
`;
