import produce from "immer";
import {handleActions} from "redux-actions";
import {
    GET_STATISTICS_ACCOUNT_LINE_FAILURE,
    GET_STATISTICS_ACCOUNT_LINE_SUCCESS,
    GET_STATISTICS_ACCOUNT_RATE_FAILURE,
    GET_STATISTICS_ACCOUNT_RATE_SUCCESS,
    GET_STATISTICS_ACCOUNT_TABLE_FAILURE,
    GET_STATISTICS_ACCOUNT_TABLE_SUCCESS,
    GET_STATISTICS_ACCUMULATE_DATE_FAILURE,
    GET_STATISTICS_ACCUMULATE_DATE_SUCCESS,
    GET_STATISTICS_ACCUMULATE_TODAY_FAILURE,
    GET_STATISTICS_ACCUMULATE_TODAY_SUCCESS, GET_STATISTICS_ADDITIONAL_SUCCESS,
    GET_STATISTICS_PAYMENT_TABLE_FAILURE,
    GET_STATISTICS_PAYMENT_TABLE_SUCCESS
} from "./statistics_type";

const initialState = {
    accumulateDate: [],
    accumulateToday:[],
    accountRate:{sex:[],complete:[]},
    accountLine:[],
    additionalRate:{
        single_reason: [],
        interest: [],
        address: []
    },
    accountTable:{header:[],body:[],all_count:0},
    paymentTable:{header:[],body:[],all_count:0}
};

const statisticsReducer = handleActions(
    {
        [GET_STATISTICS_ACCUMULATE_DATE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.accumulateDate = action.payload;
            }),
        [GET_STATISTICS_ACCUMULATE_DATE_FAILURE]: (state,action) =>
            produce(state,(draft)=>{
            }),
        [GET_STATISTICS_ACCUMULATE_TODAY_SUCCESS]: (state,action)=>
            produce(state, (draft) => {
                draft.accumulateToday = action.payload;
            }),
        [GET_STATISTICS_ACCUMULATE_TODAY_FAILURE]: (state,action) =>
            produce(state,(draft)=>{
            }),
        [GET_STATISTICS_ACCOUNT_RATE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.accountRate = action.payload;
            }),
        [GET_STATISTICS_ACCOUNT_RATE_FAILURE]: (state,action) =>
            produce(state,(draft)=>{
            }),

        [GET_STATISTICS_ACCOUNT_LINE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.accountLine = action.payload.reverse();
            }),
        [GET_STATISTICS_ACCOUNT_LINE_FAILURE]: (state,action) =>
            produce(state,(draft)=>{
            }),
        [GET_STATISTICS_ACCOUNT_TABLE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.accountTable = action.payload;
            }),
        [GET_STATISTICS_ACCOUNT_TABLE_FAILURE]: (state,action) =>
            produce(state,(draft)=>{
            }),
        [GET_STATISTICS_PAYMENT_TABLE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.paymentTable = action.payload;
            }),
        [GET_STATISTICS_PAYMENT_TABLE_FAILURE]: (state,action) =>
            produce(state,(draft)=>{
            }),
        [GET_STATISTICS_ADDITIONAL_SUCCESS]: (state,action)=>
            produce(state,(draft)=>{
                draft.additionalRate = action.payload;
            }),
    }
    ,
    initialState
);

export default statisticsReducer;
