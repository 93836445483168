import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// custom hooks
import useInput from '../../hook/useInput';

/** 상품정보 */
function ProductInfoComponent({ setParams, setPoint }) {
    const [productID, onChangeProductID] = useInput(0);
    const [productOptionID, onChangeProductOptionID] = useInput(0);
    const [productCount, onChangeProductCount] = useInput(0);
    const [deliveryMessage, onChangeDeliveryMessage] = useInput(0);
    const [phoneNumber, onChangePhoneNumber] = useInput(0);
    const [userName, onChangeUserName] = useInput(0);
    const [address, onChangeAddress] = useInput(0);
    const [addressDetail, onChangeAddressDetail] = useInput(0);

    const [amount, setAmount] = useState(0);

    // 입력값 확인
    useEffect(() => {
        let newParam = {
            product_id: parseInt(productID), // 상품ID 입력
            option_id: parseInt(productOptionID), // 상품옵션ID 입력
            count: parseInt(productCount), // 상품갯수 입력
            delivery_message: deliveryMessage, // 요청사항 입력
            phone_no: parseInt(phoneNumber), // 휴대폰번호 입력
            receiver: userName, // 수신자명 입력
            address: address, // 주소 입력
            address_detail: addressDetail, // 상세주소 입력
            pay_amount: amount,
            pay_contents: 5,
        };

        setParams(newParam);
    }, [
        productID,
        productOptionID,
        productCount,
        deliveryMessage,
        phoneNumber,
        userName,
        address,
        addressDetail,
        setParams,
        amount,
    ]);

    // 상춤가격 입력
    const onChangePayments = (e) => {
        setAmount(parseInt(e.target.value));
    };

    // 포인트 입력
    const onChangePoint = (e) => {
        setPoint(parseInt(e.target.value));
    };

    return (
        <div>
            <ColWrapper className="pay-contents">
                <Subtitle>결제가격</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="결제가격 입력" onChange={onChangePayments} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>포인트</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="포인트 입력" onChange={onChangePoint} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>상품ID</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="상품ID 입력" onChange={onChangeProductID} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>상품옵션ID</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="상품옵션ID 입력" onChange={onChangeProductOptionID} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>상품갯수</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="상품갯수 입력" onChange={onChangeProductCount} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="delivery_message">
                <Subtitle>요청사항</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="요청사항 입력" onChange={onChangeDeliveryMessage} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="user-phone-number">
                <Subtitle>휴대폰번호</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="휴대폰번호 입력" onChange={onChangePhoneNumber} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="user-name">
                <Subtitle>수신자명</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="휴대폰번호 입력" onChange={onChangeUserName} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>주소</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="휴대폰번호 입력" onChange={onChangeAddress} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>상세주소</Subtitle>
                <SubInfo>
                    <Input type="text" placeholder="휴대폰번호 입력" onChange={onChangeAddressDetail} />
                </SubInfo>
            </ColWrapper>
        </div>
    );
}

export default ProductInfoComponent;

/********************************* styled *********************************/

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 85px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 60px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;
