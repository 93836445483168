import { useEffect, useRef, useState } from 'react';
import LoadingPage from '../../pages/loading/LoadingPage';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { Image } from '../../designComponents/imageboxs/Image';
import { MText, SText } from '../../designComponents/text/ContentText';
import styled from 'styled-components';
import { dateFilter, userFilter } from '../../utils/textFilter';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import UserDetailModal from '../UserComponents/UserDetailModal';
import { Flex } from 'antd';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

const ChatDetail = ({ data, userInfo, usersProfile, leftContentYn = false, setChatParams }) => {
    const [userDetailToggle, setUserDetailToggle] = useState({});
    const [imageParam, setImageParam] = useState({ toggle: false });
    const [imageToggle, setImageToggle] = useState(false);

    const [chatData, setChatData] = useState(JSON.parse(JSON.stringify(data)));

    const imgRef = useRef(null);

    useEffect(() => {
        setChatData(data);
    }, [data]);

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageParam({ toggle: false });
            setImageToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const deleteChatContent = async (uuid, user_id) => {
        if (window.confirm('해당 내용을 삭제하시겠습니까?')) {
            const response = await RefreshCheckApi.patch(`/adminChatting/delete/chat`, { uuid: uuid, chat_id: data._id, user_id: user_id });
            if (response.status === 204) {
                alert('해당 내용이 삭제 되었습니다.');
                const findIndex = chatData?.contents.findIndex((ele) => ele.uuid === uuid);
                let copy = JSON.parse(JSON.stringify(chatData));
                copy.contents[findIndex]['content'] = '삭제된 메세지 입니다.';
                copy.contents[findIndex]['delete_yn'] = true;
                setChatData(copy);
            }
        }
    };

    if (!data) return <LoadingPage text={'채팅방 내용을 불러오는 중입니다.'} style={{ height: '100%' }} />;
    return (
        <FlexMainWrapper style={{ flexDirection: 'column', gap: '15px' }}>
            {userDetailToggle.toggle && <UserDetailModal sinorYn={chatData.info.type !== 1} userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />}
            {(imageParam.toggle || imageToggle) && (
                <ImageModal
                    xIcon={true}
                    onClickClose={() => {
                        setImageParam({ toggle: false });
                        setImageToggle(false);
                    }}
                >
                    <Image url={imageParam.url} width={500} height={false} cover={'contain'} imgRef={imgRef} />
                </ImageModal>
            )}
            {chatData?.contents.map((item, index) => {
                item.unReadUser = Number(chatData?.participants?.length || 0) - Number(item?.read_user?.length || 0);

                return Boolean(item.admin_yn || !item.user_id) ? (
                    <FlexMainWrapper
                        key={index}
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 'auto',
                            backgroundColor: '#0000001c',
                            borderRadius: '5px',
                            padding: '5px 15px',
                        }}
                    >
                        <SText text={item.content} style={{ width: 'auto' }} color={'#323232'} />
                    </FlexMainWrapper>
                ) : data.info.from === item.user_id || leftContentYn ? (
                    <RightUser
                        key={index}
                        item={item}
                        url={userFilter(item.user_id, userInfo, usersProfile)?.url}
                        nickName={userFilter(item.user_id, userInfo, usersProfile)?.nick_name}
                        userId={item.user_id}
                        onClick={(url) => {
                            setImageParam({ toggle: true, url: url });
                        }}
                        onClickUser={(userId) => {
                            setUserDetailToggle({ toggle: true, userId: userId });
                        }}
                        onClickDelete={async (uuid) => {
                            await deleteChatContent(uuid, item.user_id);
                        }}
                    />
                ) : (
                    <LeftUser
                        key={index}
                        item={item}
                        url={userFilter(item.user_id, userInfo, usersProfile)?.url}
                        nickName={userFilter(item.user_id, userInfo, usersProfile)?.nick_name}
                        userId={item.user_id}
                        onClick={(url) => {
                            setImageParam({ toggle: true, url: url });
                        }}
                        onClickUser={(userId) => {
                            setUserDetailToggle({ toggle: true, userId: userId });
                        }}
                        onClickDelete={async (uuid) => {
                            await deleteChatContent(uuid, item.user_id, item._id);
                        }}
                    />
                );
            })}
        </FlexMainWrapper>
    );
};

export default ChatDetail;

/** 왼쪽 정렬 */
const LeftUser = ({ url, nickName, item, userId, onClickUser = () => {}, onClick = () => {}, onClickDelete = () => {} }) => {
    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;

    return (
        <FlexMainWrapper style={{ alignItems: 'start', gap: '5px' }}>
            <Image url={url} width={40} height={40} cover={'cover'} style={{ borderRadius: '50%' }} onClick={() => (url ? onClick(url) : onClick)} />
            <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', gap: '5px' }}>
                <SText text={nickName || nullTag} style={{ cursor: 'pointer', width: 'auto' }} onClick={() => onClickUser(userId)} />
                <FlexMainWrapper style={{ alignItems: 'end' }}>
                    {item.chatting_type === 1 ? (
                        <Image
                            url={item.content}
                            width={200}
                            height={false}
                            cover={'contain'}
                            style={{ borderRadius: '5px', border: '1px solid #c7c7c7' }}
                            onClick={() => (item.content ? onClick(item.content) : onClick)}
                        />
                    ) : (
                        <ContentBox>{item.delete_yn ? <SText text={'삭제된 메세지 입니다.'} color={'#797979'} /> : <SText text={item.content} />}</ContentBox>
                    )}
                    <FlexMainWrapper style={{ flexDirection: 'column', gap: 0 }}>
                        <SText text={'삭제'} color={'#d74f4f'} style={{ fontWeight: 500, cursor: 'pointer' }} onClick={() => onClickDelete(item.uuid)} />
                        <SText text={dateFilter(item.created_at)} />
                        {Number(item.unReadUser) !== 0 ? <SText text={item.unReadUser}></SText> : <SText text={'모두 읽음'}></SText>}
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

/** 오른쪽 정렬 */
const RightUser = ({ url, nickName, item, userId, onClickUser = () => {}, onClick = () => {}, onClickDelete = () => {} }) => {
    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;

    return (
        <FlexMainWrapper style={{ justifyContent: 'end', alignItems: 'start', gap: '5px', width: '100%' }}>
            <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'end', gap: '5px' }}>
                <SText text={nickName || nullTag} style={{ cursor: 'pointer', width: 'auto' }} onClick={() => onClickUser(userId)} />
                <FlexMainWrapper style={{ alignItems: 'end' }}>
                    <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'end', justifyContent: 'start', gap: 0 }}>
                        <SText text={'삭제'} color={'red'} style={{ fontWeight: 500, cursor: 'pointer' }} onClick={() => onClickDelete(item.uuid)} />
                        <SText text={dateFilter(item.created_at)} />
                        {Number(item.unReadUser) !== 0 ? <SText text={item.unReadUser}></SText> : <SText text={'모두 읽음'}></SText>}
                    </FlexMainWrapper>
                    {item.chatting_type === 1 ? (
                        <Image
                            url={item.content}
                            width={200}
                            height={false}
                            cover={'contain'}
                            style={{ borderRadius: '5px', border: '1px solid #c7c7c7' }}
                            onClick={() => (item.content ? onClick(item.content) : onClick)}
                        />
                    ) : (
                        <ContentBox>{item.delete_yn ? <SText text={'삭제된 메세지 입니다.'} color={'#797979'} /> : <SText text={item.content} />}</ContentBox>
                    )}
                </FlexMainWrapper>
            </FlexMainWrapper>
            <Image url={url} width={40} height={40} cover={'cover'} style={{ borderRadius: '50%' }} onClick={() => (url ? onClick(url) : onClick)} />
        </FlexMainWrapper>
    );
};

const ContentBox = styled.div`
    padding: 10px 10px;
    background-color: #fff;
    border-radius: 5px;
    width: auto;
    max-width: 25vh;
`;
