import React, { useEffect, useState } from 'react';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { Image } from '../../designComponents/imageboxs/Image';
import { errImage } from '../../utils/errImage';
import { availFilter, roleFilter } from '../../utils/adminFileter';
import { dateFilter } from '../../utils/textFilter';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { MText, SText } from '../../designComponents/text/ContentText';

/** 관리자 마이페이지 */
const MyPage = () => {
    const [myInfo, setMyInfo] = useState({});
    const adminCheckAPI = async () => {
        const reseponse = await RefreshCheckApi.get('/admin/check');
        const adminInfo = reseponse.data.info;
        setMyInfo(adminInfo);
    };

    useEffect(() => {
        adminCheckAPI();
    }, []);

    const titleStyle = { width: '80px', fontSize: '14px' };
    const alignItems = { alignItems: 'center' };
    const color797979 = '#797979';
    return (
        <div>
            <MainPageTitle text={<HelloUser name={myInfo.name} />} />
            <Wrapper02 style={{ padding: '5vh', position: 'relative' }}>
                <FlexMainWrapper style={{ alignItems: 'center', gap: '7vh' }}>
                    <Image errImg={errImage({ sex: 1 })} style={{ borderRadius: '50%' }} width={150} height={150} />
                    <FlexMainWrapper style={{ flexDirection: 'column', gap: '7px' }}>
                        <FlexMainWrapper style={alignItems}>
                            <MText text={'관리자 권한'} color={color797979} style={titleStyle} />
                            <SText text={roleFilter(myInfo.role)} />
                        </FlexMainWrapper>
                        {parseInt(myInfo.role) !== 0 && (
                            <>
                                <FlexMainWrapper style={alignItems}>
                                    <MText text={'소속'} color={color797979} style={titleStyle} />
                                    <SText text={myInfo.manager_group} />
                                </FlexMainWrapper>
                                <FlexMainWrapper style={alignItems}>
                                    <MText text={'직책'} color={color797979} style={titleStyle} />
                                    <SText text={myInfo.department} />
                                </FlexMainWrapper>
                            </>
                        )}
                        <FlexMainWrapper style={alignItems}>
                            <MText text={'계정'} color={color797979} style={titleStyle} />
                            <SText text={myInfo.admin_id} />
                        </FlexMainWrapper>
                        <FlexMainWrapper style={alignItems}>
                            <MText text={'전화번호'} color={color797979} style={titleStyle} />
                            <SText text={myInfo.phone_no} />
                        </FlexMainWrapper>
                        <FlexMainWrapper style={alignItems}>
                            <MText text={'가입일'} color={color797979} style={titleStyle} />
                            <SText text={dateFilter(myInfo.created_at)} />
                        </FlexMainWrapper>
                        <FlexMainWrapper style={alignItems}>
                            <MText text={'최근접속일'} color={color797979} style={titleStyle} />
                            <SText text={dateFilter(myInfo.recent_login_datetime)} />
                        </FlexMainWrapper>
                        <FlexMainWrapper style={alignItems}>
                            <MText text={'활성화여부'} color={color797979} style={titleStyle} />
                            <SText text={availFilter(myInfo.avail_yn)} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </Wrapper02>
        </div>
    );
};

export default MyPage;

const HelloUser = ({ name }) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'center' }}>
            <div>{name}</div>
            <div>님 안녕하세요.</div>
        </FlexMainWrapper>
    );
};
