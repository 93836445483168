/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';

// communication_system
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { marketting_main_server_ip } from '../../communication_system/communication_ips';

// utils
import { checkReadFilter, dateFilter } from '../../utils/textFilter';
// components
import GatheringSinorPickTable from '../../components/GatheringSinorPickComponents/GatheringSinorPickTable';

// designComponents
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import { LookCountSearchbar } from '../../designComponents/searchbars/LookCountSearchbar';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import MainTable from '../../designComponents/tables/MainTable';
import { Image } from '../../designComponents/imageboxs/Image';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { FilterToggleButton } from '../../designComponents/buttons/FilterToggleButton';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { Card } from '../../designComponents/card/Card';
import ContentDetailModal from '../../designComponents/modals/ContentDetailModal';
import SearchModal from '../../components/CreatePayInfoComponents/SearchModal';

/** 시놀 PICK 게식글 페이지 */
const GatheringPickContents = () => {
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({});

    const [detailToggle, setDetailToggle] = useState([false, 0]);
    const [pickContentsToggle, setPickContentsToggle] = useState(false);

    const [pickData, setPickData] = useState([]);
    const [populerContents, setPopulerContents] = useState([]);
    const [nowPickLists, setNowPickLists] = useState([]);
    const [open, setOpen] = useState(false);
    const [contentsID, setContentsID] = useState(0);
    const [contents, setContents] = useState(0);
    const [rendering, setRendering] = useState(false);

    const ref = useRef();
    const tableRef = useRef();

    const callApi = useCallback(
        async (page, param) => {
            const input = {
                page: page,
                size: 1000,
                search_type: searchType,
                search_word: searchText,
                deleted: open ? 1 : 0,
                ...param,
            };
            const response = await RefreshCheckApi.get(`/adminGathering/pick/contents`, { params: input });
            return response.data.data;
        },
        [open, searchText, searchType],
    );

    const callApiMain = async () => {
        const requestAccessToken = await RefreshCheckApi.post('/auth/testLogin', { id: 1 });
        const accessToken = requestAccessToken.data.data.access_token;
        const response = await axios.get(`${marketting_main_server_ip}gatheringContents/popular`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        setPopulerContents(response.data.data);
    };

    useEffect(() => {
        callApiMain();
    }, []);

    const funcParam = {
        contentsID: contentsID,
        setContentsID: setContentsID,
        contents: contents,
        setContents: setContents,
        pickLength: pickData?.filter((v) => Boolean(!v.deleted_at)).length,
        searchData: callApi,
    };

    const onContents = async (lists, type) => {
        try {
            if (window.confirm('선택하신 게시글을 복원하시겠습니까?')) {
                const response = await RefreshCheckApi.patch(`/adminGathering/pick/delete/contents`, {
                    contents_id_lists: lists,
                    type: type,
                });
                alert(response.data.message);
                setRendering((prev) => !prev);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onClickMiddle = (data, checkItems) => () => {
        let dataLength = data?.filter((v) => Boolean(!v.deleted_at)).length;
        if (dataLength >= 8) {
            return alert('8개의 관리자 PICK이 이미 존재합니다.');
        } else if (checkItems.length + dataLength > 8 || checkItems.length > 8) {
            return alert('관리자 PICK은 8개까지 가능합니다.');
        }
        if (!checkItems.length) return alert('선택된 게시글이 존재하지 않습니다.');
        return onContents(checkItems, 1);
    };

    const deleteContents = async (lists, type) => {
        try {
            if (window.confirm('선택하신 게시글을 삭제하시겠습니까?')) {
                const response = await RefreshCheckApi.patch(`/adminGathering/pick/delete/contents`, {
                    contents_id_lists: lists,
                    type: type,
                });
                alert(response.data.message);
                setRendering((prev) => !prev);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setRendering((prev) => !prev);
    }, [pickContentsToggle]);

    return (
        <div>
            {detailToggle[0] && <ContentDetailModal id={detailToggle[1]} setToggle={setDetailToggle} />}
            {pickContentsToggle && <SearchModal type={'게시글'} setToggle={setPickContentsToggle} funcParam={funcParam} />}

            <Wrapper02 style={{ marginBottom: '2%' }}>
                <MainPageTitle wrapperStyle={{ cursor: 'pointer', userSelect: 'none' }} text={'시놀 PICK'} onClick={callApiMain} />
                <SearchbarWithDropdown
                    style={{ margin: '45px 0' }}
                    list={[
                        { id: 1, title: '게시글ID', value: 1 },
                        { id: 2, title: '제목', value: 2 },
                        { id: 3, title: '게시글 내용', value: 3 },
                    ]}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                    onChangeSubmit={(e) => {
                        e.preventDefault();
                        ref?.current?.refresh();
                    }}
                    searchText={searchText}
                    onChangeInput={(e) => {
                        setSearchText(e.target.value);
                        callApi();
                    }}
                />

                <FlexMainWrapper style={{ alignItems: 'center', gap: '10px' }}>
                    <MainPageTitle wrapperStyle={{ margin: '0 0 5px 0', userSelect: 'none' }} fontStyle={{ fontSize: '20px', fontWeight: '500' }} text={'현재 PICK'} onClick={callApiMain} />
                    <TopSideButton
                        leftOn={false}
                        middleOn={false}
                        text02={'PICK에서 제외'}
                        rightWidth={100}
                        onClickDelete={async () => {
                            if (window.confirm('선택하신 게시글을 인기게시글에서 제외 하시겠습니까?')) {
                                const response = await RefreshCheckApi.patch(`/adminGathering/pick/delete/contents`, {
                                    contents_id_lists: nowPickLists,
                                    type: 3,
                                });
                                alert(response.data.message);
                                callApiMain();
                                setRendering((prev) => !prev);
                            }
                        }}
                    />
                </FlexMainWrapper>

                <FlexMainWrapper style={{ overflowY: 'auto', alignItems: 'center', gap: '10px' }}>
                    {populerContents.map((item) => {
                        return (
                            <div key={item?.contents_id}>
                                <Card
                                    onClickDetail={(contents_id) => setDetailToggle([true, contents_id])}
                                    rendering={rendering}
                                    item={item}
                                    title={item.title}
                                    content={item.content}
                                    id={item.contents_id}
                                    url={item.thumbnail}
                                    itemLists={nowPickLists}
                                    setItemLists={setNowPickLists}
                                />
                            </div>
                        );
                    })}
                </FlexMainWrapper>

                <div id="explanation" style={{ marginBottom: '5%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', color: 'gray', fontSize: '13px' }}>
                        <h4 style={{ margin: '20px 0 0 0', color: 'black' }}>시놀 PICK 기준</h4>
                        <span>1. 인기게시글은 총 8개입니다.</span>
                        <span>2. 관리자 PICK을 최상단에 정렬하고 조회수가 높은 순, 댓글수 순으로 정렬</span>
                        <span>3. 마지막으로 상단에서 8개를 선택하여 랜덤하게 보여줍니다.</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', color: 'gray', fontSize: '13px' }}>
                        <h4 style={{ margin: '20px 0 0 0', color: 'black' }}>인기게시글 제외하기</h4>
                        <span>1. 현재 PICK에서 제외하고자 하는 게시글 선택</span>
                        <span>2. 상단 PICK에서 제외 클릭</span>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', color: 'gray', fontSize: '13px' }}>
                        <h4 style={{ margin: '20px 0 0 0', color: 'black' }}>관리자 권한 8개 선택 방법</h4>
                        <span>1. 등록 클릭</span>
                        <span>2. 게시글 검색</span>
                        <span>3. 상단에 노출하고자 하는 게시글 선택</span>
                    </div>
                </div>
            </Wrapper02>

            <Wrapper02>
                <MainPageTitle
                    text={'관리자 PICK'}
                    onClick={() => {
                        callApi();
                        setRendering((prev) => !prev);
                    }}
                />
                <MainTable
                    ref={ref}
                    rendering={rendering}
                    toggleBlur={true}
                    buttonComponents={({ checkItems, data }) => (
                        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <FilterToggleButton onChange={(checked) => setOpen(checked)} checked={open} text={'삭제포함 보기'} />
                            <TopSideButton
                                text01={'등록'}
                                onClick={() => {
                                    setPickData(data);
                                    setPickContentsToggle(true);
                                }}
                                text03={'복원'}
                                onClickMiddle={onClickMiddle(data, checkItems)}
                                text02={'삭제'}
                                onClickDelete={() => {
                                    deleteContents(checkItems, 0);
                                    setRendering((prev) => !prev);
                                }}
                            />
                        </FlexMainWrapper>
                    )}
                    tableStyle={{ width: '95%', marginBottom: '10%' }}
                    tableRef={tableRef}
                    loadingText={'관리자 PICK 내역을 불러오고 있습니다.'}
                    headerData={[
                        { key: 'id', value: '게시글 ID' },
                        { key: 'deleted_at', value: '삭제여부' },
                        { key: 'title', value: '모임명' },
                        { key: 'content', value: '게시글 제목' },
                        { key: 'thumbnail', value: '이미지' },
                        { key: 'content_created_at', value: '게시글 작성일' },
                    ]}
                    checkID={'id'}
                    param={param}
                    setParam={setParam}
                    callApi={callApi}
                    onClickRowItem={(item) => () => {
                        let _contents_id = parseInt(item.params);
                        setDetailToggle([true, _contents_id]);
                    }}
                    tbodyRenderItem={(key, value) => {
                        if (key === 'deleted_at') return checkReadFilter(value ? 0 : 1, 'ON', 'OFF');
                        if (key === 'thumbnail') return <Image url={value} />;
                        if (key === 'content_created_at') return dateFilter(value);
                        else return value;
                    }}
                    pagenaitionOn={false}
                />
            </Wrapper02>
        </div>
    );
};

export default GatheringPickContents;
