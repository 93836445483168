import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { TableTitle, ExcelWrapper } from '../styles/UserActivityTableStyled';
import { Table, DateHeaderTh, CustomTh } from '../styles/SalesStatisticsTableStyled';

// excel
import { onClickPaymentExcelFormat } from '../../utils/xlsxUtil';

// components
import ExcelBtn from './Excel';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getSalesStatisticsData, getSalesStatisticsDataExcel } from '../../data_system/redux/modules/statistics_reducer/statistics.reducer';
import LoadingPage from '../../pages/loading/LoadingPage';

function SalesStatisticsTable() {
    const dispatch = useDispatch();

    // useSelector
    const { salesStatisticsData, salesStatisticsExcelData } = useSelector((state) => state.newStatisticsReducer);

    // state
    const [page, setPage] = useState(1);
    const [params, setParams] = useState({ page: 1, size: 10 });

    /***************************** useEffect *****************************/

    // 매출 데이터 요청
    useEffect(() => {
        requestSalesStatisticsData(params);
        requestPrintExcel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    /***************************** function *****************************/

    // 매출 데이터 요청 함수
    const requestSalesStatisticsData = () => {
        let param = {
            page: page,
            size: 10,
        };

        setParams(param);
        dispatch(getSalesStatisticsData(param));
    };

    // 매출 excel 데이터 요청
    const requestPrintExcel = () => {
        dispatch(getSalesStatisticsDataExcel());
    };

    // 매출 excel 출력
    const onPressPrintExcel = () => {
        onClickPaymentExcelFormat(salesStatisticsExcelData.header, salesStatisticsExcelData.body, '매출.xlsx');
    };

    /****************************** filter ******************************/

    // 결제자 수
    const allPayCountFilter = (GPay, SPay) => {
        let _GPay = parseInt(GPay);
        let _SPay = parseInt(SPay);
        return (_GPay + _SPay).toLocaleString('ko-KR');
    };

    /******************************* html *******************************/
    const syrup_color = { backgroundColor: 'rgb(255 16 125 / 5%)' };
    const gathering_color = { backgroundColor: 'rgb(66 139 247 / 10%)' };
    const middle_styled = { backgroundColor: 'rgb(79 247 66 / 5%)', borderRight: '1px solid rgb(205,205,205)' };

    return (
        <div className="bottom-table" style={{ marginBottom: '50px' }}>
            <TableTitle>매출</TableTitle>

            <ExcelWrapper>
                <ExcelBtn onPress={onPressPrintExcel} />
            </ExcelWrapper>

            <Table>
                <thead id="thead">
                    <tr>
                        <DateHeaderTh rowSpan={2}>일시</DateHeaderTh>
                        <th rowSpan={2} style={middle_styled}>
                            총 결제 금액
                        </th>

                        <CustomTh colSpan={2} style={gathering_color}>
                            모임 결제
                        </CustomTh>
                        <CustomTh colSpan={1} style={gathering_color}>
                            결제전환율
                        </CustomTh>

                        <th rowSpan={2} style={middle_styled}>
                            DAU
                        </th>

                        <CustomTh colSpan={2} style={syrup_color}>
                            시럽 결제
                        </CustomTh>
                        <CustomTh colSpan={1} style={syrup_color}>
                            결제전환율
                        </CustomTh>
                    </tr>

                    <tr>
                        <th style={gathering_color}>결제자 수</th>
                        <th style={gathering_color}>결제 금액</th>
                        <th style={gathering_color}>전환률</th>

                        <th style={syrup_color}>결제자 수</th>
                        <th style={syrup_color}>결제 금액</th>
                        <th style={syrup_color}>전환률</th>
                    </tr>
                </thead>

                <tbody id="tbody">
                    {!salesStatisticsData?.data ? (
                        <tr>
                            <td colSpan={10}>
                                <LoadingPage text={'매출 데이터를 불러오는 중입니다.'} />
                            </td>
                        </tr>
                    ) : (
                        salesStatisticsData?.data?.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td className="date">{data.date}</td>

                                    {/* 총 결제 금액 */}
                                    <td style={middle_styled}>
                                        <span>{allPayCountFilter(data.gatering_pay_amount, data.syrup_pay_amount) || 0}원</span>
                                    </td>

                                    {/* 모임결제 */}
                                    <td className="gathering">{data.gatering_pay_count}명</td>
                                    <td className="gathering">{parseInt(data.gatering_pay_amount).toLocaleString('ko-KR')}원</td>

                                    {/* 모임결제 결제 > 결제전환율 */}
                                    <td className="gathering">
                                        <span>{data.gathering_pay_transform_percent}%</span>
                                    </td>

                                    {/* DAU */}
                                    <td style={middle_styled}>
                                        <span>{data.user_count || 0}명</span>
                                    </td>

                                    {/* 단짝찾기 결제 */}
                                    <td className="syrup">{data.syrup_pay_count}명</td>
                                    <td className="syrup">{parseInt(data.syrup_pay_amount).toLocaleString('ko-KR')}원</td>

                                    {/* 단짝찾기 결제 > 결제전환율 */}
                                    <td className="syrup">
                                        <span>{data.syrup_pay_transform_percent}%</span>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>

            <Explanation>
                <div style={{ marginLeft: '3px', display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <span style={{ fontWeight: '600' }}>DAU</span> : 해당 날짜에 접속한 회원의 수(중복 접속 제외)
                    </div>
                    <div>
                        <span style={{ fontWeight: '500' }}>전환률</span>: (결제자 수 / DAU) X 100
                    </div>
                </div>
                <div style={{ marginRight: '3px' }}>
                    <span style={{ color: 'red' }}>해당 지표는 포인트 결제는 제외한 실결제금액만 나타냈습니다.</span>
                </div>
            </Explanation>

            <Pagination totalPages={salesStatisticsData?.all_count ? Math.round(salesStatisticsData?.all_count / 10) : 10} limit={10} page={page} setPage={setPage} />
        </div>
    );
}

export default SalesStatisticsTable;

/******************************* styled *******************************/

const Explanation = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;

    width: 92%;
    margin: 10px auto 0 auto;

    color: gray;
    font-size: 13px;
`;

// date # 일자
// subscribe_cnt  # 결제자 수
// subscribe_amount # 결제 금액
// subscribe_rate # 결제자수 / 회원수 (남)
// activity_cnt # 결제자 수
// activity_amount  # 결제 금액
// activity_rate  # 결제자 수 / 회원 수
// activity # 놀이 수
// host # 호스트 수
