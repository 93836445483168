import React, { useState, useEffect, useRef, useCallback } from 'react';

// styled-components
import { LeftOutlined } from '@ant-design/icons';
import {
    MobileBox,
    ModalWrapper,
    Box,
    CreateTopWrapper,
    ButtonWeapper,
    InputWrapper,
    SubTitle,
    Input,
    Checkbox,
    SearchBoxWrapper,
    Button,
    Select,
    DateInputWrapper,
    DateWrapper,
} from '../../components/Common/Styles/CommonTableSideModalStyled';
import { Image, ImageComponent, ImageWrapper, ImageBox } from '../Common/Styles/CommonTableStyled';
import { ModalBody, CheckboxWrapper, CheckboxItemWrapper } from './EventLayoutStyled';

// URL & custom axios
import { main_server_image_ip } from '../../communication_system/communication_ips';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { updateEventBannerInfo, RESET_STATE } from '../../data_system/redux/modules/events_reducer/events.reducer';
import LoadingPage from '../../pages/loading/LoadingPage';

// custom hook
import useInput from '../../hook/useInput';

/** 이벤트 베너 상세정보 */
const DetailBanner = ({ setToggle, detailToggle, setReloadToggle }) => {
    const [eventDetailInfo, setEventDetailInfo] = useState({});
    const [updateToggle, setUpdateToggle] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [platform, setPlatform] = useState(0);

    const [exposureYn, setExposureYn] = useState(0); // exposure yes or no
    const [title, onChangeTitle, setTitle] = useInput(''); // banner title
    const [exposureLocation, setExposureLocation] = useState(0); // exposure location
    const [linkUrl, setLinkUrl] = useState(''); // page link in app
    const [imageUrl, setImageUrl] = useState(''); // image url
    const [period, setPeriod] = useState(''); // exposure span
    const [createdAt, setCreatedAt] = useState(''); // create date
    const [updatedAt, setUpdatedAt] = useState(''); // update date
    const [order, setOrder] = useState(null);

    const locationList = [
        {
            title: '메인 페이지',
            exposure_location: 1,
        },
        {
            title: '모임 페이지',
            exposure_location: 2,
        },
        {
            title: '채팅 페이지',
            exposure_location: 3,
        },
    ];

    const [loading, setLoading] = useState(true);

    // 데이터 요청
    useEffect(() => {
        requestEvent().then();
    }, [detailToggle, updateToggle]);

    // 상세 데이터 조회
    const requestEvent = async () => {
        try {
            setLoading(true);

            const res = await RefreshCheckApi.get(`/adminEvent`, {
                params: {
                    event_id: detailToggle[1],
                },
            });

            setEventDetailInfo(res.data.data);

            setLoading(false);
        } catch (err) {}
    };

    useEffect(() => {
        resetApplyDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventDetailInfo]);

    // 상태 초기화
    const resetApplyDate = () => {
        setExposureYn(eventDetailInfo?.exposure_yn);
        setTitle(eventDetailInfo?.title);
        setExposureLocation(eventDetailInfo?.exposure_location);
        setLinkUrl(eventDetailInfo?.link_url);
        setImageUrl(eventDetailInfo?.image_url);
        setPeriod(eventDetailInfo?.period);
        setCreatedAt(eventDetailInfo?.created_at);
        setUpdatedAt(eventDetailInfo?.updated_at);
        setStartDate(eventDetailInfo?.period?.split('~')[0]);
        setEndDate(eventDetailInfo?.period?.split('~')[1]);
        setPlatform(eventDetailInfo?.platform);
        setOrder(eventDetailInfo?.order);
    };

    // 노출위치 TEXT 필터
    const exposureLocationFilter = (num) => {
        let _num = parseInt(num);
        switch (true) {
            case _num === 1:
                return '메인 페이지';
            case _num === 2:
                return '모임 페이지';
            case _num === 3:
                return '채팅 페이지';
            default:
                return false;
        }
    };

    // 이미지 에러 처리
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    // 모달창 닫기
    const closeModal = () => {
        setToggle([false, 0]);
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback(async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });
        const response = await RefreshCheckApi.post('/adminEvent/transform', imageFormData);
        setTimeout(() => {
            setImageUrl(response.data.data);
        }, 1100);
    }, []);

    // 상태 업데이트
    const onClickUpdateToggle = (boolean) => () => {
        if (!boolean) {
            if (window.confirm('수정된 내용은 저장되지 않습니다. 취소하시겠습니까?')) {
                resetApplyDate();
                return setUpdateToggle((prev) => !prev);
            }
        } else {
            resetApplyDate();
            return setUpdateToggle((prev) => !prev);
        }
    };

    // 연결위치 변경
    const onChangeExposureLocation = (e) => {
        setExposureLocation(e.target.value);
    };

    // link update
    const onChatingLinkUrl = (e) => {
        setLinkUrl(e.target.value);
    };

    const onChangeOrder = (e) => {
        setOrder(e.target.value);
    };

    // 날짜변환
    const onChangeDate = (type) => (e) => {
        if (type === 1) {
            setStartDate(e.target.value);
        } else if (type === 2) {
            setEndDate(e.target.value);
        }
    };

    // 앱 위치 수정
    const onChangePlatformStatus = (num) => (e) => {
        setPlatform(num);
    };

    // 노출여부 수정
    const onChangeExposureYn = (num) => () => {
        setExposureYn(num);
    };

    // 수정여부 저장
    const onClickSave = async () => {
        try {
            let body = {
                platform: platform,
                exposure_yn: exposureYn,
                title: title,
                exposure_location: exposureLocation,
                link_url: linkUrl,
                image_url: imageUrl,
                order: order,
                exposure_start_datetime: startDate,
                exposure_end_datetime: endDate,
                createdAt: createdAt,
                updatedAt: updatedAt,
            };
            await RefreshCheckApi.put(`/adminEvent/${detailToggle[1]}`, body);
            setUpdateToggle(false);
            setReloadToggle((prev) => !prev);
        } catch (err) {}
    };

    let detailComponent;

    if (loading) {
        detailComponent = <LoadingPage text={loading ? '베너 상세정보를 불러오는 중입니다.' : '업데이트를 진행하고 있습니다.'} />;
    } else {
        detailComponent = (
            <ModalBody>
                <label id="label">앱 위치 수정</label>
                <InputWrapper id="" style={{ marginTop: '5%', marginBottom: '4%' }}>
                    <SubTitle style={{ width: '77px' }}>앱 위치 수정</SubTitle>
                    <CheckboxWrapper>
                        <CheckboxItemWrapper>
                            <Checkbox type="checkbox" checked={Number(platform) === 0} onChange={onChangePlatformStatus(0)} disabled={!updateToggle} />
                            <span>전체</span>
                        </CheckboxItemWrapper>

                        <CheckboxItemWrapper>
                            <Checkbox type="checkbox" checked={Number(platform) === 1} onChange={onChangePlatformStatus(1)} disabled={!updateToggle} />
                            <span>시럽</span>
                        </CheckboxItemWrapper>

                        <CheckboxItemWrapper>
                            <Checkbox type="checkbox" checked={Number(platform) === 2} onChange={onChangePlatformStatus(2)} disabled={!updateToggle} />
                            <span>시놀</span>
                        </CheckboxItemWrapper>
                    </CheckboxWrapper>
                </InputWrapper>

                <label id="label">노출 상태</label>
                <InputWrapper id="" style={{ marginTop: '5%', marginBottom: '4%' }}>
                    <SubTitle style={{ width: '77px' }}>노출 상태</SubTitle>
                    <CheckboxWrapper>
                        <CheckboxItemWrapper>
                            <Checkbox type="checkbox" checked={parseInt(exposureYn)} onChange={onChangeExposureYn(1)} disabled={!updateToggle} />
                            <span>ON</span>
                        </CheckboxItemWrapper>

                        <CheckboxItemWrapper>
                            <Checkbox type="checkbox" checked={!parseInt(exposureYn)} onChange={onChangeExposureYn(0)} disabled={!updateToggle} />
                            <span>OFF</span>
                        </CheckboxItemWrapper>
                    </CheckboxWrapper>
                </InputWrapper>

                <label id="label">베너 제목</label>
                <InputWrapper id="">
                    <SubTitle>베너 제목</SubTitle>
                    <Input type="text" value={title || '정보없음'} disabled={!updateToggle} onChange={onChangeTitle} />
                </InputWrapper>

                <label id="label">노출 위치</label>
                {updateToggle ? (
                    <InputWrapper id="exposure-location-select">
                        <SubTitle>노출 위치</SubTitle>
                        <Select value={exposureLocation} onChange={onChangeExposureLocation}>
                            {locationList?.map((filter, index) => {
                                return filter.exposure_location ? (
                                    <option key={index} value={filter.exposure_location}>
                                        {exposureLocationFilter(filter.exposure_location)}
                                    </option>
                                ) : null;
                            })}
                        </Select>
                    </InputWrapper>
                ) : (
                    <InputWrapper id="exposure-location">
                        <SubTitle>노출 위치</SubTitle>
                        <Input type="text" value={exposureLocationFilter(exposureLocation) || '정보없음'} disabled={!updateToggle} readOnly />
                    </InputWrapper>
                )}

                <label id="label">이미지</label>
                <InputWrapper id="banner-image">
                    <SubTitle>이미지</SubTitle>

                    <SearchBoxWrapper>
                        <Input type="text" value={imageUrl || '정보없음'} disabled={true} />
                        <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />

                        <Button onClick={onClickAddImage} disabled={!updateToggle} updateToggle={updateToggle}>
                            찾기
                        </Button>
                    </SearchBoxWrapper>
                </InputWrapper>

                <label id="label">이미지 보여주기</label>
                <ImageWrapper>
                    <ImageBox>
                        <Image src={main_server_image_ip + imageUrl} alt="banner image" onError={handleImgError} width={280} height={100} />
                    </ImageBox>
                </ImageWrapper>

                <label id="label">노출 기간</label>
                <InputWrapper id="span-date" style={{ alignItems: 'start' }}>
                    <SubTitle>노출 기간</SubTitle>
                    {updateToggle ? (
                        <DateInputWrapper>
                            <DateWrapper className="date-wrapper">
                                <div className="date-title" style={{ height: '24px', fontSize: '10px' }}>
                                    시작시간
                                </div>
                                <Input type="datetime-local" value={startDate} disabled={!updateToggle} onChange={onChangeDate(1)} style={{}} />
                            </DateWrapper>

                            <DateWrapper className="date-wrapper">
                                <div className="date-title" style={{ height: '24px', fontSize: '10px' }}>
                                    종료시간
                                </div>
                                <Input type="datetime-local" value={endDate} disabled={!updateToggle} onChange={onChangeDate(2)} />
                            </DateWrapper>
                        </DateInputWrapper>
                    ) : (
                        <Input type="text" value={period || '정보없음'} disabled={!updateToggle} />
                    )}
                </InputWrapper>

                <label id="label">연결 URL</label>
                <InputWrapper id="">
                    <SubTitle>연결 URL</SubTitle>
                    <Input type="text" value={linkUrl || '정보없음'} disabled={!updateToggle} onChange={onChatingLinkUrl} />
                </InputWrapper>

                <label id="label">순번</label>
                <InputWrapper id="">
                    <SubTitle>순번</SubTitle>
                    <Input type="text" value={order === undefined || order === null ? '순번 미지정' : order} disabled={!updateToggle} onChange={onChangeOrder} />
                </InputWrapper>

                <label id="label">등록일</label>
                <InputWrapper id="">
                    <SubTitle>등록일</SubTitle>
                    <Input type="text" value={createdAt || '정보없음'} disabled={true} />
                </InputWrapper>

                <label id="label">수정일</label>
                <InputWrapper id="">
                    <SubTitle>수정일</SubTitle>
                    <Input type="text" value={updatedAt || '정보없음'} disabled={true} />
                </InputWrapper>
            </ModalBody>
        );
    }

    return (
        <MobileBox className="mobile">
            <ModalWrapper className="modal-wrapper">
                <Box height={650} limit={eventDetailInfo?.length} style={{ position: 'relative' }} className="box">
                    <CreateTopWrapper style={{ position: 'absolute', width: '100%' }}>
                        <LeftOutlined className="close-button" onClick={closeModal} />
                        <div>상세 정보</div>
                    </CreateTopWrapper>
                    {detailComponent}
                    <ButtonWeapper>
                        <button className="update" onClick={updateToggle ? onClickUpdateToggle(false) : onClickUpdateToggle(true)}>
                            {updateToggle ? '수정 취소' : '수정'}
                        </button>
                        <button className={updateToggle ? 'save' : 'cencle'} onClick={updateToggle ? onClickSave : closeModal}>
                            {updateToggle ? '저장' : '취소'}
                        </button>
                    </ButtonWeapper>
                </Box>
            </ModalWrapper>
        </MobileBox>
    );
};

export default DetailBanner;
