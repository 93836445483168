import React, { useCallback, useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

// react-query && axios $ URL
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import LoadingPage from '../../pages/loading/LoadingPage';
import SearchModalPagination from '../Common/SearchModalPagination';
import SearchModalGatheringEntry from './SearchModalGatheringEntry';
import SearchModalScheduleEntry from './SearchModalScheduleEntry';
import SearchUserEntry from '../PointComponents/Coupon/SearchUserModalEntry';
import ContentsEntry from './ContentsEntry';

/** 검색 모달 */
function SearchModal({ type, setToggle, funcParam }) {
    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [page, setPage] = useState(1);

    // react-query
    const { isLoading, error, data, refetch } = useQuery(['searchData', page], async () => {
        if (type === '모임') {
            let gatheringQuery = `?size=${10}&page=${page}&searchType=${searchType}&searchWord=${searchText}`;

            return await RefreshCheckApi.get(`/adminGathering${gatheringQuery}`).then((res) => {
                return res.data.gatherings;
            });
        } else if (type === '게시글') {
            let shceduleQuery = `?size=${10}&page=${page}&searchType=${searchType}&searchWord=${searchText}&type=${'pick'}`;

            return await RefreshCheckApi.get(`/adminGathering/contents${shceduleQuery}`).then((res) => {
                return res.data.data;
            });
        } else if (type === '일정') {
            let shceduleQuery = `?size=${10}&page=${page}&searchType=${searchType}&searchWord=${searchText}`;

            return await RefreshCheckApi.get(`/adminGathering/schedule${shceduleQuery}`).then((res) => {
                return res.data.data;
            });
        } else if (type === '회원') {
            let shceduleQuery = `?size=${10}&page=${page}&searchType=${searchType}&searchWord=${searchText}`;

            return await RefreshCheckApi.get(`/adminUser${shceduleQuery}`).then((res) => {
                return res.data.data;
            });
        }
    });

    /** 검색어 입력 */
    const onChangeText = useCallback(
        (e) => {
            setSearchText(e.target.value);
        },
        [setSearchText],
    );

    /** 검색 */
    const onSubmitSearch = (e) => {
        e.preventDefault();
        refetch();
    };

    /** 검색 타입 설정 */
    const onChangeSelect = (e) => {
        let _type = parseInt(e.target.value);
        setSearchType(_type);
    };

    /** 모달창 닫기 */
    const onClickClose = useCallback(() => {
        setToggle(false);
    }, [setToggle]);

    // 검색어 기본 타입 설정
    useEffect(() => {
        if (type === '모임') {
            setSearchType(1);
        } else if (type === '게시글') {
            setSearchType(2);
        } else if (type === '일정') {
            setSearchType(0);
        } else if (type === '회원') {
            setSearchType(1);
        }
    }, [type]);

    /** 검색 타입 리스트 */
    let dropDownLists = [];
    if (type === '모임') {
        dropDownLists = [
            { id: 1, title: '닉네임', value: 1 },
            { id: 2, title: '모임장명', value: 2 },
            { id: 3, title: '모임명', value: 3 },
            { id: 4, title: '모임ID', value: 4 },
            { id: 5, title: '활동지역', value: 5 },
            { id: 6, title: '가입회원수', value: 6 },
        ];
    } else if (type === '게시글') {
        dropDownLists = [
            { id: 2, title: '게시글 제목', value: 2 },
            { id: 3, title: '게시글 내용', value: 3 },
            { id: 6, title: '모임명', value: 6 },
            { id: 4, title: '이름', value: 4 },
            { id: 5, title: '닉네임', value: 5 },
            { id: 1, title: '게시글ID', value: 1 },
        ];
    } else if (type === '일정') {
        dropDownLists = [
            { id: 0, title: '일정ID', value: 0 },
            { id: 1, title: '일정명', value: 1 },
            { id: 2, title: '모임명', value: 2 },
            { id: 3, title: '일정개설자', value: 3 },
            { id: 4, title: '모임장이름', value: 4 },
        ];
    } else if (type === '회원') {
        dropDownLists = [
            { id: 1, title: '핸드폰 번호', value: 1 },
            { id: 2, title: '이름', value: 2 },
            { id: 3, title: '닉네임', value: 3 },
            { id: 4, title: '회원ID', value: 4 },
        ];
    }

    const typeComponents = (item, index) => {
        switch (true) {
            case type === '모임':
                return <SearchModalGatheringEntry item={item} index={index} funcParam={funcParam} setToggle={setToggle} />;
            case type === '게시글':
                return <ContentsEntry item={item} index={index} funcParam={funcParam} setToggle={setToggle} />;
            case type === '일정':
                return <SearchModalScheduleEntry item={item} index={index} funcParam={funcParam} setToggle={setToggle} />;
            case type === '회원':
                return <SearchUserEntry item={item} index={index} funcParam={funcParam} setToggle={setToggle} />;
            default:
                return <div style={{ color: '#fff' }}>검색 결과를 확인할 수 없습니다.</div>;
        }
    };

    if (isLoading) {
        return (
            <ModalWindow>
                <ModalBox>
                    <LoadingPage text={'검색 결과를 불러오는 중입니다.'} style={{ color: '#fff' }} />;
                </ModalBox>
            </ModalWindow>
        );
    } else if (error) {
        alert(error);
    } else {
        return (
            <ModalWindow>
                <CloseIcon onClick={onClickClose}>
                    <CloseOutlined />
                </CloseIcon>
                <div style={{ padding: 16, border: 'solid white', backgroundColor: '#000000', borderRadius: 32 }}>
                    <SearchbarWrapper onSubmit={onSubmitSearch}>
                        <Select onChange={onChangeSelect}>
                            {dropDownLists.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>
                                        {item.title}
                                    </option>
                                );
                            })}
                        </Select>
                        <Input type="text" placeholder="검색어를 입력하세요" onChange={onChangeText} />
                        <SearchButton o>검색</SearchButton>
                    </SearchbarWrapper>

                    <ModalBox>
                        <ResultWrapper>
                            {data?.length ? (
                                <div className="data-entry">
                                    {data.map((item, index) => {
                                        return <div key={index}>{typeComponents(item, index)}</div>;
                                    })}
                                </div>
                            ) : (
                                <div style={{ color: '#fff', textAlign: 'center' }}>{type === '모임' ? '검색결과가 없습니다.' : '일정 내역이 없습니다.'}</div>
                            )}
                        </ResultWrapper>

                        <div id="pagination-wrapper">
                            <SearchModalPagination totalPages={data?.allCount ? Math.round(data?.allCount / 10) : 10} limit={10} page={page} setPage={setPage} />
                        </div>
                    </ModalBox>
                </div>
            </ModalWindow>
        );
    }
}

export default SearchModal;

const CloseIcon = styled.div`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    padding: 2%;

    font-size: 35px;
    color: #fff;
`;

const SearchbarWrapper = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 95%;
`;

const Select = styled.select`
    cursor: pointer;
    outline: none;
    width: 120px;
    height: 27px;

    background-color: #ffffff38;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
`;

const Input = styled.input`
    border: 0;
    border-bottom: 3px solid #828282;
    background: transparent;
    outline: none;

    font-size: 17px;
    font-weight: bold;
    color: #fff;

    padding: 2px 5px;

    width: 66.5vh;
    max-width: 95%;
    margin: 10px 0;

    @media (max-width: 912px) {
        width: 70%;
    }
`;

const SearchButton = styled.button`
    cursor: pointer;
    outline: none;
    border: 0;

    width: 50px;
    height: 27px;

    background-color: #ffffff38;
    font-size: 15px;
    font-weight: bold;
    color: #fff;

    &:hover {
        background-color: #aeaeae26;
    }
`;

const ModalWindow = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;

    background-color: #000000ba;
`;

const ModalBox = styled.div`
    width: 85vh; // 95vh;
    max-width: 98%;
    height: 47vh;

    /* background-color: #fff; */
    @media (max-width: 912px) {
        width: 95%;
        height: 70vh;
    }
`;

const ResultWrapper = styled.div`
    height: 100%;
    overflow: auto;
`;
