import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 모임채팅 리스트 불러오기
export const GET_GATHERING_CHAT_REQUEST = 'GET_GATHERING_CHAT_REQUEST';
export const GET_GATHERING_CHAT_SUCCESS = 'GET_GATHERING_CHAT_SUCCESS';
export const GET_GATHERING_CHAT_FAILURE = 'GET_GATHERING_CHAT_FAILURE';
// 모임채팅 리스트 총 길이 불러오기
export const GET_GATHERING_CHAT_ALL_COUNT_REQUEST = 'GET_GATHERING_CHAT_ALL_COUNT_REQUEST';
export const GET_GATHERING_CHAT_ALL_COUNT_SUCCESS = 'GET_GATHERING_CHAT_ALL_COUNT_SUCCESS';
export const GET_GATHERING_CHAT_ALL_COUNT_FAILURE = 'GET_GATHERING_CHAT_ALL_COUNT_FAILURE';
// 모임채팅 상세 불러오기
export const GET_GATHERING_CHAT_DETAIL_REQUEST = 'GET_GATHERING_CHAT_DETAIL_REQUEST';
export const GET_GATHERING_CHAT_DETAIL_SUCCESS = 'GET_GATHERING_CHAT_DETAIL_SUCCESS';
export const GET_GATHERING_CHAT_DETAIL_FAILURE = 'GET_GATHERING_CHAT_DETAIL_FAILURE';

// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    gatheringChatLists: [], // 모임채팅 리스트
    gatheringChatListsAllCount: null, // 모임채팅 총길이 리스트
    gatheringChatDetail: null, // 모임채팅 상세 데이터
    // 모임채팅 리스트
    getGatheringChatLoading: false,
    getGatheringChatDone: false,
    getGatheringChatError: null,
    // 모임채팅 리스트 총 길이
    getGatheringChatAllCountLoading: false,
    getGatheringChatAllCountDone: false,
    getGatheringChatAllCountError: null,
    // 모임채팅 상세 리스트
    getGatheringChatDetailLoading: false,
    getGatheringChatDetailDone: false,
    getGatheringChatDetailError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 모임채팅 리스트 불러오기
export const requestGetGatheringChatLists = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_CHAT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/chatting`, { params: input });
        dispatch({
            type: GET_GATHERING_CHAT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_CHAT_FAILURE,
            error: error,
        });
    }
};

// 모임채팅 리스트 총길이 불러오기
export const requestGetGatheringChatAllCount = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_CHAT_ALL_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/chatting/allCount`, { params: input });
        dispatch({
            type: GET_GATHERING_CHAT_ALL_COUNT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_CHAT_ALL_COUNT_FAILURE,
            error: error,
        });
    }
};

// 모임채팅 상세 불러오기
export const requestGetGatheringChatDetail = (chatting_id) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_CHAT_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/chatting/detail?chatting_id=${chatting_id}`);
        dispatch({
            type: GET_GATHERING_CHAT_DETAIL_SUCCESS,
            payload: response.data.data[0],
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_CHAT_DETAIL_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const getGatheringChatReducer = handleActions(
    {
        // 모임채팅 리스트 불러오기
        [GET_GATHERING_CHAT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatLoading = true;
                draft.getGatheringChatDone = false;
                draft.getGatheringChatError = null;
            }),
        [GET_GATHERING_CHAT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatLoading = action.payload ? false : true;
                draft.gatheringChatLists = action.payload;
                draft.getGatheringChatDone = action.payload ? true : false;
            }),
        [GET_GATHERING_CHAT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatLoading = false;
                draft.getGatheringChatError = action.error;
            }),

        // 모임채팅 리스트 총 길이 불러오기
        [GET_GATHERING_CHAT_ALL_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatAllCountLoading = true;
                draft.getGatheringChatAllCountDone = false;
                draft.getGatheringChatAllCountError = null;
            }),
        [GET_GATHERING_CHAT_ALL_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatAllCountLoading = action.payload ? false : true;
                draft.gatheringChatListsAllCount = action.payload;
                draft.getGatheringChatAllCountDone = action.payload ? true : false;
            }),
        [GET_GATHERING_CHAT_ALL_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatAllCountLoading = false;
                draft.getGatheringChatAllCountError = action.error;
            }),

        // 모임채팅 상세 불러오기
        [GET_GATHERING_CHAT_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatDetailLoading = true;
                draft.getGatheringChatDetailDone = false;
                draft.getGatheringChatDetailError = null;
            }),
        [GET_GATHERING_CHAT_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatDetailLoading = action.payload ? false : true;
                draft.gatheringChatDetail = action.payload;
                draft.getGatheringChatDetailDone = action.payload ? true : false;
            }),
        [GET_GATHERING_CHAT_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringChatDetailLoading = false;
                draft.getGatheringChatDetailError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 모임채팅
                draft.getGatheringChatLoading = false;
                draft.getGatheringChatDone = false;
                draft.getGatheringChatError = null;
                // 모임채팅 상세
                draft.getGatheringChatDetailLoading = false;
                draft.getGatheringChatDetailDone = false;
                draft.getGatheringChatDetailError = null;
            }),
    },
    initialState,
);

export default getGatheringChatReducer;
