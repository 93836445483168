import produce from 'immer';
import { handleActions } from 'redux-actions';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 포인트 내역 리스트
export const GET_POINT_HISTORY_REQUEST = 'GET_POINT_HISTORY_REQUEST';
export const GET_POINT_HISTORY_SUCCESS = 'GET_POINT_HISTORY_SUCCESS';
export const GET_POINT_HISTORY_FAILURE = 'GET_POINT_HISTORY_FAILURE';
// 포인트 내역 상세조회
export const GET_POINT_HISTORY_DETAIL_REQUEST = 'GET_POINT_HISTORY_DETAIL_REQUEST';
export const GET_POINT_HISTORY_DETAIL_SUCCESS = 'GET_POINT_HISTORY_DETAIL_SUCCESS';
export const GET_POINT_HISTORY_DETAIL_FAILURE = 'GET_POINT_HISTORY_DETAIL_FAILURE';
// 포인트 생성
export const CREATE_POINT_HISTORY_REQUEST = 'CREATE_POINT_HISTORY_REQUEST';
export const CREATE_POINT_HISTORY_SUCCESS = 'CREATE_POINT_HISTORY_SUCCESS';
export const CREATE_POINT_HISTORY_FAILURE = 'CREATE_POINT_HISTORY_FAILURE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

// 초기상태
const initialState = {
    pointHistoryLists: null, // 포인트 내역 리스트
    pointHistoryDetailInfo: null, // 포인트 내역 리스트
    createPointMessage: null, // 포인트 지급 메세지
    // 포인트 조회
    getPointHistoryLoading: false,
    getPointHistoryDone: false,
    getPointHistoryError: null,
    // 포인트 상세조회
    getPointHistoryDetailLoading: false,
    getPointHistoryDetailDone: false,
    getPointHistoryDetailError: null,
    // 포인트 지급
    createPointLoading: false,
    createPointDone: false,
    createPointError: null,
};

// 포인트 조회
export const getPointHistoryLists = (input) => async (dispatch) => {
    dispatch({
        type: GET_POINT_HISTORY_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminPoint`, { params: input });
        dispatch({
            type: GET_POINT_HISTORY_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.error(error);
        // if failed? start this code line
        dispatch({
            type: GET_POINT_HISTORY_FAILURE,
            error: error,
        });
    }
};

// 포인트 상세조회
export const getPointHistoryDetailInfo = (point_hist_id) => async (dispatch) => {
    dispatch({
        type: GET_POINT_HISTORY_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminPoint/detail/${point_hist_id}`);
        dispatch({
            type: GET_POINT_HISTORY_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        console.error(error);
        // if failed? start this code line
        dispatch({
            type: GET_POINT_HISTORY_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 포인트 지급
export const createPointHistory = (param) => async (dispatch) => {
    dispatch({
        type: CREATE_POINT_HISTORY_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminPoint`, param);
        dispatch({
            type: CREATE_POINT_HISTORY_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        console.error(error);
        // if failed? start this code line
        dispatch({
            type: CREATE_POINT_HISTORY_FAILURE,
            error: error,
        });
    }
};

const pointHistoryReducer = handleActions(
    {
        // 포인트 조회
        [GET_POINT_HISTORY_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getPointHistoryLoading = true;
                draft.getPointHistoryDone = false;
                draft.getPointHistoryError = null;
            }),
        [GET_POINT_HISTORY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getPointHistoryLoading = action.payload ? false : true;
                draft.pointHistoryLists = action.payload;
                draft.getPointHistoryDone = action.payload ? true : false;
            }),
        [GET_POINT_HISTORY_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getPointHistoryLoading = false;
                draft.getPointHistoryError = action.error;
            }),

        // 포인트 상세조회
        [GET_POINT_HISTORY_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getPointHistoryDetailLoading = true;
                draft.getPointHistoryDetailDone = false;
                draft.getPointHistoryDetailError = null;
            }),
        [GET_POINT_HISTORY_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getPointHistoryDetailLoading = action.payload ? false : true;
                draft.pointHistoryDetailInfo = action.payload;
                draft.getPointHistoryDetailDone = action.payload ? true : false;
            }),
        [GET_POINT_HISTORY_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getPointHistoryDetailLoading = false;
                draft.getPointHistoryDetailError = action.error;
            }),

        // 포인트 지급
        [CREATE_POINT_HISTORY_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.createPointLoading = true;
                draft.createPointDone = false;
                draft.createPointError = null;
            }),
        [CREATE_POINT_HISTORY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.createPointLoading = action.payload ? false : true;
                draft.createPointMessage = action.payload;
                draft.createPointDone = action.payload ? true : false;
            }),
        [CREATE_POINT_HISTORY_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.createPointLoading = false;
                draft.createPointError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 포인트 지급
                draft.createPointMessage = null;
                draft.createPointLoading = false;
                draft.createPointDone = false;
                draft.createPointError = null;
            }),
    },
    initialState,
);

export default pointHistoryReducer;
