import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 베너 리스트 불러오기
export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE';
// 베너 정보 상세조회
export const GET_BANNER_DETAIL_REQUEST = 'GET_BANNER_DETAIL_REQUEST';
export const GET_BANNER_DETAIL_SUCCESS = 'GET_BANNER_DETAIL_SUCCESS';
export const GET_BANNER_DETAIL_FAILURE = 'GET_BANNER_DETAIL_FAILURE';
// 베너정보 수정
export const UPDATE_BANNER_DETAIL_REQUEST = 'UPDATE_BANNER_DETAIL_REQUEST';
export const UPDATE_BANNER_DETAIL_SUCCESS = 'UPDATE_BANNER_DETAIL_SUCCESS';
export const UPDATE_BANNER_DETAIL_FAILURE = 'UPDATE_BANNER_DETAIL_FAILURE';
// 베너정보 생성
export const CREATE_BANNER_DETAIL_REQUEST = 'CREATE_BANNER_DETAIL_REQUEST';
export const CREATE_BANNER_DETAIL_SUCCESS = 'CREATE_BANNER_DETAIL_SUCCESS';
export const CREATE_BANNER_DETAIL_FAILURE = 'CREATE_BANNER_DETAIL_FAILURE';
// 베너정보 삭제
export const DELETE_BANNER_DETAIL_REQUEST = 'DELETE_BANNER_DETAIL_REQUEST';
export const DELETE_BANNER_DETAIL_SUCCESS = 'DELETE_BANNER_DETAIL_SUCCESS';
export const DELETE_BANNER_DETAIL_FAILURE = 'DELETE_BANNER_DETAIL_FAILURE';
// 베너 리스트 우선순위 변경
export const UPDATE_BANNER_ORDER_DETAIL_REQUEST = 'UPDATE_BANNER_ORDER_DETAIL_REQUEST';
export const UPDATE_BANNER_ORDER_DETAIL_SUCCESS = 'UPDATE_BANNER_ORDER_DETAIL_SUCCESS';
export const UPDATE_BANNER_ORDER_DETAIL_FAILURE = 'UPDATE_BANNER_ORDER_DETAIL_FAILURE';
// 베너 광고 현황
export const GET_BANNER_STATICS_REQUEST = 'GET_BANNER_STATICS_REQUEST';
export const GET_BANNER_STATICS_SUCCESS = 'GET_BANNER_STATICS_SUCCESS';
export const GET_BANNER_STATICS_FAILURE = 'GET_BANNER_STATICS_FAILURE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    events: [], // 베너 리스트
    eventDetailInfo: [], // 베너 정보 상세조회
    updateResultMessage: null, // 베너정보 수정 결과 메세지
    createResultMessage: null, // 베너정보 생성 결과 메세지
    deleteResultMessage: null, // 베너정보 삭제 결과 메세지
    updateOrderResultMessage: null, // 베너 리스트 우선순위 변경
    getEventStatics: null, // 베너 광고 현황
    // 베너 조회
    getBannerLoading: false,
    getBannerDone: false,
    getBannerError: null,
    // 베너 조회
    getBannerDetailLoading: false,
    getBannerDetailDone: false,
    getBannerDetailError: null,
    // 베너정보 수정
    updateBannerDetailLoading: false,
    updateBannerDetailDone: false,
    updateBannerDetailError: null,
    // 베너 생성
    createBannerDetailLoading: false,
    createBannerDetailDone: false,
    createBannerDetailError: null,
    // 베너 삭제
    deleteBannerDetailLoading: false,
    deleteBannerDetailDone: false,
    deleteBannerDetailError: null,
    // 베너 리스트 우선순위 변경
    updateBannerOrderDetailLoading: false,
    updateBannerOrderDetailDone: false,
    updateBannerOrderDetailError: null,
    // 베너 광고 현황
    getEventStaticsLoading: false,
    getEventStaticsDone: false,
    getEventStaticsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 베너 리스트 불러오기
export const getEventsLists = (input) => async (dispatch) => {
    dispatch({
        type: GET_BANNER_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminEvent`, { params: input });

        dispatch({
            type: GET_BANNER_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_BANNER_FAILURE,
            error: error,
        });
    }
};

// 베너 정보 상세조회
export const getEventsDetail = (event_id) => async (dispatch) => {
    dispatch({
        type: GET_BANNER_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminEvent/${event_id}`);

        dispatch({
            type: GET_BANNER_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_BANNER_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 베너정보 수정
export const updateEventBannerInfo = (event_id, body) => async (dispatch) => {
    dispatch({
        type: UPDATE_BANNER_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.put(`/adminEvent/${event_id}`, body);

        dispatch({
            type: UPDATE_BANNER_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_BANNER_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 베너정보 생성
export const createEventBannerInfo = (body) => async (dispatch) => {
    dispatch({
        type: CREATE_BANNER_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminEvent`, body);
        dispatch({
            type: CREATE_BANNER_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_BANNER_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 베너정보 삭제
export const deleteEventBannerInfo = (lists) => async (dispatch) => {
    dispatch({
        type: DELETE_BANNER_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminEvent`, {
            event_id: lists,
        });
        dispatch({
            type: DELETE_BANNER_DETAIL_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: DELETE_BANNER_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 베너 리스트 우선순위 변경
export const updateOrderBanner = (order) => async (dispatch) => {
    dispatch({
        type: UPDATE_BANNER_ORDER_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.put(`/adminEvent/order`, {
            order: order,
        });
        dispatch({
            type: UPDATE_BANNER_ORDER_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_BANNER_ORDER_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 베너 광고 현황
export const getEventsStatics = (input) => async (dispatch) => {
    dispatch({
        type: GET_BANNER_STATICS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminEvent/click`, { params: input });
        dispatch({
            type: GET_BANNER_STATICS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_BANNER_STATICS_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const newEventsReducer = handleActions(
    {
        // 베너 리스트 조회
        [GET_BANNER_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getBannerLoading = true;
                draft.getBannerDone = false;
                draft.getBannerError = null;
            }),
        [GET_BANNER_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getBannerLoading = action.payload ? false : true;
                draft.events = action.payload;
                draft.getBannerDone = action.payload ? true : false;
            }),
        [GET_BANNER_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getBannerLoading = false;
                draft.getBannerError = action.error;
            }),

        // 베너 정보 상세조회
        [GET_BANNER_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getBannerDetailLoading = true;
                draft.getBannerDetailDone = false;
                draft.getBannerDetailError = null;
            }),
        [GET_BANNER_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getBannerDetailLoading = action.payload ? false : true;
                draft.eventDetailInfo = action.payload;
                draft.getBannerDetailDone = action.payload ? true : false;
            }),
        [GET_BANNER_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getBannerDetailLoading = false;
                draft.getBannerDetailError = action.error;
            }),

        // 베너정보 수정
        [UPDATE_BANNER_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateBannerDetailLoading = true;
                draft.updateBannerDetailDone = false;
                draft.updateBannerDetailError = null;
            }),
        [UPDATE_BANNER_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateBannerDetailLoading = action.payload ? false : true;
                draft.updateResultMessage = action.payload;
                draft.updateBannerDetailDone = action.payload ? true : false;
            }),
        [UPDATE_BANNER_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateBannerDetailLoading = false;
                draft.updateBannerDetailError = action.error;
            }),

        // 베너정보 생성
        [CREATE_BANNER_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.createBannerDetailLoading = true;
                draft.createBannerDetailDone = false;
                draft.createBannerDetailError = null;
            }),
        [CREATE_BANNER_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.createBannerDetailLoading = action.payload ? false : true;
                draft.createResultMessage = action.payload;
                draft.createBannerDetailDone = action.payload ? true : false;
            }),
        [CREATE_BANNER_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.createBannerDetailLoading = false;
                draft.createBannerDetailError = action.error;
            }),

        // 베너정보 삭제
        [DELETE_BANNER_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteBannerDetailLoading = true;
                draft.deleteBannerDetailDone = false;
                draft.deleteBannerDetailError = null;
            }),
        [DELETE_BANNER_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteBannerDetailLoading = action.payload ? false : true;
                draft.deleteResultMessage = action.payload;
                draft.deleteBannerDetailDone = action.payload ? true : false;
            }),
        [DELETE_BANNER_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteBannerDetailLoading = false;
                draft.deleteBannerDetailError = action.error;
            }),

        // 베너정보 우선순위 변경
        [UPDATE_BANNER_ORDER_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateBannerOrderDetailLoading = true;
                draft.updateBannerOrderDetailDone = false;
                draft.updateBannerOrderDetailError = null;
            }),
        [UPDATE_BANNER_ORDER_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateBannerOrderDetailLoading = action.payload ? false : true;
                draft.updateOrderResultMessage = action.payload;
                draft.updateBannerOrderDetailDone = action.payload ? true : false;
            }),
        [UPDATE_BANNER_ORDER_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateBannerOrderDetailLoading = false;
                draft.updateBannerOrderDetailError = action.error;
            }),

        // 베너 광고 현황
        [GET_BANNER_STATICS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getEventStaticsLoading = true;
                draft.getEventStaticsDone = false;
                draft.getEventStaticsError = null;
            }),
        [GET_BANNER_STATICS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getEventStaticsLoading = action.payload ? false : true;
                draft.getEventStatics = action.payload;
                draft.getEventStaticsDone = action.payload ? true : false;
            }),
        [GET_BANNER_STATICS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getEventStaticsLoading = false;
                draft.getEventStaticsError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 베너 리스트
                draft.getBannerLoading = false;
                draft.getBannerDone = false;
                draft.getBannerError = null;
                // 베너 상세정보
                draft.getBannerDetailLoading = false;
                draft.getBannerDetailDone = false;
                draft.getBannerDetailError = null;
                // 베너정보 수정
                draft.updateBannerDetailLoading = false;
                draft.updateBannerDetailDone = false;
                draft.updateBannerDetailError = null;
                draft.updateResultMessage = null;
                // 베너정보 생성
                draft.createBannerDetailLoading = false;
                draft.createBannerDetailDone = false;
                draft.createBannerDetailError = null;
                draft.createResultMessage = null;
                // 베너정보 삭제
                draft.deleteBannerDetailLoading = false;
                draft.deleteBannerDetailDone = false;
                draft.deleteBannerDetailError = null;
                draft.deleteResultMessage = null;
                // 베너 리스트 우선순위 변경
                draft.updateOrderResultMessage = false;
                draft.updateBannerOrderDetailLoading = false;
                draft.updateBannerOrderDetailDone = null;
                draft.updateBannerOrderDetailError = null;
            }),
    },
    initialState,
);

export default newEventsReducer;
