import { Switch } from 'antd';
import React from 'react';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';

export const FilterToggleButton = ({ onChange, checked, text, style, subFilter }) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'center', ...style }}>
            <Switch onChange={onChange} checked={checked} style={{ margin: 0 }} />
            <div style={{ fontSize: '13px', fontWeight: 600 }}>{text}</div>
            {checked && subFilter}
        </FlexMainWrapper>
    );
};
