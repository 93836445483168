import React, { useEffect, useState } from 'react';
import FlexMainWrapper from '../../../designComponents/wrappers/FlexMainWrapper';
import { Image } from '../../../designComponents/imageboxs/Image';
import { MainButton } from '../../../designComponents/buttons/Buttons';
import { InputItemLists, InputTwoCheckBox } from '../../../designComponents/modals/InputItemLists';
import styled from 'styled-components';

export const AddPoint = ({ detail, userProfile, onClickClose = () => {}, onClickSave = () => {} }) => {
    const [param, setParam] = useState({});

    useEffect(() => {
        setParam({
            user_id: detail.user_id,
            user_name: userProfile.name,
            point_reason_detail: '',
            point: 0,
            pointType: 1,
        });
    }, [detail, userProfile]);

    return (
        <div style={{ position: 'relative', height: '83%' }}>
            <Wrapper style={{ margin: '20px', gap: '5%' }}>
                <Image url={userProfile.url} width={120} height={120} style={{ margin: '10px' }} />
                <div id="user-information">
                    <InputItemLists
                        wrapperStyle={{ margin: '10px 0', gap: '0px' }}
                        titleStyle={{ textAlign: 'start' }}
                        items={[
                            {
                                title: 'ID',
                                value: detail.user_id,
                                disabled: true,
                            },
                            {
                                title: '이름',
                                value: userProfile.name,
                                disabled: true,
                            },
                            {
                                title: '닉네임',
                                value: userProfile.nick_name,
                                disabled: true,
                            },
                            {
                                title: '전화번호',
                                value: userProfile.phone_no,
                                disabled: true,
                            },
                        ]}
                    />
                </div>
                <div id="point-information">
                    <InputItemLists
                        wrapperStyle={{ margin: '10px 0', gap: '0px' }}
                        titleStyle={{ textAlign: 'start' }}
                        items={[
                            {
                                title: '포인트 ID',
                                value: detail.id,
                                disabled: true,
                            },
                        ]}
                    />
                    <InputTwoCheckBox
                        titleStyle={{ textAlign: 'start' }}
                        title={'포인트 지급'}
                        items={[
                            {
                                subTitle: '증감',
                                checked: param.pointType === 1,
                                onChange: (e) => {
                                    let obj = { ...param };
                                    obj.pointType = e.target.checked ? 1 : 0;
                                    setParam(obj);
                                },
                            },
                            {
                                subTitle: '차감',
                                checked: param.pointType === 0,
                                onChange: (e) => {
                                    let obj = { ...param };
                                    obj.pointType = e.target.checked ? 0 : 1;
                                    setParam(obj);
                                },
                            },
                        ]}
                    />
                    <InputItemLists
                        wrapperStyle={{ margin: '10px 0', gap: '0px' }}
                        titleStyle={{ textAlign: 'start' }}
                        items={[
                            {
                                title: param.pointType ? '지급 포인트' : '차감 포인트',
                                value: param.point,
                                type: 'number',
                                onChange: (e) => {
                                    let obj = { ...param };
                                    obj.point = param.pointType === 1 ? e.target.value : e.target.value * -1;
                                    setParam(obj);
                                },
                            },
                            {
                                title: '변동사유',
                                value: param.point_reason_detail,
                                type: 'string',
                                onChange: (e) => {
                                    let obj = { ...param };
                                    obj.point_reason_detail = e.target.value;
                                    setParam(obj);
                                },
                            },
                            {
                                title: '추전인 번호',
                                value: param.recommendN,
                                onChange: (e) => {
                                    let obj = { ...param };
                                    obj.recommendNum = e.target.value;
                                    setParam(obj);
                                },
                            },
                        ]}
                    />
                </div>
            </Wrapper>

            <Wrapper02>
                <FlexMainWrapper style={{ gap: '10px', justifyContent: 'center' }}>
                    <MainButton text="취소" styleReverse={true} onClick={onClickClose} />
                    <MainButton text="포인트 지급" width={100} onClick={() => onClickSave(param)} />
                </FlexMainWrapper>
            </Wrapper02>
        </div>
    );
};

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 7px;
    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

const Wrapper02 = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
`;
