import React, { useCallback, useState } from 'react';
import MatchDispatchCenter from '../../../../../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';
import { only_number } from '../../../../../../data_system/validation_check/validation_check';
import {
    h_px10rate,
    h_px12rate,
    h_px14rate,
    h_px25rate,
    h_px28rate,
    h_px42rate,
    w_px11rate,
    w_px123rate,
    w_px134rate,
    w_px136rate,
    w_px164rate,
    w_px185rate,
    w_px20rate,
    w_px25rate,
    w_px26rate,
    w_px35rate,
    w_px47rate,
    w_px5rate,
    w_px60rate,
    w_px6rate,
    w_px8rate,
} from '../../../../../size';
import ContentText from '../../../../content_text/ContentText';
import DetailTextInput from '../../../../detail_text_input/DetailTextInput';
import DivisionWhiteBlueLine from '../../../../division_white_blue_line/DivisionWhiteBlueLine';
import Dropdown from '../../../../../../components/Common/Dropdown';
import ListContent from '../../../../list_content/ListContent';
import ListName from '../../../../list_name/ListName';
import OneImageSelector from '../../../../one_image_selector/OneImageSelector';
import PinkBtn from '../../../../pink_btn/PinkBtn';
import RadioBtn from '../../../../radio_btn/RadioBtn';
import SubTitle from '../../../../sub_title/SubTitle';
import TitleTextInput from '../../../../title_text_input/TitleTextInput';
import WhiteBtn from '../../../../white_btn/WhiteBtn';
import { postHostImage } from '../../../../../../communication_system/axios_apis/axios_apis';
import { Textarea } from '../../../../detail_textarea/DetailTextarea';

const HostDetailRegister = ({ closeModal, refresh, setRefresh }) => {
    const list = [
        {
            id: 0,
            title: '농협은행',
            selected: false,
            key: 'nonghyup',
            value: 0,
        },
        {
            id: 1,
            title: '국민은행',
            selected: false,
            key: 'kookmin',
            value: 1,
        },
        {
            id: 2,
            title: '신한은행',
            selected: false,
            key: 'shinhan',
            value: 2,
        },
        {
            id: 3,
            title: '우리은행',
            selected: false,
            key: 'woori',
            value: 3,
        },
        {
            id: 4,
            title: '기업은행',
            selected: false,
            key: 'ibk',
            value: 4,
        },
        {
            id: 5,
            title: '하나은행',
            selected: false,
            key: 'hana',
            value: 5,
        },
        {
            id: 6,
            title: '새마을금고',
            selected: false,
            key: 'saemaul_geumgo',
            value: 6,
        },
        {
            id: 7,
            title: '우체국',
            selected: false,
            key: 'post_office',
            value: 7,
        },
        {
            id: 8,
            title: 'SC제일은행',
            selected: false,
            key: 'sc',
            value: 8,
        },
        {
            id: 9,
            title: '대구은행',
            selected: false,
            key: 'daegu',
            value: 9,
        },
    ];
    const [hostName, setHostName] = useState('');
    const [exposureYn, setExposureYn] = useState(1);

    const [registNumber, setRegistNumber] = useState('');

    const [representName, setRepresentName] = useState('');
    const [representNumber, setRepresentNumber] = useState('');

    const [hostAddress, setHostAddress] = useState('');
    const [hostManagerName, setHostManagerName] = useState('');

    const [hostNumber, setHostNumber] = useState('');

    const [hostBankCodeItem, setHostBankCodeItem] = useState(list[0]);

    const [hostMarginRate, setHostMarginRate] = useState('');

    const [profileImg, setProfileImg] = useState(null);
    const [serverGet, setServerGet] = useState(false);
    const [adminMemo, setAdminMemo] = useState('');
    const init = () => {
        setHostName('');
        setExposureYn(1);
        setRegistNumber('');
        setRepresentName('');
        setRepresentNumber('');
        setHostAddress('');
        setHostManagerName('');
        setHostNumber('');
        setHostBankCodeItem(list[0]);
        setHostMarginRate('');
        setProfileImg(null);
        setServerGet(false);
        setAdminMemo('');
    };
    const onChangeHostName = useCallback((e) => {
        setHostName(e.target.value);
    }, []);

    const onClickExposureYes = useCallback(() => {
        setExposureYn(1);
    }, []);

    const onClickExposureNo = useCallback(() => {
        setExposureYn(0);
    }, []);

    const onChangeRegistNumber = useCallback((e) => {
        if (only_number.test(e.target.value)) {
            setRegistNumber(e.target.value);
        }
    }, []);

    const onChangeRepresentName = useCallback((e) => {
        setRepresentName(e.target.value);
    }, []);

    const onChangeRepresentNumber = (e) => {
        if (only_number.test(e.target.value)) {
            setRepresentNumber(e.target.value);
        }
    };

    const onChangeHostAddress = useCallback((e) => {
        setHostAddress(e.target.value);
    }, []);

    const onChangeHostManagerName = useCallback((e) => {
        setHostManagerName(e.target.value);
    }, []);

    const onChangeHostNumber = useCallback((e) => {
        if (only_number.test(e.target.value)) {
            setHostNumber(e.target.value);
        }
    }, []);

    const resetThenSet = useCallback((item) => {
        setHostBankCodeItem(item);
    }, []);

    const [hostAccountNumber, setHostAccountNumber] = useState('');

    const onChangeHostAccountNumber = useCallback((e) => {
        setHostAccountNumber(e.target.value);
    }, []);

    const onChangeHostMarginRate = useCallback((e) => {
        if (only_number.test(e.target.value)) {
            setHostMarginRate(e.target.value);
        }
    }, []);

    const { onPostHostsAsync } = MatchDispatchCenter();

    return (
        <div>
            <div
                style={{
                    paddingTop: h_px25rate,
                    paddingLeft: w_px25rate,
                }}
            >
                <TitleTextInput
                    value={hostName}
                    height={h_px42rate}
                    font_size={w_px20rate}
                    placeholder={'호스트 이름을 입력하세요.'}
                    onChange={onChangeHostName}
                ></TitleTextInput>
                <div
                    style={{
                        marginTop: h_px10rate,
                        marginBottom: h_px14rate,
                    }}
                >
                    <SubTitle>기본 정보</SubTitle>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>노출 상태</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <RadioBtn
                                    checked={exposureYn === 1 ? true : false}
                                    onClick={onClickExposureYes}
                                ></RadioBtn>
                                <div
                                    style={{
                                        marginLeft: w_px5rate,
                                    }}
                                >
                                    <ContentText>ON</ContentText>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginLeft: w_px5rate,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <RadioBtn
                                        checked={exposureYn === 0 ? true : false}
                                        onClick={onClickExposureNo}
                                    ></RadioBtn>
                                    <div
                                        style={{
                                            marginLeft: w_px5rate,
                                        }}
                                    >
                                        <ContentText>OFF</ContentText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>사진(로고)</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <OneImageSelector
                            profileImg={profileImg}
                            setProfileImg={setProfileImg}
                            serverGet={serverGet}
                            setServerGet={setServerGet}
                        ></OneImageSelector>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>사업자등록번호</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px164rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            placeholder={'사업자등록번호를 입력해주세요.'}
                            value={registNumber}
                            onChange={onChangeRegistNumber}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>대표자명</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px60rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            placeholder={'홍길동'}
                            value={representName}
                            onChange={onChangeRepresentName}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>대표 번호</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px136rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            placeholder={'대표 번호를 입력해주세요.'}
                            value={representNumber}
                            onChange={onChangeRepresentNumber}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>주소</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px185rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            placeholder={'주소를 입력해주세요.'}
                            value={hostAddress}
                            onChange={onChangeHostAddress}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>담당자명</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px47rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            placeholder={'홍길동'}
                            value={hostManagerName}
                            onChange={onChangeHostManagerName}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>연락처</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px123rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            placeholder={'연락처를 입력해주세요.'}
                            value={hostNumber}
                            onChange={onChangeHostNumber}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        marginBottom: h_px12rate,
                        alignItems: 'center',
                    }}
                >
                    <DivisionWhiteBlueLine></DivisionWhiteBlueLine>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <SubTitle>정산 정보</SubTitle>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>은행명</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <Dropdown list={list} resetThenSet={resetThenSet} index={0}></Dropdown>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>계좌번호</ListName>
                    <div
                        style={{
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px134rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            placeholder={'계좌번호를 입력해주세요.'}
                            value={hostAccountNumber}
                            onChange={onChangeHostAccountNumber}
                        ></DetailTextInput>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: h_px12rate,
                    }}
                >
                    <ListName>수수료율</ListName>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: w_px26rate,
                        }}
                    >
                        <DetailTextInput
                            width={w_px35rate}
                            height={h_px28rate}
                            font_size={w_px11rate}
                            value={hostMarginRate}
                            onChange={onChangeHostMarginRate}
                        ></DetailTextInput>
                        <div
                            style={{
                                marginLeft: w_px6rate,
                            }}
                        >
                            <ListContent>%</ListContent>
                        </div>
                    </div>
                </div>
                <div>
                    <ListName>관리자 메모</ListName>
                    <Textarea
                        style={{ width: '100%' }}
                        value={adminMemo}
                        onChange={(e) => setAdminMemo(e.target.value)}
                        rows={5}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: h_px12rate,
                    }}
                >
                    <WhiteBtn>취소</WhiteBtn>
                    <div
                        style={{
                            marginLeft: w_px8rate,
                        }}
                    >
                        <PinkBtn
                            onClick={async () => {
                                if (hostName) {
                                    const data = {
                                        host_name: hostName,
                                        exposure_yn: exposureYn,
                                        regist_number: registNumber,
                                        represent_name: representName,
                                        represent_number: representNumber,
                                        host_address: hostAddress,
                                        host_manager_name: hostManagerName,
                                        host_number: hostNumber,
                                        host_bank_code: hostBankCodeItem.value,
                                        host_account_number: hostAccountNumber,
                                        host_margin_rate: parseInt(hostMarginRate),
                                        admin_memo: adminMemo,
                                    };
                                    if (profileImg) {
                                        const input = new FormData();
                                        input.append('host_image', profileImg);
                                        const result = await postHostImage(input);
                                        data.host_image = result.data.data;
                                    }
                                    await onPostHostsAsync(data);
                                    closeModal();
                                    // setRefresh(!refresh);
                                    init();
                                } else {
                                    alert('호스트명은 필수 등록사항입니다.');
                                }
                            }}
                        >
                            등록
                        </PinkBtn>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(HostDetailRegister);
