import React, { useEffect, useState } from 'react';
import moment from 'moment';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { exampleData2 } from '../../utils/mockData';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { DateInput } from '../../designComponents/inputs/InputLine';
import { isEmptyObj } from '../../utils/textFilter';
import { MText } from '../../designComponents/text/ContentText';

/** 1. 문의 비율 */
const InCallQuestionChart = ({ title = '비율 차트', requestUrl = '', name = 'name', y = 'y' }) => {
    const [options, setOptions] = useState(exampleData2);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));

    const requestChartData = async () => {
        try {
            const input = { startDate: startDate, endDate: endDate };
            let tempSeries = [];

            const response = await RefreshCheckApi.get('/adminCS' + requestUrl, { params: input });
            if (response.status === 200) {
                let result = response.data.data;

                if (result.length <= 0) {
                    return setOptions({});
                }
                result.forEach((item) => {
                    tempSeries.push({
                        name: item[name],
                        y: item[y],
                    });
                });
            }
            let obj = { ...exampleData2 };
            obj.title = { text: title };
            obj.series = [{ name: '총 인원', colorByPoint: true, data: tempSeries }];
            setOptions(obj);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        requestChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return (
        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center' }}>
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', gap: 0, marginBottom: '10px' }}>
                <DateInput
                    wrapperStyle={{ margin: '0 5px' }}
                    inputStyle={{ width: '13vh' }}
                    value={endDate}
                    onChange={(e) => {
                        setEndDate(e.target.value);
                    }}
                />
                <span>~</span>
                <DateInput
                    wrapperStyle={{ margin: '0 5px' }}
                    inputStyle={{ width: '13vh' }}
                    value={startDate}
                    onChange={(e) => {
                        setStartDate(e.target.value);
                    }}
                />
            </FlexMainWrapper>
            {isEmptyObj(options) ? (
                <HighchartsReact highcharts={Highcharts} options={options} width={70} />
            ) : (
                <MText text={'기간 내 통계 수치가 존재하지 않습니다.'} color="#797979" style={{ margin: '10vh 0' }} />
            )}
        </FlexMainWrapper>
    );
};

export default InCallQuestionChart;
