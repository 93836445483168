import { List } from '../../common/BootStrap/List';
import { useEffect, useState } from 'react';
import locationData from '../../utils/locationData';
import styled from 'styled-components';
import { MiddleModal } from '../../common/BootStrap/Modal';
import { MainButton } from '../../designComponents/buttons/Buttons';

export const LocationSelectModal = ({ visible, setVisible, initValue = null, onChange }) => {
    const [subList, setSubList] = useState([]);
    const [mainIdx, setMainIdx] = useState(-1);
    const [subIdx, setSubIdx] = useState(-1);
    const data = locationData;
    useEffect(() => {
        if (initValue && visible) {
            const addresses = initValue.split(' ');
            const main = data.findIndex((e) => e.name === addresses[0]);
            if (main !== -1) {
                setMainIdx(main);
            }
        }
    }, [initValue, visible]);

    useEffect(() => {
        if (mainIdx > -1) {
            setSubList(data[mainIdx]?.sub ?? []);
            if (initValue) {
                const addresses = initValue.split(' ');
                const sub = data[mainIdx].sub?.findIndex((e) => e.name === addresses[1]) ?? -1;
                setSubIdx(sub);
            } else {
                setSubIdx(-1);
            }
        }
    }, [mainIdx]);
    return (
        <MiddleModal
            title={'위치 선택'}
            visible={visible}
            setVisible={setVisible}
            footer={
                <>
                    <MainButton text={'닫기'} styleReverse onClick={() => setVisible(false)} />
                    <MainButton
                        text={'저장'}
                        onClick={() => {
                            if ((subList.length > 0 && subIdx === -1) || mainIdx === -1) {
                                alert('주소를 모두 선택해 주세요');
                                return;
                            }
                            if (onChange) {
                                if (subList.length > 0 && subIdx === -1) {
                                    alert('주소를 모두 선택해 주세요');
                                    return;
                                } else {
                                    if (subList.length === 0) {
                                        onChange(data[mainIdx].name);
                                    } else {
                                        onChange(`${data[mainIdx].name} ${subList[subIdx].name}`);
                                    }
                                }
                            }
                            setVisible(false);
                        }}
                    />
                </>
            }
        >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ScrollView>
                    <List data={data} startIndex={mainIdx} renderItem={(e) => e.name} onPressItem={(_, i) => setMainIdx(i)} />
                </ScrollView>

                {subList.length > 0 ? (
                    <ScrollView>
                        <List data={subList} startIndex={subIdx} renderItem={(e) => e.name} onPressItem={(_, i) => setSubIdx(i)} />
                    </ScrollView>
                ) : null}
            </div>
        </MiddleModal>
    );
};
const ScrollView = styled.div`
    overflow-y: scroll;
    height: 50vh;
    flex: 1;
`;
