import React from "react";
import styled from "styled-components";
import {rgb_205_205_205, rgba_0_0_0_0d1} from "../../colors";
import {h_px631rate} from "../../size";

const BodyScroll = ({ children }) => {
  return <Scroll>{children}</Scroll>;
};

export default React.memo(BodyScroll);

const Scroll = styled.div`
  overflow-y: scroll;
  height: ${h_px631rate}px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${rgba_0_0_0_0d1};
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  border-left: 1px solid ${rgb_205_205_205};
  border-right: 1px solid ${rgb_205_205_205};
  border-bottom: 1px solid ${rgb_205_205_205};
`;
