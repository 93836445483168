import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// styled-components
import { LeftOutlined } from '@ant-design/icons';
import DownOutlined from '../../svg/DropdownButton.svg';
import DeleteKategoryButton from '../../svg/DeleteKategoryButton.svg';
import {
    ImageWrapper,
    ImageBox,
    ImageComponent,
    CustomCloseOutlined,
    MainPic,
    Image,
    MobileBox,
    CreateBox,
    CheckBoxWrapper,
    CreateTopWrapper,
    InputWrapper,
    InputWrapper02,
    InputWrapper03,
    CheckMainAddressBox,
    SubTitle,
    Textarea,
    Input,
    SearchBoxWrapper,
    Button,
    CheckBox,
    ButtonWeapper,
    KategoryItem,
    KategoryContentsWrapper,
    CloseWindow,
    SearchAddressBox,
    ModalWindow,
    CustomAddressCloseOutlined,
    Select,
} from '../../components/styles/AddGatheringModalStyled';

// API
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_image_ip } from '../../communication_system/communication_ips';

// 주소검색
import DaumPostcode from 'react-daum-postcode';

// kategory lists
import { interestMock } from '../../dataSystems/CategoryLists';

// custom hook
import useInput from '../../hook/useInput';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { postGathering, RESET_STATE } from '../../data_system/redux/modules/gathering.reducer/createGathering.reducer';

//* 모임등록 Modal
function AddGatheringModal({ setCreateBoxToggle, interestToggle, setInterestToggle, kategoryLists, setKategoryLists, usersData, setImageToggle, setSrc }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // useInput
    const [name, onChangeName] = useInput(''); // 모임명
    const [explanation, onChangeExplanation] = useInput(''); // 모임소개
    // const [category, onChangeCategory] = useInput(1); // 모임 카테고리
    const [role, onChangeRole] = useInput(3); // 모임 권한 설정
    // state
    const [onAddressToggle, setOnAddressToggle] = useState(false);
    const [imageLists, setImageLists] = useState([]);
    const [address, setAddress] = useState(''); // 활동지역 address
    const [exposure, setExposure] = useState(1); // 노출여부
    // useSelector
    const { addGatheringDone } = useSelector((state) => state.addGatheringReducer);

    /*********************** useEffect ***********************/

    // 모임생성 완료 시
    useEffect(() => {
        if (addGatheringDone) {
            alert('모임생성을 완료했습니다.');
            setCreateBoxToggle(false);
            setKategoryLists([]);

            navigate('/gathering');

            dispatch({
                type: RESET_STATE,
            });
        }
    }, [dispatch, setCreateBoxToggle, addGatheringDone, setKategoryLists, navigate]);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                if (!interestToggle && !onAddressToggle) {
                    closeModal();
                } else if (!interestToggle && !onAddressToggle) {
                    closeModal();
                } else if (onAddressToggle) {
                    setOnAddressToggle(false);
                }
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interestToggle, onAddressToggle]);

    /*********************** function ***********************/

    // 생성창 닫기
    const onClickClose = () => {
        setKategoryLists([]);
        setCreateBoxToggle((prev) => !prev);
    };

    // 등록창 닫기
    const closeModal = () => {
        setKategoryLists([]);
        setCreateBoxToggle(false);
    };

    // 이미지 제거
    const onClickDeleteImage = (index) => () => {
        let arr = [...imageLists];
        arr.splice(index, 1);

        setImageLists(arr);
    };

    // 관심주제 선택
    const onClickInterestModalToggle = () => {
        setInterestToggle((prev) => !prev);
    };

    // 선택 카테고리 제거
    const onClickDeleteKategory = (kategory) => () => {
        let arr = [...kategoryLists];

        const findIndex = arr.indexOf(kategory.key);
        arr.splice(findIndex, 1);

        setKategoryLists(arr);
    };

    // 주소검색 토글
    const onClickAddressToggle = () => {
        setOnAddressToggle((prev) => !prev);
    };

    // 주소결과
    const onCompletePost = (data) => {
        setAddress(`${data.sido} ${data.sigungu}`); // roadAddress
        setOnAddressToggle((prev) => !prev);
    };

    // 노출여부 체크
    const onChangeExposure = () => {
        if (exposure) {
            setExposure(0);
        } else if (!exposure) {
            setExposure(1);
        }
    };

    // 이미지 크게보기
    const onClickShowImage = (src) => () => {
        setSrc(src);
        setImageToggle((prev) => !prev);
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback(async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });

        const response = await RefreshCheckApi.post('/adminGathering/uploadImage', imageFormData);

        setTimeout(() => {
            setImageLists(response.data);
        }, 1100);
    }, []);

    /** 모임생성 (최종 저장 버튼) */
    const createGathering = () => {
        /** 회원조건 필터 */
        if (!usersData.name) {
            return alert('회원 이름이 존재하지 않아 모임생성이 불가능 합니다.');
        }
        if (!usersData.nickname) {
            return alert('회원 닉네임이 존재하지 않아 모임생성이 불가능합니다.');
        }
        if (!usersData.sex) {
            return alert('회원 성별이 불분명하여 모임생성이 불가능합니다.');
        }
        if (!usersData.phone_no) {
            return alert('회원 전화번호를 확인해주세요. 번호가 존재하지 않아 모임생성이 불가능합니다.');
        }

        /** 입려값 필터 */
        if (!name) {
            return alert('모임명을 작성해주세요.');
        }
        if (!explanation) {
            return alert('모임소개를 작성해주세요.');
        }
        if (!address) {
            return alert('주소를 입력해주세요.');
        }
        if (kategoryLists.length === 0) {
            return alert('카테고리를 선택해주세요.');
        }
        if (imageLists.length === 0) {
            return alert('이미지를 업로드 해주세요.');
        }

        const params = {
            user_id: usersData.id, // 모이장 회원 id
            role: role,
            name: name, // 모임명
            explanation: explanation, // 모임소개
            // category: parseInt(category), // 모임 카테고리
            address: address, // 활동지역 - 위도, 경도로 서버에서 변환
            interests_code: kategoryLists, // 관심주제
            image_lists: imageLists, // 모바일 이미지
            exposure_yn: exposure ? 1 : 0, // 노출여부
        };

        dispatch(postGathering(params));
    };

    /************************* html *************************/

    // 다음 주소창 스타일 정의 code
    const postCodeStyle = {
        display: onAddressToggle ? 'block' : 'none',
        border: '3px solid #c4c4c4',
        width: '400px',
        height: '500px',
    };

    return (
        <div>
            {/* 주소검색 모달 */}
            {onAddressToggle && (
                <ModalWindow>
                    <SearchAddressBox>
                        <CloseWindow>
                            <CustomAddressCloseOutlined onClick={onClickAddressToggle} />
                        </CloseWindow>
                        <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} />
                    </SearchAddressBox>
                </ModalWindow>
            )}

            <MobileBox>
                <CreateBox>
                    <CreateTopWrapper>
                        <LeftOutlined className="close-button" onClick={onClickClose} />
                        <div>모임만들기</div>
                    </CreateTopWrapper>

                    <InputWrapper id="gathering-name">
                        <SubTitle>ID</SubTitle>
                        <Input type="text" value={usersData.id || '정보없음'} readOnly disabled />
                    </InputWrapper>

                    <InputWrapper id="gathering-name">
                        <SubTitle>이름</SubTitle>
                        <Input type="text" value={usersData.name || '정보없음'} readOnly disabled />
                    </InputWrapper>

                    <InputWrapper id="gathering-name">
                        <SubTitle>전화번호</SubTitle>
                        <Input type="text" value={usersData.phone_no || '정보없음'} readOnly disabled />
                    </InputWrapper>

                    <InputWrapper id="gathering-name">
                        <SubTitle style={{ width: '75px' }}>권한설정</SubTitle>
                        <Select value={role} onChange={onChangeRole}>
                            <option value={3}>모임장</option>
                            <option value={2}>운영진</option>
                            <option value={1}>일반회원</option>
                        </Select>
                    </InputWrapper>

                    <InputWrapper id="gathering-name">
                        <SubTitle>모임명</SubTitle>
                        <Input type="text" placeholder="모임 이름을 입력해주세요." onChange={onChangeName} />
                    </InputWrapper>

                    <InputWrapper02 id="gathering-introduce">
                        <SubTitle>모임 소개</SubTitle>
                        <Textarea type="text" placeholder="모임 이름을 입력해주세요." onChange={onChangeExplanation} />
                    </InputWrapper02>

                    <div id="activity-area">
                        <InputWrapper>
                            <SubTitle>활동 지역</SubTitle>
                            <SearchBoxWrapper>
                                <Input type="text" value={address} placeholder="주소를 검색해 주세요." readOnly />
                                <Button onClick={onClickAddressToggle}>주소검색</Button>
                            </SearchBoxWrapper>
                        </InputWrapper>

                        <CheckMainAddressBox>
                            <CheckBox type="checkbox" />
                            <span>회원 기본 주소로 가져오기</span>
                        </CheckMainAddressBox>
                    </div>

                    <InputWrapper onClick={onClickInterestModalToggle}>
                        <SubTitle style={{ width: '75px' }}>관심 주제</SubTitle>
                        <InputWrapper03>
                            <div>선택하기</div>
                            <img src={DownOutlined} alt="drop-down" />
                        </InputWrapper03>
                    </InputWrapper>

                    <ImageWrapper>
                        {kategoryLists.length > 0 &&
                            kategoryLists.map((kategory, index) => {
                                // eslint-disable-next-line array-callback-return
                                return (
                                    <KategoryItem key={index}>
                                        {interestMock.map((data, index) => {
                                            return (
                                                kategory === data.id && (
                                                    <div key={index} className="box-wrapper">
                                                        <KategoryContentsWrapper>
                                                            <div>{data.code_name}</div>
                                                            <img style={{ cursor: 'pointer' }} src={DeleteKategoryButton} alt="카테고리삭제" onClick={onClickDeleteKategory(data)} />
                                                        </KategoryContentsWrapper>
                                                    </div>
                                                )
                                            );
                                        })}
                                    </KategoryItem>
                                );
                            })}
                    </ImageWrapper>

                    <InputWrapper02 id="gathering-introduce">
                        <SubTitle style={{ width: '83px' }}>노출 여부</SubTitle>
                        <CheckBoxWrapper>
                            <CheckBox type="checkbox" checked={exposure ? true : false} onChange={onChangeExposure} />
                            <span>Y</span>
                        </CheckBoxWrapper>

                        <CheckBoxWrapper>
                            <CheckBox type="checkbox" checked={exposure ? false : true} onChange={onChangeExposure} />
                            <span>N</span>
                        </CheckBoxWrapper>
                    </InputWrapper02>

                    <InputWrapper>
                        <SubTitle>모바일 이미지</SubTitle>

                        <SearchBoxWrapper>
                            <Input type="text" placeholder="이미지를 등록해주세요." readOnly disabled />
                            <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                            <Button onClick={onClickAddImage}>찾기</Button>
                        </SearchBoxWrapper>
                    </InputWrapper>

                    <ImageWrapper>
                        <ImageBox>
                            {imageLists?.map((src, index) => {
                                return (
                                    <ImageComponent key={index}>
                                        <CustomCloseOutlined onClick={onClickDeleteImage(index)} />

                                        <Image key={index} src={main_server_image_ip + src} alt="모임사진" onClick={onClickShowImage(main_server_image_ip + src)} />

                                        {index === 0 ? <MainPic>대표사진</MainPic> : null}
                                    </ImageComponent>
                                );
                            })}
                        </ImageBox>
                    </ImageWrapper>

                    <ButtonWeapper>
                        <button className="cencle" onClick={closeModal}>
                            취소
                        </button>
                        <button className="save" onClick={createGathering}>
                            저장
                        </button>
                    </ButtonWeapper>
                </CreateBox>
            </MobileBox>
        </div>
    );
}

export default AddGatheringModal;
