export const GET_ACTIVITY_PAYMENTS_DETAIL =
  "admin/GET_ACTIVITY_PAYMENTS_DETAIL";
export const GET_ACTIVITY_PAYMENTS_DETAIL_SUCCESS =
  "admin/GET_ACTIVITY_PAYMENTS_DETAIL_SUCCESS";
export const GET_ACTIVITY_PAYMENTS_DETAIL_FAILURE =
  "admin/GET_ACTIVITY_PAYMENTS_DETAIL_FAILURE";
export const PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO =
  "admin/PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO";
export const PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS =
  "admin/PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS";
export const PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE =
  "admin/PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE";
export const POST_ACTIVITY_PAYMENTS_DETAIL_REFUND =
  "admin/POST_ACTIVITY_PAYMENTS_DETAIL_REFUND";
export const POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_SUCCESS =
  "admin/POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_SUCCESS";
export const POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_FAILURE =
  "admin/POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_FAILURE";
