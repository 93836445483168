import produce from 'immer';
import { handleActions } from 'redux-actions';

import { getAdminStatus } from '../../../../communication_system/axios_apis/axios_apis';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// action_클릭률(상세유입경로)
export const GET_CLICK_PERCENTAGE_REQUEST = 'GET_CLICK_PERCENTAGE_REQUEST';
export const GET_CLICK_PERCENTAGE_SUCCESS = 'GET_CLICK_PERCENTAGE_SUCCESS';
export const GET_CLICK_PERCENTAGE_FAILURE = 'GET_CLICK_PERCENTAGE_FAILURE';
// 모임순위
export const GET_GATHERING_RANK_REQUEST = 'GET_GATHERING_RANK_REQUEST';
export const GET_GATHERING_RANK_SUCCESS = 'GET_GATHERING_RANK_SUCCESS';
export const GET_GATHERING_RANK_FAILURE = 'GET_GATHERING_RANK_FAILURE';
// 모임순위 총 카운트
export const GET_GATHERING_RANK_ALL_COUNT_REQUEST = 'GET_GATHERING_RANK_ALL_COUNT_REQUEST';
export const GET_GATHERING_RANK_ALL_COUNT_SUCCESS = 'GET_GATHERING_RANK_ALL_COUNT_SUCCESS';
export const GET_GATHERING_RANK_ALL_COUNT_FAILURE = 'GET_GATHERING_RANK_ALL_COUNT_FAILURE';
// 일정순위
export const GET_GATHERING_SCHEDULE_RANK_REQUEST = 'GET_GATHERING_SCHEDULE_RANK_REQUEST';
export const GET_GATHERING_SCHEDULE_RANK_SUCCESS = 'GET_GATHERING_SCHEDULE_RANK_SUCCESS';
export const GET_GATHERING_SCHEDULE_RANK_FAILURE = 'GET_GATHERING_SCHEDULE_RANK_FAILURE';
// 일정순위 총 카운트
export const GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_REQUEST = 'GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_REQUEST';
export const GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_SUCCESS = 'GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_SUCCESS';
export const GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_FAILURE = 'GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_FAILURE';
// 놀이순위
export const GET_ACTIVITY_RANKING_REQUEST = 'GET_ACTIVITY_LANKING_REQUEST';
export const GET_ACTIVITY_RANKING_SUCCESS = 'GET_ACTIVITY_LANKING_SUCCESS';
export const GET_ACTIVITY_RANKING_FAILURE = 'GET_ACTIVITY_LANKING_FAILURE';
// 상품순위
export const GET_GOODS_RANKING_REQUEST = 'GET_GOODS_LANKING_REQUEST';
export const GET_GOODS_RANKING_SUCCESS = 'GET_GOODS_LANKING_SUCCESS';
export const GET_GOODS_RANKING_FAILURE = 'GET_GOODS_LANKING_FAILURE';

// initial states
const initialState = {
    clickPercentageData: null, // 클릭률
    clickPercentageCnt: 0,
    activityRankingData: null, // 놀이순위
    goodsRankingData: null, // 상품순위
    gatheringRank: [], // 모임순위
    gatheringRankAllCount: null, // 모임순위 총 카운트
    gatheringScheduleRank: [], // 일정순위
    gatheringScheduleRankAllCount: null, // 일정순위 총 카운트
    // 모임순위
    loadGatheringRankLoading: false,
    loadGatheringRankDone: false,
    loadGatheringRankError: null,
    // 모임순위 총 카운트
    loadGatheringRankAllCountLoading: false,
    loadGatheringRankAllCountDone: false,
    loadGatheringRankAllCountError: null,
    // 일정순위
    loadGatheringScheduleRankLoading: false,
    loadGatheringScheduleRankDone: false,
    loadGatheringScheduleRankError: null,
    // 일정순위 총 카운트
    loadGatheringScheduleRankAllCountLoading: false,
    loadGatheringScheduleRankAllCountDone: false,
    loadGatheringScheduleRankAllCountError: null,
    // 클릭률 데이터
    loadClickDataLoading: false,
    loadClickDataDone: false,
    loadClickDataError: null,
    // 놀이순위 데이터
    loadActivityDataLoading: false,
    loadActivityDataDone: false,
    loadActivityDataError: null,
    // 상품순위 데이터
    loadGoodsRankingDataLoading: false,
    loadGoodsRankingDataDone: false,
    loadGoodsRankingDataError: null,
};

/******************************************
 * action 함수 생성_클릭률, 놀이순위, 상품순위
 ******************************************/
// 클릭률
export const fetchGetClickPercentage = (page, size, lineDateType, lineStartDate, lineEndDate) => async (dispatch) => {
    dispatch({
        type: GET_CLICK_PERCENTAGE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`adminStatus/click`, {
            params: {
                page,
                size,
                graphType: lineDateType,
                startDate: lineStartDate,
                endDate: lineEndDate,
            },
        });

        dispatch({
            type: GET_CLICK_PERCENTAGE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CLICK_PERCENTAGE_FAILURE,
            error: error,
        });
    }
};

// 모임순위
export const fetchGetGatheringRankData = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_RANK_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/rank/gathering`, { params: input });

        dispatch({
            type: GET_GATHERING_RANK_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_RANK_FAILURE,
            error: error,
        });
    }
};

// 모임순위 페이지 총 카운드
export const fetchGetGatheringRankAllCountData = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_RANK_ALL_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/rank/gathering/allCount`, { params: input });
        dispatch({
            type: GET_GATHERING_RANK_ALL_COUNT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_RANK_ALL_COUNT_FAILURE,
            error: error,
        });
    }
};

// 일정순위
export const fetchGetGatheringScheduleRankData = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_SCHEDULE_RANK_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/rank/gathering/schedule`, { params: input });
        dispatch({
            type: GET_GATHERING_SCHEDULE_RANK_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_SCHEDULE_RANK_FAILURE,
            error: error,
        });
    }
};

// 일정순위 페이지 총 카운드
export const fetchGetGatheringScheduleRankAllCountData = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/rank/gathering/schedule/allCount`, { params: input });
        dispatch({
            type: GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_FAILURE,
            error: error,
        });
    }
};

// 놀이순위
export const fetchGetActivityRankingData = (page, size) => async (dispatch) => {
    dispatch({
        type: GET_ACTIVITY_RANKING_REQUEST,
    });
    try {
        const response = await getAdminStatus('activityRank', page, size);

        dispatch({
            type: GET_ACTIVITY_RANKING_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ACTIVITY_RANKING_FAILURE,
            error: error,
        });
    }
};

// 상품순위
export const fetchGetGoodsRankingData = (page, size) => async (dispatch) => {
    dispatch({
        type: GET_GOODS_RANKING_REQUEST,
    });
    try {
        const response = await getAdminStatus('activityRank', page, size);

        dispatch({
            type: GET_GOODS_RANKING_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GOODS_RANKING_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const clickPercentageReducer = handleActions(
    {
        // 클릭률(상세유입경로)
        [GET_CLICK_PERCENTAGE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadClickDataLoading = true;
                draft.loadClickDataDone = false;
                draft.loadClickDataError = null;
            }),
        [GET_CLICK_PERCENTAGE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadClickDataLoading = false;
                draft.clickPercentageData = action.payload; // 클릭률 데이터 STATE
                draft.loadClickDataDone = true;
            }),
        [GET_CLICK_PERCENTAGE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadClickDataLoading = false;
                draft.loadClickDataError = action.error;
            }),

        // 모임순위 데이터
        [GET_GATHERING_RANK_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringRankLoading = true;
                draft.loadGatheringRankDone = false;
                draft.loadGatheringRankError = null;
            }),
        [GET_GATHERING_RANK_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringRankLoading = action.payload ? false : true;
                draft.gatheringRank = action.payload;
                draft.loadGatheringRankDone = action.payload ? true : false;
            }),
        [GET_GATHERING_RANK_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringRankLoading = false;
                draft.loadGatheringRankError = action.error;
            }),

        // 모임순위 총 카운트
        [GET_GATHERING_RANK_ALL_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringRankAllCountLoading = true;
                draft.loadGatheringRankAllCountDone = false;
                draft.loadGatheringRankAllCountError = null;
            }),
        [GET_GATHERING_RANK_ALL_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringRankAllCountLoading = action.payload ? false : true;
                draft.gatheringRankAllCount = action.payload;
                draft.loadGatheringRankAllCountDone = action.payload ? true : false;
            }),
        [GET_GATHERING_RANK_ALL_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringRankAllCountLoading = false;
                draft.loadGatheringRankAllCountError = action.error;
            }),

        // 일정순위 데이터
        [GET_GATHERING_SCHEDULE_RANK_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringScheduleRankLoading = true;
                draft.loadGatheringScheduleRankDone = false;
                draft.loadGatheringScheduleRankError = null;
            }),
        [GET_GATHERING_SCHEDULE_RANK_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringScheduleRankLoading = action.payload ? false : true;
                draft.gatheringScheduleRank = action.payload;
                draft.loadGatheringScheduleRankDone = action.payload ? true : false;
            }),
        [GET_GATHERING_SCHEDULE_RANK_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringScheduleRankLoading = false;
                draft.loadGatheringScheduleRankError = action.error;
            }),

        // 일정순위 총 카운터
        [GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringScheduleRankAllCountLoading = true;
                draft.loadGatheringScheduleRankAllCountDone = false;
                draft.loadGatheringScheduleRankAllCountError = null;
            }),
        [GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringScheduleRankAllCountLoading = action.payload ? false : true;
                draft.gatheringScheduleRankAllCount = action.payload;
                draft.loadGatheringScheduleRankAllCountDone = action.payload ? true : false;
            }),
        [GET_GATHERING_SCHEDULE_RANK_ALL_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGatheringScheduleRankAllCountLoading = false;
                draft.loadGatheringScheduleRankAllCountError = action.error;
            }),

        // 놀이순위 데이터
        [GET_ACTIVITY_RANKING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityDataLoading = true;
                draft.loadActivityDataDone = false;
                draft.loadActivityDataError = null;
            }),
        [GET_ACTIVITY_RANKING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityDataLoading = false;
                draft.activityRankingData = action.payload; // 놀이순위 데이터 STATE
                draft.loadActivityDataDone = true;
            }),
        [GET_ACTIVITY_RANKING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityDataLoading = false;
                draft.loadActivityDataError = action.error;
            }),

        // 상품순위 데이터
        [GET_GOODS_RANKING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGoodsRankingDataLoading = true;
                draft.loadGoodsRankingDataDone = false;
                draft.loadGoodsRankingDataError = null;
            }),
        [GET_GOODS_RANKING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGoodsRankingDataLoading = false;
                draft.goodsRankingData = action.payload; // 상품순위 데이터 STATE
                draft.loadGoodsRankingDataDone = true;
            }),
        [GET_GOODS_RANKING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadGoodsRankingDataLoading = false;
                draft.loadGoodsRankingDataError = action.error;
            }),
    },
    initialState,
);

export default clickPercentageReducer;
