import { ChartTitle } from "../../pages/customerData/customerData";
import PieChartComponents from "../CustomerDataComponents/PieChartComponents";
import React, { useEffect, useState } from "react";
import RefreshCheckApi from "../../communication_system/axios_apis/refresh_check/refresh_check_api";
import LoadingPage from "../../pages/loading/LoadingPage";

const UserDataPieChart = ({title, type, platform}) => {
	const [data, setData] = useState([])


	useEffect(()=> {
		getData().then()

	}, [ platform])
	const getData = async () => {
	    try {
			const response = await RefreshCheckApi.get(`/adminStatus/${type}/chart`, {
				params: {
					platform
				}
			})

			setData(response.data.chart_data)

		} catch(err){

		}
	}


	return (
		<div className="chart-area">
			<ChartTitle>{title}</ChartTitle>
			<PieChartComponents datas={data}/>
		</div>
	)
}


export default UserDataPieChart