/* eslint-disable import/no-anonymous-default-export */
import { useCallback, useState } from 'react';

// react hook form(https://react-hook-form.com/get-started)
export default (initialValue = null) => {
    const [value, setValue] = useState(initialValue);

    const handler = useCallback((e) => {
        setValue(e.target.value);
    }, []);

    return [value, handler, setValue];
};
