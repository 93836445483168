import axios from 'axios';
import { main_server_ip } from '../../communication_ips';
import { refresh_check, refresh_error_handle } from './refresh_check';

const RefreshCheckApi = axios.create({
    baseURL: main_server_ip,
    timeout: 50000,
    params: {},
});

RefreshCheckApi.interceptors.request.use(refresh_check, refresh_error_handle);

RefreshCheckApi.interceptors.response.use((response) => response);

export default RefreshCheckApi;
