import styled from 'styled-components';

export const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 98%;
  height: 100%;

  margin: 0 auto;
`;

export const Table = styled.table`
  border-collapse: collapse;

  width: 100%;

  #thead > tr > th {
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    padding: 17px 10px;

    font-size: 15px;
  }

  #tbody > tr {
    cursor: pointer;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  }

  #tbody > tr:hover {
    background-color: #e4e4e4;
  }

  #tbody > tr > td {
    padding: 16px 10px;

    font-size: 14px;
    text-align: center;
  }
`;

export const CheckBox = styled.input`
  cursor: pointer;

  width: 15px;
  height: 15px;
`;

export const On = styled.div`
  width: 27px;
  height: 17px;
  line-height: 19px;

  padding: 0 !important;

  font-family: Noto Sans KR;
  color: #428bf7;

  background-color: #428bf714;
  border: 1px solid #428bf7;
`;

export const Off = styled.div`
  width: 34px;
  height: 19px;
  line-height: 19px;

  padding: 0;

  font-family: Noto Sans KR;
  color: #ff107d;

  background-color: #ffe3f0;
  border: 1px solid #ffb7d8;
`;
