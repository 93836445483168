export const exampleJson = {
    completeTime: '2024-03-15T14:00:00', // 전송 완료 시간
    content: '테스트(test)\n안녕하세요 시놀입니다', // 내용
    countryCode: '82', // 국가 코드
    messageId: 'a7793b66-58f1-4499-ad37-c6132d9e6513', // 메세지 ID
    messageStatusCode: '0000', // 메세지 상태 코드(not important)
    messageStatusDesc: '정상 발송', // 메세지 결과
    messageStatusName: 'success', // 메세지 결과(Eng)
    plusFriendId: '@시놀', // 카카오톡 채널명
    requestStatusCode: 'A000', // 요청 결과(예약 메세지 할 때)
    requestStatusDesc: '성공', // 요청 결과
    requestStatusName: 'success', // 요청 결과(Eng)
    requestTime: '2024-03-15T14:00:00.006', // 요청 시간
    templateCode: 'test', // 템플릿 코드 이름
    to: '01049229038', // 전송 전화번호
    useSmsFailover: false, // 전송 실패 여부(아직 쓰지말것)
};

// 알림톡 내역
export const alarmTalkFilter = [
    { key: 'date', value: '날짜' },
    {
        key: 'messageId',
        value: '메세지 ID',
    },
    {
        key: 'templateCode',
        value: '템플릿 코드',
    },
    {
        key: 'to',
        value: '전화번호',
    },

    {
        key: 'content',
        value: '내용',
    },
    {
        key: 'plusFriendId',
        value: '채널명',
    },
    {
        key: 'requestTime',
        value: '요청 시간',
    },
    {
        key: 'requestStatusDesc',
        value: '요청 결과',
    },
    {
        key: 'completeTime',
        value: '완료 시간',
    },
    {
        key: 'messageStatusCode',
        value: '발송 결과',
    },
];

// 알람톡 예약 내역
export const reservealarmFilter = [
    {
        key: 'request_id',
        value: '요청 ID',
    },
    {
        key: 'reserve_date',
        value: '예약 시간',
    },
    {
        key: 'status',
        value: '전송 상태',
    },
    {
        key: 'template_code',
        value: '템플릿 유형',
    },
    {
        key: 'created_at',
        value: '생성일',
    },
    {
        key: 'updated_at',
        value: '수정일',
    },
];

// 알림톡 템플릿
export const alarmTalkTemplateFilter = [
    {
        key: 'created_at',
        value: '생성일',
    },
    {
        key: 'channelId',
        value: '채널명',
    },
    {
        key: 'templateName',
        value: '템플릿명',
    },
    {
        key: 'templateStatus',
        value: '템플릿 상태',
        filter: [
            {
                key: 'READY',
                value: '승인건',
            },
            {
                key: 'ACTIVE',
                value: '거절건',
            },
        ],
    },
    {
        key: 'content',
        value: '내용',
    },
];
