import React, { useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';
import { ImageModalWrapper, CustomCloseOutlined, ModalImage } from '../styles/GatheringDetailComponentStyled';

// react-query && axios
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// axios
import axios from 'axios';

// BANK_CODE
import { BANK_CODE } from '../SubscribePayment/payBankCodeService';

// useInput
import useInput from '../../hook/useInput';

// regacy-components
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

// components
import LoadingPage from '../../pages/loading/LoadingPage';
import UserModal from '../UserComponents/UserModal';
import UserDetailModal from '../UserComponents/UserDetailModal';
// import CreateVirtualAccount from '../../components/SubscribePayment/CreateVirtualAccount';

const ComUtil = require('../../utils/comUtil');

/** URL */
let testLoginUrl = 'https://senor.co.kr/auth/testLogin';
let userPayInfoRefundCheckUrl = 'https://senor.co.kr/userPayInfo/refund/check';

if (!ComUtil.IsProduction()) {
    // testLoginUrl = 'https://test.senor.co.kr/userPayInfo/refund/check';
    // userPayInfoRefundCheckUrl = 'https://test.senor.co.kr/userPayInfo/refund/check';
}

/**  모달 */
function PayInfoDetailModal({ payInfoDetailToggle, setToggle, type }) {
    const [userDetailToggle, setUserDetailToggle] = useState({});

    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');

    // state
    const [accessToken, setAccessToken] = useState('');
    const [sendMsgLoading, setSendMsgLoading] = useState(false);

    const [bank, setBank] = useState('039'); // 은행
    const [accountHolder, onChangeAccountHolder] = useInput(''); // 예금주
    const [accountNumber, onChangeAccountNumber] = useInput(''); // 계좌번호
    const [refundAccount, onChangeRefundAccount] = useInput(''); // 환불 예정 금액

    // useRef
    const currentTarget = useRef();

    // react-query
    // eslint-disable-next-line no-unused-vars
    const { isLoading, error, data, refetch } = useQuery(['payDetail'], async () => {
        return await RefreshCheckApi.get(`/adminSyrup/payments/detail?pay_id=${payInfoDetailToggle[1]}`).then((res) => {
            return res.data.data[0];
        });
    });

    // 클릭 감지
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    /** 이미지 모달 외부 클릭 시 Modal창 닫힘 */
    const handleClickOutside = (event) => {
        if (!currentTarget.current) {
            return;
        } else {
            if (currentTarget && !currentTarget.current.contains(event.target)) {
                setImageToggle((prev) => !prev);
            }
        }
    };

    // 인증토큰 발급 받기
    const onClickGetToken = () => {
        const response = axios.post(testLoginUrl, {
            id: data.user_id,
        });
        response.then((res) => {
            let token = res.data.data.access_token;
            setAccessToken(token);
        });
    };

    // useEffect(() => {
    //     const response = axios.post(testLoginUrl, {
    //         id: data?.user_id,
    //     });
    //     response.then((res) => {
    //         let token = res.data.data.access_token;
    //         setAccessToken(token);
    //     });
    // }, [data]);

    // 환불신청하기
    const onClickRefund = () => {
        const req = axios.post(testLoginUrl, {
            id: data.user_id,
        });
        req.then((res) => {
            let userToken = res.data.data.access_token;
            let param = {
                pay_id: payInfoDetailToggle[1],
                apply_yn: true,
                refund_remark: '관리자 취소',
            };
            if (!userToken) return alert('인증토큰 발급 후 환불요청이 가능합니다.');
            if (!data.bank_name) return alert('은행정보가 존재하지 않습니다.');
            if (data.pay_type_number === 0 || data.pay_type_number === -1) {
                const response = axios.post(userPayInfoRefundCheckUrl, param, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                response.then((res) => {});
            } else if (data.pay_type_number === 1 || data.pay_type_number === 4) {
                param = {
                    pay_id: payInfoDetailToggle[1],
                    apply_yn: true,
                    refund_remark: '관리자 취소',
                    account_info: {
                        account_num: accountNumber, // 계좌번호
                        name: accountHolder, // 예금주
                        bank_num: String(bank), // 은행
                    },
                };
                const response = axios.post(userPayInfoRefundCheckUrl, param, {
                    headers: { Authorization: `Bearer ${userToken}` },
                });
                response.then((res) => {
                    if (res.data.ok) alert('환불신청이 완료되었습니다.');
                });
            }
        }).catch((error) => {
            alert('[Error] 관리자에게 문의 바랍니다.');
        });
    };

    // 은행선택
    const onChangeBank = (e) => {
        setBank(parseInt(e.target.value));
    };

    // 회원 상세정보 모달
    const onClickUserModal = (user_id) => () => {
        let _user_id = parseInt(user_id);
        if (_user_id) {
            setUserDetailToggle({ toggle: true, userId: _user_id });
        }
    };

    /** 가상계좌 문자전송 */
    const onClickSendMsg = async () => {
        if (!data.amount) {
            return alert('실 결제 금액을 확인해 주세요.');
        } else if (!data?.virtualAccountNumber?.account_no) {
            return alert('계좌번호가 존재하지 않습니다.');
        } else if (!data?.virtualAccountNumber?.bank_name) {
            return alert('은행명이 존재하지 않습니다.');
        }
        setSendMsgLoading(true);
        const body = {
            phone_no_list: [data.user_phone_no],
            message_title: `[시놀] 결제를 위한 가상계좌 정보`,
            message_content: `은행명: ${data?.virtualAccountNumber?.bank_name}\n계좌번호: ${data?.virtualAccountNumber?.account_no}\n금액: ${data.amount?.toLocaleString('ko-KR')}\n입급기한: ${moment(
                data?.virtualAccountNumber?.expire_date,
            ).format('YYYY.MM.DD HH:mm')}`,
        };
        try {
            const response = await RefreshCheckApi.post('/adminSms', body);
            if (response.status !== 200) {
                alert('[서버 관리자 문의]메세지 전송하는 과정에서 Error가 발생했습니다.');
                setSendMsgLoading(false);
            }
            if (response.status === 200) {
                if (response.data.ok) {
                    alert(`[${response.data.ok}] 메세지 발송에 성공했습니다.`);
                }
            }
            setSendMsgLoading(false);
        } catch (error) {}
    };

    /** 창 닫기 */
    const onClickClose = () => {
        setImageToggle(false);
    };

    const refundDateFilter = (date) => {
        if (date) return moment(date).format('YYYY-MM-DD HH:mm:ss');
        else return <span style={{ color: 'gray', fontWeight: 'bold' }}>내용없음</span>;
    };

    let accountExpire_date = data?.virtualAccountNumber?.expire_date;
    return (
        <DetailModalWrapper id="modal-window">
            {/*{userDetailToggle.toggle && <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />}*/}
            {imageToggle && (
                <ImageModalWrapper>
                    <CustomCloseOutlined onClick={onClickClose} />
                    <ModalImage src={src} alt="크게보기 이미지" ref={currentTarget} />
                </ImageModalWrapper>
            )}

            <DetailModalBox id="modal-box">
                <Header id="top-line">
                    <Text>결제 상세 정보</Text>
                    <Text style={{ cursor: 'pointer' }} onClick={() => setToggle([false, payInfoDetailToggle[1]])}>
                        닫기
                    </Text>
                </Header>

                {isLoading ? (
                    <LoadingPage text={'결제정보를 불러오는 중입니다.'} />
                ) : (
                    <>
                        <UserNameBox onClick={onClickUserModal(data.user_id)}>
                            <UserName>{data.name}</UserName>
                            <MptyBox />
                        </UserNameBox>

                        <ModalBoxBody>
                            <label id="label">회원 정보</label>
                            <InfoBox className="subscribe-info">
                                <InfoTitle>결제정보</InfoTitle>

                                <ColWrapper>
                                    <Subtitle>결제상태</Subtitle>
                                    <SubInfo>{data.pay_status}</SubInfo>
                                </ColWrapper>

                                <label id="label">가상 계좌</label>
                                {data.pay_type !== '신용 카드' && (
                                    <PaymentSubInfoWrapper className="pay_status">
                                        <Subtitle>가상계좌</Subtitle>
                                        <SubInfo>
                                            {data?.virtualAccountNumber ? (
                                                <div className="virtual-account-info">
                                                    <span>{data?.virtualAccountNumber?.bank_name}</span>
                                                    <span style={{ margin: '0 5px' }}>/</span>
                                                    <span style={{ marginRight: '5px' }}>{data?.virtualAccountNumber?.account_no}</span>

                                                    {sendMsgLoading ? <SendMsgButton>전송중...</SendMsgButton> : <SendMsgButton onClick={onClickSendMsg}>문자발송</SendMsgButton>}
                                                </div>
                                            ) : (
                                                <span style={{ color: 'gray' }}>가상계좌 기간이 만료되었거나 정보가 존재하지 않습니다.</span>
                                            )}
                                        </SubInfo>
                                    </PaymentSubInfoWrapper>
                                )}

                                <label id="label">가상 계좌 만료 기간</label>
                                {data.pay_type !== '신용 카드' && data?.virtualAccountNumber && (
                                    <PaymentSubInfoWrapper className="pay_status" style={{ marginBottom: '20px' }}>
                                        <Subtitle>가상계좌 만료기간</Subtitle>
                                        <SubInfo>
                                            <div className="virtual-account-info">
                                                <span>{moment(accountExpire_date).format('YYYY년 MM월 DD일 HH:mm:ss')}</span>
                                                <span style={{ marginLeft: '3px' }}>까지</span>
                                            </div>
                                        </SubInfo>
                                    </PaymentSubInfoWrapper>
                                )}

                                <ColWrapper>
                                    <Subtitle>결제 일시</Subtitle>
                                    <SubInfo>{moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>결제 수단</Subtitle>
                                    <SubInfo>{data.pay_type}</SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>결제 상품</Subtitle>
                                    {type === '모임' ? (
                                        <SubInfo>
                                            <span style={{ fontWeight: 'bold' }}>{data.pay_name}</span>
                                            <span style={{ margin: '0 5px' }}>/</span>
                                            {data?.gatheringInfo?.gathering_name}
                                            <span style={{ margin: '0 5px' }}>{'>'}</span>
                                            {data?.gatheringInfo?.gathering_contents_title}
                                        </SubInfo>
                                    ) : (
                                        <SubInfo>
                                            <span style={{ fontWeight: 'bold' }}>{data.pay_name}</span>
                                            <span style={{ margin: '0 5px' }}>/</span>
                                            <span>{data?.syrupInfo?.sinor_love}개</span>
                                        </SubInfo>
                                    )}
                                </ColWrapper>
                                <ColWrapper>
                                    <Subtitle>포인트 사용</Subtitle>
                                    <SubInfo>{data.use_point_amount || 0}P</SubInfo>
                                </ColWrapper>
                                <ColWrapper>
                                    <Subtitle>쿠폰 사용</Subtitle>
                                    <SubInfo>{data.coupon}</SubInfo>
                                </ColWrapper>
                                <ColWrapper>
                                    <Subtitle>실 결제 금액</Subtitle>
                                    <SubInfo>{data.amount?.toLocaleString('ko-KR') || 0}원</SubInfo>
                                </ColWrapper>
                                <ColWrapper>
                                    <Subtitle>포인트 적립</Subtitle>
                                    <SubInfo>{data.point_accumulate || 0}P</SubInfo>
                                </ColWrapper>
                            </InfoBox>

                            <label id="label">시럽 충전</label>
                            <InfoBox className="subscribe-info">
                                <InfoTitle>환불 정보</InfoTitle>

                                <ColWrapper>
                                    <Subtitle>환불 요청일시</Subtitle>
                                    <SubInfo>{refundDateFilter(data.refund_created_at)}</SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>환불 예정금액</Subtitle>

                                    {accessToken && (data.pay_type_number === 1 || data.pay_type_number === 4) ? (
                                        <Input type="number" placeholder="환불 금액을 입력하세요" value={refundAccount} onChange={onChangeRefundAccount} />
                                    ) : (
                                        <SubInfo>{data.refund_amount ? data.refund_amount?.toLocaleString('ko-KR') : 0}원</SubInfo>
                                    )}
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>환불 은행</Subtitle>
                                    <SubInfo>
                                        {accessToken && (data.pay_type_number === 1 || data.pay_type_number === 4) ? (
                                            <Select onChange={onChangeBank}>
                                                {BANK_CODE.map((obj, index) => (
                                                    <option key={index} value={obj.CODE1}>
                                                        {obj.KOR}
                                                    </option>
                                                ))}
                                            </Select>
                                        ) : (
                                            <>{data.bank_name || <span style={{ color: 'gray', fontWeight: 'bold' }}>내용없음</span>}</>
                                        )}
                                    </SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>환불 계좌번호</Subtitle>
                                    <SubInfo>
                                        {accessToken && (data.pay_type_number === 1 || data.pay_type_number === 4) ? (
                                            <Input type="number" placeholder='계좌번호를 "-" 없이 입력' onChange={onChangeAccountNumber} />
                                        ) : (
                                            <>{data.account_no || <span style={{ color: 'gray', fontWeight: 'bold' }}>내용없음</span>}</>
                                        )}
                                    </SubInfo>
                                </ColWrapper>

                                <ColWrapper>
                                    <Subtitle>환불 예금주</Subtitle>
                                    <SubInfo>
                                        {accessToken && (data.pay_type_number === 1 || data.pay_type_number === 4) ? (
                                            <Input type="text" placeholder="예금주명" onChange={onChangeAccountHolder} />
                                        ) : (
                                            <>{data.refund_user_name || <span style={{ color: 'gray', fontWeight: 'bold' }}>내용없음</span>}</>
                                        )}
                                    </SubInfo>
                                </ColWrapper>

                                {data?.pay_status_number === 2 && (
                                    <ColWrapper>
                                        <Subtitle>인증토큰 발급</Subtitle>
                                        <SubInfo>
                                            <Button onClick={onClickGetToken} disabled={Boolean(accessToken)} token={Boolean(accessToken)}>
                                                {accessToken ? '토큰발급완료' : '토큰발급'}
                                            </Button>
                                        </SubInfo>
                                    </ColWrapper>
                                )}
                            </InfoBox>
                        </ModalBoxBody>
                    </>
                )}

                {/* {data?.pay_status_number === 4 && ( */}
                {true && (
                    <ButtonWeapper>
                        <button className="refund" onClick={onClickRefund}>
                            {data?.pay_status_number === 3 ? '환불처리' : '환불신청'}
                        </button>
                    </ButtonWeapper>
                )}
            </DetailModalBox>
        </DetailModalWrapper>
    );
}

export default PayInfoDetailModal;

/************************************** styled **************************************/

const SendMsgButton = styled.button`
    cursor: pointer;

    height: 22px;
    padding: 0 12px;

    background-color: #428bf7;
    border-radius: 3px;
    color: #fff;
    border: 0;
    margin-bottom: 5px;

    &:hover {
        background-color: #196dea;
    }
`;

const DetailModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: #000000b0;
`;

const DetailModalBox = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: start;
    z-index: 2;

    width: 100vh;
    height: 75%;
    overflow: auto;

    border-radius: 10px;
    background-color: #fff;

    @media (max-width: 912px) {
        width: 98%;
        height: 82.5%;
        overflow: auto;
    }

    .close-window {
        padding: 5px 25px;
        text-align: start;
        font-size: 20px;
        color: #428bf7;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        padding: 15px;
        color: gray;
    }
`;

const Header = styled.div`
    z-index: 999;
    display: flex;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    height: 5vh;

    align-items: center;

    @media (max-width: 1120px) {
        width: 100%;
    }
`;

const Text = styled.div`
    padding: 0 15px;

    color: #fff;
    font-size: 16px;
    font-weight: 600;

    line-height: 45px;
`;

const ModalBoxBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding: 0px 30px;

    #label {
        display: none;
    }

    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    width: 50%;
    height: 59vh;

    border-left: ${(props) => (props.leftLine ? '1px solid gray' : '')};
    padding-left: ${(props) => (props.leftLine ? '2%' : '')};

    @media (max-width: 912px) {
        width: 100%;
    }
`;

const InfoTitle = styled.h2`
    margin: 15px 0 30px 0;

    font-size: 17px;
    font-weight: 600;
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 3%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 120px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;
`;

const UserNameBox = styled.div`
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;

    height: 45px;
    margin-left: 3%;

    @media (max-width: 912px) {
        display: block;
        width: 90px;
    }
`;

const MptyBox = styled.div`
    width: 335px;

    @media (max-width: 912px) {
        display: none;
    }
`;

const UserName = styled.div`
    border: 1px solid rgba(66, 139, 247, 0.2);
    /* margin-left: 10px; */
    width: 977px;

    font-family: noto_sans_kr_medium;
    font-size: 16px;
    font-weight: 600;

    text-align: start;

    line-height: 3.2vh;

    width: 120px;

    padding: 0px 5px;
    margin-top: 15px;

    @media (max-width: 912px) {
        padding: 0 5px;

        width: 115px;
    }
`;

const PaymentSubInfoWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: baseline;

    width: 500px;
    font-size: 13px;

    margin-bottom: 2%;

    @media (max-width: 912px) {
        width: 310px;
    }

    table {
        width: 100%;

        border-collapse: collapse;
    }

    .header {
        background-color: rgb(66 139 247);
        font-weight: 500;
        color: #fff;
    }

    td {
        width: 100px;
        border: 1px solid rgb(184 184 184);
        padding: 2px 4px;
    }
`;

const ButtonWeapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;

    background-color: #fff;
    padding: 1% 1%;

    @media (max-width: 912px) {
        position: fixed;
        bottom: 8.8%;

        margin: 0 4%;
        padding: 4% 1%;
    }

    button {
        cursor: pointer;
        border: 0;
        border-radius: 3px;
        background-color: #fff;

        width: 120px;
        height: 35px;
    }

    .refund {
        border: 1px solid rgb(255, 16, 125);
        background-color: rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(226, 0, 103);
        }
    }
`;

const Button = styled.button`
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    background-color: #fff;
    color: #fff;

    border: ${(props) => (props.token ? '#cccccc' : '1px solid rgb(255, 16, 125)')};
    background-color: ${(props) => (props.token ? '#cccccc' : 'rgb(255, 16, 125)')};

    width: 100px;
    height: 25px;

    :hover {
        background-color: ${(props) => (props.token ? '#cccccc' : 'rgb(255, 16, 125)')};
    }
`;

const Select = styled.select`
    cursor: pointer;
    width: 120px;
    height: 23px;

    outline: none;
    border: 1px solid rgb(202 202 202);

    font-size: 13.5px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;
