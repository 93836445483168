import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';

export const RowLine = styled.div`
  background-color: rgba(66, 139, 247, 0.2);

  width: 100%;
  height: 0.5px;
`;

export const DetailModalBox = styled.div`
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 30px 0px;

  width: 40%;
`;

export const CloseBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;

  padding: 0 7px;
  height: ${(props) => props.headerHeight - 10}px;

  font-size: 17px;
  border-top: 1px solid rgb(66, 139, 247);

  background-color: rgb(66, 139, 247);
  color: #fff;
`;

export const DetailInfoWrapper = styled.div`
  overflow: auto;

  padding: 10px;

  height: ${(props) => props.height - 10}px;
`;

export const CustomLeftOutlined = styled(LeftOutlined)`
  cursor: pointer;
  padding-bottom: 3px;

  font-size: 20px;

  :hover {
    color: black;
  }
`;

export const TitleInput = styled.input`
  outline: none;

  padding: 0 7px;

  height: 30px;
  width: 75%;

  border: 1px solid rgba(66, 139, 247, 0.2);
  box-sizing: border-box;

  font-size: 15px;
  font-family: noto_sans_kr_bold;
  color: rgb(0, 0, 0);
`;

export const Title = styled.h4`
  font-size: 17px;
`;

export const ImageInfoWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 30px;

  margin: 13px 0;

  font-size: 14px;
  font-weight: 400;
`;

export const AddImageBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 3px;
`;

export const AddImageBtn = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 0;
  background-color: rgb(66, 139, 247);

  font-size: 13px;
  color: #fff;

  width: 78px;
  height: 78px;

  :hover {
    background-color: rgb(45 118 227);
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;

  margin: 10px 0;

  font-size: 14px;
  font-weight: 400;
`;

export const SubTitle = styled.div`
  width: 80px;

  font-size: 13px;
  color: rgb(121, 121, 121);
`;

export const Image = styled.img`
  object-fit: cover;

  width: 78px;
  height: 78px;

  border: 1px solid rgb(229, 229, 229);
`;

export const ScheduleInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  margin: 10px 0;

  font-size: 14px;
  font-weight: 400;
`;

export const AddButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  padding: 4px 3px;

  color: #fff;
  background-color: rgb(66, 139, 247);

  :hover {
    background-color: rgb(32 105 213);
  }
`;

export const AddButton = styled.div`
  padding: 1px 5px;

  font-size: 9px;
`;

export const ButtonWeapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  button {
    cursor: pointer;
    border: 0;
    background-color: #fff;

    width: 120px;
    height: 35px;
  }

  .cencle {
    border: 1px solid rgb(229, 229, 229);
    color: black;

    :hover {
      background-color: rgb(242 242 242);
    }
  }

  .save {
    border: 1px solid rgb(255, 16, 125);
    background-color: rgb(255, 16, 125);
    color: #fff;

    :hover {
      background-color: rgb(198 3 92);
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 25px;

  .check-box {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
  }
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  cursor: pointer;

  width: 15px;
  height: 15px;

  border: 1px solid rgba(66 139 247 0.2);
  border-radius: 50%;

  margin: 0px;
  outline: none;
  &:before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60%;
    height: 60%;

    margin: 20% auto;
    border-radius: 50%;
  }
  &:checked:before {
    background: rgb(66 139 247);
  }
`;

export const InputForValue = styled.input`
  outline: none;

  height: 25px;
  width: 70%;

  margin-right: 5px;
  padding: 0 3px;

  border: 1px solid rgba(66, 139, 247, 0.2);
  box-sizing: border-box;

  font-size: 11px;
  font-family: noto_sans_kr_medium;
  color: rgb(0, 0, 0);
`;

export const AddressInput = styled.input`
  outline: none;

  height: 25px;
  width: 70%;

  padding: 0 3px;

  border: 1px solid rgba(66, 139, 247, 0.2);
  box-sizing: border-box;

  font-size: 12px;
  font-family: noto_sans_kr_medium;
  color: rgb(0, 0, 0);
`;

export const Input = styled.input`
  outline: none;

  height: 25px;
  width: 100%;

  font-size: 12px;
  font-family: noto_sans_kr_medium;

  padding: 0 3px;

  border: 1px solid rgba(66, 139, 247, 0.2);
  box-sizing: border-box;
`;

export const Textarea = styled.textarea`
  width: 175px;
  height: 170px;

  overflow: auto;
  outline: none;

  font-size: 12px;
  font-family: noto_sans_kr_medium;
  color: rgb(0, 0, 0);

  padding-top: 5px;
  padding-left: 3px;

  border: 1px solid rgba(66, 139, 247, 0.2);
  box-sizing: border-box;

  resize: none;
`;

export const TextAndBtnWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 3px;

  span {
    font-size: 11px;
  }
`;

export const CheckBox = styled.input`
  cursor: pointer;
  margin: 0 5px 0 1px;
`;

export const TextDirectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
`;

export const SearchAddressBrn = styled.button`
  cursor: pointer;
  background-color: rgb(66, 139, 247);

  width: 50px;
  height: 24.7px;

  border-width: 0px;
  box-sizing: border-box;

  padding: 0px;
  margin: 0px;

  font-size: 9px;
  font-family: noto_sans_kr_bold;
  color: rgb(255, 255, 255);

  :hover {
    background-color: rgb(32 105 213);
  }
`;
