import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MainTable from '../../designComponents/tables/MainTable';
import { alarmTalkFilter } from '../../dataSystems/PageFilters/AlarmTalkFilter';
import { colorFilter, contentFilter, dateFilter } from '../../utils/textFilter';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { MainButton } from '../../designComponents/buttons/Buttons';
import CheckboxInput from '../../designComponents/checkboxs/CheckboxInput';
import { MText } from '../../designComponents/text/ContentText';
import AlarmTalkDetailModal from './AlarmTalkDetailModal';

const AlarmTalkHistory = () => {
    const [param, setParam] = useState({ page: 1, size: 10 });

    const ref = useRef(null);

    const [searchType, setSearchType] = useState(1);
    const [searchWord, setSearchWord] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [showMessageId, setShowMessageId] = useState(false);
    const [showTempleteCode, setShowTemplateCode] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showDetailModal, setShowDetailModal] = useState(false);
    useEffect(() => {
        if (!showDetailModal) {
            setSelectedItem(null);
        }
    }, [showDetailModal]);
    const callApi = async (page, param) => {
        try {
            const input = { page: page, size: 10, searchType: searchType, searchWord: searchWord, ...param };
            const response = await RefreshCheckApi.get('/adminAlarmTalk/history', { params: input });
            setHasMore(response.data.data.hasMore);
            return response.data.data.list || [];
        } catch (error) {
            console.error(error);
        }
    };

    const pageCountCallApi = async (page, param) => {
        try {
            const input = { page: page, size: 10, ...param };
            const response = await RefreshCheckApi.get('/adminAlarmTalk/history', { params: input });
            return response.data.data?.all_cnt;
        } catch (error) {
            console.error(error);
        }
    };
    const nextPage = () => {
        setParam((pre) => ({ ...pre, page: pre.page + 1 }));
    };
    const prePage = () => {
        setParam((pre) => ({ ...pre, page: pre.page - 1 }));
    };
    return (
        <div>
            <AlarmTalkDetailModal visible={showDetailModal} setVisible={setShowDetailModal} item={selectedItem} />
            <SearchbarWithDropdown
                style={{ margin: '45px 0 5px 0' }}
                list={[{ id: 1, title: '휴대폰번호', value: 1 }]}
                resetThenSet={useCallback((item) => setSearchType(item.value), [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchWord}
                onChangeInput={(e) => {
                    setSearchWord(e.target.value);
                }}
            />
            <FlexMainWrapper style={{ gap: 6, marginBlock: 8 }}>
                <CheckboxInput exposure checked={showMessageId} onChange={() => setShowMessageId((pre) => !pre)} text={'메시지 ID 보기'} />
                <CheckboxInput exposure checked={showTempleteCode} onChange={() => setShowTemplateCode((pre) => !pre)} text={'템플릿 코드 보기'} />
            </FlexMainWrapper>
            <MainTable
                ref={ref}
                loadingText={'알람톡 내역을 불러오고 있습니다.'}
                headerData={alarmTalkFilter.filter((e) => (showMessageId ? true : e.key !== 'messageId')).filter((e) => (showTempleteCode ? true : e.key !== 'templateCode'))}
                checkID={'messageId'}
                param={param}
                setParam={setParam}
                toggleBlur={true}
                checkAble={false}
                callApi={callApi}
                onClickRowItem={(item) => () => {
                    console.log(item);
                    setSelectedItem(item);
                    setShowDetailModal(true);
                }}
                pageCountCallApi={pageCountCallApi}
                tbodyRenderItem={(key, value, item) => {
                    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;
                    if (key === 'date') return dateFilter(item['completeTime'], 'YYYY-MM-DD');
                    if (key === 'completeTime') return dateFilter(value, 'HH:mm:ss');
                    if (key === 'requestTime') return dateFilter(value, 'HH:mm:ss');
                    if (key === 'plusFriendId') return <span style={{ fontWeight: 600 }}>{value}</span>;
                    if (key === 'messageStatusCode') return colorFilter(value === '0000', value === '0000' ? '성공' : '실패');
                    if (key === 'requestStatusDesc') return colorFilter(value === '성공', value);
                    if (key === 'content') return contentFilter(value, 25);
                    if (key === 'status') return colorFilter(value === 1, value === 1 ? '전송 성공' : '전송 대기');
                    return value || nullTag;
                }}
            />
            <FlexMainWrapper style={{ gap: 8, marginBlock: 16 }}>
                {param.page > 1 && <MainButton text={'이전'} style={{ flex: 1 }} onClick={prePage} />}
                {hasMore && <MainButton styleReverse text={'다음'} style={{ flex: 1 }} onClick={nextPage} />}
            </FlexMainWrapper>
            <FlexMainWrapper style={{ justifyContent: 'center' }}>
                <MText text={`page : ${param.page}`} />
            </FlexMainWrapper>
        </div>
    );
};

export default AlarmTalkHistory;
