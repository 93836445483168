import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { ModalWrapper, ModalBox, Header, Text, Body } from '../Common/Styles/CommonModalStyled';

// redux
import { useSelector } from 'react-redux';
import { getSendMessageLists } from '../../data_system/redux/modules/sms.reducer/sms.reducer';
import { useDispatch } from 'react-redux';
import LoadingPage from '../../pages/loading/LoadingPage';

// 메세지 전송리스트 확인 모달 창
function ShowSendMessageListsModal({ setToggle }) {
    const dispatch = useDispatch();
    // useSelector
    const { sendMessageLists } = useSelector((state) => state.newSmsReducer);

    // state
    const [state, setState] = useState();

    /********************************************** useEffect **********************************************/

    // 데이터 요청
    useEffect(() => {
        dispatch(getSendMessageLists());
    }, [dispatch]);

    /********************************************** function **********************************************/

    // 모달창 닫기
    const onClickClose = () => {
        setToggle(false);
    };

    /************************************************ html ************************************************/
    return (
        <ModalWrapper>
            <ModalBox>
                <Header>
                    <Text>메세지 전송 내역</Text>
                    <Text onClick={onClickClose}>닫기</Text>
                </Header>

                <Body>
                    <LoadingPage text={'문자 전송 내역을 불러오는 중입니다.'} />
                </Body>
            </ModalBox>
        </ModalWrapper>
    );
}
export default ShowSendMessageListsModal;

/******************************************** styled-components ********************************************/

// TODO
