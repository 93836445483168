import React from 'react';
import styled from 'styled-components';

export const TextArea = ({ style, value, onChange, placeholder, disabled = false }) => {
    return <TextAreaSt style={{ ...style }} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />;
};

const TextAreaSt = styled.textarea`
    width: 96%;
    height: 20vh;
    border: 1px solid #428bf742;
    font-size: 14px;
    font-family: noto_sans_kr_medium;
    padding-top: 6px;
    padding-left: 8px;
    box-sizing: border-box;
    resize: none;
    &:focus {
        outline: none;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0 0 0 0 / d1);
        border-radius: 10px;
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
`;
