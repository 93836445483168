import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Image } from '../imageboxs/Image';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { UserModalImageXButton } from '../buttons/Buttons';

const HorizontalImageLists = ({ images, originData, setData, onChange = false }) => {
    /** 이미지 이동 */
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    /** 이미지 결과 출력 */
    const onDragEnd = (result) => {
        if (!result.destination) return;
        let arr = [];
        let response = reorder(images, result.source.index, result.destination.index);
        response.forEach((item) => {
            arr.push(item.content);
        });

        if (onChange) {
            let copyData = JSON.parse(JSON.stringify(originData));
            copyData.body.image_lists = arr;
            onChange(copyData);
        } else {
            let copyData = { ...originData };
            copyData.profile_list = arr;
            setData(copyData);
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, _) => (
                    <div ref={provided.innerRef} style={{ display: 'flex', gap: '5px', overflow: 'auto', width: '50.5vh', flexWrap: 'wrap' }} {...provided.droppableProps}>
                        {images?.map((item, index) => (
                            <Draggable key={index} draggableId={item.id} index={index}>
                                {(provided, _) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <FlexMainWrapper style={{ position: 'relative' }}>
                                            <UserModalImageXButton
                                                onClickDelete={() => {
                                                    if (window.confirm('선택하신 이미지를 삭제하시겠습니까?\n한장의 경우 기본이미지로 대체 됩니다.')) {
                                                        const result = Array.from(images);
                                                        const findIndex = result.findIndex((v) => v.id === item.id);
                                                        if (result.length === 1) {
                                                            if (findIndex > -1) result.splice(findIndex, 1);
                                                            if (onChange) {
                                                                let copyData = JSON.parse(JSON.stringify(originData));
                                                                copyData.body.image_lists = ['userPhoto/default.jpg'];
                                                                onChange(copyData);
                                                            } else {
                                                                let copyData = { ...originData };
                                                                copyData.profile_list = ['userPhoto/default.jpg'];
                                                                setData(copyData);
                                                            }
                                                        } else {
                                                            if (findIndex > -1) result.splice(findIndex, 1);
                                                            let arr = [];
                                                            result.forEach((item) => {
                                                                arr.push(item.content);
                                                            });
                                                            if (onChange) {
                                                                let copyData = JSON.parse(JSON.stringify(originData));
                                                                copyData.body.image_lists = arr;
                                                                onChange(copyData);
                                                            } else {
                                                                let copyData = { ...originData };
                                                                copyData.profile_list = arr;
                                                                setData(copyData);
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                            <Image url={item.content} width={113} height={113} style={{ border: '1px solid #c7c7c7', borderRadius: '3px' }} />
                                        </FlexMainWrapper>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default HorizontalImageLists;
