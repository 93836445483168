import styled from 'styled-components';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { SText } from '../text/ContentText';
import { Checkbox } from '../checkboxs/CheckboxInput';
import { useEffect, useState } from 'react';
const TableFilter = ({ wrapperStyle, arrFilterSt, topFilterSt, originfilter = true, topFilterLists = [], orderLists = [], filterLists = [], param, setParam, dispatch, research }) => {
    const [open, setOpen] = useState(true);
    const onHandleChangeFilter = (list, item) => (e) => {
        onChangeCheck(e.target.checked, list, item);
    };

    const onChangeCheck = (checked, list, item) => {
        const tmp = param;
        if (checked) {
            tmp[list.key] = [...(tmp[list.key] ?? []), String(item.key)];
        } else {
            if (tmp[list.key].length === 0) delete tmp[list.key];
            else tmp[list.key] = tmp[list.key].filter((e) => String(e) !== String(item.key));
        }
        if (dispatch) dispatch(tmp);
        setParam({ ...tmp });
        research?.current?.refresh();
    };

    return (
        <FilterTableWrapper style={{ ...wrapperStyle }}>
            <tbody>
                {topFilterLists.map((item, index) => {
                    return (
                        <tr className="top-filter" key={index}>
                            <td style={{ ...topFilterSt, backgroundColor: '#f2f4f6', width: '100px' }}>
                                <SText text={item.filterText} style={{ width: '100px', fontWeight: 500 }} />
                            </td>
                            <td style={{ ...topFilterSt, width: `${item.width}px` }}>
                                <OrderByFilter lists={item.orderLists} onChange={item.onChange} orderbyType={item.orderbyType} />
                            </td>
                        </tr>
                    );
                })}
                {originfilter &&
                    filterLists.map((item, index) => {
                        if (!item?.filter?.length) return null;
                        return (
                            <tr key={index}>
                                <td style={{ backgroundColor: '#f2f4f6', width: '100px' }}>
                                    <SText text={item.value} style={{ width: '100px', fontWeight: 500 }} />
                                </td>
                                <td>
                                    <FlexMainWrapper style={{ alignItems: 'center', ...arrFilterSt, flexWrap: 'wrap' }}>
                                        {item?.filter?.map((filterItem, index) => {
                                            const check = param[item.key]?.find((data) => Number(data) === Number(filterItem.key));
                                            return (
                                                <Checkbox
                                                    checked={check !== undefined}
                                                    wrapperWidth={false}
                                                    width={filterItem.width}
                                                    key={index}
                                                    reverse={true}
                                                    text={filterItem.value}
                                                    onChange={onHandleChangeFilter(item, filterItem)}
                                                />
                                            );
                                        })}
                                    </FlexMainWrapper>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </FilterTableWrapper>
    );
};

export default TableFilter;

const OrderByFilter = ({ checkedType = false, lists = [], orderbyType, onChange = () => {} }) => {
    return (
        <OrderByFlexWrapper>
            {lists?.map((item, index) => {
                return (
                    <Checkbox
                        boxBlur={item.boxBlur}
                        mobileOn
                        key={index}
                        reverse={true}
                        text={item?.name}
                        width={item?.width}
                        onChange={(e) => onChange(e, item?.type)}
                        checked={item.type != undefined && item.type != null ? item?.type === orderbyType : index + 1 === orderbyType}
                    />
                );
            })}
        </OrderByFlexWrapper>
    );
};

const FilterTableWrapper = styled.table`
    margin-bottom: 20px;
    border-collapse: collapse;
    width: 100%;
    tbody > tr > td {
        text-align: start;
        padding: 5px;
        border-top: 1px solid #c7c7c7;
        border-bottom: 1px solid #c7c7c7;
    }
`;

const OrderByFlexWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 120px;
    gap: 7px;
`;

// const FilterWrapper = styled.div`
//     position: relative;
//     display: flex;
//     align-items: start;
//     justify-content: start;
//     gap: 0;
//     width: 100%;
//     flex-wrap: wrap;
//     border-bottom: ${(props) => (props.originfilter ? '1px solid #c7c7c7' : '')};
//     @media (max-width: 912px) {
//         flex-direction: column;
//         flex-direction: ${(props) => (props.mobileOn ? 'column' : '')};
//         padding-bottom: ${(props) => (props.mobileOn ? '25px' : '')};
//     }
// `;

// const FilterItemWrapper = styled.div`
//     position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: start;
//     gap: 0;
//     width: 33.3%;
//     border-top: 1px solid #c7c7c7;
//     @media (max-width: 912px) {
//         width: 100%;
//     }
// `;

/*
<FlexMainWrapper style={{ flexDirection: 'column', gap: 0, marginBottom: '25px', ...borderSt, ...wrapperStyle }}>
    <FlexMainWrapper style={{ alignItems: 'center', gap: 0, width: '100%', ...borderStyle }}>
        <FlexMainWrapper style={{ backgroundColor: '#f2f4f6', height: '30px', alignItems: 'center', padding: '0 10px' }}>
            <SText text={filterText} style={{ width: '100px', fontWeight: 500 }} />
        </FlexMainWrapper>
        <OrderByFilter lists={orderLists} onChange={onChange} orderbyType={orderbyType} />
    </FlexMainWrapper>

    <FilterWrapper originfilter={originfilter}>
        {originfilter &&
            filterLists.map((item, index) => {
                if (!item?.filter?.length) return null;
                return (
                    <FilterItemWrapper key={index}>
                        <FlexMainWrapper style={{ backgroundColor: '#f2f4f6', height: '30px', alignItems: 'center', padding: '0 10px' }}>
                            <SText text={item.value} style={{ width: '100px', fontWeight: 500 }} />
                        </FlexMainWrapper>
                        <FlexMainWrapper style={{ alignItems: 'start', gap: '12px', width: '100%', padding: '0 5px' }}>
                            {item?.filter?.map((filterItem, index) => {
                                return <Checkbox wrapperWidth={false} width={false} key={index} reverse={true} text={filterItem.value} onChange={onHandleChangeFilter(item, filterItem)} />;
                            })}
                        </FlexMainWrapper>
                    </FilterItemWrapper>
                );
            })}
    </FilterWrapper>
</FlexMainWrapper>
*/
