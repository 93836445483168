import React, { useEffect, useState } from 'react';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { ChooseIntersetBox, Item, KategorySubTitle, KategoryWrapper, ModalWindow, SaveButton, SaveButtonWrapper, Title, TitleLineWrapper } from '../styles/AddGatheringModalStyled';
import { CloseOutlined } from '@ant-design/icons';
import { MiddleModal } from '../../common/BootStrap/Modal';
export const InterestCodeType = {
    SirupInterest: 'INTEREST_CODE',
    GatheringInterest: 'GATHERING_INTEREST_CODE',
};
const InterestSelector = ({ withCodeName = false, title = '관심 주제 선택', onSave, maxCount = 4, codeType = InterestCodeType.SirupInterest, activeCodes, visible, setVisible }) => {
    const [codeList, setCodeList] = useState({});
    const [activeCode, setActiveCode] = useState([]);

    // 카테고리 가져오기 함수
    const getInterestCode = async () => {
        const response = await RefreshCheckApi.get(`/code?code_group=${codeType}`);
        const data = chunkArrayByKeyboard(response.data.data, 'sub_code_group');
        setCodeList(data);
    };
    const onCheck = (code) => {
        if (maxCount === 1) {
            setActiveCode([code]);
        } else {
            if (codeList.includes(code)) {
                setActiveCode((pre) => pre.filter((e) => e !== code));
            } else {
                if (codeList >= maxCount) {
                    alert(`최대 ${maxCount}개 까지 선택 가능합니다.`);
                } else {
                    setActiveCode((pre) => pre.push(code));
                }
            }
        }
    };
    const chunkArrayByKeyboard = (list = [], key) => {
        const keys = [];
        list.map((e) => {
            if (!keys.includes(e[key])) {
                keys.push(e[key]);
            }
        });
        const ret = {};
        keys.map((e) => {
            ret[e] = list.filter((f) => f[key] === e);
        });

        return ret;
    };
    const onClickSave = () => {
        if (withCodeName) {
            const ret = [];
            Object.getOwnPropertyNames(codeList).map((e) => {
                codeList[e].map((item) => {
                    if (activeCode.includes(item.code)) {
                        ret.push(item);
                    }
                });
            });
            onSave(ret);
        } else {
            onSave(activeCodes);
        }
    };
    useEffect(() => {
        setActiveCode(activeCodes);
    }, [activeCodes]);
    useEffect(() => {
        getInterestCode();
    }, [codeType]);
    return (
        <MiddleModal
            visible={visible}
            setVisible={setVisible}
            scrollable={true}
            title={title}
            footer={
                <SaveButtonWrapper id="save-button">
                    <SaveButton onClick={onClickSave}>저장</SaveButton>
                </SaveButtonWrapper>
            }
        >
            <div>
                <div>
                    {Object.getOwnPropertyNames(codeList).map((e) => (
                        <div key={`user_interests_${e}`}>
                            <KategorySubTitle>{e}</KategorySubTitle>
                            <KategoryWrapper>
                                {codeList[e].map((item, index) => (
                                    <Item
                                        key={`interests_item_${e}_${index}`}
                                        onClick={() => {
                                            onCheck(item.code);
                                        }}
                                        checked={activeCode?.includes(item.code)}
                                    >
                                        {item.code_name}
                                    </Item>
                                ))}
                            </KategoryWrapper>
                        </div>
                    ))}
                </div>
            </div>
        </MiddleModal>
    );
};

export default InterestSelector;
