import React, { useState, useRef, useLayoutEffect } from 'react';

// styled-components
import styled from 'styled-components';

// recharts
import { ResponsiveContainer } from 'recharts';

// components
import SalesStatisticsTable from './SalesStatisticsTable';
import PaymentStackedBarChart from './PaymentStackedBarChart';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

// 매출 통계 페이지
const PaymentsStatistic = () => {
    // state
    const [lineWidth, setLineWidth] = useState(0);

    // useRef
    const lineChartAreaRef = useRef();

    // 너비 구하기
    useLayoutEffect(() => {
        setLineWidth(lineChartAreaRef.current.offsetWidth);
    }, [lineChartAreaRef]);

    return (
        <Wrapper>
            <Wrapper02 style={{ marginBottom: '3%', overflowY: 'auto' }}>
                <label id="label">매출 라인차트</label>
                <LineChartWrapper ref={lineChartAreaRef}>
                    <ResponsiveContainer width="100%" height="100%">
                        <PaymentStackedBarChart lineWidth={lineWidth} />
                    </ResponsiveContainer>
                </LineChartWrapper>
            </Wrapper02>

            <Wrapper02 style={{ width: '130vh', overflowY: 'auto' }}>
                <label id="label">통계 - 매출</label>
                <SalesStatisticsTable />
            </Wrapper02>
        </Wrapper>
    );
};

export default PaymentsStatistic;

const Wrapper = styled.div`
    #label {
        display: none;
    }
`;

const LineChartWrapper = styled.div`
    width: 92%;
    margin: auto;
`;
