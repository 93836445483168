import {
    getEvents,
    postEvents,
    postAdminEvents,
    deleteAdminEvents
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import {DELETE_EVENTS, GET_EVENTS, POST_EVENTS} from "./events_table_type";
import CreateDeleteRequestThunk from "../../redux_functions/create_delete_request_thunk";

// 이벤트 표 관련

export const getEventsAsync = createGetRequestThunk(GET_EVENTS, getEvents);

export const postEventsAsync = createPostRequestThunk(POST_EVENTS, postEvents);

export const postAdminEventsAsync = createPostRequestThunk(POST_EVENTS, postAdminEvents);

export const deleteAdminEventsAsync = CreateDeleteRequestThunk(DELETE_EVENTS,deleteAdminEvents)
