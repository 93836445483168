import produce from "immer";
import {handleActions} from "redux-actions";
import {CHECK_BOX} from "../../../data_type/data_type";
import {
    GET_ADMINISTRATORS_DETAIL_FAILURE,
    GET_ADMINISTRATORS_DETAIL_SUCCESS,
    PUT_ADMINISTRATORS_DETAIL_FAILURE,
    PUT_ADMINISTRATORS_DETAIL_SUCCESS,
} from "./administrators_detail/administrators_detail_type";
import {
    DELETE_ADMINISTRATORS_FAILURE,
    DELETE_ADMINISTRATORS_SUCCESS,
    GET_ADMINISTRATORS_FAILURE,
    GET_ADMINISTRATORS_SUCCESS,
    POST_ADMINISTRATORS_FAILURE,
    POST_ADMINISTRATORS_SUCCESS,
    SET_ADMINISTRATORS,
} from "./administrators_table/administrators_table_type";

const initialState = {
  administrators: null,
  administratorsDetail: null,
};

const administratorsReducer = handleActions(
  {
    [GET_ADMINISTRATORS_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.administrators = action.payload;
      }),
    [GET_ADMINISTRATORS_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [GET_ADMINISTRATORS_DETAIL_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.administratorsDetail = action.payload;
      }),
    [GET_ADMINISTRATORS_DETAIL_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [PUT_ADMINISTRATORS_DETAIL_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        for (let i = 0; i < draft.administrators.length; i++) {
          if (draft.administrators[i]["id"] === action.payload["admin_id"]) {
            draft.administrators[i]["data"][3]["data"] =
              action.payload["phone_no"];
          }
        }
      }),
    [PUT_ADMINISTRATORS_DETAIL_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [POST_ADMINISTRATORS_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.administrators.body = [
          ...draft.administrators.body,
          action.payload
        ];
      }),
    [POST_ADMINISTRATORS_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [DELETE_ADMINISTRATORS_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        let tempList = [];
        for (let i = 0; i < draft.administrators.length; i++) {
          if (draft.administrators[i]["data"][0]["type"] === CHECK_BOX) {
            if (parseInt(draft.administrators[i]["data"][0].data) !== 1) {
              tempList.push(draft.administrators[i]);
            }
          }
        }
        draft.administrators = tempList;
      }),
    [DELETE_ADMINISTRATORS_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [SET_ADMINISTRATORS]: (state, action) =>
      produce(state, (draft) => {
        draft.administrators = action.payload.administrators;
      }),
  },
  initialState
);

export default administratorsReducer;
