import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 모임정보 리스트 불러오기
export const GET_GATHERING_REQUEST = 'GET_GATHERING_REQUEST';
export const GET_GATHERING_SUCCESS = 'GET_GATHERING_SUCCESS';
export const GET_GATHERING_FAILURE = 'GET_GATHERING_FAILURE';
// 모임상세정보 불러오기
export const GET_GATHERING_DETAIL_REQUEST = 'GET_GATHERING_DETAIL_REQUEST';
export const GET_GATHERING_DETAIL_SUCCESS = 'GET_GATHERING_DETAIL_SUCCESS';
export const GET_GATHERING_DETAIL_FAILURE = 'GET_GATHERING_DETAIL_FAILURE';
// 모임 참가원 조회
export const GET_GATHERING_DETAIL_CREW_REQUEST = 'GET_GATHERING_DETAIL_CREW_REQUEST';
export const GET_GATHERING_DETAIL_CREW_SUCCESS = 'GET_GATHERING_DETAIL_CREW_SUCCESS';
export const GET_GATHERING_DETAIL_CREW_FAILURE = 'GET_GATHERING_DETAIL_CREW_FAILURE';
// 모임 정보수정
export const UPDATE_GATHERING_REQUEST = 'UPDATE_GATHERING_REQUEST';
export const UPDATE_GATHERING_SUCCESS = 'UPDATE_GATHERING_SUCCESS';
export const UPDATE_GATHERING_FAILURE = 'UPDATE_GATHERING_FAILURE';
// 모임삭제
export const DELETE_GATHERING_REQUEST = 'DELETE_GATHERING_REQUEST';
export const DELETE_GATHERING_SUCCESS = 'DELETE_GATHERING_SUCCESS';
export const DELETE_GATHERING_FAILURE = 'DELETE_GATHERING_FAILURE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    gatheringLists: [], // 모임정보 리스트
    gatheringList: [], // 모임상세정보/모임정보
    gatheringCrewList: [], // 모임상세정보/모임운영진정보
    gatheringPastOwners: [], // 모임상세정보/모임운영진정보
    gatheringJoinUser: [], //  모임 참가자 조회
    deleteCount: [], //  모임 참가자 조회
    // 모임정보 리스트
    getGatheringLoading: false,
    getGatheringDone: false,
    getGatheringError: null,
    // 모임상세정보
    getGatheringDetailLoading: false,
    getGatheringDetailDone: false,
    getGatheringDetailError: null,
    // 모임 참가자 조회
    getGatheringDetailCrewLoading: false,
    getGatheringDetailCrewDone: false,
    getGatheringDetailCrewError: null,
    // 모임정보수정
    updateGatheringLoading: false,
    updateGatheringDone: false,
    updateGatheringError: null,
    // 모임삭제
    deleteGatheringLoading: false,
    deleteGatheringDone: false,
    deleteGatheringError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 모임정보 리스트 불러오기
export const requestGetGathering = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering`, { params: input });

        dispatch({
            type: GET_GATHERING_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_FAILURE,
            error: error,
        });
    }
};

// 모임상세정보 불러오기
export const requestGetGatheringDetail = (gathering_id) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/detail?gathering_id=${gathering_id}`);

        dispatch({
            type: GET_GATHERING_DETAIL_SUCCESS,
            findDetailData: response.data.findDetailData[0],
            findDetailCrew: response.data.findDetailCrew,
            gatheringPastOwners: response.data.pastOwners,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 모임 참가원 조회
export const requestGetGatheringDetailCrew = (gathering_id, param) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_DETAIL_CREW_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/detail/crew?gathering_id=${gathering_id}`, {
            params: param,
        });

        dispatch({
            type: GET_GATHERING_DETAIL_CREW_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_DETAIL_CREW_FAILURE,
            error: error,
        });
    }
};

// 모임정보수정
export const updateGathering = (params) => async (dispatch) => {
    dispatch({
        type: UPDATE_GATHERING_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering`, params);

        dispatch({
            type: UPDATE_GATHERING_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_GATHERING_FAILURE,
            error: error,
        });
    }
};

// 모임삭제
export const deleteGathering = (params) => async (dispatch) => {
    dispatch({
        type: DELETE_GATHERING_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering/delete`, {
            gathering_lists: params,
        });

        dispatch({
            type: DELETE_GATHERING_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: DELETE_GATHERING_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const getGatheringReducer = handleActions(
    {
        // 모임정보 리스트 불러오기
        [GET_GATHERING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringLoading = true;
                draft.getGatheringDone = false;
                draft.getGatheringError = null;
            }),
        [GET_GATHERING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringLoading = action.payload ? false : true;
                draft.gatheringLists = action.payload;
                draft.getGatheringDone = action.payload ? true : false;
            }),
        [GET_GATHERING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringLoading = false;
                draft.getGatheringError = action.error;
            }),

        // 모임상세조회
        [GET_GATHERING_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringDetailLoading = true;
                draft.getGatheringDetailDone = false;
                draft.getGatheringDetailError = null;
            }),
        [GET_GATHERING_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringDetailLoading = false;
                draft.gatheringList = action.findDetailData;
                draft.gatheringCrewList = action.findDetailCrew;
                draft.gatheringPastOwners = action.gatheringPastOwners;
                draft.getGatheringDetailDone = true;
            }),
        [GET_GATHERING_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringDetailLoading = false;
                draft.getGatheringDetailError = action.error;
            }),

        // 모임 참가원 조회
        [GET_GATHERING_DETAIL_CREW_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringDetailCrewLoading = true;
                draft.getGatheringDetailCrewDone = false;
                draft.getGatheringDetailCrewError = null;
            }),
        [GET_GATHERING_DETAIL_CREW_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringDetailCrewLoading = false;
                draft.gatheringJoinUser = action.payload;
                draft.getGatheringDetailCrewDone = true;
            }),
        [GET_GATHERING_DETAIL_CREW_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringDetailCrewLoading = false;
                draft.getGatheringDetailCrewError = action.error;
            }),

        // 모임정보수정
        [UPDATE_GATHERING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateGatheringLoading = true;
                draft.updateGatheringDone = false;
                draft.updateGatheringError = null;
            }),
        [UPDATE_GATHERING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateGatheringLoading = action.payload ? false : true;
                draft.updateGatheringDone = action.payload ? true : false;
            }),
        [UPDATE_GATHERING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateGatheringLoading = false;
                draft.updateGatheringError = action.error;
            }),

        // 모임삭제
        [DELETE_GATHERING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringLoading = true;
                draft.deleteGatheringDone = false;
                draft.deleteGatheringError = null;
            }),
        [DELETE_GATHERING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringLoading = action.payload ? false : true;
                draft.deleteCount = action.payload;
                draft.deleteGatheringDone = action.payload ? true : false;
            }),
        [DELETE_GATHERING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringLoading = false;
                draft.deleteGatheringError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringLoading = false;
                draft.deleteGatheringDone = false;
                draft.deleteGatheringError = null;
            }),
    },
    initialState,
);

export default getGatheringReducer;
