import {
    getSubscribePaymentsDetail,
    getSubscribePaymentsDetailRefund,
    putSubscribePaymentsDetailAdminMemo,
    putSubscribePaymentsDetailDescribeAdd,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import {
    GET_SUBSCRIBE_PAYMENTS_DETAIL,
    GET_SUBSCRIBE_PAYMENTS_DETAIL_REFUND,
    PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO,
    PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD,
} from "./subscribePayments_detail_type";

export const getSubscribePaymentsDetailAsync = createGetRequestThunk(
  GET_SUBSCRIBE_PAYMENTS_DETAIL,
  getSubscribePaymentsDetail
);

export const putSubscribePaymentsDetailAdminMemoAsync = createPutRequestThunk(
  PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO,
  putSubscribePaymentsDetailAdminMemo
);

export const putSubscribePaymentsDetailDescribeAddAsync = createPutRequestThunk(
  PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD,
  putSubscribePaymentsDetailDescribeAdd
);

export const getSubscribePaymentsDetailRefundAsync = createGetRequestThunk(
  GET_SUBSCRIBE_PAYMENTS_DETAIL_REFUND,
  getSubscribePaymentsDetailRefund
);
