import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import TheadLists from './TheadLists';
import TbodyLists from './TbodyLists';
import { TableFilterCheckboxBtn } from '../checkboxs/FilterCheckboxBtn';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';

import Table from 'react-bootstrap/Table';

const MainTable = forwardRef(
    (
        {
            tableStyle,
            filterKey,
            filterItem,
            buttonComponents = () => {},
            headerData = [],
            loadingText,
            bodyData = [],
            filterRenderItem = null,
            theadRenderItem = null,
            calSpan = 20,
            tbodyRenderItem,
            param,
            setParam,
            callApi = () => {},
            pageCountCallApi = () => {},
            onClickRowItem = () => {},
            checkID,
            tableRef,
            toggleBlur = false,
            rendering,
            noneDataText,
            pagenaitionOn = true,
            nullText,
            adminCheck,
            inTableStyle,
            scrollWidth = 150,
            plusCount = 0,
            fitPage = false,
            checkAble = true,
            onChangeCheck,
        },
        _ref,
    ) => {
        const [data, setData] = useState(bodyData);
        const [allCount, setAllCount] = useState(0);
        const [page, setPage] = useState(1);
        const [filterToggle, setFilterToggle] = useState(false);
        const [loading, setLoading] = useState(true);

        const [checkItems, setCheckItems] = useState([]);
        const [checkDatas, setCheckDatas] = useState([]);

        const init = async () => {
            if (data.length === 0) setLoading(true);
            const list = await callApi(page, param);
            if (list) {
                setLoading(false);
                setData(list);
            }

            const allCount = await pageCountCallApi(page, param);
            if (allCount) setAllCount(allCount);
        };

        useEffect(() => {
            if (onChangeCheck) {
                onChangeCheck(checkDatas);
            }
        }, [checkDatas]);
        useEffect(() => {
            if (callApi) init();
        }, [page, rendering]);

        useEffect(() => {
            console.log(param);
            if (page === 1) init();
            else setPage(1);
        }, [param]);

        const onClickFilterToggle = () => {
            setFilterToggle((prev) => !prev);
        };

        // 클릭 시 단일 체크
        const onClickSingleCheck = (e, datas) => {
            return handleSingleCheck(e.target.checked, datas);
        };

        // 체크박스 단일 선택
        const handleSingleCheck = (checked, data) => {
            if (checked) {
                // 단일 선택 시 체크된 아이템을 배열에 추가
                setCheckItems((prev) => [...prev, data[checkID]]);
                setCheckDatas((prev) => [...prev, data]);
            } else {
                // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
                setCheckItems(checkItems.filter((el) => el !== data[checkID]));
                setCheckDatas(checkDatas.filter((item) => item[checkID] !== data[checkID]));
            }
        };

        // 클릭 시 전체 체크
        const onClickAllCheck = (e) => {
            return handleAllCheck(e.target.checked);
        };

        // 체크박스 전체 선택
        const handleAllCheck = (checked) => {
            if (checked) {
                // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
                const idArray = [];
                const dataArray = [];
                data?.forEach((data) => {
                    if (!data.deleted_at) {
                        idArray.push(data[checkID]);
                    }
                });
                data?.forEach((data) => dataArray.push(data));
                setCheckItems(idArray);
                setCheckDatas(dataArray);
            } else {
                // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
                setCheckItems([]);
                setCheckDatas([]);
            }
        };

        useImperativeHandle(_ref, () => ({
            refresh: () => init(),
            page: page,
        }));

        return (
            <TableWrapper filterToggle={filterToggle} data={data} style={{ ...tableStyle }} toggleBlur={toggleBlur}>
                {buttonComponents({ checkItems, checkDatas, setCheckItems, setCheckDatas, data })}
                <div id="table-wrapper">
                    <div id="table" style={{ width: '100%', overflowX: 'scroll' }}>
                        {!toggleBlur && (
                            <MoreKategory onClick={onClickFilterToggle}>
                                <div>
                                    <span>상세검색 버튼</span>
                                    {filterToggle ? <CaretUpOutlined /> : <CaretDownOutlined />}
                                </div>
                            </MoreKategory>
                        )}

                        <table ref={tableRef} style={{ ...inTableStyle }} data-sticky-header="true">
                            {filterRenderItem || (filterKey && filterItem) ? (
                                <thead className="filter">
                                    {filterToggle &&
                                        (filterRenderItem ? (
                                            filterRenderItem(headerData)
                                        ) : (
                                            <TableFilterCheckboxBtn filterKey={filterKey} filterItem={filterItem} lists={headerData} params={param} setParams={setParam} dispatch={init} />
                                        ))}
                                </thead>
                            ) : null}

                            <thead
                                className="title"
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                }}
                            >
                                {theadRenderItem !== null ? (
                                    theadRenderItem(headerData)
                                ) : (
                                    <TheadLists
                                        checkAble={checkAble}
                                        headerLists={headerData}
                                        onClickAllCheck={(e) => onClickAllCheck(e)}
                                        checked={checkItems?.length === data?.length ? true : false}
                                    />
                                )}
                            </thead>

                            <tbody className="body">
                                <TbodyLists
                                    checkAble={checkAble}
                                    data={data}
                                    noneDataText={noneDataText}
                                    colSpan={calSpan}
                                    loading={loading}
                                    loadingText={loadingText}
                                    renderItem={tbodyRenderItem}
                                    tdItemLists={headerData}
                                    onClickRowItem={onClickRowItem}
                                    onClickSingleCheck={(e, item) => onClickSingleCheck(e, item)}
                                    checked={(item) => (checkItems.includes(item[checkID]) ? true : false)}
                                    nullText={nullText}
                                    adminCheck={adminCheck}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
                {pagenaitionOn && allCount > 10 && <Pagination totalPages={allCount ? Math.round(allCount / 10) + plusCount : 10} limit={10} page={page} setPage={setPage} />}
            </TableWrapper>
        );
    },
);

export default React.memo(MainTable);

const TableWrapper = styled.div`
    width: 100%;
    @media (max-width: 912px) {
        display: block;
    }

    table {
        border-top: ${(props) => (props.toggleBlur ? '1px solid black' : 0)};
        border-collapse: collapse;
        font-size: 13px;
        display: table;
        width: 100%;
        white-space: nowrap;
    }
    table th:first-child {
        background-color: white;
        position: sticky;
        left: 0;
    }
    table td:first-child {
        background-color: white;
        position: sticky;
        left: 0;
    }
    table > tbody {
        width: 100%;
    }

    table > thead {
        width: 100%;
    }

    table > thead > tr {
        width: 100%;
    }

    table > thead > tr > td {
        text-align: center;
        padding: 5px 15px;
    }
    .title > tr > th {
        text-align: center;
        padding: 10px 15px;
        border-top: ${(props) => (props.filterToggle ? '1px solid black' : '')};
        border-bottom: 1px solid black;
    }

    table > .body > tr:hover {
        cursor: ${(props) => (props.data?.length === 0 ? '#000000' : 'pointer')};
        background-color: ${(props) => (props.data?.length === 0 ? '#000000' : '#f4f4ff')};
    }

    td {
        text-align: center;
        vertical-align: center;
        border-bottom: solid 1px black;
        padding-block: 8px;
    }
`;

const MoreKategory = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    user-select: none;

    width: 100%;
    height: 25px;

    border-top: 1px solid black;
    border-bottom: 1px solid black;

    text-align: center;
    font-size: 15px;
    font-weight: 600;

    :hover {
        background-color: #dfdfdf;
    }
`;
