import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#3eff28', '#ff28c2'];

const FormatLineData = (value) => {
    switch (value) {
        case 'user_new':
            return '신규 가입자';
        case 'user_male':
            return '남성';
        case 'user_female':
            return '여성';
        case 'drawal':
            return '탈퇴자';
        case 'subscribe':
            return '구독자';
        case 'user_null':
            return 'Null';
        default:
            return value;
    }
};

export default class LineChartEntry extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <LineChart
                width={this.props.lineWidth}
                height={435}
                data={this.props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dateInfo" unit={this.props.lineDateType === 0 ? '일' : this.props.lineDateType === 1 ? '주' : '월'} />
                <YAxis unit={'명'} />
                <Tooltip
                    formatter={(value, name) => {
                        return [value, FormatLineData(name)];
                    }}
                />
                <Legend align={'right'} verticalAlign={'middle'} layout={'vertical'} wrapperStyle={{ paddingLeft: '15px' }} formatter={FormatLineData} />

                {this.props.data.length > 0
                    ? // eslint-disable-next-line array-callback-return
                      Object.getOwnPropertyNames(this.props.data[0]).map((key, index) => {
                          if (key !== 'dateInfo') return <Line key={'line_' + index} type={'linear'} dataKey={key} stroke={COLORS[index]} activeDot={{ r: 8 }} />;
                      })
                    : null}
            </LineChart>
        );
    }
}
