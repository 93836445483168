import mainRequest from "../request";


const mainUrl  = `/v2/chatting`

export const getChattingListRequest = async (params) => {
	try {
		const response = await mainRequest.post(`${mainUrl}/list`, params)


		return response?.data


	} catch(err){
		return {
			ok:false,
			status: err.response.status || 500,
			message: err.response.data.message || '서버 에러'
		}
	}
}


export const patchChattingDisabledRequest = async (params) => {
	try {
		const response = await mainRequest.patch(`${mainUrl}/disable`, params)


		return response?.data


	} catch(err){
		return {
			ok:false,
			status: err.response.status || 500,
			message: err.response.data.message || '서버 에러'
		}
	}
}


