import React from 'react';
import MainPageTitle from '../titles/MainPageTitle';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { FlexTwoText } from '../text/TextMapping';

export const InformationCard = ({ mainTitle, marginTop = 3, info = [], renderItem = null, wrapperStyle, cardWrapStyle }) => {
    return (
        <div style={{ ...wrapperStyle }}>
            <MainPageTitle
                wrapperStyle={{ margin: 0 }}
                fontStyle={{ fontSize: '17px', fontWeight: 600, color: '#797979' }}
                text={mainTitle}
            />
            <FlexMainWrapper style={{ marginTop: `${marginTop}%`, justifyContent: 'start' }}>
                <FlexMainWrapper style={{ flexDirection: 'column', padding: '0 20px', gap: '10px', ...cardWrapStyle }}>
                    {info.map((item, index) => (
                        <FlexTwoText
                            text01Style={{ width: `${item.width}px` }}
                            text01={item.title}
                            text02={renderItem ? renderItem(item.value) : item.value}
                        />
                    ))}
                </FlexMainWrapper>
            </FlexMainWrapper>
        </div>
    );
};
