import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 회원정보 불러오기
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
// 문자 전송내역
export const GET_SEND_MESSAGE_LISTS_REQUEST = 'GET_SEND_MESSAGE_LISTS_REQUEST';
export const GET_SEND_MESSAGE_LISTS_SUCCESS = 'GET_SEND_MESSAGE_LISTS_SUCCESS';
export const GET_SEND_MESSAGE_LISTS_FAILURE = 'GET_SEND_MESSAGE_LISTS_FAILURE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    users: [], // 회원정보 리스트
    sendMessageLists: [], // 문자 전송내역
    // 회원정보 불러오기
    getUsersLoading: false,
    getUsersDone: false,
    getUsersError: null,
    // 문자 전송내역
    getSendMessageListsLoading: false,
    getSendMessageListsDone: false,
    getSendMessageListsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 회원정보 불러오기
export const getUsersInformation = (params) => async (dispatch) => {
    dispatch({
        type: GET_USERS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSms`, { params: params });
        dispatch({
            type: GET_USERS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_FAILURE,
            error: error,
        });
    }
};

// 문자 전송내역 조회
export const getSendMessageLists = () => async (dispatch) => {
    dispatch({
        type: GET_SEND_MESSAGE_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSms/sendLists`);
        dispatch({
            type: GET_SEND_MESSAGE_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SEND_MESSAGE_LISTS_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const newSmsReducer = handleActions(
    {
        // 회원정보 불러오기
        [GET_USERS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersLoading = true;
                draft.getUsersDone = false;
                draft.getUsersError = null;
            }),
        [GET_USERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersLoading = action.payload ? false : true;
                draft.users = action.payload;
                draft.getUsersDone = action.payload ? true : false;
            }),
        [GET_USERS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersLoading = false;
                draft.getUsersError = action.error;
            }),
        // 문자 전송내역 조회
        [GET_SEND_MESSAGE_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getSendMessageListsLoading = true;
                draft.getSendMessageListsDone = false;
                draft.getSendMessageListsError = null;
            }),
        [GET_SEND_MESSAGE_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getSendMessageListsLoading = action.payload ? false : true;
                draft.sendMessageLists = action.payload;
                draft.getSendMessageListsDone = action.payload ? true : false;
            }),
        [GET_SEND_MESSAGE_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getSendMessageListsLoading = false;
                draft.getSendMessageListsError = action.error;
            }),
    },
    initialState,
);

export default newSmsReducer;
