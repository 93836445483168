import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

function ReturnGoods() {
    return (
        <Wrapper02>
            <MainPageTitle text={'반품 관리'} />
            <p>ReturnGoods</p>
        </Wrapper02>
    );
}

export default ReturnGoods;
