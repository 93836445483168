export const SchedulePayUserFilter = [
    {
        key: 'created_at',
        value: '결제일시',
        filter: [
            {
                key: 1,
                value: '내림차순',
            },
            {
                key: 2,
                value: '오름차순',
            },
        ],
    },
    {
        key: 'pay_status',
        value: '결제상태',
        filter: [
            {
                key: 0,
                value: '결제대기중',
            },
            {
                key: 1,
                value: '입금대기중',
            },
            {
                key: 2,
                value: '결제완료',
            },
            {
                key: 3,
                value: '환불신청',
            },
            {
                key: 4,
                value: '환불완료',
            },
            {
                key: 5,
                value: '환불거절',
            },
            {
                key: 6,
                value: '결제취소',
            },
        ],
    },
    {
        key: 'pay_type',
        value: '결제수단',
        filter: [
            {
                key: 0,
                value: '신용 카드',
            },
            {
                key: 1,
                value: '무통장 계좌',
            },
            {
                key: 2,
                value: '카카오 페이',
            },
            {
                key: 3,
                value: '네이버 페이',
            },
            {
                key: 4,
                value: '계좌 이체',
            },
            {
                key: 5,
                value: '현장 결제',
            },
        ],
    },
    {
        key: 'name',
        value: '이름',
        filter: [
            {
                key: 1,
                value: '내림차순',
            },
            {
                key: 2,
                value: '오름차순',
            },
            {
                key: 3,
                value: '없음',
            },
        ],
    },
    {
        key: 'nick_name',
        value: '닉네임',
    },
    {
        key: 'phone_no',
        value: '핸드폰번호',
    },
    {
        key: 'gathering_name',
        value: '참여 모임명',
    },
    {
        key: 'title',
        value: '일정명',
    },
    {
        key: 'start_date',
        value: '일정일시',
    },
    {
        key: 'start_time',
        value: null,
    },
    {
        key: 'use_point_amount',
        value: '포인트사용',
    },
    {
        key: 'pay_amount',
        value: '실 결제금액',
        filter: [
            {
                key: 1,
                value: '없음',
            },
            {
                key: 2,
                value: '0~1,000원',
            },
            {
                key: 3,
                value: '1,000~5,000원',
            },
            {
                key: 4,
                value: '5,000~5 만원',
            },
            {
                key: 5,
                value: '5 만원~10 만원',
            },
            {
                key: 6,
                value: '10 만원~30 만원',
            },
            {
                key: 7,
                value: '30 만원~100 만원',
            },
            {
                key: 8,
                value: '100 만원 이상',
            },
        ],
    },
    {
        key: 'accumulate_pay',
        value: '누적결제금액',
        filter: [
            {
                key: 1,
                value: '없음',
            },
            {
                key: 2,
                value: '0~1,000원',
            },
            {
                key: 3,
                value: '1,000~5,000원',
            },
            {
                key: 4,
                value: '5,000~5 만원',
            },
            {
                key: 5,
                value: '5 만원~10 만원',
            },
            {
                key: 6,
                value: '10 만원~30 만원',
            },
            {
                key: 7,
                value: '30 만원~100 만원',
            },
            {
                key: 8,
                value: '100 만원 이상',
            },
        ],
    },
];
