import React from 'react';

// styled-components
import styled from 'styled-components';
import { ArrowDownBlueUnderbar1x } from '../../design_system/icons/icons';

// 엑셀 다운로드
const ExcelBtn = ({ onPress }) => {
    return (
        <ExcelDownload onClick={onPress}>
            <ArrowDownBlueUnderbar1x />
            <Text style={{ color: 'rgb(48 94 255)' }}>엑셀 다운로드</Text>{' '}
        </ExcelDownload>
    );
};

export default ExcelBtn;

const ExcelDownload = styled.div`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${'rgb(48 94 255)'};

    padding-inline: 10px;
`;

const Text = styled.div`
    font-size: 13px;
    font-family: noto_sans_kr_medium;

    padding: 6px 8px;
`;
