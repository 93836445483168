import React from 'react';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { MainButton } from './Buttons';

export const TopSideButton = ({
    rightOn = true,
    leftOn = true,
    middleOn = true,
    text01 = '등록',
    text02 = '삭제',
    text03 = '복원',
    onClick = () => {},
    onClickMiddle = () => {},
    onClickDelete = () => {},
    wrapperStyle,
    leftStyle,
    leftWidth,
    leftHeight,
    middleStyle,
    middleWidth,
    middleHeight,
    rightStyle,
    rightWidth,
    rightHeight,
}) => {
    return (
        <FlexMainWrapper style={{ justifyContent: 'end', marginBottom: '5px', ...wrapperStyle }}>
            {leftOn && <MainButton text={text01} onClick={onClick} style={leftStyle} width={leftWidth} height={leftHeight} />}
            {middleOn && <MainButton text={text03} color="#fff" onClick={onClickMiddle} style={{ backgroundColor: 'rgb(76, 175, 80)', ...middleStyle }} width={middleWidth} height={middleHeight} />}
            {rightOn && <MainButton text={text02} color="rgb(66, 139, 247)" onClick={onClickDelete} styleReverse={true} style={rightStyle} width={rightWidth} height={rightHeight} />}
        </FlexMainWrapper>
    );
};
