import React, { useState, useEffect } from 'react';

// styled-components
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';

// react - query
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import LoadingPage from '../../pages/loading/LoadingPage';

// 질문 입력
function AddQuestionEntry({ index, addData, setAddData, count, setCount, isSuccess }) {
    // request
    const { isLoading, error, data } = useQuery('repoData', async () => {
        return await RefreshCheckApi.get(`/adminQuestion/category`).then((res) => {
            return {
                main: res.data.findMainCategoryLists,
                sub: res.data.findCategoryLists,
            };
        });
    });

    // state-toggle
    const [inputSelfToggle, setInputSelfToggle] = useState(false);

    // state
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [mainSelected, setMainSelected] = useState('');
    const [subSelected, setSubSelected] = useState('');

    /**************************************** useEffect ****************************************/

    // 상태적용
    useEffect(() => {
        if (data && data?.main?.length) {
            setMainSelected(data?.main[0]?.main_category_name);
        }
    }, [data]);

    // 배열안의 객체 데이터 추가
    useEffect(() => {
        let arr = addData;
        if (question && answer && mainSelected && subSelected) {
            arr[index] = {
                question: question,
                answer: answer,
                main_category_name: mainSelected,
                sub_category_name: subSelected,
                input_self: inputSelfToggle,
            };
        } else {
            arr.splice(index, 1);
        }
        setAddData(arr);
    }, [index, addData, setAddData, question, answer, mainSelected, subSelected, inputSelfToggle]);

    // 저장 완료 시 초기화
    useEffect(() => {
        if (isSuccess) {
            setQuestion('');
            setAnswer('');
            setMainSelected(data?.main[0].main_category_name);
            setSubSelected('');
        }
    }, [data, isSuccess]);

    /**************************************** function ****************************************/

    // 질문입력
    const onChangeQuestion = (e) => {
        setQuestion(e.target.value);
    };

    // 답변입력
    const onChangeAnswer = (e) => {
        setAnswer(e.target.value);
    };

    // 대분류 선택
    const handleMainSelect = (e) => {
        setMainSelected(e.target.value);
        setSubSelected('');
    };

    // 소분류 선택
    const handleSubSelect = (e) => {
        setSubSelected(e.target.value);
    };

    // 직접입력 토글
    const onClickInputSelfToggle = () => {
        if (!inputSelfToggle) {
            setMainSelected('');
            setSubSelected('');
        } else {
            setMainSelected(data?.main[0].main_category_name);
            setSubSelected('');
        }

        setInputSelfToggle((prev) => !prev);
    };

    // 선택 질문 삭제
    const deleteQuestionObject = () => {
        let obj = addData;

        if (addData[index]) {
            obj.splice(index, 1);
            setAddData(obj);
        } else {
            setCount(count - 1);
        }
    };

    /******************************************* html *******************************************/
    if (isLoading) {
        return <LoadingPage text={'데이터를 불러오는 중입니다.'} />;
    } else if (error) {
        return <LoadingPage text={error} />;
    } else {
        return (
            <div id="category-wrapper">
                <div id="category">
                    <DetailTableWrapper>
                        {index > 0 && (
                            <LineWrapper>
                                <Line />
                                <Delete className="delete" onClick={deleteQuestionObject}>
                                    DELETE
                                </Delete>
                            </LineWrapper>
                        )}
                        <SelectWrapper id="category-select">
                            {inputSelfToggle ? (
                                <>
                                    <CategoryInput placeholder="대분류" onChange={handleMainSelect} />
                                    <RightOutlined />
                                    <CategoryInput placeholder="소분류" onChange={handleSubSelect} />
                                </>
                            ) : (
                                <>
                                    <Select onChange={handleMainSelect} value={mainSelected}>
                                        {data?.main?.map((main, index) => {
                                            return <option key={index}>{main.main_category_name}</option>;
                                        })}
                                    </Select>
                                    <RightOutlined />
                                    <Select02 onChange={handleSubSelect} value={subSelected}>
                                        {subSelected ? null : <option>소분류 선택</option>}
                                        {data?.sub?.map((sub, index) => {
                                            return (
                                                mainSelected === sub.main_category_name && (
                                                    <option key={index} value={subSelected ? sub.sub_category_name : null}>
                                                        {sub.sub_category_name}
                                                    </option>
                                                )
                                            );
                                        })}
                                    </Select02>
                                </>
                            )}
                            <SelfInput onClick={onClickInputSelfToggle}>{inputSelfToggle ? '선택입력' : '직접입력'}</SelfInput>
                        </SelectWrapper>
                    </DetailTableWrapper>
                </div>

                <TextareaWrapper id="question">
                    <Textarea placeholder="질문을 입력해주세요" onChange={onChangeQuestion} />
                    <Textarea02 placeholder="답변을 입력해주세요" onChange={onChangeAnswer} />
                </TextareaWrapper>
            </div>
        );
    }
}

export default AddQuestionEntry;

const DetailTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 7px;

    margin-top: 3%;
`;

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;
`;

// 직접입력
const CategoryInput = styled.input`
    outline: none;

    width: 27%;
    padding: 5px 3px;

    border: 1px solid #cccccc;
    border-radius: 3px;
`;

// 카테고리 선택
const Select = styled.select`
    cursor: pointer;
    outline: none;

    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border: 0;
    border-radius: 3px;
    background-color: rgb(118 139 172);
`;

const Select02 = styled.select`
    cursor: pointer;
    outline: none;

    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border: 0;
    border-radius: 3px;
    background-color: rgb(19 164 249);
`;

const SelfInput = styled.h3`
    cursor: pointer;
    user-select: none;

    margin: 0;
    padding: 4px 7px;

    background-color: #fff;
    color: rgb(19 164 249);

    border: 1px solid rgb(19 164 249);
    border-radius: 3px;

    text-align: end;
    font-size: 13px;

    :hover {
        background-color: rgb(19 164 249);
        color: #fff;
    }
    :active {
        background-color: #fff;
        color: rgb(19 164 249);
    }
`;

const TextareaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 5px;

    margin-top: 2%;
    width: 100%;
`;

const Textarea = styled.textarea`
    display: block;

    outline: none;
    padding: 5px;

    width: 98.2%;
    height: 3vh;

    border: 1px solid #cccccc;
`;

const Textarea02 = styled.textarea`
    display: block;

    outline: none;
    padding: 5px;

    width: 98.2%;
    height: 7vh;

    border: 1px solid #cccccc;
`;

const LineWrapper = styled.div`
    display: flex;
    align-items: start;

    width: 100%;
`;

const Line = styled.div`
    background-color: #999;
    margin: 0px 0;

    width: 100%;
    height: 1px;
`;

const Delete = styled.div`
    cursor: pointer;
    user-select: none;

    padding: 3px 6px;

    font-size: 14px;
    font-weight: 600;

    background-color: #f55050;
    color: #fff;

    :hover {
        background-color: #ff0000;
    }
`;
