import {
    getSubscribePayments,
    getSubscribePaymentsSearch,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import {GET_SUBSCRIBE_PAYMENTS} from "./subscribePayments_table_type";

export const getSubscribePaymentsAsync = createGetRequestThunk(
  GET_SUBSCRIBE_PAYMENTS,
  getSubscribePayments
);

export const getSubscribePaymentsSearchAsync = createGetRequestThunk(
  GET_SUBSCRIBE_PAYMENTS,
  getSubscribePaymentsSearch
);
