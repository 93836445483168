import axios from 'axios';
import { marketting_main_server_ip } from '../../communication_ips';

const RequestMain = axios.create({
    baseURL: marketting_main_server_ip,
    timeout: 20000,
    params: {},
});

export default RequestMain;
