import React, { useState } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

// moment
import moment from 'moment';

// components
import { WhitePlus1x } from '../../icons/icons';
import ListName from '../list_name/ListName';

const AddScheduleList = ({ dateList }) => {
  // const [scheduleList, setScheculeList] = useState([]);
  const [toggleAddSchedule, setToggleAddSchedule] = useState(false);
  // 매주 일정 여부
  const [everyWeekChecked, setEveryWeekChecked] = useState(false);
  // 일정 협의 여부
  const [discussionScheduleChecked, setDiscussionScheduleChecked] = useState(false);

  const onClickAddBtn = () => {
    setToggleAddSchedule((prev) => !prev);
  };

  const onClickCheckedEveryWeeks = () => {
    setEveryWeekChecked(!everyWeekChecked);
  };

  const onClickDiscussionScheduleChecked = () => {
    setDiscussionScheduleChecked((prev) => !prev);
  };

  return (
    <>
      {/* 일정추가 Modal */}
      {toggleAddSchedule ? (
        <ModalWrapper>
          <ModalBox>
            <CloseBtn onClick={onClickAddBtn} style={{ cursor: 'pointer' }}>
              <CloseOutlined />
            </CloseBtn>

            <ContentWrapper>
              <BoxTitle>일정 추가</BoxTitle>
              <InputWrapper>
                <InputBox>
                  <SubTitle>시작일</SubTitle>

                  <InputBox02>
                    <Input type="date" style={{ color: 'rgb(107 106 106)', width: '186px' }} />
                  </InputBox02>

                  <InputBox02>
                    <Checkbox type="checkbox" checked={everyWeekChecked} onClick={onClickCheckedEveryWeeks} />
                    <SubTitle>매주 일정</SubTitle>
                  </InputBox02>

                  {everyWeekChecked && (
                    <InputBox03>
                      <DayBtn>월</DayBtn>
                      <DayBtn>화</DayBtn>
                      <DayBtn>수</DayBtn>
                      <DayBtn>목</DayBtn>
                      <DayBtn>금</DayBtn>
                      <DayBtn>토</DayBtn>
                      <DayBtn>일</DayBtn>
                    </InputBox03>
                  )}
                </InputBox>

                <InputBox>
                  <SubTitle>시간(24hr)</SubTitle>
                  <InputBox02>
                    <Input placeholder="10:00" /> ~ <Input placeholder="22:00" />
                  </InputBox02>
                  <InputBox02>
                    <Checkbox
                      type="checkbox"
                      checked={discussionScheduleChecked}
                      onClick={onClickDiscussionScheduleChecked}
                    />
                    <SubTitle>일정 협의 필요</SubTitle>
                  </InputBox02>
                </InputBox>

                <InputBox>
                  <SubTitle>모집인원</SubTitle>
                  <InputBox02>
                    <Input type="number" placeholder="직접입력" />
                  </InputBox02>
                  <InputBox02>
                    <Checkbox type="checkbox" />
                    <SubTitle>일정 협의 필요</SubTitle>
                  </InputBox02>
                </InputBox>
              </InputWrapper>
            </ContentWrapper>

            <EndButtonWrapper>
              <AddButton>추가</AddButton>
              <CloseButton onClick={onClickAddBtn}>닫기</CloseButton>
            </EndButtonWrapper>
          </ModalBox>
        </ModalWrapper>
      ) : null}

      <ScheduleListWrapper>
        <ScheduleListButtonWrapper>
          <ListName>일정</ListName>

          <AddScheduleButtonWrapper>
            <AddBtn onClick={onClickAddBtn}>
              <BtnIconWrapper>
                <WhitePlus1x />
                <BtnDiv>추가</BtnDiv>
              </BtnIconWrapper>
            </AddBtn>
          </AddScheduleButtonWrapper>
        </ScheduleListButtonWrapper>

        <div style={{ alignItems: 'center' }}>
          <TableHeader>
            <div style={{ width: '105px' }}>시작일</div>
            <div style={{ width: '105px' }}>시간</div>
            <div style={{ width: '86px' }}>모집인원</div>
          </TableHeader>

          {dateList
            ? dateList.map((item, index) => (
                <TableRow key={index}>
                  <div style={{ width: '105px' }}>{moment(item.start_datetime).format('YYYY-MM-DD')}</div>
                  <div style={{ width: '105px' }}>
                    {moment(item.start_datetime).format('HH:mm')}~{moment(item.end_datetime).format('HH:mm')}
                  </div>
                  <div style={{ width: '86px' }}>{item.max_participants}</div>
                </TableRow>
              ))
            : null}
        </div>
      </ScheduleListWrapper>
    </>
  );
};

export default React.memo(AddScheduleList);

/************************* styled-components *************************/

const AddBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 28px;
  border-width: 0px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: 11px;
  font-family: noto_sans_kr_bold;
  background-color: rgb(66 139 247);
  color: rgb(255 255 255);
`;

const TableHeader = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
  font-family: noto_sans_kr_bold;
  color: rgb(0 0 0);
  border-top: 1px solid rgba(66 139 247 0.2);
  border-bottom: 0.5px solid rgba(66 139 247 0.2);
`;

const TableRow = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
  font-family: noto_sans_kr_regular;
  color: rgb(0 0 0);
  border-bottom: 0.5px solid rgb(216 216 216);
`;

/******************************
 * 시간추가 Modal
 ******************************/
const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  background-color: #0000008a;
  z-index: 999;
`;

const ModalBox = styled.div`
  position: fixed;
  top: 33%;
  left: 40%;

  background-color: #fff;
  border-radius: 10px;

  width: 600px;
  height: 275px;
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  padding: 25px;
`;

const ContentWrapper = styled.div`
  margin: 40px auto;
`;

const BoxTitle = styled.div`
  text-align: start;

  margin: 0 20px;
  margin-bottom: 30px;

  font-size: 25px;
  font-weight: bold;
  color: #428bf7;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Input = styled.input`
  outline: none;

  width: 100px;
  height: 22px;

  border: 1px solid #d6d6d6;

  // 달력 이모지 커서 포인트
  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`;

const Checkbox = styled.input`
  cursor: pointer;
  border: 1px solid blue;
  margin: 0 5px 0 0;
`;

const InputBox = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 5px;
`;

const InputBox02 = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 5px;
`;

const InputBox03 = styled.div`
  display: flex;
  align-items: center;

  margin-top: 10px;
`;

const DayBtn = styled.button`
  cursor: pointer;
  width: 25px;
  height: 25px;

  font-size: 13px;

  border: 0;
  margin: 0 1px;

  :hover {
    background-color: #428bf7;
  }
`;

const SubTitle = styled.div`
  font-size: 13px;
`;

const EndButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
`;

const AddButton = styled.button`
  cursor: pointer;
  width: 90px;
  height: 27px;

  border: 0;

  background-color: #428bf7;
  color: #fff;
`;

const CloseButton = styled.button`
  cursor: pointer;
  width: 90px;
  height: 27px;

  border: 1px solid #d6d6d6;

  background-color: #fff;
  color: black;
`;

/*********************** 여기까지 Modal ***********************/

const ScheduleListWrapper = styled.div`
  position: relative;

  margin-bottom: 12px;
  margin-right: 4px;
`;

const ScheduleListButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
`;

const AddScheduleButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-left: 26px;
`;

const BtnIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BtnDiv = styled.div`
  margin-left: 2px;
`;
