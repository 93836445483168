import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { Image } from '../../designComponents/imageboxs/Image';
import { LText, SText } from '../../designComponents/text/ContentText';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { CheckboxList } from '../../designComponents/checkboxs/CheckboxInput';
import { dateFilter } from '../../utils/textFilter';
import { TextArea } from '../../designComponents/inputs/TextArea';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { useCallback } from 'react';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_ip } from '../../communication_system/communication_ips';

const ProductDetailInfo = ({ data, detailProduct, setDetailProduct }) => {
    return (
        <FlexMainWrapper style={{ flexDirection: 'column', padding: '20px' }}>
            <FlexMainWrapper style={{ alignItems: 'start', gap: 0 }} mobileOn>
                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', flex: 1, marginBottom: '20px' }}>
                    <LText text={'상품 정보'} style={{ marginBottom: '10px' }} />
                    <FlexMainWrapper style={{ alignItems: 'start', gap: '20px' }}>
                        <Image url={data.productImages[0].url} alt="상품이미지" style={{ border: '1px solid #c7c7c7', borderRadius: '10px' }} cover="cover" width={150} height={150} />
                        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <InputLine
                                title={'상품명'}
                                value={data.product.name}
                                wrapperStyle={{ margin: 0 }}
                                titleStyle={{ textAlign: 'start', width: '60px' }}
                                inputStyle={{ width: '250px' }}
                                onChange={(e) => {
                                    let obj = JSON.parse(JSON.stringify(detailProduct));
                                    obj.data.product.name = e.target.value;
                                    setDetailProduct(obj);
                                }}
                            />
                            <InputLine
                                title={'상품 설명'}
                                value={data.product.promotional_title}
                                wrapperStyle={{ margin: 0 }}
                                titleStyle={{ textAlign: 'start', width: '60px' }}
                                inputStyle={{ width: '250px' }}
                                onChange={(e) => {
                                    let obj = JSON.parse(JSON.stringify(detailProduct));
                                    obj.data.product.promotional_title = e.target.value;
                                    setDetailProduct(obj);
                                }}
                            />
                            <InputLine
                                title={'정상가'}
                                value={data.product.list_price}
                                wrapperStyle={{ margin: 0 }}
                                titleStyle={{ textAlign: 'start', width: '60px' }}
                                inputStyle={{ width: '250px' }}
                                onChange={(e) => {
                                    let obj = JSON.parse(JSON.stringify(detailProduct));
                                    obj.data.product.list_price = e.target.value;
                                    setDetailProduct(obj);
                                }}
                            />
                            <InputLine
                                title={'할인가'}
                                value={data.product.discount_price}
                                wrapperStyle={{ margin: 0 }}
                                titleStyle={{ textAlign: 'start', width: '60px' }}
                                inputStyle={{ width: '250px' }}
                                onChange={(e) => {
                                    let obj = JSON.parse(JSON.stringify(detailProduct));
                                    obj.data.product.discount_price = e.target.value;
                                    setDetailProduct(obj);
                                }}
                            />
                            <CheckboxList
                                title={'노출 여부'}
                                wrapperStyle={{ marginTop: '7px', marginBottom: '7px' }}
                                titleStyle={{ width: '58px' }}
                                gap={'10px'}
                                itemLists={[
                                    {
                                        subject: 'Y',
                                        checked: data.product.avail_yn === 1,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = JSON.parse(JSON.stringify(detailProduct));
                                                obj.data.product.avail_yn = 1;
                                                setDetailProduct(obj);
                                            }
                                        },
                                    },
                                    {
                                        subject: 'N',
                                        checked: data.product.avail_yn !== 1,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = JSON.parse(JSON.stringify(detailProduct));
                                                obj.data.product.avail_yn = 0;
                                                setDetailProduct(obj);
                                            }
                                        },
                                    },
                                ]}
                            />
                            <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                                <InputLine
                                    title={'수량'}
                                    value={data.options[0]?.inventory_cnt === undefined ? '수량 미지정' : data.options[0]?.inventory_cnt}
                                    wrapperStyle={{ margin: 0 }}
                                    titleStyle={{ textAlign: 'start', width: '60px' }}
                                    inputStyle={{ width: '150px' }}
                                    onChange={(e) => {
                                        let obj = JSON.parse(JSON.stringify(detailProduct));
                                        obj.data.options[0].inventory_cnt = e.target.value;
                                        setDetailProduct(obj);
                                    }}
                                />
                                {Number(data.options[0]?.inventory_cnt) !== 0 ? (
                                    <MainButton
                                        style={{ borderRadius: 0, height: '31px', width: '100px' }}
                                        text={'판매 종료 처리'}
                                        onClick={() => {
                                            let obj = JSON.parse(JSON.stringify(detailProduct));
                                            obj.data.options[0].inventory_cnt = 0;
                                            setDetailProduct(obj);
                                        }}
                                    />
                                ) : null}
                            </FlexMainWrapper>

                            <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                                <InputLine
                                    title={'판매처'}
                                    placeholder={'판매처 정보가 없습니다.'}
                                    value={data.product.refund_policy}
                                    wrapperStyle={{ margin: 0 }}
                                    titleStyle={{ textAlign: 'start', width: '60px' }}
                                    inputStyle={{ width: '190px' }}
                                    onChange={(e) => {
                                        let obj = JSON.parse(JSON.stringify(detailProduct));
                                        obj.data.product.refund_policy = e.target.value;
                                        setDetailProduct(obj);
                                    }}
                                />
                                <MainButton
                                    style={{ borderRadius: 0, height: '31px' }}
                                    text={data.product.refund_policy ? <LinkTagBtn url={data.product.refund_policy} text={'사이트로'} /> : '정보없음'}
                                />
                            </FlexMainWrapper>
                            <FlexMainWrapper style={{ alignItems: 'start', marginTop: '10px' }}>
                                <SText text={'메모'} style={{ width: '63px', textAlign: 'start', fontWeight: '500' }} color="#797979" />
                                <TextArea
                                    value={data.product.admin_memo}
                                    placeholder={'내용을 입력하세요.'}
                                    style={{ width: '263px', height: '15vh' }}
                                    onChange={(e) => {
                                        let obj = JSON.parse(JSON.stringify(detailProduct));
                                        obj.data.product.admin_memo = e.target.value;
                                        setDetailProduct(obj);
                                    }}
                                />
                            </FlexMainWrapper>
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>

                <FlexMainWrapper style={{ alignItems: 'start', flex: 1, flexDirection: 'column' }}>
                    <LText text={'판매처 정보'} style={{ marginBottom: '10px' }} />
                    <FlexMainWrapper style={{ alignItems: 'start', width: '100%', gap: '20px' }}>
                        <Image url={data.host.image} style={{ border: '1px solid #c7c7c7', borderRadius: '10px' }} width={150} height={150} />
                        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '100%', marginBottom: '20px', gap: '20px' }}>
                            <FlexTwoText text01={'판매처ID'} text02={data.host.id} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <FlexTwoText text01={'대표자명'} text02={data.host.owner} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <FlexTwoText text01={'대표번호'} text02={data.host.represent_number} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <FlexTwoText text01={'판매처명'} text02={data.host.name} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <FlexTwoText text01={'판매처 주소'} text02={`${data.host.address}, ${data.host.address_detail}`} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <FlexTwoText text01={'우편번호'} text02={data.host.zone_code} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <FlexTwoText text01={'사업자 번호'} text02={data.host.business_number} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <FlexTwoText text01={'등록일'} text02={dateFilter(data.host.created_at)} text01Style={{ width: '80px', textAlign: 'start' }} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </FlexMainWrapper>
            <SText text={'상품 이미지'} />
            <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                {/*<input type="file" name="host-logo-image" multiple hidden ref={imageInput} onChange={onChangeImages} />*/}
                {/*<InputLine*/}
                {/*    title={'이미지 등록'}*/}
                {/*    placeholder={'이미지를 등록해 주세요.'}*/}
                {/*    wrapperStyle={{ margin: 0 }}*/}
                {/*    titleStyle={{ textAlign: 'start', width: '60px' }}*/}
                {/*    inputStyle={{ width: '190px' }}*/}
                {/*    disabled={true}*/}
                {/*/>*/}
                {/*<MainButton style={{ borderRadius: 0, height: '31px' }} text={'추가'} onClick={onClickImageUpload} />*/}
            </FlexMainWrapper>
            <FlexMainWrapper style={{ alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}>
                {data.productImages.map((item, index) => (
                    <Image key={index} url={item.url} alt="상품이미지" style={{ border: '1px solid #c7c7c7', borderRadius: '10px' }} cover="cover" width={95} height={95} />
                ))}
            </FlexMainWrapper>
            <div style={{ width: '100%', borderTop: '1px solid #c7c7c7', margin: '10px 0' }} />
            <LText text={'상품 상세 정보'} />
            <div style={{ width: '100%', borderTop: '1px solid #c7c7c7', margin: '10px 0' }} />
            <HTML_PARSER>{ReactHtmlParser(data.product.text_info)}</HTML_PARSER>
        </FlexMainWrapper>
    );
};

export default ProductDetailInfo;

const LinkTagBtn = ({ url, text = '클릭' }) => {
    return (
        <a href={url} target="parent" style={{ fontSize: '13px', textDecoration: 'none', color: '#fff' }}>
            {text}
        </a>
    );
};

const HTML_PARSER = styled.div`
    img {
        width: 80%;
    }
    p {
        padding: 0px 40px;
    }
`;
