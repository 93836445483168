import React from 'react';
import { DateInput, InputLine, SelectEntry, TwoCheckBox } from '../inputs/InputLine';

export const InputItemLists = ({ items = [], wrapperStyle, titleStyle, inputStyle }) => {
    return items.map((item, index) => {
        if (item.dateInput)
            return (
                <DateInput
                    wrapperStyle={wrapperStyle}
                    titleStyle={titleStyle}
                    inputStyle={inputStyle}
                    title={item.title}
                    onChange={item.onChange}
                    name={item.name}
                    value={item.value}
                />
            );
        return item.selectYN ? (
            <SelectEntry key={index} option={item.option} title={item.title} titleStyle={titleStyle} onChange={item.onChange} />
        ) : (
            <InputLine
                type={item.type}
                wrapperStyle={wrapperStyle}
                titleStyle={titleStyle}
                key={index}
                title={item.title}
                value={item.value}
                onChange={item.onChange}
                placeholder={item.placeholder}
                disabled={item.disabled}
            />
        );
    });
};

export const InputTwoCheckBox = ({ title, items = [], titleStyle }) => {
    return <TwoCheckBox title={title} items={items} titleStyle={titleStyle} />;
};
