import React from 'react';

// components
import UserPieChart from './UserPieChart';
import LineChartCompoenent from './LineChartCompoenent';
import UserActivityTable from './UserActivityTable';
import { Wrapper02, Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';

// 회원통계 페이지
function UserStatistic() {
    return (
        <div>
            <Wrapper03 style={{ marginBottom: '1%', padding: '3%' }}>
                <UserPieChart />
            </Wrapper03>

            <Wrapper03 style={{ marginBottom: '3%', padding: '3%' }}>
                <LineChartCompoenent />
            </Wrapper03>

            <Wrapper03  style={{ marginBottom: '3%', padding: '3%' }}>
                <UserActivityTable/>
            </Wrapper03>

        </div>
    );
}

export default UserStatistic;
