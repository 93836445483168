import React, { useCallback, useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// react-query && axios $ URL
import { main_server_image_ip } from '../../communication_system/communication_ips';

/** 일정 결과 Entry */
function SearchModalScheduleEntry({ item, index, funcParam, setToggle }) {
    const [clickToggle, setClickToggle] = useState(false);

    useEffect(() => {
        setClickToggle(item.id === funcParam.contentsID);
    }, [funcParam.contentsID, item.id]);

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /** 결과 선택 */
    const onClickResultID = useCallback(
        (item) => () => {
            funcParam.setContentsID(item.id);
            funcParam.setContentsName(item.title);
            setToggle(false);
        },
        [funcParam, setToggle],
    );

    return (
        <ColumnWrapper key={index} onClick={onClickResultID(item)} clickToggle={clickToggle}>
            <div>
                <Images
                    sizes={100}
                    src={main_server_image_ip + item.gathering_image}
                    alt="모임 이미지"
                    onError={handleImgError}
                />
            </div>
            <div>
                <ColWrapper className="pay-contents">
                    <Subtitle>일정ID</Subtitle>
                    <SubInfo>
                        <span>{item.id}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>일정명</Subtitle>
                    <SubInfo>
                        <span>{item.title}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>모임명</Subtitle>
                    <SubInfo>
                        <span>{item.gathering_name}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>
                        <span>일정개설자</span>
                    </Subtitle>
                    <SubInfo>{item.schedule_creater}</SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>참여인원</Subtitle>
                    <SubInfo>
                        <span>{item.join_users_count || 0}명</span>
                    </SubInfo>
                </ColWrapper>
            </div>
        </ColumnWrapper>
    );
}

export default SearchModalScheduleEntry;

const Images = styled.img`
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

const ColumnWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 10px;

    padding-top: 5px;
    width: 100%;

    background-color: ${(props) => (props.clickToggle ? '#00000038' : '')};

    &:hover {
        background-color: #00000038;
    }
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
    width: 100%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: #fff;

    width: 135px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 100%;
    color: #fff;
`;
