// ENV CHECK
exports.IsProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

// imageUrlFilter - JS
exports.imageUrlFilter = (url) => {
    const { main_server_image_ip } = require('../communication_system/communication_ips');

    if (!url) {
        return null;
    }

    if (url.indexOf('http') < 0) {
        return main_server_image_ip + url;
    } else {
        return url;
    }
};
