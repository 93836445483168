import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';

import { InputLine } from '../../designComponents/inputs/InputLine';

const componetsLists = {
    accountId: '계좌 ID',
    commentId: '댓글 ID',
    contentsId: '게시글 ID',
    gatheringId: '모임 ID',
    chattingId: '채팅 ID',
    payId: '주문 ID',
    initProductId: '상품 ID',
    pointYn: '포인트 노출 유무',
    sinorBlackType: '시놀 블랙 type',
};

const TypeComponent = forwardRef(({ types }, _ref) => {
    const [param, setParam] = useState({});

    useImperativeHandle(_ref, () => ({
        paramsData: param,
    }));

    return (
        <FlexMainWrapper style={{ flexDirection: 'column', gap: 0 }}>
            {Object.keys(types).map((key, index) => {
                return (
                    <FlexMainWrapper key={index} style={{ flexDirection: 'column', width: '100%' }}>
                        <InputLine
                            wrapperStyle={{ margin: '5px 0', width: '100%' }}
                            inputStyle={{ width: '200px' }}
                            titleStyle={{ width: '97px', fontWeight: 'bold' }}
                            title={types[key]}
                            value={param[key]}
                            placeholder={types[key]}
                            onChange={(e) => {
                                let obj = { ...param };
                                obj[key] = e.target.value;
                                setParam(obj);
                            }}
                        />
                    </FlexMainWrapper>
                );
            })}
        </FlexMainWrapper>
    );
});

export default TypeComponent;
