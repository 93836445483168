import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';
import { LeftOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import { ModalWrapper, Box, MobileBox, LoadinLogoWrapper, Alert, SearchingAddressWrapper, ModalBox, SettingDateModal, ChooseDateModalBox } from '../../styles/ReadScheduleModalStyled'; //CreateTopWrapper
import {
    ImageWrapper,
    ImageBox,
    ImageComponent,
    CustomCloseOutlined,
    MainPic,
    Image,
    CreateTopWrapper,
    InputWrapper,
    InputWrapper02,
    CheckMainAddressBox,
    SubTitle,
    Textarea,
    Input,
    SearchBoxWrapper,
    Button,
    ButtonWeapper,
} from '../../styles/AddGatheringModalStyled';
import { CommentBox, UserImage, Name, Comment, CommentDate, DeleteComment } from '../../Common/Styles/CommentStyled';

// useInput
import useInput from '../../../hook/useInput';

// API
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_image_ip } from '../../../communication_system/communication_ips';

// 다음 주소 검색 API
import DaumPostcode from 'react-daum-postcode';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getScheduleDetail, updateScheduleDetail, RESET_STATE } from '../../../data_system/redux/modules/gathering.reducer/gathering.schedule.reducer';

import { getCommentsLists } from '../../../data_system/redux/modules/reports.reducer/reports.reducer';
import { deleteGatheringContentsComment } from '../../../data_system/redux/modules/gathering.reducer/gatheringContents.reducer';

// components
import JoinUserListsModal from './JoinUserListsModal';
import ShowCommentsModal from './ShowCommentsModal';
import Comments from '../Comments';
import { MainButton } from '../../../designComponents/buttons/Buttons';
import RequestMain from '../../../communication_system/axios_apis/refresh_check/requestMain';
import FlexMainWrapper from '../../../designComponents/wrappers/FlexMainWrapper';
import { TitleCheckboxInput } from '../../../designComponents/checkboxs/CheckboxInput';

// 일정조회 Modal
function ReadScheduleModal({ setToggle, setImageToggle, setSrc, tableRef, data, id }) {
    const dispatch = useDispatch();

    // useState
    const [height, setHeight] = useState(0);
    const [updateToggle, setUpdateToggle] = useState(true); // 업데이트 전환 토글
    const [successAlert, setSuccessAlert] = useState(false); // 업데이트 성공 알림 토글

    const [chooseDateToggle, setChooseDateToggle] = useState(false); // 일정선택 토글
    const [searchAddressToggle, setSearchAddressToggle] = useState(false); // 일정선택 토글
    const [searchJoinUserToggle, setSearchJoinUserToggle] = useState(false); // 일정선택 토글
    const [showCommentToggle, setShowCommentToggle] = useState([false, 0]); // 댓글 조회 토글

    // useSelector
    const { scheduleList, updateScheduleDetailDone, updateScheduleDetailLoading, updateScheduleDetailError } = useSelector((state) => state.scheduleReducer);
    const { comments } = useSelector((state) => state.reportsReducer);

    // 데이터 상태 값
    const [gatheringName, onChangeGatheringName, setGatheringName] = useInput(scheduleList?.gathering_id || '정보없음');
    const [gatheringPhoneNum, onChangeGatheringPhoneNum, setGatheringPhoneNum] = useInput(scheduleList?.phone_no || '정보없음');
    const [creater, onChangeCreater, setCreater] = useInput(scheduleList?.schedule_creater || '정보없음');
    const [title, onChangeTitle, setTitle] = useInput(scheduleList?.title || '정보없음');
    /** DATE */
    const [originalDate, setOriginalDate] = useState('');
    const [startDate, setStartDate] = useState(scheduleList?.start_date || '정보없음');
    const [startTime, setStartTime] = useState(scheduleList?.start_time || '정보없음');
    const [endDate, setEndDate] = useState(scheduleList?.end_date || '정보없음');
    const [endTime, setEndTime] = useState(scheduleList?.end_time || '정보없음');

    const [address, setAddress] = useState(scheduleList?.address || '정보없음');
    const [detail, onChangeDetail, setDetail] = useInput(scheduleList?.detail || '정보없음');
    const [maxParticipants, onChangeMaxParticipants, setMaxParticipants] = useInput(scheduleList?.maxParticipants || 0);
    const [price, onChangePrice, setPrice] = useInput(scheduleList?.price || 0);
    const [content, onChangeContent, setContent] = useInput(scheduleList?.explanation || '정보없음');
    const [gatheringImageLists, setGatheringImageLists] = useState(scheduleList?.gathering_image_lists || []);
    const [ownerYN, setOwnerYN] = useState(1);
    const [localPayYn, setLocalPayYn] = useState(1);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                if (searchJoinUserToggle) {
                    return;
                } else {
                    closeModal();
                }
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchJoinUserToggle]);

    // 업데이트 성공 시 Alert 창
    useEffect(() => {
        if (updateScheduleDetailDone) {
            setSuccessAlert(true);
            // setUpdateToggle(false);
        }

        setTimeout(() => {
            setSuccessAlert(false);

            dispatch({
                type: RESET_STATE,
            });
        }, 1500);
    }, [dispatch, updateScheduleDetailDone]);

    // 데이터 상태 적용
    useEffect(() => {
        resetAndApplyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleList]);

    // 상세 데이터 요청
    useEffect(() => {
        dispatch(getScheduleDetail(id));
    }, [dispatch, id]);

    // 테이블 높이적용
    useEffect(() => {
        setHeight(tableRef.current.offsetHeight);
    }, [tableRef]);

    // 데이터 조회
    useEffect(() => {
        dispatch(getCommentsLists(id));
    }, [dispatch, showCommentToggle, id]);

    /******************************************* function *******************************************/

    // 댓글 삭제하기
    const onClickDeleteComment = (commnet_id) => () => {
        if (window.confirm('댓글을 삭제하시겠습니까?')) {
            dispatch(deleteGatheringContentsComment(commnet_id));
        }
    };

    // 데이터 상태 적용
    const resetAndApplyData = () => {
        setGatheringName(scheduleList.gathering_name);
        setGatheringPhoneNum(scheduleList.phone_no);
        setCreater(scheduleList.schedule_creater);
        setTitle(scheduleList.title);
        setOriginalDate(`${startDate}T${startTime}`);
        setStartDate(scheduleList.start_date);
        setStartTime(scheduleList.start_time);
        setEndDate(scheduleList.end_date);
        setEndTime(scheduleList.end_time);
        setAddress(scheduleList.address);
        setDetail(scheduleList.detail);
        setMaxParticipants(scheduleList.max_participants);
        setPrice(scheduleList.price);
        setContent(scheduleList.content);
        setGatheringImageLists(scheduleList?.contents_image_lists ? scheduleList?.contents_image_lists : []);
        setOwnerYN(scheduleList.owner_yn);
        setLocalPayYn(scheduleList.local_pay_yn);
    };

    // 모달창 닫기
    const closeModal = () => {
        setToggle(false);
    };

    // 이미지 크게보기
    const onClickShowImage = (src) => () => {
        setSrc(src);
        setImageToggle((prev) => !prev);
    };

    // 이미지 제거
    const onClickDeleteImage = (index) => () => {
        let arr = [...gatheringImageLists];
        arr.splice(index, 1);

        setGatheringImageLists(arr);
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback(async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });

        const response = await RefreshCheckApi.post('/adminGathering/uploadImage', imageFormData);
        setTimeout(() => {
            setGatheringImageLists(response.data);
        }, 1100);
    }, []);

    // 클릭 업데이트 Toggle
    const onClickUpdate = (boolean) => () => {
        if (boolean) {
            if (window.confirm('수정된 내용은 저장되지 않습니다. 취소하시겠습니까?')) {
                resetAndApplyData();
                setUpdateToggle((prev) => !prev);
            }
        } else {
            resetAndApplyData();
            setUpdateToggle((prev) => !prev);
        }
    };

    // alert 창 닫기
    const onClickCloseAlert = () => {
        setSuccessAlert(false);
    };

    // 일정선택 토글
    const onClickChooseDate = () => {
        console.log('hrer');

        setChooseDateToggle((prev) => !prev);
    };

    // 주소검색 토글
    const onClickSearchAddress = () => {
        setSearchAddressToggle((prev) => !prev);
    };

    // 참가원 조회 토글
    const onClickSearchJoinUser = () => {
        setSearchJoinUserToggle((prev) => !prev);
    };

    // 다음 주소 검색 스타일 정의 code
    const postCodeStyle = {
        width: '400px',
        height: '700px',
    };

    // 다음 주소검색 결과
    const onCompletePost = (data) => {
        if (data) {
            setSearchAddressToggle(false);
            setAddress(`${data.sido} ${data.sigungu}`);
        }
    };

    // 집결일정
    const onChangeDate = (e) => {
        setOriginalDate(e.target.value);
        setStartDate(moment(e.target.value).format('YYYY-MM-DD'));
        setStartTime(moment(e.target.value).format('HH:mm:ss'));
    };

    // 종료시간
    // eslint-disable-next-line no-unused-vars
    const onChangeEndDate = (e) => {
        let endDate = moment(e.target.value).format('YYYY-0M-DD');
        let endTime = moment(e.target.value).format('HH:mm:ss');
        if (Boolean(`${startDate} ${startTime}` < `${endDate} ${endTime}`)) {
            setEndDate(endDate);
            setEndTime(endTime);
        } else {
            alert('시작일보다 종료일자가 빠를 수 없습니다. 확인해주세요.');
        }
    };

    // 일자 추가, 모달 종료
    const onClickAddDateToggle = () => {
        if (!startDate || !startTime) {
            return alert('일자를 확인해주세요.');
        }
        setChooseDateToggle(false);
    };

    /*********************** [수정본 저장] ***********************/

    const onClickSave = () => {
        let param = {
            id: id,
            gathering_id: scheduleList.gathering_id,
            gathering_name: gatheringName,
            phone_no: gatheringPhoneNum,
            schedule_creater: creater,
            title: title,
            start_date: startDate,
            start_time: startTime,
            address: address,
            detail: detail,
            max_participants: maxParticipants,
            price: price,
            content: content,
            gathering_image_lists: gatheringImageLists,
            owner_yn: ownerYN,
            local_pay_yn: localPayYn,
        };
        if (window.confirm('수정된 내용을 저장하시겠습니까?')) {
            dispatch(updateScheduleDetail(param));
            if (!updateScheduleDetailLoading && updateScheduleDetailDone) {
                const response = RefreshCheckApi.get(`/adminGathering/schedule/detail?schedule_id=${id}`);
                response
                    .then((response) => {
                        resetAndApplyData();
                        alert('수정한 내용이 저장되었습니다.');
                    })
                    .catch((err) => {
                        console.error(err);
                        alert('[sever error] 관리자에게 문의해주세요.');
                    });
            }
        }
    };

    // 댓글보기
    const onClickShowComment = (id) => () => {
        setShowCommentToggle([true, id]);
    };

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /************************* html *************************/
    return (
        <Wrapper>
            <label id="label">댓글보기 모달창</label>
            {showCommentToggle[0] && <ShowCommentsModal showCommentToggle={showCommentToggle} setShowCommentToggle={setShowCommentToggle} />}

            <label id="label">저장 결과 알람창</label>
            {successAlert && (
                <Alert>
                    <CloseOutlined className="close" onClick={onClickCloseAlert} />
                    <div>수정 내용이 저장되었습니다.</div>
                </Alert>
            )}

            <label id="label">일정 시간선택 모달창</label>
            {chooseDateToggle && (
                <SettingDateModal>
                    <ChooseDateModalBox>
                        <div className="close-window">
                            <CloseOutlined className="close-icon" onClick={() => setChooseDateToggle(false)} />
                        </div>

                        <h2 className="title">일정선택</h2>
                        <h4>시작시간</h4>
                        <input className="date" type="datetime-local" onChange={onChangeDate} value={originalDate} />
                        <div className="add-btn" onClick={onClickAddDateToggle}>
                            추가
                        </div>
                    </ChooseDateModalBox>
                </SettingDateModal>
            )}

            <label id="label">주소검색 모달창</label>
            {searchAddressToggle && (
                <SearchingAddressWrapper>
                    <ModalBox>
                        <div className="close-window">
                            <CloseOutlined className="close-icon" onClick={onClickSearchAddress} />
                        </div>
                        <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} />
                    </ModalBox>
                </SearchingAddressWrapper>
            )}

            <label id="label">참여회원 조회 모달창</label>
            {searchJoinUserToggle && <JoinUserListsModal contentsId={id} searchJoinUserToggle={searchJoinUserToggle} setSearchJoinUserToggle={setSearchJoinUserToggle} />}

            <MobileBox className="mobile-box">
                <ModalWrapper className="modal-wrapper">
                    <Box className="box" height={height} limit={0} style={{ position: 'relative' }}>
                        <CreateTopWrapper style={{ position: 'absolute' }} updateToggle={updateToggle}>
                            <LeftOutlined className="close-button" onClick={closeModal} />
                            <div>{updateToggle ? '일정수정' : '일정조회'}</div>
                        </CreateTopWrapper>

                        {updateScheduleDetailLoading ? (
                            <LoadinLogoWrapper>
                                <LoadingOutlined />
                                <div className="text">loading...</div>
                            </LoadinLogoWrapper>
                        ) : (
                            <>
                                <InputWrapper id="gathering-name" style={{ marginTop: '17%' }}>
                                    <SubTitle>일정ID</SubTitle>
                                    <Input type="text" value={scheduleList.id || '정보없음'} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>댓글 수</SubTitle>
                                    <SearchBoxWrapper>
                                        <Input type="text" placeholder="집결일정을 선택해주세요." value={scheduleList.comments_count + '개' || '정보없음'} readOnly disabled />
                                        <Button onClick={onClickShowComment(scheduleList.id)}>댓글보기</Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>모임ID</SubTitle>
                                    <Input type="text" value={scheduleList.gathering_id || '정보없음'} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>모임명</SubTitle>
                                    <Input type="text" value={gatheringName || '정보없음'} onChange={onChangeGatheringName} disabled={!updateToggle} />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>모임장 명</SubTitle>
                                    <Input type="text" value={scheduleList.owner_name || '정보없음'} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>
                                        <div>모임장</div>
                                        <div>전화번호</div>
                                    </SubTitle>
                                    <Input type="text" value={gatheringPhoneNum || '정보없음'} onChange={onChangeGatheringPhoneNum} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>주최자 명</SubTitle>
                                    <Input type="text" placeholder="모임 이름을 입력해주세요." disabled={!updateToggle} value={creater || '정보없음'} onChange={onChangeCreater} />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>일정 명</SubTitle>
                                    <Input type="text" placeholder="모임 이름을 입력해주세요." disabled={!updateToggle} value={title} onChange={onChangeTitle} />
                                </InputWrapper>

                                <FlexMainWrapper>
                                    <TitleCheckboxInput
                                        exposure
                                        gap={27}
                                        text={'현장 납부'}
                                        text01={'가능'}
                                        text02={'불가능'}
                                        titleStyle={{ width: '60px', marginLeft: '30px', color: '#797979' }}
                                        checkedTitleStyle={{ fontWeight: 400 }}
                                        checked01={localPayYn === 1}
                                        checked02={localPayYn === 0}
                                        onChange01={(e) => e.target.checked && updateToggle && setLocalPayYn(1)}
                                        onChange02={(e) => e.target.checked && updateToggle && setLocalPayYn(0)}
                                    />
                                </FlexMainWrapper>

                                <InputWrapper>
                                    <SubTitle>집결 일정</SubTitle>
                                    <SearchBoxWrapper>
                                        <Input type="text" placeholder="집결일정을 선택해주세요." value={`${startDate} ${startTime}`} readOnly />
                                        <Button disabled={!updateToggle} updateToggle={updateToggle} onClick={onClickChooseDate}>
                                            시간 선택
                                        </Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <InputWrapper>
                                    <SubTitle>집결 장소</SubTitle>
                                    <SearchBoxWrapper>
                                        <Input type="text" placeholder="주소" value={address} onChange={(e) => setAddress(e.target.value)} />
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>최대인원</SubTitle>
                                    <Input type="text" placeholder="0" disabled={!updateToggle} value={maxParticipants} onChange={onChangeMaxParticipants} />
                                </InputWrapper>

                                <FlexMainWrapper>
                                    <TitleCheckboxInput
                                        exposure
                                        gap={27}
                                        text={'모임장 참석'}
                                        text01={'참석'}
                                        text02={'미참석'}
                                        titleStyle={{ marginLeft: '30px', color: '#797979' }}
                                        checkedTitleStyle={{ fontWeight: 400 }}
                                        checked01={ownerYN === 1}
                                        checked02={ownerYN === 0}
                                        onChange01={(e) => e.target.checked && updateToggle && setOwnerYN(1)}
                                        onChange02={(e) => e.target.checked && updateToggle && setOwnerYN(0)}
                                    />
                                </FlexMainWrapper>

                                {/* 참가원 조회 Query 작성해야 함 */}
                                <InputWrapper>
                                    <SubTitle>현재 참가원</SubTitle>
                                    <SearchBoxWrapper>
                                        <Input type="text" placeholder="현재 참가원이 없습니다." value={(scheduleList.crew_count || 0) + ownerYN + '명'} readOnly />
                                        <Button updateToggle={updateToggle} onClick={onClickSearchJoinUser}>
                                            회원조회
                                        </Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>참가비</SubTitle>
                                    <Input type="text" placeholder="0" disabled={!updateToggle} value={price?.toLocaleString('ko-KR')} onChange={onChangePrice} />
                                </InputWrapper>

                                <InputWrapper02 id="gathering-introduce">
                                    <SubTitle>일정 상세 내용</SubTitle>
                                    <Textarea
                                        type="text"
                                        placeholder="*일정 소개를 해주세요!&#13;&#10;*2차 장소가 있다면 시간과 장소를&#13;&#10;알려주면 좋아요!"
                                        disabled={!updateToggle}
                                        value={content}
                                        onChange={onChangeContent}
                                    />
                                </InputWrapper02>

                                <InputWrapper>
                                    <SubTitle>모바일 이미지</SubTitle>

                                    <SearchBoxWrapper>
                                        <Input
                                            type="text"
                                            placeholder="일정 이미지가 없습니다."
                                            readOnly
                                            disabled
                                            value={gatheringImageLists?.map((url) => {
                                                return url;
                                            })}
                                        />
                                        <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                                        <Button onClick={onClickAddImage} disabled={!updateToggle} updateToggle={updateToggle}>
                                            찾기
                                        </Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <ImageWrapper>
                                    <ImageBox>
                                        {gatheringImageLists?.map((src, index) => {
                                            return (
                                                <ImageComponent key={index}>
                                                    {updateToggle && <CustomCloseOutlined onClick={onClickDeleteImage(index)} />}

                                                    <Image key={index} src={main_server_image_ip + src} alt="모임사진" onClick={onClickShowImage(main_server_image_ip + src)} />

                                                    {index === 0 ? <MainPic>대표사진</MainPic> : null}
                                                </ImageComponent>
                                            );
                                        })}
                                    </ImageBox>
                                </ImageWrapper>

                                <ButtonWeapper style={{ marginBottom: '5%' }}>
                                    {updateToggle ? (
                                        <button className="cencle" onClick={onClickUpdate(true)}>
                                            수정 취소
                                        </button>
                                    ) : (
                                        <button className="cencle" onClick={closeModal}>
                                            취소
                                        </button>
                                    )}

                                    <MainButton
                                        text="일정취소"
                                        styleReverse
                                        style={{ border: '1px solid rgb(198 3 92)', zIndex: 1 }}
                                        fontStyle={{ color: 'rgb(198 3 92)' }}
                                        onClick={async () => {
                                            const message = '해당 일정을 취소 하시겠습니까? 참여비는 참가한 회원들에게 자동 환불됩니다.';
                                            try {
                                                if (window.confirm(message)) {
                                                    const response = await RequestMain.post('/auth/testLogin', {
                                                        id: scheduleList.owner_id,
                                                    });
                                                    const token = response.data.data.access_token;
                                                    // 토큰이 있으면 일정 취소 요청
                                                    if (token) {
                                                        const config = {
                                                            headers: { Authorization: `Bearer ${token}` },
                                                        };
                                                        const response = await RequestMain.post('/gatheringDate/cancel', { contents_id: id }, config);
                                                        if (response.status === 200 && response.data.ok) {
                                                            alert('일정 취소가 완료되었습니다.');
                                                            closeModal();
                                                        }
                                                    }
                                                }
                                            } catch (error) {
                                                console.error('error: ', error);
                                            }
                                        }}
                                    />

                                    {updateToggle ? (
                                        <button className="save" onClick={onClickSave}>
                                            저장
                                        </button>
                                    ) : (
                                        <button className="update" onClick={onClickUpdate(false)}>
                                            수정
                                        </button>
                                    )}
                                </ButtonWeapper>
                            </>
                        )}

                        <div style={{ height: '100%', overflow: 'auto', padding: '0 20px' }}>
                            <h5>댓글</h5>
                            {comments.length >= 1
                                ? comments?.map((data, index) => {
                                      return (
                                          <>
                                              <CommentBox key={index} style={{ margin: '0 10px' }}>
                                                  <UserImage key={index} sizes={45} src={main_server_image_ip + data.url} alt="회원이미지" onError={handleImgError} />
                                                  <div style={{ width: '100%' }}>
                                                      <Name>
                                                          {data.name}({data.nick_name})<CommentDate>{moment(data.created_at).format('YYYY년 MM월 DD일')}</CommentDate>
                                                      </Name>
                                                      <Comment>{data.comment}</Comment>
                                                      <DeleteComment>
                                                          <span className="delte-btn" onClick={onClickDeleteComment(data.id)}>
                                                              삭제
                                                          </span>
                                                      </DeleteComment>
                                                  </div>
                                              </CommentBox>
                                              <Comments contentsId={data.id} />
                                          </>
                                      );
                                  })
                                : null}
                        </div>
                    </Box>
                </ModalWrapper>
            </MobileBox>
        </Wrapper>
    );
}

export default ReadScheduleModal;

const Wrapper = styled.div`
    #label {
        display: none;
    }
`;
