import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
    GET_USERS_DETAIL_FAILURE,
    GET_USERS_DETAIL_SUCCESS,
    PUT_USERS_DETAIL_ADMIN_MEMO_SUCCESS,
    PUT_USERS_DETAIL_FAILURE,
    PUT_USERS_DETAIL_SUCCESS,
} from './users_detail/users_detail_type';
import { GET_USERS_FAILURE, GET_USERS_SUCCESS } from './users_table/users_table_type';

const initialState = {
    users: null,
    usersDetail: null,
};

const usersReducer = handleActions(
    {
        [GET_USERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.users = action.payload;
            }),
        [GET_USERS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_USERS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.usersDetail = action.payload;
            }),

        [GET_USERS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [PUT_USERS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < state.users?.data.length; i++) {
                    if (state.users.data[i]['id'] === action.payload['id']) {
                        draft.users.data[i] = {
                            ...state.users.data[i],
                            ...action.payload,
                        };
                        break;
                    }
                }
            }),
        [PUT_USERS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [PUT_USERS_DETAIL_ADMIN_MEMO_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.users.data.length; i++) {
                    if (draft.users[i]['id'] === action.payload['user_id']) {
                        draft.users[i]['data'][8]['data'] = action.payload['admin_memo'];
                        break;
                    }
                }
            }),
    },
    initialState,
);

export default usersReducer;
