import React, { useState } from 'react';
import styled from 'styled-components';

import UserInformation from '../../components/CustomerServiceComponents/UserInformation';
import CallHistory from '../../components/CustomerServiceComponents/CallHistory';
import AllPaymentLists from '../../components/CustomerServiceComponents/AllPaymentLists';
import JoinGathering from '../../components/CustomerServiceComponents/JoinGathering';
import OperateGathering from '../../components/CustomerServiceComponents/OperateGathering';
import JoinSchedule from '../../components/CustomerServiceComponents/JoinSchedule';
import OperateSchedule from '../../components/CustomerServiceComponents/OperateSchedule';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { MText } from '../../designComponents/text/ContentText';
import UsingPointLists from '../../components/CustomerServiceComponents/UsingPointLists';
import UsingSyrupLists from '../../components/CustomerServiceComponents/UsingSyrupLists';

/** 고객 서비스 페이지 */
const CustomerService = () => {
    const boxgap = 15;
    const [userId, setUserId] = useState(0);
    return (
        <div id="customer-page">
            <CustomerWrapper>
                <CustomerFlex column={true} style={{ gap: boxgap, flexDirection: 'column' }}>
                    <CustomerFlex>
                        <UserInformation userId={userId} setUserId={setUserId} wrapperStyle={{ flex: 1 }} />
                        <AllPaymentLists userId={userId} wrapperStyle={{ flex: 1 }} />
                    </CustomerFlex>

                    <CustomerFlex style={{ width: '100%', gap: boxgap }}>
                        <CallHistory userId={userId} />
                    </CustomerFlex>

                    <CustomerFlex style={{ width: '100%', gap: boxgap }}>
                        <UsingSyrupLists userId={userId} />
                        <UsingPointLists userId={userId} />
                    </CustomerFlex>

                    <CustomerFlex style={{ width: '100%', gap: boxgap }}>
                        <JoinGathering userId={userId} wrapperStyle={{ flex: 1 }} />
                        <OperateGathering userId={userId} wrapperStyle={{ flex: 1 }} />
                    </CustomerFlex>

                    <CustomerFlex style={{ width: '100%', gap: boxgap }}>
                        <JoinSchedule userId={userId} wrapperStyle={{ flex: 1 }} />
                    </CustomerFlex>

                    <CustomerFlex style={{ width: '100%', gap: boxgap }}>
                        <OperateSchedule userId={userId} wrapperStyle={{ flex: 1 }} />
                    </CustomerFlex>
                </CustomerFlex>
            </CustomerWrapper>

            <NoUsingMobileAlert>
                <MText text={'해당 페이지는 모바일에서 사용이 불가능합니다.'} color={'#797979'} />
            </NoUsingMobileAlert>
        </div>
    );
};

export default CustomerService;

/** 회원 미선택 시 보이는 '회원을 선택해주세요' 안내 컴포넌트 */
export const NoneUserId = ({ style, text = '정보가 존재하지 않습니다.', color = '#797979' }) => {
    return (
        <FlexMainWrapper style={{ height: '85%', justifyContent: 'center', alignItems: 'center', ...style }}>
            <MText text={text} color={color} />
        </FlexMainWrapper>
    );
};

const CustomerWrapper = styled.div`
    margin-bottom: 15%;

    margin: 0 auto 10% auto;
    @media (max-width: 1500px) {
        width: 90%;
        padding: 0 5%;
    }
    @media (max-width: 912px) {
        display: none;
    }
`;

const CustomerFlex = styled.div`
    position: relative;
    display: flex;
    gap: 7px;
    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

const NoUsingMobileAlert = styled.div`
    display: none;
    @media (max-width: 912px) {
        display: block;
        text-align: center;
        padding: 10% 0;
    }
`;
