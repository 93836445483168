import React, { useEffect, useState } from 'react';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

import SendPush from '../../EventComponents/PushAlarm/SendPush';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import AppTab from '../../GroupComponent/Tab/AppTab';
import UserDeviceTable from '../../EventComponents/PushAlarm/UserDeviceTable';
import Form from 'react-bootstrap/Form';
import { MText, SText } from '../../designComponents/text/ContentText';
import CheckboxInput from '../../designComponents/checkboxs/CheckboxInput';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
const PushAlarm = () => {
    const [platform, setPlatform] = useState(1);

    const [userIdList, setUserIdList] = useState([]);

    const [type, setType] = useState(0);
    const [sex, setSex] = useState(0);
    const [gatheringId, setGatheringid] = useState('');
    const [isMarketing, setIsMarketing] = useState(false);
    const getTargetList = async () => {
        const input = {
            type: type,
            is_marketing: isMarketing,
            gathering_id: gatheringId,
            sex: sex,
            platform: platform,
        };

        const response = await RefreshCheckApi.get(`/adminPush/target`, { params: input });

        if (response.status === 200) {
            setUserIdList(response.data.data);
        }
    };
    useEffect(() => {
        setType(0);
        setSex(0);
        setUserIdList([]);
    }, [platform]);
    useEffect(() => {
        if (type !== 0) {
            getTargetList();
        }
    }, [platform, sex, type, isMarketing, gatheringId]);
    return (
        <Wrapper02 style={{ marginBottom: '20px' }}>
            <MainPageTitle text={'푸시 알람'} />
            <AppTab platform={platform} setPlatform={setPlatform} />

            <SendPush platform={platform} userIdList={userIdList} />
            <TypeSelector platform={platform} type={type} setType={setType} sex={sex} setSex={setSex} />

            {type !== 0 && (
                <>
                    <Form.Check // prettier-ignore
                        type="switch"
                        label="마켓팅 동의 회원만 보내기"
                        value={String(isMarketing)}
                        onChange={(e) => setIsMarketing((pre) => !pre)}
                    />
                    <SText color={'red'} text={'* 푸시 알림 미동의 회원은 자동 제외됩니다.'} />
                </>
            )}
            {type === 0 ? (
                <UserDeviceTable setSendUser={setUserIdList} platform={platform} />
            ) : type === 2 ? (
                <div>
                    <Form.Label>모임 ID 입력</Form.Label>
                    <Form.Control type="text" value={gatheringId} onChange={(e) => setGatheringid(Number(e.target.value))} />
                </div>
            ) : null}
        </Wrapper02>
    );
};
const TypeSelector = ({ platform, type, setType, sex, setSex }) => {
    const isGathering = platform === 2;

    return (
        <div>
            <div className="mb-3">
                <MText text={'전송대상 선택'} />
                <Form>
                    <FlexMainWrapper>
                        <Form.Check inline label="특정 회원" name="group1" type={'radio'} checked={type === 0} onClick={() => setType(0)} />
                        <Form.Check inline label="전체 회원" name="group1" type={'radio'} checked={type === 1} onClick={() => setType(1)} />
                        {isGathering && <Form.Check inline label="특정 모임" name="group1" type={'radio'} checked={type === 2} onClick={() => setType(2)} />}
                    </FlexMainWrapper>
                </Form>
            </div>
            {type !== 0 && (
                <div className={'mb-3'}>
                    <MText text={'대상 성별'} />
                    <Form>
                        <FlexMainWrapper>
                            <Form.Check inline label="성별 무관" name="group1" type={'radio'} checked={sex === 0} onClick={() => setSex(0)} />
                            <Form.Check inline label="남성 회원" name="group1" type={'radio'} checked={sex === 1} onClick={() => setSex(1)} />
                            <Form.Check inline label="여성 회원" name="group1" type={'radio'} checked={sex === 2} onClick={() => setSex(2)} />
                        </FlexMainWrapper>
                    </Form>
                </div>
            )}
        </div>
    );
};
export default PushAlarm;
