import moment from 'moment';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import { MText, SText } from '../../designComponents/text/ContentText';
import { dateFilter, moneyFilter } from '../../utils/textFilter';
import { InputTitle } from '../../designComponents/inputs/InputLine';
import { BankSelectLine } from '../../designComponents/dropdowns/Selectdown';
import { BANK_CODE } from '../../components/SubscribePayment/payBankCodeService';
import { MainButton } from '../../designComponents/buttons/Buttons';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

const PayInfoModal = ({ data, originData, onChange = () => {} }) => {
    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;
    const virtualAccountNumber = data.virtualAccountNumber;
    const expire_date = moment(data?.virtualAccountNumber?.expire_date).format('YYYY년 MM월 DD일 HH시 mm분 까지');
    const color = data.pay_status_number !== 3 && data.pay_status_number !== 4 ? 'rgb(5 101 242)' : 'rgb(255, 16, 125)';
    return (
        <FlexMainWrapper style={{ alignItems: 'start', justifyContent: 'center', padding: '30px' }}>
            <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'start', width: '50%', gap: '20px' }}>
                <MText text={'결제 정보'} />
                <FlexTwoText text01={'화원명'} text02={data.name} text01Style={{ width: '80px', textAlign: 'start' }} />
                <FlexTwoText text01={'결제 ID'} text02={data.pay_id || nullTag} text01Style={{ width: '80px', textAlign: 'start' }} />
                <FlexTwoText text01={'결제 상태'} text02={data.pay_status || nullTag} text01Style={{ width: '80px', textAlign: 'start' }} text02Style={{ color: color }} />
                {virtualAccountNumber ? (
                    <FlexMainWrapper style={{ flexDirection: 'column', gap: '10px', marginBottom: '5px' }}>
                        <SText text={'가상 계좌 정보'} />
                        <FlexTwoText text01={'은행 기관'} text02={virtualAccountNumber?.bank_name || nullTag} text01Style={{ width: '80px', textAlign: 'start' }} />
                        <FlexMainWrapper style={{ alignItems: 'center' }}>
                            <FlexTwoText text01={'계좌 번호'} text02={virtualAccountNumber?.account_no || nullTag} text01Style={{ width: '80px', textAlign: 'start' }} />
                            <MainButton
                                text={'문자 전송'}
                                width={80}
                                height={20}
                                styleReverse
                                onClick={async () => {
                                    const bankName = data.virtualAccountNumber?.bank_name;
                                    const accountNum = data.virtualAccountNumber?.account_no;
                                    const amount = data.amount?.toLocaleString('ko-KR');
                                    const expireDate = moment(data?.virtualAccountNumber?.expire_date).format('YYYY.MM.DD HH:mm');
                                    const today = moment().format('YYYY.MM.DD HH:mm');
                                    try {
                                        const body = {
                                            phone_no_list: [data.user_phone_no],
                                            message_title: `[시놀] 결제를 위한 가상계좌 정보`,
                                            message_content: `시놀 무통장 입금 안내 \n은행명: ${bankName}\n계좌번호: ${accountNum}\n금액: ${amount}\n입급기한: ${expireDate}`,
                                        };

                                        if (!data.virtualAccountNumber?.bank_name) return alert('은행정보가 만료되었거나 없습니다. 결제정보를 새로 생성해주세요.');
                                        if (!data.virtualAccountNumber?.account_no) return alert('가상 계좌 번호가 존재하지 않습니다. 결제정보를 새로 생성해주세요.');
                                        if (parseInt(data.virtualAccountNumber?.amount) <= 0) return alert('결제 금액이 존재하지 않습니다. 확인해주세요.');
                                        if (expire_date < today) return alert('기간이 만료되었습니다. 결제정보를 새로 생성해주세요.');

                                        if (window.confirm('문자를 전송하시겠습니까?')) {
                                            const response = await RefreshCheckApi.post('/adminSms', body);
                                            if (response.status !== 200) alert('[서버 관리자 문의]메세지 전송하는 과정에서 Error가 발생했습니다.');
                                            if (response.status === 200 && response.data.ok) alert(`${data.name}님께 가상계좌 정보를 발송했습니다.`);
                                        }
                                    } catch (error) {
                                        console.error('error: ', error);
                                    }
                                }}
                            />
                        </FlexMainWrapper>
                        <FlexTwoText text01={'만료 기간'} text02={expire_date || nullTag} text01Style={{ width: '80px', textAlign: 'start' }} />
                    </FlexMainWrapper>
                ) : (
                    <FlexTwoText
                        text01={'가상 계좌'}
                        text02={<SText text={'가상계좌 발급을 원하신다면 결제정보를 다시 생성해주세요.'} color="#797979" />}
                        text01Style={{ width: '80px', textAlign: 'start' }}
                    />
                )}
                <FlexTwoText text01={'실 결제금액'} text02={moneyFilter(data.amount, '원')} text01Style={{ width: '80px', textAlign: 'start' }} />
                <FlexTwoText text01={'결제 일시'} text02={dateFilter(data.created_at)} text01Style={{ width: '80px', textAlign: 'start' }} />
                <FlexTwoText text01={'결제 수단'} text02={data.pay_type} text01Style={{ width: '80px', textAlign: 'start' }} />
                <FlexTwoText text01={'결제 상품'} text02={data.pay_name} text01Style={{ width: '80px', textAlign: 'start' }} />
                {data.gatheringInfo && data.pay_contents === 6 && (
                    <FlexTwoText text01={'모임명'} text02={`${data.gatheringInfo?.gathering_name}[${data.gatheringInfo?.gathering_id}]`} text01Style={{ width: '80px', textAlign: 'start' }} />
                )}
                {data.gatheringInfo && data.pay_contents === 6 && (
                    <FlexTwoText
                        text01={'일정명'}
                        text02={`${data.gatheringInfo?.gathering_contents_title}[${data.gatheringInfo?.gathering_contents_id}]`}
                        text01Style={{ width: '80px', textAlign: 'start' }}
                    />
                )}
                {data.syrupInfo && data.pay_contents === 7 && <FlexTwoText text01={'시럽 갯수'} text02={`${data.syrupInfo?.sinor_love || 0}개`} text01Style={{ width: '80px', textAlign: 'start' }} />}
                <FlexTwoText text01={'포인트 사용'} text02={moneyFilter(data.use_point_amount || 0, 'P')} text01Style={{ width: '80px', textAlign: 'start' }} />
                <FlexTwoText text01={'쿠폰 사용'} text02={data.coupon || nullTag} text01Style={{ width: '80px', textAlign: 'start' }} />
                <FlexTwoText text01={'포인트 적립'} text02={moneyFilter(data.point_accumulate, '원')} text01Style={{ width: '80px', textAlign: 'start' }} />
            </FlexMainWrapper>

            <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'start', width: '50%', gap: '20px' }}>
                <MText text={'환불 정보'} />
                <FlexTwoText text01={'환불 요청일시'} text02={data.refund_created_at ? dateFilter(data.refund_created_at) : nullTag} text01Style={{ width: '90px', textAlign: 'start' }} />
                <InputTitle
                    title={'환불 예정금액'}
                    value={data.refund_amount}
                    placeholder={data.refund_amount || originData.refund_amount || '정보없음'}
                    onChange={(e) => onChange(e.target.value, 'refund_amount')}
                    type="number"
                    wrapperStyle={{ margin: 0 }}
                    titleStyle={{ textAlign: 'start', fontSize: '13px', fontWeight: 600, width: '80px' }}
                    inputStyle={{ width: '150px' }}
                />
                {data.bank_name ? (
                    <InputTitle
                        title={'환불 은행'}
                        value={data.bank_name}
                        placeholder={data.bank_name}
                        type="text"
                        wrapperStyle={{ margin: 0 }}
                        titleStyle={{ textAlign: 'start', fontSize: '13px', fontWeight: 600, width: '80px' }}
                        inputStyle={{ width: '150px' }}
                        disabled
                    />
                ) : (
                    <BankSelectLine
                        title={'환불 은행'}
                        titleStyle={{ textAlign: 'start', fontSize: '13px', fontWeight: 600, width: '83px' }}
                        selectStyle={{ width: '162px', height: '31px' }}
                        wrapperStyle={{ alignItems: 'center' }}
                        list={BANK_CODE}
                        resetThenSet={(target) => {
                            onChange(target.value, 'bank_code');
                        }}
                    />
                )}
                <InputTitle
                    title={'환불 계좌번호'}
                    value={data.account_no}
                    placeholder={data.account_no || originData.account_no || '정보없음'}
                    onChange={(e) => onChange(e.target.value, 'account_no')}
                    type="number"
                    wrapperStyle={{ margin: 0 }}
                    titleStyle={{ textAlign: 'start', fontSize: '13px', fontWeight: 600, width: '80px' }}
                    inputStyle={{ width: '150px' }}
                />
                <InputTitle
                    title={'환불 예금주'}
                    value={data.refund_user_name}
                    placeholder={data.refund_user_name || originData.refund_user_name || '정보없음'}
                    onChange={(e) => onChange(e.target.value, 'refund_user_name')}
                    type="text"
                    wrapperStyle={{ margin: 0 }}
                    titleStyle={{ textAlign: 'start', fontSize: '13px', fontWeight: 600, width: '80px' }}
                    inputStyle={{ width: '150px' }}
                />
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export default PayInfoModal;
