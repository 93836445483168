import { useState, useCallback, useRef } from 'react';

// communication_system
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// designComponents
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import { HoverSpan } from '../../designComponents/tables/TbodyLists';

// components
import { FriendsReportFilter } from './FriendsReportFilter';
import { adminUsers } from '../../dataSystems/AdminUserLists';
import TableFilter from '../../designComponents/filters/TableFilter';
import UserDetailModal from '../UserComponents/UserDetailModal';

/** 단짝신고 */
const FriendsReportPage = () => {
    const [userDetailToggle, setUserDetailToggle] = useState({});

    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [param, setParam] = useState({});

    // useRef
    const ref = useRef();
    const tableRef = useRef();

    // 회원 상세정보 보기
    const onClickUserInfoModal = (userId) => () => {
        setUserDetailToggle({ toggle: true, userId: userId });
    };

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminReport`, { params: input });
        return response.data.data.body;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminReport`, { params: input });
        return response.data.data.all_count;
    };

    return (
        <div>
            {userDetailToggle.toggle && <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />}
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '신고자 닉네임', value: 1 },
                    { id: 2, title: '신고자 이름', value: 2 },
                    { id: 3, title: '신고자 휴대폰번호', value: 3 },
                    { id: 4, title: '신고자 유저 ID', value: 4 },
                    { id: 5, title: '피 신고자 닉네임', value: 5 },
                    { id: 6, title: '피 신고자 이름', value: 6 },
                    { id: 7, title: '피 신고자 휴대폰번호', value: 7 },
                    { id: 8, title: '피 신고자 유저 ID', value: 8 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                    setParam({ ...param, page: 1 });
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />
            <div style={{ overflow: 'auto' }}>
                <TableFilter
                    wrapperStyle={{ width: '95%' }}
                    arrFilterSt={{ flexDirection: 'column', alignItems: 'start' }}
                    param={param}
                    setParam={setParam}
                    topFilterLists={[
                        {
                            filterText: '순서정렬 필터',
                            orderLists: [
                                { name: '최신순', width: 50, type: 1 },
                                { name: '오래된순', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.reportedDate = type;
                                else delete obj.reportedDate;
                                setParam(obj);
                            },
                            orderbyType: param.reportedDate,
                        },
                    ]}
                    filterLists={FriendsReportFilter}
                    research={ref}
                />
            </div>
            <MainTable
                ref={ref}
                tableRef={tableRef}
                toggleBlur={true}
                loadingText={'단짝 신고 내역을 불러오고 있습니다.'}
                headerData={FriendsReportFilter}
                checkID={'pay_id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {}}
                adminCheck={(item) => adminUsers.includes(item.phone_no) && { color: 'rgb(255, 16, 125)' }}
                tbodyRenderItem={(key, value, item) => {
                    if (key === 'reporter_name') return <HoverSpan onClick={onClickUserInfoModal(item.reporter_user_id)}>{value}</HoverSpan>;
                    if (key === 'reported_name') return <HoverSpan onClick={onClickUserInfoModal(item.reported_user_id)}>{value}</HoverSpan>;
                    return value;
                }}
            />
        </div>
    );
};

export default FriendsReportPage;
