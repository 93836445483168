export const GatheringAccountInfoFilter = [
    {
        key: 'gathering_id',
        value: '모임ID',
    },
    {
        key: 'gathering_name',
        value: '모임명',
    },
    {
        key: 'user_name',
        value: '모임장명',
    },
    {
        key: 'business_yn',
        value: '볍인 계좌 여부',
        filter: [
            {
                key: true,
                value: '법인 계좌',
            },
            {
                key: 0,
                value: '개인 계좌',
            },
        ],
    },
    {
        key: 'license_name',
        value: '이름 / 법인명',
        filter: [
            {
                key: 1,
                value: '정보있음',
            },
            {
                key: 0,
                value: '정보없음',
            },
        ],
    },
    {
        key: 'license_number',
        value: '주민 번호 / 사업자 등록 번호',
        filter: [
            {
                key: 1,
                value: '정보있음',
            },
            {
                key: 0,
                value: '정보없음',
            },
        ],
    },
    {
        key: 'user_phone_no',
        value: '모임장 연락처',
    },
    {
        key: 'account_bank',
        value: '은행',
        filter: [
            {
                key: 1,
                value: '정보있음',
            },
            {
                key: 0,
                value: '정보없음',
            },
        ],
    },
    {
        key: 'account_no',
        value: '모임 계좌번호',
        filter: [
            {
                key: 1,
                value: '정보있음',
            },
            {
                key: 0,
                value: '정보없음',
            },
        ],
    },
    {
        key: 'account_holder',
        value: '예금주',
        filter: [
            {
                key: 1,
                value: '정보있음',
            },
            {
                key: 0,
                value: '정보없음',
            },
        ],
    },
    {
        key: 'auth_yn',
        value: '계좌인증여부',
        filter: [
            {
                key: 1,
                value: '인증완료',
            },
            {
                key: 0,
                value: '인증 미완료',
            },
            {
                key: 3,
                value: '인증 대기중',
            },
        ],
    },
    {
        key: 'updated_at',
        value: '요청시간',
    },
];
