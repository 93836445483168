/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getHostInformations } from '../../data_system/redux/modules/product_reducer/product.host_reducer';

// components
import AddHostInfoComponent from '../../components/ProductComponent/addHostInfoComponent';
import HostInformation from '../../components/ProductComponent/hostInformationLists';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

const CummersHost = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // useSelector
    const { hostInformationLists } = useSelector((state) => state.productHostReducer);
    // state
    const [toggle, setToggle] = useState(false);
    const [moreAddInfoToggle, setMoreAddInfoToggle] = useState(false); // 호스트 정보 ? 추가정보입력 : null
    const [hostInfoParams, setHostInfoParams] = useState(null); // 호스트정보를 담고있는 객체

    const [page, setPage] = useState(1);

    // 호스트 리스트 get 요청
    useEffect(() => {
        dispatch(getHostInformations());
    }, [dispatch]);

    const onClickToggle = () => {
        setToggle((prev) => !prev);
    };

    return (
        <Wrapper02>
            <MainPageTitle text={'판매자 관리'} />
            <ButtomWrapper>
                <AddButton onClick={onClickToggle}>{toggle ? '등록 취소' : '호스트 등록'}</AddButton>
            </ButtomWrapper>

            {toggle && <AddHostInfoComponent setMoreAddInfoToggle={setMoreAddInfoToggle} setHostInfoParams={setHostInfoParams} />}
            {toggle && <Line />}

            <div>
                <MainTitle style={{ marginTop: '30px' }}>판매자 목록</MainTitle>
                {hostInformationLists?.map((data, index) => (
                    <HostInformation data={data} key={index} />
                ))}
            </div>

            <div>
                <Pagination totalPages={100 ? Math.round(100 / 10) : 10} limit={10} page={page} setPage={setPage} />
            </div>
        </Wrapper02>
    );
};

export default CummersHost;

export const ButtomWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 3px;

    width: 100%; // 33%;
    margin-bottom: 8px;
`;

const AddButton = styled.button`
    cursor: pointer;
    width: 140px;
    height: 30px;

    margin-bottom: 17px;
    color: #fff;

    background-color: rgb(66, 139, 247);
    border: 0;
    border-radius: 2px;

    :hover {
        background-color: rgb(40 116 228);
    }
`;

const MainTitle = styled.div`
    background-color: #cccccc;

    padding: 10px 10px;
    margin-bottom: 3px;
`;

const Line = styled.div`
    width: 100%;
    border: 1px solid #cccccc;

    margin: 30px 0;
`;
