import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { login_router_url } from '../communication_system/inner_router_url';
import { sidebarData } from '../dataSystems/SidebarData';

import Layout from '../components/Layouts/Layout';
import Login from '../pages/login/Login';
import { useState } from 'react';

/** 라우터 설정 */
const TotalRouter = () => {
    const [userId, setUserId] = useState('');

    const loginRoute = (id) => {
        setUserId(id);
        localStorage.setItem('user_id', id);
    };

    const logoutRoute = () => {
        setUserId('');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user');
    };

    useEffect(() => {
        setUserId(localStorage.getItem('user_id'));
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                {!Boolean(userId) && <Route path={login_router_url} element={<Login authenticate={loginRoute} />} />}
                <Route path="*" element={<Navigate to={Boolean(userId) ? `/home` : '/login'} />} />
                {Boolean(userId) && (
                    <Route element={<Layout logout={logoutRoute} />}>
                        {sidebarData.map((main) => {
                            return main.subTitleList.map((sub) => {
                                return <Route path={sub.router_url} element={sub.components} />;
                            });
                        })}
                    </Route>
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default React.memo(TotalRouter);
