import { useEffect, useState, useContext, useRef, useCallback } from 'react';

import styled from 'styled-components';
import { ModalBoxWrapper, ModalWrapper } from '../../design_system/components/modal/NewUserModalStyled';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// redux
import { useDispatch } from 'react-redux';
import { requestGetGatheringDetailCrew } from '../../data_system/redux/modules/gathering.reducer/gathering.reducer';

import UserModal from '../../components/UserComponents/UserModal';
import { GatheringUserListsFillter } from '../MockData/GatheringListsMockData';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import { FilterToggleButton } from '../../designComponents/buttons/FilterToggleButton';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { ExcelButton, MainButton } from '../../designComponents/buttons/Buttons';

import { onClickGatheringCrewListsExcel } from '../../utils/xlsxUtil';
import MainTable from '../../designComponents/tables/MainTable';
import { availFilter, colorFilter, dateFilter, deleteCrewType, gatheringRoleFilter } from '../../utils/textFilter';
import { Image } from '../../designComponents/imageboxs/Image';
import { DefaultModal, ImageModal } from '../../designComponents/modals/ModalComponent';
import SearchbarWithDropdownV2 from '../../designComponents/searchbars/SearchbarWithDropdownV2';
import TableFilter from '../../designComponents/filters/TableFilter';
import { LText, SText } from '../../designComponents/text/ContentText';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import UserDetailModal from '../UserComponents/UserDetailModal';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import { getGatheringContentsListRequest } from '../../api/main/gathering/GatheringCrewRequest';
import ApprovalRequestList from '../../EventComponents/Gathering/ApprovalRequestList';

/** 참여회원 리스트 */
const GatheringUserLists = ({ gathering_id }) => {
    const dispatch = useDispatch();

    const [userDetailToggle, setUserDetailToggle] = useState({});

    const [userGatheringRole, setUserGatheringRole] = useState(false); // 체크된 회원id를 담을 배열
    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');
    const [approvalRequest, setApprovalRequest] = useState([]);
    const [page, setPage] = useState(1);
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [open, setOpen] = useState(false);
    const [param, setParam] = useState({
        gatheringId: gathering_id,
        orderType: 1,
        orderByType: 1,
        roleType: [0, 1, 2, 3],
    });
    const [countObj, setCountObj] = useState({});

    const [countToggle, setCountToggle] = useState({});
    const [authorityToggle, setAuthorityToggle] = useState([{}, false]);

    const ref = useRef(null);
    const tableRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
        ref?.current?.refresh();
    }, []);

    // 회원정보 선택 -> Modal 상세
    const onClickDetailModal = (user_id, role) => () => {
        if (user_id) {
            if (parseInt(role) === 3) {
                setUserGatheringRole(true);
            } else {
                setUserGatheringRole(false);
            }
            setUserDetailToggle({ toggle: true, userId: user_id });
        } else {
            alert('회원 정보가 존재하지 않습니다.');
        }
    };

    /** 권한 수정 */
    const onChangeRole = (userData, role) => async () => {
        const name = userData.user_name;
        const nickname = userData.user_nick_name;
        const roleValidate = (user_role) => {
            switch (user_role) {
                case 1:
                    return '일반회원';
                case 2:
                    return '운영진';
                case 3:
                    return '모임장';
                default:
                    return;
            }
        };
        const userRole = roleValidate(userData.content_user_role);
        const updateRole = roleValidate(role);

        if (window.confirm(`${name}(${nickname})님의 권한을 ${userRole} → ${updateRole}으로 수정하시겠습니까?`)) {
            const body = {
                user_id: userData.user_id,
                gathering_id: gathering_id,
                update_role: role,
            };

            const response = await RefreshCheckApi.patch('/adminGathering/role/update', body);
            if (response.status === 200) {
                dispatch(requestGetGatheringDetailCrew(gathering_id, param));
                setAuthorityToggle([0, false]);
                alert(response.data.message);
            }
        }
    };

    const onClickExcel = async () => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchText: searchText,
            gathering_id: gathering_id,
            deleted_at: open,
            ...param,
        };
        const response = await RefreshCheckApi.get('/adminGathering/gahering/crew/excel', { params: input });
        const header = [
            { key: 'role', value: '권한' },
            { key: 'user_name', value: '이름' },
            { key: 'user_nickname', value: '닉네임' },
            { key: 'birthday', value: '생년월일' },
            { key: 'phone_no', value: '휴대폰 번호' },
            { key: 'address', value: '주소' },
            { key: 'sex', value: '성별' },
            { key: 'created_at', value: '앱 가입일' },
            { key: 'crew_created_at', value: '모임 가입일' },
            { key: 'gatherin_deleted_at', value: '모임 탈퇴일' },
            { key: 'marketing_agree_yn', value: '마케팅 동의 여부' },
            { key: 'private_info_agree_yn', value: '개인정보 이용동의' },
            { key: 'service_agree_yn', value: '서비스 이용 동의' },
        ];
        onClickGatheringCrewListsExcel(response.data.gathering_name, header, response.data.data, `${response.data.gathering_name}.xlsx`);
    };

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            gatheringId: gathering_id,
            ...param,
        };

        const postResponse = await getGatheringContentsListRequest(input);
        const response = await RefreshCheckApi.get(`/adminGathering/detail/crew?gathering_id=${gathering_id}`, { params: input });
        if (response.status === 200) return postResponse.data;
    };

    // const getApprovalRequest = async () => {
    //     const response = await RefreshCheckApi.get(`/approval/question?gathering_id=${gathering_id}`);
    //     if (response.status === 200) {
    //         setApprovalRequest(response.data);
    //     }
    // };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            gatheringId: gathering_id,
            ...param,
        };
        const response = await getGatheringContentsListRequest(input);
        return response.all_cnt;
    };

    return (
        <Wrapper02>
            {userDetailToggle.toggle && <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />}
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image imgRef={imgRef} size="S150" url={src} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}
            {authorityToggle[1] && (
                <AuthorityModal
                    lists={[
                        {
                            text: <span style={{ color: 'rgb(66,139,247)' }}>모임장</span>,
                            onPress: onChangeRole(authorityToggle[0], 3),
                            role: authorityToggle[0].content_user_role !== 3,
                        },
                        {
                            text: <span style={{ color: 'rgb(255,16,125)' }}>운영진</span>,
                            onPress: onChangeRole(authorityToggle[0], 2),
                            role: authorityToggle[0].content_user_role !== 2,
                        },
                        {
                            text: '일반회원',
                            onPress: onChangeRole(authorityToggle[0], 1),
                            role: authorityToggle[0].content_user_role !== 1,
                        },
                        {
                            text: '닫기',
                            onPress: () => setAuthorityToggle(false),
                            role: true,
                        },
                    ]}
                />
            )}
            {countToggle.contentToggle && (
                <DefaultModal
                    boxStyle={{ width: '45vh', height: '17vh' }}
                    onClickClose={() => setCountToggle({})}
                    saveOn
                    saveText="확인"
                    onClickSave={() => {
                        setParam({ ...param, ...countObj });
                        setCountToggle({});
                    }}
                    closeOn
                    cancelText="초기화"
                    onClickBottomClose={() => {
                        let obj = { ...param, ...countObj };
                        delete obj.contentStartCount;
                        delete obj.contentEndCount;
                        setCountObj(obj);
                        setParam(obj);
                    }}
                >
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', width: '100%', gap: 0 }}>
                        <InputLine
                            type="number"
                            placeholder={'~개 부터'}
                            value={countObj.contentStartCount || ''}
                            onChange={(e) => {
                                let obj = { ...countObj };
                                obj.contentStartCount = e.target.value;
                                setCountObj(obj);
                            }}
                        />
                        <span>~</span>
                        <InputLine
                            type="number"
                            placeholder={'~개 까지'}
                            value={countObj.contentEndCount || ''}
                            onChange={(e) => {
                                let obj = { ...countObj };
                                obj.contentEndCount = e.target.value;
                                setCountObj(obj);
                            }}
                        />
                    </FlexMainWrapper>
                </DefaultModal>
            )}
            {countToggle.commentToggle && (
                <DefaultModal
                    boxStyle={{ width: '45vh', height: '17vh' }}
                    onClickClose={() => setCountToggle({})}
                    saveOn
                    saveText="확인"
                    onClickSave={() => {
                        setParam({ ...param, ...countObj });
                        setCountToggle({});
                    }}
                    closeOn
                    cancelText="초기화"
                    onClickBottomClose={() => {
                        let obj = { ...param, ...countObj };
                        delete obj.commentStartCount;
                        delete obj.commentEndCount;
                        setCountObj(obj);
                        setParam(obj);
                    }}
                >
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', width: '100%', gap: 0 }}>
                        <InputLine
                            type="number"
                            placeholder={'~개 부터'}
                            value={countObj.commentStartCount || ''}
                            onChange={(e) => {
                                let obj = { ...countObj };
                                obj.commentStartCount = e.target.value;
                                setCountObj(obj);
                            }}
                        />
                        <span>~</span>
                        <InputLine
                            type="number"
                            placeholder={'~개 까지'}
                            value={countObj.commentEndCount || ''}
                            onChange={(e) => {
                                let obj = { ...countObj };
                                obj.commentEndCount = e.target.value;
                                setCountObj(obj);
                            }}
                        />
                    </FlexMainWrapper>
                </DefaultModal>
            )}
            <SearchbarWithDropdown
                style={{ margin: '25px 0' }}
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '회원 ID', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
                research={ref}
                setSearchText={setSearchText}
            />
            <div style={{ overflow: 'auto' }}>
                <TableFilter
                    param={param}
                    setParam={setParam}
                    topFilterLists={[
                        {
                            filterText: '순서 정렬 기준',
                            orderLists: [
                                { name: '모임 가입일', width: 100, type: 1 },
                                { name: '앱 가입일', width: 100, type: 2 },
                                { name: '회원명', width: 100, type: 3 },
                                { name: '닉네임', width: 100, type: 4 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.orderByType = type;
                                else delete obj.orderByType;
                                setParam(obj);
                            },
                            orderbyType: param.orderByType,
                        },
                        {
                            filterText: '순서 정렬 방식',
                            orderLists: [
                                { name: '내림 차순', width: 100, type: 1 },
                                { name: '오른 차순', width: 100, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.orderType = type;
                                else delete obj.orderType;
                                setParam(obj);
                            },
                            orderbyType: param.orderType,
                        },
                        {
                            filterText: '회원 대표사진',
                            orderLists: [
                                { name: '있음', width: 100, type: 1 },
                                { name: '없음', width: 100, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.user_profile_url = type;
                                else delete obj.user_profile_url;
                                setParam(obj);
                            },
                            orderbyType: param.user_profile_url,
                        },
                        {
                            filterText: '참여 상태',
                            orderLists: [
                                { name: '정상', width: 100, type: 1 },
                                { name: '자진 탈퇴', width: 100, type: 2 },
                                { name: '내보내기', width: 100, type: 3 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.crew_delete_at = type;
                                else delete obj.crew_delete_at;
                                setParam(obj);
                            },
                            orderbyType: param.crew_delete_at,
                        },
                        {
                            filterText: '성별',
                            orderLists: [
                                { name: '남자', width: 100, type: 1 },
                                { name: '여자', width: 100, type: 2 },
                                { name: '전체', width: 100, type: 3 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.sex = type;
                                else delete obj.sex;
                                setParam(obj);
                            },
                            orderbyType: param.sex,
                        },
                        {
                            filterText: '게시글 수',
                            orderLists: [
                                {
                                    boxBlur: true,
                                    name: (
                                        <FlexMainWrapper style={{ alignItems: 'center', width: '100vh' }}>
                                            <MainButton
                                                text="게시글 수 범위"
                                                style={{ height: '22px', width: '100px' }}
                                                onClick={() => setCountToggle({ contentToggle: true, commentToggle: false })}
                                            />
                                            <FlexTwoText
                                                gap={5}
                                                fromTo={param.contentStartCount && param.contentEndCount}
                                                text01={param.contentStartCount}
                                                text02={param.contentEndCount}
                                                text02Style={{ color: '#797979' }}
                                            />
                                        </FlexMainWrapper>
                                    ),
                                },
                            ],
                        },
                        {
                            filterText: '댓글 수',
                            orderLists: [
                                {
                                    boxBlur: true,
                                    name: (
                                        <FlexMainWrapper style={{ alignItems: 'center', width: '100vh' }}>
                                            <MainButton text="댓글 수 범위" style={{ height: '22px', width: '100px' }} onClick={() => setCountToggle({ contentToggle: false, commentToggle: true })} />
                                            <FlexTwoText
                                                gap={5}
                                                fromTo={param.commentStartCount && param.commentEndCount}
                                                text01={param.commentStartCount}
                                                text02={param.commentEndCount}
                                                text02Style={{ color: '#797979' }}
                                            />
                                        </FlexMainWrapper>
                                    ),
                                },
                            ],
                        },
                    ]}
                    filterLists={[
                        {
                            key: 'roleType',
                            value: '권한',
                            filter: [
                                {
                                    key: 3,
                                    value: '모임장',
                                },
                                {
                                    key: 2,
                                    value: '운영진',
                                },
                                {
                                    key: 1,
                                    value: '일반 회원',
                                },
                            ],
                        },
                    ]}
                    research={ref}
                />
            </div>
            <MainTable
                scrollWidth={160}
                toggleBlur={true}
                buttonComponents={() => (
                    <FlexMainWrapper style={{ marginBottom: '5px', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <ExcelButton text="Excel" onClick={onClickExcel} />
                    </FlexMainWrapper>
                )}
                ref={ref}
                tableRef={tableRef}
                loadingText={'회원 리스트를 불러오고 있습니다.'}
                headerData={GatheringUserListsFillter}
                checkID={'id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item, _) => onClickDetailModal(item.user_id)}
                adminCheck={(item) => item.content_user_role > 1 && { backgroundColor: 'rgb(237 237 237)' }}
                tbodyRenderItem={(key, value, item) => {
                    if (key === 'crew_created_at') return dateFilter(value, 'YYYY-MM-DD');
                    if (key === 'user_created_at') return dateFilter(value, 'YYYY-MM-DD');
                    if (key === 'user_profile_url') return <Image url={value} width={80} height={80} style={{ borderRadius: '12%' }} />;
                    if (key === 'crew_delete_at') return deleteCrewType(value);
                    if (key === 'user_contents_count') return `${value || 0}개`;
                    if (key === 'user_comment_count') return `${value || 0}개`;
                    if (key === 'content_user_role')
                        return gatheringRoleFilter(item.content_user_role, () => {
                            setAuthorityToggle([item, true]);
                        });
                    if (key === 'market_info_agree') return colorFilter(parseInt(value), parseInt(value) ? 'Y' : 'N');
                    if (key === 'alarm_agree_yn') return colorFilter(parseInt(value), parseInt(value) ? 'Y' : 'N');
                    return value;
                }}
            />
            <LText style={{ marginTop: 32 }} text={'가입 대기'} />
            <ApprovalRequestList
                gatheringId={gathering_id}
                onApproval={() => {
                    ref?.current?.refresh();
                }}
            />
        </Wrapper02>
    );
};

export default GatheringUserLists;

const AuthorityModal = ({ lists }) => {
    return (
        <ModalWrapper>
            <ModalBoxWrapper style={{ width: '35vh', maxWidth: '98%', textAlign: 'center' }}>
                {lists.map((text, index) => {
                    return text.role ? (
                        <ApplyAuthorityButton key={index} onClick={text.onPress}>
                            {text.text}
                        </ApplyAuthorityButton>
                    ) : null;
                })}
            </ModalBoxWrapper>
        </ModalWrapper>
    );
};

const ApplyAuthorityButton = styled.div`
    cursor: pointer;
    border-radius: 13px;
    padding: 5% 0;
    font-size: 17px;

    &:hover {
        background-color: #ebebeb;
        font-weight: bold;
    }
`;
