import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {h_px4rate, h_px64rate, w_px20rate, w_px362rate,} from "../../../../../size";
import {rgb_153_204_255, rgb_255_255_255} from "../../../../../colors";
import {ArrowBackwardWhite1x} from "../../../../../icons/icons";
import TotalDetailComponents from "../../detail_components/total_detail_component/TotalDetailComponents";
import BodyScroll from "../../../../body_scroll/BodyScroll";
import ChattingTitleContext from "../../../../../../data_system/contexts/chatting_title_context/chatting_title_context";

const DetailChattingForm = ({
  setItemOpen,
  choiceRowIndex,
  setChoiceRowIndex,
  DETAIL_DATA_TYPE,
  limit,
  page,
}) => {
  const { chatting_title_state } = useContext(ChattingTitleContext);

  const close = useCallback(() => {
    setItemOpen(false);
    setChoiceRowIndex(null);
  }, [setItemOpen, setChoiceRowIndex]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    !loading && (
      <div>
        <HeaderTitle>
          <HeaderArrowBackwardBtn
            onClick={() => {
              close();
            }}
          >
            <ArrowBackwardWhite1x></ArrowBackwardWhite1x>
          </HeaderArrowBackwardBtn>
          <div
            style={{
              marginBottom: h_px4rate,
            }}
          >
            {chatting_title_state.title}
          </div>
        </HeaderTitle>
        <BodyScroll>
          <TotalDetailComponents
            choiceRowIndex={choiceRowIndex}
            DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
            limit={limit}
            page={page}
          ></TotalDetailComponents>
        </BodyScroll>
      </div>
    )
  );
};

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  width: ${w_px362rate}px;
  height: ${h_px64rate}px;
  font-size: ${w_px20rate}px;
  font-family: noto_sans_kr_bold;
  background-color: ${rgb_153_204_255};
  color: ${rgb_255_255_255};
`;

const HeaderArrowBackwardBtn = styled.button`
  border-width: 0px;
  background-color: ${rgb_153_204_255};
`;

export default React.memo(DetailChattingForm);
