export const GET_ACTIVITIES_DETAIL = "admin/GET_ACTIVITIES_DETAIL";
export const GET_ACTIVITIES_DETAIL_SUCCESS =
  "admin/GET_ACTIVITIES_DETAIL_SUCCESS";
export const GET_ACTIVITIES_DETAIL_FAILURE =
  "admin/GET_ACTIVITIES_DETAIL_FAILURE";
export const PUT_ACTIVITIES_DETAIL_ADMIN_MEMO =
  "admin/PUT_ACTIVITIES_DETAIL_ADMIN_MEMO";
export const PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_SUCCESS =
  "admin/PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_SUCCESS";
export const PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_FAILURE =
  "admin/PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_FAILURE";
export const PUT_ACTIVITY_DETAIL = "admin/PUT_ACTIVITY_DETAIL";
export const PUT_ACTIVITY_DETAIL_SUCCESS = "admin/PUT_ACTIVITY_DETAIL_SUCCESS";
export const PUT_ACTIVITY_DETAIL_FAILURE = "admin/PUT_ACTIVITY_DETAIL_FAILURE";
