import React from 'react';
import styled from 'styled-components';
import { rgb_0_0_0, rgb_205_205_205, rgb_66_139_247 } from '../../colors';
import { h_px1rate, h_px8rate, w_px12rate, w_px20rate, w_px50rate } from '../../size';

const ToggleBtn = ({ toggle, clickedToggle, style }) => {
  return (
    <Toggle onClick={clickedToggle} toggle={toggle} style={{ ...style }}>
      <Circle toggle={toggle} />
    </Toggle>
  );
};

export default React.memo(ToggleBtn);

const Toggle = styled.button`
  width: 27px; // ${w_px20rate}px;
  height: 11px; // ${h_px8rate}px;;
  border-radius: ${h_px8rate}px;
  border: none;
  cursor: pointer;
  background-color: ${(props) => (!props.toggle ? rgb_205_205_205 : rgb_66_139_247)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
`;
const Circle = styled.div`
  background-color: white;
  width: 13px; // ${w_px12rate}px;
  height: 13px;
  border-radius: ${w_px50rate}px;
  position: absolute;
  left: -5%;
  border: ${h_px1rate} solid ${rgb_0_0_0};
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 6px 8px rgba(25, 50, 47, 0.08), 0px 3px 4px rgba(18, 71, 52, 0.02),
    0px 1px 16px rgba(18, 71, 52, 0.03);
  ${(props) =>
    props.toggle &&
    `
      transform: translate(17px, 0);
      transition: all 0.5s ease-in-out;
    `}
`;
