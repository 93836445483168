import FlexMainWrapper, { Wrapper, Wrapper02, Wrapper03 } from '../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../designComponents/titles/MainPageTitle';
import React, { useEffect, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { dateFilter } from '../utils/textFilter';
import { RedoOutlined } from '@ant-design/icons';
import moment from 'moment';
import RefreshCheckApi from '../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainButton } from '../designComponents/buttons/Buttons';
import { MText } from '../designComponents/text/ContentText';
const AuthNumberHistory = () => {
    const [data, setData] = useState([]);
    const sse = useRef(true);
    const getData = async (showResult = false) => {
        const req = await RefreshCheckApi.get(`/auth/authNumber?term=${60}`);
        if (req.status === 200) {
            setData(req.data.data);
            if (showResult) {
                alert('새로고침되었습니다');
            }
        }
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <Wrapper02>
            <MainPageTitle text={'인증 번호'} />
            <FlexMainWrapper style={{ marginBottom: 12 }}>
                <MainButton
                    onClick={() => {
                        getData(true);
                    }}
                    text={
                        <span>
                            새로 고침
                            <RedoOutlined style={{ marginLeft: 4 }} />
                        </span>
                    }
                />
            </FlexMainWrapper>
            <div>
                <MText color={'rgb(255, 16, 125)'} text={'* 상대방이 인증번호를 요청/재전송 요청시 새로고침으로 확인해야합니다'} />
                <MText color={'rgb(255, 16, 125)'} text={'* 해당 사용자가 인증을 완료시 인증내역이 사라집니다'} />
            </div>
            <Wrapper03>
                <Table>
                    <thead>
                        <tr>
                            <th>전화번호</th>
                            <th>인증번호</th>
                            <th>생성시간</th>
                            <th>상태</th>
                            <th>재요청 횟수</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((e, i) => (
                            <tr key={`auth_table_row_${i}`}>
                                <td>{e.phone_no}</td>
                                <td>{e.auth_no}</td>
                                <td>{dateFilter(e.created_at)}</td>
                                <td style={{ width: 100 }}>
                                    <IsExpired date={e.created_at} />
                                </td>
                                <td style={{ width: 100 }}>{e.auth_cnt}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Wrapper03>
        </Wrapper02>
    );
};
const IsExpired = ({ date }) => {
    const [leftSecond, setLeftSecond] = useState(0);
    const timerRef = useRef(null);
    useEffect(() => {
        const m = moment(date).add(10, 'minutes');
        const second = m.diff(moment(), 'seconds');
        setLeftSecond(second);
        if (second > 0) {
            timerRef.current = setInterval((e) => {
                setLeftSecond((pre) => pre - 1);
            }, 1000);
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [date]);
    useEffect(() => {
        if (leftSecond < 0) {
            clearInterval(timerRef.current);
        }
    }, [leftSecond]);
    if (leftSecond <= 0) {
        return <span style={{ color: 'rgb(255, 16, 125)' }}>만료</span>;
    }
    return (
        <span style={{ color: '#2977ff' }}>
            {('00' + Math.floor(leftSecond / 60)).slice(-2)}:{('00' + (leftSecond % 60)).slice(-2)}
        </span>
    );
};

export default AuthNumberHistory;
