import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import DesignTable from '../../designComponents/tables/DesignTable';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { colorFilter, contentFilter, dateFilter } from '../../utils/textFilter';

// 가입 모임정보 모달창
const CheckUserGatheringModal = ({ userId, type, setToggle, closeModal }) => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [allCount, setAllCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        RefreshCheckApi.get(`/adminUser/user/gathering?user_id=${userId}&type=${type}`)
            .then((response) => {
                setData(response.data.data);
                setAllCount(response.data.data.length);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                alert('[ERROR]관리자에게 문의하세요');
            });
    }, [type, userId]);

    // 모임정보 페이지로 이동
    const onClickMoveGathering = (id) => {
        closeModal();
        return navigate(`/gathering/detail/${id}`);
    };

    return (
        <DefaultModal
            bodyWidth={54}
            boxStyle={{ width: '117vh', height: '65vh' }}
            title={parseInt(type) === 1 ? '참여모임 목록' : '운영모임 목록'}
            xIcon={true}
            onClickClose={() => setToggle({})}
            closeOn
            cancelText="닫기"
            onClickBottomClose={() => setToggle({})}
        >
            <FlexMainWrapper style={{ margin: '10px 5px' }}>
                <DesignTable
                    loading={loading}
                    marginTop={0.5}
                    header={[
                        { title: '모임 ID', width: 50 },
                        { title: '모임명', width: 230 },
                        { title: '모임개설일', width: 135 },
                        { title: '모임삭제여부', width: 80 },
                        { title: '노출여부', width: 80 },
                        { title: '모임장 명', width: 120 },
                        { title: '모임장 연락처', width: 100 },
                        { title: '활동지역', width: 135 },
                        { title: '가입회원 수', width: 80 },
                    ]}
                    onClick={(item) => {
                        return onClickMoveGathering(item.gathering_id);
                    }}
                    body={[
                        { key: 'gathering_id', width: 50 },
                        { key: 'gathering_name', width: 230 },
                        { key: 'gathering_created_at', width: 135 },
                        { key: 'gathering_deleted_at', width: 80 },
                        { key: 'exposure_yn', width: 80 },
                        { key: 'gathering_owner_name', width: 120 },
                        { key: 'gathering_owner_phone_no', width: 100 },
                        { key: 'gathering_address', width: 135 },
                        { key: 'crew_count', width: 80 },
                    ]}
                    data={data}
                    allCount={allCount}
                    page={page}
                    setPage={setPage}
                    bodyRederItem={(key, value, item) => {
                        if (key === 'gathering_name') return contentFilter(value);
                        if (key === 'gathering_owner_name') return contentFilter(value, 20);
                        if (key === 'gathering_created_at') return dateFilter(value);
                        if (key === 'gathering_deleted_at') return colorFilter(value ? false : true, value ? 'DELETED' : 'PROGRESS');
                        if (key === 'user_name') return `${value}/${item.user_nick_name}`;
                        if (key === 'gathering_address') return value || '-';
                        if (key === 'crew_count') return `${value || 0}명`;
                        return value;
                    }}
                    loadingText={'요청하신 시럽 사용 내역을 불러오고 있습니다.'}
                    noneText={'시럽 사용 내역이 존재하지 않습니다.'}
                    noneHieght={27}
                />
            </FlexMainWrapper>
        </DefaultModal>
    );
};

export default CheckUserGatheringModal;
