// 상품리스트, 상품상세조회, 상품추가, 상품삭제
import produce from 'immer';
import { handleActions } from 'redux-actions';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

/************************ action을 정의 ************************/

// 상품생성
export const POST_ADD_PRODUCT_REQUEST = 'POST_ADD_PRODUCT_REQUEST';
export const POST_ADD_PRODUCT_SUCCESS = 'POST_ADD_PRODUCT_SUCCESS';
export const POST_ADD_PRODUCT_FAILURE = 'POST_ADD_PRODUCT_FAILURE';
// 상품조회
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
// 상품상세조회
export const GET_PRODUCT_DETAIL_REQUEST = 'GET_PRODUCT_DETAIL_REQUEST';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'GET_PRODUCT_DETAIL_FAILURE';
// 상품정보수정
export const UPDATE_PRODUCT_DETAIL_REQUEST = 'UPDATE_PRODUCT_DETAIL_REQUEST';
export const UPDATE_PRODUCT_DETAIL_SUCCESS = 'UPDATE_PRODUCT_DETAIL_SUCCESS';
export const UPDATE_PRODUCT_DETAIL_FAILURE = 'UPDATE_PRODUCT_DETAIL_FAILURE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/************************ 초기상태 ************************/
const initialState = {
    productLists: null, // 상품정보를 담고 있는 데이터 객체
    productList: null, // 상품상세정보를 담고 있는 데이터 객체
    updateProductMessage: null, // 상품정보수정
    // 상품생성
    addProductLoading: false,
    addProductDone: false,
    addProductError: null,
    // 상품조회
    loadProductLoading: false,
    loadProductDone: false,
    loadProductError: null,
    // 상품상세조회
    loadProductDetailLoading: false,
    loadProductDetailDone: false,
    loadProductDetailError: null,
    // 상품수정
    loadProductUpdateLoading: false,
    loadProductUpdateDone: false,
    loadProductUpdateError: null,
    // 상품삭제
};

/************************ action 함수 ************************/

// 상품생성
export const createProduct = (hostInformationLists, product, productOption, productThumbnail) => async (dispatch) => {
    dispatch({
        type: POST_ADD_PRODUCT_REQUEST,
    });
    try {
        // 상품생성
        const addProductResponse = await RefreshCheckApi.post('adminProduct', {
            host_id: hostInformationLists.id, // 호스트정보
            name: product.name, // 상품명
            promotional_title: product.promotional_title, // 상품 타이틀
            text_info: product.text_info, // 상품 상세정보
            list_price: product.list_price, // 상품 가격
            discount_price: product.discount_price || 0, // 상품 할인가
            default_option_seq: 0,
            big_category: 1, // 대 분류
            small_category: 1, // 소 분류
        });

        // 옵션, 이미지 생성
        const response = await RefreshCheckApi.post('adminProduct/detailProductInfo', {
            product_id: addProductResponse.data.data.id, // 상품정보
            productOption: productOption, //
            productThumbnail: productThumbnail,
        });
        dispatch({
            type: POST_ADD_PRODUCT_SUCCESS,
            product: addProductResponse.data.data,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: POST_ADD_PRODUCT_FAILURE,
            payload: error,
        });
    }
};

// 상품조회
export const getProduct = (params) => async (dispatch) => {
    dispatch({
        type: GET_PRODUCT_REQUEST,
    });
    const response = await RefreshCheckApi.get('adminProduct', { params });

    try {
        dispatch({
            type: GET_PRODUCT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_FAILURE,
            payload: error,
        });
    }
};

// 상품상세조회
export const getProductDetail = (id, host_id) => async (dispatch) => {
    dispatch({
        type: GET_PRODUCT_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`adminProduct/detail?id=${id}&host_id=${host_id}`);

        dispatch({
            type: GET_PRODUCT_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_DETAIL_FAILURE,
            payload: error,
        });
    }
};

// 상품수정
export const updateProductDetail = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminProductOrder/product`, body);

        dispatch({
            type: UPDATE_PRODUCT_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_DETAIL_FAILURE,
            payload: error,
        });
    }
};

/************************ reducer ************************/
const productReducer = handleActions(
    {
        // 상품추가
        [POST_ADD_PRODUCT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.addProductLoading = true;
                draft.addProductDone = false;
                draft.addProductError = null;
            }),
        [POST_ADD_PRODUCT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.addProductLoading = false;
                draft.addProductDone = true;
            }),
        [POST_ADD_PRODUCT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.addProductLoading = false;
                draft.addProductError = action.error;
            }),

        // 상품조회
        [GET_PRODUCT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductLoading = true;
                draft.loadProductDone = false;
                draft.loadProductError = null;
            }),
        [GET_PRODUCT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductLoading = false;
                draft.productLists = action.payload;
                draft.loadProductDone = true;
            }),
        [GET_PRODUCT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductLoading = false;
                draft.loadProductError = action.error;
            }),

        // 상품상세조회
        [GET_PRODUCT_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductDetailLoading = true;
                draft.loadProductDetailDone = false;
                draft.loadProductDetailError = null;
            }),
        [GET_PRODUCT_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductDetailLoading = false;
                draft.productList = action.payload;
                draft.loadProductDetailDone = true;
            }),
        [GET_PRODUCT_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductDetailLoading = false;
                draft.loadProductDetailError = action.error;
            }),

        // 상품정보수정
        [UPDATE_PRODUCT_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductUpdateLoading = true;
                draft.loadProductUpdateDone = false;
                draft.loadProductUpdateError = null;
            }),
        [UPDATE_PRODUCT_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductUpdateLoading = false;
                draft.updateProductMessage = action.payload;
                draft.loadProductUpdateDone = true;
            }),
        [UPDATE_PRODUCT_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductUpdateLoading = false;
                draft.loadProductUpdateError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadProductUpdateLoading = true;
                draft.loadProductUpdateDone = false;
                draft.loadProductUpdateError = null;
                draft.updateProductMessage = null;
            }),
    },
    initialState,
);

export default productReducer;
