export const phone_reg = /^\d{11}$/g
export const format_phone = (text)=>{
    if(text)
        return text.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
    else
        return text;
}
export const format_number = (text)=>{

        if(text){
            return String(text).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else
            return text;
}
