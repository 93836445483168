import styled from 'styled-components';
import React from 'react';
import { h_px36rate, w_px12rate, w_px145rate } from '../../size';
import { rgb_0_0_0, rgb_229_229_229, rgb_255_255_255 } from '../../colors';

const WhiteBtn = ({ children, onClick, style }) => {
    return (
        <Btn style={{ ...style }} onClick={onClick}>
            {children}
        </Btn>
    );
};

export default React.memo(WhiteBtn);

const Btn = styled.button`
    width: ${w_px145rate}px;
    height: ${h_px36rate}px;
    border: 1px solid ${rgb_229_229_229};
    background-color: ${rgb_255_255_255};
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: noto_sans_kr_bold;
    color: ${rgb_0_0_0};
    :hover {
        background-color: rgb(231 228 228);
    }
`;
