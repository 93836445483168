import styled from 'styled-components';

export const ImageInfoWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 30px;

  margin: 13px 0;

  font-size: 14px;
  font-weight: 400;
`;

export const SubTitle = styled.div`
  width: 80px;

  font-size: 13px;
  color: rgb(121, 121, 121);
`;

export const AddImageBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 3px;
`;

export const AddImageBtn = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 0;
  background-color: rgb(66, 139, 247);

  font-size: 13px;
  color: #fff;

  width: 78px;
  height: 78px;

  :hover {
    background-color: rgb(45 118 227);
  }
`;

export const Image = styled.img`
  object-fit: cover;

  width: 78px;
  height: 78px;

  border: 1px solid rgb(229, 229, 229);
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;

  margin: 10px 0;

  font-size: 14px;
  font-weight: 400;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 25px;

  .check-box {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
  }
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  cursor: pointer;

  width: 15px;
  height: 15px;

  border: 1px solid rgba(66 139 247 0.2);
  border-radius: 50%;

  margin: 0px;
  outline: none;

  &:before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60%;
    height: 60%;

    margin: 20% auto;
    border-radius: 50%;
  }
  &:checked:before {
    background: rgb(66 139 247);
  }
`;
