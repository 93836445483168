import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
    h_px10rate,
    h_px12rate,
    h_px14rate,
    h_px16rate,
    h_px25rate,
    h_px6rate,
    h_px80rate,
    w_px11rate,
    w_px215rate,
    w_px257rate,
    w_px25rate,
    w_px26rate,
    w_px5rate,
    w_px60rate,
    w_px8rate,
    w_px93rate,
} from '../../../../../size';
import { rgb_0_0_0, rgba_0_0_0_0d1 } from '../../../../../colors';
import RadioBtn from '../../../../radio_btn/RadioBtn';
import DivisionWhiteBlueLine from '../../../../division_white_blue_line/DivisionWhiteBlueLine';
import DetailTextarea from '../../../../detail_textarea/DetailTextarea';
import DetailTextInput from '../../../../detail_text_input/DetailTextInput';
import UserName from '../../../../user_name/UserName';
import SubTitle from '../../../../sub_title/SubTitle';
import ListName from '../../../../list_name/ListName';
import ContentText from '../../../../content_text/ContentText';
import WhiteBtn from '../../../../white_btn/WhiteBtn';
import PinkBtn from '../../../../pink_btn/PinkBtn';
import { main_server_image_ip } from '../../../../../../communication_system/communication_ips';
import { table_date_function } from '../../../../../../data_system/data_functions/table_date_function/table_date_function';
import MatchDispatchCenter from '../../../../../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';

const UserDetailComponent = ({ usersDetail }) => {
    const [profilePhotoAuthYn, setProfilePhotoAuthYn] = useState(usersDetail['profile_photo_auth_yn'] === 2 ? 2 : 0);

    const onClickProfilePhotoAuthYnYes = useCallback(() => {
        setProfilePhotoAuthYn(2);
    }, []);

    const onClickProfilePhotoAuthYnNo = useCallback(() => {
        setProfilePhotoAuthYn(0);
    }, []);

    const [phoneNo, setPhoneNo] = useState(usersDetail['phone_no']);

    const onChangePhoneNo = useCallback((e) => {
        setPhoneNo(e.target.value);
    }, []);

    const [userStatus, setUserStatus] = useState(usersDetail['user_status']);

    const onClickUserStatusYes = useCallback(() => {
        setUserStatus(0);
    }, []);

    const onClickUserStatusNo = useCallback(() => {
        setUserStatus(1);
    }, []);

    const [adminMemo, setAdminMemo] = useState(usersDetail['admin_memo'] === null ? '' : usersDetail['admin_memo']);

    const onChangeAdminMemo = useCallback((e) => {
        setAdminMemo(e.target.value);
    }, []);

    const { onPutUsersDetailAsync } = MatchDispatchCenter();

    return (
        <div
            style={{
                paddingTop: h_px25rate,
                paddingLeft: w_px25rate,
            }}
        >
            <UserName>{usersDetail['user_name']}</UserName>
            <div
                style={{
                    marginTop: h_px10rate,
                    marginBottom: h_px14rate,
                }}
            >
                <SubTitle>프로필 정보</SubTitle>
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        marginRight: w_px26rate,
                    }}
                >
                    <ListName>프로필 사진</ListName>
                </div>
                <ProfileImgScroll>
                    {usersDetail['image_list'].map((image_item, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    marginRight: w_px11rate,
                                }}
                            >
                                <ProfileImg src={main_server_image_ip + image_item['image_url']}></ProfileImg>
                            </div>
                        );
                    })}
                </ProfileImgScroll>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: h_px12rate,
                }}
            >
                <div
                    style={{
                        marginRight: w_px26rate,
                    }}
                >
                    <ListName>카메라 인증</ListName>
                </div>
                <div>
                    <div
                        style={{
                            marginBottom: h_px6rate,
                        }}
                    >
                        <RadioTotalFrame>
                            <RadioFrame>
                                <RadioBtn
                                    onClick={onClickProfilePhotoAuthYnYes}
                                    checked={profilePhotoAuthYn === 2 ? true : false}
                                ></RadioBtn>
                                <div
                                    style={{
                                        marginLeft: w_px5rate,
                                    }}
                                >
                                    <ContentText>Y(AI판별 일치)</ContentText>
                                </div>
                            </RadioFrame>
                            <div
                                style={{
                                    marginLeft: w_px5rate,
                                }}
                            >
                                <RadioFrame>
                                    <RadioBtn
                                        onClick={onClickProfilePhotoAuthYnNo}
                                        checked={profilePhotoAuthYn <= 1 ? true : false}
                                    ></RadioBtn>
                                    <div
                                        style={{
                                            marginLeft: w_px5rate,
                                        }}
                                    >
                                        <ContentText>N(AI판별 불일치)</ContentText>
                                    </div>
                                </RadioFrame>
                            </div>
                        </RadioTotalFrame>
                    </div>
                    <div
                        style={{
                            marginBottom: h_px12rate,
                        }}
                    >
                        <ProfileImg src={main_server_image_ip + usersDetail['user_auth_photo_url']}></ProfileImg>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: h_px12rate,
                }}
            >
                <ListName>나이 / 성별</ListName>
                <div
                    style={{
                        marginLeft: w_px26rate,
                    }}
                >
                    <ContentText>
                        {usersDetail['user_age']}세 / {parseInt(usersDetail['sex']) === 2 ? '여성' : '남성'}
                    </ContentText>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: h_px12rate,
                }}
            >
                <ListName>휴대폰 번호</ListName>
                <div
                    style={{
                        marginLeft: w_px26rate,
                    }}
                >
                    <DetailTextInput
                        width={w_px93rate}
                        height={h_px16rate}
                        font_size={w_px11rate}
                        value={phoneNo}
                        onChange={onChangePhoneNo}
                    ></DetailTextInput>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: h_px12rate,
                }}
            >
                <ListName>관심사</ListName>
                <div
                    style={{
                        marginLeft: w_px26rate,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {usersDetail['interest_list'].map((interest_item, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ContentText>{interest_item['interests_name']}</ContentText>
                                {usersDetail['interest_list'].length - 1 !== index && <ContentText>&nbsp;/&nbsp;</ContentText>}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: h_px12rate,
                }}
            >
                <ListName>거주지</ListName>
                <div
                    style={{
                        marginLeft: w_px26rate,
                    }}
                >
                    <ContentText>{usersDetail['address']}</ContentText>
                </div>
            </div>
            <div
                style={{
                    marginBottom: h_px12rate,
                    alignItems: 'center',
                }}
            >
                <DivisionWhiteBlueLine></DivisionWhiteBlueLine>
            </div>
            <div
                style={{
                    marginBottom: h_px14rate,
                }}
            >
                <SubTitle>추가 정보</SubTitle>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: h_px12rate,
                }}
            >
                <ListName>가입일</ListName>
                <div
                    style={{
                        marginLeft: w_px26rate,
                    }}
                >
                    <ContentText>{table_date_function(usersDetail['created_at'])}</ContentText>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: h_px12rate,
                }}
            >
                <div
                    style={{
                        marginRight: w_px26rate,
                    }}
                >
                    <ListName>이용 상태</ListName>
                </div>
                <div>
                    <div
                        style={{
                            marginBottom: h_px6rate,
                        }}
                    >
                        <RadioTotalFrame>
                            <RadioFrame>
                                <RadioBtn
                                    checked={parseInt(userStatus) === 0 ? true : false}
                                    onClick={onClickUserStatusYes}
                                ></RadioBtn>
                                <div
                                    style={{
                                        marginLeft: w_px5rate,
                                    }}
                                >
                                    <ContentText>정상</ContentText>
                                </div>
                            </RadioFrame>
                            <div
                                style={{
                                    marginLeft: w_px5rate,
                                }}
                            >
                                <RadioFrame>
                                    <RadioBtn
                                        checked={parseInt(userStatus) === 1 ? true : false}
                                        onClick={onClickUserStatusNo}
                                    ></RadioBtn>
                                    <div
                                        style={{
                                            marginLeft: w_px5rate,
                                        }}
                                    >
                                        <ContentText>이용차단</ContentText>
                                    </div>
                                </RadioFrame>
                            </div>
                        </RadioTotalFrame>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: h_px12rate,
                }}
            >
                <ListName>관리자 메모</ListName>
                <div
                    style={{
                        marginLeft: w_px26rate,
                    }}
                >
                    <DetailTextarea
                        width={w_px215rate}
                        height={h_px80rate}
                        font_size={w_px11rate}
                        value={adminMemo}
                        onChange={onChangeAdminMemo}
                        disabled={false}
                    ></DetailTextarea>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: h_px12rate,
                }}
            >
                <WhiteBtn>취소</WhiteBtn>
                <div
                    style={{
                        marginLeft: w_px8rate,
                    }}
                >
                    <PinkBtn
                        onClick={() => {
                            onPutUsersDetailAsync({
                                user_id: usersDetail['user_id'],
                                phone_no: phoneNo,
                                user_status: userStatus,
                                admin_memo: adminMemo,
                                photo_auth_yn: profilePhotoAuthYn,
                            });
                        }}
                    >
                        저장
                    </PinkBtn>
                </div>
            </div>
        </div>
    );
};

export default React.memo(UserDetailComponent);

const ProfileImgScroll = styled.div`
    display: flex;
    width: ${w_px257rate}px;
    overflow: auto;
    &::-webkit-scrollbar {
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${rgba_0_0_0_0d1};
        border-radius: 10px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
`;

const ProfileImg = styled.img`
    width: ${w_px60rate}px;
    height: ${w_px60rate}px;
    border-radius: ${w_px60rate / 2}px;
    background-color: ${rgb_0_0_0};
`;

const RadioTotalFrame = styled.div`
    display: flex;
`;

const RadioFrame = styled.div`
    display: flex;
    align-items: center;
`;
