import {
    admin_status_current_graph_router,
    admin_status_current_router,
    admin_status_pay_router,
    admin_status_user_account_line_router,
    admin_status_user_account_page_router,
    adminAccount_detail_router,
    adminAccount_router,
    adminActivity_activity_adminMemo_router,
    adminActivity_activity_detail_review_router,
    adminActivity_activity_router,
    adminActivity_activityPay_adminMemo_router,
    adminActivity_activityPay_router,
    adminActivity_host_adminMemo_router,
    adminActivity_host_router,
    adminBenefit_announce_detail_router,
    adminBenefit_announce_router,
    adminBenefit_coupon_router,
    adminBenefit_point_router,
    adminChatting_router,
    adminDescribe_adminMemo_router,
    adminDescribe_describeAdd_router,
    admin_subscribe_router,
    adminReport_router,
    adminUser_adminMemo_router,
    adminUser_detail_router,
    adminUser_router,
    common_code_router,
    sms_router,
    adminPoint_router,
    adminCoupon_router,
    adminEvent_router,
    adminEvent_transform_router,
    adminHost_router,
    admin_active_user_router,
    admin_status_additional_router,
} from '../communication_router_urls';
import RefreshCheckApi from './refresh_check/refresh_check_api';
import MainRefreshCheckApi from './refresh_check/main_refresh_check_api';
import RefreshCheckApiWithFormdata from './refresh_check/refresh_check_api_with_formdata';
import moment from 'moment';
import { main_server_ip } from '../communication_ips';

// 고객데이터 파이차트 요청 URL
export async function getCustomerPieData(id) {
    const response = await RefreshCheckApi.get(`/adminStatus/${id}/chart`);

    return response;
}

// 고객데이터 요청 URL
export async function getCustomerStatus(id, platform) {
    const response = await RefreshCheckApi.get(`/adminStatus/${id}?platform=${platform}`);

    return response;
}

// 클릭률 요청 URL
export async function getAdminStatus(id, page, size, lineDateType, lineStartDate, lineEndDate) {
    // const response = await MainRefreshCheckApi.get(
    const response = await RefreshCheckApi.get(`/adminStatus/${id}?page=${page}&size=${size}&graphType=${lineDateType}&startDate=${lineStartDate}&endDate=${lineEndDate}`);

    return response;
}

export async function getUsers(input) {
    const response = await RefreshCheckApi.get(adminUser_router, { params: input });

    return response;
}

export async function getUsersSearch(input) {
    const response = await RefreshCheckApi.get(adminUser_router, { params: input });
    return response;
}

export async function getUsersSearchForCoupon(input) {
    const response = await RefreshCheckApi.get(adminUser_router + `?keyword=${input.keyword}&query_type=${2}`);
    return response;
}

export async function getUsersDetail(id) {
    const response = await RefreshCheckApi.get(adminUser_detail_router + `/${id}`);

    return response;
}

export async function getCommonCode(detail) {
    const response = await RefreshCheckApi.get(common_code_router + (detail ? `?code_group=${detail}` : '/all'));
    return response;
}

export async function putUsersDetail(input) {
    const id = input.id;
    const response = await RefreshCheckApi.put(adminUser_detail_router + `/${id}`, input);
    return response;
}

export async function putUsersDetailAdminMemo(input) {
    const response = await RefreshCheckApi.put(adminUser_adminMemo_router, input);

    return response;
}

export async function getAdministrators() {
    const response = await RefreshCheckApi.get(adminAccount_router);

    return response;
}

export async function postAdministrators(input) {
    const response = await RefreshCheckApi.post(adminAccount_router, input);

    return response;
}

export async function deleteAdministrators(input) {
    const response = await RefreshCheckApi.delete(adminAccount_router + `?admin_id=${input.admin_id}`);

    return response;
}

export async function getAdministratorsSearch(input) {
    const response = await RefreshCheckApi.get(adminAccount_router + `?keyword=${input.keyword}&query_type=${input.query_type}`);

    return response;
}

export async function getAdministratorsDetail(id) {
    const response = await RefreshCheckApi.get(adminAccount_detail_router + `?admin_id=${id}`);

    return response;
}

export async function putAdministratorsDetail(input) {
    return await RefreshCheckApi.put(adminAccount_detail_router, input);
}

export async function getSubscribePayments(input) {
    const response = await RefreshCheckApi.get(admin_subscribe_router, { params: input });
    return response;
}

export async function getSubscribePaymentsSearch(input) {
    const response = await RefreshCheckApi.get(admin_subscribe_router, { params: input });
    return response;
}

export async function getSubscribePaymentsDetail(id) {
    const response = await RefreshCheckApi.get(admin_subscribe_router + `/${id}`);
    return response;
}

export async function putSubscribePaymentsDetailAdminMemo(input) {
    const response = await RefreshCheckApi.put(adminDescribe_adminMemo_router, input);

    return response;
}

export async function putSubscribePaymentsDetailDescribeAdd(input) {
    const response = await RefreshCheckApi.put(adminDescribe_describeAdd_router, input);

    return response;
}

export async function getSubscribePaymentsDetailRefund(input) {
    const response = await RefreshCheckApi.get(admin_subscribe_router + `/${input}/cancel`);

    return response;
}

export async function getReports(input) {
    const response = await RefreshCheckApi.get(adminReport_router, { params: input });

    return response;
}

export async function getReportsSearch(input) {
    const response = await RefreshCheckApi.get(adminReport_router + `?keyword=${input.keyword}&query_type=${input.query_type}&now_yn=${input.now_yn}`);

    return response;
}

export async function postReportsDetailAdminMemo(input) {
    const response = await RefreshCheckApi.post(adminReport_router + `/${input.report_id}/memo`, {
        content: input.admin_memo,
    });

    return response;
}

export async function getChattings(input) {
    const response = await RefreshCheckApi.get(adminChatting_router, { params: input });

    return response;
}

export async function getChattingsSearch(input) {
    const response = await RefreshCheckApi.get(adminChatting_router, { params: input });

    return response;
}

export async function getChattingsDetail(input) {
    const id = input.chatting_id;
    delete input.id;
    const response = await RefreshCheckApi.get(adminChatting_router + `/${id}`, { params: input });

    return response;
}

export async function getActivityPayments(input) {
    const response = await RefreshCheckApi.get(adminActivity_activityPay_router, { params: input });

    return response;
}

export async function getActivityPaymentsSearch(input) {
    const response = await RefreshCheckApi.get(adminActivity_activityPay_router + `?keyword=${input.keyword}&query_type=${input.query_type}&pay_type=${input.pay_type}`);

    return response;
}

export async function getActivityPaymentsDetail(id) {
    const response = await RefreshCheckApi.get(adminActivity_activityPay_router + `/${id}`);

    return response;
}

export async function putActivityPaymentsDetailAdminMemo(input) {
    const response = await RefreshCheckApi.put(adminActivity_activityPay_adminMemo_router, input);

    return response;
}

export async function getActivityPaymentsDetailRefund(input) {
    const response = await RefreshCheckApi.get(adminActivity_activityPay_router + `/${input}/refund`);

    return response;
}

// admin Host 관련
export async function getHosts(input) {
    const response = await RefreshCheckApi.get(adminActivity_host_router, { params: input });

    return response;
}

export async function getHostsSearch(input) {
    const response = await RefreshCheckApi.get(adminActivity_host_router, { params: input });

    return response;
}

export async function postHosts(input) {
    const response = await RefreshCheckApi.post(adminActivity_host_router, input);

    return response;
}

export async function postHostImage(formData) {
    const response = await RefreshCheckApiWithFormdata.post(adminActivity_host_router + '/transform', formData);
    return response;
}

export async function postActivityImage(formData) {
    const response = await RefreshCheckApiWithFormdata.post(adminActivity_activity_router + '/transform', formData);
    return response;
}

export async function getHostsDetail(id) {
    const response = await RefreshCheckApi.get(adminActivity_host_router + `/${id}`);
    return response;
}

export async function putHostsDetail(input) {
    const id = input.host_id;
    delete input.host_id;
    const response = await RefreshCheckApi.put(adminActivity_host_router + `/${id}`, input);

    return response;
}

export async function getHostsAll() {
    const response = await RefreshCheckApi.get(adminActivity_host_router + '/all');
    return response;
}

export async function putHostsDetailAdminMemo(input) {
    const response = await RefreshCheckApi.put(adminActivity_host_adminMemo_router, input);

    return response;
}

export async function deleteAdminHost(input) {
    return await RefreshCheckApi.delete(adminHost_router, input);
}

// Activity 관련
export async function getActivities(input) {
    return await RefreshCheckApi.get(adminActivity_activity_router, { params: input });
}

export async function postActivities(input) {
    const response = await RefreshCheckApi.post(adminActivity_activity_router, input);

    return response;
}

export async function deleteActivities(input) {
    return await RefreshCheckApi.delete(adminActivity_activity_router, input);
}

export async function getActivitiesSearch(input) {
    const response = await RefreshCheckApi.get(adminActivity_activity_router + `?keyword=${input.keyword}&query_type=${input.query_type}&active_yn=${input.active_yn}`);

    return response;
}

export async function getActivitiesDetail(id) {
    const response = await RefreshCheckApi.get(adminActivity_activity_router + `/${id}`);

    return response;
}

export async function putActivityDetail(param) {
    const response = await RefreshCheckApi.put(adminActivity_activity_router + `/${param.activity_id}`, param);
    return response;
}

export async function putActivitiesDetailAdminMemo(input) {
    const response = await RefreshCheckApi.put(adminActivity_activity_adminMemo_router, input);

    return response;
}

export async function getPoints(input) {
    const response = await RefreshCheckApi.get(adminPoint_router, { params: input });

    return response;
}

export async function getPointsSearch(input) {
    const response = await RefreshCheckApi.get(adminBenefit_point_router + `?keyword=${input.keyword}&query_type=${input.query_type}`);

    return response;
}

export async function getPointsDetail(id) {
    const response = await RefreshCheckApi.get(adminPoint_router + `/${id}`);

    return response;
}

export async function postPoints(input) {
    const response = await RefreshCheckApi.post(adminPoint_router, input);
    return response;
}

export async function getCoupons(input) {
    const response = await RefreshCheckApi.get(adminCoupon_router, { params: input });

    return response;
}

export async function getCouponsSearch(input) {
    const response = await RefreshCheckApi.get(adminBenefit_coupon_router + `?keyword=${input.keyword}&query_type=${input.query_type}`);

    return response;
}

export async function getCouponsDetail(id) {
    const response = await RefreshCheckApi.get(adminCoupon_router + `/${id}`);
    return response;
}

export async function getCouponUseDetail(input) {
    const id = input.id;
    delete input.id;
    const response = await RefreshCheckApi.get(adminCoupon_router + `/${id}/use`, { params: input });
    return response;
}

export async function postCouponDetail(input) {
    const response = await RefreshCheckApi.post(adminCoupon_router, input);

    return response;
}

export async function putCouponDetail(input) {
    const id = input.id;
    delete input.id;
    const response = await RefreshCheckApi.put(adminCoupon_router + `/${id}`, input);
    return response;
}
export async function deleteCoupon(input) {
    const response = await RefreshCheckApi.delete(adminCoupon_router, {
        headers: {
            'Content-Type': `application/json`,
        },
        data: input,
    });
    return response;
}

// adminEvent
export async function getEvents(input) {
    const response = await RefreshCheckApi.get(adminEvent_router, { params: input });
    return response;
}

export async function getEventsDetail(id) {
    const response = await RefreshCheckApi.get(adminEvent_router + `/${id}`);
    return response;
}
export async function transformEventPhoto(input) {
    const response = await RefreshCheckApiWithFormdata.post(adminEvent_router + '/transform', input);
    return response;
}

export async function putEventsDetail(input) {
    const id = input.event_id;
    delete input.event_id;
    const response = await RefreshCheckApi.put(adminEvent_router + `/${id}`, input);

    return response;
}
export async function putEventsOrder(input) {
    const response = await RefreshCheckApi.put(adminEvent_router + '/order', input);
    return response;
}

export async function postEvents(input) {
    const response = await RefreshCheckApiWithFormdata.post(adminEvent_router, input);

    return response;
}

export async function postAdminEvents(input) {
    return await RefreshCheckApi.post(adminEvent_router, input);
}
export async function deleteAdminEvents(input) {
    return await RefreshCheckApi.delete(adminEvent_router, {
        headers: {
            'Content-Type': `application/json`,
        },
        data: input,
    });
}

export async function postAdminEventTransform(input) {
    return await RefreshCheckApiWithFormdata.post({
        adminEvent_transform_router,
        input,
    });
}

// Announcements 관련
export async function getAnnouncements() {
    const response = await RefreshCheckApi.get(adminBenefit_announce_router);

    return response;
}

export async function postAnnouncements(input) {
    const response = await RefreshCheckApi.post(adminBenefit_announce_router, input);

    return response;
}

export async function deleteAnnouncements(input) {
    const response = await RefreshCheckApi.delete(adminBenefit_announce_router + `?announce_id=${input.announce_id}`);

    return response;
}

export async function getAnnouncementsDetail(id) {
    const response = await RefreshCheckApi.get(adminBenefit_announce_detail_router + `?announce_id=${id}`);

    return response;
}

export async function putAnnouncementsDetail(input) {
    const response = await RefreshCheckApi.put(adminBenefit_announce_router, input);

    return response;
}

export async function getReviewLists(id) {
    const response = await RefreshCheckApi.get(adminActivity_activity_detail_review_router + `?activity_id=${id}`);

    return response;
}

export async function deleteReviewLists(input) {
    const response = await RefreshCheckApi.delete(adminActivity_activity_detail_review_router + `?review_id=${input.review_id}`);

    return response;
}

export async function getSmsUserList(param) {
    const response = await RefreshCheckApi.get(sms_router, { params: param });
    return response;
}

export async function sendSmsList(smsList) {
    const response = await RefreshCheckApi.post(sms_router, smsList);
    return response;
}

export async function getAccountTableData(param) {
    const response = await RefreshCheckApi.get(admin_status_user_account_page_router, { params: param });

    return response;
}

export async function getStatisticsAccumulateData(param) {
    const response = await RefreshCheckApi.get(admin_status_current_router, { params: param });
    return response;
}

export async function getStatisticsAccumulateToday(accumulateDate) {
    const response = await MainRefreshCheckApi.get(admin_status_current_router, {
        params: {
            date: moment(accumulateDate).format('YYYY-MM-DD'),
        },
    });
    return response;
}

export async function getStatisticsLine(input) {
    const type = input.type;
    delete input.type;
    const response = await RefreshCheckApi.get(admin_status_user_account_line_router + `/${type}`, { params: input });
    return response;
}

// 통계
export async function getStatisticsAccountRate() {
    const response = await MainRefreshCheckApi.get(admin_status_current_graph_router);
    return response;
}

export async function getStatisticsPaymentTable(input) {
    const response = await RefreshCheckApi.get(admin_status_pay_router, { params: input });
    return response;
}

export async function getStatisticsAdditional() {
    const response = await RefreshCheckApi.get(admin_status_additional_router);
    return response;
}

// 회원활동
export async function getActiveUserGraph(input) {
    const type = input.type;
    delete input.type;

    const response = await RefreshCheckApi.get('/activeUser' + `/${type}`, { params: input });
    // const response = await MainRefreshCheckApi.get(admin_active_user_router + `/${type}`, { params: input });

    return response; // '/adminMarketing/graph'
}

// 리텐션
export async function getRetentionTable(input) {
    const type = input.type;
    delete input.type;
    // let url = admin_retention_router + `/${type}`;
    let url = main_server_ip + '/adminMarketing/graph/retention'; // TEST LOCAL

    const response = await MainRefreshCheckApi.get(url, { params: input });
}
