import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { TextInputNoLine } from '../design_system/components/input/Inputs';
import moment from 'moment/moment';

const DateTimeSelector = ({ onDateChange, showDateType = true, startTime, endTime }) => {
    const [dateType, setDateType] = useState(0);
    const [startDate, setStartDate] = useState(startTime ? moment(startTime) : moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(endTime ? moment(endTime) : moment().subtract(0, 'days').format('YYYY-MM-DD'));
    const onStartDateChange = (e) => {
        const value = moment(e.target.value);
        const end = moment(endDate);
        if (value.isAfter(end)) {
            alert('올바른 날짜를 선택해 주세요');
        } else {
            setStartDate(e.target.value);
        }
    };
    const onEndDateChange = (e) => {
        const value = moment(e.target.value);
        const start = moment(startDate);
        if (value.isBefore(start)) {
            alert('올바른 날짜를 선택해 주세요');
        } else {
            setEndDate(e.target.value);
        }
    };
    useEffect(() => {
        onDateChange(dateType, startDate, endDate);
    }, [dateType, startDate, endDate]);
    return (
        <ButtonWrapper>
            {showDateType && (
                <TypeInputContainer>
                    <TypeInput type={'button'} value={'일별'} style={{ fontWeight: dateType === 0 ? 'bold' : 'normal' }} onClick={() => setDateType(0)} />
                    <div style={{ backgroundColor: 'rgb(229 229 229)', width: '1px' }} />
                    <TypeInput type={'button'} value={'주별'} style={{ fontWeight: dateType === 1 ? 'bold' : 'normal' }} onClick={() => setDateType(1)} />
                    <div style={{ backgroundColor: 'rgb(229 229 229)', width: '1px' }} />
                    <TypeInput type={'button'} value={'월별'} style={{ fontWeight: dateType === 2 ? 'bold' : 'normal' }} onClick={() => setDateType(2)} />
                </TypeInputContainer>
            )}

            <TextInputNoLineWrapper id="date-format">
                <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={startDate} onChange={onStartDateChange} />
                <span>~</span>
                <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={endDate} onChange={onEndDateChange} />
            </TextInputNoLineWrapper>
        </ButtonWrapper>
    );
};

const TypeInputContainer = styled.div`
    border: 1px solid rgb(229 229 229);
    display: flex;
    align-items: center;
    border-radius: 5px;
`;
const TypeInput = styled.input`
    cursor: pointer;
    background-color: rgb(255 255 255);
    font-style: noto_sans_kr_medium;
    border: 0px;
    padding-inline: 12px;
    padding-block: 4px;
`;
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin: 35px auto 20px auto;
`;
const TextInputNoLineWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: 12px;
`;

export default DateTimeSelector;
