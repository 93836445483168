import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// Power User Curve
export const GET_POWER_USER_CURVE_REQUEST = 'GET_POWER_USER_CURVE_REQUEST';
export const GET_POWER_USER_CURVE_SUCCESS = 'GET_POWER_USER_CURVE_SUCCESS';
export const GET_POWER_USER_CURVE_FAILURE = 'GET_POWER_USER_CURVE_FAILURE';
// 상태 초기화
export const RESET_REQUEST = 'RESET_REQUEST';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    powerUserCurve: [],
    // Power User Curve
    getPowerUserCurveLoading: false,
    getPowerUserCurveDone: false,
    getPowerUserCurveError: null,
};

/******************************************
 * action function
 ******************************************/

// Power User Curve
export const requestGetPowerUserCurve = (input) => async (dispatch) => {
    dispatch({
        type: GET_POWER_USER_CURVE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminMarketing/power_user_curve`, { params: input });
        dispatch({
            type: GET_POWER_USER_CURVE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_POWER_USER_CURVE_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const powerUserCurveReducer = handleActions(
    {
        // Power User Curve
        [GET_POWER_USER_CURVE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getPowerUserCurveLoading = true;
                draft.getPowerUserCurveDone = false;
                draft.getPowerUserCurveError = null;
            }),
        [GET_POWER_USER_CURVE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getPowerUserCurveLoading = action.payload ? false : true;
                draft.powerUserCurve = action.payload;
                draft.getPowerUserCurveDone = action.payload ? true : false;
            }),
        [GET_POWER_USER_CURVE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getPowerUserCurveLoading = false;
                draft.getPowerUserCurveError = action.error;
            }),

        // 상태 초기화
        [RESET_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                // Power User Curve
                draft.getPowerUserCurveLoading = false;
                draft.getPowerUserCurveDone = false;
                draft.getPowerUserCurveError = null;
            }),
    },
    initialState,
);

export default powerUserCurveReducer;
