// gathering.schedule;
import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 결제조회
export const GET_USER_ACCOUNT_REQUEST = 'GET_USER_ACCOUNT_REQUEST';
export const GET_USER_ACCOUNT_SUCCESS = 'GET_USER_ACCOUNT_SUCCESS';
export const GET_USER_ACCOUNT_FAILURE = 'GET_USER_ACCOUNT_FAILURE';
// 결제조회 총 카운트
export const GET_USER_ACCOUNT_ALL_COUNT_REQUEST = 'GET_USER_ACCOUNT_ALL_COUNT_REQUEST';
export const GET_USER_ACCOUNT_ALL_COUNT_SUCCESS = 'GET_USER_ACCOUNT_ALL_COUNT_SUCCESS';
export const GET_USER_ACCOUNT_ALL_COUNT_FAILURE = 'GET_USER_ACCOUNT_ALL_COUNT_FAILURE';
// 출금신청 조회
export const GET_ACCOUNT_WITHDRAW_REQUEST = 'GET_ACCOUNT_WITHDRAW_REQUEST';
export const GET_ACCOUNT_WITHDRAW_SUCCESS = 'GET_ACCOUNT_WITHDRAW_SUCCESS';
export const GET_ACCOUNT_WITHDRAW_FAILURE = 'GET_ACCOUNT_WITHDRAW_FAILURE';
// 출금신청 완료
export const UPDATE_ACCOUNT_WITHDRAW_REQUEST = 'UPDATE_ACCOUNT_WITHDRAW_REQUEST';
export const UPDATE_ACCOUNT_WITHDRAW_SUCCESS = 'UPDATE_ACCOUNT_WITHDRAW_SUCCESS';
export const UPDATE_ACCOUNT_WITHDRAW_FAILURE = 'UPDATE_ACCOUNT_WITHDRAW_FAILURE';
// 리셋
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    userAccountStatus: [], // 결제상태 데이터
    userAccountStatusAllCount: null, // 결제상태 총 카운트 데이터
    accountWithdrawLists: [], // 출금신청 리스트 데이터
    updateAccountWithdrawMessage: null,
    // 결제상태관리
    getUserAccountStatusLoading: false,
    getUserAccountStatusDone: false,
    getUserAccountStatusError: null,
    // 결제상태관리 총 카운트
    getUserAccountStatusAllCountLoading: false,
    getUserAccountStatusAllCountDone: false,
    getUserAccountStatusAllCountError: null,
    // 출금신청 조회
    getAccountWithdrawLoading: false,
    getAccountWithdrawDone: false,
    getAccountWithdrawError: null,
    // 출금신청 완료
    updateAccountWithdrawLoading: false,
    updateAccountWithdrawDone: false,
    updateAccountWithdrawError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 회원 계좌관리 조회
export const getUserAccountStatus = (params, excelYN) => async (dispatch) => {
    dispatch({
        type: GET_USER_ACCOUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/account/status`, { params: params });
        dispatch({
            type: GET_USER_ACCOUNT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_ACCOUNT_FAILURE,
            error: error,
        });
    }
};

// 회원 계좌관리 총 카운트 조회
export const getUserAccountStatusAllCount = (params) => async (dispatch) => {
    dispatch({
        type: GET_USER_ACCOUNT_ALL_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/account/status/allCount`, { params: params });
        dispatch({
            type: GET_USER_ACCOUNT_ALL_COUNT_SUCCESS,
            payload: response.data.all_count,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_ACCOUNT_ALL_COUNT_FAILURE,
            error: error,
        });
    }
};

// 출금신청 리스트 조회
export const getAccountWithdraw = (params) => async (dispatch) => {
    dispatch({
        type: GET_ACCOUNT_WITHDRAW_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/account`, { params: params });
        dispatch({
            type: GET_ACCOUNT_WITHDRAW_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ACCOUNT_WITHDRAW_FAILURE,
            error: error,
        });
    }
};

// 출금신청 완료(업데이트)
export const updateAccountWithdraw = (dataLists) => async (dispatch) => {
    dispatch({
        type: UPDATE_ACCOUNT_WITHDRAW_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering/pay_withdraw`, dataLists);
        dispatch({
            type: UPDATE_ACCOUNT_WITHDRAW_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ACCOUNT_WITHDRAW_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const gatheringAccountReducer = handleActions(
    {
        // 회원 결제상태 조회
        [GET_USER_ACCOUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAccountStatusLoading = true;
                draft.getUserAccountStatusDone = false;
                draft.getUserAccountStatusError = null;
            }),
        [GET_USER_ACCOUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAccountStatusLoading = false;
                draft.userAccountStatus = action.payload;
                draft.getUserAccountStatusDone = true;
            }),
        [GET_USER_ACCOUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAccountStatusLoading = false;
                draft.getUserAccountStatusError = action.error;
            }),

        // 회원 결제상태 총 카운트
        [GET_USER_ACCOUNT_ALL_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAccountStatusAllCountLoading = true;
                draft.getUserAccountStatusAllCountDone = false;
                draft.getUserAccountStatusAllCountError = null;
            }),
        [GET_USER_ACCOUNT_ALL_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAccountStatusAllCountLoading = false;
                draft.userAccountStatusAllCount = action.payload;
                draft.getUserAccountStatusAllCountDone = true;
            }),
        [GET_USER_ACCOUNT_ALL_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAccountStatusAllCountLoading = false;
                draft.getUserAccountStatusAllCountError = action.error;
            }),

        // 출금신청 리스트 조회
        [GET_ACCOUNT_WITHDRAW_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getAccountWithdrawLoading = true;
                draft.getAccountWithdrawDone = false;
                draft.getAccountWithdrawError = null;
            }),
        [GET_ACCOUNT_WITHDRAW_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getAccountWithdrawLoading = false;
                draft.accountWithdrawLists = action.payload;
                draft.getAccountWithdrawDone = true;
            }),
        [GET_ACCOUNT_WITHDRAW_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getAccountWithdrawLoading = false;
                draft.getAccountWithdrawError = action.error;
            }),

        // 출금신청 완료 처리
        [UPDATE_ACCOUNT_WITHDRAW_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateAccountWithdrawLoading = true;
                draft.updateAccountWithdrawDone = false;
                draft.updateAccountWithdrawError = null;
            }),
        [UPDATE_ACCOUNT_WITHDRAW_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateAccountWithdrawLoading = action.payload ? false : true;
                draft.updateAccountWithdrawMessage = action.payload;
                draft.updateAccountWithdrawDone = action.payload ? true : false;
            }),
        [UPDATE_ACCOUNT_WITHDRAW_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateAccountWithdrawLoading = false;
                draft.updateAccountWithdrawError = action.error;
            }),

        // 상태 리셋
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 출금신청 리스트 조회
                draft.getAccountWithdrawLoading = false;
                draft.getAccountWithdrawDone = false;
                draft.getAccountWithdrawError = null;
                // 출금신청 완료 처리
                draft.updateAccountWithdrawMessage = null;
                draft.updateAccountWithdrawLoading = false;
                draft.updateAccountWithdrawDone = false;
                draft.updateAccountWithdrawError = null;
            }),
    },
    initialState,
);

export default gatheringAccountReducer;
