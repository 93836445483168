import React, { useEffect, useState } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';

// react-query && axios
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// recharts
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// components
import LoadingPage from '../../pages/loading/LoadingPage';
import DateTimeSelector from '../../common/DateTimeSelector';

// 매출 차트
const PaymentStackedBarChart = ({ lineWidth }) => {
    const [lineDateType, setLineDateType] = useState(0);
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().subtract(0, 'days').format('YYYY-MM-DD'));

    // react-query
    const { isLoading, error, data, refetch } = useQuery(['paymentGraph', lineDateType, startDate, endDate], async () => {
        let url = `/adminStatus/payment/graph?date_type=${lineDateType}&start_date=${startDate}&end_date=${endDate}`;
        return await RefreshCheckApi.get(url).then((res) => res.data.data);
    });

    // 에러감지
    useEffect(() => {
        if (error) {
            console.error('error: ', error);
            alert(error);
        }
    }, [error]);

    const Label = (props) => {
        const { x, y, value } = props;

        return (
            <>
                <text x={x - 92} y={y - 15} dx={'2%'} dy={'-1%'} fontSize="11" fontWeight="bold" fill={'#000'} textAnchor="left">
                    {false && '전체매출'}
                </text>
                <text x={x - 98} y={y} dx={'2%'} dy={'-1%'} fontSize="12" fontWeight="bold" fill={'#bf923a'} textAnchor="left">
                    {false && value.toLocaleString('ko-KR')}
                </text>
            </>
        );
    };

    let marginObj = {
        top: 20,
        right: 25,
        left: 25,
        bottom: 5,
    };

    return (
        <div>
            <DateTimeSelector
                onDateChange={(type, start, end) => {
                    setLineDateType(type);
                    setStartDate(start);
                    setEndDate(end);
                    refetch();
                }}
            />

            {isLoading ? (
                <LoadingPage />
            ) : (
                <div id="gragh-wrapper">
                    <BarChart width={lineWidth} height={550} data={data} margin={marginObj}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar dataKey="단짝" stackId="a" fill="#FF107D" />
                        <Bar dataKey="모임" stackId="a" fill="#428BF7" />
                        <Bar dataKey="전체매출" stackId="a" fill="#ffc65800" label={<Label />} />
                    </BarChart>
                </div>
            )}
        </div>
    );
};

export default PaymentStackedBarChart;

/** 툴팁 커스텀 */
export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <TooltipWrapper className="custom-tooltip">
                <p className="label">{`${label}`}</p>
                <TooltipText>
                    {payload.map((pld) => {
                        return (
                            <Text style={{ marginBottom: '5%' }}>
                                <div style={{ color: pld.name === '전체매출' ? '#ffc658' : pld.fill }}>{pld.dataKey}:</div>
                                <div style={{ color: pld.name === '전체매출' ? '#ffc658' : pld.fill }}>{parseInt(pld.value)?.toLocaleString('ko-KR')}원</div>
                            </Text>
                        );
                    })}
                </TooltipText>
            </TooltipWrapper>
        );
    }

    return null;
};

const TooltipWrapper = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #fff;
    border: 1px solid rgb(205, 205, 205);

    width: 150px;
    padding: 0 20px;
`;

const TooltipText = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
`;

const Text = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
