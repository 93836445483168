import React, { useCallback, useState } from 'react';

import OrderPageComponents from '../../components/ProductComponent/OrderPageComponents';
import RefundOrderPageComponents from '../../components/ProductComponent/RefundOrderPageComponents';

import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

/** 주문 관리 */
const OrderLists = () => {
    const [allOrderListsToggle, setAllOrderListsToggle] = useState(true);
    const [cancelAndChangeToggle, setCancelAndChangeToggle] = useState(false);

    /****************************************** function ******************************************/

    // 전체보기
    const onClickAll = useCallback(() => {
        setAllOrderListsToggle(true);
        setCancelAndChangeToggle(false);
    }, [setAllOrderListsToggle, setCancelAndChangeToggle]);

    // 취소/교환/환불(반품) 보기
    const onClickCancel = useCallback(() => {
        setCancelAndChangeToggle(true);
        setAllOrderListsToggle(false);
    }, [setAllOrderListsToggle, setCancelAndChangeToggle]);

    const rederItem = [
        { text: '전체', toggle: allOrderListsToggle, onPress: onClickAll },
        { text: '취소/교환/환불(반품)', toggle: cancelAndChangeToggle, onPress: onClickCancel },
    ];

    /******************************************** html ********************************************/
    return (
        <Wrapper02>
            <MainPageTitle sigle={false} rederItem={rederItem} />
            {allOrderListsToggle && <OrderPageComponents />}
            {cancelAndChangeToggle && <RefundOrderPageComponents />}
        </Wrapper02>
    );
};

export default OrderLists;
