import React, { useCallback, useEffect, useRef, useState } from 'react';
import MainTable from '../../designComponents/tables/MainTable';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';
import { alarmTalkFilter, reservealarmFilter } from '../../dataSystems/PageFilters/AlarmTalkFilter';
import { colorFilter, contentFilter, dateFilter } from '../../utils/textFilter';

const AlarmTalkReserveHistory = () => {
    const [param, setParam] = useState({ page: 1, size: 10 });

    const ref = useRef(null);

    /** 날짜를 넣지 않으면 기본적으로 오늘 ~ 일주일 전 기록을 보내줌 */
    const callApi = async (page, param) => {
        try {
            const input = { page: page, size: 10, ...param };
            const response = await MainRefreshCheckApi.get('/adminRequest/alarmTalk/reserve', { params: input });
            return response.data.data || [];
        } catch (error) {
            console.error(error);
        }
    };

    const pageCountCallApi = async (page, param) => {
        try {
            const input = { page: page, size: 10, ...param };
            const response = await MainRefreshCheckApi.get('/adminRequest/alarmTalk/reserve', { params: input });
            return response.data.data?.all_cnt;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <MainTable
                buttonComponents={({ checkItems, setCheckItems }) => {
                    return (
                        <TopSideButton
                            leftOn={false}
                            middleOn={false}
                            text02={'예약 전송 취소'}
                            rightWidth={100}
                            onClickDelete={async () => {
                                if (!checkItems.length) return alert('선택된 메세지가 없습니다.');
                                let failed = false;
                                checkItems.forEach((requestId) => {
                                    const body = { data: { request_id: requestId } };
                                    const res = MainRefreshCheckApi.delete('/adminRequest/alarmTalk/reserve', body);
                                    res.then((res) => {
                                        if (!res.data.ok) failed = true;
                                        setCheckItems([]);
                                        ref?.current?.refresh();
                                    }).catch((err) => {
                                        alert(`[관리자 문의] ${err.message}`);
                                    });
                                });
                                if (!failed) alert('예약 전송 취소 완료');
                            }}
                        />
                    );
                }}
                ref={ref}
                tableStyle={{ width: '95%' }}
                loadingText={'알람톡 내역을 불러오고 있습니다.'}
                headerData={reservealarmFilter}
                checkID={'request_id'}
                param={param}
                setParam={setParam}
                toggleBlur={true}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {}}
                tbodyRenderItem={(key, value) => {
                    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;
                    if (key === 'completeTime') return dateFilter(value);
                    if (key === 'requestTime') return dateFilter(value);
                    if (key === 'plusFriendId') return <span style={{ fontWeight: 600 }}>{value}</span>;
                    if (key === 'messageStatusDesc') return colorFilter(value === '정상 발송', value);
                    if (key === 'requestStatusDesc') return colorFilter(value === '성공', value);
                    if (key === 'content') return contentFilter(value, 25);
                    if (key === 'status') return colorFilter(value === 1, value === 1 ? '전송 성공' : '전송 대기');
                    return value || nullTag;
                }}
            />
        </>
    );
};

export default AlarmTalkReserveHistory;
