import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 편지 리스트 불러오기
export const GET_LETTER_REQUEST = 'GET_LETTER_REQUEST';
export const GET_LETTER_SUCCESS = 'GET_LETTER_SUCCESS';
export const GET_LETTER_FAILURE = 'GET_LETTER_FAILURE';
// 편지 리스트 전체 길이 불러오기
export const GET_LETTER_COUNT_REQUEST = 'GET_LETTER_COUNT_REQUEST';
export const GET_LETTER_COUNT_SUCCESS = 'GET_LETTER_COUNT_SUCCESS';
export const GET_LETTER_COUNT_FAILURE = 'GET_LETTER_COUNT_FAILURE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    getLetterLists: [], // 편지 데이터
    getLetterListsAllCount: null, // 편지 데이터 총 길이
    // 편지 리스트
    getLetterLoading: false,
    getLetterDone: false,
    getLetterError: null,
    // 편지 총 길이 불러오기
    getLetterAllCountLoading: false,
    getLetterAllCountDone: false,
    getLetterAllCountError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 편지 리스트 불러오기
export const requestGetLetter = (input) => async (dispatch) => {
    dispatch({
        type: GET_LETTER_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminLetter`, { params: input });

        dispatch({
            type: GET_LETTER_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_LETTER_FAILURE,
            error: error,
        });
    }
};

// 편지 리스트 페이지 길이 불러오기
export const requestGetLetterAllCount = (input) => async (dispatch) => {
    dispatch({
        type: GET_LETTER_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminLetter/allCount`, { params: input });
        dispatch({
            type: GET_LETTER_COUNT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_LETTER_COUNT_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const getLetterReducer = handleActions(
    {
        // 편지 현황 조회
        [GET_LETTER_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getLetterLoading = true;
                draft.getLetterDone = false;
                draft.getLetterError = null;
            }),
        [GET_LETTER_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getLetterLoading = action.payload ? false : true;
                draft.getLetterLists = action.payload;
                draft.getLetterDone = action.payload ? true : false;
            }),
        [GET_LETTER_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getLetterLoading = false;
                draft.getLetterError = action.error;
            }),

        // 편지 총 길이 조회
        [GET_LETTER_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getLetterAllCountLoading = true;
                draft.getLetterAllCountDone = false;
                draft.getLetterAllCountError = null;
            }),
        [GET_LETTER_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getLetterAllCountLoading = action.payload ? false : true;
                draft.getLetterListsAllCount = action.payload;
                draft.getLetterAllCountDone = action.payload ? true : false;
            }),
        [GET_LETTER_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getLetterAllCountLoading = false;
                draft.getLetterAllCountError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                draft.getLetterLoading = false;
                draft.getLetterDone = false;
                draft.getLetterError = null;
            }),
    },
    initialState,
);

export default getLetterReducer;
