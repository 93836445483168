import React, { useCallback, useContext, useRef, useState } from 'react';

// styled-components
import { ArrowRightOutlined } from '@ant-design/icons';

// utils
import { checkReadFilter, contentFilter, dateFilter, sexFilter } from '../../utils/textFilter';

// data_system
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

// communication_system
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import { letterTableFilter } from '../../components/LetterComponents/LetterTableFilter';
import LetterDetailModal from '../../components/LetterComponents/LetterDetailModal';
import UserModal from '../../components/UserComponents/UserModal';

// design-components
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import { ImageModal } from '../../designComponents/modals/ImageModal';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

/** 편지조회 페이지 */
const Letter = () => {
    // regacy-context api
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({});

    const [detailData, setDetailData] = useState([]);
    const [letterDetailToggle, setLetterDetailToggle] = useState(false);
    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');

    const ref = useRef();
    const currentTarget = useRef();

    const callApi = useCallback(
        async (page, param) => {
            let input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.get(`/adminLetter`, { params: input });

            return response.data.data;
        },
        [searchText, searchType],
    );

    const pageCountCallApi = useCallback(
        async (page, param) => {
            let input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.get(`/adminLetter/allCount`, { params: input });
            return response.data.allCount;
        },
        [searchText, searchType],
    );

    const onClickDetailContent = useCallback((data) => {
        setDetailData(data);
        setLetterDetailToggle(true);
    }, []);

    const onClickUserDetail = useCallback(
        (user_id) => () => {
            user_info_modal_actions.setId(user_id);
            user_info_modal_actions.setModalVisible(true);
        },
        [user_info_modal_actions],
    );

    return (
        <Wrapper02>
            {user_info_modal_state.modalVisible ? <UserModal setImageToggle={setImageToggle} setSrc={setSrc} filterParam={param} /> : null}

            {imageToggle && <ImageModal onClickClose={() => setImageToggle(false)} ref={currentTarget} src={src} toggle={imageToggle} />}

            {letterDetailToggle && <LetterDetailModal detailData={detailData} setModalToggle={setLetterDetailToggle} onClickUserDetail={onClickUserDetail} />}

            <MainPageTitle text={'편지'} />
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '이름', value: 1 },
                    { id: 2, title: '닉네임', value: 2 },
                    { id: 3, title: '핸드폰번호', value: 3 },
                    { id: 4, title: '편지내용', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />

            <MainTable
                ref={ref}
                tableStyle={{ width: '98%' }}
                loadingText={'채팅 내역을 불러오고 있습니다.'}
                headerData={letterTableFilter}
                checkID={'id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    onClickDetailContent(item);
                }}
                tbodyRenderItem={(key, value) => {
                    if (!key) return <ArrowRightOutlined />;
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'content') return contentFilter(value);
                    if (key === 'user_sex' || key === 'target_user_sex') return sexFilter(value);
                    if (key === 'read_yn') return checkReadFilter(value);
                    if (key === 'refund_yn') return checkReadFilter(value, 'Y', 'N');
                    return value;
                }}
            />
        </Wrapper02>
    );
};

export default Letter;
