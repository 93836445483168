import React, { useCallback, useContext, useRef, useState } from 'react';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import useInput from '../../hook/useInput';
import MainTable from '../../designComponents/tables/MainTable';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { callHistoryFilter } from '../../dataSystems/PageFilters/CallHistoryFilter';
import { ArrowRightOutlined } from '@ant-design/icons';
import { HoverSpan } from '../../designComponents/tables/TbodyLists';
import UserModal from '../../components/UserComponents/UserModal';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

const CallHistory = () => {
    const { user_info_modal_state, user_info_modal_actions } = useContext(UserInfoModalContext);

    const [searchText, onChangeSearchText] = useInput('');
    const [searchType, setSearchType] = useState(1);
    const [param, setParam] = useState({});

    const ref = useRef();

    const onClickUserDetail = (user_id) => {
        user_info_modal_actions.setId(user_id);
        user_info_modal_actions.setModalVisible(true);
    };

    const callApi = async (page, param) => {
        try {
            const input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.get('/adminCall', { params: input });

            return response.data.data;
        } catch (error) {
            console.error('error: ', error);
        }
    };

    const pageCountCallApi = async (page, param) => {
        try {
            const response = await RefreshCheckApi.get('/adminCall');
            return response.data.all_cnt;
        } catch (error) {
            console.error('error: ', error);
        }
    };

    return (
        <Wrapper02>
            {user_info_modal_state.modalVisible && <UserModal />}
            <MainPageTitle
                text={'통화내역'}
                sigle={true}
                onClick={() => ref?.current?.refresh()}
                wrapperStyle={{ cursor: 'pointer' }}
            />
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '전체검색', value: 1 },
                    { id: 2, title: '휴대폰번호', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '이름', value: 4 },
                    { id: 5, title: '회원ID', value: 5 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={onChangeSearchText}
            />
            <MainTable
                ref={ref}
                headerData={callHistoryFilter}
                checkID={'id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                tbodyRenderItem={(key, value, item) => {
                    if (!key) return <ArrowRightOutlined />;
                    if (key === 'receive_user_name')
                        return <HoverSpan onClick={() => onClickUserDetail(item.receive_user_id)}>{value}</HoverSpan>;
                    if (key === 'send_user_name')
                        return <HoverSpan onClick={() => onClickUserDetail(item.send_user_id)}>{value}</HoverSpan>;
                    if (key === 'receive_user_nick_name' || key === 'send_nick_name')
                        return <span style={{ color: '#797979', fontWeight: 600 }}>{value}</span>;
                    else return value;
                }}
            />
        </Wrapper02>
    );
};

export default CallHistory;
