import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

// styled-components
import { Wrapper, DateFilterBtnWrapper, DateFilterBtnWrapper02, FilterTitle, DateButton, DateInput, ResetButton, SearchWrapper, Select } from './PageStyled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrderLists } from '../../data_system/redux/modules/product_reducer/product.order_reducer';

// components
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';
import SearchBar from '../../design_system/components/searchbar/SearchBar';
import Dropdown from '../../components/Common/Dropdown';
import OrderTable from './OrderTable';

/** 주문내역 페이지 */
const OrderPageComponents = () => {
    const dispatch = useDispatch();

    // useSelect
    const { orderLists } = useSelector((state) => state.orderReducer);

    // state
    const [deliveryNum, setDeliveryNum] = useState([]); // 배송상태
    const [onlyPayments, setOnlyPayments] = useState(false);
    const [page, setPage] = useState(1); // 페이지
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({}); // 요청 query-params
    // 기준날짜
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    /************************************ useEffect ************************************/

    // 주문내역 데이터 조회 요청
    useEffect(() => {
        dispatchOrderLists(param);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, param, onlyPayments, startDate, endDate, searchText]);

    /************************************ function ************************************/

    // 주문조회 함수
    const dispatchOrderLists = (tmp) => {
        let param = {
            page: page,
            size: 10,
            search_type: searchType,
            search_word: searchText,
            only_payment_user: onlyPayments ? 1 : 0,
            start_date: startDate,
            end_date: endDate,
            type: 1,
        };

        dispatch(getOrderLists(param));
    };

    // 배송상태 조회 필터
    // eslint-disable-next-line no-unused-vars
    const onClickFilter = (num) => () => {
        let arr = [...deliveryNum];

        if (deliveryNum.includes(num)) {
        } else {
            arr.push(num);
            setDeliveryNum(arr);
        }
    };

    // 시작날짜 설정
    const onChangeStartDate = (e) => {
        setStartDate(e.target.value);
    };

    // 끝 날짜 설정
    const onChangeEndDate = (e) => {
        setEndDate(e.target.value);
    };

    // 기간설정 초기화
    const onClickDateReset = () => {
        setStartDate(null);
        setEndDate(moment().format('YYYY-MM-DD'));
    };

    // 조회기간 설정
    const onClickSetDate = (type) => () => {
        let _type = parseInt(type);

        switch (true) {
            case _type === 1: // 오늘
                setStartDate(moment().format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
                break;
            case _type === 2: // 일주일
                setStartDate(moment().subtract(1, 'weeks').format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
                break;
            case _type === 3: // 1개월
                setStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
                break;
            case _type === 4: // 3개월
                setStartDate(moment().subtract(3, 'months').format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
                break;
            default:
                setStartDate(null);
                setEndDate(moment().format('YYYY-MM-DD'));
                break;
        }
    };

    // 검색어
    const onChangeInput = useCallback((e) => {
        setSearchText(e.target.value);
    }, []);

    // 검색 요청
    const onChangeSubmit = (e) => {
        e.preventDefault();

        setPage(1);
        dispatchOrderLists(param);
    };

    // 상태 SELECT 필터
    const selectFilter = (data) => {
        return (
            <Select onChange={onChangeInput}>
                {data?.map((item, index) => {
                    return (
                        <option key={index} value={item.key}>
                            {item.value}
                        </option>
                    );
                })}
            </Select>
        );
    };

    // 검색창 type 필터
    const searchbarFilter = (type_num) => {
        let _type_num = parseInt(type_num);

        // 일반 검색창 FORM
        let searchbarForm = (
            <form style={{ marginLeft: '5px' }} onSubmit={onChangeSubmit}>
                <SearchBar type="submit" searchText={searchText} onChangeSearchText={onChangeInput} />
            </form>
        );

        // 주문상태 FORM
        let payStatusSelectForm = selectFilter([
            { key: 7, value: '전체보기' },
            { key: 0, value: '결제 대기중' },
            { key: 1, value: '입금 대기중' },
            { key: 2, value: '결제 완료' },
            { key: 3, value: '환불 신청' },
            { key: 4, value: '환불 완료' },
            { key: 5, value: '환불 거절' },
            { key: 6, value: '입금 기한 만료' },
        ]);

        // 배송상태 FORM
        let orderStatusSelectForm = selectFilter([
            { key: 6, value: '전체보기' },
            { key: 0, value: '상품 준비중' },
            { key: 1, value: '배송 중' },
            { key: 2, value: '배송 완료' },
            { key: 3, value: '구매 확정' },
            { key: 4, value: '반품 신청' },
            { key: 5, value: '반품 완료' },
        ]);

        // 결제수단 FORM
        let payTypeSelectForm = selectFilter([
            { key: 3, value: '전체보기' },
            { key: 0, value: '신용 카드' },
            { key: 1, value: '가상 계좌' },
        ]);

        switch (true) {
            case _type_num === 1:
                return payStatusSelectForm;
            case _type_num === 2:
                return orderStatusSelectForm;
            case _type_num === 3:
                return searchbarForm;
            case _type_num === 4:
                return searchbarForm;
            case _type_num === 5:
                return searchbarForm;
            case _type_num === 6:
                return searchbarForm;
            case _type_num === 7:
                return searchbarForm;
            case _type_num === 8:
                return searchbarForm;
            case _type_num === 9:
                return payTypeSelectForm;
            default:
                return searchbarForm;
        }
    };

    /******************************* dropdownLists *******************************/

    const dropdownLists = [
        { id: 1, title: '주문상태', value: 1 },
        { id: 2, title: '배송상태', value: 2 },
        { id: 3, title: '주문번호', value: 3 },
        { id: 4, title: '회원명', value: 4 },
        { id: 5, title: '휴대폰 번호', value: 5 },
        { id: 6, title: '상품명', value: 6 },
        { id: 7, title: '판매처', value: 7 },
        { id: 8, title: '배송주소', value: 8 },
        { id: 9, title: '결제수단', value: 9 },
    ];

    /************************************** html **************************************/
    return (
        <Wrapper id="page-wrapper">
            <label id="label">날짜로 조회</label>
            <DateFilterBtnWrapper>
                <DateFilterBtnWrapper02 className="get-date">
                    <FilterTitle>조회</FilterTitle>

                    <DateButton onClick={onClickSetDate(1)}>오늘</DateButton>
                    <DateButton onClick={onClickSetDate(2)}>일주일</DateButton>
                    <DateButton onClick={onClickSetDate(3)}>1개월</DateButton>
                    <DateButton onClick={onClickSetDate(4)}>3개월</DateButton>
                </DateFilterBtnWrapper02>

                <DateFilterBtnWrapper02 className="get-date">
                    <FilterTitle>기간설정</FilterTitle>

                    <DateInput type="date" onChange={onChangeStartDate} value={startDate || false} />
                    <span>~</span>
                    <DateInput type="date" onChange={onChangeEndDate} value={endDate || false} />
                    <ResetButton onClick={onClickDateReset}>초기화</ResetButton>
                </DateFilterBtnWrapper02>
            </DateFilterBtnWrapper>

            <label id="label">검색</label>
            <SearchWrapper type="submit">
                <Dropdown
                    list={dropdownLists}
                    index={0}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                />
                {searchbarFilter(searchType)}
            </SearchWrapper>

            <label id="label">테이블</label>
            <OrderTable order_lists={orderLists?.data} param={param} setParam={setParam} onlyPayments={onlyPayments} setOnlyPayments={setOnlyPayments} type={1} />

            <div className="pagination">
                {orderLists?.all_count > 10 ? <Pagination totalPages={orderLists?.all_count ? Math.round(orderLists?.all_count / 10) + 1 : 10} limit={10} page={page} setPage={setPage} /> : null}
            </div>
        </Wrapper>
    );
};

export default OrderPageComponents;
