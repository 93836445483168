export const index_router_url = "/";
export const login_router_url = "/login";
export const administrator_router_url = "/administrator";
export const subscribePayment_router_url = "/subscribePayment";
export const report_router_url = "/report";
export const chatting_router_url = "/chatting";
export const activityPayment_router_url = "/activityPayment";
export const host_router_url = "/host";
export const activity_router_url = "/activity";
export const point_router_url = "/point";
export const coupon_router_url = "/coupon";
export const event_router_url = "/event";
export const announcement_router_url = "/announcement";

export const redirect_router_url = '/redirect'
