import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { SText } from '../../designComponents/text/ContentText';
import { MainButton } from '../../designComponents/buttons/Buttons';
import React, { useEffect } from 'react';

const AlarmTalkConsole = ({ sendReseltLists, setSendReseltLists }) => {
    return (
        <FlexMainWrapper style={{ flexDirection: 'column', width: '35vh', height: '35vh' }}>
            <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', height: '90%', border: '1px solid #c7c7c7', overflow: 'auto', padding: '10px' }}>
                {sendReseltLists.length ? (
                    sendReseltLists.map((item) => {
                        return <SText text={item} />;
                    })
                ) : (
                    <SText text={'현재 전송한 내역이 없습니다.'} color={'#797979'} />
                )}
            </FlexMainWrapper>
            <FlexMainWrapper style={{ width: '100%', justifyContent: 'start' }}>
                <MainButton text={'기록 삭제'} width={80} onClick={() => setSendReseltLists([])} />
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export default AlarmTalkConsole;
