import produce from 'immer';
import { handleActions } from 'redux-actions';
import { table_date_function } from '../../../data_functions/table_date_function/table_date_function';
import { EVENT_IMAGE, STRING, VIEW_STATE } from '../../../data_type/data_type';
import { GET_EVENTS_DETAIL_FAILURE, GET_EVENTS_DETAIL_SUCCESS, PUT_EVENTS_DETAIL_FAILURE, PUT_EVENTS_DETAIL_SUCCESS } from './events_detail/events_detail_type';
import {
    CHANGE_EVENT_ORDER,
    DELETE_EVENTS_SUCCESS,
    GET_EVENTS_FAILURE,
    GET_EVENTS_SUCCESS,
    POST_EVENTS_FAILURE,
    POST_EVENTS_SUCCESS,
    CHANGE_EVENT_USER_PROFILE_ORDER,
} from './events_table/events_table_type';

const initialState = {
    events: null,
    eventsDetail: null,
};

const eventsReducer = handleActions(
    {
        [GET_EVENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.events = action.payload;
            }),

        [GET_EVENTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_EVENTS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.eventsDetail = action.payload;
            }),

        [GET_EVENTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),

        [PUT_EVENTS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.events.body.length; i++) {
                    if (draft.events.body[i]['event_id'] === action.payload['event_id']) {
                        draft.events.body[i].exposure_yn = action.payload['exposure_yn'];
                        draft.events.body[i].image_url = action.payload['image_url'];
                        draft.events.body[i].title = action.payload['title'];
                        draft.events.body[i].exposure_location = action.payload['exposure_location'];
                        draft.events.body[i].period = action.payload.period;
                        break;
                    }
                }
            }),

        [PUT_EVENTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),

        [POST_EVENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.events = [
                    {
                        id: action.payload['event_id'],
                        data: [
                            {
                                type: VIEW_STATE,
                                data: action.payload['exposure_yn'],
                            },
                            {
                                type: EVENT_IMAGE,
                                data: action.payload['image'],
                            },
                            {
                                type: STRING,
                                data: action.payload['title'],
                            },
                            {
                                type: STRING,
                                data: action.payload['exposure_location'] === 0 ? '마이페이지' : '액티비티 메인',
                            },
                            {
                                type: STRING,
                                data: '0',
                            },
                            {
                                type: STRING,
                                data: `${table_date_function(action.payload['exposure_start_datetime'])} ~ ${table_date_function(action.payload['exposure_end_datetime'])}`,
                            },
                        ],
                    },
                    ...draft.events,
                ];
            }),
        [POST_EVENTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [CHANGE_EVENT_ORDER]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < action.order.length; i++) {
                    const idx = state.events.body.findIndex((e) => e.event_id === action.order[i]);
                    if (idx !== -1 && state.events.body[idx].order !== i) {
                        draft.events.body[idx].order = i;
                    }
                }
            }),

        // 회원정보 이미지 순서변경
        [CHANGE_EVENT_USER_PROFILE_ORDER]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < action.order.length; i++) {
                    const index = action.order.findIndex((e) => e === action.order[i]);
                    if (index !== -1 && action.order[index] !== i) {
                        action.order[index] = action.order[i];
                    }
                }
            }),
    },
    initialState,
);

export default eventsReducer;
