import produce from 'immer';
import { handleActions } from 'redux-actions';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// activeUser
const WAIT_ACTIVATE_USER_DATE = 'WAIT_ACTIVATE_USER_DATE';
const GET_ACTIVE_USERS_SUCCESS = 'GET_ACTIVE_USERS_SUCCESS';
const GET_ACTIVE_USERS_FAIL = 'GET_ACTIVE_USERS_FAIL';

// Retention
const WAIT_RETENTION_TABLE_DATE = 'WAIT_RETENTION_TABLE_DATE';
const GET_RETENTION_SUCCESS = 'GET_RETENTION_SUCCESS';
const GET_RETENTION_FAIL = 'GET_RETENTION_FAIL';

const initialState = {
    activeUserLine: [],
    activeUserLoading: true,
    retentionTableLoading: true,
    retentionTable: { header: [], body: [], all_count: 0 },
};

/** 활성 가입자 수 */
export const getActiveUserData = (input) => async (dispatch) => {
    dispatch({
        type: WAIT_ACTIVATE_USER_DATE,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminMarketing/activeUser`, {
            params: {
                graphType: input.type,
                startDate: input.startDate,
                endDate: input.endDate,
            },
        });

        dispatch({
            type: GET_ACTIVE_USERS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ACTIVE_USERS_FAIL,
            payload: error,
        });
    }
};

// retention Data
export const getRetentionTableData = (input) => async (dispatch) => {
    dispatch({
        type: WAIT_RETENTION_TABLE_DATE,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminMarketing/graph/retention`, {
            params: {
                appType: input.appType,
                graphType: input.type,
                startDate: input.startDate,
                endDate: input.endDate,
            },
        });

        dispatch({
            type: GET_RETENTION_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_RETENTION_FAIL,
            payload: error,
        });
    }
};

const marketingReducer = handleActions(
    {
        [WAIT_ACTIVATE_USER_DATE]: (state, action) =>
            produce(state, (draft) => {
                draft.activeUserLoading = true;
            }),
        [GET_ACTIVE_USERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.activeUserLine = action.payload;
                draft.activeUserLoading = false;
            }),

        [GET_ACTIVE_USERS_FAIL]: (state, action) =>
            produce(state, (draft) => {
                draft.activeUserLoading = false;
            }),
        [WAIT_RETENTION_TABLE_DATE]: (state, action) =>
            produce(state, (draft) => {
                draft.retentionTableLoading = true;
            }),
        [GET_RETENTION_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.retentionTable = action.payload;
                draft.retentionTableLoading = false;
            }),

        [GET_RETENTION_FAIL]: (state, action) =>
            produce(state, (draft) => {
                draft.retentionTable = { header: [], body: [], all_count: 0 };
                draft.retentionTableLoading = false;
            }),
    },
    initialState,
);

export default marketingReducer;
