import React, { useEffect, useRef, useContext } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { ModalWrapper, ModalBox } from '../styles/GatheringAccountWithdrawStyled';
import { ButtonWeapper } from '../styles/AddGatheringModalStyled';

// API
import { main_server_image_ip } from '../../communication_system/communication_ips';

// regacy-components
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

// 편지 상세 조회
function LetterDetailModal({ detailData, setModalToggle, onClickUserDetail }) {
    // regacy-context api
    const { user_info_modal_state } = useContext(UserInfoModalContext);
    // useRef
    const dashMenu = useRef();

    /************************ useEffect ************************/

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                if (!user_info_modal_state.modalVisible) {
                    onClickCancel();
                }
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_info_modal_state]);

    /*************************** function ***************************/

    // 모달창 닫기
    const onClickCancel = () => {
        setModalToggle(false);
    };

    // 프로필 이미지 url 필터 함수
    const imagesFilterUrl = (src) => {
        switch (true) {
            case src?.includes('k.kakaocdn.net'):
                return src;
            case src?.length === 0:
                return null;
            case !src:
                return null;
            default:
                return main_server_image_ip + src;
        }
    };

    // 성별 필터
    const sexFilter = (num) => {
        let _num = parseInt(num);

        if (_num === 1) {
            return <span style={{ color: '#2977ff' }}>(남)</span>;
        } else if (_num === 2) {
            return <span style={{ color: 'rgb(255, 16, 125)' }}>(여)</span>;
        } else {
            return <span style={{ color: 'gray' }}>정보없음</span>;
        }
    };

    /************************* html *************************/
    return (
        <>
            <ModalWrapper>
                <ModalBox ref={dashMenu}>
                    <div className="close-window">
                        <h3>편지 상세 조회</h3>
                        <CloseOutlined className="close-icon" onClick={() => setModalToggle(false)} />
                    </div>

                    <div className="letter-box">
                        <UserImageWrapper>
                            <UserInfoWrapper>
                                <Imageg src={imagesFilterUrl(detailData?.user_url)} alt="보낸사람" onClick={onClickUserDetail(detailData.user_id)} />
                                <div>
                                    {detailData.user_name}
                                    {sexFilter(detailData.user_sex)}
                                </div>
                            </UserInfoWrapper>
                            <div className="arrow">
                                <ArrowRightOutlined />
                            </div>
                            <UserInfoWrapper>
                                <Imageg src={imagesFilterUrl(detailData.target_user_url)} alt="받는사람" onClick={onClickUserDetail(detailData.target_user_id)} />
                                <div>
                                    {detailData.target_user_name}
                                    {sexFilter(detailData.target_user_sex)}
                                </div>
                            </UserInfoWrapper>
                        </UserImageWrapper>

                        <div className="textarea">
                            <Textarea type="text" value={detailData.content} readonly />
                        </div>
                    </div>

                    <ButtonWeapper style={buttonBoxStyled}>
                        <button className="cencle" onClick={onClickCancel}>
                            닫기
                        </button>
                    </ButtonWeapper>
                </ModalBox>
            </ModalWrapper>
        </>
    );
}

export default LetterDetailModal;

const buttonBoxStyled = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    margin: '20px 0',
};

const UserImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .arrow {
        font-weight: bold;
        font-size: 20px;

        margin-bottom: 20px;
    }
`;

const UserInfoWrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 10px;
`;

const Imageg = styled.img`
    cursor: pointer;
    width: 150px;
    height: 150px;

    object-fit: cover;
`;

const Textarea = styled.textarea`
    display: block;
    outline: none;

    margin: 10px auto;
    padding: 5px;

    width: 80%;
    height: 32vh;
    overflow: auto;

    border: 1px solid rgb(0, 0, 0, 0.1);

    @media (max-width: 912px) {
        height: 27vh;
    }
`;
