import React, { useCallback, useRef, useState } from 'react';

import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { gatheringAccountUseListsExcel } from '../../data_system/xlsx_util/xlsx_util';
import { adminUsers } from '../../dataSystems/AdminUserLists';

import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { ExcelButton, MainButton } from '../../designComponents/buttons/Buttons';

import { userAccountStatusFilter } from '../../components/MockData/UserAccountStatusFilter';
import AccountModal from '../../components/GatheringComponents/ModalComponents/AccountModal';
import ContentText from '../../designComponents/text/ContentText';
import { accountFilter, contentFilter, gatheringPayTypeFilter, moneyFilter, moneyUpAndDown, statusFilter } from '../../utils/textFilter';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { getGatheringAccountHistoryListRequest } from '../../api/main/gathering/GatheringAccountRequest';
import TableFilter from '../../designComponents/filters/TableFilter';

/** 계좌 사용 내역 */
const GatheringAccountUseInfo = () => {
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({
        orderType: 1,
        orderByType: 1,
    });

    const [allCharge, setAllCharge] = useState(0);
    const [gatheringAccountHistoryID, setGatheringAccountHistoryID] = useState(0);
    const [detailToggle, setDetailToggle] = useState(false);

    const ref = useRef(null);
    const tableRef = useRef(null);

    // Excel 출력 함수
    const onPressPrintExcel = async () => {
        let input = {
            page: ref?.current?.page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminGathering/account/status`, { params: { ...input, excelYN: true } });
        const headers = [
            { key: 'owner_user_name', value: '모임장명' },
            { key: 'account_bank', value: '은행' },
            { key: 'account_number', value: '계좌번호' },
            { key: 'account_holder', value: '예금주' },
            { key: 'license_number', value: '주민번호' },
            { key: 'business_name', value: '사업자' },
            { key: 'business_number', value: '사업자번호' },
        ];
        gatheringAccountUseListsExcel(headers, response.data.data, '계좌사용내역.xlsx');
    };
    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await getGatheringAccountHistoryListRequest(input);
        setAllCharge(response.all_charge);
        return [...response.data];
    };

    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await getGatheringAccountHistoryListRequest(input);
        return response.all_cnt;
    };

    return (
        <div id="account-using-recode">
            {detailToggle && <AccountModal id={gatheringAccountHistoryID} setDetailToggle={setDetailToggle} />}
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '모임명', value: 1 },
                    { id: 2, title: '모임장명', value: 2 },
                    { id: 3, title: '모임장 닉네임', value: 3 },
                    { id: 4, title: '일정명', value: 4 },
                    { id: 5, title: '모임원명', value: 5 },
                    { id: 6, title: '모임원 닉네임', value: 6 },
                    { id: 7, title: '휴대폰번호', value: 7 },
                    { id: 8, title: '계좌ID', value: 8 },
                    { id: 9, title: '모임ID', value: 9 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => setSearchText(e.target.value)}
            />

            <TableFilter
                param={param}
                setParam={setParam}
                topFilterLists={[
                    {
                        filterText: '정렬 기준',
                        orderLists: [{ name: '사용 일시', width: 50, type: 1 }],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked)
                                obj.orderByType = type; // setOrderbyType(type);
                            else delete obj.orderByType;
                            setParam(obj);
                        },
                        orderbyType: param.orderByType,
                    },
                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림 차순', width: 50, type: 1 },
                            { name: '오름 차순', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked)
                                obj.orderType = type; // setOrderbyType(type);
                            else delete obj.orderType;
                            setParam(obj);
                        },
                        orderbyType: param.orderType,
                    },
                ]}
                filterLists={[
                    {
                        key: 'statusType',
                        value: '출금 상태',
                        filter: [
                            {
                                key: 1,
                                value: '입금',
                            },
                            {
                                key: 2,
                                value: '출금',
                            },
                            {
                                key: 3,
                                value: '이체 신청',
                            },
                        ],
                    },
                    {
                        key: 'contentType',
                        value: '상품 구분',
                        filter: [
                            {
                                key: 0,
                                value: '일정',
                            },
                            {
                                key: 2,
                                value: '연회비',
                            },
                        ],
                    },
                ]}
                research={ref}
            />

            <MainTable
                buttonComponents={({ checkItems, setCheckItems, data }) => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <FlexMainWrapper style={{ width: 'auto' }}>
                            <ContentText text={'누적 수수료의 합:'} style={{ fontSize: '14px', fontWeight: 600 }} />
                            <ContentText text={<span style={{ color: 'rgb(255, 16, 125)', fontWeight: '600' }}>{parseInt(allCharge).toLocaleString('ko-KR')} 원</span>} style={{ fontSize: '14px' }} />
                            <ContentText text={'※ 모임장에게 직접 전달한 현장결제는 출금 후 잔액에 포함되지 않습니다.'} style={{ fontSize: '13px', color: 'gray', marginLeft: '10px' }} />
                        </FlexMainWrapper>
                        <FlexMainWrapper>
                            <TopSideButton
                                rightOn={false}
                                middleOn={false}
                                leftWidth={80}
                                text01={'출금완료'}
                                onClick={async () => {
                                    let arr = [];
                                    const filterData = await data.filter((v) => checkItems.includes(v.gathering_account_history_id));
                                    await filterData.forEach((v) => {
                                        if (v.pay_out_status === 1) arr.push(v.gathering_account_history_id);
                                    });
                                    await setCheckItems(arr);
                                    if (!arr.length) {
                                        ref?.current?.refresh();
                                        return alert('출금신청한 회원이 존재하지 않습니다.');
                                    } else {
                                        const response = await RefreshCheckApi.patch(`/adminGathering/pay_withdraw`, arr);
                                        if (response.status === 200) {
                                            ref?.current?.refresh();
                                            return alert('출금 신청이 완료되었습니다.');
                                        }
                                    }
                                }}
                            />
                            <ExcelButton text="Excel" onClick={onPressPrintExcel} style={{ marginBottom: '5px' }} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                )}
                filterKey={'interest_code'}
                filterItem={(item) => <MainButton text={item.value} onClick={() => {}} />}
                tableStyle={{ width: '95%' }}
                scrollWidth={200}
                inTableStyle={{ fontSize: '11.5px' }}
                ref={ref}
                tableRef={tableRef}
                loadingText={'계좌 사용 내역 리스트를 불러오고 있습니다.'}
                headerData={userAccountStatusFilter}
                checkID={'gathering_account_history_id'}
                param={param}
                toggleBlur={true}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    setGatheringAccountHistoryID(item.gathering_account_history_id);
                    setDetailToggle(true);
                }}
                adminCheck={(item) => adminUsers.includes(item.user_phone_no) && { color: 'rgb(255, 16, 125)' }}
                tbodyRenderItem={(key, value, item) => {
                    const noneInfo = <span style={{ color: '#797979' }}>정보없음</span>;
                    const owner = item.pay_out_status > 0;
                    const mainColor = { color: 'rgb(66,139,247)' };

                    const paySite = <span style={{ color: 'red' }}>현장결제</span>;
                    const passCostIn = `${item.cost?.toLocaleString('ko-KR')}`;
                    const costPayOut = <span style={mainColor}>{`${parseInt(value).toLocaleString('ko-KR')}원`}</span>;

                    if (key === 'history_type') {
                        if (Number(item.history_type) === 0) {
                            return <div>일정</div>;
                        } else if (Number(item.history_type) === 2) {
                            return <div>연회비</div>;
                        } else {
                            return <div>-</div>;
                        }
                    }
                    if (key === 'cost') return moneyUpAndDown(item.status === 1 ? value : -1 * value, '원');
                    if (key === 'gathering_name') return value ? contentFilter(value) : noneInfo;
                    if (key === 'owner_name') return value ? owner ? <span style={mainColor}>{value} (모임장)</span> : value : noneInfo;
                    if (key === 'start_date') return value ? `${value}` : noneInfo;
                    if (key === 'content_title') return value ? contentFilter(value) : noneInfo;
                    if (key === 'charge') return `${parseInt(value).toLocaleString('ko-KR')}원`;
                    if (key === 'cost_pay_out') return value ? costPayOut : '-';
                    if (key === 'total') return `${moneyFilter(value)}원`; // [1, 2].includes(item.pay_out_status) ? moneyFilter(value - item.cost_out) + '원' : '-';
                    if (key === 'cost_out') return value ? `${value?.toLocaleString('ko-KR')}원` : '-';
                    if (key === 'status') return statusFilter(value);
                    if (key === 'pay_out_status') return accountFilter(value);
                    else return value;
                }}
            />
        </div>
    );
};

export default GatheringAccountUseInfo;
