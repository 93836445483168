import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import moment from 'moment';

import styled from 'styled-components';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { arrayToExcelMarketting } from '../../data_system/xlsx_util/xlsx_util';

import LoadingPage from '../../pages/loading/LoadingPage';
import ExcelBtn from '../../components/ClickPercentComponents/Excel/ExcelBtn';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { SText } from '../../designComponents/text/ContentText';
import PlatformTab from '../../GroupComponent/Tab/PlatformTab';

const ActivityUserCountBar = () => {
    const [lineWidth, setLineWidth] = useState(0);
    const lineChartAreaRef = useRef();

    useLayoutEffect(() => {
        setLineWidth(lineChartAreaRef.current.offsetWidth);
    }, [lineChartAreaRef]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lineDateType, setLineDateType] = useState(0);
    const [lineStartDate, setLineStartDate] = useState(moment().subtract(9, 'days').format('YYYY-MM-DD'));
    const [lineEndDate, setLineEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [chartType, setChartType] = useState(0); // 1: 전체, 2: 모임, 3: 단짝

    useEffect(() => {
        getData().then();
    }, [chartType, lineDateType, lineStartDate, lineEndDate]);

    const getData = async () => {
        try {
            setLoading(true);

            const params = {
                graphType: Number(lineDateType),
                startDate: moment(lineStartDate).format('YYYY-MM-DD'),
                endDate: moment(lineEndDate).add(0, 'day').format('YYYY-MM-DD'),
                chartType: chartType,
            };

            const dataResponse = await RefreshCheckApi.get('/adminMarketing/activeUser', { params: params });

            setData(dataResponse.data.data);

            setLoading(false);
        } catch (err) {}
    };

    /** DAU, WAU, MAU 날짜 설정 */
    const setDateForDWMAU = (type) => () => setLineDateType(parseInt(type));

    // 다운로드 엑셀
    const onClickPrintExcel = () => {
        const input = {
            graphType: lineDateType,
            startDate: lineStartDate,
            endDate: lineEndDate,
        };
        const res = RefreshCheckApi.get(`/adminMarketing/activeUser`, { params: input });
        res.then((res) => {
            let arr = [];
            res.data.data.forEach((item, index) => arr.push(item.login_date));
            arrayToExcelMarketting(['날짜', '기존 접속회원', '신규 접속회원'], res.data.data.reverse(), res.data.header); // excel 형식으로 출력
        }).catch((err) => console.error(err));
    };

    const Label = (props) => {
        const { x, y, value } = props;
        return (
            <>
                <text x={x - 92} y={y - 15} dx={'2%'} dy={'-1%'} fontSize="11" fontWeight="bold" fill={'#000'} textAnchor="left">
                    {false && '전체매출'}
                </text>
                <text x={x - 98} y={y} dx={'2%'} dy={'-1%'} fontSize="12" fontWeight="bold" fill={'#bf923a'} textAnchor="left">
                    {false && value.toLocaleString('ko-KR')}
                </text>
            </>
        );
    };

    const dateButtonStyled = (lineDateType, num = 0) => {
        return {
            cursor: 'pointer',
            padding: '5px 0',
            fontWeight: lineDateType === num ? 'bold' : 'normal',
            textAlign: 'center',
            width: '50px',
        };
    };

    const dataType = () => {
        switch (chartType) {
            case 1:
                return '전체';
            case 2:
                return '모임';
            case 3:
                return '단짝';
            default:
                return;
        }
    };

    const marginObj = { top: 20, right: 25, left: 25, bottom: 5 };

    let chartComponent;

    if (loading) {
        chartComponent = <LoadingPage text={`${dataType()}의 마케팅 데이터를 불러오고 있습니다.`} />;
    } else {
        chartComponent = (
            <div id="gragh-wrapper">
                <BarChart width={lineWidth + 30} height={550} data={data} margin={marginObj}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="login_date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey="기존 접속회원" stackId="a" fill="rgb(108 134 255)" />
                    <Bar dataKey="신규 접속회원" stackId="a" fill="rgb(255 150 207)" />
                    <Bar dataKey="총 접속자수" stackId="a" fill="#ffc65800" label={<Label />} />
                </BarChart>
            </div>
        );
    }

    return (
        <LineChartWrapper ref={lineChartAreaRef}>
            <FlexMainWrapper style={{ alignItems: 'start' }}>
                <MainPageTitle text={'활성화 유저'} fontStyle={{ width: '35vh', fontSize: '19px', fontWeight: 'bold' }} />
            </FlexMainWrapper>
            <FlexMainWrapper style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <PlatformTab setPlatform={setChartType}></PlatformTab>

                <FlexMainWrapper style={{ alignItems: 'center' }}>
                    <SText text={'※ 접속 기록 중 재접속한 회원에 대하여 중복이 제외된 지표입니다. ※'} style={{ color: '#797979' }} />
                    <ExcelBtn onPress={onClickPrintExcel} />
                </FlexMainWrapper>
            </FlexMainWrapper>

            <FlexMainWrapper style={{ justifyContent: 'center', marginTop: '20px' }}>
                <FlexMainWrapper style={{ alignItems: 'center', gap: '20px' }}>
                    <FlexMainWrapper
                        style={{
                            alignItems: 'center',
                            width: '100%',
                            gap: '0px',
                            border: '1px solid rgb(229 229 229)',
                            borderRadius: '5px',
                        }}
                    >
                        <SText text={'일별'} onClick={setDateForDWMAU(0)} style={dateButtonStyled(lineDateType, 0)} />
                        <SText text={'주별'} onClick={setDateForDWMAU(1)} style={dateButtonStyled(lineDateType, 1)} />
                        <SText text={'월별'} onClick={setDateForDWMAU(2)} style={dateButtonStyled(lineDateType, 2)} />
                    </FlexMainWrapper>
                    <FlexMainWrapper style={{ alignItems: 'center' }}>
                        <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={lineStartDate} onChange={(e) => setLineStartDate(e.target.value)} />
                        <span>~</span>
                        <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={lineEndDate} onChange={(e) => setLineEndDate(e.target.value)} />
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </FlexMainWrapper>

            {chartComponent}
        </LineChartWrapper>
    );
};

export default ActivityUserCountBar;

/** 툴팁 커스텀 */
export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <TooltipWrapper className="custom-tooltip">
                <p className="label">{`${label}`}</p>
                <TooltipText>
                    {payload.map((pld) => {
                        return (
                            <Text02 style={{ marginBottom: '5%' }}>
                                <div style={{ color: pld.name === '총 접속자수' ? '#ffc658' : pld.fill }}>{pld.dataKey}:</div>
                                <div style={{ color: pld.name === '총 접속자수' ? '#ffc658' : pld.fill }}>{parseInt(pld.value)?.toLocaleString('ko-KR')}</div>
                            </Text02>
                        );
                    })}
                </TooltipText>
            </TooltipWrapper>
        );
    }

    return null;
};

const TooltipWrapper = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #fff;
    border: 1px solid rgb(205, 205, 205);

    width: 150px;
    padding: 0 20px;
`;

const TooltipText = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
`;

const Text02 = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TextInputNoLine = styled.input`
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    font-family: noto_sans_kr_medium;
    box-sizing: border-box;
    border: 0px;

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
`;

export const LineChartWrapper = styled.div`
    width: 92%; // margin: auto;
`;
