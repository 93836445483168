import React, { useState, useEffect } from 'react';
import moment from 'moment';

// styled-components
import { CommentBox, UserImage, Name, Comment, CommentDate } from '../Common/Styles/CommentStyled';

// API
import { main_server_image_ip } from '../../communication_system/communication_ips';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

const Comments = ({ contentsId, style }) => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const response = RefreshCheckApi.get(`/adminGathering/comments/retry?comment_id=${contentsId}`);
        response
            .then((res) => {
                setComments(res.data.data);
            })
            .catch((error) => {
                console.error(error.message);
            });
    }, [contentsId]);

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    return (
        <div style={{ marginLeft: '15%', ...style }}>
            {comments?.map((data, index) => (
                <CommentBox key={index} style={{ margin: '0 10px' }}>
                    <UserImage key={index} sizes={35} src={main_server_image_ip + data.url} alt="회원이미지" onError={handleImgError} />
                    <div style={{ width: '100%' }}>
                        <Name>
                            {data.name}({data.nick_name})<CommentDate>{moment(data.created_at).format('YYYY년 MM월 DD일')}</CommentDate>
                        </Name>
                        <Comment>{data.comment}</Comment>
                    </div>
                </CommentBox>
            ))}
        </div>
    );
};

export default Comments;
