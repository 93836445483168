import styled from 'styled-components';

export const ModalMobile = styled.div`
    @media (max-width: 912px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
    }
`;

export const ModalWrapper = styled.div`
    background-color: #fff;
    width: 400px; // 100%;

    @media (max-width: 912px) {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 3;

        width: 100%;
        height: 85%;
        overflow: auto;
    }
`;

export const CreateTopWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    background-color: #428bf7;
    padding: 0 10px;

    height: 44px;

    font-size: 17px;
    font-weight: bold;
    color: #fff;

    .close-button {
        cursor: pointer;

        font-size: 20px;
        line-height: 17px;
    }
`;

export const Box = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: ${(props) => {
        if (props.limit < 10) {
            return `606px`;
        } else {
            return `${props.height + 33}px`;
        }
    }};

    overflow: auto;

    @media (max-width: 912px) {
        height: 100%;
    }
`;

export const MobileBox = styled.div`
    @media (max-width: 912px) {
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;
        z-index: 3;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        overflow: auto;

        background-color: #000000ab;
    }
`;

export const LoadinLogoWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    font-size: 30px;
    font-weight: bold;

    text-align: center;

    .text {
        font-size: 13px;
    }
`;

export const Alert = styled.div`
    position: absolute;
    top: 1%;
    right: 0.2%;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

    width: 500px;
    height: 70px;
    line-height: 70px;

    border-radius: 10px;

    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 30px 0px;

    text-align: center;
    font-size: 14px;

    .close {
        cursor: pointer;
        position: absolute;
        right: 0;

        padding: 7px;

        font-size: 18px;
        font-weight: bold;
    }
`;

export const SearchingAddressWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;

    width: 100%;
    height: 100vh;

    background-color: #0000009e;
`;

export const ModalBox = styled.div`
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: end;

    .close-window {
        width: 400px;
        background-color: gray;
    }

    .close-icon {
        color: #fff;
        font-size: 20px;
        padding: 7px;
    }
`;

export const SettingDateModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;

    background-color: #0000009e;

    @media (max-width: 912px) {
        z-index: 5;
    }
`;

export const ChooseDateModalBox = styled.div`
    background-color: #fff;

    width: 300px;
    height: 250px;

    padding: 20px 50px;

    border-radius: 10px;

    .close-window {
        text-align: end;
        color: gray;
    }

    .title {
        color: #428bf7;
    }

    .date {
        border: 1px solid #428bf742;

        width: 100%;
        height: 17px;

        padding: 5px;
    }

    .text {
        outline: none;
        border: 1px solid #428bf742;

        width: 100%;
        height: 17px;

        padding: 5px;
    }

    .add-btn {
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin: 10% auto;

        width: 50px;
        height: 28px;

        text-align: center;

        color: #fff;
        background-color: #428bf7;
    }
`;

export const SearchWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;

    #form {
        display: flex;
        align-items: center;
        justify-content: start;

        width: 45%;
    }

    input {
        outline: none;
        border: 1px solid #428bf742;

        width: 100%;
        height: 17px;

        padding: 5px;
    }

    .search-btn {
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 50px;
        height: 29px;

        text-align: center;

        color: #fff;
        border: 1px solid #428bf7;
        background-color: #428bf7;
    }
`;

export const ChooseCreaterModalBox = styled.div`
    background-color: #fff;

    width: 630px; // 45%;
    height: 700px;

    padding: 20px 50px;

    border-radius: 10px;

    @media (max-width: 912px) {
        width: 80%;
    }

    .top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 20px 0;
    }

    .title {
        color: #428bf7;
    }

    .date {
        border: 1px solid #428bf742;

        width: 100%;
        height: 17px;

        padding: 5px;
    }

    .add-btn {
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin: 1% auto;

        width: 50px;
        height: 28px;

        text-align: center;

        color: #fff;
        background-color: #428bf7;
    }
`;

export const Select = styled.select`
    cursor: pointer;
    outline: none;

    width: 100px;
    height: 29px;

    border: 1px solid #428bf742;
`;

export const TableWrapper = styled.div`
    margin: 20px 0;

    height: 53vh;
    width: 100%;

    overflow: auto;

    table {
        border-collapse: collapse;
        margin: 0;
        padding: 0;

        width: 100%;

        margin: auto;
    }

    table > thead {
        font-size: 15px;
        border-bottom: 1px solid black;
    }

    table > tbody > tr > th {
        text-align: start;
        width: 70px;
    }

    table > tbody > tr {
        width: 100%;
        height: 33px;

        font-size: 15px;
        font-weight: 600;

        border-bottom: 1px solid black;
    }

    table > tbody > tr > td {
        padding: 0 5px;
    }

    table > tfoot > tr {
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    table > tfoot > tr:hover {
        background-color: #f7f7ff;
    }

    table > tfoot > tr > td {
        font-size: 14px;
        font-weight: 500;

        padding: 0 5px;
    }
`;
