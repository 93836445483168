import React, { useEffect, useState } from 'react';

// styled-components
import { PlusOutlined } from '@ant-design/icons';
import {
    RowLine,
    DetailModalBox,
    CloseBtnWrapper,
    DetailInfoWrapper,
    CustomLeftOutlined,
    TitleInput,
    Title,
    SubTitle,
    ScheduleInfoWrapper,
    AddButtonWrapper,
    AddButton,
    ButtonWeapper,
} from '../styles/ActivityDetailModalStyled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { createActivity, getAllHosts } from '../../data_system/redux/modules/activities_reducer/activity.create.reducer';

// components
import AddActivityModalInfoEntry from './AddActivityModalInfoEntry';
import AddActivityModalActivityInfoEntry from './AddActivityModalActivityInfoEntry';
import ActivityDetailModalScheduleEntry from './ActivityDetailModalScheduleEntry';

function AddActivityModal({ headerHeight, height, setAddModalToggle }) {
    const dispatch = useDispatch();
    // useSelector
    const { addActivityLoading, addActivityDone, addActivityError } = useSelector((state) => state.addActivityReducer);
    const { allHosts } = useSelector((state) => state.addActivityReducer);
    // state
    const [toggleAddSchedule, setToggleAddSchedule] = useState(false);
    const [title, setTitle] = useState('');
    // param
    const [infoParams, setInfoParams] = useState({}); // 기본정보
    const [activityInfoParams, setActivityInfoParams] = useState({}); // 놀이정보

    // 일정 Params
    const [scheduleParams, setScheduleParams] = useState([]);

    useEffect(() => {
        dispatch(getAllHosts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /******************** function ********************/

    // Modal 창 닫기
    const closeModal = () => {
        setAddModalToggle(false);
    };

    // 일정 추가
    const onClickAddScheduleBtn = () => {
        setToggleAddSchedule((prev) => !prev);
    };

    // 제목 입력 함수
    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    // 취미/여가 생성
    const onClickAddActivity = () => {
        const param = {
            title: title,
            detail_image_list: infoParams.detail_image_list,
            exposure_yn: infoParams.exposure_yn,
            crawling_yn: infoParams.crawling_yn,
            category: infoParams.category,
            host_id: infoParams.host_id,
            routin_yn: activityInfoParams.routin_yn,
            routin_count: activityInfoParams.routin_count,
            price: activityInfoParams.price,
            discount_rating: activityInfoParams.discount_rating,
            visit_yn: activityInfoParams.visit_yn,
            zone: activityInfoParams.zone,
            address: activityInfoParams.address,
            address_detail: activityInfoParams.address_detail,
            explanation: activityInfoParams.explanation,
            info_url: activityInfoParams.explanation_image[0],
            notice: activityInfoParams.notice,
            material: activityInfoParams.material,
            refund: activityInfoParams.refund,
            caution: activityInfoParams.caution,
            date_list: scheduleParams,
        };

        dispatch(createActivity(param));
    };

    /************************ html ************************/
    return (
        <>
            {toggleAddSchedule ? <ActivityDetailModalScheduleEntry setToggleAddSchedule={setToggleAddSchedule} scheduleParams={scheduleParams} setScheduleParams={setScheduleParams} /> : null}

            <DetailModalBox>
                <CloseBtnWrapper headerHeight={headerHeight}>
                    <CustomLeftOutlined onClick={closeModal} />
                    <div>상세조회</div>
                </CloseBtnWrapper>

                <DetailInfoWrapper height={height}>
                    <TitleInput type="text" onChange={onChangeTitle} placeholder="취미/여가 명을 입력하세요." />

                    <div id="information">
                        <Title>기본정보</Title>
                        <AddActivityModalInfoEntry setInfoParams={setInfoParams} allHosts={allHosts} />
                    </div>

                    <RowLine />

                    <div id="activity-information">
                        <Title>놀이정보</Title>
                        <AddActivityModalActivityInfoEntry setActivityInfoParams={setActivityInfoParams} />
                    </div>

                    <RowLine />

                    <div id="schedule">
                        <ScheduleInfoWrapper>
                            <SubTitle>일정</SubTitle>

                            <AddButtonWrapper onClick={onClickAddScheduleBtn}>
                                <PlusOutlined />
                                <AddButton>추가</AddButton>
                            </AddButtonWrapper>
                        </ScheduleInfoWrapper>
                    </div>

                    <div id="schadule-lists">
                        <div style={{ display: 'flex', alignContent: 'center', fontSize: '13px', fontWeight: 'bold', gap: '71px' }}>
                            <div>일정</div>
                            <div>
                                <span>시간</span>
                            </div>
                            <div>
                                <span>모집인원</span>명
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100px',
                                overflow: 'auto',
                                marginBottom: '20px',
                                border: '1px solid rgba(66, 139, 247, 0.2)',
                            }}
                        >
                            {Array.isArray(scheduleParams) ? (
                                scheduleParams.map((list, index) => {
                                    return (
                                        <div key={index} style={{ display: 'flex', alignContent: 'center', fontSize: '13px', gap: '25px' }}>
                                            <div>{list.date_time}</div>
                                            <div>
                                                <span>{`${list.start_time}`}</span>~<span>{`${list.end_time}`}</span>
                                            </div>
                                            <div>
                                                <span>{list.max_participants}</span>명
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div style={{ display: 'flex', alignContent: 'center', fontSize: '13px', gap: '25px' }}>
                                    <div>{scheduleParams.date_time}</div>
                                    <div>
                                        <span>{`${scheduleParams.start_time}`}</span>~<span>{`${scheduleParams.end_time}`}</span>
                                    </div>
                                    <div>
                                        <span>{scheduleParams.max_participants}</span>명
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <ButtonWeapper>
                        <button className="cencle" onClick={closeModal}>
                            취소
                        </button>
                        <button className="save" onClick={onClickAddActivity}>
                            저장
                        </button>
                    </ButtonWeapper>
                </DetailInfoWrapper>
            </DetailModalBox>
        </>
    );
}

export default AddActivityModal;
