import mainRequest from '../request';

const url = '/v2/gatheringAccount';

export const getGatheringAccountListRequest = async (params) => {
    try {
        const response = await mainRequest.post(`${url}/list`, params);
        return response?.data;
    } catch (err) {
        return {
            ok: false,
            status: err.response.status || 500,
            message: err.response.data.message || '서버 에러',
        };
    }
};
export const getGatheringAccountHistoryListRequest = async (params) => {
    try {
        const response = await mainRequest.post(`${url}/history/list`, params);

        return response?.data;
    } catch (err) {
        return {
            ok: false,
            status: err.response.status || 500,
            message: err.response.data.message || '서버 에러',
        };
    }
};
