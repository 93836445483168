import styled from 'styled-components';

export const TopBtnWrapperReportWrapper = styled.div`
    #label {
        display: none;
    }
`;

export const TopBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;

export const MainRightSideTitle = styled.div`
    font-size: 26px;
    font-family: noto_sans_kr_bold;
    color: rgb(0 0 0);

    font-weight: ${(props) => (props.point ? '600' : '')};
    text-decoration: ${(props) => (props.point ? 'underline' : '')};

    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;

    span {
        cursor: pointer;
    }
`;
