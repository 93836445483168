import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { requestGetQuestionLists } from '../../data_system/redux/modules/survey.reducer/survey.reducer';

// components
import LoadingPage from '../../pages/loading/LoadingPage';

// 설문관리 상세 > 질문확인
function QuestionComponent() {
    const dispatch = useDispatch();

    // useParams
    const { survey_id } = useParams();

    // useSelector
    const { questionLists, getQuestionLoading } = useSelector((state) => state.getSurveyReducer);

    /******************************** useEffect ********************************/

    // 데이터 요청
    useEffect(() => {
        dispatch(requestGetQuestionLists(survey_id));
    }, [dispatch, survey_id]);

    /********************************** html **********************************/
    return (
        <div>
            {questionLists.length === 0 || getQuestionLoading ? (
                <LoadingPage text={'질문에 대한 정보를 불러오는 중입니다.'} />
            ) : (
                <div>
                    {questionLists?.map((data, index) => {
                        return (
                            <QuestionBox key={index}>
                                <Question>{data.question}</Question>
                                <AnswerWrapper>
                                    <CheckBox type="checkbox" disabled />
                                    <div>매우 그렇다</div>
                                </AnswerWrapper>
                                <AnswerWrapper>
                                    <CheckBox type="checkbox" disabled />
                                    <div>그렇다</div>
                                </AnswerWrapper>
                                <AnswerWrapper>
                                    <CheckBox type="checkbox" disabled />
                                    <div>보통이다</div>
                                </AnswerWrapper>
                                <AnswerWrapper>
                                    <CheckBox type="checkbox" disabled />
                                    <div>그렇지 않다</div>
                                </AnswerWrapper>
                                <AnswerWrapper>
                                    <CheckBox type="checkbox" disabled />
                                    <div>매우 그렇지 않다</div>
                                </AnswerWrapper>
                            </QuestionBox>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default QuestionComponent;

/********************************** styled **********************************/

export const QuestionBox = styled.div`
    background-color: rgba(66, 139, 247, 0.1);

    margin: 10px 0;
    padding: 20px;

    border-radius: 10px;
`;

export const Question = styled.div`
    font-size: 15px;
    font-weight: 600;

    margin-bottom: 20px;
`;

export const AnswerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    font-size: 14px;
    margin-bottom: 10px;
`;

export const CheckBox = styled.input`
    cursor: pointer;
    margin: 0;

    width: 17px;
    height: 17px;
`;
