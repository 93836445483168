import SMSTypeTab from '../../GroupComponent/Tab/SMSTypeTab';
import { InputTitle } from '../../designComponents/inputs/InputLine';
import { TextArea } from '../../designComponents/inputs/TextArea';
import ContentText from '../../designComponents/text/ContentText';
import React, { useEffect, useState } from 'react';
import CheckboxInput from '../../designComponents/checkboxs/CheckboxInput';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import SMSFileUpload from './SMSFileUpload';
import { CloseOutlined } from '@ant-design/icons';
const SendSMS = ({ sendPhoneList, setSendPhoneList }) => {
    const [smsType, setSmsType] = useState(1);

    const [smsTitle, setSmsTitle] = useState('');
    const [smsMessage, setSmsMessage] = useState('');
    const [fileList, setFileList] = useState([]);

    let maxLength = 80;
    if (Number(smsType) !== 1) {
        maxLength = 2000;
    }

    const onClickSendSMS = async () => {
        try {
            const data = {
                smsType: smsType,
                phone_no_list: sendPhoneList,
                message_title: smsTitle,
                message_content: smsMessage,
                fileList: fileList,
            };

            if (!sendPhoneList.length) return alert('선택된 회원이 없습니다.');
            if (!smsTitle.length && smsType !== 1) return alert('SMS 제목을 입력하세요.');
            if (!smsMessage.length) return alert('내용을 입력하세요.');

            if (smsMessage.length >= maxLength) return alert(`문자 길이가 ${maxLength}자를 초과할 수 없습니다.`);

            if (window.confirm('문자를 전송하시겠습니까?')) {
                const response = await RefreshCheckApi.post('/adminSms', data);
                if (response.status !== 200) alert('[서버 관리자 문의]메세지 전송하는 과정에서 Error가 발생했습니다.');
                if (response.status === 200 && response.data.ok) alert(`${sendPhoneList.length}명의 회원에게 메세지 발송을 성공했습니다.`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onChangeSelectAll = async (e) => {
        const checked = e.target.checked;
        if (checked) {
            const response = await RefreshCheckApi.post('/adminSms/list', {
                limit: 0,
            });
            setSendPhoneList(response.data.data.body.map((item) => item.phone_no));
        } else {
            setSendPhoneList([]);
        }
    };

    let placeholderTitle = '메시지 제목';

    let holderText = '메시지 내용(80자 이내)';
    if (Number(smsType) === 0) {
        holderText = '메시지 내용(100자이내)';
    }

    const resetMessage = async () => {
        setSmsType(1);
        setSmsTitle('');
        setFileList([]);
        setSendPhoneList([]);
        setSmsMessage('');
    };
    useEffect(() => {
        console.log(sendPhoneList);
    }, [sendPhoneList]);
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'space-between', width: '100%' }}>
                <SMSTypeTab type={smsType} setType={setSmsType}></SMSTypeTab>
                <TopSideButton leftStyle={{ marginLeft: '100px', marginTop: '10px' }} middleOn={false} rightOn={false} text01={'초기화'} onClick={resetMessage} />
            </div>

            <InputTitle inputStyle={{ width: '100%', padding: 0, margin: 0 }} exposure={false} placeholder={placeholderTitle} onChange={(e) => setSmsTitle(e.target.value)} />
            <TextArea value={smsMessage} style={{ width: '100%' }} onChange={(e) => setSmsMessage(e.target.value)} rows={10} placeholder={holderText} />

            {Number(smsType) === 3 ? <SMSFileUpload setFileList={setFileList}></SMSFileUpload> : <></>}
            <ContentText
                text={smsMessage.length >= maxLength ? <span style={{ color: 'red' }}>{maxLength}자가 초과하면 안됩니다.</span> : `${smsMessage.length}자 / ${maxLength}자`}
                style={{ marginBottom: '3%' }}
            />

            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <CheckboxInput text={'SMS 전체 회원 전송'} exposure style={{ width: '17px', height: '17px', margin: '0 5px 0 0' }} fontStyle={{ fontSize: '14px' }} onChange={onChangeSelectAll} />

                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <UserCountNumber count={sendPhoneList.length} />
                    <TopSideButton middleOn={false} rightOn={false} text01={'발송'} onClick={onClickSendSMS} />
                </FlexMainWrapper>
            </FlexMainWrapper>
            <FlexMainWrapper style={{ flexWrap: 'wrap', gap: 8 }}>
                {sendPhoneList.map((e) => (
                    <SelectedPhoneNo
                        key={`send_sms_${e}`}
                        phoneNo={e}
                        onCancel={() => {
                            setSendPhoneList((pre) => pre.filter((f) => f !== e));
                        }}
                    />
                ))}
            </FlexMainWrapper>
        </>
    );
};

export default SendSMS;

export const UserCountNumber = ({ count }) => {
    const countSt = { fontSize: '15px', fontWeight: 600, color: 'rgb(66,139,247)', marginLeft: '5px' };
    return (
        <span style={{ fontSize: '14px', marginRight: '10px' }}>
            <span>선택된 회원</span>
            <span style={{ ...countSt }}>{count}</span>
            <span>명</span>
        </span>
    );
};
const SelectedPhoneNo = ({ phoneNo, onCancel }) => {
    return (
        <span style={{ backgroundColor: 'rgba(112,115,124,0.22)', paddingInline: 6, borderRadius: 12 }}>
            <span>{phoneNo}</span>
            <CloseOutlined onClick={onCancel} style={{ marginLeft: 4 }} />
        </span>
    );
};
