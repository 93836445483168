import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

// header from this line
export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;

    background-color: rgb(0 0 0 / 35%);
    align-items: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // 라벨 표시 제거(태그의 역할을 파악하기 위함)
    #label {
        display: none;
    }

    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

export const CustomCloseOutlined = styled(CloseOutlined)`
    position: fixed;
    top: 0;
    right: 0;

    padding: 17px;

    color: #fff;
    font-size: 32px;

    @media (max-width: 1300px) {
        display: none;
    }
`;

export const ModalBoxWrapper = styled.div`
    width: 120vh;
    max-width: 98%;

    background-color: #fff;
    border-radius: 13px;

    @media (max-width: 912px) {
        width: 100%;
        margin: auto;
    }
`;

export const ModalBox = styled.div`
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;

    width: 100%;
    height: 76vh;

    @media (max-width: 912px) {
        width: 100%;
    }
`;

export const Header = styled.div`
    z-index: 999;
    display: flex;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    width: 100%;
    height: 5vh;

    align-items: center;

    @media (max-width: 1120px) {
        width: 100%;
    }
`;

export const Text = styled.div`
    padding: 0 15px;

    color: #fff;
    font-size: 16px;
    font-weight: 600;

    line-height: 45px;
`;

// body from this line
export const Body = styled.div`
    overflow: auto;
    flex: auto;

    height: 76vh;
    padding: 0px 3%;
`;

export const PaymentBody = styled.div`
    height: 85%;
    overflow: auto;

    background-color: #fff;

    @media (max-width: 912px) {
        overflow: auto;
        flex: auto;
    }
`;

export const UserNameBox = styled.div`
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;

    width: 118.3vh;
    height: 45px;

    @media (max-width: 912px) {
        display: block;
        width: 90px;
    }
`;

export const MptyBox = styled.div`
    width: 335px;

    @media (max-width: 912px) {
        display: none;
    }
`;

export const Line = styled.div`
    border-right: 1px solid rgb(210, 210, 210);
    height: 97vh;
    margin: 0px 22px;

    @media (max-width: 912px) {
        border-top: 1px solid rgb(210, 210, 210);
        height: 1px;
        width: 100%;
        margin: 20px 0px 7px 0;
    }
`;

export const PaymentLine = styled.div`
    border-right: 1px solid rgb(210, 210, 210);
    height: 71vh;
    margin: 0px 22px;

    @media (max-width: 912px) {
        display: none;
    }
`;

export const UserName = styled.div`
    border: 1px solid rgba(66, 139, 247, 0.2);

    margin-left: 10px;
    width: 977px;

    font-family: noto_sans_kr_medium;
    font-size: 16px;
    font-weight: 600;

    text-align: start;

    line-height: 3.2vh;

    width: 120px;

    padding: 0px 5px;
    margin-top: 15px;

    @media (max-width: 912px) {
        padding: 0 5px;

        width: 115px;
    }
`;

// 회원 정보 박스 영역
export const InfoBoxWrapper = styled.div`
    position: relative;

    display: flex;
    justify-content: center;

    width: 100%;
    height: 81vh;

    @media (max-width: 912px) {
        flex-direction: column;
        width: 41.5vh; // width: 100%;
        justify-content: flex-start;
    }
`;

// 구독 결제
export const PaymentInfoBoxWrapper = styled.div`
    position: relative;

    display: flex;
    justify-content: start;
    gap: 5%;

    margin-left: 5%;

    // width: 118.3vh; // width: 500px;
    height: 100%; // height: 71vh;

    @media (max-width: 1120px) {
        width: 110.3vh;
    }

    @media (max-width: 912px) {
        flex-direction: column;
        width: 41.5vh; // width: 100%;
        justify-content: flex-start;
    }

    /* @media (max-height: 912px) {
        flex-direction: column;
        width: 41.5vh; // width: 100%;
        justify-content: flex-start;

        margin-left: 5%;
    } */
`;

export const InfoBox = styled.div`
    text-align: start; /* width: 53vh; */
    width: 100%;

    @media (max-width: 912px) {
        /* width: 435px; */
    }
`;

export const TitleLine = styled.div`
    margin: 17px 0;
    font-weight: 600;
`;

// BODY 하위 내용

export const SubTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
`;

export const SubTitle = styled.div`
    margin-top: 10px;

    color: rgb(121, 121, 121);
    font-size: 14px;

    width: 113px; // 95px;

    @media (max-width: 912px) {
        width: 95px;
    }
`;

export const More = styled.div`
    cursor: pointer;
    margin-top: 10px;

    color: #929292;
    font-size: 13px;

    :hover {
        font-weight: bold;
    }
`;

export const UpdateSubTitle = styled.div`
    cursor: pointer;
    margin-top: 10px;
    width: 110px;

    color: rgb(121, 121, 121);
    text-decoration: underline;
    font-size: 13px;

    margin: 5px 0;

    :hover {
        color: black;
        font-weight: 600;
    }

    @media (max-width: 912px) {
        width: 95px;
    }
`;

// 인증 미인증 인증대기 Wrapper
export const ResultWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 912px) {
        display: block;
    }
`;

// 프로필 사진 설정박스
export const PhotoBox = styled.div`
    position: relative;
    /* display: inline; */
    width: 150px;
`;

// 회원 이미지 박스
export const UserImgBox = styled.div`
    width: 460px;
    height: 27vh;

    @media (max-width: 1120px) {
        width: 400px;
        height: 30vh;
    }

    @media (max-width: 735px) {
        width: 380px;
        height: 30vh;
    }

    @media (max-width: 465px) {
        width: 300px;
        height: 40vh;
    }
`;

// 이미지 셋업 버튼 박스
export const ImgeSetupBtn = styled.div`
    position: absolute;

    z-index: 999;

    display: flex;
    justify-content: space-between;

    margin: 5px;
    width: 100px;

    // 기본 이미지로 변경
    .setup {
        cursor: pointer;
        background-color: #00000091;
        width: 63px;
        color: #fff;

        text-align: center;

        border-radius: 10px;

        font-size: 11px;
        line-height: 19px;

        :hover {
            background-color: black;
        }
    }

    // 삭제
    .delete {
        cursor: pointer;
        background-color: #00000091;
        font-size: 13px;
        color: #fff;

        padding: 3px;
        border-radius: 50%;

        :hover {
            background-color: black;
        }
    }
`;

export const ImgCloseOutlined = styled(CloseOutlined)`
    display: block;
`;

export const Image = styled.img`
    display: inline;
    width: 150px;
    height: 150px;
    object-fit: cover;

    margin: 0;
`;

// SubTitle 하위 내용에 대한 flex wrapper
export const SubInfoWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: baseline;

    font-size: 13px;
    margin: 5px 0;

    @media (max-width: 912px) {
        width: 310px;
    }
`;

export const PaymentSubInfoWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: baseline;

    /* width: 500px; */
    font-size: 13px;

    margin: 15px 0;

    @media (max-width: 912px) {
        width: 310px;
    }

    table {
        width: 100%;

        border-collapse: collapse;
    }

    .header {
        background-color: rgb(66 139 247);
        font-weight: 500;
        color: #fff;
    }

    td {
        width: 100px;
        border: 1px solid rgb(184 184 184);
        padding: 2px 4px;
    }
`;

export const TextInSubInfoWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: baseline;

    width: 100%;
    font-size: 13px;

    margin: 5px 0;

    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

export const CheckLine = styled.div`
    display: flex;
    align-items: center;

    margin-top: 10px;

    .result {
        width: 70px;
    }

    input {
        cursor: pointer;
    }

    @media (max-width: 390px) {
        margin-top: 0px;
    }
`;

// modal 하단 버튼 취소/저장
export const BottomButtonWrapper = styled.div`
    display: flex;
    justify-content: end;

    background-color: #fff;

    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;

    padding: 10px 10px;
    text-align: end;

    .cancel {
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(229, 229, 229);

        :hover {
            background-color: rgb(225 220 220);
        }
    }
    .save {
        background-color: rgb(255, 16, 125);
        border: 1px solid rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(226 0 103);
        }
    }

    @media (max-width: 1120px) {
        width: 100%;
        justify-content: center;
        padding: 5px 0;
    }
`;

export const Button = styled.button`
    cursor: pointer;
    width: 150px;
    height: 35px;

    border: none;

    margin: 6px 4px;

    &:hover {
        background-color: gray;
    }

    @media (max-width: 1120px) {
        height: 30px;
        font-size: 13px;
    }
`;

// 정보란
export const InfoText = styled.div`
    border: 1px solid rgba(66, 139, 247, 0.2);

    font-size: 13px;

    margin-top: 10px;
    padding: 2px 3px;

    width: 134px;
    height: 20px;
    line-height: 21px;
`;

export const InfoText02 = styled.div`
    font-weight: 600;
    font-size: 13px;
    margin-top: 10px;
`;

export const UnitSpan = styled.span`
    color: #797979;
    margin: 0 7px;
`;

export const NoneText = styled.span`
    color: #797979;
`;

// 달력
export const TextInput = styled.input`
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;

    width: 160px;
    height: 27px;

    border: 1px solid rgba(66, 139, 247, 0.2);

    font-size: 13px;
    color: rgb(0, 0, 0);
    font-family: noto_sans_kr_medium;

    box-sizing: border-box;
    margin: 0px;

    &:focus {
        outline: none;
    }
    &:disable {
        background-color: rgb(255, 255, 255);
    }

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
`;

// 추가정보
export const RowBottomLine = styled.div`
    width: 100%;

    padding-bottom: 20px;
    border-bottom: 1px solid rgb(210, 210, 210);

    @media (max-width: 912px) {
        border-bottom: none;

        .more-title-line {
            width: 100%;
            padding-top: 20px;

            border-bottom: none;
        }
    }
`;

// textarea
export const MemoInput = styled.textarea`
    border: 1px solid rgba(66, 139, 247, 0.2);
    width: 83%;
    height: 130px;

    font-size: 11px;
    font-weight: 500;
    color: rgb(35, 40, 35);

    padding: 5px;

    &:focus {
        outline: none;
    }

    @media (max-width: 912px) {
        margin-top: 7px;
        width: 333px;
        max-width: 98%;
    }
`;

// drag and drop
export const DemOuter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;

    width: 100%;

    .demo2 {
        width: 457px;
        height: 310px;

        @media (max-width: 360px) {
            width: 300px;
        }
    }

    .demo2-ball {
        position: absolute;
    }
`;

export const DragImage = styled.img`
    display: inline;

    user-select: none;
    -webkit-user-drag: none;

    width: 110px;
    height: 110px;

    object-fit: cover;
`;

export const VirtualAccountBtn = styled.button`
    cursor: pointer;

    width: 85px;
    height: 23px;

    border: 0;
    border-radius: 2px;

    background-color: #428bf7;
    color: #fff;

    &:hover {
        background-color: #1067e7;
    }
`;

export const UserAuthImgWrapper = styled.div`
    cursor: pointer;
    position: relative;

    width: 150px;
    height: 150px;

    .preview {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        background-color: #00000099;
        line-height: 150px;

        color: #fff;
        text-align: center;
    }

    :hover {
        .preview {
            display: block;
        }
    }
`;
