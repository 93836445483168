import axios from "axios";
import { main_server_ip } from "../../communication_system/communication_ips";
import { refresh_check, refresh_error_handle } from "../../communication_system/axios_apis/refresh_check/refresh_check";

const mainRequest = axios.create({
	baseURL: main_server_ip,
	timeout: 50000,
	params: {}
})


mainRequest.interceptors.request.use(refresh_check, refresh_error_handle)

mainRequest.interceptors.response.use(
	(response) => response,
)

export default mainRequest