import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

// 모임생성
export const ImageWrapper = styled.div`
    margin-left: 28%;
    margin-bottom: 10%;
    /* width: 270px; */
`;

export const ImageBox = styled.div`
    display: flexbox;
    gap: 3px;
`;

export const ImageComponent = styled.div`
    position: relative;
    display: inline-block;

    width: 65px;
    height: 65px;
`;

export const CustomCloseOutlined = styled(CloseOutlined)`
    cursor: pointer;
    position: absolute;
    top: 1px;
    right: 3px;

    background-color: #0000009c;
    color: #fff;

    padding: 1px;

    :hover {
        background-color: black;
    }
`;

export const MainPic = styled.div`
    position: absolute;
    bottom: 5px;
    left: 3px;

    width: 90%;
    text-align: center;

    background-color: #0000009c;
    color: #fff;

    font-size: 12px;
`;

export const Image = styled.img`
    cursor: pointer;
    border: 1px solid #e7e7e7;

    margin: 0 2px;

    width: 59px;
    height: 59px;

    object-fit: cover;
`;

export const MobileBox = styled.div`
    @media (max-width: 912px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100vh;

        background-color: #000000ab;
    }
`;

export const CreateBox = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;

    height: 55vh;
    width: 400px;
    max-width: 98%;

    overflow: auto;

    @media (max-width: 912px) {
        position: fixed;
        top: 4%;
        left: 0;
        right: 0;

        margin: auto;

        width: 97%;
        height: 90%;
    }
`;

export const CreateTopWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    background-color: ${(props) => (props.updateToggle ? 'rgb(255,16,125)' : '#428bf7')};
    padding: 0 10px;

    width: 95%;
    height: 44px;

    font-size: 17px;
    font-weight: bold;
    color: #fff;

    .close-button {
        cursor: pointer;

        font-size: 20px;
        line-height: 17px;
    }

    @media (max-width: 912px) {
        width: 100%;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    margin: 10px 30px;
`;

export const InputWrapper02 = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;

    margin: 10px 30px;
`;

// CAMERA_A하기 wrapper
export const InputWrapper03 = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid #428bf742;

    padding: 6px 5px;
    width: 110px;

    font-size: 14px;
`;

export const CheckMainAddressBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    margin-left: 28%;

    span {
        font-size: 13px;
    }
`;

export const SubTitle = styled.div`
    font-size: 13px;
    color: #797979;

    width: 100px;
`;

export const Textarea = styled.textarea`
    outline: none;

    padding: 5px;

    width: 84%;
    height: 88px;

    border: 1px solid #428bf742;
`;

export const Input = styled.input`
    outline: none;

    padding: 2px 5px;

    width: 95%;
    height: 25px;

    border: 1px solid #428bf742;
`;

export const SearchBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const Button = styled.button`
    cursor: pointer;

    width: 90px;
    height: 31px;

    border: ${(props) => (props.updateToggle ? '1px solid rgb(255,16,125)' : '1px solid #428bf7')};

    color: #fff;
    background-color: ${(props) => (props.updateToggle ? 'rgb(255,16,125)' : '#428bf7')};
`;

export const CheckBox = styled.input`
    cursor: pointer;

    width: 14px;
    height: 14px;

    overflow: auto;

    border: 1px solid #428bf7;
`;

export const CheckBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    margin-right: 25px;
`;

export const ButtonWeapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    margin-bottom: 10px;

    button {
        cursor: pointer;
        border: 0;
        background-color: #fff;

        width: 120px;
        height: 35px;
    }

    .cencle {
        border: 1px solid rgb(229, 229, 229);
        color: black;

        :hover {
            background-color: rgb(242 242 242);
        }
    }

    .update {
        border: 1px solid rgb(255, 16, 125);
        background-color: rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(198 3 92);
        }
    }

    .save {
        border: 1px solid #428bf7;
        background-color: #428bf7;
        color: #fff;

        :hover {
            background-color: #1d6ee6;
        }
    }
`;

/************ AddGatheringChooseInterestModal ************/

export const ModalWindow = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #0000009c;

    width: 100%;
    height: 100vh;
`;

export const ChooseIntersetBox = styled.div`
    background-color: #fff;
    border-radius: 20px;

    padding: 20px 30px;

    width: 430px;
    height: 65vh;

    overflow: auto;
`;

export const TitleLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-btn {
        cursor: pointer;
        font-size: 17px;
        font-weight: bold;
        color: #797979;
    }
`;

export const Title = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #428bf7;

    margin-bottom: 5px;
`;

export const KategoryWrapper = styled.div`
    display: flexbox;
    align-items: center;
    justify-content: start;
    gap: 7px;

    width: 420px;
`;

export const KategorySubTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin: 15px 0;
`;

export const Item = styled.div`
    cursor: pointer;
    display: inline-block;

    font-size: 13px;
    text-align: center;

    padding: 5px 13px;
    margin: 2px 2px;

    width: auto;

    background-color: #fff;
    border: ${(props) => (props.checked ? `1px solid #428bf7` : `1px solid #428bf73d`)};
    color: ${(props) => (props.checked ? '#428bf7' : '')};

    :hover {
        border: 1px solid #428bf7;
        color: #428bf7;
    }
`;

export const SaveButtonWrapper = styled.div`
    text-align: center;

    margin-top: 30px;
`;

export const SaveButton = styled.button`
    cursor: pointer;
    background-color: #428bf7;
    border: 0;

    color: #fff;

    width: 70px;
    height: 30px;

    :hover {
        background-color: #226bd8;
    }
`;

export const KategoryItem = styled.div`
    display: inline-block;

    background-color: #f4f4f4;
    border-radius: 10px;

    width: auto;

    font-size: 14px;
    text-align: center;

    margin: 2px 2px;
    padding: 5px 7px;
`;

export const KategoryContentsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
`;

export const CloseWindow = styled.div`
    display: flex;
    justify-content: end;

    height: 33px;
    background-color: #c4c4c4;
`;

export const SearchAddressBox = styled.div`
    // TODO
`;

export const CustomAddressCloseOutlined = styled(CloseOutlined)`
    cursor: pointer;
    font-size: 25px;
    padding: 6px;
`;

export const Select = styled.select`
    cursor: pointer;
    outline: none;

    width: 100px;
    height: 27px;

    border: 1px solid #428bf742;
`;

export const ImgWrapper = styled.div`
    cursor: pointer;
    position: relative;

    .preview {
        display: none;
        position: absolute;
        top: 1px;
        left: 0;
        right: 0;

        background-color: #00000099;
        padding: 31% 1px;

        color: #fff;
        text-align: center;
    }

    :hover {
        .preview {
            display: block;
        }
    }
`;
