import axios from 'axios';
import moment from 'moment';
import { main_server_ip } from '../../communication_ips';
import { auth_adminRefresh_router } from '../../communication_router_urls';

const refresh_check = async (config) => {
    const refresh_token = localStorage.getItem('refresh_token');
    const expire_at = localStorage.getItem('expires_at');
    let access_token = localStorage.getItem('access_token');

    // 토큰이 만료되었고, refreshToken 이 저장되어 있을 때
    if (moment(expire_at).diff(moment()) < 0 && refresh_token) {
        // 토큰 갱신 서버통신
        axios.defaults.headers['Authorization'] = `Bearer ${refresh_token}`;
        try {
            const get = await axios.get(`${main_server_ip + auth_adminRefresh_router}`);
            if (get.status === 200) {
                access_token = get.data.access_token;
                localStorage.setItem('access_token', get.data.access_token);
                localStorage.setItem('expires_at', moment().add(1, 'hour').format('yyyy-MM-DD HH:mm:ss'));
            }
        } catch (e) {
            if (e.response) {
                if (e.response.status === 401 && window.location.pathname !== '/login') {
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('expires_at');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('user');
                    window.location = '/login';
                }
            }
        }
    }
    config.headers['Authorization'] = `Bearer ${access_token}`;
    return config;
};

const refresh_error_handle = (err) => {
    localStorage.setItem('refresh_token', '');
};

export { refresh_check, refresh_error_handle };
