export const GET_STATISTICS = 'admin/GET_STATISTICS';
export const GET_STATISTICS_SUCCESS = GET_STATISTICS+'_SUCCESS';
export const GET_STATISTICS_FAILURE = GET_STATISTICS+'_FAILURE';
export const GET_STATISTICS_ACCUMULATE_DATE = GET_STATISTICS+'_ACCUMULATE_DATE';
export const GET_STATISTICS_ACCUMULATE_DATE_SUCCESS = GET_STATISTICS_ACCUMULATE_DATE+'_SUCCESS';
export const GET_STATISTICS_ACCUMULATE_DATE_FAILURE = GET_STATISTICS_ACCUMULATE_DATE+'_FAILURE';
export const GET_STATISTICS_ACCUMULATE_TODAY = GET_STATISTICS+'_ACCUMULATE_TODAY';
export const GET_STATISTICS_ACCUMULATE_TODAY_SUCCESS = GET_STATISTICS_ACCUMULATE_TODAY+'_SUCCESS';
export const GET_STATISTICS_ACCUMULATE_TODAY_FAILURE = GET_STATISTICS_ACCUMULATE_TODAY+'_FAILURE';
export const GET_STATISTICS_ACCOUNT_RATE = GET_STATISTICS+'_ACCOUNT_RATE';
export const GET_STATISTICS_ACCOUNT_RATE_SUCCESS=GET_STATISTICS_ACCOUNT_RATE+'_SUCCESS';
export const GET_STATISTICS_ACCOUNT_RATE_FAILURE = GET_STATISTICS_ACCOUNT_RATE+'_FAILURE';
export const GET_STATISTICS_ACCOUNT_LINE = GET_STATISTICS+'_ACCOUNT_LINE';
export const GET_STATISTICS_ACCOUNT_LINE_SUCCESS= GET_STATISTICS_ACCOUNT_LINE+'_SUCCESS';
export const GET_STATISTICS_ACCOUNT_LINE_FAILURE = GET_STATISTICS_ACCOUNT_LINE+'_FAILURE';
export const GET_STATISTICS_ACCOUNT_TABLE = GET_STATISTICS+'_ACCOUNT_TABLE';
export const GET_STATISTICS_ACCOUNT_TABLE_SUCCESS = GET_STATISTICS_ACCOUNT_TABLE+'_SUCCESS';
export const GET_STATISTICS_ACCOUNT_TABLE_FAILURE = GET_STATISTICS_ACCOUNT_TABLE+'_FAILURE';
export const GET_STATISTICS_PAYMENT_TABLE = GET_STATISTICS+'_PAYMENT_TABLE';
export const GET_STATISTICS_PAYMENT_TABLE_SUCCESS = GET_STATISTICS_PAYMENT_TABLE+'_SUCCESS';
export const GET_STATISTICS_PAYMENT_TABLE_FAILURE = GET_STATISTICS_PAYMENT_TABLE+'_FAILURE';
export const GET_STATISTICS_ADDITIONAL = GET_STATISTICS+'_ADDITIONAL';
export const GET_STATISTICS_ADDITIONAL_SUCCESS = GET_STATISTICS_ADDITIONAL+'_SUCCESS';


