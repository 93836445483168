import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { gatheringAccountUseListsExcel } from '../../data_system/xlsx_util/xlsx_util';
import { adminUsers } from '../../dataSystems/AdminUserLists';

import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { ExcelButton } from '../../designComponents/buttons/Buttons';

import { userAccountStatusFilter } from '../../components/MockData/UserAccountStatusFilter';
import AccountModal from '../../components/GatheringComponents/ModalComponents/AccountModal';
import ContentText from '../../designComponents/text/ContentText';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { accountFilter } from '../../utils/textFilter';

/** 계좌 상세 사용 내역 */
const GatheringAccountInfoDetail = () => {
    const params = useParams();
    const navigator = useNavigate();
    const [param, setParam] = useState({});

    const [allCharge, setAllCharge] = useState(0);
    const [gatheringAccountHistoryID, setGatheringAccountHistoryID] = useState(0);
    const [detailToggle, setDetailToggle] = useState(false);

    const ref = useRef(null);
    const tableRef = useRef(null);

    // Excel 출력 함수
    const onPressPrintExcel = async () => {
        let input = {
            page: ref?.current?.page,
            size: 10,
            searchType: 9,
            searchWord: params.gathering_id,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminGathering/account/status`, { params: { ...input, excelYN: true } });
        const headers = [
            { key: 'owner_user_name', value: '모임장명' },
            { key: 'account_bank', value: '은행' },
            { key: 'account_number', value: '계좌번호' },
            { key: 'account_holder', value: '예금주' },
            { key: 'license_number', value: '주민번호' },
            { key: 'business_name', value: '사업자' },
            { key: 'business_number', value: '사업자번호' },
        ];
        gatheringAccountUseListsExcel(headers, response.data.data, '.xlsx');
    };

    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: 9,
            searchWord: params.gathering_id,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminGathering/account/status`, { params: input });
        setAllCharge(response.data.all_charge);
        return [...response.data.data];
    };

    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: 9,
            searchWord: params.gathering_id,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminGathering/account/status/allCount`, { params: input });
        return response.data.all_count;
    };

    return (
        <Wrapper02 id="account-using-recode">
            {detailToggle && <AccountModal id={gatheringAccountHistoryID} setDetailToggle={setDetailToggle} />}
            <MainPageTitle text={'계좌 상세 사용내역'} />
            <SearchbarWithDropdown
                list={[{ id: 9, title: '모임ID', value: 9 }]}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={params.gathering_id}
            />
            <MainTable
                buttonComponents={() => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <FlexMainWrapper style={{ width: 'auto' }}>
                            <ContentText text={'총 수익(수수료):'} style={{ fontSize: '14px', fontWeight: 600 }} />
                            <ContentText text={`${parseInt(allCharge).toLocaleString('ko-KR')}원`} style={{ fontSize: '14px' }} />
                            <ContentText text={'※ 모임장에게 직접 전달한 현장결제는 출금 후 잔액에 포함되지 않습니다.'} style={{ fontSize: '13px', color: 'gray', marginLeft: '10px' }} />
                        </FlexMainWrapper>
                        <FlexMainWrapper>
                            <TopSideButton rightOn={false} leftOn={false} middleWidth={80} text03={'목록보기'} onClickMiddle={() => navigator(`/gathering/account`)} />
                            <ExcelButton text="Excel" onClick={onPressPrintExcel} style={{ marginBottom: '5px' }} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                )}
                tableStyle={{ width: '98%' }}
                inTableStyle={{ fontSize: '11.5px' }}
                ref={ref}
                tableRef={tableRef}
                loadingText={'계좌 사용 내역 리스트를 불러오고 있습니다.'}
                headerData={userAccountStatusFilter}
                checkID={'gathering_account_history_id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    setGatheringAccountHistoryID(item.gathering_account_history_id);
                    setDetailToggle(true);
                }}
                adminCheck={(item) => adminUsers.includes(item.user_phone_no) && { color: 'rgb(255, 16, 125)' }}
                tbodyRenderItem={(key, value, item) => {
                    const noneInfo = <span style={{ color: '#797979' }}>정보없음</span>;
                    const owner = item.pay_out_status > 0;
                    const mainColor = { color: 'rgb(66,139,247)' };
                    const paySite = <span style={{ color: 'red' }}>현장결제</span>;
                    const passCostIn = `${item.pass_cost_in.toLocaleString('ko-KR')}`;
                    const costPayOut = <span style={mainColor}>{`${parseInt(value - item.charge).toLocaleString('ko-KR')}원`}</span>;
                    if (key === 'pay_type') {
                        return value === 5 ? (
                            <FlexMainWrapper style={{ flexDirection: 'column', gap: '1px' }}>
                                <div>{paySite}</div>
                                <div>{passCostIn}원</div>
                            </FlexMainWrapper>
                        ) : (
                            <span>{item.cost_in ? item.cost_in.toLocaleString('ko-KR') : 0}원</span>
                        );
                    }
                    if (key === 'gathering_name') return <div style={{ width: '90px' }}>{value}</div>;
                    if (key === 'user_name') return value ? owner ? <span style={mainColor}>{value} (모임장)</span> : value : noneInfo;
                    if (key === 'start_date') return value ? `${value} ${item.start_time}` : noneInfo;
                    if (key === 'gathering_title') return value ? <div style={{ width: '90px' }}>{value}</div> : noneInfo;
                    if (key === 'charge') {
                        return item.status === 3 ? `${parseInt(value).toLocaleString('ko-KR')}원` : '-';
                    }
                    if (key === 'cost_pay_out') return value ? costPayOut : '-';
                    if (key === 'total') return item.pay_out_status === 2 || item.pay_out_status === 1 ? value : '-';
                    if (key === 'cost_out') return value ? `${value?.toLocaleString('ko-KR')}원` : '-';
                    if (key === 'pay_out_status') return accountFilter(value);
                    else return value;
                }}
            />
        </Wrapper02>
    );
};

export default GatheringAccountInfoDetail;
