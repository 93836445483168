/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

// styled-components
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchGetClickPercentage } from '../../../data_system/redux/modules/clickPercentage/clickPercentage_reducer';

// statistics
import { TypeInput } from '../../../pages/statistics/StatisticsPage';

// design_system
import { TextInputNoLine } from '../../../design_system/components/input/Inputs';
import ContentText from '../../../design_system/components/content_text/ContentText';

// Print_Excel
import { arrayToExcel } from '../../../data_system/xlsx_util/xlsx_util';
import { headers } from '../../../components/ClickPercentComponents/Excel/headerForExcel';

// components
import Pagination from '../../../design_system/components/admin_table/pagination/Pagination';
import ExcelBtn from '../../../components/ClickPercentComponents/Excel/ExcelBtn';
import { moneyFilter } from '../../../utils/textFilter';

// 클릭률 테이블
const ClickPercentageTable = () => {
    const dispatch = useDispatch();
    const { clickPercentageData } = useSelector((state) => state.clickPercentageReducer); // 클릭률 데이터, 놀이순위 데이터, 상품순위 데이터

    const [lineDateType, setLineDateType] = useState(1);
    const [lineStartDate, setLineStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [lineEndDate, setLineEndDate] = useState(moment().format('YYYY-MM-DD'));

    const [itemOpen, setItemOpen] = useState(false);
    const [choiceRowIndex, setChoiceRowIndex] = useState();

    const [clickPage, setClickPage] = useState(1); // 각 테이블의 페이지 넘버
    const [clickLimit, setClickLimit] = useState('10'); // 각 테이블의 limit_number

    useEffect(() => {
        dispatch(fetchGetClickPercentage(clickPage, clickLimit, lineDateType, lineStartDate, lineEndDate)); // 클릭률
    }, [dispatch, clickPage, clickLimit, lineDateType, lineStartDate, lineEndDate]);

    const setLimit = (num) => (e) => num === 1 && setClickLimit(e.target.value); // POST 갯수제한
    return (
        <div>
            <TypeInputContainerWrapper>
                <TypeInputContainer>
                    <TypeInput type={'button'} value={'일별'} style={{ fontWeight: lineDateType === 1 ? 'bold' : 'normal' }} onClick={() => setLineDateType(1)} />
                </TypeInputContainer>

                <SetDateWrapper className="date-setting-wrapper">
                    <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={lineStartDate} onChange={(e) => setLineStartDate(e.target.value)} />
                    <ContentText style={{ marginInline: '12px' }}>~</ContentText>
                    <TextInputNoLine style={{ borderBottom: `1px solid rgb(171 171 171)` }} type={'date'} value={lineEndDate} onChange={(e) => setLineEndDate(e.target.value)} />
                </SetDateWrapper>
            </TypeInputContainerWrapper>
            <ExcelPostBtnLine>
                <BtnWrapper>
                    <ExcelBtn onPress={() => arrayToExcel(headers, clickPercentageData, '클릭률.xlsx')} />
                    <Select onChange={setLimit(1)} value={clickLimit} id="pet-select">
                        <option value="10">10개씩 보기</option>
                        <option value="15">15개씩 보기</option>
                        <option value="20">20개씩 보기</option>
                        <option value="25">25개씩 보기</option>
                        <option value="30">30개씩 보기</option>
                        <option value="35">35개씩 보기</option>
                        <option value="40">40개씩 보기</option>
                        <option value="45">45개씩 보기</option>
                        <option value="50">50개씩 보기</option>
                    </Select>
                </BtnWrapper>
            </ExcelPostBtnLine>

            <table className="table table--min" cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th rowSpan="5" style={{ borderRight: '1px solid #cdcdcd' }}>
                            일자
                        </th>
                        <th colSpan="1" style={{ borderRight: '1px solid #cdcdcd' }}>
                            금일 기준
                        </th>
                        <th colSpan="2" style={{ borderRight: '1px solid #cdcdcd' }}>
                            모임 메뉴
                        </th>
                        <th colSpan="5" style={{ borderRight: '1px solid #cdcdcd' }}>
                            모임 카테고리
                        </th>
                        <th colSpan="2" style={{ borderRight: '1px solid #cdcdcd' }}>
                            모임명
                        </th>
                        <th colSpan="2" style={{ borderRight: '1px solid #cdcdcd' }}>
                            단짝 찾기 메뉴
                        </th>
                        <th colSpan="2" style={{ borderRight: '1px solid #cdcdcd' }}>
                            인연 메뉴
                        </th>
                    </tr>
                    <tr>
                        <td style={{ borderRight: '1px solid #cdcdcd' }}>총 방문자</td>

                        <td>클릭수</td>
                        <td style={{ borderRight: '1px solid #cdcdcd' }}>클릭률</td>

                        <td>BLACK</td>
                        <td>여행</td>
                        <td>문화</td>
                        <td>건강</td>
                        <td style={{ borderRight: '1px solid #cdcdcd' }}>기타</td>

                        <td>클릭수</td>
                        <td style={{ borderRight: '1px solid #cdcdcd' }}>클릭률</td>

                        <td>클릭수</td>
                        <td style={{ borderRight: '1px solid #cdcdcd' }}>클릭률</td>

                        <td>클릭수</td>
                        <td>클릭률</td>
                    </tr>
                </thead>

                <tbody className="tbody">
                    {clickPercentageData?.data?.map((data, index) => {
                        return (
                            <tr key={index}>
                                {/* 일자 */}
                                <td style={{ borderRight: '1px solid #cdcdcd' }}>{data.date_info}</td>
                                {/* 금일 방문자 */}
                                <td style={{ borderRight: '1px solid #cdcdcd' }}>{data.user_visit ? moneyFilter(data.user_visit) + '명' : '-'}</td>
                                {/* 모임메뉴 > 클릭수, 클릭률 */}
                                <td>{moneyFilter(parseInt(data.click_gathering_menu)) || '-'}</td>
                                <td style={{ borderRight: '1px solid #cdcdcd' }}>{data.click_gathering_menu_rate || '-'}</td>
                                {/* 모임 카테고리 > 여행, 문화, 건강, 기타 */}
                                <td>{parseInt(0) || <span style={{ color: '#c7c7c7' }}>-</span>}</td>
                                <td>{parseInt(data.click_gathering_category_travel) || '-'}</td>
                                <td>{parseInt(data.click_gathering_category_culture) || '-'}</td>
                                <td>{parseInt(data.click_gathering_category_health) || '-'}</td>
                                <td style={{ borderRight: '1px solid #cdcdcd' }}>{parseInt(data.click_gathering_category_etc) || '-'}</td>
                                {/* 모임명 > 클릭수, 클릭률 */}
                                <td>{moneyFilter(parseInt(data.click_gathering_detail)) || '-'}</td>
                                <td style={{ borderRight: '1px solid #cdcdcd' }}>{data.click_gathering_detail_rate || '-'}</td>
                                {/* 단짝찾기메뉴_클릭수/클릭률 */}
                                <td>{moneyFilter(parseInt(data.click_recommend_recommend_cnt)) || '-'}</td>
                                <td style={{ borderRight: '1px solid #cdcdcd' }}>{data.click_recommend_recommend_cnt_rate || '-'}</td>
                                {/* 인연메뉴_클릭수/클릭률 */}
                                <td>{moneyFilter(parseInt(data.click_recommend_destiney_cnt)) || '-'}</td>
                                <td>{data.click_recommend_destiney_cnt_rate || '-'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <PaginationLine>
                <Pagination
                    totalPages={clickPercentageData?.all_cnt ? Math.round(clickPercentageData?.all_cnt / 10) + 1 : 10}
                    limit={clickLimit}
                    page={clickPage}
                    setPage={setClickPage}
                    setItemOpen={setItemOpen}
                    setChoiceRowIndex={setChoiceRowIndex}
                />
            </PaginationLine>
        </div>
    );
};

export default ClickPercentageTable;

export const TypeInputContainerWrapper = styled.div`
    display: flex;
    gap: 15px;

    width: 100%;
    flex-direction: row;

    margin-top: 35px;
    margin-bottom: 25px;
`;

export const TypeInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid rgb(229, 229, 229);
    border-radius: 5px;

    @media (max-width: 912px) {
        width: 100px;
    }
`;

export const PaginationLine = styled.div`
    position: relative;
`;

export const ManualBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;

    margin: 0px 2px 5px 2px;

    color: #797979;
    font-family: Noto Sans KR;
    font-size: 13px;

    .manual span {
        font-weight: 600;
    }

    @media (max-width: 912px) {
        margin-top: 10%;
        /* margin-top: 15px; */
    }
`;

export const SubTitle = styled.div`
    font-size: 23px;
    font-family: noto_sans_kr_medium;

    margin: 7vh 10px 35px 10px;
`;

export const GoodsSubTitle = styled.div`
    font-size: 23px;
    font-family: noto_sans_kr_medium;

    margin: 3vh 10px 35px 10px;
`;

export const ExcelPostBtnLine = styled.div`
    margin: 5px 0px;
`;

export const MorePostButton = styled.div`
    display: flex;
    justify-content: start;

    margin: 7px 0;
`;

export const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const Select = styled.select`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid black;
    outline: none;

    padding: 5.3px;
`;

// 놀이/상품 순위
export const ActivityGoodsTableWrapper = styled.div`
    .table {
        table-layout: fixed;
        width: 100%;
        text-align: center;
    }
    .table th {
        background: #e6e6e6;
    }
    .table td,
    .table th {
        padding: 10px 20px;
        border-top: 1px solid #cdcdcd;
        word-break: break-all;
    }
    .table--min {
        min-width: 700px;
    }

    @media screen and (max-width: 768px) {
        /*normal*/
        .table-box {
            overflow-x: auto;
        }
    }
`;

// 클릭률 테이블/반응형
export const ClickPercentageTableWrapper = styled.div`
    .table {
        padding-right: 30px;
        table-layout: fixed;
        width: 100%;
        text-align: center;

        font-size: 14px;
    }

    .table th {
        background: #e6e6e6;
    }

    .table td,
    .table th {
        padding: 7px 0px;
        border-top: 1px solid #cdcdcd;
        word-break: break-all;
    }
    .table--min {
        min-width: 700px;
    }

    .tbody tr td {
        padding: 15px 0;
    }

    @media screen and (max-width: 768px) {
        /*normal*/
        .table-box {
            overflow-x: auto;
        }
    }
`;

// 날짜 설정 wrapper
const SetDateWrapper = styled.div`
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
