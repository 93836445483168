import React, { useCallback, useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
// icons
import { ArrowBackwardGray1x, ArrowForwardGray1x } from '../../../icons/icons';
import { sliceArrayByLimit } from './pagination_function';

// 페이지 네이션 컴포넌트
const Pagination = ({ totalPages, limit, page, setPage, setItemOpen, setChoiceRowIndex, wrapperStyle, style }) => {
    // state
    const [totalPageArray, setTotalPageArray] = useState([]);
    const [currentPageArray, setCurrentPageArray] = useState([]);

    useEffect(() => {
        if (page % limit === 1) {
            setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
        } else if (page % limit === 0) {
            setCurrentPageArray(totalPageArray[Math.floor(page / limit) - 1]);
        } else if (page === totalPages) {
            setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
        }
    }, [page, totalPages, limit, totalPageArray]);

    useEffect(() => {
        const slicedPageArray = sliceArrayByLimit(totalPages, limit);
        setTotalPageArray(slicedPageArray);
        setCurrentPageArray(slicedPageArray[0]);
    }, [totalPages, limit]);

    const go_to_first = useCallback(() => {
        setPage(1);
        if (setItemOpen) setItemOpen(false);
        if (setChoiceRowIndex) setChoiceRowIndex(null);
    }, [setPage, setItemOpen, setChoiceRowIndex]);

    // 맨 끝으로
    const go_to_last = useCallback(() => {
        setPage(totalPages);
        if (setItemOpen) setItemOpen(false);
        if (setChoiceRowIndex) setChoiceRowIndex(null);
    }, [totalPages, setPage, setItemOpen, setChoiceRowIndex]);

    // target page 이동
    const go_to_page = useCallback(
        (num) => {
            setPage(num);

            if (setItemOpen) setItemOpen(false);
            if (setChoiceRowIndex) setChoiceRowIndex(null);
        },
        [setPage, setItemOpen, setChoiceRowIndex],
    );

    // page number click
    const on_click_page_number = (page_index, index) => (e) => {
        e.preventDefault();

        go_to_page(page_index + 1);
    };

    // 이전 페이지
    const back_page = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
            if (setItemOpen) setItemOpen(false);
            if (setChoiceRowIndex) setChoiceRowIndex(null);
        }
    }, [page, setPage, setItemOpen, setChoiceRowIndex]);

    // 다음 페이지
    const next_page = useCallback(() => {
        if (page < totalPages) {
            setPage(page + 1);
            if (setItemOpen) setItemOpen(false);
            if (setChoiceRowIndex) setChoiceRowIndex(null);
        }
    }, [page, totalPages, setPage, setItemOpen, setChoiceRowIndex]);

    /************************************************ html ************************************************/
    return (
        <PaginationFrame style={{ ...wrapperStyle }}>
            <div className="first-btn">
                <BtnFirst
                    onClick={(e) => {
                        e.preventDefault();
                        go_to_first();
                    }}
                    disabled={page === 1}
                >
                    처음
                </BtnFirst>
            </div>

            <div style={{ marginTop: '3px', marginRight: '10px' }} className="left-btn">
                <BtnLeft
                    onClick={(e) => {
                        e.preventDefault();
                        back_page();
                    }}
                    disabled={page === 1}
                >
                    <ArrowBackwardGray1x />
                </BtnLeft>
            </div>

            {currentPageArray?.map((page_index, index) => {
                return (
                    <NumberArrayWrapper key={index} className="num-lists" style={{ ...style }}>
                        <BtnPageNum key={page_index + 1} current={page === page_index + 1} onClick={on_click_page_number(page_index, index)}>
                            {page_index + 1}
                        </BtnPageNum>
                    </NumberArrayWrapper>
                );
            })}

            <div style={{ marginTop: '3px' }} className="right-btn">
                <BtnRight
                    onClick={(e) => {
                        e.preventDefault();

                        next_page();
                    }}
                    disabled={page === totalPages}
                >
                    <ArrowForwardGray1x />
                </BtnRight>
            </div>

            <div className="last-btn">
                <BtnLast
                    onClick={(e) => {
                        e.preventDefault();

                        go_to_last();
                    }}
                    disabled={page === totalPages}
                >
                    마지막
                </BtnLast>
            </div>
        </PaginationFrame>
    );
};

export default React.memo(Pagination);

/************************************************ styled ************************************************/

const PaginationFrame = styled.div`
    display: flex;
    justify-content: center;

    margin: 40px auto;

    width: 100%;
`;

const BtnFirst = styled.button`
    cursor: pointer;

    background-color: #fff;
    border-width: 0px;

    font-size: 14px;
    font-family: noto_sans_kr_medium;
    color: rgb(112, 112, 112);

    width: 50px;

    @media (max-width: 912px) {
        width: 30px;
        /* width: auto; */
    }
`;

const BtnLast = styled.button`
    cursor: pointer;

    background-color: #fff;
    border-width: 0px;

    font-size: 14px;
    font-family: noto_sans_kr_medium;
    color: rgb(112, 112, 112);

    width: 50px;

    @media (max-width: 912px) {
        width: 38px;
        /* width: auto; */
    }
`;

const NumberArrayWrapper = styled.div`
    margin-right: 20px; //5px;

    @media (max-width: 912px) {
        margin-right: 10px;
    }
`;

const BtnPageNum = styled.button`
    cursor: pointer;

    border-width: 0px;
    font-size: 14px;
    font-family: noto_sans_kr_medium;
    font-weight: ${(props) => (props.current ? 'bold' : 'normal')};
    background-color: #fff;
    color: ${(props) => (props.current ? 'rgb(61,127,246)' : 'rgb(112, 112, 112)')};

    :hover {
        font-weight: bold;
        color: rgb(66, 139, 247);
    }
`;

const BtnLeft = styled.button`
    background-color: #fff;
    border-width: 0px;

    img {
        width: 13px;
        height: 13px;
    }
`;

const BtnRight = styled.button`
    background-color: #fff;
    border-width: 0px;

    img {
        width: 13px;
        height: 13px;
    }
`;
