import React, { useCallback, useEffect, useState } from 'react';

// redux
import { useSelector } from 'react-redux';

import MatchDispatchCenter from '../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';
import { ReduxGetLoading } from '../../data_system/redux/modules/redux_functions/redux_get_loading';
import { GET_HOSTS } from '../../data_system/redux/modules/hosts_reducer/hosts_table/hosts_table_type';
import CustomTable from '../../design_system/components/table/CustomTable';
import { h_px15rate, h_px1rate, h_px20rate, h_px4rate, h_px6rate, w_px12rate, w_px32rate, w_px362rate, w_px5rate } from '../../design_system/size';
import { ToggleText } from '../../design_system/components/total_top_section/TotalTopSection';

import WhiteBtn from '../../design_system/components/white_btn/WhiteBtn';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';
import { Motion, spring } from 'react-motion';
import { HeaderArrowBackwardBtn, HeaderTitle } from '../../design_system/components/admin_table/detail/forms/detail_common_form/DetailCommonForm';
import { ArrowBackwardWhite1x } from '../../design_system/icons/icons';
import LoadingPage from '../loading/LoadingPage';

import HostDetailRegister from '../../design_system/components/admin_table/detail/detail_registers/host_detail_register/HostDetailRegister';
import HostDetailComponent from '../../design_system/components/admin_table/detail/detail_components/host_detail_component/HostDetailComponent';
import ToggleBtn from '../../design_system/components/toggle_btn/ToggleBtn';
import Dropdown from '../../components/Common/Dropdown';
import SearchBar from '../../design_system/components/searchbar/SearchBar';
import { rgb_255_16_125, rgb_66_139_247, rgba_255_16_125_0d3, rgba_66_139_247_0d2 } from '../../design_system/colors';
import { format_number } from '../../data_system/Regexp/Regexps';
import { MainBtn } from '../../design_system/components/pink_btn/PinkBtn';
import { RightSideBar } from '../../design_system/components/modal/RightSideBar';

import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

const Host = () => {
    const loading = ReduxGetLoading(GET_HOSTS);
    const hosts = useSelector((state) => state.hostsReducer.hosts);

    const hostDetail = useSelector((state) => state.hostsReducer.hostsDetail);
    const { onGetHostsAsync, onGetHostsDetailAsync, onPutHostsDetailAsync, onDeleteHostAsync } = MatchDispatchCenter();

    const [page, setPage] = useState(1);
    const [checks, setChecks] = useState([]);
    const [registHostModal, setRegistHostModal] = useState(0);
    const [hostInfoModal, setHostInfoModal] = useState(0);
    const [activeToggle, setActiveToggle] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);

    useEffect(() => {
        onGetHostsAsync({
            searchWord: searchText,
            searchType: searchType,
            filterExposure: activeToggle,
            page: page,
            size: 10,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, activeToggle, refresh]);
    const openRegistHostModal = () => {
        if (hostInfoModal !== 0) closeHostInfoModal();
        setRegistHostModal(w_px362rate);
    };
    const closeRegistHostModal = () => {
        setRegistHostModal(0);
    };
    const openHostInfoModal = () => {
        if (registHostModal !== 0) closeRegistHostModal();

        setHostInfoModal(w_px362rate);
    };
    const closeHostInfoModal = () => {
        setHostInfoModal(0);
    };
    const renderHostCell = (key, value, index, style) => {
        if (key === 'exposure_yn') {
            return (
                <td key={'customTd_' + index} style={style}>
                    <button
                        style={{
                            color: !value ? rgb_255_16_125 : rgb_66_139_247,
                            backgroundColor: !value ? rgba_255_16_125_0d3 : rgba_66_139_247_0d2,
                            border: `${h_px1rate}px solid ${!value ? rgb_255_16_125 : rgb_66_139_247}`,
                        }}
                    >
                        {!value ? 'off' : 'on'}
                    </button>
                </td>
            );
        } else if (key === 'current_pay_amount' || key === 'prev_calculate_amount' || key === 'prev_pay_amount') {
            return (
                <td key={'customTd_' + index} style={style}>
                    {format_number(value)}
                </td>
            );
        } else {
            return (
                <td key={'customTd_' + index} style={style}>
                    {value}
                </td>
            );
        }
    };
    /**
     * 호스트 여러개 삭제
     * @returns {Promise<void>}
     */
    const deleteAdminHost = async () => {
        const deletedHostList = checks.map((hostData) => hostData.host_id);
        const body = {
            data: {
                host_id: deletedHostList,
            },
        };
        await onDeleteHostAsync(body);
        setRefresh(!refresh);
    };

    const searchHost = async () => {
        await onGetHostsAsync({
            searchWord: searchText,
            searchType: searchType,
            filterExposure: activeToggle,
            page: page,
            size: 10,
        });
        setRefresh(!refresh);
    };

    return (
        <Wrapper02>
            <MainPageTitle text={'호스트'} />
            <div style={{ marginTop: h_px20rate, marginBottom: h_px20rate, display: 'flex' }}>
                <Dropdown
                    list={[{ id: 1, title: '호스트명', value: 1 }]}
                    index={0}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                />
                <div style={{ marginLeft: w_px5rate }}>
                    <SearchBar
                        searchText={searchText}
                        onChangeSearchText={useCallback((e) => {
                            setSearchText(e.target.value);
                        }, [])}
                        onSearch={searchHost}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: h_px15rate,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBlock: h_px6rate,
                    }}
                >
                    <ToggleText>모집중인 업체만 보기</ToggleText>
                    <div style={{ marginInline: w_px12rate }}>
                        <ToggleBtn toggle={activeToggle} clickedToggle={() => setActiveToggle(!activeToggle)} />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <MainBtn style={{ marginInline: w_px12rate, paddingInline: w_px32rate, width: 'auto' }} onClick={openRegistHostModal}>
                        등록
                    </MainBtn>
                    <WhiteBtn style={{ marginInline: w_px12rate, paddingInline: w_px32rate, width: 'auto' }} onClick={deleteAdminHost}>
                        삭제
                    </WhiteBtn>
                </div>
            </div>
            {!loading && hosts ? (
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <CustomTable
                            header={hosts.header}
                            data={hosts.body}
                            trStyle={{
                                paddingBlock: h_px15rate,
                                fontSize: h_px15rate,
                            }}
                            adminIdKey={'host_id'}
                            onRowClick={(item, e) => {
                                if (e.target.tagName === 'TD') {
                                    onGetHostsDetailAsync(item.host_id);
                                    openHostInfoModal();
                                } else if (e.target.tagName === 'BUTTON') {
                                    onPutHostsDetailAsync({
                                        host_id: item.host_id,
                                        exposure_yn: item.exposure_yn ? false : true,
                                    });
                                }
                            }}
                            component={renderHostCell}
                            setCheck={setChecks}
                            checkList={checks}
                        />
                    </div>
                    <div>
                        <Motion style={{ width: spring(registHostModal) }}>
                            {({ width }) => (
                                <RightSideBar style={{ width: width }}>
                                    <HeaderTitle>
                                        <HeaderArrowBackwardBtn
                                            onClick={() => {
                                                closeRegistHostModal();
                                            }}
                                        >
                                            <ArrowBackwardWhite1x></ArrowBackwardWhite1x>
                                        </HeaderArrowBackwardBtn>
                                        <div
                                            style={{
                                                marginBottom: h_px4rate,
                                            }}
                                        >
                                            호스트 정보 등록
                                        </div>
                                    </HeaderTitle>
                                    <HostDetailRegister closeModal={closeRegistHostModal} refresh={refresh} setRefresh={setRefresh} />
                                </RightSideBar>
                            )}
                        </Motion>
                    </div>
                    <div>
                        <Motion style={{ width: spring(hostInfoModal) }}>
                            {({ width }) => (
                                <RightSideBar style={{ width: width }}>
                                    <HeaderTitle>
                                        <HeaderArrowBackwardBtn
                                            onClick={() => {
                                                closeHostInfoModal();
                                            }}
                                        >
                                            <ArrowBackwardWhite1x></ArrowBackwardWhite1x>
                                        </HeaderArrowBackwardBtn>
                                        <div
                                            style={{
                                                marginBottom: h_px4rate,
                                            }}
                                        >
                                            상세정보
                                        </div>
                                    </HeaderTitle>
                                    <HostDetailComponent hostsDetail={hostDetail} closeModal={closeHostInfoModal} refresh={refresh} setRefresh={setRefresh} />
                                </RightSideBar>
                            )}
                        </Motion>
                    </div>
                </div>
            ) : (
                <LoadingPage />
            )}
            <Pagination totalPages={hosts ? Math.round(hosts.all_count / 10) + 1 : 10} limit={10} page={page} setPage={setPage} />
        </Wrapper02>
    );
};

export default React.memo(Host);
