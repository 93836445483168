import moment from 'moment';
import styled from 'styled-components';
import { interestMock } from '../dataSystems/CategoryLists';
import { BANK_CODE } from '../components/SubscribePayment/payBankCodeService';
import { MainButton } from '../designComponents/buttons/Buttons';
import FlexMainWrapper from '../designComponents/wrappers/FlexMainWrapper';

// 모임 결제 방식
export const gatheringPayTypeFilter = (num, price) => {
    const _num = parseInt(num);
    switch (true) {
        case _num === 0:
            return <span style={{ color: 'rgb(255,16,125)' }}>일반</span>;
        case _num === 2:
            return (
                <FlexMainWrapper style={{ flexDirection: 'column', gap: '1px' }}>
                    <span style={{ color: 'rgb(66,139,247)' }}>연회비</span>
                    <span style={{ color: 'rgb(66,139,247)' }}>{moneyFilter(price || 0)}원</span>
                </FlexMainWrapper>
            );
        default:
            return;
    }
};

// 모임권한 필터
export const gatheringRoleFilter = (role, onClick = () => {}) => {
    switch (true) {
        case role === 3:
            return <MainButton text="모임장" style={{ backgroundColor: 'rgb(66,139,247)' }} onClick={onClick} />;
        case role === 2:
            return <MainButton text="운영진" style={{ backgroundColor: 'rgb(255,16,125)' }} onClick={onClick} />;
        case role === 1:
            return <MainButton text="일반 회원" style={{ backgroundColor: 'gray' }} onClick={onClick} />;
        default:
            return <MainButton text="가입 대기" style={{ backgroundColor: 'rgb(70,69,69)' }} onClick={onClick} />;
    }
};

// 관심주제 필터
export const interestWordFilter = (interest_num) => {
    let arr = [];
    interest_num?.forEach((num) => {
        interestMock.forEach((data) => {
            if (num === data.id) arr.push(data.code_name);
        });
    });
    return arr?.map((num) => num);
};

/** 채팅방 회원 필터 */
export const userFilter = (userId, userInfo, usersProfile) => {
    const [user] = userInfo ? userInfo.filter((v) => v.id === userId) : null;
    const [userProfile] = usersProfile ? usersProfile.filter((v) => v.user_id === userId) : null;
    return { ...user, ...userProfile };
};

/** 빈객체 확인 */
export const isEmptyObj = (obj, type = false) => {
    if (type) {
        if (obj) {
            if (Object.keys(obj).length === 0) return false;
            return true;
        } else {
            return false;
        }
    } else {
        if (obj.constructor === Object && Object.keys(obj).length === 0) return false;
        return true;
    }
};

/** 카카오 알림톡 메세지 유형 필터 */
export const messageTypeFilter = (type) => {
    switch (type) {
        case 'BA':
            return '기본형';
        case 'EX':
            return '부가 정보형';
        case 'AD':
            return '광고 추가형';
        case 'MI':
            return '복합형';
        default:
            return null;
    }
};

/** 은행정보 필터 */
export const gatheringBankInfoFilter = (bank_code) => {
    if (bank_code) return BANK_CODE.map((data) => bank_code === data.CODE1 && data.KOR);
    else return '계좌 정보없음';
};

/** 시럽 및 보인트 증감/차감 */
export const moneyUpAndDown = (value, unit = '개') =>
    value < 0 ? (
        <span style={{ color: 'rgb(66,139,247)' }}>
            {moneyFilter(value)}
            {unit}
        </span>
    ) : value === 0 ? (
        <span style={{ color: '#797979' }}>0{unit}</span>
    ) : (
        <span style={{ color: 'rgb(255, 16, 125)' }}>
            +{moneyFilter(value)}
            {unit}
        </span>
    );

/** 카테고리 필터 */
export const interestCodeFilter = (interestCodeLists) => (
    <InterestCode id="interest-code-wrapper">
        {interestCodeLists.map((data) =>
            interestMock.map((item) => {
                if (item.id === parseInt(data)) return <div className="code">{`${item.code_group} > ${item.code_name}`}</div>;
                else return false;
            }),
        )}
    </InterestCode>
);

export const newUpdate = ({ text, style, fontStyle }) => {
    return (
        <IsNew style={{ ...style }}>
            <span style={{ ...fontStyle }}>{text}</span>
        </IsNew>
    );
};

// 돈 단위 필터
export const moneyFilter = (num, text = '') => {
    let _num = parseInt(num);
    if (_num) return `${_num.toLocaleString('ko-KR')}${text}`;
    if (!_num) return `0`;
    else return <span>{`${0}${text}`}</span>;
};

export const dateFilter = (date, type = 'YYYY-MM-DD HH:mm:ss') => {
    if (!date) {
        return '날짜 없음';
    }
    return moment(date || '0000-00-00 00:00:00').format(type);
};

// 모임 구분
export const gatheringType = (num) => {
    let _num = parseInt(num);
    switch (true) {
        case _num === 0:
            return <span style={{ color: '#2977ff', fontWeight: 'bold', fontSize: '15px' }}>친목</span>;
        case _num === 1:
            return <span style={{ color: 'rgb(255, 16, 125)', fontWeight: 'bold', fontSize: '15px' }}>배움</span>;
        default:
            return <span>정보 없음</span>;
    }
};

// 채팅상태 필터
export const availFilter = (num, textY = '진행중', textN = '종료', style = { Y: null, N: null }) => {
    let _num = parseInt(num);

    if (_num === 1) {
        return (
            <Status color={Boolean(_num)} style={{ ...style.Y }}>
                {textY}
            </Status>
        );
    } else if (_num === 0) {
        return (
            <Status color={Boolean(_num)} style={{ ...style.N }}>
                {textN}
            </Status>
        );
    }
};

export const sexFilter = (num, man = '남자', girl = '여자') => {
    let _num = parseInt(num);

    if (_num === 1) {
        return <span style={{ color: '#2977ff' }}>{man}</span>;
    } else if (_num === 2) {
        return <span style={{ color: 'rgb(255, 16, 125)' }}>{girl}</span>;
    } else {
        return null;
    }
};

export const colorFilter = (boolean, value) => {
    if (boolean) {
        return <span style={{ color: '#2977ff' }}>{value}</span>;
    } else {
        return <span style={{ color: 'rgb(255, 16, 125)' }}>{value}</span>;
    }
};

export const checkReadFilter = (num, textY = '읽음', textN = '읽지않음') => {
    let _num = parseInt(num);

    if (_num) return <Status color={Boolean(_num)}>{textY}</Status>;
    else if (!_num) {
        return (
            <Status Status color={Boolean(_num)}>
                {textN}
            </Status>
        );
    }
};

export const contentFilter = (content, limit = 15) => {
    if (!content) return false;
    if (content?.length > limit) {
        return content?.slice(0, limit) + '...';
    } else {
        return content;
    }
};

export const contentTypeFilter = (num, gathering_id) => {
    const _gathering_id = parseInt(gathering_id);
    if (_gathering_id === 525) return <ContentTypeStatus color={'#f16e3f'}>7학년</ContentTypeStatus>; // 7학년
    if (_gathering_id === 526) return <ContentTypeStatus color={'#d43cfb'}>6학년</ContentTypeStatus>; // 6학년
    if (_gathering_id === 527) return <ContentTypeStatus color={'#4d631e'}>5학년</ContentTypeStatus>; // 5학년
    const _num = parseInt(num);
    if (_num === 1) {
        return <ContentTypeStatus color={false}>일반</ContentTypeStatus>;
    } else if (_num === 3) {
        return <ContentTypeStatus color={'rgb(255, 16, 125)'}>공지</ContentTypeStatus>;
    } else if (_num === 4) {
        return <ContentTypeStatus color={'rgb(85 189 103)'}>첫인사</ContentTypeStatus>;
    } else if (_num === 5) {
        return <ContentTypeStatus color={'rgb(237 66 247)'}>후기</ContentTypeStatus>;
    }
};

/** 모임 > 관심주제 필터 */
export const interestFilter = (array) => {
    let arr = [];
    array?.forEach((num) => {
        interestMock.forEach((data) => {
            if (num === data.id) arr.push(data.code_name);
        });
    });
    return arr?.map((num, index) => <KategoryItem key={index}>{num}</KategoryItem>);
};

export const interestTextFilter = (array) => {
    let arr = [];
    array?.forEach((num) => {
        interestMock.forEach((data) => {
            if (num === data.id) arr.push(data.code_name);
        });
    });
    return arr?.map((num, index) => `${num}`);
};

export const deleteUnderbarFromPhoneNum = (num) => {
    let _num = num.replace(/[^0-9]/g, ''); // 숫자를 제외한 모든 문자 제거
    return _num;
};

// 결제상태 필터 (결제상태 0: 결제대기중, 1: 입금대기중, 2: 결제완료, 3: 환불신청, 4: 환불완료 5: 환불거절 6: 결제취소)
export const payStatusFilter = (status) => {
    switch (true) {
        case status === 0:
            return '결제대기중';
        case status === 1:
            return '입금대기중';
        case status === 2:
            return '결제완료';
        case status === 3:
            return '환불신청';
        case status === 4:
            return '환불완료';
        case status === 5:
            return '환불거절';
        case status === 6:
            return '기간 만료';
        case status === 7:
            return '결제 취소';
        default:
            return <span style={{ color: 'gray' }}>정보없음</span>;
    }
};

export const availCheck = (blocked_yn, avail_yn) => {
    switch (true) {
        case blocked_yn === 1 && avail_yn === 1:
            return <span style={{ color: 'red' }}>이용차단</span>;
        case blocked_yn === 0 && avail_yn === 1:
            return <span>정상</span>;
        case blocked_yn === 1 && avail_yn === 0:
            return <span style={{ color: 'red' }}>이용차단</span>;
        default:
            return <span style={{ color: 'red' }}>탈퇴</span>;
    }
};

export const profilePhotoAuth = (num) => {
    let _num = parseInt(num);
    if (_num === 0) return <span style={{ color: 'red' }}>미인증</span>;
    else if (_num === 1) return <span style={{ color: 'rgb(76, 175, 80)' }}>인증 대기중</span>;
    else return <span style={{ color: 'rgb(66, 139, 247)' }}>인증완료</span>;
};

/** 출금신청 여부 */
export const accountFilter = (num) => {
    let _num = parseInt(num);
    switch (_num) {
        case 1:
            return <span style={{ color: 'rgb(66, 139, 247)' }}>출금신청</span>;
        case 2:
            return '이체완료';
        case 3:
            return '출금신청반려';
        default:
            return '-';
    }
};

/** 입/출금 상태 [1: 입금 2: 참여 취소 3: 출금신청] */
export const statusFilter = (num) => {
    let _num = parseInt(num);
    switch (_num) {
        case 1:
            return '입금';
        case 2:
            return <span style={{ color: 'rgb(255, 16, 125)' }}>참여 취소</span>;
        case 3:
            return <span style={{ color: 'rgb(66, 139, 247)' }}>이체 신청</span>;
        default:
            return '-';
    }
};

export const noneImage = (sex) => {
    const _sex = parseInt(sex);
    const man = 'userPhoto/to+me.png'; // https://sinor-production.s3.ap-northeast-2.amazonaws.com/userPhoto/to+me.png
    const woman = 'userPhoto/you+die.png'; // https://sinor-production.s3.ap-northeast-2.amazonaws.com/userPhoto/you+die.png
    const result = parseInt(_sex) === 1 ? man : woman;
    return result;
};

/** CS 처리여부 */
export const finishedFilter = (num) => {
    let _num = parseInt(num);
    switch (_num) {
        case 0:
            return <span style={{ color: 'rgb(255, 16, 125)' }}>미처리</span>;
        case 1:
            return '진행중';
        case 2:
            return <span style={{ color: 'rgb(66, 139, 247)' }}>완료</span>;
        default:
            return '-';
    }
};

/** 모임원 권한 */
export const roleFilter = (num) => {
    let _num = parseInt(num);
    switch (_num) {
        case 1:
            return '일반';
        case 2:
            return <span style={{ color: 'rgb(255, 16, 125)' }}>운영진</span>;
        case 3:
            return <span style={{ color: 'rgb(66, 139, 247)' }}>모임장</span>;
        default:
            return '-';
    }
};

/** 모임 공개 여부 */
export const exposureFilter = (num) => {
    let _num = parseInt(num);
    switch (_num) {
        case 0:
            return <span style={{ color: 'rgb(255, 16, 125)' }}>N</span>;
        case 1:
            return <span style={{ color: 'rgb(66, 139, 247)' }}>Y</span>;
        default:
            return '-';
    }
};

/* 앱 노출 위치 */
export const appSectionFilter = (num) => {
    let _num = parseInt(num);
    switch (_num) {
        case 0:
            return <span style={{ color: 'black', fontWeight: 'bold' }}>전체</span>;
        case 1:
            return <span style={{ color: 'rgb(255, 16, 125)', fontWeight: 'bold' }}>시럽</span>;
        case 2:
            return <span style={{ color: 'rgb(66, 139, 247)', fontWeight: 'bold' }}>시놀</span>;
        default:
            return '-';
    }
};

export const payContentsFilter = (contentsType) => {
    const numberType = Number(contentsType);
    if (numberType === 5) {
        return <span>상품</span>;
    } else if (numberType === 6) {
        return <span>모임 일정</span>;
    } else if (numberType === 7) {
        return <span>하트 패키지</span>;
    } else if (numberType === 9) {
        return <span>연회비</span>;
    }
};

export const payTypeFilter = (payType) => {
    const numberType = Number(payType);

    if (numberType === -1) {
        return <span>0원 결제</span>;
    } else if (numberType === 0) {
        return <span>국내 카드 결제</span>;
    } else if (numberType === 1) {
        return <span>무통장 입금</span>;
    } else if (numberType === 4) {
        return <span>계좌 이체</span>;
    } else if (numberType === 5) {
        return <span>현장 결제</span>;
    }
};

export const deleteCrewType = (deleteType) => {
    if (Number(deleteType) === 0) {
        return <span> 정상 </span>;
    } else if (Number(deleteType) === 1) {
        return <span> 자진 탈퇴</span>;
    } else if (Number(deleteType) === 2) {
        return <span> 내보내기</span>;
    } else {
        return <span>-</span>;
    }
};

const Status = styled.div`
    width: 50px;
    padding: 3px 0;

    text-align: center;
    font-weight: 500;

    color: ${(props) => (props.color ? 'rgb(66, 139, 247)' : 'rgb(255, 16, 125)')};
    border: ${(props) => (props.color ? '1px solid rgb(66, 139, 247)' : '1px solid rgb(255, 183, 216)')};
    background-color: ${(props) => (props.color ? 'rgba(66, 139, 247, 0.08)' : 'rgb(255, 227, 240)')};
`;

const ContentTypeStatus = styled.div`
    width: 50px;
    padding: 3px 0;

    text-align: center;
    font-weight: 600;

    color: ${(props) => (props.color ? `${props.color}` : 'rgb(66, 139, 247)')};
    border: ${(props) => (props.color ? `1px solid ${props.color}` : '1px solid rgb(66, 139, 247)')};
    background-color: ${(props) => (props.color === 3 ? 'rgb(255, 227, 240)' : 'rgba(66, 139, 247, 0.08)')};
`;

const KategoryItem = styled.div`
    display: inline-block;

    background-color: rgba(66, 139, 247, 0.08);
    border: 1px solid rgb(66, 139, 247);
    border-radius: 10px;

    width: auto;

    font-size: 12px;
    text-align: center;
    color: rgb(66, 139, 247);

    margin: 2px 2px;
    padding: 5px 7px;
`;

const IsNew = styled.div`
    display: inline;
    color: #4045ff;
    font-weight: 600;
    font-size: 11px;
    padding: 2px 7px;
    margin-left: 5px;
    background-color: #49cc906b;
    border-radius: 10px;
`;

const InterestCode = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 3px;
    .code {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        border-radius: 10px;
        background-color: rgb(66, 139, 247);
        color: #fff;
    }
`;

export const alarmTalkStatusFilter = (code) => {
    switch (code) {
        case '0000':
            return '정상 발송';
        case '1001':
            return '잘못된 Body Data (개발자 확인 필요)';
        case '1002':
            return '파트너 키가 유효하지 않음';
        case '1003':
            return '발신 프로필 키가 유효하지 않음';
        case '1004':
            return 'Request BODY(Json)에서 name을 찾을 수 없음';
        case '1005':
            return '발신 프로필을 찾을 수 없음';
        case '1006':
            return '삭제된 발신 프로필';
        case '1007':
            return '차단 상태의 발신 프로필';
        case '1011':
            return '계약 정보를 찾을 수 없음';
        case '1012':
            return '잘못된 형식의 유저 키 요청';
        case '1013':
            return '유효하지 않은 app연결';
        case '1014':
            return '유효하지 않은 사업자번호';
        case '1015':
            return '유효하지 않은 app user id 요청';
        case '1016':
            return '사업자등록번호 불일치';
        case '1020':
            return '올바른 유저 식별자 값이 하나도 없는 경우';
        case '1021':
            return '차단 상태의 카카오톡 채널 (카카오톡 채널 운영툴에서 확인)';
        case '1022':
            return '닫힘 상태의 카카오톡 채널 (카카오톡 채널 운영툴에서 확인)';
        case '1023':
            return '삭제된 카카오톡 채널 (카카오톡 채널 운영툴에서 확인)';
        case '1024':
            return '삭제 대기 상태의 카카오톡 채널 (카카오톡 채널 운영툴에서 확인)';
        case '1025':
            return '메시지 차단 상태의 카카오톡 채널 (카카오톡 채널 운영툴에서 확인)';
        case '1026':
            return '해당 msg_type 에서 사용할 수 없는 response_method 로 요청 (이미지알림톡(AI)는 realtime 으로 발송 불가)';
        case '1030':
            return '잘못된 파라메터 요청';
        case '1033':
            return '템플릿 타입과 메시지 타입 불일치';
        case '2003':
            return '메시지 전송 실패 (테스트 서버에서 카카오톡 채널을 추가하지 않은 경우)';
        case '2004':
            return '템플릿 일치 확인 시 오류 발생 (카카오 내부 오류)';
        case '2006':
            return '시리얼넘버 형식 불일치';
        case '3000':
            return '예기치 않은 오류 발생';
        case '3005':
            return '메시지를 발송 했으나 수신확인 안 됨 (성공 불 확실)';
        case '3006':
            return '카카오 내부 시스템 오류로 메시지 전송 실패';
        case '3008':
            return '전화번호 오류';
        case '3010':
            return 'Json 파싱 오류';
        case '3011':
            return '메시지가 존재하지 않음';
        case '3012':
            return '메시지 일련번호가 중복됨 (메시지 일련 번호는 고유의 값이 부여되어야 함)';
        case '3013':
            return '빈 메시지';
        case '3014':
            return '메시지 길이 제한 오류 (텍스트 타입 1000자 초과, 이미지 타입 400자 초과)';
        case '3015':
            return '템플릿을 찾을 수 없음';
        case '3016':
            return '메시지 내용이 템플릿과 일치하지 않음';
        case '3018':
            return '메시지를 전송할 수 없음';
        case '3020':
            return '메시지 확인 정보를 찾을 수 없음';
        case '3022':
            return '메시지 발송 가능한 시간이 아님 (친구 톡/마케팅 메시지는 08시~ 20시까지 발송 가능)';
        case '3024':
            return '메시지에 포함된 이미지를 전송할 수 없음';
        case '3025':
            return '변수 글자 수 제한 초과';
        case '3026':
            return '상담/봇 전환 버튼 extra, event 글자수 제한 초과';
        case '3027':
            return '버튼 내용이 템플릿과 일치 하지 않음';
        case '3028':
            return '메시지 강조 표기 타이틀이 템플릿과 일치하지 않음';
        case '3029':
            return '메시지 강조 표기 타이틀 길이 제한 초과 (50자)';
        case '3031':
            return '텍스트 유형 불일치';
        case '3030':
            return '메시지 타입과 템플릿 강조유형이 일치하지 않음';
        case '3031':
            return '헤더가 템플릿과 일치하지 않음';
        case '3032':
            return '헤더 길이 제한 초과(16 자)';
        case '3033':
            return '아이템 하이라이트가 템플릿과 일치하지 않음';
        case '3034':
            return '아이템 하이라이트 타이틀 길이 제한 초과 (이미지 없는 경우 30 자, 이미지 있는 경우 21 자)';
        case '3035':
            return '아이템 하이라이트 디스크립션 길이 제한 초과 (이미지 없는 경우 19 자, 이미지 있는 경우 14 자)';
        case '3036':
            return '아이템 리스트가 템플릿과 일치하지 않음';
        case '3037':
            return '아이템 리스트의 아이템의 디스크립션 길이 제한 초과(23 자)';
        case '3038':
            return '아이템 요약 정보가 템플릿과 일치하지 않음';
        case '3039':
            return '아이템 요약정보의 디스크립션 길이 제한 초과(14 자)';
        case '3040':
            return '아이템 요약 정보의 디스크립션에 허용되지 않은 문자 포함(통화기호/코드, 숫자, 콤마, 소수점, 공백을 제외한 문자 포함)';
        case '4000':
            return '메시지 전송 결과를 찾을 수 없음';
        case '4001':
            return '알수 없는 메시지 상태';
        case '7011':
            return '시리얼 넘버 패턴 에러';
        case '7014':
            return '메시지 유효 시간 초과 에러';
        case '8512':
            return '수신자 타입 찾을 수 없음';
        case '8514':
            return 'request_id 찾을 수 없음';
        case '8520':
            return '지원하지 않는 상품 타입 오류';
        case '8521':
            return '지원하지 않는 메시지 타입 오류';
        case '8522':
            return '지원하지 않는 텍스트 유형 오류';
        case '8523':
            return '지원하지 않는 response method 오류';
        case '8530':
            return '수신자 목록 사이즈 오류';
        case '8999':
            return '내부 서버 오류';
        case '9998':
            return '시스템에 문제가 발생하여 담당자 확인 중';
        case '9999':
            return '시스템에 문제가 발생하여 담당자 확인 중';
        case 'B000':
            return '중계사 발송을 위한 사전 작업 실패';
        case 'B001':
            return '중계사 발송 실패';
        case 'B002':
            return '잘못된 요청으로 인해 필터링됨';
        case 'B003':
            return '올바르지 않은 발신번호 포멧';
        case 'B004':
            return '쿼터(발송 제한량) 초과';
        case 'B005':
            return '메시지 요청 시간과 처리 시간의 차이가 허용 범위를 벗어남';
        case 'B400':
            return '메시지 형식 오류';
        case 'B999':
            return '예기치 못한 에러';
    }
};

export const bankFilter = (bank) => {
    return BANK_CODE.find((e) => e.CODE1 === bank || e.CODE2 === bank);
};
