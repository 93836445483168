import React, { useCallback, useEffect, useState } from 'react';

// module
import produce from 'immer';

// CSS
import styled from 'styled-components';
import { ArrowDownBlue1x, ArrowUpBlue1x } from '../../icons/icons';
import { h_px38rate, w_px29rate } from '../../size';
import { rgb_255_255_255, rgb_35_40_45, rgba_66_139_247_0d2 } from '../../colors';

const CustomCommonDropDown = ({ list, index, resetThenSet }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('선택 안함');

  useEffect(() => {
    setHeaderTitle(index < 0 ? '선택 안함' : list.find((item) => item.code === index)?.code_name);
  }, [index, list]);

  const selectItem = useCallback(
    (item) => {
      const code_name = item?.code_name;
      if (code_name) {
        setHeaderTitle(code_name);
        resetThenSet(item.code);
      } else {
        setHeaderTitle('선택 안함');
        resetThenSet(-1);
      }

      setIsListOpen(false);
    },
    [resetThenSet],
  );

  const toggleList = useCallback(() => {
    setIsListOpen(produce((isListOpen) => !isListOpen));
  }, []);

  return (
    <DropdownFrame>
      <MainTitleBtn type="button" onClick={toggleList} isListOpen={isListOpen}>
        <HeaderTitle>{headerTitle}</HeaderTitle>
        {isListOpen ? <ArrowUpBlue1x></ArrowUpBlue1x> : <ArrowDownBlue1x></ArrowDownBlue1x>}
      </MainTitleBtn>

      {isListOpen && (
        <DropdownListFrame role="list">
          <MenuItemBtn type="button" key={'common_null_btn'} onClick={() => selectItem()}>
            선택 안함
          </MenuItemBtn>

          {list.map((item) => (
            <MenuItemBtn type="button" key={item.id} onClick={() => selectItem(item)}>
              {item.code_name}
            </MenuItemBtn>
          ))}
        </DropdownListFrame>
      )}
    </DropdownFrame>
  );
};
export default React.memo(CustomCommonDropDown);

const DropdownFrame = styled.div`
  /* position: relative; */
`;

const MainTitleBtn = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 150px;
  height: ${h_px38rate}px;

  background-color: ${rgb_255_255_255};

  border-width: 1px;
  border-bottom-width: ${(props) => (props.isListOpen ? '0px' : '1px')};
  border-color: ${rgba_66_139_247_0d2};

  word-break: break-all;

  :hover {
    background-color: #00000012;
  }
`;

const HeaderTitle = styled.div`
  margin-right: ${w_px29rate}px;

  font-family: noto_sans_kr_medium;
  font-size: 13px;
  color: ${rgb_35_40_45};

  word-break: break-all;
`;

const DropdownListFrame = styled.div`
  position: absolute;
  box-sizing: border-box;

  width: 150px;

  border: 1px solid ${rgba_66_139_247_0d2};
  border-top-width: 0px;
  background-color: ${rgb_255_255_255};
  /* z-index: 999; */
`;

const MenuItemBtn = styled.button`
  cursor: pointer;
  text-align: left;

  width: 148px;
  height: ${h_px38rate}px;

  background-color: ${rgb_255_255_255};
  border-width: 0px;

  font-family: noto_sans_kr_medium;
  font-size: 13px;
  color: ${rgb_35_40_45};

  word-break: break-all;

  :hover {
    background-color: #00000012;
  }
`;
