// 회원리스트 상세검색 필터
export const GatheringUserListsFillterTableHeader = [
    {
        key: 'crew_created_at',
        value: '모임 가입일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'user_created_at',
        value: '앱 가입일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'role',
        value: '권한',
        filter: [
            {
                key: 1,
                value: '일반',
            },
            {
                key: 2,
                value: '운영진',
            },
            {
                key: 3,
                value: '모임장',
            },
        ],
    },
    {
        key: 'membership_bage',
        value: '회원등급',
        filter: [
            {
                key: 1,
                value: '일반',
            },
            {
                key: 2,
                value: '우수회원',
            },
        ],
    },
    {
        key: 'name',
        value: '회원명',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'nick_name',
        value: '닉네임',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'phone_no',
        value: '회원연락처',
    },
    {
        key: 'user_photo',
        value: '회원 대표사진',
        filter: [
            {
                key: 1,
                value: '있음',
            },
            {
                key: 2,
                value: '없음',
            },
        ],
    },
    {
        key: 'deleted_at',
        value: '참여상태',
        filter: [
            {
                key: 1,
                value: '정상',
            },
            {
                key: 2,
                value: '탈퇴',
            },
        ],
    },
    {
        key: 'contents_count',
        value: '게시글 수',
        filter: [
            {
                key: 1,
                value: '0개~10개',
            },
            {
                key: 2,
                value: '10개~20개',
            },
            {
                key: 3,
                value: '30개~40개',
            },
            {
                key: 4,
                value: '40개~50개',
            },
            {
                key: 5,
                value: '50개 이상',
            },
        ],
    },
    {
        key: 'comment_count',
        value: '댓글 수',
        filter: [
            {
                key: 1,
                value: '0개~10개',
            },
            {
                key: 2,
                value: '10개~20개',
            },
            {
                key: 3,
                value: '30개~40개',
            },
            {
                key: 4,
                value: '40개~50개',
            },
            {
                key: 5,
                value: '50개 이상',
            },
        ],
    },
    {
        key: 'count_join_schedule',
        value: '일정참여 횟수',
        filter: [
            {
                key: 1,
                value: '1회~5회',
            },
            {
                key: 2,
                value: '5회~10회',
            },
            {
                key: 3,
                value: '10회 이상',
            },
        ],
    },
];

export const GatheringUserListsFillter = [
    {
        key: 'crew_created_at',
        value: '모임 가입일',
    },
    {
        key: 'user_created_at',
        value: '앱 가입일',
    },
    {
        key: 'content_user_role',
        value: '권한',
    },
    {
        key: 'user_name',
        value: '회원명',
    },
    {
        key: 'user_nick_name',
        value: '닉네임',
    },
    {
        key: 'user_phone_no',
        value: '회원연락처',
    },
    {
        key: 'user_profile_url',
        value: '회원 대표사진',
    },
    {
        key: 'crew_delete_at',
        value: '참여상태',
    },
    {
        key: 'user_contents_count',
        value: '게시글 수',
    },
    {
        key: 'user_comment_count',
        value: '댓글 수',
    },
    {
        key: 'market_info_agree',
        value: '마케팅 동의',
    },
    {
        key: 'alarm_agree_yn',
        value: '알림 동의',
    },
];
