import React, { useEffect, useState } from 'react';
import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainButton } from '../../designComponents/buttons/Buttons';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import DesignTable from '../../designComponents/tables/DesignTable';
import { checkReadFilter, contentFilter, dateFilter, moneyFilter, moneyUpAndDown, sexFilter } from '../../utils/textFilter';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import { MText } from '../../designComponents/text/ContentText';
import { ImageFlexWrap } from '../../designComponents/imageboxs/Image';
import { FlexTwoTextLists } from '../../designComponents/text/TextMapping';
import { InputLine } from '../../designComponents/inputs/InputLine';
import RequestMain from '../../communication_system/axios_apis/refresh_check/requestMain';
import DetailUseSyrupListModal from '../SyrupComponents/DetailUseSyrupListModal';
import { ArrowRightOutlined } from '@ant-design/icons';

/** 시럽 사용 내역 컴포넌트 */
const UsingSyrupLists = ({ userId }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [allCount, setAllCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const [detailToggle, setDetailToggle] = useState([false, 0]);
    const [onCreateSyrup, setOnCreateSyrup] = useState(false);
    const [onLetterLists, setOnLetterLists] = useState(false);

    useEffect(() => {
        if (userId) {
            let input = {
                page: page,
                size: 10,
                searchType: 5,
                searchWord: userId,
            };
            setLoading(true);
            const response = RefreshCheckApi.get('/adminSyrup', { params: input });
            response.then((res) => {
                setData(res.data.lists);
                setAllCount(res.data.all_count);
                setLoading(false);
            });
        } else {
            setPage(1);
            setAllCount(0);
            setData([]);
        }
    }, [userId, page]);

    return (
        <Wrapper03 style={{ marginBottom: 0, flex: 1 }}>
            {onCreateSyrup && <CreatedSyrupModal userId={userId} setToggle={setOnCreateSyrup} />}
            {onLetterLists && <LetterListsModal userId={userId} setToggle={setOnLetterLists} />}
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <MainPageTitle wrapperStyle={{ margin: 0 }} fontStyle={{ fontSize: '17px', fontWeight: 600, color: '#797979' }} text={'시럽 사용 내역'} />
                {userId ? (
                    <FlexMainWrapper>
                        <MainButton styleReverse onClick={() => setOnLetterLists(true)} text={'편지 내역'} />
                        <MainButton onClick={() => setOnCreateSyrup(true)} text={'시럽 지급'} />
                    </FlexMainWrapper>
                ) : null}
            </FlexMainWrapper>
            <DesignTable
                loading={loading}
                marginTop={0.5}
                onToggle={detailToggle[0]}
                modalComponent={<DetailUseSyrupListModal detailToggle={detailToggle} setDetailToggle={setDetailToggle} />}
                header={[
                    { title: '일자', width: 135 },
                    { title: '회원명/닉네임', width: 130 },
                    { title: '사유', width: 35 },
                    { title: '증감/차감', width: 60 },
                    { title: '현재 시럽', width: 55 },
                    { title: '변동 사유', width: 155 },
                ]}
                onClick={(item) => setDetailToggle([true, item.id])}
                body={[
                    { key: 'created_at', width: 135 },
                    { key: 'user_name', width: 130 },
                    { key: 'reason_type', width: 35 },
                    { key: 'sinor_love', width: 60 },
                    { key: 'sinor_love_count', width: 55 },
                    { key: 'reason_detail', width: 155 },
                ]}
                data={data}
                allCount={allCount}
                page={page}
                setPage={setPage}
                bodyRederItem={(key, value, item) => {
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'user_name') return `${value}/${item.user_nick_name}`;
                    if (key === 'sinor_love') return moneyUpAndDown(value);
                    if (key === 'sinor_love_count') return moneyUpAndDown(value);
                    return value;
                }}
                loadingText={'요청하신 시럽 사용 내역을 불러오고 있습니다.'}
                noneText={'시럽 사용 내역이 존재하지 않습니다.'}
                noneHieght={27}
            />
        </Wrapper03>
    );
};

export default UsingSyrupLists;

/** 시럽 지급 모달창 */
const CreatedSyrupModal = ({ userId, setToggle }) => {
    const [data, setData] = useState({});
    const [userImages, setUserImages] = useState([]);
    const [param, setParam] = useState({});
    const [rendering, setRendering] = useState(false);

    useEffect(() => {
        const response = RefreshCheckApi.get(`/adminSyrup/userSyrup?user_id=${userId}`);
        response.then((res) => {
            setData(res.data.data);
            setUserImages(res.data.user_photo);
        });
    }, [userId, rendering]);

    return (
        <DefaultModal
            title={'시럽 지급'}
            boxStyle={{ width: '100vh', height: '70vh' }}
            bottomBtnOn={true}
            closeOn={true}
            saveOn={true}
            saveText={'지급하기'}
            onClickClose={() => setToggle(false)}
            onClickBottomClose={() => setToggle(false)}
            onClickSave={async () => {
                try {
                    const access_token = localStorage.getItem('access_token');
                    if (access_token) {
                        const body = {
                            target_id: data.user_id,
                            sinor_love: param.sinor_love,
                        };
                        const response = await RequestMain.post(`/adminRequest/sinorLove`, body, {
                            headers: { Authorization: `Bearer ${access_token}` },
                        });
                        if (response.status === 200 && response.data.ok) {
                            setRendering((prev) => !prev);
                            setParam({});
                            alert(`${param.sinor_love}개의 시럽 지급 완료`);
                        }
                    }
                } catch (error) {
                    console.error('error: ', error);
                }
            }}
        >
            <FlexMainWrapper style={{ width: '100%', justifyContent: 'center', padding: '2%' }}>
                <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                    <MText text={'지급 대상 정보'} color="#797979" />
                    <ImageFlexWrap
                        objYN={true}
                        keyValue={'url'}
                        imageStyle={{
                            border: '1px solid rgb(227, 227, 227)',
                            borderRadius: '10px',
                            width: '100px',
                            height: '100px',
                        }}
                        image_lists={userImages}
                        imageWidth={80}
                        imageHeight={80}
                        onClickDefault={false}
                        onClick={(src) => {}}
                    />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '보유 시럽',
                                text02: moneyFilter(data?.sinor_love || 0) + '개',
                                text01Style: { color: '#000' },
                                text02Style: { color: 'rgb(66,139,247)' },
                            },
                        ]}
                    />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '회원ID',
                                text02: data?.user_id,
                            },
                            {
                                text01: '회원명',
                                text02: (
                                    <span>
                                        {data?.user_name} / {sexFilter(data?.sex)}
                                    </span>
                                ),
                            },
                            {
                                text01: '회원 닉네임',
                                text02: data?.user_nickname,
                            },
                            {
                                text01: '전화번호',
                                text02: data?.phone_no,
                            },
                            {
                                text01: '나이',
                                text02: data?.age,
                            },
                            {
                                text01: '가입일',
                                text02: dateFilter(data?.user_created_at),
                            },
                            {
                                text01: '최근 접속일',
                                text02: dateFilter(data?.recent_login_datetime),
                            },
                        ]}
                    />
                </FlexMainWrapper>
                <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                    <MText text={'지급 시럽 입력'} color="#797979" />
                    <InputLine
                        title={'지급 시럽'}
                        type="number"
                        wrapperStyle={{ margin: '10px 0' }}
                        titleStyle={{ textAlign: 'start' }}
                        inputStyle={{ height: '22px' }}
                        placeholder={'지급할 시럽의 갯수를 입력하세요'}
                        value={param.sinor_love || ''}
                        onChange={(e) => {
                            let obj = { ...param };
                            obj.sinor_love = parseInt(e.target.value);
                            setParam(obj);
                        }}
                    />
                </FlexMainWrapper>
            </FlexMainWrapper>
        </DefaultModal>
    );
};

/** 편지 사용 내역 모달창 */
const LetterListsModal = ({ userId, setToggle }) => {
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let input = {
            page: page,
            size: 10000,
            searchType: 5,
            searchWord: userId,
        };
        setLoading(true);
        const response = RefreshCheckApi.get(`/adminLetter`, { params: input });
        response.then((res) => {
            setData(res.data.data);
            setLoading(false);
        });
    }, [userId, page]);

    return (
        <DefaultModal
            title={'편지 사용 내역'}
            boxStyle={{ width: '100vh', height: '70vh' }}
            bodyWidth={59}
            bottomBtnOn={true}
            closeOn={true}
            onClickClose={() => setToggle(false)}
            onClickBottomClose={() => setToggle(false)}
        >
            <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <DesignTable
                    loading={loading}
                    marginTop={0.5}
                    onToggle={false}
                    modalComponent={<></>}
                    rowTextStyle={{ borderLeft: '0px' }}
                    header={[
                        { title: '작성일', width: 135 },
                        { title: '편지 내용', width: 300 },
                        { title: '보낸 사람/닉네임/성별', width: 170 },
                        { title: '', width: 10 },
                        { title: '받은 사람/닉네임/성별', width: 170 },
                        { title: '읽음 여부', width: 50 },
                    ]}
                    onClick={(item) => {}}
                    body={[
                        { key: 'created_at', width: 135 },
                        { key: 'content', width: 300 },
                        { key: 'user_name', width: 170 },
                        { key: 'arrow', width: 10 },
                        { key: 'target_user_name', width: 170 },
                        { key: 'read_yn', width: 50 },
                    ]}
                    data={data}
                    page={page}
                    setPage={setPage}
                    bodyRederItem={(key, value, item) => {
                        if (key === 'created_at') return dateFilter(value);
                        if (key === 'content') return contentFilter(value, 30);
                        if (key === 'user_name')
                            return (
                                <span>
                                    {value} / {item.user_nick_name} / {sexFilter(item.user_sex)}
                                </span>
                            );
                        if (key === 'arrow') return <ArrowRightOutlined />;
                        if (key === 'target_user_name')
                            return (
                                <span>
                                    {value} / {item.target_user_nick_name} / {sexFilter(item.target_user_sex)}
                                </span>
                            );
                        if (key === 'read_yn') return checkReadFilter(value);
                        return value;
                    }}
                    loadingText={'요청하신 편지 내역을 불러오고 있습니다.'}
                    noneText={'편지 내역이 존재하지 않습니다.'}
                    noneHieght={27}
                />
            </FlexMainWrapper>
        </DefaultModal>
    );
};
