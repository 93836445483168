import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { InputTitle } from '../../designComponents/inputs/InputLine';
import { TextArea } from '../../designComponents/inputs/TextArea';
import { MainButton } from '../../designComponents/buttons/Buttons';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';
import { SText } from '../../designComponents/text/ContentText';
import { MainSelect } from '../../designComponents/dropdowns/Selectdown';
import { isEmptyObj } from '../../utils/textFilter';
import TypeComponent from '../../components/PushAlarm/TypeComponent';
import React, { useEffect, useRef, useState } from 'react';
import { UserCountNumber } from '../SMS/SendSMS';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

const SendPush = ({ platform, userIdList }) => {
    const [movePages, setMovePages] = useState([]);
    const [topicLists, setTopicLists] = useState([]);

    const [loading, setLoading] = useState(true);
    const [currentParam, setCurrentParam] = useState({});
    const [param, setParam] = useState({ title: '', content: '', params: {}, platform: platform, targetList: userIdList });

    const paramsRef = useRef(null);

    useEffect(() => {
        setParam((prev) => {
            return {
                ...param,
                targetList: userIdList,
                platform: platform,
            };
        });
    }, [userIdList, platform]);

    useEffect(() => {
        setLoading(true);
        const res = RefreshCheckApi.get('/adminPush/movePage');
        res.then((response) => {
            let arr = [];

            response.data.data.forEach((item) => {
                const obj = {
                    name: item.name || '------- 페이지 정보 없음 -------',
                    page: item.page,
                    params: item.params,
                };
                arr.push(obj);
            });
            setMovePages(arr);
        });

        const resTopic = MainRefreshCheckApi.get('/adminRequest/pushAlarm/topic');
        resTopic.then((response) => {
            setTopicLists(response.data.data);
            setLoading(false);
        });
    }, []);

    return (
        <>
            <FlexMainWrapper style={{ alignItems: 'start', marginBottom: '10px', gap: '20px' }}>
                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '65%' }}>
                    <InputTitle
                        exposure={false}
                        placeholder="제목을 입력해주세요."
                        wrapperStyle={{ margin: 0, width: '100%' }}
                        inputStyle={{ width: '100%' }}
                        onChange={(e) => {
                            let obj = { ...param };
                            obj.title = e.target.value;
                            setParam(obj);
                        }}
                    />
                    <TextArea
                        style={{ width: '100%', marginBottom: '5px' }}
                        placeholder={'내용을 입력해 주세요.'}
                        onChange={(e) => {
                            let obj = { ...param };
                            obj.content = e.target.value;
                            setParam(obj);
                        }}
                    />
                    <FlexMainWrapper style={{ justifyContent: 'end', width: '100%' }}>
                        <UserCountNumber count={userIdList?.length || 0}></UserCountNumber>

                        <MainButton
                            text="앱 푸시 전송"
                            width={100}
                            onClick={async () => {
                                try {
                                    const paramsData = paramsRef?.current?.paramsData;
                                    let obj = { ...param };
                                    obj.params = paramsData;

                                    if (window.confirm('푸시 알람을 전송하시겠습니까?')) {
                                        const res = await RefreshCheckApi.post('/adminPush', obj);
                                        if (res.status === 200 && res.data.ok) alert('푸시 알람 전송 성공');
                                    }
                                } catch (error) {
                                    // console.error('error: ', error);

                                    if (error.response.status === 400) {
                                        alert(`알람 전송 싫패! \n사유: ${error?.response?.data?.message || '서버 에러!'}`);
                                    } else {
                                        alert('[Error]서버 관리자에게 문의 바랍니다.');
                                    }
                                }
                            }}
                        />
                    </FlexMainWrapper>
                </FlexMainWrapper>

                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '35%' }}>
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                        <SText text={'이동 페이지'} style={{ width: '100px', fontWeight: 'bold' }} color={'#797979'} />
                        <MainSelect
                            title={'name'}
                            itemKey={'page'}
                            list={[{ name: loading ? '이동 페이지 목록을 불러오는 중입니다...' : '이동 페이지를 선택해주세요' }, ...movePages]}
                            style={{ width: '210px', height: '31px', padding: '0 3px' }}
                            resetThenSet={(target) => {
                                const [params] = movePages.filter((v) => v.page === target.value);
                                const objectKeysArr = Object.keys(params.params);

                                let obj = { ...param };
                                obj.params = {};

                                let currentObj = { ...currentParam };
                                currentObj = {};

                                obj.page = target.value;
                                objectKeysArr.forEach((item) => {
                                    obj.params[item] = params.params[item].label;
                                    currentObj[item] = params.params[item].label;
                                });

                                setParam(obj);
                                setCurrentParam(currentObj);
                            }}
                        />
                    </FlexMainWrapper>
                    {isEmptyObj(param.params) ? <TypeComponent types={param.params} ref={paramsRef} /> : null}
                </FlexMainWrapper>
            </FlexMainWrapper>
        </>
    );
};

export default SendPush;
