export const RecommendListsFilter = [
    {
        key: 'hist_id',
        value: 'ID',
    },
    {
        key: 'created_at',
        value: '추천일',
    },
    {
        key: 'recommend_type',
        value: '추천 사유',
    },
    {
        key: 'user_name',
        value: '회원명/닉네임',
    },
    {
        key: 'user_phone_no',
        value: '회원 전화번호',
    },
    {
        key: 'user_address',
        value: '회원 주소',
    },
    {
        key: null,
        value: null,
    },
    {
        key: 'target_user_name',
        value: '추천 회원/닉네임',
    },
    {
        key: 'target_user_phone_no',
        value: '추천 회원 전화번호',
    },
    {
        key: 'target_address',
        value: '추천 회원 주소',
    },
];
