import styled from 'styled-components';

export const TableWrapper = styled.div`
    width: 98%;

    @media (max-width: 912px) {
        display: block;
    }

    #label {
        display: none;
    }

    table {
        border-collapse: collapse;
        font-size: ${(props) => (props.text ? `11px` : '13px')};

        width: 100%;

        @media (max-width: 912px) {
            font-size: 9px;
            font-weight: 500;
        }
    }

    table > thead > tr > td {
        text-align: start;
        padding: 5px 15px;
    }

    table > .title > tr > th {
        text-align: start;
        padding: 10px 15px;

        border-top: ${(props) => (props.filterToggle ? '1px solid black' : '')};
        border-bottom: 1px solid black;
    }

    table > .body > tr:hover {
        cursor: ${(props) => (props.data?.length === 0 ? null : 'pointer')};
        background-color: ${(props) => (props.data?.length === 0 ? '' : '#f4f4ff')};
    }

    table > .body > tr {
        height: 50px;
    }

    table > .body > tr > td {
        text-align: start;
        padding: 0 15px;

        border-bottom: 1px solid black;
    }

    .user-link:hover {
        color: blue;
        font-weight: 600;
    }

    #choise-interest {
        cursor: pointer;
        width: 65px;
        height: 22px;

        border: 0;

        color: #fff;
        background-color: rgb(66, 139, 247);

        :hover {
            background-color: rgb(5 101 242);
        }
    }
`;

export const MoreKategory = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    user-select: none;

    width: 100%;
    height: 25px;

    border-top: 1px solid black;
    border-bottom: 1px solid black;

    text-align: center;
    font-size: 15px;
    font-weight: 600;

    .icon {
        font-size: 25px;
        font-weight: bold;
        line-height: 10px;
    }

    :hover {
        background-color: #dfdfdf;
    }
`;

export const KategoryWord = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 13px;
    font-weight: 500;
`;

export const Checkbox = styled.input`
    cursor: pointer;
    width: 15px;
    height: 15px;

    @media (max-width: 912px) {
        width: 11px;
        height: 11px;

        margin: 0;
    }
`;

export const ButtonWrapper = styled.div`
    text-align: end;
`;

export const ButtonWrapper02 = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
`;

export const Button = styled.button`
    cursor: pointer;

    width: 60px;
    height: 30px;

    background-color: #428bf7;
    border-radius: 3px;

    color: #fff;
    border: 0;

    margin-bottom: 5px;

    :hover {
        background-color: #1d6ee6;
    }
`;

export const ImageWrapper = styled.div`
    margin-left: 27.3%;
`;

export const ImageBox = styled.div`
    display: flexbox;
    gap: 3px;
`;

export const ImageComponent = styled.div`
    position: relative;
    display: inline-block;

    width: 65px;
    height: 65px;
`;

export const Image = styled.img`
    object-fit: contain;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.height ? props.height : props.sizes}px`};
`;

export const CreateButton = styled.button`
    cursor: pointer;

    width: 60px;
    height: 30px;

    background-color: #fff;
    color: #428bf7;

    border: 1px solid #428bf7;

    :hover {
        background-color: #f1eaea;
    }
`;

export const DeleteButton = styled.button`
    cursor: pointer;

    width: 60px;
    height: 30px;

    background-color: #fff;
    color: rgb(255, 16, 125);

    border: 1px solid rgb(255, 183, 216);

    :hover {
        background-color: #f1eaea;
    }
`;
