import produce from 'immer';
import { handleActions } from 'redux-actions';
import { CHECK_BOX, PUSH_STATE, STRING, VIEW_STATE } from '../../../data_type/data_type';
import {
    GET_ANNOUNCEMENTS_DETAIL_FAILURE,
    GET_ANNOUNCEMENTS_DETAIL_SUCCESS,
    PUT_ANNOUNCEMENTS_DETAIL_FAILURE,
    PUT_ANNOUNCEMENTS_DETAIL_SUCCESS,
} from './announcements_detail/announcements_detail_type';
import {
    DELETE_ANNOUNCEMENTS_FAILURE,
    DELETE_ANNOUNCEMENTS_SUCCESS,
    GET_ANNOUNCEMENTS_FAILURE,
    GET_ANNOUNCEMENTS_SUCCESS,
    POST_ANNOUNCEMENTS_FAILURE,
    POST_ANNOUNCEMENTS_SUCCESS,
    SET_ANNOUNCEMENTS,
} from './announcements_table/announcements_table_type';

const initialState = {
    announcements: null,
    announcementsDetail: null,
};

const announcementsReducer = handleActions(
    {
        [GET_ANNOUNCEMENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.announcements = action.payload;
            }),
        [GET_ANNOUNCEMENTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_ANNOUNCEMENTS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.announcementsDetail = action.payload;
            }),
        [GET_ANNOUNCEMENTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [PUT_ANNOUNCEMENTS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.announcements.length; i++) {
                    if (draft.announcements[i]['id'] === action.payload['announce_id']) {
                        draft.announcements[i]['data'][1]['data'] = action.payload['exposure_yn'];
                        draft.announcements[i]['data'][2]['data'] = action.payload['title'];
                        draft.announcements[i]['data'][3]['data'] = action.payload['push_yn'];
                    }
                }
            }),
        [PUT_ANNOUNCEMENTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [POST_ANNOUNCEMENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.announcements = [
                    {
                        id: action.payload['announce_id'],
                        data: [
                            {
                                type: CHECK_BOX,
                                data: 0,
                            },
                            {
                                type: VIEW_STATE,
                                data: action.payload['exposure_yn'],
                            },
                            {
                                type: STRING,
                                data: action.payload['title'],
                            },
                            {
                                type: PUSH_STATE,
                                data: action.payload['push_yn'],
                            },
                        ],
                    },
                    ...draft.announcements,
                ];
            }),
        [POST_ANNOUNCEMENTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [DELETE_ANNOUNCEMENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                let tempList = [];
                for (let i = 0; i < draft.announcements.length; i++) {
                    if (draft.announcements[i]['data'][0]['type'] === CHECK_BOX) {
                        if (parseInt(draft.announcements[i]['data'][0].data) !== 1) {
                            tempList.push(draft.announcements[i]);
                        }
                    }
                }
                draft.announcements = tempList;
            }),
        [DELETE_ANNOUNCEMENTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [SET_ANNOUNCEMENTS]: (state, action) =>
            produce(state, (draft) => {
                draft.announcements = action.payload.announcements;
            }),
    },
    initialState,
);

export default announcementsReducer;
