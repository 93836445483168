import { useEffect, useState } from 'react';
import styled from 'styled-components';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { exampleData2 } from '../../utils/mockData';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { isEmptyObj } from '../../utils/textFilter';
import { MText } from '../../designComponents/text/ContentText';

const UserChart = ({ title = '비율 차트', data = [], name = 'name', y = 'y' }) => {
    const [options, setOptions] = useState(exampleData2);

    useEffect(() => {
        let obj = { ...exampleData2 };
        obj.title = { text: title };
        obj.series = [{ name: '총 인원', colorByPoint: true, data: data }];
        obj.credits = { enabled: false };
        setOptions(obj);
    }, [data, title]);

    return (
        <ChartWrapper>
            <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', width: '400px' }}>
                {isEmptyObj(options) ? (
                    <HighchartsReact highcharts={Highcharts} options={options} />
                ) : (
                    <MText text={'기간 내 통계 수치가 존재하지 않습니다.'} color="#797979" style={{ margin: '10vh 0' }} />
                )}
            </FlexMainWrapper>
        </ChartWrapper>
    );
};

export default UserChart;

const ChartWrapper = styled.div`
    .highcharts-container,
    .highcharts-root,
    .highcharts-plot-border,
    .sc-kImNAt.cJCsai {
        // TODO: if you want to custom styled in write here
    }
    .highcharts-background {
        width: 0;
    }
    .sc-kImNAt.cJCsai {
        width: 30vh;
    }
    .highcharts-credits {
        display: none;
    }
`;
