import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 신고 리스트 불러오기
export const GET_REPORTS_REQUEST = 'GET_REPORTS_REQUEST';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
// 모임신고 리스트 불러오기
export const GET_GATHERING_REPORTS_REQUEST = 'GET_GATHERING_REPORTS_REQUEST';
export const GET_GATHERING_REPORTS_SUCCESS = 'GET_GATHERING_REPORTS_SUCCESS';
export const GET_GATHERING_REPORTS_FAILURE = 'GET_GATHERING_REPORTS_FAILURE';
// 게시글 상세정보
export const GET_CONTENT_DETAIL_REQUEST = 'GET_CONTENT_DETAIL_REQUEST';
export const GET_CONTENT_DETAIL_SUCCESS = 'GET_CONTENT_DETAIL_SUCCESS';
export const GET_CONTENT_DETAIL_FAILURE = 'GET_CONTENT_DETAIL_FAILURE';
// 댓글 리스트 가져오기
export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';
// 대댓글 리스트 가져오기
export const GET_COMMENTS_RETRY_REQUEST = 'GET_COMMENTS_RETRY_REQUEST';
export const GET_COMMENTS_RETRY_SUCCESS = 'GET_COMMENTS_RETRY_SUCCESS';
export const GET_COMMENTS_RETRY_FAILURE = 'GET_COMMENTS_RETRY_FAILURE';
// 모임 참가원 조회
export const RESET_REQUEST = 'RESET_REQUEST';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    reports: [], // 신고 리스트
    gatheringReports: [], // 모임신고 리스트
    content: null, // 게시글 상세 데이터
    comments: [], // 댓글
    // 신고
    getReportsLoading: false,
    getReportsDone: false,
    getReportsError: null,
    // 모임신고
    getGatheringReportsLoading: false,
    getGatheringReportsDone: false,
    getGatheringReportsError: null,
    // 게시글 상세
    getContentDetailLoading: false,
    getContentDetailDone: false,
    getContentDetailError: null,
    // 댓글
    getCommentsLoading: false,
    getCommentsDone: false,
    getCommentsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 신고 리스트 불러오기
export const requestGetReports = (input) => async (dispatch) => {
    dispatch({
        type: GET_REPORTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminReport`, { params: input });

        dispatch({
            type: GET_REPORTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_REPORTS_FAILURE,
            error: error,
        });
    }
};

// 모임신고 리스트 불러오기
export const requestGetGatheringReports = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_REPORTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminReport/gathering`, { params: input });

        dispatch({
            type: GET_GATHERING_REPORTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_REPORTS_FAILURE,
            error: error,
        });
    }
};

// 어드민 메모 기록(신고)
export const updateAdminMemo = (report_id, admin_memo) => async (dispatch) => {
    // eslint-disable-next-line no-unused-vars
    const response = await RefreshCheckApi.put(`/adminReport/adminMemo`, {
        report_id: report_id,
        admin_memo: admin_memo,
    });
};

// 어드민 메모 기록(모임신고)
export const updateGatheringAdminMemo = (report_id, admin_memo) => async (dispatch) => {
    // eslint-disable-next-line no-unused-vars
    const response = await RefreshCheckApi.put(`/adminReport/adminMemo/gathering`, {
        report_id: report_id,
        admin_memo: admin_memo,
    });
};

// 게시글 상세정보 불러오기
export const getContentDetail = (id) => async (dispatch) => {
    dispatch({
        type: GET_CONTENT_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminReport/content/detail?id=${id}`);
        const commentsResponse = await RefreshCheckApi.get(`/adminGathering/comments?contents_id=${id}`);

        dispatch({
            type: GET_CONTENT_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CONTENT_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 댓글 리스트 가져오기
export const getCommentsLists = (id) => async (dispatch) => {
    dispatch({
        type: GET_COMMENTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/comments?contents_id=${id}`);

        dispatch({
            type: GET_COMMENTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_COMMENTS_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const reportsReducer = handleActions(
    {
        // 신고 리스트 불러오기
        [GET_REPORTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getReportsLoading = true;
                draft.getReportsDone = false;
                draft.getReportsError = null;
            }),
        [GET_REPORTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getReportsLoading = action.payload ? false : true;
                draft.reports = action.payload;
                draft.getReportsDone = action.payload ? true : false;
            }),
        [GET_REPORTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getReportsLoading = false;
                draft.getReportsError = action.error;
            }),

        // 모임신고 리스트 불러오기
        [GET_GATHERING_REPORTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringReportsLoading = true;
                draft.getGatheringReportsDone = false;
                draft.getGatheringReportsError = null;
            }),
        [GET_GATHERING_REPORTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringReportsLoading = action.payload ? false : true;
                draft.gatheringReports = action.payload;
                draft.getGatheringReportsDone = action.payload ? true : false;
            }),
        [GET_GATHERING_REPORTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringReportsLoading = false;
                draft.getGatheringReportsError = action.error;
            }),

        // 게시글 상세정보 불러오기
        [GET_CONTENT_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getContentDetailLoading = true;
                draft.getContentDetailDone = false;
                draft.getContentDetailError = null;
            }),
        [GET_CONTENT_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getContentDetailLoading = action.payload ? false : true;
                draft.content = action.payload[0];
                draft.getContentDetailDone = action.payload ? true : false;
            }),
        [GET_CONTENT_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getContentDetailLoading = false;
                draft.getContentDetailError = action.error;
            }),

        // 댓글 리스트 가져오기
        [GET_COMMENTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getCommentsLoading = true;
                draft.getCommentsDone = false;
                draft.getCommentsError = null;
            }),
        [GET_COMMENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getCommentsLoading = action.payload ? false : true;
                draft.comments = action.payload;
                draft.getCommentsDone = action.payload ? true : false;
            }),
        [GET_COMMENTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getCommentsLoading = false;
                draft.getCommentsError = action.error;
            }),

        // 상태 초기화
        [RESET_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                // 신고
                draft.getReportsLoading = false;
                draft.getReportsDone = false;
                draft.getReportsError = null;
                // 모임신고
                draft.getGatheringReportsLoading = false;
                draft.getGatheringReportsDone = false;
                draft.getGatheringReportsError = null;
            }),
    },
    initialState,
);

export default reportsReducer;
