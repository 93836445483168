export const GET_EVENTS = 'admin/GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'admin/GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILURE = 'admin/GET_EVENTS_FAILURE';
export const POST_EVENTS = 'admin/POST_EVENTS';
export const POST_EVENTS_SUCCESS = 'admin/POST_EVENTS_SUCCESS';
export const POST_EVENTS_FAILURE = 'admin/POST_EVENTS_FAILURE';
export const CHANGE_EVENT_ORDER = 'CHANGE_EVENT_ORDER';
export const DELETE_EVENTS = 'admin/DELETE_EVENTS';
export const DELETE_EVENTS_SUCCESS = 'admin/DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_FAILURE = 'admin/DELETE_EVENTS_FAILURE';
export const CHANGE_EVENT_USER_PROFILE_ORDER = 'CHANGE_EVENT_USER_PROFILE_ORDER';
