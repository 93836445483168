/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';

// axios
import axios from 'axios';

// react-query
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// BANK_CODE
import { BANK_CODE } from '../../SubscribePayment/payBankCodeService';

// custom-hook
import useInput from '../../../hook/useInput';

// components
import LoadingPage from '../../../pages/loading/LoadingPage';

// 결제회원 상세정보 Modal
function SchedulePayUserDetailModal({ id, setDetailToggle }) {
    const { isLoading, error, data, refetch } = useQuery('gathering_pay_detail', async () => {
        return await RefreshCheckApi.get(`/adminGathering/account/pay/detail?gathering_pay_id=${id}`).then((res) => {
            return res.data.data[0];
        });
    });

    // state-toggle
    const [updateRefundInfoToggle, setUpdateRefundInfoToggle] = useState(false);

    // state
    const [refundLoading, setRefundLoading] = useState(false); // 환불로딩
    const [refundMessag, setRefundMessage] = useState(''); // 환불성공
    const [refundFail, setRefundFail] = useState(false); // 환불실패

    const [accessToken, setAccessToken] = useState('');

    const [bank, setBank] = useState(''); // 은행
    const [bankName, setBankName] = useState(''); // 은행명
    const [accountHolder, onChangeAccountHolder, setAccountHolder] = useInput(''); // 예금주
    const [accountNumber, onChangeAccountNumber, setAccountNumber] = useInput(''); // 계좌번호
    const [refundAccount, onChangeRefundAccount, setRefundAccount] = useInput(''); // 환불금액

    /******************************* useEffect *******************************/

    // 환불결과
    useEffect(() => {
        if (refundMessag) {
            alert(refundMessag);
        }
    }, [refundMessag]);

    // 환불정보 적용
    useEffect(() => {
        if (updateRefundInfoToggle && !data?.refund_bank) {
            setBank('039');
            setBankName('경남은행');
        } else if (!updateRefundInfoToggle) {
            setBank('');
            setBankName('');
        }

        // 은행코드
        BANK_CODE.forEach((item) => {
            if (String(data?.refund_bank) === String(item.KOR)) {
                setBank(item.CODE1);
                setBankName(item.KOR);
            }
        });

        setAccountNumber(data?.refund_account); // 계좌번호
        setAccountHolder(data?.refund_holder); // 예금주
        setRefundAccount(data?.refund_amount); // 환불 예정 금액
    }, [data, updateRefundInfoToggle, setBank, setBankName, setAccountHolder, setAccountNumber, setRefundAccount]);

    // 환불실패
    useEffect(() => {
        if (refundFail) {
            alert(refundFail);
            setRefundFail(false);
        }
    }, [refundFail]);

    /******************************* function *******************************/

    // 환불처리
    const onClickRefund = (pay_id) => () => {
        const response = RefreshCheckApi.get(`/adminSubscribe/${pay_id}/cancel`);

        response
            .then((res) => {
                alert(`${res.data.message}`);
                refetch();
            })
            .catch((err) => {
                console.error(err);
                alert(`[${err.response.status}] ${err.response.data.message}`);
            });
    };

    // 환불신청하기
    const onClickRefundCheck = (pay_id) => () => {
        if (pay_id) {
            let url = 'https://senor.co.kr/userPayInfo/refund/check';
            // let url = 'https://test.senor.co.kr/userPayInfo/refund/check';
            // let url = 'https://4b03-14-36-59-115.ngrok-free.app/userPayInfo/refund/check';

            let param = {
                pay_id: pay_id,
                apply_yn: true,
                refund_remark: '관리자 취소',
            };
            if (!accessToken) {
                return alert('인증토큰 발급 후 환불요청이 가능합니다.');
            }

            if (data.pay_type_number === 0) {
                // 카드결제의 경우
                const response = axios.post(url, param, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                response
                    .then((res) => {
                        if (res.status === 200) {
                            alert('환불신청이 완료되었습니다.');
                        }
                    })
                    .catch((err) => {
                        alert(err.response.data.message);
                    });
            } else if (data.pay_type_number === 1) {
                // 가상계좌의 경우
                param = {
                    pay_id: pay_id,
                    apply_yn: true,
                    refund_remark: '관리자 취소',
                    account_info: {
                        account_num: accountNumber, // 계좌번호
                        name: accountHolder, // 예금주
                        bank_num: String(bank), // 은행
                    },
                };
                const response = axios.post(url, param, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                response
                    .then((res) => {
                        alert('환불신청이 완료되었습니다.');
                    })
                    .catch((err) => {
                        console.error(err);
                        alert(err.response.data.message);
                    });
            }
        } else {
            alert('환불신청에 대한 정보가 없습니다.');
        }
    };

    // 인증토큰 발급 받기
    const onClickGetToken = () => {
        let url = 'https://senor.co.kr/auth/testLogin';
        // let url = 'https://test.senor.co.kr/auth/testLogin';

        const response = axios.post(url, {
            id: data.user_id,
        });

        response.then((res) => {
            let token = res.data.data.access_token;
            setAccessToken(token);
        });
    };

    useEffect(() => {
        if (data?.pay_status_number === 2) {
            let url = 'https://senor.co.kr/auth/testLogin';
            // let url = 'https://test.senor.co.kr/auth/testLogin';
            const response = axios.post(url, {
                id: data.user_id,
            });
            response.then((res) => {
                let token = res.data.data.access_token;
                setAccessToken(token);
            });
        }
    }, [data]);

    // 은행선택
    const onChangeBank = (e) => {
        setBank(parseInt(e.target.value));

        BANK_CODE.forEach((item) => {
            if (String(e.target.value) === String(item.CODE1)) {
                setBankName(item.KOR);
            }
        });
    };

    // 환불정보 입력 토글
    const onClickUpdateUserRefundInfo = () => {
        setUpdateRefundInfoToggle((prev) => !prev);
    };

    // 환불정보 저장
    const onClickSaveRefundInfo = () => {
        setUpdateRefundInfoToggle((prev) => !prev);
        if (!data?.pay_id) {
            alert('결제 정보가 존재하지 않습니다.');
        }

        if (!bankName || !accountHolder || !accountNumber || !refundAccount) {
            alert('입력되지 않은 값이 있습니다.');
        } else {
            const response = RefreshCheckApi.post('/adminGathering/account/refund_info', {
                user_id: data?.user_id,
                bank_name: bankName,
                account_holder: accountHolder,
                account_number: accountNumber,
                refund_amount: refundAccount,
                amount: data?.amount,
                pay_amount: data?.amount,
                pay_id: data?.pay_id,
            });

            response
                .then((res) => {
                    refetch();
                })
                .catch((err) => {
                    console.error(err);
                    alert(err.response.message);
                });
        }
    };

    // 회원 ID 정보
    const onClickUserIdInfo = (user_id) => () => {
        console.debug('user_id: ', user_id);
    };

    /********************************* html *********************************/
    if (isLoading || refundLoading) {
        return (
            <SchedulePayUserDetailModalWrapper>
                <SchedulePayUserDetailModalBox>
                    <div className="page-wrapper" style={{ margin: '5% 0' }}>
                        <LoadingPage text={'결제정보 데이터를 불러오는 중입니다.'} />
                    </div>
                </SchedulePayUserDetailModalBox>
            </SchedulePayUserDetailModalWrapper>
        );
    } else if (error) {
        return (
            <SchedulePayUserDetailModalWrapper>
                <SchedulePayUserDetailModalBox>
                    <div className="page-wrapper" style={{ margin: '5% 0' }}>
                        <LoadingPage text={error} />
                    </div>
                </SchedulePayUserDetailModalBox>
            </SchedulePayUserDetailModalWrapper>
        );
    }
    return (
        <SchedulePayUserDetailModalWrapper>
            <SchedulePayUserDetailModalBox>
                <Header>
                    <Text>상세정보</Text>
                    <Text style={{ cursor: 'pointer' }} onClick={() => setDetailToggle([false, 0])}>
                        닫기
                    </Text>
                </Header>

                <UserName className="user-name" onClick={onClickUserIdInfo(data.user_id)}>
                    {data?.user_name || <span style={{ color: 'gray' }}>정보없음</span>}
                </UserName>

                <ModalBoxBody className="modal-body">
                    <label id="label">결제 정보</label>
                    <InfoBox className="subscribe-info">
                        <InfoTitle>결제 정보</InfoTitle>

                        <ColWrapper>
                            <Subtitle>결제 ID</Subtitle>
                            <SubInfo>{data?.pay_id || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>결제 상태</Subtitle>
                            <SubInfo>{data?.pay_status || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>결제 일시</Subtitle>
                            <SubInfo>{data?.pay_created_at || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>결제 수단</Subtitle>
                            <SubInfo>{data?.pay_type || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>결제 상품</Subtitle>
                            <SubInfo>{data?.pay_name || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>포인트 사용</Subtitle>
                            <SubInfo style={{ display: 'flex', alignItems: 'start', gap: '3px' }}>
                                <span>{data?.use_point_amount || 0}</span>
                                <span style={{ color: 'gray' }}>포인트</span>
                            </SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>쿠폰 사용</Subtitle>
                            <SubInfo>{data?.coupon || '없음'}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>실 결제 금액</Subtitle>
                            <SubInfo>{data?.amount ? data?.amount?.toLocaleString('ko-KR') : 0}원</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>포인트 적립</Subtitle>
                            <SubInfo style={{ display: 'flex', alignItems: 'start', gap: '3px' }}>
                                <span>{data?.point_accumulate || 0}</span>
                                <span style={{ color: 'gray' }}>포인트</span>
                            </SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>참여 모임</Subtitle>
                            <SubInfo>{data?.gathering_name || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                        </ColWrapper>
                    </InfoBox>

                    <label id="label">환불 정보</label>
                    <InfoBox className="refund-info" leftLine={true}>
                        <InfoTitle>환불 정보</InfoTitle>
                        <ColWrapper>
                            <Subtitle>인증토큰 발급</Subtitle>
                            <SubInfo>
                                <Button onClick={onClickGetToken} disabled={Boolean(accessToken)} token={Boolean(accessToken)}>
                                    {accessToken ? '토큰발급완료' : '토큰발급'}
                                </Button>
                            </SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>환불 요청일시</Subtitle>
                            <SubInfo>{data?.refund_date ? moment(data?.refund_date).format('YYYY-YY-MM HH:mm:ss') : <span style={{ color: 'gray' }}>내용 없음</span>}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>환불 예정금액</Subtitle>

                            {updateRefundInfoToggle ? (
                                <Input type="number" placeholder="환불 금액을 입력하세요" value={refundAccount} onChange={onChangeRefundAccount} />
                            ) : (
                                <SubInfo>{data?.refund_amount ? data?.refund_amount?.toLocaleString('ko-KR') : <span style={{ color: 'gray' }}>내용 없음</span>}</SubInfo>
                            )}
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>은행 CODE</Subtitle>
                            <SubInfo>{bank || <span style={{ color: 'gray' }}>내용 없음</span>}</SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>환불 은행</Subtitle>
                            <SubInfo>
                                {updateRefundInfoToggle ? (
                                    <Select onChange={onChangeBank}>
                                        {BANK_CODE.map((obj, index) => (
                                            <option key={index} value={obj.CODE1}>
                                                {obj.KOR}
                                            </option>
                                        ))}
                                    </Select>
                                ) : (
                                    <>{bankName ? bankName : <span style={{ color: 'gray' }}>내용 없음</span>}</>
                                )}
                            </SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>환불 계좌번호</Subtitle>
                            <SubInfo>
                                {updateRefundInfoToggle ? (
                                    <Input type="number" placeholder='계좌번호를 "-" 없이 입력' onChange={onChangeAccountNumber} />
                                ) : (
                                    <>{accountNumber ? accountNumber : <span style={{ color: 'gray' }}>내용 없음</span>}</>
                                )}
                            </SubInfo>
                        </ColWrapper>

                        <ColWrapper>
                            <Subtitle>환불 예금주</Subtitle>
                            <SubInfo>
                                {updateRefundInfoToggle ? (
                                    <Input type="text" placeholder="예금주명" onChange={onChangeAccountHolder} />
                                ) : (
                                    <>{accountHolder ? accountHolder : <span style={{ color: 'gray' }}>내용 없음</span>}</>
                                )}
                            </SubInfo>
                        </ColWrapper>
                    </InfoBox>
                </ModalBoxBody>

                <Footer>
                    {/* {data?.pay_status_number === 4 ? '닫기' : '취소'} */}
                    <CancelButton onClick={() => setDetailToggle([false, 0])}>닫기</CancelButton>

                    {data.refund_account || data.refund_account || data.pay_type_number === 0 ? (
                        <>
                            {data?.pay_status_number === 3 ? (
                                <>{data?.pay_status_number === 4 ? null : <RefundButton onClick={onClickRefund(data?.pay_id ? data?.pay_id : null)}>환불처리</RefundButton>}</>
                            ) : (
                                <>{data?.pay_status_number === 4 ? null : <RefundButton onClick={onClickRefundCheck(data?.pay_id ? data?.pay_id : null)}>환불신청</RefundButton>}</>
                            )}
                        </>
                    ) : (
                        <RefundButton onClick={updateRefundInfoToggle ? onClickSaveRefundInfo : onClickUpdateUserRefundInfo}>{updateRefundInfoToggle ? '환불정보 저장' : '환불정보 입력'}</RefundButton>
                    )}
                </Footer>
            </SchedulePayUserDetailModalBox>
        </SchedulePayUserDetailModalWrapper>
    );
}

export default SchedulePayUserDetailModal;

/********************************* styled *********************************/

export const Header = styled.div`
    z-index: 999;
    display: flex;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    height: 5vh;

    align-items: center;

    @media (max-width: 1120px) {
        width: 100%;
    }
`;

export const Text = styled.div`
    padding: 0 15px;

    color: #fff;
    font-size: 16px;
    font-weight: 600;

    line-height: 45px;
`;

export const SchedulePayUserDetailModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: #000000b0;
`;

export const SchedulePayUserDetailModalBox = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 120vh;
    max-width: 98%;
    height: 78vh;

    border-radius: 10px;
    background-color: #fff;

    @media (max-width: 912px) {
        width: 98%;
        height: 95%;

        overflow: auto;
    }

    .close-window {
        padding: 5px 25px;
        text-align: start;
        font-size: 20px;
        color: #428bf7;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        padding: 15px;
        color: gray;
    }
`;

export const UserName = styled.span`
    cursor: pointer;
    user-select: none;

    width: 200px;
    border: 1px solid rgba(66, 139, 247, 0.2);

    text-align: start;
    font-size: 15px;
    font-weight: 600;

    margin: 20px 30px;
    padding: 3px 50px 3px 7px;

    @media (max-width: 912px) {
        margin-top: 15%;
    }
`;

export const ModalBoxBody = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding: 0px 30px;

    #label {
        display: none;
    }

    @media (max-width: 912px) {
        flex-direction: column;

        .refund-info {
            margin-top: 7%;
        }
    }
`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    width: 100%;
    height: 59vh;

    border-left: ${(props) => (props.leftLine ? '1px solid gray' : '')};
    padding-left: ${(props) => (props.leftLine ? '2%' : '')};

    @media (max-width: 912px) {
        border-left: none;
        height: auto;
    }
`;

export const InfoTitle = styled.h2`
    margin: 0 0 15px 0;

    font-size: 17px;
    font-weight: 600;
`;

export const ColWrapper = styled.div`
    display: flex;
    align-items: start;

    margin-bottom: 3%;
`;

export const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 90px;
`;

export const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;

    width: 98.5%;

    @media (max-width: 912px) {
        justify-content: center;

        margin: 5% 0;
    }
`;

export const CancelButton = styled.button`
    cursor: pointer;

    width: 100px;
    height: 33px;

    margin: 1.5% 0;

    border: 1px solid rgb(229, 229, 229);
    border-radius: 3px;

    background-color: #fff;
    color: #000;

    :hover {
        background-color: #f6f6f6;
    }

    :active {
        background-color: #fff;
    }
`;

export const RefundButton = styled.button`
    cursor: pointer;

    width: 100px;
    height: 33px;

    margin: 1.5% 0;

    border: 0;
    border-radius: 3px;

    background-color: rgb(255, 16, 125);
    color: #fff;

    :hover {
        background-color: rgb(233 4 108);
    }

    :active {
        background-color: rgb(255, 16, 125);
    }
`;

const Select = styled.select`
    cursor: pointer;
    width: 120px;
    height: 23px;

    outline: none;
    border: 1px solid rgb(202 202 202);

    font-size: 13.5px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const Button = styled.button`
    cursor: pointer;
    border: 0;
    border-radius: 3px;
    background-color: #fff;
    color: #fff;

    border: ${(props) => (props.token ? '#cccccc' : '1px solid rgb(255, 16, 125)')};
    background-color: ${(props) => (props.token ? '#cccccc' : 'rgb(255, 16, 125)')};

    width: 100px;
    height: 25px;

    :hover {
        background-color: ${(props) => (props.token ? '#cccccc' : 'rgb(255, 16, 125)')};
    }
`;
