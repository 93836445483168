import { useCallback, useEffect, useRef, useState } from 'react';
import { DefaultModal, ImageModal } from '../../designComponents/modals/ModalComponent';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { LText, SText } from '../../designComponents/text/ContentText';
import { InputWithTitle } from '../../designComponents/inputs/InputLine';
import { Image, makeImageUrl } from '../../designComponents/imageboxs/Image';
import { CheckboxList } from '../../designComponents/checkboxs/CheckboxInput';
import { dateFilter, interestWordFilter, noneImage } from '../../utils/textFilter';
import { Slider } from 'antd';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import { CategorySelectLine, SelectLine } from '../../designComponents/dropdowns/Selectdown';
import { TextArea } from '../../designComponents/inputs/TextArea';
import AddGatheringChooseInterestModal from '../../designComponents/modals/AddGatheringChooseInterestModal';
import PhotoHistoryModal from './PhotoHistoryModal';
import CheckUserGatheringModal from './CheckUserGatheringModal';
import axios from 'axios';
import { DownloadOutlined } from '@ant-design/icons';
import { marketting_main_server_ip } from '../../communication_system/communication_ips';
import { MainButton } from '../../designComponents/buttons/Buttons';
import HorizontalImageLists from '../../designComponents/BeautifulDND/HorizontalImageLists';

const UserDetailModal = ({ userId, setToggle, updateLists = () => {}, sinorYn = false }) => {
    const [data, setData] = useState({});
    const [originData, setOriginData] = useState({});

    const [addressLists, setAddressLists] = useState([]);
    const [commonCode, setCommonCode] = useState([]);
    const [categoryParams, setCategoryParams] = useState({});

    const [imageToggle, setImageToggle] = useState({});
    const [interestToggle, setInterestToggle] = useState({ lists: [] });
    const [photoHistoryToggle, setPhotoHistoryToggle] = useState({});
    const [userGatheringToggle, setUserGatheringToggle] = useState({});
    const [rendering, setRendering] = useState(false);

    const imgRef = useRef(null);
    const modalRef = useRef(null);

    /** 카테고리 코드 가져오기 */
    useEffect(() => {
        const response = RefreshCheckApi.get('/adminUser/commonCode');
        response.then((res) => setCommonCode(res.data.data));
    }, [rendering]);

    /** 기본 회원 상세 데이터 */
    useEffect(() => {
        const res = RefreshCheckApi.get(`/adminUser/${userId}?sinorYn=${sinorYn}`);
        res.then((response) => {
            setData(response.data.data);
            setOriginData(response.data.data);
        }).catch((error) => {
            console.error(error);
        });
    }, [userId, rendering]);

    /** 주소 목록 가져오기 */
    useEffect(() => {
        const response = RefreshCheckApi.get('/adminUser/address');
        response.then((res) => setAddressLists([...res?.data?.all])).catch((err) => console.error(err));
    }, []);

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle({});
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = async (e) => {
        try {
            const imageFormData = new FormData();
            [].forEach.call(e.target.files, (f) => imageFormData.append('image', f));
            const response = await RefreshCheckApi.post('/adminGathering/contents/images', imageFormData);
            if (response.status === 200) {
                if (data.profile_list?.length && data.profile_list) {
                    setTimeout(() => {
                        let obj = { ...data };
                        if (obj.profile_list[0] === 'userPhoto/default.jpg') {
                            obj.profile_list = [...response.data];
                        } else {
                            obj.profile_list = [...data.profile_list, ...response.data];
                        }
                        setData(obj);
                    }, [1000]);
                } else {
                    let obj = { ...data };
                    obj.profile_list = [...response.data];
                    setData(obj);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const filterCommonCode = (CODE_GROUP) => {
            if (commonCode.length) {
                const codeGroup = commonCode.filter((data) => data.code_group === CODE_GROUP);
                codeGroup.push({
                    id: null,
                    code: null,
                    code_name: '미선택',
                });

                return codeGroup.map((data) => {
                    return {
                        id: data.code,
                        code: data.code,
                        code_name: data.code_name,
                    };
                });
            }
        };

        const params = {
            religion: filterCommonCode('RELIGION_CODE'),
            drink: filterCommonCode('DRINK_CODE'),
            single: filterCommonCode('SINGLE_CODE'),
            job: filterCommonCode('JOB_CODE'),
            pick_religion: { code: data.religion },
            pick_drink: { code: data.drink },
            pick_single: { code: data.single },
            pick_job: { code: data.job },
        };

        setCategoryParams(params);
    }, [data, commonCode]);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                const onClickEventCondition = !imageToggle.toggle && !photoHistoryToggle.toggle && !interestToggle.toggle && !userGatheringToggle.toggle;
                if (onClickEventCondition) {
                    setToggle({});
                } else {
                    setInterestToggle({});
                    setPhotoHistoryToggle({});
                    setUserGatheringToggle({});
                    setImageToggle({});
                }
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
    }, [imageToggle, photoHistoryToggle, interestToggle, userGatheringToggle, setToggle]);

    const handleClickModalOutside = (event) => {
        const onClickEventCondition = !imageToggle.toggle && !photoHistoryToggle.toggle && !interestToggle.toggle && !userGatheringToggle.toggle;
        if (onClickEventCondition) {
            if (!modalRef.current) return;
            else if (modalRef && !modalRef.current.contains(event.target)) {
                setToggle({});
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickModalOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickModalOutside);
        };
    });

    const onClickSave = async () => {
        const body = {
            id: data?.id,
            name: data?.name,
            nick_name: data?.nick_name,
            sinor_love_user_nick_name: data?.sinor_love_user_nick_name,
            sinor_user_nick_name: data?.sinor_user_nick_name,
            birthday: data?.birthday,
            sex: parseInt(data?.sex),
            max_age: data?.max_age,
            min_age: data?.min_age,
            drink: data?.drink,
            job: data?.job,
            religion: data?.religion,
            single_reason: data?.single,
            phone_no: data?.phone_no,
            recommend_yn: parseInt(data?.recommend_yn),
            profile_photo_auth_yn: data?.profile_auth_yn,
            self_note: data?.self_note,
            admin_memo: data?.admin_memo,
            blocked_yn: data?.blocked_yn,
            image_list: data?.profile_list,
            address: data?.address,
            latitude: data?.latitude,
            longitude: data?.longitude,
            marketing_info_receive_agree_yn: data?.market_info_agree,
            alarm_agree_yn: data?.alarm_agree_yn,
            interest_code: data?.interest_code,
        };
        try {
            const updateRes = await RefreshCheckApi.put(`/adminUser/${data?.id}`, body);
            if (updateRes.status === 200) {
                setImageToggle({ ...imageToggle, update: false });
                alert('회원정보 수정이 완료 되었습니다.');
                updateLists();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onClickWithDrawal = async () => {
        try {
            const withdrawal = prompt('탈퇴 사유를 입력해주세요. 미입력 시 탈퇴는 진행되지 않습니다.');
            if (!withdrawal) {
                return;
            }

            const platformAnswer = prompt('탈퇴시킬 앱을 적어주세요 시놀/시럽 (미 입력시: 시럽)');
            let platform = 0;
            if (platformAnswer === '시놀') {
                platform = 2;
            }

            if (window.confirm('탈퇴를 진행하시겠습니까?')) {
                const deleteUrl = `${marketting_main_server_ip}withdrawUser`;

                const tokenResponse = await RefreshCheckApi.post('/auth/testLogin', { id: data?.id });
                const userToken = tokenResponse.data.data.access_token;
                const body = { withdrawal_reason: 5, remark: withdrawal };
                const header = { headers: { Authorization: `Bearer ${userToken}`, platform: platform === 2 ? 'gathering' : '' } };

                const response = await axios.post(deleteUrl, body, header);
                if (response.status === 200 && response.data.ok) {
                    alert('탈퇴가 정상적으로 진행되었습니다.');
                    setRendering((prev) => !prev);
                    updateLists();
                }
            }
        } catch (error) {
            console.error(error);
            alert(error.response.data.message);
        }
    };

    const getItems = (originData) => {
        if (!originData || originData.length <= 0) {
            return [];
        }
        let arr = []; // {id: 'item-0', content: 'item 0'}
        originData.forEach((url, index) => {
            arr.push({ id: `item-${index}`, content: url });
        });
        return arr;
    };

    const imageboxSt = {
        height: data?.profile_list?.length > 4 ? (imageToggle.update ? '47vh' : '30vh') : '16vh',
        border: data?.profile_list?.length > 4 ? '1px solid #c7c7c7' : '0px',
        padding: data?.profile_list?.length > 4 ? '5px 3px' : '0',
        borderRadius: '3px',
        overflow: 'auto',
        width: '50vh',
        flexWrap: 'wrap',
    };

    return (
        <DefaultModal
            title={data?.name ? `${data?.name}님의 상세 정보` : '회원 상세 정보'}
            modalRef={modalRef}
            bodyWidth={70}
            boxStyle={{ height: '78vh' }} // width: '130vh'
            xIcon={true}
            onClickClose={() => setToggle({})}
            closeOn
            onClickBottomClose={() => setToggle({})}
            middleOn={data?.avail_yn === 1}
            middleText={'탈퇴'}
            onClickMiddle={onClickWithDrawal}
            saveOn
            onClickSave={onClickSave}
        >
            {userGatheringToggle.toggle && (
                <CheckUserGatheringModal userId={userGatheringToggle.user_id} type={userGatheringToggle.type} setToggle={setUserGatheringToggle} closeModal={setUserGatheringToggle} />
            )}
            {photoHistoryToggle.toggle && <PhotoHistoryModal userId={photoHistoryToggle.user_id} type={photoHistoryToggle.type} setToggle={setPhotoHistoryToggle} />}
            {imageToggle.toggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle({})}>
                    <Image imgRef={imgRef} url={imageToggle.src} size="S150" width={600} height={600} cover="contain" style={{ cursor: 'auto', borderRadius: '5%', webkitUserDrag: 'none' }} />
                </ImageModal>
            )}
            {interestToggle.toggle && (
                <FlexMainWrapper style={{ textAlign: 'start' }}>
                    <AddGatheringChooseInterestModal
                        boxStyle={{ height: '55vh', borderRadius: '10px' }}
                        kategoryLists={data.interest_code || []}
                        onClickInterests={(_, __, array = []) => {
                            let obj = JSON.parse(JSON.stringify(data));
                            obj.interest_code = array.sort();
                            setData(obj);
                        }}
                        onClickCloseModal={() => {
                            setInterestToggle({});
                        }}
                    />
                </FlexMainWrapper>
            )}
            <FlexMainWrapper style={{ alignItems: 'start', padding: '15px 30px', marginBottom: '7vh' }} mobileOn>
                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start' }} width={50} mobileWidth={100}>
                    <LText text={'기본 정보'} />
                    <CheckboxList
                        title={'가입 앱'}
                        itemLists={[
                            {
                                subject: '모두',
                                checked: parseInt(data?.platform) === 0,
                            },
                            {
                                subject: '시럽',
                                checked: parseInt(data?.platform) === 1,
                            },
                            {
                                subject: '시놀',
                                checked: parseInt(data?.platform) === 2,
                            },
                            {
                                subject: '이용 안함',
                                checked: parseInt(data?.platform) === -1,
                            },
                        ]}
                    />

                    <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '100%', marginTop: '15px' }}>
                        <FlexMainWrapper style={{ alignItems: 'center' }}>
                            <SText text={'프로필 사진'} />
                            <SText
                                text={'...더보기'}
                                color="gray"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setPhotoHistoryToggle({ toggle: true, user_id: data?.id, type: 1 });
                                }}
                            />
                        </FlexMainWrapper>
                        <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                        <FlexMainWrapper style={{ alignItems: 'center', width: '100%', gap: '1px' }}>
                            <InputWithTitle
                                placeholder={'추가할 프로필 이미지를 선택해 주세요'}
                                disabled={true}
                                wrapperStyle={{ width: '66%' }}
                                buttonOn
                                buttonOnWidth={100}
                                btnText="추가"
                                onClick={onClickAddImage}
                            />
                            <MainButton
                                text={imageToggle.update ? '취소' : '이미지 수정'}
                                styleReverse={!imageToggle.update}
                                style={{ borderRadius: 0, height: '31px', width: `${imageToggle.update ? 60 : 80}px`, marginBottom: '5px', backgroundColor: imageToggle.update ? 'red' : '' }}
                                onClick={() => {
                                    if (imageToggle.update) {
                                        if (window.confirm('수정한 이미지는 저장되지 않습니다. 진행하시겠습니까?')) {
                                            let obj = { ...data };
                                            obj.profile_list = originData.profile_list;
                                            setData(obj);
                                            setRendering((prev) => !prev);
                                            setImageToggle({ update: false });
                                        }
                                    } else {
                                        setImageToggle({ update: true });
                                    }
                                }}
                            />
                        </FlexMainWrapper>
                    </FlexMainWrapper>

                    {imageToggle.update ? (
                        <FlexMainWrapper style={imageboxSt}>
                            <HorizontalImageLists images={getItems(data?.profile_list)} originData={data} setData={setData} />
                        </FlexMainWrapper>
                    ) : (
                        <FlexMainWrapper style={imageboxSt}>
                            {data.profile_list?.map((url, index) => (
                                <div key={`${index}/${url}`} style={{ position: 'relative' }}>
                                    <Image
                                        url={url}
                                        width={113}
                                        height={113}
                                        style={{
                                            border: '1px solid #c7c7c7',
                                            borderRadius: '3px',
                                            webkitUserDrag: 'none',
                                        }}
                                        errImg={noneImage(data?.sex)}
                                        onClick={() => {
                                            setImageToggle({ toggle: true, src: url });
                                        }}
                                    />
                                    <a
                                        href={makeImageUrl(url)}
                                        download={true}
                                        style={{
                                            width: 24,
                                            height: 24,
                                            top: 6,
                                            right: 6,
                                            position: 'absolute',
                                            backgroundColor: 'rgba(255,255,255,0.7)',
                                            borderRadius: 12,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <DownloadOutlined color={'#000000'} />
                                    </a>
                                </div>
                            ))}
                        </FlexMainWrapper>
                    )}

                    <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '100%', marginTop: '10px' }}>
                        <FlexMainWrapper style={{ alignItems: 'center' }}>
                            <SText text={'카메라 인증'} />
                            <SText
                                text={'...더보기'}
                                color="gray"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setPhotoHistoryToggle({ toggle: true, user_id: data?.id, type: 2 });
                                }}
                            />
                        </FlexMainWrapper>
                        <Image
                            url={data?.profile_auth_image}
                            width={113}
                            height={113}
                            style={{ border: '1px solid #c7c7c7', borderRadius: '3px', webkitUserDrag: 'none' }}
                            errImg={noneImage(data?.sex)}
                            onClick={() => {
                                setImageToggle({ toggle: true, src: data?.profile_auth_image });
                            }}
                        />
                    </FlexMainWrapper>

                    <FlexMainWrapper style={{ flexDirection: 'column', gap: '13px', marginTop: '20px' }}>
                        <CheckboxList
                            title={'카메라 인증'}
                            itemLists={[
                                {
                                    subject: '인증 미완료',
                                    checked: parseInt(data?.profile_auth_yn) === 0,
                                    onChange: (e) => {
                                        if (e.target.checked) {
                                            let obj = { ...data };
                                            obj.profile_auth_yn = '0';
                                            setData(obj);
                                        }
                                    },
                                },
                                {
                                    subject: '인증 대기중',
                                    checked: parseInt(data?.profile_auth_yn) === 1,
                                    onChange: (e) => {
                                        if (e.target.checked) {
                                            let obj = { ...data };
                                            obj.profile_auth_yn = '1';
                                            setData(obj);
                                        }
                                    },
                                },
                                {
                                    subject: '인증 완료',
                                    checked: parseInt(data?.profile_auth_yn) === 2,
                                    onChange: (e) => {
                                        if (e.target.checked) {
                                            let obj = { ...data };
                                            obj.profile_auth_yn = '2';
                                            setData(obj);
                                        }
                                    },
                                },
                            ]}
                        />
                        <InputWithTitle title={'회원 ID'} value={data?.id} disabled={true} />
                        <InputWithTitle
                            title={'이름'}
                            value={data.name}
                            onChange={(e) => {
                                let obj = { ...data };
                                obj.name = e.target.value;
                                setData(obj);
                            }}
                        />
                        <InputWithTitle
                            title={'시럽 닉네임'}
                            value={data?.sinor_love_user_nick_name}
                            onChange={(e) => {
                                let obj = { ...data };
                                obj.sinor_love_user_nick_name = e.target.value;
                                setData(obj);
                            }}
                        />
                        <InputWithTitle
                            title={'시놀 닉네임'}
                            value={data?.sinor_user_nick_name}
                            onChange={(e) => {
                                let obj = { ...data };
                                obj.sinor_user_nick_name = e.target.value;
                                setData(obj);
                            }}
                        />
                        <InputWithTitle
                            title={'생년월일'}
                            type="date"
                            titleStyle={{ width: '114px' }}
                            inputStyle={{ width: '90%' }}
                            value={data?.birthday?.replace(/\./g, '-')}
                            onChange={(e) => {
                                let obj = { ...data };
                                obj.birthday = e.target.value;
                                setData(obj);
                            }}
                        />
                        <CheckboxList
                            title={'성별'}
                            itemLists={[
                                {
                                    subject: '남성',
                                    checked: parseInt(data?.sex) === 1,
                                    onChange: (e) => {
                                        if (e.target.checked) {
                                            let obj = { ...data };
                                            obj.sex = '1';
                                            setData(obj);
                                        }
                                    },
                                },
                                {
                                    subject: '여성',
                                    checked: parseInt(data?.sex) === 2,
                                    onChange: (e) => {
                                        if (e.target.checked) {
                                            let obj = { ...data };
                                            obj.sex = '2';
                                            setData(obj);
                                        }
                                    },
                                },
                            ]}
                        />
                        <InputWithTitle
                            title={'휴대폰 번호'}
                            value={data?.phone_no?.replace(/^(\d{3,4})(\d{4,5})(\d{4})$/, `$1$2$3`).trim()}
                            onChange={(e) => {
                                let obj = { ...data };
                                obj.phone_no = e.target.value;
                                setData(obj);
                            }}
                        />

                        <FlexMainWrapper style={{ alignItems: 'cemter', width: '100%', margin: '7px 0' }}>
                            <SText text={'선호 연령'} color="#797979" style={{ width: '115px', textAlign: 'start', fontSize: '14px', fontWeight: '400' }} />
                            <FlexMainWrapper style={{ flexDirection: 'column', width: '100%' }}>
                                <FlexMainWrapper style={{ alignItems: 'center', width: '75%', justifyContent: 'start' }}>
                                    <SText text={data?.min_age && `${data?.min_age}세`} style={{ textAlign: 'start', fontSize: '14px' }} />
                                    <span>~</span>
                                    <SText text={data?.max_age && `${data?.max_age}세`} style={{ textAlign: 'start', fontSize: '14px' }} />
                                </FlexMainWrapper>
                                <div style={{ width: '77%' }}>
                                    <Slider
                                        style={{ margin: '0 5px' }}
                                        range={{ draggableTrack: false }}
                                        defaultValue={[data.min_age || 50, data.max_age || 85]}
                                        dotSize={10}
                                        min={50}
                                        max={85}
                                        step={5}
                                        open={true}
                                        placement={true}
                                        onChange={(value) => {
                                            let obj = { ...data };
                                            obj.min_age = value[0] ? value[0] : data?.min_age;
                                            obj.max_age = value[1] ? value[1] : data?.max_age;
                                            setData(obj);
                                        }}
                                    />
                                </div>
                            </FlexMainWrapper>
                        </FlexMainWrapper>

                        <InputWithTitle
                            title={'관심사'}
                            placeholder={'관심사를 선택해주세요'}
                            value={interestWordFilter(data.interest_code)}
                            disabled={true}
                            buttonOn
                            buttonOnWidth={100}
                            btnText="수정"
                            onClick={() => setInterestToggle({ toggle: true })}
                        />
                        <FlexMainWrapper style={{ flexDirection: 'column', margin: '13px 0' }}>
                            <FlexTwoText
                                wrapperStyle={{ margin: '10px 0' }}
                                text01={'시놀 거주지'}
                                text01Style={{ fontSize: '14px', fontWeight: '400', width: '90px', textAlign: 'start' }}
                                text02={data?.sinor_address}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <FlexTwoText
                                wrapperStyle={{ marginBottom: '7px', marginTop: '3px' }}
                                text01={'시놀 위도/경도'}
                                text01Style={{ fontSize: '14px', fontWeight: '400', width: '90px', textAlign: 'start' }}
                                text02={`${data?.sinor_latitude}/${data?.sinor_longitude}`}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                        </FlexMainWrapper>
                        <FlexMainWrapper style={{ flexDirection: 'column', margin: '13px 0' }}>
                            <FlexTwoText
                                wrapperStyle={{ margin: '10px 0' }}
                                text01={'시럽 거주지'}
                                text01Style={{ fontSize: '14px', fontWeight: '400', width: '90px', textAlign: 'start' }}
                                text02={data?.address}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <FlexTwoText
                                wrapperStyle={{ marginBottom: '7px', marginTop: '3px' }}
                                text01={'시럽 위도/경도'}
                                text01Style={{ fontSize: '14px', fontWeight: '400', width: '90px', textAlign: 'start' }}
                                text02={`${data?.latitude}/${data?.longitude}`}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <SelectLine
                                wrapperStyle={{ alignItems: 'center' }}
                                titleStyle={{ textAlign: 'start', fontSize: '14px', width: '105px' }}
                                selectStyle={{ width: '88%', height: '33px', padding: '5px 3px', fontSize: '14px' }}
                                title={'주소 변경'}
                                list={[{ title: '변경할 주소를 선택해 주세요' }, ...addressLists]}
                                itemKey={'title'}
                                resetThenSet={async (target) => {
                                    const input = { word: target.value };
                                    const response = await RefreshCheckApi.get('/adminUser/search/address', { params: input });
                                    if (response.status === 200) {
                                        const [addressData] = response.data.data;
                                        let obj = { ...data };
                                        obj.address = target.value;
                                        obj.latitude = addressData.latitude;
                                        obj.longitude = addressData.longitude;
                                        setData(obj); //
                                    }
                                }}
                            />
                        </FlexMainWrapper>

                        <FlexMainWrapper style={{ alignItems: 'center' }}>
                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'가입 모임 수'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '90px', textAlign: 'start' }}
                                text02={`${data?.my_gathering_count || 0}개`}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <SText
                                text={'...더보기'}
                                color="gray"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setUserGatheringToggle({ toggle: true, type: 1, user_id: data?.id });
                                }}
                            />
                        </FlexMainWrapper>

                        <FlexMainWrapper style={{ alignItems: 'center' }}>
                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'운영모임 수'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '90px', textAlign: 'start' }}
                                text02={`${data?.operate_gathering_count || 0}개`}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <SText
                                text={'...더보기'}
                                color="gray"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setUserGatheringToggle({ toggle: true, type: 2, user_id: data?.id });
                                }}
                            />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>

                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start' }} width={50} mobileWidth={100}>
                    <LText text={'추가 정보'} />
                    <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '100%', marginTop: '15px' }}>
                        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '100%', marginTop: '15px', gap: '15px' }}>
                            <CategorySelectLine
                                title={'종교'}
                                wrapperStyle={{ alignItems: 'center', width: '100%' }}
                                titleStyle={{ textAlign: 'start', fontSize: '15px', width: '90px' }}
                                selectStyle={{ width: '35%', height: '33px', padding: '5px 3px', fontSize: '14px' }}
                                value={categoryParams?.pick_religion?.code || '미선택'}
                                list={categoryParams.religion}
                                resetThenSet={async (target) => {
                                    let obj = { ...data };
                                    obj.religion = target.value;
                                    setData(obj);
                                }}
                            />
                            <CategorySelectLine
                                title={'음주량'}
                                wrapperStyle={{ alignItems: 'center', width: '100%' }}
                                titleStyle={{ textAlign: 'start', fontSize: '15px', width: '90px' }}
                                selectStyle={{ width: '35%', height: '33px', padding: '5px 3px', fontSize: '14px' }}
                                value={categoryParams.pick_drink?.code || '미선택'}
                                list={categoryParams.drink}
                                resetThenSet={async (target) => {
                                    let obj = { ...data };
                                    obj.drink = target.value;
                                    setData(obj);
                                }}
                            />
                            <CategorySelectLine
                                title={'싱글 사유'}
                                wrapperStyle={{ alignItems: 'center', width: '100%' }}
                                titleStyle={{ textAlign: 'start', fontSize: '15px', width: '90px' }}
                                selectStyle={{ width: '35%', height: '33px', padding: '5px 3px', fontSize: '14px' }}
                                value={categoryParams.pick_single?.code || '미선택'}
                                list={categoryParams.single}
                                resetThenSet={async (target) => {
                                    let obj = { ...data };
                                    obj.single = target.value;
                                    setData(obj);
                                }}
                            />
                            <CategorySelectLine
                                title={'직업'}
                                wrapperStyle={{ alignItems: 'center', width: '100%' }}
                                titleStyle={{ textAlign: 'start', fontSize: '15px', width: '90px' }}
                                selectStyle={{ width: '35%', height: '33px', padding: '5px 3px', fontSize: '14px' }}
                                value={categoryParams.pick_job?.code || '미선택'}
                                list={categoryParams.job}
                                resetThenSet={async (target) => {
                                    let obj = { ...data };
                                    obj.job = parseInt(target.value);
                                    setData(obj);
                                }}
                            />

                            <FlexMainWrapper style={{ width: '100%' }}>
                                <SText text={'자기소개'} color="gray" style={{ cursor: 'pointer', fontSize: '15px', fontWeight: '400', width: '105px', textAlign: 'start' }} />
                                <TextArea
                                    placeholder={'입력된 자기소개가 없습니다.'}
                                    value={data?.self_note || ''}
                                    onChange={(e) => {
                                        let obj = { ...data };
                                        obj.self_note = e.target.value;
                                        setData(obj);
                                    }}
                                />
                            </FlexMainWrapper>
                        </FlexMainWrapper>

                        <div style={{ width: '100%', borderBottom: '1px solid rgb(210, 210, 210)', margin: '20px 0' }} />
                        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '100%', gap: '15px' }}>
                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'가입일'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '110px', textAlign: 'start' }}
                                text02={data?.created_at}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'시럽 최근 접속일'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '110px', textAlign: 'start' }}
                                text02={dateFilter(data?.sinor_love_user_recent_login_datetime)}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'시놀 최근 접속일'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '110px', textAlign: 'start' }}
                                text02={dateFilter(data?.sinor_user_recent_login_datetime)}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <CheckboxList
                                title={'회원 상태'}
                                wrapperStyle={{ marginTop: '10px' }}
                                titleStyle={{ width: '100px', fontWeight: '500' }}
                                itemLists={[
                                    {
                                        subject: '차단 해제',
                                        checked: parseInt(data?.blocked_yn) === 0,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.blocked_yn = 0;
                                                setData(obj);
                                            }
                                        },
                                    },
                                    {
                                        subject: '이용 차단',
                                        checked: parseInt(data?.blocked_yn) === 1,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.blocked_yn = 1;
                                                setData(obj);
                                            }
                                        },
                                    },
                                ]}
                            />
                            <CheckboxList
                                title={'단짝찾기 공개'}
                                wrapperStyle={{ marginTop: '10px' }}
                                titleStyle={{ width: '100px', fontWeight: '500' }}
                                itemLists={[
                                    {
                                        subject: '공개',
                                        checked: parseInt(data?.recommend_yn) === 1,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.recommend_yn = 1;
                                                setData(obj);
                                            }
                                        },
                                    },
                                    {
                                        subject: '비공개',
                                        checked: parseInt(data?.recommend_yn) === 0,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.recommend_yn = 0;
                                                setData(obj);
                                            }
                                        },
                                    },
                                ]}
                            />
                            <CheckboxList
                                title={'알람 동의 여부'}
                                wrapperStyle={{ marginTop: '10px' }}
                                titleStyle={{ width: '100px', fontWeight: '500' }}
                                itemLists={[
                                    {
                                        subject: '동의',
                                        checked: parseInt(data?.alarm_agree_yn) === 1,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.alarm_agree_yn = 1;
                                                setData(obj);
                                            }
                                        },
                                    },
                                    {
                                        subject: '미동의',
                                        checked: parseInt(data?.alarm_agree_yn) === 0,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.alarm_agree_yn = 0;
                                                setData(obj);
                                            }
                                        },
                                    },
                                ]}
                            />
                            <CheckboxList
                                title={'마케팅 동의 여부'}
                                wrapperStyle={{ marginTop: '10px' }}
                                titleStyle={{ width: '100px', fontWeight: '500' }}
                                itemLists={[
                                    {
                                        subject: '동의',
                                        checked: parseInt(data?.market_info_agree) === 1,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.market_info_agree = 1;
                                                setData(obj);
                                            }
                                        },
                                    },
                                    {
                                        subject: '미동의',
                                        checked: parseInt(data?.market_info_agree) === 0,
                                        onChange: (e) => {
                                            if (e.target.checked) {
                                                let obj = { ...data };
                                                obj.market_info_agree = 0;
                                                setData(obj);
                                            }
                                        },
                                    },
                                ]}
                            />
                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'신고한 횟수'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '110px', textAlign: 'start' }}
                                text02={`${data?.report_couont || 0}회`}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'신고 받은 횟수'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '110px', textAlign: 'start' }}
                                text02={`${data?.reported_count || 0}회`}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />

                            <FlexMainWrapper style={{ width: '100%', margin: '10px 0' }}>
                                <SText text={'관리자 메모'} color="gray" style={{ cursor: 'pointer', fontSize: '14px', width: '120px', textAlign: 'start', fontWeight: '500' }} />
                                <TextArea
                                    placeholder={'입력된 관리자 메모가 없습니다.'}
                                    value={data?.admin_memo || ''}
                                    onChange={(e) => {
                                        let obj = { ...data };
                                        obj.admin_memo = e.target.value;
                                        setData(obj);
                                    }}
                                />
                            </FlexMainWrapper>

                            <FlexTwoText
                                wrapperStyle={{ margin: '7px 0' }}
                                text01={'탈퇴 사유'}
                                text01Style={{ fontSize: '14px', fontWeight: '500', width: '110px', textAlign: 'start' }}
                                text02={data?.withdrawal_reason}
                                text02Style={{ fontWeight: '500', fontSize: '14px' }}
                            />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </FlexMainWrapper>
        </DefaultModal>
    );
};

export default UserDetailModal;

/********** 탈퇴 처리 설명서 **********
 * You have to send with Token
 * POST: '/withdrawUser'
 * BODY: { withdrawal_reason: 0 }
 * 0: '매칭이 잘 안되요'
 * 1: '안전하지 않아요'
 * 2: '비매너 사용자를 만났어요'
 * 3: '새 계정을 만들고 싶어요'
 * 4: '사용이 어려워요'
 * 5: 직접입력
 *
 **********************************/
