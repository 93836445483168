import { createContext, useState } from 'react';

const LoginContext = createContext({
  state: { user: null, name: null },
  actions: {
    setUser: () => {},
    setName: () => {},
  },
});

const LoginProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [name, setName] = useState(null);
  const value = {
    state: { user, name },
    actions: { setUser, setName },
  };

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};

const LoginConsumer = LoginContext.Consumer;

export { LoginConsumer, LoginProvider };

export default LoginContext;
