import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { SText } from '../text/ContentText';

const CheckboxInput = ({ containerStyle, style, fontStyle, checked, onChange = () => {}, text, exposure = false, reverse = false }) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', gap: '1px', ...containerStyle }}>
            {reverse && <Text style={{ ...fontStyle }}>{text}</Text>}
            <Input type="checkbox" checked={checked} onChange={onChange} style={{ ...style }} />
            {exposure && <Text style={{ ...fontStyle }}>{text}</Text>}
        </FlexMainWrapper>
    );
};
export const MultipleTitleCheckBoxInput = ({ title, data = [], onChange, init = -1 }) => {
    const [checkIndex, setCheckIndex] = useState(-1);
    useEffect(() => {
        const idx = data.findIndex((e) => e.key === init);
        setCheckIndex(idx);
    }, [init]);
    return (
        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', gap: 16 }}>
            <Text>{title}</Text>
            <FlexMainWrapper style={{ alignItems: 'center', gap: 8 }}>
                {data.map((e, i) => (
                    <FlexMainWrapper key={`multiple_input_${i}`} style={{ alignItems: 'center', gap: 5 }}>
                        <SText text={e.value} />
                        <Input
                            type="checkbox"
                            checked={e.key === checkIndex}
                            onChange={() => {
                                if (onChange) {
                                    onChange(e, i);
                                }
                                setCheckIndex(e.key);
                            }}
                        />
                    </FlexMainWrapper>
                ))}
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export const TitleCheckboxInput = ({
    wrapperStyle,
    checkboxStyle,
    checkedTitleStyle,
    checkWrapperStyle,
    titleStyle,
    gap = 20,
    checkGap = 10,
    checked01,
    checked02,
    onChange01 = () => {},
    onChange02 = () => {},
    text,
    text01 = 'Y',
    text02 = 'N',
    exposure = false,
    disabled = false,
}) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', gap: `${gap}px`, ...wrapperStyle }}>
            {exposure && <Text style={{ ...titleStyle }}>{text}</Text>}
            <FlexMainWrapper style={{ alignItems: 'center', gap: `${checkGap}px` }}>
                <FlexMainWrapper style={{ alignItems: 'center', gap: '5px', ...checkWrapperStyle }}>
                    <SText text={text01} style={{ ...checkedTitleStyle }} />
                    <Input type="checkbox" checked={checked01} onChange={onChange01} style={{ ...checkboxStyle }} disabled={disabled} />
                </FlexMainWrapper>
                <FlexMainWrapper style={{ alignItems: 'center', gap: '5px', ...checkWrapperStyle }}>
                    <SText text={text02} style={{ ...checkedTitleStyle }} />
                    <Input type="checkbox" checked={checked02} onChange={onChange02} style={{ ...checkboxStyle }} disabled={disabled} />
                </FlexMainWrapper>
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export const Checkbox = ({ boxBlur = false, mobileOn, wrapperWidth = '100%', width = 70, checkedTitleStyle, text, checkboxStyle, disabled, checked, onChange = () => {}, reverse = false }) => {
    return (
        <FlexMainWrapper style={{ width: wrapperWidth ? '100%' : '', gap: 0 }} mobileOn={mobileOn}>
            {reverse || <SText text={text} style={{ width: width ? `${width}px` : '', fontWeight: 500, ...checkedTitleStyle }} />}
            {boxBlur ? null : <Input type="checkbox" checked={checked} onChange={onChange} style={{ ...checkboxStyle }} disabled={disabled} />}
            {reverse && <SText text={text} style={{ width: width ? `${width}px` : '', fontWeight: 500, ...checkedTitleStyle }} />}
        </FlexMainWrapper>
    );
};

export const CheckboxList = ({ title, itemLists }) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'center', marginBottom: '10px' }}>
            <Title>{title}</Title>
            {itemLists.map((item, index) => (
                <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                    <Input type="checkbox" key={index} checked={item.checked} onChange={item.onChange} style={{ ...item.checkboxStyle }} disabled={item.disabled} />
                    <SText text={item.subject} style={{ paddingTop: '1px' }} />
                </FlexMainWrapper>
            ))}
        </FlexMainWrapper>
    );
};

export default CheckboxInput;

const Title = styled.div`
    font-size: 14px;
    color: #797979;
    width: 80px;
    text-align: start;
`;

const Text = styled.span`
    font-size: 13px;
`;

const Input = styled.input`
    cursor: pointer;
    margin: 0 5px 0 5px;
    width: 15px;
    height: 15px;
`;
