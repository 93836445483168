import React, { useState } from 'react'; // 353
// components
import ShowSendMessageListsModal from '../../components/MessageComponent/ShowSendMessageListsModal';

// designComponents
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

import MainPageTitle from '../../designComponents/titles/MainPageTitle';

import SendSMS from "../../EventComponents/SMS/SendSMS";
import SendSMSUserTable from "../../EventComponents/SMS/SendSMSUserTable";

/** 문자발송 페이지 */
const SmsPage = () => {

    const [sendPhoneList, setSendPhoneList] = useState([])

    return (
        <Wrapper02 className="sms-page-wrapper">
            {false && <ShowSendMessageListsModal setToggle={() => {}} />}
            <MainPageTitle text={'SMS 발송'} />
            <SendSMS sendPhoneList={sendPhoneList} setSendPhoneList={setSendPhoneList}></SendSMS>
            <SendSMSUserTable setPhoneUserList={setSendPhoneList}></SendSMSUserTable>
        </Wrapper02>
    );
};

export default SmsPage;
