import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';
import { Slider } from 'antd';
import {
    ModalBoxWrapper,
    ModalWrapper,
    ModalBox,
    Header,
    Text,
    Body,
    UserNameBox,
    Line,
    InfoBoxWrapper,
    InfoBox,
    TitleLine,
    SubTitleWrapper,
    SubTitle,
    More,
    UpdateSubTitle,
    ResultWrapper,
    UserImgBox,
    Image,
    SubInfoWrapper,
    TextInSubInfoWrapper,
    CheckLine,
    BottomButtonWrapper,
    Button,
    InfoText,
    InfoText02,
    TextInput,
    RowBottomLine,
    MemoInput,
    UserAuthImgWrapper,
} from '../../design_system/components/modal/NewUserModalStyled';

// bank_code
import { BANK_CODE } from '../SubscribePayment/payBankCodeService';

// server ip
import { main_server_image_ip } from '../../communication_system/communication_ips';

// useContext
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getUsersDetail, RESET_REQUEST } from '../../data_system/redux/modules/users_reducer/admin.users_reducer'; // updateUserDetailInfo,

// redux action & reducer
import { GET_USERS_DETAIL_SUCCESS } from '../../data_system/redux/modules/users_reducer/users_detail/users_detail_type';
import { CHANGE_EVENT_USER_PROFILE_ORDER } from '../../data_system/redux/modules/events_reducer/events_table/events_table_type';

// components
import CustomCommonDropDown from '../../design_system/components/dropdown/CustomCommonDropDown';
import LoadingPage from '../../pages/loading/LoadingPage';
import ModalDraggableBallHook from '../../designComponents/draggable_list/modalDraggableBallHook';
import NewRadioBtn from '../../design_system/components/radio_btn/NewRadioBtn';
import CheckUserGatheringModal from '../../components/UserComponents/CheckUserGatheringModal';
import PhotoHistoryModal from './PhotoHistoryModal';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainSelect } from '../../designComponents/dropdowns/Selectdown';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { TitleCheckboxInput } from '../../designComponents/checkboxs/CheckboxInput';

/** 회원 상세 모달창 */
const UserModal = ({ setImageToggle, setSrc = () => {}, serSrcOrigin = () => {}, searchUser, filterParam, gathering_role }) => {
    const dispatch = useDispatch();
    const { usersDetail, updateUsersInfoResult, updateUserDetailDone } = useSelector((state) => state.newUsersReducer);

    // useRef
    const dashMenu = useRef();

    // context
    const { user_info_modal_state, user_info_modal_actions } = useContext(UserInfoModalContext);

    // state
    const [commonCode, setCommonCode] = useState([]);
    const [name, setName] = useState('');
    const [nickName, setNickName] = useState('');
    const [aiDetect, setAIDetect] = useState(0);
    const [birthday, setBirthDay] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [adminMemo, setAdminMemo] = useState('');
    const [selfNote, setSelfNote] = useState('');
    const [avail, setAvail] = useState(0);
    const [religionId, setReligionId] = useState(-1);
    const [drinkId, setDrinkId] = useState(-1);
    const [workId, setWorkId] = useState(-1);
    const [singleId, setSingleId] = useState(-1);
    const [blockYn, setBlockYn] = useState(false);
    const [describeExpireDate, setDescribeExpireDate] = useState('');
    const [recommendSelf, setRecommendSelf] = useState(true);
    const [profileList, setProfileList] = useState([]);
    const [userGatheringType, setUserGatheringType] = useState(0);
    const [ageFilter, setAgeFilter] = useState([usersDetail?.min_age, usersDetail?.max_age]);
    const [address, setAdress] = useState('');
    const [updateAddress, setUpdateAddress] = useState({});

    const [marketingAgreeYn, setMarketingAgreeYn] = useState(0);
    const [alarmAgreeYn, setAlarmAgreeYn] = useState(0);

    // state-toggle
    const [updateProfileImageToggle, setUpdateProfileImageToggle] = useState(false);
    const [userGatheringToggle, setUserGatheringToggle] = useState(false);
    const [photoHistoryToggle, setPhotoHistoryToggle] = useState([false, 0]);
    const [addressLists, setAddressLists] = useState([]);

    useEffect(() => {
        const response = RefreshCheckApi.get('/adminUser/commonCode');
        response.then((res) => setCommonCode(res.data.data));
    }, [usersDetail]);

    useEffect(() => {
        if (updateUserDetailDone && updateUsersInfoResult) dispatch({ type: RESET_REQUEST });
        if (user_info_modal_state.id) dispatch(getUsersDetail(user_info_modal_state.id));
    }, [dispatch, user_info_modal_state.id, updateUsersInfoResult, updateUserDetailDone]);

    useState(() => {
        const response = RefreshCheckApi.get('/adminUser/address');
        response.then((res) => setAddressLists([...res?.data?.all])).catch((err) => console.error(err));
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => applyFetchData(), [usersDetail]);

    /** ESC로 창 닫기 */
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                if (!userGatheringToggle) {
                    closeModal();
                } else {
                    setUserGatheringToggle(false);
                }
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userGatheringToggle]);

    /** 외부 클릭시 modalCloseHandler 함수 호출 */
    useEffect(() => {
        // window.addEventListener('click', modalCloseHandler);
        return () => {
            // window.removeEventListener('click', modalCloseHandler);
        };
    });

    /** modal 외부 클릭 시 닫힘 */
    // eslint-disable-next-line no-unused-vars
    const modalCloseHandler = ({ target }) => {
        if (dashMenu.current) {
            if (user_info_modal_state.modalVisible && !dashMenu.current.contains(target)) {
                closeModal();
            }
        }
    };

    /** 모달창 닫기 */
    const closeModal = () => {
        // if (window.confirm('창을 닫으면 수정된 내용이 저장되지 않습니다. 진행하시겠습니까?')) {
        initData();
        user_info_modal_actions.setModalVisible(false);

        dispatch({
            type: GET_USERS_DETAIL_SUCCESS,
            payload: null,
        });
        // }
    };

    /** 이름변경 */
    const onChangeName = (e) => {
        setName(e.target.value);
    };

    /** 데이터 초기화 */
    const initData = () => {
        setName('');
        setAIDetect(0);
        setBirthDay('');
        setUserPhone('');
        setAdminMemo('');
        setSelfNote('');
        setNickName('');
        setAvail(false);
        setBlockYn(false);
        setReligionId(-1);
        setDrinkId(-1);
        setWorkId(-1);
        setSingleId(-1);
    };

    /** 데이터 상태 적용 */
    const applyFetchData = () => {
        if (usersDetail) {
            setAIDetect(usersDetail.profile_auth_yn);
            if (usersDetail.birthday) setBirthDay(usersDetail.birthday.replace(/\./g, '-'));
            setUserPhone(usersDetail.phone_no ?? '');
            setSelfNote(usersDetail.self_note ?? '');
            setWorkId(Number(usersDetail.job ?? -1));
            setDrinkId(Number(usersDetail.drink ?? -1));
            setReligionId(Number(usersDetail.religion ?? -1));
            setSingleId(Number(usersDetail.single ?? -1));
            setAdminMemo(usersDetail.admin_memo ?? '');
            setNickName(usersDetail.nick_name ?? '');
            setName(usersDetail.name ?? '');
            setRecommendSelf(usersDetail.recommend_yn === 1);
            setProfileList(usersDetail.profile_list ?? []);
            setAgeFilter([usersDetail.min_age, usersDetail.max_age]);
            setAdress(usersDetail.address);
            setMarketingAgreeYn(usersDetail.market_info_agree);
            setAlarmAgreeYn(usersDetail.alarm_agree_yn);

            if (usersDetail.describe_expire_date) {
                setDescribeExpireDate(moment(usersDetail.describe_expire_date).format('YYYY-MM-DD HH:mm'));
            }

            if (usersDetail.blocked_yn) {
                setAvail(2);
            } else {
                setAvail(usersDetail.avail_yn);
            }
        }
    };

    /** 데이터 저장(수정) 이벤트 함수 */
    const saveUserInfo = async () => {
        try {
            if (window.confirm('수정된 내용을 저장하시겠습니까?')) {
                let block = 0;

                if (avail === 2) {
                    block = 1;
                } else if (avail === 1) {
                    block = 0;
                }

                const param = {
                    id: usersDetail.id,
                    user_name: name,
                    birthday: birthday,
                    nick_name: nickName,
                    drink: drinkId === -1 ? null : drinkId,
                    job: workId === -1 ? null : workId,
                    religion: religionId === -1 ? null : religionId,
                    single_reason: singleId === -1 ? null : singleId,
                    phone_no: userPhone,
                    recommend_yn: recommendSelf,
                    profile_photo_auth_yn: aiDetect,
                    self_note: selfNote === '' ? null : selfNote,
                    admin_memo: adminMemo === '' ? null : adminMemo,
                    blocked_yn: block,
                    describe_expire_date: describeExpireDate === '' ? null : describeExpireDate,
                    image_list: profileList,
                    address: updateAddress.address || usersDetail.address,
                    latitude: updateAddress.latitude || usersDetail.latitude,
                    longitude: updateAddress.longitude || usersDetail.longitude,
                    marketing_info_receive_agree_yn: marketingAgreeYn,
                    alarm_agree_yn: alarmAgreeYn,
                };
                const response = await RefreshCheckApi.put(`/adminUser/${param.id}`, param);
                if (response.status === 200) {
                    alert('회원정보 수정을 완료했습니다.');
                    dispatch(getUsersDetail(user_info_modal_state.id));
                    applyFetchData(); // 상태적용
                    searchUser(); // 회원 페이지 데이터 재요청
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    /** 이미지 필터 */
    const imageFilter = (src) => {
        return src.includes('https://k.kakaocdn.net') ? src : main_server_image_ip + src;
    };

    /** 이미지 순서 변경 */
    const onChangeRow = (order) => {
        setProfileList(order);

        dispatch({
            type: CHANGE_EVENT_USER_PROFILE_ORDER,
            order: order,
        });
    };

    /** 이미지 크게보기 */
    const onClickShowImage = (src) => () => {
        setSrc(imageFilter(src));
        serSrcOrigin(src);
        setImageToggle((prev) => !prev);
    };

    /** 이미지 수정 토글 */
    const onClickUpdateImage = () => {
        setUpdateProfileImageToggle((prev) => !prev);
    };

    /** 이미지 onError */
    const handleImgError = (e) => {
        e.target.src = `/images/noneImage.png`;
    };

    /** 회원 모임 상세보기 */
    const onClickMyGathering = (type) => () => {
        setUserGatheringToggle(true);
        setUserGatheringType(type);
    };

    /** 모임 계좌정보 필터 */
    const gatheringBankInfoFilter = (bank_code, bank_no) => {
        if (bank_no && bank_code) {
            BANK_CODE.forEach((data) => {
                if (bank_code === data.CODE1) {
                    return `${data.KOR}: ${bank_no}`;
                }
            });
        } else {
            return '계좌 정보없음';
        }
    };

    /** 사진 업데이트 이력 보기 토글 */
    const onClickMorePhotoHistory = (type) => () => {
        if (type === 1) {
            setPhotoHistoryToggle([true, 1]);
        } else if (type === 2) {
            setPhotoHistoryToggle([true, 2]);
        }
    };

    /** 선호 나이 설정 적용하기 */
    const onClickApplyAgeFilter = async () => {
        try {
            const response = await RefreshCheckApi.patch('/adminUser/userAge/update', {
                userId: usersDetail.id,
                ageArray: ageFilter.sort(),
            });
            if (response.status === 200) {
                dispatch(getUsersDetail(user_info_modal_state.id));
                searchUser(filterParam); // 회원 페이지 데이터 재요청
                alert(response.data.message);
            }
        } catch (error) {
            console.error(error);
            alert('[Error] 관리자에게 문의 부탁드립니다.');
        }
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback(
        async (e) => {
            try {
                const imageFormData = new FormData();
                [].forEach.call(e.target.files, (f) => imageFormData.append('image', f));

                const response = await RefreshCheckApi.post('/adminGathering/contents/images', imageFormData);
                if (response.status === 200) {
                    if (profileList.length) {
                        setTimeout(() => setProfileList([...usersDetail?.profile_list, ...response.data]), [1000]);
                    } else {
                        setProfileList(response.data);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        [usersDetail, profileList],
    );

    return (
        <>
            {photoHistoryToggle[0] && <PhotoHistoryModal userId={usersDetail.id} type={photoHistoryToggle[1]} setToggle={setPhotoHistoryToggle} />}

            {userGatheringToggle && <CheckUserGatheringModal userId={usersDetail?.id} type={userGatheringType} setToggle={setUserGatheringToggle} closeModal={closeModal} />}

            <ModalWrapper className="mobile-wrapper">
                <ModalBoxWrapper className="modal-wrapper" ref={dashMenu}>
                    <Header>
                        <Text>상세정보</Text>
                        <Text style={{ cursor: 'pointer' }} onClick={() => closeModal()}>
                            닫기
                        </Text>
                    </Header>

                    <ModalBox>
                        {usersDetail ? (
                            <Body>
                                <div className="user-name">
                                    <label id="label">회원 이름</label>
                                    <UserNameBox>
                                        <UserNameInput type="text" value={name} placeholder={name || '정보없음'} onChange={onChangeName} />
                                    </UserNameBox>

                                    <InfoBoxWrapper className="info-wrapper">
                                        <label id="label">기본 정보</label>
                                        <InfoBox className="user-info">
                                            <TitleLine>기본 정보</TitleLine>
                                            <FlexMainWrapper style={{ alignItems: 'center', gap: 0 }}>
                                                <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                                                <InputLine
                                                    title={false}
                                                    disabled={true}
                                                    wrapperStyle={{ margin: 0 }}
                                                    inputStyle={{ width: '230px', height: '22px' }}
                                                    placeholder={'추가할 프로필 이미지를 선택해주세요'}
                                                />
                                                <MainButton text="추가" onClick={onClickAddImage} style={{ borderRadius: 0, height: '28px' }} />
                                            </FlexMainWrapper>

                                            <label id="label">웹 전용 flex line wrapper</label>
                                            <div className="photo-line-wrapper">
                                                <label id="label">프로필 사진</label>
                                                <div className="photo-line">
                                                    <SubTitleWrapper>
                                                        <SubTitle style={{ width: 'auto' }}>프로필 사진</SubTitle>
                                                        <More onClick={onClickMorePhotoHistory(1)}>...더보기</More>
                                                    </SubTitleWrapper>
                                                    <UpdateSubTitle onClick={onClickUpdateImage}>{updateProfileImageToggle ? '취소' : '수정하기'}</UpdateSubTitle>

                                                    {updateProfileImageToggle ? (
                                                        <UserImgBox className="user-img-box">
                                                            {usersDetail?.profile_list?.length === 0 ? (
                                                                <div
                                                                    style={{
                                                                        marginTop: '5px',
                                                                        fontSize: '14px',
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    프로필 사진 없음
                                                                </div>
                                                            ) : (
                                                                <ModalDraggableBallHook
                                                                    data={usersDetail?.profile_list}
                                                                    profileList={profileList}
                                                                    setProfileList={setProfileList}
                                                                    onChangeRow={onChangeRow}
                                                                />
                                                            )}
                                                        </UserImgBox>
                                                    ) : (
                                                        <div>
                                                            {profileList?.map((src, index) => {
                                                                return (
                                                                    <DragImage
                                                                        key={index}
                                                                        onClick={onClickShowImage(src)}
                                                                        style={{ cursor: 'pointer' }}
                                                                        src={imageFilter(src)}
                                                                        alt="회원 이미지"
                                                                        onError={handleImgError}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>

                                                <label id="label">카메라 인증</label>
                                                <SubInfoWrapper style={{ gap: '1%' }}>
                                                    <div className="photo-auth-line">
                                                        <SubTitleWrapper>
                                                            <SubTitle style={{ width: 'auto' }}>카메라 인증</SubTitle>
                                                            <More onClick={onClickMorePhotoHistory(2)}>...더보기</More>
                                                        </SubTitleWrapper>
                                                        {usersDetail?.profile_auth_image ? (
                                                            <UserAuthImgWrapper onClick={onClickShowImage(usersDetail.profile_auth_image)} style={{ cursor: 'pointer' }}>
                                                                <div className="preview">Preview</div>
                                                                <Image src={main_server_image_ip + usersDetail.profile_auth_image} alt="카메라 인증" />
                                                            </UserAuthImgWrapper>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    height: 130,
                                                                    marginTop: '10px',
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                인증사진 없음
                                                            </div>
                                                        )}
                                                    </div>

                                                    {gathering_role && (
                                                        <>
                                                            <div className="gathering-license-copy">
                                                                <SubTitle>신분증 이미지</SubTitle>
                                                                {usersDetail?.license_copy ? (
                                                                    <UserAuthImgWrapper onClick={onClickShowImage(main_server_image_ip + usersDetail?.license_copy)} style={{ cursor: 'pointer' }}>
                                                                        <div className="preview">Preview</div>
                                                                        <Image src={main_server_image_ip + usersDetail?.license_copy} alt="신분증 사진" />
                                                                    </UserAuthImgWrapper>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            height: 130,
                                                                            marginTop: '10px',
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        신분증 사진 없음
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="gathering-license-copy">
                                                                <SubTitle>계좌사본 이미지</SubTitle>
                                                                {usersDetail?.license_copy ? (
                                                                    <UserAuthImgWrapper onClick={onClickShowImage(main_server_image_ip + usersDetail?.license_copy)} style={{ cursor: 'pointer' }}>
                                                                        <div className="preview">Preview</div>
                                                                        <Image src={main_server_image_ip + usersDetail?.gathering_account_copy} alt="신분증 사진" />
                                                                    </UserAuthImgWrapper>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            height: 130,
                                                                            marginTop: '10px',
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        계좌사본 사진 없음
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </SubInfoWrapper>
                                            </div>
                                            <label id="label">모임 계좌정보</label>
                                            {gathering_role && (
                                                <div>
                                                    <SubInfoWrapper className="nickname">
                                                        <SubTitle>모임 계좌정보</SubTitle>
                                                        <InfoText style={{ width: '250px' }}>
                                                            <InputText
                                                                value={gatheringBankInfoFilter(usersDetail.gathering_account_bank, usersDetail.gathering_account_no)}
                                                                placeholder={usersDetail.id || '정보없음'}
                                                                readOnly
                                                            />
                                                        </InfoText>
                                                    </SubInfoWrapper>
                                                </div>
                                            )}
                                            <label id="label">카메라 인증</label>
                                            <SubInfoWrapper>
                                                <SubTitle className="camera-auth">카메라 인증</SubTitle>
                                                <ResultWrapper>
                                                    <CheckLine className="check-line">
                                                        <NewRadioBtn
                                                            type="checkbox"
                                                            onClick={() => {
                                                                setAIDetect(0);
                                                            }}
                                                            checked={aiDetect === 0}
                                                        />
                                                        <span className="result">인증 미완료</span>
                                                    </CheckLine>
                                                    <CheckLine className="check-line">
                                                        <NewRadioBtn
                                                            type="checkbox"
                                                            onClick={() => {
                                                                setAIDetect(1);
                                                            }}
                                                            checked={aiDetect === 1}
                                                        />
                                                        <span className="result">인증 대기중</span>
                                                    </CheckLine>
                                                    <CheckLine className="check-line">
                                                        <NewRadioBtn
                                                            type="checkbox"
                                                            onClick={() => {
                                                                setAIDetect(2);
                                                            }}
                                                            checked={aiDetect === 2}
                                                        />
                                                        <span className="result">인증 완료</span>
                                                    </CheckLine>
                                                </ResultWrapper>
                                            </SubInfoWrapper>
                                            <label id="label">회원 ID</label>
                                            <SubInfoWrapper className="nickname">
                                                <SubTitle>회원 ID</SubTitle>
                                                <InfoText>
                                                    <InputText value={usersDetail.id || '정보없음'} placeholder={usersDetail.id || '정보없음'} readOnly />
                                                </InfoText>
                                            </SubInfoWrapper>
                                            <label id="label">닉네임</label>
                                            <SubInfoWrapper className="nickname">
                                                <SubTitle>닉네임</SubTitle>
                                                <InfoText>
                                                    <InputText value={nickName} onChange={(text) => setNickName(text.target.value)} placeholder={'닉네임 없음'} />
                                                </InfoText>
                                            </SubInfoWrapper>
                                            <label id="label">생년월일</label>
                                            <SubInfoWrapper className="birth" style={{ margin: '10px 0px' }}>
                                                <SubTitle>생년월일</SubTitle>

                                                <TextInput type={'date'} style={{ width: '142px', marginTop: '8px' }} value={birthday} onChange={(text) => setBirthDay(text.target.value)} />
                                            </SubInfoWrapper>
                                            <label id="label">성별</label>
                                            <SubInfoWrapper className="sex">
                                                <SubTitle>성별</SubTitle>
                                                <InfoText02>{Number(usersDetail.sex) === 1 ? '남성' : Number(usersDetail.sex) === 2 ? '여성' : 'Null'}</InfoText02>
                                            </SubInfoWrapper>
                                            <label id="label">휴대폰 번호</label>
                                            <SubInfoWrapper className="phone-num">
                                                <SubTitle>휴대폰 번호</SubTitle>
                                                <InfoText>
                                                    <InputText value={userPhone} onChange={(text) => setUserPhone(text.target.value)} />
                                                </InfoText>
                                            </SubInfoWrapper>
                                            <label id="label">단짝 선호 연령</label>
                                            <SubInfoWrapper className="describe-expire" style={{ alignItems: 'center' }}>
                                                <SubTitle style={{ margin: '0 18px 0 0 ' }}>단짝 선호 연령</SubTitle>

                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', width: '100%' }}>
                                                    <div style={{ width: '60%' }}>
                                                        <Slider
                                                            range={{ draggableTrack: false }}
                                                            defaultValue={[usersDetail.min_age, usersDetail.max_age]}
                                                            dotSize={10}
                                                            min={50}
                                                            max={85}
                                                            step={5}
                                                            open={true}
                                                            placement={true}
                                                            onChange={(value) => setAgeFilter(value)}
                                                        />
                                                    </div>
                                                    <ApplyButton onClick={onClickApplyAgeFilter}>적용하기</ApplyButton>
                                                </div>
                                            </SubInfoWrapper>
                                            <label id="label">관심사</label>
                                            <SubInfoWrapper className="interesting-things">
                                                <SubTitle>관심사</SubTitle>
                                                <InfoText02>{usersDetail.interest || <span style={{ color: 'gray' }}>정보없음</span>}</InfoText02>
                                            </SubInfoWrapper>
                                            <SubInfoWrapper className="adress">
                                                <SubTitle style={{ width: '143px' }}>거주지</SubTitle>
                                                <FlexMainWrapper
                                                    style={{
                                                        width: '100%',
                                                        flexDirection: 'column',
                                                        justifyContent: 'start',
                                                    }}
                                                >
                                                    <InfoText02>{address}</InfoText02>
                                                </FlexMainWrapper>
                                            </SubInfoWrapper>
                                            <SubInfoWrapper className="adress">
                                                <SubTitle style={{ width: '111px' }}>주소 변경</SubTitle>
                                                <FlexMainWrapper
                                                    style={{
                                                        width: '300px',
                                                        flexDirection: 'column',
                                                        alignItems: 'start',
                                                        justifyContent: 'start',
                                                    }}
                                                >
                                                    <MainSelect
                                                        list={[{ title: '변경할 주소를 선택해 주세요' }, ...addressLists]}
                                                        itemKey={'title'}
                                                        resetThenSet={async (target) => {
                                                            const input = { word: target.value };
                                                            const response = await RefreshCheckApi.get('/adminUser/search/address', { params: input });
                                                            if (response.status === 200) {
                                                                const [data] = response.data.data;
                                                                let obj = { ...updateAddress };
                                                                obj.address = target.value;
                                                                obj.latitude = data.latitude;
                                                                obj.longitude = data.longitude;
                                                                setUpdateAddress(obj);
                                                            }
                                                        }}
                                                    />
                                                </FlexMainWrapper>
                                            </SubInfoWrapper>
                                            <SubInfoWrapper className="adress">
                                                <SubTitle>위도/경도</SubTitle>
                                                <InfoText02>
                                                    <span style={{ color: '#797979' }}>{updateAddress.latitude || usersDetail.latitude}</span>
                                                    <span style={{ margin: '0 5px' }}>/</span>
                                                    <span style={{ color: '#797979' }}>{updateAddress.longitude || usersDetail.longitude}</span>
                                                </InfoText02>
                                            </SubInfoWrapper>
                                            <label id="label">가입모임 수</label>
                                            <SubInfoWrapper className="adress">
                                                <SubTitle>가입모임 수</SubTitle>
                                                <InfoText02>{usersDetail.my_gathering_count || 0}개</InfoText02>
                                                <InfoText02>
                                                    <MoreButton onClick={onClickMyGathering(1)}>...상세보기</MoreButton>
                                                </InfoText02>
                                            </SubInfoWrapper>
                                            <label id="label">운영모임 수</label>
                                            <SubInfoWrapper className="adress">
                                                <SubTitle>운영모임 수</SubTitle>
                                                <InfoText02>{usersDetail.operate_gathering_count || 0}개</InfoText02>
                                                <InfoText02>
                                                    <MoreButton onClick={onClickMyGathering(2)}>...상세보기</MoreButton>
                                                </InfoText02>
                                            </SubInfoWrapper>
                                        </InfoBox>

                                        <Line />

                                        <label id="label">추가 정보</label>
                                        <InfoBox className="more-user-info">
                                            <RowBottomLine>
                                                <TitleLine className="more-title-line">추가 정보</TitleLine>

                                                <label id="label">종교</label>
                                                <SubInfoWrapper className="religion">
                                                    <SubTitle>종교</SubTitle>

                                                    <CustomCommonDropDown list={commonCode?.filter((data) => data.code_group === 'RELIGION_CODE')} index={religionId} resetThenSet={setReligionId} />
                                                </SubInfoWrapper>

                                                <label id="label">음주량</label>
                                                <SubInfoWrapper className="how-many-drink">
                                                    <SubTitle>음주량</SubTitle>

                                                    <CustomCommonDropDown list={commonCode?.filter((data) => data.code_group === 'DRINK_CODE')} index={drinkId} resetThenSet={setDrinkId} />
                                                </SubInfoWrapper>

                                                <label id="label">싱글 사유</label>
                                                <SubInfoWrapper className="why-single">
                                                    <SubTitle>싱글 사유</SubTitle>

                                                    <CustomCommonDropDown list={commonCode?.filter((data) => data.code_group === 'SINGLE_CODE')} index={singleId} resetThenSet={setSingleId} />
                                                </SubInfoWrapper>

                                                <label id="label">직업</label>
                                                <SubInfoWrapper className="job">
                                                    <SubTitle>직업</SubTitle>

                                                    <CustomCommonDropDown list={commonCode?.filter((data) => data.code_group === 'JOB_CODE')} index={workId} resetThenSet={setWorkId} />
                                                </SubInfoWrapper>

                                                <label id="label">자기소개</label>
                                                <TextInSubInfoWrapper className="introduce">
                                                    <SubTitle style={{ width: '123px' }}>자기소개</SubTitle>

                                                    <MemoInput value={selfNote} onChange={(text) => setSelfNote(text.target.value)} />
                                                </TextInSubInfoWrapper>
                                            </RowBottomLine>

                                            <label id="label">가입일</label>
                                            <SubInfoWrapper className="signup-created-at">
                                                <SubTitle>가입일</SubTitle>
                                                <InfoText02>{usersDetail.created_at}</InfoText02>
                                            </SubInfoWrapper>

                                            <label id="label">최근접속일</label>
                                            <SubInfoWrapper className="signup-created-at">
                                                <SubTitle>최근 접속일</SubTitle>
                                                <InfoText02>{moment(usersDetail.recent_login_datetime).format('YYYY.MM.DD HH:mm:ss')}</InfoText02>
                                            </SubInfoWrapper>

                                            <label id="label">회원 상태</label>
                                            <SubInfoWrapper>
                                                <SubTitle>회원상태</SubTitle>
                                                <ResultWrapper>
                                                    <CheckLine className="check-line">
                                                        <NewRadioBtn
                                                            type="checkbox"
                                                            onClick={() => {
                                                                setAvail(1);
                                                                if (blockYn) setBlockYn(0);
                                                            }}
                                                            checked={avail === 0 || avail === 1}
                                                        />
                                                        <span className="result">차단 해제</span>
                                                    </CheckLine>
                                                    <CheckLine className="check-line">
                                                        <NewRadioBtn
                                                            type="checkbox"
                                                            onClick={() => {
                                                                setAvail(2);
                                                            }}
                                                            checked={avail === 2}
                                                        />
                                                        <span className="result">이용 차단</span>
                                                    </CheckLine>
                                                </ResultWrapper>
                                            </SubInfoWrapper>

                                            <label id="label">단짝찾기 공개</label>
                                            <SubInfoWrapper>
                                                <SubTitle className="camera-auth">단짝찾기 공개</SubTitle>
                                                <ResultWrapper>
                                                    <CheckLine className="check-line">
                                                        <NewRadioBtn
                                                            type="checkbox"
                                                            onClick={() => {
                                                                setRecommendSelf(true);
                                                            }}
                                                            checked={recommendSelf}
                                                        />
                                                        <span className="result">공개</span>
                                                    </CheckLine>
                                                    <CheckLine className="check-line">
                                                        <NewRadioBtn
                                                            type="checkbox"
                                                            onClick={() => {
                                                                setRecommendSelf(false);
                                                            }}
                                                            checked={!recommendSelf}
                                                        />
                                                        <span className="result">비공개</span>
                                                    </CheckLine>
                                                </ResultWrapper>
                                            </SubInfoWrapper>

                                            <SubInfoWrapper className="signup-created-at">
                                                <TitleCheckboxInput
                                                    wrapperStyle={{ justifyContent: 'start', margin: '10px 0 0 0' }}
                                                    titleStyle={{ color: '#797979', width: '90px', fontSize: '14px' }}
                                                    checkWrapperStyle={{ flexDirection: 'row-reverse' }}
                                                    checkGap={50}
                                                    gap={23}
                                                    exposure
                                                    text={'알람 동의 여부'}
                                                    checked01={alarmAgreeYn}
                                                    onChange01={(e) => {
                                                        const checked = e.target.checked;
                                                        if (checked) setAlarmAgreeYn(1);
                                                    }}
                                                    checked02={!alarmAgreeYn}
                                                    onChange02={(e) => {
                                                        const checked = e.target.checked;
                                                        if (checked) setAlarmAgreeYn(0);
                                                    }}
                                                />
                                            </SubInfoWrapper>

                                            <label id="label">마케팅 동의 여부</label>
                                            <SubInfoWrapper className="signup-created-at">
                                                <TitleCheckboxInput
                                                    wrapperStyle={{ justifyContent: 'start', margin: '10px 0 0 0' }}
                                                    titleStyle={{ color: '#797979', width: '90px', fontSize: '14px' }}
                                                    checkWrapperStyle={{ flexDirection: 'row-reverse' }}
                                                    checkGap={50}
                                                    gap={23}
                                                    exposure
                                                    text={'마케팅 동의 여부'}
                                                    checked01={marketingAgreeYn}
                                                    onChange01={(e) => {
                                                        const checked = e.target.checked;
                                                        if (checked) setMarketingAgreeYn(1);
                                                    }}
                                                    checked02={!marketingAgreeYn}
                                                    onChange02={(e) => {
                                                        const checked = e.target.checked;
                                                        if (checked) setMarketingAgreeYn(0);
                                                    }}
                                                />
                                            </SubInfoWrapper>

                                            <label id="label">신고한 횟수</label>
                                            <SubInfoWrapper className="signup-created-at">
                                                <SubTitle>신고한 횟수</SubTitle>
                                                <InfoText02>
                                                    <span>{usersDetail.report_couont}</span>회
                                                </InfoText02>
                                            </SubInfoWrapper>

                                            <label id="label">신고 받은 횟수</label>
                                            <SubInfoWrapper className="signup-created-at">
                                                <SubTitle>신고 받은 횟수</SubTitle>
                                                <InfoText02>
                                                    <span>{usersDetail.reported_count}</span>회
                                                </InfoText02>
                                            </SubInfoWrapper>

                                            <label id="label">관리자 메모</label>
                                            <TextInSubInfoWrapper className="introduce">
                                                <SubTitle style={{ width: '123px' }}>관리자 메모</SubTitle>

                                                <MemoInput
                                                    value={adminMemo}
                                                    onChange={(text) => {
                                                        setAdminMemo(text.target.value);
                                                    }}
                                                />
                                            </TextInSubInfoWrapper>

                                            <label id="label">탈퇴 사유</label>
                                            <SubInfoWrapper className="signup-created-at" style={{ marginBottom: '22%' }}>
                                                <SubTitle>탈퇴 사유</SubTitle>
                                                <InfoText02>{usersDetail.withdrawal_reason}</InfoText02>
                                            </SubInfoWrapper>
                                        </InfoBox>
                                    </InfoBoxWrapper>
                                </div>
                            </Body>
                        ) : (
                            <LoadingPage text={'회원 정보를 불러오는 중입니다.'} />
                        )}
                    </ModalBox>

                    <BottomButtonWrapper>
                        <Button className="cancel" onClick={() => closeModal()}>
                            취소
                        </Button>
                        <Button className="save" onClick={saveUserInfo}>
                            저장
                        </Button>
                    </BottomButtonWrapper>
                </ModalBoxWrapper>
            </ModalWrapper>
        </>
    );
};

export default UserModal;

/********************************************************* styled *********************************************************/

const UserNameInput = styled.input`
    border: 1px solid rgba(66, 139, 247, 0.2);
    font-size: 15px;
    outline: none;

    padding: 0 5px;

    width: 120px;
    height: 27px;
`;

const InputText = styled.input`
    border: 0;
    width: 130px;
    outline: none;
`;

const DragImage = styled.img`
    display: inline;
    margin-right: 3px;

    user-select: none;
    -webkit-user-drag: none;

    width: 110px;
    height: 110px;

    object-fit: cover;
`;

const MoreButton = styled.div`
    cursor: pointer;
    user-select: none;

    margin-left: 8px;

    color: gray;
    font-size: 13px;

    :hover {
        color: black;
    }
`;

const ApplyButton = styled.div`
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 30px;
    border-radius: 5px;
    background-color: rgb(66, 139, 247);
    color: #fff;

    &:hover {
        background-color: rgb(16 104 235);
    }
`;
