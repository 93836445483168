import mainRequest from "../request";

export const gatheringScheduleListApi = async (params) => {

	try {
		const response = await mainRequest.post('/v2/adminGatheringScheduler/list', params)


		return response?.data


	} catch(err){
		return {
			ok:false,
			status: err.response.status || 500,
			message: err.response.data.message || '서버 에러'
		}
	}

}

export const gatheringSchedulerPayListApi = async (params) => {
	try {
		const response = await mainRequest.post('/v2/adminGatheringScheduler/pay/list', params)

		return response?.data

	} catch(err){
		return {
			ok:false,
			status: err.response.status || 500,
			message: err.response.data.message || '서버 에러'
		}
	}
}