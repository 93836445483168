export const GET_ADMINISTRATORS = "admin/GET_ADMINISTRATORS";
export const GET_ADMINISTRATORS_SUCCESS = "admin/GET_ADMINISTRATORS_SUCCESS";
export const GET_ADMINISTRATORS_FAILURE = "admin/GET_ADMINISTRATORS_FAILURE";
export const POST_ADMINISTRATORS = "admin/POST_ADMINISTRATORS";
export const POST_ADMINISTRATORS_SUCCESS = "admin/POST_ADMINISTRATORS_SUCCESS";
export const POST_ADMINISTRATORS_FAILURE = "admin/POST_ADMINISTRATORS_FAILURE";
export const DELETE_ADMINISTRATORS = "admin/DELETE_ADMINISTRATORS";
export const DELETE_ADMINISTRATORS_SUCCESS =
  "admin/DELETE_ADMINISTRATORS_SUCCESS";
export const DELETE_ADMINISTRATORS_FAILURE =
  "admin/DELETE_ADMINISTRATORS_FAILURE";
export const SET_ADMINISTRATORS = "admin/SET_ADMINISTRATORS";
