import React, { useCallback, useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
	requestGetQuestionCategory,
	requestGetQuestionLists,
	requestGetQuestionListsAllCount,
} from '../../data_system/redux/modules/question.reducer/QuestionReducer';

// components
import SearchBar from '../../design_system/components/searchbar/SearchBar';
import Dropdown from '../../components/Common/Dropdown';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';
import QuestionListsTable from '../../components/QuestionComponents/QuestionListsTable';
import { useNavigate } from 'react-router-dom';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

/** 질문관리 */
const Question = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // useSelector
    const { questionLists, getQuestionListsAllCount, getQuestionListsAllCountLoading, questionCategoryLists } = useSelector(
        (state) => state.questionReducer,
    );

    // useState
    const [interestCodeLists, setInterestCodeLists] = useState([]);
    const [mainCategoryNum, setMainCategoryNum] = useState([]);
    const [categoryNum, setCategoryNum] = useState([]);

    const [page, setPage] = useState(1);
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({
        page: 1,
        size: 10,
    });

    /******************************** useEffect ********************************/

    // 질문 데이터 조회, 카데고리 불러오기
    useEffect(() => {
        getQuestionLists(param);
        getCategoryCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, page, param, searchText, interestCodeLists, mainCategoryNum, categoryNum]);

    /******************************** function ********************************/

    // 카테고리 가져오기 함수
    const getCategoryCode = () => {
        dispatch(requestGetQuestionCategory());
    };

    // 질문 리스트 데이터 요청
    const getQuestionLists = (tmp) => {
        let params = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            mainCategoryNum: mainCategoryNum,
            category: categoryNum,
            created_at: tmp.created_at,
            updated_at: tmp.updated_at,
            deleted_at: tmp.deleted_at,
        };

        dispatch(requestGetQuestionLists(params));
        dispatch(requestGetQuestionListsAllCount(params));
    };

    // 검색 요청
    const onChangeSubmit = (e) => {
        e.preventDefault();

        setPage(1);
        getQuestionLists();
    };

    // 검색어
    const onChangeInput = useCallback((e) => {
        setSearchText(e.target.value);
    }, []);

    // 선택한 관심주제 전체제거
    const onClickAllDeleteInterestCode = () => {
        setInterestCodeLists([]);
        setMainCategoryNum([]);
        setCategoryNum([]);
    };

    //* code number 제거 해야됨
    // 선택힌 관심주제 제거
    const deleteInterestCode = (code) => () => {
        let array = interestCodeLists.slice();
        let array02 = mainCategoryNum.slice();
        let array03 = categoryNum.slice();
        const findIndex = array.indexOf(code.sub_category_name);
        array.splice(findIndex, 1);
        array02.splice(findIndex, 1);
        array03.splice(findIndex, 1);
        setInterestCodeLists(array);
        setMainCategoryNum(array02);
        setCategoryNum(array03);
    };

    /***************************** dropdownLists *****************************/

    const dropdownLists = [
        { id: 1, title: '질문', value: 1 },
        { id: 2, title: '답변', value: 2 },
    ];

    /********************************** html **********************************/
    return (
        <Wrapper02>
            <MainPageTitle text={'질문관리'} />
            <SearchWrapper type="submit" onSubmit={getQuestionLists}>
                <Dropdown
                    list={dropdownLists}
                    index={0}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                />
                <form style={{ marginLeft: '5px' }} onSubmit={onChangeSubmit}>
                    <SearchBar type="submit" searchText={searchText} onChangeSearchText={onChangeInput} />
                </form>

                <TagBoxWrapper className="interest-filter-wrapper">
                    {interestCodeLists.length > 0 ? (
                        <AllDelete onClick={onClickAllDeleteInterestCode}>
                            <span>전체삭제</span>
                            <CustomCloseOutlined />
                        </AllDelete>
                    ) : null}

                    {interestCodeLists.length > 0
                        ? interestCodeLists.map((mainTitle) => {
                              return questionCategoryLists?.findCategoryLists?.map((code, index) => {
                                  if (code.sub_category_name === mainTitle && mainCategoryNum.includes(code.main_id)) {
                                      return (
                                          <div key={index}>
                                              <TagBox className="interest-icon" onClick={deleteInterestCode(code)}>
                                                  {code.main_category_name}
                                                  <RightOutlined />
                                                  {code.sub_category_name}
                                                  <CustomCloseOutlined />
                                              </TagBox>
                                          </div>
                                      );
                                  }
                                  return null;
                              });
                          })
                        : null}
                </TagBoxWrapper>
            </SearchWrapper>

            <div id="table">
                <QuestionListsTable
                    data={questionLists?.data}
                    param={param}
                    setParam={setParam}
                    getQuestionLists={getQuestionLists}
                    interestCodeLists={interestCodeLists}
                    setInterestCodeLists={setInterestCodeLists}
                    mainCategoryNum={mainCategoryNum}
                    setMainCategoryNum={setMainCategoryNum}
                    categoryNum={categoryNum}
                    setCategoryNum={setCategoryNum}
                />
            </div>

            <div id="pagination">
                {getQuestionListsAllCountLoading ? (
                    <PaginationLoadingText>
                        <LoadingOutlined style={{ fontSize: '25px' }} />
                        <div style={{ fontSize: '14px' }}>페이지네이션에 적용할 전체 리스트를 불러오는 중입니다...</div>
                    </PaginationLoadingText>
                ) : (
                    <Pagination
                        totalPages={
                            getQuestionListsAllCount?.all_count ? Math.round(getQuestionListsAllCount?.all_count / 10) + 1 : 10
                        }
                        limit={10}
                        page={page}
                        setPage={setPage}
                    />
                )}
            </div>
        </Wrapper02>
    );
};

export default Question;

/********************************** styled **********************************/

const SearchWrapper = styled.div`
    display: flex;
    margin: 45px 0;
`;

const PaginationLoadingText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
`;

const TagBoxWrapper = styled.div`
    display: flexbox;
    align-items: start;
    justify-content: start;

    width: 63%;
    margin-left: 5px;
`;

const AllDelete = styled.div`
    cursor: pointer;
    background-color: rgb(228 96 96 / 17%);

    border: 1px solid rgb(253 149 149);
    border-radius: 10px;

    width: auto;

    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ff3b3b;

    margin: 2px 2px;
    padding: 5px 7px;
`;

const TagBox = styled.div`
    display: inline-block;

    background-color: rgba(66, 139, 247, 0.08);

    border: 1px solid rgb(66, 139, 247);
    border-radius: 10px;

    width: auto;

    font-size: 12px;
    text-align: center;
    color: rgb(66, 139, 247);

    margin: 2px 2px;
    padding: 5px 7px;
`;

const CustomCloseOutlined = styled(CloseOutlined)`
    cursor: pointer;

    font-size: 14px;
    font-weight: bold;
    color: black;

    margin-left: 7px;
`;
