
import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";

const GatheringSchedulerTab = ({setValue}) => {

	const tabList = [
		{
			title: '일정',
			key: 0
		},{
			title: '결제 회원',
			key: 1
		},
	]

	return (
		<BootStrapTabs tabList={tabList} setValue={setValue}></BootStrapTabs>
	)
}

export default GatheringSchedulerTab