import FlexMainWrapper, { Wrapper02 } from "../../designComponents/wrappers/FlexMainWrapper";
import MainPageTitle, { PageButton } from "../../designComponents/titles/MainPageTitle";
import React, { useEffect, useState } from "react";
import { ChartTitle, CircleChartWrapper, CustomerTableWrapper } from "../../pages/customerData/customerData";
import UserDataPieChart from "./UserDataPieChart";
import PlatformTab from "../../GroupComponent/Tab/PlatformTab";


// pieChart
const UserDataPieChartLayOut =  () => {

	const [platform, setPlatform] = useState(0)

 	return (


		<Wrapper02 style={{  }}>
			<MainPageTitle text={'고객데이터'}
						   wrapperStyle={{ width: '100%', justifyContent: 'start', alignItems: 'center' }}/>
			<PlatformTab setPlatform={setPlatform}></PlatformTab>
			{/*<FlexMainWrapper>*/}
			{/*	*/}

			{/*	<SelectEntry*/}
			{/*		value={'나라'}*/}
			{/*		onChange={(e) => {*/}
			{/*			setCountry(Number(e.target.value));*/}
			{/*		}}*/}
			{/*		title={false}*/}
			{/*		selectStyle={{ width: '15vh' }}*/}
			{/*		wrapperStyle={{ marginLeft:'100px' }}*/}
			{/*		option={[*/}
			{/*			{ text: '전체', value: 1 },*/}
			{/*			{ text: '대한민국', value: 2 },*/}
			{/*			{ text: '홍콩', value: 3 },*/}
			{/*		]}*/}
			{/*	/>*/}
			{/*</FlexMainWrapper>*/}

			<CustomerTableWrapper>

				<CircleChartWrapper style={{ borderBottom: '0' }}>
					<UserDataPieChart title={'결혼 상태'} type={'married'} platform={platform}/>
					<UserDataPieChart title={'관심사'} type={'interests'} platform={platform}/>
					<UserDataPieChart title={'지역'} type={'location'} platform={platform}/>
				</CircleChartWrapper>

				<CircleChartWrapper style={{ borderTop: '0'}}>
					<UserDataPieChart title={'연령대'} type={'age'} platform={platform}/>
					<UserDataPieChart title={'탈퇴사유'} type={'withdraw'} platform={platform}/>
					<UserDataPieChart title={'카메라 인증'} type={'camera_auth'} platform={platform}/>
				</CircleChartWrapper>

			</CustomerTableWrapper>
		</Wrapper02>
	)
}

export default UserDataPieChartLayOut