import React from 'react';

// styled-components
import styled from 'styled-components';

// excel
import { ArrowDownBlueUnderbar1x } from '../../design_system/icons/icons';
import ContentText from '../../design_system/components/content_text/ContentText';

const ExcelBtn = ({ onPress }) => {
  return (
    <ExcelDownload onClick={onPress}>
      <ArrowDownBlueUnderbar1x />
      <ContentText style={{ color: 'rgb(48 94 255)' }}>엑셀 다운로드</ContentText>{' '}
    </ExcelDownload>
  );
};

export default ExcelBtn;

// styled
const ExcelDownload = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  border: 1px solid rgb(48 94 255);
  padding: 5px 10px;

  :hover {
    background-color: #dbdbdb;
  }
`;
