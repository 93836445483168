export const GET_HOSTS = "admin/GET_HOSTS";
export const GET_HOSTS_SUCCESS = "admin/GET_HOSTS_SUCCESS";
export const GET_HOSTS_FAILURE = "admin/GET_HOSTS_FAILURE";

export const POST_HOSTS = "admin/POST_HOSTS";
export const POST_HOSTS_SUCCESS = "admin/POST_HOSTS_SUCCESS";
export const POST_HOSTS_FAILURE = "admin/POST_HOSTS_FAILURE";

export const DELETE_HOSTS = "admin/DELETE_HOSTS";
export const DELETE_HOSTS_SUCCESS = "admin/DELETE_HOSTS_SUCCESS";
export const DELETE_HOSTS_FAILURE = "admin/DELETE_HOSTS_FAILURE";