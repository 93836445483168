import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";

export const GatheringDetailTab = ({index, setIndex}) => {
	const tabList = [
		{
			title: '모임 상세',
			key: 0
		},{
			title: '회원 리스트',
			key: 1
		},
	]


	return (
		<BootStrapTabs tabList={tabList} setValue={setIndex} defaultKey={index}></BootStrapTabs>
	)
}

export default GatheringDetailTab