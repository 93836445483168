import React, { useCallback, useRef, useState } from 'react';

import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { dateFilter } from '../../../utils/textFilter';

import SearchbarWithDropdown from '../../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../../designComponents/tables/MainTable';
import { pointHistoryListsFilter } from './PointHistoryListsFilter';
import { adminUsers } from '../../../dataSystems/AdminUserLists';
import FlexMainWrapper from '../../../designComponents/wrappers/FlexMainWrapper';
import { DefaultModal, ModalComponent } from '../../../designComponents/modals/ModalComponent';
import { InputItemLists } from '../../../designComponents/modals/InputItemLists';
import { AddPoint } from './AddPoint';
import { MText } from '../../../designComponents/text/ContentText';
import { ExcelButton } from '../../../designComponents/buttons/Buttons';
import { gatheringAccountUseListsExcel } from '../../../data_system/xlsx_util/xlsx_util';

/** 포인트 페이지 */
const PointPage = () => {
    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [param, setParam] = useState({});

    const [detail, setDetail] = useState({});
    const [userProfile, setUserProfile] = useState({});
    const [update, setUpdate] = useState(false);
    const [onToggleDetail, setOnToggleDetail] = useState(false);
    const [allCountUser, setAllCountUser] = useState(0);

    const ref = useRef(null);
    const tableRef = useRef(null);

    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get('/adminPoint', { params: input });
        return response.data.data;
    };

    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };
        const response = await RefreshCheckApi.get('/adminPoint', { params: input });
        setAllCountUser(response.data.all_count);
        return response.data.all_count;
    };

    // Excel 출력 함수
    const onPressPrintExcel = async () => {
        const excelTitle = prompt('엑셀 제목을 입력해주세요');
        if (excelTitle) {
            let input = {
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.get('/adminPoint/excel', { params: input });
            const headers = [
                { key: 'created_at', value: '일자' },
                { key: 'user_name', value: '회원명' },
                { key: 'user_nickname', value: '닉네임' },
                { key: 'user_phone_no', value: '휴대폰 번호' },
                { key: 'age', value: '나이' },
                { key: 'used_point', value: '포인트 증감' },
                { key: 'left_point', value: '보유 포인트' },
                { key: 'point_reason_detail', value: '변동 사유' },
            ];
            gatheringAccountUseListsExcel(headers, response.data.data, `${excelTitle}.xlsx`);
        }
    };

    return (
        <div id="point-page">
            <DefaultModal boxStyle={{ width: '98%', maxWidth: '900px' }} title="포인트 지급" onClickClose={() => setUpdate(false)} xIcon={true} visible={update}>
                <AddPoint
                    detail={detail}
                    userProfile={userProfile}
                    onClickClose={() => setUpdate(false)}
                    onClickSave={async (item) => {
                        try {
                            let param = {
                                user_id: item?.user_id,
                                point_reason: item.pointType ? 1 : 0, // 지급 & 차감
                                used_point: item.pointType ? parseInt(item.point) : parseInt(-item.point), // 지급할 포인트
                                point_reason_detail: item.point_reason_detail, // 변동사유
                            };
                            if (!item.point) return alert('지급 및 차감할 포인트를 입력하세요.');
                            if (!item.point_reason_detail) return alert('변동사유를 입력하세요');
                            if (!Number(item.point)) return alert('포인트는 숫자만 입력이 가능합니다.');

                            let userName = item?.user_name;
                            let resultPoint = parseInt(item.point).toLocaleString('ko-KR');
                            let resultPointType = item.pointType ? '지급' : '차감';
                            let message = `${userName}님${item.pointType ? '께' : '의'}\n${resultPoint} 포인트를 ${resultPointType} 하시겠습니까?`;

                            if (window.confirm(message)) {
                                const response = await RefreshCheckApi.post(`/adminPoint`, param);
                                ref?.current?.refresh();
                                alert(response.data.message);
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                />
            </DefaultModal>

            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '지급 사유', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />
            <FlexMainWrapper>
                <MainTable
                    buttonComponents={() => {
                        return (
                            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '5px' }}>
                                <MText text={`총 ${allCountUser.toLocaleString('ko-KR')}건`} />
                                <ExcelButton text="Excel" onClick={onPressPrintExcel} />
                            </FlexMainWrapper>
                        );
                    }}
                    tableStyle={{ width: onToggleDetail ? '70%' : '100%' }}
                    scrollWidth={190}
                    ref={ref}
                    tableRef={tableRef}
                    loadingText={'전체 결제내역을 불러오고 있습니다.'}
                    headerData={pointHistoryListsFilter}
                    checkID={'pay_id'}
                    param={param}
                    setParam={setParam}
                    callApi={callApi}
                    pageCountCallApi={pageCountCallApi}
                    plusCount={1}
                    onClickRowItem={(item) => async () => {
                        const response = await RefreshCheckApi.get(`/adminPoint/detail/${item.id}`);
                        if (response.status === 200) {
                            setDetail(response.data.data);
                            setUserProfile(response.data.profiles);
                            setOnToggleDetail(true);
                        }
                    }}
                    adminCheck={(item) => {
                        if (Number(item.used_point) <= 0) {
                            // 결제 사용
                            return { color: 'rgb(66,139,247)' };
                        } else {
                            return { color: 'rgb(255, 16, 125)' };
                        }
                    }}
                    tbodyRenderItem={(key, value) => {
                        if (key === 'created_at') return dateFilter(value);
                        // if (key === 'used_point') return value < 0 ? <span style={{ color: 'rgb(66,139,247)' }}>{value}</span> : value;
                        if (key === 'left_point') return value < 0 ? <span style={{ color: 'rgb(66,139,247)' }}>{value}</span> : value;
                        return value;
                    }}
                />
                <ModalComponent
                    toggle={onToggleDetail}
                    closeModal={() => {
                        setOnToggleDetail(false);
                        setUpdate(false);
                    }}
                    header={'포인트 상세정보'}
                    body={
                        <div>
                            <InputItemLists
                                items={[
                                    {
                                        title: '포인트 내역ID',
                                        value: detail.id,
                                        disabled: true,
                                    },
                                    {
                                        title: '시럽 닉네임',
                                        value: detail.sinor_love_user_nick_name,
                                        disabled: true,
                                    },
                                    {
                                        title: '시놀 닉네임',
                                        value: detail.sinor_user_nick_name,
                                        disabled: true,
                                    },
                                    {
                                        title: '회원명',
                                        value: detail.user_name,
                                        disabled: true,
                                    },
                                    {
                                        title: '휴대폰번호',
                                        value: detail.user_phone_no,
                                        disabled: true,
                                    },
                                    {
                                        title: '나이',
                                        value: detail.age,
                                        placeholder: '변경할 비밀번호를 입력하세요',
                                        disabled: true,
                                    },
                                    {
                                        title: '포인트 증감',
                                        value: detail.used_point,
                                        placeholder: '변경할 비밀번호를 입력하세요',
                                        disabled: true,
                                    },
                                    {
                                        title: '변동 사유',
                                        value: detail.point_reason_detail,
                                        placeholder: '변경할 비밀번호를 입력하세요',
                                        disabled: true,
                                    },
                                    {
                                        title: '보유 포인트',
                                        value: detail.left_point,
                                        placeholder: '변경할 비밀번호를 입력하세요',
                                        disabled: true,
                                    },

                                    {
                                        title: '추천인 번호',
                                        value: detail.point_reason_remark || '추천인 번호 없음',
                                        disabled: true,
                                    },
                                ]}
                            />
                        </div>
                    }
                    saveText={'포인트 지급'}
                    onClickSave={() => setUpdate(true)}
                />
            </FlexMainWrapper>
        </div>
    );
};

export default React.memo(PointPage);
