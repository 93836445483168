import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import axios from 'axios';
import { marketting_main_server_ip } from '../../../../communication_system/communication_ips';

// 게시글 리스트 불러오기
export const GET_GATHERING_CONTENTS_REQUEST = 'GET_GATHERING_CONTENTS_REQUEST';
export const GET_GATHERING_CONTENTS_SUCCESS = 'GET_GATHERING_CONTENTS_SUCCESS';
export const GET_GATHERING_CONTENTS_FAILURE = 'GET_GATHERING_CONTENTS_FAILURE';
// 게시글 수정하기
export const UPDATE_GATHERING_CONTENTS_REQUEST = 'UPDATE_GATHERING_CONTENTS_REQUEST';
export const UPDATE_GATHERING_CONTENTS_SUCCESS = 'UPDATE_GATHERING_CONTENTS_SUCCESS';
export const UPDATE_GATHERING_CONTENTS_FAILURE = 'UPDATE_GATHERING_CONTENTS_FAILURE';
// 게시글 삭제
export const DELETE_GATHERING_CONTENTS_REQUEST = 'DELETE_GATHERING_CONTENTS_REQUEST';
export const DELETE_GATHERING_CONTENTS_SUCCESS = 'DELETE_GATHERING_CONTENTS_SUCCESS';
export const DELETE_GATHERING_CONTENTS_FAILURE = 'DELETE_GATHERING_CONTENTS_FAILURE';
// 댓글 삭제
export const DELETE_GATHERING_COMMENT_REQUEST = 'DELETE_GATHERING_COMMENT_REQUEST';
export const DELETE_GATHERING_COMMENT_SUCCESS = 'DELETE_GATHERING_COMMENT_SUCCESS';
export const DELETE_GATHERING_COMMENT_FAILURE = 'DELETE_GATHERING_COMMENT_FAILURE';
// 대댓글 삭제
export const DELETE_GATHERING_COMMENT_RETRY_REQUEST = 'DELETE_GATHERING_COMMENT_RETRY_REQUEST';
export const DELETE_GATHERING_COMMENT_RETRY_SUCCESS = 'DELETE_GATHERING_COMMENT_RETRY_SUCCESS';
export const DELETE_GATHERING_COMMENT_RETRY_FAILURE = 'DELETE_GATHERING_COMMENT_RETRY_FAILURE';
// 인기 게시글 불러오기
export const GET_PAPULAR_GATHERING_CONTENTS_REQUEST = 'GET_PAPULAR_GATHERING_CONTENTS_REQUEST';
export const GET_PAPULAR_GATHERING_CONTENTS_SUCCESS = 'GET_PAPULAR_GATHERING_CONTENTS_SUCCESS';
export const GET_PAPULAR_GATHERING_CONTENTS_FAILURE = 'GET_PAPULAR_GATHERING_CONTENTS_FAILURE';
// 인기 게시글 숨기기
export const HIDE_PAPULAR_GATHERING_CONTENTS_REQUEST = 'HIDE_PAPULAR_GATHERING_CONTENTS_REQUEST';
export const HIDE_PAPULAR_GATHERING_CONTENTS_SUCCESS = 'HIDE_PAPULAR_GATHERING_CONTENTS_SUCCESS';
export const HIDE_PAPULAR_GATHERING_CONTENTS_FAILURE = 'HIDE_PAPULAR_GATHERING_CONTENTS_FAILURE';
// 이미지 업로드
export const UPLOAD_IMAGES_REQUEST = 'UPLOAD_IMAGES_REQUEST';
export const UPLOAD_IMAGES_SUCCESS = 'UPLOAD_IMAGES_SUCCESS';
export const UPLOAD_IMAGES_FAILURE = 'UPLOAD_IMAGES_FAILURE';
// 이미지 삭제
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    gatheringContentsLists: [], // 게시글 리스트
    updateMessage: '', // 수정결과 메세지
    deleteMessage: '', // 삭제결과 메세지
    deleteCommentMessage: '', // 삭제결과 메세지
    deleteCommentRetryMessage: '', // 대댓글 삭제결과 메세지
    deletedPopulerContentsMessage: null, // 대댓글 삭제결과 메세지

    imagePaths: [], // 이미지 url
    populerContents: [], // 인기 게시글
    updateHideCommentMessage: '', // 인기게시글 숨기기 결과 메세지
    // 게시글 리스트
    getGatheringContentsLoading: false,
    getGatheringContentsDone: false,
    getGatheringContentsError: null,
    // 게시글 수정
    updateGatheringContentsLoading: false,
    updateGatheringContentsDone: false,
    updateGatheringContentsError: null,
    // 게시글 삭제
    deleteGatheringContentsLoading: false,
    deleteGatheringContentsDone: false,
    deleteGatheringContentsError: null,
    // 댓글 삭제
    deleteGatheringCommentLoading: false,
    deleteGatheringCommentDone: false,
    deleteGatheringCommentError: null,
    // 대댓글 삭제
    deleteGatheringCommentRetryLoading: false,
    deleteGatheringCommentRetryDone: false,
    deleteGatheringCommentRetryError: null,
    // 이미지 업로드
    uploadImagesLoading: false,
    uploadImagesDone: false,
    uploadImagesError: null,
    // 인기 게시글 불러오기
    getPapularContentsLoading: false,
    getPapularContentsDone: false,
    getPapularContentsError: null,
    // 인기 게시글 숨기기
    hidePapularContentsLoading: false,
    hidePapularContentsDone: false,
    hidePapularContentsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 이미지 업로드
export const uploadImages = (formData) => async (dispatch) => {
    dispatch({
        type: UPLOAD_IMAGES_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminGathering/contents/images`, formData);
        dispatch({
            type: UPLOAD_IMAGES_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: UPLOAD_IMAGES_FAILURE,
            error: error,
        });
    }
};

// 게시글 리스트 불러오기
export const getGatheringContents = (input) => async (dispatch) => {
    dispatch({
        type: GET_GATHERING_CONTENTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/contents`, { params: input });
        dispatch({
            type: GET_GATHERING_CONTENTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_GATHERING_CONTENTS_FAILURE,
            error: error,
        });
    }
};

// 게시글 수정하기
export const updateGatheringContents = (param) => async (dispatch) => {
    dispatch({
        type: UPDATE_GATHERING_CONTENTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering/contents/update`, param);
        dispatch({
            type: UPDATE_GATHERING_CONTENTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_GATHERING_CONTENTS_FAILURE,
            error: error,
        });
    }
};

// 게시글 삭제하기
export const deleteGatheringContents = (param) => async (dispatch) => {
    dispatch({
        type: DELETE_GATHERING_CONTENTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering/contents/delete`, {
            contents_id_lists: param,
        });
        dispatch({
            type: DELETE_GATHERING_CONTENTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: DELETE_GATHERING_CONTENTS_FAILURE,
            error: error,
        });
    }
};

// 댓글 삭제하기
export const deleteGatheringContentsComment = (comment_id) => async (dispatch) => {
    dispatch({
        type: DELETE_GATHERING_COMMENT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering/comments/delete`, {
            comment_id: comment_id,
        });
        dispatch({
            type: DELETE_GATHERING_COMMENT_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: DELETE_GATHERING_COMMENT_FAILURE,
            error: error,
        });
    }
};

// 대댓글 삭제하기
export const deleteGatheringContentsCommentRetry = (comment_id) => async (dispatch) => {
    dispatch({
        type: DELETE_GATHERING_COMMENT_RETRY_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.delete(`/adminGathering/comments/retry/delete`, {
            comment_id: comment_id,
        });
        dispatch({
            type: DELETE_GATHERING_COMMENT_RETRY_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: DELETE_GATHERING_COMMENT_RETRY_FAILURE,
            error: error,
        });
    }
};

// 인기 게시글 불러오기
export const getPaularContents = () => async (dispatch) => {
    // 토큰 발행
    const requestAccessToken = await RefreshCheckApi.post('/auth/testLogin', { id: 1 });
    const accessToken = requestAccessToken.data.data.access_token;

    dispatch({
        type: GET_PAPULAR_GATHERING_CONTENTS_REQUEST,
    });
    try {
        const response = await axios.get(`${marketting_main_server_ip}gatheringContents/popular`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        dispatch({
            type: GET_PAPULAR_GATHERING_CONTENTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_PAPULAR_GATHERING_CONTENTS_FAILURE,
            error: error,
        });
    }
};

// 인기 게시글 숨기기
export const hidePopularContents = (lists, type) => async (dispatch) => {
    dispatch({
        type: HIDE_PAPULAR_GATHERING_CONTENTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering/pick/delete/contents`, {
            contents_id_lists: lists,
            type: type,
        });

        dispatch({
            type: HIDE_PAPULAR_GATHERING_CONTENTS_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: HIDE_PAPULAR_GATHERING_CONTENTS_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const getGatheringContentsReducer = handleActions(
    {
        // 인기 게시글 리스트 불러오기
        [GET_PAPULAR_GATHERING_CONTENTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getPapularContentsLoading = true;
                draft.getPapularContentsDone = false;
                draft.getPapularContentsError = null;
            }),
        [GET_PAPULAR_GATHERING_CONTENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getPapularContentsLoading = action.payload ? false : true;
                draft.populerContents = action.payload;
                draft.getPapularContentsDone = action.payload ? true : false;
            }),
        [GET_PAPULAR_GATHERING_CONTENTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getPapularContentsLoading = false;
                draft.getPapularContentsError = action.error;
            }),

        // 인기 게시글 리스트 숨기기(차단)
        [HIDE_PAPULAR_GATHERING_CONTENTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.hidePapularContentsLoading = true;
                draft.hidePapularContentsDone = false;
                draft.hidePapularContentsError = null;
            }),
        [HIDE_PAPULAR_GATHERING_CONTENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.hidePapularContentsLoading = action.payload ? false : true;
                draft.deletedPopulerContentsMessage = action.payload;
                draft.hidePapularContentsDone = action.payload ? true : false;
            }),
        [HIDE_PAPULAR_GATHERING_CONTENTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.hidePapularContentsLoading = false;
                draft.hidePapularContentsError = action.error;
            }),

        // 게시글 리스트 불러오기
        [GET_GATHERING_CONTENTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringContentsLoading = true;
                draft.getGatheringContentsDone = false;
                draft.getGatheringContentsError = null;
            }),
        [GET_GATHERING_CONTENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringContentsLoading = action.payload ? false : true;
                draft.gatheringContentsLists = action.payload;
                draft.getGatheringContentsDone = action.payload ? true : false;
            }),
        [GET_GATHERING_CONTENTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getGatheringContentsLoading = false;
                draft.getGatheringContentsError = action.error;
            }),

        // 게시글 수정
        [UPDATE_GATHERING_CONTENTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateGatheringContentsLoading = true;
                draft.updateGatheringContentsDone = false;
                draft.updateGatheringContentsError = null;
            }),
        [UPDATE_GATHERING_CONTENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateGatheringContentsLoading = action.payload ? false : true;
                draft.updateMessage = action.payload;
                draft.updateGatheringContentsDone = action.payload ? true : false;
            }),
        [UPDATE_GATHERING_CONTENTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateGatheringContentsLoading = false;
                draft.updateGatheringContentsError = action.error;
            }),

        // 게시글 삭제
        [DELETE_GATHERING_CONTENTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringContentsLoading = true;
                draft.deleteGatheringContentsDone = false;
                draft.deleteGatheringContentsError = null;
            }),
        [DELETE_GATHERING_CONTENTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringContentsLoading = action.payload ? false : true;
                draft.deleteMessage = action.payload;
                draft.deleteGatheringContentsDone = action.payload ? true : false;
            }),
        [DELETE_GATHERING_CONTENTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringContentsLoading = false;
                draft.deleteGatheringContentsError = action.error;
            }),

        // 댓글 삭제
        [DELETE_GATHERING_COMMENT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringCommentLoading = true;
                draft.deleteGatheringCommentDone = false;
                draft.deleteGatheringCommentError = null;
            }),
        [DELETE_GATHERING_COMMENT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringCommentLoading = action.payload ? false : true;
                draft.deleteCommentMessage = action.payload;
                draft.deleteGatheringCommentDone = action.payload ? true : false;
            }),
        [DELETE_GATHERING_COMMENT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringCommentLoading = false;
                draft.deleteGatheringCommentError = action.error;
            }),

        // 대댓글 삭제
        [DELETE_GATHERING_COMMENT_RETRY_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringCommentRetryLoading = true;
                draft.deleteGatheringCommentRetryDone = false;
                draft.deleteGatheringCommentRetryError = null;
            }),
        [DELETE_GATHERING_COMMENT_RETRY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringCommentRetryLoading = action.payload ? false : true;
                draft.deleteCommentRetryMessage = action.payload;
                draft.deleteGatheringCommentRetryDone = action.payload ? true : false;
            }),
        [DELETE_GATHERING_COMMENT_RETRY_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteGatheringCommentRetryLoading = false;
                draft.deleteGatheringCommentRetryError = action.error;
            }),

        // 이미지 업로드
        [UPLOAD_IMAGES_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.uploadImagesLoading = true;
                draft.uploadImagesDone = false;
                draft.uploadImagesError = null;
            }),
        [UPLOAD_IMAGES_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.uploadImagesLoading = action.payload ? false : true;
                draft.imagePaths = action.payload;
                draft.uploadImagesDone = action.payload ? true : false;
            }),
        [UPLOAD_IMAGES_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.uploadImagesLoading = false;
                draft.uploadImagesError = action.error;
            }),

        // 이미지 삭제
        [REMOVE_IMAGE]: (state, action) =>
            produce(state, (draft) => {
                draft.imagePaths = draft.imagePaths.filter((v, i) => i !== action.data);
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 게시글 불러오기
                draft.getGatheringContentsLoading = false;
                draft.getGatheringContentsDone = false;
                draft.getGatheringContentsError = null;
                // 댓글삭제
                draft.deleteGatheringCommentLoading = false;
                draft.deleteGatheringCommentDone = false;
                draft.deleteGatheringCommentError = null;
                draft.deleteCommentMessage = '';
                // 대댓글삭제
                draft.deleteGatheringCommentRetryLoading = false;
                draft.deleteGatheringCommentRetryDone = false;
                draft.deleteGatheringCommentRetryError = null;
                draft.deleteCommentRetryMessage = '';
                // 인기게시글 삭제
                draft.hidePapularContentsLoading = false;
                draft.hidePapularContentsDone = false;
                draft.hidePapularContentsError = null;
                draft.deletedPopulerContentsMessage = null;
                // 이미지
                draft.imagePaths = [];
            }),
    },
    initialState,
);

export default getGatheringContentsReducer;
