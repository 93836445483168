import React, { useEffect, useState, useCallback } from 'react';

// styled
import styled from 'styled-components';

// react-query && axios $ URL
import { main_server_image_ip } from '../../../communication_system/communication_ips';

/** 회원검색 결과 모달창 */
function SearchUserEntry({ item, index, funcParam, setToggle }) {
    const [clickToggle, setClickToggle] = useState(false);

    useEffect(() => {
        setClickToggle(item.id === funcParam.params.user_id);
    }, [funcParam, item.id]);

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /** 결과 선택 */
    const onClickResultID = useCallback(
        (item) => () => {
            const _params = JSON.parse(JSON.stringify(funcParam.params));

            _params.user_id = item.id;
            funcParam.setParams({ ..._params });
            setToggle(false);
        },
        [funcParam, setToggle],
    );

    const sexFilter = (num) => {
        let _num = parseInt(num);

        switch (true) {
            case _num === 1:
                return '남자';
            case _num === 2:
                return '여자';
            default:
                return null;
        }
    };

    /** 이미지 필터 */
    const imageFilter = (data) => {
        if (data?.profile_image_list?.length > 0) {
            return main_server_image_ip + data?.profile_image_list[0];
        } else {
            return '/images/noneImage.png';
        }
    };

    return (
        <ColumnWrapper key={index} onClick={onClickResultID(item)} clickToggle={clickToggle}>
            <div>
                <Images sizes={100} src={imageFilter(item)} alt="회원 이미지" onError={handleImgError} />
            </div>
            <div>
                <ColWrapper className="pay-contents">
                    <Subtitle>회원ID</Subtitle>
                    <SubInfo>
                        <span>{item.id}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>회원명</Subtitle>
                    <SubInfo>
                        <span>
                            {item.name || '정보없음'}({item.name && sexFilter(item.sex)})
                        </span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>닉네임</Subtitle>
                    <SubInfo>
                        <span>{item.nickname || '정보없음'}</span>
                    </SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>
                        <span>전화번호</span>
                    </Subtitle>
                    <SubInfo>{item.phone_no || '정보없음'}</SubInfo>
                </ColWrapper>

                <ColWrapper className="pay-contents">
                    <Subtitle>생년월일</Subtitle>
                    <SubInfo>
                        <span>{item.birthday || '정보없음'}</span>
                    </SubInfo>
                </ColWrapper>
            </div>
        </ColumnWrapper>
    );
}

export default SearchUserEntry;

const Images = styled.img`
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

const ColumnWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 10px;

    padding-top: 5px;
    width: 100%;

    background-color: ${(props) => (props.clickToggle ? '#00000038' : '')};

    &:hover {
        background-color: #00000038;
    }
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
    width: 100%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: #fff;

    width: 135px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 100%;
    color: #fff;
`;
