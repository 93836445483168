import React from "react";
import styled from "styled-components";
import {rgb_0_0_0, rgba_0_0_0_0d1, rgba_66_139_247_0d2} from "../../colors";
import {h_px6rate, w_px8rate} from "../../size";

const DetailTextarea = ({
  width,
  height,
  font_size,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Textarea
      width={width}
      height={height}
      font_size={font_size}
      value={value}
      onChange={onChange}
      disabled={disabled}
    ></Textarea>
  );
};

export default React.memo(DetailTextarea);

export const Textarea = styled.textarea`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  font-size: ${(props) => props.font_size}px;
  color: ${rgb_0_0_0};
  font-family: noto_sans_kr_medium;
  padding-top: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${rgba_0_0_0_0d1};
    border-radius: 10px;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  resize: none;
`;
