
import {
    getEventsDetail,
    putEventsDetail,
    putEventsOrder,
    postAdminEventTransform
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import {GET_EVENTS_DETAIL, PUT_EVENTS_DETAIL, POST_EVENTS_DETAIL, PUT_EVENTS_ORDER} from "./events_detail_type";


// 이벤트 상세 관련
export const getEventsDetailAsync = createGetRequestThunk(
  GET_EVENTS_DETAIL,
  getEventsDetail
);

export const putEventsDetailAsync = createPutRequestThunk(
  PUT_EVENTS_DETAIL,
  putEventsDetail
);


// 이미지 변환
export const putEventsDetailTransformAsync = createPostRequestThunk(
  POST_EVENTS_DETAIL,
  postAdminEventTransform
);

export const putEventOrderAsync = createPutRequestThunk(
    PUT_EVENTS_ORDER,
    putEventsOrder
)
