import { ArrowRightOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SearchbarWithDropdownV2 from '../../designComponents/searchbars/SearchbarWithDropdownV2';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainTable from '../../designComponents/tables/MainTable';
import { adminUsers } from '../../dataSystems/AdminUserLists';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { RecommendListsFilter } from '../../components/RecommendListsComponents/RecommendListsFilter';
import { dateFilter, sexFilter } from '../../utils/textFilter';
import { HoverSpan } from '../../designComponents/tables/TbodyLists';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import UserModal from '../../components/UserComponents/UserModal';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import { Image } from '../../designComponents/imageboxs/Image';
import { NoticeColor } from '../../designComponents/tables/NoticeColor';
import SearchbarWithDropdown from "../../designComponents/searchbars/SearchbarWithDropdown";
import TableFilter from "../../designComponents/filters/TableFilter";

/** 단짝 추천 리스트 조회 페이지 */
const RecommendHistory = () => {
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({});

    const ref = useRef(null);
    const tableRef = useRef(null);
    const imgRef = useRef(null);

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 0,
            ...param,
        };
        const response = await RefreshCheckApi.post('/adminFriends', input);
        return response.data.data;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 0,
            ...param,
        };
        const response = await RefreshCheckApi.post('/adminFriends', input);
        return response.data.allCount;
    };

    const onClickUserModal = (user_id) => () => {
        user_info_modal_actions.setId(user_id);
        user_info_modal_actions.setModalVisible(true);
    };

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle((prev) => !prev);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <Wrapper02>
            {user_info_modal_state.modalVisible && <UserModal setImageToggle={setImageToggle} serSrcOrigin={setSrc} filterParam={param} searchUser={() => ref?.current?.refresh()} />}
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image imgRef={imgRef} size="S150" url={src} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}

            <MainPageTitle text={'단짝 추천 내역'} />
            <SearchbarWithDropdown
                style={{ margin: '25px 0' }}
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '회원 ID', value: 4 },
                    { id: 5, title: '추천된 회원 주소', value: 5 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
                research={ref}
                setSearchText={setSearchText}
            />


            <TableFilter
                param={param}
                setParam={setParam}
                wrapperStyle={{ width: '95%' }}
                arrFilterSt={{ flexDirection: 'column', alignItems: 'start' }}
                topFilterLists={[
                ]}
                filterLists={[
                    {
                        key: 'recommend_type',
                        value: '추천 사유',
                        filter: [
                            {
                                key: 0,
                                value: '무료추천'
                            },
                            {
                                key: 1,
                                value: '오늘의 랜덤 단짝'
                            },
                            {
                                key: 2,
                                value: '최근 가입한 단짝'
                            },
                            {
                                key: 3,
                                value: '편지를 많이 받은 단짝'
                            },{
                                key: 4,
                                value: '사진 인증 완료된 단짝'
                            },{
                                key: 5,
                                value: '평점이 높은 단짝'
                            },{
                                key: 6,
                                value: '프로필 작성률 높은 단짝'
                            },{
                                key: 7,
                                value: '종교가 같은 단짝'
                            },
                        ]
                    }
                ]}
                research={ref}
            />



            <MainTable
                scrollWidth={150}
                toggleBlur={true}
                buttonComponents={({ checkItems, checkDatas }) => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', gap: '20px', marginBottom: '5px' }}>
                        <NoticeColor />
                    </FlexMainWrapper>
                )}
                ref={ref}
                tableRef={tableRef}
                loadingText={'단짝 추천 내역 리스트를 불러오고 있습니다.'}
                headerData={RecommendListsFilter}
                checkID={'hist_id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item, key) => {}}
                adminCheck={(item) => adminUsers.includes(item.user_phone_no) && { color: 'rgb(255, 16, 125)' }}
                tbodyRenderItem={(key, value, item) => {
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'user_name')
                        return (
                            <HoverSpan onClick={onClickUserModal(item.user_id)}>
                                {`${value}/${item.user_nickname}/`}
                                {sexFilter(item.user_sex)}
                            </HoverSpan>
                        );
                    if (key === 'target_user_name')
                        return (
                            <HoverSpan onClick={onClickUserModal(item.target_user_id)}>
                                {`${value}/${item.target_user_nickname}/`}
                                {sexFilter(item.target_user_sex)}
                            </HoverSpan>
                        );
                    if (!key) return <ArrowRightOutlined />;
                    return value;
                }}
            />
        </Wrapper02>
    );
};

export default RecommendHistory;
