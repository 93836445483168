import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'moment/locale/ko';
import './global.css';

import axios from 'axios';
import TotalRouter from './router/Router';

import { QueryClient, QueryClientProvider } from 'react-query';
import { LoginProvider } from './data_system/contexts/login_context/login_context';
import { AdminMemoModalProvider } from './data_system/contexts/modal_context/admin_memo_modal_context';
import { AddScheduleModalProvider } from './data_system/contexts/modal_context/add_schedule_modal_context';
import { ReviewListModalProvider } from './data_system/contexts/modal_context/review_list_modal_context';
import { DeleteModalProvider } from './data_system/contexts/modal_context/delete_modal_context';
import { ChattingTitleProvider } from './data_system/contexts/chatting_title_context/chatting_title_context';
import { UserInfoModalProvider } from './data_system/contexts/user_context/user_info_modal_context';

const queryClient = new QueryClient();

const App = () => {
    moment.locale('ko');
    axios.defaults.withCredentials = true;
    return (
        <Wrapper className="root">
            <QueryClientProvider client={queryClient}>
                <LoginProvider>
                    <AdminMemoModalProvider>
                        <ReviewListModalProvider>
                            <DeleteModalProvider>
                                <ChattingTitleProvider>
                                    <AddScheduleModalProvider>
                                        <UserInfoModalProvider>
                                            <TotalRouter />
                                        </UserInfoModalProvider>
                                    </AddScheduleModalProvider>
                                </ChattingTitleProvider>
                            </DeleteModalProvider>
                        </ReviewListModalProvider>
                    </AdminMemoModalProvider>
                </LoginProvider>
            </QueryClientProvider>
        </Wrapper>
    );
};

export default React.memo(App);

const Wrapper = styled.div`
    height: 100vh;
    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }
`;
