import { createContext, useState } from 'react';

const UserInfoModalContext = createContext({
  user_info_modal_state: {
    modalVisible: false,
    id: null,
  },
  user_info_modal_actions: {
    setModalVisible: () => {},
    setId: () => {},
  },
});
const UserInfoModalProvider = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState(null);
  const value = {
    user_info_modal_state: {
      modalVisible,
      id,
    },
    user_info_modal_actions: {
      setModalVisible,
      setId,
    },
  };
  return <UserInfoModalContext.Provider value={value}>{children}</UserInfoModalContext.Provider>;
};
const UserInfoModalConsumer = UserInfoModalContext.Consumer;
export { UserInfoModalConsumer, UserInfoModalProvider };

export default UserInfoModalContext;
