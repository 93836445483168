/**
 * 전체 은행 코드 표
 * @type {[{CODE2: string, CODE1: string, KOR: string, ENG: string},{CODE2: string, CODE1: string, KOR: string, ENG: string},{CODE2: string, CODE1: string, KOR: string, ENG: string},{CODE2: string, CODE1: string, KOR: string, ENG: string},{CODE2: string, CODE1: string, KOR: string, ENG: string},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]}
 */
exports.BANK_CODE = [
    { CODE1: '039', CODE2: '39', KOR: '경남은행', ENG: 'KYONGNAMBANK' },
    { CODE1: '034', CODE2: '34', KOR: '광주은행', ENG: 'GWANGJUBANK' },
    { CODE1: '261', CODE2: 'S8', KOR: '교보증권', ENG: 'KYOBO_SECURITIES' },
    { CODE1: '012', CODE2: '12', KOR: '단위농협', ENG: 'LOCALNONGHYEOP' },
    { CODE1: '267', CODE2: 'SE', KOR: '대신증권', ENG: 'DAISHIN_SECURITIES' },
    { CODE1: '287', CODE2: 'SK', KOR: '메리츠증권', ENG: 'MERITZ_SECURITIES' },
    { CODE1: '238', CODE2: 'S5', KOR: '미래에셋증권', ENG: 'MIRAE_ASSET_SECURITIES' },
    { CODE1: '290', CODE2: 'SM', KOR: '부산은행', ENG: 'BUSANBANK' },
    { CODE1: '240', CODE2: 'S3', KOR: '삼성증권', ENG: 'SAMSUNG_SECURITIES' },
    { CODE1: '045', CODE2: '45', KOR: '새마을금고', ENG: 'SAEMAUL' },
    { CODE1: '064', CODE2: '64', KOR: '산림조합', ENG: 'SANLIM' },
    { CODE1: '291', CODE2: 'SN', KOR: '신영증권', ENG: 'SHINYOUNG_SECURITIES' },
    { CODE1: '088', CODE2: '88', KOR: '신한은행', ENG: 'SHINHAN' },
    { CODE1: '278', CODE2: 'S2', KOR: '신한금융투자', ENG: 'SHINHAN_INVESTMENT' },
    { CODE1: '048', CODE2: '48', KOR: '신협', ENG: 'SHINHYEOP' },
    { CODE1: '027', CODE2: '27', KOR: '씨티은행', ENG: 'CITI' },
    { CODE1: '020', CODE2: '20', KOR: '우리은행', ENG: 'WOORI' },
    { CODE1: '071', CODE2: '71', KOR: '우체국', ENG: '우체국예금보험' },
    { CODE1: '209', CODE2: 'S0', KOR: '유안타증권', ENG: 'YUANTA_SECURITES' },
    { CODE1: '280', CODE2: 'SJ', KOR: '유진투자증권', ENG: 'EUGENE_INVESTMENT_AND_SECURITIES' },
    { CODE1: '050', CODE2: '50', KOR: '저축은행중앙회', ENG: 'SAVINGBANK' },
    { CODE1: '037', CODE2: '37', KOR: '전북은행', ENG: 'JEONBUKBANK' },
    { CODE1: '035', CODE2: '35', KOR: '제주', ENG: 'JEJUBANK' },
    { CODE1: '090', CODE2: '90', KOR: '카카오', ENG: 'KAKAOBANK' },
    { CODE1: '089', CODE2: '89', KOR: '케이뱅크', ENG: 'KBANK' },
    { CODE1: '092', CODE2: '92', KOR: '토스뱅크', ENG: 'TOSSBANK' },
    { CODE1: '271', CODE2: 'ST', KOR: '토스증권', ENG: 'TOSS_SECURITIES' },
    { CODE1: '294', CODE2: 'SR', KOR: '펀드온라인코리아', ENG: 'KOREA_FOSS_SECURITIES' },
    { CODE1: '270', CODE2: 'SH', KOR: '하나금융투자', ENG: 'HANA_INVESTMENT_AND_SECURITIES' },
    { CODE1: '081', CODE2: '81', KOR: '하나은행', ENG: 'HANA' },
    { CODE1: '262', CODE2: 'S9', KOR: '하이투자증권', ENG: 'HI_INVESTMENT_AND_SECURITIES' },
    { CODE1: '243', CODE2: 'S6', KOR: '한국투자증권', ENG: 'KOREA_INVESTMENT_AND_SECURITIES' },
    { CODE1: '269', CODE2: 'SG', KOR: '한화투자증권', ENG: 'HANHWA_INVESTMENT_AND_SECURITIES' },
    { CODE1: '263', CODE2: 'SA', KOR: '현대차증권', ENG: 'HYUNDAI_MOTOR_SECURITIES' },
    { CODE1: '279', CODE2: 'SI', KOR: 'DB금융투자', ENG: 'DB_INVESTMENT_AND_SECURITIES' },
    { CODE1: '031', CODE2: '31', KOR: 'DGB대구은행', ENG: 'DAEGUBANK' },
    { CODE1: '003', CODE2: '03', KOR: 'IBK기업은행', ENG: 'IBK' },
    { CODE1: '004', CODE2: '06', KOR: 'KB국민은행', ENG: 'KOOKMIN' },
    { CODE1: '218', CODE2: 'S4', KOR: 'KB증권', ENG: 'KB_SECURITIES' },
    { CODE1: '002', CODE2: '02', KOR: 'KDB산업은행', ENG: 'KDBBANK' },
    { CODE1: '227', CODE2: 'SP', KOR: 'KTB투자증권(다올투자증권)', ENG: 'DAOL_INVESTMENT_AND_SECURITIES' },
    { CODE1: '292', CODE2: 'SO', KOR: 'LIG투자증권', ENG: 'LIG_INVESTMENT_AND_SECURITIES' },
    { CODE1: '011', CODE2: '11', KOR: 'NH농협은행', ENG: 'NONGHYEOP' },
    { CODE1: '247', CODE2: 'SL', KOR: 'NH투자증권', ENG: 'NH_INVESTMENT_AND_SECURITIES' },
    { CODE1: '023', CODE2: '23', KOR: 'SC제일은행', ENG: 'SC' },
    { CODE1: '007', CODE2: '07', KOR: 'Sh수협은행', ENG: 'SUHYEOP' },
    { CODE1: '266', CODE2: 'SD', KOR: 'SK증권', ENG: 'SK_SECURITIES' },
];

/*********************************************************************************
 * Q. 어떤 은행에서 가상계좌를 발급받을 수 있나요?
 * A. 경남은행, 광주은행, KB국민은행, IBK기업은행, NH농협은행, DGB대구은행, 부산은행, 새마을금고,
 * Sh수협은행, 신한은행, 우리은행, 우체국예금보험, 전북은행, 하나은행에서 가상계좌를 발급받을 수 있습니다.
 *********************************************************************************/
exports.VIRTUAL_BANK_CODE = [
    { CODE1: '039', CODE2: '39', KOR: '경남은행', ENG: 'KYONGNAMBANK' },
    { CODE1: '034', CODE2: '34', KOR: '광주은행', ENG: 'GWANGJUBANK' },
    { CODE1: '004', CODE2: '06', KOR: 'KB국민은행', ENG: 'KOOKMIN' },
    { CODE1: '003', CODE2: '03', KOR: 'IBK기업은행', ENG: 'IBK' },
    { CODE1: '011', CODE2: '11', KOR: 'NH농협은행', ENG: 'NONGHYEOP' },
    { CODE1: '031', CODE2: '31', KOR: 'DGB대구은행', ENG: 'DAEGUBANK' },
    { CODE1: '290', CODE2: 'SM', KOR: '부산은행', ENG: 'BUSANBANK' },
    { CODE1: '045', CODE2: '45', KOR: '새마을금고', ENG: 'SAEMAUL' },
    { CODE1: '007', CODE2: '07', KOR: 'Sh수협은행', ENG: 'SUHYEOP' },
    { CODE1: '088', CODE2: '88', KOR: '신한은행', ENG: 'SHINHAN' },
    { CODE1: '020', CODE2: '20', KOR: '우리은행', ENG: 'WOORI' },
    { CODE1: '071', CODE2: '71', KOR: '우체국', ENG: '우체국예금보험' },
    { CODE1: '037', CODE2: '37', KOR: '전북은행', ENG: 'JEONBUKBANK' },
    { CODE1: '081', CODE2: '81', KOR: '하나은행', ENG: 'HANA' },
];

/**
 * 은행 코드 - 코드
 * @param code
 * @returns {{CODE2: string, CODE1: string, KOR: string, ENG: string} | {CODE2: string, CODE1: string, KOR: string, ENG: string} | {CODE2: string, CODE1: string, KOR: string, ENG: string} | {CODE2: string, CODE1: string, KOR: string, ENG: string} | {CODE2: string, CODE1: string, KOR: string, ENG: string}}
 */
exports.findBankCodeDataByCode = (code) => {
    return this.BANK_CODE.find((data) => data.CODE1 === code || data.CODE2 === code);
};

/**
 * 은행 코드 찾기 - 은행 이름
 * @param codeName
 * @returns {{CODE2: string, CODE1: string, KOR: string, ENG: string}}
 */
exports.findBankCodeDataByCodeName = (codeName) => {
    return this.BANK_CODE.find((data) => data.KOR === codeName);
};
