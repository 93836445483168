import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { h_px10rate, h_px14rate, h_px16rate, h_px18rate, h_px1rate, h_px31rate, h_px5rate, h_px8rate, w_px5rate } from '../../size';
import CheckBox from '../check_box/CheckBox';
import { rgb_0_0_0, rgb_153_204_255, rgb_205_205_205, rgb_255_255_255, rgba_0_0_0_0d1, rgba_66_139_247_0d1 } from '../../colors';
import AdminMemoModalContext from '../../../data_system/contexts/modal_context/admin_memo_modal_context';
import { SquareAndPencil1x } from '../../icons/icons';
import { format_phone, phone_reg } from '../../../data_system/Regexp/Regexps';
import ContentText from '../content_text/ContentText';

const CustomTable = ({ headerStyle, adminIdKey, trStyle, rowStyle, component, header, data, onRowClick, setCheck, checkList, tdStyle, combineFormat, adminMemoType, topComponent, onFilter }) => {
    return (
        <>
            {topComponent ? topComponent : null}
            <table
                style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                }}
            >
                <CustomTableHeader
                    onFilter={onFilter}
                    header={header}
                    data={data}
                    headerStyle={headerStyle}
                    trStyle={trStyle}
                    setCheck={setCheck}
                    combineFormat={combineFormat}
                    adminMemoType={adminMemoType}
                />
                <CustomTableBody
                    header={header}
                    rowStyle={rowStyle}
                    tdStyle={tdStyle}
                    data={data}
                    component={component}
                    onRowClick={onRowClick}
                    setCheck={setCheck}
                    checkList={checkList}
                    adminMemoType={adminMemoType}
                    adminIdKey={adminIdKey}
                />
            </table>
        </>
    );
};

const CustomTableHeader = ({ header, headerStyle, trStyle, setCheck, data, combineFormat, adminMemoType, onFilter }) => {
    const [headerData, setHeaderData] = useState([]);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        if (combineFormat) {
            const tmp = [];

            header.map((item, index) => {
                let temp = { ...item };
                for (const combine of combineFormat) {
                    if (combine.start <= index && combine.end >= index) {
                        temp = {
                            ...item,
                            subTr: true,
                            colSpan: combine.end - combine.start + 1,
                            render: combine.start === index,
                            spanValue: combine.value,
                            divid: index === combine.end,
                        };
                        tmp.push(temp);
                        return;
                    }
                }
                temp.rowSpan = 2;
                temp.render = true;
                tmp.push(temp);
            });
            setHeaderData(tmp);
        }
    }, [header]);

    return (
        <thead
            style={{
                width: '100%',
                fontFamily: 'noto_sans_kr_medium',
                fontSize: h_px18rate,

                ...headerStyle,
            }}
            align={'left'}
        >
            {combineFormat ? (
                <>
                    <tr
                        style={{
                            borderTop: `${h_px1rate}px solid ${rgb_0_0_0}`,
                        }}
                    >
                        {headerData.map((item, index) => {
                            if (item.render) {
                                return (
                                    <th
                                        style={{
                                            borderLeft: `${index === 0 ? 0 : h_px1rate}px solid ${rgb_205_205_205}`,
                                            borderRight: `${index === headerData.length - 1 ? 0 : h_px1rate}px solid ${rgb_205_205_205}`,
                                            borderBottom: `${item.colSpan ? h_px1rate : 0}px solid ${rgb_205_205_205}`,
                                        }}
                                        colSpan={item.colSpan ? item.colSpan : 1}
                                        rowSpan={item.rowSpan ? item.rowSpan : 1}
                                        key={'customHeader_' + index}
                                    >
                                        <div
                                            style={{
                                                paddingBlock: h_px31rate,
                                                height: '100%',
                                                width: '100%',
                                                position: 'relative',
                                                ...trStyle,
                                            }}
                                        >
                                            {item.subTr ? item.spanValue : item.value}
                                        </div>
                                        {item.filter && !item.subTr ? (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    display: 'flex',
                                                }}
                                            >
                                                {item.filter.map((item, index) => {})}
                                            </div>
                                        ) : null}
                                    </th>
                                );
                            }
                        })}
                        {adminMemoType ? (
                            <th
                                style={{
                                    borderBottom: `${h_px1rate}px solid ${rgb_205_205_205}`,
                                }}
                                rowSpan={2}
                            >
                                <div
                                    style={{
                                        paddingBlock: h_px31rate,
                                        height: '100%',
                                        width: '100%',

                                        ...trStyle,
                                    }}
                                >
                                    관리자 메모
                                </div>
                            </th>
                        ) : null}
                    </tr>
                    <tr
                        style={{
                            borderBottom: `${h_px1rate}px solid ${rgb_0_0_0}`,
                        }}
                    >
                        {headerData
                            .filter((item) => item.subTr === true)
                            .map((item, index) => {
                                return (
                                    <th style={{ borderRight: `${item.divid ? h_px1rate : 0}px solid ${rgb_205_205_205}` }} key={'customHeader_' + index}>
                                        <div
                                            style={{
                                                paddingBlock: h_px31rate,
                                                height: '100%',
                                                width: '100%',
                                                ...trStyle,
                                            }}
                                        >
                                            {item.value}
                                        </div>
                                    </th>
                                );
                            })}
                    </tr>
                </>
            ) : (
                <tr
                    style={{
                        borderBottom: `${h_px1rate}px solid black`,
                        borderTop: `${h_px1rate}px solid black`,
                    }}
                >
                    {setCheck ? (
                        <th
                            style={{
                                borderSpacing: 0,
                                paddingTop: h_px31rate,
                                paddingBottom: h_px31rate,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                ...trStyle,
                            }}
                        >
                            <CheckBox
                                checked={checkAll}
                                onClick={() => {
                                    if (checkAll) {
                                        setCheck([]);
                                    } else {
                                        setCheck(data);
                                    }
                                    setCheckAll(!checkAll);
                                }}
                            />
                        </th>
                    ) : null}
                    {header.map((item, index) => {
                        return (
                            <HeaderCell
                                // key={'customHeader_' + index}
                                key={'header_cell_' + index}
                                onFilter={onFilter}
                                item={item}
                                value={item.value}
                                trStyle={trStyle}
                            />
                        );
                    })}
                    {adminMemoType ? (
                        <th>
                            <div
                                style={{
                                    paddingBlock: h_px31rate,
                                    height: '100%',
                                    width: '100%',
                                    ...trStyle,
                                }}
                            >
                                관리자 메모
                            </div>
                        </th>
                    ) : null}
                </tr>
            )}
        </thead>
    );
};
const HeaderCell = ({ value, trStyle, item, onFilter }) => {
    const [openFilter, setOpenFilter] = useState(false);
    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };
    return (
        <th style={{ position: 'relative', textAlign: 'center' }}>
            <div
                style={{
                    paddingBlock: h_px31rate,
                    height: '100%',
                    width: '100%',

                    ...trStyle,
                }}
                onClick={toggleFilter}
            >
                {value === '성별' || value === '카메라 인증 결과' ? <CursorTr>{value}</CursorTr> : <HeadText>{value}</HeadText>}
            </div>
            {item.filter && !item.subTr ? (
                <div
                    style={{
                        position: 'absolute',

                        display: openFilter ? 'flex' : 'none',
                        flexDirection: 'column',
                        backgroundColor: rgb_255_255_255,
                        border: `1px solid ${rgb_153_204_255}`,
                        boxSizing: 'border-box',
                        paddingInline: w_px5rate,
                        paddingBlock: h_px10rate,
                        zIndex: 999,
                    }}
                >
                    {item.filter.map((filter, index) => (
                        <div
                            style={{
                                display: 'flex',
                                marginBlock: h_px5rate,
                            }}
                            key={`filter_${item.key}_${filter.key}`}
                        >
                            <ContentText style={{ flex: 1 }}>{filter.value}</ContentText>
                            <CheckBox
                                checked={false}
                                onClick={(checked) => {
                                    onFilter(item.key, filter.key, checked);
                                }}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
        </th>
    );
};

const CustomTableBody = (props) => {
    const { admin_memo_modal_actions } = useContext(AdminMemoModalContext);
    const { data, tbodyStyle, component, header, tdStyle, onRowClick, setCheck, checkList, rowStyle, adminMemoType, adminIdKey } = props;

    const openAdminMemo = (item) => {
        admin_memo_modal_actions.set_DETAIL_DATA_TYPE(adminMemoType);
        admin_memo_modal_actions.setId(item[adminIdKey]);
        admin_memo_modal_actions.setAdminMemoMessage(item.admin_memo);
        admin_memo_modal_actions.setModalVisible(true);
    };

    return (
        <tbody
            style={{
                fontFamily: 'noto_sans_kr_medium',
                fontSize: h_px14rate,
                marginTop: h_px8rate,
                ...tbodyStyle,
            }}
        >
            {data.map((item, index) => {
                return (
                    <CustomTr key={'customTable_' + index} style={{ ...rowStyle }} onClick={(e) => (onRowClick ? onRowClick(item, e) : null)}>
                        {setCheck && checkList ? (
                            <th
                                style={{
                                    paddingTop: h_px16rate,
                                    paddingBottom: h_px16rate,
                                    ...tdStyle,
                                }}
                            >
                                <CheckBox
                                    checked={checkList.findIndex((e) => e[adminIdKey ?? 'id'] === item[adminIdKey ?? 'id']) !== -1}
                                    onClick={() => {
                                        const tmp = [...checkList];
                                        const index = checkList.findIndex((e) => e[adminIdKey ?? 'id'] === item[adminIdKey ?? 'id']);

                                        if (index !== -1) {
                                            tmp.splice(index, 1);
                                        } else {
                                            tmp.push(item);
                                        }
                                        setCheck(tmp);
                                    }}
                                />
                            </th>
                        ) : null}

                        {header.map((head, index) => {
                            if (component) {
                                return component(
                                    head.key,
                                    item[head.key],
                                    index,
                                    {
                                        paddingTop: h_px16rate,
                                        paddingBottom: h_px16rate,
                                        ...tdStyle,
                                    },
                                    item,
                                );
                            } else {
                                return (
                                    <td
                                        key={'customTd_' + index}
                                        style={{
                                            paddingTop: h_px16rate,
                                            paddingBottom: h_px16rate,
                                            ...tdStyle,
                                        }}
                                    >
                                        {phone_reg.test(item[head.key]) ? format_phone(item[head.key]) : item[head.key]}
                                    </td>
                                );
                            }
                        })}
                        {adminMemoType ? (
                            <th
                                style={{
                                    paddingTop: h_px16rate,
                                    paddingBottom: h_px16rate,
                                    ...tdStyle,
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={() => openAdminMemo(item)}>
                                    <SquareAndPencil1x />
                                </div>
                            </th>
                        ) : null}
                    </CustomTr>
                );
            })}
        </tbody>
    );
};

export default CustomTable;

const HeadText = styled.div`
    padding: 0;
`;

const CustomTr = styled.tr`
    cursor: pointer;
    text-align: center;
    /* background-color: #f3f3f3; */
    border-bottom: ${h_px1rate}px solid ${rgba_0_0_0_0d1};
    &:hover {
        background-color: ${rgba_66_139_247_0d1};
    }
`;

const CursorTr = styled.div`
    cursor: pointer;

    :hover {
        color: gray;
    }
`;
