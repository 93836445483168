import React, { useEffect, useState } from 'react';

// styled-components
import { CloseOutlined } from '@ant-design/icons';
import {
    ModalWindow,
    ChooseIntersetBox,
    TitleLineWrapper,
    Title,
    KategoryWrapper,
    KategorySubTitle,
    Item,
    SaveButtonWrapper,
    SaveButton,
} from '../styles/AddGatheringModalStyled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { requestGetQuestionCategory } from '../../data_system/redux/modules/question.reducer/QuestionReducer';

function ChoisCategoryModal({
    setInterestToggle,
    interestCodeLists,
    setInterestCodeLists,
    mainCategoryNum,
    setMainCategoryNum,
    categoryNum,
    setCategoryNum,
}) {
    const dispatch = useDispatch();
    // useSelector
    const { questionCategoryLists } = useSelector((state) => state.questionReducer);
    // state
    const [categoryCodeLists, setCategoryCodeLists] = useState([]);

    /*********************** useEffect ***********************/

    useEffect(() => {
        setCategoryCodeLists(questionCategoryLists?.findCategoryLists);
    }, [questionCategoryLists]);

    // 카데고리 불러오기
    useEffect(() => {
        getCategoryCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                onClickClose();
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*********************** function ***********************/

    // 카테고리 가져오기 함수
    const getCategoryCode = () => {
        dispatch(requestGetQuestionCategory());
    };

    // 여행 > 체크박스 단일 선택
    const handleSingleCheck = (checked, key, main_id, id) => () => {
        let arr = [];
        let mainNumArr = [];
        let numArr = [];
        if (interestCodeLists && categoryNum) {
            arr = [...interestCodeLists];
            mainNumArr = [...mainCategoryNum];
            numArr = [...categoryNum];
        }

        if (!checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            arr.push(key);
            mainNumArr.push(main_id);
            numArr.push(id);

            setInterestCodeLists(arr);
            setMainCategoryNum(mainNumArr);
            setCategoryNum(numArr);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setInterestCodeLists(interestCodeLists.filter((el) => el !== key));
            setMainCategoryNum(mainCategoryNum.filter((el) => el !== main_id));
            setCategoryNum(categoryNum.filter((el) => el !== id));
        }
    };

    // 모달창 닫기
    const onClickClose = () => {
        setInterestToggle(false);
    };

    // 선택 카테고리 저장
    const onClickSave = () => {
        onClickClose();
    };

    /************************* html *************************/
    return (
        <ModalWindow>
            <ChooseIntersetBox>
                <TitleLineWrapper>
                    <Title>카테고리 분류</Title>
                    <CloseOutlined className="close-btn" onClick={onClickClose} />
                </TitleLineWrapper>

                {questionCategoryLists?.findMainCategoryLists?.map((data, index) => {
                    return (
                        <div key={index}>
                            <KategorySubTitle>{data.main_category_name}</KategorySubTitle>
                            <KategoryWrapper>
                                {categoryCodeLists?.map((item, index) => {
                                    return (
                                        item.main_id === data.main_id && (
                                            <Item
                                                key={index}
                                                onClick={handleSingleCheck(
                                                    interestCodeLists?.includes(item.sub_category_name),
                                                    item.sub_category_name,
                                                    item.main_id,
                                                    item.id,
                                                )}
                                                checked={
                                                    interestCodeLists?.includes(item.sub_category_name) &&
                                                    mainCategoryNum.includes(item.main_id)
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {item.sub_category_name}
                                            </Item>
                                        )
                                    );
                                })}
                            </KategoryWrapper>
                        </div>
                    );
                })}

                <SaveButtonWrapper id="save-button">
                    <SaveButton onClick={onClickSave}>닫기</SaveButton>
                </SaveButtonWrapper>
            </ChooseIntersetBox>
        </ModalWindow>
    );
}

export default ChoisCategoryModal;
