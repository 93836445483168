import { MiddleModal } from '../../common/BootStrap/Modal';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { MText } from '../../designComponents/text/ContentText';
import { dateFilter } from '../../utils/textFilter';

const AlarmTalkDetailModal = ({ item, visible, setVisible }) => {
    if (!item) {
        return <div />;
    }
    return (
        <MiddleModal title={'알림톡 전송 내역'} visible={visible} setVisible={setVisible}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FlexMainWrapper>
                    <Item title={'MessageId'} content={item.messageId} />
                    <Item title={'템플릿 코드'} content={item.templateCode} />
                </FlexMainWrapper>
                <FlexMainWrapper>
                    <Item title={'요청 시간'} content={dateFilter(item.requestTime)} />
                    <Item title={'요청 결과'} content={`${item.requestStatusCode}/${item.requestStatusDesc}`} />
                </FlexMainWrapper>
                <FlexMainWrapper>
                    <Item title={'발송 시간'} content={dateFilter(item.completeTime)} />
                    <Item title={'발송 결과'} content={`${item.messageStatusCode}/${item.messageStatusDesc}`} />
                </FlexMainWrapper>
                <Item title={'전화번호'} content={item.to} single />
                <Item title={'내용'} content={item.content} single />
            </div>
        </MiddleModal>
    );
};

const Item = (props) => {
    return (
        <FlexMainWrapper style={{ gap: 8, alignItems: 'center', flex: 1, marginBlock: 8 }}>
            <MText text={props.title} style={{ flex: 1 }} color={'rgba(46,47,51,0.88)'} />
            <MText text={props.content} color={props.color} style={{ flex: props.single ? 9 : 4 }} />
        </FlexMainWrapper>
    );
};

export default AlarmTalkDetailModal;
