import styled from 'styled-components';

// 댓글 CSS
export const CommentBox = styled.div`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    width: 96%;
    padding: 10px 0;

    &:hover {
        background-color: #f2f2f2;
    }
`;

export const UserImage = styled.img`
    object-fit: cover;

    border: 1px solid #cccccc;
    border-radius: 50%;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

export const Name = styled.div`
    font-size: 13px;
    font-weight: 600;
`;

export const Comment = styled.div`
    font-size: 14px;
    font-weight: 500;
`;

export const CommentDate = styled.span`
    font-size: 12px;
    color: gray;

    margin-left: 10px;
`;

export const DeleteComment = styled.div`
    text-align: end;
    font-size: 12px;
    text-decoration: underline;

    .delte-btn {
        cursor: pointer;
    }

    .delte-btn:hover {
        color: gray;
    }
`;
