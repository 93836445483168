import React, { useState } from 'react';

import FriendsReportPage from '../../components/ReportComponent/FriendsReportPage';
import ContentsReportPage from '../../components/ReportComponent/ContentsReportPage';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

/** 신고페이지 */
const Report = () => {
    const [onClickReportPageToggle, setOnClickReportPageToggle] = useState(true);
    const [onClickGatheringReportPageToggle, setOnClickGatheringReportPageToggle] = useState(false);

    const onClickReport = () => {
        setOnClickReportPageToggle(true);
        setOnClickGatheringReportPageToggle(false);
    };

    const onClickGatheringReport = () => {
        setOnClickReportPageToggle(false);
        setOnClickGatheringReportPageToggle(true);
    };

    const renderItem = [
        { text: '단짝 신고', toggle: onClickReportPageToggle, onPress: onClickReport },
        { text: '게시글 신고', toggle: onClickGatheringReportPageToggle, onPress: onClickGatheringReport },
    ];
    return (
        <Wrapper02>
            <MainPageTitle rederItem={renderItem} sigle={false} />
            {onClickReportPageToggle && <FriendsReportPage />}
            {onClickGatheringReportPageToggle && <ContentsReportPage />}
        </Wrapper02>
    );
};

export default Report;
