export const contentsListsTableFilter = [
    {
        key: 'id',
        value: '게시글 ID',
    },
    {
        key: 'created_at',
        value: '작성일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'deleted_at',
        value: '삭제여부',
        filter: [
            {
                key: 1,
                value: 'ON',
            },
            {
                key: 0,
                value: 'OFF',
            },
        ],
    },
    {
        key: 'content_type',
        value: '게시글 유형',
        filter: [
            {
                key: 4,
                value: '첫 인사',
            },
            {
                key: 1,
                value: '일반',
            },
            {
                key: 3,
                value: '공지',
            },
            {
                key: 5,
                value: '후기',
            },
        ],
    },
    {
        key: 'content_title',
        value: '게시글 제목',
    },
    {
        key: 'content',
        value: '게시글 내용',
    },
    {
        key: 'contents_image_url',
        value: '게시글 이미지',
    },
    {
        key: 'blur_yn',
        value: '노출여부',
        filter: [
            {
                key: 0,
                value: 'ON',
            },
            {
                key: 1,
                value: 'OFF',
            },
        ],
    },
    {
        key: 'look_cnt',
        value: '조회수',
    },
    {
        key: 'empathy_cnt',
        value: '공감수',
    },
    {
        key: 'user_name',
        value: '작성자',
    },
    {
        key: 'comment_cnt',
        value: '댓글',
    },
    {
        key: 'gathering_name',
        value: '모임명',
    },
];
