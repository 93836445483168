import React, { useState, useCallback, useRef, useEffect } from 'react';

// S3-route
import { main_server_image_ip } from '../../communication_system/communication_ips';
// api
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// styled-components
import { PlusOutlined } from '@ant-design/icons';
import { ImageInfoWrapper, SubTitle, AddImageBtnWrapper, AddImageBtn, InfoWrapper, InputWrapper, RadioInput, Image } from '../styles/ActivityDetailModalInfoEntryStyled';

// components
import styled from 'styled-components';

// 카테고리 객체 리스트
export const categoryDropdownList = [
    {
        id: 0,
        title: '여행',
        selected: false,
        key: 'travel',
    },
    {
        id: 1,
        title: '교육',
        selected: false,
        key: 'education',
    },
    {
        id: 2,
        title: '문화/뷰티',
        selected: false,
        key: 'culture_and_beauty',
    },
    {
        id: 3,
        title: '레저/건강',
        selected: false,
        key: 'leisure_and_health',
    },
];

// 기본정보 기입
function AddActivityModalInfoEntry({ setInfoParams, allHosts }) {
    const [imageArray, setImageArray] = useState([]);
    const [category, setCategory] = useState(null);
    const [host, setHost] = useState(null);
    const [exposure, setExposure] = useState(null);
    const [crawling, setCrawling] = useState(null);

    useEffect(() => {
        setHost(allHosts && allHosts[0]?.id);
        setCategory(categoryDropdownList[0].id);
    }, [allHosts]);

    useEffect(() => {
        setInfoParams({
            detail_image_list: imageArray,
            category: category,
            host_id: host,
            exposure_yn: exposure,
            crawling_yn: crawling,
        });
    }, [setInfoParams, imageArray, category, host, exposure, crawling]);

    /******************** function ********************/

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickImageUpload = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback((e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image_url', f);
        });

        // 이미지 form 담기
        requestServer(imageFormData);
    }, []);

    // 이미지를 서버로 보낸다.
    const requestServer = async (FormData) => {
        const result = await RefreshCheckApi.post('adminActivity/transform', FormData);

        setTimeout(() => {
            setImageArray(result.data.data.image_url);
        }, 1200);
    };

    // 노출상태 변환
    const onChangeExposure = (num) => () => {
        setExposure(num);
    };

    // 크롤링 여부 변환
    const onChangeCrawling = (num) => () => {
        setCrawling(num);
    };

    // 카테고리 선택
    const onChangeCategory = useCallback((e) => {
        setCategory(e.target.value);
    }, []);

    // 호스트 선택
    const onChangeHost = useCallback((e) => {
        setHost(e.target.value);
    }, []);

    /******************** html ********************/
    return (
        <>
            <ImageInfoWrapper id="thumbnail" style={{ gap: '0px' }}>
                <SubTitle>썸네일</SubTitle>
                <AddImageBtnWrapper>
                    <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                    <AddImageBtn onClick={onClickImageUpload}>
                        <PlusOutlined />
                        <div>사진 불러오기</div>
                    </AddImageBtn>
                </AddImageBtnWrapper>
            </ImageInfoWrapper>

            <div id="image-box" style={{ width: '310px', display: 'table' }}>
                {imageArray.length > 0 && (
                    <div style={{ height: '220px', overflow: 'auto' }}>
                        {imageArray?.map((src, index) => {
                            return <Image style={{ width: '100px', height: '100px' }} src={main_server_image_ip + src} alt="이미지" key={index} />;
                        })}
                    </div>
                )}
            </div>

            <InfoWrapper id="category">
                <SubTitle>카테고리</SubTitle>
                <Select onChange={onChangeCategory}>
                    {categoryDropdownList.map((data, index) => {
                        return (
                            <option value={data.id} key={index}>
                                {data.title}
                            </option>
                        );
                    })}
                </Select>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>호스트</SubTitle>
                <Select onChange={onChangeHost}>
                    {allHosts?.map((data, index) => {
                        return (
                            <option value={data.id} key={index}>
                                {data.host_name}
                            </option>
                        );
                    })}
                </Select>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>노출 상태</SubTitle>
                <InputWrapper>
                    <div className="check-box">
                        <RadioInput checked={exposure === 1 ? true : false} onChange={onChangeExposure(1)} />
                        <span>ON</span>
                    </div>
                    <div className="check-box">
                        <RadioInput checked={exposure === 0 ? true : false} onChange={onChangeExposure(0)} />
                        <span>OFF</span>
                    </div>
                </InputWrapper>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>크롤링 여부</SubTitle>
                <InputWrapper>
                    <div className="check-box">
                        <RadioInput checked={crawling === 1 ? true : false} onChange={onChangeCrawling(1)} />
                        <span>Y</span>
                    </div>
                    <div className="check-box">
                        <RadioInput checked={crawling === 0 ? true : false} onChange={onChangeCrawling(0)} />
                        <span>N</span>
                    </div>
                </InputWrapper>
            </InfoWrapper>
        </>
    );
}

export default AddActivityModalInfoEntry;

const Select = styled.select`
    width: 170px;
    height: 35px;

    outline: none;
    border-color: rgba(66, 139, 247, 0.2);
`;
