import React, {useCallback, useContext, useMemo, useState} from "react";
import styled from "styled-components";
import {
    rgb_0_0_0,
    rgb_205_205_205,
    rgb_255_16_125,
    rgb_255_255_255,
    rgb_48_94_255,
    rgb_66_139_247,
    rgba_66_139_247_0d05,
} from "../../colors";
import {
    h_px14rate,
    h_px51rate,
    h_px62d5rate,
    h_px671d5rate,
    h_px735d5rate,
    w_px11rate,
    w_px1226rate,
    w_px146rate,
    w_px25rate,
    w_px362rate,
    w_px864rate,
} from "../../size";
import {
    AGE,
    AGREE,
    CAMERA_AUTH_STATE,
    CHECK_BOX,
    COUPON_STATE,
    DATE,
    EVENT_IMAGE,
    HEADER_CHECK_BOX,
    HEADER_TITLE,
    MANAGER_MEMO,
    NAME,
    NUMBER,
    ON_OFF_STATE,
    PAYMENT_STATE,
    PRICE,
    PUSH_STATE,
    RATE,
    REVIEW,
    SEX_STATE,
    STRING,
    SUBSCRIBE_STATE,
    USE_REJECT_STATE,
    VIEW_STATE,
} from "../../../data_system/data_type/data_type";
import Pagination from "./pagination/Pagination";
import DetailTotalForm from "./detail/forms/detail_total_form/DetailTotalForm";
import {table_date_function} from "../../../data_system/data_functions/table_date_function/table_date_function";
import {table_price_function} from "../../../data_system/data_functions/table_price_function/table_price_function";
import {
    table_payment_state_text_function
} from "../../../data_system/data_functions/table_payment_state_text_function/table_payment_state_text_function";
import CheckBox from "../check_box/CheckBox";
import produce from "immer";
import {main_server_image_ip} from "../../../communication_system/communication_ips";
import TooltipCard from "../tooltip_card/TooltipCard";
import {
    ACTIVITY_DETAIL_COMPONENT,
    ACTIVITY_PAYMENT_DETAIL_COMPONENT,
    ADMINISTRATOR_DETAIL_COMPONENT,
    ANNOUNCEMENT_DETAIL_COMPONENT,
    CHATTING_DETAIL_COMPONENT,
    COUPON_DETAIL_COMPONENT,
    EVENT_DETAIL_COMPONENT,
    HOST_DETAIL_COMPONENT,
    NONE_DETAIL_COMPONENT,
    POINT_DETAIL_COMPONENT,
    REPORT_DETAIL_COMPONENT,
    SUBSCRIBE_PAYMENT_DETAIL_COMPONENT,
    USER_DETAIL_COMPONENT,
} from "../../../data_system/detail_data_type/detail_data_type";
import MatchDispatchCenter from "../../../data_system/redux/modules/match_dispatch_center/match_dispatch_center";
import TotalTopSection from "../total_top_section/TotalTopSection";
import AdminMemoModalContext from "../../../data_system/contexts/modal_context/admin_memo_modal_context";
import ReviewListModalContext from "../../../data_system/contexts/modal_context/review_list_modal_context";
import ChattingTitleContext from "../../../data_system/contexts/chatting_title_context/chatting_title_context";

const AdminTable = ({
  structureInfo,
  headerTitle,
  bodyContents,
  DETAIL_DATA_TYPE,
  DETAIL_FORM_TYPE,
  DETAIL_DATA_REGISTER,
  total_top_section_disable,
  detail_disable,
  administrator_total_number_disable,
  make_disable,
  remove_disable,
  payment_apply_state_disable,
  current_subscribe_user_state_disable,
  current_active_host_state_disable,
  reward_part_total_number_disable,
}) => {
  const { admin_memo_modal_actions } = useContext(AdminMemoModalContext);
  const { review_list_modal_actions } = useContext(ReviewListModalContext);
  const { chatting_title_actions } = useContext(ChattingTitleContext);

  const [itemOpen, setItemOpen] = useState(false);
  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [innerHeaderTitle, setInnerHeaderTitle] = useState(headerTitle);
  const [registerOpen, setRegisterOpen] = useState(false);

  const onClickRow = useCallback(
    (rowIndex) => {
      if (!detail_disable) {
        setChoiceRowIndex((pre) =>
          pre === null ? rowIndex : pre === rowIndex ? null : rowIndex
        );
        setRegisterOpen(false);
        if (choiceRowIndex === null) {
          setItemOpen(true);
        } else {
          if (choiceRowIndex === rowIndex) {
            setItemOpen(false);
          } else {
            setItemOpen(true);
          }
        }
      }

      if (DETAIL_DATA_TYPE === CHATTING_DETAIL_COMPONENT) {
        chatting_title_actions.setTitle(
          `${bodyContents[rowIndex]["data"][1]["data"]} / ${bodyContents[rowIndex]["data"][2]["data"]}`
        );
      }
    },
    [
      choiceRowIndex,
      detail_disable,
      DETAIL_DATA_TYPE,
      bodyContents,
      chatting_title_actions,
    ]
  );

  const offset = useMemo(() => {
    return (page - 1) * limit;
  }, [page]);

  const totalPages = useMemo(() => {
    return Math.ceil(bodyContents.length / limit);
  }, [bodyContents]);

  const lastFullPage = useMemo(() => {
    return parseInt(bodyContents.length / limit);
  }, [bodyContents]);

  const { onSetAdministrators, onSetAnnouncements } = MatchDispatchCenter();

  const onClickHeaderCheckBox = useCallback(() => {
    if (parseInt(innerHeaderTitle[0].data) === 0) {
      setInnerHeaderTitle(
        produce(innerHeaderTitle, (innerHeaderTitleDraft) => {
          innerHeaderTitleDraft[0].data = 1;
        })
      );
      if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
        if (page > lastFullPage) {
          onSetAdministrators({
            administrators: produce(bodyContents, (bodyContentsDraft) => {
              for (
                let i = (page - 1) * limit;
                i < bodyContents.length % limit;
                i++
              ) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 1;
                }
              }
            }),
          });
        } else {
          onSetAdministrators({
            administrators: produce(bodyContents, (bodyContentsDraft) => {
              for (let i = (page - 1) * limit; i < page * limit; i++) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 1;
                }
              }
            }),
          });
        }
      } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === REPORT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === CHATTING_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ACTIVITY_PAYMENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === HOST_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ACTIVITY_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === POINT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === COUPON_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === EVENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ANNOUNCEMENT_DETAIL_COMPONENT) {
        if (page > lastFullPage) {
          onSetAnnouncements({
            announcements: produce(bodyContents, (bodyContentsDraft) => {
              for (
                let i = (page - 1) * limit;
                i < bodyContents.length % limit;
                i++
              ) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 1;
                }
              }
            }),
          });
        } else {
          onSetAnnouncements({
            announcements: produce(bodyContents, (bodyContentsDraft) => {
              for (let i = (page - 1) * limit; i < page * limit; i++) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 1;
                }
              }
            }),
          });
        }
      } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
      }
    } else {
      setInnerHeaderTitle(
        produce(innerHeaderTitle, (innerHeaderTitleDraft) => {
          innerHeaderTitleDraft[0].data = 0;
        })
      );
      if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
        if (page > lastFullPage) {
          onSetAdministrators({
            administrators: produce(bodyContents, (bodyContentsDraft) => {
              for (
                let i = (page - 1) * limit;
                i < bodyContents.length % limit;
                i++
              ) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 0;
                }
              }
            }),
          });
        } else {
          onSetAdministrators({
            administrators: produce(bodyContents, (bodyContentsDraft) => {
              for (let i = (page - 1) * limit; i < page * limit; i++) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 0;
                }
              }
            }),
          });
        }
      } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === REPORT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === CHATTING_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ACTIVITY_PAYMENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === HOST_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ACTIVITY_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === POINT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === COUPON_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === EVENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ANNOUNCEMENT_DETAIL_COMPONENT) {
        if (page > lastFullPage) {
          onSetAnnouncements({
            announcements: produce(bodyContents, (bodyContentsDraft) => {
              for (
                let i = (page - 1) * limit;
                i < bodyContents.length % limit;
                i++
              ) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 0;
                }
              }
            }),
          });
        } else {
          onSetAnnouncements({
            announcements: produce(bodyContents, (bodyContentsDraft) => {
              for (let i = (page - 1) * limit; i < page * limit; i++) {
                if (bodyContentsDraft[i]["data"][0]["type"] === CHECK_BOX) {
                  bodyContentsDraft[i]["data"][0].data = 0;
                }
              }
            }),
          });
        }
      } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
      }
    }
  }, [
    innerHeaderTitle,
    DETAIL_DATA_TYPE,
    bodyContents,
    onSetAdministrators,
    onSetAnnouncements,
    lastFullPage,
    page,
  ]);

  const onClickCheckBox = useCallback(
    (rowIndex) => {
      if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
        onSetAdministrators({
          administrators: produce(bodyContents, (bodyContentsDraft) => {
            if (bodyContentsDraft[rowIndex]["data"][0]["type"] === CHECK_BOX) {
              if (parseInt(bodyContentsDraft[rowIndex]["data"][0].data) === 0) {
                bodyContentsDraft[rowIndex]["data"][0].data = 1;
              } else {
                bodyContentsDraft[rowIndex]["data"][0].data = 0;
              }
            }
          }),
        });
      } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === REPORT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === CHATTING_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ACTIVITY_PAYMENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === HOST_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ACTIVITY_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === POINT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === COUPON_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === EVENT_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ANNOUNCEMENT_DETAIL_COMPONENT) {
        onSetAnnouncements({
          announcements: produce(bodyContents, (bodyContentsDraft) => {
            if (bodyContentsDraft[rowIndex]["data"][0]["type"] === CHECK_BOX) {
              if (parseInt(bodyContentsDraft[rowIndex]["data"][0].data) === 0) {
                bodyContentsDraft[rowIndex]["data"][0].data = 1;
              } else {
                bodyContentsDraft[rowIndex]["data"][0].data = 0;
              }
            }
          }),
        });
      } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
      }
    },
    [DETAIL_DATA_TYPE, bodyContents, onSetAdministrators, onSetAnnouncements]
  );

  return (
    <>
      <TotalTopSection
        total_top_section_disable={total_top_section_disable}
        DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
        bodyContents={bodyContents}
        itemOpen={itemOpen}
        setItemOpen={setItemOpen}
        setChoiceRowIndex={setChoiceRowIndex}
        registerOpen={registerOpen}
        setRegisterOpen={setRegisterOpen}
        administrator_total_number_disable={administrator_total_number_disable}
        make_disable={make_disable}
        remove_disable={remove_disable}
        payment_apply_state_disable={payment_apply_state_disable}
        current_subscribe_user_state_disable={
          current_subscribe_user_state_disable
        }
        current_active_host_state_disable={current_active_host_state_disable}
        reward_part_total_number_disable={reward_part_total_number_disable}
      ></TotalTopSection>
      {!(bodyContents.length > 0) ? (
        <div>없음...</div>
      ) : (
        <TotalTableRowSection>
          <TotalTable itemOpen={itemOpen}>
            <TotalTableBody itemOpen={itemOpen}>
              <TotalTableHeader>
                {structureInfo.map((structure, index) => {
                  if (headerTitle[index]["type"] === HEADER_TITLE) {
                    return (
                      <div
                        key={index}
                        style={{
                          marginLeft: itemOpen
                            ? structure["margin_left_open"]
                            : structure["margin_left_close"],
                          width: structure["width"],
                        }}
                      >
                        {headerTitle[index]["data"]}
                      </div>
                    );
                  } else if (headerTitle[index]["type"] === HEADER_CHECK_BOX) {
                    return (
                      <div
                        key={index}
                        style={{
                          marginLeft: itemOpen
                            ? structure["margin_left_open"]
                            : structure["margin_left_close"],
                          width: structure["width"],
                        }}
                      >
                        <CheckBox
                          onClick={(e) => {
                            e.stopPropagation();
                            onClickHeaderCheckBox();
                          }}
                          checked={innerHeaderTitle[index]["data"]}
                        ></CheckBox>
                      </div>
                    );
                  }
                })}
              </TotalTableHeader>
              {bodyContents
                .slice(offset, offset + limit)
                .map((rowOb, rowIndex) => {
                  const id = rowOb["id"];
                  const row = rowOb["data"];
                  return (
                    <TotalTableRow
                      key={rowIndex}
                      onClick={(e) => {
                        e.preventDefault();
                        onClickRow(rowIndex);
                      }}
                      rowIndex={rowIndex}
                      choiceRowIndex={choiceRowIndex}
                    >
                      {structureInfo.map((structure, colIndex) => {
                        if (row[colIndex]["type"] === DATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {table_date_function(row[colIndex]["data"])}
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === NAME) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <TotalTableItemName>
                                {row[colIndex]["data"]}
                              </TotalTableItemName>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === SEX_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {row[colIndex]["data"] === 1 ? "남성" : "여성"}
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === STRING) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {row[colIndex]["data"]}
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === REVIEW) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                              onClick={(e) => {
                                e.stopPropagation();
                                review_list_modal_actions.setActivityId(id);
                                review_list_modal_actions.setModalVisible(true);
                              }}
                            >
                              <div
                                style={{
                                  width: w_px25rate,
                                }}
                              >
                                {row[colIndex]["data"]}
                              </div>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === AGREE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <TotalTableItemAgree
                                agree={row[colIndex]["data"]}
                              >
                                {row[colIndex]["data"] === 0 ? "N" : "Y"}
                              </TotalTableItemAgree>
                            </TotalTableItem>
                          );
                        } else if (
                          row[colIndex]["type"] === CAMERA_AUTH_STATE
                        ) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <TotalTableItemCameraAuthState
                                camera_auth_state={row[colIndex]["data"]}
                              >
                                {parseInt(row[colIndex]["data"]) <= 1
                                  ? "N (AI 판별 불일치)"
                                  : "Y (AI 판별 일치)"}
                              </TotalTableItemCameraAuthState>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === USE_REJECT_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <TotalTableItemUseRejectState
                                use_reject_state={row[colIndex]["data"]}
                              >
                                {row[colIndex]["data"] === 1
                                  ? "이용차단"
                                  : "정상"}
                              </TotalTableItemUseRejectState>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === MANAGER_MEMO) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              onClick={(e) => {
                                e.stopPropagation();
                                admin_memo_modal_actions.set_DETAIL_DATA_TYPE(
                                  DETAIL_DATA_TYPE
                                );
                                admin_memo_modal_actions.setId(id);
                                admin_memo_modal_actions.setAdminMemoMessage(
                                  row[colIndex]["data"]
                                );
                                admin_memo_modal_actions.setModalVisible(true);
                              }}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <TooltipCard
                                contents={row[colIndex]["data"]}
                              ></TooltipCard>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === CHECK_BOX) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <CheckBox
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onClickCheckBox(rowIndex);
                                }}
                                checked={parseInt(row[colIndex]["data"])}
                              ></CheckBox>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === PAYMENT_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <PaymentStateText
                                payment_state={parseInt(row[colIndex]["data"])}
                              >
                                {table_payment_state_text_function(
                                  row[colIndex]["data"]
                                )}
                              </PaymentStateText>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === SUBSCRIBE_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <SubscribeStateText>
                                {row[colIndex]["data"]}
                              </SubscribeStateText>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === PRICE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <PriceText>
                                {table_price_function(row[colIndex]["data"])}
                              </PriceText>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === NUMBER) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {row[colIndex]["data"]}회
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === RATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {row[colIndex]["data"]}%
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === ON_OFF_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <OnOffStateText
                                on_off_state={row[colIndex]["data"]}
                              >
                                {row[colIndex]["data"] === 0 ? "OFF" : "ON"}
                              </OnOffStateText>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === AGE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {row[colIndex]["data"]}세
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === COUPON_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <CouponStateText
                                coupon_state={row[colIndex]["data"]}
                              >
                                {row[colIndex]["data"] === 0
                                  ? "진행중"
                                  : "종료"}
                              </CouponStateText>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === VIEW_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <ViewStateText
                                event_state={row[colIndex]["data"]}
                              >
                                {row[colIndex]["data"] === 1
                                  ? "노출"
                                  : "비노출"}
                              </ViewStateText>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === EVENT_IMAGE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              <img
                                src={`${
                                  main_server_image_ip + row[colIndex]["data"]
                                }`}
                                alt=""
                                style={{
                                  width: w_px146rate,
                                  height: h_px51rate,
                                  backgroundColor: rgb_0_0_0,
                                }}
                              ></img>
                            </TotalTableItem>
                          );
                        } else if (row[colIndex]["type"] === PUSH_STATE) {
                          return (
                            <TotalTableItem
                              key={colIndex}
                              itemOpen={itemOpen}
                              structure={structure}
                            >
                              {row[colIndex]["data"] === 0 ? "미사용" : "사용"}
                            </TotalTableItem>
                          );
                        }
                      })}
                    </TotalTableRow>
                  );
                })}
            </TotalTableBody>
            <Pagination
              totalPages={totalPages}
              limit={limit}
              page={page}
              setPage={setPage}
              setItemOpen={setItemOpen}
              setChoiceRowIndex={setChoiceRowIndex}
            ></Pagination>
          </TotalTable>
          {itemOpen && (
            <TotalTableDetail>
              <TotalTableDetailBodyFrame>
                <DetailTotalForm
                  itemOpen={itemOpen}
                  setItemOpen={setItemOpen}
                  choiceRowIndex={choiceRowIndex}
                  setChoiceRowIndex={setChoiceRowIndex}
                  registerOpen={registerOpen}
                  setRegisterOpen={setRegisterOpen}
                  DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
                  DETAIL_FORM_TYPE={DETAIL_FORM_TYPE}
                  DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
                  limit={limit}
                  page={page}
                ></DetailTotalForm>
              </TotalTableDetailBodyFrame>
            </TotalTableDetail>
          )}
        </TotalTableRowSection>
      )}
    </>
  );
};

export default React.memo(AdminTable);

const TotalTableRowSection = styled.div`
  display: flex;
`;

const TotalTable = styled.div`
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableBody = styled.div`
  border-top: 1px solid ${rgb_0_0_0};
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${rgb_205_205_205};
  height: ${h_px62d5rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
`;

const TotalTableRow = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${rgb_205_205_205};
  background-color: ${(props) =>
    props.choiceRowIndex === null || props.choiceRowIndex !== props.rowIndex
      ? rgb_255_255_255
      : rgba_66_139_247_0d05};
`;

const TotalTableItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${w_px11rate}px;
  margin-left: ${(props) =>
    props.itemOpen
      ? props.structure["margin_left_open"]
      : props.structure["margin_left_close"]}px;
  width: ${(props) => props.structure["width"]}px;
  font-family: noto_sans_kr_regular;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
`;

const TotalTableItemName = styled.div`
  font-family: noto_sans_kr_bold;
`;

const TotalTableItemAgree = styled.div`
  color: ${(props) => (props.agree === 0 ? rgb_255_16_125 : rgb_0_0_0)};
`;

const TotalTableItemCameraAuthState = styled.div`
  color: ${(props) =>
    props.camera_auth_state <= 1 ? rgb_255_16_125 : rgb_0_0_0};
`;

const TotalTableItemUseRejectState = styled.div`
  color: ${(props) =>
    props.use_reject_state === 1 ? rgb_255_16_125 : rgb_0_0_0};
`;

const TotalTableDetail = styled.div`
  width: ${w_px362rate}px;
  height: ${h_px735d5rate}px;
`;

const TotalTableDetailBodyFrame = styled.div`
  height: ${h_px671d5rate}px;
`;

const PaymentStateText = styled.div`
  color: ${(props) =>
    props.payment_state === 0 ||
    props.payment_state === 1 ||
    props.payment_state === 2 ||
    props.payment_state === 5 ||
    props.payment_state === 6
      ? rgb_0_0_0
      : props.payment_state === 4
      ? rgb_48_94_255
      : rgb_255_16_125};
  font-family: noto_sans_kr_medium;
`;

const SubscribeStateText = styled.div`
  font-family: noto_sans_kr_regular;
`;

const PriceText = styled.div`
  font-family: noto_sans_kr_regular;
`;

const OnOffStateText = styled.div`
  color: ${(props) =>
    props.on_off_state === 0 ? rgb_66_139_247 : rgb_255_16_125};
`;

const CouponStateText = styled.div`
  color: ${(props) =>
    props.coupon_state === 0 ? rgb_66_139_247 : rgb_255_16_125};
`;

const ViewStateText = styled.div`
  color: ${(props) =>
    props.event_state === 1 ? rgb_66_139_247 : rgb_255_16_125};
`;
