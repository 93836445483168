import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// components
import LoadingPage from '../../pages/loading/LoadingPage';

// 설문관리 상세 > 설정
function SettingComponent() {
    // useParams
    const { survey_id } = useParams();

    // useSelector
    const { questionLists, getQuestionLoading } = useSelector((state) => state.getSurveyReducer);

    /********************************** html **********************************/
    return (
        <div>
            <div>{questionLists.length === 0 || getQuestionLoading ? <LoadingPage text={'설정 페이지에 대한 정보를 불러오는 중입니다.'} /> : <div>설정 페이지</div>}</div>
        </div>
    );
}

export default SettingComponent;
