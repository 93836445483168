import { sexFilter } from '../../utils/textFilter';
import { SmsPageUserListsFilter } from '../../dataSystems/PageFilters/SmsPageUserListsFilter';
import MainTable from '../../designComponents/tables/MainTable';
import React, { useCallback, useContext, useRef, useState } from 'react';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';

const UserPhoneTable = ({ platform, setSendUser }) => {
    const userRef = useRef(null);
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [sex, setSex] = useState(1);

    const [param, setParam] = useState({});

    const callApi = async (page, param) => {
        try {
            const input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                limit: 1,
                ...param,
            };
            const response = await RefreshCheckApi.post(`/adminSms/list`, input);
            return response.data.data.body;
        } catch (error) {
            console.error(error);
        }
    };

    const pageCountCallApi = async (page, param) => {
        try {
            const input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                limit: 1,
                ...param,
            };
            const response = await RefreshCheckApi.post(`/adminSms`, input);
            return response.data.data.all_count;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <SearchbarWithDropdown
                style={{ margin: '45px 0 5px 0' }}
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '닉네임', value: 2 },
                    { id: 3, title: '이름', value: 3 },
                ]}
                resetThenSet={useCallback((item) => setSearchType(item.value), [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    userRef?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => setSearchText(e.target.value)}
            />

            <MainTable
                ref={userRef}
                tableStyle={{}}
                loadingText={'회원 내역을 불러오고 있습니다.'}
                headerData={SmsPageUserListsFilter}
                onChangeCheck={(checkData) => {
                    let lastChat = null;
                    if (checkData.length > 0) {
                        lastChat = checkData.pop();

                        if (checkData.length > 0) {
                            alert('알람톡은 한명한테만 전송 가능합니다');
                        }

                        setSendUser({ ...lastChat });
                    } else {
                        setSendUser(null);
                    }
                }}
                checkID={'phone_no'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                toggleBlur={true}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    setSex(parseInt(item.sex));
                    user_info_modal_actions.setId(item.id);
                    user_info_modal_actions.setModalVisible(true);
                }}
                tbodyRenderItem={(key, value) => {
                    if (key === 'sex') return sexFilter(value);
                    if (key === 'marketing_info_receive_agree_yn') return sexFilter(value === 1 ? 1 : 2, 'Y', 'N');
                    return value;
                }}
            />
        </>
    );
};

export default UserPhoneTable;
