import { sexFilter } from '../../utils/textFilter';
import MainTable from '../../designComponents/tables/MainTable';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import { PushUserListsFilter } from '../../dataSystems/PageFilters/PushUserListsFilter';
import { Status } from '../../components/EventBannerComponents/EventLayoutStyled';

const UserDeviceTable = ({ platform, setSendUser }) => {
    const userRef = useRef(null);
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [sex, setSex] = useState(1);

    const [param, setParam] = useState({
        platform: platform,
    });

    useEffect(() => {
        setParam({ ...param, platform: platform });
    }, [platform]);

    const tokenFilter = (value) => {
        if (Number(value) === 1) {
            return (
                <Status color="rgb(66, 139, 247)" backgroundColor="rgba(66, 139, 247, 0.08)">
                    가능
                </Status>
            );
        } else {
            return (
                <Status color="rgb(255, 16, 125)" backgroundColor="rgb(255, 227, 240)">
                    불가능
                </Status>
            );
        }
    };

    const callApi = async (page, param) => {
        try {
            const input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                platform: platform,
                ...param,
            };

            const response = await RefreshCheckApi.post(`/adminPush/list`, input);

            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    };

    const pageCountCallApi = async (page, param) => {
        try {
            const input = {
                page: page,
                size: 10,
                searchType: searchType,
                searchWord: searchText,
                ...param,
            };
            const response = await RefreshCheckApi.post(`/adminPush/list`, input);
            return response.data.all_cnt;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <SearchbarWithDropdown
                style={{ margin: '45px 0 5px 0' }}
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '닉네임', value: 2 },
                    { id: 3, title: '이름', value: 3 },
                    { id: 4, title: '유저 ID', value: 4 },
                    { id: 5, title: '모임명', value: 5 },
                    { id: 6, title: '모임 ID', value: 6 },
                ]}
                resetThenSet={useCallback((item) => setSearchType(item.value), [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    userRef?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => setSearchText(e.target.value)}
            />

            <MainTable
                ref={userRef}
                tableStyle={{}}
                loadingText={'회원 내역을 불러오고 있습니다.'}
                headerData={PushUserListsFilter}
                onChangeCheck={(checkData) => {
                    const userIdList = checkData.map((data) => data.user_id);

                    setSendUser([...userIdList]);
                }}
                checkID={'phone_no'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                toggleBlur={true}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    setSex(parseInt(item.sex));
                    user_info_modal_actions.setId(item.id);
                    user_info_modal_actions.setModalVisible(true);
                }}
                tbodyRenderItem={(key, value) => {
                    if (key === 'sex') return sexFilter(value);
                    if (key === 'token_yn') return tokenFilter(value);

                    return value;
                }}
            />
        </>
    );
};

export default UserDeviceTable;
