import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// custom hooks
import useInput from '../../hook/useInput';

// 놀이 결제정보 생성
function ActivityInfoComponent({ setPayments, setPoint, setParams }) {
    const [activityID, onChangeActivityID] = useInput(0);
    const [amount, setAmount] = useState(0);

    // 입력값 확인
    useEffect(() => {
        let newParams = {
            activity_id: parseInt(activityID),
            amount: amount,
        };

        setParams(newParams);
    }, [activityID, setParams, amount]);

    const onChangePayment = (e) => {
        setAmount(parseInt(e.target.value));
    };

    const onChangePoint = (e) => {
        setPoint(parseInt(e.target.value));
    };

    return (
        <div>
            <ColWrapper className="pay-contents">
                <Subtitle>결제가격</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="결제가격 입력" onChange={onChangePayment} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>포인트</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="포인트 입력" onChange={onChangePoint} />
                </SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>놀이ID</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="포인트 입력" onChange={onChangeActivityID} />
                </SubInfo>
            </ColWrapper>
        </div>
    );
}

export default ActivityInfoComponent;

/********************************* styled *********************************/

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 85px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 60px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;
