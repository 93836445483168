import React, { useEffect, useRef, useState } from 'react';

// communication_system
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { ModalComponent } from '../../designComponents/modals/ModalComponent';
import { InputItemLists, InputTwoCheckBox } from '../../designComponents/modals/InputItemLists';
import { dateFilter } from '../../utils/textFilter';

/** 이용자관리 > 관리자 페이지 */
const Administrator = () => {
    const [onToggleDetail, setOnToggleDetail] = useState(false);
    const [onToggleRegist, setOnToggleRegist] = useState(false);
    const [rendering, setRendering] = useState(false);

    const [detail, setDetail] = useState({});
    const [role, setRole] = useState(0);
    const [param, setParam] = useState({});

    const tableRef = useRef(null);

    /** 등록 */
    const onClickRegist = () => {
        setOnToggleRegist(true);
        setOnToggleDetail(false);
    };

    /** 삭제 */
    const onClickDelete = (checkItems) => async () => {
        try {
            if (window.confirm('삭제 후 복구는 불가능 합니다.\n관리자 계정 삭제를 진행하시겠습니까?')) {
                const response = await RefreshCheckApi.put('/adminAccount/delete', { lists: checkItems });
                if (response.status === 200) {
                    setRendering((prev) => !prev);
                    alert('삭제가 완료되었습니다.');
                }
            }
        } catch (error) {
            console.error('error: ', error);
        }
    };

    const callApi = async () => {
        const response = await RefreshCheckApi.get('/adminAccount');
        return response.data.data.body;
    };

    const pageCountCallApi = async () => {
        const response = await RefreshCheckApi.get('/adminAccount');
        return response.data.data.all_count;
    };

    const textWrapper = { display: 'flex', alignItems: 'center', gap: '3px', fontSize: '13px' };
    const textSt = { fontWeight: 600, color: 'rgb(66, 139, 247)' };

    return (
        <Wrapper02 id="administrator">
            <MainPageTitle text={'관리자'} />
            <FlexMainWrapper>
                <MainTable
                    rendering={rendering}
                    toggleBlur={true}
                    buttonComponents={({ checkItems, data }) => (
                        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ ...textWrapper }}>
                                <span>전체 운영자</span>
                                <span style={{ ...textSt }}>{data.length || 0}</span>
                                <span>명</span>
                            </div>
                            <TopSideButton middleOn={false} text01={'등록'} onClick={onClickRegist} text02={'삭제'} onClickDelete={onClickDelete(checkItems)} />
                        </FlexMainWrapper>
                    )}
                    tableStyle={{ width: onToggleDetail ? '70%' : '95%' }}
                    tableRef={tableRef}
                    loadingText={'관리자 정보를 불러오고 있습니다.'}
                    headerData={[
                        { key: 'name', value: '이름' },
                        { key: 'department', value: '직책' },
                        { key: 'manager_group', value: '소속' },
                        { key: 'admin_id', value: '계정' },
                        { key: 'phone_no', value: '연락처' },
                        { key: 'role', value: '권한' },
                        { key: 'recent_login_datetime', value: '최근접속일' },
                        { key: 'created_at', value: '가입일' },
                        { key: 'updated_at', value: '수정일' },
                    ]}
                    checkID={'id'}
                    param={param}
                    setParam={setParam}
                    callApi={callApi}
                    pageCountCallApi={pageCountCallApi}
                    onClickRowItem={(item) => () => {
                        setDetail({ ...item });
                        setOnToggleDetail(true);
                        setOnToggleRegist(false);
                    }}
                    tbodyRenderItem={(key, value) => {
                        if (key === 'created_at') return dateFilter(value, 'YYYY년 MM월 DD일');
                        if (key === 'updated_at') return dateFilter(value, 'YYYY년 MM월 DD일');
                        if (key === 'recent_login_datetime') return dateFilter(value, 'YYYY년 MM월 DD일');
                        if (key === 'role') return parseInt(value) === 1 ? <span style={{ color: 'rgb(5 101 242)' }}>관리자</span> : <span style={{ color: 'rgb(255, 16, 125)' }}>방문자</span>;
                        return value;
                    }}
                />
                <ModalComponent
                    toggle={onToggleDetail}
                    closeModal={() => setOnToggleDetail(false)}
                    header={'관리자 정보'}
                    body={
                        <div>
                            <InputItemLists
                                items={[
                                    {
                                        title: '이름',
                                        value: detail.name,
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.name = e.target.value;
                                            setDetail(obj);
                                        },
                                    },
                                    {
                                        title: '이메일',
                                        value: detail.admin_id,
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.admin_id = e.target.value;
                                            setDetail(obj);
                                        },
                                    },
                                    {
                                        title: '전화번호',
                                        value: detail.phone_no,
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.phone_no = e.target.value;
                                            setDetail(obj);
                                        },
                                    },
                                    {
                                        title: '비밀번호',
                                        value: detail.password,
                                        placeholder: '변경할 비밀번호를 입력하세요',
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.password = e.target.value;
                                            setDetail(obj);
                                        },
                                    },
                                ]}
                            />
                            <InputTwoCheckBox
                                title={'권한'}
                                items={[
                                    {
                                        subTitle: '관리자',
                                        checked: parseInt(detail.role) === 1 && true,
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.role = e.target.checked && 1;
                                            setDetail(obj);
                                        },
                                    },
                                    {
                                        subTitle: '방문자',
                                        checked: parseInt(detail.role) === 0 && true,
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.role = e.target.checked && 0;
                                            setDetail(obj);
                                        },
                                    },
                                ]}
                            />
                        </div>
                    }
                    onClickSave={async () => {
                        const response = await RefreshCheckApi.put('/adminAccount/detail', detail);
                        if (response.status === 200) {
                            setRendering((prev) => !prev);
                            alert('관리자 정보 수정이 완료되었습니다.');
                        }
                    }}
                />
                <ModalComponent
                    toggle={onToggleRegist}
                    closeModal={() => setOnToggleRegist(false)}
                    header={'관리자 정보 등록'}
                    body={
                        <div>
                            <InputItemLists
                                items={[
                                    {
                                        title: '이름',
                                        placeholder: '관리자의 이름을 적어주세요',
                                        onChange: (e) => {
                                            let obj = { ...param };
                                            obj.name = e.target.value;
                                            setParam(obj);
                                        },
                                    },
                                    {
                                        title: '이메일',
                                        placeholder: '이메일을 입력해주세요',
                                        onChange: (e) => {
                                            let obj = { ...param };
                                            obj.admin_id = e.target.value;
                                            setParam(obj);
                                        },
                                    },
                                    {
                                        title: '비밀번호',
                                        placeholder: '비밀번호를 입력해주세요',
                                        onChange: (e) => {
                                            let obj = { ...param };
                                            obj.password = e.target.value;
                                            setParam(obj);
                                        },
                                    },
                                    {
                                        title: '비밀번호 확인',
                                        placeholder: '비밀번호를 다시 입력해주세요',
                                        onChange: (e) => {
                                            let obj = { ...param };
                                            obj.password_check = e.target.value;
                                            setParam(obj);
                                        },
                                    },
                                    {
                                        title: '전화번호',
                                        placeholder: '전화번호를 입력해주세요',
                                        onChange: (e) => {
                                            let obj = { ...param };
                                            obj.phone_no = e.target.value;
                                            setParam(obj);
                                        },
                                    },
                                ]}
                            />
                            <InputTwoCheckBox
                                title={'권한'}
                                items={[
                                    {
                                        subTitle: '관리자',
                                        checked: role === 1 && true,
                                        onChange: (e) => {
                                            let obj = { ...param };
                                            obj.role = e.target.checked ? 1 : 0;
                                            setRole(e.target.checked ? 1 : 0);
                                            setParam(obj);
                                        },
                                    },
                                    {
                                        subTitle: '방문자',
                                        checked: role === 0 && true,
                                        onChange: (e) => {
                                            let obj = { ...param };
                                            obj.role = e.target.checked ? 0 : 1;
                                            setRole(e.target.checked ? 0 : 1);
                                            setParam(obj);
                                        },
                                    },
                                ]}
                            />
                        </div>
                    }
                    onClickSave={async () => {
                        const response = await RefreshCheckApi.post('/adminAccount', param);
                        if (response.status === 200) {
                            setRendering((prev) => !prev);
                            alert('관리자 정보가 추가되었습니다.');
                        }
                    }}
                />
            </FlexMainWrapper>
        </Wrapper02>
    );
};

export default React.memo(Administrator);
