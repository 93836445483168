import styled from 'styled-components';

export const Table = styled.table`
    border-collapse: collapse;

    width: 92%;
    margin: 0 auto;

    #thead > tr > th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        border-left: 1px solid rgb(205, 205, 205);
        border-right: 1px solid rgb(205, 205, 205);

        padding: 10px;

        font-size: 13px;
    }

    #tbody > tr {
        cursor: pointer;
        border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    }

    #tbody > tr:hover {
        background-color: #00000026;
    }

    #tbody > tr > td {
        padding: 13px 10px;

        font-size: 14px;
        text-align: center;
    }

    .date {
        border-right: 1px solid rgb(205, 205, 205);
    }

    .gathering {
        background-color: rgb(66 139 247 / 10%);
        border-right: 1px solid rgb(205, 205, 205);
    }

    .syrup {
        background-color: rgb(255 16 125 / 5%);
        border-left: 1px solid rgb(205, 205, 205);
    }
`;

export const DateHeaderTh = styled.th`
    border-left: 0 !important;
    width: 13%;
`;

export const CustomTh = styled.th`
    border-bottom: 1px solid rgb(0, 0, 0, 0.1) !important;
`;
