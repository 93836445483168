import React from 'react';
import styled from 'styled-components';

export const TableFilterCheckboxBtn = ({ lists = [], params, setParams, dispatch, checkboxWrapperStyle, fontStyle, filterKey, filterItem }) => {
    const onHandleChangeFilter = (list, item) => (e) => {
        onChange(e.target.checked, list, item);
    };

    const onChange = (checked, list, item) => {
        const tmp = params;
        if (checked) {
            tmp[list.key] = [...(tmp[list.key] ?? []), String(item.key)];
        } else {
            if (tmp[list.key].length === 0) delete tmp[list.key];
            else tmp[list.key] = tmp[list.key].filter((e) => String(e) !== String(item.key));
        }
        if (dispatch) dispatch(tmp);
        setParams(tmp);
    };

    return (
        <tr>
            <td />
            {lists.map((item, index) => (
                <td key={index}>
                    {item.filter &&
                        item.filter.map((v, i) =>
                            item.key !== filterKey ? (
                                <div key={i} style={{ display: 'flex', alignItems: 'center', ...checkboxWrapperStyle }}>
                                    <Checkbox type="checkbox" onChange={onHandleChangeFilter(item, v)} />
                                    <span style={{ fontSize: '13px', ...fontStyle }}>{v.value}</span>
                                </div>
                            ) : (
                                filterItem(item)
                            ),
                        )}
                </td>
            ))}
        </tr>
    );
};

export const FilterCheckboxBtn = ({ lists = [], params, setParams, dispatch, wrapperStyle, checkboxWrapperStyle, fontStyle, setRendering }) => {
    const onHandleChangeFilter = (list, item) => (e) => {
        onChange(e.target.checked, list, item);
    };

    const onChange = (checked, list, item) => {
        const tmp = params;
        if (checked) {
            tmp[list.key] = [...(tmp[list.key] ?? []), String(item.key)];
        } else {
            if (tmp[list.key].length === 0) delete tmp[list.key];
            else tmp[list.key] = tmp[list.key].filter((e) => String(e) !== String(item.key));
        }
        if (dispatch) dispatch(tmp);
        setRendering((prev) => !prev);
        setParams(tmp);
    };

    return (
        <CheckboxWrapper style={{ ...wrapperStyle }}>
            {lists.map(
                (item, _) =>
                    item.filter &&
                    item.filter.map((v, i) => {
                        return (
                            <div key={i} style={{ display: 'flex', alignItems: 'center', ...checkboxWrapperStyle }}>
                                <Checkbox type="checkbox" onChange={onHandleChangeFilter(item, v)} />
                                <span style={{ fontSize: '14px', ...fontStyle }}>{v.value}</span>
                            </div>
                        );
                    }),
            )}
        </CheckboxWrapper>
    );
};

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;

const Checkbox = styled.input`
    cursor: pointer;
    margin: 0 2px 0 0;
    width: 15px;
    height: 15px;
`;
