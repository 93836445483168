import React from 'react';
import ReactDOM from 'react-dom';

import { CookiesProvider } from 'react-cookie';

import { Provider } from 'react-redux';
import { applyMiddleware, legacy_createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './data_system/redux/root_reducer/root_reducer';

// components
import App from './App';

// react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const store = legacy_createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </CookiesProvider>,
    document.getElementById('root'),
);

// import Modal from 'react-modal';
// Modal.setAppElement('#root');
