import {createContext, useState} from "react";

const ReviewListModalContext = createContext({
  review_list_modal_state: {
    modalVisible: false,
    activityId: null,
    reviewId: null,
  },
  review_list_modal_actions: {
    setModalVisible: () => {},
    setActivityId: () => {},
    setReviewId: () => {},
  },
});

const ReviewListModalProvider = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [reviewId, setReviewId] = useState(null);

  const value = {
    review_list_modal_state: { modalVisible, activityId, reviewId },
    review_list_modal_actions: {
      setModalVisible,
      setActivityId,
      setReviewId,
    },
  };

  return (
    <ReviewListModalContext.Provider value={value}>
      {children}
    </ReviewListModalContext.Provider>
  );
};

const ReviewListModalConsumer = ReviewListModalContext.Consumer;

export { ReviewListModalConsumer, ReviewListModalProvider };

export default ReviewListModalContext;
