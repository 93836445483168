import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";

const AppTab = ({setPlatform, platform}) => {
	const tabList = [
		{
			title: '시럽',
			key: 1
		},{
			title: '시놀',
			key: 2
		},
	]


	return (
		<BootStrapTabs tabList={tabList} setValue={setPlatform} defaultKey={platform}></BootStrapTabs>
	)

}


export default AppTab