import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// styled-components
import { TableWrapper, MoreKategory, Checkbox } from '../styles/TableStyled';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Switch } from 'antd';

// Filter
import { orderTableFilter } from './OrderTableFilter';
import { refundOrderTableFilter } from './RefundOrderTableFilter';
import styled from 'styled-components';
import OrderTableFilterEntry from './OrderTableFilterEntry';

// 전체주문 리스트 테이블
function OrderTable({ order_lists, param, setParam, onlyPayments, setOnlyPayments, type }) {
    const navigate = useNavigate();

    // toggle-state
    const [filterToggle, setFilterToggle] = useState(false);

    // state
    const [checkItems, setCheckItems] = useState([]); // 체크된 회원id를 담을 배열

    const tableRef = useRef();

    /***************************************** filter *****************************************/

    // 날짜 필터
    const dateFilter = (date, nullPayInfo) => {
        return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : nullPayInfo;
    };

    // 주문 상태 = 0: 결제대기중, 1: 입금대기중, 2: 결제완료, 3: 환불신청, 4: 환불완료 5: 환불거절 6: 결제취소
    const payStatusFilter = (num, status) => {
        switch (true) {
            case num === 0:
                return status;
            case num === 1:
                return status;
            case num === 2:
                return <span style={{ color: 'rgb(66,139,247)', fontWeight: '600' }}>{status}</span>;
            case num === 3:
                return <span style={{ color: 'rgb(255,16,125)', fontWeight: '600' }}>{status}</span>;
            case num === 4:
                return status;
            case num === 5:
                return status;
            case num === 6:
                return status;
            default:
                return false;
        }
    };

    const orderStatusFilter = (num, status) => {
        // 0: 상품 준비중, 1: 배송 중, 2: 배송 완료, 3: 구매 확정, 4: 반품 신청, 5: 반품 완료
        switch (true) {
            case num === 0:
                return status;
            case num === 1:
                return <span style={{ color: 'green', fontWeight: '600' }}>{status}</span>;
            case num === 2:
                return <span style={{ fontWeight: '700' }}>{status}</span>;
            case num === 3:
                return status;
            case num === 4:
                return status;
            case num === 5:
                return status;
            default:
                return false;
        }
    };

    /**************************************** function ****************************************/

    //? 결제상품만 보기
    const onChangeOnlyPayment = (checked) => {
        setOnlyPayments(checked);
    };

    // 상세검색 필터
    const onClickFilterToggle = () => {
        setFilterToggle((prev) => !prev);
    };

    // 클릭 시 전체 체크
    const onClickAllCheck = (e) => {
        return handleAllCheck(e.target.checked);
    };

    // 클릭 시 단일 체크
    const onClickSingleCheck = (datas) => (e) => {
        return handleSingleCheck(e.target.checked, datas);
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if (checked) {
            // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
            const idArray = [];

            order_lists.forEach((data) => idArray.push(data.order_id));
            setCheckItems(idArray);
        } else {
            // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
            setCheckItems([]);
        }
    };

    // 체크박스 단일 선택
    const handleSingleCheck = (checked, data) => {
        if (checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            setCheckItems((prev) => [...prev, data.order_id]);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setCheckItems(checkItems.filter((el) => el !== data.order_id));
        }
    };

    //* 상세조회 페이지 이동(react-router-dom)
    const onClickOrderDetail = (order_id) => () => {
        navigate(`/order/${order_id}`);
    };

    //* 필터 적용 검색
    // 필터 함수 핸들러
    const onHandleChangeFilter = (list, item) => (e) => {
        onChangeFilter(e.target.checked, list, item);
    };

    // 필터 함수
    const onChangeFilter = (checked, list, item) => {
        let tmp = param;

        if (checked) {
            tmp[list.key] = [...(tmp[list.key] ?? []), String(item.key)];
        } else {
            tmp[list.key] = tmp[list.key].filter((e) => String(e) !== String(item.key));

            if (tmp[list.key].length === 0) {
                delete tmp[list.key];
            }
        }
        setParam(tmp);
    };

    /****************************************** html ******************************************/
    const nullPayInfo = <span style={{ color: 'gray' }}>결제정보 없음</span>;
    const nullInfo = <span style={{ color: 'gray' }}>정보없음</span>;

    return (
        <TableWrapper filterToggle={filterToggle}>
            <ToggleButtonWrapper id="with-payment-user">
                <Switch onChange={onChangeOnlyPayment} checked={onlyPayments} style={{ margin: 3 }} />
                <div>결제자만 모아보기</div>
            </ToggleButtonWrapper>

            <div id="table">
                <MoreKategory onClick={onClickFilterToggle}>
                    <div>
                        <span>상세검색 버튼</span>
                        {filterToggle ? <CaretUpOutlined /> : <CaretDownOutlined />}
                    </div>
                </MoreKategory>

                <table ref={tableRef}>
                    <thead className="filter">
                        {filterToggle && (
                            <tr>
                                <td />
                                {type ? (
                                    <OrderTableFilterEntry filters={orderTableFilter} onHandleChangeFilter={onHandleChangeFilter} />
                                ) : (
                                    <OrderTableFilterEntry filters={refundOrderTableFilter} onHandleChangeFilter={onHandleChangeFilter} />
                                )}
                            </tr>
                        )}
                    </thead>

                    <tbody className="title">
                        <tr>
                            <th>
                                <Checkbox
                                    type="checkbox"
                                    onChange={onClickAllCheck}
                                    checked={checkItems?.length === order_lists?.length ? true : false} // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                                />
                            </th>
                            <th>결제일시</th>
                            <th>주문상태</th>
                            <th>배송상태</th>
                            <th>주문번호</th>
                            <th>주문자</th>
                            <th>휴대폰 번호</th>
                            <th>상품ID</th>
                            <th>상품명</th>
                            <th>수량</th>
                            <th>판매처</th>
                            <th>배송주소</th>
                            <th>구매금액</th>
                            <th>실 결제금액</th>
                            <th>결제수단</th>
                        </tr>
                    </tbody>

                    <tbody className="body">
                        {order_lists?.map((data, index) => {
                            return (
                                <tr key={index} style={{ fontSize: '11px' }}>
                                    <td>
                                        <Checkbox
                                            type="checkbox"
                                            onChange={onClickSingleCheck(data)}
                                            checked={checkItems.includes(data.order_id) ? true : false} // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        />
                                    </td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{dateFilter(data.payment_created_at, nullPayInfo)}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{payStatusFilter(data.pay_status_number, data.pay_status) || nullPayInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{orderStatusFilter(data.order_status_number, data.order_status) || nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.order_number || nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.receiver_user_name || nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.phone_no || nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.product_id || nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.product_name || nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.order_count || nullInfo}개</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.host_name || nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.address ? `${data.address} ${data.address_detail}` : nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.discount_price ? `${data.discount_price.toLocaleString('ko-KR')}원` : nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.pay_amount ? `${data.pay_amount.toLocaleString('ko-KR')}원` : nullInfo}</td>
                                    <td onClick={onClickOrderDetail(data.order_id)}>{data.pay_type_number ? data.pay_type : nullInfo}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </TableWrapper>
    );
}

export default OrderTable;

const ToggleButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    font-size: 14px;
`;
