import {createContext, useState} from "react";

const AdminMemoModalContext = createContext({
  admin_memo_modal_state: {
    adminMemoMessage: "",
    modalVisible: false,
    id: null,
    DETAIL_DATA_TYPE: null,
  },
  admin_memo_modal_actions: {
    setAdminMemoMessage: () => {},
    setModalVisible: () => {},
    setId: () => {},
    set_DETAIL_DATA_TYPE: () => {},
  },
});

const AdminMemoModalProvider = ({ children }) => {
  const [adminMemoMessage, setAdminMemoMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState(null);
  const [DETAIL_DATA_TYPE, set_DETAIL_DATA_TYPE] = useState(null);

  const value = {
    admin_memo_modal_state: {
      adminMemoMessage,
      modalVisible,
      id,
      DETAIL_DATA_TYPE,
    },
    admin_memo_modal_actions: {
      setAdminMemoMessage,
      setModalVisible,
      setId,
      set_DETAIL_DATA_TYPE,
    },
  };

  return (
    <AdminMemoModalContext.Provider value={value}>
      {children}
    </AdminMemoModalContext.Provider>
  );
};

const AdminMemoModalConsumer = AdminMemoModalContext.Consumer;

export { AdminMemoModalConsumer, AdminMemoModalProvider };

export default AdminMemoModalContext;
