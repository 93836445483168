import React, {useRef} from "react";
import styled from "styled-components";
import {rgb_0_0_0, rgb_255_255_255, rgb_66_139_247, rgba_66_139_247_0d2,} from "../../colors";
import {XMarkBlue1x} from "../../icons/icons";
import {
    h_px12rate,
    h_px28rate,
    h_px6rate,
    w_px11rate,
    w_px160rate,
    w_px220rate,
    w_px60rate,
    w_px8rate,
} from "../../size";

const OneInfoImageSelector = ({
  profileImg,
  setProfileImg,
  serverGet,
  setServerGet,
}) => {
  const imageInput = useRef();

  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  const onRemove = () => {
    setServerGet(false);
    setProfileImg(null);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <SearchInput
          placeholder="이미지를 업로드해주세요."
          disabled={true}
        ></SearchInput>
        <SearchBtn onClick={onCickImageUpload}>파일첨부</SearchBtn>
      </div>
      <input
        ref={imageInput}
        style={{
          display: "none",
        }}
        type="file"
        accept="image/*"
        name="profile_img"
        multiple={false}
        onChange={(e) => {
          setServerGet(false);
          setProfileImg(e.target.files[0]);
        }}
      ></input>
      {profileImg !== null && (
        <div
          style={{
            marginTop: h_px12rate,
            alignItems: "center",
          }}
        >
          <ImgListItem>
            {profileImg === null
              ? null
              : serverGet
              ? profileImg
              : profileImg.name}
            <RemoveBtn onClick={onRemove}>
              <XMarkBlue1x></XMarkBlue1x>
            </RemoveBtn>
          </ImgListItem>
        </div>
      )}
    </div>
  );
};

export default React.memo(OneInfoImageSelector);

const SearchInput = styled.input`
  padding-top: ${h_px6rate}px;
  padding-bottom: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  padding-right: ${w_px8rate}px;
  width: ${w_px160rate}px;
  height: ${h_px28rate}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  font-size: ${w_px11rate}px;
  color: ${rgb_0_0_0};
  font-family: noto_sans_kr_medium;
  box-sizing: border-box;
  margin: 0px;
  &:focus {
    outline: none;
  }
  &:disable {
    background-color: ${rgb_255_255_255};
  }
`;

const SearchBtn = styled.button`
  width: ${w_px60rate}px;
  height: ${h_px28rate}px;
  border-width: 0px;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
`;

const ImgListItem = styled.div`
  position: relative;
  box-sizing: border-box;
  width: ${w_px220rate}px;
  padding-top: ${h_px6rate}px;
  padding-bottom: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
  color: ${rgb_0_0_0};
  border: 1px solid ${rgba_66_139_247_0d2};
`;

const RemoveBtn = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: ${rgb_255_255_255};
  width: ${h_px28rate}px;
  height: ${h_px28rate}px;
  z-index: 999;
  margin: 0px;
  border-width: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;
