import React from 'react';
import { SearchbarWrapper } from '../wrappers/FlexMainWrapper';

import Searchbar from './Searchbar';
import MainDropdown from '../dropdowns/Dropdown';

const SearchbarWithDropdown = ({ list, index = 0, resetThenSet = () => {}, onChangeSubmit = () => {}, searchText, onChangeInput, style }) => {
    return (
        <SearchbarWrapper type="submit" onSubmit={onChangeSubmit} style={{ margin: '45px 0', gap: 0, ...style }}>
            <MainDropdown list={list} index={index} resetThenSet={resetThenSet} />
            <form style={{ marginLeft: '5px' }}>
                <Searchbar type="submit" searchText={searchText} onChangeSearchText={onChangeInput} />
            </form>
        </SearchbarWrapper>
    );
};

export default React.memo(SearchbarWithDropdown);
