import mainRequest from '../request';
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';

const url = '/aiCall';
export const getAiCallHistory = async (params) => {
    try {
        const response = await RefreshCheckApi.get(`${url}/history`, { params: params });
        return response?.data;
    } catch (err) {
        return {
            ok: false,
            status: err.response.status || 500,
            message: err.response.data.message || '서버 에러',
        };
    }
};

export const getAiCallTotal = async (params) => {
    try {
        const response = await RefreshCheckApi.get(`${url}/useAmount`, { params: params });
        return response?.data;
    } catch (err) {
        return {
            ok: false,
            status: err.response.status || 500,
            message: err.response.data.message || '서버 에러',
        };
    }
};
