import { useCallback, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { ArrowDownBlue1x, ArrowUpBlue1x } from '../../design_system/icons/icons';

const listType = { id: 1, title: '휴대폰번호', value: 1 };

/** 드롭다운 리스트 바 */
const MainDropdown = ({ list = [listType], index = 0, resetThenSet = () => {} }) => {
    const [isListOpen, setIsListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(index ? list[index]['title'] : list[0]['title']);

    const toggleList = useCallback(() => {
        setIsListOpen(produce((isListOpen) => !isListOpen));
    }, []);

    const selectItem = useCallback(
        (item) => {
            const { title } = item;
            setHeaderTitle(title);
            setIsListOpen(false);
            resetThenSet(item);
        },
        [resetThenSet],
    );

    return (
        <DropdownFrame>
            <MainTitleBtn type="button" onClick={toggleList} isListOpen={isListOpen}>
                <HeaderTitle>{headerTitle}</HeaderTitle>
                {isListOpen ? <ArrowUpBlue1x /> : <ArrowDownBlue1x />}
            </MainTitleBtn>

            {isListOpen ? (
                <DropdownListFrame role="list">
                    {list.map((item, index) => (
                        <MenuItemBtn key={index} type="button" onClick={() => selectItem(item)}>
                            {item.title}
                        </MenuItemBtn>
                    ))}
                </DropdownListFrame>
            ) : null}
        </DropdownFrame>
    );
};

export default MainDropdown;

const DropdownFrame = styled.div`
    position: relative;
`;

const MainTitleBtn = styled.button`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 140px;
    height: 35px;

    background-color: rgb(255 255 255);

    border-width: 1px;
    border-color: rgba(66, 139, 247, 0.2);
    border-bottom-width: 1px;

    word-break: break-all;
`;

const HeaderTitle = styled.div`
    /* margin-right: 29px; */
    font-family: noto_sans_kr_medium;
    font-size: 13px;
    color: rgb(35 40 45);
    word-break: break-all;
`;

const DropdownListFrame = styled.div`
    position: absolute;
    z-index: 1; // 999;

    display: flex;
    flex-direction: column;

    border: 1px solid rgba(66, 139, 247, 0.2);
    border-top-width: 0px;
    box-sizing: border-box;

    width: 100%; // 131px
    max-height: 439px;

    background-color: rgb(255, 255, 255);
    overflow: auto;
`;

const MenuItemBtn = styled.button`
    cursor: pointer;

    width: 100%; // 131px
    height: 35px;

    flex-wrap: wrap;

    background-color: rgb(255, 255, 255);
    border-width: 0px;

    font-family: noto_sans_kr_medium;
    font-size: 13px;
    color: rgb(35, 40, 35);
    text-align: left;

    word-break: break-all;
    border-block: 1px solid rgba(66, 139, 247, 0.2);

    :hover {
        background-color: rgb(245 241 241);
    }
`;
