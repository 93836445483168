/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import AdminTable from '../../design_system/components/admin_table/AdminTable';
import { ANNOUNCEMENT_DETAIL_COMPONENT } from '../../data_system/detail_data_type/detail_data_type';
import { DETAIL_COMMON_FORM } from '../../design_system/components/admin_table/detail/forms/detail_form_type/detail_form_type';

import { announcement_headerTitle, announcement_structureInfo } from '../../data_system/announcement_data/announcement_data';

import { ANNOUNCEMENT_DETAIL_REGISTER } from '../../data_system/register_data_type/register_data_type';
import { useSelector } from 'react-redux';
// import MatchDispatchCenter from '../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';
import { ReduxGetLoading } from '../../data_system/redux/modules/redux_functions/redux_get_loading';
import { GET_ANNOUNCEMENTS } from '../../data_system/redux/modules/announcements_reducer/announcements_table/announcements_table_type';

import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainTable from '../../designComponents/tables/MainTable';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';
import { userTableFilter } from '../../components/UserComponents/UserTableFilter';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainButton } from '../../designComponents/buttons/Buttons';
import AnnounceDetailModal from '../../EventComponents/Announce/AnnounceDetailModal';
import ToggleBtn from '../../design_system/components/toggle_btn/ToggleBtn';
import { dateFilter } from '../../utils/textFilter';

const Announcement = () => {
    const tableRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const getData = async () => {
        const req = await RefreshCheckApi.get('/v2/announce');
        if (req.status === 200) {
            return req.data.data;
        } else {
            return [];
        }
    };
    useEffect(() => {
        if (!showModal) {
            setSelected(null);
        }
    }, [showModal]);
    return (
        <Wrapper02>
            <AnnounceDetailModal visible={showModal} setVisible={setShowModal} item={selected} onChange={() => tableRef.current.refresh()} />
            <MainPageTitle text={'공지'} />
            <FlexMainWrapper style={{ justifyContent: 'flex-end', marginBottom: 16 }}>
                <MainButton
                    text={'공지 등록하기'}
                    onClick={() => {
                        setSelected(null);
                        setShowModal(true);
                    }}
                />
            </FlexMainWrapper>
            <MainTable
                ref={tableRef}
                checkAble={false}
                tbodyRenderItem={(key, value, item) => {
                    if (key === 'deleted_at') {
                        return (
                            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }} onClick={(event) => event.stopPropagation()}>
                                <AnnounceItemToggle item={item} />
                            </div>
                        );
                    } else if (key === 'created_at') {
                        return dateFilter(value, 'YYYY-MM-DD');
                    }
                    return value;
                }}
                onClickRowItem={(data) => () => {
                    setSelected(data);
                    setShowModal(true);
                }}
                loadingText={'리스트를 불러오고 있습니다.'}
                headerData={[
                    {
                        key: 'id',
                        value: 'ID',
                    },
                    {
                        key: 'title',
                        value: 'Title',
                    },
                    {
                        key: 'created_at',
                        value: '생성 날짜',
                    },
                    {
                        key: 'deleted_at',
                        value: '노출',
                    },
                ]}
                callApi={getData}
            />
        </Wrapper02>
    );
};

const AnnounceItemToggle = ({ item }) => {
    const [active, setActive] = useState(false);
    useEffect(() => {
        setActive(!item?.deleted_at);
    }, [item]);
    const onClick = async () => {
        const { id } = item;
        const req = await RefreshCheckApi.delete('/v2/announce', {
            data: {
                id,
                disable: active,
            },
        });
        setActive((pre) => !pre);
    };
    return <ToggleBtn toggle={active} clickedToggle={onClick} />;
};
export default React.memo(Announcement);
