import React, { useContext, useEffect, useState } from 'react';
import { ImageModal, ModalBody } from '../../designComponents/modals/ModalComponent';
import { FlexColumnWithTextArea, FlexTwoTextLists } from '../../designComponents/text/TextMapping';
import { Image, ImageFlexWrap } from '../../designComponents/imageboxs/Image';
import { dateFilter } from '../../utils/textFilter';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import UserModal from '../UserComponents/UserModal';

const ScheduleDetail = ({ data }) => {
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);
    const [imageInfo, setImageInfo] = useState({ toggle: false });

    return (
        <ModalBody
            modalComponents={
                <div>
                    {user_info_modal_state.modalVisible && <UserModal serSrcOrigin={(srcOrigin) => setImageInfo({ toggle: true, src: srcOrigin })} />}
                    {imageInfo.toggle && (
                        <ImageModal xIcon={true} onClickClose={() => setImageInfo({ toggle: false })}>
                            <Image url={imageInfo.src} width={500} height={500} />
                        </ImageModal>
                    )}
                </div>
            }
            leftComponents={
                <>
                    <ImageFlexWrap
                        title={'일정 사진'}
                        objYN={true}
                        keyValue="url"
                        image_lists={data.schedule_images}
                        imageWidth={80}
                        imageHeight={80}
                        onClickDefault={false}
                        onClick={(src) => setImageInfo({ toggle: true, src: src })}
                    />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '일정명',
                                text02: data.schedule_name,
                            },
                            {
                                text01: '일정 생성일',
                                text02: dateFilter(data.schedule_created_at),
                            },
                        ]}
                    />
                    <FlexColumnWithTextArea title={'일정 설명'} color={'#797979'} height={20} value={data.schedule_content} wrapperStyle={{ margin: '5px 0' }} />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '진행 여부',
                                text02: data.complete_yn,
                                text02Style: { color: data.complete_yn_num === 1 ? 'red' : 'blue' },
                            },
                            {
                                text01: '일정 날짜',
                                text02: `${data.start_date} ${data.start_time}`,
                            },
                            {
                                text01: '최대 정원',
                                text02: (data.max_participants || 0) + '명',
                            },
                            {
                                text01: '모임명',
                                text02: data.gathering_name,
                            },
                        ]}
                    />
                </>
            }
            rightComponents={
                <>
                    <ImageFlexWrap
                        title={'주관자 프로필 사진'}
                        objYN={true}
                        keyValue="url"
                        image_lists={data.owner_images}
                        imageWidth={80}
                        imageHeight={80}
                        onClickDefault={() => {
                            user_info_modal_actions.setId(data.user_id);
                            user_info_modal_actions.setModalVisible(true);
                        }}
                    />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '주관자명',
                                text02: `${data.user_name} ${data.sex === 1 ? '(남)' : '(여)'}`,
                            },
                            {
                                text01: '주관자 닉네임',
                                text02: data.user_nick_name,
                            },
                            {
                                text01: '생년월일/나이',
                                text02: `${data.birthday} / ${data.age}세`,
                            },
                            {
                                text01: '연락처',
                                text02: data.user_phone_no,
                            },
                        ]}
                    />
                </>
            }
        />
    );
};

export default ScheduleDetail;
