import produce from "immer";
import {handleActions} from "redux-actions";
import {GET_COMMON_CODE_FAILURE, GET_COMMON_CODE_SUCCESS} from "./commonCode_detail/common_code_detail_type";

const initialState = {
    code:[]
};

const commonCodeReducer = handleActions(
    {
        [GET_COMMON_CODE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.code = action.payload;
            }),
        [GET_COMMON_CODE_FAILURE]: (state, action) => produce(state, (draft) => {}),
    },
    initialState
);

export default commonCodeReducer;
