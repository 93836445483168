export const auth_adminLogin_router = '/auth/adminLogin';
export const auth_adminRefresh_router = '/auth/adminRefresh';
export const adminUser_router = '/adminUser';
export const adminAccount_router = '/adminAccount';
export const admin_subscribe_router = '/adminSubscribe';
export const adminReport_router = '/adminReport';
export const adminChatting_router = '/adminChatting';
export const adminActivity_activityPay_router = '/adminActivityPay';
export const adminActivity_host_router = '/adminHost';
export const adminActivity_activity_router = '/adminActivity';
export const adminBenefit_point_router = '/adminBenefit/point';
export const adminPoint_router = '/adminPoint';
export const adminBenefit_coupon_router = '/adminBenefit/coupon';
export const adminCoupon_router = '/adminCoupon';

// event 관련
export const adminBenefit_event_router = '/adminBenefit/event';
export const adminEvent_router = '/adminEvent';
export const adminEvent_transform_router = '/adminEvent/transform';
export const adminBenefit_announce_router = '/adminBenefit/announce';
export const adminUser_detail_router = '/adminUser';
export const adminAccount_detail_router = '/adminAccount/detail';
export const adminDescribe_detail_router = '/adminDescribe/detail';
export const adminChatting_detail_router = '/adminChatting/detail';
export const adminActivity_activityPay_detail_router = '/adminActivity/activityPay/detail';

// adminHost
export const adminActivity_host_detail_router = '/adminActivity/host/detail';
export const adminHost_router = '/adminHost';

// adminActivity
export const adminActivity_activity_detail_router = '/adminActivity/activity/detail';
export const adminBenefit_point_detail_router = '/adminBenefit/point/detail';
export const adminBenefit_coupon_detail_router = '/adminBenefit/coupon/detail';
export const adminBenefit_event_detail_router = '/adminBenefit/event/detail';
export const adminBenefit_announce_detail_router = '/adminBenefit/announce/detail';
export const adminDescribe_describeAdd_router = '/adminDescribe/describeAdd';
export const adminDescribe_adminMemo_router = '/adminDescribe/adminMemo';
export const adminDescribe_refund_router = '/adminDescribe/refund/New';

export const adminReport_adminMemo_router = '/adminReport/adminMemo';
export const adminActivity_activityPay_adminMemo_router = '/adminActivity/activityPay/adminMemo';
export const adminActivity_host_adminMemo_router = '/adminActivity/host/adminMemo';
export const adminActivity_activity_adminMemo_router = '/adminActivity/activity/adminMemo';
export const adminActivity_activity_detail_review_router = '/adminActivity/activity/detail/review';
export const adminUser_adminMemo_router = '/adminUser/adminMemo';
export const common_code_router = '/code';
export const sms_router = '/adminSms';
export const admin_status_user_account_page_router = '/adminStatus/userAccount/page';
export const admin_status_user_account_router = '/adminStatus/userAccount';
export const admin_status_current_router = '/adminStatus/current'; // 회원데이터
export const admin_status_additional_router = '/adminStatus/additional';
export const admin_status_pay_router = '/adminStatus/pay';
export const admin_status_user_account_line_router = '/adminStatus/userAccount/graph';
export const admin_status_current_graph_router = '/adminStatus/current/graph';
export const admin_active_user_router = '/adminMarketing/graph';
export const admin_retention_router = '/adminMarketing/retention';
