import React, { useState, useEffect, useCallback, useRef } from 'react';

// styled-components
import { LeftOutlined } from '@ant-design/icons';
import {
    MobileBox,
    ModalWrapper,
    Box,
    CreateTopWrapper,
    ButtonWeapper,
    InputWrapper,
    SubTitle,
    Input,
    Checkbox,
    SearchBoxWrapper,
    Button,
    Select,
    DateInputWrapper,
    DateWrapper,
} from '../../components/Common/Styles/CommonTableSideModalStyled';
import { Image, ImageComponent, ImageWrapper, ImageBox } from '../Common/Styles/CommonTableStyled';
import { ModalBody, CheckboxWrapper, CheckboxItemWrapper } from './EventLayoutStyled';

// URL & custom axios
import { main_server_image_ip } from '../../communication_system/communication_ips';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { RESET_STATE, createEventBannerInfo } from '../../data_system/redux/modules/events_reducer/events.reducer';

// custom hook
import useInput from '../../hook/useInput';
import LoadingPage from '../../pages/loading/LoadingPage';

// 이벤트 베너 등록
function CreateBanner({ data, setToggle, tableRef, setReloadToggle }) {
    const dispatch = useDispatch();

    // useSelector
    const { events, createResultMessage, createBannerDetailLoading, createBannerDetailError } = useSelector((state) => state.newEventsReducer);

    // state
    const [copyEventData, setCopyEventData] = useState([{}]);
    const [height, setHeight] = useState(0);
    const [linkUrl, setLinkUrl] = useState('');
    const [order, setOrder] = useState(0);

    // aplly data in state
    const [exposureYn, setExposureYn] = useState(0); // exposure yes or no
    const [title, onChangeTitle, setTitle] = useInput(''); // banner title
    const [exposureLocation, setExposureLocation] = useState(1); // exposure location
    const [imageUrl, setImageUrl] = useState(''); // image url
    const [startDate, setStartDate] = useState(''); // start date
    const [endDate, setEndDate] = useState(''); // end date
    const [platform, setPlatform] = useState(0);

    /******************************************* useEffect *******************************************/

    // 테이블 높이적용
    useEffect(() => {
        setHeight(tableRef.current.offsetHeight - 35);
    }, [tableRef, data]);

    // 에러감지
    useEffect(() => {
        if (createBannerDetailError) {
            console.error(createBannerDetailError);
            // alert(createBannerDetailError);
        }
    }, [createBannerDetailError]);

    // 등록 성공여부
    useEffect(() => {
        if (createResultMessage?.ok && createResultMessage?.data?.event_id) {
            dispatch({
                type: RESET_STATE,
            });

            resetState();
            alert(`${createResultMessage?.data?.title} 베너정보를 생성했습니다.`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, createResultMessage]);

    /*********************************** aplly data info in state ***********************************/

    useEffect(() => {
        resetApplyDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    /******************************************** filter ********************************************/

    // reset state
    const resetState = () => {
        setExposureYn(0);
        setTitle('');
        setExposureLocation('');
        setImageUrl('');
        setStartDate('');
        setEndDate('');
    };

    // 노출위치 TEXT 필터
    const exposureLocationFilter = (num) => {
        let _num = parseInt(num);

        switch (true) {
            case _num === 1:
                return '메인 페이지';
            case _num === 2:
                return '모임 페이지';
            case _num === 3:
                return '채팅 페이지';
            default:
                return false;
        }
    };

    /******************************************* function *******************************************/

    // 상태 초기화
    const resetApplyDate = () => {
        setCopyEventData({
            locationLists: [
                {
                    exposure_location: 1,
                },
                {
                    exposure_location: 2,
                },
                {
                    exposure_location: 3,
                },
            ],
        }); // 깊은복사
    };

    // 모달창 닫기
    const closeModal = () => {
        setToggle(false);
    };

    // 노출여부 수정
    const onChangeExposureYn = (num) => () => {
        setExposureYn(num);
    };

    // 연결위치 변경
    const onChangeLinkUrl = (e) => {
        setExposureLocation(e.target.value);
    };

    const onChangeLinkedUrl = (e) => {
        setLinkUrl(e.target.value);
    };

    const onChangeOrder = (e) => {
        setOrder(e.target.value);
    };

    const onChangePlatform = (e) => {
        setPlatform(e.target.value);
    };

    const platformList = [
        {
            value: 0,
            name: '공통',
        },
        {
            value: 1,
            name: '시럽만 노출',
        },
        {
            value: 2,
            name: '시놀만 노출',
        },
    ];

    // 이미지 에러 처리
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback(async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });

        const response = await RefreshCheckApi.post('/adminEvent/transform', imageFormData);
        setTimeout(() => {
            setImageUrl(response.data.data);
        }, 1100);
    }, []);

    // 날짜변환
    const onChangeDate = (type) => (e) => {
        if (type === 1) {
            setStartDate(e.target.value);
        } else if (type === 2) {
            setEndDate(e.target.value);
        }
    };

    // 이벤트 생성
    const createEvent = () => {
        let body = {
            exposure_yn: exposureYn,
            title: title,
            exposure_location: exposureLocation,
            image_url: imageUrl,
            exposure_start_datetime: startDate,
            exposure_end_datetime: endDate,
            link_url: linkUrl,
            order: order,
            platform: platform,
        };
        if (!body.title) {
            return alert('제목을 입력해주세요');
        }

        if (!body.image_url) {
            return alert('이미지가 선택되지 않았습니다');
        }

        if (!body.link_url) {
            return alert('연결 URL을 입력해주세요');
        }

        if (!body.exposure_start_datetime) {
            return alert('시작 날짜를 지정해주세요');
        }

        if (!body.exposure_end_datetime) {
            return alert('끝 날짜를 지정해주세요');
        }
        dispatch(createEventBannerInfo(body));
        setReloadToggle((prev) => !prev);
    };

    /********************************************* html *********************************************/
    return (
        <MobileBox className="mobile">
            <ModalWrapper>
                <Box height={650} limit={data?.length} style={{ position: 'relative' }}>
                    <CreateTopWrapper style={{ position: 'absolute', width: '100%' }}>
                        <LeftOutlined className="close-button" onClick={closeModal} />
                        <div>이벤트 생성</div>
                    </CreateTopWrapper>

                    {createBannerDetailLoading ? (
                        <LoadingPage text={'베너정보를 생성하고 있습니다.'} />
                    ) : (
                        <ModalBody height={height}>
                            <label id="label">앱 위치</label>
                            <InputWrapper id="span-date" style={{ alignItems: 'start' }}>
                                <SubTitle>앱 위치</SubTitle>
                                <Select value={platform} onChange={onChangePlatform}>
                                    {platformList?.map((data, index) => {
                                        return (
                                            <option key={index} value={data.value}>
                                                {data.name}
                                            </option>
                                        );
                                    }) || []}
                                </Select>
                            </InputWrapper>

                            <label id="label">노출 상태</label>
                            <InputWrapper id="" style={{ marginTop: '5%', marginBottom: '4%' }}>
                                <SubTitle style={{ width: '77px' }}>노출 상태</SubTitle>
                                <CheckboxWrapper>
                                    <CheckboxItemWrapper>
                                        <Checkbox type="checkbox" checked={parseInt(exposureYn)} onChange={onChangeExposureYn(1)} />
                                        <span>ON</span>
                                    </CheckboxItemWrapper>

                                    <CheckboxItemWrapper>
                                        <Checkbox type="checkbox" checked={!parseInt(exposureYn)} onChange={onChangeExposureYn(0)} />
                                        <span>OFF</span>
                                    </CheckboxItemWrapper>
                                </CheckboxWrapper>
                            </InputWrapper>

                            <label id="label">베너 제목</label>
                            <InputWrapper id="">
                                <SubTitle>베너 제목</SubTitle>
                                <Input type="text" value={title} onChange={onChangeTitle} placeholder="베너 제목을 입력해주세요." />
                            </InputWrapper>

                            <label id="label">노출 위치</label>
                            <InputWrapper id="exposure-location-select">
                                <SubTitle>노출 위치</SubTitle>
                                <Select value={exposureLocation} onChange={onChangeLinkUrl}>
                                    {copyEventData?.locationLists?.map((filter, index) => {
                                        return filter.exposure_location ? (
                                            <option key={index} value={filter.exposure_location}>
                                                {exposureLocationFilter(filter.exposure_location)}
                                            </option>
                                        ) : null;
                                    })}
                                </Select>
                            </InputWrapper>

                            <label id="label">이미지</label>
                            <InputWrapper id="banner-image">
                                <SubTitle>이미지</SubTitle>

                                <SearchBoxWrapper>
                                    <Input type="text" value={imageUrl || '정보없음'} disabled={true} />
                                    <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />

                                    <Button onClick={onClickAddImage} updateToggle={true} style={{ height: '24px', fontSize: '12px' }}>
                                        찾기
                                    </Button>
                                </SearchBoxWrapper>
                            </InputWrapper>

                            <label id="label">이미지 보여주기</label>
                            <ImageWrapper>
                                <ImageBox>
                                    <Image src={main_server_image_ip + imageUrl} alt="banner image" onError={handleImgError} width={280} height={100} />
                                </ImageBox>
                            </ImageWrapper>

                            <label id="label">노출 기간</label>
                            <InputWrapper id="span-date" style={{ alignItems: 'start' }}>
                                <SubTitle>노출 기간</SubTitle>
                                <DateInputWrapper>
                                    <DateWrapper className="date-wrapper">
                                        <div className="date-title" style={{ height: '24px', fontSize: '10px' }}>
                                            시작시간
                                        </div>
                                        <Input type="datetime-local" value={startDate} onChange={onChangeDate(1)} />
                                    </DateWrapper>

                                    <DateWrapper className="date-wrapper">
                                        <div className="date-title" style={{ height: '24px', fontSize: '10px' }}>
                                            종료시간
                                        </div>
                                        <Input type="datetime-local" value={endDate} onChange={onChangeDate(2)} />
                                    </DateWrapper>
                                </DateInputWrapper>
                            </InputWrapper>
                            <label id="label">이동 URL</label>
                            <InputWrapper id="span-date" style={{ alignItems: 'start' }}>
                                <SubTitle>이동 URL</SubTitle>
                                <Input type="text" value={linkUrl} onChange={onChangeLinkedUrl} placeholder="이동 할 주소를 입력해주세요" />
                            </InputWrapper>
                            <label id="label">순서</label>
                            <InputWrapper id="span-date" style={{ alignItems: 'start' }}>
                                <SubTitle>순서</SubTitle>
                                <Input type="text" value={order} onChange={onChangeOrder} placeholder="같은 순번이면 랜덤으로 지정됩니다" />
                            </InputWrapper>
                        </ModalBody>
                    )}

                    <ButtonWeapper>
                        <button className="save" onClick={createEvent}>
                            생성
                        </button>
                        <button className="cencle" onClick={closeModal}>
                            취소
                        </button>
                    </ButtonWeapper>
                </Box>
            </ModalWrapper>
        </MobileBox>
    );
}

export default CreateBanner;
