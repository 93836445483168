import React, { useState } from 'react';
import { contentFilter, dateFilter, moneyFilter, payStatusFilter } from '../../utils/textFilter';
import PayInfoDetailModal from '../SyrupComponents/PayInfoDetailModal';
import DesignTable from '../../designComponents/tables/DesignTable';

/** 모임 결제 정보 컴포넌트 */
const GatheringPayInfo = ({ data, allCount, page, setPage }) => {
    const [detailToggle, setDetailToggle] = useState([false, 0]);
    return (
        <DesignTable
            marginTop={0.5}
            onToggle={detailToggle[0]}
            modalComponent={<PayInfoDetailModal payInfoDetailToggle={detailToggle} setToggle={setDetailToggle} type={'모임'} />}
            header={[
                { title: '결제일시', width: 130 },
                { title: '결제상태', width: 90 },
                { title: '결제수단', width: 70 },
                { title: '포인트', width: 75 },
                { title: '실 결제액', width: 75 },
                { title: '누적 결제액', width: 75 },
                { title: '결제상품', width: 250 },
            ]}
            onClick={(item) => setDetailToggle([true, item.pay_id])}
            body={[
                { key: 'created_at', width: 130 },
                { key: 'pay_status', width: 90 },
                { key: 'pay_type', width: 70 },
                { key: 'use_point_amount', width: 75 },
                { key: 'pay_amount', width: 75 },
                { key: 'accumulate_pay_amount', width: 75 },
                { key: 'gathering_name', width: 250 },
            ]}
            data={data}
            allCount={allCount}
            page={page}
            setPage={setPage}
            bodyRederItem={(key, value) => {
                if (key === 'created_at') return dateFilter(value);
                if (key === 'pay_status') return payStatusFilter(value);
                if (key === 'gathering_name') return contentFilter(value, 25);
                if (key === 'use_point_amount') return moneyFilter(value) + '원';
                if (key === 'pay_amount') return moneyFilter(value) + '원';
                if (key === 'accumulate_pay_amount') return moneyFilter(value) + '원';
                return value;
            }}
        />
    );
};

export default GatheringPayInfo;
