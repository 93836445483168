import React, { useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import styled from 'styled-components';
import { rgb_0_0_0, rgb_255_255_255, rgb_66_139_247, rgba_66_139_247_0d2 } from '../../colors';
import { h_px28rate, h_px6rate, w_px11rate, w_px160rate, w_px220rate, w_px3rate, w_px60rate, w_px8rate } from '../../size';
import CheckBox from '../check_box/CheckBox';

const AddressSearchBar = ({ basicAddress, address, setAddress, detailAddress, setDetailAddress }) => {
    const [openPostcode, setOpenPostcode] = useState(false);

    const handle = {
        // 버튼 클릭 이벤트
        clickButton: () => {
            setOpenPostcode((current) => !current);
        },

        // 주소 선택 이벤트
        selectAddress: (data) => {
            setAddress(data.address);

            setBasicChecked(false);
            setOpenPostcode(false);
        },
    };

    const [basicChecked, setBasicChecked] = useState(true);

    const onClickBasicChecked = () => {
        if (basicChecked === true) {
            setAddress('');
            setBasicChecked(false);
        } else {
            setAddress(basicAddress);
            setBasicChecked(true);
        }
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <SearchInput value={address} onChange={() => {}} disabled={true}></SearchInput>
                <SearchBtn onClick={handle.clickButton}>주소검색</SearchBtn>
            </div>
            <div
                style={{
                    marginTop: h_px6rate,
                }}
            >
                <DetailInput
                    value={detailAddress}
                    onChange={(e) => {
                        setDetailAddress(e.target.value);
                    }}
                ></DetailInput>
            </div>
            <div
                style={{
                    marginTop: h_px6rate,
                    display: 'flex',
                }}
            >
                <CheckBox checked={basicChecked} onClick={onClickBasicChecked}></CheckBox>
                <div
                    style={{
                        marginLeft: w_px3rate,
                    }}
                >
                    <Text>호스트 기본 주소 가져오기</Text>
                </div>
            </div>

            {openPostcode && (
                <DaumPostcodeEmbed
                    onComplete={handle.selectAddress} // 값을 선택할 경우 실행되는 이벤트
                    autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                    defaultQuery="" // 팝업을 열때 기본적으로 입력되는 검색어
                    style={{
                        width: w_px220rate,
                        height: w_px220rate,
                    }}
                />
            )}
        </div>
    );
};

export default React.memo(AddressSearchBar);

const SearchInput = styled.input`
    padding-top: ${h_px6rate}px;
    padding-bottom: ${h_px6rate}px;
    padding-left: ${w_px8rate}px;
    padding-right: ${w_px8rate}px;
    width: ${w_px160rate}px;
    height: ${h_px28rate}px;
    border: 1px solid ${rgba_66_139_247_0d2};
    font-size: ${w_px11rate}px;
    color: ${rgb_0_0_0};
    font-family: noto_sans_kr_medium;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
    &:disable {
        background-color: ${rgb_255_255_255};
    }
`;

const DetailInput = styled.input`
    padding-top: ${h_px6rate}px;
    padding-bottom: ${h_px6rate}px;
    padding-left: ${w_px8rate}px;
    padding-right: ${w_px8rate}px;
    width: ${w_px220rate}px;
    height: ${h_px28rate}px;
    border: 1px solid ${rgba_66_139_247_0d2};
    font-size: ${w_px11rate}px;
    color: ${rgb_0_0_0};
    font-family: noto_sans_kr_medium;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;

const SearchBtn = styled.button`
    width: ${w_px60rate}px;
    height: ${h_px28rate}px;
    border-width: 0px;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    font-size: ${w_px11rate}px;
    font-family: noto_sans_kr_bold;
    background-color: ${rgb_66_139_247};
    color: ${rgb_255_255_255};
`;

const Text = styled.div`
    font-size: ${w_px11rate}px;
    font-family: noto_sans_kr_regular;
    color: ${rgb_0_0_0};
`;
