import React, { useContext, useRef, useState } from 'react';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { noneImage } from '../../utils/textFilter';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import UserModal from '../../components/UserComponents/UserModal';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import { Image } from '../../designComponents/imageboxs/Image';

import UserPhoneTable from "../../EventComponents/AlarmTalk/UserPhoneTable";
import SendAlarmTalk from "../../EventComponents/AlarmTalk/SendAlarmTalk";

const AlarmTalk = () => {
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [imageToggle, setImageToggle] = useState(false);

    const [src, setSrc] = useState('');
    const [sex, setSex] = useState(1);

    const [param, setParam] = useState({});

    const imgRef = useRef(null);
    const userRef = useRef(null);
    const [sendUser, setSendUser] = useState(null);




    // result


    return (
        <Wrapper02>
            {user_info_modal_state.modalVisible && <UserModal setImageToggle={setImageToggle} serSrcOrigin={setSrc} filterParam={param} searchUser={() => userRef?.current?.refresh()} />}
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image imgRef={imgRef} size="S150" url={src} errImg={noneImage(sex)} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}

            <MainPageTitle text={'알람톡 전송'} />
            <SendAlarmTalk sendUser={sendUser}></SendAlarmTalk>
            <UserPhoneTable setSendUser={setSendUser} ></UserPhoneTable>
        </Wrapper02>
    );
};

export default AlarmTalk;
