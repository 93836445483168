// 상품리스트, 상품상세조회, 상품추가
import produce from 'immer';
import { handleActions } from 'redux-actions';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// action을 정의
export const CHECK_HOST_INFO_REQUEST = 'CHECK_HOST_INFO_REQUEST'; // 호스트 중복확인
export const CHECK_HOST_INFO_SUCCESS = 'CHECK_HOST_INFO_SUCCESS';
export const CHECK_HOST_INFO_FAILURE = 'CHECK_HOST_INFO_FAILURE';
// 회사명 리스트 이름 불러오기
export const GET_OFFICE_NAME_REQUEST = 'GET_OFFICE_NAME_REQUEST';
export const GET_OFFICE_NAME_SUCCESS = 'GET_OFFICE_NAME_SUCCESS';
export const GET_OFFICE_NAME_FAILURE = 'GET_OFFICE_NAME_FAILURE';
// 호스트 정보 불러오기(모두)
export const GET_HOST_INFORMATIONS_REQUEST = 'GET_HOST_INFORMATIONS_REQUEST';
export const GET_HOST_INFORMATIONS_SUCCESS = 'GET_HOST_INFORMATIONS_SUCCESS';
export const GET_HOST_INFORMATIONS_FAILURE = 'GET_HOST_INFORMATIONS_FAILURE';
// 호스트 정보 불러오기(상세)
export const GET_HOST_INFORMATION_REQUEST = 'GET_HOST_INFORMATION_REQUEST';
export const GET_HOST_INFORMATION_SUCCESS = 'GET_HOST_INFORMATION_SUCCESS';
export const GET_HOST_INFORMATION_FAILURE = 'GET_HOST_INFORMATION_FAILURE';
// 호스트생성
export const ADD_HOST_REQUEST = 'ADD_HOST_REQUEST';
export const ADD_HOST_SUCCESS = 'ADD_HOST_SUCCESS';
export const ADD_HOST_FAILURE = 'ADD_HOST_FAILURE';
// 호스트 정보수정
export const UPDATE_HOST_REQUEST = 'UPDATE_HOST_REQUEST';
// 상품생성
export const POST_ADD_PRODUCT_REQUEST = 'POST_ADD_PRODUCT_REQUEST';
export const POST_ADD_PRODUCT_SUCCESS = 'POST_ADD_PRODUCT_SUCCESS';
export const POST_ADD_PRODUCT_FAILURE = 'POST_ADD_PRODUCT_FAILURE';

// 초기상태
const initialState = {
    hostInformation: null, // 호스트 정보를 담고 있는 데이터 객체
    hostOfficeLists: null, // 회사명 리스트 정보를 담고 있는 데이터 객체
    hostInformationList: null, // 호스트 정보를 담고 있는 데이터 객체(단일)
    hostInformationLists: null, // 호스트 정보를 담고 있는 데이터 객체(단일)
    // 회사명 리스트
    loadOfficeNameLoading: false,
    loadOfficeNameDone: false,
    loadOfficeNameError: null,
    // 호스트 정보 불러오기(모두)
    loadHostInformationsLoading: false,
    loadHostInformationsDone: false,
    loadHostInformationsError: null,
    // 호스트 정보 불러오기(상세)
    loadHostInformationLoading: false,
    loadHostInformationDone: false,
    loadHostInformationError: null,
    // 호스트 중복확인
    checkHostInfo: null,
    checkHostInfoLoading: false,
    checkHostInfoDone: false,
    checkHostInfoError: null,
    // 호스트 생성
    addHostInformationLoading: false,
    addHostInformationDone: false,
    addHostInformationError: null,
};

// 호스트 중복확인
export const checkHostName = (name) => async (dispatch) => {
    // 01. request
    dispatch({
        type: CHECK_HOST_INFO_REQUEST,
    });
    try {
        // 02. request
        const response = await RefreshCheckApi.get(`adminProduct/checkHostName?name=${name}`);
        dispatch({
            type: CHECK_HOST_INFO_SUCCESS,
            payload: response.data.result,
        });
    } catch (error) {
        console.error(error);
        // if failed? start this code line
        dispatch({
            type: CHECK_HOST_INFO_FAILURE,
            error: error,
        });
    }
};

// 회사명 리스트를 불러온다.
export const getHostNameRequest = () => async (dispatch) => {
    dispatch({
        type: GET_OFFICE_NAME_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`adminProduct/hostName`);
        dispatch({
            type: GET_OFFICE_NAME_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_OFFICE_NAME_FAILURE,
            error: error,
        });
    }
};

// 호스트 정보를 불러온다.(모두)
export const getHostInformations = (id) => async (dispatch) => {
    dispatch({
        type: GET_HOST_INFORMATIONS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get('adminProduct/hostInformations');

        dispatch({
            type: GET_HOST_INFORMATIONS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_HOST_INFORMATIONS_FAILURE,
            error: error,
        });
    }
};

// 호스트 정보를 불러온다.(상세)
export const getHostInformation = (id) => async (dispatch) => {
    dispatch({
        type: GET_HOST_INFORMATION_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`adminProduct/hostInformation?id=${id}`);
        dispatch({
            type: GET_HOST_INFORMATION_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_HOST_INFORMATION_FAILURE,
            error: error,
        });
    }
};

// 호스트 추가
export const addHost = (hostInfoParams) => async (dispatch) => {
    dispatch({
        type: ADD_HOST_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post('adminProduct/addHost', {
            name: hostInfoParams.hostOfficeName,
            owner: hostInfoParams.hostName,
            represent_number: hostInfoParams.hostPhoneNum,
            zone_code: hostInfoParams.zonecode,
            address: hostInfoParams.address,
            address_detail: hostInfoParams.addressDetail,
            business_number: hostInfoParams.hostBusinessNumber,
            image: hostInfoParams.imagePaths,
        });

        dispatch({
            type: ADD_HOST_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: ADD_HOST_FAILURE,
            payload: error,
        });
    }
};

// 호스트 수정
export const updateHost = () => async (dispatch) => {
    dispatch({
        type: UPDATE_HOST_REQUEST,
    });
};

// reducer
const productHostReducer = handleActions(
    {
        // 호스트 중복확인
        [CHECK_HOST_INFO_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.checkHostInfoLoading = true;
                draft.checkHostInfoDone = false;
                draft.checkHostInfoError = null;
            }),
        [CHECK_HOST_INFO_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.checkHostInfoLoading = false;
                draft.checkHostInfo = action.payload;
                draft.checkHostInfoDone = true;
            }),
        [CHECK_HOST_INFO_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.checkHostInfoLoading = false;
                draft.checkHostInfoError = action.error;
            }),

        // 회사명 리스트 불러오기
        [GET_OFFICE_NAME_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOfficeNameLoading = true;
                draft.loadOfficeNameDone = false;
                draft.loadOfficeNameError = null;
            }),
        [GET_OFFICE_NAME_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOfficeNameLoading = false;
                draft.hostOfficeLists = action.payload;
                draft.loadOfficeNameDone = true;
            }),
        [GET_OFFICE_NAME_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOfficeNameLoading = false;
                draft.loadOfficeNameError = action.error;
            }),

        // 호스트 정보 불러오기(모두)
        [GET_HOST_INFORMATIONS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadHostInformationsLoading = true;
                draft.loadHostInformationsDone = false;
                draft.loadHostInformationsError = null;
            }),
        [GET_HOST_INFORMATIONS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadHostInformationsLoading = false;
                draft.hostInformationLists = action.payload;
                draft.loadHostInformationsDone = true;
            }),
        [GET_HOST_INFORMATIONS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadHostInformationsLoading = false;
                draft.loadHostInformationsError = action.error;
            }),
        // 호스트 정보 불러오기(상세)
        [GET_HOST_INFORMATION_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadHostInformationLoading = true;
                draft.loadHostInformationDone = false;
                draft.loadHostInformationError = null;
            }),
        [GET_HOST_INFORMATION_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadHostInformationLoading = false;
                draft.hostInformationList = action.payload;
                draft.hostInformation = action.payload;
                draft.loadHostInformationDone = true;
            }),
        [GET_HOST_INFORMATION_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadHostInformationLoading = false;
                draft.loadHostInformationError = action.error;
            }),

        // 호스트 생성
        [ADD_HOST_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.addHostInformationLoading = false;
                draft.addHostInformationDone = false;
                draft.addHostInformationError = null;
            }),
        [ADD_HOST_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.addHostInformationLoading = false;
                draft.hostInformationList = action.payload;
                draft.addHostInformationDone = true;
            }),
        [ADD_HOST_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.addHostInformationLoading = false;
                draft.addHostInformationError = action.error;
            }),

        // 호스트 정보 수정
        [UPDATE_HOST_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                console.debug('START UPDATE_HOST_REQUEST');
            }),
    },
    initialState,
);

export default productHostReducer;
