import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 채팅 리스트 불러오기
export const GET_CHATTING_LISTS_REQUEST = 'GET_CHATTING_LISTS_REQUEST';
export const GET_CHATTING_LISTS_SUCCESS = 'GET_CHATTING_LISTS_SUCCESS';
export const GET_CHATTING_LISTS_FAILURE = 'GET_CHATTING_LISTS_FAILURE';
// 채팅 리스트 총 길이 불러오기
export const GET_CHATTING_LISTS_ALL_COUNT_REQUEST = 'GET_CHATTING_LISTS_ALL_COUNT_REQUEST';
export const GET_CHATTING_LISTS_ALL_COUNT_SUCCESS = 'GET_CHATTING_LISTS_ALL_COUNT_SUCCESS';
export const GET_CHATTING_LISTS_ALL_COUNT_FAILURE = 'GET_CHATTING_LISTS_ALL_COUNT_FAILURE';
// 채팅상세내용 불러오기기
export const GET_CHATTING_DETAIL_REQUEST = 'GET_CHATTING_DETAIL_REQUEST';
export const GET_CHATTING_DETAIL_SUCCESS = 'GET_CHATTING_DETAIL_SUCCESS';
export const GET_CHATTING_DETAIL_FAILURE = 'GET_CHATTING_DETAIL_FAILURE';
// 채팅상세내용 불러오기기
export const POST_ADMIN_CHATTING_REQUEST = 'POST_ADMIN_CHATTING_REQUEST';
export const POST_ADMIN_CHATTING_SUCCESS = 'POST_ADMIN_CHATTING_SUCCESS';
export const POST_ADMIN_CHATTING_FAILURE = 'POST_ADMIN_CHATTING_FAILURE';
// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    chattingLists: [], // 채팅 리스트
    chattingListsAllCount: null, // 채팅 리스트 총 길이
    chattingDetail: null, // 채팅 상세내용
    sendMessageResult: [], // 관리자 권한 채팅전송 결과
    // 채팅 리스트
    getChattingListsLoading: false,
    getChattingListsDone: false,
    getChattingListsError: null,
    // 채팅 리스트 총 길이
    getChattingListsAllCountLoading: false,
    getChattingListsAllCountDone: false,
    getChattingListsAllCountError: null,
    // 채팅 상세 내용
    getChattingDetailLoading: false,
    getChattingDetailDone: false,
    getChattingDetailError: null,
    // 채팅 상세 내용
    postAdminChattingLoading: false,
    postAdminChattingDone: false,
    postAdminChattingError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 채팅 리스트 불러오기
export const requestGetChattingLists = (input) => async (dispatch) => {
    dispatch({
        type: GET_CHATTING_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminChatting`, { params: input });
        dispatch({
            type: GET_CHATTING_LISTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CHATTING_LISTS_FAILURE,

            error: error,
        });
    }
};

// 채팅 총 길이 불러오기
export const requestGetChattingListsAllCount = (input) => async (dispatch) => {
    dispatch({
        type: GET_CHATTING_LISTS_ALL_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminChatting/allCount`, { params: input });
        dispatch({
            type: GET_CHATTING_LISTS_ALL_COUNT_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CHATTING_LISTS_ALL_COUNT_FAILURE,
            error: error,
        });
    }
};

// 채팅 상세정보 불러오기
export const requestGetChattingListsDetail = (chatting_id) => async (dispatch) => {
    dispatch({
        type: GET_CHATTING_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminChatting/detail?chatting_id=${chatting_id}`);
        dispatch({
            type: GET_CHATTING_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CHATTING_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 관리자 권한 채팅 보내기
export const requestSendAdminMessage = (bodyData) => async (dispatch) => {
    dispatch({
        type: POST_ADMIN_CHATTING_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminChatting`, bodyData);
        dispatch({
            type: POST_ADMIN_CHATTING_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: POST_ADMIN_CHATTING_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const chatReducer = handleActions(
    {
        // 채팅 리스트 불러오기
        [GET_CHATTING_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingListsLoading = true;
                draft.getChattingListsDone = false;
                draft.getChattingListsError = null;
            }),
        [GET_CHATTING_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingListsLoading = action.payload ? false : true;
                draft.chattingLists = action.payload;
                draft.getChattingListsDone = action.payload ? true : false;
            }),
        [GET_CHATTING_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingListsLoading = false;
                draft.getChattingListsError = action.error;
            }),

        // 채팅 리스트 총 길이 불러오기
        [GET_CHATTING_LISTS_ALL_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingListsAllCountLoading = true;
                draft.getChattingListsAllCountDone = false;
                draft.getChattingListsAllCountError = null;
            }),
        [GET_CHATTING_LISTS_ALL_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingListsAllCountLoading = action.payload ? false : true;
                draft.chattingListsAllCount = action.payload;
                draft.getChattingListsAllCountDone = action.payload ? true : false;
            }),
        [GET_CHATTING_LISTS_ALL_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingListsAllCountLoading = false;
                draft.getChattingListsAllCountError = action.error;
            }),

        // 채팅상세조회
        [GET_CHATTING_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingDetailLoading = true;
                draft.getChattingDetailDone = false;
                draft.getChattingDetailError = null;
            }),
        [GET_CHATTING_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingDetailLoading = action.payload ? false : true;
                draft.chattingDetail = action.payload;
                draft.getChattingDetailDone = action.payload ? true : false;
            }),
        [GET_CHATTING_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingDetailLoading = false;
                draft.getChattingDetailError = action.error;
            }),

        // 관리자 권한 채팅 보내기
        [POST_ADMIN_CHATTING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.postAdminChattingLoading = true;
                draft.postAdminChattingDone = false;
                draft.postAdminChattingError = null;
            }),
        [POST_ADMIN_CHATTING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.postAdminChattingLoading = action.payload ? false : true;
                draft.sendMessageResult = action.payload;
                draft.postAdminChattingDone = action.payload ? true : false;
            }),
        [POST_ADMIN_CHATTING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.postAdminChattingLoading = false;
                draft.postAdminChattingError = action.error;
            }),

        // 상태 초기화
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                draft.getChattingListsLoading = false;
                draft.getChattingListsDone = false;
                draft.getChattingListsError = null;
                draft.getChattingDetailLoading = false;
                draft.getChattingDetailDone = false;
                draft.getChattingDetailError = null;
            }),
    },
    initialState,
);

export default chatReducer;
