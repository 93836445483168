import React from 'react';
import styled from 'styled-components';

const SubTitle = ({ children, style, onClick }) => {
  return (
    <Title onClick={onClick} style={{ ...style }}>
      {children}
    </Title>
  );
};

export default React.memo(SubTitle);

const Title = styled.div`
  font-size: 19px;
  font-weight: bold;
  font-family: noto_sans_kr_medium;

  margin-top: 20px;
`;
