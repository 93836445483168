import styled from 'styled-components';

export const Wrapper = styled.div`
    #label {
        display: none;
    }
`;

export const DateFilterBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;

    width: 100%;
`;

export const DateFilterBtnWrapper02 = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    margin-bottom: 1%;
`;

export const FilterTitle = styled.div`
    font-size: 15px;
    margin-right: 5px;
`;

export const DateButton = styled.div`
    cursor: pointer;
    text-align: center;
    font-size: 14px;

    border: 1px solid #428bf7;
    padding: 2px 0;
    width: 50px;
`;

export const DateInput = styled.input`
    cursor: pointer;
    border: 1px solid #428bf7;

    padding: 2px 5px;
    width: 130px;
`;

export const ResetButton = styled.div`
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    color: #fff;

    border: 1px solid #428bf7;
    border-radius: 3px;
    background-color: #428bf7;

    padding: 2px 5px;
    width: 50px;

    &:hover {
        background-color: #166ef0;
    }
    &:active {
        background-color: #428bf7;
    }
`;

export const SearchWrapper = styled.div`
    display: flex;
    margin: 20px 0;
`;

export const Select = styled.select`
    cursor: pointer;
    outline: none;

    width: 200px;
    height: 35px;

    padding-left: 10px;
    padding-right: 10px;

    margin: 0 0 0 5px;

    vertical-align: middle;

    font-size: 15px;
    font-family: noto_sans_kr_regular;

    box-sizing: border-box;
    border: 1px solid rgba(66, 139, 247, 0.2);
`;
