export const PushUserListsFilter = [
    {
        key: 'token_yn',
        value: '전송 여부',
        filter: []
    },
    {
        key: 'phone_no',
        value: '핸드폰 번호',
        filter: [
        ],
    },
    {
        key: 'nick_name',
        value: '닉네임',
    },{
        key: 'name',
        value: '이름',
    },

        {
        key: 'sex',
        value: '성별',
        filter: [
        ],
    },
    {
        key: 'age',
        value: '연령',
    },

];
