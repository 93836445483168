import React, { useEffect, useRef, useState } from 'react';

// styled-components
import { TableWrapper, Table, CheckBox, On, Off } from '../styles/ActivityTableStyled';

// redux
import { useDispatch } from 'react-redux';
import { requestActiviyDetail } from '../../data_system/redux/modules/activities_reducer/activity.reducer';

// components
import ActivityDetailModal from './ActivityDetailModal';
import AddActivityModal from './AddActivityModal';

// 놀이 리스트 테이블
function ActivityTable({ data, checkItems, setCheckItems, detailModalToggle, setDetailModalToggle, addModalToggle, setAddModalToggle }) {
    const dispatch = useDispatch();
    // state
    const [headerHeight, setHeaderHeight] = useState(null);
    const [height, setHeight] = useState(null);
    // useRef
    const tableHeaderHeight = useRef();
    const tableHeight = useRef();

    /*********************** useEffect ***********************/

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // modal창의 놓이를 테이블 높이에 맞추기 위함
        setHeaderHeight(tableHeaderHeight.current?.offsetHeight);
        setHeight(tableHeight.current?.offsetHeight);
    });

    /*********************** function ***********************/

    // 체크박스 단일 선택
    const handleSingleCheck = (checked, id) => {
        if (checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            setCheckItems((prev) => [...prev, id]);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if (checked) {
            // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
            const idArray = [];
            data?.body.forEach((el) => idArray.push(el.id));
            setCheckItems(idArray);
        } else {
            // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
            setCheckItems([]);
        }
    };

    // 표기할 텍스트 글자 길이 제한
    const limitText = (text, num) => {
        if (text.length > num) {
            return text.slice(0, num) + '...';
        } else {
            return text;
        }
    };

    // 취미/여가 상세조회
    const onClickDetailInfo = (activity_id) => () => {
        setDetailModalToggle(true); // 놀이상세조회 토글
        setAddModalToggle(false);
        dispatch(requestActiviyDetail(activity_id));
    };

    /*********************** html ***********************/
    return (
        <TableWrapper>
            <Table>
                <thead id="thead" ref={tableHeaderHeight}>
                    <tr>
                        <th>
                            <CheckBox
                                type="checkbox"
                                onChange={(e) => handleAllCheck(e.target.checked)}
                                // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                                checked={checkItems.length === data?.body.length ? true : false}
                            />
                        </th>
                        <th>상태</th>
                        <th>ID</th>
                        <th>크롤링 여부</th>
                        <th>호스트</th>
                        <th>놀이명</th>
                        <th>가격</th>
                        <th>신청 수</th>
                        <th>평점</th>
                        <th>후기</th>
                        <th>인기 놀이</th>
                    </tr>
                </thead>

                <tbody id="tbody" ref={tableHeight}>
                    {data?.body.map((data, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <CheckBox
                                        type="checkbox"
                                        onChange={(e) => handleSingleCheck(e.target.checked, data.id)}
                                        // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        checked={checkItems.includes(data.id) ? true : false}
                                    />
                                </td>
                                <td onClick={onClickDetailInfo(data.id)}>{data.status === 'ON' ? <On>{data.status}</On> : <Off>{data.status}</Off>}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{data.id}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{data.crawling_yn}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{limitText(data.host_name, 7)}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{limitText(data.title, 25)}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{limitText(data.raw_price, 10)}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{data.register_count}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{data.rating}</td>
                                <td onClick={onClickDetailInfo(data.id)}>{data.review_cnt}</td>
                                <td onClick={onClickDetailInfo(data.id)}>Null</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>

            {addModalToggle ? <AddActivityModal setAddModalToggle={setAddModalToggle} headerHeight={headerHeight} height={height} /> : null}

            {detailModalToggle ? <ActivityDetailModal setDetailModalToggle={setDetailModalToggle} headerHeight={headerHeight} height={height} /> : null}
        </TableWrapper>
    );
}

export default ActivityTable;
