export const GET_ACTIVITIES = "admin/GET_ACTIVITIES";
export const GET_ACTIVITIES_SUCCESS = "admin/GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITIES_FAILURE = "admin/GET_ACTIVITIES_FAILURE";

export const POST_ACTIVITIES = "admin/POST_ACTIVITIES";
export const POST_ACTIVITIES_SUCCESS = "admin/POST_ACTIVITIES_SUCCESS";
export const POST_ACTIVITIES_FAILURE = "admin/POST_ACTIVITIES_FAILURE";

export const DELETE_ACTIVITIES_LISTS = "admin/DELETE_LISTS";
export const DELETE_ACTIVITIES_SUCCESS = "admin/DELETE_LISTS_SUCCESS";
export const DELETE_ACTIVITIES_FAILURE = "admin/DELETE_LISTS_FAILURE";

export const GET_REVIEW_LISTS = "admin/GET_REVIEW_LISTS";
export const GET_REVIEW_LISTS_SUCCESS = "admin/GET_REVIEW_LISTS_SUCCESS";
export const GET_REVIEW_LISTS_FAILURE = "admin/GET_REVIEW_LISTS_FAILURE";

export const DELETE_REVIEW_LISTS = "admin/DELETE_REVIEW_LISTS";
export const DELETE_REVIEW_LISTS_SUCCESS = "admin/DELETE_REVIEW_LISTS_SUCCESS";
export const DELETE_REVIEW_LISTS_FAILURE = "admin/DELETE_REVIEW_LISTS_FAILURE";
