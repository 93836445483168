// gathering.schedule;
import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import MainRefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/main_refresh_check_api';

// 일정생성
export const ADD_SCHEDULE_REQUEST = 'ADD_SCHEDULE_REQUEST';
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS';
export const ADD_SCHEDULE_FAILURE = 'ADD_SCHEDULE_FAILURE';
// 일정조회
export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAILURE = 'GET_SCHEDULE_FAILURE';
// 일정상세조회
export const GET_SCHEDULE_DETAIL_REQUEST = 'GET_SCHEDULE_DETAIL_REQUEST';
export const GET_SCHEDULE_DETAIL_SUCCESS = 'GET_SCHEDULE_DETAIL_SUCCESS';
export const GET_SCHEDULE_DETAIL_FAILURE = 'GET_SCHEDULE_DETAIL_FAILURE';
// 일정수정
export const UPDATE_SCHEDULE_DETAIL_REQUEST = 'UPDATE_SCHEDULE_DETAIL_REQUEST';
export const UPDATE_SCHEDULE_DETAIL_SUCCESS = 'UPDATE_SCHEDULE_DETAIL_SUCCESS';
export const UPDATE_SCHEDULE_DETAIL_FAILURE = 'UPDATE_SCHEDULE_DETAIL_FAILURE';
// 모임 참가원 조회
export const GET_USERS_IN_GATHERING_REQUEST = 'GET_USERS_IN_GATHERING_REQUEST';
export const GET_USERS_IN_GATHERING_SUCCESS = 'GET_USERS_IN_GATHERING_SUCCESS';
export const GET_USERS_IN_GATHERING_FAILURE = 'GET_USERS_IN_GATHERING_FAILURE';
// 일정 참가원 조회
export const GET_USERS_IN_SCHEDULE_REQUEST = 'GET_USERS_IN_SCHEDULE_REQUEST';
export const GET_USERS_IN_SCHEDULE_SUCCESS = 'GET_USERS_IN_SCHEDULE_SUCCESS';
export const GET_USERS_IN_SCHEDULE_FAILURE = 'GET_USERS_IN_SCHEDULE_FAILURE';
// 일정삭제
export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST';
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAILURE = 'DELETE_SCHEDULE_FAILURE';
// 리셋
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    scheduleLists: [], // 일정 데이터
    scheduleList: [], // 일정 상세 데이터
    usersInGathering: [], // 모임참가원 데이터 리스트
    joinUsers: [], // 일정 참가원 데이터 리스트
    deleteMessage: null,
    // 일정생성
    addScheduleLoading: false,
    addScheduleDone: false,
    addScheduleError: null,
    // 일정조회
    getScheduleLoading: false,
    getScheduleDone: false,
    getScheduleError: null,
    // 일정상세조회
    getScheduleDetailLoading: false,
    getScheduleDetailDone: false,
    getScheduleDetailError: null,
    // 일정수정
    updateScheduleDetailLoading: false,
    updateScheduleDetailDone: false,
    updateScheduleDetailError: null,
    // 모임 참가원 조회
    getUsersInGatheringLoading: false,
    getUsersInGatheringDone: false,
    getUsersInGatheringError: null,
    // 일정 참가원 조회
    getUsersInScheduleLoading: false,
    getUsersInScheduleDone: false,
    getUsersInScheduleError: null,
    // 일정 삭제
    deleteScheduleLoading: false,
    deleteScheduleDone: false,
    deleteScheduleError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 일정생성
export const postSchedule = (params) => async (dispatch) => {
    dispatch({
        type: ADD_SCHEDULE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminGathering/schedule`, params);
        dispatch({
            type: ADD_SCHEDULE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: ADD_SCHEDULE_FAILURE,
            error: error,
        });
    }
};

// 일정조회
export const getSchedule = (params) => async (dispatch) => {
    dispatch({
        type: GET_SCHEDULE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/schedule`, { params: params });
        dispatch({
            type: GET_SCHEDULE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SCHEDULE_FAILURE,
            error: error,
        });
    }
};

// 일정상세조회
export const getScheduleDetail = (schedule_id) => async (dispatch) => {
    dispatch({
        type: GET_SCHEDULE_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/schedule/detail?schedule_id=${schedule_id}`);
        dispatch({
            type: GET_SCHEDULE_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SCHEDULE_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 일정수정
export const updateScheduleDetail = (params) => async (dispatch) => {
    dispatch({
        type: UPDATE_SCHEDULE_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminGathering/schedule/detail`, params);
        dispatch({
            type: UPDATE_SCHEDULE_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SCHEDULE_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 일정 참여인원 조회
export const getUsersInSchedule = (contents_id, tmp) => async (dispatch) => {
    dispatch({
        type: GET_USERS_IN_SCHEDULE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/schedule/users?contents_id=${contents_id}`, {
            params: tmp,
        });
        dispatch({
            type: GET_USERS_IN_SCHEDULE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_IN_SCHEDULE_FAILURE,
            error: error,
        });
    }
};

// 모임원 조회
export const getUsersInGathering = (params) => async (dispatch) => {
    dispatch({
        type: GET_USERS_IN_GATHERING_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminGathering/gahering/crew`, { params: params });
        dispatch({
            type: GET_USERS_IN_GATHERING_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_IN_GATHERING_FAILURE,
            error: error,
        });
    }
};

// 일정삭제 [type - 1:취소, 2: 삭제]
export const deleteSchedule = (idLists, userList, type) => async (dispatch) => {
    if (type === 1) {
        // TODO: 취소
    } else if (type === 2) {
        // TODO: 삭제
    }
};

/******************************************
 * reducer
 ******************************************/
const scheduleReducer = handleActions(
    {
        // 일정생성
        [ADD_SCHEDULE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.addScheduleLoading = true;
                draft.addScheduleDone = false;
                draft.addScheduleError = null;
            }),
        [ADD_SCHEDULE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.addScheduleLoading = false;
                draft.addScheduleDone = true;
            }),
        [ADD_SCHEDULE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.addScheduleLoading = false;
                draft.addScheduleError = action.error;
            }),

        // 일정조회
        [GET_SCHEDULE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getScheduleLoading = true;
                draft.getScheduleDone = false;
                draft.getScheduleError = null;
            }),
        [GET_SCHEDULE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getScheduleLoading = false;
                draft.scheduleLists = action.payload;
                draft.getScheduleDone = true;
            }),
        [GET_SCHEDULE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getScheduleLoading = false;
                draft.getScheduleError = action.error;
            }),

        // 일정 참여회원 목록조회
        [GET_USERS_IN_SCHEDULE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersInScheduleLoading = true;
                draft.getUsersInScheduleDone = false;
                draft.getUsersInScheduleError = null;
            }),
        [GET_USERS_IN_SCHEDULE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersInScheduleLoading = false;
                draft.joinUsers = action.payload;
                draft.getUsersInScheduleDone = true;
            }),
        [GET_USERS_IN_SCHEDULE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersInScheduleLoading = false;
                draft.getUsersInScheduleError = action.error;
            }),

        // 일정상세조회
        [GET_SCHEDULE_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getScheduleDetailLoading = true;
                draft.getScheduleDetailDone = false;
                draft.getScheduleDetailError = null;
            }),
        [GET_SCHEDULE_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getScheduleDetailLoading = false;
                draft.scheduleList = action.payload;
                draft.getScheduleDetailDone = true;
            }),
        [GET_SCHEDULE_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getScheduleDetailLoading = false;
                draft.getScheduleDetailError = action.error;
            }),

        // 일정수정
        [UPDATE_SCHEDULE_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateScheduleDetailLoading = true;
                draft.updateScheduleDetailDone = false;
                draft.updateScheduleDetailError = null;
            }),
        [UPDATE_SCHEDULE_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateScheduleDetailLoading = action.payload ? false : true;
                draft.updateScheduleDetailDone = action.payload ? true : false;
            }),
        [UPDATE_SCHEDULE_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateScheduleDetailLoading = true;
                draft.updateScheduleDetailError = action.error;
            }),

        // 모임 참가원 조회
        [GET_USERS_IN_GATHERING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersInGatheringLoading = true;
                draft.getUsersInGatheringDone = false;
                draft.getUsersInGatheringError = null;
            }),
        [GET_USERS_IN_GATHERING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersInGatheringLoading = action.payload ? false : true;
                draft.usersInGathering = action.payload;
                draft.getUsersInGatheringDone = action.payload ? true : false;
            }),
        [GET_USERS_IN_GATHERING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersInGatheringLoading = true;
                draft.getUsersInGatheringError = action.error;
            }),

        // 일정삭제
        [DELETE_SCHEDULE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteScheduleLoading = true;
                draft.deleteScheduleDone = false;
                draft.deleteScheduleError = null;
            }),
        [DELETE_SCHEDULE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteScheduleLoading = action.payload ? false : true;
                draft.deleteMessage = action.payload;
                draft.deleteScheduleDone = action.payload ? true : false;
            }),
        [DELETE_SCHEDULE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.deleteScheduleLoading = true;
                draft.deleteScheduleError = action.error;
            }),

        // 상태 리셋
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 일정수정
                draft.updateScheduleDetailLoading = false;
                draft.updateScheduleDetailDone = false;
                draft.updateScheduleDetailError = null;
                // 일정생성
                draft.addScheduleLoading = false;
                draft.addScheduleDone = false;
                draft.addScheduleError = null;
                // 일정삭제
                draft.deleteScheduleLoading = false;
                draft.deleteScheduleDone = false;
                draft.deleteScheduleError = null;
            }),
    },
    initialState,
);

export default scheduleReducer;
