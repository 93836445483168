import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Image } from '../../designComponents/imageboxs/Image';
import { CustomCloseOutlined } from '../../components/styles/AddGatheringModalStyled';

const DraggableImageGrid = ({ grid = 8, style, data = [], onImageChange }) => {
    const [images, setImages] = useState([]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        position: 'relative',
        margin: `0 ${grid}px 0 0`,
        background: isDragging ? 'lightgreen' : 'grey',
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'lightblue' : 'lightgrey',
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    });
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(images, result.source.index, result.destination.index);
        setImages(items);
        if (onImageChange) {
            onImageChange(items.map((e) => e.content));
        }
    };
    const deleteImage = (idx) => {
        const newList = images.filter((_, i) => i !== idx);
        setImages(newList);
        if (onImageChange) {
            onImageChange(newList.map((e) => e.content));
        }
    };

    useEffect(() => {
        if (data) {
            const tmp = data.map((e, i) => ({ content: e, id: `draggable_item_${i}` }));
            setImages(tmp);
        }
    }, [data]);

    if (images.length === 0) {
        return null;
    }
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                        {images.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                        <CustomCloseOutlined
                                            onClick={() => {
                                                deleteImage(index);
                                            }}
                                        />
                                        <Image key={index} url={item.content} width={170} height={170} alt="게시글 이미지" />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableImageGrid;
