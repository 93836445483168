import React from 'react';

// styled-components
import { KategoryWord, Checkbox } from '../styles/TableStyled';

// 필터 리스트
export default function OrderTableFilterEntry({ filters, onHandleChangeFilter }) {
    return filters.map((list, index) => {
        return (
            <td key={index}>
                {list.filter &&
                    list.filter.map((item, index) => {
                        return (
                            <KategoryWord key={index}>
                                <Checkbox type="checkbox" onChange={onHandleChangeFilter(list, item)} />
                                <div>{item.value}</div>
                            </KategoryWord>
                        );
                    })}
            </td>
        );
    });
}
