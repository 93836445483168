export const GET_ADMINISTRATORS_DETAIL = "admin/GET_ADMINISTRATORS_DETAIL";
export const GET_ADMINISTRATORS_DETAIL_SUCCESS =
  "admin/GET_ADMINISTRATORS_DETAIL_SUCCESS";
export const GET_ADMINISTRATORS_DETAIL_FAILURE =
  "admin/GET_ADMINISTRATORS_DETAIL_FAILURE";
export const PUT_ADMINISTRATORS_DETAIL = "admin/PUT_ADMINISTRATORS_DETAIL";
export const PUT_ADMINISTRATORS_DETAIL_SUCCESS =
  "admin/PUT_ADMINISTRATORS_DETAIL_SUCCESS";
export const PUT_ADMINISTRATORS_DETAIL_FAILURE =
  "admin/PUT_ADMINISTRATORS_DETAIL_FAILURE";
