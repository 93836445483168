import React, { useEffect, useState } from 'react';
import { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import DesignTable from '../../designComponents/tables/DesignTable';
import { contentFilter, dateFilter, moneyFilter } from '../../utils/textFilter';
import { NoneUserId } from '../../pages/customerService/CustomerService';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import ScheduleDetail from './ScheduleDetail';
import LoadingPage from '../../pages/loading/LoadingPage';

/** 가입한 일정 */
const JoinSchedule = ({ userId }) => {
    const [contentInfo, setContentInfo] = useState({ toggle: false });
    const [data, setData] = useState([]);
    const [allCount, setAllCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [detailLoading, setDetailLoading] = useState(true);

    useEffect(() => {
        if (userId) {
            const input = {
                userId: userId,
                page: page,
                size: 10,
            };
            setLoading(true);
            const request = RefreshCheckApi.get('/adminGathering/mySchedule/join', { params: input });
            request.then((res) => {
                setData(res.data.data);
                setAllCount(res.data.allCount);
                setLoading(false);
            });
        } else {
            setData([]);
            setAllCount(0);
            setLoading(false);
        }
    }, [userId, page]);

    return (
        <Wrapper03 style={{ marginBottom: 0, flex: 1 }}>
            {contentInfo.toggle && (
                <DefaultModal
                    title={'참여 일정 상세정보'}
                    boxStyle={{ width: '100vh', height: '70vh' }}
                    onClickClose={() => setContentInfo({ toggle: false })}
                    closeOn={true}
                    onClickBottomClose={() => setContentInfo({ toggle: false })}
                >
                    {detailLoading ? <LoadingPage text={'일정 상세정보를 불러오고 있습니다.'} /> : <ScheduleDetail data={contentInfo.contentDetail} />}
                </DefaultModal>
            )}
            <MainPageTitle wrapperStyle={{ margin: 0 }} fontStyle={{ fontSize: '17px', fontWeight: 600, color: '#797979' }} text={'참여 일정'} />
            {userId ? (
                <DesignTable
                    loading={loading}
                    marginTop={2}
                    onToggle={null}
                    modalComponent={false}
                    header={[
                        { title: '참여신청일', width: 135 },
                        { title: '모임명', width: 200 },
                        { title: '모임장명', width: 70 },
                        { title: '일정명', width: 200 },
                        { title: '결제 상태', width: 70 },
                        { title: '결제 금액', width: 70 },
                        { title: '일정 설명', width: 500 },
                    ]}
                    onClick={async (item) => {
                        try {
                            setDetailLoading(true);
                            const url = `/adminGathering/mySchedule/join/detail?content_id=${item.content_id}`;
                            const response = await RefreshCheckApi.get(url);
                            if (response.status === 200) {
                                setContentInfo({
                                    toggle: true,
                                    content_id: item.content_id,
                                    contentDetail: response.data.data,
                                });
                                setDetailLoading(false);
                            }
                        } catch (error) {
                            alert('[Error]관리자에게 문의하세요.');
                            console.error(error);
                        }
                    }}
                    body={[
                        { key: 'join_created_at', width: 135 },
                        { key: 'gathering_name', width: 200 },
                        { key: 'owner_name', width: 70 },
                        { key: 'schedule_name', width: 200 },
                        { key: 'pay_status', width: 70 },
                        { key: 'pay_amount', width: 70 },
                        { key: 'content_explanation', width: 500 },
                    ]}
                    data={data}
                    allCount={allCount}
                    page={page}
                    setPage={setPage}
                    bodyRederItem={(key, value) => {
                        if (key === 'join_created_at') return dateFilter(value);
                        if (key === 'gathering_name') return contentFilter(value, 20);
                        if (key === 'schedule_name') return contentFilter(value, 20);
                        if (key === 'pay_amount') return moneyFilter(value) + '원';
                        if (key === 'content_explanation') return contentFilter(value, 50);
                        return value;
                    }}
                    noneText="참여한 일정 내역 데이터가 존재하지 않습니다."
                    noneHieght={27}
                />
            ) : (
                <NoneUserId text="회원을 선택해 주세요." />
            )}
        </Wrapper03>
    );
};

export default JoinSchedule;
