import React, { useState, useEffect, useCallback } from 'react';

// styled-components
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import { ModalWrapper, Box, MobileBox } from '../../styles/ReadScheduleModalStyled'; //CreateTopWrapper
import { CreateTopWrapper, Image } from '../../styles/AddGatheringModalStyled';

// API
import { main_server_image_ip } from '../../../communication_system/communication_ips';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { requestGetGathering } from '../../../data_system/redux/modules/gathering.reducer/gathering.reducer';

// components
import SearchBar from '../../../design_system/components/searchbar/SearchBar';
import Dropdown from '../../Common/Dropdown';
import Pagination from '../../../design_system/components/admin_table/pagination/Pagination';
import LoadingPage from '../../../pages/loading/LoadingPage';

// 모임선택 모달창
function ChoisGatheringModal({ setToggle, setImageToggle, setSrc, setGatheringID, tableRef }) {
    const dispatch = useDispatch();

    // useSelector
    const { gatheringLists } = useSelector((state) => state.getGatheringReducer);

    // state
    const [height, setHeight] = useState(0);
    const [id, setID] = useState(0);
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1);
    const [params, setParams] = useState({});

    /*********************** useEffect ***********************/

    // 모임리스트 가져오기
    useEffect(() => {
        getGatheringDate(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, page, dispatch]);

    // 테이블 높이적용
    useEffect(() => {
        setHeight(tableRef.current.offsetHeight);
    }, [tableRef]);

    /*********************** function ***********************/

    /** 데이터 요청 함수 */
    const getGatheringDate = (tmp) => {
        let tmps = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...params,
            ...tmp,
        };

        dispatch(requestGetGathering(tmps));
    };

    // 이미지 크게보기
    const onClickShowImage = (src) => () => {
        setSrc(src);
        setImageToggle((prev) => !prev);
    };

    // 모달창 닫기
    const closeModal = () => {
        setToggle(false);
        setGatheringID(0);
    };

    // 모임선택 완료
    const onClickChoose = () => {
        setGatheringID(id);
    };

    // 리스트 ID 선택
    const onClickList = (id) => () => {
        setID(id);
    };

    // 검색어
    const onChangeInput = useCallback((e) => {
        setSearchText(e.target.value);
    }, []);

    // 검색 타입 설정 리스트
    const dropdownLists = [
        { id: 1, title: '닉네임', value: 1 },
        { id: 2, title: '모임장명', value: 2 },
        { id: 3, title: '모임명', value: 3 },
        { id: 4, title: '모임ID', value: 4 },
        { id: 5, title: '활동지역', value: 5 },
        { id: 6, title: '가입회원수', value: 6 },
    ];

    // 모임검색
    const searchingGathering = (e) => {
        e.preventDefault();
        let params = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
        };

        setParams(params);
        getGatheringDate(params);
    };

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /************************* HTML *************************/
    return (
        <MobileBox>
            <ModalWrapper>
                <Box height={height} limit={gatheringLists?.length || 10} style={{ position: 'relative ' }}>
                    <CreateTopWrapper style={{ position: 'sticky', top: '0', zIndex: '4' }}>
                        <LeftOutlined className="close-button" onClick={closeModal} />
                        <div>모임선택</div>
                    </CreateTopWrapper>

                    {/* placeholder="모임명 또는 모임 ID를 입력해주세요." */}
                    <SearchWrapper type="submit">
                        <Dropdown
                            list={dropdownLists}
                            index={0}
                            resetThenSet={useCallback((item) => {
                                setSearchType(item.value);
                            }, [])}
                        />
                        <form style={{ marginLeft: '5px' }} onSubmit={searchingGathering}>
                            <SearchBar type="submit" searchText={searchText} onChangeSearchText={onChangeInput} />
                        </form>
                    </SearchWrapper>

                    {gatheringLists.gatherings ? (
                        <div id="gathering-lists">
                            {gatheringLists.gatherings?.map((data, index) => {
                                if (id === data.id) {
                                    return (
                                        <ListsWrapper key={index} onClick={onClickList(data.id)} style={{ backgroundColor: '#cfdbff' }}>
                                            <Image
                                                key={index}
                                                src={main_server_image_ip + data.gathering_image}
                                                alt="모임사진"
                                                onClick={onClickShowImage(main_server_image_ip + data.gathering_image)}
                                                onError={handleImgError}
                                            />

                                            <div>
                                                <div>{data.id}</div>
                                                <div>{data.gathering_name}</div>
                                            </div>
                                        </ListsWrapper>
                                    );
                                } else {
                                    return (
                                        <ListsWrapper key={index} onClick={onClickList(data.id)}>
                                            <Image
                                                key={index}
                                                src={main_server_image_ip + data.gathering_image}
                                                alt="모임사진"
                                                onClick={onClickShowImage(main_server_image_ip + data.gathering_image)}
                                                onError={handleImgError}
                                            />

                                            <div>
                                                <div>{data.id}</div>
                                                <div>{data.gathering_name}</div>
                                            </div>
                                        </ListsWrapper>
                                    );
                                }
                            })}
                        </div>
                    ) : (
                        <LoadingPage text={'모임 정보를 불러오고 있습니다'} />
                    )}

                    <Pagination totalPages={gatheringLists?.allCount ? Math.round(gatheringLists?.allCount / 10) : 10} limit={10} page={page} setPage={setPage} style={{ marginRight: '5px ' }} />

                    <ButtonWeapper>
                        <button className="cencle" onClick={closeModal}>
                            취소
                        </button>

                        <button className="save" onClick={onClickChoose}>
                            선택완료
                        </button>
                    </ButtonWeapper>
                </Box>
            </ModalWrapper>
        </MobileBox>
    );
}

export default ChoisGatheringModal;

/************************* styled-coponents *************************/

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
`;

const ListsWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    padding: 12px 20px;

    border-bottom: 1px solid #80808014;

    :hover {
        background-color: #80808026;
    }
`;

const ButtonWeapper = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 15px;
    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    button {
        cursor: pointer;
        border: 0;
        background-color: #fff;

        width: 120px;
        height: 35px;
    }

    .cencle {
        border: 1px solid rgb(229, 229, 229);
        color: black;

        :hover {
            background-color: rgb(242 242 242);
        }
    }

    .update {
        border: 1px solid rgb(255, 16, 125);
        background-color: rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(198 3 92);
        }
    }

    .save {
        border: 1px solid #428bf7;
        background-color: #428bf7;
        color: #fff;

        :hover {
            background-color: #1d6ee6;
        }
    }
`;
