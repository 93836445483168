import React, { useCallback, useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined, RightOutlined } from '@ant-design/icons';

// react - query
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import LoadingPage from '../../pages/loading/LoadingPage';

// 질문상세보기
function QuestionDetailModal({ id, setToggle }) {
    // request
    const { isLoading, error, data } = useQuery('repoData', async () => {
        let detail = await RefreshCheckApi.get(`/adminQuestion/detail?faq_id=${id}`).then((res) => {
            return res.data.data[0];
        });

        let category = await RefreshCheckApi.get(`/adminQuestion/category`).then((res) => {
            return res.data;
        });

        return {
            detail: detail,
            category: category,
        };
    });

    // state
    const [updateToggle, setUpdateToggle] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [mainSelected, setMainSelected] = useState('');
    const [subSelected, setSubSelected] = useState('');

    /******************************* useEffect *******************************/

    // 상태적용
    useEffect(() => {
        setQuestion(data?.detail?.question);
        setAnswer(data?.detail?.answer);
        setMainSelected(data?.detail?.main_category_name);
        setSubSelected(data?.detail?.sub_category_name);
    }, [data]);

    /******************************* function *******************************/

    // text 변경
    const onChangeText = (e, type) => {
        if (type === 1) {
            setQuestion(e.target.value);
        } else if (type === 2) {
            setAnswer(e.target.value);
        }
    };

    // 모달창 닫기
    const onClickClose = () => {
        setToggle(false);
    };

    // 수정사항 저장
    const onClickSave = () => {
        if (!subSelected) {
            alert('소분류를 선택해주세요.');
        } else {
            RefreshCheckApi.patch('adminQuestion/update', {
                id: data.detail.id,
                question: question,
                answer: answer,
                mainSelected: mainSelected,
                subSelected: subSelected,
            })
                .then((res) => {
                    if (res.data.count) {
                        alert(`${res.data.message}건의 FAQ가 수정되었습니다.`);
                        onClickUpdate();
                    } else if (!res.data.counta) {
                        alert('수정된 내용이 존재하지 않습니다.');
                    }
                })
                .catch((err) => {
                    if (err) {
                        alert('[서버 관리자 문의] 수정중 에러가 발생했습니다.');
                    }
                });
        }
    };

    // 수정 토글
    const onClickUpdate = () => {
        setUpdateToggle((prev) => !prev);
    };

    // 대분류 선택
    const handleMainSelect = (e) => {
        setMainSelected(e.target.value);
        setSubSelected('');
    };

    // 소분류 선택
    const handleSubSelect = (e) => {
        setSubSelected(e.target.value);
    };

    /********************************* html *********************************/

    if (isLoading) {
        return (
            <ModalWindow>
                <ChooseIntersetBox>
                    <LoadingPage text={'질문 상세정보를 불러오는 중입니다.'} />
                </ChooseIntersetBox>
            </ModalWindow>
        );
    } else if (error) {
        console.error(error);
        return alert('[서버 관리자 문의] DATA 조회중 에러가 발생했습니다.');
    } else if (data) {
        return (
            <ModalWindow>
                <ChooseIntersetBox>
                    <TitleLineWrapper>
                        <Title>{updateToggle ? '질문 수정 하기' : '질문 상세 정보'}</Title>
                        <CloseOutlined className="close-btn" onClick={onClickClose} />
                    </TitleLineWrapper>

                    <DetailTableWrapper>
                        {updateToggle ? (
                            <SelectWrapper id="category-select">
                                <Select onChange={handleMainSelect} value={mainSelected}>
                                    {data.category.findMainCategoryLists.map((main, index) => {
                                        return <option key={index}>{main.main_category_name}</option>;
                                    })}
                                </Select>
                                <RightOutlined />
                                <Select02 onChange={handleSubSelect} value={subSelected}>
                                    {subSelected ? null : <option>소분류 선택</option>}
                                    {data.category.findCategoryLists.map((sub, index) => {
                                        return (
                                            mainSelected === sub.main_category_name && (
                                                <option key={index} value={subSelected ? sub.sub_category_name : null}>
                                                    {sub.sub_category_name}
                                                </option>
                                            )
                                        );
                                    })}
                                </Select02>
                            </SelectWrapper>
                        ) : (
                            <CategoryWrapper>
                                <CategoryIcon>{data?.detail?.main_category_name}</CategoryIcon>
                                <RightOutlined />
                                <CategoryIcon02>{data?.detail?.sub_category_name}</CategoryIcon02>
                            </CategoryWrapper>
                        )}

                        <div className="question" style={{ width: '100%' }}>
                            <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>[질문]</div>
                            {updateToggle ? <Textarea type="text" value={question} onChange={(e) => onChangeText(e, 1)} /> : <div>{data?.detail?.question}</div>}
                        </div>

                        <div className="answer" style={{ width: '100%' }}>
                            <div style={{ marginBottom: '5px', fontWeight: 'bold' }}>[답변]</div>
                            {updateToggle ? (
                                <Textarea02 type="text" value={answer} onChange={(e) => onChangeText(e, 2)} />
                            ) : (
                                <div>
                                    {data?.detail?.answer?.split('\n').map((line, i) => {
                                        return <div key={i}>{line}</div>;
                                    })}
                                </div>
                            )}
                        </div>
                    </DetailTableWrapper>

                    <ButtonWrapper>
                        <button className="update" onClick={updateToggle ? onClickSave : onClickUpdate}>
                            {updateToggle ? '저장' : '수정'}
                        </button>
                        <button className="cancle" onClick={updateToggle ? onClickUpdate : onClickClose}>
                            취소
                        </button>
                    </ButtonWrapper>
                </ChooseIntersetBox>
            </ModalWindow>
        );
    }
}

export default QuestionDetailModal;

/********************************* styled *********************************/

export const ModalWindow = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #0000009c;

    width: 100%;
    height: 100vh;
`;

export const ChooseIntersetBox = styled.div`
    position: relative;

    background-color: #fff;
    border-radius: 20px;

    padding: 20px 30px 10% 30px;

    width: 800px;

    overflow: auto;

    @media (max-width: 912px) {
        width: 98%;
    }
`;

export const TitleLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close-btn {
        cursor: pointer;
        font-size: 17px;
        font-weight: bold;
        color: #797979;
    }
`;

export const Title = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #428bf7;

    margin-bottom: 5px;
`;

export const DetailTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 7px;

    margin-top: 3%;
`;

const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;
`;

// 카테고리 선택
const Select = styled.select`
    cursor: pointer;
    outline: none;

    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border: 0;
    border-radius: 3px;
    background-color: rgb(118 139 172);
`;

const Select02 = styled.select`
    cursor: pointer;
    outline: none;

    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border: 0;
    border-radius: 3px;
    background-color: rgb(19 164 249);
`;

const CategoryWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;

    margin-bottom: 2%;
`;

const CategoryIcon = styled.div`
    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border-radius: 3px;
    background-color: rgb(118 139 172);
`;

const CategoryIcon02 = styled.div`
    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border-radius: 3px;
    background-color: rgb(19 164 249);
`;

const Textarea = styled.textarea`
    outline: none;
    padding: 5px;

    width: 100%;
    height: 10vh;

    border: 1px solid #cccccc;
`;

const Textarea02 = styled.textarea`
    outline: none;
    padding: 5px;

    width: 100%;
    height: 20vh;

    border: 1px solid #cccccc;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .update {
        cursor: pointer;

        width: 60px;
        height: 30px;

        background-color: #428bf7;
        color: #fff;

        border: 0;
    }

    .create:hover {
        background-color: #1d6ee6;
    }

    .cancle {
        cursor: pointer;

        width: 60px;
        height: 30px;

        background-color: #fff;
        color: #428bf7;

        border: 1px solid #428bf7;
    }

    .delete:hover {
        background-color: #f1eaea;
    }
`;
