import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { main_server_ip } from '../../communication_system/communication_ips';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import moment from 'moment';
import LoadingPage from '../loading/LoadingPage';

import { LText, MText, SText } from '../../designComponents/text/ContentText';
import { Image } from '../../designComponents/imageboxs/Image';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import CheckboxInput from '../../designComponents/checkboxs/CheckboxInput';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { InputTitle } from '../../designComponents/inputs/InputLine';
import { useNavigate } from 'react-router-dom';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';

/** 로그인 페이지 */
const Login = ({ authenticate }) => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    const [inputId, setInputId] = useState('');
    const [inputPw, setInputPw] = useState('');
    const [loading, setLoading] = useState(true);
    const [saveId, setSaveId] = useState(false);

    const [findToggle, setFindToggle] = useState({});

    const inputRef = useRef(null);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        const user = localStorage.getItem('user_id');
        if (Boolean(user)) navigate('/home');
    }, [navigate]);

    const onClickLogin = async () => {
        try {
            setLoading(true);
            axios.defaults.withCredentials = true;
            const data = {
                admin_id: inputId,
                password: inputPw,
            };
            const get = await axios.post(main_server_ip + '/auth/adminLogin', data);
            if (get.status === 200) {
                localStorage.setItem('access_token', get.data.access_token);
                localStorage.setItem('expires_at', moment().add(1, 'hour').format('yyyy-MM-DD HH:mm:ss'));
                localStorage.setItem('refresh_token', get.data.refresh_token);
                setLoading(false);
                authenticate(inputId);
                navigate('/home');
            }
        } catch (error) {
            if (error.response.status === 400) alert('이메일 또는 비밀번호를 확인해주세요');
            if (error.response.status === 410) alert(error.response.data.message);
            setLoading(false);
        }
    };

    // id를 cookie에 담아 저장한다.
    useEffect(() => {
        let save_user_id = cookies.save_id;
        if (save_user_id) {
            setInputId(save_user_id);
            setSaveId(true);
        }
    }, [cookies.save_id]);

    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) setFindToggle({});
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
    }, []);

    const logo = 'https://lh3.googleusercontent.com/p/AF1QipN-h4-AKjCeRpWh_mXs-9x9PyQFBewyLR0K-yYL=s680-w680-h510';
    return loading ? (
        <BackGroundWrapper style={{ backgroundColor: '#f2f4f6' }}>
            <LoadingPage className={'container'} style={{ height: '100vh' }} text={'환영합니다.🙂 잠시만 기다려주세요. 관리자 정보를 확인중입니다.'} />
        </BackGroundWrapper>
    ) : (
        <BackGroundWrapper>
            {findToggle.emailToggle && (
                <DefaultModal title="아이디 찾기" xIcon onClickClose={() => setFindToggle({})} boxStyle={{ width: '80vh', height: '50vh' }} closeOn>
                    <FlexMainWrapper style={{ alignItems: 'center', padding: '10px' }}>
                        <SText text={'아이디 찾기 개발 진행중'} />
                    </FlexMainWrapper>
                </DefaultModal>
            )}
            {findToggle.passwordToggle && (
                <DefaultModal title="비밀번호 변경" xIcon onClickClose={() => setFindToggle({})} boxStyle={{ width: '80vh', height: '50vh' }} closeOn>
                    <FlexMainWrapper style={{ alignItems: 'center', padding: '10px' }}>
                        <SText text={'비밀번호 변경 개발 진행중'} />
                    </FlexMainWrapper>
                </DefaultModal>
            )}
            <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '100vh' }}>
                <FlexMainWrapper style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <LoginBoxWrapper>
                        <FlexMainWrapper style={{ alignItems: 'center', gap: 0, marginBottom: '2vh', width: '100%' }}>
                            <Image originYN={true} url={logo} width={50} height={50} onClick={() => window.location.reload()} style={{ borderRadius: '50%' }} />
                            <LText
                                style={{ fontSize: '22px', marginTop: '5px', marginLeft: '10px' }}
                                wrapperStyle={{ justifyContent: 'start', marginBottom: '2vh' }}
                                color="#000"
                                gap={0}
                                text={'시놀 로그인'}
                            />
                        </FlexMainWrapper>
                        <form type="submit" onSubmit={onClickLogin}>
                            <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'start', alignItems: 'start', gap: '7px' }}>
                                    <FlexMainWrapper style={{ flexDirection: 'column', gap: '5px' }}>
                                        <MText text={'이메일 주소'} style={{ fontWeight: '500', color: '#000' }} wrapperStyle={{ justifyContent: 'start' }} />
                                        <InputTitle
                                            wrapperStyle={{ margin: 0, width: '40vh' }}
                                            inputStyle={{ width: '100%', height: '3.5vh', borderRadius: '7px', padding: '2px 13px' }}
                                            exposure={false}
                                            type="text"
                                            value={inputId}
                                            onChange={(e) => {
                                                setInputId(e.target.value);
                                                if (saveId) setCookie('save_id', e.target.value);
                                            }}
                                        />
                                        <FlexMainWrapper style={{ width: '100%', alignItems: 'center', justifyContent: 'end' }}>
                                            <CheckboxInput
                                                reverse
                                                text={'아이디 기억하기'}
                                                fontStyle={{ color: '#000' }}
                                                style={{ margin: '0 0 0 5px' }}
                                                onChange={(e) => {
                                                    const checked = e.target.checked;
                                                    setSaveId(checked);
                                                    if (saveId === false) setCookie('save_id', inputId);
                                                    else if (saveId === true) removeCookie('save_id');
                                                }}
                                                checked={saveId}
                                            />
                                        </FlexMainWrapper>
                                    </FlexMainWrapper>
                                    <FlexMainWrapper style={{ flexDirection: 'column', gap: '5px' }}>
                                        <MText text={'비밀번호'} style={{ fontWeight: '500', color: '#000' }} wrapperStyle={{ justifyContent: 'start' }} />
                                        <InputTitle
                                            inputRef={inputRef}
                                            wrapperStyle={{ margin: 0, width: '40vh' }}
                                            inputStyle={{
                                                width: '100%',
                                                height: '3.5vh',
                                                borderRadius: '7px',
                                                padding: '2px 13px',
                                            }}
                                            exposure={false}
                                            type="password"
                                            value={inputPw}
                                            onChange={(e) => setInputPw(e.target.value)}
                                        />
                                    </FlexMainWrapper>
                                </FlexMainWrapper>

                                <FlexMainWrapper style={{ width: '100%', alignItems: 'center', justifyContent: 'end', marginTop: '20px', gap: '10px' }}>
                                    <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', alignItems: 'end', justifyContent: 'end', gap: '3px' }}>
                                        <SText
                                            style={{ cursor: 'pointer', userSelect: 'none', fontWeight: '500' }}
                                            color={'rgb(0 68 169)'}
                                            text={'이메일을 잊으셨나요?'}
                                            onClick={() => setFindToggle({ emailToggle: true })}
                                        />
                                        <SText
                                            style={{ cursor: 'pointer', userSelect: 'none', fontWeight: '500' }}
                                            color={'rgb(0 68 169)'}
                                            text={'비밀번호를 잊으셨나요?'}
                                            onClick={() => setFindToggle({ passwordToggle: true })}
                                        />
                                    </FlexMainWrapper>
                                    <MainButton text={'로그인'} width={120} height={35} style={{ borderRadius: '3px' }} />
                                </FlexMainWrapper>
                            </FlexMainWrapper>
                        </form>
                    </LoginBoxWrapper>
                </FlexMainWrapper>

                <Wrapper02 className="mobile" style={{ backgroundColor: 'rgb(207 207 207 / 45%)', width: '100%', borderRadius: 0, marginBottom: 0, height: '30vh' }}>
                    <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '3vh', width: '25vh' }}>
                            <MText text={'시놀(주)'} color="#000" style={{ cursor: 'pointer' }} wrapperStyle={{ justifyContent: 'start' }} />
                            <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', gap: '2vh' }}>
                                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                    <CustomText text={'사업자번호:'} />
                                    <SText text={'584-88-03032'} color="#ababab" wrapperStyle={{ justifyContent: 'start' }} />
                                </FlexMainWrapper>

                                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                    <CustomText text={'법인등록번호:'} />
                                    <SText text={'124411-0358061'} color="#ababab" wrapperStyle={{ justifyContent: 'start' }} />
                                </FlexMainWrapper>

                                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                    <CustomText text={'은행정보:'} />
                                    <SText color="#ababab" text={'우리은행 1005-304-471988'} wrapperStyle={{ justifyContent: 'start', with: '120px' }} />
                                </FlexMainWrapper>

                                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                    <CustomText text={'고객센터:'} />
                                    <SText text={'02-508-5670'} color="#ababab" wrapperStyle={{ justifyContent: 'start' }} />
                                </FlexMainWrapper>

                                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'start', width: '100%' }}>
                                    <CustomText text={'카카오톡:'} />
                                    <SText text={'@시놀'} color="#ababab" wrapperStyle={{ justifyContent: 'start' }} />
                                </FlexMainWrapper>
                            </FlexMainWrapper>
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </Wrapper02>
            </FlexMainWrapper>
        </BackGroundWrapper>
    );
};

export default React.memo(Login);

const CustomText = ({ text, style }) => {
    return (
        <span style={{ width: '80px', textAlign: 'start' }}>
            <SText color="#ababab" text={text} style={{ ...style }} />
        </span>
    );
};

const BackGroundWrapper = styled.div`
    .container {
        width: 100%;
        height: 100%;
        text-align: center;
        position: relative;
        z-index: 1;
    }
    .container::after {
        width: 100%;
        height: 100%;
        content: '';
        /* background-image: url('https://images.khan.co.kr/article/2017/07/31/2017073102001324100326151.jpg'); */
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.7;
    }
    .mobile {
        @media (max-width: 912px) {
            display: none;
        }
    }
`;

const LoginBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 40vh;
    padding: 2vh 7vh;
    border-radius: 25px;
    background-color: rgb(255 255 255 / 45%); // rgb(0 0 0 / 55%);
`;
