import React, { useState, useCallback, useRef } from 'react';

// filter
import { SchedulePayUserFilter } from '../MockData/SchedulePayUserFilter';

// components
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { contentFilter, dateFilter, payStatusFilter, payTypeFilter } from '../../utils/textFilter';

// modals
import PayInfoDetailModal from '../SyrupComponents/PayInfoDetailModal';
import CreatedPayInfoModal from '../Common/Payments/CreatedPayInfoModal';
import TableFilter from '../../designComponents/filters/TableFilter';
import { gatheringSchedulerPayListApi } from '../../api/main/gathering/GatheringScheduleRequest';

// 일정 > 결제회원 페이지
const GatheringSchedulePayUser = () => {
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({
        orderType: 1,
        orderByType: 1,
    });

    const [detailToggle, setDetailToggle] = useState([false, 0]);
    const [createPaymentsModal, setCreatePaymentsModal] = useState(false);

    const ref = useRef(null);
    const tableRef = useRef(null);

    const callApi = async (page, param) => {
        let input = {
            page: page,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };

        const response = await gatheringSchedulerPayListApi(input);

        return response.data;
    };

    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            searchType: searchType,
            searchWord: searchText,
            ...param,
        };

        const response = await gatheringSchedulerPayListApi(input);

        return response.all_cnt;
    };

    return (
        <div>
            {createPaymentsModal && <CreatedPayInfoModal setToggle={setCreatePaymentsModal} />}
            {detailToggle[0] && <PayInfoDetailModal payInfoDetailToggle={detailToggle} setToggle={setDetailToggle} type={'모임'} />}

            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '모임명', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '휴대폰번호', value: 4 },
                    { id: 5, title: '일정명', value: 5 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />

            <TableFilter
                param={param}
                setParam={setParam}
                wrapperStyle={{ width: '95%' }}
                topFilterLists={[
                    {
                        filterText: '정렬 기준',
                        orderLists: [
                            { name: '결제 일시', width: 50, type: 1 },
                            { name: '일정 시작 날짜', width: 100, type: 2 },
                            { name: '일정명', width: 100, type: 3 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderByType = type;
                            else delete obj.orderByType;
                            setParam(obj);
                        },
                        orderbyType: param.orderByType,
                    },
                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림차순', width: 50, type: 1 },
                            { name: '오름차순', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderType = type;
                            else delete obj.orderType;
                            setParam(obj);
                        },
                        orderbyType: param.orderType,
                    },
                ]}
                filterLists={[
                    {
                        key: 'payStatusType',
                        value: '결제 상태',
                        filter: [
                            {
                                key: 0,
                                value: '결제대기중',
                            },
                            {
                                key: 1,
                                value: '입금대기중',
                            },
                            {
                                key: 2,
                                value: '결제완료',
                            },
                            {
                                key: 3,
                                value: '환불신청',
                            },
                            {
                                key: 4,
                                value: '환불완료',
                            },
                            {
                                key: 5,
                                value: '환불거절',
                            },
                            {
                                key: 6,
                                value: '기간 만료',
                            },
                            {
                                key: 7,
                                value: '결제 취소',
                            },
                        ],
                    },
                    {
                        key: 'payType',
                        value: '결제수단',
                        filter: [
                            {
                                key: -1,
                                value: '0원 결제',
                            },
                            {
                                key: 0,
                                value: '국내 카드',
                            },
                            {
                                key: 1,
                                value: '무통장 계좌',
                            },
                            {
                                key: 4,
                                value: '계좌 이체',
                            },
                            {
                                key: 5,
                                value: '현장 결제',
                            },
                        ],
                    },
                    {
                        key: 'payAmountType',
                        value: '결제 금액',
                        filter: [
                            {
                                key: 1,
                                value: '0원 결제',
                                width: '130',
                            },
                            {
                                key: 2,
                                value: '1원 ~ 3,000원',
                                width: '130',
                            },
                            {
                                key: 3,
                                value: '3,001원 ~ 5,000원',
                                width: '130',
                            },
                            {
                                key: 4,
                                value: '5,001원 ~ 10,000원',
                                width: '130',
                            },
                            {
                                key: 5,
                                value: '10,001원 ~ 30,000원',
                                width: '130',
                            },
                            {
                                key: 6,
                                value: '30,001원 ~ 50,000원',
                                width: '130',
                            },
                            {
                                key: 7,
                                value: '50,001원 ~ 100,000원',
                                width: '130',
                            },
                            {
                                key: 8,
                                value: '100,001원 ~',
                                width: '130',
                            },
                        ],
                    },
                    {
                        key: 'accumulatePayType',
                        value: '누적 결제 금액',
                        filter: [
                            {
                                key: 1,
                                value: '0원 결제',
                                width: '130',
                            },
                            {
                                key: 2,
                                value: '1원 ~ 3,000원',
                                width: '130',
                            },
                            {
                                key: 3,
                                value: '3,001원 ~ 5,000원',
                                width: '130',
                            },
                            {
                                key: 4,
                                value: '5,001원 ~ 10,000원',
                                width: '130',
                            },
                            {
                                key: 5,
                                value: '10,001원 ~ 30,000원',
                                width: '130',
                            },
                            {
                                key: 6,
                                value: '30,001원 ~ 50,000원',
                                width: '130',
                            },
                            {
                                key: 7,
                                value: '50,001원 ~ 100,000원',
                                width: '130',
                            },
                            {
                                key: 8,
                                value: '100,001원 ~',
                                width: '130',
                            },
                        ],
                    },
                ]}
                research={ref}
            />

            <MainTable
                buttonComponents={() => (
                    <TopSideButton
                        middleOn={false}
                        rightOn={false}
                        text01={'결제내역생성'}
                        leftWidth={90}
                        onClick={() => {
                            setCreatePaymentsModal((prev) => !prev);
                        }}
                    />
                )}
                toggleBlur={true}
                scrollWidth={190}
                ref={ref}
                tableRef={tableRef}
                loadingText={'결제회원 리스트를 불러오고 있습니다.'}
                headerData={SchedulePayUserFilter}
                checkID={'pay_id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    setDetailToggle([true, item.id]);
                }}
                tbodyRenderItem={(key, value, item) => {
                    const nullPoint = <span style={{ color: '#797979' }}>0원</span>;
                    const nullWon = <span style={{ color: '#797979' }}>0원</span>;
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'pay_status') return payStatusFilter(value);
                    if (key === 'gathering_name') return contentFilter(value, 10);
                    if (key === 'pay_type') return Number(item.pay_type) === 5 && Number(item.pay_status) === 1 ? '헌장 결제 대기' : payTypeFilter(value);
                    if (key === 'use_point_amount') return value ? `${Number(value || 0).toLocaleString('ko-KR')}포인트` : nullPoint;
                    if (key === 'pay_amount') return value ? `${Number(value || 0).toLocaleString('ko-KR')}원` : nullWon;
                    if (key === 'accumulate_pay') {
                        return value ? `${Number(value || 0).toLocaleString('ko-KR')}원` : nullWon;
                    }

                    return value;
                }}
            />
        </div>
    );
};

export default GatheringSchedulePayUser;
