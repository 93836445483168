import React from 'react';
import styled from 'styled-components';

const ContentText = ({ children, style }) => {
    return <Text style={{ ...style }}>{children}</Text>;
};

export default React.memo(ContentText);

const Text = styled.div`
    font-size: 13px;
    font-family: noto_sans_kr_medium;
`;
