import mainRequest from "../request";


export const getGatheringContentsListRequest = async (params) => {
	try {
		const response = await mainRequest.post('/v2/adminGatheringContents/list', params)


		return response?.data


	} catch(err){
		return {
			ok:false,
			status: err.response.status || 500,
			message: err.response.data.message || '서버 에러'
		}
	}
}