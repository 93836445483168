import produce from 'immer';
import { handleActions } from 'redux-actions';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

/************************ action을 정의 ************************/

// 회원정보 조회
export const GET_DELETED_USERS_REQUEST = 'GET_DELETED_USERS_REQUEST';
export const GET_DELETED_USERS_SUCCESS = 'GET_DELETED_USERS_SUCCESS';
export const GET_DELETED_USERS_FAILURE = 'GET_DELETED_USERS_FAILURE';

// 전체회원정보 길이조회
export const GET_ALL_DELETED_USERS_COUNT_REQUEST = 'GET_ALL_DELETED_USERS_COUNT_REQUEST';
export const GET_ALL_DELETED_USERS_COUNT_SUCCESS = 'GET_ALL_DELETED_USERS_COUNT_SUCCESS';
export const GET_ALL_DELETED_USERS_COUNT_FAILURE = 'GET_ALL_DELETED_USERS_COUNT_FAILURE';

// 회원정보 상세 조회
export const GET_DELETED_USERS_DETAIL_REQUEST = 'GET_DELETED_USERS_DETAIL_REQUEST';
export const GET_DELETED_USERS_DETAIL_SUCCESS = 'GET_DELETED_USERS_DETAIL_SUCCESS';
export const GET_DELETED_USERS_DETAIL_FAILURE = 'GET_DELETED_USERS_DETAIL_FAILURE';

// 회원정보 일괄수정
export const BATCH_UPDATE_DELETED_USERS_INFO_REQUEST = 'BATCH_UPDATE_DELETED_USERS_INFO_REQUEST';
export const BATCH_UPDATE_DELETED_USERS_INFO_SUCCESS = 'BATCH_UPDATE_DELETED_USERS_INFO_SUCCESS';
export const BATCH_UPDATE_DELETED_USERS_INFO_FAILURE = 'BATCH_UPDATE_DELETED_USERS_INFO_FAILURE';

// 회원이 가입한 모임 리스트 조회
export const GET_DELETED_USER_GATHERING_LISTS_REQUEST = 'GET_DELETED_USER_GATHERING_LISTS_REQUEST';
export const GET_DELETED_USER_GATHERING_LISTS_SUCCESS = 'GET_DELETED_USER_GATHERING_LISTS_SUCCESS';
export const GET_DELETED_USER_GATHERING_LISTS_FAILURE = 'GET_DELETED_USER_GATHERING_LISTS_FAILURE';

// 상태 초기화
export const RESET_REQUEST = 'RESET_REQUEST';

/************************ 초기상태 ************************/

const initialState = {
    deletedUsers: null, // 회원정보 데이터
    allDeletedUsersCount: null, // 전체회원정보 길이
    deletedUsersDetail: null, // 회원상세정보 데이터
    deletedUserUpdateResultMessage: {}, // 회원정보 업데이트 결과
    deletedUserGatheringLists: [], // 회원이 가입한 모임 리스트
    // 회원정보 조회
    getDeletedUserLoading: false,
    getDeletedUserDone: false,
    getDeletedUserError: null,
    // 전체회원정보 길이조회
    getAllDeletedUserCountLoading: false,
    getAllDeletedUserCountDone: false,
    getAllDeletedUserCountError: null,
    // 회원정보 상세조회
    getDeletedUserDetailLoading: false,
    getDeletedUserDetailDone: false,
    getDeletedUserDetailError: null,
    // 회원정보 일괄수정
    batchUpdateDeletedUsersInfoLoading: false,
    batchUpdateDeletedUsersInfoDone: false,
    batchUpdateDeletedUsersInfoError: null,
    // 회원이 가입한 모임 리스트
    getDeletedUserGatheringListsLoading: false,
    getDeletedUserGatheringListsDone: false,
    getDeletedUserGatheringListsError: null,
};

/************************ action 함수 ************************/

// 회원정보 조회
export const getDeletedUsers = (input) => async (dispatch) => {
    dispatch({
        type: GET_DELETED_USERS_SUCCESS,
    });
    try {
        const response = await RefreshCheckApi.get('/adminDeletedUser', { params: input });
        dispatch({
            type: GET_DELETED_USERS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_DELETED_USERS_FAILURE,
            payload: error,
        });
    }
};

// 전체회원정보 길이조회
export const getAllDeletedUsersCount = (input) => async (dispatch) => {
    dispatch({
        type: GET_ALL_DELETED_USERS_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get('/adminDeletedUser/all_count', { params: input });
        dispatch({
            type: GET_ALL_DELETED_USERS_COUNT_SUCCESS,
            payload: response.data.all_count,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_DELETED_USERS_COUNT_FAILURE,
            payload: error,
        });
    }
};

// 회원정보 상세조회
export const getDeletedUsersDetail = (user_id) => async (dispatch) => {
    dispatch({
        type: GET_DELETED_USERS_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminDeletedUser/${user_id}`);
        dispatch({
            type: GET_DELETED_USERS_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_DELETED_USERS_DETAIL_FAILURE,
            payload: error,
        });
    }
};

// 회원정보 일괄수정
export const batchUpdateDeletedUsersInfo = (idLists, params) => async (dispatch) => {
    dispatch({
        type: BATCH_UPDATE_DELETED_USERS_INFO_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminDeletedUser/userInfo/batchUpdate`, {
            idLists: idLists,
            params: params,
        });
        dispatch({
            type: BATCH_UPDATE_DELETED_USERS_INFO_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: BATCH_UPDATE_DELETED_USERS_INFO_FAILURE,
            payload: error,
        });
    }
};

// 회원이 가입한 모임 리스트 조회
export const getDeletedUserGatheringLists = (user_id, type) => async (dispatch) => {
    dispatch({
        type: GET_DELETED_USER_GATHERING_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminDeletedUser/user/gathering?user_id=${user_id}&type=${type}`);
        dispatch({
            type: GET_DELETED_USER_GATHERING_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_DELETED_USER_GATHERING_LISTS_FAILURE,
            payload: error,
        });
    }
};

/************************ reducer ************************/

const deletedUsersReducer = handleActions(
    {
        // 회원정보 조회
        [GET_DELETED_USERS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserLoading = true;
                draft.getDeletedUserDone = false;
                draft.getDeletedUserError = null;
            }),
        [GET_DELETED_USERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserLoading = action.payload ? false : true;
                draft.deletedUsers = action.payload;
                draft.getDeletedUserDone = action.payload ? true : false;
            }),
        [GET_DELETED_USERS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserLoading = false;
                draft.getDeletedUserError = action.error;
            }),

        // 전체회원정보 길이조회
        [GET_ALL_DELETED_USERS_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getAllDeletedUserCountLoading = true;
                draft.getAllDeletedUserCountDone = false;
                draft.getAllDeletedUserCountError = null;
            }),
        [GET_ALL_DELETED_USERS_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getAllDeletedUserCountLoading = action.payload ? false : true;
                draft.allDeletedUsersCount = action.payload;
                draft.getAllDeletedUserCountDone = action.payload ? true : false;
            }),
        [GET_ALL_DELETED_USERS_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getAllDeletedUserCountLoading = false;
                draft.getAllDeletedUserCountError = action.error;
            }),

        // 회원정보 상세조회
        [GET_DELETED_USERS_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserDetailLoading = false;
                draft.getDeletedUserDetailDone = false;
                draft.getDeletedUserDetailError = null;
            }),
        [GET_DELETED_USERS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserDetailLoading = false;
                draft.deletedUsersDetail = action.payload;
                draft.getDeletedUserDetailDone = true;
            }),
        [GET_DELETED_USERS_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserDetailLoading = false;
                draft.getDeletedUserDetailError = action.error;
            }),

        // 회원정보 일괄수정
        [BATCH_UPDATE_DELETED_USERS_INFO_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.batchUpdateDeletedUsersInfoLoading = true;
                draft.batchUpdateDeletedUsersInfoDone = false;
                draft.batchUpdateDeletedUsersInfoError = null;
            }),
        [BATCH_UPDATE_DELETED_USERS_INFO_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.batchUpdateDeletedUsersInfoLoading = action.payload ? false : true;
                draft.deletedUserUpdateResultMessage = action.payload;
                draft.batchUpdateDeletedUsersInfoDone = action.payload ? true : false;
            }),
        [BATCH_UPDATE_DELETED_USERS_INFO_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.batchUpdateDeletedUsersInfoLoading = false;
                draft.batchUpdateDeletedUsersInfoError = action.error;
            }),

        // 회원이 가입한 모임 리스트 조회
        [GET_DELETED_USER_GATHERING_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserGatheringListsLoading = true;
                draft.getDeletedUserGatheringListsDone = false;
                draft.getDeletedUserGatheringListsError = null;
            }),
        [GET_DELETED_USER_GATHERING_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserGatheringListsLoading = action.payload ? false : true;
                draft.deletedUserGatheringLists = action.payload;
                draft.getDeletedUserGatheringListsDone = action.payload ? true : false;
            }),
        [GET_DELETED_USER_GATHERING_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getDeletedUserGatheringListsLoading = false;
                draft.getDeletedUserGatheringListsError = action.error;
            }),

        // 상태 초기화
        [RESET_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                // 회원정보 일괄수정 상태 초기화
                draft.batchUpdateDeletedUsersInfoLoading = true;
                draft.batchUpdateDeletedUsersInfoDone = false;
                draft.batchUpdateDeletedUsersInfoError = null;
            }),
    },
    initialState,
);

export default deletedUsersReducer;
