import {
    getUsersDetail,
    putUsersDetail,
    putUsersDetailAdminMemo,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import {GET_USERS_DETAIL, PUT_USERS_DETAIL, PUT_USERS_DETAIL_ADMIN_MEMO,} from "./users_detail_type";

export const getUsersDetailAsync = createGetRequestThunk(
  GET_USERS_DETAIL,
  getUsersDetail
);

export const putUsersDetailAsync = createPutRequestThunk(
  PUT_USERS_DETAIL,
  putUsersDetail
);

export const putUsersDetailAdminMemoAsync = createPutRequestThunk(
  PUT_USERS_DETAIL_ADMIN_MEMO,
  putUsersDetailAdminMemo
);
