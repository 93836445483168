/** This function perpose is check for admin role */
export const roleFilter = (num) => {
    const _num = parseInt(num);
    switch (_num) {
        case 1:
            return '관리자';
        case 0:
            return '방문자';
        default:
            return '권한정보 없음';
    }
};

/** This function perpose is check for admin availed */
export const availFilter = (num) => {
    const _num = parseInt(num);
    switch (_num) {
        case 1:
            return '활성화';
        case 2:
            return '비활성화';
        default:
            return '활성화 정보 없음';
    }
};
