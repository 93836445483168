import {
    deleteCoupon,
    getCouponsDetail,
    getCouponUseDetail,
    postCouponDetail, putCouponDetail
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import {GET_COUPONS_DETAIL} from "./coupons_detail_type";
import {
    DELETE_COUPONS,
    GET_COUPONS_HISTORY,
    POST_COUPONS_DETAIL,
    PUT_COUPONS_DETAIL
} from "../coupons_table/coupons_table_type";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import CreateDeleteRequestThunk from "../../redux_functions/create_delete_request_thunk";

export const getCouponsDetailAsync = createGetRequestThunk(
  GET_COUPONS_DETAIL,
  getCouponsDetail
);
export const getCouponsUseDetailAsync = createGetRequestThunk(GET_COUPONS_HISTORY,getCouponUseDetail)
export const postCouponsDetailAsync = createPostRequestThunk(POST_COUPONS_DETAIL,postCouponDetail);
export const putCouponsDetailAsync = createPutRequestThunk(PUT_COUPONS_DETAIL,putCouponDetail);
export const deleteCouponsAsync = CreateDeleteRequestThunk(DELETE_COUPONS,deleteCoupon);
