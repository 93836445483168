import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { rgb_255_255_255, rgb_46_46_46, rgb_66_139_247 } from '../../colors';
import { WhitePlus1x, XMarkWhite1x } from '../../icons/icons';
import { h_px12rate, h_px4rate, w_px12rate, w_px18rate, w_px204rate, w_px60rate, w_px8rate } from '../../size';
import { main_server_image_ip, main_server_ip } from '../../../communication_system/communication_ips';

const MultiImageSelector = ({ profileImgList, setProfileImgList }) => {
    const imageInput = useRef();

    const onCickImageUpload = () => {
        imageInput.current.click();
    };

    const onRemove = (index) => {
        let profileImgTempList = profileImgList.filter((profileImg, profileImgIndex) => {
            if (index !== profileImgIndex) {
                return profileImg;
            }
        });
        setProfileImgList(profileImgTempList);
    };

    return (
        <div
            style={{
                display: 'flex',
                position: 'relative',
                flexWrap: 'wrap',
                width: w_px204rate,
            }}
        >
            <div
                style={{
                    marginRight: w_px12rate,
                }}
            >
                <ImgSelectBtn onClick={onCickImageUpload}>
                    <div>
                        <div
                            style={{
                                marginBottom: h_px4rate,
                            }}
                        >
                            <WhitePlus1x></WhitePlus1x>
                        </div>
                        <ImgSelectText>사진 불러오기</ImgSelectText>
                    </div>
                </ImgSelectBtn>
                <input
                    ref={imageInput}
                    style={{
                        display: 'none',
                    }}
                    type="file"
                    accept="image/*"
                    name="profile_img"
                    multiple={true}
                    onChange={(e) => {
                        let profileImgTempList = [...profileImgList];

                        for (let i = 0; i < e.target.files.length; i++) {
                            profileImgTempList.push(e.target.files[i]);
                        }
                        setProfileImgList(profileImgTempList.slice(0, 5));
                    }}
                ></input>
            </div>
            {profileImgList
                ? profileImgList.map((profileImg, index) => {
                      return (
                          <div
                              key={index}
                              style={{
                                  position: 'relative',
                                  marginRight: index === 1 || index === 4 ? 0 : w_px12rate,
                                  marginBottom: h_px12rate,
                              }}
                          >
                              <UploadImgFrame>
                                  <UploadImg
                                      src={typeof profileImg === 'string' ? (profileImg.includes('http') ? profileImg : main_server_image_ip + profileImg) : URL.createObjectURL(profileImg)}
                                      alt={''}
                                  ></UploadImg>
                                  <RemoveBtn
                                      onClick={() => {
                                          onRemove(index);
                                      }}
                                  >
                                      <XMarkWhite1x></XMarkWhite1x>
                                  </RemoveBtn>
                              </UploadImgFrame>
                          </div>
                      );
                  })
                : null}
        </div>
    );
};

export default React.memo(MultiImageSelector);

const ImgSelectBtn = styled.button`
    width: ${w_px60rate}px;
    height: ${w_px60rate}px;
    border-width: 0px;
    margin: 0px;
    background-color: ${rgb_66_139_247};
`;

const ImgSelectText = styled.div`
    font-size: ${w_px8rate}px;
    font-family: noto_sans_kr_medium;
    color: ${rgb_255_255_255};
`;

const UploadImgFrame = styled.div`
    postion: relative;
`;

const UploadImg = styled.img`
    display: block;
    width: ${w_px60rate}px;
    height: ${w_px60rate}px;
`;

const RemoveBtn = styled.button`
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: ${rgb_46_46_46};
    width: ${w_px18rate}px;
    height: ${w_px18rate}px;
    z-index: 999;
    margin: 0px;
    border-width: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
