import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// components
import UserStatistic from '../../components/StatisticsComponents/UserStatistic';
import PaymentsStatistic from '../../components/StatisticsComponents/PaymentsStatistic';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

/** 통계 페이지 */
const StatisticsPage = () => {
    const [userStatistic, setUserStatistic] = useState(true);
    const [paymentStatistic, setPaymentStatistic] = useState(false);

    useEffect(() => {
        RefreshCheckApi.get('/adminStatus/update/batch');
    }, []);

    const userStatistics = () => {
        setUserStatistic(true);
        setPaymentStatistic(false);
    };

    const paymentStatistics = () => {
        setPaymentStatistic(true);
        setUserStatistic(false);
    };

    const rederItem = [
        { text: '전체 회원 통계', toggle: userStatistic, onPress: userStatistics },
        { text: '전체 매출 통계', toggle: paymentStatistic, onPress: paymentStatistics },
    ];

    return (
        <div>
            <MainPageTitle sigle={false} rederItem={rederItem} />
            {userStatistic && <UserStatistic />}
            {paymentStatistic && <PaymentsStatistic />}
        </div>
    );
};

export default StatisticsPage;

export const ExplainUserCountStaticWrapper = styled.div`
    p {
        margin: 3px 0;
    }
    span {
        font-size: 13px;
        font-weight: 500;
        color: #878787;
    }
`;

export const ChartWrapper = styled.div`
    display: flex;
    gap: 2px; //18px;

    @media (max-width: 912px) {
        flex-direction: column;
        width: auto;
    }
`;

export const TextInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`;

export const TypeInputContainer = styled.div`
    display: flex;
    align-items: center;

    border: 1px solid rgb(229 229 229);
    border-radius: 5px;
`;

export const TypeInput = styled.input`
    cursor: pointer;
    background-color: rgb(255 255 255);
    font-style: noto_sans_kr_medium;
    border: 0px;
    padding-inline: 12px;
    padding-block: 4px;
`;
