import React from 'react';
import styled from 'styled-components';
export const RightSideBar = styled.div`
  position: absolute;
  top: 0;

  overflow-x: hidden;
  white-space: nowrap;

  right: 0;
  z-index: 99;
  background-color: white;
`;
