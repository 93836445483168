import React, { useEffect, useState } from 'react';
import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainButton } from '../../designComponents/buttons/Buttons';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import DesignTable from '../../designComponents/tables/DesignTable';
import { dateFilter, moneyFilter, moneyUpAndDown, sexFilter } from '../../utils/textFilter';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import { MText } from '../../designComponents/text/ContentText';
import { ImageFlexWrap } from '../../designComponents/imageboxs/Image';
import { FlexTwoTextLists } from '../../designComponents/text/TextMapping';
import { InputLine } from '../../designComponents/inputs/InputLine';
import RequestMain from '../../communication_system/axios_apis/refresh_check/requestMain';
import LoadingPage from '../../pages/loading/LoadingPage';

/** 포인트 사용 내역 컴포넌트 */
const UsingPointLists = ({ userId }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [allCount, setAllCount] = useState(0);
    const [onCreatePoint, setOnCreatePoint] = useState(false);
    const [onDetailToggle, setOnDetailToggle] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (userId) {
            let input = {
                page: page,
                size: 10,
                searchType: 5,
                searchWord: userId,
            };
            setLoading(true);
            const response = RefreshCheckApi.get('/adminPoint', { params: input });
            response.then((res) => {
                setData(res.data.data);
                setAllCount(res.data.all_count);
                setLoading(false);
            });
        } else {
            setPage(1);
            setAllCount(0);
            setData([]);
        }
    }, [userId, page]);

    return (
        <Wrapper03 style={{ marginBottom: 0, flex: 1 }}>
            {onCreatePoint && <CreatedPointModal userId={userId} setOnCreatePoint={setOnCreatePoint} />}
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <MainPageTitle wrapperStyle={{ margin: 0 }} fontStyle={{ fontSize: '17px', fontWeight: 600, color: '#797979' }} text={'포인트 사용 내역'} />
                {userId ? (
                    <FlexMainWrapper>
                        <MainButton onClick={() => setOnCreatePoint(true)} text={'포인트 지급'} />
                    </FlexMainWrapper>
                ) : null}
            </FlexMainWrapper>
            <DesignTable
                loading={loading}
                marginTop={0.5}
                onToggle={onDetailToggle.toggle}
                modalComponent={<PointInfoDetailModal point_hist_id={onDetailToggle.id} setToggle={setOnDetailToggle} userId={userId} />}
                header={[
                    { title: '일자', width: 135 },
                    { title: '회원명/닉네임', width: 130 },
                    { title: '전화번호', width: 100 },
                    { title: '증감/차감', width: 60 },
                    { title: '보유 포인트', width: 60 },
                    { title: '변동 사유', width: 130 },
                ]}
                onClick={(item) => {
                    setOnDetailToggle({ toggle: true, id: item.id });
                }}
                body={[
                    { key: 'created_at', width: 135 },
                    { key: 'user_name', width: 130 },
                    { key: 'user_phone_no', width: 100 },
                    { key: 'used_point', width: 60 },
                    { key: 'left_point', width: 60 },
                    { key: 'point_reason_detail', width: 130 },
                ]}
                data={data}
                allCount={allCount}
                page={page}
                setPage={setPage}
                bodyRederItem={(key, value, item) => {
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'user_name') return `${value}/${item.user_nickname}`;
                    if (key === 'used_point') return moneyUpAndDown(value, 'P');
                    if (key === 'left_point') return moneyUpAndDown(value, 'P');
                    return value;
                }}
                loadingText={'요청하신 포인트 사용 내역을 불러오고 있습니다.'}
                noneText={'포인트 사용 내역이 존재하지 않습니다.'}
                noneHieght={27}
            />
        </Wrapper03>
    );
};

export default UsingPointLists;

/** 포인트 지급 모달창 */
const CreatedPointModal = ({ userId, setOnCreatePoint }) => {
    const [data, setData] = useState({});
    const [userImages, setUserImages] = useState([]);
    const [param, setParam] = useState({});
    const [rendering, setRendering] = useState(false);

    useEffect(() => {
        const response = RefreshCheckApi.get(`/adminPoint/user/${userId}`);
        response.then((res) => {
            setData(res.data.data);
            setUserImages(res.data.user_photo);
        });
    }, [userId, rendering]);

    return (
        <DefaultModal
            title={'포인트 지급'}
            boxStyle={{ width: '100vh', height: '70vh' }}
            bottomBtnOn={true}
            closeOn={true}
            saveOn={true}
            saveText={'지급하기'}
            onClickClose={() => setOnCreatePoint(false)}
            onClickBottomClose={() => setOnCreatePoint(false)}
            onClickSave={async () => {
                try {
                    const access_token = localStorage.getItem('access_token');
                    if (access_token) {
                        const body = {
                            target_id: data.user_id,
                            point: param.point,
                        };
                        const response = await RequestMain.post(`/adminRequest/point`, body, {
                            headers: { Authorization: `Bearer ${access_token}` },
                        });
                        if (response.status === 200 && response.data.ok) {
                            setRendering((prev) => !prev);
                            setParam({});
                            alert(`${param.point}포인트 지급 완료`);
                        }
                    }
                } catch (error) {
                    console.error('error: ', error);
                }
            }}
        >
            <FlexMainWrapper style={{ width: '100%', justifyContent: 'center', padding: '2%' }}>
                <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                    <MText text={'지급 대상 정보'} color="#797979" />
                    <ImageFlexWrap
                        objYN={true}
                        keyValue={'url'}
                        imageStyle={{
                            border: '1px solid rgb(227, 227, 227)',
                            borderRadius: '10px',
                            width: '100px',
                            height: '100px',
                        }}
                        image_lists={userImages}
                        imageWidth={80}
                        imageHeight={80}
                        onClickDefault={false}
                        onClick={(src) => {}}
                    />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '보유 포인트',
                                text02: moneyFilter(data?.point || 0) + 'P',
                                text01Style: { color: '#000' },
                                text02Style: { color: 'rgb(66,139,247)' },
                            },
                        ]}
                    />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '회원ID',
                                text02: data?.user_id,
                            },
                            {
                                text01: '회원명',
                                text02: (
                                    <span>
                                        {data?.user_name} / {sexFilter(data?.sex)}
                                    </span>
                                ),
                            },
                            {
                                text01: '회원 닉네임',
                                text02: data?.user_nickname,
                            },
                            {
                                text01: '전화번호',
                                text02: data?.phone_no,
                            },
                            {
                                text01: '나이',
                                text02: data?.age,
                            },
                            {
                                text01: '가입일',
                                text02: dateFilter(data?.user_created_at),
                            },
                            {
                                text01: '최근 접속일',
                                text02: dateFilter(data?.recent_login_datetime),
                            },
                        ]}
                    />
                </FlexMainWrapper>
                <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                    <MText text={'지급 포인트 입력'} color="#797979" />
                    <InputLine
                        title={'지급 포인트'}
                        type="number"
                        wrapperStyle={{ margin: '10px 0' }}
                        titleStyle={{ textAlign: 'start' }}
                        inputStyle={{ height: '22px' }}
                        placeholder={'지급할 포인트를 입력하세요'}
                        value={param.point || ''}
                        onChange={(e) => {
                            let obj = { ...param };
                            obj.point = parseInt(e.target.value);
                            setParam(obj);
                        }}
                    />
                </FlexMainWrapper>
            </FlexMainWrapper>
        </DefaultModal>
    );
};

/** 포인트 상세 조회 모달창 */
const PointInfoDetailModal = ({ userId, setToggle, point_hist_id }) => {
    const [data, setData] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [param, setParam] = useState({});
    const [rendering, setRendering] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const users = RefreshCheckApi.get(`/adminUser/${userId}`);
        users.then((res) => setUserInfo(res.data.data));
        setLoading(true);
        const response = RefreshCheckApi.get(`/adminPoint/detail/${point_hist_id}`);
        response.then((res) => {
            setData(res.data.data);
            setLoading(false);
        });
    }, [userId, point_hist_id, rendering]);

    return (
        <DefaultModal
            title={'포인트 상세 정보'}
            boxStyle={{ width: '100vh', height: '70vh' }}
            bottomBtnOn={true}
            closeOn={true}
            saveOn={true}
            saveText={'지급하기'}
            onClickClose={() => setToggle({ toggle: false })}
            onClickBottomClose={() => setToggle({ toggle: false })}
            onClickSave={async () => {
                try {
                    const access_token = localStorage.getItem('access_token');
                    if (access_token) {
                        const body = {
                            target_id: userInfo.user_id,
                            point: param.point,
                        };
                        const response = await RequestMain.post(`/adminRequest/point`, body, {
                            headers: { Authorization: `Bearer ${access_token}` },
                        });
                        if (response.status === 200 && response.data.ok) {
                            setRendering((prev) => !prev);
                            setParam({});
                            alert(`${param.point}포인트 지급 완료`);
                        }
                    }
                } catch (error) {
                    console.error('error: ', error);
                }
            }}
        >
            {loading ? (
                <LoadingPage text={'포인트 상세정보 데이터를 불러오고 있습니다.'} />
            ) : (
                <FlexMainWrapper style={{ width: '100%', justifyContent: 'center', padding: '2%' }}>
                    <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                        <MText text={'회원 정보'} color="#797979" />
                        <ImageFlexWrap
                            imageStyle={{
                                border: '1px solid rgb(227, 227, 227)',
                                borderRadius: '10px',
                                width: '100px',
                                height: '100px',
                            }}
                            image_lists={userInfo?.profile_list}
                            imageWidth={80}
                            imageHeight={80}
                            onClickDefault={false}
                            onClick={(src) => {}}
                        />
                        <FlexTwoTextLists
                            lists={[
                                {
                                    text01: '현재 보유 포인트',
                                    text02: moneyFilter(data?.left_point || 0) + 'P',
                                    text01Style: { color: '#000' },
                                    text02Style: { color: 'rgb(66,139,247)' },
                                },
                            ]}
                        />
                        <FlexTwoTextLists
                            lists={[
                                {
                                    text01: '회원ID',
                                    text02: userInfo?.id,
                                },
                                {
                                    text01: '회원명',
                                    text02: (
                                        <span>
                                            {userInfo?.name} / {sexFilter(userInfo?.sex)}
                                        </span>
                                    ),
                                },
                                {
                                    text01: '회원 닉네임',
                                    text02: userInfo?.nick_name,
                                },
                                {
                                    text01: '전화번호',
                                    text02: userInfo?.phone_no,
                                },
                                {
                                    text01: '나이',
                                    text02: userInfo?.age,
                                },
                                {
                                    text01: '가입일',
                                    text02: dateFilter(userInfo?.created_at),
                                },
                                {
                                    text01: '최근 접속일',
                                    text02: dateFilter(userInfo?.recent_login_datetime),
                                },
                            ]}
                        />
                    </FlexMainWrapper>
                    <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                        <MText text={'포인트 사용 내역'} color="#797979" />
                        <FlexTwoTextLists
                            lists={[
                                {
                                    text01: '사용 일시',
                                    text02: dateFilter(data.created_at),
                                },
                                {
                                    text01: '상태',
                                    text02: data.reason,
                                },
                                {
                                    text01: '포인트 증감',
                                    text02: <span>{moneyUpAndDown(data?.used_point, 'P')}</span>,
                                },
                                {
                                    text01: '지급 사유',
                                    text02: data.point_reason_detail,
                                },
                            ]}
                        />
                    </FlexMainWrapper>
                </FlexMainWrapper>
            )}
        </DefaultModal>
    );
};
