import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: start;

    #label {
        display: none;
    }
`;

export const MobileBox = styled.div`
    @media (max-width: 912px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        background-color: #000000ab;
    }
`;

export const ModalWrapper = styled.div`
    background-color: #fff;
    width: 430px; //height: 100vh;

    @media (max-width: 912px) {
        position: fixed;
        right: 0;
        left: 0;

        margin: auto;

        width: 98%;
        height: 90%;

        background-color: #fff;
    }
`;

export const Box = styled.div`
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: ${(props) => {
        if (props.limit < 10) {
            return `606px`;
        } else {
            return `${props.height + 33}px`;
        }
    }};

    @media (max-width: 912px) {
        height: 100%;
    }

    #label {
        display: none;
    }
`;

export const CreateTopWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    background-color: ${(props) => (props.updateToggle ? 'rgb(255,16,125)' : '#428bf7')};
    padding: 0 10px;

    width: 95%;
    height: 44px;

    font-size: 17px;
    font-weight: bold;
    color: #fff;

    .close-button {
        cursor: pointer;

        font-size: 20px;
        line-height: 17px;
    }

    @media (max-width: 912px) {
        width: 95.5%;
    }
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    margin: 10px 30px;
`;

export const SubTitle = styled.div`
    font-size: 13px;
    color: #797979;

    width: 100px;
`;

export const Textarea = styled.textarea`
    outline: none;

    padding: 5px;

    width: 84%;
    height: 88px;

    border: 1px solid #428bf742;
`;

export const Input = styled.input`
    outline: none;

    padding: 2px 5px;

    width: 95%;
    height: 24px;

    font-size: 12px;
    border: 1px solid #428bf742;

    ::-webkit-inner-spin-button {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
        user-select: none;
    }
`;

export const ButtonWeapper = styled.div`
    position: absolute;
    bottom: 3%;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    margin-bottom: 10px;

    @media (max-width: 912px) {
        bottom: 7%;
    }

    button {
        cursor: pointer;
        border: 0;
        background-color: #fff;

        width: 120px;
        height: 35px;
    }

    .cencle {
        border: 1px solid rgb(229, 229, 229);
        color: black;

        :hover {
            background-color: rgb(242 242 242);
        }
    }

    .update {
        border: 1px solid rgb(255, 16, 125);
        background-color: rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(198 3 92);
        }
    }

    .save {
        border: 1px solid #428bf7;
        background-color: #428bf7;
        color: #fff;

        :hover {
            background-color: #1d6ee6;
        }
    }
`;

export const CheckboxWrapperLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

export const Checkbox = styled.input`
    cursor: pointer;

    width: 16px;
    height: 16px;

    margin: 0;
    border: 1px solid #428bf742;
`;

export const Text = styled.span`
    font-size: 14px;
`;

export const SearchBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const Button = styled.button`
    cursor: pointer;

    width: 90px;
    height: 31px;

    border: ${(props) => (props.updateToggle ? '1px solid rgb(255,16,125)' : '1px solid #428bf7')};

    color: #fff;
    background-color: ${(props) => (props.updateToggle ? 'rgb(255,16,125)' : '#428bf7')};
`;

export const Select = styled.select`
    cursor: pointer;
    outline: none;

    padding: 2px 1px;
    font-size: 12px;

    width: 98%;
    height: 30px;

    border: 1px solid #428bf742;
`;

export const DateInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;

    width: 281px;
`;

export const DateWrapper = styled.div`
    display: flex;
    align-items: center;

    width: 100%;
    font-size: 14px;

    .date-title {
        border: 1px solid rgb(255, 16, 125); // #428bf742;
        background-color: rgb(255, 16, 125); // #428bf7;

        color: #fff;

        padding: 6px 10px;
        width: 70px;
    }
`;
