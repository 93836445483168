export const pointHistoryListsFilter = [
    {
        key: 'created_at',
        value: '일자',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'user_name',
        value: '회원명',
    },
    {
        key: 'user_nickname',
        value: '닉네임',
    },
    {
        key: 'user_phone_no',
        value: '휴대폰 번호',
    },
    {
        key: 'age',
        value: '나이',
    },
    {
        key: 'used_point',
        value: '포인트 증감',
        filter: [
            {
                key: 1, // 0: 적립, 1: 충전, 4: 환불
                value: '증감',
            },
            {
                key: 3, // 2: 사용, 3: 만료,
                value: '차감',
            },
        ],
    },
    {
        key: 'left_point',
        value: '보유 포인트',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'point_reason_detail', // 0: 적립, 1: 충전, 2: 사용, 3: 만료, 4: 환불
        value: '변동 사유',
        filter: [
            {
                key: 0,
                value: '적립',
            },
            {
                key: 1,
                value: '충전',
            },
            {
                key: 2,
                value: '사용',
            },
            {
                key: 3,
                value: '만료',
            },
            {
                key: 4,
                value: '환불',
            },
        ],
    },
];
