import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { h_px17rate, h_px22rate, h_px30rate, w_px10rate, w_px12rate, w_px26rate, w_px4rate, w_px5rate, w_px60rate, w_px6rate } from '../../size';
import Dropdown from '../../../components/Common/Dropdown';
import SearchBar from '../searchbar/SearchBar';
import {
    ACTIVITY_DETAIL_COMPONENT,
    ACTIVITY_PAYMENT_DETAIL_COMPONENT,
    ADMINISTRATOR_DETAIL_COMPONENT,
    ANNOUNCEMENT_DETAIL_COMPONENT,
    CHATTING_DETAIL_COMPONENT,
    COUPON_DETAIL_COMPONENT,
    EVENT_DETAIL_COMPONENT,
    HOST_DETAIL_COMPONENT,
    NONE_DETAIL_COMPONENT,
    POINT_DETAIL_COMPONENT,
    REPORT_DETAIL_COMPONENT,
    SUBSCRIBE_PAYMENT_DETAIL_COMPONENT,
    USER_DETAIL_COMPONENT,
} from '../../../data_system/detail_data_type/detail_data_type';
import MatchDispatchCenter from '../../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';
import { rgb_255_255_255, rgb_35_40_45, rgb_66_139_247 } from '../../colors';
import CheckDropdown from '../check_dropdown/CheckDropdown';
import produce from 'immer';
import { CHECK_BOX } from '../../../data_system/data_type/data_type';
import ToggleBtn from '../toggle_btn/ToggleBtn';

const TotalTopSection = ({
    bodyContents,
    total_top_section_disable,
    DETAIL_DATA_TYPE,
    itemOpen,
    setItemOpen,
    setChoiceRowIndex,
    registerOpen,
    setRegisterOpen,
    administrator_total_number_disable,
    make_disable,
    remove_disable,
    payment_apply_state_disable,
    current_subscribe_user_state_disable,
    current_active_host_state_disable,
    reward_part_total_number_disable,
}) => {
    const [dropdownItem, setDropdownItem] = useState({
        id: 0,
        title: '휴대폰번호',
        selected: false,
        key: 'phone_number',
        value: 1,
    });

    const resetThenSet = useCallback((item) => {
        setDropdownItem(item);
    }, []);

    const [searchText, setSearchText] = useState('');

    const [checkList, setCheckList] = useState([]);

    const [subscribeToggle, setSubscribeToggle] = useState(false);

    const clickedSubscribeToggle = () => {
        setSubscribeToggle((pre) => !pre);
    };

    const [activityToggle, setActivityToggle] = useState(false);

    const clickedActivityToggle = () => {
        setActivityToggle((pre) => !pre);
    };

    const {
        onGetUsersSearchAsync,
        onGetAdministratorsSearchAsync,
        onDeleteAdministratorsAsync,
        onGetSubscribePaymentsSearchAsync,
        onGetReportsSearchAsync,
        onGetChattingsSearchAsync,
        onGetActivityPaymentsSearchAsync,
        onGetHostsSearchAsync,
        onGetActivitiesSearchAsync,
        onGetPointsSearchAsync,
        onGetCouponsSearchAsync,
        onDeleteAnnouncementsAsync,
    } = MatchDispatchCenter();

    const onSearch = useCallback(() => {
        if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
            onGetUsersSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
            });
        } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
            onGetAdministratorsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
            });
        } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
            onGetSubscribePaymentsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
                now_yn: subscribeToggle ? 1 : 0,
                pay_type: checkList,
            });
        } else if (DETAIL_DATA_TYPE === REPORT_DETAIL_COMPONENT) {
            onGetReportsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
                now_yn: subscribeToggle ? 1 : 0,
            });
        } else if (DETAIL_DATA_TYPE === CHATTING_DETAIL_COMPONENT) {
            onGetChattingsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
            });
        } else if (DETAIL_DATA_TYPE === ACTIVITY_PAYMENT_DETAIL_COMPONENT) {
            onGetActivityPaymentsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
                pay_type: checkList,
            });
        } else if (DETAIL_DATA_TYPE === HOST_DETAIL_COMPONENT) {
            onGetHostsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
                activity_yn: activityToggle ? 1 : 0,
            });
        } else if (DETAIL_DATA_TYPE === ACTIVITY_DETAIL_COMPONENT) {
            onGetActivitiesSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
                activity_yn: activityToggle ? 1 : 0,
            });
        } else if (DETAIL_DATA_TYPE === POINT_DETAIL_COMPONENT) {
            onGetPointsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
            });
        } else if (DETAIL_DATA_TYPE === COUPON_DETAIL_COMPONENT) {
            onGetCouponsSearchAsync({
                keyword: searchText,
                query_type: dropdownItem.value,
            });
        } else if (DETAIL_DATA_TYPE === EVENT_DETAIL_COMPONENT) {
        } else if (DETAIL_DATA_TYPE === ANNOUNCEMENT_DETAIL_COMPONENT) {
        } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
        }
    }, [
        DETAIL_DATA_TYPE,
        onGetUsersSearchAsync,
        onGetAdministratorsSearchAsync,
        onGetSubscribePaymentsSearchAsync,
        onGetReportsSearchAsync,
        onGetChattingsSearchAsync,
        onGetActivityPaymentsSearchAsync,
        onGetHostsSearchAsync,
        onGetActivitiesSearchAsync,
        onGetPointsSearchAsync,
        onGetCouponsSearchAsync,
        searchText,
        dropdownItem.value,
        subscribeToggle,
        activityToggle,
        checkList,
    ]);

    const onChangeSearchText = useCallback((e) => {
        setSearchText(e.target.value);
    }, []);

    const [list, setList] = useState([
        {
            id: 0,
            title: '휴대폰번호',
            selected: false,
            key: 'phone_number',
            value: 1,
        },
        {
            id: 1,
            title: '이름',
            selected: false,
            key: 'name',
            value: 2,
        },
    ]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === REPORT_DETAIL_COMPONENT) {
            setList([
                {
                    id: 0,
                    title: '휴대폰번호',
                    selected: false,
                    key: 'phone_number',
                    value: 1,
                },
                {
                    id: 1,
                    title: '신고자',
                    selected: false,
                    key: 'report',
                    value: 2,
                },
                {
                    id: 2,
                    title: '신고받은 사람',
                    selected: false,
                    key: 'reported',
                    value: 3,
                },
            ]);
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === CHATTING_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === ACTIVITY_PAYMENT_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === HOST_DETAIL_COMPONENT) {
            setList([
                {
                    id: 0,
                    title: '호스트명',
                    selected: false,
                    key: 'host_name',
                    value: 1,
                },
            ]);
            setDropdownItem({
                id: 0,
                title: '호스트명',
                selected: false,
                key: 'host_name',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === ACTIVITY_DETAIL_COMPONENT) {
            setList([
                {
                    id: 0,
                    title: '놀이명',
                    selected: false,
                    key: 'activity_name',
                    value: 1,
                },
            ]);
            setDropdownItem({
                id: 0,
                title: '놀이명',
                selected: false,
                key: 'activity_name',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === POINT_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === COUPON_DETAIL_COMPONENT) {
            setList([
                {
                    id: 0,
                    title: '쿠폰명',
                    selected: false,
                    key: 'coupon_name',
                    value: 1,
                },
            ]);
            setDropdownItem({
                id: 0,
                title: '쿠폰명',
                selected: false,
                key: 'coupon_name',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === EVENT_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === ANNOUNCEMENT_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
            setDropdownItem({
                id: 0,
                title: '휴대폰번호',
                selected: false,
                key: 'phone_number',
                value: 1,
            });
        }

        setLoading(false);
    }, [DETAIL_DATA_TYPE]);

    return (
        !loading && (
            <>
                {!total_top_section_disable && (
                    <SearchSection>
                        <Dropdown list={list} resetThenSet={resetThenSet} index={0}></Dropdown>
                        <div
                            style={{
                                marginLeft: w_px4rate,
                            }}
                        >
                            <SearchBar searchText={searchText} onChangeSearchText={onChangeSearchText} onSearch={onSearch}></SearchBar>
                        </div>
                    </SearchSection>
                )}
                {(!administrator_total_number_disable ||
                    !make_disable ||
                    !remove_disable ||
                    !payment_apply_state_disable ||
                    !current_subscribe_user_state_disable ||
                    !current_active_host_state_disable ||
                    !reward_part_total_number_disable) && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginRight: w_px10rate,
                            marginBottom: h_px17rate,
                        }}
                    >
                        <div>
                            {!administrator_total_number_disable && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <MiddleSectionBlackText>전체 운영자</MiddleSectionBlackText>
                                    <div
                                        style={{
                                            marginLeft: w_px5rate,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <MiddleSectionBlueText>{bodyContents.length}</MiddleSectionBlueText>
                                        <MiddleSectionBlackText>명</MiddleSectionBlackText>
                                    </div>
                                </div>
                            )}
                            {!reward_part_total_number_disable && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <MiddleSectionBlackText>전체</MiddleSectionBlackText>
                                    <div
                                        style={{
                                            marginLeft: w_px5rate,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <MiddleSectionBlueText>{bodyContents.length}</MiddleSectionBlueText>
                                        <MiddleSectionBlackText>명</MiddleSectionBlackText>
                                    </div>
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {!payment_apply_state_disable && (
                                    <div
                                        style={{
                                            marginRight: w_px26rate,
                                        }}
                                    >
                                        <CheckDropdown setCheckList={setCheckList}></CheckDropdown>
                                    </div>
                                )}
                                {!current_subscribe_user_state_disable && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: w_px6rate,
                                            }}
                                        >
                                            <ToggleText>현재 구독중 회원만 보기</ToggleText>
                                        </div>
                                        <ToggleBtn toggle={subscribeToggle} clickedToggle={clickedSubscribeToggle}></ToggleBtn>
                                    </div>
                                )}
                                {!current_active_host_state_disable && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: w_px6rate,
                                            }}
                                        >
                                            <ToggleText>활동 중인 업체만 보기</ToggleText>
                                        </div>
                                        <ToggleBtn toggle={activityToggle} clickedToggle={clickedActivityToggle}></ToggleBtn>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {!make_disable && (
                                <RegisterBtn
                                    onClick={() => {
                                        setChoiceRowIndex(null);
                                        if (!itemOpen && !registerOpen) {
                                            setItemOpen(true);
                                            setRegisterOpen(true);
                                        } else if (itemOpen && registerOpen) {
                                            setItemOpen(false);
                                            setRegisterOpen(false);
                                        } else if (itemOpen && !registerOpen) {
                                            setRegisterOpen(true);
                                        }
                                    }}
                                >
                                    등록
                                </RegisterBtn>
                            )}

                            {!remove_disable && (
                                <div
                                    style={{
                                        marginLeft: w_px10rate,
                                    }}
                                >
                                    <DeleteBtn
                                        onClick={() => {
                                            if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
                                                produce(bodyContents, (bodyContentsDraft) => {
                                                    const tempIdList = [];
                                                    for (let i = 0; i < bodyContentsDraft.length; i++) {
                                                        if (bodyContentsDraft[i]['data'][0]['type'] === CHECK_BOX) {
                                                            if (parseInt(bodyContentsDraft[i]['data'][0].data) === 1) {
                                                                tempIdList.push(bodyContentsDraft[i]['id']);
                                                            }
                                                        }
                                                    }
                                                    onDeleteAdministratorsAsync({
                                                        admin_id: tempIdList,
                                                    });
                                                });
                                            } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === REPORT_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === CHATTING_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === ACTIVITY_PAYMENT_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === HOST_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === ACTIVITY_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === POINT_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === COUPON_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === EVENT_DETAIL_COMPONENT) {
                                            } else if (DETAIL_DATA_TYPE === ANNOUNCEMENT_DETAIL_COMPONENT) {
                                                produce(bodyContents, (bodyContentsDraft) => {
                                                    const tempIdList = [];
                                                    for (let i = 0; i < bodyContentsDraft.length; i++) {
                                                        if (bodyContentsDraft[i]['data'][0]['type'] === CHECK_BOX) {
                                                            if (parseInt(bodyContentsDraft[i]['data'][0].data) === 1) {
                                                                tempIdList.push(bodyContentsDraft[i]['id']);
                                                            }
                                                        }
                                                    }
                                                    onDeleteAnnouncementsAsync({
                                                        announce_id: tempIdList,
                                                    });
                                                });
                                            } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
                                            }
                                        }}
                                    >
                                        삭제
                                    </DeleteBtn>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    );
};

export default React.memo(TotalTopSection);

const SearchSection = styled.div`
    position: relative;
    display: flex;
    margin-bottom: ${h_px22rate}px;
    z-index: 999;
`;

export const MiddleSectionBlackText = styled.div`
    font-size: 12px;
    color: ${rgb_35_40_45};
    font-family: noto_sans_kr_bold;
`;

export const MiddleSectionBlueText = styled.div`
    font-size: 12px;
    color: ${rgb_66_139_247};
    font-family: noto_sans_kr_bold;
`;

const RegisterBtn = styled.button`
    width: ${w_px60rate}px;
    height: ${h_px30rate}px;
    border: 1px solid ${rgb_66_139_247};
    background-color: ${rgb_66_139_247};
    color: ${rgb_255_255_255};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${w_px12rate}px;
    font-family: noto_sans_kr_bold;
`;

const DeleteBtn = styled.button`
    width: ${w_px60rate}px;
    height: ${h_px30rate}px;
    border: 1px solid ${rgb_66_139_247};
    background-color: ${rgb_255_255_255};
    color: ${rgb_66_139_247};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${w_px12rate}px;
    font-family: noto_sans_kr_bold;
`;

export const ToggleText = styled.div`
    font-size: 15px;
    font-family: noto_sans_kr_medium;
`;
