import {
    getActivitiesDetailAsync,
    putActivitiesDetailAdminMemoAsync,
    putActivityDetailAsync,
} from '../activities_reducer/activities_detail/activities_detail_async';
import {
    deleteActivitiesListAsync,
    deleteReviewListsAsync,
    getActivitiesAsync,
    getActivitiesSearchAsync,
    getReviewListsAsync,
    postActivitiesAsync,
} from '../activities_reducer/activities_table/activities_table_async';
import {
    getActivityPaymentsDetailAsync,
    getActivityPaymentsDetailRefundAsync,
    putActivityPaymentsDetailAdminMemoAsync,
} from '../activityPayments_reducer/activityPayments_detail/activityPayments_detail_async';
import {
    getActivityPaymentsAsync,
    getActivityPaymentsSearchAsync,
} from '../activityPayments_reducer/activityPayments_table/activityPayments_table_async';
import {
    getAdministratorsDetailAsync,
    putAdministratorsDetailAsync,
} from '../administrators_reducer/administrators_detail/administrators_detail_async';
import { setAdministrators } from '../administrators_reducer/administrators_table/administrators_table_actions';
import {
    deleteAdministratorsAsync,
    getAdministratorsAsync,
    getAdministratorsSearchAsync,
    postAdministratorsAsync,
} from '../administrators_reducer/administrators_table/administrators_table_async';
import {
    getAnnouncementsDetailAsync,
    putAnnouncementsDetailAsync,
} from '../announcements_reducer/announcements_detail/announcements_detail_async';
import { setAnnouncements } from '../announcements_reducer/announcements_table/announcements_table_actions';
import {
    deleteAnnouncementsAsync,
    getAnnouncementsAsync,
    postAnnouncementsAsync,
} from '../announcements_reducer/announcements_table/announcements_table_async';

import {
    deleteCouponsAsync,
    getCouponsDetailAsync,
    getCouponsUseDetailAsync,
    postCouponsDetailAsync,
    putCouponsDetailAsync,
} from '../coupons_reducer/coupons_detail/coupons_detail_async';
import { getCouponsAsync, getCouponsSearchAsync } from '../coupons_reducer/coupons_table/coupons_table_async';

import {
    getEventsDetailAsync,
    putEventOrderAsync,
    putEventsDetailAsync,
    putEventsDetailTransformAsync,
} from '../events_reducer/events_detail/events_detail_async';
import {
    getEventsAsync,
    postEventsAsync,
    postAdminEventsAsync,
    deleteAdminEventsAsync,
} from '../events_reducer/events_table/events_table_async';

import {
    getAllHostsAsync,
    getHostsDetailAsync,
    putHostsDetailAdminMemoAsync,
    putHostsDetailAsync,
} from '../hosts_reducer/hosts_detail/hosts_detail_async';
import {
    deleteHostAsync,
    getHostsAsync,
    getHostsSearchAsync,
    postHostsAsync,
} from '../hosts_reducer/hosts_table/hosts_table_async';
import { getPointsDetailAsync } from '../points_reducer/points_detail/points_detail_async';
import { getPointsAsync, getPointsSearchAsync, postPointsAsync } from '../points_reducer/points_table/points_table_async';
import MatchDispatch from '../redux_functions/match_dispatch';
import { postReportsDetailAdminMemoAsync } from '../reports.reducer/reports_detail/reports_detail_async';
import { getReportsAsync, getReportsSearchAsync } from '../reports.reducer/reports_table/reports_table_async';
import {
    getSubscribePaymentsDetailAsync,
    getSubscribePaymentsDetailRefundAsync,
    putSubscribePaymentsDetailAdminMemoAsync,
    putSubscribePaymentsDetailDescribeAddAsync,
} from '../subscribePayments_reducer/subscribePayments_detail/subscribePayments_detail_async';
import {
    getSubscribePaymentsAsync,
    getSubscribePaymentsSearchAsync,
} from '../subscribePayments_reducer/subscribePayments_table/subscribePayments_table_async';
import {
    getUsersDetailAsync,
    putUsersDetailAdminMemoAsync,
    putUsersDetailAsync,
} from '../users_reducer/users_detail/users_detail_async';
import { getUsersAsync, getUsersSearchAsync } from '../users_reducer/users_table/users_table_async';
import { getCommonCodeAsync } from '../commonCode_reducer/commonCode_detail/common_code_async';
import { getSmsUserAsync } from '../sms_reducer/sms_async';
import {
    getStatisticsAccountAccumulateDate,
    getStatisticsAccountAccumulateToday,
    getStatisticsAccountCurrentGraphData,
    getStatisticsAccountTableData,
    getStatisticsAdditionalRate,
    getStatisticsLineData,
    getStatisticsPayment,
} from '../statistics_reducer/statistics_async';
import { getActiveUserGraphAsync, getRetentionTableAsync } from '../marketing_reduter/marketing_async';

const MatchDispatchCenter = () => {
    return {
        onGetUsersAsync: MatchDispatch(getUsersAsync),
        onGetUsersDetailAsync: MatchDispatch(getUsersDetailAsync),
        onGetAdministratorsAsync: MatchDispatch(getAdministratorsAsync),
        onGetAdministratorsDetailAsync: MatchDispatch(getAdministratorsDetailAsync),
        onGetSubscribePaymentsAsync: MatchDispatch(getSubscribePaymentsAsync),
        onGetSubscribePaymentsDetailAsync: MatchDispatch(getSubscribePaymentsDetailAsync),
        onGetReportsAsync: MatchDispatch(getReportsAsync),
        onGetActivityPaymentsAsync: MatchDispatch(getActivityPaymentsAsync),
        onGetActivityPaymentsDetailAsync: MatchDispatch(getActivityPaymentsDetailAsync),
        onGetHostsAsync: MatchDispatch(getHostsAsync),
        onGetHostsDetailAsync: MatchDispatch(getHostsDetailAsync),
        onGetActivitiesAsync: MatchDispatch(getActivitiesAsync),
        onGetActivitiesDetailAsync: MatchDispatch(getActivitiesDetailAsync),
        onGetPointsAsync: MatchDispatch(getPointsAsync),
        onGetPointsDetailAsync: MatchDispatch(getPointsDetailAsync),
        onGetCouponsAsync: MatchDispatch(getCouponsAsync),
        onGetCouponsDetailAsync: MatchDispatch(getCouponsDetailAsync),
        onDeleteCouponsAsync: MatchDispatch(deleteCouponsAsync),
        onGetEventsAsync: MatchDispatch(getEventsAsync),
        onGetEventsDetailAsync: MatchDispatch(getEventsDetailAsync),
        onGetAnnouncementsAsync: MatchDispatch(getAnnouncementsAsync),
        onGetAnnouncementsDetailAsync: MatchDispatch(getAnnouncementsDetailAsync),
        onPutUsersDetailAsync: MatchDispatch(putUsersDetailAsync),
        onGetUsersSearchAsync: MatchDispatch(getUsersSearchAsync),
        onPutAdministratorsDetailAsync: MatchDispatch(putAdministratorsDetailAsync),
        onGetAdministratorsSearchAsync: MatchDispatch(getAdministratorsSearchAsync),
        onPostAdministratorsAsync: MatchDispatch(postAdministratorsAsync),
        onDeleteAdministratorsAsync: MatchDispatch(deleteAdministratorsAsync),
        onGetSubscribePaymentsSearchAsync: MatchDispatch(getSubscribePaymentsSearchAsync),
        onPutSubscribePaymentsDetailAdminMemoAsync: MatchDispatch(putSubscribePaymentsDetailAdminMemoAsync),
        onPutSubscribePaymentsDetailDescribeAddAsync: MatchDispatch(putSubscribePaymentsDetailDescribeAddAsync),
        onGetSubscribePaymentsDetailRefundAsync: MatchDispatch(getSubscribePaymentsDetailRefundAsync),
        onGetReportsSearchAsync: MatchDispatch(getReportsSearchAsync),
        onGetActivityPaymentsSearchAsync: MatchDispatch(getActivityPaymentsSearchAsync),
        onGetHostsSearchAsync: MatchDispatch(getHostsSearchAsync),
        onGetActivitiesSearchAsync: MatchDispatch(getActivitiesSearchAsync),
        onGetPointsSearchAsync: MatchDispatch(getPointsSearchAsync),
        onGetCouponsSearchAsync: MatchDispatch(getCouponsSearchAsync),
        onPostHostsAsync: MatchDispatch(postHostsAsync),
        onPostActivitiesAsync: MatchDispatch(postActivitiesAsync),
        onSetAdministrators: MatchDispatch(setAdministrators),
        onPutAnnouncementsDetailAsync: MatchDispatch(putAnnouncementsDetailAsync),
        onPostAnnouncementsAsync: MatchDispatch(postAnnouncementsAsync),
        onDeleteAnnouncementsAsync: MatchDispatch(deleteAnnouncementsAsync),
        onSetAnnouncements: MatchDispatch(setAnnouncements),
        onPostReportsDetailAdminMemoAsync: MatchDispatch(postReportsDetailAdminMemoAsync),
        onPutActivityPaymentsDetailAdminMemoAsync: MatchDispatch(putActivityPaymentsDetailAdminMemoAsync),
        onPutHostsDetailAdminMemoAsync: MatchDispatch(putHostsDetailAdminMemoAsync),
        onPutActivitiesDetailAdminMemoAsync: MatchDispatch(putActivitiesDetailAdminMemoAsync),
        onPutActivityDetailAsync: MatchDispatch(putActivityDetailAsync),
        onPutUsersDetailAdminMemoAsync: MatchDispatch(putUsersDetailAdminMemoAsync),
        onGetReviewListsAsync: MatchDispatch(getReviewListsAsync),
        onDeleteReviewListsAsync: MatchDispatch(deleteReviewListsAsync),
        onPostPointsAsync: MatchDispatch(postPointsAsync),
        onGetActivityPaymentsDetailRefundAsync: MatchDispatch(getActivityPaymentsDetailRefundAsync),
        onPutHostsDetailAsync: MatchDispatch(putHostsDetailAsync),
        onPutEventsDetailAsync: MatchDispatch(putEventsDetailAsync),
        onPostEventsAsync: MatchDispatch(postEventsAsync),
        onGetCommonCodeAsync: MatchDispatch(getCommonCodeAsync),
        onGetSmsUserAsync: MatchDispatch(getSmsUserAsync),
        onGetStatisticsAccountTableData: MatchDispatch(getStatisticsAccountTableData),
        onGetStatisticsAccountAccumulateDate: MatchDispatch(getStatisticsAccountAccumulateDate),
        onGetStatisticsAccountAccumulateToday: MatchDispatch(getStatisticsAccountAccumulateToday), // 회원데이터
        onGetStatisticsLineData: MatchDispatch(getStatisticsLineData),
        onGetStatisticsAccountCurrentGraphData: MatchDispatch(getStatisticsAccountCurrentGraphData),
        onGetStatisticsPayment: MatchDispatch(getStatisticsPayment),
        onGetAllHostsAsync: MatchDispatch(getAllHostsAsync),
        onGetCouponsUseDetailAsync: MatchDispatch(getCouponsUseDetailAsync),
        onPostCouponsDetailAsync: MatchDispatch(postCouponsDetailAsync),
        onPutCouponsDetailAsync: MatchDispatch(putCouponsDetailAsync),
        onPutEventOrderAsync: MatchDispatch(putEventOrderAsync),
        onPostAdminEventsAsync: MatchDispatch(postAdminEventsAsync),
        onDeleteHostAsync: MatchDispatch(deleteHostAsync),
        onDeleteAdminEventAsync: MatchDispatch(deleteAdminEventsAsync),
        onDeleteAdminActivityListAsync: MatchDispatch(deleteActivitiesListAsync),
        onGetStatisticsAccountTableDataAsync: MatchDispatch(getStatisticsAccountTableData),
        onGetStatisticsAdditionalRate: MatchDispatch(getStatisticsAdditionalRate),
        onGetActiveUserGraphAsync: MatchDispatch(getActiveUserGraphAsync), // 운영관리 > 마케팅 / 활성가입자수
        onGetRetentionTableAsync: MatchDispatch(getRetentionTableAsync), // 운영관리 > 마케팅 / 리텐션
    };
};

export default MatchDispatchCenter;
