import React from 'react';
import styled from 'styled-components';

export const TopMovingButton = ({ icon, onClick = () => {} }) => {
    return (
        <TopButtonWrapper>
            <img className="top-btn" src={icon} alt="맨위로 버튼" onClick={onClick} />
        </TopButtonWrapper>
    );
};

const TopButtonWrapper = styled.div`
    .top-btn {
        cursor: pointer;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 2;

        width: 50px;
        margin: 5vh;

        :hover {
            opacity: 0.5;
        }

        :active {
            opacity: 1;
        }

        @media (max-width: 912px) {
            display: none;
            width: 40px;
        }
    }
`;
