export const FriendsReportFilter = [
    {
        key: 'report_id',
        value: '신고 ID',
    },
    {
        key: 'reported_date',
        value: '신고 일자',
    },
    {
        key: 'reporter_name',
        value: '신고자',
    },
    {
        key: 'reporter_nick_name',
        value: '신고자 닉네임',
    },
    {
        key: 'reported_name',
        value: '피 신고자 이름',
    },
    {
        key: 'reported_nick_name',
        value: '피 신고자 닉네임',
    },
    {
        key: 'report_reason',
        value: '신고 사유',
        filter: [
            {
                key: 0,
                value: '이 사용자에게 관심이 없음',
            },
            {
                key: 1,
                value: '허위 정보',
            },
            {
                key: 2,
                value: '광고/영업',
            },
            {
                key: 3,
                value: '부적절한 프로필 사진',
            },
            {
                key: 4,
                value: '부적절한 메세지',
            },
            {
                key: 5,
                value: '기타(직접 입력)',
            },
        ],
    },
];
