import styled from 'styled-components';

export const ModalWindow = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #0000009c;

    width: 100%;
    height: 100vh;
`;

export const ChooseIntersetBox = styled.div`
    position: relative;
    background-color: #fff;

    padding: 0px 30px;

    width: 50%;
    height: 60vh;

    overflow: auto;
`;

export const TitleLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 20px 30px;
    width: 50%;

    .close-btn {
        cursor: pointer;
        font-size: 17px;
        font-weight: bold;
        color: #797979;
    }
`;

export const Title = styled.div`
    font-size: 25px;
    font-weight: bold;
    color: #428bf7;

    margin-bottom: 5px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 20px 30px;
    width: 50%;

    .update {
        cursor: pointer;

        width: 60px;
        height: 30px;

        background-color: #428bf7;
        color: #fff;

        border: 0;
    }

    .create:hover {
        background-color: #1d6ee6;
    }

    .cancle {
        cursor: pointer;

        width: 60px;
        height: 30px;

        background-color: #fff;
        color: #428bf7;

        border: 1px solid #428bf7;
    }

    .delete:hover {
        background-color: #f1eaea;
    }
`;

export const MinButton = styled.div`
    cursor: pointer;
    text-align: center;

    background-color: #ffd1d1;
    width: 99.2%;

    padding: 2px 0;
    margin: auto;

    :hover {
        background-color: #f2b4b4;
    }
`;

export const MoreButton = styled.div`
    cursor: pointer;
    text-align: center;

    background-color: #e9e5e5;
    width: 99.2%;

    padding: 2px 0;
    margin: auto;

    :hover {
        background-color: #cccccc;
    }
`;
