import React, { useCallback, useContext, useRef, useState } from 'react';
import moment from 'moment';

// axios
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// utils
import { moneyFilter, payStatusFilter } from '../../utils/textFilter';

// filter
import { SyrupPaymentsFilter } from '../../components/SyrupComponents/SyrupPaymentsFilter';

// regacy-components
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

// components
import CreatedPayInfoModal from '../../components/Common/Payments/CreatedPayInfoModal';
import PayInfoDetailModal from '../../components/SyrupComponents/PayInfoDetailModal';
import UserModal from '../../components/UserComponents/UserModal';
import MainTable from '../../designComponents/tables/MainTable';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import UserLists from './UserLists';
import AddSyrup from './AddSyrup';
import TableFilter from '../../designComponents/filters/TableFilter';

/* 시럽 결제 */
const SyrupPayments = () => {
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [createPaymentsModal, setCreatePaymentsModal] = useState(false);
    const [payInfoDetailToggle, setPayInfoDetailToggle] = useState([false, 0]);
    const [userParam, setUserParam] = useState({});
    const [addSyrup, setAddSyrup] = useState({});

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [params, setParams] = useState({
        orderByType: 1,
        orderType: 1,
    });

    const ref = useRef(null);

    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...params,
        };
        const response = await RefreshCheckApi.post('/adminSyrup/payments/list', input);
        return response.data.data;
    };

    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            ...params,
        };
        const response = await RefreshCheckApi.post('/adminSyrup/payments/list', input);
        return response.data.all_cnt;
    };

    return (
        <div>
            {createPaymentsModal && <CreatedPayInfoModal setToggle={setCreatePaymentsModal} />}
            {user_info_modal_state.modalVisible && <UserModal />}
            {payInfoDetailToggle[0] && <PayInfoDetailModal payInfoDetailToggle={payInfoDetailToggle} setToggle={setPayInfoDetailToggle} />}
            {userParam.toggle && (
                <DefaultModal
                    title={'시럽 등록 > 회원 선택'}
                    bodyWidth={79}
                    bottomBtnOn
                    closeOn={true}
                    xIcon={true}
                    boxStyle={{ width: '135vh', height: '90vh' }}
                    onClickClose={() => setUserParam({})}
                    onClickBottomClose={() => setUserParam({})}
                >
                    <UserLists
                        onClickUser={(item) => {
                            setUserParam({ toggle: false, userData: item });
                            setAddSyrup({ toggle: true });
                        }}
                    />
                </DefaultModal>
            )}
            {addSyrup.toggle && (
                <DefaultModal
                    title={`시럽 등록 (${userParam.userData.name}/${userParam.userData.nickname})`}
                    bodyWidth={29}
                    bottomBtnOn
                    saveOn={true}
                    saveText={'지급'}
                    cancelText={'이전'}
                    closeOn={true}
                    xIcon={true}
                    boxStyle={{ width: '95vh', height: '40vh' }}
                    onClickClose={() => setAddSyrup({})}
                    onClickBottomClose={() => {
                        setUserParam({ toggle: true });
                        setAddSyrup({ toggle: false });
                    }}
                    onClickSave={async () => {
                        try {
                            const body = {
                                user_id: userParam.userData.id,
                                reason_type: 0,
                                ...addSyrup,
                            };
                            if (!body.reason_detail) return alert('지급 사유를 입력해 주세요.');
                            else if (!body.sinor_love) return alert('지급 갯수를 입력해 주세요.');
                            else {
                                if (window.confirm('시럽 지급을 진행하시겠습니까?')) {
                                    const response = await RefreshCheckApi.post('/adminSyrup', body);
                                    if (response.status === 200 && response.data.ok) {
                                        ref?.current?.refresh();
                                        alert('시럽 지급이 완료되었습니다.');
                                    }
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                >
                    <AddSyrup data={userParam.userData} body={addSyrup} setBody={setAddSyrup} />
                </DefaultModal>
            )}

            <FlexMainWrapper style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <SearchbarWithDropdown
                    list={[
                        { id: 1, title: '휴대폰번호', value: 1 },
                        { id: 2, title: '이름', value: 2 },
                        { id: 3, title: '닉네임', value: 3 },
                    ]}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                    onChangeSubmit={(e) => {
                        e.preventDefault();
                        ref?.current?.refresh();
                    }}
                    searchText={searchText}
                    onChangeInput={(e) => {
                        setSearchText(e.target.value);
                    }}
                />

                <TopSideButton
                    rightOn={false}
                    text01={'결제 내역 생성'}
                    onClick={() => setCreatePaymentsModal((prev) => !prev)}
                    leftWidth={100}
                    text03={'시럽 등록'}
                    middleWidth={70}
                    onClickMiddle={() => setUserParam({ toggle: true })}
                />
            </FlexMainWrapper>

            <TableFilter
                param={params}
                setParam={setParams}
                topFilterLists={[
                    {
                        filterText: '정렬 기준',
                        orderLists: [{ name: '사용 일시', width: 50, type: 1 }],
                        onChange: (e, type) => {
                            let obj = { ...params };
                            if (e.target.checked)
                                obj.orderByType = type; // setOrderbyType(type);
                            else delete obj.orderByType;
                            setParams(obj);
                        },
                        orderbyType: params.orderByType,
                    },
                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림 차순', width: 50, type: 1 },
                            { name: '오름 차순', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...params };
                            if (e.target.checked)
                                obj.orderType = type; // setOrderbyType(type);
                            else delete obj.orderType;
                            setParams(obj);
                        },
                        orderbyType: params.orderType,
                    },
                ]}
                filterLists={[
                    {
                        key: 'pay_status',
                        value: '결제 상태',
                        filter: [
                            {
                                key: 0,
                                value: '결제 대기 중',
                            },
                            {
                                key: 1,
                                value: '입금 대기 중',
                            },
                            {
                                key: 2,
                                value: '결제 완료',
                            },
                            {
                                key: 3,
                                value: '환불 신청',
                            },
                            {
                                key: 4,
                                value: '환불 완료',
                            },
                            {
                                key: 5,
                                value: '환불 거절',
                            },
                            {
                                key: 6,
                                value: '기간 만료',
                            },
                            {
                                key: 7,
                                value: '결제 취소',
                            },
                        ],
                    },
                    {
                        key: 'pay_type',
                        value: '결제 방식',
                        filter: [
                            {
                                key: -1,
                                value: '0원 결제 ',
                            },
                            {
                                key: 1,
                                value: '무통장 입금',
                            },
                            {
                                key: 0,
                                value: '카드 결제',
                            },
                            {
                                key: 4,
                                value: '계좌 이체',
                            },
                        ],
                    },
                ]}
                research={ref}
            />

            <MainTable
                ref={ref}
                tableStyle={{}}
                toggleBlur={true}
                loadingText={'시럽결제 데이터를 불러오고 있습니다.'}
                headerData={SyrupPaymentsFilter}
                checkID={'pay_id'}
                param={params}
                setParam={setParams}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    setPayInfoDetailToggle([true, item.id]);
                }}
                tbodyRenderItem={(key, value) => {
                    if (key === 'accumulate_amount' || key === 'amount') return <span>{moneyFilter(value)}원</span>;
                    if (key === 'created_at') return moment(value).format('YYYY-MM-DD HH:mm:ss');
                    if (key === 'user_name') return <span style={{ fontWeight: 600 }}>{value}</span>;
                    if (key === 'point') return <span>{moneyFilter(value)}P</span>;
                    if (key === 'pay_status') return <span>{payStatusFilter(Number(value))}</span>;

                    return value;
                }}
            />
        </div>
    );
};

export default SyrupPayments;
