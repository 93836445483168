export const GET_ANNOUNCEMENTS = "admin/GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_SUCCESS = "admin/GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAILURE = "admin/GET_ANNOUNCEMENTS_FAILURE";
export const POST_ANNOUNCEMENTS = "admin/POST_ANNOUNCEMENTS";
export const POST_ANNOUNCEMENTS_SUCCESS = "admin/POST_ANNOUNCEMENTS_SUCCESS";
export const POST_ANNOUNCEMENTS_FAILURE = "admin/POST_ANNOUNCEMENTS_FAILURE";
export const DELETE_ANNOUNCEMENTS = "admin/DELETE_ANNOUNCEMENTS";
export const DELETE_ANNOUNCEMENTS_SUCCESS =
  "admin/DELETE_ANNOUNCEMENTS_SUCCESS";
export const DELETE_ANNOUNCEMENTS_FAILURE =
  "admin/DELETE_ANNOUNCEMENTS_FAILURE";
export const SET_ANNOUNCEMENTS = "admin/SET_ANNOUNCEMENTS";
