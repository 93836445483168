import produce from "immer";
import {handleActions} from "redux-actions";
import {GET_COUPONS_DETAIL_FAILURE, GET_COUPONS_DETAIL_SUCCESS,} from "./coupons_detail/coupons_detail_type";
import {
    GET_COUPONS_FAILURE,
    GET_COUPONS_HISTORY_SUCCESS,
    GET_COUPONS_SUCCESS,
} from "./coupons_table/coupons_table_type";

const initialState = {
  coupons: null,
  couponsDetail: null,
  couponsHistory:null
};

const couponsReducer = handleActions(
  {
    [GET_COUPONS_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.coupons = action.payload;
      }),
    [GET_COUPONS_FAILURE]: (state, action) => produce(state, (draft) => {}),
    [GET_COUPONS_DETAIL_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.couponsDetail = action.payload;
      }),
    [GET_COUPONS_DETAIL_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
      [GET_COUPONS_HISTORY_SUCCESS]:(state,action)=>
          produce(state,(draft)=>{
              draft.couponsHistory = action.payload;
          })
  },
  initialState
);

export default couponsReducer;
