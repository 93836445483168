import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';
import { CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import { AnswerWrapper, QuestionBox, Question, CheckBox } from './QuestionComponent';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionDetailResult } from '../../data_system/redux/modules/survey.reducer/survey.reducer';

// 설문관리 상세조회 > 응답 > 질문
function ResponseQuestion({ setShowDetail, setSummarize, setQuestion, selectQuestionID, setSelectQuestionID }) {
    const dispatch = useDispatch();

    // useParams
    const { survey_id } = useParams();

    // useSelector
    const { questionLists, questionDetailResult } = useSelector((state) => state.getSurveyReducer);

    // state-toggle
    const [selectToggle, setSelectToggle] = useState(false);
    const [showResponseUsers, setShowResponseUsers] = useState(false);

    // state
    const [selectQuestion, setSelectQuestion] = useState('');
    const [usersIDLists, setUsersIDLists] = useState([]);
    const [usersNameLists, setUsersNameLists] = useState([]);

    // useRef
    const currentTarget = useRef();

    // 조회 첫 질문 적용
    useEffect(() => {
        setSelectQuestion(questionLists[0].question);
    }, [questionLists]);

    // 선택지 데이터 요청
    useEffect(() => {
        dispatch(getQuestionDetailResult(selectQuestionID));
    }, [dispatch, selectQuestion, selectQuestionID]);

    // 클릭 감지
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    /******************************** function ********************************/

    // 이미지 모달 외부 클릭 시 Modal창 닫힘
    const handleClickOutside = (event) => {
        if (!currentTarget.current) {
            return;
        } else {
            if (currentTarget && !currentTarget.current.contains(event.target)) {
                setSelectToggle(false);
                setShowResponseUsers(false);
            }
        }
    };

    // 질문 리스트 토글
    const onClickSelect = () => {
        setSelectToggle(true);
    };

    // 질문 선택
    const onClickSetQuestion = (data) => () => {
        setSelectQuestion(data.question);
        setSelectQuestionID(data.id);
        setSelectToggle(false);
    };

    // 응답한 회원 리스트 보기 Toggle
    const onClickShowResponseUsers = (nameLists, idList) => () => {
        setUsersNameLists(nameLists);
        setUsersIDLists(idList);

        setShowResponseUsers(true);
    };

    // 질문에 답한 회원 선택 > 상세 답변 페이지로 이동
    const userResponseDetail = (name, index) => () => {
        const participantID = usersIDLists[index];

        if (name !== 'NULL') {
            window.history.pushState('', '', `/survey/management/detail/${survey_id}/response/detail/${participantID}`);
            setSummarize(false);
            setQuestion(false);
            setShowDetail(true);
            setShowResponseUsers(false);
        }
    };

    /********************************** html **********************************/
    return (
        <Wrapper>
            <label id="label">답변 선택한 회원 목록 모달</label>
            {showResponseUsers && (
                <ResponseUsersListsWindox>
                    <WindowCloseLine>
                        <CloseOutlined className="icon" />
                    </WindowCloseLine>
                    <ResponseUsersListsBox ref={currentTarget}>
                        {usersNameLists?.map((name, index) => {
                            return (
                                <QuestionList key={index} onClick={userResponseDetail(name, index)}>
                                    <UserName>{name !== 'NULL' ? name : <span style={{ color: 'gray' }}>정보없음</span>}</UserName>
                                </QuestionList>
                            );
                        })}
                    </ResponseUsersListsBox>
                </ResponseUsersListsWindox>
            )}

            <div>
                <label id="label">답변 선택 드롭다운 모달</label>
                {selectToggle && (
                    <OptionBox ref={currentTarget}>
                        {questionLists?.map((list, index) => {
                            return (
                                <QuestionList key={index} color={Boolean(list.id === selectQuestionID)} onClick={onClickSetQuestion(list)}>
                                    <div>{list.question}</div>
                                </QuestionList>
                            );
                        })}
                    </OptionBox>
                )}

                <label id="label">답변 선택 드롭다운 INPUT 창</label>
                <QuestionBox>
                    <InputWrapper onClick={onClickSelect}>
                        <div>{selectQuestion}</div>
                        <CaretDownOutlined />
                    </InputWrapper>
                </QuestionBox>

                <div style={{ position: 'relative' }}>
                    <label id="label">선택한 질문 보여주기</label>
                    {questionLists?.map((data, index) => {
                        return (
                            <div className="question-box" key={index}>
                                {data.id === selectQuestionID ? (
                                    <QuestionBox key={index}>
                                        <Question>
                                            {data.sequence + 1}. {data.question}
                                        </Question>
                                    </QuestionBox>
                                ) : null}
                            </div>
                        );
                    })}

                    <label id="label">답변 결과 보기</label>
                    {Array(questionDetailResult?.length)
                        .fill()
                        .map((_, length_index) => {
                            return (
                                <QuestionBox className="result-box" key={length_index}>
                                    {questionDetailResult?.map((result, index) => {
                                        return (
                                            <AnswerWrapper key={index} style={{ marginBottom: '10px' }}>
                                                <CheckBox type="checkbox" checked={result.answer === length_index + 1} readOnly />
                                                <div>{result.select_answer}</div>
                                            </AnswerWrapper>
                                        );
                                    })}

                                    <Line />

                                    {questionDetailResult?.map((result, response_index) => {
                                        return (
                                            <div className="response_count" key={response_index} style={{ position: 'relative' }}>
                                                {result.answer === length_index + 1 ? (
                                                    <ReponseText onClick={onClickShowResponseUsers(result.user_name_lists, result.participant_lists)}>
                                                        <span>응답</span>
                                                        <span>{result.count}</span>
                                                        <span>개</span>
                                                    </ReponseText>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </QuestionBox>
                            );
                        })}
                </div>
            </div>
        </Wrapper>
    );
}

export default ResponseQuestion;

/********************************** styled **********************************/

const Wrapper = styled.div`
    position: relative;
    height: 100vh;

    #label {
        display: none;
    }
`;

const InputWrapper = styled.div`
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: start;
    justify-content: space-between;

    width: 50%;
    padding: 10px 10px;

    font-size: 14px;
    background-color: #fff;

    border: 1px solid #dcdcdc;
    border-radius: 4px;
`;

const OptionBox = styled.div`
    position: absolute;
    top: auto;
    right: 0;
    left: 21px;
    z-index: 3;

    user-select: none;

    box-shadow:
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12),
        0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 3px;

    width: 50%;
    max-height: 55vh;

    overflow: auto;
`;

const QuestionList = styled.div`
    cursor: pointer;
    font-size: 14px;
    padding: 12px 20px;

    background-color: ${(props) => (props.color ? 'rgb(66 139 247 / 20%)' : null)};

    :hover {
        background-color: #cccccc61;
    }
`;

const Line = styled.div`
    background-color: #cccccc;

    width: 100%;
    height: 1px;

    margin-top: 20px;
    margin-bottom: 10px;
`;

const ReponseText = styled.span`
    cursor: pointer;
    color: #1a73e8;
    font-size: 12px;

    width: 100px;
    border-radius: 5px;

    padding: 7px 3px;

    :hover {
        background-color: #d4e4fa;
    }
`;

const UserName = styled.div`
    font-size: 13px;
`;

const WindowCloseLine = styled.div`
    cursor: pointer;

    width: 30%;
    text-align: end;

    .icon {
        padding: 5px 0;

        font-size: 23px;
        color: #d7d7d7;
    }
`;

const ResponseUsersListsWindox = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #00000094;

    width: 100%;
    height: 100vh;
`;

const ResponseUsersListsBox = styled.div`
    user-select: none;

    box-shadow:
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12),
        0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 3px;

    width: 30%;
    max-height: 55vh;

    overflow: auto;
`;
