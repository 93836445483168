import React, { useCallback, useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { requestActiviyLists } from '../../data_system/redux/modules/activities_reducer/activity.reducer';

// styled-components
import styled from 'styled-components';

import MatchDispatchCenter from '../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';

// components
import Dropdown from '../../components/Common/Dropdown';
import SearchBar from '../../design_system/components/searchbar/SearchBar';
import Pagination from '../../design_system/components/admin_table/pagination/Pagination';

// conponents
import ActivityTable from '../../components/ActivityComponent/ActivityTable';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

const Activity = () => {
    const dispatch = useDispatch();
    // useSelector
    const { activityLists } = useSelector((state) => state.activityReducer);

    // state
    const [detailModalToggle, setDetailModalToggle] = useState(false); // 조회, 수정
    const [addModalToggle, setAddModalToggle] = useState(false); // 추가
    const [checkItems, setCheckItems] = useState([]); // 체크된 아이템을 담을 배열
    // param state
    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(false);

    // API
    const { onDeleteAdminActivityListAsync } = MatchDispatchCenter();

    /*********************** useEffect ***********************/

    // 검색 시 요청됨
    useEffect(() => {
        const param = { page: page, size: 10 };

        if (searchText.length > 0) {
            param.searchType = searchType;
            param.searchWord = searchText;
        }

        dispatch(requestActiviyLists(param));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, refresh, search]);

    /*********************** function ***********************/

    // 검색 함수
    const activitySearch = (e) => {
        e.preventDefault();

        setSearch(!search);
        setCheckItems([]);
    };

    // 선택된 놀이 삭제 함수
    const deleteActivityList = async () => {
        const params = {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                activity_id: checkItems,
            },
        };

        if (window.confirm('선택된 놀이 정보를 삭제하시겠습니까?')) {
            await onDeleteAdminActivityListAsync(params);
            setRefresh(!refresh);
        }
    };

    // 검색어 입력 함수
    const onChangeSearchText = useCallback((e) => {
        setSearchText(e.target.value);
    }, []);

    // 놀이추가
    const addActivity = () => {
        setAddModalToggle(true);
        setDetailModalToggle(false);
    };

    // DropDown 선택된 검색 타입
    const dropdown = useCallback((item) => {
        setSearchType(item.value);
    }, []);

    // DropDown에 넘길 option 리스트
    const lists = [
        { id: 1, title: '호스트명', value: 1 },
        { id: 2, title: 'ID', value: 2 },
        { id: 3, title: '놀이명', value: 3 },
    ];

    /*********************** html ***********************/
    return (
        <Wrapper02>
            <MainPageTitle text={'취미/여가'} />

            <div id="search-line">
                <SearchBarWrapper>
                    <Dropdown list={lists} index={0} resetThenSet={dropdown} />
                    <form onSubmit={activitySearch}>
                        <SearchBar searchText={searchText} onChangeSearchText={onChangeSearchText} onSearch={activitySearch} />
                    </form>
                </SearchBarWrapper>
            </div>

            <div id="button-line">
                <ButtonWrapper>
                    <CreateButton onClick={addActivity}>등록</CreateButton>
                    <DeleteButton onClick={deleteActivityList}>삭제</DeleteButton>
                </ButtonWrapper>
            </div>

            <div id="table-line">
                <ActivityTable
                    data={activityLists}
                    checkItems={checkItems}
                    setCheckItems={setCheckItems}
                    detailModalToggle={detailModalToggle}
                    setDetailModalToggle={setDetailModalToggle}
                    addModalToggle={addModalToggle}
                    setAddModalToggle={setAddModalToggle}
                />
            </div>

            <div id="pagination" style={{ margin: '50px 0' }}>
                <Pagination
                    totalPages={activityLists ? Math.round(activityLists.all_count / 10) + 1 : 10}
                    limit={10}
                    page={page}
                    setPage={setPage}
                />
            </div>
        </Wrapper02>
    );
};

export default React.memo(Activity);

/*********************** styled-components ***********************/

const SearchBarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    width: 98%;
    margin: auto;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;

    width: 98%;
    margin: 0 auto 10px auto;
`;

const CreateButton = styled.button`
    cursor: pointer;
    width: 85px;
    height: 35px;

    background-color: rgb(66, 139, 247);
    color: #fff;

    border: none;

    :hover {
        background-color: rgb(44 107 200);
    }
`;

const DeleteButton = styled.button`
    cursor: pointer;
    width: 85px;
    height: 35px;

    background-color: #fff;
    color: black;

    border: 1px solid rgb(229, 229, 229);

    :hover {
        background-color: #eaeaea;
    }
`;
