import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { TextArea } from '../../designComponents/inputs/TextArea';
import { SelectLine } from '../../designComponents/dropdowns/Selectdown';
import { InputLine, InputTitle } from '../../designComponents/inputs/InputLine';
import moment from 'moment/moment';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import CheckboxInput from '../../designComponents/checkboxs/CheckboxInput';
import { isEmptyObj } from '../../utils/textFilter';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';
import React, { useContext, useEffect, useRef, useState } from 'react';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import AlarmTalkConsole from './AlarmTalkConsole';

const SendAlarmTalk = ({ sendUser }) => {
    /** 템플릿 리스트 상태 */
    const [templateLists, setTemplateLists] = useState([]);
    const [alarmParam, setAlarmParam] = useState({});
    const [templateLoading, setTemplateLoading] = useState(true);

    /** 전송결과 */
    const [reserveYN, setReserveYN] = useState(false);
    const [reserveDate, setReserveDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
    const [sendReseltLists, setSendReseltLists] = useState([]);

    const [template, setTemplate] = useState(null);
    const [templateCode, setTemplateCode] = useState(null);

    useEffect(() => {
        getTemplateListData().then();
    }, []);

    useEffect(() => {
        if (sendUser) {
            setAlarmParam((prev) => {
                return { ...prev, 닉네임: sendUser.nick_name };
            });
        }
    }, [sendUser]);

    // 정보 업데이트
    const getTemplateListData = async () => {
        try {
            setTemplateLoading(true);
            const templateResponse = await RefreshCheckApi.get('/adminAlarmTalk/template/list', {
                params: {
                    page: 1,
                    size: 100,
                },
            });

            const templateList = templateResponse.data?.data;

            let listTypes = [
                {
                    id: '미선택',
                    title: '미 선택',
                    value: 'null',
                },
            ]; /** 템플릿 리스트 정리 */
            templateList.forEach((item, index) => {
                const listType = {
                    id: index + 1,
                    title: item.templateName,
                    value: item.templateCode,
                };
                listTypes.push(listType);
            });

            setTemplateLists(listTypes);

            setTemplateLoading(false);

            // 선택시 파라미터 조회
            // let param = {}; /** value는 null 값으로 초기화 */
            // Object.keys(response.data.data[0]?.params).forEach((key) => {
            //     param[key] = null;
            // });
            // setAlarmParam(param);
            // setTemplateLoading(false);
        } catch (err) {}
    };

    const getTemplateDetailData = async (target) => {
        try {
            const templateCode = target.value;

            if (!templateCode) {
                alert('템플릿을 선택해주세요');
            }

            if (target.value === 'null') {
                setAlarmParam({});
                setTemplate(null);
                return;
            }

            setTemplateLoading(true);

            const templateDetailResponse = await RefreshCheckApi.get('/adminAlarmTalk/template', {
                params: {
                    templateCode: templateCode,
                },
            });

            const templateDetail = templateDetailResponse?.data?.data;

            setTemplateCode(templateCode);

            if (templateDetail.params) {
                let param = {};
                Object.keys(templateDetail.params).forEach((key) => {
                    param[key] = null;
                });

                if (sendUser) {
                    param['닉네임'] = sendUser.nick_name;
                }

                setAlarmParam(param);
            }

            setTemplate(templateDetail);
            setTemplateLoading(false);
        } catch (err) {}
    };

    const sendAlarmTalk = async (e) => {
        try {
            if (!templateCode) return alert('템플릿 타입을 선택해 주세요.');
            if (!sendUser) return alert('선택된 회원이 없습니다.');

            if (!window.confirm('작성하신 메세지를 전송하시겠습니까?')) {
                return alert('메세지 전송을 취소하였습니다');
            }

            let boolean = false;

            if (isEmptyObj(alarmParam)) {
                Object.values(alarmParam).forEach((inputData) => {
                    if (!inputData || inputData.trim() === '') boolean = true;
                });
                if (boolean) {
                    return alert('입력란에 빈 값이 존재합니다. 확인해 주세요.');
                }
            }

            if (!sendUser) {
                return alert('선택된 회원이 없습니다.');
            }

            let copyAlarmParam = { ...alarmParam };

            if (Object.keys(alarmParam).includes('닉네임')) copyAlarmParam['닉네임'] = sendUser.nick_name;
            if (Object.keys(alarmParam).includes('이름')) copyAlarmParam['이름'] = sendUser.name;

            try {
                const body = {
                    reserve_date: reserveYN ? reserveDate : undefined,
                    template_code: templateCode,
                    phone_no_list: [sendUser.phone_no],
                    params: copyAlarmParam,
                };
                if (!reserveYN) delete body.reserve_date;
                // if (!isEmptyObj(body.param)) delete body.param;

                const response = await MainRefreshCheckApi.post('adminRequest/alarmTalk', body);
                let resultArr = [...sendReseltLists];
                const success = <span style={{ color: 'blue' }}>{`[Success] ${sendUser.name}/${sendUser.nick_name}님께 전송을 완료했습니다.`}</span>;
                const failed = <span style={{ color: 'red' }}>{`[Failed] ${sendUser.name}/${sendUser.nick_name}님께 전송을 실패했습니다.`}</span>;

                if (response.status === 200 && response.data.data) {
                    resultArr.push(success);
                    setSendReseltLists(resultArr);
                } else {
                    resultArr.push(failed);
                    setSendReseltLists(resultArr);
                }
            } catch (error) {
                console.error(error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <FlexMainWrapper
            style={{
                justifyContent: 'start',
                alignItems: 'center',
                flexDirection: 'row',
                margin: '10px',
            }}
        >
            <TextArea style={{ width: '30%', height: '35vh' }} value={template ? template.content : '템플렛을 선택해주세요'} disabled />
            <AlarmTalkConsole sendReseltLists={sendReseltLists} setSendReseltLists={setSendReseltLists}></AlarmTalkConsole>

            <FlexMainWrapper style={{ justifyContent: 'space-between', alignItems: 'start', width: '30%', flexDirection: 'column', marginLeft: '10px', height: '35vh' }}>
                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between', width: '100%' }}>
                    <SelectLine
                        title={'템플릿'}
                        list={templateLists}
                        wrapperStyle={{ width: '100%', alignItems: 'center', gap: '0' }}
                        titleStyle={{ width: '100px' }}
                        selectStyle={{ fontSize: '12px' }}
                        resetThenSet={getTemplateDetailData}
                    />
                    <FlexMainWrapper style={{ overflow: 'auto', flexDirection: 'column', height: '20vh' }}>
                        {Object.keys(alarmParam).map((item, index) => {
                            let blurLists = ['닉네임', '이름'];
                            let usersName = sendUser ? sendUser.nick_name : '회원을 선택해 주세요.';
                            return (
                                <InputLine
                                    key={index}
                                    wrapperStyle={{}}
                                    titleStyle={{ width: '100px' }}
                                    inputStyle={{ width: '100%', fontSize: '12px' }}
                                    title={item}
                                    placeholder={blurLists.includes(item) ? usersName : '값을 입력해주세요.'}
                                    disabled={blurLists.includes(item)}
                                    onChange={(e) => {
                                        let obj = { ...alarmParam };
                                        obj[item] = e.target.value;
                                        setAlarmParam(obj);
                                    }}
                                />
                            );
                        })}
                    </FlexMainWrapper>

                    {reserveYN && (
                        <FlexMainWrapper style={{ alignItems: 'start', width: '100%' }}>
                            <InputTitle
                                type={'datetime-local'}
                                wrapperStyle={{ gap: 0, margin: 0, width: '80%' }}
                                inputStyle={{ width: '50%', fontSize: '12px' }}
                                titleStyle={{ width: '100px' }}
                                exposure={true}
                                title={'예약 전송'}
                                value={reserveDate}
                                onChange={(e) => setReserveDate(moment(e.target.value).format('YYYY-MM-DD HH:mm'))}
                            />
                            <TopSideButton
                                wrapperStyle={{ textAlign: 'center', width: '20%' }}
                                leftOn={false}
                                middleOn={true}
                                rightOn={false}
                                text03={'초기화'}
                                onClickMiddle={() => setReserveDate(moment().format('YYYY-MM-DD HH:mm'))}
                            />
                        </FlexMainWrapper>
                    )}
                </FlexMainWrapper>
                <FlexMainWrapper style={{ flexDirection: 'row' }}>
                    <TopSideButton wrapperStyle={{ margin: 0, textAlign: 'center' }} leftOn={true} middleOn={false} rightOn={false} leftWidth={100} text01={'메세지 전송'} onClick={sendAlarmTalk} />
                    <CheckboxInput
                        fontStyle={{ fontSize: '14px' }}
                        exposure={true}
                        text={'예약 전송하기'}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setReserveYN(checked);
                            if (!checked) setReserveDate(moment().format('YYYY-MM-DD HH:mm'));
                        }}
                    />
                </FlexMainWrapper>
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export default SendAlarmTalk;
