import {getCoupons, getCouponsSearch,} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import {GET_COUPONS} from "./coupons_table_type";

export const getCouponsAsync = createGetRequestThunk(GET_COUPONS, getCoupons);

export const getCouponsSearchAsync = createGetRequestThunk(
  GET_COUPONS,
  getCouponsSearch
);
