import React, {useCallback, useState} from "react";
import produce from "immer";
import {ArrowDownBlue1x, ArrowUpBlue1x} from "../../icons/icons";
import {h_px38rate, w_px12rate, w_px131rate, w_px133rate, w_px14rate, w_px15rate, w_px29rate,} from "../../size";
import styled from "styled-components";
import {rgb_255_255_255, rgb_35_40_45, rgba_66_139_247_0d2,} from "../../colors";
import CheckBox from "../check_box/CheckBox";

const CheckDropdown = ({ setCheckList }) => {
  const [list, setList] = useState([
    {
      id: 0,
      title: "입금대기",
      selected: false,
      key: "deposit_prepare",
      value: 1,
    },
    {
      id: 1,
      title: "결제완료",
      selected: false,
      key: "payment_complete",
      value: 2,
    },
    {
      id: 2,
      title: "환불신청",
      selected: false,
      key: "refund_ask",
      value: 3,
    },
    {
      id: 3,
      title: "환불완료",
      selected: false,
      key: "refund_complete",
      value: 4,
    },
    {
      id: 4,
      title: "환불거절",
      selected: false,
      key: "deposit_due_over",
      value: 5,
    },
    {
      id: 5,
      title: "결제취소",
      selected: false,
      key: "payment_cancel",
      value: 6,
    },
  ]);

  const [isListOpen, setIsListOpen] = useState(false);

  const toggleList = useCallback(() => {
    setIsListOpen(produce((isListOpen) => !isListOpen));
  }, []);

  return (
    <DropdownFrame>
      <MainTitleBtn type="button" onClick={toggleList} isListOpen={isListOpen}>
        <HeaderTitle>신청상태</HeaderTitle>
        {isListOpen ? (
          <ArrowUpBlue1x></ArrowUpBlue1x>
        ) : (
          <ArrowDownBlue1x></ArrowDownBlue1x>
        )}
      </MainTitleBtn>
      {isListOpen && (
        <DropdownListFrame role="list">
          {list.map((item) => (
            <MenuItemBtn key={item.id}>
              <CheckBox
                onClick={() => {
                  const checkList = [];
                  for (let i = 0; i < list.length; i++) {
                    if (item.id === list[i].id) {
                      if (!item.selected) {
                        checkList.push(item.value);
                      }
                      setList(
                        list.map((item) => {
                          return item.id === list[i].id
                            ? {
                                ...item,
                                selected: !item.selected,
                              }
                            : item;
                        })
                      );
                    } else {
                      if (list[i].selected) {
                        checkList.push(list[i].value);
                      }
                    }
                  }
                  setCheckList(checkList);
                }}
                checked={item.selected}
              ></CheckBox>
              <div
                style={{
                  marginLeft: w_px12rate,
                }}
              >
                {item.title}
              </div>
            </MenuItemBtn>
          ))}
        </DropdownListFrame>
      )}
    </DropdownFrame>
  );
};

export default React.memo(CheckDropdown);

const DropdownFrame = styled.div`
  position: relative;
`;

const MainTitleBtn = styled.button`
  display: flex;
  width: ${w_px133rate}px;
  height: ${h_px38rate}px;
  padding-left: ${w_px14rate}px;
  padding-right: ${w_px14rate}px;
  align-items: center;
  justify-content: space-between;
  background-color: ${rgb_255_255_255};
  border-width: 1px;
  border-color: ${rgba_66_139_247_0d2};
  border-bottom-width: ${(props) => (props.isListOpen ? "0px" : "1px")};
`;

const HeaderTitle = styled.div`
  margin-right: ${w_px29rate}px;
  font-family: noto_sans_kr_medium;
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
`;

const DropdownListFrame = styled.div`
  position: absolute;
  width: ${w_px133rate}px;
  box-sizing: border-box;
  border: 1px solid ${rgba_66_139_247_0d2};
  border-top-width: 0px;
  background-color: ${rgb_255_255_255};
`;

const MenuItemBtn = styled.button`
  display: flex;
  width: ${w_px131rate}px;
  height: ${h_px38rate}px;
  padding-left: ${w_px15rate}px;
  background-color: ${rgb_255_255_255};
  border-width: 0px;
  font-family: noto_sans_kr_medium;
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
  text-align: left;
  align-items: center;
`;
