import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

export const AiCallPayInfo = ({ setParams, payType }) => {
    const [hour, setHour] = useState(0);
    useEffect(() => {
        setParams((pre) => ({ ...pre, hour, pay_amount: hour * 6000 }));
    }, [hour]);
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', paddingBlock: 2 }}>
                <div style={{ fontSize: 13, color: 'gray', flex: 1 }}>결제가격</div>
                <div style={{ flex: 2.5 }}>{String(hour * 6000).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', paddingBlock: 2 }}>
                <div style={{ fontSize: 13, color: 'gray', flex: 1 }}>충전시간</div>
                <div style={{ flex: 2.5 }}>
                    <Input type="number" placeholder="충전 시간" onChange={(e) => setHour(Number(e.target.value))} />
                </div>
            </div>
        </>
    );
};
