import React, { useEffect, useState } from 'react';
import { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import DesignTable from '../../designComponents/tables/DesignTable';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { contentFilter, dateFilter, exposureFilter, roleFilter } from '../../utils/textFilter';
import { NoneUserId } from '../../pages/customerService/CustomerService';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import GatheringDetail from './GatheringDetail';
import LoadingPage from '../../pages/loading/LoadingPage';

/** 운영 모임 */
const OperateGathering = ({ userId }) => {
    const [gatherinDetailInfo, setGatherinDetailInfo] = useState({ toggle: false });
    const [data, setData] = useState([]);
    const [allCount, setAllCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [detailLoading, setDetailLoading] = useState(true);

    useEffect(() => {
        if (userId) {
            const input = {
                userId: userId,
                role: [2, 3],
                page: page,
                size: 10,
            };
            setLoading(true);
            const request = RefreshCheckApi.get('/adminGathering/myGathering', { params: input });
            request.then((res) => {
                setData(res.data.data);
                setAllCount(res.data.allCount);
                setLoading(false);
            });
        } else {
            setData([]);
            setAllCount(0);
            setLoading(false);
        }
    }, [userId, page]);

    return (
        <Wrapper03 style={{ marginBottom: 0, flex: 1 }}>
            {gatherinDetailInfo.toggle && (
                <DefaultModal
                    title={'운영 모임 상세정보'}
                    boxStyle={{ width: '100vh', height: '70vh' }}
                    onClickClose={() => setGatherinDetailInfo({ toggle: false })}
                    closeOn={true}
                    onClickBottomClose={() => setGatherinDetailInfo({ toggle: false })}
                >
                    {detailLoading ? <LoadingPage text={'모임 상세정보를 불러오고 있습니다.'} /> : <GatheringDetail data={gatherinDetailInfo.gatheringDetail} userId={userId} />}
                </DefaultModal>
            )}
            <MainPageTitle wrapperStyle={{ margin: 0 }} fontStyle={{ fontSize: '17px', fontWeight: 600, color: '#797979' }} text={'운영 모임'} />
            {userId ? (
                <DesignTable
                    loading={loading}
                    marginTop={2}
                    onToggle={null}
                    modalComponent={false}
                    header={[
                        { title: '가입일', width: 130 },
                        { title: '권한', width: 50 },
                        { title: '공개여부', width: 50 },
                        { title: '모임명', width: 120 },
                        { title: '모임설명', width: 250 },
                    ]}
                    onClick={async (item) => {
                        try {
                            setDetailLoading(true);
                            const url = `/adminGathering/detail?gathering_id=${item.gatering_id}`;
                            const response = await RefreshCheckApi.get(url);
                            if (response.status === 200) {
                                setGatherinDetailInfo({ toggle: true, gatheringDetail: response.data.findDetailData[0] });
                                setDetailLoading(false);
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                    body={[
                        { key: 'created_at', width: 130 },
                        { key: 'role', width: 50 },
                        { key: 'exposure_yn', width: 50 },
                        { key: 'name', width: 120 },
                        { key: 'explanation', width: 250 },
                    ]}
                    data={data}
                    allCount={allCount}
                    page={page}
                    setPage={setPage}
                    bodyRederItem={(key, value) => {
                        if (key === 'created_at') return dateFilter(value);
                        if (key === 'role') return roleFilter(value);
                        if (key === 'exposure_yn') return exposureFilter(value);
                        if (key === 'name') return contentFilter(value, 9);
                        if (key === 'explanation') return contentFilter(value, 23);
                        return value;
                    }}
                    noneText="운영중인 모임 내역이 존재하지 않습니다."
                    noneHieght={27}
                />
            ) : (
                <NoneUserId text="회원을 선택해 주세요." />
            )}
        </Wrapper03>
    );
};

export default OperateGathering;
