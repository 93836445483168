export const CouponTableFilter = [
    {
        key: 'coupon_name',
        value: '쿠폰명',
        filter: [],
    },
    {
        key: 'coupon_status',
        value: '상태',
    },
    {
        key: 'coupon_target',
        value: '발행 대상',
    },
    {
        key: 'coupon_use',
        value: '사용/발행',
    },
    {
        key: 'coupon_period',
        value: '사용 기간',
        filter: [],
    },
];
