import React, { useState, useEffect } from 'react';

// styled-components
import styled from 'styled-components';
import { ResultWrapper } from '../../design_system/components/modal/NewUserModalStyled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { batchUpdateUsersInfo, RESET_REQUEST } from '../../data_system/redux/modules/users_reducer/admin.users_reducer';

function BatchUpdateModal({ checkItems, checkAllData, setBatchWorkToggle }) {
    const dispatch = useDispatch();
    // useSelector
    const { batchUpdateUsersInfoLoading, userUpdateResultMessage, batchUpdateUsersInfoDone } = useSelector(
        (state) => state.newUsersReducer,
    );
    // state
    const [authUserPictureResult, setAuthUserPictureResult] = useState(null); // 카메라인증 결과
    const [describeExpireDate, setDescribeExpireDate] = useState(''); // 구독 만료 날짜
    const [blurExpire, setBlurExpire] = useState(''); // 지나간인연 구독권 날짜
    const [avail, setAvail] = useState(null); // 회원상태
    const [recommendSelf, setRecommendSelf] = useState(null); // 단짝찾기 여부
    const [sex, setSex] = useState(null); // 성별
    const [alertYN, setAlertYN] = useState(null); // 알람 동의여부
    const [markettingYN, setMarkettingYN] = useState(null); // 마케팅 동의여부
    const [adminMemo, setAdminMemo] = useState(''); // 관리자메모

    // 입력가능 TOGGLE
    const [authUserPictureToggle, setAuthUserPictureToggle] = useState(false);
    const [describeExpireDateToggle, setDescribeExpireDateToggle] = useState(false);
    const [blurExpireToggle, setBlurExpireToggle] = useState(false);
    const [availToggle, setAvailToggle] = useState(false);
    const [recommendSelfToggle, setRecommendSelfToggle] = useState(false);
    const [sexToggle, setSexToggle] = useState(false);
    const [alertYNToggle, setAlertYNToggle] = useState(false);
    const [markettingYNToggle, setMarkettingYNToggle] = useState(false);
    const [adminMemoToggle, setAdminMemoToggle] = useState(false);

    /************************** useEffect **************************/

    // 업데이트 완료 후 처리
    useEffect(() => {
        if (!batchUpdateUsersInfoLoading && batchUpdateUsersInfoDone) {
            alert(
                `${Object.keys(userUpdateResultMessage).map((key, index) =>
                    index === 0 ? `${userUpdateResultMessage[key]}` : `\n${userUpdateResultMessage[key]}`,
                )}`,
            );
            setBatchWorkToggle(false);

            dispatch({
                type: RESET_REQUEST,
            });
        }
    }, [dispatch, setBatchWorkToggle, batchUpdateUsersInfoLoading, userUpdateResultMessage, batchUpdateUsersInfoDone]);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                closeModal();
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /************************** function ***************************/

    // 수정할 목록 고르기
    const onCheckedList = (type) => (event) => {
        const checked = event.target.checked;
        const stateLists = {
            0: setAuthUserPictureToggle, // 카메라인증결과
            1: setDescribeExpireDateToggle, // 구독 만료 날짜
            2: setBlurExpireToggle, // 지나간 인연
            3: setAvailToggle, // 회원상태
            4: setRecommendSelfToggle, // 단짝찾기공개
            5: setSexToggle, // 성별
            6: setAlertYNToggle, // 알림 동의여부
            7: setMarkettingYNToggle, // 마케팅 동의여부
            8: setAdminMemoToggle, // 관리자 메모 여부
        };

        new Array(9).fill().forEach((_, index) => {
            if (type === index + 1) stateLists[index](checked);
        });
    };

    // 체크박스 함수
    const onChangeCheckInput = (type, num) => (event) => {
        const checked = event.target.checked;

        if (checked) {
            switch (true) {
                case type === 1:
                    setAuthUserPictureResult(num);
                    break;
                case type === 4:
                    setAvail(num); // if (num === 1 && blockYn) setBlockYn(0);
                    break;
                case type === 5:
                    setRecommendSelf(num);
                    break;
                case type === 6:
                    setSex(num);
                    break;
                case type === 7:
                    setAlertYN(num);
                    break;
                case type === 8:
                    setMarkettingYN(num);
                    break;
                default:
                    break;
            }
        } else {
            switch (true) {
                case type === 1:
                    setAuthUserPictureResult(null);
                    break;
                case type === 4:
                    setAvail(null);
                    break;
                case type === 5:
                    setRecommendSelf(null);
                    break;
                case type === 6:
                    setSex(null);
                    break;
                case type === 7:
                    setAlertYN(null);
                    break;
                case type === 8:
                    setMarkettingYN(null);
                    break;
                default:
                    break;
            }
        }
    };

    // 빈 객체 확인 함수
    const isEmptyObj = (obj) => {
        if (Object.keys(obj).length > 0) {
            return true;
        }
        return false;
    };

    // 일괄작업 모달창 닫기
    const closeModal = () => {
        let checkParam = {};

        if (authUserPictureResult === 0 || authUserPictureResult) checkParam.authUserPictureResult = authUserPictureResult;
        if (describeExpireDate) checkParam.describeExpireDate = describeExpireDate;
        if (blurExpire) checkParam.blurExpire = blurExpire;
        if (avail) checkParam.avail = avail;
        if (recommendSelf) checkParam.recommendSelf = recommendSelf;
        if (sex) checkParam.sex = sex;
        if (alertYN) checkParam.alertYN = alertYN;
        if (markettingYN) checkParam.markettingYN = markettingYN;
        if (adminMemo) checkParam.adminMemo = adminMemo;

        if (isEmptyObj(checkParam)) {
            if (window.confirm('수정할 내용이 사라집니다. 종료하시겠습니까?')) {
                setBatchWorkToggle(false);
            }
        } else {
            setBatchWorkToggle(false);
        }
    };

    // 일괄수정 적용(서버 요청)
    const onClickAllUpdate = () => {
        let json = {};

        if (authUserPictureToggle && (authUserPictureResult === 0 || authUserPictureResult))
            json.authUserPictureResult = authUserPictureResult;
        if (describeExpireDateToggle && describeExpireDate) json.describeExpireDate = describeExpireDate;
        if (blurExpireToggle && blurExpire) json.blurExpire = blurExpire;
        if (availToggle && (avail === 0 || avail)) json.avail = avail;
        if (recommendSelfToggle && (recommendSelf === 0 || recommendSelf)) json.recommendSelf = recommendSelf;
        if (sexToggle && sex) json.sex = sex;
        if (alertYNToggle && (alertYN === 0 || alertYN)) json.alertYN = alertYN;
        if (markettingYNToggle && (markettingYN === 0 || markettingYN)) json.markettingYN = markettingYN;
        if (adminMemoToggle && adminMemo) json.adminMemo = adminMemo;

        if (isEmptyObj(json)) {
            dispatch(batchUpdateUsersInfo(checkItems, json));
        } else {
            alert('변경할 내용이 없습니다.');
        }
    };

    /**************************** html *****************************/
    return (
        <ModalBoxWrapper>
            <ModalBox>
                <WindowBox>
                    <div className="title">선택회원 일괄작업</div>
                    <div className="close" onClick={closeModal}>
                        닫기
                    </div>
                </WindowBox>

                <SelectUpdateWrapper>
                    <div style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 30px 0px', borderRadius: '10px' }}>
                        <SubTitle style={{ margin: '0 auto 0 5px', padding: '5px 0', fontWeight: 'bold' }}>
                            <span>선택된 회원 목록</span>
                            <span style={{ color: 'black', margin: '0 3px' }}>{checkItems.length}명</span>
                        </SubTitle>

                        <SelectUserLists02 className="select-user-lists">
                            <table>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>이름</th>
                                        <th>나이</th>
                                        <th>닉네임</th>
                                    </tr>
                                </thead>

                                {checkAllData.map((data, index) => {
                                    return (
                                        <tbody key={index}>
                                            <tr>
                                                <td>{data.id}</td>
                                                <td>
                                                    {data.name?.length > 5
                                                        ? data.name.slice(0, 5) + '...'
                                                        : data.name || <span style={{ color: 'gray' }}>NULL</span>}
                                                </td>
                                                <td>{data.age || <span style={{ color: 'gray' }}>NULL</span>}</td>
                                                <td>
                                                    {data.nickname?.length > 5
                                                        ? data.nickname.slice(0, 5) + '...'
                                                        : data.nickname || <span style={{ color: 'gray' }}>NULL</span>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    );
                                })}
                            </table>
                        </SelectUserLists02>
                    </div>

                    <UpdateSelectWrapper id="update-info">
                        <div>
                            <label id="label">1. 카메라 인증 결과 업데이트</label>
                            <div id="update-auth-image-result">
                                <SubInfoWrapper>
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(1)} />
                                        <SubTitle style={{ margin: 0 }}>카메라 인증 결과</SubTitle>
                                    </CheckedWrapper>

                                    <ResultWrapper>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onChange={onChangeCheckInput(1, 0)}
                                                checked={authUserPictureResult === 0}
                                                disabled={!authUserPictureToggle}
                                            />
                                            <span className="result" style={{ color: !authUserPictureToggle ? 'gray' : '' }}>
                                                미인증
                                            </span>
                                        </CheckLine>

                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onChange={onChangeCheckInput(1, 1)}
                                                checked={authUserPictureResult === 1}
                                                disabled={!authUserPictureToggle}
                                            />
                                            <span className="result" style={{ color: !authUserPictureToggle ? 'gray' : '' }}>
                                                인증 대기중
                                            </span>
                                        </CheckLine>

                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onChange={onChangeCheckInput(1, 2)}
                                                checked={authUserPictureResult === 2}
                                                disabled={!authUserPictureToggle}
                                            />
                                            <span className="result" style={{ color: !authUserPictureToggle ? 'gray' : '' }}>
                                                인증 완료
                                            </span>
                                        </CheckLine>
                                    </ResultWrapper>
                                </SubInfoWrapper>
                            </div>

                            <Line />

                            <label id="label">2. 구독 만료 날짜 업데이트</label>
                            <div id="update-discribe-date">
                                <SubInfoWrapper className="describe-expire">
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(2)} />
                                        <SubTitle style={{ margin: 0 }}>구독 만료 날짜</SubTitle>
                                    </CheckedWrapper>

                                    <TextInput
                                        type={'datetime-local'}
                                        style={{ color: !describeExpireDateToggle ? 'gray' : '' }}
                                        value={describeExpireDate}
                                        disabled={!describeExpireDateToggle}
                                        onChange={(event) => setDescribeExpireDate(event.target.value)}
                                    />
                                </SubInfoWrapper>
                            </div>

                            <label id="label">3. 관심있는 친구보기 날짜 업데이트</label>
                            <div id="update-past-relationship">
                                <SubInfoWrapper className="describe-expire">
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(3)} />
                                        <SubTitle style={{ margin: 0 }}>관심있는 친구보기</SubTitle>
                                    </CheckedWrapper>

                                    <TextInput
                                        type={'datetime-local'}
                                        style={{ color: !blurExpireToggle ? 'gray' : '' }}
                                        value={blurExpire}
                                        disabled={!blurExpireToggle}
                                        onChange={(event) => setBlurExpire(event.target.value)}
                                    />
                                </SubInfoWrapper>
                            </div>

                            <Line />

                            <label id="label">4. 회원상태 업데이트</label>
                            <div id="update-user-status">
                                <SubInfoWrapper>
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(4)} />
                                        <SubTitle style={{ margin: 0 }}>회원상태</SubTitle>
                                    </CheckedWrapper>

                                    <ResultWrapper>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(4, 1)}
                                                checked={avail === 1}
                                                disabled={!availToggle}
                                            />
                                            <span className="result" style={{ color: !availToggle ? 'gray' : '' }}>
                                                정상
                                            </span>
                                        </CheckLine>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(4, 0)}
                                                checked={avail === 0}
                                                disabled={!availToggle}
                                            />
                                            <span className="result" style={{ color: !availToggle ? 'gray' : '' }}>
                                                탈퇴
                                            </span>
                                        </CheckLine>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(4, 2)}
                                                checked={avail === 2}
                                                disabled={!availToggle}
                                            />
                                            <span className="result" style={{ color: !availToggle ? 'gray' : '' }}>
                                                이용차단
                                            </span>
                                        </CheckLine>
                                    </ResultWrapper>
                                </SubInfoWrapper>
                            </div>

                            <Line />

                            <label id="label">5. 단짝찾기공개 업데이트</label>
                            <div id="update-recommend">
                                <SubInfoWrapper>
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(5)} />
                                        <SubTitle style={{ margin: 0 }}>단짝찾기 공개</SubTitle>
                                    </CheckedWrapper>

                                    <ResultWrapper>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(5, 1)}
                                                checked={recommendSelf === 1}
                                                disabled={!recommendSelfToggle}
                                            />
                                            <span className="result" style={{ color: !recommendSelfToggle ? 'gray' : '' }}>
                                                공개
                                            </span>
                                        </CheckLine>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(5, 0)}
                                                checked={recommendSelf === 0}
                                                disabled={!recommendSelfToggle}
                                            />
                                            <span className="result" style={{ color: !recommendSelfToggle ? 'gray' : '' }}>
                                                비공개
                                            </span>
                                        </CheckLine>
                                    </ResultWrapper>
                                </SubInfoWrapper>
                            </div>

                            <Line />

                            <label id="label">6. 성별 업데이트</label>
                            <div id="update-sex">
                                <SubInfoWrapper>
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(6)} />
                                        <SubTitle style={{ margin: 0 }}>성별</SubTitle>
                                    </CheckedWrapper>

                                    <ResultWrapper>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(6, 1)}
                                                checked={sex === 1}
                                                disabled={!sexToggle}
                                            />
                                            <span className="result" style={{ color: !sexToggle ? 'gray' : '' }}>
                                                남자
                                            </span>
                                        </CheckLine>

                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(6, 2)}
                                                checked={sex === 2}
                                                disabled={!sexToggle}
                                            />
                                            <span className="result" style={{ color: !sexToggle ? 'gray' : '' }}>
                                                여자
                                            </span>
                                        </CheckLine>
                                    </ResultWrapper>
                                </SubInfoWrapper>
                            </div>

                            <Line />

                            <label id="label">7. 알람 동의여부 업데이트</label>
                            <div id="update-alert-agree">
                                <SubInfoWrapper>
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(7)} />
                                        <SubTitle style={{ margin: 0 }}>알람 동의여부</SubTitle>
                                    </CheckedWrapper>

                                    <ResultWrapper>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(7, 1)}
                                                checked={alertYN === 1}
                                                disabled={!alertYNToggle}
                                            />
                                            <span className="result" style={{ color: !alertYNToggle ? 'gray' : '' }}>
                                                Y
                                            </span>
                                        </CheckLine>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(7, 0)}
                                                checked={alertYN === 0}
                                                disabled={!alertYNToggle}
                                            />
                                            <span className="result" style={{ color: !alertYNToggle ? 'gray' : '' }}>
                                                N
                                            </span>
                                        </CheckLine>
                                    </ResultWrapper>
                                </SubInfoWrapper>
                            </div>

                            <Line />

                            <label id="label">8. 마케팅 동의여부 업데이트</label>
                            <div id="update-marketting-agree">
                                <SubInfoWrapper>
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(8)} />
                                        <SubTitle style={{ margin: 0 }}>마케팅 동의여부</SubTitle>
                                    </CheckedWrapper>

                                    <ResultWrapper>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(8, 1)}
                                                checked={markettingYN === 1}
                                                disabled={!markettingYNToggle}
                                            />
                                            <span className="result" style={{ color: !markettingYNToggle ? 'gray' : '' }}>
                                                Y
                                            </span>
                                        </CheckLine>
                                        <CheckLine className="check-line">
                                            <Input
                                                type="checkbox"
                                                onClick={onChangeCheckInput(8, 0)}
                                                checked={markettingYN === 0}
                                                disabled={!markettingYNToggle}
                                            />
                                            <span className="result" style={{ color: !markettingYNToggle ? 'gray' : '' }}>
                                                N
                                            </span>
                                        </CheckLine>
                                    </ResultWrapper>
                                </SubInfoWrapper>
                            </div>

                            <Line />

                            <label id="label">9. 관리자메모 업데이트</label>
                            <div id="update-admin-memo">
                                <TextInSubInfoWrapper className="introduce">
                                    <CheckedWrapper>
                                        <Checkbox type="checkbox" onChange={onCheckedList(9)} />
                                        <SubTitle style={{ margin: 0 }}>관리자메모</SubTitle>
                                    </CheckedWrapper>

                                    <MemoInput
                                        value={adminMemo}
                                        placeholder={!adminMemoToggle ? '비활성화된 상태입니다.' : '내용을 작성해주세요.'}
                                        onChange={(text) => setAdminMemo(text.target.value)}
                                        disabled={!adminMemoToggle}
                                    />
                                </TextInSubInfoWrapper>
                            </div>
                        </div>
                    </UpdateSelectWrapper>
                </SelectUpdateWrapper>

                <div style={{ display: 'flex', alignContent: 'center', gap: '3px', marginBottom: '23px' }}>
                    <SaveButton id="save-button" onClick={onClickAllUpdate}>
                        일괄수정
                    </SaveButton>
                    <CencleButton id="cencle-button" onClick={closeModal}>
                        취소
                    </CencleButton>
                </div>
            </ModalBox>
        </ModalBoxWrapper>
    );
}

export default BatchUpdateModal;

/************************ styled-components ************************/

const ModalBoxWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;

    background-color: #000000b0;
`;

const ModalBox = styled.div`
    position: fixed;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    width: 900px;
    height: 83vh;

    border-radius: 10px;
    background-color: #fff;

    .loading-icon {
        font-size: 30px;
        font-weight: bold;
    }

    .font {
        font-size: 15px;
        text-align: center;
    }

    @media (max-width: 912px) {
        width: 98%;
        height: 97vh;
    }
`;

const WindowBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    width: 100%;
    height: 5vh;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 45px;

    .title,
    .close {
        padding: 0 15px;
    }

    .close {
        cursor: pointer;
    }

    @media (max-width: 912px) {
        position: absolute;
        top: 0;
    }
`;

const SelectUpdateWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 2px;

    @media (max-width: 912px) {
        height: 100vh;
        flex-direction: column-reverse;
    }
`;

const SelectUserLists02 = styled.div`
    overflow: auto;
    height: 67vh;

    table {
        position: relative;
        border-collapse: collapse;
        width: 352px;
    }

    table > thead > tr {
        position: sticky;
        top: 0;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #f6f3f3; // #fff;

        font-size: 14px;
    }

    table > thead > tr > th {
        text-align: start;
        padding: 5px 10px;
    }

    table > tbody > tr > td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 5px 10px;

        text-align: start;
        font-size: 13px;
    }

    @media (max-width: 912px) {
        height: 33vh;
    }
`;

const UpdateSelectWrapper = styled.div`
    margin-top: 5px;
    overflow: auto;

    padding-top: 10px;
    padding-left: 10px;
    /* margin-right: 5%; */

    height: 71vh;

    label {
        /* margin-bottom: 10px;
    display: block; */
        display: none;
    }

    @media (max-width: 912px) {
        margin-top: 13%;

        width: 100%;
        height: 45vh;

        overflow: auto;
    }
`;

const SaveButton = styled.button`
    cursor: pointer;

    background-color: #2977ff;
    color: #fff;

    border: 0;
    border-radius: 3px;

    width: 100px;
    height: 32px;

    :hover {
        background-color: #1360e6;
    }
`;

const CencleButton = styled.button`
    cursor: pointer;

    background-color: #fff;

    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;

    width: 100px;
    height: 32px;

    :hover {
        background-color: #e1dfdf;
    }
`;

const SubInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 13px;

    margin: 15px 0;

    @media (max-width: 912px) {
        flex-direction: column;
        align-items: start;
        justify-content: start;

        width: 310px;
    }
`;

const SubTitle = styled.div`
    margin-top: 10px;
    width: 150px;

    color: rgb(121, 121, 121);
    font-size: 13px;

    @media (max-width: 912px) {
        width: 100%;
        font-size: 17px;
        /* line-height: 30px; */
    }
`;

const TextInput = styled.input`
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;

    width: 190px;
    height: 27px;

    border: 1px solid rgba(66, 139, 247, 0.2);

    font-size: 13px;
    color: rgb(0, 0, 0);
    font-family: noto_sans_kr_medium;

    box-sizing: border-box;
    margin: 0px;

    &:focus {
        outline: none;
    }
    &:disable {
        background-color: rgb(255, 255, 255);
    }

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
`;

const Checkbox = styled.input`
    cursor: pointer;
    width: 15px;
    height: 15px;

    margin: 0 5px;
`;

const CheckLine = styled.div`
    display: flex;
    align-items: center;

    .result {
        width: 70px;
    }

    input {
        cursor: pointer;
    }

    @media (max-width: 390px) {
        margin-top: 0px;
    }
`;

const TextInSubInfoWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;

    width: 420px;
    font-size: 13px;

    margin: 5px 0;

    @media (max-width: 912px) {
        flex-direction: column;
        width: 310px;
    }
`;

const MemoInput = styled.textarea`
    border: 1px solid rgba(66, 139, 247, 0.2);
    width: 336px;
    height: 130px;

    font-size: 11px;
    font-weight: 500;
    color: rgb(35, 40, 35);

    padding: 5px;

    &:focus {
        outline: none;
    }

    @media (max-width: 912px) {
        margin: 10px 0;

        width: 105%;
        height: 200px;
    }
`;

const CheckedWrapper = styled.div`
    display: flex;
    align-content: center;

    @media (max-width: 912px) {
        margin-bottom: 10px;

        font-weight: bold;
        line-height: 20px;

        input {
            width: 20px;
            height: 20px;
            margin: 0 3px 0 3px;
        }
    }
`;

const Line = styled.div`
    @media (max-width: 912px) {
        width: 98%;
        margin: 23px auto 23px auto;

        border: 1px solid rgba(66, 139, 247, 0.2);
    }
`;

const Input = styled.input`
    cursor: pointer;
    width: 15px;
    height: 15px;
`;
