import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { MText } from '../../designComponents/text/ContentText';
import { Image } from '../../designComponents/imageboxs/Image';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import { InputLine } from '../../designComponents/inputs/InputLine';

const AddSyrup = ({ data, body, setBody }) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'start', width: '95.5%', padding: '20px', gap: 0 }} mobileOn>
            <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                <MText text={'회원 정보'} />
                <FlexMainWrapper style={{ alignItems: 'start' }}>
                    <Image url={data.profile_image_list[0]} width={140} height={140} style={{ border: '1px solid #c7c7c7', borderRadius: '5px' }} />
                    <FlexMainWrapper style={{ flexDirection: 'column', gap: '15px' }}>
                        <FlexTwoText text01={'회원 ID'} text02={data.id} text01Style={{ width: '60px', textAlign: 'start' }} />
                        <FlexTwoText text01={'이름'} text02={data.name} text01Style={{ width: '60px', textAlign: 'start' }} />
                        <FlexTwoText text01={'닉네임'} text02={data.nickname} text01Style={{ width: '60px', textAlign: 'start' }} />
                        <FlexTwoText text01={'전화번호'} text02={data.phone_no} text01Style={{ width: '60px', textAlign: 'start' }} />
                        <FlexTwoText text01={'생년월일'} text02={data.birthday} text01Style={{ width: '60px', textAlign: 'start' }} />
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </FlexMainWrapper>
            <FlexMainWrapper style={{ flexDirection: 'column', width: '50%' }}>
                <MText text={'지급 시럽'} />
                <InputLine
                    title={'시럽 갯수'}
                    type="number"
                    placeholder={'시럽 갯수를 입력해 주세요.'}
                    wrapperStyle={{ margin: 0 }}
                    titleStyle={{ textAlign: 'start' }}
                    inputStyle={{ height: '22px' }}
                    onChange={(e) => {
                        let obj = { ...body };
                        obj.sinor_love = e.target.value;
                        setBody(obj);
                    }}
                />
                <InputLine
                    title={'지급 사유'}
                    type="text"
                    placeholder={'지급 사유를 입력해 주세요.'}
                    wrapperStyle={{ margin: 0 }}
                    titleStyle={{ textAlign: 'start' }}
                    inputStyle={{ height: '22px' }}
                    onChange={(e) => {
                        let obj = { ...body };
                        obj.reason_detail = e.target.value;
                        setBody(obj);
                    }}
                />
            </FlexMainWrapper>
        </FlexMainWrapper>
    );
};

export default AddSyrup;
