import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import MainTable from '../../designComponents/tables/MainTable';
import { ScheduleTableFilter } from '../MockData/ScheduleTableFilter';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import ReadScheduleModal from './ModalComponents/ReadScheduleModal';
import CreateScheduleModal from './ModalComponents/CreateScheduleModal';
import ChoisGatheringModal from './ModalComponents/ChoisGatheringModal';
import { availFilter, contentFilter, dateFilter } from '../../utils/textFilter';
import { FilterToggleButton } from '../../designComponents/buttons/FilterToggleButton';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { ImageModalWrapper, CustomCloseOutlined, ModalImage } from '../styles/GatheringDetailComponentStyled';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';
import RequestMain from '../../communication_system/axios_apis/refresh_check/requestMain';
import { MainButton } from '../../designComponents/buttons/Buttons';
import TableFilter from '../../designComponents/filters/TableFilter';
import { gatheringScheduleListApi } from '../../api/main/gathering/GatheringScheduleRequest';

/** 일정 리스트 조회 */
const GatheringScheduleLists = () => {
    const location = useLocation();
    const queryString = new URLSearchParams(location.search).get('gathering_name');

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [open, setOpen] = useState(false);
    const [param, setParam] = useState({
        orderType: 1,
        orderByType: 1,
    });

    const [rendering, setRendering] = useState(false);
    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');
    const [gatheringID, setGatheringID] = useState(0);
    const [readScheduleModalToggle, setReadScheduleModalToggle] = useState(false);
    const [createToggle, setCreateToggle] = useState(false);
    const [choiseScheduleID, setChoiseScheduleID] = useState(0);
    const [data, setData] = useState([]);

    const currentTarget = useRef(null);
    const ref = useRef(null);
    const tableRef = useRef(null);

    const handleClickOutside = (event) => {
        if (currentTarget.current) {
            if (currentTarget && !currentTarget.current.contains(event.target)) {
                setImageToggle((prev) => !prev);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const callApi = async (page, params) => {
        let input = {
            page: page || 1,
            size: 10,
            searchType: queryString ? 2 : searchType,
            searchWord: queryString ? queryString : searchText,
            today_filter: open ? 1 : 0,
            ...params,
        };

        const response = await gatheringScheduleListApi(input);
        return response.data;
    };

    const pageCountCallApi = async (page, params) => {
        let input = {
            page: page || 1,
            size: 10,
            searchType: queryString ? 2 : searchType,
            searchWord: queryString ? queryString : searchText,
            today_filter: open ? 1 : 0,
            ...params,
        };

        const response = await gatheringScheduleListApi(input);
        return response?.all_cnt;
    };

    const onClickCreate = () => {
        setCreateToggle(true);
        setReadScheduleModalToggle(false);
    };

    const onClickMiddle = async (data) => {
        if (data.length !== 0) {
            if (window.confirm('선택한 일정을 취소하시겠습니까?')) {
                data.forEach((item) => {
                    const tokem = MainRefreshCheckApi.post('/auth/testLogin', { id: item.owner_id });
                    tokem.then((res) => {
                        const accessToken = res.data.data.access_token;
                        const config = { headers: { Authorization: `Bearer ${accessToken}` } };
                        const body = { contents_id: item.id };
                        const response = RequestMain.post('/gatheringDate/cancel', body, config);
                        response
                            .then((res) => {
                                if (res.data.ok) {
                                    ref.current?.refresh();
                                    alert(`${item.title}의 일정이 취소 되었습니다.`);
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                                alert('[Error]서버 관리자에게 문의 바랍니다.');
                            });
                    });
                });
            }
        } else alert('선택된 일정이 없습니다.');
    };

    const onClickDelete = (checkItems) => {
        if (checkItems.length !== 0) {
            if (window.confirm('선택한 일정을 삭제하시겠습니까?')) {
                checkItems.forEach((item) => {
                    const response = RefreshCheckApi.patch('/adminGathering/schedule/delete', { id_lists: checkItems });
                    response
                        .then((res) => {
                            ref.current?.refresh();
                            alert(res.data.message);
                        })
                        .catch((err) => {
                            console.error(err);
                            alert('[Error]서버 관리자에게 문의 바랍니다.');
                        });
                });
            }
        } else alert('선택된 일정이 없습니다.');
    };

    return (
        <div id="schedule-lists">
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '일정명', value: 1 },
                    { id: 2, title: '모임명', value: 2 },
                    { id: 3, title: '일정개설자', value: 3 },
                    { id: 4, title: '모임장이름', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />

            <TableFilter
                param={param}
                setParam={setParam}
                wrapperStyle={{ width: '95%' }}
                topFilterLists={[
                    {
                        filterText: '정렬 기준',
                        orderLists: [
                            { name: '생성 일', width: 50, type: 1 },
                            { name: '일정 시작 날짜', width: 100, type: 2 },
                            { name: '결제 인원 수', width: 100, type: 3 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderByType = type;
                            else delete obj.orderByType;
                            setParam(obj);
                        },
                        orderbyType: param.orderByType,
                    },

                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림차순', width: 50, type: 1 },
                            { name: '오름차순', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderType = type;
                            else delete obj.orderType;
                            setParam(obj);
                        },
                        orderbyType: param.orderType,
                    },
                    {
                        filterText: '종료 상태',
                        orderLists: [
                            { name: '예정', width: 50, type: 1 },
                            { name: '종료', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.completeType = type;
                            else delete obj.completeType;
                            setParam(obj);
                        },
                        orderbyType: param.completeType,
                    },
                    {
                        filterText: '취소 여부',
                        orderLists: [
                            { name: '정상', width: 50, type: 1 },
                            { name: '취소', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.cancelType = type;
                            else delete obj.cancelType;
                            setParam(obj);
                        },
                        orderbyType: param.cancelType,
                    },
                    {
                        filterText: '삭제 여부',
                        orderLists: [
                            { name: '미 삭제', width: 50, type: 1 },
                            { name: '삭제', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.deleteType = type;
                            else delete obj.deleteType;
                            setParam(obj);
                        },
                        orderbyType: param.deleteType,
                    },
                ]}
                filterLists={[]}
                research={ref}
            />

            <FlexMainWrapper>
                <MainTable
                    tableRef={tableRef}
                    ref={ref}
                    rendering={rendering}
                    scrollWidth={180}
                    buttonComponents={({ checkItems, checkDatas }) => (
                        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'end' }}>
                            {/*<FilterToggleButton onChange={(checked) => setOpen(checked)} checked={open} text={'예정일정만 보기'} />*/}
                            <TopSideButton
                                text01={'등록'}
                                text02={'삭제'}
                                text03={'일정취소'}
                                middleWidth={80}
                                onClick={onClickCreate}
                                onClickMiddle={() => onClickMiddle(checkDatas)}
                                onClickDelete={() => onClickDelete(checkItems)}
                            />
                        </FlexMainWrapper>
                    )}
                    tableStyle={{ width: readScheduleModalToggle || createToggle ? '73%' : '100%' }}
                    loadingText={'일정을 불러오고 있습니다.'}
                    headerData={ScheduleTableFilter}
                    toggleBlur={true}
                    checkID={'id'}
                    param={param}
                    setParam={setParam}
                    callApi={callApi}
                    pageCountCallApi={pageCountCallApi}
                    onClickRowItem={(item) => () => {
                        setChoiseScheduleID(item.id);
                        setData(item);
                        setRendering((prev) => !prev);
                        setCreateToggle(false);
                        setReadScheduleModalToggle(true);
                    }}
                    tbodyRenderItem={(key, value) => {
                        if (key === 'created_at') return dateFilter(value);
                        if (key === 'complete_yn') return availFilter(value ? 0 : 1);
                        if (key === 'cancel_yn') return availFilter(value ? 0 : 1, '정상', '취소');
                        if (key === 'deleted_at') return availFilter(value ? 0 : 1, 'ON', 'DELETE');
                        if (key === 'join_rate') return value ? `${parseFloat(value).toFixed(2)}%` : '0%';
                        if (key === 'max_participants') return `${value || 0}명`;
                        if (key === 'join_cnt') return `${value || 0}명`;
                        if (key === 'pay_amount') return value ? `${value.toLocaleString('ko-KR')}원` : '0원';
                        if (key === 'comment_cnt') return `${value || 0}개`;
                        if (key === 'title') return contentFilter(value, 15);
                        if (key === 'address') return contentFilter(value, 10);
                        if (key === 'gathering_name') return contentFilter(value, 10);
                        if (key === 'start_date') return <span style={{ color: open ? '#428bf7' : '', fontWeight: open ? 'bold' : '' }}>{value}</span>;

                        return value;
                    }}
                />

                {readScheduleModalToggle && (
                    <ReadScheduleModal id={choiseScheduleID} setToggle={setReadScheduleModalToggle} setImageToggle={setImageToggle} setSrc={setSrc} tableRef={tableRef} data={data} />
                )}

                {createToggle &&
                    (gatheringID ? (
                        <CreateScheduleModal
                            gatheringID={gatheringID}
                            setGatheringID={setGatheringID}
                            setToggle={setCreateToggle}
                            tableRef={tableRef}
                            setImageToggle={setImageToggle}
                            setSrc={setSrc}
                        />
                    ) : (
                        <ChoisGatheringModal setGatheringID={setGatheringID} setToggle={setCreateToggle} setImageToggle={setImageToggle} setSrc={setSrc} tableRef={tableRef} />
                    ))}

                {imageToggle && (
                    <ImageModalWrapper>
                        <CustomCloseOutlined onClick={() => setImageToggle(false)} />
                        <ModalImage src={src} alt="크게보기 이미지" ref={currentTarget} />
                    </ImageModalWrapper>
                )}
            </FlexMainWrapper>
        </div>
    );
};

export default GatheringScheduleLists;
