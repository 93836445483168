export const GET_COUPONS = "admin/GET_COUPONS";
export const GET_COUPONS_SUCCESS = "admin/GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAILURE = "admin/GET_COUPONS_FAILURE";
export const GET_COUPONS_HISTORY = "admin/GET_COUPONS_HISTORY";
export const GET_COUPONS_HISTORY_SUCCESS = "admin/GET_COUPONS_HISTORY_SUCCESS";
export const GET_COUPONS_HISTORY_FAILURE = "admin/GET_COUPONS_HISTORY_FAILURE";
export const POST_COUPONS_DETAIL = "admin/POST_COUPONS_DETAIL";
export const POST_COUPONS_DETAIL_SUCCESS = "admin/POST_COUPONS_DETAIL_SUCCESS";
export const POST_COUPONS_DETAIL_FAILURE = "admin/POST_COUPONS_DETAIL_FAILURE";
export const PUT_COUPONS_DETAIL = "admin/PUT_COUPONS_DETAIL";
export const DELETE_COUPONS = "admin/DELETE_COUPONS";
