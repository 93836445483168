export const GatheringReportFilter = [
    {
        key: 'report_id',
        value: 'ID',
    },
    {
        key: 'created_at',
        value: '신고 일자',
    },
    {
        key: 'user_name',
        value: '신고자 이름',
    },
    {
        key: 'user_nickname',
        value: '신고자 닉네임',
    },
    {
        key: 'reported_name',
        value: '피신고자 이름',
    },
    {
        key: 'reported_nick_name',
        value: '피신고자 닉네임',
    },
    {
        key: 'content_title',
        value: '게시글 제목',
    },
    {
        key: 'content',
        value: '게시글 내용',
    },
    {
        key: 'reason_detail',
        value: '신고 사유',
        // 신고 사유 0: 기타, 1: 부적절한 게시글, 2: 광고/영업
        filter: [
            {
                key: 0,
                value: '기타',
            },
            {
                key: 1,
                value: '부적절한 게시글',
            },
            {
                key: 2,
                value: '광고/영업',
            },
        ],
    },
];
