// 상품리스트, 상품상세조회, 상품추가, 상품삭제
import produce from 'immer';
import { handleActions } from 'redux-actions';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

/************************ action을 정의 ************************/

// 주문내역조회
export const GET_ORDER_LISTS_REQUEST = 'GET_ORDER_LISTS_REQUEST';
export const GET_ORDER_LISTS_SUCCESS = 'GET_ORDER_LISTS_SUCCESS';
export const GET_ORDER_LISTS_FAILURE = 'GET_ORDER_LISTS_FAILURE';
// 주문상세
export const GET_ORDER_DETAIL_REQUEST = 'GET_ORDER_DETAIL_REQUEST';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAILURE = 'GET_ORDER_DETAIL_FAILURE';
// 주문상세 수정
export const PATCH_ORDER_DETAIL_REQUEST = 'PATCH_ORDER_DETAIL_REQUEST';
export const PATCH_ORDER_DETAIL_SUCCESS = 'PATCH_ORDER_DETAIL_SUCCESS';
export const PATCH_ORDER_DETAIL_FAILURE = 'PATCH_ORDER_DETAIL_FAILURE';
// 상태 초기화
export const RESET_STATUS = 'RESET_STATUS';

/************************ 초기상태 ************************/

const initialState = {
    orderLists: null, // 주문내역을 담고 있는 데이터 객체
    orderDetail: null, // 주문상세
    updateOrderMessage: null, // 주문상세 수정
    // 주문내역조회
    loadOrderListsLoading: false,
    loadOrderListsDone: false,
    loadOrderListsError: null,
    // 주문상세
    loadOrderDetailLoading: false,
    loadOrderDetailDone: false,
    loadOrderDetailError: null,
    // 주문상세 수정
    loadUpdateOrderDetailLoading: false,
    loadUpdateOrderDetailDone: false,
    loadUpdateOrderDetailError: null,
};

/************************ action 함수 ************************/

// 주문내역조회
export const getOrderLists = (input) => async (dispatch) => {
    dispatch({
        type: GET_ORDER_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminProductOrder`, { params: input });
        dispatch({
            type: GET_ORDER_LISTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ORDER_LISTS_FAILURE,
            payload: error,
        });
    }
};

// 주문내역 상세조회
export const getOrderDetail = (order_id) => async (dispatch) => {
    dispatch({
        type: GET_ORDER_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminProductOrder/detail?order_id=${order_id}`);
        dispatch({
            type: GET_ORDER_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ORDER_DETAIL_FAILURE,
            payload: error,
        });
    }
};

// 주문내역 상세 수정
export const updateOrderDetail = (body) => async (dispatch) => {
    dispatch({
        type: PATCH_ORDER_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminProductOrder`, body);
        dispatch({
            type: PATCH_ORDER_DETAIL_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: PATCH_ORDER_DETAIL_FAILURE,
            payload: error,
        });
    }
};

/************************ reducer ************************/

const orderReducer = handleActions(
    {
        // 주문내역조회
        [GET_ORDER_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOrderListsLoading = false;
                draft.loadOrderListsDone = false;
                draft.loadOrderListsError = null;
            }),
        [GET_ORDER_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOrderListsLoading = action.payload ? false : true;
                draft.orderLists = action.payload;
                draft.loadOrderListsDone = action.payload ? true : false;
            }),
        [GET_ORDER_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOrderListsLoading = false;
                draft.loadOrderListsError = action.error;
            }),

        // 주문상세
        [GET_ORDER_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOrderDetailLoading = false;
                draft.loadOrderDetailDone = false;
                draft.loadOrderDetailError = null;
            }),
        [GET_ORDER_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOrderDetailLoading = action.payload ? false : true;
                draft.orderDetail = action.payload;
                draft.loadOrderDetailDone = action.payload ? true : false;
            }),
        [GET_ORDER_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadOrderDetailLoading = false;
                draft.loadOrderDetailError = action.error;
            }),

        // 주문상세 수정
        [PATCH_ORDER_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUpdateOrderDetailLoading = false;
                draft.loadUpdateOrderDetailDone = false;
                draft.loadUpdateOrderDetailError = null;
            }),
        [PATCH_ORDER_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUpdateOrderDetailLoading = action.payload ? false : true;
                draft.updateOrderMessage = action.payload;
                draft.loadUpdateOrderDetailDone = action.payload ? true : false;
            }),
        [PATCH_ORDER_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUpdateOrderDetailLoading = false;
                draft.loadUpdateOrderDetailError = action.error;
            }),

        [RESET_STATUS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadUpdateOrderDetailLoading = false;
                draft.loadUpdateOrderDetailDone = false;
                draft.loadUpdateOrderDetailError = null;
                draft.updateOrderMessage = null;
            }),
    },
    initialState,
);

export default orderReducer;
