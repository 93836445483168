import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GatheringUserLists from '../../components/GatheringComponents/GatheringUserLists';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import GatheringDetailComponent from '../../components/GatheringComponents/GatheringDetailComponent';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainButton } from '../../designComponents/buttons/Buttons';
import GatheringDetailV2, { getGatheringDetail } from '../../EventComponents/Gathering/GatheringDetailV2';
import GatheringDetailTab from '../../GroupComponent/Tab/GatheringDetailTab';

function GatheringDetail() {
    const { gathering_id } = useParams();
    const [subPageIndex, setSubPageIndex] = useState(0);
    const navigator = useNavigate();
    const [loading, setLoading] = useState(true);
    const [gatheringList, setGatheringList] = useState({});
    const [gatheringCrewList, setGatheringCrewList] = useState([]);
    const [gatheringPastOwners, setGatheringPastOwners] = useState([]);
    const [question, setQuestion] = useState([]);
    const [render, setRender] = useState(false);

    useEffect(() => {
        gatGatheringDetailInfo().then();
    }, [gathering_id, render]);

    const gatGatheringDetailInfo = async () => {
        try {
            setLoading(true);

            const response = await RefreshCheckApi.get(`/adminGathering/detail?gathering_id=${gathering_id}`);
            setGatheringList(response.data.findDetailData);
            setGatheringCrewList(response.data.findDetailCrew);
            setGatheringPastOwners(response.data.pastOwners);
            setQuestion(response.data.questions);
            setLoading(false);
        } catch (err) {
            console.error(err);
            alert('[Error]서버 관리자에게 문의 바랍니다.');
        }
    };

    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <GatheringDetailTab setIndex={setSubPageIndex} index={subPageIndex}></GatheringDetailTab>
                <div>
                    <MainButton text="목록 보기" styleReverse onClick={() => navigator(-1)} />
                </div>
            </div>
            {subPageIndex === 0 && (
                <GatheringDetailComponent
                    questions={question}
                    gatheringList={gatheringList[0]}
                    gatheringCrewList={gatheringCrewList}
                    gathering_id={gathering_id}
                    pastOwners={gatheringPastOwners}
                    loading={loading}
                    research={() => setRender((prev) => !prev)}
                />
            )}
            {subPageIndex === 1 && <GatheringUserLists gathering_id={gathering_id} />}
        </>
    );
}

export default GatheringDetail;
