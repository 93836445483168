import styled from 'styled-components';

export const TableTitle = styled.h3`
    font-size: 20px;
    width: 92%;
    margin: 30px auto 0 auto;
`;

export const ExcelWrapper = styled.div`
    display: flex;
    justify-content: end;

    font-size: 20px;

    width: ${(props) => (props.width ? `${props.width}%` : '92%')};
    margin: 0 auto 5px auto;
`;

// 일자
export const DateHeaderTh = styled.th`
    text-align: center;
    font-size: 13px;
    font-weight: bold;

    width: 10%;
    padding: 15px 0;

    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 0 !important;

    border-right: 1px solid rgb(205, 205, 205);
`;
// 신규가입/누적 회원수
export const NewUserAccumulateHeaderTh = styled.th`
    text-align: center;
    font-size: 13px;
    font-weight: bold;

    width: 35%;
    padding: 15px 0;

    border-top: 1px solid black;

    border-right: 1px solid rgb(205, 205, 205);
    border-bottom: 1px solid rgb(205, 205, 205);
`;
// 신규회원 활성도
export const NewActivityHeaderTh = styled.th`
    text-align: center;
    font-size: 13px;
    font-weight: bold;

    // width: 25%;
    padding: 15px 0;

    border-top: 1px solid black;
    border-right: 1px solid rgb(205, 205, 205);

    border-bottom: 1px solid rgb(205, 205, 205);
`;
// 편지발송수
export const LetterHeaderTh = styled.th`
    text-align: center;
    font-size: 13px;
    font-weight: bold;
	width: 10%;

    padding: 15px 0;

    border-top: 1px solid black;
    border-bottom: 1px solid black;

    border-left: 1px solid rgb(205, 205, 205);
`;
// 채팅수
export const ChatHeaderTh = styled.th`
    text-align: center;
    font-size: 13px;
    font-weight: bold;

    padding: 15px 0;

    width: 10%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    border-left: 1px solid rgb(205, 205, 205);
    border-right: 0 !important;
`;

export const Table = styled.table`
    border-collapse: collapse;

    width: 92%;
    margin: 0 auto;

    #thead > tr > th {
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        border-left: 1px solid rgb(205, 205, 205);
        border-right: 1px solid rgb(205, 205, 205);

        padding: 10px;

        font-size: 13px;
    }

    #tbody > tr {
        cursor: pointer;
        border-bottom: 1px solid rgb(0, 0, 0, 0.1);
    }

    #tbody > tr:hover {
        background-color: #e4e4e4;
    }

    #tbody > tr > td {
        padding: 13px 10px;

        font-size: 14px;
        text-align: center;
    }

    .count-title {
        font-size: 13.5px;
        font-weight: 500;
        font-weight: bold;
    }

    .count-title01 {
        font-size: 13.5px;
        font-weight: 500;
        font-weight: bold;
        /* color: #0000bd; */
    }

    .slash-title {
        font-size: 13px;
        font-weight: bold;
        margin: 0 5px;
    }

    .count {
        font-size: 13.5px;
        font-weight: 500;
    }

    .count01 {
        font-size: 13.5px;
        font-weight: bold;
        /* color: #0000bd; */
    }

    .slash {
        font-size: 13px;
        font-weight: bold;
        color: gray;

        margin: 0 2px;
    }
`;

// 활성도 점수 기준 설명
export const ExplainBox = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 45px;

    width: 92%;
    margin: auto;

    background-color: #fff;

    h3 {
        font-size: 13px;
        margin: 0;
    }

    h4 {
        font-size: 12px;
        margin: 0;
    }

    p {
        font-size: 11px;
        margin: 0;
        width: 80px;
    }

    span {
        font-size: 11px;
        font-weight: bold;
        margin-left: 10px;
    }

    .wrapper01 {
        display: flex;
        align-items: center;
        gap: 10px;

        margin-bottom: 10px;
    }

    .wrapper02 {
        display: flex;
        align-items: center;
        gap: 60px;
    }

    .point-wrapper {
        display: flex;
        align-items: center;

        padding: 3px 0;
    }

    .point-explanation {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 5px;

        font-weight: 600;
        margin-bottom: 5px;
    }

    .header-box {
        width: 130px;
    }
`;

export const Box = styled.div`
    text-align: start;
    font-size: 13px;

    padding: 10px 13px;
`;
