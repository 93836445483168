import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { CloseSquareFilled, SearchOutlined } from '@ant-design/icons';

const SearchBarV2 = ({ searchText, onChangeSearchText, onSearch, setSearchText }) => {
    return (
        <FlexMainWrapper style={{ position: 'relative', alignItems: 'center', width: '100%', gap: 0 }}>
            <SingleInput placeholder="검색어 입력" value={searchText} onChange={onChangeSearchText} />
            {searchText.length ? <CloseSquareFilled style={{ position: 'absolute', right: '32px', fontSize: '16px', color: 'gray', cursor: 'pointer' }} onClick={() => setSearchText('')} /> : null}
            <SearchIcon onClick={onSearch}>
                <SearchOutlined style={{ color: 'rgb(66,139,247)' }} />
            </SearchIcon>
        </FlexMainWrapper>
    );
};

SearchBarV2.propTypes = {
    searchText: PropTypes.string.isRequired,
    onChangeSearchText: PropTypes.func.isRequired,
};

export default React.memo(SearchBarV2);

const SingleInput = styled.input`
    outline: none;
    padding: 8.5px 19px 8.5px 10px;
    width: 250px;
    border: 0;
    background-color: rgb(237 238 241);
    @media (max-width: 912px) {
        width: 170px;
    }
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    ::placeholder,
    ::-webkit-input-placeholder {
        color: rgb(139 149 161);
    }

    :-ms-input-placeholder {
        color: rgb(139 149 161);
    }

    &:focus {
        outline: none;
    }
`;

const SearchIcon = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(237 238 241);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 32px;
    height: 32px;
    &:hover {
        background-color: rgb(218 218 218);
    }
`;
