import { useState, useEffect, useRef, useContext } from 'react';
import UserInfoModalContext from '../../../data_system/contexts/user_context/user_info_modal_context';

import UserModal from '../../UserComponents/UserModal';
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { Wrapper02 } from '../../../designComponents/wrappers/FlexMainWrapper';
import { DefaultModal } from '../../../designComponents/modals/ModalComponent';
import DesignTable from '../../../designComponents/tables/DesignTable';
import { Image } from '../../../designComponents/imageboxs/Image';
import { sexFilter } from '../../../utils/textFilter';
import { CustomCloseOutlined, ImageModalWrapper, ModalImage } from '../../styles/GatheringDetailComponentStyled';

const JoinUserListsModal = ({ contentsId, owner_id, searchJoinUserToggle, setSearchJoinUserToggle }) => {
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [joinUserLists, setJoinUserLists] = useState([]);
    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');
    const [params, setParams] = useState({});

    const currentTarget = useRef();

    useEffect(() => {
        const response = RefreshCheckApi.get(`/adminGathering/schedule/users?contents_id=${contentsId}`, { params: params });
        response.then((res) => {
            setJoinUserLists(res.data.data);
        });
    }, [contentsId, params]);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                if (user_info_modal_state.modalVisible) {
                    user_info_modal_actions.setModalVisible(false);
                } else {
                    setSearchJoinUserToggle(false);
                }
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_info_modal_state, searchJoinUserToggle]);

    // 클릭 감지
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    // 이미지 모달 외부 클릭 시 Modal창 닫힘
    const handleClickOutside = (event) => {
        if (!currentTarget.current) {
            return;
        } else {
            if (currentTarget && !currentTarget.current.contains(event.target)) {
                setImageToggle((prev) => !prev);
            }
        }
    };

    const onClickUserDetail = (item) => {
        user_info_modal_actions.setId(item.id);
        user_info_modal_actions.setModalVisible(true);
    };

    return (
        <DefaultModal
            title="참여회원 목록"
            boxStyle={{ width: '90vh', height: '65vh' }}
            onClickClose={() => setSearchJoinUserToggle(false)}
            onClickBottomClose={() => setSearchJoinUserToggle(false)}
            bodyWidth={55}
            bottomBtnOn
            closeOn
            cancelText={'닫기'}
        >
            <Wrapper02>
                {user_info_modal_state.modalVisible ? <UserModal setImageToggle={setImageToggle} setSrc={setSrc} /> : null}
                {imageToggle && (
                    <ImageModalWrapper>
                        <CustomCloseOutlined onClick={() => {}} />
                        <ModalImage src={src} alt="크게보기 이미지" ref={currentTarget} />
                    </ImageModalWrapper>
                )}
                <DesignTable
                    marginTop={0.5}
                    onToggle={null}
                    modalComponent={false}
                    header={[
                        { title: 'ID', width: 80 },
                        { title: '회원명', width: 80 },
                        { title: '닉네임', width: 110 },
                        { title: '성별', width: 70 },
                        { title: '생년월일', width: 100 },
                        { title: '휴대폰번호', width: 100 },
                        { title: '결제 상태', width: 100 },
                        { title: '결제 방법', width: 100 },
                        { title: '프로필 이미지', width: 100 },
                    ]}
                    body={[
                        { key: 'id', width: 80 },
                        { key: 'name', width: 80 },
                        { key: 'nick_name', width: 110 },
                        { key: 'sex', width: 70 },
                        { key: 'birthday', width: 100 },
                        { key: 'phone_no', width: 100 },
                        { key: 'pay_status', width: 100 },
                        { key: 'pay_type', width: 100 },
                        { key: 'url', width: 100 },
                    ]}
                    data={joinUserLists}
                    allCount={0}
                    wrapper={{ width: '80vh', margin: 'auto' }}
                    tableWrapper={{ justifyContent: 'space-between' }}
                    rowTextStyle={{ borderLeft: '0px' }}
                    bodyRederItem={(key, value, item) => {
                        const ownerColor = (owner_id, user_id) => (owner_id === user_id ? '(주최자)' : null);
                        if (key === 'id') return <span style={{ padding: '20px 0' }}>{value}</span>;
                        if (key === 'name')
                            return (
                                <span style={{ padding: '20px 0', color: item.owner_id === item.id ? 'red' : '', fontWeight: item.owner_id === item.id ? 'bold' : '' }}>
                                    {value}
                                    {ownerColor(item.owner_id, item.id)}
                                </span>
                            );
                        if (key === 'nick_name') return value;
                        if (key === 'sex') return sexFilter(value);
                        if (key === 'birthday') return value;
                        if (key === 'phone_no') return value;
                        if (key === 'url') return <Image url={value} width={80} height={80} onClick={() => onClickUserDetail(item)} />;
                        return <span>{value}</span>;
                    }}
                    noneText="참여회원이 존재하지 않습니다."
                    noneHieght={25}
                />
            </Wrapper02>
        </DefaultModal>
    );
};

export default JoinUserListsModal;
