import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, LabelList } from 'recharts';

// 파워유저 곡선 차트
function PowerUserLineChart({ data, type }) {
    // useState
    const [barWidth, setBarWidth] = useState(0);

    // useRef
    const barChartAreaRef = useRef();

    /********************************************** useEffect **********************************************/

    // 너비 적용
    useLayoutEffect(() => {
        setBarWidth(barChartAreaRef.current.offsetWidth);
    }, []);

    /********************************************** function **********************************************/

    // 라벨 커스텀
    const renderCustomizedLabel = (props) => {
        const { x, y, width, value } = props;
        const radius = 0;
        let percentageNumber = 30;

        // 표기문구 핉터
        const textFilter = (text) => {
            let findIndex = text.indexOf('(');
            let findIndex02 = text.indexOf(')');

            let newText = type === 0 ? text.slice(findIndex) : text.slice(findIndex + 1, findIndex02);

            return newText;
        };

        return (
            <g>
                <circle cx={x + width / 2} cy={y - radius} r={radius} fill={'rgb(66,139,247)'} />
                <text
                    x={x + width / 2}
                    y={parseInt(value) > percentageNumber ? y + radius + 17 : y + radius - 15}
                    fill={parseInt(value) > percentageNumber ? '#fff' : 'black'}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: '11px' }}
                >
                    {parseInt(value)}%
                </text>
                <text
                    x={x + width / 2}
                    y={parseInt(value) > percentageNumber ? y + radius + 28 : y + radius - 5}
                    fill={parseInt(value) > percentageNumber ? '#fff' : 'black'}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: '11px' }}
                >
                    {textFilter(value)}
                </text>
            </g>
        );
    };

    // data custom
    const dataCustom = (data) => {
        return `${parseInt(data.percentage)}`;
    };

    /********************************************** html **********************************************/

    const RectangleComponent = <Rectangle fill="gold" stroke="purple" />;
    const barStyled = { cursor: 'pointer' };

    return (
        <div ref={barChartAreaRef} className="line-chart-wrapper">
            <BarChart width={barWidth - 150} height={430} data={data} margin={{ top: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dayDiff" style={{ fontSize: '12px' }} />
                <YAxis dataKey="all_percent" style={{ fontSize: '12px' }} />
                {/* <Tooltip /> <Legend /> */}
                <Bar dataKey={dataCustom} fill="rgb(66,139,247)" activeBar={RectangleComponent} style={barStyled} minPointSize={5}>
                    <LabelList dataKey="percentage" content={renderCustomizedLabel} />
                </Bar>
            </BarChart>
        </div>
    );
}

export default PowerUserLineChart;
