import {
    deleteReviewLists,
    getActivities,
    getActivitiesSearch,
    getReviewLists,
    postActivities,
    deleteActivities
} from "../../../../../communication_system/axios_apis/axios_apis";
import CreateDeleteRequestThunk from "../../redux_functions/create_delete_request_thunk";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import {DELETE_REVIEW_LISTS, GET_ACTIVITIES, GET_REVIEW_LISTS, POST_ACTIVITIES, DELETE_ACTIVITIES_LISTS} from "./activities_table_type";

export const getActivitiesAsync = createGetRequestThunk(
  GET_ACTIVITIES,
  getActivities
);

export const postActivitiesAsync = createPostRequestThunk(
  POST_ACTIVITIES,
  postActivities
);

export const getActivitiesSearchAsync = createGetRequestThunk(
  GET_ACTIVITIES,
  getActivitiesSearch
);

export const deleteActivitiesListAsync = CreateDeleteRequestThunk(
  DELETE_ACTIVITIES_LISTS,
  deleteActivities
);


export const deleteActivitiesAsync = CreateDeleteRequestThunk(
  DELETE_REVIEW_LISTS,
  deleteReviewLists
);

export const getReviewListsAsync = createGetRequestThunk(
  GET_REVIEW_LISTS,
  getReviewLists
);

export const deleteReviewListsAsync = CreateDeleteRequestThunk(
  DELETE_REVIEW_LISTS,
  deleteReviewLists
);
