import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

// data_system
import { getRetentionTableData } from '../../data_system/redux/modules/marketing_reduter/marketing_reducer';

// design_system
import { TextInputNoLine } from '../../design_system/components/input/Inputs';
import ContentText from '../../design_system/components/content_text/ContentText';
import { TypeInput, TypeInputContainer } from '../../pages/statistics/StatisticsPage';

// redux
import { useSelector, useDispatch } from 'react-redux';

// components
import RetentionTable from '../../components/MarketingComponents/RetentionsTable';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import CheckboxInput from '../../designComponents/checkboxs/CheckboxInput';
import LoadingPage from '../../pages/loading/LoadingPage';

/** 리텐션 컴포넌트 */
const RetentionComponent = () => {
    const { retentionTable, retentionTableLoading } = useSelector((state) => state.marketingReducer);
    const [retentionDateType, setRetentionDateType] = useState(0);
    const [retentionStartDate, setRetentionStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [retentionEndDate, setRetentionEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [type, setType] = useState(1);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        onChangeRetentionDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, retentionEndDate, retentionStartDate, retentionDateType, type]);

    const onChangeRetentionDate = () => {
        dispatch(
            getRetentionTableData({
                appType: type,
                type: Number(retentionDateType),
                startDate: moment(retentionStartDate).format('YYYY-MM-DD'),
                endDate: moment(retentionEndDate).format('YYYY-MM-DD'),
            }),
        );
    };

    return (
        <div>
            <MainPageTitle text={'리텐션'} fontStyle={{ fontSize: '19px', fontWeight: 'bold' }} />
            <FlexMainWrapper className="retention-wrapper" style={{ alignItems: 'center' }}>
                <TypeInputContainer>
                    <TypeInput type={'button'} value={'일별'} style={{ fontWeight: retentionDateType === 0 ? 'bold' : 'normal' }} onClick={() => setRetentionDateType(0)} />
                </TypeInputContainer>

                <FlexMainWrapper className="date-convert" style={{ alignItems: 'center' }}>
                    <TextInputNoLine className="text-input-number" type={'date'} value={retentionStartDate} onChange={(e) => setRetentionStartDate(e.target.value)} />
                    <ContentText style={{ marginInline: 12 }}>~</ContentText>
                    <TextInputNoLine className="text-input-number" type={'date'} value={retentionEndDate} onChange={(e) => setRetentionEndDate(e.target.value)} />
                </FlexMainWrapper>
            </FlexMainWrapper>
            <FlexMainWrapper style={{ marginBlock: 12 }}>
                <CheckboxInput
                    text={'시놀'}
                    exposure
                    style={{ width: '17px', height: '17px', margin: '0 5px 0 0' }}
                    fontStyle={{ fontSize: '14px' }}
                    onChange={() => setType(1)}
                    checked={type === 1}
                />
                <CheckboxInput
                    text={'시럽'}
                    exposure
                    style={{ width: '17px', height: '17px', margin: '0 5px 0 0' }}
                    fontStyle={{ fontSize: '14px' }}
                    onChange={() => setType(2)}
                    checked={type === 2}
                />
            </FlexMainWrapper>
            <div className="table-line">
                {retentionTableLoading ? <LoadingPage text={'리텐션 데이터 조회중입니다'} /> : <RetentionTable header={retentionTable?.header} data={retentionTable} type={retentionDateType} />}
            </div>
        </div>
    );
};

export default RetentionComponent;
