import React, { useState } from 'react';
import styled from 'styled-components';
import { rgb_0_0_0, rgb_255_255_255, rgb_35_40_45, rgb_66_139_247, rgba_66_139_247_0d2 } from '../../colors';
import { SearchWhite1x } from '../../icons/icons';
import {
    h_px12rate,
    h_px28rate,
    h_px38rate,
    h_px6rate,
    w_px11rate,
    w_px125rate,
    w_px12rate,
    w_px131rate,
    w_px133rate,
    w_px15rate,
    w_px8rate,
} from '../../size';
import Dropdown from '../../../components/Common/Dropdown';

const UserSelectOrSearch = ({
    list,
    resetThenSet,
    index,
    value,
    onChange,
    couponTargetDropdownItem,
    onSearch,
    userList,
    targetUserOb,
    setTargetUserOb,
}) => {
    const [listOpen, setListOpen] = useState(false);

    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            <Dropdown list={list} resetThenSet={resetThenSet} index={index}></Dropdown>
            <div
                style={{
                    position: 'relative',
                    marginTop: h_px12rate,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Input
                        width={w_px125rate}
                        height={h_px28rate}
                        font_size={w_px11rate}
                        placeholder={'이름 또는 핸드폰 번호'}
                        disabled={couponTargetDropdownItem.value !== 4}
                        value={value}
                        onChange={onChange}
                    ></Input>
                    <SearchBtn
                        onClick={() => {
                            onSearch();
                            setListOpen(true);
                        }}
                        disabled={couponTargetDropdownItem.value !== 4}
                    >
                        <SearchWhite1x></SearchWhite1x>
                    </SearchBtn>
                </div>
                <div
                    style={{
                        position: 'absolute',
                    }}
                >
                    {userList !== null && listOpen && (
                        <ListFrame role="list">
                            {userList?.map((userOb, index) => {
                                const user_id = userOb['id'];
                                const user_data = userOb['data'];
                                return (
                                    <MenuItemBtn
                                        type="button"
                                        key={user_id}
                                        onClick={() => {
                                            setTargetUserOb(userOb);
                                            setListOpen(false);
                                        }}
                                    >
                                        {user_data[1]['data']}
                                    </MenuItemBtn>
                                );
                            })}
                        </ListFrame>
                    )}
                </div>
                {targetUserOb !== null && (
                    <Input
                        width={w_px125rate}
                        height={h_px28rate}
                        font_size={w_px11rate}
                        placeholder={''}
                        disabled={true}
                        value={`선택된 유저: ${targetUserOb['data'][1]['data']}`}
                        onChange={() => {}}
                    ></Input>
                )}
            </div>
        </div>
    );
};

export default React.memo(UserSelectOrSearch);

const Input = styled.input`
    padding-top: ${h_px6rate}px;
    padding-bottom: ${h_px6rate}px;
    padding-left: ${w_px8rate}px;
    padding-right: ${w_px8rate}px;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    border: 1px solid ${rgba_66_139_247_0d2};
    font-size: ${(props) => props.font_size}px;
    color: ${rgb_0_0_0};
    font-family: noto_sans_kr_medium;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;

const SearchBtn = styled.button`
    margin: 0px;
    padding: 0px;
    border-width: 0px;
    background-color: ${rgb_66_139_247};
    width: ${h_px28rate}px;
    height: ${h_px28rate}px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ListFrame = styled.div`
    position: absolute;
    width: ${w_px133rate}px;
    box-sizing: border-box;
    border: 1px solid ${rgba_66_139_247_0d2};
    border-top-width: 0px;
    background-color: ${rgb_255_255_255};
    z-index: 999;
`;

const MenuItemBtn = styled.button`
    width: ${w_px131rate}px;
    height: ${h_px38rate}px;
    padding-left: ${w_px15rate}px;
    background-color: ${rgb_255_255_255};
    border-width: 0px;
    font-family: noto_sans_kr_medium;
    font-size: ${w_px12rate}px;
    color: ${rgb_35_40_45};
    text-align: left;
    word-break: break-all;
`;
