import React, {useRef} from "react";
import styled from "styled-components";
import {main_server_image_ip} from "../../../communication_system/communication_ips";
import {rgb_255_255_255, rgb_46_46_46, rgb_66_139_247} from "../../colors";
import {WhitePlus1x, XMarkWhite1x} from "../../icons/icons";
import {h_px4rate, w_px12rate, w_px18rate, w_px60rate, w_px8rate,} from "../../size";

const OneImageSelector = ({
  profileImg,
  setProfileImg,
  serverGet,
  setServerGet,
}) => {
  const imageInput = useRef();

  const onCickImageUpload = () => {
    imageInput.current.click();
  };

  const onRemove = () => {
    setServerGet(false);
    setProfileImg(null);
  };

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
      }}
    >
      <div
        style={{
          marginRight: w_px12rate,
        }}
      >
        <ImgSelectBtn onClick={onCickImageUpload}>
          <div>
            <div
              style={{
                marginBottom: h_px4rate,
              }}
            >
              <WhitePlus1x></WhitePlus1x>
            </div>
            <ImgSelectText>사진 불러오기</ImgSelectText>
          </div>
        </ImgSelectBtn>
            <input
            ref={imageInput}
            style={{
              display: "none",
            }}
            type="file"
            accept="image/jpg, impge/png, image/jpeg"
            name="profile_img"
            multiple={false}
            onChange={(e) => {
              setServerGet(false);
              setProfileImg(e.target.files[0]);
            }}
        />
      </div>
      {profileImg?(
        <UploadImgFrame>
          <UploadImg
            src={
              !profileImg? null : serverGet ? main_server_image_ip + profileImg : URL.createObjectURL(profileImg)
            }
            alt={""}
          ></UploadImg>
          <RemoveBtn onClick={onRemove}>
            <XMarkWhite1x></XMarkWhite1x>
          </RemoveBtn>
        </UploadImgFrame>
      ):null}
    </div>
  );
};

export default React.memo(OneImageSelector);

const ImgSelectBtn = styled.button`
  width: ${w_px60rate}px;
  height: ${w_px60rate}px;
  border-width: 0px;
  margin: 0px;
  background-color: ${rgb_66_139_247};
`;

const ImgSelectText = styled.div`
  font-size: ${w_px8rate}px;
  font-family: noto_sans_kr_medium;
  color: ${rgb_255_255_255};
`;

const UploadImgFrame = styled.div`
  postion: relative;
`;

const UploadImg = styled.img`
  width: ${w_px60rate}px;
  height: ${w_px60rate}px;
`;

const RemoveBtn = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: ${rgb_46_46_46};
  width: ${w_px18rate}px;
  height: ${w_px18rate}px;
  z-index: 999;
  margin: 0px;
  border-width: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
