import React from 'react';

import SearchBarV2 from './SearchBarV2';
import MainDropdownV2 from '../dropdowns/DropdownV2';
import { SearchbarWrapper } from '../wrappers/FlexMainWrapper';

const SearchbarWithDropdownV2 = ({ list, index = 0, resetThenSet = () => {}, onChangeSubmit = () => {}, searchText, onChangeInput, setSearchText, style }) => {
    return (
        <SearchbarWrapper type="submit" onSubmit={onChangeSubmit} style={{ width: '100%', ...style }}>
            <MainDropdownV2 list={list} index={index} resetThenSet={resetThenSet} />
            <form>
                <SearchBarV2 type="submit" searchText={searchText} onChangeSearchText={onChangeInput} onSearch={onChangeSubmit} setSearchText={setSearchText} />
            </form>
        </SearchbarWrapper>
    );
};

export default React.memo(SearchbarWithDropdownV2);
