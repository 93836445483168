import { cdn_image_ip, main_server_image_ip } from '../../communication_system/communication_ips';
import styled from 'styled-components';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { SText } from '../text/ContentText';

export const SIZE = {
    S80: '80x80',
    S150: '150x150',
    W200H125: '200x125',
    W360: '360w',
    W360H270: '360x270',
    W480: '480w',
    W640: '640w',
    W1280: '1280w',
};

export const makeImageUrl = (src, size, originYN) => {
    switch (true) {
        case src?.length === 0:
            return null;
        case /^http/.test(src) || /^file/.test(src):
            return src;
        case size:
            return cdn_image_ip + `?key=${src}&size=${size}`;
        default:
            return originYN ? src : main_server_image_ip + src;
    }
};

const originUrl = (src, originYN) => {
    switch (true) {
        case src?.length === 0:
            return null;
        case src?.includes('k.kakaocdn.net'):
            return src;
        default:
            return originYN ? src : main_server_image_ip + src;
    }
};

export const Image = ({ imgRef, url, errImg, width = 100, height = 100, cover = 'cover', alt = '이미지', style, size = 'S150', onClick = () => {}, originYN = false, resizing = true }) => {
    const handleImgError = (e) => {
        e.target.src = errImg ? makeImageUrl(errImg, SIZE[size]) : '/images/noneImage.png';
    };

    return (
        <ImageTag
            ref={imgRef}
            style={{ ...style }}
            width={width}
            height={height}
            cover={cover}
            src={resizing ? makeImageUrl(url, SIZE[size], originYN) : originUrl(url, originYN)}
            alt={alt}
            onError={handleImgError}
            onClick={onClick}
        />
    );
};

export const ImageFlexWrap = ({
    title,
    titleFontStyle,
    fontColor = '#797979',
    objYN = false,
    keyValue = '',
    image_lists = [],
    onClick = () => {},
    onClickDefault = () => {},
    imageWidth = 80,
    imageHeight = 80,
    wrapperStyle,
    imageStyle,
}) => {
    return (
        <>
            {image_lists.length ? <SText text={title} color={fontColor} titleFontStyle={titleFontStyle} /> : null}
            <FlexMainWrapper style={{ width: '100%', justifyContent: 'start', flexWrap: 'wrap', ...wrapperStyle }}>
                {image_lists.map((src, index) => {
                    return (
                        <Image
                            style={{ ...imageStyle }}
                            key={index}
                            url={objYN ? src[keyValue] : src}
                            width={imageWidth}
                            height={imageHeight}
                            onClick={onClickDefault ? onClickDefault : () => onClick(objYN ? src[keyValue] : src)}
                        />
                    );
                })}
            </FlexMainWrapper>
        </>
    );
};

const ImageTag = styled.img`
    cursor: pointer;
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
    object-fit: ${(props) => `${props.cover}`};
`;
