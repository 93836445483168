import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';
import { QuestionBox } from './QuestionComponent';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyParticipantLists, getSurveyParticipantAnswerDetail } from '../../data_system/redux/modules/survey.reducer/survey.reducer';

// components
import LoadingPage from '../../pages/loading/LoadingPage';

// 설문관리 상세조회 > 응답 > 개별보기
function ResponseShowDetail() {
    const dispatch = useDispatch();

    // useParams
    const { survey_id } = useParams();

    // useSelector
    const { participantLists, userAnswerDetail, getUserAnswerDetailListsLoading } = useSelector((state) => state.getSurveyReducer);

    // state
    const [participantID, setParticipantID] = useState(0);
    const [reDispatch, setReDispatch] = useState(true);

    /******************************** useEffect ********************************/

    useEffect(() => {
        if (reDispatch) {
            setParticipantID(participantLists[0]?.id);
        }
    }, [survey_id, participantLists, userAnswerDetail, reDispatch]);

    // 응답자 리스트 조회
    useEffect(() => {
        dispatch(getSurveyParticipantLists(survey_id));
    }, [dispatch, survey_id]);

    // 선택된 user_id를 통한 응답 상세결과 조회 [participant_id]
    useEffect(() => {
        dispatch(getSurveyParticipantAnswerDetail(survey_id, participantID));
    }, [dispatch, survey_id, participantID]);

    /******************************** function ********************************/

    // 답변 체크 리스트
    const checkLists = (answer) => {
        const answerText = (index) => {
            let _index = index + 1;

            if (_index === 1) {
                return '매우 그렇다';
            } else if (_index === 2) {
                return '그렇다';
            } else if (_index === 3) {
                return '보통이다';
            } else if (_index === 4) {
                return '그렇지 않다';
            } else if (_index === 5) {
                return '매우 그렇지 않다';
            }
        };

        return (
            <div style={{ marginTop: '20px' }}>
                {Array(userAnswerDetail?.length)
                    .fill()
                    .map((_, index) => {
                        let _index = parseInt(index);

                        return (
                            <AnswerWrapper>
                                <CheckBox key={index} type="checkbox" checked={_index + 1 === answer} readOnly />
                                <div>{answerText(index)}</div>
                            </AnswerWrapper>
                        );
                    })}
            </div>
        );
    };

    // 회원 선택
    const onChangeParticipant = (e) => {
        let id = e.target.value;

        if (id) {
            setReDispatch(false);
            setParticipantID(id);
        }
    };

    /********************************** html **********************************/
    return (
        <div>
            <QuestionBox>
                <Select onChange={onChangeParticipant}>
                    {participantLists?.map((data, index) => {
                        return (
                            <option key={index} value={data.participant_id}>
                                {data.name || '탈퇴'}
                            </option>
                        );
                    })}
                </Select>
            </QuestionBox>

            {getUserAnswerDetailListsLoading ? (
                <LoadingPage text={'상세 답변에 대한 정보를 불러오는 중입니다.'} />
            ) : (
                <div className="answer-lists">
                    {userAnswerDetail?.map((data, index) => {
                        return (
                            <QuestionBox key={index}>
                                <div>
                                    {data.sequence + 1}. {data.question}
                                </div>
                                <div>{checkLists(data.answer)}</div>
                            </QuestionBox>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default ResponseShowDetail;

/********************************** styled **********************************/

export const CheckBox = styled.input`
    cursor: pointer;
    margin: 0;

    width: 17px;
    height: 17px;
`;

export const AnswerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    font-size: 14px;
    margin-bottom: 10px;
`;

export const Select = styled.select`
    cursor: pointer;
    outline: none;

    width: 20%;
    padding: 3px;

    border: 1px solid #cccccc;
`;
