import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { SettingDateModal, SearchWrapper, ChooseCreaterModalBox, Select, TableWrapper } from '../../styles/ReadScheduleModalStyled';

// API
import { main_server_image_ip } from '../../../communication_system/communication_ips';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getUsersInGathering } from '../../../data_system/redux/modules/gathering.reducer/gathering.schedule.reducer';

function SearchingCreaterModal({ setSearchCreaterUserToggle, setCreaterUserID, gatheringId, setCreater }) {
    const dispatch = useDispatch();

    // state
    const [userID, setUserID] = useState(0);

    // 회원검색 모달 state
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [params, setParams] = useState({
        gathering_id: gatheringId,
    });

    // useSelector
    const { usersInGathering } = useSelector((state) => state.scheduleReducer);

    /************************* useEffect *************************/

    useEffect(() => {
        dispatch(getUsersInGathering(params));
    }, [dispatch, params]);

    /************************* function *************************/

    // 검색어 입력
    const onChangeText = (e) => {
        setSearchText(e.target.value);
    };

    // 회원 검색
    const onSubmitSearch = (e) => {
        e.preventDefault();

        let param = {
            gathering_id: gatheringId,
            searchType: searchType,
            searchText: searchText,
        };

        setParams(param);
        dispatch(getUsersInGathering(params));
    };

    // 프로필 이미지 url 필터 함수
    const imagesFilterUrl = (src) => {
        switch (true) {
            case src?.includes('k.kakaocdn.net'):
                return src;
            case src.length === 0:
                return null;
            default:
                return main_server_image_ip + src;
        }
    };

    // 권한 data filter
    const filterRole = (num) => {
        if (num === 3) {
            return '모임장';
        }
        if (num === 2) {
            return '운영진';
        }
    };

    // 회원 선택
    const choiseUser = (id, name) => () => {
        setUserID(id);
        setCreaterUserID(id);
        setCreater(name);
    };

    /************************* html *************************/
    return (
        <SettingDateModal>
            <ChooseCreaterModalBox>
                <div className="top-wrapper">
                    <h2 className="title" style={{ margin: '0' }}>
                        모임원검색
                    </h2>
                    <div className="close-window">
                        <CloseOutlined className="close-icon" onClick={() => setSearchCreaterUserToggle(false)} />
                    </div>
                </div>

                <SearchWrapper id="search-input-wrapper">
                    <Select>
                        <option value={() => setSearchType(1)}>회원명</option>
                        <option value={() => setSearchType(2)}>회원ID</option>
                    </Select>

                    <form id="form" type="submit" onSubmit={onSubmitSearch}>
                        <input className="text" type="text" onChange={onChangeText} placeholder="회원명 또는 회원ID를 통해 검색해주세요." />
                        <button type="submit" className="search-btn">
                            검색
                        </button>
                    </form>
                </SearchWrapper>

                <TableWrapper id="user-lists">
                    {usersInGathering ? (
                        <table>
                            <tbody>
                                <tr>
                                    <th>프로필 사진</th>
                                    <th>참여모임ID</th>
                                    <th>회원ID</th>
                                    <th>이름</th>
                                    <th>닉네임</th>
                                    <th>권한</th>
                                </tr>
                            </tbody>

                            <tfoot>
                                {usersInGathering.map((data, index) => {
                                    return (
                                        <>
                                            <tr
                                                key={index}
                                                style={{
                                                    position: 'relative',
                                                    backgroundColor: userID === data.id ? '#428bf7' : null,
                                                    color: userID === data.id ? '#fff' : null,
                                                }}
                                                onClick={choiseUser(data.id, data.user_name)}
                                            >
                                                <td className="image">
                                                    <Img src={imagesFilterUrl(data.profile_image)} alt="회원 이미지" />
                                                </td>
                                                <td>{data.gathering_id}</td>
                                                <td>{data.id}</td>
                                                <td>{data.user_name || 'NULL'}</td>
                                                <td>{data.user_nickname || 'NULL'}</td>
                                                <td>{filterRole(data.role)}</td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tfoot>
                        </table>
                    ) : (
                        <div>데이터 없음</div>
                    )}
                </TableWrapper>

                <div className="add-btn" onClick={() => setSearchCreaterUserToggle(false)}>
                    추가
                </div>
            </ChooseCreaterModalBox>
        </SettingDateModal>
    );
}

export default SearchingCreaterModal;

const Img = styled.img`
    width: 70px;
    height: 70px;

    margin-top: 5px;

    object-fit: cover;
`;

const BigImageWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    width: 100%;
    height: 100vh;

    background-color: #00000003;
`;

const BigImage = styled.img`
    cursor: pointer;
    width: 70%;
`;
