import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import DesignTable from '../../designComponents/tables/DesignTable';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { contentFilter, dateFilter, finishedFilter } from '../../utils/textFilter';
import { TextArea } from '../../designComponents/inputs/TextArea';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { MainSelect, SelectLine } from '../../designComponents/dropdowns/Selectdown';
import moment from 'moment';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { MText } from '../../designComponents/text/ContentText';

/** 통화기록 컴포넌트 */
const CallHistory = forwardRef(({ userId, showAll = false, style, showPhoneNo = false, showNickName = false }, _ref) => {
    const [data, setData] = useState([]); // 통화내역 데이터
    const [page, setPage] = useState(1); // 페이지 넘버
    const [allCount, setAllCount] = useState(0); // 페이지 총 카운트
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [header, setHeader] = useState([]);
    const [body, setBody] = useState([]);
    useEffect(() => {
        //통화 기록
        if (userId && !showAll) {
            let input = { page: page, size: 10 };

            input.searchType = 4;
            input.searchWord = userId;

            const res = RefreshCheckApi.get('/adminCS', { params: input });
            res.then((res) => {
                setData(res.data.data.list);
                setAllCount(res.data.data.all_cnt);
                setLoading(false);
            });
        } else if (showAll) {
            let input = { page: page, size: 10 };
            const res = RefreshCheckApi.get('/adminCS', { params: input });
            res.then((res) => {
                setData(res.data.data.list);
                setAllCount(res.data.data.all_cnt);
                setLoading(false);
            });
        }
    }, [page, userId, showAll, refresh]);
    useImperativeHandle(_ref, () => ({
        refresh: () => setRefresh((pre) => !pre),
    }));
    useEffect(() => {
        const tmpHeader = [
            { title: '통화시각', width: 80 },
            { title: '유입경로', width: 60 },
        ];
        const tmpBody = [
            { key: 'call_date', width: 80 },
            { key: 'path', width: 60 },
        ];
        if (showPhoneNo) {
            tmpHeader.push({ title: '전화번호', width: 80 });
            tmpBody.push({ key: 'phone_no', width: 80 });
        }

        setHeader([...tmpHeader, { title: '통화사유', width: 130 }, { title: '내용', width: 630 }, { title: '처리여부', width: 55 }, { title: '담당자', width: 45 }]);
        setBody([...tmpBody, { key: 'big_category', width: 130 }, { key: 'content', width: 630 }, { key: 'status', width: 55 }, { key: 'admin_manager', width: 45 }]);
    }, [showNickName, showPhoneNo]);
    return (
        <Wrapper03 style={{ marginBottom: 0, flex: 1, ...style }}>
            <MainPageTitle wrapperStyle={{ margin: 0 }} fontStyle={{ fontSize: '17px', fontWeight: 600, color: '#797979' }} text={'통화 기록'} />
            {!userId && !showAll ? (
                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', marginBlock: 64 }}>
                    <MText text={'유저를 선택해 주세요'} />
                </FlexMainWrapper>
            ) : (
                <>
                    <DesignTable
                        loading={loading}
                        marginTop={0.5}
                        header={header}
                        body={body}
                        data={data}
                        allCount={allCount}
                        page={page}
                        setPage={setPage}
                        bodyRederItem={(key, value) => {
                            if (key === 'call_date') return dateFilter(value);
                            if (key === 'content') return contentFilter(value, 67);
                            if (key === 'status') return finishedFilter(value);
                            return value;
                        }}
                        loadingText={'요청하신 통화내역 데이터를 불러오고 있습니다.'}
                        noneText="요청하신 통화내역 데이터가 존재하지 않습니다."
                        noneHieght={25}
                    />
                </>
            )}
        </Wrapper03>
    );
});

export default CallHistory;
