import produce from 'immer';
import { handleActions } from 'redux-actions';

import { getCustomerPieData, getCustomerStatus } from '../../../../communication_system/axios_apis/axios_apis';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 파이차트 액션 정의: 결혼, 관심사, 지역, 연령대, 탈퇴사유
export const GET_CUSTOMER_MARRIED_PIE_REQUEST = 'GET_CUSTOMER_MARRIED_PIE_REQUEST';
export const GET_CUSTOMER_MARRIED_PIE_SUCCESS = 'GET_CUSTOMER_MARRIED_PIE_SUCCESS';
export const GET_CUSTOMER_MARRIED_PIE_FAILURE = 'GET_CUSTOMER_MARRIED_PIE_FAILURE';

export const GET_CUSTOMER_INTERESTS_PIE_REQUEST = 'GET_CUSTOMER_INTERESTS_PIE_REQUEST';
export const GET_CUSTOMER_INTERESTS_PIE_SUCCESS = 'GET_CUSTOMER_INTERESTS_PIE_SUCCESS';
export const GET_CUSTOMER_INTERESTS_PIE_FAILURE = 'GET_CUSTOMER_INTERESTS_PIE_FAILURE';

export const GET_CUSTOMER_LOCATION_PIE_REQUEST = 'GET_CUSTOMER_LOCATION_PIE_REQUEST';
export const GET_CUSTOMER_LOCATION_PIE_SUCCESS = 'GET_CUSTOMER_LOCATION_PIE_SUCCESS';
export const GET_CUSTOMER_LOCATION_PIE_FAILURE = 'GET_CUSTOMER_LOCATION_PIE_FAILURE';

export const GET_AGE_GROUP_PIE_REQUEST = 'GET_AGE_GROUP_PIE_REQUEST';
export const GET_AGE_GROUP_PIE_SUCCESS = 'GET_AGE_GROUP_PIE_SUCCESS';
export const GET_AGE_GROUP_PIE_FAILURE = 'GET_AGE_GROUP_PIE_FAILURE';

export const GET_WITHDRAW_GROUP_PIE_REQUEST = 'GET_WITHDRAW_GROUP_PIE_REQUEST';
export const GET_WITHDRAW_GROUP_PIE_SUCCESS = 'GET_WITHDRAW_GROUP_PIE_SUCCESS';
export const GET_WITHDRAW_GROUP_PIE_FAILURE = 'GET_WITHDRAW_GROUP_PIE_FAILURE';

// action_결혼상태, 관심사, 지역, 연령대, 탈퇴사유
export const GET_CUSTOMER_MARRIED_REQUEST = 'GET_CUSTOMER_MARRIED_REQUEST';
export const GET_CUSTOMER_MARRIED_SUCCESS = 'GET_CUSTOMER_MARRIED_SUCCESS';
export const GET_CUSTOMER_MARRIED_FAILURE = 'GET_CUSTOMER_MARRIED_FAILURE';

export const GET_CUSTOMER_INTERESTS_REQUEST = 'GET_CUSTOMER_INTERESTS_REQUEST';
export const GET_CUSTOMER_INTERESTS_SUCCESS = 'GET_CUSTOMER_INTERESTS_SUCCESS';
export const GET_CUSTOMER_INTERESTS_FAILURE = 'GET_CUSTOMER_INTERESTS_FAILURE';

export const GET_CUSTOMER_LOCATION_REQUEST = 'GET_CUSTOMERLOCATION_REQUEST';
export const GET_CUSTOMER_LOCATION_SUCCESS = 'GET_CUSTOMERLOCATION_SUCCESS';
export const GET_CUSTOMER_LOCATION_FAILURE = 'GET_CUSTOMERLOCATION_FAILURE';

export const GET_CUSTOMER_AGE_REQUEST = 'GET_CUSTOMER_AGE_REQUEST';
export const GET_CUSTOMER_AGE_SUCCESS = 'GET_CUSTOMER_AGE_SUCCESS';
export const GET_CUSTOMER_AGE_FAILURE = 'GET_CUSTOMER_AGE_FAILURE';

export const GET_CUSTOMER_WITHDRAW_REQUEST = 'GET_CUSTOMER_WITHDRAW_REQUEST';
export const GET_CUSTOMER_WITHDRAW_SUCCESS = 'GET_CUSTOMER_WITHDRAW_SUCCESS';
export const GET_CUSTOMER_WITHDRAW_FAILURE = 'GET_CUSTOMER_WITHDRAW_FAILURE';

export const GET_CUSTOMER_WITHDRAW_ELSE_REQUEST = 'GET_CUSTOMER_WITHDRAW_ELSE_REQUEST';
export const GET_CUSTOMER_WITHDRAW_ELSE_SUCCESS = 'GET_CUSTOMER_WITHDRAW_ELSE_SUCCESS';
export const GET_CUSTOMER_WITHDRAW_ELSE_FAILURE = 'GET_CUSTOMER_WITHDRAW_ELSE_FAILURE';

// 카메라 인증 파이, 카메라 인증 데이터
export const GET_CAMERA_AUTH_PIE_REQUEST = 'GET_CAMERA_AUTH_PIE_REQUEST';
export const GET_CAMERA_AUTH_PIE_SUCCESS = 'GET_CAMERA_AUTH_PIE_SUCCESS';
export const GET_CAMERA_AUTH_PIE_FAILURE = 'GET_CAMERA_AUTH_PIE_FAILURE';

export const GET_CAMERA_AUTH_REQUEST = 'GET_CAMERA_AUTH_REQUEST';
export const GET_CAMERA_AUTH_SUCCESS = 'GET_CAMERA_AUTH_SUCCESS';
export const GET_CAMERA_AUTH_FAILURE = 'GET_CAMERA_AUTH_FAILURE';

// initial states
const initialState = {
    // 결혼상태
    marriedStatusData: [],
    loadMarriedDataLoading: false,
    loadMarriedDataDone: false,
    loadMarriedDataError: null,

    // 관심사
    interestsData: [],
    loadInterestsDataLoading: false,
    loadInterestsDataDone: false,
    loadInterestsDataError: null,

    // 지역
    locationData: [],
    loadLocationDataLoading: false,
    loadLocationDataDone: false,
    loadLocationDataError: null,

    // 연령대
    AgeData: [],
    loadAgeDataLoading: false,
    loadAgeDataDone: false,
    loadAgeDataError: null,

    // 탈퇴사유
    withdrawData: [],
    loadWithdrawDataLoading: false,
    loadWithdrawDataDone: false,
    loadWithdrawDataError: null,

    // 탈퇴기타사유
    withdrawElseData: [],
    loadWithdrawElseDataLoading: false,
    loadWithdrawElseDataDone: false,
    loadWithdrawElseDataError: null,

    // 결혼상태 파이차트
    marriedStatusPieData: null,
    loadMarriedPieDataLoading: false,
    loadMarriedPieDataDone: false,
    loadMarriedPieDataError: null,

    // 관심사 파이차트
    interestsPieData: null,
    loadInterestsPieDataLoading: false,
    loadInterestsPieDataDone: false,
    loadInterestsPieDataError: null,

    // 지역 파이차트
    locationPieData: null,
    loadLocationPieDataLoading: false,
    loadLocationPieDataDone: false,
    loadLocationPieDataError: null,

    // 연령대 파이차트
    ageGroupPieData: null,
    ageGroupPieDataLoading: false,
    ageGroupPieDataDone: false,
    ageGroupPieDataError: null,

    // 탈퇴사유 파이차트
    withdrawUsersPieData: null,
    withdrawUsersPieDataLoading: false,
    withdrawUsersPieDataDone: false,
    withdrawUsersPieDataError: null,

    // 카메라 인증
    cameraAuthStatusData: [],
    loadCameraAuthDataLoading: false,
    loadCameraAuthDataDone: false,
    loadCameraAuthDataError: null,

    // 카메아 인증 파이 차트
    cameraAuthPieData: null,
    loadCameraAuthPieDataLoading: false,
    loadCameraAuthPieDataDone: false,
    loadCameraAuthPiePieDataError: null,
};

/************************************** action 함수 **************************************/

// 카메라 인증 파이 차트
export const fetchCameraAuthPieData = () => async (dispatch) => {
    dispatch({
        type: GET_CAMERA_AUTH_PIE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/camera_auth/chart`);

        dispatch({
            type: GET_CAMERA_AUTH_PIE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CAMERA_AUTH_PIE_FAILURE,
            error: error,
        });
    }
};

// 카메라 인증
export const fetchCameraAuthData = () => async (dispatch) => {
    dispatch({
        type: GET_CAMERA_AUTH_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminStatus/camera_auth`);

        dispatch({
            type: GET_CAMERA_AUTH_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CAMERA_AUTH_FAILURE,
            error: error,
        });
    }
};

// 결혼상태 파이차트
export const fetchGetMarriedPieData = () => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_MARRIED_PIE_REQUEST,
    });
    try {
        const response = await getCustomerPieData('married');

        dispatch({
            type: GET_CUSTOMER_MARRIED_PIE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_MARRIED_PIE_FAILURE,
            error: error,
        });
    }
};

// 결혼상태
export const fetchGetMarriedData = () => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_MARRIED_REQUEST,
    });
    try {
        const response = await getCustomerStatus('married');

        dispatch({
            type: GET_CUSTOMER_MARRIED_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_MARRIED_FAILURE,
            error: error,
        });
    }
};

// 관심사 파이차트
export const fetchGetInterestsPieData = () => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_INTERESTS_PIE_REQUEST,
    });
    try {
        const response = await getCustomerPieData('interests');

        dispatch({
            type: GET_CUSTOMER_INTERESTS_PIE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_INTERESTS_PIE_FAILURE,
            error: error,
        });
    }
};

// 관심사
export const fetchGetInterestsData = () => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_INTERESTS_REQUEST,
    });
    try {
        const response = await getCustomerStatus('interests');

        dispatch({
            type: GET_CUSTOMER_INTERESTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_INTERESTS_FAILURE,
            error: error,
        });
    }
};

// 지역 파이차트
export const fetchGetLocationPieData = () => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_LOCATION_PIE_REQUEST,
    });
    try {
        // const response = await RefreshCheckApi.get(`${main_server_ip}/adminStatus/activityRank?page=${page}&size=${size}`);
        const response = await getCustomerPieData('location');

        dispatch({
            type: GET_CUSTOMER_LOCATION_PIE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_LOCATION_PIE_FAILURE,
            error: error,
        });
    }
};

// 지역
export const fetchGetLocationData = (platform) => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_LOCATION_REQUEST,
    });
    try {
        // const response = await RefreshCheckApi.get(`${main_server_ip}/adminStatus/activityRank?page=${page}&size=${size}`);
        const response = await getCustomerStatus('location', platform);

        dispatch({
            type: GET_CUSTOMER_LOCATION_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_LOCATION_FAILURE,
            error: error,
        });
    }
};

// 연령대 파이차트
export const fetchGetAgeBroupPieData = () => async (dispatch) => {
    dispatch({
        type: GET_AGE_GROUP_PIE_REQUEST,
    });
    try {
        const response = await getCustomerPieData('age');

        dispatch({
            type: GET_AGE_GROUP_PIE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_AGE_GROUP_PIE_FAILURE,
            error: error,
        });
    }
};

// 연령대
export const fetchGetAgeData = () => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_AGE_REQUEST,
    });
    try {
        const response = await getCustomerStatus('age');

        dispatch({
            type: GET_CUSTOMER_AGE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_AGE_FAILURE,
            error: error,
        });
    }
};

// 탈퇴사유 파이차트
export const fetchGetWithdrawPieData = () => async (dispatch) => {
    dispatch({
        type: GET_WITHDRAW_GROUP_PIE_REQUEST,
    });
    try {
        const response = await getCustomerPieData('withdraw');

        dispatch({
            type: GET_WITHDRAW_GROUP_PIE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_WITHDRAW_GROUP_PIE_FAILURE,
            error: error,
        });
    }
};

// 탈퇴사유
export const fetchGetWithdrawData = () => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_WITHDRAW_REQUEST,
    });
    try {
        const response = await getCustomerStatus('withdraw');

        dispatch({
            type: GET_CUSTOMER_WITHDRAW_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_WITHDRAW_FAILURE,
            error: error,
        });
    }
};

// 탈퇴기타사유
export const fetchGetWithdrawElseData = (input) => async (dispatch) => {
    dispatch({
        type: GET_CUSTOMER_WITHDRAW_ELSE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get('/adminStatus/withdraw/else', { params: input });

        dispatch({
            type: GET_CUSTOMER_WITHDRAW_ELSE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_WITHDRAW_ELSE_FAILURE,
            error: error,
        });
    }
};

/************************************** reducer **************************************/

const customerDataReducer = handleActions(
    {
        /***********************************************
         ** 테이블 데이터 부분
         ***********************************************/
        // 카메라 인증 데이터
        [GET_CAMERA_AUTH_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCameraAuthDataLoading = true;
                draft.loadCameraAuthDataDone = false;
                draft.loadCameraAuthDataError = null;
            }),
        [GET_CAMERA_AUTH_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCameraAuthDataLoading = false;
                draft.loadCameraAuthDataDone = true;
                draft.cameraAuthStatusData = action.payload; // 클릭률 데이터 STATE
            }),
        [GET_CAMERA_AUTH_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCameraAuthDataLoading = false;
                draft.loadCameraAuthDataError = action.error;
            }),

        // 결혼상태
        [GET_CUSTOMER_MARRIED_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadMarriedDataLoading = true;
                draft.loadMarriedDataDone = false;
                draft.loadMarriedDataError = null;
            }),
        [GET_CUSTOMER_MARRIED_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadMarriedDataLoading = false;
                draft.loadMarriedDataDone = true;
                draft.marriedStatusData = action.payload; // 클릭률 데이터 STATE
            }),
        [GET_CUSTOMER_MARRIED_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadMarriedDataLoading = false;
                draft.loadMarriedDataError = action.error;
            }),

        // 관심사
        [GET_CUSTOMER_INTERESTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadInterestsDataLoading = true;
                draft.loadInterestsDataDone = false;
                draft.loadInterestsDataError = null;
            }),
        [GET_CUSTOMER_INTERESTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadInterestsDataLoading = false;
                draft.loadInterestsDataDone = true;
                draft.interestsData = action.payload; // 놀이순위 데이터 STATE
            }),
        [GET_CUSTOMER_INTERESTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadInterestsDataLoading = false;
                draft.loadInterestsDataError = action.error;
            }),

        // 지역
        [GET_CUSTOMER_LOCATION_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadLocationDataLoading = true;
                draft.loadLocationDataDone = false;
                draft.loadLocationDataError = null;
            }),
        [GET_CUSTOMER_LOCATION_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadLocationDataLoading = false;
                draft.loadLocationDataDone = true;
                draft.locationData = action.payload; // 상품순위 데이터 STATE
            }),
        [GET_CUSTOMER_LOCATION_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadLocationDataLoading = false;
                draft.loadLocationDataError = action.error;
            }),

        // 연령대
        [GET_CUSTOMER_AGE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadAgeDataLoading = true;
                draft.loadAgeDataDone = false;
                draft.loadAgeDataError = null;
            }),
        [GET_CUSTOMER_AGE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadAgeDataLoading = false;
                draft.AgeData = action.payload;
                draft.loadAgeDataDone = true;
            }),
        [GET_CUSTOMER_AGE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadAgeDataLoading = false;
                draft.loadAgeDataError = action.error;
            }),

        // 탈퇴사유
        [GET_CUSTOMER_WITHDRAW_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadWithdrawDataLoading = true;
                draft.loadWithdrawDataDone = false;
                draft.loadWithdrawDataError = null;
            }),
        [GET_CUSTOMER_WITHDRAW_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadWithdrawDataLoading = action.payload ? false : true;
                draft.withdrawData = action.payload;
                draft.loadWithdrawDataDone = action.payload ? true : false;
            }),
        [GET_CUSTOMER_WITHDRAW_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadWithdrawDataLoading = false;
                draft.loadWithdrawDataError = action.error;
            }),

        // 탈퇴기타사유
        [GET_CUSTOMER_WITHDRAW_ELSE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadWithdrawElseDataLoading = true;
                draft.loadWithdrawElseDataDone = false;
                draft.loadWithdrawElseDataError = null;
            }),
        [GET_CUSTOMER_WITHDRAW_ELSE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadWithdrawElseDataLoading = action.payload ? false : true;
                draft.withdrawElseData = action.payload;
                draft.loadWithdrawElseDataDone = action.payload ? true : false;
            }),
        [GET_CUSTOMER_WITHDRAW_ELSE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadWithdrawElseDataLoading = false;
                draft.loadWithdrawElseDataError = action.error;
            }),

        /***********************************************
         ** 파이차트 데이터 부분
         ***********************************************/
        // 카메라 인증 파이 차트 데이터
        [GET_CAMERA_AUTH_PIE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCameraAuthPieDataLoading = true;
                draft.loadCameraAuthPieDataDone = false;
                draft.loadCameraAuthPiePieDataError = null;
            }),
        [GET_CAMERA_AUTH_PIE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCameraAuthPieDataLoading = false;
                draft.loadCameraAuthPieDataDone = true;
                draft.cameraAuthPieData = action.payload; // 상품순위 데이터 STATE
            }),
        [GET_CAMERA_AUTH_PIE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadCameraAuthPieDataLoading = false;
                draft.loadCameraAuthPiePieDataError = action.error;
            }),

        // 결혼상태 파이차트 데이터
        [GET_CUSTOMER_MARRIED_PIE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadMarriedPieDataLoading = true;
                draft.loadMarriedPieDataDone = false;
                draft.loadMarriedPieDataError = null;
            }),
        [GET_CUSTOMER_MARRIED_PIE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadMarriedPieDataLoading = false;
                draft.loadMarriedPieDataDone = true;
                draft.marriedStatusPieData = action.payload; // 상품순위 데이터 STATE
            }),
        [GET_CUSTOMER_MARRIED_PIE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadMarriedPieDataLoading = false;
                draft.loadMarriedPieDataError = action.error;
            }),

        // 관심사 파이차트 데이터
        [GET_CUSTOMER_INTERESTS_PIE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadInterestsPieDataLoading = true;
                draft.loadInterestsPieDataDone = false;
                draft.loadInterestsPieDataError = null;
            }),
        [GET_CUSTOMER_INTERESTS_PIE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadInterestsPieDataLoading = false;
                draft.loadInterestsPieDataDone = true;
                draft.interestsPieData = action.payload; // 상품순위 데이터 STATE
            }),
        [GET_CUSTOMER_INTERESTS_PIE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadInterestsPieDataLoading = false;
                draft.loadInterestsPieDataError = action.error;
            }),

        // 지역 파이차트 데이터
        [GET_CUSTOMER_LOCATION_PIE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadLocationPieDataLoading = true;
                draft.loadLocationPieDataDone = false;
                draft.loadLocationPieDataError = null;
            }),
        [GET_CUSTOMER_LOCATION_PIE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadLocationPieDataLoading = false;
                draft.loadLocationPieDataDone = true;
                draft.locationPieData = action.payload; // 상품순위 데이터 STATE
            }),
        [GET_CUSTOMER_LOCATION_PIE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadLocationPieDataLoading = false;
                draft.loadLocationPieDataError = action.error;
            }),

        // 연령대 파이차트 데이터
        [GET_AGE_GROUP_PIE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.ageGroupPieDataLoading = true;
                draft.ageGroupPieDataDone = false;
                draft.ageGroupPieDataError = null;
            }),
        [GET_AGE_GROUP_PIE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.ageGroupPieDataLoading = false;
                draft.ageGroupPieData = action.payload;
                draft.ageGroupPieDataDone = true;
            }),
        [GET_AGE_GROUP_PIE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.ageGroupPieDataLoading = false;
                draft.ageGroupPieDataError = action.error;
            }),

        // 탈퇴회원 파이차트 데이터
        [GET_WITHDRAW_GROUP_PIE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.withdrawUsersPieDataLoading = true;
                draft.withdrawUsersPieDataDone = false;
                draft.withdrawUsersPieDataError = null;
            }),
        [GET_WITHDRAW_GROUP_PIE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.withdrawUsersPieDataLoading = action.payload ? false : true;
                draft.withdrawUsersPieData = action.payload;
                draft.withdrawUsersPieDataDone = action.payload ? true : false;
            }),
        [GET_WITHDRAW_GROUP_PIE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.withdrawUsersPieDataLoading = false;
                draft.withdrawUsersPieDataError = action.error;
            }),
    },
    initialState,
);

export default customerDataReducer;
