import React, { useEffect, useState } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';

// API
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_image_ip } from '../../communication_system/communication_ips';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { deleteGatheringContentsCommentRetry, RESET_STATE } from '../../data_system/redux/modules/gathering.reducer/gatheringContents.reducer';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { Image } from '../../designComponents/imageboxs/Image';
import { SText } from '../../designComponents/text/ContentText';
import CommentTile from '../../EventComponents/Gathering/CommentTile';

/** 대댓글 컴포넌트 */
function CommentRetry({ commentId }) {
    const dispatch = useDispatch();
    const [commentRetryState, setCommentRetryState] = useState([]);
    const { deleteCommentRetryMessage } = useSelector((state) => state.getGatheringContentsReducer);

    useEffect(() => {
        const response = RefreshCheckApi.get(`/adminGathering/comments/retry?comment_id=${commentId}`);
        response
            .then((res) => {
                setCommentRetryState(res.data.data);
            })
            .catch((error) => {
                console.error(error.message);
            });

        dispatch({ type: RESET_STATE });
    }, [dispatch, commentId, setCommentRetryState, deleteCommentRetryMessage]);

    const onClickDeleteCommentRetry = (commnet_id) => () => {
        if (window.confirm('대댓글을 삭제하시겠습니까?')) {
            dispatch(deleteGatheringContentsCommentRetry(commnet_id));
        }
    };

    /************************************************** html **************************************************/
    return (
        <div>
            {commentRetryState?.map((data, index) => {
                return (
                    <CommentTile
                        key={`comment_retry_${index}`}
                        data={data}
                        onDelete={(id) => {
                            setCommentRetryState((pre) => pre.filter((e) => e.id !== id));
                        }}
                        isRetry={true}
                    />
                );
            })}
        </div>
    );
}

export default CommentRetry;
