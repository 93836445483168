import React from 'react';

// styled-components
import styled from 'styled-components';

// prop-types
import PropTypes from 'prop-types';

import { rgb_171_171_171, rgb_66_139_247, rgba_66_139_247_0d2 } from '../../design_system/colors';
import { SearchWhite1x } from '../../design_system/icons/icons';

const SearchBar = ({ searchText, onChangeSearchText, onSearch }) => {
    return (
        <SearchBarFrame>
            <SearchBarInput placeholder="검색어 입력" value={searchText} onChange={onChangeSearchText} />
            <SearchBtn onClick={onSearch}>
                <div style={{ marginTop: '2px' }}>
                    <SearchWhite1x />
                </div>
            </SearchBtn>
        </SearchBarFrame>
    );
};

SearchBar.propTypes = {
    searchText: PropTypes.string.isRequired,
    onChangeSearchText: PropTypes.func.isRequired,
    // onSearch: PropTypes.func.isRequired,
};

export default React.memo(SearchBar);

const SearchBarFrame = styled.div`
    display: flex;
`;

const SearchBarInput = styled.input`
    width: 220px;
    height: 35px;

    padding-left: 10px;
    padding-right: 10px;

    vertical-align: middle;
    margin: 0px;
    font-size: 15px;
    font-family: noto_sans_kr_regular;
    box-sizing: border-box;
    border: 1px solid ${rgba_66_139_247_0d2};
    border-right-width: 0px;

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${rgb_171_171_171};
    }

    :-ms-input-placeholder {
        color: ${rgb_171_171_171};
    }

    &:focus {
        outline: none;
    }
`;

const SearchBtn = styled.button`
    cursor: pointer;
    width: 35px;
    height: 35px;

    background-color: ${rgb_66_139_247};
    border-width: 0px;
    align-items: center;
    justify-content: center;

    img {
        width: 17px;
        height: 17px;
    }

    :hover {
        background-color: rgb(5 101 242);
    }
    :active {
        background-color: ${rgb_66_139_247};
    }
`;
