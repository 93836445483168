import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";

export const GatheringAccountTab = ({setIndex}) => {
	const tabList = [
		{
			title: '계좌 정보',
			key: 0
		},{
			title: '계좌 사용 내역',
			key: 1
		},
	]


	return (
		<BootStrapTabs tabList={tabList} setValue={setIndex} ></BootStrapTabs>
	)
}

export default GatheringAccountTab