import React from 'react';
import styled from 'styled-components';

const MainPageTitle = ({ text, onClick, rederItem, sigle = true, wrapperStyle, pageBtnStyle, fontStyle }) => {
    return sigle ? (
        <TitleWrapper style={{ ...wrapperStyle }} onClick={onClick}>
            <span style={{ ...fontStyle }}>{text}</span>
        </TitleWrapper>
    ) : (
        <TitleWrapper style={{ ...wrapperStyle }}>

            {rederItem.map((item, index) => (
                <PageButton style={{ ...pageBtnStyle }} key={index} onToggle={item.toggle} onClick={item.onPress}>
                    <span style={{ ...fontStyle }}>{item.text}</span>
                </PageButton>
            ))}
        </TitleWrapper>
    );
};

export default MainPageTitle;

const TitleWrapper = styled.div`
    display: flex;
    align-items: start;
    gap: 20px;

    font-size: 26px;
    font-family: noto_sans_kr_bold;
    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;
`;

export const PageButton = styled.div`
    color: ${(props) => (props.onToggle ? 'black' : '#797979')};
    text-decoration: ${(props) => (props.onToggle ? 'underline' : 'none')};

    :hover {
        cursor: pointer;
        color: black;
        text-decoration: underline;
    }
`;
