export const questionTableFilter = [
    {
        key: 'created_at',
        value: '생성일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'updated_at',
        value: '수정일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'deleted_at',
        value: '삭제여부',
        filter: [
            {
                key: 1,
                value: 'ON',
            },
            {
                key: 2,
                value: 'OFF',
            },
        ],
    },
    {
        key: 'category',
        value: '분류',
        type: 1, // button
    },
];
