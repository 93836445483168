import { MiddleModal } from '../../common/BootStrap/Modal';
import Form from 'react-bootstrap/Form';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { makeImageUrl } from '../../designComponents/imageboxs/Image';
import DateTimeSelector from '../../common/DateTimeSelector';
import { MText } from '../../designComponents/text/ContentText';
import { MainBtn } from '../../design_system/components/pink_btn/PinkBtn';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { InputLine } from '../../designComponents/inputs/InputLine';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { createEventBannerInfo } from '../../data_system/redux/modules/events_reducer/events.reducer';
const LinkTypes = {
    EXTERNAL: 0,
    APP: 1,
    KAKAO_CHAT: 2,
    TO_SIRUP: 3,
    TO_SINOR: 4,
};
const LocationType = {
    MainPopUp: 1,
    GatheringHome: 2,
    Chatting: 3,
    MyInfoTab: 5,
};
const sinorDeepLink = 'https://senor.co.kr/sinorGathering';
const sirupDeepLink = 'https://senor.co.kr/scheme';
const BannerInfoModal = ({ data, visible, setVisible, onSave }) => {
    const [title, setTitle] = useState('');
    const [exposureLocation, setExposureLocation] = useState(-1);
    const [exposureState, setExposureState] = useState(-1);
    const [exposureStartDate, setExposureStartDate] = useState(null);
    const [exposureEndDate, setExposureEndDate] = useState(null);
    const [platform, setPlatform] = useState(0);
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);

    const [order, setOrder] = useState(0);
    const [linkUrl, setLinkUrl] = useState(null);
    const [linkType, setLinkType] = useState(0);
    const linkRef = useRef();
    const imageRef = useRef();
    const checkLinkType = (url) => {
        if (/^http/.test(url)) {
            setLinkUrl(url);
            if (url === kakaoChatUrl) {
                setLinkType(LinkTypes.KAKAO_CHAT);
            } else if (url === sirupDeepLink) {
                setLinkType(LinkTypes.TO_SIRUP);
            } else if (url === sinorDeepLink) {
                setLinkType(LinkTypes.TO_SINOR);
            } else {
                setLinkType(LinkTypes.EXTERNAL);
            }
        } else {
            try {
                const json = JSON.parse(url);
                setLinkUrl(json);
                setLinkType(LinkTypes.APP);
            } catch (e) {}
        }
    };
    const getImageUrl = async () => {
        if (file) {
            const formData = new FormData();

            formData.append('image', file);

            const response = await RefreshCheckApi.post('/adminEvent/transform', formData);
            if (response.status === 200) {
                return response.data.data;
            } else {
                alert(`이미지 저장에 실패했습니다. 나중에 다시 시도해주세요 (${response.status}`);
                return null;
            }
        } else {
            return image;
        }
    };
    const saveBanner = async () => {
        if (exposureLocation === -1) {
            alert('노출 위치를 입력해주세요');
            return;
        }
        if (!image && !file) {
            alert('이미지를 선택해 주세요');
            return;
        }
        if (!exposureStartDate || !exposureEndDate) {
            alert('노출 시작시간과 종료시간을 입력해 주세요');
            return;
        }
        if (!linkRef.current?.checkParam() && linkType === LinkTypes.APP) {
            alert('페이지 이동의 필수 항목을 모두 입력해주세요');
            return;
        }
        const isCreate = !data?.event_id;
        if (window.confirm(`${isCreate ? '생성' : '수정'}하시겠습니까?`)) {
            const linkData =
                linkType === LinkTypes.EXTERNAL
                    ? linkUrl
                    : linkType === LinkTypes.KAKAO_CHAT
                      ? kakaoChatUrl
                      : linkType === LinkTypes.APP
                        ? linkRef.current?.makeUrlString()
                        : linkType === LinkTypes.TO_SIRUP
                          ? sirupDeepLink
                          : linkType === LinkTypes.TO_SINOR
                            ? sinorDeepLink
                            : null;

            const imageUrl = await getImageUrl();
            if (!imageUrl) {
                return;
            }
            const bannerData = {
                platform,
                title,
                image_url: imageUrl,
                exposure_location: exposureLocation,
                exposure_yn: exposureState,
                exposure_start_datetime: exposureStartDate,
                exposure_end_datetime: exposureEndDate,
                order,
                link_url: linkData,
            };
            let response;

            if (isCreate) {
                response = await RefreshCheckApi.post(`/adminEvent`, bannerData);
            } else {
                response = await RefreshCheckApi.put(`/adminEvent/${data.event_id}`, bannerData);
            }
            if (response.status === 200) {
                alert(`${isCreate ? '생성' : '수정'}되었습니다.`);

                onSave();
                setVisible(false);
            } else {
                alert('오류가 발생했습니다. 나중에 다시 시도해 주세요');
            }
        }
    };
    useEffect(() => {
        if (!visible || data) {
            setFile(null);
            setTitle(data?.title ?? '');
            setExposureLocation(data?.exposure_location ?? -1);
            setExposureState(data?.exposure_yn ?? -1);
            setExposureStartDate(data?.exposure_start_datetime ?? null);
            setExposureEndDate(data?.exposure_end_datetime ?? null);
            setOrder(data?.order ?? 0);
            setPlatform(data?.platform ?? 0);
            setImage(data?.image_url ?? null);
            if (data?.link_url) {
                checkLinkType(data.link_url);
            } else {
                setLinkUrl(null);
            }
        }
    }, [data, visible]);
    return (
        <MiddleModal
            scrollable={true}
            visible={visible}
            setVisible={setVisible}
            title={data?.title ?? 'Banner 등록'}
            footer={
                <FlexMainWrapper style={{ justifyContent: 'flex-end' }}>
                    <MainButton text={'닫기'} onClick={() => setVisible(false)} />
                    <MainButton styleReverse text={'저장하기'} onClick={saveBanner} />
                </FlexMainWrapper>
            }
        >
            <FlexMainWrapper>
                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', gap: 16 }}>
                    <div style={{ width: 400, height: 400, marginRight: 16 }}>
                        {image === null && file === null ? (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                <MText text={'이미지를 선택해 주세요'} />
                            </div>
                        ) : (
                            <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={file ? URL.createObjectURL(file) : makeImageUrl(image)} />
                        )}
                    </div>
                    <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        hidden
                        ref={imageRef}
                        onChange={(e) => {
                            const file = e.target?.files[0];
                            if (file) {
                                setFile(file);
                            }
                        }}
                    />
                    <MainButton
                        text={'이미지 선택'}
                        onClick={() => {
                            imageRef.current.click();
                        }}
                    />
                </FlexMainWrapper>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'stretch' }}>
                    <TextInfo value={title} setValue={setTitle} title={'베너 이름'} />
                    <SelectInfo
                        title={'베너 위치'}
                        placeHolder={'베너 위치를 선택해 주세요'}
                        value={exposureLocation}
                        onChange={(e) => setExposureLocation(Number(e))}
                        data={[
                            { name: '메인 팝업', value: LocationType.MainPopUp },
                            { name: '모임 메인 (띠베너)', value: LocationType.GatheringHome },
                            { name: '채팅 탭 (띠베너)', value: LocationType.Chatting },
                            { name: '내정보 탭 (띠베너)', value: LocationType.MyInfoTab },
                        ]}
                    />
                    <SelectInfo
                        title={'노출 여부'}
                        placeHolder={'노출 여부를 선택해 주세요 (노출기간이 우선으로 적용됩니다.)'}
                        value={exposureState}
                        onChange={(e) => setExposureState(Number(e))}
                        data={[
                            { name: '비노출', value: 0 },
                            { name: '노출', value: 1 },
                        ]}
                    />
                    <SelectInfo
                        title={'노출 앱'}
                        placeHolder={'노출 앱을 선택해주세요'}
                        value={platform}
                        onChange={(e) => setPlatform(Number(e))}
                        data={[
                            { name: '전체', value: 0 },
                            { name: '시럽', value: 1 },
                            { name: '시놀', value: 2 },
                        ]}
                    />
                    <PeriodSelector
                        title={'노출 기간'}
                        startDate={exposureStartDate}
                        endDate={exposureEndDate}
                        onChangeStartDate={(e) => setExposureStartDate(e)}
                        onChangeEndDate={(e) => setExposureEndDate(e)}
                    />
                    <TextInfo value={order} setValue={setOrder} title={'노출 순위'} />
                    <SelectInfo
                        title={'링크 타입'}
                        placeHolder={'링크 타입을 선택해 주세요'}
                        value={linkType}
                        onChange={(e) => setLinkType(Number(e))}
                        data={[
                            { name: '인앱 Page', value: LinkTypes.APP },
                            { name: '외부 링크', value: LinkTypes.EXTERNAL },
                            { name: '카카오톡 문의하기', value: LinkTypes.KAKAO_CHAT },
                            { name: '시럽 실행/마켓', value: LinkTypes.TO_SIRUP },
                            { name: '시놀 실행/마켓', value: LinkTypes.TO_SINOR },
                        ]}
                    />
                    {linkType === LinkTypes.EXTERNAL ? (
                        <TextInfo title={'이동 URL'} value={typeof linkUrl === 'object' ? '' : linkUrl} setValue={setLinkUrl} />
                    ) : linkType === LinkTypes.APP ? (
                        <LinkSelector ref={linkRef} url={linkUrl} />
                    ) : null}
                </div>
            </FlexMainWrapper>
        </MiddleModal>
    );
};
const TextInfo = ({ title, required, value, setValue }) => {
    return (
        <div className="mb-3">
            <Form.Label style={{ display: 'flex' }}>
                {title} {required ? <MText text={'*'} color={'red'} /> : null}
            </Form.Label>
            <Form.Control type="text" value={value} onChange={(e) => setValue(e.target.value)} placeholder="" />
        </div>
    );
};
const ImageInfo = ({ title, image = null, required, setImage }) => {
    const [file, setFile] = useState(null);
    const imageRef = useRef();
    const getImageUrl = async () => {
        if (file) {
            const formData = new FormData();

            formData.append('image', file);

            const response = await RefreshCheckApi.post('/adminEvent/transform', formData);

            if (response.status === 200) {
                const url = response.data.data;
                setTimeout(() => {
                    setImage(url);
                    setFile(null);
                }, 1000);
            } else {
                alert('이미지 URL 생성에 실패하였습니다.');
            }
        }
    };
    return (
        <div className="mb-3">
            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Form.Label>{title}</Form.Label>
                <input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    hidden
                    ref={imageRef}
                    onChange={(e) => {
                        const file = e.target?.files[0];
                        if (file) {
                            setFile(file);
                        }
                    }}
                />
                <MainButton
                    text={'이미지 선택'}
                    onClick={() => {
                        imageRef.current.click();
                    }}
                />
            </FlexMainWrapper>

            {image === null && file === null ? null : (
                <div>
                    <div style={{ width: 100, height: 100 }}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={file ? URL.createObjectURL(file) : makeImageUrl(image)} />{' '}
                    </div>
                    {file != null && (
                        <div>
                            <MainButton text={'이미지 URL 생성하기'} onClick={getImageUrl} />
                            <MText text={'* 이미지 URL을 생성해야 서버에서 사용 가능합니다.'} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
const SelectInfo = ({ title, placeHolder, data, value, onChange }) => {
    return (
        <div className="mb-3">
            <Form.Label>{title}</Form.Label>
            <Form.Select
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            >
                {placeHolder ? <option>{placeHolder}</option> : null}
                {data.map((e) => (
                    <option key={`link_option_${e.value}`} value={e.value}>
                        {e.name}
                    </option>
                ))}
            </Form.Select>
        </div>
    );
};
export const PeriodSelector = ({ title, startDate, endDate, onChangeStartDate, onChangeEndDate }) => {
    return (
        <div className="mb-3">
            <Form.Label>{title}</Form.Label>
            <FlexMainWrapper>
                <input style={{ flex: 1 }} type={'datetime-local'} value={startDate} onChange={(e) => onChangeStartDate(e.target.value)} />
                ~
                <input style={{ flex: 1 }} type={'datetime-local'} value={endDate} onChange={(e) => onChangeEndDate(e.target.value)} />
            </FlexMainWrapper>
        </div>
    );
};

const LinkSelector = forwardRef(({ url }, _ref) => {
    const [data, setData] = useState(null);
    const [params, setParams] = useState([]);
    useEffect(() => {
        if (url) {
            const d = {
                ...url,
                ...url.param,
            };
            delete d.param;
            setData(d);
        } else {
            setData(null);
        }
    }, [url]);
    useEffect(() => {
        if (data?.page) {
            const idx = LinkUrl.findIndex((e) => e.page === data.page);
            if (idx > -1) {
                if (params !== LinkUrl[idx].param) setParams(LinkUrl[idx].param);
            } else {
                setParams([]);
            }
        }
    }, [data]);
    const checkParam = () => {
        let check = true;
        for (var e of params) {
            if (e.required && (data[e.key] ?? '').length === 0) {
                check = false;
                break;
            }
        }
        return check;
    };

    const makeUrlString = () => {
        const url = { param: {}, page: data.page };

        for (var p of params) {
            if (data[p.key]) {
                url.param[p.key] = data[p.key];
            }
        }
        return JSON.stringify(url);
    };
    const getData = () => {
        return data;
    };
    useImperativeHandle(_ref, () => ({
        checkParam,
        getData,
        makeUrlString,
    }));
    return (
        <div>
            <Form.Label>Page 선택</Form.Label>
            <Form.Select style={{ marginBottom: 16 }} value={data?.page} onChange={(e) => setData({ ...data, page: e.target.value })}>
                <option>아래 페이지 이외는 아무 페이지로 생성후 개발자 문의</option>
                {LinkUrl.map((e) => (
                    <option key={`link_option_${e.page}`} value={e.page}>
                        {e.name}
                    </option>
                ))}
            </Form.Select>
            {params?.map((e) =>
                e.type === 'image' ? (
                    <ImageInfo
                        key={`sub_param_${e.key}`}
                        title={e.name}
                        required={e.required}
                        image={data ? (data[e.key] ?? null) : null}
                        setImage={(url) => {
                            const d = { ...data };
                            d[e.key] = url;
                            setData(d);
                        }}
                    />
                ) : (
                    <TextInfo
                        key={`sub_param_${e.key}`}
                        title={e.name}
                        required={e.required}
                        value={data ? (data[e.key] ?? '') : ''}
                        setValue={(value) => {
                            const d = { ...data };
                            d[e.key] = e.type === 'number' ? Number(value) : value;
                            setData(d);
                        }}
                    />
                ),
            )}
        </div>
    );
});

export const BannerLocationModal = ({ visible, setVisible, bannerId }) => {
    const [location, setLocation] = useState(-1);
    useEffect(() => {
        if (!visible) {
            setLocation(-1);
        }
    }, [visible]);
    const copyBanner = async () => {
        if (location === -1) {
            alert('복사 위치를 선택해 주세요');
        }
        const response = await RefreshCheckApi.post(`/adminEvent/copy`, { banner_id: bannerId, location });
        if (response.status === 200) {
            alert('복사되었습니다.');
            setVisible(false);
        } else {
            alert(`복사 실패 (${response.status})`);
        }
    };
    if (!bannerId) {
        return null;
    }
    return (
        <MiddleModal
            scrollable={true}
            visible={visible}
            setVisible={setVisible}
            title={'Banner 복사'}
            footer={
                <FlexMainWrapper style={{ justifyContent: 'flex-end' }}>
                    <MainButton text={'닫기'} onClick={() => setVisible(false)} />
                    <MainButton styleReverse text={'저장하기'} onClick={copyBanner} />
                </FlexMainWrapper>
            }
        >
            <Form.Select value={location} onChange={(e) => setLocation(Number(e.target.value))}>
                <option value={-1}>복사할 위치를 선택해 주세요</option>
                <option value={1}>메인 홈 팝업</option>
                <option value={2}>모임 홈 탭</option>
                <option value={3}>채팅 탭</option>
                <option value={5}>내정보 탭</option>
            </Form.Select>
        </MiddleModal>
    );
};

const kakaoChatUrl = 'http://pf.kakao.com/_xixaUFb/chat';
const LinkUrl = [
    {
        name: '이벤트 페이지',
        page: 'EventPage',
        param: [
            { key: 'url', name: '링크' },
            { key: 'image', name: '이미지', type: 'image' },
            { key: 'title', name: '상단제목' },
        ],
    },
    {
        name: '메인 페이지',
        page: 'MainPage',
        param: [{ key: 'index', name: '이동 탭 (없으면 Home,내 모임(0),내 인연(2),채팅(3),내정보(4) )' }],
    },
    {
        name: '공동구매',
        page: 'CommercePage',
        param: [{ key: 'initProductId', name: '상품 ID (없으면 목록 이동)', type: 'number' }],
    },

    {
        name: '모임 상세',
        page: 'GatheringContentsPage',
        param: [{ key: 'gathering_id', name: '모임 ID', required: true, type: 'number' }],
    },
    {
        name: '게시글 상세',
        page: 'GatheringContentsDetailPage',
        param: [{ key: 'contents_id', name: '게시글 ID', required: true }],
    },
    {
        name: '친구 초대',
        page: 'InviteFriendPage',
        param: [],
    },
    {
        name: '공지 사항',
        page: 'AnnounceInfoPage',
        param: [{ key: 'id', name: '상품 ID (없으면 목록 이동)', type: 'number' }],
    },
];

export default BannerInfoModal;
