import React, { useCallback, useEffect, useRef, useState } from 'react';

// components
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { dateFilter } from '../../utils/textFilter';
import PolicyDetailModal from './PolicyDetailModal';

// 포인트 테이블
function PolicyTable({}) {
    const tableRef = useRef();
    const [detailVisible, setDetailVisible] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState(null);
    const callApi = useCallback(async () => {
        const response = await RefreshCheckApi.get('/adminPolicy');
        return response.data.data;
    }, []);
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        if (!detailVisible) {
            setSelectedPolicy(null);
        }
    }, [detailVisible]);
    return (
        <>
            <Wrapper02>
                <FlexMainWrapper>
                    <MainTable
                        toggleBlur={true}
                        checkAble={false}
                        buttonComponents={({ checkItems, setCheckItems }) => (
                            <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'end' }}>
                                <TopSideButton
                                    middleOn={false}
                                    rightOn={false}
                                    onClick={() => {
                                        setDetailVisible(true);
                                    }}
                                />
                            </FlexMainWrapper>
                        )}
                        rendering={refresh}
                        tableStyle={{ width: '98%' }}
                        tableRef={tableRef}
                        loadingText={'정책 내역을 불러오고 있습니다.'}
                        headerData={[
                            {
                                key: 'id',
                                value: 'ID',
                            },
                            {
                                key: 'title',
                                value: '이름',
                            },
                            {
                                key: 'createdAt',
                                value: '생성일',
                            },
                        ]}
                        callApi={callApi}
                        pagenaitionOn={false}
                        onClickRowItem={(item) => () => {
                            setSelectedPolicy(item);
                            setDetailVisible(true);
                        }}
                        tbodyRenderItem={(key, value) => {
                            if (key === 'createdAt') {
                                return dateFilter(new Date(value), 'YYYY-MM-DD');
                            }
                            return value;
                        }}
                    />
                </FlexMainWrapper>
            </Wrapper02>

            <PolicyDetailModal
                onUpdate={() => {
                    setRefresh(!refresh);
                }}
                item={selectedPolicy}
                visible={detailVisible}
                setVisible={setDetailVisible}
            />
        </>
    );
}

export default PolicyTable;
