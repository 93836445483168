import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CheckBox = ({ onClick, checked, style }) => {
  const [check, setChecked] = useState(checked);

  const setCheck = () => {
    onClick(!check);
    setChecked(!check);
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return <CheckBoxInput onClick={setCheck} checked={check} style={{ ...style }} readOnly></CheckBoxInput>;
};

export default React.memo(CheckBox);

export const CheckBoxInput = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;
