import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import React, { useEffect, useState } from 'react';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { Image } from '../../designComponents/imageboxs/Image';
import styled from 'styled-components';
import { LText, MText } from '../../designComponents/text/ContentText';
import { MainButton } from '../../designComponents/buttons/Buttons';
import LoadingPage from '../../pages/loading/LoadingPage';
import { Empty } from 'antd';
import { EmptyWrapper } from '../../designComponents/tables/TbodyLists';
const ApprovalRequestList = ({ gatheringId, onApproval }) => {
    const [data, setData] = useState([]);
    const init = async () => {
        const response = await RefreshCheckApi.get(`/adminGathering/approval/question?gathering_id=${gatheringId}`);

        if (Array.isArray(response.data.data)) {
            setData(response.data?.data ?? []);
        }
    };
    const approveRequest = async (data) => {
        const { user_id, request_id } = data;
        if (window.confirm('가입을 승인하시겠어요?')) {
            const response = await RefreshCheckApi.post(`/adminGathering/approval/request`, {
                user_id,
                request_id,
                gathering_id: gatheringId,
            });
            if (response.data.ok) {
                alert('가입처리되었습니다');
                onApproval();
                setData((pre) => pre.filter((e) => e.request_id !== request_id));
            }
        }
    };
    const rejectRequest = async (data) => {
        const { request_id } = data;
        if (window.confirm('가입 거절할까요?')) {
            const response = await RefreshCheckApi.delete(`/adminGathering/approval/request`, {
                data: {
                    request_id,
                    gathering_id: gatheringId,
                },
            });
            if (response.data.ok) {
                alert('처리되었습니다');
                setData((pre) => pre.filter((e) => e.request_id !== request_id));
            }
        }
    };
    useEffect(() => {
        init();
    }, []);
    return (
        <FlexMainWrapper column style={{ alignItems: 'stretch' }}>
            {data?.map((item, index) => (
                <ItemWrapper key={`approval_request_${index}`}>
                    <FlexMainWrapper style={{ alignItems: 'center' }}>
                        <Image url={item.profile} alt="회원 이미지" width={42} height={42} style={{ border: '1px solid #c7c7c7', borderRadius: '50%' }} />
                        <FlexMainWrapper style={{ flex: 1 }} colunm>
                            <MText text={`${item.nick_name} • ${Number(item.sex) === 1 ? '남' : '여'} • ${item.age}대`} />
                        </FlexMainWrapper>
                        <FlexMainWrapper style={{ flex: 1, gap: 4, justifyContent: 'flex-end' }}>
                            <MainButton text={'승인'} onClick={() => approveRequest(item)} />
                            <MainButton styleReverse text={'거절'} onClick={() => rejectRequest(item)} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                    {item?.answer_data?.map((ans, idx) =>
                        ans ? (
                            <div key={`ans_${index}_${idx}`} style={{ paddingBlock: 4, gap: 2 }}>
                                <MText text={`Q${idx + 1}. ${ans.question}`} />
                                <LText text={ans.answer} />
                            </div>
                        ) : null,
                    )}
                </ItemWrapper>
            ))}
            {data?.length === 0 && (
                <EmptyWrapper className="empty-box">
                    <Empty />
                    <div style={{ margin: '5% 0' }}>가입 대기인원이 없거나 승인제 모임이 아닙니다</div>
                </EmptyWrapper>
            )}
        </FlexMainWrapper>
    );
};

const ItemWrapper = styled.div`
    padding: 12px;
    border-radius: 12px;
    border: solid gray 1px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
export default ApprovalRequestList;
