import React from 'react';
import { BarLoader } from 'react-spinners';

// styled-components
import styled from 'styled-components';

const LoadingPage = ({ text, sizes, style, className }) => {
    return (
        <Wrapper sizes={sizes} style={{ ...style }} className={className}>
            <BarLoader height={'4px'} width={'100px'} color={'rgb(66 139 247)'} />
            <div style={{ fontSize: '13px' }}>{text || null}</div>
        </Wrapper>
    );
};
export default React.memo(LoadingPage);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    height: ${(props) => (props.sizes ? `140px` : `564px`)};
    width: 100%;
`;
