import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 취미/여가 생성
export const ADD_ACTIVITY_REQUEST = 'ADD_ACTIVITY_REQUEST';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_FAILURE = 'ADD_ACTIVITY_FAILURE';
// 호스트 정보 불러오기
export const GET_ALL_HOSTS_REQUEST = 'GET_ALL_HOSTS_REQUEST';
export const GET_ALL_HOSTS_SUCCESS = 'GET_ALL_HOSTS_SUCCESS';
export const GET_ALL_HOSTS_FAILURE = 'GET_ALL_HOSTS_FAILURE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    allHosts: null, // 호스트 정보
    // 취미/여가 생성
    addActivityLoading: false,
    addActivityDone: false,
    addActivityError: null,
    // 호스트 정보 불러오기
    getHostsLoading: false,
    getHostsDone: false,
    getHostsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 취미/여가 생성
export const createActivity = (param) => async (dispatch) => {
    dispatch({
        type: ADD_ACTIVITY_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminActivity`, param);
        dispatch({
            type: ADD_ACTIVITY_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: ADD_ACTIVITY_FAILURE,
            error: error,
        });
    }
};

// 호스트 정보 불러오기
export const getAllHosts = () => async (dispatch) => {
    dispatch({
        type: GET_ALL_HOSTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`adminHost/all`);
        dispatch({
            type: GET_ALL_HOSTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_HOSTS_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const addActivityReducer = handleActions(
    {
        // 취미/여가 생성
        [ADD_ACTIVITY_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.addActivityLoading = true;
                draft.addActivityDone = false;
                draft.addActivityError = null;
            }),
        [ADD_ACTIVITY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.addActivityLoading = false;
                draft.addActivityDone = true;
            }),
        [ADD_ACTIVITY_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.addActivityLoading = false;
                draft.addActivityError = action.error;
            }),

        // 호스트 정보 불러오기
        [GET_ALL_HOSTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getHostsLoading = true;
                draft.getHostsDone = false;
                draft.getHostsError = null;
            }),
        [GET_ALL_HOSTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getHostsLoading = false;
                draft.allHosts = action.payload;
                draft.getHostsDone = true;
            }),
        [GET_ALL_HOSTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getHostsLoading = false;
                draft.getHostsError = action.error;
            }),
    },
    initialState,
);

export default addActivityReducer;
