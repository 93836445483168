import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { availCheck, contentFilter, dateFilter, noneImage, profilePhotoAuth, sexFilter } from '../../utils/textFilter';
import { userTableFilter } from '../../components/UserComponents/UserTableFilter';

import { adminUsers } from '../../dataSystems/AdminUserLists';
import MainTable from '../../designComponents/tables/MainTable';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { Image } from '../../designComponents/imageboxs/Image';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import SearchbarWithDropdownV2 from '../../designComponents/searchbars/SearchbarWithDropdownV2';
import TableFilter from '../../designComponents/filters/TableFilter';

const UserLists = ({ onClickUser = () => {} }) => {
    const navigator = useNavigate();
    const [imageToggle, setImageToggle] = useState(false);

    const [checkItems, setCheckItems] = useState([]); // 체크된 회원id를 담을 배열
    const [checkAllData, setCheckAllData] = useState([]); // 체크된 회원data를 담을 배열
    const [src, setSrc] = useState('');
    const [sex, setSex] = useState(1);

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({});

    const ref = useRef(null);
    const imgRef = useRef(null);
    const tableRef = useRef(null);
    useEffect(() => {
        if (searchType === 1) {
            setSearchText(searchText.replace(/-/g, ''));
        }
        ref?.current?.refresh();
    }, [searchText, searchType]);

    useEffect(() => {
        const res = RefreshCheckApi.get('/admin/check');
        res.then((res) => {
            if (parseInt(res.data.info.role) === 0) navigator('/statistics');
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_token');
                localStorage.removeItem('expires_at');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user');
                alert('로그인 기한이 만료 되었습니다. 다시 로그인을 시도해주세요.');
                window.location.replace('/login');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 0,
            ...param,
        };
        const response = await RefreshCheckApi.get('/adminUser', { params: input });
        if (response.status === 200) return response.data.data;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 0,
            ...param,
        };
        const response = await RefreshCheckApi.get('/adminUser/allCount', { params: input });
        if (response.status === 200) return response?.data?.all_count;
    };

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle((prev) => !prev);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const onClickImage = (item, value) => {
        setSex(item.sex);
        setImageToggle(true);
        setSrc(value);
    };

    return (
        <div style={{ padding: '10px 10px' }}>
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image imgRef={imgRef} size="S150" url={src} errImg={noneImage(sex)} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}
            <div style={{ textAlign: 'start' }}>
                <SearchbarWithDropdownV2
                    style={{ margin: '25px 0' }}
                    list={[
                        { id: 1, title: '휴대폰번호', value: 1 },
                        { id: 2, title: '이름', value: 2 },
                        { id: 3, title: '닉네임', value: 3 },
                        { id: 4, title: '회원ID', value: 4 },
                    ]}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                    onChangeSubmit={(e) => {
                        e.preventDefault();
                        if (searchType === 1) {
                            setSearchText(searchText.replace(/-/g, ''));
                        }
                        ref?.current?.refresh();
                    }}
                    searchText={searchType === 1 ? searchText.replace(/-/g, '') : searchText}
                    onChangeInput={(e) => {
                        setSearchText(e.target.value);
                    }}
                    research={ref}
                    setSearchText={setSearchText}
                />
            </div>
            <div style={{ overflow: 'auto' }}>
                <TableFilter
                    param={param}
                    setParam={setParam}
                    topFilterLists={[
                        {
                            filterText: '신고횟수',
                            orderLists: [
                                { name: '3회 미만', width: 50, type: 1 },
                                { name: '3회 이상', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.reportCount = type;
                                else delete obj.reportCount;
                                setParam(obj);
                            },
                            orderbyType: param.reportCount,
                        },
                        {
                            filterText: '순서정렬 필터',
                            orderLists: [
                                { name: '이름', width: 25, type: 1 },
                                { name: '닉네임', width: 35, type: 2 },
                                { name: '나이', width: 25, type: 3 },
                                { name: '생년월일', width: 45, type: 4 },
                                { name: '가입일', width: 35, type: 5 },
                                { name: '한달 내 접속자', width: 80, type: 6 },
                                { name: '프로필 사진 최신', width: 90, type: 7 },
                                { name: '카메라 인증 최신', width: 90, type: 8 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.orderbyType = type; // setOrderbyType(type);
                                else delete obj.orderbyType;
                                setParam(obj);
                            },
                            orderbyType: param.orderbyType,
                        },
                    ]}
                    filterLists={userTableFilter}
                    research={ref}
                />
            </div>
            <MainTable
                scrollWidth={175}
                toggleBlur={true}
                buttonComponents={({ checkItems, checkDatas }) => {
                    setCheckItems(checkItems);
                    setCheckAllData(checkDatas);
                }}
                ref={ref}
                tableRef={tableRef}
                loadingText={'회원 리스트를 불러오고 있습니다.'}
                headerData={userTableFilter}
                checkID={'id'}
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item, key) => {
                    if (key !== 'profile_image_list' && key !== 'auth_image') {
                        return () => onClickUser(item);
                    }
                }}
                adminCheck={(item) => adminUsers.includes(item.id) && { color: 'rgb(255, 16, 125)' }}
                tbodyRenderItem={(key, value, item) => {
                    const redText = (text) => <span style={{ color: 'red' }}>{text}</span>;
                    if (key === 'nickname') return contentFilter(value, 10);
                    if (key === 'sex') return sexFilter(value);
                    if (key === 'kakao_yn') return !value ? '휴대폰' : <span style={{ color: '#a4a42c' }}>카카오</span>;
                    if (key === 'report_count') return !value ? '0회' : `${value}회`;
                    if (key === 'recommend_yn') return value === 1 ? '공개' : redText('비공개');
                    if (key === 'avail_yn' || key === 'blocked_yn') return availCheck(item.blocked_yn, item.avail_yn);
                    if (key === 'user_created_at') return dateFilter(value);
                    if (key === 'recent_login_datetime') return dateFilter(value);
                    if (key === 'profile_photo_auth_yn') return profilePhotoAuth(value);
                    if (key === 'profile_image_list')
                        return (
                            <span onClick={() => onClickImage(item, value[0])}>
                                <Image size="S80" url={value[0]} errImg={noneImage(item.sex)} width={80} height={80} alt="회원 이미지" style={{ borderRadius: '12%', paddingTop: '4%' }} />
                            </span>
                        );
                    if (key === 'auth_image')
                        return (
                            <span onClick={() => onClickImage(item, value)}>
                                <Image size="S80" url={value} errImg={noneImage(item.sex)} width={80} height={80} alt="인증 이미지" style={{ borderRadius: '12%', paddingTop: '4%' }} />
                            </span>
                        );
                    return value;
                }}
            />
        </div>
    );
};

export default UserLists;
