import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 설문 주제 불러오기
export const GET_SURVEY_LISTS_REQUEST = 'GET_SURVEY_LISTS_REQUEST';
export const GET_SURVEY_LISTS_SUCCESS = 'GET_SURVEY_LISTS_SUCCESS';
export const GET_SURVEY_LISTS_FAILURE = 'GET_SURVEY_LISTS_FAILURE';
// 질문 리스트 불러오기
export const GET_SURVEY_QUESTION_LISTS_REQUEST = 'GET_SURVEY_QUESTION_LISTS_REQUEST';
export const GET_SURVEY_QUESTION_LISTS_SUCCESS = 'GET_SURVEY_QUESTION_LISTS_SUCCESS';
export const GET_SURVEY_QUESTION_LISTS_FAILURE = 'GET_SURVEY_QUESTION_LISTS_FAILURE';
// 응답자 수
export const GET_QUESTION_ANSWER_COUNT_REQUEST = 'GET_QUESTION_ANSWER_COUNT_REQUEST';
export const GET_QUESTION_ANSWER_COUNT_SUCCESS = 'GET_QUESTION_ANSWER_COUNT_SUCCESS';
export const GET_QUESTION_ANSWER_COUNT_FAILURE = 'GET_QUESTION_ANSWER_COUNT_FAILURE';
// 응답자 결과요약
export const GET_QUESTION_ANSWER_SUMMARIZE_REQUEST = 'GET_QUESTION_ANSWER_SUMMARIZE_REQUEST';
export const GET_QUESTION_ANSWER_SUMMARIZE_SUCCESS = 'GET_QUESTION_ANSWER_SUMMARIZE_SUCCESS';
export const GET_QUESTION_ANSWER_SUMMARIZE_FAILURE = 'GET_QUESTION_ANSWER_SUMMARIZE_FAILURE';
// 응답자 결과요약
export const GET_QUESTION_DETAIL_RESULT_REQUEST = 'GET_QUESTION_DETAIL_RESULT_REQUEST';
export const GET_QUESTION_DETAIL_RESULT_SUCCESS = 'GET_QUESTION_DETAIL_RESULT_SUCCESS';
export const GET_QUESTION_DETAIL_RESULT_FAILURE = 'GET_QUESTION_DETAIL_RESULT_FAILURE';
// 설문 참여자 조회
export const GET_PARTICIPANT_LISTS_REQUEST = 'GET_PARTICIPANT_LISTS_REQUEST';
export const GET_PARTICIPANT_LISTS_SUCCESS = 'GET_PARTICIPANT_LISTS_SUCCESS';
export const GET_PARTICIPANT_LISTS_FAILURE = 'GET_PARTICIPANT_LISTS_FAILURE';
// 회원 설문 결과 상세조회
export const GET_DETAIL_USER_ANSWER_REQUEST = 'GET_DETAIL_USER_ANSWER_REQUEST';
export const GET_DETAIL_USER_ANSWER_SUCCESS = 'GET_DETAIL_USER_ANSWER_SUCCESS';
export const GET_DETAIL_USER_ANSWER_FAILURE = 'GET_DETAIL_USER_ANSWER_FAILURE';

// 상태 초기화
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    surveyLists: [], // 설문 주제 데이터
    questionLists: [], // 질문 리스트 데이터
    answerCount: null, // 응답자 수
    answerUsers: [], // 응답 > 결과 참여자 목록
    answerSummarize: [], // 응답 > 결과 요약(통계)
    questionDetailResult: [], // 응답 > 질문, 상세결과
    participantLists: [], // 설문 참여자 조회
    userAnswerDetail: [], // 회원 설문 결과 상세조회
    // 설문 주제 조회
    getSurveyLoading: false,
    getSurveyDone: false,
    getSurveyError: null,
    // 질문 리스트 조회
    getQuestionLoading: false,
    getQuestionDone: false,
    getQuestionError: null,
    // 응답자 수
    getAnswerCountLoading: false,
    getAnswerCountDone: false,
    getAnswerCountError: null,
    // 응답 > 결과 요약(통계)
    getAnswerSummarizeLoading: false,
    getAnswerSummarizeDone: false,
    getAnswerSummarizeError: null,
    // 응답 > 질문, 상세결과
    getQuestionDetailResultLoading: false,
    getQuestionDetailResultDone: false,
    getQuestionDetailResultError: null,
    // 설문 참여자 조회
    getQuestionParticipantListsLoading: false,
    getQuestionParticipantListsDone: false,
    getQuestionParticipantListsError: null,
    // 회원 설문 결과 상세조회
    getUserAnswerDetailListsLoading: false,
    getUserAnswerDetailListsDone: false,
    getUserAnswerDetailListsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 설문 주제 리스트 불러오기
export const requestGetSurveyLists = (input) => async (dispatch) => {
    dispatch({
        type: GET_SURVEY_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSurvey`, { params: input });
        dispatch({
            type: GET_SURVEY_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SURVEY_LISTS_FAILURE,
            error: error,
        });
    }
};

// 질문 리스트 가져오기
export const requestGetQuestionLists = (survey_id) => async (dispatch) => {
    dispatch({
        type: GET_SURVEY_QUESTION_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSurvey/question?survey_id=${survey_id}`);
        dispatch({
            type: GET_SURVEY_QUESTION_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_SURVEY_QUESTION_LISTS_FAILURE,
            error: error,
        });
    }
};

// 응답자 수 확인
export const requestGetAnswerUsersCount = (survey_id) => async (dispatch) => {
    dispatch({
        type: GET_QUESTION_ANSWER_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSurvey/answer/count?survey_id=${survey_id}`);
        dispatch({
            type: GET_QUESTION_ANSWER_COUNT_SUCCESS,
            payload: response.data.count,
        });
    } catch (error) {
        dispatch({
            type: GET_QUESTION_ANSWER_COUNT_FAILURE,
            error: error,
        });
    }
};

// 설문관리 상세조회 > 응답 > 요약
export const getResponseSummarize = (survey_id) => async (dispatch) => {
    dispatch({
        type: GET_QUESTION_ANSWER_SUMMARIZE_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSurvey/answer/summarize?survey_id=${survey_id}`);
        dispatch({
            type: GET_QUESTION_ANSWER_SUMMARIZE_SUCCESS,
            joinUsers: response.data.join_users,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_QUESTION_ANSWER_SUMMARIZE_FAILURE,
            error: error,
        });
    }
};

// 응답 > 질문, 질문 상세 결과 조회
export const getQuestionDetailResult = (question_id) => async (dispatch) => {
    dispatch({
        type: GET_QUESTION_DETAIL_RESULT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSurvey/answer/question?question_id=${question_id}`);
        dispatch({
            type: GET_QUESTION_DETAIL_RESULT_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_QUESTION_DETAIL_RESULT_FAILURE,
            error: error,
        });
    }
};

// 설문상세 > 응답 > 개별보기, 답변한 회원 목록
export const getSurveyParticipantLists = (survey_id) => async (dispatch) => {
    dispatch({
        type: GET_PARTICIPANT_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSurvey/answer/detail?survey_id=${survey_id}`);
        dispatch({
            type: GET_PARTICIPANT_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_PARTICIPANT_LISTS_FAILURE,
            error: error,
        });
    }
};

// 설문상세 > 응답 > 개별보기, 회원 답변 상세조회
export const getSurveyParticipantAnswerDetail = (survey_id, participant_id) => async (dispatch) => {
    let _survey_id = parseInt(survey_id);
    let _participant_id = parseInt(participant_id);

    dispatch({
        type: GET_DETAIL_USER_ANSWER_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminSurvey/answer/detail/participant?survey_id=${_survey_id}&participant_id=${_participant_id}`);
        dispatch({
            type: GET_DETAIL_USER_ANSWER_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_DETAIL_USER_ANSWER_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const getSurveyReducer = handleActions(
    {
        // 설문 주제 현황 조회
        [GET_SURVEY_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getSurveyLoading = true;
                draft.getSurveyDone = false;
                draft.getSurveyError = null;
            }),
        [GET_SURVEY_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getSurveyLoading = action.payload ? false : true;
                draft.surveyLists = action.payload;
                draft.getSurveyDone = action.payload ? true : false;
            }),
        [GET_SURVEY_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getSurveyLoading = false;
                draft.getSurveyError = action.error;
            }),

        // 질문 리스트 조회
        [GET_SURVEY_QUESTION_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionLoading = true;
                draft.getQuestionDone = false;
                draft.getQuestionError = null;
            }),
        [GET_SURVEY_QUESTION_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionLoading = action.payload ? false : true;
                draft.questionLists = action.payload;
                draft.getQuestionDone = action.payload ? true : false;
            }),
        [GET_SURVEY_QUESTION_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionLoading = false;
                draft.getQuestionError = action.error;
            }),

        // 응답자 수 조회
        [GET_QUESTION_ANSWER_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getAnswerCountLoading = true;
                draft.getAnswerCountDone = false;
                draft.getAnswerCountError = null;
            }),
        [GET_QUESTION_ANSWER_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getAnswerCountLoading = action.payload ? false : true;
                draft.answerCount = action.payload;
                draft.getAnswerCountDone = action.payload ? true : false;
            }),
        [GET_QUESTION_ANSWER_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getAnswerCountLoading = false;
                draft.getAnswerCountError = action.error;
            }),

        // 응답자 수 조회
        [GET_QUESTION_ANSWER_SUMMARIZE_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getAnswerSummarizeLoading = true;
                draft.getAnswerSummarizeDone = false;
                draft.getAnswerSummarizeError = null;
            }),
        [GET_QUESTION_ANSWER_SUMMARIZE_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getAnswerSummarizeLoading = action.payload ? false : true;
                draft.answerUsers = action.joinUsers;
                draft.answerSummarize = action.payload;
                draft.getAnswerSummarizeDone = action.payload ? true : false;
            }),
        [GET_QUESTION_ANSWER_SUMMARIZE_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getAnswerSummarizeLoading = false;
                draft.getAnswerSummarizeError = action.error;
            }),

        // 응답 > 질문, 상세결과
        [GET_QUESTION_DETAIL_RESULT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionDetailResultLoading = true;
                draft.getQuestionDetailResultDone = false;
                draft.getQuestionDetailResultError = null;
            }),
        [GET_QUESTION_DETAIL_RESULT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionDetailResultLoading = action.payload ? false : true;
                draft.questionDetailResult = action.payload;
                draft.getQuestionDetailResultDone = action.payload ? true : false;
            }),
        [GET_QUESTION_DETAIL_RESULT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionDetailResultLoading = false;
                draft.getQuestionDetailResultError = action.error;
            }),

        // 설문상세 > 응답 > 개별보기, 회원 답변 상세조회
        [GET_PARTICIPANT_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionParticipantListsLoading = true;
                draft.getQuestionParticipantListsDone = false;
                draft.getQuestionParticipantListsError = null;
            }),
        [GET_PARTICIPANT_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionParticipantListsLoading = action.payload ? false : true;
                draft.participantLists = action.payload;
                draft.getQuestionParticipantListsDone = action.payload ? true : false;
            }),
        [GET_PARTICIPANT_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getQuestionParticipantListsLoading = false;
                draft.getQuestionParticipantListsError = action.error;
            }),

        // 회원 설문 결과 상세조회
        [GET_DETAIL_USER_ANSWER_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAnswerDetailListsLoading = true;
                draft.getUserAnswerDetailListsDone = false;
                draft.getUserAnswerDetailListsError = null;
            }),
        [GET_DETAIL_USER_ANSWER_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAnswerDetailListsLoading = action.payload ? false : true;
                draft.userAnswerDetail = action.payload;
                draft.getUserAnswerDetailListsDone = action.payload ? true : false;
            }),
        [GET_DETAIL_USER_ANSWER_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserAnswerDetailListsLoading = false;
                draft.getUserAnswerDetailListsError = action.error;
            }),
    },
    initialState,
);

export default getSurveyReducer;
