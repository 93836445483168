export const rgb_229_229_229 = "rgb(229, 229, 229)";
export const rgb_121_121_121 = "rgb(121, 121, 121)";
export const rgb_0_0_0 = "rgb(0, 0, 0)";
export const rgba_0_0_0_0d1 = "rgb(0, 0, 0, 0.1)";
export const rgb_131_131_131 = "rgb(211,211,211)";
export const rgb_210_210_210 = "rgb(210, 210, 210)";
export const rgb_255_255_255 = "rgb(255, 255, 255)";
export const rgb_66_139_247 = "rgb(66, 139, 247)";
export const rgba_66_139_247_0d1 = "rgba(66, 139, 247, 0.1)";
export const rgba_66_139_247_0d2 = "rgba(66, 139, 247, 0.2)";
export const rgba_66_139_247_0d05 = "rgba(66, 139, 247, 0.05)";
export const rgb_205_205_205 = "rgb(205, 205, 205)";
export const rgb_35_40_45 = "rgb(35, 40, 35)";
export const rgb_171_171_171 = "rgb(171, 171, 171)";
export const rgb_255_16_125 = "rgb(255, 16, 125)";
export const rgba_255_16_125_0d3 = "rgba(255,16,125,0.3)";
export const rgb_112_112_112 = "rgb(112, 112, 112)";
export const rgba_112_112_112d1 = "rgb(112, 112, 112,0.1)";
export const rgb_48_94_255 = "rgb(48, 94, 255)";
export const rgb_46_46_46 = "rgb(46, 46, 46)";
export const rgb_216_216_216 = "rgb(216, 216, 216)";
export const rgb_153_204_255 = "rgb(153, 204, 255)";
export const rgb_85_210_41 = "rgb(85,210,41)";
export const rgb_250_251_255 = "rgb(250,251,255)";
