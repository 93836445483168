import React, { useCallback, useRef, useState } from 'react';

// components
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import MainTable from '../../designComponents/tables/MainTable';
import { contentsListsTableFilter } from '../../components/MockData/ContentsListsTableFilter';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { checkReadFilter, contentFilter, contentTypeFilter, dateFilter } from '../../utils/textFilter';
import { Image } from '../../designComponents/imageboxs/Image';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import ContentDetailModal from '../../designComponents/modals/ContentDetailModal';

import { MainButton } from '../../designComponents/buttons/Buttons';
import TableFilter from '../../designComponents/filters/TableFilter';
import { getGatheringContentsListRequest } from '../../api/main/gathering/GatheringContentsRequest';
import DraggableImageGrid from '../../GroupComponent/Grid/DraggableImageGrid';

/** 게시글 조회 페이지 */
const GatheringContents = () => {
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({
        orderType: 1,
        orderByType: 1,
    });
    const [contentsInfo, setContentsInfo] = useState({});

    const [contentId, setContentId] = useState(0);
    const [contentDetailModalToggle, setContentDetailModalToggle] = useState(false);
    const [startCount, setStartCount] = useState('');
    const [endCount, setEndCount] = useState('');
    const [open, setOpen] = useState(false);
    const [rendering, setRendering] = useState(false);

    const ref = useRef(null);
    const tableRef = useRef(null);

    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            startCount: startCount,
            endCount: endCount,
            onlyFreeContents: open,
            ...param,
        };
        const response = await getGatheringContentsListRequest(input);
        return response.data;
    };

    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            startCount: startCount,
            endCount: endCount,
            onlyFreeContents: open,
            ...param,
        };
        const response = await getGatheringContentsListRequest(input);
        return response.all_cnt;
    };

    const onClickDeleteContents = async (checkItems = [], setCheckItems) => {
        if (checkItems.length) {
            if (window.confirm('선택된 게시글을 삭제하시겠습니까?')) {
                const response = await RefreshCheckApi.patch(`/adminGathering/contents/delete`, {
                    contents_id_lists: checkItems,
                });
                if (response.status === 200) {
                    alert(response.data.message);
                    setCheckItems([]);
                    setRendering((prev) => !prev);
                }
            }
        } else {
            alert('선택된 게시글이 존재하지 않습니다.');
        }
    };

    const freeContentsFilter = {
        key: 'love_room',
        value: '나이대',
        filter: [
            { key: 527, value: '50대' },
            { key: 526, value: '60대' },
            { key: 525, value: '70대' },
        ],
    };

    const onChangeStartCount = (e) => {
        setStartCount(e.target.value);
    };

    const onChangeEndCount = (e) => {
        setEndCount(e.target.value);
    };

    return (
        <Wrapper02>
            {contentDetailModalToggle && (
                <ContentDetailModal
                    id={contentId}
                    contentsInfo={contentsInfo}
                    setToggle={setContentDetailModalToggle}
                    onClickReSearch={() => {
                        ref?.current?.refresh();
                        ref?.current?.refresh();
                    }}
                />
            )}
            <MainPageTitle text={'게시글'} />
            <SearchbarWithDropdown
                style={{ margin: '45px 0 5px 0' }}
                list={[
                    { id: 1, title: '게시글 ID', value: 1 },
                    { id: 2, title: '제목', value: 2 },
                    { id: 3, title: '게시글 내용', value: 3 },
                    { id: 4, title: '작성자 이름', value: 4 },
                    { id: 5, title: '작성자 닉네임', value: 5 },
                    { id: 6, title: '모임명', value: 6 },
                    { id: 7, title: '댓글 작성자 닉네임', value: 7 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />
            {/*<LookCountSearchbar text={'조회수'} onSubmit={(e) => e.preventDefault()} onChangeStart={onChangeStartCount} onChangeEnd={onChangeEndCount} onClickCallAPI={() => callApi} />*/}

            <TableFilter
                param={param}
                setParam={setParam}
                wrapperStyle={{ width: '95%' }}
                topFilterLists={[
                    {
                        filterText: '정렬 기준',
                        orderLists: [{ name: '작성일', width: 50, type: 1 }],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderByType = type;
                            else delete obj.orderByType;
                            setParam(obj);
                        },
                        orderbyType: param.orderByType,
                    },

                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림차순', width: 50, type: 1 },
                            { name: '오름차순', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderType = type;
                            else delete obj.orderType;
                            setParam(obj);
                        },
                        orderbyType: param.orderType,
                    },
                    {
                        filterText: '게시글 유형',
                        orderLists: [
                            { name: '첫 인사', width: 50, type: 4 },
                            { name: '일반', width: 50, type: 1 },
                            { name: '공지', width: 50, type: 3 },
                            { name: '후기', width: 50, type: 5 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.contetnsType = type;
                            else delete obj.contetnsType;
                            setParam(obj);
                        },
                        orderbyType: param.contetnsType,
                    },
                    {
                        filterText: '자유 게시판',
                        orderLists: [
                            { name: '5 학년', width: 50, type: 1 },
                            { name: '6 학년', width: 50, type: 2 },
                            { name: '7 학년', width: 50, type: 3 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.freeGatheringType = type;
                            else delete obj.freeGatheringType;
                            setParam(obj);
                        },
                        orderbyType: param.freeGatheringType,
                    },
                    {
                        filterText: '노츌 여부',
                        orderLists: [
                            { name: '노출', width: 50, type: 1 },
                            { name: '미 노출', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.blurType = type;
                            else delete obj.blurType;
                            setParam(obj);
                        },
                        orderbyType: param.blurType,
                    },
                    {
                        filterText: '삭제 여부',
                        orderLists: [{ name: '삭제', width: 50, type: 2 }],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.deleteType = type;
                            else delete obj.deleteType;
                            setParam(obj);
                        },
                        orderbyType: param.deleteType,
                    },
                ]}
                filterLists={[]}
                research={ref}
            />

            <MainTable
                ref={ref}
                rendering={rendering}
                buttonComponents={({ checkItems, setCheckItems }) => (
                    <FlexMainWrapper style={{ justifyContent: 'end', alignItems: 'center' }}>
                        <TopSideButton leftOn={false} middleOn={false} text01={'삭제'} onClickDelete={() => onClickDeleteContents(checkItems, setCheckItems)} />
                    </FlexMainWrapper>
                )}
                filterKey={'interest_code'}
                filterItem={(item) => <MainButton text={item.value} onClick={() => {}} />}
                tableStyle={{ width: '95%' }}
                tableRef={tableRef}
                loadingText={'게시글 리스트를 불러오고 있습니다.'}
                headerData={contentsListsTableFilter}
                checkID={'id'}
                param={param}
                setParam={setParam}
                toggleBlur={true}
                callApi={callApi}
                noneDataText={'게시글이 존재하지 않습니다.'}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {
                    // 임시 수정
                    item.content_id = item.id;

                    setContentsInfo(item);
                    setContentId(item.id);
                    setContentDetailModalToggle(true);
                }}
                tbodyRenderItem={(key, value, data) => {
                    const loveRoom = [525, 526, 527];
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'deleted_at') return checkReadFilter(value ? 0 : 1, '정상', '삭제');
                    if (key === 'content_type') return contentTypeFilter(value, data.gathering_id);
                    if (key === 'content_title') {
                        if (loveRoom.includes(data.gathering_id)) return contentFilter(data.content, 10);
                        return contentFilter(value, 10);
                    }
                    if (key === 'content') return contentFilter(value);
                    if (key === 'blur_yn') return checkReadFilter(value ? 0 : 1, 'ON', 'OFF');
                    if (key === 'look_cnt') return value ? `${value}회` : '0회';
                    if (key === 'comment_cnt') return value ? `${value}개` : '0개';
                    if (key === 'gathering_name') return contentFilter(value, 15);
                    if (key === 'empathy_cnt') return `${value || 0}개`;
                    if (key === 'contents_image_url') return value ? <Image url={value} /> : <span style={{ color: '#CDCDCD' }}>이미지 없음</span>;
                    return value;
                }}
            />
        </Wrapper02>
    );
};

export default React.memo(GatheringContents);
