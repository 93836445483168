import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 90vh;
    min-width: 1200px;

    #label {
        display: none;
    }
`;

export const MainRightSideTitle = styled.div`
    display: flex;
    align-items: start;
    gap: 20px;

    font-size: 26px;
    font-family: noto_sans_kr_bold;
    font-weight: ${(props) => {
        if (props.buttomToggle) {
            return '600';
        }
    }};
    text-decoration: ${(props) => {
        if (props.buttomToggle) {
            return 'underline';
        }
    }};

    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 5%;

    padding: 0 3%;
    width: 80%;

    @media (max-width: 912px) {
        flex-direction: column;

        .pay-info {
            margin-bottom: 60%;
        }
    }
`;

export const InfoBox = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: start;

    width: 100%;
    min-width: 375px;

    #label {
        display: none;
    }
`;

export const Line = styled.div`
    width: 1px;
    height: 60vh;

    background-color: #428bf763;

    @media (max-width: 912px) {
        width: 100%;
        height: 1px;

        margin: 5% 0;
    }
`;

export const Title = styled.h1`
    font-size: 15px;
    font-weight: bold;
    margin: 15px 0;
`;

export const DetailInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const DetailInfoLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 3px;

    margin: 7px 0;
`;

export const SubTitle = styled.h3`
    font-size: 13px;
    font-weight: 600;
    color: #797979;

    width: 120px;
    margin: 0;
`;

export const Value = styled.h4`
    font-size: 13px;
    font-weight: 500;

    margin: 0;
`;

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 3px;
`;

export const Input = styled.input`
    outline: none;
    border: 1px solid #428bf763; // #428bf7;

    width: 100%;
    height: 23px;

    padding: 1px 5px;

    ::-webkit-inner-spin-button {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
`;

export const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;

    margin: 3%;

    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;

    @media (max-width: 912px) {
        position: absolute;
        bottom: 0;
        left: 35px;

        width: 100%;
        margin: 10px;
    }

    .cancel {
        border: 1px solid #f74242;
        background-color: #fff;
        color: #f74242;
    }
    .cancel:hover {
        background-color: #fff2f7;
    }
    .cancel:active {
        background-color: #fff;
    }

    .save {
        border: ${(props) => (props.color ? '1px solid #428bf7' : '1px solid rgb(255, 16, 125)')};
        background-color: ${(props) => (props.color ? '#428bf7' : 'rgb(255, 16, 125)')};
        color: #fff;
    }
    .save:hover {
        background-color: ${(props) => (props.color ? '#267af7' : 'rgb(208 7 99)')};
    }

    .back {
        border: 1px solid #19c300;
        background-color: #19c300;
        color: #fff;
    }
    .back:hover {
        background-color: #149800;
    }
    .back:active {
        background-color: #19c300;
    }
`;

export const Button = styled.button`
    cursor: pointer;

    width: 70px;
    padding: 5px 0;
`;

export const Select = styled.select`
    cursor: pointer;
    outline: none;

    border: 1px solid #428bf763;

    width: 120px;
    height: 27px;
`;
