import React, { useState, useEffect } from 'react';

// styled-components
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetGatheringScheduleRankData, fetchGetGatheringScheduleRankAllCountData } from '../../../data_system/redux/modules/clickPercentage/clickPercentage_reducer';

// components
import Pagination from '../../../design_system/components/admin_table/pagination/Pagination';
import LoadingPage from '../../../pages/loading/LoadingPage';

// 일정순위 테이블
function ScheduleRankTable() {
    const dispatch = useDispatch();

    // useSelector
    const { gatheringScheduleRank, loadGatheringScheduleRankLoading, gatheringScheduleRankAllCount, loadGatheringScheduleRankAllCountLoading } = useSelector((state) => state.clickPercentageReducer);

    // state
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);

    /**************************** useEffect ****************************/

    // 데이터 요청
    useEffect(() => {
        let tmp = {
            page: page,
            size: parseInt(size),
        };
        dispatch(fetchGetGatheringScheduleRankData(tmp));
        dispatch(fetchGetGatheringScheduleRankAllCountData(tmp));
    }, [dispatch, page, size]);

    /**************************** function ****************************/

    // 모임 상태 필터
    const statusFilter = (deltedAt, blur) => {
        switch (true) {
            case Boolean(deltedAt):
                return <span style={{ color: 'red', fontWeight: '600' }}>삭제</span>;
            case blur === 0:
                return '정상';
            case blur === 1:
                return <span style={{ color: 'red', fontWeight: '600' }}>OFF</span>;
            default:
                return '정상';
        }
    };

    /****************************** html ******************************/
    return (
        <div>
            <MorePostButton>
                <Select onChange={(e) => setSize(e.target.value)} value={size} id="pet-select">
                    <option value="10">10개씩 보기</option>
                    <option value="15">15개씩 보기</option>
                    <option value="20">20개씩 보기</option>
                    <option value="25">25개씩 보기</option>
                    <option value="30">30개씩 보기</option>
                    <option value="35">35개씩 보기</option>
                    <option value="40">40개씩 보기</option>
                    <option value="45">45개씩 보기</option>
                    <option value="50">50개씩 보기</option>
                </Select>
            </MorePostButton>

            <table className="table table--min" cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th>순위</th>
                        <th>상태</th>
                        <th>개최자</th>
                        <th>모임명</th>
                        <th>일정명</th>
                        <th>일정일시</th>
                        <th>신청/결제완료</th>
                        <th>조회수</th>
                    </tr>
                </thead>

                {loadGatheringScheduleRankLoading ? (
                    <tr>
                        <td colSpan={5}>
                            <LoadingPage />
                        </td>
                    </tr>
                ) : (
                    <tbody>
                        {gatheringScheduleRank?.data?.length > 0 &&
                            gatheringScheduleRank?.data?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.ranking}</td>
                                        <td>{statusFilter(item.deleted_at, item.blur_yn)}</td>
                                        <td>{item.owner_name || <span style={{ color: 'gray' }}>정보없음</span>}</td>
                                        <td>{item.gathering_name || <span style={{ color: 'gray' }}>정보없음</span>}</td>
                                        <td>{item.title || <span style={{ color: 'gray' }}>정보없음</span>}</td>
                                        <td>{`${item.start_date} ${item.start_time}` || <span style={{ color: 'gray' }}>정보없음</span>}</td>
                                        <td>{item.count || 0}명</td>
                                        <td>{item.look_cnt || 0}회</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                )}
            </table>

            <div>
                {loadGatheringScheduleRankAllCountLoading ? (
                    <PaginationLoadingText>
                        <LoadingOutlined style={{ fontSize: '25px' }} />
                        <div style={{ fontSize: '14px' }}>페이지네이션에 적용할 전체 데이터 수를 불러오는 중입니다...</div>
                    </PaginationLoadingText>
                ) : (
                    <>
                        {gatheringScheduleRankAllCount?.cnt >= 10 ? (
                            <Pagination totalPages={gatheringScheduleRankAllCount?.cnt ? Math.round(gatheringScheduleRankAllCount?.cnt / 10) : 10} limit={10} page={page} setPage={setPage} />
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
}

export default ScheduleRankTable;

/****************************** styled ******************************/

export const MorePostButton = styled.div`
    display: flex;
    justify-content: start;

    margin: 7px 0;
`;

export const Select = styled.select`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid black;
    outline: none;

    padding: 5.3px;
`;

export const PaginationLoadingText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
`;
