import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";

const SendAlarmTalkTypeTab= ({setSendType}) => {
	const tabList = [
		{
			title: '전체 전송내역',
			key: 0
		},{
			title: '예약 전송내역',
			key: 1
		},
	]


	return (
		<BootStrapTabs tabList={tabList} setValue={setSendType}></BootStrapTabs>
	)

}


export default SendAlarmTalkTypeTab