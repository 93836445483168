import {
    deleteAdministrators,
    getAdministrators,
    getAdministratorsSearch,
    postAdministrators,
} from "../../../../../communication_system/axios_apis/axios_apis";
import CreateDeleteRequestThunk from "../../redux_functions/create_delete_request_thunk";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import {DELETE_ADMINISTRATORS, GET_ADMINISTRATORS, POST_ADMINISTRATORS,} from "./administrators_table_type";

export const getAdministratorsAsync = createGetRequestThunk(
  GET_ADMINISTRATORS,
  getAdministrators
);

export const getAdministratorsSearchAsync = createGetRequestThunk(
  GET_ADMINISTRATORS,
  getAdministratorsSearch
);

export const postAdministratorsAsync = createPostRequestThunk(
  POST_ADMINISTRATORS,
  postAdministrators
);

export const deleteAdministratorsAsync = CreateDeleteRequestThunk(
  DELETE_ADMINISTRATORS,
  deleteAdministrators
);
