import { CloseOutlined } from '@ant-design/icons';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

export const ImageModal = ({ onClickClose, ref, src, toggle, alt = '크게보기 이미지' }) => {
    // regacy-context api
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    // 이미지 모달 외부 클릭 시 Modal창 닫힘
    const handleClickOutside = (event) => {
        if (!ref) onClickClose();
    };

    // 클릭 감지
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                if (user_info_modal_state.modalVisible) {
                    user_info_modal_actions.setModalVisible(true);
                    onClickClose();
                }
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle, user_info_modal_state]);

    return (
        <ImageModalWrapper>
            <CustomCloseOutlined onClick={onClickClose} />
            <ModalImage src={src} alt={alt} ref={ref} />
        </ImageModalWrapper>
    );
};

const ImageModalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    background-color: #000000c9;
    text-align: center;

    width: 100%;
    height: 100vh;
`;

const CustomCloseOutlined = styled(CloseOutlined)`
    position: fixed;
    top: 0;
    right: 0;

    padding: 30px;

    color: #fff;
    font-size: 35px;
    font-weight: bold;
`;

const ModalImage = styled.img`
    width: 500px;
    border-radius: 5%;
`;
