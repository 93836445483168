import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { MText, SText } from '../text/ContentText';

const listType = { id: 1, title: '휴대폰번호', value: 1 };

/** 드롭다운 리스트 바 */
const MainDropdownV2 = ({ list = [listType], index = 0, resetThenSet = () => {} }) => {
    const [isListOpen, setIsListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState(index ? list[index]['title'] : list[0]['title']);
    const mainDropdownRef = useRef(null);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (!dropdownRef.current) return;
        else if (dropdownRef && !dropdownRef.current.contains(event.target)) {
            mainDropdownRef.current.contains(event.target) || setIsListOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const toggleList = useCallback(() => {
        setIsListOpen(produce((isListOpen) => !isListOpen));
    }, []);

    const selectItem = useCallback(
        (item) => {
            const { title } = item;
            setHeaderTitle(title);
            setIsListOpen(false);
            resetThenSet(item);
        },
        [resetThenSet],
    );

    const dropdownboxSt = {
        cursor: 'pointer',
        width: '120px',
        justifyContent: 'space-between',
        border: '1px solid rgb(237 238 241)',
        borderTopLeftRadius: '2px',
        borderBottomLeftRadius: '2px',
        padding: '7px',
        fontWeight: 400,
        userSelect: 'none',
    };

    return (
        <FlexMainWrapper styled={{ flexDirection: 'column', position: 'relative', backgroundColor: '#fff' }}>
            <div ref={mainDropdownRef}>
                <FlexMainWrapper type="button" onClick={toggleList} isListOpen={isListOpen} style={dropdownboxSt}>
                    <HeaderTitle>{headerTitle}</HeaderTitle>
                    {isListOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </FlexMainWrapper>
            </div>
            {isListOpen ? (
                <FlexMainWrapper style={{ flexDirection: 'column', gap: 0, zIndex: 1, position: 'absolute', top: '31px' }}>
                    <div ref={dropdownRef}>
                        {list.map((item, index) => (
                            <Hover>
                                <SText key={index} text={item.title} style={{ ...dropdownboxSt, borderTopWidth: index !== 0 ? 0 : 1 }} onClick={() => selectItem(item)} />
                            </Hover>
                        ))}
                    </div>
                </FlexMainWrapper>
            ) : null}
        </FlexMainWrapper>
    );
};

export default MainDropdownV2;

const HeaderTitle = styled.div`
    font-family: noto_sans_kr_medium;
    font-size: 13px;
    color: rgb(35 40 45);
    word-break: break-all;
    background-color: #fff;
`;

const Hover = styled.div`
    width: 100%;
    background-color: #fff;
    &:hover {
        background-color: rgb(237 238 241);
    }
`;
