import {
    getActivityPaymentsDetail,
    getActivityPaymentsDetailRefund,
    putActivityPaymentsDetailAdminMemo,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import {
    GET_ACTIVITY_PAYMENTS_DETAIL,
    POST_ACTIVITY_PAYMENTS_DETAIL_REFUND,
    PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO,
} from "./activityPayments_detail_type";

export const getActivityPaymentsDetailAsync = createGetRequestThunk(
  GET_ACTIVITY_PAYMENTS_DETAIL,
  getActivityPaymentsDetail
);

export const putActivityPaymentsDetailAdminMemoAsync = createPutRequestThunk(
  PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO,
  putActivityPaymentsDetailAdminMemo
);

export const getActivityPaymentsDetailRefundAsync = createGetRequestThunk(
  POST_ACTIVITY_PAYMENTS_DETAIL_REFUND,
  getActivityPaymentsDetailRefund
);
