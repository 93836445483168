import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import React, { useCallback, useRef, useState } from 'react';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import TableFilter from '../../designComponents/filters/TableFilter';
import { SmsPageUserListsFilter } from '../../dataSystems/PageFilters/SmsPageUserListsFilter';
import MainTable from '../../designComponents/tables/MainTable';
import { sexFilter } from '../../utils/textFilter';

const SendSMSUserTable = ({ setPhoneUserList, sendPhoneUser }) => {
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({ platform: 1 });

    const ref = useRef(null);
    const tableRef = useRef(null);

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 1,
            ...param,
        };
        const response = await RefreshCheckApi.post(`/adminSms/list`, input);
        return response.data.data.body;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            limit: 1,
            ...param,
        };
        const response = await RefreshCheckApi.post(`/adminSms/list`, input);
        return response.data.data.all_count;
    };

    return (
        <>
            <div style={{ overflow: 'auto' }}>
                <SearchbarWithDropdown
                    list={[
                        { id: 1, title: '휴대폰번호', value: 1 },
                        { id: 2, title: '닉네임', value: 2 },
                        { id: 3, title: '이름', value: 3 },
                        { id: 4, title: '모임ID', value: 4 },
                    ]}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                    onChangeSubmit={(e) => {
                        e.preventDefault();
                        ref?.current?.refresh();
                    }}
                    searchText={searchText}
                    onChangeInput={(e) => {
                        setSearchText(e.target.value);
                    }}
                />

                <TableFilter
                    param={param}
                    setParam={setParam}
                    wrapperStyle={{ width: '95%' }}
                    topFilterLists={[
                        {
                            filterText: '플랫폼',
                            orderLists: [
                                { name: '전체 유저', width: 50, type: 1 },
                                { name: '시럽', width: 50, type: 2 },
                                { name: '시놀', width: 50, type: 3 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.platform = type;
                                else delete obj.platform;
                                setParam(obj);
                            },
                            orderbyType: param.platform,
                        },

                        {
                            filterText: '나이',
                            orderLists: [
                                { name: '오름차순', width: 50, type: 1 },
                                { name: '내림차순', width: 50, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.age = type;
                                else delete obj.age;
                                setParam(obj);
                            },
                            orderbyType: param.age,
                        },
                        {
                            filterText: '접속일자 기준',
                            orderLists: [
                                { name: '1개월 이내', width: 70, type: 1 },
                                { name: '2개월 이내', width: 70, type: 2 },
                                { name: '6개월 이내', width: 70, type: 3 },
                                { name: '6개월 이상', width: 70, type: 4 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.loginDate = type;
                                else delete obj.loginDate;
                                setParam(obj);
                            },
                            orderbyType: param.loginDate,
                        },
                        {
                            filterText: '성별',
                            orderLists: [
                                { name: '남자', width: 70, type: 1 },
                                { name: '여자', width: 70, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.sex = type;
                                else delete obj.sex;
                                setParam(obj);
                            },
                            orderbyType: param.sex,
                        },
                        {
                            filterText: '마케팅 여부',
                            orderLists: [
                                { name: 'Y', width: 70, type: 1 },
                                { name: 'N', width: 70, type: 2 },
                            ],
                            onChange: (e, type) => {
                                let obj = { ...param };
                                if (e.target.checked) obj.marketing = type;
                                else delete obj.marketing;
                                setParam(obj);
                            },
                            orderbyType: param.marketing,
                        },
                    ]}
                    filterLists={SmsPageUserListsFilter}
                    research={ref}
                />
            </div>
            <MainTable
                toggleBlur={true}
                onChangeCheck={(checkDatas) => {
                    const phoneNumberList = checkDatas.map((data) => data.phone_no);
                    setPhoneUserList([...phoneNumberList]);
                }}
                ref={ref}
                tableRef={tableRef}
                loadingText={'회원 리스트를 불러오고 있습니다.'}
                headerData={SmsPageUserListsFilter}
                checkID={'phone_no'} // phone_no
                param={param}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item) => () => {}}
                adminCheck={(item) => {}}
                tbodyRenderItem={(key, value) => {
                    if (key === 'sex') return sexFilter(value);
                    if (key === 'marketing_info_receive_agree_yn') return sexFilter(value === 1 ? 1 : 2, 'Y', 'N');
                    return value;
                }}
            />
        </>
    );
};

export default SendSMSUserTable;
