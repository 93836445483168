import styled from 'styled-components';

export const DropdownFrame = styled.div`
    position: relative;
`;

export const MainTitleBtn = styled.button`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 140px;
    height: 35px;

    background-color: rgb(255 255 255);

    border-width: 1px;
    border-color: rgba(66, 139, 247, 0.2);
    border-bottom-width: 1px;

    word-break: break-all;
`;

export const HeaderTitle = styled.div`
    /* margin-right: 29px; */
    font-family: noto_sans_kr_medium;
    font-size: 13px;
    color: rgb(35 40 45);
    word-break: break-all;
`;

export const HeaderInput = styled.input`
    font-family: noto_sans_kr_medium;
    font-size: 13px;

    border: 0px solid black;

    width: 100%;
    height: 100%;
`;
export const DropdownListFrame = styled.div`
    position: absolute;
    z-index: 1; // 999;

    display: flex;
    flex-direction: column;

    border: 1px solid rgba(66, 139, 247, 0.2);
    border-top-width: 0px;
    box-sizing: border-box;

    width: 100%; // 131px
    max-height: 439px;

    background-color: rgb(255, 255, 255);
    overflow: auto;
`;

export const MenuItemBtn = styled.button`
    cursor: pointer;

    width: 100%; // 131px
    height: 35px;

    flex-wrap: wrap;

    background-color: rgb(255, 255, 255);
    border-width: 0px;

    font-family: noto_sans_kr_medium;
    font-size: 13px;
    color: rgb(35, 40, 35);
    text-align: left;

    word-break: break-all;
    border-block: 1px solid rgba(66, 139, 247, 0.2);

    :hover {
        background-color: rgb(245 241 241);
    }
`;
