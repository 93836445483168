import axios from "axios";
import {main_server_ip} from "../../communication_ips";
import {refresh_check, refresh_error_handle} from "./refresh_check";

const RefreshCheckApiWithFormdata = axios.create({
  baseURL: main_server_ip,
  timeout: 10000,
  params: {},
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

RefreshCheckApiWithFormdata.interceptors.request.use(
  refresh_check,
  refresh_error_handle
);

export default RefreshCheckApiWithFormdata;
