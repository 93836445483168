export const UseSyrupListsTableFilter = [
    {
        key: 'id',
        value: '사용 내역 ID',
    },
    {
        key: 'created_at',
        value: '사용일시',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'reason_type',
        value: '사용상태',
        filter: [
            {
                key: 0,
                value: '충전',
            },
            {
                key: 1,
                value: '사용',
            },
            {
                key: 2,
                value: '환불',
            },
        ],
    },
    {
        key: 'reason_detail',
        value: '사용내용',
        filter: [
            {
                key: '충전',
                value: '충전',
            },
            {
                key: '사진 인증 완료된 단짝',
                value: '사진 인증 완료된 단짝',
            },
            {
                key: '프로필 작성률 높은 단짝',
                value: '프로필 작성률 높은 단짝',
            },
            {
                key: '최근 가입한 단짝',
                value: '최근 가입한 단짝',
            },
            {
                key: '편지를 많이 받은 단짝',
                value: '편지를 많이 받은 단짝',
            },
            {
                key: '편지 사용',
                value: '편지 사용',
            },
            {
                key: '평점이 높은 단짝',
                value: '평점이 높은 단짝',
            },
            {
                key: '지나간 인연 보기',
                value: '지나간 인연 보기',
            },
            {
                key: '환불',
                value: '환불',
            },
            {
                key: '충전(관리자)',
                value: '충전(관리자)',
            },
            {
                key: '빼빼로데이 이벤트 시럽 증정',
                value: '빼빼로데이 이벤트 시럽 증정',
            },
            {
                key: '빼빼로데이 이벤트 시럽 소멸',
                value: '빼빼로데이 이벤트 시럽 소멸',
            },
            {
                key: '편지 사용(최초 사용 무료)',
                value: '편지 사용(최초 사용 무료)',
            },
            {
                key: '편지 반환',
                value: '편지 반환',
            },
            {
                key: '관리자 추가',
                value: '관리자 추가',
            },
            {
                key: '관리자 지급',
                value: '관리자 지급',
            },
            {
                key: '관리자 충전 ',
                value: '관리자 충전 ',
            },
            {
                key: '크리스마스 이벤트 시럽 증정',
                value: '크리스마스 이벤트 시럽 증정',
            },
        ],
    },
    {
        key: 'sinor_love',
        value: '사용된 시럽 갯수',
    },
    {
        key: 'user_sex',
        value: '성별',
    },
    {
        key: 'user_name',
        value: '회원 이름',
    },
    {
        key: 'user_nick_name',
        value: '회원 닉네임',
    },
    {
        key: 'user_phone_no',
        value: '휴대폰 번호',
    },
];
