import produce from "immer";
import {handleActions} from "redux-actions";
import {
    GET_ACTIVITY_PAYMENTS_DETAIL_FAILURE,
    GET_ACTIVITY_PAYMENTS_DETAIL_SUCCESS,
    POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_FAILURE,
    POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_SUCCESS,
    PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE,
    PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS,
} from "./activityPayments_detail/activityPayments_detail_type";
import {
    GET_ACTIVITY_PAYMENTS_FAILURE,
    GET_ACTIVITY_PAYMENTS_SUCCESS,
} from "./activityPayments_table/activityPayments_table_type";

const initialState = {
  activityPayments: null,
  activityPaymentsDetail: null,
};

const activityPaymentsReducer = handleActions(
  {
    [GET_ACTIVITY_PAYMENTS_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.activityPayments = action.payload;
      }),
    [GET_ACTIVITY_PAYMENTS_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [GET_ACTIVITY_PAYMENTS_DETAIL_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.activityPaymentsDetail = action.payload;
      }),
    [GET_ACTIVITY_PAYMENTS_DETAIL_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        for (let i = 0; i < draft.activityPayments.length; i++) {
          if (
            draft.activityPayments[i]["id"] ===
            action.payload["activity_pay_id"]
          ) {
            draft.activityPayments[i]["data"][7]["data"] =
              action.payload["admin_memo"];
          }
        }
      }),
    [PUT_ACTIVITY_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
    [POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        for (let i = 0; i < draft.activityPayments.length; i++) {
          if (draft.activityPayments[i]["id"] === action.payload["pay_id"]) {
            draft.activityPayments[i]["data"][1]["data"] = 4;
          }
        }
      }),
    [POST_ACTIVITY_PAYMENTS_DETAIL_REFUND_FAILURE]: (state, action) =>
      produce(state, (draft) => {}),
  },
  initialState
);

export default activityPaymentsReducer;
