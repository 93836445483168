import React, { useState } from 'react';
import { moneyFilter } from '../../utils/textFilter';
import PayInfoDetailModal from '../SyrupComponents/PayInfoDetailModal';
import DesignTable from '../../designComponents/tables/DesignTable';

/** 전체 결제 정보 컴포넌트 */
const AllPayInfo = ({ data, allCount, page, setPage }) => {
    const [detailToggle, setDetailToggle] = useState([false, 0]);
    return (
        <DesignTable
            marginTop={0.5}
            onToggle={detailToggle[0]}
            modalComponent={<PayInfoDetailModal payInfoDetailToggle={detailToggle} setToggle={setDetailToggle} />}
            header={[
                { title: '일시', width: 130 },
                { title: '상태', width: 90 },
                { title: '상품', width: 50 },
                { title: '수단', width: 70 },
                { title: '포인트', width: 75 },
                { title: '실 결제금액', width: 75 },
                { title: '누적 결제금액', width: 75 },
            ]}
            onClick={(item) => setDetailToggle([true, item.pay_id])}
            body={[
                { key: 'pay_created_at', width: 130 },
                { key: 'pay_status', width: 90 },
                { key: 'contents', width: 50 },
                { key: 'pay_type', width: 70 },
                { key: 'point', width: 75 },
                { key: 'amount', width: 75 },
                { key: 'accumulate_amount', width: 75 },
            ]}
            data={data}
            allCount={allCount}
            page={page}
            setPage={setPage}
            bodyRederItem={(key, value) => {
                if (key === 'point') return moneyFilter(value) + '원';
                if (key === 'amount') return moneyFilter(value) + '원';
                if (key === 'accumulate_amount') return moneyFilter(value) + '원';
                return value;
            }}
        />
    );
};

export default AllPayInfo;
