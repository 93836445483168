import React, { useState, useEffect } from 'react';

// custom hooks
import useInput from '../../hook/useInput';

// styled-components
import {
  ImageInfoWrapper,
  InfoWrapper,
  SubTitle,
  InputWrapper,
  RadioInput,
  InputForValue,
  AddressInput,
  Input,
  Textarea,
  TextAndBtnWrapper,
  CheckBox,
  TextDirectionWrapper,
  SearchAddressBrn,
} from '../styles/ActivityDetailModalStyled';

// 놀이정보 기입
function ActivityDetailModalActivityInfoEntry({ activityDetail, setInfoDetailParam }) {
  // state
  const [routin, setRoutin] = useState(0); // 구분(정기, 단기)
  const [dateCount, onChangeDateCount, setDateCount] = useInput(0); // 총 횟수
  const [price, onChangePrice, setPrice] = useInput(0); // 가격
  const [discount, onChangeDiscount, setDiscount] = useInput(0); // 할인율
  const [visit, setVisit] = useState(0); // 방문서비스(예, 아니오)
  const [address, onChangeAddress, setAddress] = useInput(''); // 주소
  const [infoUrl, setInfoUrl] = useState(''); // 상세설명-파일첨부
  const [explanation, onChangeExplanation, setExplanation] = useInput(''); // 상세설명
  const [material, onChangeMaterial, setMaterial] = useInput(''); // 준비물
  const [notice, onChangeNotice, setNotice] = useInput(''); // 유의사항
  const [refund, onChangeRefund, setRefund] = useInput(''); // 환불정책
  /******************** useEffect ********************/

  // 데이터 초기값 상태 setting
  useEffect(() => {
    setRoutin(Number(activityDetail?.routin_yn)); // 구분(정기, 단기)
    setDateCount(Number(activityDetail?.routin_count)); // 총 횟수
    setPrice(Number(activityDetail?.price)); // 가격
    setDiscount(Number(activityDetail?.discount_rating)); // 할인율
    setVisit(activityDetail?.visit_yn); // 방문서비스
    setAddress(activityDetail?.visit_yn); // 주소
    setInfoUrl(activityDetail?.info_url); // 상세설명-파일첨부
    setExplanation(activityDetail?.explanation); // 상세설명
    setMaterial(activityDetail?.material); // 준비물
    setNotice(activityDetail?.notice); // 유의사항
    setRefund(activityDetail?.refund); // 환불정책
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDetail]);

  useEffect(() => {
    setInfoDetailParam({
      routin_yn: routin, // 구분(정기, 단기)
      date_count: dateCount, // 총 횟수
      price: price, // 가격
      charge: discount, // 할인율
      visit_yn: visit, // 방문 서비스
      address: address, // 주소
      explanation: explanation, // 상세설명
      notice: notice, // 유의사항
      material: material, // 준비물
      refund: refund, // 환불정책
    });
  }, [routin, dateCount, price, discount, visit, address, explanation, notice, material, refund, setInfoDetailParam]);

  /******************** function ********************/

  // 구분 변경
  const onChangeRoutin = () => {
    if (routin === 0) {
      setRoutin(1);
    } else if (routin === 1) {
      setRoutin(0);
    }
  };

  // 방문서비스 체크 변경
  const onChangeVisit = () => {
    if (visit === 0) {
      setVisit(1);
    } else if (visit === 1) {
      setVisit(0);
    }
  };

  /******************** html ********************/
  return (
    <>
      <InfoWrapper id="category">
        <SubTitle>구분</SubTitle>
        <InputWrapper>
          <div className="check-box">
            <RadioInput type="checkbox" checked={routin ? true : false} onChange={onChangeRoutin} />
            <span>정기</span>
          </div>
          <div className="check-box">
            <RadioInput type="checkbox" checked={routin ? false : true} onChange={onChangeRoutin} />
            <span>단기</span>
          </div>
        </InputWrapper>
      </InfoWrapper>

      <InfoWrapper id="category">
        <SubTitle>총 횟수</SubTitle>
        <div style={{ width: '200px' }}>
          <InputForValue
            type="number"
            placeholder="미입력시 1회로 산정됩니다."
            value={dateCount}
            onChange={onChangeDateCount}
          />
          <span>회</span>
        </div>
      </InfoWrapper>

      <InfoWrapper id="category">
        <SubTitle>가격</SubTitle>
        <div style={{ width: '200px' }}>
          <InputForValue type="number" value={price} onChange={onChangePrice} />
          <span>원</span>
        </div>
      </InfoWrapper>

      <InfoWrapper id="category">
        <SubTitle>할인율</SubTitle>
        <div style={{ width: '200px' }}>
          <InputForValue type="number" value={discount} onChange={onChangeDiscount} />
          <span>%</span>
        </div>
      </InfoWrapper>

      <InfoWrapper id="category">
        <SubTitle>방문 서비스</SubTitle>
        <InputWrapper>
          <div className="check-box">
            <RadioInput type="checkbox" checked={visit ? true : false} onChange={onChangeVisit} />
            <span>예</span>
          </div>
          <div className="check-box">
            <RadioInput type="checkbox" checked={visit ? false : true} onChange={onChangeVisit} />
            <span>아니오</span>
          </div>
        </InputWrapper>
      </InfoWrapper>

      <ImageInfoWrapper id="category">
        <SubTitle>주소</SubTitle>
        <TextDirectionWrapper>
          <TextAndBtnWrapper>
            <AddressInput type="text" value={address} disabled readOnly />
            <SearchAddressBrn>주소검색</SearchAddressBrn>
          </TextAndBtnWrapper>

          <div style={{ width: '98%' }}>
            <Input type="text" onChange={onChangeAddress} />
          </div>

          <TextAndBtnWrapper>
            <CheckBox type="checkbox" />
            <span>호스트 기본 주소 가져오기</span>
          </TextAndBtnWrapper>
        </TextDirectionWrapper>
      </ImageInfoWrapper>

      <ImageInfoWrapper id="category">
        <SubTitle>상세 설명</SubTitle>
        <TextDirectionWrapper>
          <TextAndBtnWrapper>
            <AddressInput type="text" placeholder="이미지를 업로드 해주세요." disabled readOnly />
            <SearchAddressBrn>파일첨부</SearchAddressBrn>
          </TextAndBtnWrapper>

          <div style={{ width: '98%' }}>
            <Input type="text" value={infoUrl} />
          </div>

          <div>
            <Textarea value={explanation} onChange={onChangeExplanation} />
          </div>
        </TextDirectionWrapper>
      </ImageInfoWrapper>

      <InfoWrapper id="category">
        <SubTitle>준비물</SubTitle>

        <Input
          type="text"
          placeholder="준비물을 입력해 주세요."
          style={{ width: '175px' }}
          value={material}
          onChange={onChangeMaterial}
        />
      </InfoWrapper>

      <InfoWrapper id="category">
        <SubTitle>유의사항</SubTitle>

        <Input
          type="text"
          placeholder="유의사항을 입력해 주세요."
          style={{ width: '175px' }}
          value={notice}
          onChange={onChangeNotice}
        />
      </InfoWrapper>

      <ImageInfoWrapper id="category">
        <SubTitle>환불 정책</SubTitle>
        <div>
          <Textarea value={refund} onChange={onChangeRefund} />
        </div>
      </ImageInfoWrapper>
    </>
  );
}

export default ActivityDetailModalActivityInfoEntry;
