import React, { useEffect, useRef, useState, useCallback } from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { RESET_STATE } from '../../data_system/redux/modules/gathering.reducer/gatheringContents.reducer';

import CommentRetry from '../../components/Common/CommentRetry';
import { DefaultModal, ImageModal } from './ModalComponent';
import { InputLine } from '../inputs/InputLine';
import { TitleCheckboxInput } from '../checkboxs/CheckboxInput';
import { FlexColumnWithTextArea, FlexTwoText } from '../text/TextMapping';
import FlexMainWrapper, { FormWrapper } from '../wrappers/FlexMainWrapper';
import { Image } from '../../designComponents/imageboxs/Image';
import { LText, MText, SText } from '../text/ContentText';
import { MainButton } from '../buttons/Buttons';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import DraggableImageGrid from '../../GroupComponent/Grid/DraggableImageGrid';
import CommentTile from '../../EventComponents/Gathering/CommentTile';

/** 게시글 상세보기 */
const ContentDetailModal = ({ id, contentsInfo, setToggle, onClickReSearch = () => {} }) => {
    const dispatch = useDispatch();
    const { updateGatheringContentsDone, deleteCommentMessage } = useSelector((state) => state.getGatheringContentsReducer);
    const [updateToggle, setUpdateToggle] = useState(false);

    const [contentTitle, setContentTitle] = useState('');
    const [blurYn, setBlurYn] = useState(0);
    const [contentText, setContentText] = useState('');
    const [imageLists, setImageLists] = useState([]);
    const [rendering, setRendering] = useState(false);

    const [content, setContent] = useState({});
    const [comments, setComments] = useState([]);
    const [commentParam, setCommentParam] = useState({});
    const [imageParam, setImageParam] = useState({ toggle: false });

    // 데이터 불러오는 함수
    const fetchData = useCallback(async () => {
        try {
            const content = await RefreshCheckApi.get(`/adminReport/content/detail?id=${id}`);
            setContent(content.data.data);

            const comments = await RefreshCheckApi.get(`/adminGathering/comments?contents_id=${id}`);
            setComments(comments.data.data);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    // 게시글 상세정보 불러오기
    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [updateGatheringContentsDone, id, rendering, fetchData]);

    // ESC로 창 닫기
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                closeModal();
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 데이터 상태적용
    useEffect(() => {
        if (JSON.stringify(content) !== '{}') {
            setContentTitle(content?.content_title);
            setBlurYn(content?.blur_yn);
            setContentText(content?.content);
            setImageLists(content?.content_image_lists);
        }
    }, [content]);

    // 모달창 닫기
    const closeModal = () => {
        setToggle(false);
    };

    // 제목변경
    const onChangeTitle = (e) => {
        setContentTitle(e.target.value);
    };

    // 노출여부 수정
    const onChangeBlur = (num) => () => {
        setBlurYn(num);
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickImageUpload = useCallback(() => {
        imageInput.current.click();
    }, []);

    // 이미지 업로드
    const onChangeImages = async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });
        const response = await RefreshCheckApi.post(`/adminGathering/contents/images`, imageFormData);
        if (response.status === 200) {
            setImageLists([...imageLists, ...response.data]);
        }
    };

    // 수정 저장하기
    const updateData = async () => {
        try {
            let param = {
                id: content?.content_id,
                contentTitle: contentTitle,
                blurYn: blurYn ? true : false,
                contentText: contentText,
                imageLists: imageLists,
            };
            if (window.confirm('수정한 내용을 저장하시겠습니까?')) {
                const response = await RefreshCheckApi.patch(`/adminGathering/contents/update`, param);
                if (response.status === 200) {
                    fetchData();
                    setUpdateToggle(false);
                    onClickReSearch();
                    alert('수정한 내용이 저장되었습니다.');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    // 댓글 삭제하기
    const onClickDeleteComment = (comment_id) => {
        setComments((pre) => pre.filter((e) => e.id !== comment_id));
    };

    const loveRoomYn = (gatheringId) => {
        const boolean = [525, 526, 527].includes(parseInt(gatheringId));
        return boolean;
    };

    return (
        <DefaultModal
            title={'게시글 상세 조회'}
            boxStyle={{ width: '100vh', height: '95vh' }}
            bodyWidth={85}
            xIcon={true}
            onClickClose={() => setToggle(false)}
            saveOn={true}
            closeOn={true}
            saveText={'저장'}
            cancelText={'닫기'}
            onClickSave={updateData}
            onClickBottomClose={closeModal}
        >
            {imageParam.toggle && (
                <ImageModal xIcon onClickClose={() => setImageParam({ toggle: false })}>
                    <Image url={imageParam.src} width={500} height={500} />
                </ImageModal>
            )}
            <div id="content" style={{ margin: '20px' }}>
                <FlexMainWrapper style={{ alignItems: 'start', justifyContent: 'center', gap: 0 }}>
                    <FlexMainWrapper style={{ flexDirection: 'column', gap: 0, width: '50%' }}>
                        <InputLine wrapperStyle={{ width: '80%' }} titleStyle={{ width: '120px', textAlign: 'start' }} title={'게시글 ID'} value={content?.content_id} disabled={true} />
                        {loveRoomYn(content?.gathering_id) ? null : (
                            <InputLine
                                wrapperStyle={{ width: '80%' }}
                                titleStyle={{ width: '120px', textAlign: 'start' }}
                                title={'제목'}
                                value={contentTitle}
                                placeholder={contentTitle || content?.content_title}
                                onChange={onChangeTitle}
                            />
                        )}
                        <InputLine
                            wrapperStyle={{ width: '80%' }}
                            titleStyle={{ width: '120px', textAlign: 'start' }}
                            title={'작성된 모임'}
                            value={`${content?.gathering_name} (${content?.gathering_id})`}
                            placeholder={content?.gathering_name}
                            disabled={true}
                        />
                        <InputLine
                            wrapperStyle={{ width: '80%' }}
                            titleStyle={{ width: '120px', textAlign: 'start' }}
                            title={'작성자명'}
                            value={`${content?.user_name} (${content?.user_id})`}
                            disabled={true}
                        />
                        <InputLine wrapperStyle={{ width: '80%' }} titleStyle={{ width: '120px', textAlign: 'start' }} title={'작성자 닉네임'} value={content?.nick_name} disabled={true} />
                    </FlexMainWrapper>

                    <FlexMainWrapper style={{ flexDirection: 'column', gap: 0, width: '50%' }}>
                        <TitleCheckboxInput
                            wrapperStyle={{ justifyContent: 'start', margin: '10px' }}
                            gap={23}
                            titleStyle={{ color: '#797979' }}
                            exposure
                            text={'노출 상태'}
                            checked01={!blurYn}
                            onChange01={onChangeBlur(0)}
                            checked02={blurYn}
                            onChange02={onChangeBlur(1)}
                        />
                        <FlexTwoText
                            wrapperStyle={{ width: '50%', margin: '15px 10px' }}
                            text01Style={{ fontWeight: 400, width: '70px', textAlign: 'start' }}
                            text01={'작성일'}
                            text02={moment(content?.created_at).format('YYYY-MM-DD HH:mm:ss')}
                        />
                        <FlexTwoText
                            wrapperStyle={{ width: '50%', margin: '15px 10px' }}
                            text01Style={{ fontWeight: 400, width: '70px', textAlign: 'start' }}
                            text01={'수정일'}
                            text02={moment(content?.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                        />
                        <FlexTwoText
                            wrapperStyle={{ width: '50%', margin: '15px 10px' }}
                            text01Style={{ fontWeight: 400, width: '70px', textAlign: 'start' }}
                            text01={'조회수'}
                            text02={`${content?.look_cnt || 0} 회`}
                        />
                        <FlexTwoText
                            wrapperStyle={{ width: '50%', margin: '15px 10px' }}
                            text01Style={{ fontWeight: 400, width: '70px', textAlign: 'start' }}
                            text01={'댓글수'}
                            text02={`${comments?.length || 0} 개`}
                        />
                    </FlexMainWrapper>
                </FlexMainWrapper>
                <div>
                    <FlexMainWrapper style={{ flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
                        <MText text={'이미지'} />
                        <MainButton width={null} height={null} onClick={onClickImageUpload} text={'이미지 추가'} />
                        <input
                            type="file"
                            multiple
                            hidden
                            ref={imageInput}
                            onChange={(e) => {
                                onChangeImages(e);
                            }}
                        />
                    </FlexMainWrapper>
                    <div style={{ marginTop: 16 }}>
                        <DraggableImageGrid data={imageLists} onImageChange={(data) => setImageLists(data)} />
                    </div>
                </div>
                <FlexColumnWithTextArea height={50} title={'내용'} value={contentText} placeholder={contentText} disabled={false} imgYn={true} onChange={(e) => setContentText(e.target.value)} />

                <FormWrapper style={{ backgroundColor: '#0000001c', padding: '0 10px', borderRadius: '10px' }}>
                    <FlexMainWrapper style={{ alignItems: 'center', gap: '0', width: '100%', justifyContent: 'start', margin: '15px 0' }}>
                        <InputLine
                            wrapperStyle={{ alignItems: 'center', gap: '10px', width: '100%', margin: 0 }}
                            titleStyle={{ width: 'auto' }}
                            inputStyle={{ borderTopWidth: '0px', borderLeftWidth: '0px', borderRightWidth: '0px' }}
                            title={
                                <Image url={'userPhoto/1_2023_09_20T01:33:19.901Z_temp_file_20230920_103317.jpg'} width={30} height={30} style={{ borderRadius: '50%', border: '1px solid #c7c7c7' }} />
                            }
                            placeholder={'댓글을 입력해주세요.'}
                            value={commentParam.comment || ''}
                            onChange={(e) => {
                                setCommentParam({ comment: e.target.value });
                            }}
                        />
                        <MainButton
                            width={80}
                            text="댓글 추가"
                            onClick={async () => {
                                try {
                                    if (!commentParam.comment) {
                                        return alert('작성된 댓글이 없습니다.');
                                    } else {
                                        const body = {
                                            contents_id: content.content_id,
                                            user_id: 1,
                                            comment: commentParam.comment,
                                            blur_yn: 0,
                                        };
                                        const response = await RefreshCheckApi.post(`/adminGathering/comments`, body);
                                        if (response.status === 200) {
                                            setCommentParam({});
                                            setRendering((prev) => !prev);
                                        }
                                    }
                                } catch (error) {
                                    console.error(error);
                                }
                            }}
                        />
                    </FlexMainWrapper>
                </FormWrapper>
                <FlexMainWrapper column>
                    {comments?.map((data, index) => {
                        return <CommentTile key={`content_comment_${index}`} data={data} onDelete={onClickDeleteComment} />;
                    })}
                </FlexMainWrapper>
            </div>
        </DefaultModal>
    );
};

export default ContentDetailModal;
