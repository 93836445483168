import {
    deleteAnnouncements,
    getAnnouncements,
    postAnnouncements,
} from "../../../../../communication_system/axios_apis/axios_apis";
import CreateDeleteRequestThunk from "../../redux_functions/create_delete_request_thunk";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPostRequestThunk from "../../redux_functions/create_post_request_thunk";
import {DELETE_ANNOUNCEMENTS, GET_ANNOUNCEMENTS, POST_ANNOUNCEMENTS,} from "./announcements_table_type";

export const getAnnouncementsAsync = createGetRequestThunk(
  GET_ANNOUNCEMENTS,
  getAnnouncements
);

export const postAnnouncementsAsync = createPostRequestThunk(
  POST_ANNOUNCEMENTS,
  postAnnouncements
);

export const deleteAnnouncementsAsync = CreateDeleteRequestThunk(
  DELETE_ANNOUNCEMENTS,
  deleteAnnouncements
);
