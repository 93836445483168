import React, { useCallback, useEffect, useRef, useState } from 'react';
import useInput from '../../hook/useInput';

// styled-components
import {
    postCodeStyle,
    CloseWindowBox,
    LogoImagePosition,
    LogoImage,
    SelectWrapper,
    Table,
    MainTitle,
    Title,
    Input,
    Select,
    CencelButton,
    CreateButton,
    FindFileButton,
    DirectInputBtn,
    LogoImageWrapper,
    InputWrapper,
    ButtomWrapper,
    HostNameBtnWrapper,
    HostNameBtnWrapperBottom,
} from '../../styles/productSt/addHostStyled';
import { CloseOutlined } from '@ant-design/icons';

// Daum Search Address API Module
import DaumPostcode from 'react-daum-postcode';

// axios
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_ip } from '../../communication_system/communication_ips';
import { main_server_image_ip } from '../../communication_system/communication_ips';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
    checkHostName, // 회사명 중복확인
    getHostNameRequest, // 회사명 리스트 조회
    getHostInformation, // 호스트 정보 조회
    addHost, // 호스트 추가
    updateHost, // 호스트 수정
} from '../../data_system/redux/modules/product_reducer/product.host_reducer';

// 판매자 정보 컴포넌트
function AddHostInfoComponent({ setMoreAddInfoToggle, setHostInfoParams }) {
    const dispatch = useDispatch();
    // office information
    const [directInputToggle, setDirectInputToggle] = useState(false); // 회사명 직접입력창 Toggle
    const [selectValue, setSelectValue] = useState(0); // 회사명 select
    const [hostOfficeName, setHostOfficeName] = useState(''); // 회사명
    // You have to check office name please change this state use to click some where button
    const [haveToCheckOfficeName, setHaveToCheckOfficeName] = useState(false);
    // image
    const [imagePaths, setImagePaths] = useState([]);
    // Daum Search Address State
    const [addressSearchToggle, setAddressSearchToggle] = useState(false); // 주소검색 Toggle
    const [address, setAddress] = useState(null); // 구주소, 현주소
    const [zonecode, setZonecode] = useState(null); // 우편번호
    // host-information input
    const [hostName, onChangeHostName, setHostName] = useInput(''); // 사업자명
    const [hostPhoneNum, onChangeHostPhoneNum, setHostPhoneNum] = useInput(''); // 사업자 전화번호
    const [hostBusinessNumber, onChangeHostBusinessNumber, setHostBusinessNumber] = useInput(''); // 사업자 등록번호
    const [addressDetail, onChangeAddressDetail, setAddressDetail] = useInput(''); // 상세주소
    // redux
    const { checkHostInfo, checkHostInfoDone, hostOfficeLists, hostInformationList, hostInformation } = useSelector((state) => state.productHostReducer);
    // useRef: 주소창 모달 타겟 설정
    const AddressDashMenu = useRef();

    /****************************** useEffect 구간 ******************************/
    useEffect(() => {
        setHostInfoParams(hostInformation);
    }, [hostInformation, setHostInfoParams]);

    // 회사명이 없으면 상품추가를 진행할 수 없다.
    useEffect(() => {
        if (hostOfficeName || selectValue) setMoreAddInfoToggle(true);
        else if (hostName || hostPhoneNum || hostBusinessNumber || addressDetail || address || imagePaths.path) setMoreAddInfoToggle(true);
        else setMoreAddInfoToggle(false);
    }, [setMoreAddInfoToggle, hostOfficeName, selectValue, hostName, hostPhoneNum, hostBusinessNumber, addressDetail, address, imagePaths]);

    // 외부 클릭시 modalCloseHandler 함수 호출
    useEffect(() => {
        window.addEventListener('click', modalCloseHandler);
        return () => {
            window.removeEventListener('click', modalCloseHandler);
        };
    });

    // 호스트 중복확인 - 호스트 이름이 있냐 vs 없냐
    useEffect(() => {
        if (checkHostInfo) {
            setHaveToCheckOfficeName(false);
        } else {
            setHaveToCheckOfficeName(true);
        }
    }, [checkHostInfo]);

    // 회사명 불러오기 (SELECT LISTS)
    useEffect(() => {
        dispatch(getHostNameRequest());
    }, [dispatch]);

    // 호스트 정보조회
    useEffect(() => {
        if (selectValue) {
            dispatch(getHostInformation(selectValue));
        }
    }, [selectValue, dispatch]);

    useEffect(() => {
        setHostOfficeName(hostInformationList?.name || '');
        setImagePaths(hostInformationList?.image || '');
        setAddress(hostInformationList?.address || '');
        setZonecode(hostInformationList?.zone_code || '');
        setHostName(hostInformationList?.owner || '');
        setHostPhoneNum(hostInformationList?.represent_number || '');
        setHostBusinessNumber(hostInformationList?.business_number || '');
        setAddressDetail(hostInformationList?.address_detail || '');
        setImagePaths({ filename: hostInformationList?.image, path: hostInformationList?.image } || []);
    }, [selectValue, hostInformationList, setHostOfficeName, setImagePaths, setAddress, setZonecode, setHostName, setHostPhoneNum, setHostBusinessNumber, setAddressDetail]);

    // 기업선택 초기화 시 상품정보 등록 X
    if (!hostInformationList) {
        setMoreAddInfoToggle(false);
    }

    /****************************** 명령 함수 구간 ******************************/

    // modal 외부 클릭 시 닫힘
    const modalCloseHandler = ({ target }) => {
        if (addressSearchToggle && !AddressDashMenu.current.contains(target)) {
            setAddressSearchToggle((prev) => !prev);
        }
    };

    // 회사명 선택 (select)
    const handleChangeSelect = (e) => {
        setSelectValue(e.target.value);
    };

    // 회사명 직접입력
    const onChangeHostOfficeName = (e) => {
        setHostOfficeName(e.target.value);
    };

    // 하단 추가입력 정보 토글
    const onClickDirectInputToggle = () => {
        setDirectInputToggle((prev) => !prev);
        setSelectValue(0);
        setHostOfficeName('');

        if (!directInputToggle) {
            dispatch(getHostInformation(0));
        }
    };

    // 이미지 current
    const imageInput = useRef(); // 타겟 이미지 클릭
    const onClickImageUpload = () => {
        imageInput.current.click();
    };

    // 이미지 업로드
    const onChangeImages = useCallback(async (e) => {
        if (e.target.files.length > 1) {
            alert('기업의 로고는 한장만 업로드 할 수 있습니다.');
        } else {
            const imageFormData = new FormData();
            [].forEach.call(e.target.files, (f) => {
                imageFormData.append('image', f);
            });

            requestServer(imageFormData);
        }
    }, []);

    // 이미지를 서버로 보낸다.
    const requestServer = async (FormData) => {
        const result = await RefreshCheckApi.post(main_server_ip + '/adminProduct/hostImage', FormData);

        setTimeout(() => {
            setImagePaths(result.data);
        }, 500);
    };

    // 주소검색창 닫기 버튼 함수
    const onClickCloseAddressSearchwindow = () => {
        onClickAddressSearchToggle();
    };

    // 주소검색 Toggle
    const onClickAddressSearchToggle = () => {
        setAddressSearchToggle((prev) => !prev);
    };

    // 주소 검색 및 입력
    const onCompletePost = (data) => {
        setAddress(data.roadAddress);
        setZonecode(data.zonecode);

        if (data) setAddressSearchToggle((prev) => !prev);
    };

    // 판매자 정보 입력 초기화
    const resetHostInfo = () => {
        const initValidate =
            Boolean(hostOfficeName) ||
            Boolean(selectValue) ||
            Boolean(hostName) ||
            Boolean(hostPhoneNum) ||
            Boolean(hostBusinessNumber) ||
            Boolean(addressDetail) ||
            Boolean(address) ||
            Boolean(imagePaths.path);

        if (initValidate) {
            let checkConfirm = window.confirm('판매자 정보를 초기화하면 입력한 상품정보가 초기화됩니다. \n 초기화를 진행하시겠습니까?');

            if (checkConfirm) {
                setSelectValue('');
                setHostOfficeName('');
                setImagePaths('');
                setAddress(null);
                setZonecode(null);
                setHostName('');
                setHostPhoneNum('');
                setHostBusinessNumber('');
                setAddressDetail('');
                setMoreAddInfoToggle(false);
                setImagePaths([]);
            }
        } else {
            window.confirm('입력된 호스트 정보가 없습니다.');
        }
    };

    // 판매자 정보 등록
    const onSubmitAddHostInfo = () => {
        if (hostInformationList) {
            return alert('이미 존재하는 호스트 정보입니다.');
        }

        const initValidate =
            Boolean(hostOfficeName || selectValue) &&
            Boolean(hostName) &&
            Boolean(hostPhoneNum) &&
            Boolean(hostBusinessNumber) &&
            Boolean(addressDetail) &&
            Boolean(address) &&
            Boolean(imagePaths.path);
        if (initValidate) {
            if (!haveToCheckOfficeName) {
                alert('회사명 중복확인 후 진행 가능합니다.');
            } else {
                // 호스트 정보 추가 request
                dispatch(
                    addHost({
                        hostOfficeName: hostOfficeName, // 회사명
                        hostName: hostName, // 사업자명
                        hostPhoneNum: hostPhoneNum, // 대표 전화번호
                        zonecode: zonecode, // 우편번호
                        address: address, // 주소
                        addressDetail: addressDetail, // 상세 주소
                        hostBusinessNumber: hostBusinessNumber, // 사업자 등록번호
                        imagePaths: imagePaths.path, // 로고 이미지
                    }),
                );
            }
        } else {
            switch (false) {
                case Boolean(hostOfficeName || selectValue):
                    window.confirm('회사명을 입력해주세요.');
                    break;
                case Boolean(hostName):
                    window.confirm('대표자 이름을 입력해주세요.');
                    break;
                case Boolean(hostPhoneNum):
                    window.confirm('대표 전화번호를 입력해주세요.');
                    break;
                case Boolean(hostBusinessNumber):
                    window.confirm('사업자 등록번호를 입력해주세요.');
                    break;
                case Boolean(addressDetail):
                    window.confirm('상세주소를 입력해주세요.');
                    break;
                case Boolean(address):
                    window.confirm('주소를 입력해주세요.');
                    break;
                case Boolean(imagePaths.path):
                    window.confirm('회사로고 이미지를 추가해주세요.');
                    break;
                default:
            }
        }
    };

    // 호스트 중복확인
    const onClickCheckHostName = () => {
        if (hostOfficeName) {
            dispatch(checkHostName(hostOfficeName));
        } else if (!directInputToggle) {
            alert('중복확인은 직접입력 시 가능합니다.');
        } else {
            alert('회사명을 입력하세요.');
        }
    };

    // 호스트정보 수정
    const onClickUpdateHost = () => {
        alert('기능 준비중 입니다.');
        dispatch(updateHost());
    };

    /****************************** HTML 구간 ******************************/
    return (
        <div id="add-host-info">
            <MainTitle>판매자정보</MainTitle>
            <div className="info_box-logo_image">
                <Table>
                    <LogoImagePosition>
                        {imagePaths && imagePaths.path ? (
                            <div>
                                <LogoImage src={main_server_image_ip + imagePaths.path} alt="회사이미지 로고" />
                            </div>
                        ) : null}
                    </LogoImagePosition>

                    <LogoImageWrapper>
                        <Title style={{ width: '119px' }}>회사명</Title>

                        <InputWrapper>
                            {directInputToggle ? (
                                <Input type="text" placeholder="회사명을 입력해주세요." onChange={onChangeHostOfficeName} value={hostOfficeName} disabled={hostInformationList} />
                            ) : (
                                <Select onChange={handleChangeSelect} value={selectValue}>
                                    <option value="0">기업선택</option>
                                    {hostOfficeLists?.map((list, index) => {
                                        return (
                                            <option key={index} value={list.id}>
                                                {list.name}
                                            </option>
                                        );
                                    })}
                                </Select>
                            )}
                            <FindFileButton type="button" onClick={onClickCheckHostName} disabled={hostInformationList}>
                                중복확인
                            </FindFileButton>
                        </InputWrapper>
                    </LogoImageWrapper>

                    <HostNameBtnWrapper>
                        <Title style={{ width: '120px' }} />
                        <HostNameBtnWrapperBottom>
                            <div className="check-result-text">
                                {directInputToggle && checkHostInfoDone ? (
                                    !checkHostInfo ? (
                                        <span style={{ color: 'blue' }}>사용 가능한 회사명입니다.</span>
                                    ) : (
                                        <span style={{ color: 'red' }}>이미 등록된 회사명입니다.</span>
                                    )
                                ) : null}
                            </div>

                            <DirectInputBtn onClick={onClickDirectInputToggle}>{directInputToggle ? '등록된 기업 선택하기' : '직접입력하기'}</DirectInputBtn>
                        </HostNameBtnWrapperBottom>
                    </HostNameBtnWrapper>

                    <SelectWrapper>
                        <Title>대표자 이름</Title>
                        <Input type="text" name="host-name" placeholder="대표자 이름" onChange={onChangeHostName} value={hostName} disabled={hostInformationList} />
                    </SelectWrapper>

                    <SelectWrapper>
                        <Title>대표 전화번호</Title>
                        <Input
                            type="tel"
                            name="host-phone"
                            placeholder="대표전화번호"
                            pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                            required
                            onChange={onChangeHostPhoneNum}
                            value={hostPhoneNum}
                            disabled={hostInformationList}
                        />
                    </SelectWrapper>

                    <SelectWrapper>
                        <Title>사업자 등록번호</Title>
                        <Input type="text" name="host-number" placeholder="사업자 등록번호" onChange={onChangeHostBusinessNumber} value={hostBusinessNumber} disabled={hostInformationList} />
                    </SelectWrapper>

                    <div id="address-line">
                        <div className="current-modal">
                            {addressSearchToggle && (
                                <div ref={AddressDashMenu}>
                                    <CloseWindowBox>
                                        <CloseOutlined onClick={onClickCloseAddressSearchwindow} />
                                    </CloseWindowBox>
                                    <DaumPostcode style={postCodeStyle} autoClose={false} onComplete={onCompletePost} />
                                </div>
                            )}
                        </div>

                        <LogoImageWrapper>
                            <Title style={{ width: '119px' }}>사업장 주소</Title>
                            <InputWrapper>
                                <Input
                                    type="text"
                                    name="host-zonecode"
                                    placeholder="우편번호"
                                    readOnly
                                    style={{ cursor: 'pointer' }}
                                    value={zonecode || ''}
                                    onClick={onClickAddressSearchToggle}
                                    disabled={hostInformationList}
                                />
                                <FindFileButton type="button" onClick={onClickAddressSearchToggle} disabled={hostInformationList}>
                                    주소검색
                                </FindFileButton>
                            </InputWrapper>
                        </LogoImageWrapper>

                        <SelectWrapper>
                            <Title />
                            <Input
                                type="text"
                                name="host-address"
                                placeholder="사업장 주소"
                                readOnly
                                style={{ cursor: 'pointer' }}
                                value={address || ''}
                                onClick={onClickAddressSearchToggle}
                                disabled={hostInformationList}
                            />
                        </SelectWrapper>

                        <SelectWrapper>
                            <Title />
                            <Input type="text" name="host-address-detail" placeholder="상세주소" onChange={onChangeAddressDetail} value={addressDetail} disabled={hostInformationList} />
                        </SelectWrapper>
                    </div>

                    <LogoImageWrapper>
                        <Title style={{ width: '119px' }}>회사로고</Title>
                        <InputWrapper>
                            <input type="file" name="host-logo-image" multiple hidden ref={imageInput} onChange={onChangeImages} />
                            <Input className="upload-name" value={imagePaths.path ? imagePaths.filename : '첨부파일'} placeholder="첨부파일" style={{ width: '100%' }} readOnly />
                            <FindFileButton type="button" onClick={onClickImageUpload} disabled={hostInformationList}>
                                파일찾기
                            </FindFileButton>
                        </InputWrapper>
                    </LogoImageWrapper>

                    <ButtomWrapper>
                        {hostInformationList ? (
                            <>
                                <CencelButton className="cencle" onClick={onClickUpdateHost}>
                                    판매자 정보 수정
                                </CencelButton>
                            </>
                        ) : (
                            <>
                                <CencelButton className="cencle" onClick={resetHostInfo}>
                                    판매자 정보 초기화
                                </CencelButton>
                                <CreateButton className="submit" onClick={onSubmitAddHostInfo}>
                                    판매자 정보 등록
                                </CreateButton>
                            </>
                        )}
                    </ButtomWrapper>
                </Table>
            </div>
        </div>
    );
}

export default AddHostInfoComponent;
