
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useEffect, useState } from "react";

const BootStrapTabs = ({tabList, setValue, defaultKey}) => {

	const [key, setKey] = useState(defaultKey || 0)

	useEffect(() => {
		setValue(Number(key))
	}, [key]);

	if(!tabList || tabList.length <=0){
		return (<span> 탭 리스트가 없습니다</span>)
	}

	return (
		<Tabs
			defaultActiveKey="0"
			id="uncontrolled-tab-example"
			className="mb-3"
			activeKey={key}
			onSelect={(k, e) => {

				setKey(k)
			}}
			style={{borderStyle: 'none', fontStyle: 'noto_sans_kr_medium', borderColor: 'none', }}  variant='underline'
		>

			{tabList.map((data)=> {
				return (
					<Tab eventKey={data.key} title={data.title}  > </Tab>
				)
			})}

		</Tabs>
	);

}

export default BootStrapTabs