import { useEffect, useState } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import {
    Header,
    Text,
    SchedulePayUserDetailModalWrapper,
    SchedulePayUserDetailModalBox,
    UserName,
    ModalBoxBody,
    InfoBox,
    InfoTitle,
    ColWrapper,
    Subtitle,
    SubInfo,
    Footer,
} from './SchedulePayUserDetailModal';

// BANK CODE
import { BANK_CODE } from '../../SubscribePayment/payBankCodeService';

// API
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import LoadingPage from '../../../pages/loading/LoadingPage';
import UserDetailModal from '../../UserComponents/UserDetailModal';

/** 계좌관리 상세정보 Modal */
function AccountModal({ id, setDetailToggle }) {
    const { isLoading, error, data } = useQuery('accountDetail', () => RefreshCheckApi.get(`/adminGathering/account/status/detail?gathering_account_history_id=${id}`).then((res) => res.data.data));
    const [userDetailToggle, setUserDetailToggle] = useState({});
    const [showImgToggle, setShowImgToggle] = useState([false, '']);
    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                setDetailToggle(false);
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /************************************************ function ************************************************/

    // 전화번호 필터
    const phoneNumFilter = (num) => {
        if (num) {
            return num
                .replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
                .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        } else {
            return <span style={{ color: 'gray' }}>정보없음</span>;
        }
    };

    // 입출상태 필터
    const statusFilter = (num) => {
        let _num = parseInt(num);

        switch (true) {
            case _num === 1:
                return <span>입금</span>;
            case _num === 2:
                return <span>참여 취소</span>;
            case _num === 3:
                return <span>출금</span>;
            default:
                return <span style={{ color: 'gray' }}>정보없음</span>;
        }
    };

    // 출금상태 필터
    const payoutFilter = (num) => {
        let _num = parseInt(num);

        switch (true) {
            case _num === 0:
                return <span>-</span>;
            case _num === 1:
                return <span>출금 신청(출금)</span>;
            case _num === 2:
                return <span>출금 완료</span>;
            case _num === 3:
                return <span>출금 신청 반려(입금)</span>;
            default:
                return <span style={{ color: 'gray' }}>정보없음</span>;
        }
    };

    // 날짜필터
    const dateFilter = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        } else {
            return <span style={{ color: 'gray' }}>정보없음</span>;
        }
    };

    // 금액필터
    const moneyFilter = (num) => {
        let _num = parseInt(num);

        if (_num) {
            return _num.toLocaleString('ko-KR') + ' 원';
        } else {
            return <span style={{ color: 'gray' }}>0원</span>;
        }
    };

    // 은행정보 필터
    const bankFilter = (code) => {
        let _code = parseInt(code);
        let result = '';

        BANK_CODE.forEach((data) => {
            let bankCode = parseInt(data.CODE1);

            if (Boolean(bankCode === _code)) {
                result = data.KOR;
            }
        });

        return result || '없음';
    };

    // 회원 상세 정보
    const onClickUserDetail = (user_id) => () => {
        setUserDetailToggle({ toggle: true, userId: user_id });
    };

    /** 이미지 확대 닫기 */
    const onClickCloseImage = () => {
        setShowImgToggle([false, '']);
    };

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /** 출금상태 필터 */
    const payoutStatusFilter = (status) => {
        switch (true) {
            case status === 1:
                return '신청 일시';
            case status === 2:
                return '출금 일시';
            default:
                return '결제 일시';
        }
    };

    /************************************************** html **************************************************/
    const role = data?.pay_out_status > 0; // 모임장
    if (isLoading) {
        return (
            <SchedulePayUserDetailModalWrapper>
                <SchedulePayUserDetailModalBox>
                    <Header>
                        <Text>상세정보</Text>
                        <Text style={{ cursor: 'pointer' }} onClick={() => setDetailToggle(false)}>
                            닫기
                        </Text>
                    </Header>

                    <LoadingPage text={'결제 상세정보를 불러오는 중입니다.'} />
                </SchedulePayUserDetailModalBox>
            </SchedulePayUserDetailModalWrapper>
        );
    } else if (error) {
        <SchedulePayUserDetailModalWrapper>
            <SchedulePayUserDetailModalBox>
                <Header>
                    <Text>상세정보</Text>
                    <Text style={{ cursor: 'pointer' }} onClick={() => setDetailToggle(false)}>
                        닫기
                    </Text>
                </Header>

                <LoadingPage text={error} />
            </SchedulePayUserDetailModalBox>
        </SchedulePayUserDetailModalWrapper>;
    } else {
        return (
            <>
                {userDetailToggle.toggle ? (
                    <UserDetailModal userId={userDetailToggle.userId} setToggle={setUserDetailToggle} />
                ) : (
                    <SchedulePayUserDetailModalWrapper>
                        {showImgToggle[0] ? (
                            <div>
                                <CloseImageWindowWrapper className="image-modal-window">
                                    <CloseOutlined onClick={onClickCloseImage} />
                                </CloseImageWindowWrapper>

                                <ShowImage sizes={700} src={showImgToggle[1]} alt="확대 이미지" onError={handleImgError} />
                            </div>
                        ) : (
                            <SchedulePayUserDetailModalBox>
                                <Header>
                                    <Text>상세정보</Text>
                                    <Text style={{ cursor: 'pointer' }} onClick={() => setDetailToggle(false)}>
                                        닫기
                                    </Text>
                                </Header>

                                <UserName className="user-name" onClick={onClickUserDetail(data.user_id)}>
                                    {data?.user_name || <span style={{ color: 'gray' }}>정보없음</span>}
                                </UserName>

                                <ModalBoxBody className="modal-body">
                                    <label id="label">계좌 정보</label>
                                    <InfoBox className="subscribe-info">
                                        <InfoTitle>결제 정보</InfoTitle>

                                        <ColWrapper>
                                            <Subtitle>결제내역ID</Subtitle>
                                            <SubInfo>{data?.gathering_account_history_id || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                        </ColWrapper>

                                        <ColWrapper>
                                            <Subtitle>회원 연락처</Subtitle>
                                            <SubInfo>{phoneNumFilter(data?.user_phone_no)}</SubInfo>
                                        </ColWrapper>

                                        <ColWrapper>
                                            <Subtitle>입출상태</Subtitle>
                                            <SubInfo>{statusFilter(data?.status)}</SubInfo>
                                        </ColWrapper>

                                        {role && (
                                            <ColWrapper>
                                                <Subtitle>출금상태</Subtitle>
                                                <SubInfo>{payoutFilter(data?.pay_out_status)}</SubInfo>
                                            </ColWrapper>
                                        )}

                                        <ColWrapper>
                                            <Subtitle>{payoutStatusFilter(data?.pay_out_status)}</Subtitle>
                                            <SubInfo>{data?.pay_out_status === 1 || data?.pay_out_status === 2 ? dateFilter(data?.updated_at) : dateFilter(data?.created_at)}</SubInfo>
                                        </ColWrapper>

                                        <ColWrapper>
                                            <Subtitle>출금 신청 금액</Subtitle>
                                            <SubInfo>{moneyFilter(data?.cost)}</SubInfo>
                                        </ColWrapper>

                                        {role && (
                                            <ColWrapper>
                                                <Subtitle>수수료</Subtitle>
                                                <SubInfo>
                                                    {moneyFilter(data?.cost_pay_out)}
                                                    {data?.charge ? `(${data?.charge})` : ''}
                                                </SubInfo>
                                            </ColWrapper>
                                        )}

                                        <ColWrapper>
                                            <Subtitle>출금 정산액</Subtitle>
                                            <SubInfo>{moneyFilter(data?.cost - data?.cost_pay_out)}</SubInfo>
                                        </ColWrapper>

                                        <ColWrapper>
                                            <Subtitle>모임명</Subtitle>
                                            <SubInfo>{data?.gathering_name || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                        </ColWrapper>
                                    </InfoBox>

                                    <label id="label">계좌 정보</label>
                                    <InfoBox className="refund-info" leftLine={true}>
                                        {role && (
                                            <>
                                                <InfoTitle>계좌 정보</InfoTitle>

                                                {role && (
                                                    <ColWrapper>
                                                        <Subtitle>은행</Subtitle>
                                                        <SubInfo>{bankFilter(data?.account_bank) || '대기'}</SubInfo>
                                                    </ColWrapper>
                                                )}

                                                {role && (
                                                    <ColWrapper>
                                                        <Subtitle>계좌번호</Subtitle>
                                                        <SubInfo>{data?.account_number || '대기'}</SubInfo>
                                                    </ColWrapper>
                                                )}

                                                {role && (
                                                    <ColWrapper>
                                                        <Subtitle>예금주</Subtitle>
                                                        <SubInfo>{data?.account_holder || '대기'}</SubInfo>
                                                    </ColWrapper>
                                                )}

                                                {role && (
                                                    <ColWrapper>
                                                        <Subtitle>잔여 금액</Subtitle>
                                                        <SubInfo>{moneyFilter(data?.balance)}</SubInfo>
                                                    </ColWrapper>
                                                )}

                                                <InfoTitle style={{ marginTop: '5%' }}>신분증 정보</InfoTitle>

                                                <ColWrapper>
                                                    <Subtitle>신분증 이름</Subtitle>
                                                    <SubInfo>{data?.license_name || '대기'}</SubInfo>
                                                </ColWrapper>

                                                <ColWrapper>
                                                    <Subtitle>주민등록번호</Subtitle>
                                                    <SubInfo>{data?.license_number || '대기'}</SubInfo>
                                                </ColWrapper>
                                            </>
                                        )}
                                    </InfoBox>
                                </ModalBoxBody>

                                <Footer>
                                    <CancelButton onClick={() => setDetailToggle(false)}>취소</CancelButton>
                                </Footer>
                            </SchedulePayUserDetailModalBox>
                        )}
                    </SchedulePayUserDetailModalWrapper>
                )}
            </>
        );
    }
}

export default AccountModal;

/************************************************ styled ************************************************/

const CloseImageWindowWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;

    padding: 2%;

    font-size: 50px;
    color: #fff;
`;

const CancelButton = styled.button`
    cursor: pointer;

    width: 100px;
    height: 33px;

    margin: 1.5%;

    border: 1px solid rgb(229, 229, 229);
    border-radius: 3px;

    background-color: #fff;
    color: #000;

    :hover {
        background-color: #f6f6f6;
    }

    :active {
        background-color: #fff;
    }
`;

const ShowImage = styled.img`
    cursor: pointer;
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: auto;

    @media (max-width: 912px) {
        width: 100%;
    }
`;
