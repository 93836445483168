import React, { useState } from 'react';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { Image } from '../../designComponents/imageboxs/Image';
import { SText } from '../../designComponents/text/ContentText';
import moment from 'moment/moment';
import { MainButton } from '../../designComponents/buttons/Buttons';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CommentRetry from '../../components/Common/CommentRetry';
import { DELETE_GATHERING_COMMENT_RETRY_SUCCESS } from '../../data_system/redux/modules/gathering.reducer/gatheringContents.reducer';

const CommentTile = ({ data, onDelete, isRetry = false }) => {
    const [value, setValue] = useState(data.comment ?? '');
    const [editAble, setEditAble] = useState(false);
    const path = `/adminGathering/comments${isRetry ? '/retry' : ''}`;
    const deleteComment = async () => {
        const id = data.id;
        try {
            const message = `${isRetry ? '대댓글' : '댓글'}을 삭제하시겠습니까?`;
            console.log(data);
            if (window.confirm(message)) {
                const response = await RefreshCheckApi.delete(path, {
                    data: { comment_id: id },
                });
                if (response.status === 200 && response.data.ok) {
                    if (onDelete) {
                        onDelete(id);
                    }
                    alert(response.data.message);
                }
            }
        } catch (error) {
            console.error('error: ', error);
            alert('[ERROR]관리자에게 문의 바랍니다. ');
        }
    };
    const updateComment = async () => {
        const id = data.id;
        try {
            const response = await RefreshCheckApi.patch(path, {
                comment_id: id,
                comment: value,
            });
            if (response.status === 200 && response.data.ok) {
                alert(response.data.message);
                setEditAble(false);
            }
        } catch (error) {
            console.error('error: ', error);
            alert('[ERROR]관리자에게 문의 바랍니다. ');
        }
    };

    return (
        <FlexMainWrapper style={{ flexDirection: 'column', marginLeft: isRetry ? '5vh' : 0, gap: '5px', marginTop: '10px', width: '100%' }}>
            <FlexMainWrapper style={{ alignItems: 'center', width: '100%' }}>
                <Image url={data.url} alt="회원 이미지" width={40} height={40} style={{ border: '1px solid #c7c7c7', borderRadius: isRetry ? '5px' : '50%' }} />
                <FlexMainWrapper style={{ flexDirection: 'column', gap: 5, flex: 1, alignItems: 'stretch' }}>
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <FlexMainWrapper style={{ alignItems: 'end' }}>
                            <SText text={`${data.name}(${data.nick_name})`} />
                            <SText text={moment(data.created_at).format('YYYY년 MM월 DD일 HH:mm:ss')} color="#797979" style={{ fontSize: '11px' }} />
                        </FlexMainWrapper>
                        <div style={{ gap: 8, display: 'flex' }}>
                            {!editAble ? <MainButton text={'수정'} onClick={() => setEditAble(true)} /> : <MainButton text={'수정 취소'} onClick={() => setEditAble(false)} />}
                            <MainButton styleReverse text={'삭제'} onClick={deleteComment} />
                        </div>
                    </FlexMainWrapper>
                    <InputGroup className="mb-3">
                        <Form.Control
                            disabled={!editAble}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="댓글을 입력해 주세요"
                            aria-label="댓글을 입력해 주세요"
                            aria-describedby="basic-addon2"
                        />
                        {editAble && (
                            <Button onClick={updateComment} variant="outline-secondary" id="button-addon2">
                                저장
                            </Button>
                        )}
                    </InputGroup>
                </FlexMainWrapper>
            </FlexMainWrapper>
            <CommentRetry commentId={data.id} />
        </FlexMainWrapper>
    );
};

export default CommentTile;
