import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import moment from 'moment';

import SubTitle from '../../design_system/components/sub_title/SubTitle';
import { TextInputNoLine } from '../../design_system/components/input/Inputs';
import { TypeInput, TypeInputContainer } from '../../pages/statistics/StatisticsPage';



// components
import PowerUserLineChart from './PowerUserLineChart';
import LoadingPage from '../../pages/loading/LoadingPage';
import styled from 'styled-components';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import RefreshCheckApi from "../../communication_system/axios_apis/refresh_check/refresh_check_api";
import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";
import PlatformTab from "../../GroupComponent/Tab/PlatformTab";

const data = [
    {
        name: 'Page A',
        uv: 30,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 30,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 20,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 10,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 18,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 21,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 34,
        amt: 2100,
    },
];

// PowerUserCurve
function PowerUserCurve() {

    // state

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [platform, setPlatform] = useState(0)
    const [userCurveDateType, setUserCurveDateType] = useState(1);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));


    // 데이터 요청
    useEffect(() => {
        getPowerUserCurveData().then();
    }, [userCurveDateType, startDate, platform]);


    // 데이터 요청함수
    const getPowerUserCurveData = async () => {

        try {

            setLoading(true)

            let params = {
                type: userCurveDateType,
                start_date: startDate,
                end_date: moment(startDate).add(1, 'month').format('YYYY-MM-DD'),
                platform
            };

            const powerUserCurveResponse = await RefreshCheckApi.get('/adminMarketing/power_user_curve', {
                params: params

            })

            setData(powerUserCurveResponse?.data?.data)
            setLoading(false)

        } catch(err){

        }


    };


    const explanationDate = !userCurveDateType ? '일주일(7일)' : '한달(30일)';
    return (
        <div>
            <MainPageTitle text={'PowerUserCurve'} fontStyle={{ fontSize: '19px', fontWeight: 'bold' }} />
            <PlatformTab setPlatform={setPlatform}></PlatformTab>

            <div className="power-user-curve-wrapper" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <TypeInputContainer>
                    {/* <TypeInput type={'button'} value={'주별'} style={{ fontWeight: userCurveDateType === 0 ? 'bold' : 'normal' }} onClick={() => setUserCurveDateType(0)} /> */}
                    {/* <div style={{ height: '70%', backgroundColor: 'rgb(229 229 229)', width: 1 }} /> */}
                    <TypeInput type={'button'} value={'월별'} style={{ fontWeight: userCurveDateType === 1 ? 'bold' : 'normal' }} onClick={() => setUserCurveDateType(1)} />
                </TypeInputContainer>

                <TextInputNoLine className="text-input-number" type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </div>

            <div className="line-chart">
                <ResponsiveContainer width="100%" height="100%">
                    {loading ? <LoadingPage text={'데이터를 불러오는 중입니다.'} /> : <PowerUserLineChart data={data} type={userCurveDateType} />}
                </ResponsiveContainer>

                <Explanation>
                    <span>※ 해당 그래프는 {explanationDate}동안 각 회원이 들어온 횟수를 나타낸 지표입니다. (하단의 예시 참고)</span>
                    <span className="a">기준 값: 오늘부터 [7일 전] 또는 [한달전]까지의 총 접속자 수(중복회원 제외)</span>
                    <span>1일차: 접속자수(기준 값), 접속률((1일차 10명/기준 값) X 100) </span>
                    <span>2일차: 접속자수(기준 값), 접속률((2일차 5명/기준 값) X 100)</span>
                    <span>3일차: 접속자수(기준 값), 접속률((3일차 3명/기준 값) X 100)</span>
                    <span>4일차: 접속자수(기준 값), 접속률((4일차 7명/기준 값) X 100)</span>
                    <span>5일차: 접속자수(기준 값), 접속률((5일차 8명/기준 값) X 100)</span>
                    <span>6일차: 접속자수(기준 값), 접속률((6일차 9명/기준 값) X 100)</span>
                    <span>7일차: 접속자수(기준 값), 접속률((7일차 10명/기준 값) X 100) ...</span>
                </Explanation>
            </div>
        </div>
    );
}

export default PowerUserCurve;

const Explanation = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2%;
    margin-top: 3%;

    span {
        font-size: 14px;
        color: gray;
    }
    .a {
        font-size: 13px;
        font-weight: 600;
        color: rgb(66, 139, 247);

        margin: 3px 0;
    }
`;
