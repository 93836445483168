export const exampleData = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 60 + '%',
        width: 650,
    },
    title: {
        text: '데이터 차트',
        align: 'center',
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.y}명</b>',
    },
    accessibility: {
        point: {
            valueSuffix: '50%',
        },
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<span style="font-size: 1.2em"><b>{point.name}</b></span><br>' + '<span style="opacity: 0.6">{point.percentage:.1f} %</span>',
                connectorColor: 'rgba(128,128,128,0.5)',
            },
        },
    },
    series: [
        {
            name: '총 인원',
            colorByPoint: true,
            data: [
                { name: '싫어요', y: 31 },
                { name: '좋아요', y: 512 },
                { name: '결제 어찌해요?', y: 630 },
                { name: '모임이 뭐에요?', y: 146 },
                { name: '단짝은 뭐에요?', y: 78 },
            ],
        },
    ],
};

export const exampleData2 = {
    chart: { type: 'pie', height: 60 + '%', width: 500 },
    tooltip: { valueSuffix: '명' },
    plotOptions: {
        series: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: [
                {
                    enabled: true,
                    distance: 20,
                    style: { fontSize: '17px' },
                },
                {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}%',
                    style: {
                        fontSize: '20px',
                        textOutline: 'none',
                        opacity: 0.7,
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 3,
                    },
                },
            ],
        },
    },
    series: [
        {
            name: '총 인원',
            colorByPoint: true,
            data: [
                { name: '싫어요', y: 31 },
                { name: '좋아요', y: 512 },
                { name: '결제 어찌해요?', y: 630 },
                { name: '모임이 뭐에요?', y: 146 },
                { name: '단짝은 뭐에요?', y: 78 },
            ],
        },
    ],
};

export const exampleData3 = {
    chart: {
        type: 'pie',
        height: 60 + '%',
        width: 650,
    },
    title: {
        text: 'Departamental Strength of the Company',
        align: 'left',
    },
    subtitle: {
        text: 'Custom animation of pie series',
        align: 'left',
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
        point: {
            valueSuffix: '%',
        },
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            borderWidth: 2,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b><br>{point.percentage}%',
                distance: 20,
            },
        },
    },
    series: [
        {
            name: '총 인원',
            colorByPoint: true,
            data: [
                { name: '싫어요', y: 31 },
                { name: '좋아요', y: 512 },
                { name: '결제 어찌해요?', y: 630 },
                { name: '모임이 뭐에요?', y: 146 },
                { name: '단짝은 뭐에요?', y: 78 },
            ],
        },
    ],
};
