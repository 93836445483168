import { Button, Input, SearchBoxWrapper } from "../../components/Common/Styles/CommonTableSideModalStyled";
import React, { useCallback, useRef, useState } from "react";
import RefreshCheckApi from "../../communication_system/axios_apis/refresh_check/refresh_check_api";

const SMSFileUpload = ({setFileList}) => {

	const imageInput = useRef();

	const [imageUrl, setImageUrl] = useState('')

	const onClickAddImage = useCallback(() => {
		imageInput.current.click();
	}, [imageInput]);



	// 이미지 업로드
	const onChangeImages = async (e) => {
		try {


			const file = e.target?.files[0] ?? null


			if(!file){
				return
			}


			const formData = new FormData()

			formData.append('image', file)

			const uploadResponse = await RefreshCheckApi.post('/adminSMS/upload', formData)

			if(uploadResponse.status === 200){
				const fileResult = uploadResponse.data?.data

				if(!fileResult){
					return
				}
				setFileList([fileResult])
				setImageUrl(file.name)
			}


		} catch(err){

			if(err?.response?.status === 400){
				setFileList([])
				setImageUrl('정보 없음')
				alert(err?.response?.data?.message)
			}

		}

		// const response = await RefreshCheckApi.post('/adminSms/upload', imageFormData);
		// setTimeout(() => {
		// 	setImageUrl([...response.data]);
		// }, 1100);
	};


	return (
		<>
			<SearchBoxWrapper>
				<Input type="text" value={imageUrl || '정보없음'} disabled={true} style={{height: '30px'}}/>
				<input type="file" multiple hidden ref={imageInput} onChange={onChangeImages}/>
				<Button onClick={onClickAddImage} style={{height: '30px'}}>
					찾기
				</Button>
			</SearchBoxWrapper>
		</>
	)
}

export default SMSFileUpload