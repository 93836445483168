import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TopButtomSvg from '../../svg/TopButton.svg';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { TopMovingButton } from '../../designComponents/buttons/TopMovingButton';
import SinorUserTable from '../../EventComponents/User/SinorUserTable';
import AppTab from '../../GroupComponent/Tab/AppTab';
import SinorLoveUserTable from '../../EventComponents/User/SinorLoveUserTable';

/** 회원 페이지 */
const User = () => {
    const [platform, setPlatform] = useState(1);

    // legacy
    const navigator = useNavigate();

    // 로그인 확인
    useEffect(() => {
        const res = RefreshCheckApi.get('/admin/check');
        res.then((res) => {
            if (parseInt(res.data.info.role) === 0) navigator('/statistics');
        }).catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_token');
                localStorage.removeItem('expires_at');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user');
                alert('로그인 기한이 만료 되었습니다. 다시 로그인을 시도해주세요.');
                window.location.replace('/login');
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let tableComponent;
    if (Number(platform) === 2) {
        tableComponent = <SinorUserTable></SinorUserTable>;
    } else {
        tableComponent = <SinorLoveUserTable></SinorLoveUserTable>;
    }

    return (
        <Wrapper02 id="user-table">
            <MainPageTitle text={'회원'} />
            <AppTab platform={platform} setPlatform={setPlatform}></AppTab>
            {tableComponent}
            <TopMovingButton icon={TopButtomSvg} onClick={() => (window.location.href = '#')} />
        </Wrapper02>
    );
};

export default React.memo(User);
