import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import SideMenubar from './SideMenubar';
import TopHeader from './TopHeader';

import '../../global.css';

const Layout = ({ logout }) => {
    const navigate = useNavigate();
    const [trigger, setTrigger] = useState(true);
    const [adminInfo, setAdminInfo] = useState({});

    const adminCheckAPI = async () => {
        try {
            const reseponse = await RefreshCheckApi.get('/admin/check');
            const adminInfo = reseponse.data.info;
            setAdminInfo(adminInfo);
        } catch (error) {
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('access_token');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user');
        }
    };

    useEffect(() => {
        localStorage.getItem('user_id');
    }, [navigate]);

    useEffect(() => {
        adminCheckAPI();
    }, []);

    return (
        <div className="root">
            <TopHeader logout={logout} setTrigger={setTrigger} adminInfo={adminInfo} />
            <MainBody className="main" setTrigger={setTrigger}>
                {trigger && <SideMenubar trigger={trigger} adminInfo={adminInfo} />}
                <MainRightSide className="main-right-side">
                    <div style={{ margin: '2%' }}>
                        <Outlet />
                    </div>
                </MainRightSide>
            </MainBody>
        </div>
    );
};

export default React.memo(Layout);

const MainBody = styled.div`
    display: flex;
    background-color: #f2f4f6;
    @media (max-width: 912px) {
        display: block;
    }
`;

const MainRightSide = styled.div`
    width: 100%;
    height: 100vh;
    overflow: auto;
    @media (max-width: 912px) {
        width: 100%;
    }
`;
