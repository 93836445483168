import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';

import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import CSSearchbarWithDropdown from '../../designComponents/searchbars/CSSearchbarWithDropdown';
import UserModal from '../UserComponents/UserModal';
import { contentFilter, dateFilter, noneImage } from '../../utils/textFilter';
import { MainButton } from '../../designComponents/buttons/Buttons';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import { Image } from '../../designComponents/imageboxs/Image';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import DesignTable from '../../designComponents/tables/DesignTable';
import { InformationCard } from '../../designComponents/card/InformationCard';
import { MText } from '../../designComponents/text/ContentText';

/** 회원정보 컴포넌트 */
const UserInformation = ({ userId, setUserId, wrapperStyle }) => {
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [page, setPage] = useState(false);
    const [userData, setUserData] = useState(null);
    const [userAccountData, setUserAccountData] = useState(null);
    const [imageToggle, setImageToggle] = useState(false);
    const [src, setSrc] = useState('');

    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [userInfo, setUserInfo] = useState([]);

    const imgRef = useRef(null);

    useEffect(() => {
        const debounce = setTimeout(() => {
            const input = {
                page: 1,
                size: 12,
                searchType: searchType,
                searchWord: searchText,
            };
            const response = RefreshCheckApi.get('/adminUser', { params: input });
            response.then((res) => setUserInfo(res.data.data));
        }, 300);
        return () => clearTimeout(debounce);
    }, [searchText, searchType]);

    useEffect(() => {
        if (userId) {
            const response = RefreshCheckApi.get(`/adminUser/${userId}`);
            response.then((res) => setUserData(res.data.data));

            const input = { userId: userId };
            const req = RefreshCheckApi.get(`/adminGathering/personalAccount`, { params: input });
            req.then((res) => setUserAccountData(res.data.data)).catch((error) => console.error(error));
        }
    }, [userId]);

    const onClickUserDetail = () => {
        user_info_modal_actions.setId(userId);
        user_info_modal_actions.setModalVisible(true);
    };

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <Wrapper03 style={{ marginBottom: 0, ...wrapperStyle, overflow: 'scroll' }}>
            {user_info_modal_state.modalVisible && <UserModal setImageToggle={setImageToggle} serSrcOrigin={setSrc} />}
            {imageToggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle(false)}>
                    <Image ref={imgRef} imgRef={imgRef} size="S150" url={src} errImg={noneImage(userData.sex)} width={600} height={600} alt="이미지" style={{ borderRadius: '5%' }} />
                </ImageModal>
            )}
            <FlexMainWrapper>
                <MainPageTitle wrapperStyle={{ margin: 0, flex: 1 }} fontStyle={{ fontSize: 17, fontWeight: 600, color: '#797979' }} text={'회원정보'} />
                {userId ? (
                    <MainButton
                        text="다시검색"
                        onClick={() => {
                            setUserId(null);
                            setUserData(null);
                            setUserAccountData(null);
                        }}
                    />
                ) : null}
            </FlexMainWrapper>
            <FlexMainWrapper style={{ marginTop: '2%' }}>
                <CSSearchbarWithDropdown
                    disabled={userData}
                    list={[
                        { id: 1, title: '휴대폰번호', value: 1 },
                        { id: 2, title: '이름', value: 2 },
                        { id: 3, title: '닉네임', value: 3 },
                        { id: 4, title: '회원ID', value: 4 },
                    ]}
                    resetThenSet={useCallback((item) => setSearchType(item.value), [])}
                    onChangeSubmit={(e) => e.preventDefault()}
                    searchText={searchText}
                    onChangeInput={(e) => setSearchText(e.target.value)}
                />
            </FlexMainWrapper>

            {searchText === '' ? (
                <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', marginBlock: 64 }}>
                    <MText text={'유저를 검색해 주세요'}></MText>
                </FlexMainWrapper>
            ) : !userData ? (
                <div>
                    <DesignTable
                        marginTop={0.5}
                        header={[
                            { title: '이름', width: 80 },
                            { title: '닉네임', width: 70 },
                            { title: '나이', width: 30 },
                            { title: '전화번호', width: 100 },
                            { title: '상태', width: 50 },
                        ]}
                        onClick={(item) => {
                            setUserId(item.id);
                        }}
                        body={[
                            { key: 'name', width: 80 },
                            { key: 'nickname', width: 70 },
                            { key: 'age', width: 30 },
                            { key: 'phone_no', width: 100 },
                            { key: 'avail_yn', width: 50 },
                        ]}
                        data={userInfo}
                        allCount={0}
                        page={page}
                        setPage={setPage}
                        bodyRederItem={(key, value) => {
                            const availUser = <span style={{ color: 'rgb(5 101 242)' }}>정상</span>;
                            const outUser = <span style={{ color: 'red' }}>탈퇴</span>;
                            const nullTag = <span style={{ color: '#797979' }}>NULL</span>;
                            if (key === 'name') return value ? contentFilter(value, 7) : nullTag;
                            if (key === 'nickname') return value ? contentFilter(value, 7) : nullTag;
                            if (key === 'age') return value || nullTag;
                            if (key === 'phone_no') return value || nullTag;
                            if (key === 'avail_yn') return value === 1 ? availUser : outUser;
                            return value;
                        }}
                    />
                </div>
            ) : (
                <FlexMainWrapper style={{ position: 'relative', flexDirection: 'column', padding: '20px' }}>
                    <FlexMainWrapper style={{ alignItems: 'start', gap: '20px' }}>
                        <Image
                            imgRef={imgRef}
                            size="S150"
                            url={userData?.profile_list?.length ? userData.profile_list[0] : null}
                            errImg={noneImage(userData.sex)}
                            width={110}
                            height={110}
                            alt="이미지"
                            style={{ borderRadius: '5%', border: '1px solid #e3e3e3' }}
                            onClick={() => {
                                setSrc(userData.profile_list[0]);
                                setImageToggle(true);
                            }}
                        />
                        <div style={{ cursor: 'pointer' }} onClick={onClickUserDetail}>
                            <FlexMainWrapper style={{ flexDirection: 'column' }}>
                                <FlexTwoText text01Style={{ width: '60px' }} text01={'이름'} text02={userData.name} />
                                <FlexTwoText text01Style={{ width: '60px' }} text01={'닉네임'} text02={userData.nick_name} />
                                <FlexTwoText text01Style={{ width: '60px' }} text01={'전화번호'} text02={userData.phone_no} />
                                <FlexTwoText text01Style={{ width: '60px' }} text01={'나이'} text02={userData.age} />
                                <FlexTwoText text01Style={{ width: '60px' }} text01={'가입일'} text02={userData.created_at} />
                            </FlexMainWrapper>
                        </div>
                    </FlexMainWrapper>
                </FlexMainWrapper>
            )}
            {userAccountData ? (
                <InformationCard
                    wrapperStyle={{ marginTop: '10px' }}
                    mainTitle={'계좌 정보'}
                    info={[
                        { width: 85, title: '등록일', value: dateFilter(userAccountData?.created_at) },
                        { width: 85, title: '이름', value: userAccountData?.license_name },
                        { width: 85, title: '주민번호', value: userAccountData?.license_number },
                        { width: 85, title: '예금주', value: userAccountData?.account_holder },
                        { width: 85, title: '계좌번호', value: userAccountData?.account_number },
                        { width: 85, title: '사업자명', value: userAccountData?.business_name },
                        { width: 85, title: '사업자 번호', value: userAccountData?.business_number },
                    ]}
                />
            ) : userId ? (
                <FlexMainWrapper style={{ height: '45%', justifyContent: 'center', alignItems: 'center' }}>
                    <MText text={'해당 회원은 계좌정보가 존재하지 않습니다.'} color="#797979" />
                </FlexMainWrapper>
            ) : null}
        </Wrapper03>
    );
};

export default UserInformation;
