import { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import FlexMainWrapper, { Wrapper, Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { MainSelect, SelectLine } from '../../designComponents/dropdowns/Selectdown';
import { MainButton } from '../../designComponents/buttons/Buttons';
import TextArea from 'antd/es/input/TextArea';
import CallHistory from '../../components/CustomerServiceComponents/CallHistory';

const CreateCSHistory = ({}) => {
    const [allCategory, setAllCategory] = useState({});
    const [subCategory, setSubCategory] = useState([]);
    const historyRef = useRef(null);
    const [param, setParam] = useState({
        call_date: `${moment().format('YYYY-MM-DD HH:mm:ss')}`,
        path: '전화 문의',
        user_name: '',
        phone_no: '',
        big_category: '공통',
        content: '',
        status: 0,
        sub_category: '문의',
        admin_manager: '',
    });
    useEffect(() => {
        // 통화 사유 카테고리
        const callReasonResponse = RefreshCheckApi.get(`/adminCS/category`);
        callReasonResponse.then((res) => {
            setAllCategory(res.data.data);
            setSubCategory(res.data.data['공통']);
        });
    }, []);
    const saveHistory = async () => {
        if (window.confirm('저장하시겠습니까?')) {
            try {
                let obj = { ...param };
                console.log(obj);
                if (!obj.content) return alert('CS 내용을 입력해주세요.');

                obj.user_id = 1;

                const response = await RefreshCheckApi.post('/adminCS', obj);

                if (response.status === 200) {
                    alert('저장되었습니다.');
                    setParam({
                        call_date: `${moment().format('YYYY-MM-DD HH:mm:ss')}`,
                        path: '전화 문의',
                        user_name: '',
                        phone_no: '',
                        big_category: '공통',
                        content: '',
                        status: 0,
                        sub_category: '문의',
                        admin_manager: '',
                    });
                    historyRef.current?.refresh();
                } else {
                    alert('오류발생');
                }
            } catch (error) {
                console.error(error);
            }
        }
    };
    return (
        <Wrapper style={{ display: 'flex', flexDirection: 'column' }}>
            <Wrapper02 style={{ width: '100%' }}>
                <MainButton text="CS 내용 저장" width={110} onClick={saveHistory} />
                <FlexMainWrapper style={{ flexDirection: 'column', marginTop: '10px', marginBottom: '2%' }}>
                    <FlexMainWrapper style={{ alignItems: 'start', width: '100%' }}>
                        <FlexMainWrapper
                            style={{
                                flexDirection: 'column',
                                gap: '15px',
                                backgroundColor: '#f1f1f1',
                                height: '25vh',
                                padding: '10px',
                                borderRadius: '10px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', width: '100%' }}>
                                <InputLine
                                    title={'전화 번호'}
                                    value={param.phone_no}
                                    onChange={(e) => {
                                        let obj = { ...param };
                                        obj.phone_no = e.target.value;
                                        setParam(obj);
                                    }}
                                    wrapperStyle={{ margin: 0 }}
                                    titleStyle={{ width: '58px' }}
                                    inputStyle={{ flex: 1, height: '22px' }}
                                />
                                <InputLine
                                    title={'통화 시각'}
                                    type="datetime-local"
                                    value={param.call_date}
                                    onChange={(e) => {
                                        let obj = { ...param };
                                        console.log(e.target.value);
                                        obj.call_date = e.target.value;
                                        setParam(obj);
                                    }}
                                    wrapperStyle={{ margin: 0 }}
                                    titleStyle={{ width: '58px' }}
                                    inputStyle={{ flex: 1, height: '22px' }}
                                />
                                <FlexMainWrapper style={{ alignItems: 'center', width: '100%', gap: '1px' }}>
                                    <SelectLine
                                        title={'통화사유'}
                                        wrapperStyle={{ alignItems: 'center' }}
                                        titleStyle={{ width: '61px' }}
                                        selectStyle={{ width: '85px' }}
                                        list={Object.keys(allCategory).map((value) => {
                                            let obj = { id: value, title: value, value: value };
                                            return obj;
                                        })}
                                        value={param.big_category}
                                        resetThenSet={(item) => {
                                            const sublists = allCategory[item.value];
                                            setSubCategory(sublists);
                                            let obj = { ...param };
                                            obj.big_category = item.value;
                                            setParam(obj);
                                        }}
                                    />
                                    <MainSelect
                                        style={{ width: '185px' }}
                                        list={
                                            subCategory.length
                                                ? subCategory.map((value) => {
                                                      const obj = { id: value, title: value, value: value };
                                                      return obj;
                                                  })
                                                : [{ title: '문의' }]
                                        }
                                        value={param.sub_category}
                                        resetThenSet={(item) => {
                                            let obj = { ...param };
                                            obj.sub_category = item.value;
                                            setParam(obj);
                                        }}
                                    />
                                </FlexMainWrapper>
                                <SelectLine
                                    title={'유입 경로'}
                                    wrapperStyle={{ alignItems: 'center', marginRight: '10px' }}
                                    titleStyle={{ width: '61px' }}
                                    selectStyle={{ width: '85px' }}
                                    list={[
                                        { id: 1, title: '전화 문의', value: '전화 문의' },
                                        { id: 0, title: '카톡 문의', value: '카톡 문의' },
                                    ]}
                                    value={param.path}
                                    resetThenSet={(item) => {
                                        let obj = { ...param };
                                        obj.path = item.value;
                                        setParam(obj);
                                    }}
                                />
                                <SelectLine
                                    title={'처리여부'}
                                    wrapperStyle={{ alignItems: 'center', marginRight: '10px' }}
                                    titleStyle={{ width: '61px' }}
                                    selectStyle={{ width: '85px' }}
                                    list={[
                                        { id: 0, title: '미처리', value: 0 },
                                        { id: 1, title: '진행중', value: 1 },
                                        { id: 2, title: '완료', value: 2 },
                                    ]}
                                    value={param.status}
                                    resetThenSet={(item) => {
                                        let obj = { ...param };
                                        obj.status = parseInt(item.value);
                                        setParam(obj);
                                    }}
                                />
                            </FlexMainWrapper>
                        </FlexMainWrapper>
                        <TextArea
                            onChange={(e) => {
                                let obj = { ...param };
                                obj.content = e.target.value;
                                setParam(obj);
                            }}
                            value={param.content}
                            placeholder="통화 내용을 입력해주세요."
                            style={{ height: '27vh', borderRadius: '10px', padding: '15px' }}
                        />
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </Wrapper02>
            <CallHistory ref={historyRef} showNickName={true} showPhoneNo={true} showAll={true} style={{ width: '100%' }} />
        </Wrapper>
    );
};

export default CreateCSHistory;
