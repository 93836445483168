/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from 'react';

// S3-route
import { main_server_image_ip } from '../../communication_system/communication_ips';
// api
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// custom hooks
import useInput from '../../hook/useInput';

// 주소검색 API
import DaumPostcode from 'react-daum-postcode';

// styled-components
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import {
    ImageInfoWrapper,
    InfoWrapper,
    SubTitle,
    InputWrapper,
    RadioInput,
    InputForValue,
    AddressInput,
    Input,
    Textarea,
    TextAndBtnWrapper,
    CheckBox,
    TextDirectionWrapper,
    SearchAddressBrn,
} from '../styles/ActivityDetailModalStyled';

function AddActivityModalActivityInfoEntry({ setActivityInfoParams }) {
    const [routin, setRoutin] = useState(0); // 구분(정기, 단기)
    const [dateCount, onChangeDateCount, setDateCount] = useInput(0); // 총 횟수
    const [price, onChangePrice, setPrice] = useInput(0); // 가격
    const [discount, onChangeDiscount, setDiscount] = useInput(0); // 할인율
    const [visit, setVisit] = useState(0); // 방문서비스(예, 아니오)
    const [zone, setZone] = useState(''); // 주소
    const [address, setAddress] = useState(''); // 주소
    const [detailAddress, onChangeDetailAddress, setDetailAddress] = useInput(''); // 주소
    const [infoUrl, setInfoUrl] = useState([]); // 상세설명-파일첨부
    const [explanation, onChangeExplanation, setExplanation] = useInput(''); // 상세설명
    const [material, onChangeMaterial, setMaterial] = useInput(''); // 준비물
    const [notice, onChangeNotice, setNotice] = useInput(''); // 유의사항
    const [refund, onChangeRefund, setRefund] = useInput(''); // 환불정책

    // 주소검색 Toggle
    const [searchAdressToggle, setSearchAdressToggle] = useState(false);

    /******************** useEffect ********************/

    useEffect(() => {
        setActivityInfoParams({
            routin_yn: routin, // 구분
            routin_count: dateCount, // 총 횟수
            price: price, // 가격
            discount_rating: discount, // 할인율
            visit_yn: visit, // 방문 서비스 여부
            zone: zone, // 주소
            address: address, // 주소
            address_detail: detailAddress, // 상세주소
            explanation: explanation, // 상세설명
            explanation_image: infoUrl, // 상세설명_이미지
            notice: notice, // 유의사항
            material: material, // 준비물
            refund: refund, // 환불정책
        });
    }, [setActivityInfoParams, routin, dateCount, price, discount, visit, zone, address, detailAddress, infoUrl, explanation, material, notice, refund]);

    /******************** function ********************/

    // 구분 변경
    const onChangeRoutin = (num) => () => {
        setRoutin(num);
    };

    // 방문서비스 체크 변경
    const onChangeVisit = (num) => () => {
        setVisit(num);
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickImageUpload = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback((e) => {
        if (Object.entries(e.target.files).length > 1) {
            alert('상세 사진은 한장만 업로드 가능합니다.');
        } else {
            const imageFormData = new FormData();
            [].forEach.call(e.target.files, (f) => {
                imageFormData.append('info_url', f);
            });

            // 이미지 form 담기
            requestServer(imageFormData);
        }
    }, []);

    // 이미지를 서버로 보낸다.
    const requestServer = async (FormData) => {
        const result = await RefreshCheckApi.post('adminActivity/transform', FormData);

        setTimeout(() => {
            setInfoUrl(result.data.data.info_url);
        }, 1200);
    };

    // 주소검색창 Toggle
    const onClickSearchAdressToggle = () => {
        setSearchAdressToggle((prev) => !prev);
    };

    // 주소 검색결과
    const onCompletePost = (data) => {
        setZone(data.zonecode);
        setAddress(data.address);
    };

    // 주소검색창 스타일 정의 code
    const postCodeStyle = {
        display: searchAdressToggle ? 'block' : 'none',
        border: '3px solid #c4c4c4',

        width: '400px',
        height: '500px',
    };

    /************************ html ************************/
    return (
        <>
            <InfoWrapper id="category">
                <SubTitle>구분</SubTitle>
                <InputWrapper>
                    <div className="check-box">
                        <RadioInput type="checkbox" checked={routin === 1 ? true : false} onChange={onChangeRoutin(1)} />
                        <span>정기</span>
                    </div>
                    <div className="check-box">
                        <RadioInput type="checkbox" checked={routin === 0 ? true : false} onChange={onChangeRoutin(0)} />
                        <span>단기</span>
                    </div>
                </InputWrapper>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>총 횟수</SubTitle>
                <div style={{ width: '200px' }}>
                    <InputForValue type="number" placeholder="미입력시 1회로 산정됩니다." onChange={onChangeDateCount} />
                    <span>회</span>
                </div>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>가격</SubTitle>
                <div style={{ width: '200px' }}>
                    <InputForValue type="number" onChange={onChangePrice} />
                    <span>원</span>
                </div>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>할인율</SubTitle>
                <div style={{ width: '200px' }}>
                    <InputForValue type="number" onChange={onChangeDiscount} />
                    <span>%</span>
                </div>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>방문 서비스</SubTitle>
                <InputWrapper>
                    <div className="check-box">
                        <RadioInput type="checkbox" checked={visit === 1 ? true : false} onChange={onChangeVisit(1)} />
                        <span>예</span>
                    </div>
                    <div className="check-box">
                        <RadioInput type="checkbox" checked={visit === 0 ? true : false} onChange={onChangeVisit(0)} />
                        <span>아니오</span>
                    </div>
                </InputWrapper>
            </InfoWrapper>

            {searchAdressToggle && (
                <SearchAddressBox>
                    <CloseWindow>
                        <CustomCloseOutlined className="close-button" onClick={onClickSearchAdressToggle} />
                    </CloseWindow>
                    <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} />
                </SearchAddressBox>
            )}

            <ImageInfoWrapper id="category">
                <SubTitle>주소</SubTitle>
                <TextDirectionWrapper>
                    <TextAndBtnWrapper>
                        <AddressInput type="text" placeholder="우편번호" value={zone} disabled readOnly />
                        <SearchAddressBrn onClick={onClickSearchAdressToggle}>주소검색</SearchAddressBrn>
                    </TextAndBtnWrapper>

                    <div style={{ width: '98%' }}>
                        <Input type="text" placeholder="주소" value={address} disabled readOnly />
                        <Input type="text" placeholder="상세주소" onChange={onChangeDetailAddress} />
                    </div>

                    <TextAndBtnWrapper>
                        <CheckBox type="checkbox" />
                        <span>호스트 기본 주소 가져오기</span>
                    </TextAndBtnWrapper>
                </TextDirectionWrapper>
            </ImageInfoWrapper>

            <ImageInfoWrapper id="category">
                <SubTitle>상세 설명</SubTitle>
                <TextDirectionWrapper>
                    <TextAndBtnWrapper>
                        <AddressInput type="text" value={infoUrl.map((url) => url)} placeholder="이미지를 업로드 해주세요." disabled readOnly />
                        <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                        <SearchAddressBrn onClick={onClickImageUpload}>파일첨부</SearchAddressBrn>
                    </TextAndBtnWrapper>

                    <input type="file" multiple hidden />

                    <div>
                        <Textarea onChange={onChangeExplanation} placeholder="상세설명을 작성해주세요" />
                    </div>
                </TextDirectionWrapper>
            </ImageInfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>준비물</SubTitle>

                <Input type="text" placeholder="준비물을 입력해 주세요." style={{ width: '175px' }} onChange={onChangeMaterial} />
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>유의사항</SubTitle>

                <Input type="text" placeholder="유의사항을 입력해 주세요." style={{ width: '175px' }} onChange={onChangeNotice} />
            </InfoWrapper>

            <ImageInfoWrapper id="category">
                <SubTitle>환불 정책</SubTitle>
                <div>
                    <Textarea placeholder="환불정책을 입력해주세요" onChange={onChangeRefund} />
                </div>
            </ImageInfoWrapper>
        </>
    );
}

export default AddActivityModalActivityInfoEntry;

const CloseWindow = styled.div`
    display: flex;
    justify-content: end;

    height: 33px;
    background-color: #c4c4c4;
`;

const CustomCloseOutlined = styled(CloseOutlined)`
    cursor: pointer;
    font-size: 25px;
    padding: 6px;
`;

const SearchAddressBox = styled.div`
    position: fixed;
    top: 23%;
    left: 43%;
`;
