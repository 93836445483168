import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { LText } from '../../designComponents/text/ContentText';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { FlexTwoText } from '../../designComponents/text/TextMapping';
import { MainButton } from '../../designComponents/buttons/Buttons';
import HorizontalImageLists from '../../designComponents/BeautifulDND/HorizontalImageLists';
import { SelectLine } from '../../designComponents/dropdowns/Selectdown';
import { isEmptyObj } from '../../utils/textFilter';
import CKEditorComponent from '../ProductComponent/ckEditor';
import { useCallback, useEffect, useState } from 'react';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import AddProduct from '../../pages/cummers/addProduct';

const PolicyDetailModal = ({ item = {}, visible, setVisible, onUpdate }) => {
    const [html, setHtml] = useState('');
    const [title, setTitle] = useState('');
    useEffect(() => {
        setHtml(item?.content ?? '');
        setTitle(item?.title ?? '');
    }, [item]);
    useEffect(() => {
        if (!visible) {
            setHtml('');
            setTitle('');
        }
    }, [visible]);

    const onSave = async () => {
        let response;
        if (html.length === 0 || title.length === 0) {
            alert('제목과 내용을 모두 입력해 주세요');
            return;
        }
        if (item?.id) {
            response = await RefreshCheckApi.put('/adminPolicy', { id: item.id, title, content: html });
        } else {
            response = await RefreshCheckApi.post('/adminPolicy', { title, content: html });
        }
        if (response.status === 200) {
            alert('저장되었습니다');
            setVisible(false);
            if (onUpdate) {
                onUpdate();
            }
        } else {
            alert('오류가 발생했습니다');
        }
    };
    return (
        <DefaultModal
            title={`정책 ${item?.id ? '수정' : '등록'}`}
            bodyWidth={79}
            visible={visible}
            boxStyle={{ width: '80%', height: '90%' }}
            onClickClose={() => setVisible(false)}
            bottomBtnOn
            saveOn
            closeOn
            saveText={'등록'}
            onClickBottomClose={() => setVisible(false)}
            onClickSave={() => onSave()}
        >
            <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', alignItems: 'start' }}>
                <FlexMainWrapper style={{ alignItems: 'start', padding: 20, gap: 20, width: '95.5%' }} mobileOn>
                    <FlexMainWrapper style={{ flexDirection: 'column', width: '50%', marginBottom: '20px' }}>
                        <LText text={`정책 ${item?.id ? '수정' : '등록'}`} />
                        <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', gap: '15px', marginTop: '15px' }}>
                            <InputLine
                                title={'제목'}
                                value={title}
                                placeholder={'제목을 입력해주세요.'}
                                wrapperStyle={{ margin: 0 }}
                                titleStyle={{ textAlign: 'start', width: '60px' }}
                                inputStyle={{ width: '250px' }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setTitle(value);
                                }}
                            />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                </FlexMainWrapper>

                <FlexMainWrapper style={{ flexDirection: 'column', justifyContent: 'start', padding: '20px', width: '95.4%' }}>
                    <LText text={'내용'} />
                    <CKEditorComponent
                        onHtmlChange={(html) => {
                            setHtml(html);
                        }}
                        onImageHtmlChange={() => {}}
                        init={html}
                    />
                </FlexMainWrapper>
            </FlexMainWrapper>
        </DefaultModal>
    );
};
export default PolicyDetailModal;
