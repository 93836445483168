import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SIZE, makeImageUrl } from '../imageboxs/Image';

export const Card = ({ rendering, id, title, content, url, itemLists, setItemLists, onClickDetail = () => {} }) => {
    const [toggle, setToggle] = useState(false);

    const onClickCard = (contents_id) => () => {
        let arr = [...itemLists];
        let _contents_id = parseInt(contents_id);

        if (!toggle) {
            arr.push(_contents_id);
            setItemLists(arr);
        } else {
            let findIndex = arr.indexOf(_contents_id);
            arr.splice(findIndex, 1);
            setItemLists(arr);
        }
        setToggle((prev) => !prev);
    };

    useEffect(() => {
        setItemLists([]);
    }, [rendering, setItemLists]);

    return (
        <CardWrpper url={makeImageUrl(url, SIZE.S150)} toggle={toggle}>
            <TopWrapper>
                <Title className="title">{title}</Title>
                <CheckBox type="checkbox" onClick={onClickCard(id)} />
            </TopWrapper>

            <Content className="content" onClick={() => onClickDetail(id)}>
                {content}
            </Content>
        </CardWrpper>
    );
};

const CardWrpper = styled.div`
    cursor: pointer;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;

    width: 130px;
    max-width: 100%;
    height: 180px;

    padding: 12px 13px;

    border: ${(props) => (props.toggle ? '2px solid #ff6161' : '2px solid rgb(200 200 200 / 79%)')};
    border-radius: 20px;
    background-color: #0004;
    background-image: ${(props) =>
        props.toggle
            ? `linear-gradient(rgb(135 135 135 / 84%),rgba(179,179,179,0.78)),url(${props.url})`
            : `linear-gradient(rgba(135, 135, 135, 0.24), rgba(179, 179, 179, 0.78)), url(${props.url})`};

    @media (max-width: 912px) {
        width: 150px;
        max-width: 100%;
        height: 200px;
    }
`;

const TopWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
`;

const CheckBox = styled.input`
    cursor: pointer;
    width: 20px;
    height: 20px;

    margin: 0;

    color: #fff;
    border: 1px solid rgb(28 90 180);
    border-radius: 5px;
    background-color: rgb(66, 139, 247);

    &:hover {
        background-color: rgb(46 116 219);
    }
`;

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: #fff;
`;

const Content = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-shadow: 1px 1px 1px #000;

    padding-top: 83%;
`;
