export const chattingTableFilter = [
    {
        key: 'created_at',
        value: '작성일',
        // filter: [
        //     {
        //         key: 1,
        //         value: '오름차순',
        //     },
        //     {
        //         key: 2,
        //         value: '내림차순',
        //     },
        // ],
    },
    {
        key: 'avail_yn',
        value: '채팅상태',
        filter: [
            {
                key: 1,
                value: '진행중',
            },
            {
                key: 0,
                value: '종료',
            },
        ],
    },
    {
        key: 'user_name',
        value: '보낸사람명',
    },
    {
        key: 'user_nick_name',
        value: '보낸사람 닉네임',
    },
    {
        key: 'user_sex',
        value: '성별',
        filter: [
            {
                key: 1,
                value: '남자',
            },
            {
                key: 2,
                value: '여자',
            },
        ],
    },
    {
        key: null,
        value: null,
    },
    {
        key: 'target_user_name',
        value: '받는사람명',
    },
    {
        key: 'target_user_nick_name',
        value: '받는사람 닉네임',
    },
    {
        key: 'target_user_sex',
        value: '성별',
        filter: [
            {
                key: 1,
                value: '남자',
            },
            {
                key: 2,
                value: '여자',
            },
        ],
    },
    {
        key: 'call_count',
        value: '안심전화 횟수',
    },
];

export const chattingTableFilter02 = [
    {
        key: 'first_contents',
        value: '대화 시작일',
        // filter: [
        //     {
        //         key: 1,
        //         value: '오름차순',
        //     },
        //     {
        //         key: 2,
        //         value: '내림차순',
        //     },
        // ],
    },
    {
        key: 'disable_button',
        value: '채팅 상태'
    },

    // {
    //     key: 'avail_yn',
    //     value: '채팅상태',
    //     filter: [
    //         {
    //             key: 1,
    //             value: '진행중',
    //         },
    //         {
    //             key: 0,
    //             value: '종료',
    //         },
    //     ],
    // },
    {
        key: 'from',
        value: '보낸사람명/닉네임/성별',
    },
    {
        key: null,
        value: null,
    },
    {
        key: 'to',
        value: '받는사람명/닉네임/성별',
    },
    {
        key: 'call_count',
        value: '안심전화 횟수',
    },
];

export const gatheringChatFilter = [
    {
        key: 'created_at',
        value: '대화 시작일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'disable_button',
        value: '채팅 상태',
    },
    // {
    //     key: 'avail_yn',
    //     value: '채팅상태',
    //     filter: [
    //         {
    //             key: 1,
    //             value: '진행중',
    //         },
    //         {
    //             key: 0,
    //             value: '종료',
    //         },
    //     ],
    // },
    {
        key: 'to',
        value: '모임장명/닉네임',
    },
    {
        key: null,
        value: null,
    },
    {
        key: 'from',
        value: '보낸사람명/닉네임',
    },
    {
        key: 'gathering_name',
        value: '모임명',
    },
];

export const gatheringAllChatFilter = [
    {
        key: 'created_at',
        value: '단체 채팅 시작일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'disable_button',
        value: '채팅 상태',
    },
    // {
    //     key: 'avail_yn',
    //     value: '채팅상태',
    //     filter: [
    //         {
    //             key: 1,
    //             value: '진행중',
    //         },
    //         {
    //             key: 0,
    //             value: '종료',
    //         },
    //     ],
    // },
    {
        key: 'to',
        value: '모임장명/닉네임',
    },
    {
        key: 'gathering_name',
        value: '모임명',
    },
    {
        key: 'crewCount',
        value: '참여 인원',
    },
];
