import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
    GET_ACTIVITIES_DETAIL_FAILURE,
    GET_ACTIVITIES_DETAIL_SUCCESS,
    PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_FAILURE,
    PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_SUCCESS,
    PUT_ACTIVITY_DETAIL_FAILURE,
    PUT_ACTIVITY_DETAIL_SUCCESS,
} from './activities_detail/activities_detail_type';
import {
    DELETE_REVIEW_LISTS_FAILURE,
    DELETE_REVIEW_LISTS_SUCCESS,
    GET_ACTIVITIES_FAILURE,
    GET_ACTIVITIES_SUCCESS,
    GET_REVIEW_LISTS_FAILURE,
    GET_REVIEW_LISTS_SUCCESS,
    POST_ACTIVITIES_FAILURE,
    POST_ACTIVITIES_SUCCESS,
} from './activities_table/activities_table_type';

const initialState = {
    activities: null,
    activitiesDetail: null,
    reviewLists: null,
};

const activitiesReducer = handleActions(
    {
        [GET_ACTIVITIES_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.activities = action.payload;
            }),
        [GET_ACTIVITIES_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [POST_ACTIVITIES_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.activities.body = [
                    {
                        id: action.payload.activity_id,
                    },
                    ...draft.activities.body,
                ];
            }),
        [POST_ACTIVITIES_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_ACTIVITIES_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.activitiesDetail = action.payload;
            }),
        [PUT_ACTIVITY_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < state.activities.body.length; i++) {
                    if (Number(state.activities.body[i].id) === Number(action.payload.activity_id)) {
                        draft.activities.body[i].status = action.payload.exposure_yn ? 'ON' : 'OFF';
                        draft.activities.body[i].title = action.payload.title;
                        draft.activities.body[i].crawling_yn = action.payload.crawling_yn ? '크롤링' : '직접 운영';
                        draft.activities.body[i].raw_price = action.payload.price;
                        break;
                    }
                }
            }),
        [PUT_ACTIVITY_DETAIL_FAILURE]: (state, action) => {},
        [GET_ACTIVITIES_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.activities.length; i++) {
                    if (draft.activities[i]['id'] === action.payload['activity_id']) {
                        draft.activities[i]['data'][8]['data'] = action.payload['admin_memo'];
                    }
                }
            }),
        [PUT_ACTIVITIES_DETAIL_ADMIN_MEMO_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_REVIEW_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.reviewLists = action.payload;
            }),
        [GET_REVIEW_LISTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [DELETE_REVIEW_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                let tempReviewList = [];
                if (draft.reviewLists !== null) {
                    for (let i = 0; i < draft.reviewLists.length; i++) {
                        if (action.payload.review_id !== draft.reviewLists[i]['review_id']) {
                            tempReviewList.push(draft.reviewLists[i]);
                        }
                    }
                }
                let total = 0;
                for (let z = 0; z < tempReviewList.length; z++) {
                    total = total + parseFloat(tempReviewList[z]['rating']);
                }
                let average = 0;
                if (tempReviewList.length === 0) {
                    average = 0;
                } else {
                    average = total / tempReviewList.length;
                }
                draft.reviewLists = tempReviewList;
                for (let j = 0; j < draft.activities.length; j++) {
                    if (action.payload.activity_id === parseInt(draft.activities[j]['id'])) {
                        draft.activities[j]['data'][6].data = average.toFixed(2);
                        if (draft.activities[j]['data'][7].data >= 1) {
                            draft.activities[j]['data'][7].data = draft.activities[j]['data'][7].data - 1;
                        }
                    }
                }
            }),
        [DELETE_REVIEW_LISTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
    },
    initialState,
);

export default activitiesReducer;
