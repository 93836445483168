import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// svg
import TopButtomSvg from '../../svg/TopButton.svg';

// Print_Excel
import { arrayToExcelForObj } from '../../data_system/xlsx_util/xlsx_util';

// componensts
import { headers } from '../../components/CustomerDataComponents/TableCategory';
import PieChartComponents from '../../components/CustomerDataComponents/PieChartComponents';
import CustomerDataTable from '../../components/CustomerDataComponents/CustomerDataTable';
import CustomerWithdrawTable from '../../components/CustomerDataComponents/CustomerWithdrawTable';
import ExcelBtn from '../../components/CustomerDataComponents/ExcelBtn';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchGetMarriedData,
    fetchGetInterestsData,
    fetchGetLocationData,
    fetchGetAgeData,
    fetchGetWithdrawData,
    fetchGetMarriedPieData, // 결혼 파이차트
    fetchGetInterestsPieData, // 관심사 파이차트
    fetchGetLocationPieData, // 지역 파이차트
    fetchGetAgeBroupPieData, // 연령대 파이차트
    fetchGetWithdrawPieData,
    fetchCameraAuthPieData,
    fetchCameraAuthData, // 탈퇴사유 파이차트
} from '../../data_system/redux/modules/customer_data_reducer/customer_data_reducer';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import { SelectEntry } from '../../designComponents/inputs/InputLine';
import UserDataPieChartLayOut from '../../components/StatisticsComponents/UserDataPieChartLayOut';
import PlatformTab from '../../GroupComponent/Tab/PlatformTab';

// 운영관리_고객데이터
const CustomerData = () => {
    const dispatch = useDispatch();
    const [country, setCountry] = useState(1);
    const [platform, setPlatform] = useState(0);

    // useSelector
    const {
        // useSelector_테이블 데이터
        marriedStatusData,
        interestsData,
        locationData,
        AgeData,
        withdrawData,
        cameraAuthStatusData, // 카메라 인증 데이터
        /*--------------------------*/
        cameraAuthPieData, // 카메라 인증 파이 차트
    } = useSelector((state) => state.customerDataReducer);

    useEffect(() => {
        dispatch(fetchGetMarriedData()); // get 결혼상태
        dispatch(fetchGetInterestsData()); // get 관심사
        dispatch(fetchGetLocationData(platform)); // get 지역
        dispatch(fetchGetAgeData()); // get 연령대
        dispatch(fetchGetWithdrawData()); // get 탈퇴사유
        dispatch(fetchCameraAuthData()); // get 카메라 인증
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchGetLocationData(platform)); // get 지역
    }, [platform]);

    /******************************* function *******************************/

    // 다운로드 엑셀
    const onClickPrintExcel = (headers, total, data, fileName) => () => {
        arrayToExcelForObj(headers?.header, total, data, fileName); // excel 형식으로 출력
    };

    const onClickMoveTop = () => {
        window.location.href = '#';
    };

    return (
        <div>
            {country !== 3 ? (
                <div style={{ marginBottom: '20%' }}>
                    <UserDataPieChartLayOut></UserDataPieChartLayOut>

                    <Wrapper02 style={{ marginBottom: '2%' }}>
                        <MainPageTitle text={'카메라 인증'} />
                        <TableTopLine className="result-excel-line">
                            <div className="result">
                                조회결과 <span>{cameraAuthStatusData?.cameraAuth?.length}</span>건
                            </div>

                            <ExcelBtnWrapper className="exel-button">
                                <Explanation>단위: 명/비율</Explanation>
                                <ExcelPostBtnLine>
                                    <BtnWrapper>
                                        <ExcelBtn onPress={onClickPrintExcel(headers?.cameraAuth, cameraAuthStatusData?.total, cameraAuthStatusData?.cameraAuth, `카메라 인증.xlsx`)} />
                                    </BtnWrapper>
                                </ExcelPostBtnLine>
                            </ExcelBtnWrapper>
                        </TableTopLine>

                        <CustomerTableWrapper>
                            <CustomerDataTable headers={headers.married} datas={cameraAuthStatusData?.cameraAuth} total={cameraAuthStatusData?.total} />
                        </CustomerTableWrapper>
                    </Wrapper02>

                    <Wrapper02 style={{ marginBottom: '2%' }}>
                        <MainPageTitle text={'결혼 상태'} />
                        <TableTopLine className="result-excel-line">
                            <div className="result">
                                조회결과 <span>{marriedStatusData?.married?.length}</span>건
                            </div>

                            <ExcelBtnWrapper className="exel-button">
                                <Explanation>단위: 명/비율</Explanation>
                                <ExcelPostBtnLine>
                                    <BtnWrapper>
                                        <ExcelBtn onPress={onClickPrintExcel(headers?.married, marriedStatusData?.total, marriedStatusData?.married, `결혼상태.xlsx`)} />
                                    </BtnWrapper>
                                </ExcelPostBtnLine>
                            </ExcelBtnWrapper>
                        </TableTopLine>

                        <CustomerTableWrapper>
                            <CustomerDataTable headers={headers.married} datas={marriedStatusData?.married} total={marriedStatusData?.total} />
                        </CustomerTableWrapper>
                    </Wrapper02>

                    <Wrapper02 style={{ marginBottom: '2%' }}>
                        <MainPageTitle text={'관심사'} />
                        <TableTopLine className="result-excel-line">
                            <div className="result">
                                조회결과 <span>{interestsData?.interest?.length}</span>건
                            </div>

                            <ExcelBtnWrapper className="exel-button">
                                <Explanation>단위: 명/비율</Explanation>
                                <ExcelPostBtnLine>
                                    <BtnWrapper>
                                        <ExcelBtn onPress={onClickPrintExcel(headers?.interest, interestsData?.total, interestsData.interest, `관심사.xlsx`)} />
                                    </BtnWrapper>
                                </ExcelPostBtnLine>
                            </ExcelBtnWrapper>
                        </TableTopLine>

                        <CustomerTableWrapper>
                            <CustomerDataTable headers={headers.interest} datas={interestsData?.interest} total={interestsData?.total} />
                        </CustomerTableWrapper>
                    </Wrapper02>

                    <Wrapper02 style={{ marginBottom: '2%' }}>
                        <PlatformTab setPlatform={setPlatform}> </PlatformTab>
                        <MainPageTitle text={'지역'} />
                        <TableTopLine className="result-excel-line">
                            <div className="result">
                                조회결과 <span>{locationData?.location?.length}</span>건
                            </div>

                            <ExcelBtnWrapper className="exel-button">
                                <Explanation>단위: 명/비율</Explanation>
                                <ExcelPostBtnLine>
                                    <BtnWrapper>
                                        <ExcelBtn onPress={onClickPrintExcel(headers?.location, locationData?.total, locationData.location, `관심.xlsx`)} />
                                    </BtnWrapper>
                                </ExcelPostBtnLine>
                            </ExcelBtnWrapper>
                        </TableTopLine>

                        <CustomerTableWrapper>
                            <CustomerDataTable headers={headers.zone} datas={locationData?.location} total={locationData?.total} />
                        </CustomerTableWrapper>
                    </Wrapper02>

                    <Wrapper02 style={{ marginBottom: '2%' }}>
                        <MainPageTitle text={'연령대'} />
                        <TableTopLine className="result-excel-line">
                            <div className="result">
                                조회결과 <span>{AgeData?.age?.length}</span>건
                            </div>

                            <ExcelBtnWrapper className="exel-button">
                                <Explanation>단위: 명/비율</Explanation>
                                <ExcelPostBtnLine>
                                    <BtnWrapper>
                                        <ExcelBtn onPress={onClickPrintExcel(headers?.ageGroup, AgeData?.total, AgeData.age, `연령대.xlsx`)} />
                                    </BtnWrapper>
                                </ExcelPostBtnLine>
                            </ExcelBtnWrapper>
                        </TableTopLine>

                        <CustomerTableWrapper>
                            <CustomerDataTable headers={headers.ageGroup} datas={AgeData?.age} total={AgeData?.total} />
                        </CustomerTableWrapper>
                    </Wrapper02>

                    <Wrapper02>
                        <MainPageTitle text={'탈퇴사유'} />
                        <TableTopLine className="result-excel-line">
                            <div className="result">
                                조회결과 <span>{withdrawData?.withdraw?.length}</span>건
                            </div>

                            <ExcelBtnWrapper className="exel-button">
                                <Explanation>단위: 명/비율</Explanation>
                                <ExcelPostBtnLine>
                                    <BtnWrapper>
                                        <ExcelBtn onPress={onClickPrintExcel(headers?.withdraw, withdrawData?.total, withdrawData.withdraw, `연령대.xlsx`)} />
                                    </BtnWrapper>
                                </ExcelPostBtnLine>
                            </ExcelBtnWrapper>
                        </TableTopLine>

                        <CustomerTableWrapper>
                            <CustomerWithdrawTable headers={headers.withdraw} datas={withdrawData?.withdraw} total={withdrawData?.total} />
                        </CustomerTableWrapper>
                    </Wrapper02>

                    <div id="top-button">
                        <TopButton>
                            <img className="top-btn" src={TopButtomSvg} alt="맨위로 버튼" onClick={onClickMoveTop} />
                        </TopButton>
                    </div>
                </div>
            ) : (
                <Wrapper02>
                    <FlexMainWrapper>
                        <MainPageTitle text={'고객데이터'} wrapperStyle={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }} />
                        <SelectEntry
                            value={country}
                            onChange={(e) => {
                                setCountry(Number(e.target.value));
                            }}
                            title={false}
                            selectStyle={{ width: '15vh' }}
                            wrapperStyle={{ margin: 0 }}
                            option={[
                                { text: '전체', value: 1 },
                                { text: '대한민국', value: 2 },
                                { text: '홍콩', value: 3 },
                            ]}
                        />
                    </FlexMainWrapper>
                    <span style={{ color: '#797979' }}>해당 나라의 데이터가 없습니다.</span>
                </Wrapper02>
            )}
        </div>
    );
};

export default CustomerData;

// Pie 차트 CSS
export const ChartTitle = styled.div`
    font-size: 23px;
    font-family: noto_sans_kr_medium;

    margin-top: 3vh;
    margin-bottom: 2vh;

    @media (max-width: 912px) {
        margin-left: 30px;
    }
`;

export const CircleChartWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    /* border: 1px solid #b4c4ff; */
    /* background-color: #fafbff; */
    .chart-area {
        text-align: center;
    }

    svg {
        width: 450px;
        height: 450px;
    }

    @media (max-width: 912px) {
        display: block;

        .chart-area {
            text-align: start;
        }
    }

    @media (max-width: 1100px) {
        svg {
            width: 350px;
            height: 350px;
        }
    }
`;

export const SubTitle = styled.div`
    font-size: 23px;
    font-family: noto_sans_kr_medium;

    margin: 7vh 10px 35px 10px;
`;

export const TableTopLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .result {
        font-size: 13px;
        font-weight: bold;
    }
    span {
        color: #305eff;
    }
`;

// 테이블 디자인
export const CustomerTableWrapper = styled.div`
    .table {
        table-layout: fixed;
        width: 100%;
        text-align: center;
    }
    .table th {
        background: #e6e6e6;
    }
    .table td,
    .table th {
        padding: 10px 20px;
        border-top: 1px solid #cdcdcd;
        word-break: break-all;
    }
    .table--min {
        min-width: 700px;
    }

    @media screen and (max-width: 768px) {
        /*normal*/
        .table-box {
            overflow-x: auto;
        }
    }
`;

// Excel Button Wrapper
const ExcelBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

// 엑셀 다운로드 버튼
const ExcelPostBtnLine = styled.div`
    margin: 5px 0px;
`;

const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

// 단위 설명
const Explanation = styled.span`
    font-size: 13px;
    font-weight: 600;
    color: black !important;
`;

const TopButton = styled.div`
    cursor: pointer;

    position: fixed;
    bottom: 0;
    right: 0;

    padding: 10px 25px;

    font-size: 50px;
    font-weight: bold;

    .top-btn {
        width: 55px;
    }
`;
