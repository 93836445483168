import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { TableWrapper, Image, Checkbox, ButtonWrapper02, CreateButton, DeleteButton } from '../Common/Styles/CommonTableStyled';
import { Switch } from 'antd';

// redux
import { useDispatch } from 'react-redux';
import { hidePopularContents } from '../../data_system/redux/modules/gathering.reducer/gatheringContents.reducer';

// API & URL
import { main_server_image_ip } from '../../communication_system/communication_ips';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import SearchModal from '../CreatePayInfoComponents/SearchModal';
import CardEntry from './CardEntry';
import ContentDetailModal from '../../designComponents/modals/ContentDetailModal';

/** 시놀 PICK 조회 테이블 */
export default function GatheringSinorPickTable({ data, searchData }) {
    const dispatch = useDispatch();
    // state-toggle
    const [detailToggle, setDetailToggle] = useState([false, 0]);
    const [pickContentsToggle, setPickContentsToggle] = useState(false);
    // state
    const [checkItems, setCheckItems] = useState([]); // 체크된 회원id를 담을 배열
    const [contentsID, setContentsID] = useState(null);
    const [pickData, setPickData] = useState([]);
    const [checkPickItem, setCheckPickItem] = useState([]);

    // antd-toggle button
    const [open, setOpen] = useState(false);

    /******************************************* useEffect *******************************************/

    // gathering pick data: ['/adminGathering/pick/contents']
    useEffect(() => {
        let gatheringQuery = `?size=${10}&page=${1}&search_type=${1}&search_word=${''}&deleted=${open ? 1 : 0}`;
        const response = RefreshCheckApi.get(`/adminGathering/pick/contents${gatheringQuery}`);

        response
            .then((res) => {
                setPickData(res.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [data, open]);

    /******************************************* function *******************************************/

    /** Toggle 버튼 */
    const onChange = (checked) => {
        setOpen(checked);
    };

    /** 클릭 시 전체 체크 */
    const onClickAllCheck = (e) => {
        return handleAllCheck(e.target.checked);
    };

    /** 클릭 시 단일 체크 */
    const onClickSingleCheck = (datas) => (e) => {
        return handleSingleCheck(e.target.checked, datas);
    };

    /** 체크박스 전체 선택 */
    const handleAllCheck = (checked) => {
        if (checked) {
            // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
            const idArray = [];
            const dataArray = [];

            pickData.forEach((data) => {
                idArray.push(parseInt(data.id));
            });

            pickData.forEach((data) => dataArray.push(data));
            setCheckItems(idArray);
        } else {
            // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
            setCheckItems([]);
        }
    };

    /** 체크박스 단일 선택 */
    const handleSingleCheck = (checked, data) => {
        if (checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            setCheckItems((prev) => [...prev, parseInt(data.id)]);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setCheckItems(checkItems.filter((el) => el !== parseInt(data.id)));
        }
    };

    /** 상세보기 */
    const onClickDetail = (contents_id) => () => {
        let _contents_id = parseInt(contents_id);
        setDetailToggle([true, _contents_id]);
    };

    /** 인기 게시글 제외(삭제) */
    const onClickDeletePick = (type) => () => {
        if (type === 1) {
            let dataLength = pickData?.filter((v) => Boolean(!v.deleted_at)).length;

            if (dataLength >= 8) {
                return alert('8개의 관리자 PICK이 이미 존재합니다.');
            } else if (checkItems.length + dataLength > 8 || checkItems.length > 8) {
                return alert('관리자 PICK은 8개까지 가능합니다.');
            }
        }

        let checkMsg = type === 1 ? `선택하신 PICK을 복원하시겠습니까?` : `선택하신 PICK을 삭제하시겠습니까?`;
        if (window.confirm(checkMsg)) {
            if (type === 3) {
                dispatch(hidePopularContents(checkPickItem, type));
            } else {
                dispatch(hidePopularContents(checkItems, type));
            }
        }
    };

    /** 이미지 에러처리 */
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    /** PICK 등록 */
    const onClickCreatePick = () => {
        setPickContentsToggle(true);
    };

    /** 게시글 선택 state JSON */
    const funcParam = {
        contentsID: contentsID,
        setContentsID: setContentsID,
        pickLength: pickData?.filter((v) => Boolean(!v.deleted_at)).length,
        searchData: searchData,
    };

    /** 카드 선택 state JSON */
    const checkCardJSON = {
        checkPickItem: checkPickItem,
        setCheckPickItem: setCheckPickItem,
        onClickDetail: onClickDetail,
    };

    /********************************************* html *********************************************/
    return (
        <TableWrapper className="table-wrapper" filterToggle={true} data={data}>
            <label id="label">게시글 상세보기</label>
            {detailToggle[0] && <ContentDetailModal id={detailToggle[1]} setToggle={setDetailToggle} />}
            {pickContentsToggle && <SearchModal type={'게시글'} setToggle={setPickContentsToggle} funcParam={funcParam} />}

            <div className="service-pick">
                <ButtonWrapper03 id="button-wrapper" style={{ marginBottom: '5px', justifyContent: 'start', gap: '10px' }}>
                    <h2 className="now-pick">현재 PICK</h2>

                    <ButtonWrapper02>
                        <DeleteButton style={{ width: '95px' }} onClick={onClickDeletePick(3)}>
                            PICK에서 제외
                        </DeleteButton>
                    </ButtonWrapper02>
                </ButtonWrapper03>

                <CardEntryWrapper id="service-pick">
                    {data?.map((item, index) => {
                        return (
                            <div key={index}>
                                <CardEntry item={item} stateObj={checkCardJSON} />
                            </div>
                        );
                    })}
                </CardEntryWrapper>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', color: 'gray', fontSize: '13px' }}>
                    <h4 style={{ margin: '20px 0 0 0', color: 'black' }}>시놀 PICK 기준</h4>
                    <span>1. 인기게시글은 총 8개입니다.</span>
                    <span>2. 관리자 PICK을 최상단에 정렬하고 조회수가 높은 순, 댓글수 순으로 정렬</span>
                    <span>3. 마지막으로 상단에서 8개를 선택하여 랜덤하게 보여줍니다.</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', color: 'gray', fontSize: '13px' }}>
                    <h4 style={{ margin: '20px 0 0 0', color: 'black' }}>인기게시글 제외하기</h4>
                    <span>1. 현재 PICK에서 제외하고자 하는 게시글 선택</span>
                    <span>2. 상단 PICK에서 제외 클릭</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', color: 'gray', fontSize: '13px' }}>
                    <h4 style={{ margin: '20px 0 0 0', color: 'black' }}>관리자 권한 8개 선택 방법</h4>
                    <span>1. 등록 클릭</span>
                    <span>2. 게시글 검색</span>
                    <span>3. 상단에 노출하고자 하는 게시글 선택</span>
                </div>
            </div>

            <div className="admin-pick">
                <ButtonWrapper03 id="button-wrapper" style={{ marginBottom: '5px', marginTop: '5%' }}>
                    <ButtonWrapper02>
                        <h2 className="now-pick">관리자 PICK</h2>

                        <ButtonWrapper02>
                            <Switch onChange={onChange} checked={open} />
                            <span style={{ fontSize: '13px', fontWeight: 500 }}>삭제포함 보기</span>
                        </ButtonWrapper02>
                    </ButtonWrapper02>

                    <ButtonWrapper02>
                        <CreateButton onClick={onClickCreatePick}>등록</CreateButton>
                        <CreateButton style={{ color: '#4CAF50', border: '1px solid #4CAF50' }} onClick={onClickDeletePick(1)}>
                            복원
                        </CreateButton>
                        <DeleteButton onClick={onClickDeletePick(0)}>삭제</DeleteButton>
                    </ButtonWrapper02>
                </ButtonWrapper03>

                <table>
                    <thead className="filter" />
                    <tbody className="title">
                        <tr>
                            <th>
                                <Checkbox
                                    type="checkbox"
                                    onChange={onClickAllCheck}
                                    checked={checkItems?.length === pickData?.length ? true : false} // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                                />
                            </th>
                            <th>게시글 ID</th>
                            <th>삭제여부</th>
                            <th>모임명</th>
                            <th>게시글 제목</th>
                            <th>이미지</th>
                        </tr>
                    </tbody>

                    <tbody className="body">
                        {pickData?.map((item, index) => {
                            return (
                                <tr key={`${item.id}${item.title}${index}`}>
                                    <td>
                                        <Checkbox
                                            type="checkbox"
                                            onChange={onClickSingleCheck(item)}
                                            checked={checkItems.includes(parseInt(item.id)) ? true : false} // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        />
                                    </td>
                                    <td onClick={onClickDetail(item.params)}>{item.id}</td>
                                    <td onClick={onClickDetail(item.params)}>{item.deleted_at ? <OFF>OFF</OFF> : <ON>ON</ON>}</td>
                                    <td onClick={onClickDetail(item.params)}>{item.title}</td>
                                    <td onClick={onClickDetail(item.params)}>{item.content}</td>
                                    <td onClick={onClickDetail(item.params)}>
                                        <Image sizes={80} src={main_server_image_ip + item.thumbnail} alt="썸네일 이미지" onError={handleImgError} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </TableWrapper>
    );
}

const ButtonWrapper03 = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .now-pick {
        margin: 0;
        font-size: 19px;
        font-weight: 600;
    }
`;

const CardEntryWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    width: 100%;
    overflow-y: auto;
`;

const ON = styled.div`
    width: 37px;
    height: 17px;
    line-height: 19px;
    text-align: center;
    font-weight: bold;
    color: rgb(66, 139, 247);
    border: 1px solid rgb(66, 139, 247);
    background-color: rgba(66, 139, 247, 0.08);
`;

const OFF = styled.div`
    width: 37px;
    height: 17px px;
    line-height: 19px;
    text-align: center;
    font-weight: bold;
    color: rgb(255, 16, 125);
    border: 1px solid rgb(255, 16, 125);
    background-color: rgba(66, 139, 247, 0.08);
`;
