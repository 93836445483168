import {
    getAnnouncementsDetail,
    putAnnouncementsDetail,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import {GET_ANNOUNCEMENTS_DETAIL, PUT_ANNOUNCEMENTS_DETAIL,} from "./announcements_detail_type";

export const getAnnouncementsDetailAsync = createGetRequestThunk(
  GET_ANNOUNCEMENTS_DETAIL,
  getAnnouncementsDetail
);

export const putAnnouncementsDetailAsync = createPutRequestThunk(
  PUT_ANNOUNCEMENTS_DETAIL,
  putAnnouncementsDetail
);
