import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import LoadingPage from '../../../pages/loading/LoadingPage';
import { CloseOutlined } from '@ant-design/icons';

// react-query && axios
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// BANK_CODE
import { BANK_CODE } from '../../SubscribePayment/payBankCodeService';

// custom hook
import useInput from '../../../hook/useInput';

// 계좌정보 상세 모달
function AccountDetailModal({ accountDetail, setAccountDetail }) {
    // react-query
    const { isLoading, error, data, refetch } = useQuery(['accountDetailData'], async () => {
        return await RefreshCheckApi.get(`/adminGathering/users/account/detail?gathering_id=${accountDetail[1]}`).then((res) => {
            return res.data.data;
        });
    });

    // state-toggle
    const [updateToggle, setUpdateToggle] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [src, setSrc] = useState('');

    // state
    const [bankCode, setBankCode] = useState(''); // 은행
    const [accountNumber, onChangeAccountNumber, setAccountNumber] = useInput(''); // 계좌번호
    const [accountHolder, onChangeAccountHolder, setAccountHolder] = useInput(''); // 예금주
    const [email, onChangeEmail, setEmail] = useInput(''); // 예금주

    const [licenseName, onChangeLicenseName, setLicenseName] = useInput(''); // 주민등록이름
    const [licenseNumber, setLicenseNumber] = useState(''); // 주민등록번호

    /******************************* useEffect *******************************/

    // 에러감지
    useEffect(() => {
        if (error) {
            console.error(error);
            alert(error);
        }
    }, [error]);

    // 데이터적용
    useEffect(() => {
        setBankCode(data?.account_bank);
        setAccountNumber(data?.account_no);
        setAccountHolder(data?.account_holder);
        setEmail(data?.email);
        setLicenseName(data?.license_name);
        setLicenseNumber(data?.license_number);
    }, [data, setBankCode, setAccountNumber, setAccountHolder, setEmail, setLicenseName, setLicenseNumber]);

    /******************************* function *******************************/

    // 이미지 Error 처리
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    // 취소(모달창 닫기)
    const onClickCancel = () => {
        setAccountDetail([false, 0]);
    };

    // 수정하기
    const onClickUpdate = () => {
        setUpdateToggle(true);
    };

    // 주민번호 입력하기
    const onChangeLicenseNumber = (e) => {
        setLicenseNumber(e.target.value);
    };

    // 수정 취소하기
    const onClickCancelUpdate = () => {
        setBankCode(data?.account_bank);
        setAccountNumber(data?.account_no);
        setAccountHolder(data?.license_name);
        setEmail(data?.email);
        setUpdateToggle(false);
        refetch();
    };

    // 수정완료
    const onClickUpdateSubmit = () => {
        // JSON 데이터
        let body = {
            id: data.id,
            account_bank: bankCode,
            account_no: accountNumber,
            account_holder: accountHolder,
            email: email,
            license_name: licenseName,
            license_number: licenseNumber,
        };

        // 계좌정보 및 이메일 입력확인
        if (!bankCode) {
            alert('[은행]을 선택해주세요');
            return;
        } else if (!accountNumber) {
            alert('[계좌번호]를 입력해주세요');
            return;
        } else if (!accountHolder) {
            alert('[예금주명]을 입력해주세요');
            return;
        }

        // 주민번호 체크
        var juminRule = /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-8][0-9]{0}$/;

        // 신분증 정보 입력확인
        if (!licenseName) {
            alert('[주민등록이름]을 입력해주세요');
            return;
        } else if (!juminRule.test(licenseNumber)) {
            alert('[주민등록번호] 형식에 맞게 입력하세요!');
            setLicenseNumber('');
            return;
        }

        const response = RefreshCheckApi.patch('/adminGathering/users/account', body);

        response
            .then((res) => {
                let message = res.data.message;

                alert(message);
                setUpdateToggle(false);
                refetch();
            })
            .catch((err) => {
                console.error(err);
                alert(`[Error] ${err.response.data.message}`);
            });
    };

    // 은행명 변경하기
    const onChangeBankCodeSelect = (e) => {
        setBankCode(e.target.value);
    };

    /** 이미지 확대 닫기 */
    const onClickCloseImage = () => {
        setSrc(null);
        setShowImageModal(false);
    };

    /******************************** filter ********************************/

    // 은행명 필터
    const bankFilter = (num) => {
        return BANK_CODE.map((data) => {
            return (data.CODE1 === num || data.CODE2 === num) && data.KOR;
        });
    };

    /********************************* html *********************************/
    return (
        <DetailModalWrapper id="modal-window">
            {showImageModal ? (
                <div>
                    <CloseImageWindowWrapper className="image-modal-window">
                        <CloseOutlined onClick={onClickCloseImage} />
                    </CloseImageWindowWrapper>

                    <ShowImage sizes={700} src={src} alt="확대 이미지" onError={handleImgError} />
                </div>
            ) : (
                <DetailModalBox id="modal-box">
                    <Header id="top-line">
                        <Text>모임계좌 상세정보</Text>
                        <Text style={{ cursor: 'pointer' }} onClick={onClickCancel}>
                            닫기
                        </Text>
                    </Header>

                    <ModalBoxBody>
                        {isLoading ? (
                            <LoadingPage text={'계좌 상세정보를 불러오는 중입니다.'} />
                        ) : (
                            <>
                                <label id="label">모임장 정보</label>
                                <InfoBox className="subscribe-info">
                                    <InfoTitle>모임정보</InfoTitle>

                                    <ColWrapper>
                                        <Subtitle>모임ID</Subtitle>
                                        <SubInfo>{data?.gathering_id || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>모임명</Subtitle>
                                        <SubInfo>{data?.gathering_name || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper style={{ alignItems: 'start', width: '100%' }}>
                                        <Subtitle>모임명</Subtitle>
                                        <SubInfo style={{ width: '70%' }}>
                                            <Textarea type="text" disabled value={data.gathering_explanation || '정보 없음'} />
                                            {/* {data?.gathering_explanation || <span style={{ color: 'gray' }}>정보없음</span>} */}
                                        </SubInfo>
                                    </ColWrapper>

                                    <InfoTitle style={{ marginTop: '10%' }}>모임장 정보</InfoTitle>

                                    <ColWrapper>
                                        <Subtitle>회원명</Subtitle>
                                        <SubInfo>{data?.user_name || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>닉네임</Subtitle>
                                        <SubInfo>{data?.user_nickname || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>전화번호</Subtitle>
                                        <SubInfo>{data?.user_phone_no || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>주민등록 이름</Subtitle>
                                        <SubInfo>
                                            {updateToggle ? (
                                                <Input type="text" placeholder="신분증에 표기된 이름" value={licenseName} onChange={onChangeLicenseName} />
                                            ) : (
                                                <>{licenseName || <span style={{ color: 'gray' }}>정보없음</span>}</>
                                            )}
                                        </SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>주민등록번호</Subtitle>
                                        <SubInfo>
                                            {updateToggle ? (
                                                <Input type="text" placeholder="주민등록번호 7자리까지 입력" value={licenseNumber} onChange={onChangeLicenseNumber} />
                                            ) : (
                                                <span>{licenseNumber ? licenseNumber + '******' : <span style={{ color: 'gray' }}>정보없음</span>}</span>
                                            )}
                                        </SubInfo>
                                    </ColWrapper>
                                </InfoBox>

                                <label id="label">계좌정보</label>
                                <InfoBox className="subscribe-info">
                                    <InfoTitle>계좌정보</InfoTitle>

                                    <ColWrapper>
                                        <Subtitle>은행</Subtitle>
                                        <SubInfo>
                                            {updateToggle ? (
                                                <Select onChange={onChangeBankCodeSelect} value={bankCode}>
                                                    {BANK_CODE.map((data, index) => {
                                                        return (
                                                            <option key={index} value={data.CODE1}>
                                                                {data.KOR}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            ) : (
                                                <>{bankFilter(bankCode) || <span style={{ color: 'gray' }}>정보없음</span>}</>
                                            )}
                                        </SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>계좌번호</Subtitle>
                                        <SubInfo>
                                            {updateToggle ? (
                                                <Input type="number" placeholder='"-" 뺴고 입력하세요' value={accountNumber} onChange={onChangeAccountNumber} />
                                            ) : (
                                                <>{accountNumber || <span style={{ color: 'gray' }}>정보없음</span>}</>
                                            )}
                                        </SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>예금주</Subtitle>
                                        <SubInfo>
                                            {updateToggle ? (
                                                <Input type="text" placeholder="예금주명 입력" value={accountHolder} onChange={onChangeAccountHolder} />
                                            ) : (
                                                <>{accountHolder || <span style={{ color: 'gray' }}>정보없음</span>}</>
                                            )}
                                        </SubInfo>
                                    </ColWrapper>
                                </InfoBox>
                            </>
                        )}
                    </ModalBoxBody>

                    <ButtonWeapper className="bottom-btn-wrapper">
                        <button className="update" onClick={updateToggle ? onClickUpdateSubmit : onClickUpdate}>
                            {updateToggle ? '수정완료' : '수정'}
                        </button>
                        <button className="cencle" onClick={updateToggle ? onClickCancelUpdate : onClickCancel}>
                            {updateToggle ? '수정취소' : '취소'}
                        </button>
                    </ButtonWeapper>
                </DetailModalBox>
            )}
        </DetailModalWrapper>
    );
}

export default AccountDetailModal;

/************************************** styled **************************************/

const Textarea = styled.textarea`
    width: 100%;
    height: 15vh;
`;

const CloseImageWindowWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;

    padding: 2%;

    font-size: 50px;
    color: #fff;
`;

const DetailModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: #000000b0;
`;

const DetailModalBox = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 100vh;
    height: 70%;

    border-radius: 10px;
    background-color: #fff;

    @media (max-width: 912px) {
        width: 98%;
        height: 90%;

        overflow: auto;
    }

    .close-window {
        padding: 5px 25px;
        text-align: start;
        font-size: 20px;
        color: #428bf7;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        padding: 15px;
        color: gray;
    }
`;

const Header = styled.div`
    z-index: 999;
    display: flex;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

    height: 5vh;

    align-items: center;

    @media (max-width: 1120px) {
        width: 100%;
    }
`;

const Text = styled.div`
    padding: 0 15px;

    color: #fff;
    font-size: 16px;
    font-weight: 600;

    line-height: 45px;
`;

const ModalBoxBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding: 0px 30px;

    #label {
        display: none;
    }

    @media (max-width: 912px) {
        flex-direction: column;
    }
`;

const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    width: 100%;
    height: 59vh;
    margin-bottom: 20%;

    border-left: ${(props) => (props.leftLine ? '1px solid gray' : '')};
    padding-left: ${(props) => (props.leftLine ? '2%' : '')};

    @media (max-width: 912px) {
        height: auto;
    }
`;

const InfoTitle = styled.h2`
    margin: 15px 0 30px 0;

    font-size: 17px;
    font-weight: 600;
`;

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 3%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 80px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;
`;

const Image = styled.img`
    cursor: pointer;
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

const ShowImage = styled.img`
    cursor: pointer;
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: auto;

    @media (max-width: 912px) {
        width: 100%;
    }
`;

const ButtonWeapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;

    padding: 2%;

    background-color: #fff;

    @media (max-width: 912px) {
        justify-content: center;

        position: fixed;
        bottom: 5%;

        margin: auto;
        width: 90%;
    }

    button {
        cursor: pointer;
        border: 0;
        background-color: #fff;

        width: 120px;
        height: 35px;
    }

    .cencle {
        border: 1px solid rgb(229, 229, 229);
        color: black;

        :hover {
            background-color: rgb(242 242 242);
        }
    }

    .update {
        border: 1px solid rgb(255, 16, 125);
        background-color: rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(198 3 92);
        }
    }

    .save {
        border: 1px solid #428bf7;
        background-color: #428bf7;
        color: #fff;

        :hover {
            background-color: #1d6ee6;
        }
    }
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 0 5px;

    width: 100%;
    height: 22px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const Select = styled.select`
    width: 130px;
    height: 27px;

    outline: none;
    border-color: rgba(66, 139, 247, 0.2);
`;
