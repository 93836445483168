export const GET_HOSTS_DETAIL = "admin/GET_HOSTS_DETAIL";
export const GET_HOSTS_DETAIL_SUCCESS = "admin/GET_HOSTS_DETAIL_SUCCESS";
export const GET_HOSTS_DETAIL_FAILURE = "admin/GET_HOSTS_DETAIL_FAILURE";
export const PUT_HOSTS_DETAIL_ADMIN_MEMO = "admin/PUT_HOSTS_DETAIL_ADMIN_MEMO";
export const PUT_HOSTS_DETAIL_ADMIN_MEMO_SUCCESS =
  "admin/PUT_HOSTS_DETAIL_ADMIN_MEMO_SUCCESS";
export const PUT_HOSTS_DETAIL_ADMIN_MEMO_FAILURE =
  "admin/PUT_HOSTS_DETAIL_ADMIN_MEMO_FAILURE";
export const PUT_HOSTS_DETAIL = "admin/PUT_HOSTS_DETAIL";
export const PUT_HOSTS_DETAIL_SUCCESS = "admin/PUT_HOSTS_DETAIL_SUCCESS";
export const PUT_HOSTS_DETAIL_FAILURE = "admin/PUT_HOSTS_DETAIL_FAILURE";
export const GET_HOSTS_ALL = "admin/GET_HOSTS_ALL"
export const GET_HOSTS_ALL_SUCCESS= "admin/GET_HOSTS_ALL_SUCCESS";
export const GET_HOSTS_ALL_FAILURE= "admin/GET_HOSTS_ALL_FAILURE";
