import { Wrapper02 } from "../../designComponents/wrappers/FlexMainWrapper";
import MainPageTitle from "../../designComponents/titles/MainPageTitle";
import GatheringRankTable from "../../components/ClickPercentComponents/Table/GatheringRankTable";
import ScheduleRankTable from "../../components/ClickPercentComponents/Table/ScheduleRankTable";
import React from "react";
import { ActivityGoodsTableWrapper } from "../clickPercentage/clickPercentage";

const GatheringRank = () => {

	return <>

		<Wrapper02 style={{ marginBottom: '2%' }}>
			<MainPageTitle text={'모임 순위'} />
			<ActivityGoodsTableWrapper>
				<GatheringRankTable />
			</ActivityGoodsTableWrapper>
		</Wrapper02>

		<Wrapper02>
			<MainPageTitle text={'일정 순위'} />
			<ActivityGoodsTableWrapper>
				<ScheduleRankTable />
			</ActivityGoodsTableWrapper>
		</Wrapper02>

	</>

}

export default GatheringRank