import React from 'react';

import Banner from '../../components/EventBannerComponents/Banner';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

const Event = () => {
    // 노출위치: 1: 메인팝업, 2: 모임베너, 3: 채팅베터
    return (
        <Wrapper02>
            <MainPageTitle text={'Banner'} />
            <Banner />
        </Wrapper02>
    );
};

export default React.memo(Event);
