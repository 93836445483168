import { createContext, useState } from 'react';

const AddScheduleModalContext = createContext({
    add_schedule_modal_state: {
        modalVisible: false,
        dateList: [],
    },
    add_schedule_modal_actions: {
        setModalVisible: () => {},
        setDateList: () => {},
        pushDateList: () => {},
        deleteDateList: () => {},
    },
});

const AddScheduleModalProvider = ({ children }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [dateList, setDateList] = useState([]);
    const pushDateList = (item) => {
        const tmp = [...dateList];
        tmp.push(item);
        setDateList(tmp);
    };
    const deleteDateList = (index) => {
        setDateList(dateList.slice(index, 1));
    };
    const value = {
        add_schedule_modal_state: { modalVisible, dateList },
        add_schedule_modal_actions: {
            setModalVisible,
            setDateList,
            pushDateList,
            deleteDateList,
        },
    };

    return <AddScheduleModalContext.Provider value={value}>{children}</AddScheduleModalContext.Provider>;
};

const AddScheduleModalConsumer = AddScheduleModalContext.Consumer;

export { AddScheduleModalConsumer, AddScheduleModalProvider };

export default AddScheduleModalContext;
