import React from 'react';

// styled-components, CSS, icons
import styled from 'styled-components';

// components > table
import ClickPercentageTable from '../../components/ClickPercentComponents/Table/ClickPercentageTable';
import GatheringRankTable from '../../components/ClickPercentComponents/Table/GatheringRankTable';
import ScheduleRankTable from '../../components/ClickPercentComponents/Table/ScheduleRankTable';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

/** 운영관리_클릭률 */
const ClickPercentage = () => {
    return (
        <Wrapper>
            <Wrapper02 style={{ marginBottom: '2%' }}>
                <MainPageTitle text={'클릭률(상세유입경로)'} />
                <ClickPercentageTableWrapper>
                    <ClickPercentageTable />
                </ClickPercentageTableWrapper>

                <ManualBox>
                    <div className="manual">
                        <span>메뉴 클릭수</span>: 해당 메뉴 또는 버튼 클릭수
                    </div>
                    <div className="manual">
                        <span>메뉴 사용률</span>: 해당 메뉴 클릭수(중복X) / 오늘 접속자수
                    </div>
                    <div className="manual">
                        <span>놀이터 카테고리:</span> 해당 카테고리 / 놀이터 메뉴 클릭수
                    </div>
                    <div className="manual">
                        <span>인기놀이:</span> 인기놀이 섹션 ‘전체 보기’ 버튼 클릭수
                    </div>
                    <div className="manual">
                        <span>상품 썸네일</span>: 상품 썸네일 클릭수 / 전체 오늘 상품
                    </div>
                    <div className="manual">
                        <span>신청하기</span>: 상품 상세페이지 ‘신청하기’ 버튼 클릭수
                    </div>
                </ManualBox>
            </Wrapper02>


        </Wrapper>
    );
};

export default ClickPercentage;

/****************************** styled ******************************/

export const Wrapper = styled.div`
    #label {
        display: none;
    }
`;

export const TypeInputContainerWrapper = styled.div`
    display: flex;
    gap: 15px;

    width: 100%;
    flex-direction: row;

    margin-top: 35px;
    margin-bottom: 25px;
`;

export const TypeInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid rgb(229, 229, 229);
    border-radius: 5px;

    @media (max-width: 912px) {
        width: 100px;
    }
`;

export const PaginationLine = styled.div`
    position: relative;
`;

export const ManualBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;

    margin: 0px 2px 5px 2px;

    color: #797979;
    font-family: Noto Sans KR;
    font-size: 13px;

    .manual span {
        font-weight: 600;
    }

    @media (max-width: 912px) {
        margin-top: 10%;
        /* margin-top: 15px; */
    }
`;

export const SubTitle = styled.div`
    font-size: 23px;
    font-family: noto_sans_kr_medium;

    margin: 7vh 10px 35px 10px;
`;

export const GoodsSubTitle = styled.div`
    font-size: 23px;
    font-family: noto_sans_kr_medium;

    margin: 3vh 10px 35px 10px;
`;

export const ExcelPostBtnLine = styled.div`
    margin: 5px 0px;
`;

export const MorePostButton = styled.div`
    display: flex;
    justify-content: start;

    margin: 7px 0;
`;

export const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const Select = styled.select`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid black;
    outline: none;

    padding: 5.3px;
`;

// 놀이/상품 순위
export const ActivityGoodsTableWrapper = styled.div`
    .table {
        table-layout: fixed;
        width: 100%;
        text-align: center;
        font-size: 14px;
    }
    .table th {
        background: #e6e6e6;
    }
    .table td,
    .table th {
        padding: 10px 20px;
        border-top: 1px solid #cdcdcd;
        word-break: break-all;
    }
    .table--min {
        min-width: 700px;
    }

    @media screen and (max-width: 768px) {
        /*normal*/
        .table-box {
            overflow-x: auto;
        }
    }
`;

// 클릭률 테이블/반응형
export const ClickPercentageTableWrapper = styled.div`
    font-size: 14px;

    .table {
        padding-right: 30px;
        table-layout: fixed;
        width: 100%;
        text-align: center;
    }
    .table th {
        background: #e6e6e6;
    }
    .table td,
    .table th {
        padding: 7px 0px;
        border-top: 1px solid #cdcdcd;
        word-break: break-all;
    }
    .table--min {
        min-width: 700px;
    }

    .tbody tr td {
        padding: 15px 0;
    }

    @media screen and (max-width: 768px) {
        /*normal*/
        .table-box {
            overflow-x: auto;
        }
    }
`;
