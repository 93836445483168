import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';

// styled-components
import { LeftOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import { ModalWrapper, Box, MobileBox, LoadinLogoWrapper, Alert, SearchingAddressWrapper, ModalBox, SettingDateModal, ChooseDateModalBox } from '../../styles/ReadScheduleModalStyled';
import {
    ImageWrapper,
    ImageBox,
    ImageComponent,
    Image,
    CreateTopWrapper,
    InputWrapper,
    InputWrapper02,
    CheckMainAddressBox,
    SubTitle,
    Textarea,
    Input,
    SearchBoxWrapper,
    Button,
    ButtonWeapper,
    MainPic,
    CustomCloseOutlined,
} from '../../styles/AddGatheringModalStyled';

// API
import RefreshCheckApi from '../../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { main_server_image_ip } from '../../../communication_system/communication_ips';

// useInput
import useInput from '../../../hook/useInput';

// 다음 주소 검색 API
import DaumPostcode from 'react-daum-postcode';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { postSchedule, RESET_STATE } from '../../../data_system/redux/modules/gathering.reducer/gathering.schedule.reducer';
import { requestGetGatheringDetail } from '../../../data_system/redux/modules/gathering.reducer/gathering.reducer';
import SearchingCreaterModal from './SearchingCreaterModal';

// 일정생성 Modal
function CreateScheduleModal({ gatheringID, setGatheringID, setToggle, tableRef, setImageToggle, setSrc }) {
    const dispatch = useDispatch();

    // useState
    const [height, setHeight] = useState(0);
    const [successAlert, setSuccessAlert] = useState(false); // 업데이트 성공 알림 토글
    const [searchCreaterUserToggle, setSearchCreaterUserToggle] = useState(false); // 주최자 찾기 토글
    const [chooseDateToggle, setChooseDateToggle] = useState(false); // 일정선택 토글
    const [searchAddressToggle, setSearchAddressToggle] = useState(false); // 주소검색 토글

    // useSelector
    const { gatheringList } = useSelector((state) => state.getGatheringReducer);
    const { addScheduleLoading, addScheduleDone, addScheduleError } = useSelector((state) => state.scheduleReducer);

    // 데이터 상태 값
    const [createrUserID, setCreaterUserID] = useState(4305);
    const [creater, onChangeCreater, setCreater] = useInput('');
    const [title, onChangeTitle] = useInput('');
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [address, setAddress] = useState('');
    const [detail, onChangeDetail] = useInput('');
    const [maxParticipants, onChangeMaxParticipants] = useInput(0);
    const [price, onChangePrice] = useInput(0);
    const [content, onChangeContent] = useInput('');
    const [shceduleImageLists, setShceduleImageLists] = useState([]);

    /*********************** useEffect ***********************/

    /*
        gathering_id: 58
        gathering_name: "모임 테스트"
        owner_name: "고윤혁"
        phone_no: "01098005226"
        schedule_creater: 9
        title: "일정 with 이미지"
        start_date: "2023-07-19"  (집결일정)
        start_time: "06:00:00"    (집결일정)
        address: "경기 수원시 권선구 고색로18번길 6"
        detail: "string - 상세"
        max_participants: 99
        price: 3000
        explanation: '소개합니다.'
        gathering_image_lists: [이미지 리스트]
    */

    // 선택된 모임 상세정보 가져오기
    useEffect(() => {
        dispatch(requestGetGatheringDetail(gatheringID));
    }, [dispatch, gatheringID]);

    // 테이블 높이적용
    useEffect(() => {
        setHeight(tableRef.current.offsetHeight);
    }, [tableRef]);

    // 모임 일정 등록 성공 시 Alert 창
    useEffect(() => {
        // addScheduleLoading && addScheduleDone;
        if (addScheduleDone) {
            setSuccessAlert(true);

            setTimeout(() => {
                setToggle(false);
                setGatheringID(0);
            }, 1500);
        }

        setTimeout(() => {
            setSuccessAlert(false);

            dispatch({
                type: RESET_STATE,
            });
        }, 1500);
    }, [dispatch, setToggle, setGatheringID, addScheduleDone]);

    /*********************** function ***********************/

    // 모달창 닫기
    const closeModal = () => {
        setToggle(false);
        setGatheringID(0);
    };

    // 선택된 모임 취소
    const initGatheringID = () => {
        setGatheringID(0);
    };

    // alert 창 닫기
    const onClickCloseAlert = () => {
        setSuccessAlert(false);
    };

    // 일정선택 토글
    const onClickChooseDate = () => {
        setChooseDateToggle((prev) => !prev);
    };

    // 주소검색 토글
    const onClickSearchAddress = () => {
        setSearchAddressToggle((prev) => !prev);
    };

    // 다음 주소 검색 스타일 정의 code
    const postCodeStyle = {
        width: '400px',
        height: '700px',
    };

    // 다음 주소검색 결과
    const onCompletePost = (data) => {
        if (data) {
            setSearchAddressToggle(false);
            setAddress(data.roadAddress);
        }
    };

    // 집결일정
    const onChangeDate = (e) => {
        setStartDate(moment(e.target.value).format('YYYY-0M-DD'));
        setStartTime(moment(e.target.value).format('HH:mm:ss'));
    };

    // 종료시간
    const onChangeEndDate = (e) => {
        let endDate = moment(e.target.value).format('YYYY-0M-DD');
        let endTime = moment(e.target.value).format('HH:mm:ss');
        if (Boolean(`${startDate} ${startTime}` < `${endDate} ${endTime}`)) {
            setEndDate(endDate);
            setEndTime(endDate);
        } else {
            alert('시작일보다 종료일자가 빠를 수 없습니다. 확인해주세요.');
        }
    };

    // 모임회원 검색
    const onClickSearchCreaterUser = () => {
        setSearchCreaterUserToggle((prev) => !prev);
    };

    // 일자 추가, 모달 종료
    const onClickAddDateToggle = () => {
        setChooseDateToggle(false);
        // if (Boolean(`${startDate} ${startTime}` < `${endDate} ${endTime}`)) {
        // setChooseDateToggle(false);
        // } else if (!startDate || !startTime || !endDate || !endTime) {
        //   return alert('일자를 확인해주세요.');
        // } else {
        //   return alert('시작일보다 종료일자가 빠를 수 없습니다. 확인해주세요.');
        // }
    };

    /*********************** [등록 저장] ***********************/

    const onClickSave = () => {
        let param = {
            // gathering_contents
            gathering_id: gatheringList.id,
            createrUserID: createrUserID,
            schedule_creater: creater,
            title: title,
            start_date: startDate,
            start_time: startTime,
            end_date: startDate,
            end_time: startTime,
            address: address,
            detail: detail,
            max_participants: maxParticipants,
            price: price,
            content: content,
            schedule_image_lists: shceduleImageLists,
        };

        // 입력값 확인
        if (!gatheringList.id) {
            return alert('모임이 선택되지 않았습니다.');
        } else if (!creater) {
            return alert('주최자를 입력해주세요.');
        } else if (!title) {
            return alert('일정명을 입력해주세요.');
        } else if (!address) {
            return alert('집결장소(주소)를 입력해주세요.');
        } else if (!detail) {
            return alert('집결 상세주소를 입력해주세요.');
        } else if (!maxParticipants) {
            return alert('최대인원을 입력해주세요.');
        } else if (!price) {
            return alert('참가비를 입력해주세요.');
        } else if (!content) {
            return alert('일정 상세 내용을 입력해주세요.');
        }

        if (window.confirm('일정을 생성하시겠습니까?')) {
            dispatch(postSchedule(param));

            if (!addScheduleLoading && addScheduleDone) {
                alert('일정이 등록되었습니다.');
            } else if (addScheduleError) {
                alert('[sever error] 관리자에게 문의해주세요.');
            }
        }
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickAddImage = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback(async (e) => {
        const imageFormData = new FormData();
        [].forEach.call(e.target.files, (f) => {
            imageFormData.append('image', f);
        });

        const response = await RefreshCheckApi.post('/adminGathering/uploadImage', imageFormData);
        setTimeout(() => {
            setShceduleImageLists(response.data);
        }, 1100);
    }, []);

    // 이미지 제거
    const onClickDeleteImage = (index) => () => {
        let arr = [...shceduleImageLists];
        arr.splice(index, 1);

        setShceduleImageLists(arr);
    };

    // 이미지 크게보기
    const onClickShowImage = (src) => () => {
        setSrc(src);
        setImageToggle((prev) => !prev);
    };

    /************************* html *************************/
    return (
        <>
            {successAlert && (
                <Alert>
                    <CloseOutlined className="close" onClick={onClickCloseAlert} />
                    <div>모임 일정이 저장되었습니다.</div>
                </Alert>
            )}

            {searchCreaterUserToggle && (
                <SearchingCreaterModal setSearchCreaterUserToggle={setSearchCreaterUserToggle} setCreaterUserID={setCreaterUserID} setCreater={setCreater} gatheringId={gatheringList.id} />
            )}

            {chooseDateToggle && (
                <SettingDateModal>
                    <ChooseDateModalBox>
                        <div className="close-window">
                            <CloseOutlined className="close-icon" onClick={() => setChooseDateToggle(false)} />
                        </div>

                        <h2 className="title">일정선택</h2>
                        <h4>시작시간</h4>
                        <input className="date" type="datetime-local" onChange={onChangeDate} />
                        {/* <h4>종료시간</h4>
                        <input className="date" type="datetime-local" onChange={onChangeEndDate} /> */}

                        <div className="add-btn" onClick={onClickAddDateToggle}>
                            추가
                        </div>
                    </ChooseDateModalBox>
                </SettingDateModal>
            )}

            {searchAddressToggle && (
                <SearchingAddressWrapper>
                    <ModalBox>
                        <div className="close-window">
                            <CloseOutlined className="close-icon" onClick={onClickSearchAddress} />
                        </div>
                        <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} />
                    </ModalBox>
                </SearchingAddressWrapper>
            )}

            <MobileBox>
                <ModalWrapper>
                    <Box height={height} limit={10} style={{ position: 'relative' }}>
                        <CreateTopWrapper style={{ position: 'absolute', width: '378px' }}>
                            <LeftOutlined className="close-button" onClick={initGatheringID} />
                            <div>일정생성</div>
                        </CreateTopWrapper>

                        {false ? (
                            <LoadinLogoWrapper>
                                <LoadingOutlined />
                                <div className="text">loading...</div>
                            </LoadinLogoWrapper>
                        ) : (
                            <>
                                <InputWrapper id="gathering-name" style={{ marginTop: '17%' }}>
                                    <SubTitle>모임ID</SubTitle>
                                    <Input type="text" value={gatheringList?.id || '선택 모임정보 없음'} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>모임명</SubTitle>
                                    <Input type="text" value={gatheringList?.gathering_name} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>모임장 명</SubTitle>
                                    <Input type="text" value={gatheringList?.manager_name || '정보없음'} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>
                                        <div>모임장</div>
                                        <div>전화번호</div>
                                    </SubTitle>
                                    <Input type="text" value={gatheringList?.manager_phone_no} readOnly disabled />
                                </InputWrapper>

                                <InputWrapper>
                                    <SubTitle>주최자 명</SubTitle>
                                    <SearchBoxWrapper>
                                        <Input type="text" placeholder="주최자분의 성함을 입력해주세요." value={creater} onChange={onChangeCreater} />
                                        <Button onClick={onClickSearchCreaterUser}>회원찾기</Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>일정 명</SubTitle>
                                    <Input type="text" placeholder="일정명을 입력해주세요." onChange={onChangeTitle} />
                                </InputWrapper>

                                <InputWrapper>
                                    <SubTitle>집결 일정</SubTitle>
                                    <SearchBoxWrapper>
                                        <Input type="text" placeholder="집결일정을 선택해주세요." value={`${startDate} ${startTime}`} />
                                        <Button onClick={onClickChooseDate}>일정선택</Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                {false && (
                                    <CheckMainAddressBox style={{ marginLeft: '28.8%' }}>
                                        <Input type="text" placeholder="집결 종료일정을 선택해주세요." value={`${endDate} ${endTime}`} style={{ width: '86%' }} />
                                    </CheckMainAddressBox>
                                )}

                                <InputWrapper>
                                    <SubTitle>집결 장소</SubTitle>
                                    <SearchBoxWrapper>
                                        <Input type="text" placeholder="주소" value={address} readOnly />
                                        <Button onClick={onClickSearchAddress}>주소검색</Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <CheckMainAddressBox style={{ marginLeft: '28.8%' }}>
                                    <Input style={{ width: '86%' }} type="text" placeholder="상세주소" value={detail} onChange={onChangeDetail} />
                                </CheckMainAddressBox>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>최대인원</SubTitle>
                                    <Input type="text" placeholder="0명" onChange={onChangeMaxParticipants} />
                                </InputWrapper>

                                <InputWrapper id="gathering-name">
                                    <SubTitle>참가비</SubTitle>
                                    <Input type="text" placeholder="0원" onChange={onChangePrice} />
                                </InputWrapper>

                                <InputWrapper02 id="gathering-introduce">
                                    <SubTitle>일정 상세 내용</SubTitle>
                                    <Textarea
                                        type="text"
                                        placeholder="*일정 소개를 해주세요!&#13;&#10;*2차 장소가 있다면 시간과 장소를&#13;&#10;알려주면 좋아요!"
                                        value={content}
                                        onChange={onChangeContent}
                                    />
                                </InputWrapper02>

                                <InputWrapper>
                                    <SubTitle>모바일 이미지</SubTitle>

                                    <SearchBoxWrapper>
                                        <Input
                                            type="text"
                                            placeholder="일정 이미지가 없습니다."
                                            readOnly
                                            disabled
                                            value={shceduleImageLists?.map((url) => {
                                                return url;
                                            })}
                                        />
                                        <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                                        <Button onClick={onClickAddImage}>찾기</Button>
                                    </SearchBoxWrapper>
                                </InputWrapper>

                                <ImageWrapper>
                                    <ImageBox>
                                        {shceduleImageLists?.map((src, index) => {
                                            return (
                                                <ImageComponent key={index}>
                                                    <CustomCloseOutlined onClick={onClickDeleteImage(index)} />

                                                    <Image key={index} src={main_server_image_ip + src} alt="일정사진" onClick={onClickShowImage(main_server_image_ip + src)} />

                                                    {index === 0 ? <MainPic>대표사진</MainPic> : null}
                                                </ImageComponent>
                                            );
                                        })}
                                    </ImageBox>
                                </ImageWrapper>

                                <ButtonWeapper style={{ marginBottom: '10%', marginTop: '10%' }}>
                                    <button className="cencle" onClick={closeModal}>
                                        취소
                                    </button>

                                    <button className="save" onClick={onClickSave}>
                                        등록
                                    </button>
                                </ButtonWeapper>
                            </>
                        )}
                    </Box>
                </ModalWrapper>
            </MobileBox>
        </>
    );
}

export default CreateScheduleModal;
