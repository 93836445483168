import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 놀이정보 조회
export const GET_ACTIVITY_LISTS_REQUEST = 'GET_ACTIVITY_LISTS_REQUEST';
export const GET_ACTIVITY_LISTS_SUCCESS = 'GET_ACTIVITY_LISTS_SUCCESS';
export const GET_ACTIVITY_LISTS_FAILURE = 'GET_ACTIVITY_LISTS_FAILURE';
// 놀이 상세조회
export const GET_ACTIVITY_DETAIL_REQUEST = 'GET_ACTIVITY_DETAIL_REQUEST';
export const GET_ACTIVITY_DETAIL_SUCCESS = 'GET_ACTIVITY_DETAIL_SUCCESS';
export const GET_ACTIVITY_DETAIL_FAILURE = 'GET_ACTIVITY_DETAIL_FAILURE';
// 놀이 업데이트
export const UPDATE_ACTIVITY_REQUEST = 'UPDATE_ACTIVITY_REQUEST';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    activityLists: null, // 놀이정보 데이터
    activityDetail: null, // 놀이 상세정보 데이터
    reviewLists: null, // 리뷰 데이터
    // 놀이정보 조회
    loadActivityListsLoading: false,
    loadActivityListsDone: false,
    loadActivityListsError: null,
    // 놀이 상세정보 조회
    loadActivityDetailLoading: false,
    loadActivityDetailDone: false,
    loadActivityDetailError: null,
    // 놀이 업데이트
    loadActivityUpdateLoading: false,
    loadActivityUpdateDone: false,
    loadActivityUpdateError: null,
    // 리뷰 조회
    loadReviewListsLoading: false,
    loadReviewListsDone: false,
    loadReviewListsError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 놀이조회
export const requestActiviyLists = (params) => async (dispatch) => {
    dispatch({
        type: GET_ACTIVITY_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get('/adminActivity', { params });

        dispatch({
            type: GET_ACTIVITY_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ACTIVITY_LISTS_FAILURE,
            error: error,
        });
    }
};

// 놀이상세조회
export const requestActiviyDetail = (id) => async (dispatch) => {
    dispatch({
        type: GET_ACTIVITY_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminActivity/${id}`);
        dispatch({
            type: GET_ACTIVITY_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ACTIVITY_DETAIL_FAILURE,
            error: error,
        });
    }
};

// 놀이 정보 업데이트
export const updateActivity = (param) => async (dispatch) => {
    dispatch({
        type: UPDATE_ACTIVITY_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.put(`/adminActivity/${param.activity_id}`, { param });
        dispatch({
            type: UPDATE_ACTIVITY_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ACTIVITY_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const activityReducer = handleActions(
    {
        // 놀이정보 조회
        [GET_ACTIVITY_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityListsLoading = true;
                draft.loadActivityListsDone = false;
                draft.loadActivityListsError = null;
            }),
        [GET_ACTIVITY_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityListsLoading = false;
                draft.activityLists = action.payload;
                draft.loadActivityListsDone = true;
            }),
        [GET_ACTIVITY_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityListsLoading = false;
                draft.loadActivityListsError = action.error;
            }),

        // 놀이상세조회
        [GET_ACTIVITY_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityDetailLoading = true;
                draft.loadActivityDetailDone = false;
                draft.loadActivityDetailError = null;
            }),
        [GET_ACTIVITY_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityDetailLoading = false;
                draft.activityDetail = action.payload;
                draft.loadActivityDetailDone = true;
            }),
        [GET_ACTIVITY_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityDetailLoading = false;
                draft.loadActivityDetailError = action.error;
            }),

        // 놀이정보수정
        [UPDATE_ACTIVITY_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityUpdateLoading = true;
                draft.loadActivityUpdateDone = false;
                draft.loadActivityUpdateError = null;
            }),
        [UPDATE_ACTIVITY_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityUpdateLoading = false;
                draft.loadActivityUpdateDone = true;
            }),
        [UPDATE_ACTIVITY_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.loadActivityUpdateLoading = false;
                draft.loadActivityUpdateError = action.error;
            }),
    },
    initialState,
);

export default activityReducer;
