import {
    getActivityPayments,
    getActivityPaymentsSearch,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import {GET_ACTIVITY_PAYMENTS} from "./activityPayments_table_type";

export const getActivityPaymentsAsync = createGetRequestThunk(
  GET_ACTIVITY_PAYMENTS,
  getActivityPayments
);

export const getActivityPaymentsSearchAsync = createGetRequestThunk(
  GET_ACTIVITY_PAYMENTS,
  getActivityPaymentsSearch
);
