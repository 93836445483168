import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  GET_SUBSCRIBE_PAYMENTS_DETAIL_FAILURE,
  GET_SUBSCRIBE_PAYMENTS_DETAIL_SUCCESS,
  POST_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_FAILURE,
  GET_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_SUCCESS,
  PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE,
  PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS,
  PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_FAILURE,
  PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_SUCCESS,
} from './subscribePayments_detail/subscribePayments_detail_type';
import {
  GET_SUBSCRIBE_PAYMENTS_FAILURE,
  GET_SUBSCRIBE_PAYMENTS_SUCCESS,
} from './subscribePayments_table/subscribePayments_table_type';

const initialState = {
  subscribePayments: null,
  subscribePaymentsDetail: null,
};

const subscribePaymentsReducer = handleActions(
  {
    [GET_SUBSCRIBE_PAYMENTS_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        draft.subscribePayments = action.payload;
      }),
    [GET_SUBSCRIBE_PAYMENTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
    [GET_SUBSCRIBE_PAYMENTS_DETAIL_SUCCESS]: (state, action) => {
      return produce(state, (draft) => {
        draft.subscribePaymentsDetail = action.payload;
      });
    },
    [GET_SUBSCRIBE_PAYMENTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
    [PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        for (let i = 0; i < draft.subscribePayments.length; i++) {
          if (draft.subscribePayments[i]['id'] === action.payload['pay_id']) {
            draft.subscribePayments[i]['data'][9]['data'] = action.payload['admin_memo'];
          }
        }
      }),
    [PUT_SUBSCRIBE_PAYMENTS_DETAIL_ADMIN_MEMO_FAILURE]: (state, action) => produce(state, (draft) => {}),
    [PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_SUCCESS]: (state, action) => produce(state, (draft) => {}),
    [PUT_SUBSCRIBE_PAYMENTS_DETAIL_DESCRIBE_ADD_FAILURE]: (state, action) => produce(state, (draft) => {}),
    [GET_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_SUCCESS]: (state, action) =>
      produce(state, (draft) => {
        for (let i = 0; i < draft.subscribePayments.length; i++) {
          if (draft.subscribePayments[i]['id'] === action.payload['pay_id']) {
            draft.subscribePayments[i]['data'][1]['data'] = 4;
          }
        }
      }),
    [POST_SUBSCRIBE_PAYMENTS_DETAIL_REFUND_FAILURE]: (state, action) => produce(state, (draft) => {}),
  },
  initialState,
);

export default subscribePaymentsReducer;
