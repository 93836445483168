import moment from 'moment';

/* eslint-disable array-callback-return */
const xlsx = require('xlsx');

export const onClickExcelFormat = (header, body, fileName) => {
    const array = [header, ...body];

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

export const arrayToExcel = (header, body, fileName) => {
    const array = [];
    const keys = header.map((e) => e.key);

    array.push(header.map((e) => e.value));

    body.map((e) => {
        const tmp = [];

        keys.map((key) => {
            if (key === 'date_info') {
                return tmp.push(e[key].slice(0, 10));
            }
            return tmp.push(Number(e[key] ?? 0));
        });
        array.push(tmp);
    });

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

// 배열을 재나열 하는 함수
function refreshArray(data) {
    let array = [];

    data.map((value) => {
        let arr = Object.values(value);
        let acc = Array(arr.length + 1);

        arr.map((cur, idx) => {
            if (typeof cur === 'string') {
                acc.unshift(cur);
                acc.pop();
            } else {
                acc[idx + 1] = cur;
            }
        });

        acc[1] = acc[2] + acc[3];
        array.push(acc);
    });

    return array;
}

// 고객데이터 객체 형식으로 넘겨 받는 함수
export const arrayToExcelForObj = (header, total, body, fileName) => {
    const array = [];

    const newHeader = Object.values(header);
    let topHeader = Array(6);
    let middleHeader = Array(6);

    newHeader.map((value) => {
        // top header
        if (value === '결혼상태') topHeader[0] = value;
        if (value === '성별') topHeader[2] = value;
        if (value === '비율') topHeader[4] = value;

        // middle header
        if (value === 'TOTAL') middleHeader[1] = value;
        if (value === '남') {
            middleHeader[2] = value;
            middleHeader[4] = value;
        }
        if (value === '여') {
            middleHeader[3] = value;
            middleHeader[5] = value;
        }
    });

    array.push(topHeader);
    array.push(middleHeader);
    array.push(...refreshArray(total));
    array.push(...refreshArray(body));

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

/** 매출통계 엑셀 */
export const onClickPaymentExcelFormat = (header, body, fileName) => {
    const array = [];
    const keys = header.map((e) => e.key);
    array.push(header.map((e) => e.value));

    body.map((e) => {
        const tmp = [];

        keys.map((key) => {
            return tmp.push(e[key] ?? 0);
        });
        array.push(tmp);
    });

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};

export const onClickGatheringCrewListsExcel = (gathering_name, header, body, fileName) => {
    const array = [[gathering_name, `총 ${body.length}명`]];
    const keys = header.map((e) => e.key);
    array.push(header.map((e) => e.value));

    body.map((e) => {
        const tmp = [];
        keys.map((key) => {
            if (key === 'created_at') {
                if (!e[key]) tmp.push('정보없음' ?? 0);
                else tmp.push(moment(e[key]).format('YYYY-MM-DD HH:mm:ss') ?? 0);
            } else if (key === 'crew_created_at') {
                if (!e[key]) tmp.push('정보없음' ?? 0);
                else tmp.push(moment(e[key]).format('YYYY-MM-DD HH:mm:ss') ?? 0);
            } else if (key === 'role') {
                if (e[key] === 1) tmp.push('일반' ?? 0);
                if (e[key] === 2) tmp.push('운영진' ?? 0);
                if (e[key] === 3) tmp.push('모임장' ?? 0);
                if (!e[key]) tmp.push('정보없음' ?? 0);
            } else if (key === 'sex') {
                if (e[key] === '1') tmp.push('남자' ?? 0);
                else if (e[key] === '2') tmp.push('여자' ?? 0);
                else if (!e[key]) tmp.push('정보없음' ?? 0);
            } else if (key === 'gatherin_deleted_at') {
                if (e[key]) tmp.push('탈퇴' ?? 0);
                if (!e[key]) tmp.push('-' ?? 0);
            } else if (key === 'marketing_agree_yn') {
                if (e[key]) tmp.push('Y' ?? 0);
                if (!e[key]) tmp.push('N' ?? 0);
            } else if (key === 'private_info_agree_yn') {
                if (e[key]) tmp.push('Y' ?? 0);
                if (!e[key]) tmp.push('N' ?? 0);
            } else if (key === 'service_agree_yn') {
                if (e[key]) tmp.push('Y' ?? 0);
                if (!e[key]) tmp.push('N' ?? 0);
            } else {
                if (!e[key]) return tmp.push('정보없음' ?? 0);
                else return tmp.push(e[key] ?? 0);
            }
        });
        array.push(tmp);
    });

    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet(array);

    xlsx.utils.book_append_sheet(book, sheet);
    xlsx.writeFile(book, fileName);
};
