import React, { useEffect, useState } from 'react';

// styled-components
import { ModalWindow, ChooseIntersetBox, TitleLineWrapper, Title, ButtonWrapper, MinButton, MoreButton } from './QuestionStyled';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';

// react-query
import { useMutation } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import AddQuestionEntry from './AddQuestionEntry';
import LoadingPage from '../../pages/loading/LoadingPage';

function AddQuestionModal({ setToggle }) {
    // state
    const [addData, setAddData] = useState([]);
    const [count, setCount] = useState(1);

    // eslint-disable-next-line no-unused-vars
    const { mutate, isLoading, isError, error, isSuccess } = useMutation('create', async () => {
        let createData = await RefreshCheckApi.post(`/adminQuestion/create`, {
            dataLists: addData,
        }).then((res) => {
            let message = res.data.message;
            alert(message);

            return res.data.message;
        });

        return createData;
    });

    useEffect(() => {
        if (isSuccess) {
            setAddData([]);
            setCount(1);
        }
    }, [isSuccess]);

    // 모달창 닫기
    const onClickClose = () => {
        setToggle(false);
    };

    // 추가 질문생성
    const onClickMoreQuestion = () => {
        let _count = count;
        _count += 1;

        setCount(_count);
    };

    // 추가질문 제거
    const onClickMinQuestion = () => {
        let _count = count;
        _count -= 1;

        let arr = addData;
        arr.pop();

        setAddData(arr);
        setCount(_count);
    };

    // dispatch
    const onClickDispatch = () => {
        if (parseInt(addData.length)) {
            if (parseInt(addData.length) !== count) {
                alert('입력되지 않은 정보가 있습니다.');
            } else {
                mutate();
            }
        } else {
            alert('작성된 질문이 없습니다.');
        }
    };

    if (isLoading) {
        return (
            <ModalWindow>
                <ChooseIntersetBox>
                    <LoadingPage text={'데이터를 불러오는 중입니다.'} />;
                </ChooseIntersetBox>
            </ModalWindow>
        );
    } else if (error) {
        return (
            <ModalWindow>
                <ChooseIntersetBox>
                    <LoadingPage text={error} />;
                </ChooseIntersetBox>
            </ModalWindow>
        );
    } else {
        return (
            <ModalWindow>
                <TitleLineWrapper>
                    <Title>질문 등록</Title>
                    <CloseOutlined className="close-btn" onClick={onClickClose} />
                </TitleLineWrapper>

                <ChooseIntersetBox>
                    <div id="question-input">
                        {Array(count)
                            .fill()
                            .map((_, index) => {
                                return <AddQuestionEntry key={index} index={index} addData={addData} setAddData={setAddData} count={count} setCount={setCount} isSuccess={isSuccess} />;
                            })}
                    </div>

                    <div id="more-add-question">
                        {count > 1 && (
                            <MinButton onClick={onClickMinQuestion}>
                                <MinusOutlined />
                            </MinButton>
                        )}

                        <MoreButton onClick={onClickMoreQuestion}>
                            <PlusOutlined />
                        </MoreButton>
                    </div>
                </ChooseIntersetBox>

                <ButtonWrapper>
                    <button className="update" onClick={onClickDispatch}>
                        저장
                    </button>
                    <button className="cancle" onClick={onClickClose}>
                        취소
                    </button>
                </ButtonWrapper>
            </ModalWindow>
        );
    }
}

export default AddQuestionModal;
