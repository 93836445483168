import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// styled-components
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { DeleteOutlined, FontSizeOutlined, MoreOutlined, UnorderedListOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { requestGetSurveyLists } from '../../data_system/redux/modules/survey.reducer/survey.reducer';

// components
import SurveyUpdateModal from '../../components/SurveyComponents/SurveyUpdateModal';
import LoadingPage from '../../pages/loading/LoadingPage';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

/** 설문관리 페이지 */
const Survey = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useSelector
    const { surveyLists, getSurveyLoading } = useSelector((state) => state.getSurveyReducer);

    // state-toggle
    const [updateToggle, setUpdateToggle] = useState(false);

    // state
    const [surveyID, setSurveyID] = useState(0);
    const [surveyName, setSurveyName] = useState(0);
    const [data, setData] = useState({});
    useEffect(() => {
        dispatch(requestGetSurveyLists());
    }, [dispatch]);
    const onClickUpdateToggle = (id, name) => () => {
        setUpdateToggle(true); // 수정 모달창 열기

        setSurveyID(id); // ID 적용
        setSurveyName(name); // 이름 적용
    };

    // 삭제
    const onClickDeleteToggle = (id) => () => {
        if (window.confirm('선택하신 설문주제를 삭제하시겠습니까?')) {
        }
    };

    // drop-down item
    const items = [
        {
            label: (
                <div className="name" onClick={onClickUpdateToggle(data.id, data.name)}>
                    <ToggleListsBox>
                        <FontSizeOutlined id="icon" />
                        <span>이름 바꾸기</span>
                    </ToggleListsBox>
                </div>
            ),
        },
        {
            label: (
                <div className="delete" onClick={onClickDeleteToggle(data.id)}>
                    <ToggleListsBox>
                        <DeleteOutlined id="icon" />
                        <span>삭제</span>
                    </ToggleListsBox>
                </div>
            ),
        },
    ];

    // 설문 주제 상세 페이지 이동
    const onClickSurveyDetail = (survey_id) => () => {
        return navigate(`/survey/management/detail/${survey_id}`);
    };

    // 리스트 열기 > 선택 데이터 적용
    const onClickListsToggle = (data) => () => {
        setData(data);
    };

    // /* background-color: rgb(240 235 248); */
    /********************************** html **********************************/
    return (
        <Wrapper02>
            {updateToggle && <SurveyUpdateModal setToggle={setUpdateToggle} id={surveyID} name={surveyName} />}
            <MainPageTitle text={'설문관리'} />

            {getSurveyLoading ? (
                <LoadingPage text={'설문조사 목록을 불러오는 중입니다.'} />
            ) : (
                <div id="lists-box" style={{ width: '50%', margin: 'auto' }}>
                    {surveyLists?.map((data, index) => {
                        return (
                            <div key={index} style={{ margin: 'auto' }}>
                                {surveyLists[index - 1]?.created_at !== surveyLists[index]?.created_at ? (
                                    <DateItem key={index} className="date">
                                        <div>{moment(data.created_at).format('YYYY년 MM월 DD일')}</div>
                                    </DateItem>
                                ) : null}

                                <ItemWrapper className="lists">
                                    <Item style={{ justifyContent: 'start' }}>
                                        <Item onClick={onClickSurveyDetail(data.id)}>
                                            <DateWrapper style={{ gap: '10px', padding: '0 20px' }}>
                                                <UnorderedListOutlined />
                                                <div>{data.name}</div>
                                            </DateWrapper>

                                            <DateWrapper>
                                                <Date>
                                                    {moment(data.start_date).format('YYYY.MM.DD')} ~ {moment(data.end_date).format('YYYY.MM.DD')}
                                                </Date>
                                            </DateWrapper>
                                        </Item>

                                        <Dropdown menu={{ items }} trigger={['click']}>
                                            <CustomMoreOutlined onClick={onClickListsToggle(data)} />
                                        </Dropdown>
                                    </Item>

                                    {surveyLists[index]?.created_at !== surveyLists[index + 1]?.created_at || index === surveyLists?.length ? null : <Line className="under-line" />}
                                </ItemWrapper>
                            </div>
                        );
                    })}
                </div>
            )}
        </Wrapper02>
    );
};

export default Survey;

/********************************** styled **********************************/

const MainRightSideTitle = styled.div`
    cursor: pointer;
    font-size: 26px;
    font-family: noto_sans_kr_bold;
    color: rgb(0 0 0);

    font-weight: ${(props) => (props.point ? '600' : '')};
    text-decoration: ${(props) => (props.point ? 'underline' : '')};

    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;
`;

const DateItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    margin: 15px 0 20px 17px;
`;

const ItemWrapper = styled.div`
    cursor: pointer;

    :hover {
        background-color: rgba(66, 139, 247, 0.1); // #7248b92e;
        border-radius: 30px;
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 7px 0;
    width: 100%;

    font-size: 13px;
    font-weight: 500;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
`;

const CustomMoreOutlined = styled(MoreOutlined)`
    cursor: pointer;
    font-weight: bold;
    font-size: 25px;

    margin-right: 10px;

    :hover {
        background-color: #cccccc;
        border-radius: 50%;
    }
`;

const Line = styled.div`
    border-bottom: 1px solid #e3e3e3;

    width: 95.8%;
    margin: auto;
`;

const Date = styled.div`
    margin-right: 20px;
    color: #5f6368;
`;

const ToggleListsBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    width: 150px;
    text-align: start;

    margin: 3px 0;
`;
