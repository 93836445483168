import styled from 'styled-components';
import { h_px36rate, h_px6rate, w_px11rate, w_px160rate, w_px8rate } from '../../size';
import { rgb_0_0_0, rgb_255_255_255, rgba_66_139_247_0d2 } from '../../colors';

export const SearchInput = styled.input`
    padding-top: ${h_px6rate}px;
    padding-bottom: ${h_px6rate}px;
    padding-left: ${w_px8rate}px;
    padding-right: ${w_px8rate}px;
    width: ${w_px160rate}px;
    height: ${h_px36rate}px;
    border: 1px solid ${rgba_66_139_247_0d2};
    font-size: ${w_px11rate}px;
    color: ${rgb_0_0_0};
    font-family: noto_sans_kr_medium;
    box-sizing: border-box;
    margin: 0px;
    &:focus {
        outline: none;
    }
    &:disable {
        background-color: ${rgb_255_255_255};
    }
`;
export const TextInputNoLine = styled.input`
    outline: none;

    padding-top: ${h_px6rate}px;
    padding-bottom: ${h_px6rate}px;
    padding-left: ${w_px8rate}px;
    padding-right: ${w_px8rate}px;

    font-family: noto_sans_kr_medium;

    box-sizing: border-box;
    border: 0px;
    /* border-shadow: none; */

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
`;
export const TextInput = styled.input`
    outline: none;
    margin: 0px;
    padding: 8px; // 6px 8px 6px 9px

    font-size: 15px;
    font-weight: bold;
    font-family: noto_sans_kr_medium;

    background-color: rgb(241 241 241); // rgb(255 255 255)
    box-sizing: border-box;
    border: 0px;
`;
