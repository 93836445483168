import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;

    margin: 0 7%;

    #label {
        display: none;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;

    margin: 0 7%;

    .update {
        border: 1px solid #ff0000;
        color: #ff0000;
    }
    .update:hover {
        background-color: #ffe9e9;
    }

    .save {
        border: 1px solid #428bf7;
        color: #428bf7;
    }
    .save:hover {
        background-color: #f2f2fc;
    }
`;

export const Button = styled.button`
    cursor: pointer;
    width: 70px;
    height: 28px;

    background-color: #fff;
`;

export const InfoWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 50px;

    @media (max-width: 912px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export const ManagerInfoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: start;
    gap: 10px;

    margin: 0;
`;

export const CardWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 10px;
`;

export const GatheringImage = styled.img`
    cursor: pointer;
    width: 135px;
    height: 135px;

    object-fit: cover;
`;

export const ManagerImage = styled.img`
    cursor: pointer;
    width: 110px;
    height: 110px;

    object-fit: cover;
`;

export const BoxWrapper = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 23px;

    @media (max-width: 912px) {
        flex-direction: column;
        gap: 10px;
    }
`;

export const ManagerTitle = styled.div`
    margin: 0;

    font-size: 14px;
    font-weight: bold;
`;

export const ExplanationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    margin-bottom: 7px;
`;

export const SubTitle = styled.div`
    width: 70px;

    color: #797979;
    font-size: 13px;
`;

export const Input = styled.input`
    outline: none;
    border: 1px solid #428bf72e;

    padding: 3px 5px;

    width: 60%;
    height: 20px;
`;

export const SubText = styled.div`
    /* width: 145px; */
    padding: 7px 6px;
    color: black;
    font-size: 14px;
`;

export const UserTitle = styled.div`
    width: auto;

    color: black;
    font-size: 13px;
    font-weight: 500;
`;

export const ManagerExplanationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 2px;

    margin-right: 20px;
    margin-top: 15px;
`;

export const UserInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 7px;
`;

export const ImgWrapper = styled.div`
    cursor: pointer;
    position: relative;

    .preview {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        background-color: #00000099;
        padding: 43% 0;

        color: #fff;
        text-align: center;
    }

    :hover {
        .preview {
            display: block;
        }
    }
`;

export const ManagerImgWrapper = styled.div`
    cursor: pointer;
    position: relative;

    .preview {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        background-color: #00000099;
        padding: 41% 0;

        color: #fff;
        text-align: center;
    }

    :hover {
        .preview {
            display: block;
        }
    }
`;

export const ImageModalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 4;

    background-color: #000000c9;
    text-align: center;

    width: 100%;
    height: 100vh;
`;

export const CustomCloseOutlined = styled(CloseOutlined)`
    position: fixed;
    top: 0;
    right: 0;

    padding: 30px;

    color: #fff;
    font-size: 35px;
    font-weight: bold;
`;

export const ModalImage = styled.img`
    width: 500px;
`;

export const AddressBtnWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const AddressButton = styled.button`
    cursor: pointer;
    width: 48px;
    height: 28px;

    border: 1px solid #428bf7;
    color: #428bf7;
    background-color: #fff;

    :hover {
        background-color: #f2f2fc;
    }
`;

export const DaumSearchAddress = styled.div`
    position: fixed;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    text-align: end;
    width: 100%;
`;

export const TopWindow = styled.div`
    width: 400px;
    background-color: #e3e3e3;
    padding: 5px 1px;

    .close {
        font-size: 25px;
        font-weight: bold;
        padding: 0 5px;
    }
`;

export const ImageComponent = styled.div`
    position: relative;
    display: inline-block;

    width: 110px;
    height: 110px;
`;

export const MainPic = styled.div`
    position: absolute;
    bottom: 0;

    padding: 5px 0;

    width: 100%;
    text-align: center;

    background-color: #0000009c;
    color: #fff;

    font-size: 12px;
`;

export const UpdateCloseOutlined = styled(CloseOutlined)`
    position: absolute;
    top: 1px;
    right: 0;

    padding: 3px;

    color: #fff;
    font-size: 23px;
    font-weight: bold;
`;

export const SearchBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    margin-bottom: 3px;

    width: 100%;
`;

export const UpdateInput = styled.input`
    outline: none;
    border: 1px solid #428bf72e;

    padding: 3px 5px;

    width: 20%;
    height: 20px;
`;

export const UpdateButton = styled.button`
    cursor: pointer;
    width: 70px;
    height: 28px;

    border: 1px solid #428bf72e;
    background-color: #fff;
`;

export const Status = styled.div`
    width: 37px;
    height: 17px;
    line-height: 19px;

    text-align: center;
    font-weight: bold;
    font-size: 13px;

    color: ${(props) => (props.color ? 'rgb(66, 139, 247)' : 'rgb(255, 16, 125)')};
    border: ${(props) => (props.color ? '1px solid rgb(66, 139, 247)' : '1px solid rgb(255, 183, 216)')};
    background-color: ${(props) => (props.color ? 'rgba(66, 139, 247, 0.08)' : 'rgb(255, 227, 240)')};
`;

export const Textarea = styled.textarea`
    outline: none;

    width: 42%;
    height: 25vh;

    padding: 5px;

    border: 1px solid #428bf72e;
`;
