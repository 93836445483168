import {
	ChatHeaderTh,
	DateHeaderTh,
	NewActivityHeaderTh,
	NewUserAccumulateHeaderTh, Table
} from "../../components/styles/UserActivityTableStyled";
import React from "react";

const AllUserActivityTable  = ({data}) => {
	return (
		<Table>
			<thead id="thead">
			<tr>
				<DateHeaderTh rowSpan={2}>일자</DateHeaderTh>
				<NewUserAccumulateHeaderTh colSpan={4}>
					<span className="count-title">신규가입 / 현재 회원수 / 누적회원수 </span>
				</NewUserAccumulateHeaderTh>
				<NewActivityHeaderTh colSpan={4}>
					<div>신규회원 활성도</div>
					<span className="count-title">[ 일자 기준 / 누적 ]</span>
				</NewActivityHeaderTh>

				<ChatHeaderTh colSpan={2}>채팅수</ChatHeaderTh>
				<ChatHeaderTh rowSpan={2}>편지수</ChatHeaderTh>

			</tr>
			<tr>
				<th style={{ textAlign: 'center' }}>남자</th>
				<th style={{ textAlign: 'center' }}>여자</th>
				<th style={{ textAlign: 'center' }}>NULL</th>
				<th style={{ textAlign: 'center' }}>TOTAL</th>
				<th style={{ textAlign: 'center' }}>0-9</th>
				<th style={{ textAlign: 'center' }}>10-29</th>
				<th style={{ textAlign: 'center' }}>30-50</th>
				<th style={{ textAlign: 'center' }}>50-100</th>
				<th style={{ textAlign: 'center' }}>개인 채팅</th>
				<th style={{ textAlign: 'center' }}>그룹 채팅</th>
			</tr>
			</thead>

			<tbody id="tbody">
			{data?.map((data, index) => {
				return (<tr key={data.id || index} style={{ fontSize: '10px' }}>
					<td style={{ borderRight: '1px solid rgb(205, 205, 205)' }}>{data.date_info}</td>
					<td>
								<span
									className="count">{(data.new_user_male || 0).toLocaleString('ko-KR')} / {(data.user_male || 0).toLocaleString('ko-KR')} / {(data.user_male_accumulate || 0).toLocaleString('ko-KR')}</span>
					</td>
					<td>
                                        <span
											className="count">{(data.new_user_female || 0).toLocaleString('ko-KR')} / {(data.user_female || 0).toLocaleString('ko-KR')} / {(data.user_female_accumulate || 0).toLocaleString('ko-KR')}</span>
					</td>
					<td>
                                        <span
											className="count">{(data.new_user_null || 0).toLocaleString('ko-KR')} / {(data.user_null || 0).toLocaleString('ko-KR')} / {(data.user_null_accumulate || 0).toLocaleString('ko-KR')}</span>
					</td>
					<td style={{ borderRight: '1px solid rgb(205, 205, 205)' }}>
                                        <span
											className="count">{(data.new_user || 0).toLocaleString('ko-KR')} / {(data.user_current || 0).toLocaleString('ko-KR')} / {(data.user_current_accumulate || 0).toLocaleString('ko-KR')}</span>
					</td>
					<td>
						{data.com_0_10 || 0} / {(data.com_0_10_accumulate || 0).toLocaleString('ko-KR')}
					</td>
					<td>
						{data.com_10_30 || 0} / {(data.com_10_30_accumulate || 0).toLocaleString('ko-KR')}
					</td>
					<td>
						{data.com_30_50 || 0} / {(data.com_30_50_accumulate || 0).toLocaleString('ko-KR')}
					</td>
					<td style={{ borderRight: '1px solid rgb(205, 205, 205)' }}>
						{data.com_50_100 || 0} / {(data.com_50_100_accumulate || 0).toLocaleString('ko-KR')}
					</td>

					<td style={{ borderRight: '1px solid rgb(205, 205, 205)' }}>
						{data.chatting?.toLocaleString('ko-KR')} / {(data.chatting_accumulate || 0).toLocaleString('ko-KR')}
					</td>
					<td style={{ borderRight: '1px solid rgb(205, 205, 205)' }}>
						{data.group_chatting?.toLocaleString('ko-KR')} / {(data.group_chatting_accumulate || 0).toLocaleString('ko-KR')}
					</td>
					<td style={{ borderRight: '1px solid rgb(205, 205, 205)' }}>
						{data.letter?.toLocaleString('ko-KR')} / {(data.letter_accumulate || 0).toLocaleString('ko-KR')}
					</td>

				</tr>);
			})}
			</tbody>
		</Table>

	)

}


export default AllUserActivityTable