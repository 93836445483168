import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import CustomerWithdrawReasonModal from './CustomerWithdrawReasonModal';
import { DefaultModal } from '../../designComponents/modals/ModalComponent';

/** 고객데이터_탈퇴사유 테이블 */
const CustomerWithdrawTable = ({ headers, datas, total }) => {
    const [percentTotal, setPercentTotal] = useState(0);
    const [percentMan, setPercentMan] = useState(0);
    const [percentGirl, setPercentGirl] = useState(0);
    const [percentNull, setPercentNull] = useState(0);

    const [showDataToggle, setShowDataToggle] = useState(false);
    const [elseReasonToggle, setElseReasonToggle] = useState(false);

    const totalNum = total && total[0]?.sex_man + total[0]?.sex_girl + total[0]?.sex_null;
    const totalMan = total && total[0]?.sex_man;
    const totalGirl = total && total[0]?.sex_girl;
    const totalNull = total && total[0]?.sex_null;

    /** 누적값 변수 */
    let percentTotalCount = 0;
    let percentManCount = 0;
    let percentGirlCount = 0;
    let percentNullCount = 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // 누적값 상태 적용
        percentTotalCount > 100 ? setPercentTotal(percentTotalCount.toFixed()) : setPercentTotal(Math.ceil(percentTotalCount));
        percentManCount > 100 ? setPercentMan(percentManCount.toFixed()) : setPercentMan(Math.ceil(percentManCount));
        percentGirlCount > 100 ? setPercentGirl(percentGirlCount.toFixed()) : setPercentGirl(Math.ceil(percentGirlCount));
        percentNullCount > 100 ? setPercentNull(percentNullCount.toFixed()) : setPercentNull(Math.ceil(percentNullCount));
    });

    /** 누적값 더해주는 함수 */
    const accumulatePercent = (total, man, girl, nulls) => {
        percentTotalCount += parseFloat(total);
        percentManCount += parseFloat(man);
        percentGirlCount += parseFloat(girl);
        percentNullCount += parseFloat(nulls);
    };

    /** 펼쳐보기 토글 */
    const onClickShowDataToggle = () => {
        setShowDataToggle((prev) => !prev);
    };

    /** 기타이유 조회 */
    const onClickElseReason = (reason) => () => {
        if (reason === '기타(직접입력)') {
            setElseReasonToggle(true);
        }
    };

    useEffect(() => {
        const escKeyModalClose = (e) => {
            if (e.keyCode === 27) {
                setElseReasonToggle(false);
            }
        };
        window.addEventListener('keydown', escKeyModalClose);
        return () => window.removeEventListener('keydown', escKeyModalClose);
    }, []);

    return (
        <div>
            {elseReasonToggle && <CustomerWithdrawReasonModal setToggle={setElseReasonToggle} />}
            {elseReasonToggle && (
                <DefaultModal
                    title={'탈퇴 기타사유'}
                    boxStyle={{ width: '70vh', height: '75vh' }}
                    bodyWidth={64}
                    xIcon={true}
                    closeOn={true}
                    cancelText={'닫기'}
                    onClickClose={() => setElseReasonToggle(false)}
                    onClickBottomClose={() => setElseReasonToggle(false)}
                >
                    <CustomerWithdrawReasonModal />
                </DefaultModal>
            )}

            <table className="table table--min" cellSpacing="0" cellPadding="0">
                <thead>
                    <TopHeader>
                        <td colSpan="1">{headers.header.title}</td>
                        <td colSpan="1"></td>
                        <td colSpan="1"></td>
                        <td colSpan="1">{headers.header.sex}</td>
                        <td colSpan="1"></td>
                    </TopHeader>

                    <tr onClick={onClickShowDataToggle}>
                        <BtnWrapperTd colSpan="5" toggle={showDataToggle}>
                            <div className="button-wrapper">
                                <div>{showDataToggle ? '접기' : '펼쳐보기'}</div>
                                <div>{showDataToggle ? <CaretUpOutlined /> : <CaretDownOutlined />}</div>
                            </div>
                        </BtnWrapperTd>
                    </tr>

                    {showDataToggle && (
                        <MiddleHeader>
                            <td colSpan="1"></td>
                            <td colSpan="1">{headers.header.total}</td>
                            <td colSpan="1">{headers.header.man}</td>
                            <td colSpan="1">{headers.header.girl}</td>
                            <td colSpan="1">{headers.header.null_value}</td>
                        </MiddleHeader>
                    )}
                </thead>

                {showDataToggle && (
                    <tbody>
                        {total?.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <TotalTd>{data.sub_title.toLocaleString('ko-KR')}</TotalTd>
                                    <TotalTd>
                                        {(data.sex_man + data.sex_girl + data.sex_null).toLocaleString('ko-KR')}
                                        <span style={{ color: '#717171', fontWeight: '500' }}>({percentTotal}%)</span>
                                    </TotalTd>
                                    <TotalTd>
                                        {data.sex_man.toLocaleString('ko-KR') || 0}
                                        <span style={{ color: '#717171', fontWeight: '500' }}>({percentMan}%)</span>
                                    </TotalTd>
                                    <TotalTd>
                                        {data.sex_girl.toLocaleString('ko-KR') || 0}
                                        <span style={{ color: '#717171', fontWeight: '500' }}>({percentGirl}%)</span>
                                    </TotalTd>
                                    <TotalTd>
                                        {data.sex_null.toLocaleString('ko-KR') || 0}
                                        <span style={{ color: '#717171', fontWeight: '500' }}>({percentNull}%)</span>
                                    </TotalTd>
                                </tr>
                            );
                        })}

                        {datas?.map((data, index) => {
                            let total = parseFloat(((data.sex_man + data.sex_girl + data.sex_null) / totalNum) * 100).toFixed(2);
                            let man = parseFloat((data.sex_man / totalMan) * 100).toFixed(2);
                            let girl = parseFloat((data.sex_girl / totalGirl) * 100).toFixed(2);
                            let nulls = parseFloat((data.sex_null / totalNull) * 100).toFixed(2);

                            accumulatePercent(total, man, girl, nulls);

                            return (
                                <tr key={index}>
                                    <Td toggle={Boolean(data.sub_title === '기타(직접입력)')} onClick={onClickElseReason(data.sub_title)}>
                                        {data.sub_title}
                                    </Td>
                                    <Td>
                                        {(data.sex_man + data.sex_girl + data.sex_null).toLocaleString('ko-KR')}
                                        <Percentage>
                                            ({parseFloat(((data.sex_man + data.sex_girl + data.sex_null) / totalNum) * 100).toFixed(2)}
                                            %)
                                        </Percentage>
                                    </Td>
                                    <td>
                                        {data.sex_man.toLocaleString('ko-KR')}
                                        <Percentage>({parseFloat((data.sex_man / totalMan) * 100).toFixed(2)}%)</Percentage>
                                    </td>
                                    <td>
                                        {data.sex_girl.toLocaleString('ko-KR')}
                                        <Percentage>({parseFloat((data.sex_girl / totalGirl) * 100).toFixed(2)}%)</Percentage>
                                    </td>
                                    <td>
                                        {data.sex_null.toLocaleString('ko-KR')}
                                        <Percentage>({parseFloat((data.sex_null / totalNull) * 100).toFixed(2)}%)</Percentage>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
        </div>
    );
};

export default CustomerWithdrawTable;

/*********************************** styled ***********************************/

const BtnWrapperTd = styled.td`
    cursor: pointer;

    padding: 5px 0 !important;

    font-size: 13px;
    font-weight: bold;

    border-bottom: ${(props) => (props.toggle ? '0' : '1px solid #cdcdcd')};

    .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    :hover {
        background-color: #f3f3f3;
    }
`;

const TopHeader = styled.tr`
    background-color: #809cff;
    color: #fff;
`;

const MiddleHeader = styled.tr`
    font-weight: bold;
`;

const TotalTd = styled.td`
    font-weight: bold;
    color: #305eff;
`;

const Td = styled.td`
    cursor: ${(props) => (props.toggle ? 'pointer' : 'auto')};
    font-weight: bold;
    color: ${(props) => (props.toggle ? '#305eff' : '')};

    &:hover {
        color: ${(props) => (props.toggle ? '#88a3ff' : 'black')};
    }
`;

const Percentage = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #717171;
`;
