import React, { useCallback, useRef, useState } from 'react';

import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import MainTable from '../../designComponents/tables/MainTable';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { CouponTableFilter } from '../../components/PointComponents/Coupon/CouponTableFilter';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { InputItemLists } from '../../designComponents/modals/InputItemLists';
import { ModalComponent } from '../../designComponents/modals/ModalComponent';
import { dateFilter } from '../../utils/textFilter';

/** 쿠폰 페이지 */
const Coupon = () => {
    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState(1);
    const [param, setParam] = useState({});

    const [refresh, setRefresh] = useState(false);
    const [createCouponToggle, setCreateCouponToggle] = useState(false);
    const [detailToggle, setDetailToggle] = useState(false);
    const [detail, setDetaile] = useState({});
    const [create, setCreate] = useState({});

    const ref = useRef(null);
    const tableRef = useRef(null);

    const callApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            ...param,
        };
        if (searchText.length > 0) {
            input.searchType = searchType;
            input.searchWord = searchText;
        }
        const response = await RefreshCheckApi.get(`/adminCoupon`, { params: input });
        return response.data.data;
    };

    const pageCountCallApi = async (page, param) => {
        const input = {
            page: page,
            size: 10,
            ...param,
        };
        if (searchText.length > 0) {
            input.searchType = searchType;
            input.searchWord = searchText;
        }
        const response = await RefreshCheckApi.get(`/adminCoupon`, { params: input });
        return response.data.all_count;
    };

    const rowLine = {
        margin: '30px auto',
        height: '1px',
        width: '95%',
        backgroundColor: 'rgb(204, 204, 204)',
    };

    const placeholderText = () => {
        switch (detail.benefit_type) {
            case 1:
                return '금액을 입력하세요.';
            case 2:
                return '비율 입력하세요.';
            case 3:
                return '포인트를 입력하세요.';
            case 4:
                return '시럽 갯수를 입력하세요.';
            default:
                return '확인되지 않은 혜택입니다.';
        }
    };

    const createPlaceholderText = () => {
        switch (create.benefit_type) {
            case 1:
                return '금액을 입력하세요.';
            case 2:
                return '비율 입력하세요.';
            case 3:
                return '포인트를 입력하세요.';
            case 4:
                return '시럽 갯수를 입력하세요.';
            default:
                return '확인되지 않은 혜택입니다.';
        }
    };

    const checkEmptyInput = (create) => {
        if (!create.name) return alert(`쿠폰명을 입력해주세요.`);
        if (!create.status < 0) return alert(`상태를 입력해주세요.`);
        if (!create.coupon_code) return alert(`쿠폰 코드를 입력해주세요.`);
        if (!create.additional_use_yn) return alert(`중복할인 여부를 선택해 주세요.`);
        if (!create.publish_amount < 0) return alert(`발행 수량을 입력해 주세요.`);
        // if (!create.use_count) return alert(`사용 횟수를 입력해 주세요.`);
        if (!create.alarm_type) return alert(`발행 알림 여부를 확인해 주세요.`);
        if (!create.start_datetime) return alert(`등록일을 입력해 주세요.`);
        if (!create.end_datetime) return alert(`마감일을 입력해 주세요.`);
        if (!create.benefit_type) return alert(`혜택 종류를 선택해 주세요.`);
        if (!create.benefit) return alert(`혜택에 따른 값을 입력해주세요.`);
        if (!create.available_order_amount) return alert(`최소 결제 금액을 입력해 주세요.`);
        if (!create.explanation) return alert(`쿠폰 설명을 입력해 주세요.`);
    };

    return (
        <div id="coupon-page">
            <SearchbarWithDropdown
                list={[{ id: 1, title: '쿠폰명', value: 1 }]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => setSearchText(e.target.value)}
            />

            <FlexMainWrapper>
                <MainTable
                    toggleBlur={true}
                    buttonComponents={({ checkItems, setCheckItems }) => (
                        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'end' }}>
                            <TopSideButton
                                middleOn={false}
                                onClick={() => {
                                    setCreateCouponToggle(true);
                                    setDetailToggle(false);
                                }}
                                onClickDelete={async () => {
                                    try {
                                        const param = { coupon_id: checkItems };
                                        const response = await RefreshCheckApi.delete('/adminCoupon', {
                                            headers: { 'Content-Type': `application/json` },
                                            data: param,
                                        });
                                        if (response.status === 204) {
                                            setRefresh(!refresh);
                                            setCheckItems([]);
                                            alert('선택하신 쿠폰이 삭제되었습니다.');
                                        }
                                    } catch (error) {
                                        console.error('error: ', error);
                                    }
                                }}
                            />
                        </FlexMainWrapper>
                    )}
                    tableStyle={{ width: '98%' }}
                    ref={ref}
                    tableRef={tableRef}
                    loadingText={'전체 포인트 쿠폰 내역을 불러오고 있습니다.'}
                    headerData={CouponTableFilter}
                    checkID={'coupon_id'}
                    param={param}
                    setParam={setParam}
                    callApi={callApi}
                    pageCountCallApi={pageCountCallApi}
                    onClickRowItem={(item) => async () => {
                        const response = await RefreshCheckApi.get(`/adminCoupon/${item.coupon_id}`);
                        if (response.status === 200) {
                            setDetailToggle(true);
                            setCreateCouponToggle(false);
                            setDetaile(response.data.data);
                        }
                    }}
                    tbodyRenderItem={(key, value) => {
                        if (key === 'coupon_status') return String(value);
                        return value;
                    }}
                />
                <ModalComponent
                    toggle={createCouponToggle}
                    closeModal={() => setCreateCouponToggle(false)}
                    header={'쿠폰 정보 등록'}
                    body={
                        <div>
                            <InputItemLists
                                titleStyle={{ width: '110px' }}
                                items={[
                                    {
                                        title: '쿠폰명',
                                        placeholder: '쿠폰명을 입력하세요.',
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.name = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '상태',
                                        selectYN: true,
                                        option: [
                                            { text: '진행중', value: 1 },
                                            { text: '종료', value: 0 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.status = parseInt(e.target.value);
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '쿠폰 코드',
                                        placeholder: '쿠폰 코드를 입력하세요.',
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.coupon_code = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '중복 할인',
                                        selectYN: true,
                                        option: [
                                            { text: '가능', value: 1 },
                                            { text: '불가능', value: 0 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.additional_use_yn = parseInt(e.target.value);
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '발행 수량',
                                        placeholder: '0',
                                        type: 'number',
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.publish_amount = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '사용 횟수',
                                        placeholder: '0',
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.use_count = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '발행 알림',
                                        selectYN: true,
                                        option: [
                                            { text: '알림 안함', value: 1 },
                                            { text: '사용 시작일 알림', value: 0 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.alarm_type = parseInt(e.target.value);
                                            setCreate(obj);
                                        },
                                    },
                                ]}
                            />
                            <div style={rowLine} />
                            <InputItemLists
                                titleStyle={{ width: '110px' }}
                                items={[
                                    {
                                        title: '등록일',
                                        value: create.start_datetime,
                                        dateInput: true,
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.start_datetime = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '마감일',
                                        value: create.end_datetime,
                                        dateInput: true,
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.end_datetime = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '혜택',
                                        value: create.benefit_type || 1,
                                        selectYN: true,
                                        option: [
                                            { text: '금액 할인', value: 1 },
                                            { text: '비율 할인', value: 2 },
                                            { text: '포인트 지급', value: 3 },
                                            { text: '시럽 지급', value: 4 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.benefit_type = parseInt(e.target.value);
                                            delete obj.benefit;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        type: 'number',
                                        placeholder: createPlaceholderText(),
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.benefit = parseInt(e.target.value);
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '최소 결제 금액',
                                        placeholder: '최소 결제 금액을 입력하세요.',
                                        type: 'number',
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.available_order_amount = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                    {
                                        title: '쿠폰 설명',
                                        placeholder: '쿠폰 설명을 입력하세요.',
                                        onChange: (e) => {
                                            let obj = { ...create };
                                            obj.explanation = e.target.value;
                                            setCreate(obj);
                                        },
                                    },
                                ]}
                            />
                        </div>
                    }
                    saveText={'등록'}
                    onClickSave={async () => {
                        create.target = 0; // 현재 쓰지 않는 값
                        create.apply_target = 0; // 현재 쓰지 않는 값
                        create.status = create.status || 1;
                        create.target = create.target || 0;
                        create.additional_use_yn = create.additional_use_yn || 1;
                        create.publish_amount = create.publish_amount || 1;
                        create.alarm_type = create.alarm_type || 1;
                        checkEmptyInput(create);
                        const response = await RefreshCheckApi.post(`/adminCoupon`, create);
                        if (response.status === 200) {
                            setCreate({});
                            alert('쿠폰 등록이 완료되었습니다.');
                        }
                    }}
                />

                <ModalComponent
                    header={'쿠폰 상세 정보'}
                    toggle={detailToggle}
                    closeModal={() => setDetailToggle(false)}
                    body={
                        <div>
                            <InputItemLists
                                titleStyle={{ width: '110px' }}
                                items={[
                                    {
                                        title: '쿠폰명',
                                        value: detail.name,
                                        placeholder: '쿠폰명을 입력하세요.',
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.name = e.target.value;
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '상태',
                                        value: detail.status,
                                        selectYN: true,
                                        option: [
                                            { text: '진행중', value: 1 },
                                            { text: '종료', value: 0 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.status = parseInt(e.target.value);
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '쿠폰 코드',
                                        value: detail.coupon_code,
                                        placeholder: '쿠폰 코드를 입력하세요.',
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.coupon_code = e.target.value;
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '중복 할인',
                                        value: detail.additional_use_yn,
                                        selectYN: true,
                                        option: [
                                            { text: '가능', value: 1 },
                                            { text: '불가능', value: 0 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.additional_use_yn = parseInt(e.target.value);
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '발행 수량',
                                        value: detail.publish_amount,
                                        type: 'number',
                                        placeholder: '0',
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.publish_amount = e.target.value;
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '사용 횟수',
                                        value: detail.use_count,
                                        type: 'number',
                                        placeholder: '0',
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.use_count = e.target.value;
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '발행 알림',
                                        value: detail.alarm_type,
                                        selectYN: true,
                                        option: [
                                            { text: '알림 안함', value: 1 },
                                            { text: '사용 시작일 알림', value: 0 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.alarm_type = parseInt(e.target.value);
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '등록일',
                                        value: dateFilter(detail.start_datetime, 'YYYY-MM-DD'),
                                        dateInput: true,
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.start_datetime = e.target.value;
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '마감일',
                                        value: dateFilter(detail.end_datetime, 'YYYY-MM-DD'),
                                        dateInput: true,
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.end_datetime = e.target.value;
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '혜택',
                                        value: detail.benefit_type,
                                        selectYN: true,
                                        option: [
                                            { text: '금액 할인', value: 1 },
                                            { text: '비율 할인', value: 2 },
                                            { text: '포인트 지급', value: 3 },
                                            { text: '시럽 지급', value: 4 },
                                        ],
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.benefit_type = parseInt(e.target.value);
                                            delete obj.benefit;
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        value: detail.benefit ? detail.benefit : '',
                                        placeholder: placeholderText(),
                                        type: 'number',
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.benefit = parseInt(e.target.value);
                                            setDetaile(obj);
                                        },
                                    },
                                    {
                                        title: '최소 결제 금액',
                                        value: detail.available_order_amount,
                                        placeholder: '최소 결제 금액을 입력하세요.',
                                        type: 'number',
                                        onChange: (e) => {
                                            let obj = { ...detail };
                                            obj.available_order_amount = e.target.value;
                                            setDetaile(obj);
                                        },
                                    },
                                ]}
                            />
                        </div>
                    }
                    saveText={'수정'}
                    onClickSave={async () => {
                        const response = await RefreshCheckApi.put(`/adminCoupon/${detail.coupon_id}`, detail);
                        if (response.status === 200) alert('쿠폰 수정이 완료되었습니다.');
                    }}
                />
            </FlexMainWrapper>
        </div>
    );
};

export default React.memo(Coupon);
