import React from 'react';
import styled from 'styled-components';

const ContentText = ({ text, style }) => {
    return <Text style={{ ...style }}>{text}</Text>;
};

export default React.memo(ContentText);

export const SText = ({ text, style, color = '#000', onClick = () => {}, gap = 7 }) => {
    return (
        <TextS style={{ ...style }} color={color} onClick={onClick}>
            {text}
        </TextS>
    );
};

export const MText = ({ text, style, color = '#000', onClick = () => {}, gap = 7, children }) => {
    return (
        <TextM style={{ ...style }} color={color} onClick={onClick}>
            {text ?? children}
        </TextM>
    );
};

export const LText = ({ text, style, color = '#000', onClick = () => {}, gap = 7 }) => {
    return (
        <TextL style={{ ...style }} color={color} onClick={onClick}>
            {text}
        </TextL>
    );
};

export const Textbox = ({ item, style }) => {
    return <Box style={{ ...style }}>{item}</Box>;
};

const Box = styled.div`
    border: 1px solid rgb(219 219 219);
    border-radius: 5px;
    padding: 5px;
    height: 20vh;
    overflow: auto;
`;

const Text = styled.div`
    font-size: 13px;
    font-family: noto_sans_kr_medium;
`;

const TextS = styled.div`
    font-size: 13px;
    font-weight: 600;
    font-family: noto_sans_kr_medium;
    color: ${(props) => props.color};
`;

const TextM = styled.div`
    font-size: 15px;
    font-weight: 600;
    font-family: noto_sans_kr_medium;
    color: ${(props) => props.color};
`;

const TextL = styled.div`
    font-size: 20px;
    font-weight: 600;
    font-family: noto_sans_kr_medium;
    color: ${(props) => props.color};
`;
