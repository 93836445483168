import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 쿠폰조회
export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE';
// 쿠폰상세조회
export const GET_COUPON_DETAIL_REQUEST = 'GET_COUPON_DETAIL_REQUEST';
export const GET_COUPON_DETAIL_SUCCESS = 'GET_COUPON_DETAIL_SUCCESS';
export const GET_COUPON_DETAIL_FAILURE = 'GET_COUPON_DETAIL_FAILURE';
// 쿠폰정보수정
export const UPDATE_COUPON_DETAIL_REQUEST = 'UPDATE_COUPON_DETAIL_REQUEST';
export const UPDATE_COUPON_DETAIL_SUCCESS = 'UPDATE_COUPON_DETAIL_SUCCESS';
export const UPDATE_COUPON_DETAIL_FAILURE = 'UPDATE_COUPON_DETAIL_FAILURE';
// 쿠폰 사용내역 조회
export const GET_USED_COUPON_LISTS_REQUEST = 'GET_USED_COUPON_LISTS_REQUEST';
export const GET_USED_COUPON_LISTS_SUCCESS = 'GET_USED_COUPON_LISTS_SUCCESS';
export const GET_USED_COUPON_LISTS_FAILURE = 'GET_USED_COUPON_LISTS_FAILURE';
// 쿠폰 등록
export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_FAILURE = 'CREATE_COUPON_FAILURE';
// 리셋
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    coupons: { all_count: 1000, data: [], ok: true }, // 쿠폰 데이터
    couponsDetail: {}, // 쿠폰 상세
    updateCouponResult: null, // 쿠폰 상세
    couponUsedLists: [], // 쿠폰 사용내역
    createCoupon: null, // 쿠폰 등록 결과
    // 쿠폰 데이터
    getCouponsLoading: false,
    getCouponsDone: false,
    getCouponsError: null,
    // 상세 쿠폰 데이터
    getCouponDetailLoading: false,
    getCouponDetailDone: false,
    getCouponDetailError: null,
    // 쿠폰 정보 수정
    updateCouponDetailLoading: false,
    updateCouponDetailDone: false,
    updateCouponDetailError: null,
    // 쿠폰 사용내역
    getCouponsUsedListsLoading: false,
    getCouponsUsedListsDone: false,
    getCouponsUsedListsError: null,
    // 쿠폰 사용내역
    createCouponsLoading: false,
    createCouponsDone: false,
    createCouponsError: null,
};

/******************************************
 * action 함수
 ******************************************/

/** 쿠폰 조회 */
export const getCoupons = (params) => async (dispatch) => {
    dispatch({
        type: GET_COUPONS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminCoupon`, { params: params });

        dispatch({
            type: GET_COUPONS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_COUPONS_FAILURE,
            error: error,
        });
    }
};

/** 쿠폰 상세 조회 */
export const getCouponDetail = (id) => async (dispatch) => {
    dispatch({
        type: GET_COUPON_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminCoupon/${id}`);
        dispatch({
            type: GET_COUPON_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_COUPON_DETAIL_FAILURE,
            error: error,
        });
    }
};

/** 쿠폰 정보 수정 */
export const updateCoupon = (params) => async (dispatch) => {
    dispatch({
        type: UPDATE_COUPON_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.put(`/adminCoupon/${params.coupon_id}`, params);
        dispatch({
            type: UPDATE_COUPON_DETAIL_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_COUPON_DETAIL_FAILURE,
            error: error,
        });
    }
};

/** 쿠폰 사용 내역 */
export const getCouponUsedLists = (coupon_id) => async (dispatch) => {
    dispatch({
        type: GET_USED_COUPON_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminCoupon/${coupon_id}/use`);
        dispatch({
            type: GET_USED_COUPON_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USED_COUPON_LISTS_FAILURE,
            error: error,
        });
    }
};

/** 쿠폰 등록 */
export const postRequestCoupon = (params) => async (dispatch) => {
    dispatch({
        type: CREATE_COUPON_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminCoupon`, params);
        dispatch({
            type: CREATE_COUPON_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_COUPON_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const couponsReducer = handleActions(
    {
        // 쿠폰 조회
        [GET_COUPONS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponsLoading = true;
                draft.getCouponsDone = false;
                draft.getCouponsError = null;
            }),
        [GET_COUPONS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponsLoading = action.payload ? false : true;
                draft.coupons = action.payload;
                draft.getCouponsDone = action.payload ? true : false;
            }),
        [GET_COUPONS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponsLoading = false;
                draft.getCouponsError = action.error;
            }),
        // 쿠폰 상세 조회
        [GET_COUPON_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponDetailLoading = true;
                draft.getCouponDetailDone = false;
                draft.getCouponDetailError = null;
            }),
        [GET_COUPON_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponDetailLoading = action.payload ? false : true;
                draft.couponsDetail = action.payload;
                draft.getCouponDetailDone = action.payload ? true : false;
            }),
        [GET_COUPON_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponDetailLoading = false;
                draft.getCouponDetailError = action.error;
            }),
        // 쿠폰 정보 수정
        [UPDATE_COUPON_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateCouponDetailLoading = true;
                draft.updateCouponDetailDone = false;
                draft.updateCouponDetailError = null;
            }),
        [UPDATE_COUPON_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateCouponDetailLoading = action.payload ? false : true;
                draft.updateCouponResult = action.payload;
                draft.updateCouponDetailDone = action.payload ? true : false;
            }),
        [UPDATE_COUPON_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateCouponDetailLoading = false;
                draft.updateCouponDetailError = action.error;
            }),
        // 쿠폰 사용내역 조회
        [GET_USED_COUPON_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponsUsedListsLoading = true;
                draft.getCouponsUsedListsDone = false;
                draft.getCouponsUsedListsError = null;
            }),
        [GET_USED_COUPON_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponsUsedListsLoading = action.payload ? false : true;
                draft.couponUsedLists = action.payload;
                draft.getCouponsUsedListsDone = action.payload ? true : false;
            }),
        [GET_USED_COUPON_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getCouponsUsedListsLoading = false;
                draft.getCouponsUsedListsError = action.error;
            }),
        // 쿠폰 등록
        [CREATE_COUPON_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.createCouponsLoading = true;
                draft.createCouponsDone = false;
                draft.createCouponsError = null;
            }),
        [CREATE_COUPON_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.createCouponsLoading = action.payload ? false : true;
                draft.createCoupon = action.payload;
                draft.createCouponsDone = action.payload ? true : false;
            }),
        [CREATE_COUPON_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.createCouponsLoading = false;
                draft.createCouponsError = action.error;
            }),

        // 상태 리셋
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 쿠폰 상세 조회
                draft.updateCouponResult = null;
                draft.getCouponDetailLoading = false;
                draft.getCouponDetailDone = false;
                draft.getCouponDetailError = null;
                // 쿠폰 등록
                draft.createCoupon = null;
                draft.createCouponsLoading = false;
                draft.createCouponsDone = false;
                draft.createCouponsError = null;
            }),
    },
    initialState,
);

export default couponsReducer;
