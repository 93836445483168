// 모임생성 > 카테고리 선택 목록
export const interestMock = [
    { id: 1, code: 0, code_name: '여행', code_group: '여행' },
    { id: 2, code: 1, code_name: '낚시', code_group: '여행' },
    { id: 3, code: 2, code_name: '등산', code_group: '여행' },
    { id: 4, code: 3, code_name: '자전거', code_group: '건강' },
    { id: 5, code: 4, code_name: '운동', code_group: '건강' },
    { id: 6, code: 5, code_name: '골프', code_group: '건강' },
    { id: 7, code: 6, code_name: '반려동물', code_group: '기타' },
    { id: 8, code: 7, code_name: '식물', code_group: '기타' },
    { id: 9, code: 8, code_name: '종교', code_group: '기타' },
    { id: 10, code: 9, code_name: '맛집', code_group: '여행' },
    { id: 11, code: 10, code_name: '와인', code_group: '여행' },
    { id: 12, code: 11, code_name: '술', code_group: '여행' },
    { id: 13, code: 12, code_name: '영화', code_group: '문화' },
    { id: 14, code: 13, code_name: '사진', code_group: '문화' },
    { id: 15, code: 14, code_name: '미술', code_group: '문화' },
    { id: 16, code: 15, code_name: '노래', code_group: '문화' },
    { id: 17, code: 16, code_name: '음악', code_group: '문화' },
    { id: 18, code: 17, code_name: '악기', code_group: '문화' },
    { id: 19, code: 18, code_name: '책', code_group: '문화' },
    { id: 20, code: 19, code_name: '패션', code_group: '문화' },
    { id: 21, code: 20, code_name: '뷰티', code_group: '문화' },
    { id: 22, code: 21, code_name: '투자', code_group: '기타' },
    { id: 23, code: 22, code_name: '인테리어', code_group: '기타' },
    { id: 24, code: 23, code_name: '결혼', code_group: '기타' },
    { id: 25, code: 24, code_name: '댄스', code_group: '건강' },
    { id: 27, code: 26, code_name: '캠핑', code_group: '여행' },
];


