import React, { useEffect, useState, useRef, useContext } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { UserName, ModalBoxBody, InfoBox, InfoTitle, ColWrapper, Subtitle, SubInfo } from '../GatheringComponents/ModalComponents/SchedulePayUserDetailModal';

// react-query && axios && API
import { useQuery } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import LoadingPage from '../../pages/loading/LoadingPage';
import { main_server_image_ip } from '../../communication_system/communication_ips';

// regacy-components
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';

// 시럽 사용내역
function DetailUseSyrupListModal({ detailToggle, setDetailToggle }) {
    let id = detailToggle[1];

    // regacy-context api
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    // react-query
    const { isLoading, error, data } = useQuery(['useSyrupDetailData', id], async () => {
        return await RefreshCheckApi.get(`/adminSyrup/detail?id=${id}`).then((res) => {
            return res.data.data;
        });
    });

    // state
    const [src, setSrc] = useState('');
    const [onImageToggle, setOnImageToggle] = useState(false);

    // useRef
    const mainModal = useRef();
    const dashMenu = useRef();

    /******************************* useEffect *******************************/

    // 외부 클릭시 modalCloseHandler 함수 호출
    useEffect(() => {
        if (!user_info_modal_state.modalVisible) {
            window.addEventListener('click', modalCloseHandler);
            window.addEventListener('click', mainModalCloseHandler);
            return () => {
                window.removeEventListener('click', modalCloseHandler);
                window.removeEventListener('click', mainModalCloseHandler);
            };
        }
    });

    /******************************* function *******************************/

    // main modal 외부 클릭 시 닫힘
    const mainModalCloseHandler = ({ target }) => {
        if (mainModal.current) {
            if (!mainModal.current.contains(target) && !onImageToggle) {
                setDetailToggle(false);
            }
        }
    };

    // modal 외부 클릭 시 닫힘
    const modalCloseHandler = ({ target }) => {
        if (dashMenu.current) {
            if (dashMenu.current.contains(target)) {
                closeImageModal();
            }
        }
    };

    // 회원 성별 필터
    const userSexFilter = (sex) => {
        let _sex = parseInt(sex);

        if (_sex === 1) {
            return '남자';
        } else if (_sex === 2) {
            return '여자';
        } else {
            return '정보없음';
        }
    };

    // 충전 및 사용 제목 필터
    const reasonTypeNumberFilter = (type) => {
        let _type = parseInt(type);

        switch (true) {
            case _type === 0:
                return '충전 갯수';
            case _type === 1:
                return '사용 갯수';
            case _type === 2:
                return '환불 갯수';
            default:
                return '사용 갯수';
        }
    };

    // 프로필 이미지 url 필터 함수
    const imagesFilterUrl = (src) => {
        switch (true) {
            case src?.includes('k.kakaocdn.net'):
                return src;
            case src?.length === 0:
                return null;
            default:
                return main_server_image_ip + src;
        }
    };

    // 이미지 정보 없음 시
    const handleImgError = (e) => {
        e.target.src = '/images/noneImage.png';
    };

    // 이미지 크게보기
    const onClickImage = (src) => () => {
        setSrc(src);
        setOnImageToggle(true);
    };

    // 이미지 모달 닫기
    const closeImageModal = () => {
        setOnImageToggle(false);
    };

    // 시럽 보유 갯수
    const userSyrupFilter = (syrup, bonus) => {
        let _syrup = parseInt(syrup);
        let _bonus = parseInt(bonus);

        let count = _syrup + _bonus;

        if (count !== 0) {
            return <span>{count}개</span>;
        } else {
            return <span style={{ color: 'gray' }}>보유중인 시럽이 없습니다.</span>;
        }
    };

    // 회원 상세정보
    const onClickDetailUserInfo = (user_id) => () => {
        let _user_id = parseInt(user_id);

        if (_user_id) {
            user_info_modal_actions.setId(_user_id);
            user_info_modal_actions.setModalVisible(true);
        }
    };

    /********************************* html *********************************/
    if (error) {
        alert(error);
    }
    return (
        <>
            {onImageToggle && (
                <ImageBoxWrapper ref={dashMenu}>
                    <CloseImage>
                        <CloseOutlined className="icon" onClick={closeImageModal} />
                    </CloseImage>

                    <ImageBox>
                        <Image sizes={600} src={imagesFilterUrl(src)} onError={handleImgError} />
                    </ImageBox>
                </ImageBoxWrapper>
            )}

            <DetailModalWrapper id="modal-window">
                <DetailModalBox id="modal-box" ref={mainModal}>
                    <Header id="top-line">
                        <Text>상세정보</Text>
                        <Text style={{ cursor: 'pointer' }} onClick={() => setDetailToggle(false)}>
                            닫기
                        </Text>
                    </Header>

                    {isLoading ? (
                        <LoadingPage text={'정보를 불러오는 중입니다.'} />
                    ) : (
                        <div style={{ marginTop: '6%' }}>
                            <UserName className="user-name" onClick={onClickDetailUserInfo(data?.user_id)}>
                                {data?.user_name || <span style={{ color: 'gray' }}>정보없음</span>}
                            </UserName>

                            <ModalBoxBody className="modal-body" style={{ marginTop: '2%' }}>
                                <label id="label">사용 상태</label>
                                <InfoBox className="subscribe-info">
                                    <InfoTitle>회원 정보</InfoTitle>

                                    <ModalBoxBody style={{ padding: 0 }}>
                                        <ColWrapper style={{ flexDirection: 'column' }}>
                                            <Subtitle>회원 인증 사진</Subtitle>
                                            <div onClick={onClickImage(data?.photo_auth_url)}>
                                                <Image sizes={150} src={imagesFilterUrl(data?.photo_auth_url)} onError={handleImgError} />
                                            </div>
                                        </ColWrapper>

                                        <ColWrapper style={{ flexDirection: 'column' }}>
                                            <Subtitle>회원 프로필</Subtitle>
                                            <div onClick={onClickImage(data?.photo_url)}>
                                                <Image sizes={150} src={imagesFilterUrl(data?.photo_url)} onError={handleImgError} />
                                            </div>
                                        </ColWrapper>
                                    </ModalBoxBody>

                                    <ColWrapper>
                                        <Subtitle>회원ID</Subtitle>
                                        <SubInfo>{data?.user_id || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>이름</Subtitle>
                                        <SubInfo>{data?.user_name || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>닉네임</Subtitle>
                                        <SubInfo>{data?.user_nick_name || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>성별</Subtitle>
                                        <SubInfo>{userSexFilter(data?.user_sex)}</SubInfo>
                                    </ColWrapper>
                                </InfoBox>
                                <label id="label">회원 정보</label>
                                <InfoBox className="refund-info" leftLine={true}>
                                    <InfoTitle>시럽 사용내역 정보</InfoTitle>

                                    <ColWrapper>
                                        <Subtitle>사용 내역 ID</Subtitle>
                                        <SubInfo>{data?.id || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>사용 일자</Subtitle>
                                        <SubInfo>{data?.created_at || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>사용 상태</Subtitle>
                                        <SubInfo>{data?.reason_type || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>사용 내용</Subtitle>
                                        <SubInfo>{data?.reason_detail || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>{reasonTypeNumberFilter(data?.reason_type_number)}</Subtitle>
                                        <SubInfo>{data?.sinor_love || <span style={{ color: 'gray' }}>정보없음</span>}</SubInfo>
                                    </ColWrapper>

                                    <ColWrapper>
                                        <Subtitle>보유 시럽</Subtitle>
                                        <SubInfo>{userSyrupFilter(data?.sinor_love_count, data?.bonus_love_count)}</SubInfo>
                                    </ColWrapper>
                                </InfoBox>
                            </ModalBoxBody>

                            <ButtonWeapper>
                                <button className="refund" onClick={() => setDetailToggle(false)}>
                                    닫기
                                </button>
                            </ButtonWeapper>
                        </div>
                    )}
                </DetailModalBox>
            </DetailModalWrapper>
        </>
    );
}

export default DetailUseSyrupListModal;

/******************************* styled *******************************/

const ButtonWeapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 3%;
    margin-bottom: 3%;

    button {
        cursor: pointer;
        border: 0;
        border-radius: 3px;
        background-color: #fff;

        width: 120px;
        height: 35px;
    }

    .refund {
        border: 1px solid rgb(255, 16, 125);
        background-color: rgb(255, 16, 125);
        color: #fff;

        :hover {
            background-color: rgb(226, 0, 103);
        }
    }
`;

const DetailModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: #000000b0;
`;

const DetailModalBox = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 120vh;
    max-width: 98%;
    height: 80vh;
    overflow: auto;

    border-radius: 10px;
    background-color: #fff;

    @media (max-width: 912px) {
        width: 98%;
        height: 90%;
    }

    .close-window {
        padding: 5px 25px;
        text-align: start;
        font-size: 20px;
        color: #428bf7;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        padding: 15px;
        color: gray;
    }
`;

const Header = styled.div`
    position: absolute;
    top: 0;

    z-index: 999;
    display: flex;
    justify-content: space-between;

    background-color: rgb(66, 139, 247);

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    width: 100%;
    height: 5vh;

    align-items: center;

    @media (max-width: 912px) {
        position: absolute;
        top: 0;

        width: 100%;
    }
`;

const Text = styled.div`
    padding: 0 15px;

    color: #fff;
    font-size: 16px;
    font-weight: 600;

    line-height: 45px;
`;

const Image = styled.img`
    cursor: pointer;
    object-fit: cover;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};
`;

const ImageBoxWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;

    background-color: #000000b0;
`;

const ImageBox = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const CloseImage = styled.div`
    position: fixed;
    top: 0;
    right: 0;

    margin: 20px;

    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-align: end;

    .icon {
        cursor: pointer;
    }
`;
