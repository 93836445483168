import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

// 고객데이터 테이블
function CustomerDataTable({ headers, datas, total }) {
    // state
    const [percentTotal, setPercentTotal] = useState(0);
    const [percentMan, setPercentMan] = useState(0);
    const [percentGirl, setPercentGirl] = useState(0);

    // state-toggle
    const [showDataToggle, setShowDataToggle] = useState(false);

    const totalNum = total && total[0]?.sex_man + total[0]?.sex_girl;
    const totalMan = total && total[0]?.sex_man;
    const totalGirl = total && total[0]?.sex_girl;

    // 누적값 변수
    let percentTotalCount = 0;
    let percentManCount = 0;
    let percentGirlCount = 0;

    /********************************* useEffect *********************************/

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // 누적값 상태 적용
        percentTotalCount > 100 ? setPercentTotal(percentTotalCount.toFixed()) : setPercentTotal(Math.ceil(percentTotalCount));
        percentManCount > 100 ? setPercentMan(percentManCount.toFixed()) : setPercentMan(Math.ceil(percentManCount));
        percentGirlCount > 100 ? setPercentGirl(percentGirlCount.toFixed()) : setPercentGirl(Math.ceil(percentGirlCount));
    });

    /********************************* function *********************************/

    // 누적값 더해주는 함수
    const accumulatePercent = (total, man, girl) => {
        percentTotalCount += parseFloat(total);
        percentManCount += parseFloat(man);
        percentGirlCount += parseFloat(girl);
    };

    // 펼쳐보기 토글
    const onClickShowDataToggle = () => {
        setShowDataToggle((prev) => !prev);
    };

    /*********************************** html ***********************************/
    return (
        <div>
            <table className="table table--min" cellSpacing="0" cellPadding="0">
                <thead>
                    <TopHeader>
                        <td colSpan="1">{headers.header.title}</td>
                        <td colSpan="1"></td>
                        <td colSpan="1">{headers.header.sex}</td>
                        <td colSpan="1"></td>
                    </TopHeader>

                    <tr onClick={onClickShowDataToggle}>
                        <BtnWrapperTd colSpan="4" toggle={showDataToggle}>
                            <div className="button-wrapper">
                                <div>{showDataToggle ? '접기' : '펼쳐보기'}</div>
                                <div>{showDataToggle ? <CaretUpOutlined /> : <CaretDownOutlined />}</div>
                            </div>
                        </BtnWrapperTd>
                    </tr>

                    {showDataToggle && (
                        <MiddleHeader>
                            <td colSpan="1"></td>
                            <td colSpan="1">{headers.header.total}</td>
                            <td colSpan="1">{headers.header.man}</td>
                            <td colSpan="1">{headers.header.girl}</td>
                        </MiddleHeader>
                    )}
                </thead>

                {showDataToggle && (
                    <tbody>
                        {total?.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <TotalTd>{data.sub_title.toLocaleString('ko-KR')}</TotalTd>
                                    <TotalTd>
                                        {(data.sex_man + data.sex_girl).toLocaleString('ko-KR')}
                                        <span style={{ color: '#717171', fontWeight: '500' }}>({percentTotal}%)</span>
                                    </TotalTd>
                                    <TotalTd>
                                        {data.sex_man.toLocaleString('ko-KR') || 0}
                                        <span style={{ color: '#717171', fontWeight: '500' }}>({percentMan}%)</span>
                                    </TotalTd>
                                    <TotalTd>
                                        {data.sex_girl.toLocaleString('ko-KR') || 0}
                                        <span style={{ color: '#717171', fontWeight: '500' }}>({percentGirl}%)</span>
                                    </TotalTd>
                                </tr>
                            );
                        })}

                        {datas?.map((data, index) => {
                            let total = parseFloat(((data.sex_man + data.sex_girl) / totalNum) * 100).toFixed(2);
                            let man = parseFloat((data.sex_man / totalMan) * 100).toFixed(2);
                            let girl = parseFloat((data.sex_girl / totalGirl) * 100).toFixed(2);

                            accumulatePercent(total, man, girl);

                            return (
                                <tr key={index}>
                                    <Td>{data.sub_title}</Td>
                                    <Td>
                                        {(data.sex_man + data.sex_girl)?.toLocaleString('ko-KR')}
                                        <Percentage>
                                            ({parseFloat(((data.sex_man + data.sex_girl) / totalNum) * 100).toFixed(2)}%)
                                        </Percentage>
                                    </Td>
                                    <td>
                                        {data.sex_man?.toLocaleString('ko-KR')}
                                        <Percentage>({parseFloat((data.sex_man / totalMan) * 100).toFixed(2)}%)</Percentage>
                                    </td>
                                    <td>
                                        {data.sex_girl?.toLocaleString('ko-KR')}
                                        <Percentage>({parseFloat((data.sex_girl / totalGirl) * 100).toFixed(2)}%)</Percentage>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default CustomerDataTable;

/*********************************** styled ***********************************/

const BtnWrapperTd = styled.td`
    cursor: pointer;

    padding: 5px 0 !important;

    font-size: 13px;
    font-weight: bold;

    border-bottom: ${(props) => (props.toggle ? '0' : '1px solid #cdcdcd')};

    .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    :hover {
        background-color: #f3f3f3;
    }
`;

const TopHeader = styled.tr`
    background-color: #809cff;
    color: #fff;
`;

const MiddleHeader = styled.tr`
    font-weight: bold;
`;

const TotalTd = styled.td`
    font-weight: bold;
    color: #305eff;
`;

const Td = styled.td`
    font-weight: bold;
`;

const Percentage = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: #717171;
`;
