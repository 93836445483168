import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

/** Chart Components */
import InCallQuestionChart from '../../components/CSStatistics/InCallQuestionChart';
import styled from 'styled-components';

/** CS통계 페이지 */
const CustomerStatistics = () => {
    return (
        <ChartWrapper id="customer-serice-static-page">
            <MainPageTitle text={'고객서비스 통계'} />
            <Wrapper02 style={{ width: '95%', overflow: 'auto', margin: '0 auto 10% auto' }}>
                <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'center', gap: '10vh', width: '100%' }}>
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', gap: 0 }}>
                        <InCallQuestionChart title={'메인 주제'} requestUrl={'/service/main/chart'} name={'name'} y={'y'} />
                        <InCallQuestionChart title={'상세 문의 내용'} requestUrl={'/service/sub/chart'} name={'name'} y={'y'} />
                    </FlexMainWrapper>
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center', gap: 0 }}>
                        <InCallQuestionChart title={'통화 시간대'} requestUrl={'/service/time/chart'} name={'name'} y={'y'} />
                        <InCallQuestionChart title={'성별 비중'} requestUrl={'/service/sex/chart'} name={'name'} y={'y'} />
                    </FlexMainWrapper>
                </FlexMainWrapper>
            </Wrapper02>
        </ChartWrapper>
    );
};

export default CustomerStatistics;

const ChartWrapper = styled.div`
    .highcharts-container,
    .highcharts-root,
    .highcharts-plot-border,
    .sc-kImNAt.cJCsai {
        // TODO: if you want to custom styled in write here
    }
    .highcharts-background {
        width: 0;
    }
    .sc-kImNAt.cJCsai {
        width: 40vh;
    }
    .highcharts-credits {
        display: none;
    }
`;
