import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// styled-components
import { MainRightSideTitle } from '../../components/styles/MainTitleStyled';

import MatchDispatchCenter from '../../data_system/redux/modules/match_dispatch_center/match_dispatch_center';

import { ReduxGetLoading } from '../../data_system/redux/modules/redux_functions/redux_get_loading';
import { GET_ACTIVITY_PAYMENTS } from '../../data_system/redux/modules/activityPayments_reducer/activityPayments_table/activityPayments_table_type';
import { h_px20rate, w_px5rate } from '../../design_system/size';

import Dropdown from '../../components/Common/Dropdown';
import SearchBar from '../../design_system/components/searchbar/SearchBar';
import CustomTable from '../../design_system/components/table/CustomTable';
import ActivityPaymentModal from '../../design_system/components/modal/ActivityPaymentModal';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

const ActivityPayment = () => {
    const loading = ReduxGetLoading(GET_ACTIVITY_PAYMENTS);
    const activityPayments = useSelector((state) => state.activityPaymentsReducer.activityPayments);
    const [searchToggle, setSearchToogle] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState('0');
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1);
    const [detailModal, setDetailModal] = useState(false);
    const { onGetActivityPaymentsAsync, onGetActivityPaymentsDetailAsync } = MatchDispatchCenter();

    useEffect(() => {
        const params = {
            searchType: searchType,
            searchWord: searchText,
            page: page,
        };
        onGetActivityPaymentsAsync(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchToggle]);

    const activityPaymentSearch = async () => {
        setSearchToogle(!searchToggle);
    };

    return (
        <Wrapper02>
            <MainPageTitle text={'취미/여가 결제'} />
            <ActivityPaymentModal visible={detailModal} setVisible={setDetailModal} />
            <div style={{ marginTop: h_px20rate, marginBottom: h_px20rate, display: 'flex' }}>
                <Dropdown
                    list={[
                        { id: 1, title: '휴대폰번호', value: 1 },
                        { id: 2, title: '이름', value: 2 },
                        { id: 3, title: '닉네임', value: 3 },
                        { id: 4, title: '제목', value: 4 },
                        { id: 5, title: '엑티비티 ID', value: 5 },
                        { id: 6, title: '주관 업체 명', value: 6 },
                    ]}
                    index={0}
                    resetThenSet={useCallback((item) => {
                        setSearchType(item.value);
                    }, [])}
                />
                <div style={{ marginLeft: w_px5rate }}>
                    <SearchBar
                        searchText={searchText}
                        onChangeSearchText={useCallback((e) => {
                            setSearchText(e.target.value);
                        }, [])}
                        onSearch={activityPaymentSearch}
                    />
                </div>
            </div>
            <div
                style={{
                    marginBottom: h_px20rate,
                }}
            >
                {loading || activityPayments === null || activityPayments === undefined ? (
                    <div>대기중...</div>
                ) : (
                    <CustomTable
                        header={activityPayments.header}
                        data={activityPayments.body}
                        onRowClick={(item) => {
                            onGetActivityPaymentsDetailAsync(item.id);
                            setDetailModal(true);
                        }}
                    />
                )}
            </div>
        </Wrapper02>
    );
};

export default React.memo(ActivityPayment);
