import {
    getActivitiesDetail,
    putActivitiesDetailAdminMemo, putActivityDetail,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import {GET_ACTIVITIES_DETAIL, PUT_ACTIVITIES_DETAIL_ADMIN_MEMO, PUT_ACTIVITY_DETAIL,} from "./activities_detail_type";

export const getActivitiesDetailAsync = createGetRequestThunk(
  GET_ACTIVITIES_DETAIL,
  getActivitiesDetail
);

export const putActivitiesDetailAdminMemoAsync = createPutRequestThunk(
  PUT_ACTIVITIES_DETAIL_ADMIN_MEMO,
  putActivitiesDetailAdminMemo
);
export const putActivityDetailAsync = createPutRequestThunk(
    PUT_ACTIVITY_DETAIL,
    putActivityDetail
)
