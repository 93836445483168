import ListGroup from 'react-bootstrap/ListGroup';
import { useEffect, useState } from 'react';

export const List = ({ data = [], renderItem = null, onPressItem = null, startIndex = -1, style }) => {
    const [index, setIndex] = useState(-1);
    useEffect(() => {
        setIndex(startIndex);
    }, [startIndex]);
    return (
        <ListGroup as="ul" style={style}>
            {data.map((e, i) => (
                <ListGroup.Item
                    key={`list_id_${i}`}
                    as={'li'}
                    active={i === index}
                    onClick={() => {
                        setIndex(i);
                        if (onPressItem) {
                            onPressItem(e, i);
                        }
                    }}
                >
                    {renderItem ? renderItem(e) : e}
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
};
