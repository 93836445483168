import styled from 'styled-components';
import {
    ReverseExcelButtonSt,
    ReverseExcelFont,
    ExcelButtonSt,
    ButtonSt,
    Font,
    ButtonStReverse,
    FontReverse,
    ButtonStReverse02,
    FontReverse02,
    ButtonSt02,
    Font02,
    Button,
    RedDot,
} from './ButtonsStyled';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { CloseOutlined } from '@ant-design/icons';
import { SText } from '../text/ContentText';

/** 메인 버튼 */
export const MainButton = ({ onClick = () => {}, text = '클릭', color = '#fff', style, fontStyle, styleReverse = false, type = 'submit' }) => {
    return styleReverse ? (
        <ButtonStReverse style={{ paddingInline: 8, paddingBlock: 4, ...style }} onClick={onClick} type={type}>
            <FontReverse color={color} style={{ color: 'rgb(66, 139, 247)', ...fontStyle }}>
                {text}
            </FontReverse>
        </ButtonStReverse>
    ) : (
        <ButtonSt style={{ paddingInline: 8, paddingBlock: 4, ...style }} onClick={onClick} type={type}>
            <Font color={color} style={{ ...fontStyle }}>
                {text}
            </Font>
        </ButtonSt>
    );
};

/** Excel Button */
export const ExcelButton = ({ onClick = () => {}, text = '클릭', width = 60, height = 30, color = '#FFF', style, fontStyle, styleReverse = false }) => {
    return styleReverse ? (
        <ReverseExcelButtonSt width={width} height={height} style={{ ...style }} onClick={onClick}>
            <ReverseExcelFont color={styleReverse ? '#167848' : color} style={{ ...fontStyle }}>
                {text}
            </ReverseExcelFont>
        </ReverseExcelButtonSt>
    ) : (
        <ExcelButtonSt width={width} height={height} style={{ ...style }} onClick={onClick}>
            <Font color={color} style={{ ...fontStyle }}>
                {text}
            </Font>
        </ExcelButtonSt>
    );
};

/** 모달창 하단 버튼 */
export const MainModalBtutton = ({ onClick = () => {}, text = '클릭', width = 110, height = 40, color = '#fff', style, fontStyle, styleReverse = false }) => {
    return styleReverse ? (
        <ButtonStReverse02 width={width} height={height} style={{ ...style }} onClick={onClick}>
            <FontReverse02 color={color} style={{ color: 'rgb(66, 139, 247)', ...fontStyle }}>
                {text}
            </FontReverse02>
        </ButtonStReverse02>
    ) : (
        <ButtonSt02 width={width} height={height} style={{ ...style }} onClick={onClick}>
            <Font02 color={color} style={{ ...fontStyle }}>
                {text}
            </Font02>
        </ButtonSt02>
    );
};

export const ItemButton = ({ style, iconStyle, item, onClick = () => {}, alarm = false }) => {
    return (
        <Button style={{ ...style }} onClick={onClick}>
            <div style={{ ...iconStyle }}>{item}</div>
            {alarm && <RedDot />}
        </Button>
    );
};

export const UserModalImageXButton = ({ onClickDelete = () => {} }) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'end', position: 'absolute', top: '5px', right: '5px', justifyContent: 'end', width: '100%' }}>
            <CloseBtn style={{ padding: '4px 5px', borderRadius: '50%' }} onClick={onClickDelete}>
                <SText text={<CloseOutlined style={{ color: '#000' }} />} color="#000" />
            </CloseBtn>
        </FlexMainWrapper>
    );
};

const CloseBtn = styled.div`
    cursor: pointer;
    background-color: #ffffffa3;
    :hover {
        background-color: #ffffff;
    }
`;
