import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ComUtill from '../../utils/comUtil';

// styled-components
import { BellOutlined, MenuOutlined } from '@ant-design/icons';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { Image } from '../../designComponents/imageboxs/Image';
import { errImage } from '../../utils/errImage';
import { ItemButton } from '../../designComponents/buttons/Buttons';

// 최상단 헤더
const TopHeader = ({ logout, setTrigger, adminInfo }) => {
    const navigate = useNavigate();
    const [mode, setMode] = useState('');
    const [infoModal, setInfoModal] = useState(false);
    const [alarmModal, setAlarmModal] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (!ComUtill.IsProduction()) setMode('개발모드');
        else setMode('');
    }, []);

    const handleClickOutside = (event) => {
        if (!ref.current) return;
        else if (ref && !ref.current.contains(event.target)) {
            setInfoModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <Header>
            <FlexMainWrapper style={{ alignItems: 'center', gap: '17px' }}>
                <MenuOutlined style={{ fontWeight: 700, fontSize: '20px', userSelect: 'none' }} onClick={() => setTrigger((prevState) => !prevState)} />
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <MainHeaderRightSide>관리자센터{mode ? ' (개발모드)' : null}</MainHeaderRightSide>
                    </div>
                </Link>
            </FlexMainWrapper>

            <FlexMainWrapper style={{ alignItems: 'center', gap: '30px', marginRight: '20px' }}>
                <FlexMainWrapper style={{ alignItems: 'center', gap: '15px' }}>
                    {adminInfo?.name && (
                        <div style={{ color: 'rgb(78, 89, 104)', fontSize: '13px' }}>
                            <span style={{ fontWeight: 600 }}>{adminInfo?.name}</span>
                            <span style={{ marginLeft: '3px' }}>님 안녕하세요</span>
                        </div>
                    )}
                    <DropDownModal
                        onClick={() => {
                            setAlarmModal((prev) => !prev);
                            alert('현재 기능을 준비중입니다.');
                        }}
                        rederImage={<ItemButton iconStyle={{ fontSize: '25px', margin: '0 3px 0 3px' }} item={<BellOutlined />} onClick={() => setAlarmModal((prev) => !prev)} alarm={!true} />}
                        trigger={alarmModal}
                        modal={<div ref={ref}></div>}
                    />
                    <DropDownModal
                        onClick={() => setInfoModal((prev) => !prev)}
                        rederImage={<Image width={27} height={27} style={{ borderRadius: '50%' }} errImg={errImage({ sex: 1 })} />}
                        trigger={infoModal}
                        modal={
                            <div ref={ref}>
                                <ModalBox
                                    onClickMyInfo={() => {
                                        setInfoModal(false);
                                        navigate(`/myPage/${adminInfo.id}`);
                                    }}
                                    onClickAlert={() => alert('해당 기능은 아직 존재하지 않습니다.')}
                                    onClickLogout={() => {
                                        if (window.confirm('로그아웃을 진행하시겠습니까?')) {
                                            logout();
                                            navigate('/login');
                                        }
                                    }}
                                />
                            </div>
                        }
                    />
                </FlexMainWrapper>
            </FlexMainWrapper>
        </Header>
    );
};

export default TopHeader;

const DropDownModal = ({ onClick, rederImage, trigger, modal, modalStyle }) => {
    return (
        <ModalWrapper>
            <div style={{ cursor: 'pointer' }} onClick={onClick}>
                {rederImage}
            </div>
            {trigger && <ModalItem style={{ ...modalStyle }}>{modal}</ModalItem>}
        </ModalWrapper>
    );
};

const ModalBox = ({ myInfoText = '내 정보', leftText = '알람', onClickMyInfo, onClickAlert, onClickLogout }) => {
    return (
        <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', gap: 0 }}>
            <MainHeaderLogoutButton text={myInfoText} onClick={onClickMyInfo} />
            <MainHeaderLogoutButton text={leftText} onClick={onClickAlert} />
            <MainHeaderLogoutButton onClick={onClickLogout} />
        </FlexMainWrapper>
    );
};

const MainHeaderLogoutButton = ({ fontStyle, text = '로그아웃', onClick }) => {
    return (
        <MainHeaderLogoutBtn style={{ ...fontStyle }} onClick={onClick}>
            {text}
        </MainHeaderLogoutBtn>
    );
};

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #e9eaed;
`;

const ModalWrapper = styled.div`
    position: relative;
`;

const ModalItem = styled.div`
    z-index: 3;
    position: fixed;
    top: 5%;
    right: 1.7%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0.1px 1px 10px 0.1px #dcdee0;
    width: 200px;
`;

const MainHeaderRightSide = styled.div`
    cursor: pointer;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    font-family: noto_sans_kr_bold;
    font-size: 15px;
    font-weight: 600;
    color: rgb(78, 89, 104);
`;

const MainHeaderLogoutBtn = styled.div`
    cursor: pointer;
    font-size: 12px;
    font-family: noto_sans_kr_regular;
    text-align: center;
    width: 80px;
    margin: 5px;
    padding: 10px 50px;
    border-radius: 10px;

    :hover {
        background-color: #f2f4f6;
    }
`;
