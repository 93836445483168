/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';
import {
    Button,
    ButtonWrapper,
    DetailInfoBox,
    DetailInfoLine,
    InfoBox,
    InfoWrapper,
    Input,
    InputWrapper,
    Line,
    MainRightSideTitle,
    Select,
    SubTitle,
    Title,
    Value,
    Wrapper,
} from '../../components/ProductComponent/OrderListsDetailStyled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetail, RESET_STATUS, updateOrderDetail } from '../../data_system/redux/modules/product_reducer/product.order_reducer';

// custom hook
import useInput from '../../hook/useInput';

// components
import LoadingPage from '../../pages/loading/LoadingPage';
import moment from 'moment';
import { SelectLine } from '../../designComponents/dropdowns/Selectdown';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { MainButton } from '../../designComponents/buttons/Buttons';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';

// 주문 상세
function OrderListsDetail() {
    const { order_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // useSelector
    const { orderDetail, loadOrderDetailLoading, loadOrderDetailError, updateOrderMessage } = useSelector((state) => state.orderReducer);
    // state-toggle
    const [updateToggle, setUpdateToggle] = useState(false);
    // state
    const [deliveryCompony, setDeliveryCompony] = useState(''); // 택배사
    const [deliveryNumber, onChanegeDeliveryNumber, setDeliveryNumber] = useInput(0); // 운송장번호
    const [refundAmount, setRefundAmount] = useState(0); // 환불금액
    const [orderStatus, setOrderStatus] = useState(0); // 배송상태
    const [receiverUserName, onChangeReceiverUserName, setReceiverUserName] = useInput(''); // 주문자명
    const [receiverUserPhone, onChangeReceiverUserPhone, setReceiverUserPhone] = useInput(''); // 주문자 폰번호

    useEffect(() => {
        if (loadOrderDetailError) {
            alert(loadOrderDetailError);
        }
    }, [loadOrderDetailError]);

    // 데이터요청
    useEffect(() => {
        dispatch(getOrderDetail(order_id));
    }, [dispatch, order_id]);

    //* 상태적용
    useEffect(() => {
        console.log(orderDetail);
        setDeliveryNumber(orderDetail?.delivery_number ?? '');
        setRefundAmount(orderDetail?.refund_pay_amount);
        setOrderStatus(orderDetail?.order_status_number ?? '');
        setReceiverUserName(orderDetail?.receiver_user_name ?? '');
        setReceiverUserPhone(orderDetail?.user_phone_no ?? '');
        setDeliveryCompony(orderDetail?.delivery_code);
    }, [orderDetail]);

    // 업데이트 완료
    useEffect(() => {
        if (updateOrderMessage) {
            alert(updateOrderMessage);

            dispatch({
                type: RESET_STATUS,
            });

            setUpdateToggle(false);
            dispatch(getOrderDetail(order_id));
        }
    }, [updateOrderMessage]);

    /***************************************** filter *****************************************/

    // 구매금액 필터
    const payFilter = (discountPay, listPay) => {
        let _discountPay = parseInt(discountPay);
        let _listPay = parseInt(listPay);

        if (_discountPay) {
            return <span>{_discountPay.toLocaleString('ko-KR')}원</span>;
        } else {
            return <span>{_listPay.toLocaleString('ko-KR')}원</span>;
        }
    };

    // 결제일시 필터
    const payTimeFilter = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        } else {
            return false;
        }
    };

    /**************************************** function ****************************************/

    // 배송상태 선택
    const onChangeOrderStatus = (e) => {
        let status = parseInt(e.target.value);

        setOrderStatus(status);
    };

    // 상품 삭제
    const onClickDelete = () => {
        if (window.confirm('상품을 삭제하시겠습니까?')) {
            //console.debug('order_id: ', orderDetail?.order_id);
        }
    };

    // 목록보기
    const onClickBackPage = () => {
        navigate(-1);
    };

    // 수정버튼 토글
    const onClickUpdate = () => {
        if (updateToggle) {
            if (window.confirm('수정한 정보가 저장되지 않아도 괜찮나요?')) {
                setUpdateToggle(false);
            }
        } else {
            setUpdateToggle(true);
        }
    };

    //* 저장
    const onClickSave = () => {
        if (window.confirm('수정한 정보를 저장하시겠습니까?')) {
            let param = {
                order_id: orderDetail?.order_id,
                pay_id: orderDetail?.pay_id,
                order_status: orderStatus, // 배송상태
                delivery_code: deliveryCompony, // 택배사
                delivery_number: deliveryNumber, // 운송장 번호
                receiver: receiverUserName, // 이름
                phone_no: receiverUserPhone, // 연락처
                amount: refundAmount, // 환불금액
            };

            dispatch(updateOrderDetail(param));
        }
    };

    /** 상품 상세정보 페이지 이동 */
    const onClickProductDetail = () => {
        return navigate(`/product/${orderDetail?.product_id}/${orderDetail?.host_id}`);
    };
    const sendAlarmTalk = async () => {
        if (deliveryCompony === null || String(deliveryNumber).length === 0 || deliveryNumber === 0) {
            alert('택배사와 운송장번호를 모두 입력해 주세요');
            return;
        }
        const now = moment().format('yyyy년 MM월 dd일');
        if (
            window.confirm(`
            배송 시작 알림톡을 전송하시겠습니까?
            주문ID:${orderDetail?.order_id}
            택배사:${deliveryCompony}
            운송장번호:${deliveryNumber}
            배송시작일 :${now}
            연락처:${receiverUserPhone.replace(/-/g, '')}
            
            * 중복으로 전송되지 않도록 주의해 주세요
        `)
        ) {
            const templateId = 'productDeliveryStart';
            const phoneNo = receiverUserPhone.replace(/-/g, '');
            const orderId = orderDetail?.order_id;
            const productName = orderDetail?.product_name;
            const nickName = orderDetail?.user_nickname ?? orderDetail?.user_name;
            const body = {
                phone_no_list: [phoneNo],
                template_code: templateId,
                params: {
                    닉네임: nickName,
                    배송출발일: now,
                    운송장번호: deliveryNumber,
                    택배사: deliveryCompony,
                    상품명: productName,
                    주문ID: orderId,
                },
            };
            try {
                const response = await MainRefreshCheckApi.post('adminRequest/alarmTalk', body);
                if (response.status === 200) {
                    alert('메시지 전송 완료');
                } else {
                    alert(`메시지 전송 실패 (${response.status})`);
                }
            } catch (e) {
                console.error(e);
                alert('메시지 전송 실패');
            }
        }
    };
    const nullSpanTag = <span style={{ color: 'gray' }}>정보없음</span>;
    return (
        <Wrapper>
            <MainRightSideTitle>
                <span>주문상세</span>
            </MainRightSideTitle>

            {loadOrderDetailLoading ? (
                <LoadingPage text={'주문상세정보를 불러오는 중입니다.'} />
            ) : (
                <InfoWrapper className="info-wrapper">
                    <label id="label">상품 및 배송정보</label>
                    <InfoBox className="product-info-delivery-info">
                        <div className="product-information">
                            <Title>구매 상품 정보</Title>
                            <DetailInfoBox className="detail-info">
                                <DetailInfoLine>
                                    <SubTitle>주문 ID</SubTitle>
                                    <Value>{orderDetail?.order_id}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>주문번호</SubTitle>
                                    <Value>{orderDetail?.order_number}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>상품명</SubTitle>
                                    <Value>
                                        <Link onClick={onClickProductDetail}>{orderDetail?.product_name}</Link>
                                    </Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>옵션정보</SubTitle>
                                    <Value>
                                        {orderDetail?.option_name}
                                        {parseInt(orderDetail?.option_add_price) ? ` / +${orderDetail?.option_add_price}원` : null}
                                    </Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>수량</SubTitle>
                                    <Value>{orderDetail?.order_count || 0}개</Value>
                                </DetailInfoLine>
                            </DetailInfoBox>
                        </div>

                        <div className="host-information">
                            <Title>판매자 정보</Title>
                            <DetailInfoBox className="detail-info">
                                <DetailInfoLine>
                                    <SubTitle>판매자명</SubTitle>
                                    <Value>{orderDetail?.host_name}</Value>
                                </DetailInfoLine>
                            </DetailInfoBox>
                        </div>

                        <div className="product-information" style={{ marginTop: '5%' }}>
                            <Title>주문자 정보</Title>
                            <DetailInfoBox className="detail-info">
                                <DetailInfoLine>
                                    <SubTitle>회원 ID</SubTitle>
                                    <Value>{orderDetail?.user_id || nullSpanTag}개</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>이름/닉네임</SubTitle>
                                    <Value>
                                        {orderDetail?.user_name || nullSpanTag}/{orderDetail?.user_nickname || nullSpanTag}
                                    </Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>생년월일</SubTitle>
                                    <Value>{orderDetail?.user_birthday || nullSpanTag}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>휴대폰번호</SubTitle>
                                    <Value>{orderDetail?.user_phone_no || nullSpanTag}</Value>
                                </DetailInfoLine>
                            </DetailInfoBox>
                        </div>

                        <div className="delivery-information" style={{ marginTop: '5%' }}>
                            <Title>배송 정보</Title>
                            <DetailInfoBox className="detail-info">
                                <DetailInfoLine>
                                    <SubTitle>배송상태</SubTitle>
                                    <FlexMainWrapper>
                                        <Select onChange={onChangeOrderStatus} value={orderStatus}>
                                            <option value={0}>상품 준비중</option>
                                            <option value={1}>배송 중</option>
                                            <option value={2}>배송 완료</option>
                                            <option value={3}>구매 확정</option>
                                            <option value={4}>반품 신청</option>
                                            <option value={5}>반품 완료</option>
                                        </Select>
                                        {orderStatus > 0 ? <MainButton text={'배송 시작 알림톡 전송'} onClick={sendAlarmTalk} /> : null}
                                    </FlexMainWrapper>
                                    {/* <Value>{orderDetail?.order_status}</Value> */}
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <InputWrapper>
                                        <SelectLine
                                            titleStyle={{ width: '23.5vh' }}
                                            selectStyle={{ height: '27px' }}
                                            title={'운송장 번호'}
                                            list={[
                                                { value: null, title: '택배사를 선택해 주세요' },
                                                { value: '롯데택배', title: '롯데택배' },
                                                { value: 'CJ대한통운', title: 'CJ대한통운' },
                                                { value: '우체국택배', title: '우체국택배' },
                                                { value: '로젠택배', title: '로젠택배' },
                                                { value: '한진택배', title: '한진택배' },
                                                { value: '경동택배', title: '경동택배' },
                                                { value: '대신택배', title: '대신택배' },
                                                { value: '드림택배', title: '드림택배' },
                                                { value: '일양로직스', title: '일양로직스' },
                                            ]}
                                            value={deliveryCompony}
                                            resetThenSet={(target) => {
                                                setDeliveryCompony(target.value);
                                            }}
                                        />
                                        <Input type="text" value={deliveryNumber} placeholder={deliveryNumber ? deliveryNumber : '운송장 번호 입력'} onChange={onChanegeDeliveryNumber} />
                                    </InputWrapper>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>이름</SubTitle>
                                    <InputWrapper>
                                        <Input type="text" value={receiverUserName} onChange={onChangeReceiverUserName} placeholder="주문자명을 입력하세요" />
                                        {/* <Value>{receiverUserName}</Value> */}
                                    </InputWrapper>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>연락처</SubTitle>
                                    <InputWrapper>
                                        <Input type="text" value={receiverUserPhone} onChange={onChangeReceiverUserPhone} placeholder="주문자 연락처을 입력하세요" />
                                        {/* <Value>{receiverUserPhone || <span style={{ color: 'gray' }}>정보없음</span>}</Value> */}
                                    </InputWrapper>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>주소</SubTitle>
                                    <Value>{orderDetail?.address ? `${orderDetail?.address} ${orderDetail?.address_detail}` : nullSpanTag}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>배송메세지</SubTitle>
                                    <Value>{orderDetail?.delivery_message || nullSpanTag}</Value>
                                </DetailInfoLine>
                            </DetailInfoBox>
                        </div>
                    </InfoBox>

                    <Line />

                    <label id="label">결제정보</label>
                    <InfoBox className="pay-info-btn-wrapper">
                        <div className="pay-info">
                            <Title>결제 정보</Title>
                            <DetailInfoBox className="detail-info">
                                <DetailInfoLine>
                                    <SubTitle>결제 ID</SubTitle>
                                    <Value>{orderDetail?.pay_id || nullSpanTag}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>결제 상태</SubTitle>
                                    <Value>{orderDetail?.pay_status || nullSpanTag}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>결제 수단</SubTitle>
                                    <Value>{orderDetail?.pay_type || nullSpanTag}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>결제 일시</SubTitle>
                                    <Value>{payTimeFilter(orderDetail?.pay_created_at) || nullSpanTag}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>취소/환불완료 일시</SubTitle>
                                    <Value>{orderDetail?.pay_status_number === 4 ? orderDetail?.pay_complated_at : nullSpanTag}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>구매 금액</SubTitle>
                                    <Value>{payFilter(orderDetail?.product_discount_price, orderDetail?.product_list_price)}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>포인트 사용</SubTitle>
                                    <Value>
                                        {orderDetail?.use_point_amount ? orderDetail?.use_point_amount.toLocaleString('ko-KR') : 0}
                                        포인트
                                    </Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>실 결제금액</SubTitle>
                                    <Value>{payFilter(orderDetail?.pay_amount)}</Value>
                                </DetailInfoLine>

                                <DetailInfoLine>
                                    <SubTitle>포인트 적립</SubTitle>
                                    <Value>{orderDetail?.used_point ? orderDetail?.used_point : 0}포인트</Value>
                                </DetailInfoLine>

                                {orderDetail?.pay_status_number === 3 && (
                                    <DetailInfoLine>
                                        <SubTitle>환불금액</SubTitle>
                                        <Input
                                            style={{ width: 'auto' }}
                                            type="number"
                                            placeholder={refundAmount ? refundAmount.toLocaleString('ko-KR') : '환불금액을 입력하세요'}
                                            onChange={onChanegeDeliveryNumber}
                                        />
                                        <span style={{ fontSize: '13px', color: '#797979' }}>원</span>
                                    </DetailInfoLine>
                                )}

                                <DetailInfoLine>
                                    <SubTitle>관리자 메모</SubTitle>
                                    <Value>{orderDetail?.admin_memo || nullSpanTag}</Value>
                                </DetailInfoLine>
                            </DetailInfoBox>
                        </div>

                        <div className="button-wrapper">
                            <ButtonWrapper color={updateToggle}>
                                <Button className="cancel" onClick={onClickDelete}>
                                    상품 삭제
                                </Button>
                                <Button className="save" onClick={onClickSave}>
                                    저장
                                </Button>
                                <Button className="back" onClick={onClickBackPage}>
                                    목록보기
                                </Button>
                            </ButtonWrapper>
                        </div>
                    </InfoBox>
                </InfoWrapper>
            )}
        </Wrapper>
    );
}

export default OrderListsDetail;

const Link = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: #2977ff;

    &:hover {
        color: #004fd8;
    }
`;
