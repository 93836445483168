import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';
import { QuestionBox, Question } from './QuestionComponent';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getResponseSummarize } from '../../data_system/redux/modules/survey.reducer/survey.reducer';

// 설문관리 상세조회 > 응답 > 요약
function ResponseSummarize({ count }) {
    const dispatch = useDispatch();

    // useParams
    const { survey_id } = useParams();

    // useSelector
    const { answerSummarize, answerUsers, questionLists } = useSelector((state) => state.getSurveyReducer);

    /******************************** useEffect ********************************/

    // 데이터 요청
    useEffect(() => {
        dispatch(getResponseSummarize(survey_id));
    }, [dispatch, survey_id]);

    /******************************** function ********************************/

    // 소수판별
    function isPrime(N) {
        if (N === 1) return false;
        for (let i = 2; i <= N - 1; i++) {
            if (N % 2 === 0) return false;
        }
        return true;
    }

    /********************************** html **********************************/
    return (
        <div>
            <QuestionBox>
                <Question>참여자 목록</Question>

                <Question style={{ height: '45vh', overflow: 'auto' }}>
                    {answerUsers?.map((data, index) => {
                        return (
                            <ListItem key={index}>
                                {data.user_name || <span style={{ color: 'gray' }}>{data.user_nickname ? <span>{data.user_nickname}(닉네임)</span> : '정보없음'}</span>}
                                {data.user_phone && <span style={{ margin: '0 3px' }}>/</span>}
                                <span>{data.user_phone}</span>
                                <span style={{ margin: '0 3px' }}>/</span>
                                <span style={{ color: data.used_point === '미지급' ? 'red' : 'rgb(66,139,247)' }}>{data.used_point}</span>
                            </ListItem>
                        );
                    })}
                </Question>
            </QuestionBox>

            {questionLists?.map((data, index) => {
                return (
                    <QuestionBox key={index}>
                        <Question>
                            {data.sequence + 1}. {data.question}
                        </Question>

                        {answerSummarize?.map((data, answer_index) => {
                            return (
                                <div key={answer_index}>
                                    {data.sequence === index ? (
                                        <AnswerResultWrapper>
                                            <Question
                                                style={{
                                                    margin: '0',
                                                    width: '110px',
                                                    textAlign: 'end',
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {data.answer_text}
                                            </Question>

                                            <AnswerResultWrapper style={{ gap: '2px', width: '100%' }}>
                                                <ResultBar width={(data.answer_count / count) * 100} />
                                                <UnderLine />
                                                <div style={{ fontSize: '13px' }}>
                                                    {data.answer_count}명 (
                                                    {isPrime(parseFloat((data.answer_count / count) * 100).toFixed(1))
                                                        ? parseFloat((data.answer_count / count) * 100).toFixed(1)
                                                        : (data.answer_count / count) * 100}
                                                    %)
                                                </div>
                                            </AnswerResultWrapper>
                                        </AnswerResultWrapper>
                                    ) : null}
                                </div>
                            );
                        })}
                    </QuestionBox>
                );
            })}
        </div>
    );
}

export default ResponseSummarize;

/********************************** styled **********************************/

const ListItem = styled.div`
    background-color: #fff;
    border-radius: 5px;

    font-size: 13px;
    font-weight: 400;

    width: 100%;

    padding: 5px 7px;
    margin: 2px 0;
`;

const AnswerResultWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    margin-bottom: 5px;
`;

const ResultBar = styled.div`
    width: ${(props) => `${props.width}%`}; // 100px;
    height: 22px;
    background-color: rgb(103, 58, 183);
`;

const UnderLine = styled.div`
    background-color: black;
    width: 5px;
    height: 1px;
`;
