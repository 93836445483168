import React from 'react';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';
import { SText } from './ContentText';
import { TextArea } from '../inputs/TextArea';

export const FlexTwoTextLists = ({ wrapperStyle, lists }) => {
    return (
        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', justifyContents: 'start', gap: 0, ...wrapperStyle }}>
            {lists.map((item, _) => (
                <FlexTwoText
                    wrapperStyle={{ margin: '7px 0', ...item.wrapperStyle }}
                    text01Style={{ width: '93px', textAlign: 'start', ...item.text01Style }}
                    text02Style={item.text02Style}
                    text01={item.text01}
                    text02={item.text02}
                />
            ))}
        </FlexMainWrapper>
    );
};

export const FlexTwoText = ({ wrapperStyle, text01Style, text02Style, text01, text02, gap = 0, fromTo = false }) => {
    return (
        <FlexMainWrapper style={{ alignItems: 'center', justifyContents: 'start', gap: `${gap}px`, ...wrapperStyle }}>
            <SText style={{ color: '#797979', ...text01Style }} text={text01} />
            {fromTo ? <span style={{ color: '#797979' }}>~</span> : null}
            <SText style={text02Style} text={text02} />
        </FlexMainWrapper>
    );
};

export const FlexColumnWithTextArea = ({ wrapperStyle, title = '제목', color = '#797979', height = 20, value, placeholder, disabled = true, imgYn = false, imgComponent, onChange = () => {} }) => {
    return (
        <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', margin: '15px 0', ...wrapperStyle }}>
            <SText text={title} color={color} />
            {imgYn && imgComponent}
            <TextArea style={{ height: `${height}vh` }} disabled={disabled} value={value} placeholder={placeholder} onChange={onChange} />
        </FlexMainWrapper>
    );
};
