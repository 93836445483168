import React, { useEffect, useState } from 'react';
import moment from 'moment';

// styled-components
import styled from 'styled-components';
import { CaretDownOutlined, CaretUpOutlined, RightOutlined, RedoOutlined } from '@ant-design/icons';

// filter
import { questionTableFilter } from './QuestionListsTableFilter';
import { useSelector } from 'react-redux';

// react-query
import { useMutation } from 'react-query';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import LoadingPage from '../../pages/loading/LoadingPage';
import ChoisCategoryModal from './ChoisCategoryModal';
import QuestionDetailModal from './QuestionDetailModal';
import AddQuestionModal from './AddQuestionModal';

// 질문 리스트 테이블
function QuestionListsTable({ data, param, setParam, getQuestionLists, interestCodeLists, setInterestCodeLists, mainCategoryNum, setMainCategoryNum, categoryNum, setCategoryNum }) {
    // useSelector
    const { getQuestionListsLoading } = useSelector((state) => state.questionReducer);
    // state-toggle
    const [categoryToggle, setCategoryToggle] = useState(false);
    const [questionDetailToggle, setQuestionDetailToggle] = useState(false); // 상세보기 토글
    const [addQuestionToggle, setAddQuestionToggle] = useState(false); // 상세보기 토글
    // state
    const [filterToggle, setFilterToggle] = useState(false);
    const [checkItems, setCheckItems] = useState([]); // 체크된 회원id를 담을 배열
    const [questionID, setQuestionID] = useState(0);

    // react-query
    const { mutate, isLoading, isError, error, isSuccess } = useMutation('delete', async () => {
        let deleteData = await RefreshCheckApi.patch(`/adminQuestion/delete`, {
            id_lists: checkItems,
        }).then((res) => {
            return res.data.message;
        });

        return deleteData;
    });

    /******************************** useEffect ********************************/
    // 삭제 에러 발생 시
    useEffect(() => {
        if (isError && error) {
            alert(`[Server Error] ${isError && error.response.data.message}`);
        }
    }, [isError, error]);

    // 삭제 성공 알림
    useEffect(() => {
        if (isSuccess) {
            alert('data 삭제 완료');
        }
    }, [isSuccess]);

    /******************************** filter ********************************/

    // 날짜 필터
    const dateFilter = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        } else {
            return <span style={{ color: 'gray' }}>정보없음</span>;
        }
    };

    /******************************** function ********************************/

    // 상세검색 필터
    const onClickFilterToggle = () => {
        setFilterToggle((prev) => !prev);
    };

    // 필터 함수 핸들러
    const onHandleChangeFilter = (list, item) => (e) => {
        onChangeFilter(e.target.checked, list, item);
    };

    // 필터 함수
    const onChangeFilter = (checked, list, item) => {
        let tmp = param;

        if (checked) {
            tmp[list.key] = [...(tmp[list.key] ?? []), String(item.key)];
        } else {
            tmp[list.key] = tmp[list.key].filter((e) => String(e) !== String(item.key));

            if (tmp[list.key].length === 0) {
                delete tmp[list.key];
            }
        }

        setParam(tmp);
        getQuestionLists(tmp);
    };

    // 체크박스 단일 선택
    const handleSingleCheck = (checked, id) => {
        if (checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            setCheckItems((prev) => [...prev, id]);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if (checked) {
            // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
            const idArray = [];
            data.forEach((el) => idArray.push(el.id));
            setCheckItems(idArray);
        } else {
            // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
            setCheckItems([]);
        }
    };

    // 클릭 시 단일 체크
    const onClickSingleCheck = (data) => (e) => {
        return handleSingleCheck(e.target.checked, data.id);
    };

    // 클릭 시 전체 체크
    const onClickAllCheck = (e) => {
        return handleAllCheck(e.target.checked);
    };

    // 카테고리 Modal Toggle
    const onClickCategory = () => {
        setCategoryToggle(true);
    };

    // 상세보기
    const onClickDetail = (id) => () => {
        setQuestionID(id);
        setQuestionDetailToggle(true);
    };

    // 질문등록
    const onClickAdd = () => {
        setAddQuestionToggle(true);
    };

    // 새로고침
    const onClickReaload = () => {
        getQuestionLists(param);
    };

    /********************************** html **********************************/
    if (isLoading) {
        return <LoadingPage />;
    } else {
        return (
            <TableWrapper filterToggle={filterToggle} data={data}>
                <label id="label">질문등록 모달창</label>
                <div id="add-question">{addQuestionToggle && <AddQuestionModal setToggle={setAddQuestionToggle} />}</div>

                <label id="label">카테고리 분류 모달창</label>
                <div id="modal">
                    {categoryToggle && (
                        <ChoisCategoryModal
                            setInterestToggle={setCategoryToggle}
                            interestCodeLists={interestCodeLists}
                            setInterestCodeLists={setInterestCodeLists}
                            mainCategoryNum={mainCategoryNum}
                            setMainCategoryNum={setMainCategoryNum}
                            categoryNum={categoryNum}
                            setCategoryNum={setCategoryNum}
                        />
                    )}
                </div>

                <label id="label">질문 상세정보 모달창</label>
                <div id="detail-modal">{questionDetailToggle && <QuestionDetailModal id={questionID} setToggle={setQuestionDetailToggle} />}</div>

                <label id="label">등록 및 삭제버튼</label>
                <BtnLineWrapper id="create-delete-button">
                    <RealoadBtnWrapper className="reload" onClick={onClickReaload}>
                        <RedoOutlined className="icon" />
                        <span className="reaload-text">새로고침</span>
                    </RealoadBtnWrapper>

                    <ButtonWrapper>
                        <button className="create" onClick={onClickAdd}>
                            등록
                        </button>
                        <button className="delete" onClick={mutate}>
                            삭제
                        </button>
                    </ButtonWrapper>
                </BtnLineWrapper>

                <label id="label">테이블</label>
                <div id="table">
                    <MoreKategory onClick={onClickFilterToggle}>
                        <div>
                            <span>상세검색 버튼</span>
                            {filterToggle ? <CaretUpOutlined /> : <CaretDownOutlined />}
                        </div>
                    </MoreKategory>

                    <table>
                        <thead className="filter">
                            {filterToggle && (
                                <tr className="filter-item">
                                    <td className="none-td" />
                                    {questionTableFilter.map((list, index) => {
                                        if (list.type) {
                                            return (
                                                <td key={index}>
                                                    <CategoryButton onClick={onClickCategory}>카테고리</CategoryButton>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={index}>
                                                    {list.filter &&
                                                        list.filter.map((item, index) => {
                                                            return (
                                                                <KategoryWord key={index}>
                                                                    <Checkbox type="checkbox" onChange={onHandleChangeFilter(list, item)} />
                                                                    <div>{item.value}</div>
                                                                </KategoryWord>
                                                            );
                                                        })}
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                            )}
                        </thead>

                        <tbody className="title">
                            <tr>
                                <td>
                                    <Checkbox type="checkbox" onChange={onClickAllCheck} checked={checkItems?.length === data?.length ? true : false} />
                                </td>
                                <td>생성일</td>
                                <td>수정일</td>
                                <td>삭제여부</td>
                                <td>대분류→소분류</td>
                                <td>질문</td>
                                <td>답변</td>
                            </tr>
                        </tbody>

                        <tbody className="body">
                            {data?.map((item, index) => {
                                if (getQuestionListsLoading) {
                                    return (
                                        <tr key={index}>
                                            <td colSpan={7}>
                                                <LoadingPage text="질문 리스트를 불러오는 중입니다." />
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Checkbox
                                                    type="checkbox"
                                                    onChange={onClickSingleCheck(item)}
                                                    checked={checkItems.includes(item.id) ? true : false} // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                                />
                                            </td>
                                            <td onClick={onClickDetail(item.id)} className="created-at">
                                                {dateFilter(item.created_at)}
                                            </td>
                                            <td onClick={onClickDetail(item.id)} className="updated-at">
                                                {dateFilter(item.updated_at)}
                                            </td>
                                            <td onClick={onClickDetail(item.id)} className="deleted-at">
                                                {dateFilter(item.deleted_at)}
                                            </td>
                                            <td onClick={onClickDetail(item.id)} className="category">
                                                <CategoryWrapper>
                                                    <CategoryIcon>{item.main_category_name}</CategoryIcon>
                                                    <RightOutlined />
                                                    <CategoryIcon02>{item.sub_category_name}</CategoryIcon02>
                                                </CategoryWrapper>
                                            </td>
                                            <td onClick={onClickDetail(item.id)} className="question">
                                                {item.question}
                                            </td>
                                            <td onClick={onClickDetail(item.id)} className="answer">
                                                {item.answer}
                                            </td>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            </TableWrapper>
        );
    }
}

export default QuestionListsTable;

/********************************** styled **********************************/

const BtnLineWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const RealoadBtnWrapper = styled.div`
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: start;
    gap: 3px;

    .icon {
        font-size: 20px;
    }

    .reaload-text {
        font-size: 13px;
        font-weight: 600;

        margin-top: 2px;
    }
`;

const CategoryButton = styled.button`
    cursor: pointer;
    background-color: rgb(66, 139, 247);
    color: #fff;

    padding: 5px 8px;

    border: 0;
    border-radius: 3px;

    :hover {
        background-color: rgb(33 116 239);
    }
`;

const TableWrapper = styled.div`
    width: 98%;

    overflow: auto;

    @media (max-width: 912px) {
        display: block;
    }

    #label {
        display: none;
    }

    table {
        border-collapse: collapse;

        width: 100%;
        overflow-y: auto;

        font-size: 13px;
    }

    table > thead > tr > td {
        text-align: start;
        padding: 5px 15px;
    }

    table > .title > tr {
        border-top: ${(props) => (props.filterToggle ? '1px solid black' : '')};
        border-bottom: 1px solid black;

        text-align: start;

        border-bottom: 1px solid black;
    }

    table > .title > tr > td {
        text-align: start;
        font-weight: bold;

        padding: 10px 15px;
    }

    table > .title > tr > th {
        text-align: start;
        padding: 10px 15px;

        border-top: ${(props) => (props.filterToggle ? '1px solid black' : '')};
        border-bottom: 1px solid black;
    }

    table > .body > tr:hover {
        cursor: ${(props) => (props.data?.length === 0 ? null : 'pointer')};
        background-color: ${(props) => (props.data?.length === 0 ? '' : '#f4f4ff')};
    }

    table > .body > tr {
        height: 57px;
    }

    table > .body > tr > td {
        text-align: start;
        padding: 0 15px;

        border-bottom: 1px solid black;
    }

    .created-at {
        width: 10%;
    }
    .updated-at {
        width: 10%;
    }
    .deleted-at {
        width: 10%;
    }
    .category {
        width: 13%;
    }
    .question {
        width: 20%;
    }
    .answer {
        width: 37%;
    }
`;

const CategoryWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;
`;

const CategoryIcon = styled.div`
    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border-radius: 3px;
    background-color: rgb(118 139 172);
`;

const CategoryIcon02 = styled.div`
    text-align: center;
    color: #fff;

    width: auto;
    padding: 5px 8px;

    border-radius: 3px;
    background-color: rgb(19 164 249);
`;

const MoreKategory = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    user-select: none;

    width: 100%;
    height: 25px;

    border-top: 1px solid black;
    border-bottom: 1px solid black;

    text-align: center;
    font-size: 15px;
    font-weight: 600;

    .icon {
        font-size: 25px;
        font-weight: bold;
        line-height: 10px;
    }

    :hover {
        background-color: #dfdfdf;
    }
`;

const KategoryWord = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;

    font-size: 13px;
    font-weight: 500;
`;

const Checkbox = styled.input`
    cursor: pointer;

    margin: 0 3px 3px 0;

    width: 15px;
    height: 15px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 3px;

    margin-bottom: 5px;

    .create {
        cursor: pointer;

        width: 60px;
        height: 30px;

        background-color: #428bf7;
        color: #fff;

        border: 0;
    }

    .create:hover {
        background-color: #1d6ee6;
    }

    .delete {
        cursor: pointer;

        width: 60px;
        height: 30px;

        background-color: #fff;
        color: #428bf7;

        border: 1px solid #428bf7;
    }

    .delete:hover {
        background-color: #f1eaea;
    }
`;
