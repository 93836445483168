import React, { useState, useCallback, useRef, useEffect } from 'react';

// S3-route
import { main_server_image_ip } from '../../communication_system/communication_ips';

// styled-components
import { PlusOutlined } from '@ant-design/icons';
import { ImageInfoWrapper, SubTitle, AddImageBtnWrapper, AddImageBtn, Image, InfoWrapper, InputWrapper, RadioInput } from '../styles/ActivityDetailModalInfoEntryStyled';

// 기본정보 기입
function ActivityDetailModalInfoEntry({ activityDetail, setInfoParam }) {
    const [exposure, setExposure] = useState(0);
    const [crawling, setCrawling] = useState(0);
    const [imageForm, setImageForm] = useState([]);

    // 데이터 상태적용
    useEffect(() => {
        setExposure(Number(activityDetail?.exposure_yn));
        setCrawling(Number(activityDetail?.crawling_yn));
    }, [activityDetail]);

    useEffect(() => {
        setInfoParam({
            detail_image_list: imageForm, // 썸네일 리스트
            exposure_yn: exposure, // 노출여부
            crawling_yn: crawling, // 크롤링 여부
        });
    }, [exposure, crawling, imageForm, setInfoParam]);

    /******************** function ********************/

    // 카테고리 번호 유형
    const categoryCodeNumber = (code) => {
        switch (Number(code)) {
            case 0:
                return '여행';
            case 1:
                return '교육';
            case 2:
                return '문화/예술';
            case 3:
                return '건강';
            default:
                return 'NULL';
        }
    };

    // 이미지 클릭 useRef()
    const imageInput = useRef();
    const onClickImageUpload = useCallback(() => {
        imageInput.current.click();
    }, [imageInput]);

    // 이미지 업로드
    const onChangeImages = useCallback(
        (e) => {
            const imageFormData = new FormData();
            [].forEach.call(e.target.files, (f) => {
                imageFormData.append('image', f);
            });

            // 이미지 form 담기
            setImageForm(imageFormData);
        },
        [setImageForm],
    );

    // 노출상태 변환
    const onChangeExposure = () => {
        if (exposure === 1) {
            setExposure(0);
        } else if (exposure === 0) {
            setExposure(1);
        }
    };

    // 크롤링 여부 변환
    const onChangeCrawling = () => {
        if (crawling === 1) {
            setCrawling(0);
        } else if (crawling === 0) {
            setCrawling(1);
        }
    };

    /******************** html ********************/
    return (
        <>
            <ImageInfoWrapper id="thumbnail">
                <SubTitle>썸네일</SubTitle>
                <AddImageBtnWrapper>
                    <input type="file" multiple hidden ref={imageInput} onChange={onChangeImages} />
                    <AddImageBtn onClick={onClickImageUpload}>
                        <PlusOutlined />
                        <div>사진 불러오기</div>
                    </AddImageBtn>

                    {activityDetail?.detail_image_list.map((src, index) => {
                        return <Image src={main_server_image_ip + src} alt="이미지" key={index} />;
                    })}
                </AddImageBtnWrapper>
            </ImageInfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>카테고리</SubTitle>
                <div>{categoryCodeNumber(Number(activityDetail?.category))}</div>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>호스트</SubTitle>
                <div>{activityDetail?.host_name}</div>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>노출 상태</SubTitle>
                <InputWrapper>
                    <div className="check-box">
                        <RadioInput checked={exposure ? true : false} onChange={onChangeExposure} />
                        <span>ON</span>
                    </div>
                    <div className="check-box">
                        <RadioInput checked={exposure ? false : true} onChange={onChangeExposure} />
                        <span>OFF</span>
                    </div>
                </InputWrapper>
            </InfoWrapper>

            <InfoWrapper id="category">
                <SubTitle>크롤링 여부</SubTitle>
                <InputWrapper>
                    <div className="check-box">
                        <RadioInput checked={crawling ? true : false} onChange={onChangeCrawling} />
                        <span>Y</span>
                    </div>
                    <div className="check-box">
                        <RadioInput checked={crawling ? false : true} onChange={onChangeCrawling} />
                        <span>N</span>
                    </div>
                </InputWrapper>
            </InfoWrapper>
        </>
    );
}

export default ActivityDetailModalInfoEntry;
