import React, { useCallback, useRef, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';

// components
import { chattingTableFilter02 } from '../../components/ChattingComponents/ChattingTableFilter';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// design-components
import MainTable from '../../designComponents/tables/MainTable';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

// utils
import { availFilter, dateFilter, sexFilter } from '../../utils/textFilter';
import { ChatModalComponent } from '../../designComponents/modals/ModalComponent';
import ChatDetail from '../../components/ChattingComponents/ChatDetail';
import ChatModalHeader from '../../components/ChattingComponents/ChatModalHeader';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';

// reactBootstrap
import ToggleSwitch from '../../common/BootStrap/ToggleSwitch';
import TableFilter from '../../designComponents/filters/TableFilter';
import { patchChattingDisabledRequest } from '../../api/main/chatting/ChattingRequest';

/** 채팅페이지 */
const Chatting = () => {
    const [param, setParam] = useState({
        order: -1,
        chatType: [1, 4],
    });
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [usersInfo, setUsersInfo] = useState([]);
    const [usersProfile, setUsersProfile] = useState([]);

    const [chatParams, setChatParams] = useState({ toggle: false });
    const [chatText, setChatText] = useState('');

    const [disable, setDisable] = useState(false);

    const ref = useRef();
    const tableRef = useRef();

    /** 1: 단짝, 2: 모임, 3: 모임단체 */
    const callApi02 = async (page, param) => {
        try {
            const input = {
                searchType: searchType,
                searchWord: searchText,
                type: 1,
                page: page,
                size: 10,
                ...param,
            };
            const response = await RefreshCheckApi.post(`/adminChatting/list`, input);
            setUsersInfo(response.data.usersInfo);
            setUsersProfile(response.data.usersProfile);
            return response.data.data;
        } catch (error) {
            console.error('error: ', error);
        }
    };

    /** 1: 단짝, 2: 모임, 3: 모임단체 */
    const pageCountCallApi02 = async (page, param) => {
        try {
            const input = {
                searchType: searchType,
                searchWord: searchText,
                type: 1,
                page: page,
                size: 10,
                order: 0,
                ...param,
            };
            const response = await RefreshCheckApi.post(`/adminChatting/list`, input);
            return response.data.allCount;
        } catch (error) {
            console.error('error: ', error);
        }
    };

    const onChangeDisabledChatting = async (e) => {
        const checkedYn = e.target.checked;
        const chattingId = e.target.id;

        if (e.target.checked) {
            e.target.label = '활성화';
        } else {
            e.target.label = '비 활성화';
        }

        if (!chattingId) {
            return alert('채팅창 ID가 없습니다,  관리자에게 문의해주세요 ');
        }

        try {
            await patchChattingDisabledRequest({
                chatting_id: chattingId,
                disable_yn: !checkedYn,
            });
        } catch (err) {
            if (err.response.status_code === 500) {
                alert('네트 워크 오류, 관리자에게 문의해주세요');
            }
        }
    };

    return (
        <Wrapper02>
            <MainPageTitle text={'단짝 대화'} onClick={() => ref?.current?.refresh()} />
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '채팅 내용', value: 4 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
                setSearchText={setSearchText}
            />

            <TableFilter
                param={param}
                setParam={setParam}
                topFilterLists={[
                    {
                        filterText: '정렬 조건',
                        orderLists: [
                            { name: '대화 최신 순', width: 70, type: -1 },
                            { name: '대화 나중 순', width: 70, type: 4 },
                            { name: '대화 종료 순', width: 70, type: 1 },
                            { name: '대화 높은 순', width: 70, type: 2 },
                            { name: '대화 적은 순', width: 70, type: 3 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.order = type;
                            else delete obj.order;
                            setParam(obj);
                        },
                        orderbyType: param.order,
                    },
                    {
                        filterText: '채팅 종류',
                        orderLists: [
                            { name: '일반 채팅', width: 70, type: 1 },
                            { name: '랜덤 채팅', width: 70, type: 4 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.type = type;
                            else delete obj.type;
                            setParam(obj);
                        },
                        orderbyType: param.type,
                    },
                ]}
                research={ref}
            />

            <FlexMainWrapper>
                <MainTable
                    buttonComponents={({ checkItems }) => {}}
                    ref={ref}
                    tableStyle={{ width: chatParams.toggle ? '70%' : '98%' }}
                    tableRef={tableRef}
                    loadingText={'채팅 내역을 불러오고 있습니다.'}
                    headerData={chattingTableFilter02}
                    checkID={'id'}
                    param={param}
                    setParam={setParam}
                    toggleBlur={true}
                    callApi={callApi02}
                    pageCountCallApi={pageCountCallApi02}
                    onClickRowItem={(item) => async () => {
                        try {
                            const response = await RefreshCheckApi.get(`/adminChatting/chat/${item.id}`);
                            if (response.status === 200) setChatParams({ toggle: true, data: response.data.data });
                        } catch (error) {
                            console.error('error: ', error);
                        }
                    }}
                    tbodyRenderItem={(key, value, data) => {
                        const zeroCount = <span style={{ color: '#797979' }}>0회</span>;
                        if (key === 'first_contents') return value ? dateFilter(value) : '-';
                        if (key === 'deleted_at') return availFilter(data.info.disabled ? 0 : 1, 'PROGRESS', 'DELETE', { Y: { width: '65px' } });
                        if (key === 'avail_yn') return availFilter(value);
                        if (key === 'user_sex' || key === 'target_user_sex') return sexFilter(value);
                        if (!key) return <ArrowRightOutlined />;
                        if (key === 'disable_button')
                            return <ToggleSwitch initChecked={!data.info.disabled} onChange={onChangeDisabledChatting} id={data.id} label={!data.info.disabled ? '활성화' : '비활성화'} />;
                        if (key === 'call_count') return value ? <span>{value}회</span> : zeroCount;
                        if (key === 'from') {
                            const [user] = usersInfo?.filter((v) => Number(v.id) === Number(value));
                            return (
                                <span>
                                    {user?.name}/{user?.nick_name}/{sexFilter(user?.sex)}
                                </span>
                            );
                        }
                        if (key === 'to') {
                            const [user] = usersInfo?.filter((v) => Number(v.id) === Number(value));
                            return (
                                <span>
                                    {user?.name}/{user?.nick_name}/{sexFilter(user?.sex)}
                                </span>
                            );
                        }
                        return value;
                    }}
                />
                {chatParams.toggle && (
                    <ChatModalComponent
                        toggle={chatParams.toggle}
                        closeModal={() => setChatParams({ toggle: false })}
                        header={<ChatModalHeader chatParams={chatParams} usersInfo={usersInfo} usersProfile={usersProfile} />}
                        body={<ChatDetail data={chatParams.data} userInfo={usersInfo} usersProfile={usersProfile} />}
                        onSubmit={async (e) => {
                            e.preventDefault();
                            setChatText('');
                        }}
                        inputValue={chatText}
                        onChange={(e) => setChatText(e.target.value)}
                    />
                )}
            </FlexMainWrapper>
        </Wrapper02>
    );
};

export default Chatting;
