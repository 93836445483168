import React, { useState } from 'react';

// styled-components
import styled from 'styled-components';

// 1개월 구독권: 25,000, 3개월 구독권: 60,000, 1개월(관심): 5,000
function TicketInfoComponent({ type, setPoint }) {
    const titleFilter = (type) => {
        if (type === 0) {
            return '25,000원';
        } else if (type === 1) {
            return '60,000원';
        } else {
            return '5,000원';
        }
    };

    const onChangePoint = (e) => {
        setPoint(parseInt(e.target.value));
    };

    return (
        <div>
            <ColWrapper className="pay-contents">
                <Subtitle>결제가격</Subtitle>
                <SubInfo>{titleFilter(type)}</SubInfo>
            </ColWrapper>

            <ColWrapper className="pay-contents">
                <Subtitle>포인트</Subtitle>
                <SubInfo>
                    <Input type="number" placeholder="포인트 입력" onChange={onChangePoint} />
                </SubInfo>
            </ColWrapper>
        </div>
    );
}

export default TicketInfoComponent;

/********************************* styled *********************************/

const ColWrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0 1% 2% 1%;
`;

const Subtitle = styled.div`
    font-size: 13px;
    color: gray;

    width: 85px;
`;

const SubInfo = styled.div`
    font-size: 13.5px;
    font-weight: 500;

    width: 60px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;
    height: 21px;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;
