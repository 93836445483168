import {
    getAdministratorsDetail,
    putAdministratorsDetail,
} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import createPutRequestThunk from "../../redux_functions/create_put_request_thunk";
import {GET_ADMINISTRATORS_DETAIL, PUT_ADMINISTRATORS_DETAIL,} from "./administrators_detail_type";

export const getAdministratorsDetailAsync = createGetRequestThunk(
  GET_ADMINISTRATORS_DETAIL,
  getAdministratorsDetail
);

export const putAdministratorsDetailAsync = createPutRequestThunk(
  PUT_ADMINISTRATORS_DETAIL,
  putAdministratorsDetail
);
