import BootStrapTabs from "../../common/BootStrap/BootStrapTabs";

const PlatformTab = ({setPlatform}) => {
	const tabList = [
		{
			title: '전체 회원',
			key: 0
		},{
			title: '시럽 회원',
			key: 1
		},{
			title: '시놀 회원',
			key: 2
		},
	]


	return (
		<BootStrapTabs tabList={tabList} setValue={setPlatform}></BootStrapTabs>
	)

}


export default PlatformTab