import React, { useState } from 'react';

import GatheringAccountInfo from './GatheringAccountInfo';
import GatheringAccountUseInfo from './GatheringAccountUseInfo';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import GatheringAccountTab from "../../GroupComponent/Tab/GatheringAccountTab";

/** 계좌관리 */
const GatheringAccount = () => {
    const [tabIndex, setTabIndex] = useState(0)


    return (
        <Wrapper02>
            <GatheringAccountTab setIndex={setTabIndex}></GatheringAccountTab>

            {tabIndex === 0 && <GatheringAccountInfo />}
            {tabIndex === 1 && <GatheringAccountUseInfo />}
        </Wrapper02>
    );
};

export default GatheringAccount;
