import {bindActionCreators} from "redux";
import {useDispatch} from "react-redux";
import {useMemo} from "react";

export default function MatchDispatch(actions, deps) {
    const dispatch = useDispatch();
    return useMemo(
        () => {
            if (Array.isArray(actions)) {
                return actions.map((action) => bindActionCreators(action, dispatch));
            }
            return bindActionCreators(actions, dispatch);
        },
        deps ? [dispatch, ...deps] : deps
    );
}
