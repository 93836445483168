import { useEffect, useCallback, useState } from 'react';
import moment from 'moment';

import SearchBar from '../../design_system/components/searchbar/SearchBar';
import Dropdown from '../../components/Common/Dropdown';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import FlexMainWrapper, { FormWrapper } from '../../designComponents/wrappers/FlexMainWrapper';
import { Image } from '../../designComponents/imageboxs/Image';
import { SText } from '../../designComponents/text/ContentText';
import { DefaultModal, ImageModal } from '../../designComponents/modals/ModalComponent';
import { InputLine } from '../../designComponents/inputs/InputLine';
import { FlexTwoText } from '../../designComponents/text/TextMapping';

// 탈퇴사유 기타 조회
const CustomerWithdrawReasonModal = () => {
    const [imageToggle, setImageToggle] = useState({});
    const [userDetailToggle, setUserDetailToggle] = useState({});

    const [withdrawElseData, setWithdrawElseData] = useState([]);
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');

    /** 데이터 요청 */
    const getWithdrawElseData = useCallback(async () => {
        let input = {
            searchType: searchType,
            searchWord: searchText,
        };
        const response = await RefreshCheckApi.get('/adminStatus/withdraw/else', { params: input });
        if (response.status === 200) {
            setWithdrawElseData(response.data.data);
        }
    }, [searchText, searchType]);

    useEffect(() => {
        getWithdrawElseData();
    }, [getWithdrawElseData]);

    return (
        <FlexMainWrapper style={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'start' }}>
            {userDetailToggle.toggle && (
                <DefaultModal title={'회원 정보'} boxStyle={{ width: '50vh', height: '31vh' }} onClickClose={() => setUserDetailToggle({})} xIcon={true}>
                    <FlexMainWrapper style={{ padding: '20px', width: '100%' }}>
                        <Image url={userDetailToggle.data.url} width={140} height={140} />
                        <FlexMainWrapper style={{ flexDirection: 'column', width: '100%' }}>
                            <InputLine title={'회원 ID'} value={userDetailToggle.data.user_id} disabled={true} wrapperStyle={{ margin: 0 }} titleStyle={{ textAlign: 'start' }} />
                            <InputLine title={'이름'} value={userDetailToggle.data.user_name} disabled={true} wrapperStyle={{ margin: 0 }} titleStyle={{ textAlign: 'start' }} />
                            <InputLine title={'닉네임'} value={userDetailToggle.data.user_nickname} disabled={true} wrapperStyle={{ margin: 0 }} titleStyle={{ textAlign: 'start' }} />
                            <InputLine title={'전화번호'} value={userDetailToggle.data.user_phone_no} disabled={true} wrapperStyle={{ margin: 0 }} titleStyle={{ textAlign: 'start' }} />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                    <FlexMainWrapper style={{ padding: '10px 20px' }}>
                        <FlexTwoText
                            wrapperStyle={{ alignItems: 'start' }}
                            text01={'탈퇴 사유'}
                            text01Style={{ textAlign: 'start', width: '70px' }}
                            text02={userDetailToggle.data.remark}
                            text02Style={{ textAlign: 'start', width: '35vh' }}
                        />
                    </FlexMainWrapper>
                </DefaultModal>
            )}
            {imageToggle.toggle && (
                <ImageModal xIcon={true} onClickClose={() => setImageToggle({})}>
                    <Image url={imageToggle.url} alt="회원 이미지" width={500} height={500} style={{ borderRadius: '10px' }} />
                </ImageModal>
            )}
            <FlexMainWrapper style={{ alignItems: 'center', gap: '3px', margin: '10px 20px' }}>
                <Dropdown
                    list={[
                        { id: 1, title: '사유', value: 1 },
                        { id: 2, title: '이름', value: 2 },
                        { id: 3, title: '닉네임', value: 3 },
                        { id: 4, title: '휴대폰', value: 4 },
                    ]}
                    index={0}
                    resetThenSet={(item) => {
                        setSearchType(item.value);
                    }}
                />
                <FormWrapper
                    onSubmit={(e) => {
                        e.preventDefault();
                        getWithdrawElseData();
                    }}
                >
                    <SearchBar
                        type="submit"
                        searchText={searchText}
                        onChangeSearchText={(e) => {
                            setSearchText(e.target.value);
                        }}
                    />
                </FormWrapper>
            </FlexMainWrapper>

            <div style={{ overflow: 'auto', padding: '0 20px' }}>
                {withdrawElseData?.map((data, index) => {
                    return (
                        <FlexMainWrapper
                            key={index}
                            style={{ marginBottom: '15px', alignItems: 'start', cursor: 'pointer' }}
                            onClick={() => {
                                setUserDetailToggle({ toggle: true, data: data });
                            }}
                        >
                            <Image
                                resizing={false}
                                url={data.url}
                                width={50}
                                height={50}
                                style={{ borderRadius: '5px' }}
                                onClick={() => {
                                    setImageToggle({ toggle: true, url: data.url });
                                }}
                            />
                            <FlexMainWrapper style={{ flexDirection: 'column', gap: '3px', alignItems: 'start', justifyContent: 'center' }}>
                                <FlexMainWrapper style={{ alignItems: 'end', gap: '5px' }}>
                                    <SText text={data.user_name} />
                                    <SText text={moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')} color="#797979" style={{ fontSize: '12px' }} />
                                </FlexMainWrapper>
                                <SText
                                    style={{ textAlign: 'start', fontWeight: '500' }}
                                    text={data.remark.split('\n').map((line, i) => (
                                        <div key={i}>{line}</div>
                                    ))}
                                />
                            </FlexMainWrapper>
                        </FlexMainWrapper>
                    );
                })}
            </div>
        </FlexMainWrapper>
    );
};

export default CustomerWithdrawReasonModal;
