import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

function ActivityDetailModalScheduleEntry({ setToggleAddSchedule, scheduleParams, setScheduleParams }) {
    // 매주 일정 여부
    const [everyWeekChecked, setEveryWeekChecked] = useState(false);
    // 일정 협의 여부
    const [discussionScheduleChecked, setDiscussionScheduleChecked] = useState(false);
    // 요일
    const [checked01, setChecked01] = useState(null); // 월
    const [checked02, setChecked02] = useState(null); // 화
    const [checked03, setChecked03] = useState(null); // 수
    const [checked04, setChecked04] = useState(null); // 목
    const [checked05, setChecked05] = useState(null); // 금
    const [checked06, setChecked06] = useState(null); // 토
    const [checked00, setChecked00] = useState(null); // 일
    // 매주일정 ? 요일 선택 : null
    const [choiseWeek, setChoiseWeek] = useState([]);
    // 시작일
    const [startday, setStartday] = useState('0000-00-00');
    // 시작시간
    const [startTimeHour, setStartTimeHour] = useState('00');
    const [startTimeMin, setStartTimeMin] = useState('00');
    // 끝 시간
    const [endTimeHour, setEndTimeHour] = useState('00');
    const [endTimeMin, setEndTimeMin] = useState('00');
    // 모집인원
    const [maxParticipants, setMaxParticipants] = useState(0);

    /********************** useEffect **********************/

    // 매주일정 여부에 따른 최종 객체 변환
    useEffect(() => {
        if (everyWeekChecked) {
            if (!Array.isArray(scheduleParams)) setScheduleParams([]);
        } else if (!everyWeekChecked) {
            return setScheduleParams([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [everyWeekChecked]);

    // 최종 요청 객체 확인
    useEffect(() => {
        setScheduleParams(scheduleParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleParams]);

    /********************** function **********************/

    const onClickAddBtn = () => {
        setToggleAddSchedule((prev) => !prev);
    };

    const onClickCheckedEveryWeeks = () => {
        if (everyWeekChecked) {
            if (window.confirm('체크해제 시 입력된 일정이 초기화됩니다. 초기화 하시겠습니까?')) {
                setEveryWeekChecked(!everyWeekChecked);
            }
        } else {
            setEveryWeekChecked(!everyWeekChecked);
        }
    };

    const onClickDiscussionScheduleChecked = () => {
        setDiscussionScheduleChecked((prev) => !prev);
    };

    // 시작일
    const onChangeStartDay = (e) => {
        setStartday(e.target.value);
    };

    // 시작시간(시간)
    const onChangeHour = (e) => {
        setStartTimeHour(e.target.value);
    };

    // 시작시간(분)
    const onChangeMin = (e) => {
        setStartTimeMin(e.target.value);
    };

    // 끝시간(시간)
    const onChangeEndHour = (e) => {
        setEndTimeHour(e.target.value);
    };

    // 끝시간(분)
    const onChangeEndMin = (e) => {
        setEndTimeMin(e.target.value);
    };

    // 모집인원
    const onChangeMaxParticipants = (e) => {
        setMaxParticipants(e.target.value);
    };

    // 요일선택
    const onClickWeeks = (num) => () => {
        let weekArray = [...choiseWeek];

        if (weekArray.includes(num)) {
            const findIndex = weekArray.indexOf(num);
            weekArray.splice(findIndex, 1);
            weekArray.sort((a, b) => a - b);
            setChoiseWeek(weekArray);

            switch (true) {
                case num === 1: // 월
                    setChecked01(null);
                    break;
                case num === 2: // 화
                    setChecked02(null);
                    break;
                case num === 3: // 수
                    setChecked03(null);
                    break;
                case num === 4: // 목
                    setChecked04(null);
                    break;
                case num === 5: // 금
                    setChecked05(null);
                    break;
                case num === 6: // 토
                    setChecked06(null);
                    break;
                case num === 0: // 일
                    setChecked00(null);
                    break;
                default:
                    setChecked01(null);
                    setChecked02(null);
                    setChecked03(null);
                    setChecked04(null);
                    setChecked05(null);
                    setChecked06(null);
                    setChecked00(null);
            }
        } else {
            weekArray.push(num);
            weekArray.sort((a, b) => a - b);
            setChoiseWeek(weekArray);

            switch (true) {
                case num === 1: // 월
                    setChecked01(1);
                    break;
                case num === 2: // 화
                    setChecked02(2);
                    break;
                case num === 3: // 수
                    setChecked03(3);
                    break;
                case num === 4: // 목
                    setChecked04(4);
                    break;
                case num === 5: // 금
                    setChecked05(5);
                    break;
                case num === 6: // 토
                    setChecked06(6);
                    break;
                case num === 0: // 일
                    setChecked00(0);
                    break;
                default:
                    setChecked01(null);
                    setChecked02(null);
                    setChecked03(null);
                    setChecked04(null);
                    setChecked05(null);
                    setChecked06(null);
                    setChecked00(null);
            }
        }
    };

    // 일정 추가
    /***********************************************************************
     * routine_yn false면 date_time 일정 한개 생성(must) / day_value 있든 말든
     * routine_yn true면은 day_value 존재 (both day_value(must) and date_time)
     * max_participants // max_people 제한 인원 수
     ***********************************************************************/
    const addSchedule = () => {
        if (everyWeekChecked) {
            if (!Array.isArray(scheduleParams)) setScheduleParams([]);
            let paramsAraay = [...scheduleParams];

            if (!startTimeHour || !startTimeMin || !endTimeHour || !endTimeMin || !startday || !maxParticipants) {
                alert('일정에 빈 값이 있습니다.');
            } else {
                paramsAraay.push({
                    routine_yn: everyWeekChecked,
                    day_value: choiseWeek,
                    start_time: `${startTimeHour}:${startTimeMin}`,
                    end_time: `${endTimeHour}:${endTimeMin}`,
                    date_time: startday,
                    max_participants: maxParticipants,
                });
                setScheduleParams(paramsAraay);
            }
        } else if (!everyWeekChecked) {
            if (!startTimeHour || !startTimeMin || !endTimeHour || !endTimeMin || !startday || !maxParticipants) {
                alert('일정에 빈 값이 있습니다.');
            } else {
                setScheduleParams({
                    routine_yn: everyWeekChecked,
                    day_value: choiseWeek,
                    start_time: `${startTimeHour}:${startTimeMin}`,
                    end_time: `${endTimeHour}:${endTimeMin}`,
                    date_time: startday,
                    max_participants: maxParticipants,
                });
            }
        }
    };

    // 일정 삭제
    const deleteParamList = (index) => () => {
        console.debug('index: ', index);
    };

    /********************** html **********************/
    return (
        <ModalWrapper>
            <ModalBox>
                <CloseBtn onClick={onClickAddBtn} style={{ cursor: 'pointer' }}>
                    <CloseOutlined />
                </CloseBtn>

                <ContentWrapper>
                    <BoxTitle>일정 추가</BoxTitle>
                    <InputWrapper>
                        <InputBox>
                            <SubTitle>시작일</SubTitle>

                            <InputBox02>
                                <Input type="date" style={{ color: 'rgb(107 106 106)', width: '186px' }} onChange={onChangeStartDay} />
                            </InputBox02>

                            <InputBox02>
                                <Checkbox type="checkbox" checked={everyWeekChecked} onClick={onClickCheckedEveryWeeks} />
                                <SubTitle>매주 일정</SubTitle>
                            </InputBox02>

                            {everyWeekChecked && (
                                <InputBox03>
                                    <DayBtn className={checked01 ? 'day-btn' : ''} onClick={onClickWeeks(1)}>
                                        월
                                    </DayBtn>
                                    <DayBtn className={checked02 ? 'day-btn' : ''} onClick={onClickWeeks(2)}>
                                        화
                                    </DayBtn>
                                    <DayBtn className={checked03 ? 'day-btn' : ''} onClick={onClickWeeks(3)}>
                                        수
                                    </DayBtn>
                                    <DayBtn className={checked04 ? 'day-btn' : ''} onClick={onClickWeeks(4)}>
                                        목
                                    </DayBtn>
                                    <DayBtn className={checked05 ? 'day-btn' : ''} onClick={onClickWeeks(5)}>
                                        금
                                    </DayBtn>
                                    <DayBtn className={checked06 ? 'day-btn' : ''} onClick={onClickWeeks(6)}>
                                        토
                                    </DayBtn>
                                    <DayBtn className={checked00 === 0 ? 'day-btn' : ''} onClick={onClickWeeks(0)}>
                                        일
                                    </DayBtn>
                                </InputBox03>
                            )}
                        </InputBox>

                        <InputBox>
                            <SubTitle>시간(24hr)</SubTitle>
                            <InputBox02>
                                {/* <Input placeholder="10:00" /> ~ <Input placeholder="22:00" /> */}
                                <Select onChange={onChangeHour}>
                                    {Array(25)
                                        .fill(1)
                                        .map((_, index) => {
                                            return <option>{String(index).length > 1 ? String(index) : '0' + String(index)}</option>;
                                        })}
                                </Select>
                                <span>:</span>
                                <Select onChange={onChangeMin}>
                                    {Array(60)
                                        .fill(1)
                                        .map((_, index) => {
                                            return <option>{String(index).length > 1 ? String(index) : '0' + String(index)}</option>;
                                        })}
                                </Select>
                                <span>~</span>
                                <Select onChange={onChangeEndHour}>
                                    {Array(24)
                                        .fill(1)
                                        .map((_, index) => {
                                            return <option>{String(index).length > 1 ? String(index) : '0' + String(index)}</option>;
                                        })}
                                </Select>
                                <span>:</span>
                                <Select onChange={onChangeEndMin}>
                                    {Array(60)
                                        .fill(1)
                                        .map((_, index) => {
                                            return <option>{String(index).length > 1 ? String(index) : '0' + String(index)}</option>;
                                        })}
                                </Select>
                            </InputBox02>
                            <InputBox02>
                                <Checkbox type="checkbox" checked={discussionScheduleChecked} onClick={onClickDiscussionScheduleChecked} />
                                <SubTitle>일정 협의 필요</SubTitle>
                            </InputBox02>
                        </InputBox>

                        <InputBox>
                            <SubTitle>모집인원</SubTitle>
                            <InputBox02>
                                <Input type="number" placeholder="직접입력" onChange={onChangeMaxParticipants} />
                            </InputBox02>
                            <InputBox02>
                                <Checkbox type="checkbox" />
                                <SubTitle>일정 협의 필요</SubTitle>
                            </InputBox02>
                        </InputBox>
                    </InputWrapper>

                    <TableWrapper style={{ height: '160px', overflow: 'auto', border: '1px solid #d6d6d6' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <SubTitle style={{ fontWeight: 'bold' }}>시작일</SubTitle>
                                    </th>
                                    <th>
                                        <SubTitle style={{ fontWeight: 'bold' }}>시간(24hr)</SubTitle>
                                    </th>
                                    <th>
                                        <SubTitle style={{ fontWeight: 'bold' }}>모집인원</SubTitle>
                                    </th>
                                    <th>
                                        <SubTitle>삭제</SubTitle>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {Array.isArray(scheduleParams) ? (
                                    scheduleParams.map((list, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{list.date_time}</td>
                                                <td>
                                                    <span>{`${list.start_time}`}</span>~<span>{`${list.end_time}`}</span>
                                                </td>
                                                <td>
                                                    <span>{list.max_participants}</span>명
                                                </td>
                                                <td>
                                                    <DeleteButton onClick={deleteParamList(index)}>삭제</DeleteButton>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td>{scheduleParams.date_time}</td>
                                        <td>
                                            <span>{`${scheduleParams.start_time}`}</span>~<span>{`${scheduleParams.end_time}`}</span>
                                        </td>
                                        <td>
                                            <span>{scheduleParams.max_participants}</span>명
                                        </td>
                                        <td>
                                            <DeleteButton onClick={deleteParamList()}>삭제</DeleteButton>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </TableWrapper>
                </ContentWrapper>

                <EndButtonWrapper>
                    <AddButton onClick={addSchedule}>추가</AddButton>
                    <CloseButton onClick={onClickAddBtn}>닫기</CloseButton>
                </EndButtonWrapper>
            </ModalBox>
        </ModalWrapper>
    );
}

export default ActivityDetailModalScheduleEntry;

/********************** styled **********************/

const Select = styled.select`
    width: 50px;
    height: 22px;

    outline: none;
    border-color: rgba(66, 139, 247, 0.2);
`;

const TableWrapper = styled.div`
    margin: auto;
    width: 90%;

    table {
        border-collapse: collapse;
        width: 100%;
    }

    tbody > tr:hover {
        background-color: #e3e3e3;
    }

    th,
    td {
        text-align: start;
        width: 100px;
    }

    table > tbody > tr > td {
        font-size: 13px;
        padding: 1px 0;
    }
`;

const DeleteButton = styled.button`
    cursor: pointer;
    width: 40px;
    height: 22px;

    font-size: 13px;

    border: 0;
    border-radius: 2px;

    background-color: #ff9696;
    color: #000;

    :hover {
        background-color: red;
    }
`;

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    background-color: #0000008a;
    z-index: 999;
`;

const ModalBox = styled.div`
    position: fixed;
    top: 33%;
    left: 35%;

    background-color: #fff;
    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 30px 0px;

    width: 600px;
`;

const CloseBtn = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    padding: 25px;
`;

const ContentWrapper = styled.div`
    margin: 40px auto;
`;

const BoxTitle = styled.div`
    text-align: start;

    margin: 0 20px;
    margin-bottom: 30px;

    font-size: 25px;
    font-weight: bold;
    color: #428bf7;
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Input = styled.input`
    outline: none;

    width: 100px;
    height: 22px;

    border: 1px solid #d6d6d6;

    // 달력 이모지 커서 포인트
    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
`;

const Checkbox = styled.input`
    cursor: pointer;
    border: 1px solid blue;
    margin: 0 5px 0 0;
`;

const InputBox = styled.div`
    display: flex;
    flex-direction: column;

    margin: 0 5px;
`;

const InputBox02 = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 5px;
`;

const InputBox03 = styled.div`
    display: flex;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 3px;

    .day-btn {
        background-color: #428bf7;
        color: #fff;
    }
`;

const DayBtn = styled.button`
    cursor: pointer;
    width: 25px;
    height: 25px;

    font-size: 13px;

    border: 0;
    margin: 0 1px;

    :hover {
        background-color: #428bf7;
    }
`;

const SubTitle = styled.div`
    font-size: 13px;
`;

const EndButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;

    margin-bottom: 30px;
`;

const AddButton = styled.button`
    cursor: pointer;
    width: 90px;
    height: 27px;

    border: 0;

    background-color: #428bf7;
    color: #fff;
`;

const CloseButton = styled.button`
    cursor: pointer;
    width: 90px;
    height: 27px;

    border: 1px solid #d6d6d6;

    background-color: #fff;
    color: black;
`;
