export const DATE = "DATE";
export const NAME = "NAME";
export const SEX_STATE = "SEX_STATE";
export const STRING = "STRING";
export const AGREE = "AGREE";
export const CAMERA_AUTH_STATE = "CAMERA_AUTH_STATE";
export const USE_REJECT_STATE = "USE_REJECT_STATE";
export const MANAGER_MEMO = "MANAGER_MEMO";
export const CHECK_BOX = "CHECK_BOX";
export const HEADER_TITLE = "HEADER_TITLE";
export const HEADER_CHECK_BOX = "HEADER_CHECK_BOX";
export const PAYMENT_STATE = "PAYMENT_STATE";
export const SUBSCRIBE_STATE = "SUBSCRIBE_STATE";
export const PRICE = "PRICE";
export const NUMBER = "NUMBER";
export const ON_OFF_STATE = "ON_OFF_STATE";
export const RATE = "RATE";
export const AGE = "AGE";
export const COUPON_STATE = "COUPON_STATE";
export const VIEW_STATE = "VIEW_STATE";
export const EVENT_IMAGE = "EVENT_IMAGE";
export const PUSH_STATE = "PUSH_STATE";
export const REVIEW = "REVIEW";
