export const headers = {
    cameraAuth: {
        header: {
            title: '카메라 인증',
            sex: '성별',
            percentage: '비율',
            total: 'TOTAL',
            man: '남',
            girl: '여',
        },
    },
    married: {
        header: {
            title: '결혼상태',
            sex: '성별',
            percentage: '비율',
            total: 'TOTAL',
            man: '남',
            girl: '여',
        },
    },
    interest: {
        header: {
            title: '관심사',
            sex: '성별',
            percentage: '비율',
            total: 'TOTAL',
            man: '남',
            girl: '여',
        },
    },
    zone: {
        header: {
            title: '지역',
            sex: '성별',
            percentage: '비율',
            total: 'TOTAL',
            man: '남',
            girl: '여',
        },
    },
    ageGroup: {
        header: {
            title: '연령대',
            sex: '성별',
            percentage: '비율',
            total: 'TOTAL',
            man: '남',
            girl: '여',
        },
    },
    withdraw: {
        header: {
            title: '탈퇴사유',
            sex: '성별',
            percentage: '비율',
            total: 'TOTAL',
            man: '남',
            girl: '여',
            null_value: '성별 입력 누락 회원',
        },
    },
};

export const mockData = {
    // 결혼상태
    married: [
        {
            sub_title: 'TOTAL',
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '비혼(미혼)',
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '사별',
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '이혼',
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '졸혼(별거 등)',
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
    ],
    // 관심사
    interest: [
        {
            sub_title: 'total',
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '여행', // trip
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '낚시', // fishing
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '영화', // movie
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '사진', // picture
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '미술', // painting
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '뷰티', // beauty
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '독서', // book
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '음악', // music
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '술', // drink
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '노래', // sing
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '결혼', // marriage
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
    ],
    // 지역
    zone: [
        {
            sub_title: 'total',
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '서울', // seoul
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '경기도', // kyeongkido
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '인천광역시', // incheon
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '부산', // busan
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '울산', // ulsan
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '광주', // guangju
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
        {
            sub_title: '전라도', // jeonrado
            total: '35',
            sex_man: '14',
            sex_girl: '21',
            per_man: '40%',
            per_girl: '60%',
        },
    ],
};
