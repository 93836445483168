import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { main_server_ip, main_server_image_ip } from '../../communication_system/communication_ips';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

function CKEditorComponent({ addProductParam, setAddProductParam, onHtmlChange, onImageHtmlChange, init }) {
    const [flag, setFlag] = useState(false);
    const imgLink = `${main_server_image_ip}`;

    const customUploadAdapter = (loader) => {
        return {
            upload() {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file.then(async (file) => {
                        data.append('image', file);
                        const res = RefreshCheckApi.post(main_server_ip + '/adminProduct/productImage', data);
                        res.then((res) => {
                            if (!flag) {
                                if (onImageHtmlChange) {
                                    onImageHtmlChange(res.data[0]);
                                } else {
                                    setFlag(true);
                                    let obj = JSON.parse(JSON.stringify(addProductParam));
                                    let copyObj = { ...obj };

                                    copyObj.body.text_info_image = res.data[0];
                                    setAddProductParam(copyObj);
                                }
                            }
                            setTimeout(() => {
                                resolve({ default: `${imgLink}${res.data[0]}` });
                            }, 1000);
                        }).catch((err) => reject(err));
                    });
                });
            },
        };
    };

    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        };
    }

    return (
        <CKEditorWrapper className="App">
            <CKEditor
                className="ekEditor-style"
                editor={ClassicEditor}
                config={{
                    // (4)
                    extraPlugins: [uploadPlugin],
                }}
                data={init ?? '<p></p>'}
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                    const data = editor.getData();

                    if (onHtmlChange) {
                        onHtmlChange(data);
                        return;
                    }
                    let obj = JSON.parse(JSON.stringify(addProductParam));
                    let copyObj = { ...obj };
                    copyObj.body.text_info = `${data}`;
                    setAddProductParam(copyObj);
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
            />
        </CKEditorWrapper>
    );
}

export default CKEditorComponent;

const CKEditorWrapper = styled.div`
    width: 100%;
    .ck-editor__editable {
        min-height: 450px;
    }
`;
