import RefreshCheckApi from '../communication_system/axios_apis/refresh_check/refresh_check_api';
import FlexMainWrapper, { Wrapper02 } from '../designComponents/wrappers/FlexMainWrapper';
import ContentDetailModal from '../designComponents/modals/ContentDetailModal';
import MainPageTitle from '../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../designComponents/searchbars/SearchbarWithDropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableFilter from '../designComponents/filters/TableFilter';
import MainTable from '../designComponents/tables/MainTable';
import { TopSideButton } from '../designComponents/buttons/TopSideButton';
import { MainButton } from '../designComponents/buttons/Buttons';
import { contentsListsTableFilter } from '../components/MockData/ContentsListsTableFilter';
import { checkReadFilter, contentFilter, contentTypeFilter, dateFilter } from '../utils/textFilter';
import { Image } from '../designComponents/imageboxs/Image';
import { MText } from '../designComponents/text/ContentText';
import DateTimeSelector from '../common/DateTimeSelector';

const TourHistory = () => {
    const [searchText, setSearchText] = useState('');
    const ref = useRef();
    const [param, setParam] = useState({});
    const [totalInfo, setTotalInfo] = useState({});
    const callApi = async (page) => {
        const p = {
            ...param,
            page,
        };
        if (searchText.length > 0) {
            param.search = searchText;
        }
        const result = await RefreshCheckApi.get('/v2/tour', { params: p });

        return result.data.data;
    };
    const callCountApi = async (page) => {
        const p = {
            ...param,
            page,
        };
        if (searchText.length > 0) {
            param.search = searchText;
        }
        const result = await RefreshCheckApi.get('/v2/tour', { params: p });
        return result.data.all_cnt;
    };
    const getTotal = async () => {
        const result = await RefreshCheckApi.get('/v2/tour/total');
        setTotalInfo(result.data?.data ?? {});
    };
    useEffect(() => {
        getTotal();
    }, []);
    return (
        <Wrapper02>
            <MainPageTitle text={'여행 데이터'} />
            <div>
                <div style={{ display: 'flex' }}>
                    <MText text={'App'} style={{ flex: 1 }} />
                    <MText text={totalInfo?.app} style={{ flex: 4 }} />
                </div>
                <div style={{ display: 'flex' }}>
                    <MText text={'Web'} style={{ flex: 1 }} />
                    <MText text={totalInfo?.web} style={{ flex: 4 }} />
                </div>
                <div style={{ display: 'flex' }}>
                    <MText text={'Total'} style={{ flex: 1 }} />
                    <MText text={totalInfo?.total} style={{ flex: 4 }} />
                </div>
            </div>
            <SearchbarWithDropdown
                style={{ margin: '45px 0 5px 0' }}
                list={[{ id: 1, title: '휴대폰 번호', value: 1 }]}
                resetThenSet={useCallback((item) => {}, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
            />
            <TableFilter
                param={param}
                setParam={setParam}
                topFilterLists={[
                    {
                        filterText: '플랫폼',
                        orderLists: [
                            { name: '웹', width: 50, type: 0 },
                            { name: '시놀', width: 50, type: 1 },
                            { name: '시럽', width: 50, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.platform = type;
                            else delete obj.platform;
                            setParam(obj);
                        },
                        orderbyType: param.platform,
                    },
                ]}
                research={ref}
            />
            <DateTimeSelector
                showDateType={false}
                onDateChange={(_, startDate, endDate) => {
                    setParam({
                        ...param,
                        startDate: startDate,
                        endDate: endDate,
                    });
                }}
            />
            <MainTable
                ref={ref}
                loadingText={'리스트를 불러오고 있습니다.'}
                headerData={[
                    {
                        key: 'created_at',
                        value: '방문일',
                    },
                    {
                        key: 'user_id',
                        value: '사용자 ID',
                    },
                    {
                        key: 'phone_no',
                        value: '전화번호',
                    },
                    {
                        key: 'platform',
                        value: '플렛폼',
                    },
                ]}
                param={param}
                setParam={setParam}
                toggleBlur={true}
                callApi={callApi}
                pageCountCallApi={callCountApi}
                checkAble={false}
                noneDataText={'데이터가 존재하지 않습니다.'}
                onClickRowItem={(item) => () => {}}
                tbodyRenderItem={(key, value, data) => {
                    if (key === 'created_at') return dateFilter(value);
                    return value;
                }}
            />
        </Wrapper02>
    );
};

export default TourHistory;
