export const GET_ANNOUNCEMENTS_DETAIL = "admin/GET_ANNOUNCEMENTS_DETAIL";
export const GET_ANNOUNCEMENTS_DETAIL_SUCCESS =
  "admin/GET_ANNOUNCEMENTS_DETAIL_SUCCESS";
export const GET_ANNOUNCEMENTS_DETAIL_FAILURE =
  "admin/GET_ANNOUNCEMENTS_DETAIL_FAILURE";
export const PUT_ANNOUNCEMENTS_DETAIL = "admin/PUT_ANNOUNCEMENTS_DETAIL";
export const PUT_ANNOUNCEMENTS_DETAIL_SUCCESS =
  "admin/PUT_ANNOUNCEMENTS_DETAIL_SUCCESS";
export const PUT_ANNOUNCEMENTS_DETAIL_FAILURE =
  "admin/PUT_ANNOUNCEMENTS_DETAIL_FAILURE";
