import React, { useEffect, useState } from 'react';

// styled-components
import styled from 'styled-components';

// data_system
import { sidebarData } from '../../dataSystems/SidebarData';
import { useNavigate } from 'react-router-dom';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { SText } from '../../designComponents/text/ContentText';

// 사이드 메뉴바
const SideMenubar = ({ trigger, adminInfo }) => {
    const navigate = useNavigate();
    const [sideBar, setSideBar] = useState(sidebarData);
    const [btnText, setBtnText] = useState('회원');

    useEffect(() => {
        if (parseInt(adminInfo.role) === 0) {
            setSideBar(sidebarData.filter((v) => v.mainTitle === '대시보드'));
            setBtnText('통계');
        }
    }, [adminInfo]);

    return (
        <SideWrapper className="side-wrapper" trigger={trigger}>
            {sideBar.map((item, index) => {
                return (
                    !item.blur && (
                        <MenuWrapper key={index}>
                            <MainTitle style={{ marginTop: index === 0 ? '15px' : '' }}>{item.mainTitle}</MainTitle>
                            {item.subTitleList.map((sub, idx) => {
                                return (
                                    !sub.blur && (
                                        <SubTitle
                                            btnIndex={btnText === sub.name}
                                            key={idx}
                                            onClick={() => {
                                                setBtnText(sub.name);
                                                navigate(sub.router_url);
                                            }}
                                        >
                                            <div>{sub.icon}</div>
                                            {<div>{sub.name}</div>}
                                        </SubTitle>
                                    )
                                );
                            })}
                        </MenuWrapper>
                    )
                );
            })}
            <Footer />
        </SideWrapper>
    );
};

export default SideMenubar;

const SideWrapper = styled.div`
    background-color: #f2f4f6;
    border-right: 1px solid #d1d6db;
    height: 94.2vh;
    overflow: auto;
    width: 330px;
    @media (max-width: 912px) {
        width: 100%;
    }
`;

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const MainTitle = styled.div`
    color: rgb(139, 149, 161);
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 16px;
    margin-top: 25px;
`;

const SubTitle = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    text-align: left;
    font-weight: 500;
    font-size: 14px;
    margin: 0 3px;
    padding: 12px 30px;
    color: ${(props) => (props.btnIndex ? 'rgb(5 101 242)' : '#4e5968')};
    border-radius: ${(props) => (props.btnIndex ? '10px' : '')};
    background-color: ${(props) => (props.btnIndex ? '#e8e8e8' : '')};
    &:hover {
        border-radius: 10px;
        background-color: #e8e8e8;
    }
`;

const Footer = () => (
    <FooterText>
        <div>시놀(주)</div>
        <div>사업자번호 584-88-03032</div>
        <div>법인등록번호 124411-0358061</div>
        <div>우리은행 1005-304-471988</div>
        <div>고객센터 02-508-5670</div>
        <div>카카오톡 @시놀</div>
    </FooterText>
);

const FooterText = styled.div`
    font-size: 13px;
    color: rgb(205 205 205);
    font-family: noto_sans_kr_bold;
    padding: 25px 22px 22px 22px;
`;
