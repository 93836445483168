import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";


function ToggleSwitch({label ='', initChecked, disabled=false, onChange, id, onClick}) {
	const [checked,setChecked] = useState(initChecked||false);
	useEffect(()=>{
		setChecked(initChecked);
	},[initChecked])

	return (
		<Form>
			<Form.Check // prettier-ignore
				id={id}
				disabled = {disabled}
				checked={checked}
				type="switch"
				label={label}
				onClick={onClick}
				onChange={(e)=>{
					onChange(e);
					setChecked(!checked);
				}}
				style={{color: "red"}}
			/>
		</Form>
	);
}

export default ToggleSwitch;