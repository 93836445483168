import {getUsers, getUsersSearch,} from "../../../../../communication_system/axios_apis/axios_apis";
import createGetRequestThunk from "../../redux_functions/create_get_request_thunk";
import {GET_USERS} from "./users_table_type";

export const getUsersAsync = createGetRequestThunk(GET_USERS, getUsers);

export const getUsersSearchAsync = createGetRequestThunk(
  GET_USERS,
  getUsersSearch
);
