import React from 'react';
import styled from 'styled-components';

import { SearchWhite1x } from '../../design_system/icons/icons';
import FlexMainWrapper from '../wrappers/FlexMainWrapper';

const listType = [{ id: 1, title: '휴대폰번호', value: 1 }];

const CSSearchbarWithDropdown = React.memo(
    ({ style, disabled = false, list = listType, resetThenSet = () => {}, onChangeSubmit = () => {}, searchText, onChangeInput = () => {}, selectStyle, inpuWrapperStyle }) => {
        return (
            <SearchWrapper type="submit" onSubmit={onChangeSubmit} style={{ ...style }}>
                <Select onChange={(e) => resetThenSet(e.target)} style={{ flex: 1 }}>
                    {list.map((item, index) => {
                        return (
                            <option key={index} value={item.value}>
                                {item.title}
                            </option>
                        );
                    })}
                </Select>

                <FlexMainWrapper style={{ gap: 0, marginLeft: '2px', justifyContent: 'stretch', ...inpuWrapperStyle, flex: 3, alignItems: 'center' }}>
                    <Input type="text" value={searchText} onChange={onChangeInput} disabled={disabled} style={{ flex: 1 }} />
                    <IconBtn style={{ width: 28, height: 28 }}>
                        <SearchWhite1x />
                    </IconBtn>
                </FlexMainWrapper>
            </SearchWrapper>
        );
    },
);

export default CSSearchbarWithDropdown;

const SearchWrapper = styled.form`
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 100%;
`;

const Input = styled.input`
    outline: none;
    width: 100%; // 120px;
    padding: 4px 5px;
    margin: 0;
    border: 1px solid rgba(66, 139, 247, 0.2);
`;

const IconBtn = styled.button`
    cursor: pointer;

    background-color: rgb(98 98 98); // rgb(66, 139, 247);
    border-width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 13px;
        height: 13px;
    }

    :hover {
        background-color: #000; // rgb(5 101 242);
    }
    :active {
        background-color: rgb(98 98 98); // rgb(66, 139, 247);
    }
`;

const Select = styled.select`
    cursor: pointer;
    outline: none;
    width: 30%;
    padding: 3px 0;
    border: 1px solid rgba(66, 139, 247, 0.2);
`;
