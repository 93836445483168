import produce from 'immer';
import { handleActions } from 'redux-actions';
import { MANAGER_MEMO, NAME, ON_OFF_STATE, PRICE, RATE, STRING } from '../../../data_type/data_type';
import {
    GET_HOSTS_ALL_SUCCESS,
    GET_HOSTS_DETAIL_FAILURE,
    GET_HOSTS_DETAIL_SUCCESS,
    PUT_HOSTS_DETAIL_ADMIN_MEMO_FAILURE,
    PUT_HOSTS_DETAIL_ADMIN_MEMO_SUCCESS,
    PUT_HOSTS_DETAIL_FAILURE,
    PUT_HOSTS_DETAIL_SUCCESS,
} from './hosts_detail/hosts_detail_type';
import { GET_HOSTS_FAILURE, GET_HOSTS_SUCCESS, POST_HOSTS_FAILURE, POST_HOSTS_SUCCESS } from './hosts_table/hosts_table_type';

const initialState = {
    hosts: null,
    hostsDetail: null,
    allHost: [],
};

const hostsReducer = handleActions(
    {
        [GET_HOSTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.hosts = action.payload;
            }),
        [GET_HOSTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_HOSTS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.hostsDetail = action.payload;
            }),
        [GET_HOSTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [POST_HOSTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.hosts.body = [
                    {
                        host_id: action.payload['host_id'],
                        exposure_yn: action.payload.exposure_yn,
                        host_name: action.payload.host_name,
                        register_activity_count: 0,
                        prev_pay_count: 0,
                        current_pay_count: 0,
                        prev_pay_amount: '0',
                        current_pay_amount: '0',
                        charge: '없음',
                        prev_calculate_amount: 0,
                    },
                    ...draft.hosts.body,
                ];
            }),
        [POST_HOSTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [PUT_HOSTS_DETAIL_ADMIN_MEMO_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.hosts.length; i++) {
                    if (draft.hosts[i]['id'] === action.payload['host_id']) {
                        draft.hosts[i]['data'][9]['data'] = action.payload['admin_memo'];
                    }
                }
            }),
        [PUT_HOSTS_DETAIL_ADMIN_MEMO_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [PUT_HOSTS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                for (let i = 0; i < draft.hosts.body.length; i++) {
                    if (Number(draft.hosts.body[i]['host_id']) === Number(action.payload['host_id'])) {
                        draft.hosts.body[i]['exposure_yn'] = action.payload['exposure_yn'];
                        draft.hosts.body[i]['host_name'] = action.payload['host_name'];
                        break;
                    }
                }
            }),
        [PUT_HOSTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_HOSTS_ALL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                const tmp = [];
                action.payload.map((item, index) => {
                    tmp.push({
                        id: item.id,
                        title: item.host_name,
                        value: item.id,
                    });
                });
                draft.allHost = tmp;
            }),
    },
    initialState,
);

export default hostsReducer;
