import React, { useCallback, useRef, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainTable from '../../designComponents/tables/MainTable';
import { availFilter, dateFilter, sexFilter } from '../../utils/textFilter';
import { gatheringChatFilter } from '../../components/ChattingComponents/ChattingTableFilter';
import { ChatModalComponent } from '../../designComponents/modals/ModalComponent';
import ChatModalHeader from '../../components/ChattingComponents/ChatModalHeader';
import ChatDetail from '../../components/ChattingComponents/ChatDetail';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import TableFilter from '../../designComponents/filters/TableFilter';
import ToggleSwitch from '../../common/BootStrap/ToggleSwitch';
import { getChattingListRequest, patchChattingDisabledRequest } from '../../api/main/chatting/ChattingRequest';

/** 모임채팅 페이지 */
const GatheringChat = () => {
    const [param, setParam] = useState({ order: -1 });
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [usersInfo, setUsersInfo] = useState([]);
    const [usersProfile, setUsersProfile] = useState([]);
    const [gatheringInfo, setGatheringInfo] = useState({});

    const [chatParams, setChatParams] = useState({ toggle: false });
    const [chatText, setChatText] = useState('');

    const ref = useRef(null);
    const tableRef = useRef(null);

    const callApi02 = async (page, param) => {
        try {
            /** 1: 단짝, 2: 모임, 3: 모임단체 */
            const input = {
                searchType: searchType,
                searchWord: searchText,
                type: 2,
                page: page,
                size: 10,
                ...param,
            };
            const response = await getChattingListRequest(input);
            setUsersInfo(response.usersInfo);
            setUsersProfile(response.usersProfile);

            const gatheringIdLists = [];
            await response.data.forEach((item) => {
                gatheringIdLists.push(item.info.id);
            });

            const gatheringInput = { gathering_id: gatheringIdLists, type: 1 };
            const gatheringDetail = await RefreshCheckApi.post(`/adminChatting/list`, input);
            setGatheringInfo(gatheringDetail.data.findDetailData);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const onChangeDisabledChatting = async (e) => {
        const checkedYn = e.target.checked;
        const chattingId = e.target.id;

        if (e.target.checked) {
            e.target.label = '활성화';
        } else {
            e.target.label = '비 활성화';
        }

        if (!chattingId) {
            return alert('채팅창 ID가 없습니다,  관리자에게 문의해주세요 ');
        }
        try {
            await patchChattingDisabledRequest({
                chatting_id: chattingId,
                disable_yn: !checkedYn,
            });
        } catch (err) {
            console.error(err);
            if (err.response.status_code === 500) {
                alert('네트 워크 오류, 관리자에게 문의해주세요');
            }
        }
    };

    const pageCountCallApi02 = async (page, param) => {
        try {
            /** 1: 단짝, 2: 모임, 3: 모임단체 */
            const input = {
                searchType: searchType,
                searchWord: searchText,
                type: 2,
                page: page,
                size: 10,
                ...param,
            };
            const response = await getChattingListRequest(input);
            return response.allCount;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Wrapper02>
            <MainPageTitle text={'모임 대화방'} onClick={() => ref?.current?.refresh()} />
            <SearchbarWithDropdown
                list={[
                    { id: 1, title: '휴대폰번호', value: 1 },
                    { id: 2, title: '이름', value: 2 },
                    { id: 3, title: '닉네임', value: 3 },
                    { id: 4, title: '채팅 내용', value: 4 },
                    { id: 5, title: '모임명', value: 5 },
                ]}
                resetThenSet={useCallback((item) => {
                    setSearchType(item.value);
                }, [])}
                onChangeSubmit={(e) => {
                    e.preventDefault();
                    ref?.current?.refresh();
                }}
                searchText={searchText}
                onChangeInput={(e) => {
                    setSearchText(e.target.value);
                }}
                setSearchText={setSearchText}
            />
            <TableFilter
                topFilterLists={[
                    {
                        filterText: '정렬 조건',
                        orderLists: [
                            { name: '대화 최신 순', width: 70, type: -1 },
                            { name: '대화 나중 순', width: 70, type: 4 },
                            { name: '대화 종료 순', width: 70, type: 1 },
                            { name: '대화 높은 순', width: 70, type: 2 },
                            { name: '대화 적은 순', width: 70, type: 3 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.order = type;
                            else delete obj.order;
                            setParam(obj);
                        },
                        orderbyType: param.order,
                    },
                ]}
            />
            <FlexMainWrapper>
                <MainTable
                    buttonComponents={({ checkItems }) => {}}
                    toggleBlur={true}
                    ref={ref}
                    tableStyle={{ width: chatParams.toggle ? '70%' : '98%' }}
                    tableRef={tableRef}
                    loadingText={'채팅 내역을 불러오고 있습니다.'}
                    headerData={gatheringChatFilter}
                    checkID={'id'}
                    param={param}
                    setParam={setParam}
                    callApi={callApi02}
                    pageCountCallApi={pageCountCallApi02}
                    onClickRowItem={(item) => {
                        return async () => {
                            try {
                                const response = await RefreshCheckApi.get(`/adminChatting/chat/${item.id}`);
                                if (response.status === 200) {
                                    const [user] = usersInfo?.filter((v) => v.id === response.data.data.info.to);
                                    const [fromUser] = usersInfo?.filter((v) => v.id === response.data.data.info.from);
                                    setChatParams({ toggle: true, from: fromUser, to: user, data: response.data.data });
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        };
                    }}
                    tbodyRenderItem={(key, value, data) => {
                        const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;
                        if (key === 'created_at') return data.first_contents ? dateFilter(data.first_contents) : nullTag;
                        if (key === 'disable_button')
                            return <ToggleSwitch initChecked={!data.info.disabled} onChange={onChangeDisabledChatting} id={data.id} label={!data.info.disabled ? '활성화' : '비활성화'} />;

                        if (key === 'avail_yn') return availFilter(value);
                        if (key === 'user_sex' || key === 'target_user_sex') return sexFilter(value);
                        if (!key)
                            return (
                                <FlexMainWrapper style={{ alignItems: 'center' }}>
                                    <ArrowRightOutlined />
                                </FlexMainWrapper>
                            );
                        if (key === 'gathering_name') return data?.gathering_name;
                        if (key === 'from') {
                            const [user] = usersInfo?.filter((v) => Number(v.id) === Number(value)); // fromUser
                            return (
                                <span>
                                    {user?.name ? user?.name + '/' : nullTag}
                                    {user?.nick_name ? user?.nick_name : null}
                                </span>
                            );
                        }
                        if (key === 'to') {
                            const [user] = usersInfo?.filter((v) => Number(v.id) === Number(value));
                            return (
                                <span>
                                    {user?.name ? user?.name + '/' : nullTag}
                                    {user?.nick_name ? user?.nick_name : null}
                                </span>
                            );
                        }
                        return value;
                    }}
                />
                {chatParams.toggle && (
                    <ChatModalComponent
                        toggle={chatParams.toggle}
                        closeModal={() => setChatParams({ toggle: false })}
                        header={<ChatModalHeader chatParams={chatParams} usersInfo={usersInfo} usersProfile={usersProfile} type={2} />}
                        body={<ChatDetail data={chatParams.data} userInfo={usersInfo} usersProfile={usersProfile} setChatParams={setChatParams} />}
                        onSubmit={async (e) => {
                            e.preventDefault();
                            setChatText('');
                        }}
                        inputValue={chatText}
                        onChange={(e) => setChatText(e.target.value)}
                    />
                )}
            </FlexMainWrapper>
        </Wrapper02>
    );
};

export default GatheringChat;
