import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {h_px4rate, h_px64rate, w_px20rate,} from "../../../../../size";
import {rgb_255_255_255, rgb_66_139_247} from "../../../../../colors";
import {ArrowBackwardWhite1x} from "../../../../../icons/icons";
import TotalDetailComponents from "../../detail_components/total_detail_component/TotalDetailComponents";
import TotalDetailRegister from "../../detail_registers/total_detail_register/TotalDetailRegister";
import {
    ACTIVITY_DETAIL_REGISTER,
    ADMINISTRATOR_DETAIL_REGISTER,
    ANNOUNCEMENT_DETAIL_REGISTER, COUPON_DETAIL_HISTORY,
    COUPON_DETAIL_REGISTER,
    EVENT_DETAIL_REGISTER,
    HOST_DETAIL_REGISTER,
    POINT_DETAIL_REGISTER,
} from "../../../../../../data_system/register_data_type/register_data_type";
import BodyScroll from "../../../../body_scroll/BodyScroll";

const DetailCommonForm = ({
  setItemOpen,
  choiceRowIndex,
  setChoiceRowIndex,
  registerOpen,
  setRegisterOpen,
  DETAIL_DATA_TYPE,
  DETAIL_DATA_REGISTER,
  limit,
  page,
}) => {
  const [loading, setLoading] = useState(true);

  const [detailTitle, setDetailTitle] = useState("상세정보");

  useEffect(() => {
    setLoading(true);
    if (registerOpen) {
      if (DETAIL_DATA_REGISTER === ADMINISTRATOR_DETAIL_REGISTER) {
        setDetailTitle("관리자정보 등록");
      } else if (DETAIL_DATA_REGISTER === HOST_DETAIL_REGISTER) {
        setDetailTitle("호스트 정보 등록");
      } else if (DETAIL_DATA_REGISTER === ACTIVITY_DETAIL_REGISTER) {
        setDetailTitle("액티비티 등록");
      } else if (DETAIL_DATA_REGISTER === POINT_DETAIL_REGISTER) {
        setDetailTitle("포인트 등록");
      } else if (DETAIL_DATA_REGISTER === COUPON_DETAIL_REGISTER) {
        setDetailTitle("쿠폰 등록");
      } else if (DETAIL_DATA_REGISTER === EVENT_DETAIL_REGISTER) {
        setDetailTitle("이벤트 등록");
      } else if (DETAIL_DATA_REGISTER === ANNOUNCEMENT_DETAIL_REGISTER) {
        setDetailTitle("공지 등록");
      }
    } else {
      setDetailTitle("상세정보");
    }
    setLoading(false);
  }, [registerOpen, DETAIL_DATA_REGISTER]);

  const close = useCallback(() => {
    setItemOpen(false);
    setRegisterOpen(false);
    setChoiceRowIndex(null);
  }, [setItemOpen, setRegisterOpen, setChoiceRowIndex]);

  return (
    !loading && (
      <div>
        <HeaderTitle>
          <HeaderArrowBackwardBtn
            onClick={() => {
              close();
            }}
          >
            <ArrowBackwardWhite1x></ArrowBackwardWhite1x>
          </HeaderArrowBackwardBtn>
          <div
            style={{
              marginBottom: h_px4rate,
            }}
          >
            {detailTitle}
          </div>
        </HeaderTitle>
        <BodyScroll>
          {!registerOpen ? (
            <TotalDetailComponents
              choiceRowIndex={choiceRowIndex}
              DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
              limit={limit}
              page={page}
              close={close}
            ></TotalDetailComponents>
          ) : (
            <TotalDetailRegister
              DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
            ></TotalDetailRegister>
          )}
        </BodyScroll>
      </div>
    )
  );
};

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  height: ${h_px64rate}px;
  font-size: ${w_px20rate}px;
  font-family: noto_sans_kr_bold;
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
`;

export const HeaderArrowBackwardBtn = styled.button`
  border-width: 0px;
  background-color: ${rgb_66_139_247};
`;

export default React.memo(DetailCommonForm);
