import produce from 'immer';
import { handleActions } from 'redux-actions';
import { DATE, NAME, STRING } from '../../../data_type/data_type';
import { GET_POINTS_DETAIL_FAILURE, GET_POINTS_DETAIL_SUCCESS } from './points_detail/points_detail_type';
import { GET_POINTS_FAILURE, GET_POINTS_SUCCESS, POST_POINTS_FAILURE, POST_POINTS_SUCCESS } from './points_table/points_table_type';

const initialState = {
    points: null,
    pointsDetail: null,
};

const pointsReducer = handleActions(
    {
        [GET_POINTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.points = action.payload;
            }),
        [GET_POINTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [GET_POINTS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.pointsDetail = action.payload;
            }),
        [GET_POINTS_DETAIL_FAILURE]: (state, action) => produce(state, (draft) => {}),
        [POST_POINTS_SUCCESS]: (state, action) => produce(state, (draft) => {}),
        [POST_POINTS_FAILURE]: (state, action) => produce(state, (draft) => {}),
    },
    initialState,
);

export default pointsReducer;
