export const letterTableFilter = [
    {
        key: 'created_at',
        value: '작성일',
        filter: [
            {
                key: 1,
                value: '오름차순',
            },
            {
                key: 2,
                value: '내림차순',
            },
        ],
    },
    {
        key: 'content',
        value: '편지내용',
    },
    {
        key: 'user_name',
        value: '보낸사람명',
    },
    {
        key: 'user_nick_name',
        value: '보낸사람 닉네임',
    },
    {
        key: 'user_sex',
        value: '보낸사람 성별',
        filter: [
            {
                key: 1,
                value: '남자',
            },
            {
                key: 2,
                value: '여자',
            },
        ],
    },
    {
        key: null,
        value: null,
    },
    {
        key: 'target_user_name',
        value: '받은사람명',
    },
    {
        key: 'target_user_nick_name',
        value: '받은사람 닉네임',
    },
    {
        key: 'target_user_sex',
        value: '받은사람 성별',
        filter: [
            {
                key: 1,
                value: '남자',
            },
            {
                key: 2,
                value: '여자',
            },
        ],
    },
    {
        key: 'read_yn',
        value: '읽음 여부',
        filter: [
            {
                key: 1,
                value: '읽음',
            },
            {
                key: 0,
                value: '읽지않음',
            },
        ],
    },
    {
        key: 'refund_yn',
        value: '반환 여부',
    },
];
