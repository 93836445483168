import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';
import { LeftOutlined, UpOutlined } from '@ant-design/icons';

// react-slick
import Slider from 'react-slick';

// URL
import { main_server_image_ip } from '../../communication_system/communication_ips';

// custom hook
import useInput from '../../hook/useInput';

// redux
import {
	getProductDetail,
	RESET_STATE,
	updateProductDetail
} from '../../data_system/redux/modules/product_reducer/product.list_reducer';
import { useDispatch, useSelector } from 'react-redux';

// html-parser
// eslint-disable-next-line no-unused-vars
import ReactHtmlParser from 'react-html-parser';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';

/** 상품상세페이지 */
const Detail = () => {
    const { id, host_id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // state-toggle
    const [updateToggle, setUpdateToggle] = useState(false);

    // state
    const [imageNum, setImageNum] = useState(0);
    const [choiseOption, setChoiseOption] = useState('');
    const [choiseOptionId, setChoiseOptionId] = useState('');

    // 상품 상태
    const [productName, onChangeProductName, setProductName] = useInput('');
    const [productPromotionalTitle, onChangeProductPromotionalTitle, setProductPromotionalTitle] = useInput('');
    const [url, onChangeUrl, setUrl] = useInput('');
    const [listPrice, onChangeListPrice, setListPrice] = useInput('');
    const [discountPrice, onChangeDiscountPrice, setDiscountPrice] = useInput('');
    const [inventoryCnt, onChangeInventoryCnt, setInventoryCnt] = useInput('');
    const [availYn, setAvailYn] = useState('');

    // useSelector
    const { productList, updateProductMessage } = useSelector((state) => state.productReducer);

    /************************************************ useEffect ************************************************/

    // 상태적용
    useEffect(() => {
        callSetState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productList, updateToggle]);

    // 상품상세정보 데이터 요청
    useEffect(() => {
        dispatch(getProductDetail(id, host_id));
    }, [dispatch, id, host_id]);

    // 수정 성공
    useEffect(() => {
        if (updateProductMessage?.message) {
            alert(updateProductMessage?.message);

            dispatch({
                type: RESET_STATE,
            });

            dispatch(getProductDetail(id, host_id));
        }
    }, [dispatch, updateProductMessage, host_id, id]);

    /************************************************ filter ************************************************/

    // slidesToShowLimit in react-slick
    const slidesToShowLimit = () => {
        let productListLimit = productList?.productImages.length;
        let num;

        switch (true) {
            case productListLimit <= 1:
                num = 0;
                break;
            case productListLimit === 2:
                num = 2;
                break;
            case productListLimit === 3:
                num = 3;
                break;
            case productListLimit === 4:
                num = 4;
                break;
            default:
                num = 5;
        }
        return num;
    };

    // react-slick-setting
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShowLimit(),
        slidesToScroll: 5,
    };

    /************************************************ function ************************************************/

    // 상태적용 함수
    const callSetState = () => {
        setProductName(productList?.product.name);
        setProductPromotionalTitle(productList?.product.promotional_title);
        setChoiseOption(productList?.options[0]?.name);
        setChoiseOptionId(productList?.options[0]?.id);
        setListPrice(productList?.product.list_price);
        setDiscountPrice(productList?.product.discount_price);
        setInventoryCnt(productList?.options[0]?.inventory_cnt);
        setUrl(productList?.product.refund_policy); // 판매처
        setAvailYn(productList?.product.avail_yn);
    };

    // 뒤로가기
    const onClickBackPage = () => {
        // navigate('/goodsLists');
        navigate(-1);
    };

    // 페이지 최상단으로 올라가기
    const onClickMoveTop = () => {
        window.location.href = '#';
    };

    // 이미지 클릭
    const onClickImage = (num) => () => {
        setImageNum(num);
    };

    // 옵션 선택
    const handleChangeOptionSelect = (e) => {
        setChoiseOption(e.target.value);
    };

    // 상세옵션 선택
    const handleChangeOptionDetailSelect = (e) => {
        setChoiseOptionId(parseInt(e.target.value));
    };

    // 상품 삭제
    const onClickDelete = () => {
        if (window.confirm('상품을 삭제하시겠습니까?')) {
            navigate(-1);
        }
    };

    // 상품 수정 및 수정 취소
    const onClickUpdate = (botton_type) => () => {
        if (!botton_type) {
            if (window.confirm('수정을 취소하게되면 기록된 내용이 사라집니다.')) {
                callSetState();
                setUpdateToggle((prev) => !prev);
            }
        } else {
            setUpdateToggle((prev) => !prev);
        }
    };

    // 상품 저장
    const onClickSave = () => {
        if (window.confirm('수정한 내용을 저장 하시겠습니까?')) {
            let param = {
                product_id: id,
                product_option_id: choiseOptionId,
                product_name: productName,
                promotional_title: productPromotionalTitle,
                list_price: listPrice, // 정상가
                discount_price: discountPrice, // 할인가
                inventory_cnt: inventoryCnt, // 재고
                refund_policy: url, // 판매처
                avail_yn: availYn, // 판매 여부
            };
            dispatch(updateProductDetail(param));
            callSetState();
            setUpdateToggle((prev) => !prev);
        }
    };

    // 판매여부
    const onClickAvailYn = (num) => () => {
        setAvailYn(num);
    };

   return (
        <Wrapper02>
            <MainPageTitle text={'상품 상세조회'} />
            <ItemInfoWrapper>
                <ProductImageBox id="product-images">
                    <Image
                        sizes={300}
                        src={imageNum ? main_server_image_ip + productList?.productImages[imageNum]?.url : main_server_image_ip + productList?.productImages[imageNum]?.url}
                        alt="메인상품이미지"
                    />
                    <Slider {...settings} style={{ width: '270px' }}>
                        {productList?.productImages?.map((data, index) => (
                            <Image key={index} sizes={90} src={main_server_image_ip + data.url} alt="상품이미지" onClick={onClickImage(index)} />
                        ))}
                    </Slider>
                </ProductImageBox>

                <div id="product-explain">
                    <TitleBox className="product-title-line">
                        {updateToggle ? (
                            <InputWrapper className="input-wrapper">
                                <Input width={50} height={22} type="text" placeholder="상품이름을 입력하세요" value={productName} onChange={onChangeProductName} />
                                <Input width={50} height={22} type="text" placeholder="상품 부제목을 입력하세요" value={productPromotionalTitle} onChange={onChangeProductPromotionalTitle} />
                            </InputWrapper>
                        ) : (
                            <div>
                                <MainTitle>{productName}</MainTitle>
                                <SubTitle>{productPromotionalTitle}</SubTitle>
                            </div>
                        )}
                    </TitleBox>

                    {/* 상품가격 */}
                    <PriceLine className="item-price-line">
                        <FlexWrapper>
                            <ExplainTitle>정상가</ExplainTitle>
                            {updateToggle ? (
                                <Input type="number" placeholder="상품의 정상가를 입력하세요" value={listPrice} onChange={onChangeListPrice} />
                            ) : (
                                <>{productList?.product.discount_price ? <del>{listPrice}</del> : <span>{listPrice}</span>}</>
                            )}
                        </FlexWrapper>

                        {productList?.product.discount_price && (
                            <FlexWrapper>
                                <ExplainTitle>할인가</ExplainTitle>
                                {updateToggle ? (
                                    <Input type="number" placeholder="상품의 할인가를 입력하세요" value={discountPrice} onChange={onChangeDiscountPrice} />
                                ) : (
                                    <>
                                        <span>{discountPrice}</span>
                                    </>
                                )}
                            </FlexWrapper>
                        )}
                    </PriceLine>

                    <HostLine className="info-line">
                        <FlexWrapper>
                            <ExplainTitle>호스트</ExplainTitle>
                            {productList?.host.name}
                        </FlexWrapper>
                        <FlexWrapper>
                            <ExplainTitle style={{ marginRight: '10px' }}>판매여부</ExplainTitle>
                            {updateToggle ? (
                                <CheckBoxWrapper>
                                    <CheckBoxWrapper>
                                        <Checkbox type="checkbox" checked={availYn} onClick={onClickAvailYn(1)} />
                                        <span>Y</span>
                                    </CheckBoxWrapper>
                                    <CheckBoxWrapper>
                                        <Checkbox type="checkbox" checked={!availYn} onClick={onClickAvailYn(0)} />
                                        <span>N</span>
                                    </CheckBoxWrapper>
                                </CheckBoxWrapper>
                            ) : (
                                <div>{availYn ? 'Y' : 'N'}</div>
                            )}
                        </FlexWrapper>
                    </HostLine>

                    <div className="option-line">
                        <FlexWrapper>
                            <ExplainTitle>욥션여부</ExplainTitle>
                            {productList?.options.length > 0 ? 'Y' : 'N'}
                        </FlexWrapper>
                    </div>

                    <OptionSelectWrapper className="option-select">
                        <OptionSelect>
                            <ExplainTitle>옵션</ExplainTitle>
                            <div>
                                <Select onChange={handleChangeOptionSelect} value={choiseOption}>
                                    {/* eslint-disable-next-line array-callback-return */}
                                    {productList?.options.map((data, index) => {
                                        if (productList?.options[index - 1]?.name !== data.name) {
                                            return (
                                                <option key={index} value={data.name}>
                                                    {data.name}
                                                </option>
                                            );
                                        }
                                    })}
                                </Select>
                            </div>
                        </OptionSelect>

                        <OptionSelect>
                            <ExplainTitle>상세옵션</ExplainTitle>
                            <div>
                                <Select onChange={handleChangeOptionDetailSelect} value={choiseOptionId}>
                                    {productList?.options.map((data, index) => {
                                        const resultTag = (
                                            <option key={index} value={data.id}>
                                                {data.category}
                                            </option>
                                        );
                                        return data.name === choiseOption && resultTag;
                                    })}
                                </Select>
                            </div>
                        </OptionSelect>

                        <OptionSelect>
                            <ExplainTitle>재고(수량)</ExplainTitle>

                            {updateToggle ? (
                                <Input type="number" placeholder="상품의 재고수량을 입력하세요" value={inventoryCnt} onChange={onChangeInventoryCnt} />
                            ) : (
                                <span>
                                    {productList?.options.map((data, index) => {
                                        const resultTag = <div key={index}>{data.inventory_cnt}</div>;
                                        return data.id === choiseOptionId && resultTag;
                                    })}
                                </span>
                            )}
                        </OptionSelect>

                        <OptionSelect style={{ alignItems: 'start' }}>
                            <ExplainTitle>관리자 메모</ExplainTitle>

                            {updateToggle ? (
                                <Textarea type="text" style={{ height: '7vh' }} value={null} placeholder={'관리자 메모를 입력해주세요'} onChange={null} disabled readOnly />
                            ) : (
                                <Textarea type="text" style={{ height: '7vh' }} value={null} placeholder={'관리자 메모를 입력해주세요'} disabled readOnly />
                            )}
                        </OptionSelect>

                        <OptionSelect style={{ alignItems: 'start' }}>
                            <ExplainTitle>판매처</ExplainTitle>

                            {updateToggle ? (
                                <Textarea type="text" value={url} placeholder="판매처 링크를 입력해주세요" onChange={onChangeUrl} />
                            ) : (
                                <div>
                                    {url === '정보없음' || !url ? (
                                        <span style={{ color: 'gray' }}>등록된 사이트가 없습니다.</span>
                                    ) : (
                                        <a href={url} target="parent" style={{ fontSize: '14px' }}>
                                            판매처 사이트로 이동
                                        </a>
                                    )}
                                </div>
                            )}
                        </OptionSelect>
                    </OptionSelectWrapper>

                    <BtnWrapper className="btn-line">
                        <DeleteBtn onClick={updateToggle ? onClickUpdate(0) : onClickDelete}>{updateToggle ? '수정 취소' : '삭제'}</DeleteBtn>
                        <UpdateBtn onClick={updateToggle ? onClickSave : onClickUpdate(1)}>{updateToggle ? '저장' : '수정'}</UpdateBtn>
                    </BtnWrapper>
                </div>
            </ItemInfoWrapper>

            <ProductExplainLine>
                <ProductInformation>
                    <h2>상품정보</h2>
                </ProductInformation>

                <HTML_PARSER>{ReactHtmlParser(productList?.product.text_info)}</HTML_PARSER>
                {/* <img src={main_server_image_ip + productList?.product.text_info} alt="상품설명 이미지" /> */}
            </ProductExplainLine>

            <BackBtn onClick={onClickBackPage} style={{ cursor: 'pointer' }}>
                <LeftOutlined />
            </BackBtn>

            <MoveTopBtn onClick={onClickMoveTop} style={{ cursor: 'pointer' }}>
                <UpOutlined />
            </MoveTopBtn>
        </Wrapper02>
    );
};

export default Detail;

// eslint-disable-next-line no-unused-vars
const HTML_PARSER = styled.div`
    img {
        width: 100%;
    }

    p {
        padding: 0px 40px;
    }
`;

const CheckBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
`;

const Checkbox = styled.input`
    cursor: pointer;

    width: 15px;
    height: 15px;
`;

const Textarea = styled.textarea`
    outline: none;

    width: 70%;
    height: 15vh;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
`;

const Input = styled.input`
    outline: none;
    border: 1px solid #cccccc;

    padding: 1px 5px;

    width: ${(props) => (props.width ? `${props.width}%` : '27%')};
    height: ${(props) => (props.height ? `${props.height}px` : '21px')};

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

const MoveTopBtn = styled.button`
    position: fixed;
    bottom: 2%;
    right: 2%;

    cursor: pointer;
    background-color: rgb(0 0 0 / 41%);

    border: 1px solid rgb(0 0 0 / 0%);
    border-radius: 50%;

    color: #fff;
    margin-left: 1px;

    width: 50px;
    height: 50px;

    font-size: 30px;
    font-weight: bold;

    :hover {
        background-color: rgb(0 0 0 / 58%);
    }
`;

const BackBtn = styled.button`
    position: fixed;
    bottom: 2%;
    right: 6%;

    cursor: pointer;
    background-color: rgb(0 0 0 / 41%);

    border: 1px solid rgb(0 0 0 / 0%);
    border-radius: 50%;

    color: #fff;
    margin-left: 1px;

    width: 50px;
    height: 50px;

    font-size: 30px;
    font-weight: bold;

    :hover {
        background-color: rgb(0 0 0 / 58%);
    }
`;

const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    width: 100%;
`;

const UpdateBtn = styled.button`
    cursor: pointer;
    background-color: rgb(66, 139, 247);
    border: 1px solid rgb(66, 139, 247);
    color: #fff;

    width: 100px;
    height: 30px;

    :hover {
        background-color: rgb(50 113 206);
    }
`;

const DeleteBtn = styled.button`
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ccc; // rgb(231 150 178);

    width: 100px;
    height: 30px;

    :hover {
        background-color: #cccccc;
    }
`;

const TitleBox = styled.div`
    width: 615px;

    @media (max-width: 912px) {
        width: auto;
    }
`;

const ItemInfoWrapper = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 20px;

    @media (max-width: 912px) {
        flex-direction: column;
        width: 99%;
    }

    // react-slick
    .slick-arrow.slick-prev {
        z-index: 1;
        top: 43px;
        left: 0px;
        padding-bottom: 33px;
    }
    .slick-arrow.slick-next {
        top: 43px;
        right: 20px;
        padding-bottom: 33px;
    }
    .slick-prev:before,
    .slick-next:before {
        color: #00000082;
        font-size: 40px;
    }
    .slick-slide.slick-active > div {
        margin: 0 1px;
    }
    .slick-track {
        left: -1px;
    }
`;

const ProductImageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

// 메인 이미지
const Image = styled.img`
    cursor: pointer;

    border-radius: 10px;

    width: ${(props) => `${props.sizes}px`};
    height: ${(props) => `${props.sizes}px`};

    object-fit: contain;

    @media (max-width: 912px) {
        width: auto;
    }
`;

// 상품상세이미지
// eslint-disable-next-line no-unused-vars
const ItemImage = styled.img`
    cursor: pointer;
    border-radius: 10px;

    width: 130px;
    height: 130px;

    object-fit: cover;
    padding: 0 1px; /* width: 130px; height: 130px; */

    :hover {
        width: 132px;
        height: 132px;
    }
`;

const ExplainTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-right: 13px;

    width: 63px;
`;

// 상품명
const MainTitle = styled.h3`
    margin: 5px 0;

    font-size: 15px;
    font-weight: bold;
    color: gray;
`;

// 상품이름
const SubTitle = styled.h2`
    margin: 5px 0;

    font-size: 18px;
    font-weight: bold;
`;

// 가격정보 라인
const PriceLine = styled.div`
    margin: 25px 0;
`;

// 호스트정보 라인
const HostLine = styled.div`
    margin: 25px 0;
`;

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;

    font-size: 14px;

    margin: 5px 0;
`;

// 상품옵션선택 정보박스
const OptionSelectWrapper = styled.div``;

// SELECT
const Select = styled.select`
    cursor: pointer;
    outline: none;
    border: 1px solid #cccccc;

    width: 178px;
    height: 25px;
`;

// 상품옵션선택
const OptionSelect = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const ProductExplainLine = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;

    width: 53%;
`;

const ProductInformation = styled.div`
    text-align: center;

    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;

    margin: 20px 0;
`;
