import React, { useCallback, useEffect, useRef, useState } from 'react';

// dataSystems
import { interestMock } from '../../dataSystems/CategoryLists';
import { CategoryListsBox } from '../../designComponents/checkboxs/CategoryListsBox';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import SearchbarWithDropdown from '../../designComponents/searchbars/SearchbarWithDropdown';
import FlexMainWrapper, { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';
import MainTable from '../../designComponents/tables/MainTable';
import { Image } from '../../designComponents/imageboxs/Image';
import { checkReadFilter, dateFilter, gatheringType, interestFilter } from '../../utils/textFilter';
import { useNavigate } from 'react-router-dom';
import { ExcelButton, MainButton } from '../../designComponents/buttons/Buttons';

// communication_system
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

// components
import { gatheringListsFilter } from '../../components/MockData/GatheringListsTableFilter';
import AddGatheringChooseInterestModal from '../../designComponents/modals/AddGatheringChooseInterestModal';
import { FilterToggleButton } from '../../designComponents/buttons/FilterToggleButton';
import { TopSideButton } from '../../designComponents/buttons/TopSideButton';
import { ImageModal } from '../../designComponents/modals/ModalComponent';
import MainRefreshCheckApi from '../../communication_system/axios_apis/refresh_check/main_refresh_check_api';
import axios from 'axios';
import { gatheringListsExcel } from '../../data_system/xlsx_util/xlsx_util';
import { getGatheringList } from '../../api/main/gathering/GatheringRequest';
import { SmsPageUserListsFilter } from '../../dataSystems/PageFilters/SmsPageUserListsFilter';
import TableFilter from '../../designComponents/filters/TableFilter';

// 모임 페이지
const Gathering = () => {
    const navigate = useNavigate();

    const [interestToggle, setInterestToggle] = useState(false);
    const [showWithDeleteGatheringToggle, setShowWithDeleteGatheringToggle] = useState(false);
    const [kategoryLists, setKategoryLists] = useState([]);
    const [searchType, setSearchType] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [param, setParam] = useState({
        orderType: 1,
        orderByType: 1,
    });
    const [rendering, setRendering] = useState(false);
    const [imageToggle, setImageToggle] = useState({});

    const ref = useRef(null);
    const tableRef = useRef(null);
    const imgRef = useRef(null);

    // 선택힌 관심주제 제거
    const deleteInterestCode = (target_num) => () => {
        let array = kategoryLists.slice();

        const findIndex = array.indexOf(target_num);
        array.splice(findIndex, 1);

        setKategoryLists(array);
        setRendering((prev) => !prev);
    };

    const callApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            deleted_at_toggle: showWithDeleteGatheringToggle ? 1 : 0,
            interest_code: kategoryLists,
            ...param,
        };

        const response = await getGatheringList(input);

        if (!response.ok) {
            alert('서버 에러 ');
        }

        return response.data;
    };
    const pageCountCallApi = async (page, param) => {
        let input = {
            page: page,
            size: 10,
            searchType: searchType,
            searchWord: searchText,
            deleted_at_toggle: showWithDeleteGatheringToggle ? 1 : 0,
            interest_code: kategoryLists,
            ...param,
        };
        const response = await getGatheringList(input);

        if (!response.ok) {
            alert('서버 에러 ');
        }

        return response.all_cnt;
    };

    const onClickDeleteGathering = (checkItems, setCheckItems, checkDatas, setCheckDatas) => async () => {
        const requestDelete = async (gathering_id, owner_user_id) => {
            try {
                let accessToken;
                const response = MainRefreshCheckApi.post('auth/testLogin', {
                    id: owner_user_id,
                });
                response.then((res) => {
                    accessToken = res.data.data.access_token;
                });

                if (window.confirm('삭제하시겠습니까?')) {
                    const URL = 'https://develop.senor.co.kr/gathering/detail';
                    const response = await axios.delete(`${URL}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        data: {
                            gathering_id: gathering_id,
                            reason_type: 0,
                            reason_detail: '어드민 관리자 삭제 요청',
                        },
                    });

                    if (response.status === 200) {
                        alert(response.data.message);
                        setCheckItems([]);
                        setCheckDatas([]);
                        setRendering((prev) => !prev);
                    }
                }
            } catch (error) {
                console.error('error: ', error);
            }
        };

        checkDatas.forEach((data) => {
            requestDelete(data.id, data.owner_user_id);
        });
    };

    const handleClickOutside = (event) => {
        if (!imgRef.current) return;
        else if (imgRef && !imgRef.current.contains(event.target)) {
            setImageToggle((prev) => !prev);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const onPressPrintExcel = async () => {
        let input = {
            searchType: searchType,
            searchWord: searchText,
            deleted_at_toggle: showWithDeleteGatheringToggle ? 1 : 0,
            interest_code: kategoryLists,
            ...param,
        };
        const response = await RefreshCheckApi.get(`/adminGathering`, { params: input });
        const headers = [
            { key: 'id', value: '모임ID' },
            { key: 'exposure_yn_text', value: '검색 노출' },
            { key: 'deleted_at', value: '삭제' },
            { key: 'gathering_name', value: '모임명' },
            { key: 'interest_code', value: '관심 주제' },
            { key: 'created_at', value: '개설일' },

            { key: 'user_name', value: '모임장명' },
            { key: 'user_nickname', value: '모임장 닉네임' },
            { key: 'manager_phone_no', value: '모임장 연락처' },
            { key: 'count_crew', value: '회원수' },
        ];
        gatheringListsExcel(headers, response.data['gatherings'], '모임 리스트.xlsx');
    };

    return (
        <Wrapper02 id="gathering-page">
            {interestToggle && (
                <AddGatheringChooseInterestModal
                    setInterestToggle={setInterestToggle}
                    kategoryLists={kategoryLists}
                    gatheringFilter={true}
                    onClickCloseModal={() => {
                        setInterestToggle(false);
                    }}
                    onClickInterests={(_, __, array = []) => {
                        setKategoryLists(array.sort());
                        setRendering((prev) => !prev);
                    }}
                />
            )}
            {imageToggle.toggle && (
                <ImageModal xIcon onClickClose={() => setImageToggle({ toggle: false })}>
                    <Image url={imageToggle.src} width={500} height={500} style={{ borderRadius: '5%' }} imgRef={imgRef} />
                </ImageModal>
            )}
            <MainPageTitle text={'모임'} />
            <FlexMainWrapper style={{ alignItems: 'center' }}>
                <SearchbarWithDropdown
                    list={[
                        { id: 1, title: '모임 명', value: 1 },
                        { id: 5, title: '모임 ID', value: 5 },
                        { id: 4, title: '모임장 유저 ID', value: 4 },
                        { id: 2, title: '모임장 닉네임', value: 2 },
                        { id: 3, title: '모임장 이름', value: 3 },
                    ]}
                    resetThenSet={(item) => {
                        setSearchType(item.value);
                    }}
                    onChangeSubmit={(e) => {
                        e.preventDefault();
                        ref?.current?.refresh();
                    }}
                    searchText={searchText}
                    onChangeInput={(e) => {
                        setSearchText(e.target.value);
                    }}
                />
                {kategoryLists.length > 0 ? (
                    <CategoryListsBox
                        categoryLists={kategoryLists}
                        interestMock={interestMock}
                        onClick={(num) => deleteInterestCode(num)}
                        allBtnYN={true}
                        onClickAllDelete={() => {
                            setKategoryLists([]);
                            setRendering((prev) => !prev);
                        }}
                    />
                ) : null}
            </FlexMainWrapper>

            <TableFilter
                param={param}
                setParam={setParam}
                wrapperStyle={{ width: '95%' }}
                topFilterLists={[
                    {
                        filterText: '정렬 기준',
                        orderLists: [
                            { name: '생성 일', width: 100, type: 1 },
                            { name: '가입 회원 수', width: 100, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderByType = type;
                            else delete obj.orderByType;
                            setParam(obj);
                        },
                        orderbyType: param.orderByType,
                    },

                    {
                        filterText: '정렬 방식',
                        orderLists: [
                            { name: '내림차순', width: 100, type: 1 },
                            { name: '오름차순', width: 100, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.orderType = type;
                            else delete obj.orderType;
                            setParam(obj);
                        },
                        orderbyType: param.orderType,
                    },
                    {
                        filterText: '모임 목적',
                        orderLists: [
                            { name: '재미있게', width: 100, type: 1 },
                            { name: '알차게', width: 100, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.purposeType = type;
                            else delete obj.purposeType;
                            setParam(obj);
                        },
                        orderbyType: param.purposeType,
                    },
                    {
                        filterText: '노출 여부',
                        orderLists: [
                            { name: '검색 노출', width: 100, type: 1 },
                            { name: '검색 미노출', width: 100, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.exposureType = type;
                            else delete obj.exposureType;
                            setParam(obj);
                        },
                        orderbyType: param.exposureType,
                    },
                    {
                        filterText: '삭제 여부',
                        orderLists: [
                            { name: '운영중인 모임', width: 100, type: 1 },
                            { name: '샥제된 모임', width: 100, type: 2 },
                        ],
                        onChange: (e, type) => {
                            let obj = { ...param };
                            if (e.target.checked) obj.deleteType = type;
                            else delete obj.deleteType;
                            setParam(obj);
                        },
                        orderbyType: param.deleteType,
                    },
                ]}
                filterLists={[]}
                research={ref}
            />

            <MainTable
                ref={ref}
                rendering={rendering}
                scrollWidth={195}
                buttonComponents={({ checkItems, setCheckItems, checkDatas, setCheckDatas }) => (
                    <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'end' }}>
                        <FlexMainWrapper style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <ExcelButton text="Excel" onClick={onPressPrintExcel} style={{ marginBottom: '5px' }} />
                            <TopSideButton
                                middleOn={false}
                                text01={'등록'}
                                text02={'삭제'}
                                onClick={() => navigate(`/gathering/addGathering`)}
                                onClickDelete={onClickDeleteGathering(checkItems, setCheckItems, checkDatas, setCheckDatas)}
                            />
                        </FlexMainWrapper>
                    </FlexMainWrapper>
                )}
                tableRef={tableRef}
                loadingText={'모임 리스트를 불러오고 있습니다.'}
                headerData={gatheringListsFilter}
                checkID={'id'}
                param={param}
                toggleBlur={true}
                setParam={setParam}
                callApi={callApi}
                pageCountCallApi={pageCountCallApi}
                onClickRowItem={(item, key) => () => {
                    if (key !== 'gathering_image' && key !== 'manager_profile_image') return navigate(`/gathering/detail/${item.id}`);
                }}
                tbodyRenderItem={(key, value) => {
                    if (key === 'purpose') return gatheringType(value);
                    if (key === 'gathering_image' || key === 'owner_photo') {
                        return (
                            <Image
                                size="S80"
                                url={value}
                                width={90}
                                height={90}
                                alt="회원 이미지"
                                style={{ borderRadius: '12%', paddingTop: '4%' }}
                                onClick={() => setImageToggle({ src: value, toggle: true })}
                            />
                        );
                    }
                    if (key === 'deleted_at') return value === null ? <span style={{ color: 'rgb(66, 139, 247)' }}>운영</span> : <span style={{ color: 'rgb(255, 16, 125)' }}>삭제</span>;
                    if (key === 'user_name' && !value) return <span style={{ color: '#797979' }}>-</span>;
                    if (key === 'owner_nick_name' && !value) return <span style={{ color: '#797979' }}>-</span>;
                    if (key === 'owner_phone_no' && !value) return <span style={{ color: '#797979' }}>-</span>;
                    if (key === 'exposure_yn') return checkReadFilter(value, 'ON', 'OFF');
                    if (key === 'created_at') return dateFilter(value);
                    if (key === 'interest_code') return value;
                    if (key === 'date_cnt') return value ? `${value}개` : <span style={{ color: '#797979' }}>0개</span>;
                    if (key === 'crew_cnt') return value ? `${value}명` : <span style={{ color: '#797979' }}>0명</span>;
                    if (key === 'wish_cnt') return value ? `${value}명` : <span style={{ color: '#797979' }}>0명</span>;
                    else return value;
                }}
            />
        </Wrapper02>
    );
};

export default Gathering;
