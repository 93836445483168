export const userTableFilter = [
    {
        key: 'id',
        value: 'ID',
    },
    {
        key: 'name',
        value: '이름',
    },
    {
        key: 'nick_name',
        value: '닉네임',
    },
    {
        key: 'sex',
        value: '성별',
        filter: [
            {
                key: 1,
                value: '남자',
            },
            {
                key: 2,
                value: '여자',
            },
            {
                key: 3,
                value: 'NULL',
            },
        ],
    },
    {
        key: 'age',
        value: '나이',
    },
    {
        key: 'birthday',
        value: '생년월일',
    },
    {
        key: 'phone_no',
        value: '핸드폰 번호',
    },
    {
        key: 'kakao_yn',
        value: '로그인',
        filter: [
            {
                key: 1,
                value: '카카오',
            },
            {
                key: 2,
                value: '휴대폰',
            },
        ],
    },
    {
        key: 'report_count',
        value: '신고횟수',
    },
    // {
    //     key: 'love_marketing_yn',
    //     value: '시럽 마케팅',
    //     filter: [
    //         {
    //             key: 0,
    //             value: '동의',
    //         },
    //         {
    //             key: 1,
    //             value: '미동의',
    //         },
    //     ],
    // },
    {
        key: 'marketing_yn',
        value: '마케팅 동의',
        filter: [
            {
                key: 0,
                value: '동의',
            },
            {
                key: 1,
                value: '미동의',
            },
        ],
    },
    {
        key: 'service_agree_yn',
        value: '이용 동의 여부',
        filter: [
            {
                key: 0,
                value: '동의',
            },
            {
                key: 1,
                value: '미동의',
            },
        ],
    },
    {
        key: 'avail_yn',
        value: '이용 상태',
        filter: [
            {
                key: 1,
                value: '정상',
            },
            {
                key: 2,
                value: '탈퇴',
            },
            {
                key: 3,
                value: '이용차단',
            },
        ],
    },
    {
        key: 'recommend_yn',
        value: '단짝찾기',
        filter: [
            {
                key: 1,
                value: '공개',
            },
            {
                key: 2,
                value: '비공개',
            },
        ],
    },
    {
        key: 'profile',
        value: '프로필 사진',
        filter: [
            {
                key: 1,
                value: '사진있음',
            },
            {
                key: 2,
                value: '사진없음',
            },
            {
                key: 5,
                value: '카카오 기본 프로필',
            },
        ],
    },
    {
        key: 'auth_image',
        value: '카메라 인증',
        filter: [
            {
                key: 1,
                value: '여',
            },
            {
                key: 2,
                value: '부',
            },
        ],
    },
    {
        key: 'profile_photo_auth_yn',
        value: '카메라 인증 결과',
        filter: [
            {
                key: '0',
                value: '미 인증',
            },
            {
                key: '1',
                value: '인증 대기중',
            },
            {
                key: '2',
                value: '인증 완료',
            },
        ],
    },
    {
        key: 'recent_login_date',
        value: '최근접속일',
    },
    {
        key: 'user_created_at',
        value: '가입일',
    },
];
