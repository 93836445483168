import React from 'react';

// styled-components
import styled from 'styled-components';
import { LogoImagePosition, LogoImage, SelectWrapper, Title, Input } from '../../styles/productSt/addHostStyled';

// IP
import { main_server_image_ip } from '../../communication_system/communication_ips';

function HostInformation({ data }) {
    return (
        <div id="add-host-info" style={{ margin: '7px 0' }}>
            <Table>
                <LogoImagePosition>
                    <div>
                        <LogoImage src={main_server_image_ip + data.image} alt="회사이미지 로고" />
                    </div>
                </LogoImagePosition>

                <SelectWrapper>
                    <Title>No</Title>
                    <Input type="text" placeholder="회사명을 입력해주세요." value={data.id || 'Null'} disabled />
                </SelectWrapper>

                <SelectWrapper>
                    <Title>회사명</Title>
                    <Input type="text" placeholder="회사명을 입력해주세요." value={data.name || 'Null'} disabled />
                </SelectWrapper>

                <SelectWrapper>
                    <Title>대표자 이름</Title>
                    <Input type="text" name="host-name" placeholder="대표자 이름" value={data.owner || 'Null'} disabled />
                </SelectWrapper>

                <SelectWrapper>
                    <Title>대표 전화번호</Title>
                    <Input
                        type="tel"
                        name="host-phone"
                        placeholder="대표전화번호"
                        pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                        required
                        value={data.represent_number || 'Null'}
                        disabled
                    />
                </SelectWrapper>

                <SelectWrapper>
                    <Title>사업자 등록번호</Title>
                    <Input
                        type="text"
                        name="host-number"
                        placeholder="사업자 등록번호"
                        value={data.business_number || 'Null'}
                        disabled
                    />
                </SelectWrapper>

                <div id="address-line">
                    <SelectWrapper>
                        <Title>사업장 주소</Title>
                        <Input
                            type="text"
                            name="host-zonecode"
                            placeholder="우편번호"
                            readOnly
                            style={{ cursor: 'pointer' }}
                            value={data.zone_code || 'Null'}
                            disabled
                        />
                    </SelectWrapper>

                    <SelectWrapper>
                        <Title />
                        <Input
                            type="text"
                            name="host-address"
                            placeholder="사업장 주소"
                            readOnly
                            style={{ cursor: 'pointer' }}
                            value={data.address || 'Null'}
                            disabled
                        />
                    </SelectWrapper>

                    <SelectWrapper>
                        <Title />
                        <Input
                            type="text"
                            name="host-address-detail"
                            placeholder="상세주소"
                            value={data.address_detail || 'Null'}
                            disabled
                        />
                    </SelectWrapper>
                </div>
            </Table>
        </div>
    );
}

export default HostInformation;

const Table = styled.div`
    cursor: pointer;
    position: relative;
    border: 1px solid #cccccc;

    padding: 10px 20px;

    background-color: rgb(247 247 247);

    :hover {
        background-color: rgb(232 232 232);
    }
`;
