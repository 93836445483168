import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// styled-components
import styled from 'styled-components';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { requestGetAnswerUsersCount } from '../../data_system/redux/modules/survey.reducer/survey.reducer';

// components
import QuestionComponent from '../../components/SurveyComponents/QuestionComponent';
import ResponseComponent from '../../components/SurveyComponents/ResponseComponent';
import SettingComponent from '../../components/SurveyComponents/SettingComponent';
import { Wrapper02 } from '../../designComponents/wrappers/FlexMainWrapper';

/** 설문 상세 페이지 */
const SurveyDetail = () => {
    const dispatch = useDispatch();
    const { survey_id } = useParams();
    const { answerCount } = useSelector((state) => state.getSurveyReducer);
    const [questionToggle, setQuestionToggle] = useState(true); // 질문
    const [responseToggle, setResponseToggle] = useState(false); // 응답
    const [settingToggle, setSettingToggle] = useState(false); // 설정

    useEffect(() => {
        dispatch(requestGetAnswerUsersCount(survey_id));
    }, [dispatch, survey_id]);

    /******************************** useEffect ********************************/

    // 페이지 URL 변경
    useEffect(() => {
        if (questionToggle) {
            window.history.pushState('', '', `/survey/management/detail/${survey_id}`);
        } else if (responseToggle) {
            window.history.pushState('', '', `/survey/management/detail/${survey_id}/response`);
        } else if (settingToggle) {
            window.history.pushState('', '', `/survey/management/detail/${survey_id}/setting`);
        }
    }, [survey_id, questionToggle, responseToggle, settingToggle]);

    /******************************** function ********************************/

    // 컴포넌트 라우팅
    const onClickRoutes = (page) => () => {
        if (page === 1) {
            setQuestionToggle(true);
            setResponseToggle(false);
            setSettingToggle(false);
        } else if (page === 2) {
            setQuestionToggle(false);
            setResponseToggle(true);
            setSettingToggle(false);
        } else if (page === 3) {
            setQuestionToggle(false);
            setResponseToggle(false);
            setSettingToggle(true);
        }
    };

    /********************************** html **********************************/
    return (
        <Wrapper02>
            <MainRightSideTitle02>
                <ChoisePage status={questionToggle} onClick={onClickRoutes(1)}>
                    질문
                </ChoisePage>
                <ChoisePage status={responseToggle} onClick={onClickRoutes(2)}>
                    응답
                    {answerCount ? <CountAlert status={responseToggle}>{answerCount}</CountAlert> : null}
                </ChoisePage>
                <ChoisePage status={settingToggle} onClick={onClickRoutes(3)}>
                    설정
                </ChoisePage>
            </MainRightSideTitle02>

            <Body>
                {questionToggle && <QuestionComponent />}
                {responseToggle && <ResponseComponent count={answerCount} />}
                {settingToggle && <SettingComponent />}
            </Body>
        </Wrapper02>
    );
};

export default SurveyDetail;

/********************************** styled **********************************/

const MainRightSideTitle02 = styled.div`
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 17px;

    font-size: 20px;
    font-family: noto_sans_kr_bold;
    color: rgb(0 0 0);

    font-weight: ${(props) => (props.point ? '600' : '')};
    text-decoration: ${(props) => (props.point ? 'underline' : '')};

    margin-bottom: 35px;
    margin-left: 10px;
    margin-right: 10px;
`;

const ChoisePage = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;

    text-decoration: ${(props) => (props.status ? 'underline' : '')};
    font-weight: ${(props) => (props.status ? 'bold' : '')};
    color: ${(props) => (props.status ? 'rgb(103, 58, 183)' : '')};
`;

const Body = styled.div`
    width: 50%;

    margin: auto;
    padding: 10px;

    border-radius: 10px;
`;

const CountAlert = styled.div`
    color: #fff;
    background-color: ${(props) => (props.status ? 'rgb(103, 58, 183)' : 'black')};
    text-align: center;

    width: 13px;

    border-radius: 50%;

    font-size: 11px;
    padding: 4px;
`;
