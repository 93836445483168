import React from 'react';

// styled-components
import styled from 'styled-components';

// obj-data
import { VIRTUAL_BANK_CODE } from './payBankCodeService';

/** 가상계좌 생성 */
function CreateVirtualAccount({ params, setParams, packagesData, type, payContents }) {
    // 은행선택
    const onChangeBank = (e) => {
        setParams({ ...params, bank: e.target.value ? String(e.target.value) : '039' });
    };

    /************************** html **************************/
    return (
        <Wrapper>
            <Wrapper02 className="input-line">
                <Select onChange={onChangeBank}>
                    {VIRTUAL_BANK_CODE.map((obj, index) => (
                        <option key={index} value={obj.CODE1}>
                            {obj.KOR}
                        </option>
                    ))}
                </Select>
            </Wrapper02>
        </Wrapper>
    );
}

export default CreateVirtualAccount;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 2px;

    width: 100%;

    @media (max-height: 912px) {
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
`;

const Wrapper02 = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 2px;

    width: 58%;
`;

const Select = styled.select`
    cursor: pointer;
    width: 120px;
    height: 23px;

    outline: none;
    border: 1px solid rgb(202 202 202);

    font-size: 13.5px;
`;
