import styled from 'styled-components';

export const EmptyWrapper = styled.div`
    margin: 10% auto;

    font-size: 13px;
    text-align: center;
`;

export const TableWrapper = styled.div`
    table {
        border-collapse: collapse;
        width: 98%;
        font-size: 11.5px;
    }

    table > thead > tr > td {
        text-align: start;

        padding: 4px 25px;

        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }

    table > tbody > tr > th {
        text-align: start;

        padding: 10px 25px;

        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }

    table > tfoot > tr:hover {
        cursor: ${(props) => (props.data?.length === 0 || !props.data ? null : 'pointer')};
        background-color: ${(props) => (props.data?.length === 0 || !props.data ? '' : '#f4f4ff')};
    }

    table > tfoot > tr > td {
        text-align: start;

        padding: 10px 25px;

        border-bottom: 1px solid black;
    }
`;

export const Checkbox = styled.input`
    cursor: pointer;
    width: 15px;
    height: 15px;
`;

export const SearchFilter = styled.td`
    cursor: pointer;
    user-select: none;
    background-color: #fff;

    font-size: 15px;
    font-weight: 600;

    border-top: 1px solid black;

    :hover {
        background-color: #80808038;
    }

    .filter-contents {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
    }
`;

export const KategoryWord = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;

    font-size: 11.5px;
    font-weight: 500;
`;

export const Input = styled.input`
    cursor: pointer;

    width: 13px;
    height: 13px;
`;

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;

    background-color: #000000b0;
`;

export const ModalBox = styled.div`
    position: relative;

    width: 1000px;
    height: 70vh;

    border-radius: 10px;

    background-color: #fff;

    .close-window {
        padding: 5px 25px;
        text-align: start;
        font-size: 20px;
        color: #428bf7;
    }

    .close-icon {
        position: absolute;
        top: 0;
        right: 0;

        padding: 15px;
        color: gray;
    }

    @media (max-width: 912px) {
        width: 98%;
    }
`;

export const UpdateButton = styled.button`
    cursor: pointer;
    background-color: rgb(66, 139, 247);

    border: 0;
    border-radius: 2px;

    font-size: 13px;
    color: #fff;

    width: 100px;
    height: 33px;

    margin: 5px 0;

    :hover {
        background-color: rgb(27 109 231);
    }

    :active {
        background-color: rgb(66, 139, 247);
    }
`;
