import React, { useEffect, useState } from 'react';
import FlexMainWrapper, { Wrapper03 } from '../../designComponents/wrappers/FlexMainWrapper';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';
import { MainSelect } from '../../designComponents/dropdowns/Selectdown';
import CreatedPayInfoModal from '../Common/Payments/CreatedPayInfoModal';
import { MainButton } from '../../designComponents/buttons/Buttons';
import MainPageTitle from '../../designComponents/titles/MainPageTitle';
import AllPayInfo from './AllPayInfo';
import { MText } from '../../designComponents/text/ContentText';
import SyrupPayInfo from './SyrupPayInfo';
import GatheringPayInfo from './GatheringPayInfo';

/** 전체 결제 정보 컴포넌트 */
const AllPaymentLists = ({ userId, wrapperStyle }) => {
    const [paymentType, setPaymentType] = useState(1);
    const [page, setPage] = useState(1);
    const [data, setDate] = useState([]);
    const [allCount, setAllCount] = useState(0);
    const [onCreatePay, setOnCreatePay] = useState(false);

    const request = async (url, input) => {
        try {
            const response = await RefreshCheckApi.get(url, { params: input });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (userId) {
            switch (paymentType) {
                case 1: {
                    const input = {
                        searchType: 5,
                        searchWord: userId,
                        page: page,
                        size: 13,
                    };
                    request('/adminSubscribe', input).then((res) => {
                        setDate(res?.data?.body);
                        setAllCount(res?.data?.all_count);
                    });
                    break;
                }
                case 2: {
                    const syrupInput = {
                        searchType: 4,
                        searchWord: userId,
                        page: page,
                        size: 10,
                    };
                    request('/adminSyrup/payments', syrupInput).then((res) => {
                        setDate(res?.data);
                        setAllCount(res?.all_count);
                    });
                    break;
                }
                case 3: {
                    const gatherinInput = {
                        searchType: 6,
                        searchWord: userId,
                        page: page,
                        size: 10,
                    };
                    request('/adminGathering/account/pay/users', gatherinInput).then((res) => {
                        setDate(res?.data);
                        setAllCount(res?.allCount);
                    });
                    break;
                }
                default:
                    return;
            }
        } else {
            setDate([]);
            setAllCount(0);
        }
    }, [userId, paymentType, page]);

    const selectLists = [
        { id: 1, title: '전체결제', value: 1 },
        { id: 2, title: '시럽결제', value: 2 },
        { id: 3, title: '모임결제', value: 3 },
    ];

    return (
        <Wrapper03 style={{ marginBottom: 0, ...wrapperStyle }}>
            {onCreatePay && <CreatedPayInfoModal setToggle={setOnCreatePay} />}
            <FlexMainWrapper style={{ alignItems: 'center' }}>
                <MainPageTitle wrapperStyle={{ margin: 0 }} fontStyle={{ fontSize: 17, fontWeight: 600, color: '#797979' }} text={'결제 내역'} />

                {userId ? (
                    <FlexMainWrapper style={{ flex: 1, justifyContent: 'end', alignItems: 'center' }}>
                        <div>
                            <MainSelect
                                list={selectLists}
                                resetThenSet={(item) => {
                                    setPage(1);
                                    setPaymentType(parseInt(item.value));
                                }}
                            />
                        </div>
                        <MainButton onClick={() => setOnCreatePay(true)} text={'가상계좌 생성'} />
                    </FlexMainWrapper>
                ) : null}
            </FlexMainWrapper>
            {data?.length === 0 ? (
                <FlexMainWrapper style={{ height: '85%', justifyContent: 'center', alignItems: 'center' }}>
                    <MText text={'회원정보 또는 결제내역이 존재하지 않습니다.'} color="#797979" />
                </FlexMainWrapper>
            ) : paymentType === 1 ? (
                <AllPayInfo data={data} allCount={allCount} page={page} setPage={setPage} />
            ) : paymentType === 2 ? (
                <SyrupPayInfo data={data} allCount={allCount} page={page} setPage={setPage} />
            ) : (
                <GatheringPayInfo data={data} allCount={allCount} page={page} setPage={setPage} />
            )}
        </Wrapper03>
    );
};

export default AllPaymentLists;
