import produce from 'immer';
import { handleActions } from 'redux-actions';
import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

/************************ action을 정의 ************************/

// 회원정보 조회
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

// 전체회원정보 길이조회
export const GET_ALL_USERS_COUNT_REQUEST = 'GET_ALL_USERS_COUNT_REQUEST';
export const GET_ALL_USERS_COUNT_SUCCESS = 'GET_ALL_USERS_COUNT_SUCCESS';
export const GET_ALL_USERS_COUNT_FAILURE = 'GET_ALL_USERS_COUNT_FAILURE';

// 회원정보 상세 조회
export const GET_USERS_DETAIL_REQUEST = 'GET_USERS_DETAIL_REQUEST';
export const GET_USERS_DETAIL_SUCCESS = 'GET_USERS_DETAIL_SUCCESS';
export const GET_USERS_DETAIL_FAILURE = 'GET_USERS_DETAIL_FAILURE';

// 회원 상세정보 업데이트
export const UPDATE_USERS_DETAIL_REQUEST = 'UPDATE_USERS_DETAIL_REQUEST';
export const UPDATE_USERS_DETAIL_SUCCESS = 'UPDATE_USERS_DETAIL_SUCCESS';
export const UPDATE_USERS_DETAIL_FAILURE = 'UPDATE_USERS_DETAIL_FAILURE';

// 회원정보 일괄수정
export const BATCH_UPDATE_USERS_INFO_REQUEST = 'BATCH_UPDATE_USERS_INFO_REQUEST';
export const BATCH_UPDATE_USERS_INFO_SUCCESS = 'BATCH_UPDATE_USERS_INFO_SUCCESS';
export const BATCH_UPDATE_USERS_INFO_FAILURE = 'BATCH_UPDATE_USERS_INFO_FAILURE';

// 회원이 가입한 모임 리스트 조회
export const GET_USER_GATHERING_LISTS_REQUEST = 'GET_USER_GATHERING_LISTS_REQUEST';
export const GET_USER_GATHERING_LISTS_SUCCESS = 'GET_USER_GATHERING_LISTS_SUCCESS';
export const GET_USER_GATHERING_LISTS_FAILURE = 'GET_USER_GATHERING_LISTS_FAILURE';

// 상태 초기화
export const RESET_REQUEST = 'RESET_REQUEST';

/************************ 초기상태 ************************/

const initialState = {
    users: null, // 회원정보 데이터
    allUsersCount: null, // 전체회원정보 길이
    usersDetail: {}, // 회원상세정보 데이터
    updateUsersInfoResult: null, // 회원 상세정보 업데이트 결과
    userUpdateResultMessage: {}, // 회원정보 업데이트 결과
    userGatheringLists: [], // 회원이 가입한 모임 리스트
    // 회원정보 조회
    getUserLoading: false,
    getUserDone: false,
    getUserError: null,
    // 전체회원정보 길이조회
    getAllUserCountLoading: false,
    getAllUserCountDone: false,
    getAllUserCountError: null,
    // 회원정보 상세조회
    getUserDetailLoading: false,
    getUserDetailDone: false,
    getUserDetailError: null,
    // 회원 상세정보 수정
    updateUserDetailLoading: false,
    updateUserDetailDone: false,
    updateUserDetailError: null,
    // 회원정보 일괄수정
    batchUpdateUsersInfoLoading: false,
    batchUpdateUsersInfoDone: false,
    batchUpdateUsersInfoError: null,
    // 회원이 가입한 모임 리스트
    getUserGatheringListsLoading: false,
    getUserGatheringListsDone: false,
    getUserGatheringListsError: null,
};

/************************ action 함수 ************************/

// 회원정보 조회
export const getUsers = (input) => async (dispatch) => {
    dispatch({
        type: GET_USERS_SUCCESS,
    });
    try {
        const response = await RefreshCheckApi.get('/adminUser', { params: input });
        dispatch({
            type: GET_USERS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_FAILURE,
            payload: error,
        });
    }
};

// 전체회원정보 길이조회
export const getAllUsersCount = (input) => async (dispatch) => {
    dispatch({
        type: GET_ALL_USERS_COUNT_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get('/adminUser/allCount', { params: input });
        dispatch({
            type: GET_ALL_USERS_COUNT_SUCCESS,
            payload: response.data.all_count,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_USERS_COUNT_FAILURE,
            payload: error,
        });
    }
};

/** 회원정보 상세조회 */
export const getUsersDetail = (id) => async (dispatch) => {
    dispatch({
        type: GET_USERS_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminUser/${id}`);
        dispatch({
            type: GET_USERS_DETAIL_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_DETAIL_FAILURE,
            payload: error,
        });
    }
};

/** 회원 상세정보 수정 */
export const updateUserDetailInfo = (params) => async (dispatch) => {
    dispatch({
        type: UPDATE_USERS_DETAIL_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.put(`/adminUser/${params.id}`, params);
        dispatch({
            type: UPDATE_USERS_DETAIL_SUCCESS,
            payload: response.data.ok,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_USERS_DETAIL_FAILURE,
            payload: error,
        });
    }
};

// 회원정보 일괄수정
export const batchUpdateUsersInfo = (idLists, params) => async (dispatch) => {
    dispatch({
        type: BATCH_UPDATE_USERS_INFO_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.patch(`/adminUser/userInfo/batchUpdate`, {
            idLists: idLists,
            params: params,
        });
        dispatch({
            type: BATCH_UPDATE_USERS_INFO_SUCCESS,
            payload: response.data.message,
        });
    } catch (error) {
        dispatch({
            type: BATCH_UPDATE_USERS_INFO_FAILURE,
            payload: error,
        });
    }
};

// 회원이 가입한 모임 리스트 조회
export const getUserGatheringLists = (user_id, type) => async (dispatch) => {
    dispatch({
        type: GET_USER_GATHERING_LISTS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminUser/user/gathering?user_id=${user_id}&type=${type}`);

        dispatch({
            type: GET_USER_GATHERING_LISTS_SUCCESS,
            payload: response.data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USER_GATHERING_LISTS_FAILURE,
            payload: error,
        });
    }
};

/************************ reducer ************************/

const newUsersReducer = handleActions(
    {
        // 회원정보 조회
        [GET_USERS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserLoading = true;
                draft.getUserDone = false;
                draft.getUserError = null;
            }),
        [GET_USERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserLoading = action.payload ? false : true;
                draft.users = action.payload;
                draft.getUserDone = action.payload ? true : false;
            }),
        [GET_USERS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserLoading = false;
                draft.getUserError = action.error;
            }),

        // 전체회원정보 길이조회
        [GET_ALL_USERS_COUNT_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getAllUserCountLoading = true;
                draft.getAllUserCountDone = false;
                draft.getAllUserCountError = null;
            }),
        [GET_ALL_USERS_COUNT_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getAllUserCountLoading = action.payload ? false : true;
                draft.allUsersCount = action.payload;
                draft.getAllUserCountDone = action.payload ? true : false;
            }),
        [GET_ALL_USERS_COUNT_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getAllUserCountLoading = false;
                draft.getAllUserCountError = action.error;
            }),

        // 회원정보 상세조회
        [GET_USERS_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserDetailLoading = false;
                draft.getUserDetailDone = false;
                draft.getUserDetailError = null;
            }),
        [GET_USERS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserDetailLoading = false;
                draft.usersDetail = action.payload;
                draft.getUserDetailDone = true;
            }),
        [GET_USERS_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserDetailLoading = false;
                draft.getUserDetailError = action.error;
            }),

        // 회원 상세정보 업데이트
        [UPDATE_USERS_DETAIL_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.updateUserDetailLoading = false;
                draft.updateUserDetailDone = false;
                draft.updateUserDetailError = null;
            }),
        [UPDATE_USERS_DETAIL_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.updateUserDetailLoading = action.payload ? false : true;
                draft.updateUsersInfoResult = action.payload;
                draft.updateUserDetailDone = action.payload ? true : false;
            }),
        [UPDATE_USERS_DETAIL_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.updateUserDetailLoading = false;
                draft.updateUserDetailError = action.error;
            }),

        // 회원정보 일괄수정
        [BATCH_UPDATE_USERS_INFO_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.batchUpdateUsersInfoLoading = true;
                draft.batchUpdateUsersInfoDone = false;
                draft.batchUpdateUsersInfoError = null;
            }),
        [BATCH_UPDATE_USERS_INFO_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.batchUpdateUsersInfoLoading = action.payload ? false : true;
                draft.userUpdateResultMessage = action.payload;
                draft.batchUpdateUsersInfoDone = action.payload ? true : false;
            }),
        [BATCH_UPDATE_USERS_INFO_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.batchUpdateUsersInfoLoading = false;
                draft.batchUpdateUsersInfoError = action.error;
            }),

        // 회원이 가입한 모임 리스트 조회
        [GET_USER_GATHERING_LISTS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserGatheringListsLoading = true;
                draft.getUserGatheringListsDone = false;
                draft.getUserGatheringListsError = null;
            }),
        [GET_USER_GATHERING_LISTS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserGatheringListsLoading = action.payload ? false : true;
                draft.userGatheringLists = action.payload;
                draft.getUserGatheringListsDone = action.payload ? true : false;
            }),
        [GET_USER_GATHERING_LISTS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUserGatheringListsLoading = false;
                draft.getUserGatheringListsError = action.error;
            }),

        // 상태 초기화
        [RESET_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                // 회원정보 일괄수정 상태 초기화
                draft.batchUpdateUsersInfoLoading = true;
                draft.batchUpdateUsersInfoDone = false;
                draft.batchUpdateUsersInfoError = null;
                // 회원정보 상세정보 업데이트 상태 초기화
                draft.updateUsersInfoResult = null;
                draft.updateUserDetailLoading = true;
                draft.updateUserDetailDone = false;
                draft.updateUserDetailError = null;
            }),
    },
    initialState,
);

export default newUsersReducer;
