import React, { useContext, useEffect, useRef, useState } from 'react';
import FlexMainWrapper from '../../designComponents/wrappers/FlexMainWrapper';
import { Image, ImageFlexWrap } from '../../designComponents/imageboxs/Image';
import { MText, SText } from '../../designComponents/text/ContentText';
import { dateFilter, gatheringBankInfoFilter, interestCodeFilter } from '../../utils/textFilter';
import { TextArea } from '../../designComponents/inputs/TextArea';
import { ImageModal, ModalBody } from '../../designComponents/modals/ModalComponent';
import { FlexTwoTextLists } from '../../designComponents/text/TextMapping';
import UserInfoModalContext from '../../data_system/contexts/user_context/user_info_modal_context';
import UserModal from '../UserComponents/UserModal';
import RefreshCheckApi from '../../communication_system/axios_apis/refresh_check/refresh_check_api';

const GatheringDetail = ({ data, userId }) => {
    const { user_info_modal_actions } = useContext(UserInfoModalContext);
    const { user_info_modal_state } = useContext(UserInfoModalContext);

    const [imageInfo, setImageInfo] = useState({ toggle: false });
    const [managerAccountInfo, setManagerAccountInfo] = useState({});

    const componentsRef = useRef();

    useEffect(() => {
        if (data.manager_id === userId) {
            let input = {
                userId: data.manager_id,
            };
            const response = RefreshCheckApi.get('/adminGathering/manager/account', { params: input });
            response.then((res) => {
                setManagerAccountInfo(res.data.data);
            });
        } else {
            setManagerAccountInfo(false);
        }
    }, [data, userId]);

    const handleClickOutside = (event) => {
        if (!componentsRef.current) return;
        else if (componentsRef && !componentsRef.current.contains(event.target)) setImageInfo({ toggle: false });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const nullTag = <span style={{ color: '#797979' }}>정보없음</span>;
    return (
        <ModalBody
            modalComponents={
                <div>
                    {user_info_modal_state.modalVisible && (
                        <UserModal serSrcOrigin={(srcOrigin) => setImageInfo({ toggle: true, src: srcOrigin })} />
                    )}
                    {imageInfo.toggle && (
                        <ImageModal xIcon={true} onClickClose={() => setImageInfo({ toggle: false })}>
                            <Image url={imageInfo.src} width={500} height={500} imgRef={componentsRef} />
                        </ImageModal>
                    )}
                </div>
            }
            leftComponents={
                <>
                    <ImageFlexWrap
                        title={'모임 사진'}
                        image_lists={data.gathering_image_lists}
                        imageWidth={80}
                        imageHeight={80}
                        onClickDefault={false}
                        onClick={(src) => setImageInfo({ toggle: true, src: src })}
                    />

                    <FlexMainWrapper style={{ flexDirection: 'column', width: '100%', margin: '15px 0' }}>
                        <SText text={'모임 설명'} color="#797979" />
                        <TextArea style={{ height: '20vh' }} disabled={true} value={data.explanation} />
                    </FlexMainWrapper>

                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '모임명',
                                text02: data.gathering_name,
                            },
                            {
                                text01: '모임 개설일',
                                text02: dateFilter(data.created_at),
                            },
                            {
                                text01: '관심 주제',
                                text02: data.interest_code?.length ? interestCodeFilter(data.interest_code) : nullTag,
                            },
                            {
                                text01: '지역',
                                text02: data.address || nullTag,
                            },
                            {
                                text01: '모임원 수',
                                text02: (data.count_crew || 0) + '명',
                            },
                        ]}
                    />
                </>
            }
            rightComponents={
                <>
                    <SText text={'모임장 프로필'} color="#797979" />
                    <Image
                        url={data.manager_profile_image}
                        width={80}
                        height={80}
                        onClick={() => {
                            user_info_modal_actions.setId(data.manager_id);
                            user_info_modal_actions.setModalVisible(true);
                        }}
                    />
                    <FlexTwoTextLists
                        lists={[
                            {
                                text01: '모임장명',
                                text02: data.manager_name,
                            },
                            {
                                text01: '모임장 닉네임',
                                text02: data.manager_nick_name,
                            },
                            {
                                text01: '모임장 연락처',
                                text02: data.manager_phone_no,
                            },
                        ]}
                    />
                    {managerAccountInfo && (
                        <MText text={'모임장 계좌정보'} color="#797979" style={{ marginTop: '25px', marginBottom: '15px' }} />
                    )}
                    {managerAccountInfo && (
                        <FlexTwoTextLists
                            lists={[
                                {
                                    text01: '은행명',
                                    text02: gatheringBankInfoFilter(managerAccountInfo.account_bank),
                                },
                                {
                                    text01: '계좌번호',
                                    text02: managerAccountInfo.account_number,
                                },
                                {
                                    text01: '예금주',
                                    text02: managerAccountInfo.account_holder,
                                },
                            ]}
                        />
                    )}
                    {managerAccountInfo.business_name && (
                        <FlexTwoTextLists
                            lists={[
                                {
                                    text01: '사업자명',
                                    text02: managerAccountInfo.business_name,
                                },
                                {
                                    text01: '사업자번호',
                                    text02: managerAccountInfo.business_number,
                                },
                            ]}
                        />
                    )}
                </>
            }
        />
    );
};

export default GatheringDetail;
