export const ScheduleTableFilter = [
    {
        key: 'id',
        value: '게시글 ID',
    },
    {
        key: 'created_at',
        value: '생성일자',
        filter: [
            {
                key: 1,
                value: '내림차순',
            },
            {
                key: 2,
                value: '오름차순',
            },
        ],
    },
    {
        key: 'title',
        value: '일정명',
        filter: [
            {
                key: 1,
                value: '내림차순',
            },
            {
                key: 2,
                value: '오름차순',
            },
        ],
    },
    {
        key: 'start_date',
        value: '일정날짜',
        filter: [
            {
                key: 1,
                value: '최신순',
            },
            {
                key: 2,
                value: '오래된순',
            },
        ],
    },
    {
        key: 'start_time',
        value: '시작시간',
        filter: [
            {
                key: 1,
                value: '1시간 이하',
            },
            {
                key: 2,
                value: '1~2시간',
            },
            {
                key: 3,
                value: '2~3시간',
            },
            {
                key: 4,
                value: '3~4시간',
            },
            {
                key: 5,
                value: '4~5시간',
            },
            {
                key: 6,
                value: '5시간 이상',
            },
        ],
    },
    {
        key: 'complete_yn',
        value: '진행 상태',
        filter: [
            {
                key: 1,
                value: '종료',
            },
            {
                key: 0,
                value: '진행중',
            },
        ],
    },
    {
        key: 'cancel_yn',
        value: '취소여부',
        filter: [
            {
                key: 1,
                value: '취소',
            },
        ],
    },
    {
        key: 'deleted_at',
        value: '삭제여부',
        filter: [
            {
                key: 1,
                value: '삭제',
            },
            {
                key: 2,
                value: '정상',
            },
        ],
    },
    {
        key: 'address',
        value: '일정지역',
    },
    {
        key: 'gathering_name',
        value: '모임명',
    },
    {
        key: 'owner_name',
        value: '모임장명',
    },
    {
        key: 'max_participants',
        value: '일정정원',
        filter: [
            {
                key: 1,
                value: '0~19명',
            },
            {
                key: 2,
                value: '20~39명',
            },
            {
                key: 3,
                value: '40~59명',
            },
            {
                key: 4,
                value: '60~79명',
            },
            {
                key: 5,
                value: '80~100명',
            },
            {
                key: 6,
                value: '100명 이상',
            },
        ],
    },
    {
        key: 'join_cnt',
        value: '결제인원',
        filter: [
            {
                key: 1,
                value: '0명',
            },
            {
                key: 2,
                value: '1~9명',
            },
            {
                key: 3,
                value: '10~19명',
            },
            {
                key: 4,
                value: '20~29명',
            },
            {
                key: 5,
                value: '30~39명',
            },
            {
                key: 6,
                value: '40명 이상',
            },
        ],
    },
    {
        key: 'join_rate',
        value: '참여율',
    },
    {
        key: 'pay_amount',
        value: '총 결제 회비',
        filter: [
            {
                key: 1,
                value: '없음',
            },
            {
                key: 2,
                value: '0~999원',
            },
            {
                key: 3,
                value: '1,000~4,999원',
            },
            {
                key: 4,
                value: '5,000~49,999원',
            },
            {
                key: 5,
                value: '50,000~99,999원',
            },
            {
                key: 6,
                value: '100,000~299,999원',
            },
            {
                key: 7,
                value: '300,000~100만원',
            },
            {
                key: 8,
                value: '100 만원 이상',
            },
        ],
    },

    {
        key: 'comment_cnt',
        value: '댓글',
    },
];
