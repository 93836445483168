import produce from 'immer';
import { handleActions } from 'redux-actions';

import RefreshCheckApi from '../../../../communication_system/axios_apis/refresh_check/refresh_check_api';

// 회원정보 리스트 불러오기
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
// 모임생성
export const ADD_GATHERING_REQUEST = 'ADD_GATHERING_REQUEST';
export const ADD_GATHERING_SUCCESS = 'ADD_GATHERING_SUCCESS';
export const ADD_GATHERING_FAILURE = 'ADD_GATHERING_FAILURE';
// 리셋 States
export const RESET_STATE = 'RESET_STATE';

/******************************************
 * initialState
 ******************************************/
const initialState = {
    users: [], // 회원정보 리스트
    // 회원정보 리스트
    getUsersLoading: false,
    getUsersDone: false,
    getUsersError: null,
    // 모임생성
    addGatheringLoading: false,
    addGatheringDone: false,
    addGatheringError: null,
};

/******************************************
 * action 함수
 ******************************************/

// 회원정보 리스트 불러오기
export const requestGetUsers = (input) => async (dispatch) => {
    dispatch({
        type: GET_USERS_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.get(`/adminUser`, { params: input });
        dispatch({
            type: GET_USERS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: GET_USERS_FAILURE,
            error: error,
        });
    }
};

// 모임생성
export const postGathering = (param) => async (dispatch) => {
    dispatch({
        type: ADD_GATHERING_REQUEST,
    });
    try {
        const response = await RefreshCheckApi.post(`/adminGathering`, param);
        dispatch({
            type: ADD_GATHERING_SUCCESS,
            payload: response.data.ok,
        });
    } catch (error) {
        dispatch({
            type: ADD_GATHERING_FAILURE,
            error: error,
        });
    }
};

/******************************************
 * reducer
 ******************************************/
const addGatheringReducer = handleActions(
    {
        // 회원정보 리스트 불러오기
        [GET_USERS_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersLoading = true;
                draft.getUsersDone = false;
                draft.getUsersError = null;
            }),
        [GET_USERS_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersLoading = action.payload ? false : true;
                draft.users = action.payload;
                draft.getUsersDone = action.payload ? true : false;
            }),
        [GET_USERS_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.getUsersLoading = false;
                draft.getUsersError = action.error;
            }),

        // 모임생성
        [ADD_GATHERING_REQUEST]: (state, action) =>
            produce(state, (draft) => {
                draft.addGatheringLoading = true;
                draft.addGatheringDone = false;
                draft.addGatheringError = null;
            }),
        [ADD_GATHERING_SUCCESS]: (state, action) =>
            produce(state, (draft) => {
                draft.addGatheringLoading = false;
                draft.addGatheringDone = true;
            }),
        [ADD_GATHERING_FAILURE]: (state, action) =>
            produce(state, (draft) => {
                draft.addGatheringLoading = false;
                draft.addGatheringError = action.error;
            }),

        // 상태 리셋
        [RESET_STATE]: (state, action) =>
            produce(state, (draft) => {
                // 회원정보 리스트 불러오기
                draft.getUsersLoading = true;
                draft.getUsersDone = false;
                draft.getUsersError = null;
                // 모임생성
                draft.addGatheringLoading = true;
                draft.addGatheringDone = false;
                draft.addGatheringError = null;
            }),
    },
    initialState,
);

export default addGatheringReducer;
