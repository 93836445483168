import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import produce from "immer";
import {ArrowDownBlue1x, ArrowUpBlue1x} from "../../icons/icons";
import {h_px38rate, w_px12rate, w_px14rate, w_px15rate, w_px217rate, w_px219rate, w_px29rate,} from "../../size";
import styled from "styled-components";
import {rgb_255_255_255, rgb_35_40_45, rgba_66_139_247_0d2,} from "../../colors";

const LongDropdown = ({ list, resetThenSet, index }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(list[index]["title"]);

  const toggleList = useCallback(() => {
    setIsListOpen(produce((isListOpen) => !isListOpen));
  }, []);

  const selectItem = useCallback(
    (item) => {
      const { title } = item;

      setHeaderTitle(title);
      setIsListOpen(false);
      resetThenSet(item);
    },
    [resetThenSet]
  );
    useEffect(()=>{selectItem(list[index])},[index])
  return (
    <DropdownFrame>
      <MainTitleBtn type="button" onClick={toggleList} isListOpen={isListOpen}>
        <HeaderTitle>{headerTitle}</HeaderTitle>
        {isListOpen ? (
          <ArrowUpBlue1x></ArrowUpBlue1x>
        ) : (
          <ArrowDownBlue1x></ArrowDownBlue1x>
        )}
      </MainTitleBtn>
      {isListOpen && (
        <DropdownListFrame style={{display:'flex',flexDirection:'column'}} role="list">
          {list.map((item) => (
            <MenuItemBtn
              type="button"
              key={item.id}
              onClick={() => selectItem(item)}
            >
              {item.title}
            </MenuItemBtn>
          ))}
        </DropdownListFrame>
      )}
    </DropdownFrame>
  );
};

LongDropdown.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      selected: PropTypes.bool,
      key: PropTypes.string,
    })
  ).isRequired,
  resetThenSet: PropTypes.func.isRequired,
};

export default React.memo(LongDropdown);

const DropdownFrame = styled.div`
  position: relative;
`;

const MainTitleBtn = styled.button`
  display: flex;
  width: ${w_px219rate}px;
  height: ${h_px38rate}px;
  padding-left: ${w_px14rate}px;
  padding-right: ${w_px14rate}px;
  align-items: center;
  justify-content: space-between;
  background-color: ${rgb_255_255_255};
  border-width: 1px;
  border-color: ${rgba_66_139_247_0d2};
  border-bottom-width: ${(props) => (props.isListOpen ? "0px" : "1px")};
  word-break: break-all;
`;

const HeaderTitle = styled.div`
  margin-right: ${w_px29rate}px;
  font-family: noto_sans_kr_medium;
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
  word-break: break-all;
`;

const DropdownListFrame = styled.div`
  position: absolute;
  width: ${w_px219rate}px;
  box-sizing: border-box;
  border: 1px solid ${rgba_66_139_247_0d2};
  border-top-width: 0px;
  background-color: ${rgb_255_255_255};
  z-index: 999;
`;

const MenuItemBtn = styled.button`
  width: ${w_px217rate}px;
  height: ${h_px38rate}px;
  padding-left: ${w_px15rate}px;
  background-color: ${rgb_255_255_255};
  border-width: 0px;
  font-family: noto_sans_kr_medium;
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
  text-align: left;
  word-break: break-all;
`;
